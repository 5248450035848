import React from 'react';

import { AdsRoutes } from '@pages/routes';

import Form from './Form';

export default function NewModal({ record, onHide, refetch }) {
  const { newRequest, createRequest } = AdsRoutes.AdUnitCampaigns;

  return (
    <Form
      record={record}
      onHide={onHide}
      refetch={refetch}
      request={newRequest}
      submitForm={createRequest}
      actionName="creation"
    />
  );
}
