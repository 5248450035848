import React from 'react';
import {
  isNaN, isArray, isEmpty, reduce, get,
} from 'lodash';

import { useFormGroup } from '@hooks/useFormGroup';
// eslint-disable-next-line import/no-cycle
import { useFormContext } from '@hooks/useFormContext';
import Error from './Error';

const presentErrors = (Component, errors, options) => (
  <Component {...options}>
    {options.multiline ? errors : errors.join(', ')}
  </Component>
);

export default function ServerError({
  as, className = '', full, multiline, name, handleCollectionErrors = true,
}) {
  const Component = as || Error;

  const { meta: errorsContainer } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = full ? name : generateName(name);

  const errors = errorsContainer && errorsContainer.errors && get(errorsContainer.errors, fullName);

  if (errors) {
    if (isArray(errors)) return presentErrors(Component, errors, { className, multiline });
    if (!handleCollectionErrors) return '';

    const collectionErrors = reduce(errors, (result, value, key) => {
      if (!isNaN(key) && isArray(value)) {
        const entryNumber = +key + 1;

        result.push(`Entry ${entryNumber} has following errors: ${value.join(', ')}`);
      }

      return result;
    }, []);

    if (!isEmpty(collectionErrors)) return presentErrors(Component, collectionErrors, { className, multiline });
  }

  return '';
}
