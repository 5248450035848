import { mapValues, isObject, isArray } from 'lodash';

export default function processProps(data, options) {
  return mapValues(data, (propValue) => {
    if (isArray(propValue)) return propValue.map((value) => processProps(value, options));
    if (isObject(propValue)) return processProps(propValue, options);

    const optionsName = String(propValue).split('_options:')[1];
    return optionsName ? options[optionsName] : propValue;
  });
}
