import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { TIMESTAMP_COLUMNS, InLiveColumn, InUseColumn } from '@pages/common';
import { isInUse } from '@pages/common/InUse';

import IconButton from '@controls/buttons';

export default function columnsFactory({
  applicationId, editPath, gameType, onDelete,
}) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Type', accessor: 'fileType' },
    InLiveColumn,
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({
        row: {
          original: {
            id, name, filename, inUse, downloadUrl,
          },
        },
      }) => {
        const disabled = isInUse(inUse);

        return (
          <ButtonToolbar>
            <IconButton.Edit to={editPath({ id, gameType, applicationId })} readonly={disabled} minimized />
            <IconButton.Delete onClick={() => onDelete(id, name)} disabled={disabled} minimized />
            <IconButton.Download href={downloadUrl} download={filename} minimized />
          </ButtonToolbar>
        );
      },
    },
  ];
}
