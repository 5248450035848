import React from 'react';

import { baseManifestSet, updateManifestSet } from '@requests/my-pet-cafe/manifestSets';
import CreateUpdateSet from './CreateUpdateSet';

export default function Base() {
  return (
    <CreateUpdateSet
      fetchFunction={baseManifestSet}
      saveFunction={updateManifestSet}
      actionName="update"
      isBase
    />
  );
}
