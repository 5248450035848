import React from 'react';
import { matchPath } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { ArchivedSettingsRoutes, GameSettingsRoutes, MyPetCafeRoutes } from '@pages/routes';

export default function GameSettingsNavigationItem({ applicationId, applicationTypeName, minimized }) {
  const theme = useTheme();

  return (
    <Sidebar.LinkItem
      to={GameSettingsRoutes.indexPath({ applicationId, gameType: applicationTypeName })}
      icon={faGears}
      title="Game Settings"
      color={theme.orange}
      minimized={minimized}
      isActive={(_, location) => (
        [
          `${ArchivedSettingsRoutes.indexRawPath}/*`,
          GameSettingsRoutes.indexRawPath,
          MyPetCafeRoutes.UnlockRequirements.indexRawPath,
        ].some((path) => matchPath({ path }, location.pathname))
      )}
    />
  );
}
