import React, { useState } from 'react';

import styled from 'styled-components';
import { useField } from 'formik';
import { useFormGroup } from '@hooks';
import { Badge } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const RemoveButton = styled.span`
  cursor: pointer;
  padding-left: 0.5rem;
`;

export default function TagsField({
  name,
  propertyName,
  placeholder,
  disabled,
  inline = false,
}) {
  const { generateName } = useFormGroup();
  const [{ value: tags }, , { setValue }] = useField(generateName(name));
  const [currentValue, setCurrentValue] = useState('');
  const filteredTags = tags.filter(({ _destroy }) => !_destroy);

  const onInputChange = (event) => {
    event.preventDefault();

    setCurrentValue(event.target.value);
  };

  const onInputKeyPress = (event) => {
    if (!['Enter', ' ', ','].includes(event.key)) return;

    event.preventDefault();

    if (currentValue.length === 0) return;

    setCurrentValue('');
    setValue([...tags, { [propertyName]: currentValue, _uuid: uuidv4() }]);
  };

  const handleDelete = (index) => {
    if (disabled) return;
    const targetUuid = filteredTags[index]._uuid;
    const newTags = tags.map((tag) => (tag._uuid === targetUuid ? { ...tag, _destroy: true } : tag));

    setValue(newTags);
  };

  return (
    <>
      <input
        value={currentValue}
        className="form-control"
        placeholder={placeholder}
        onChange={onInputChange}
        onKeyPress={onInputKeyPress}
        disabled={disabled}
      />
      {filteredTags.map(({ value, _uuid }, index) => (
        <div key={_uuid} className={inline ? 'd-inline-block pe-2' : ''}>
          <Badge key={_uuid} bg="secondary" className="p-2 mt-1">
            <span>{value}</span>
            <RemoveButton onClick={() => handleDelete(index)}>&times;</RemoveButton>
          </Badge>
        </div>
      ))}
    </>
  );
}
