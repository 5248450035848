import React from 'react';
import styled from 'styled-components';

const StyledTd = styled.td`
  background-color: inherit !important;
  ${({ isChanged, theme }) => isChanged && `border: solid 3px ${theme.variants.warning} !important;`}
  ${({ width }) => `
    width: ${width};
  `}
  ${({ sticky }) => sticky && `
    position: sticky;
    position: -webkit-sticky;
    left: 0;
  `}
  padding: 10px !important;
  z-index: 1;
  border-width: 1px 1px !important;
`;

export default function Td({
  onDoubleClick,
  width,
  sticky,
  isChanged,
  children,
}) {
  return (
    <StyledTd
      onDoubleClick={onDoubleClick}
      width={width}
      sticky={sticky}
      isChanged={isChanged}
    >
      {children}
    </StyledTd>
  );
}
