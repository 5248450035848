import React, { useCallback } from 'react';
import { at, memoize, omit } from 'lodash';

import APP_DATA from '@services/appData';
import {
  fieldBuilder,
  selectBuilder,
  fieldGroupBuilder,
  assetsKeySelectBuilder,
  timePeriodFieldBuilder,
} from '@services/fields-factory';
import { fromClassNamesToOptions } from '@services/enums';
import { TimePeriodField } from '@controls/form';

import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { LocalizationsRoutes } from '@pages/routes';

import { offlineGamesSettingsFields } from '@components/game-settings/Resource';
import { adRequestDelayFields } from '@components/game-settings/Resource/Shared';
import combineWithSharedFieldsFactory, { defaultPopupsOptions } from '@components/game-settings/combineWithSharedFieldsFactory';

import DailyChallengeTrophiesDistribution from './DailyChallengeTrophiesDistribution';

const dailyChallengeTrophyAmountOptions = Array.from({ length: 5 }, (_, i) => ({ label: i + 1, value: i + 1 }));

const popupsOptions = [
  ...defaultPopupsOptions,
  { label: 'New UI Tutorial', value: 'New_ui_tutorial' },
  { label: 'New Seasonal Themes', value: 'New_seasonal_themes' },
  { label: 'DC Trophies', value: 'DC_Trophies_Surface' },
  { label: 'Quick Play - Onboarding Surfacing Popup', value: 'quickplay_onboarding_surfacing_popup' },
  { label: 'Daily Login Rewards popup triggers: Game start', value: 'daily_login_rewards' },
];

const Fields = memoize(({
  customizationAssetFieldsConfiguration,
  uiForOldUsersOptions,
  journeyUnlockCriteriaOptions,
  hintOverrideActions,
  tutorialTypes,
  FoundationMoveSoundOptions,
  InvalidMoveSoundOptions,
  localizationKeyOptions,
}) => ({
  ...fieldBuilder({ name: 'disappearingBottomUiEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'analyticsUseBinaryQueue', type: 'checkbox' }),
  ...fieldBuilder({ name: 'pushNotificationsSettingEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'hintLockEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyClassicModeHints', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyClassicModeUndos', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyClassicModeShuffles', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyExpressModeHints', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyExpressModeUndos', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyExpressModeShuffles', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyTrialModeHints', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyTrialModeUndos', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyTrialModeShuffles', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyChallengeModeHints', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyChallengeModeUndos', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyChallengeModeShuffles', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyRiddleModeHints', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyRiddleModeUndos', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyRiddleModeShuffles', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyHelperAnalyticsEvents', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyGameSelectThemedButtonEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyLobbyThemedButtonEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyStockpileCycleModeHints', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyStockpileCycleModeUndos', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyStockpileCycleModeShuffles', type: 'checkbox' }),
  ...fieldBuilder({ name: 'newJourneyAvailableFirstTimeExperiencePopupEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'newJourneyAvailablePopupToggle', type: 'checkbox' }),
  ...fieldBuilder({ name: 'gameScreenBackButton', type: 'checkbox' }),
  ...fieldBuilder({ name: 'journeyCustomisationOptionsEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'deckRatingsEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'cardAttachMoveTime', type: 'number', fieldOptions: { appendText: 'msec' } }),
  ...fieldBuilder({ name: 'adsUseFairbidMediation', type: 'checkbox' }),
  ...fieldBuilder({ name: 'adsInterstitialPrefetchDelay', type: 'number' }),
  ...fieldBuilder({ name: 'adsBannerPrefetchDelay', type: 'number' }),
  ...fieldBuilder({ name: 'replayJourneyLevelsEnabled', type: 'checkbox' }),
  ...selectBuilder({ name: 'uiForOldUsers', options: uiForOldUsersOptions }),
  ...selectBuilder({ name: 'tutorialType', options: tutorialTypes, includeEmpty: true }),
  ...selectBuilder({ name: 'foundationMoveSound', options: FoundationMoveSoundOptions }),
  ...selectBuilder({ name: 'invalidMoveSound', options: InvalidMoveSoundOptions }),
  ...fieldBuilder(
    {
      name: 'newcomersReminderTime',
      componentFn: ({ name, disabled }) => (
        <TimePeriodField name={name} disabled={disabled} inputs={['hours', 'minutes']} />
      ),
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'dailyChallengePushNotificationsConfiguration',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'enabled', type: 'checkbox' }),
        ...timePeriodFieldBuilder(
          {
            name: 'time',
          },
        ),
        ...fieldBuilder({ name: 'title', type: 'text' }),
        ...selectBuilder({ name: 'titleLocalisationKeyId', options: localizationKeyOptions }),
        ...fieldBuilder({ name: 'body', type: 'text' }),
        ...selectBuilder({ name: 'bodyLocalisationKeyId', options: localizationKeyOptions },
        ),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'journeyConfiguration',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'enabled', type: 'checkbox' }),
        ...selectBuilder({ name: 'unlockCriteria', options: journeyUnlockCriteriaOptions }),
        ...fieldBuilder({ name: 'unlockCriteriaValue', type: 'number' }),
        ...fieldBuilder({ name: 'activationJourneyEnabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'postActivationJourneyHours', type: 'number' }),
        ...fieldBuilder({ name: 'journeyLimitScrollEnabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'journeyLimitScrollInitialLevel', type: 'number' }),
        ...fieldBuilder({ name: 'journeyStatToastsEnabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'journeyImprovedGoalSurfacingEnabled', type: 'checkbox' }),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'hintsConfiguration',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'hintOnMovesNum', type: 'number' }),
        ...fieldBuilder({ name: 'hintMinIdleTime', type: 'number' }),
        ...fieldBuilder({ name: 'hintMaxIdleTime', type: 'number' }),
        ...selectBuilder({ name: 'hintOverrideAction', options: hintOverrideActions, includeEmpty: true }),
        ...fieldBuilder({ name: 'hintOverrideActionValue', type: 'number' }),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'hintsAnimationConfiguration',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'moveForthAnimationDuration', type: 'number', fieldOptions: { appendText: 'msec' } }),
        ...fieldBuilder({ name: 'moveBackAnimationDuration', type: 'number', fieldOptions: { appendText: 'msec' } }),
        ...fieldBuilder({ name: 'floatAnimationDuration', type: 'number', fieldOptions: { appendText: 'msec' } }),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'challengeModeAnimationConfiguration',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'enabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'gamesCount', type: 'number' }),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'defaultCardBack',
      fieldsConfiguration: customizationAssetFieldsConfiguration(),
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'defaultCardFront',
      fieldsConfiguration: customizationAssetFieldsConfiguration(),
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'defaultTableBackground',
      fieldsConfiguration: customizationAssetFieldsConfiguration(),
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'dailyChallengeTrophiesSettings',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'dailyChallengeTrophiesEnabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'notificationEnabled', type: 'checkbox' }),
        ...selectBuilder({ name: 'dailyChallengeTrophiesAmount', options: dailyChallengeTrophyAmountOptions }),
        ...fieldBuilder(
          {
            name: 'dailyChallengeTrophiesDistribution',
            as: 'component',
            componentFn: ({ disabled }) => <DailyChallengeTrophiesDistribution disabled={disabled} />,
          },
        ),
        ...fieldBuilder({ name: 'dailyChallengeForceOverride', type: 'checkbox' }),
      },
    },
  ),
}), (args) => JSON.stringify(omit(args, 'customizationAssetFieldsConfiguration')));

const fieldsFactory = ({ applicationId }) => {
  const [
    uiForOldUsersOptions,
    journeyUnlockCriteriaOptions,
    hintOverrideActions,
    tutorialTypes,
    FoundationMoveSoundOptions,
    InvalidMoveSoundOptions,
  ] = fromClassNamesToOptions([
    'Solitaire::UiForOldUsersOptions',
    'Solitaire::JourneyUnlockCriterias',
    'Solitaire::HintOverrideActions',
    'Solitaire::TutorialTypes',
    'Solitaire::SoundOptions::FoundationMoveSound',
    'Solitaire::SoundOptions::InvalidMoveSound',
  ]);
  const [assetKeysStatuses, assetTypes] = at(
    APP_DATA.enums,
    ['AssetsKeys::Statuses', 'Solitaire::AssetTypes'],
  );
  const customizationAssetFieldsConfiguration = () => assetsKeySelectBuilder(
    {
      name: 'keyId',
      extraFieldName: 'assetId',
      types: [assetTypes.CUSTOMISATION],
      status: [assetKeysStatuses.ACTIVE, assetKeysStatuses.INACTIVE],
      showStatuses: false,
      fieldOptions: { disableLabel: true },
      selectSize: 8,
    },
  );
  const localizationKeyOptions = useCallback(() => (
    requestWithToSelectOptions(LocalizationsRoutes.Keys.indexRequest, applicationId)
  ), []);

  return Fields({
    customizationAssetFieldsConfiguration,
    uiForOldUsersOptions,
    journeyUnlockCriteriaOptions,
    hintOverrideActions,
    tutorialTypes,
    FoundationMoveSoundOptions,
    InvalidMoveSoundOptions,
    localizationKeyOptions,
  });
};

export default combineWithSharedFieldsFactory(
  [
    fieldsFactory,
    offlineGamesSettingsFields,
    adRequestDelayFields,
  ],
  { popupsOptions },
);
