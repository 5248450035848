import { max, last } from 'lodash';

import { persistedCollection, persistedMap } from '@services/utils';
import { addUuid } from '@services/recursivelyProcessObject';

const calculateTotalXpForLevels = (levels, mutate = false) => {
  let total = 0;
  return levels.map((level, index) => {
    const previousNumber = index === 0 ? 0 : levels[index - 1].number;
    total += level.xpToUnlock * (level.number - previousNumber);
    const updatedLevel = { ...level, totalXp: total };

    if (mutate) {
      Object.assign(level, updatedLevel);
      return level;
    }

    return updatedLevel;
  });
};

export const recalculateTotalXp = (state) => {
  if (!state) return {};

  const updatedLevelsAttributes = calculateTotalXpForLevels(persistedCollection(state.levelsAttributes), false);

  return { ...state, levelsAttributes: updatedLevelsAttributes };
};

const recalculateTotalXpMutate = (state) => {
  if (!state) return {};

  calculateTotalXpForLevels(persistedCollection(state.levelsAttributes), true);

  return state;
};

export default function reducer(state, action) {
  const { type, uuid } = action;
  const levelConfigType = `${state.configModule}::LevelConfig`;

  switch (type) {
    case 'addLevel': {
      const levels = persistedCollection(state.levelsAttributes);
      const maxNumber = max(levels.map(({ number }) => number)) || 0;
      const lastLevel = last(levels) || { xpToUnlock: 0 };

      state.levelsAttributes.push(
        addUuid({
          number: maxNumber + 1,
          xpToUnlock: lastLevel.xpToUnlock || 0,
          rewardAffiliationsAttributes: [],
          configType: levelConfigType,
        }),
      );
      recalculateTotalXpMutate(state);
      break;
    }
    case 'removeLevel': {
      const levelToDelete = state.levelsAttributes.find((level) => !level._destroy && level._uuid === uuid);
      levelToDelete._destroy = true;
      recalculateTotalXpMutate(state);
      break;
    }
    case 'addConfigAttributes': {
      const levelToUpdate = state.levelsAttributes.find((level) => !level._destroy && level._uuid === uuid);
      levelToUpdate.configAttributes = { featureToUnlock: [] };
      break;
    }
    case 'removeConfigAttributes': {
      const levelToUpdate = state.levelsAttributes.find((level) => !level._destroy && level._uuid === uuid);
      levelToUpdate.configAttributes.featureToUnlock = null;
      levelToUpdate.configAttributes._destroy = true;
      break;
    }
    case 'addNewLevels': {
      const maxNumber = max(persistedMap(state.levelsAttributes, ({ number }) => number)) || 0;
      for (let index = 0; index < state.numberOfNewLevels; index += 1) {
        state.levelsAttributes.push(addUuid({
          number: maxNumber + 1 + index,
          xpToUnlock: state.diff,
          rewardAffiliationsAttributes: [],
          configType: levelConfigType,
        }));
      }
      state.diff = '';
      state.numberOfNewLevels = '';
      recalculateTotalXpMutate(state);
      break;
    }
    case 'recalculateTotalXp':
      recalculateTotalXpMutate(state);
      break;
    default: break;
  }

  return state;
}
