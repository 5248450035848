import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { CSVLink } from 'react-csv';
import { getHomePageSettings, downloadAllLevels } from '@requests/my-pet-cafe/level-layouts';
import { useQuery } from '@hooks';
import IconButton from '@controls/buttons';

export default function Home() {
  const downloadCsvRef = useRef();
  const [allLevels, setAllLevels] = useState({ data: [], headers: [], loaded: false });
  const { response } = useQuery(getHomePageSettings);
  const { query } = useRouter();

  useEffect(() => {
    if (allLevels.loaded && downloadCsvRef?.current?.link) {
      downloadCsvRef.current.link.click();
    }
  }, [allLevels]);

  const handleDownloadAllLevels = () => {
    downloadAllLevels(query).then((resp) => {
      setAllLevels({ ...resp.data, loaded: true });
    });
  };

  return response ? (
    <Card>
      <Card.Header>
        <h2 className="text-center">Level Bank</h2>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={6} className="text-center">
            <h3>Layout ID count</h3>
            <h1>{response.layoutsCount}</h1>
          </Col>
          <Col xs={6} className="text-center">
            <h3>Last updated</h3>
            <h1>{response.lastUpdatedDay}</h1>
            <h4 className="text-secondary">{response.lastUpdatedTime}</h4>
          </Col>
        </Row>
        <p />
        <Row>
          <Col xs={6} className="text-center">
            <IconButton.Edit to={`/admin/applications/${query.applicationId}/my_pet_cafe/level_layouts`} readonly />
          </Col>
          <Col xs={6} className="text-center">
            <CSVLink
              ref={downloadCsvRef}
              headers={allLevels.headers}
              data={allLevels.data}
              filename="level-bank.csv"
            />
            <IconButton.Download onClick={handleDownloadAllLevels}>Download current level bank</IconButton.Download>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  ) : '';
}
