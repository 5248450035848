import React from 'react';

import { useFlashMessages } from '@tripledotstudios/react-core';
import { useCurrentApplication } from '@hooks';
import { UploadButton } from '@pages/common';

function BaseImport({ routes, refetch }) {
  const { success, error } = useFlashMessages();
  const { applicationId } = useCurrentApplication();

  const uploadFile = (file) => routes.uploadRequest({ applicationId, file })
    .then((result) => {
      switch (result.status) {
        case 422:
          error(
            `The following validation errors happened during upload: ${JSON.stringify(result.rawData._meta.errors)}`,
            { timeout: 120 },
          );
          break;
        case 500:
          error('Something went wrong', { details: result.data.message });
          break;
        default:
          success('The bank was created successfully');
          refetch();
      }
    });

  return <UploadButton accept="application/JSON, application/zip, text/csv" request={uploadFile} />;
}

export default BaseImport;
