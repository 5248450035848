import { wrapReducer } from '@reducers';

const formReducer = (state, action) => {
  switch (action.type) {
    case 'addRewardedVideoPlacement': {
      const { placementId } = action;

      state.placementAffiliationsAttributes.push({ placementId });
      break;
    }
    case 'removeRewardedVideoPlacement': {
      const { index } = action;
      const { id } = state.placementAffiliationsAttributes[index];
      if (!id) {
        state.placementAffiliationsAttributes.splice(index, 1);
        break;
      }

      state.placementAffiliationsAttributes[index]._destroy = true;
      break;
    }
    case 'restoreRewardedVideoPlacement': {
      const { index } = action;

      state.placementAffiliationsAttributes[index]._destroy = false;
      break;
    }
    default: break;
  }

  return state;
};

export default wrapReducer(formReducer);
