import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes } from '@pages/routes';

import Form from './Form';

export default function New({
  entityName, excludedColumns, requestSchemaOptions, routePostfix, schemaType, schemaTypeName,
}) {
  const { response, setResponse } = useQuery(MergeRoutes.ItemConfigurationSets.newRequest);
  const { query } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: `${entityName}s`,
    actionName: 'creation',
    redirectPath: MergeRoutes.ItemConfigurationSets.indexPath({ applicationId: query.applicationId, routePostfix }),
    setData: setResponse,
  });

  const onSubmit = (values) => (
    MergeRoutes.ItemConfigurationSets
      .createRequest({ ...values, applicationId: query.applicationId })
      .then(responseHandler)
  );

  return response && (
    <Form
      actionName="Create"
      data={response}
      entityName={entityName}
      excludedColumns={excludedColumns}
      onSubmit={onSubmit}
      requestSchemaOptions={requestSchemaOptions}
      routePostfix={routePostfix}
      schemaType={schemaType}
      schemaTypeName={schemaTypeName}
    />
  );
}
