import { get, set } from 'lodash';

import APP_DATA from '@services/appData';
import { addUuid } from '@services/recursivelyProcessObject';

const DEFAULT_CONDITION_TYPE = 'MyPetCafe::UnlockRequirements::Conditions::String';
const BOOLEAN_CONDITION_TYPE = 'MyPetCafe::UnlockRequirements::Conditions::Boolean';
const Operations = APP_DATA.enums['MyPetCafe::UnlockRequirements::Conditions::SectionOperations'];
const StringConditions = APP_DATA.enums['MyPetCafe::UnlockRequirements::Conditions::Strings'];

const buildNewCondition = () => (
  addUuid({
    id: null,
    key: StringConditions.DECORATION_SET_CHANGED,
    type: DEFAULT_CONDITION_TYPE,
    _destroy: false,
  })
);

const buildNewSection = () => addUuid(
  {
    id: null,
    operation: Operations.AND,
    conditionsAttributes: [
      buildNewCondition(),
    ],
    conditionsSectionsAttributes: [],
    _destroy: false,
  },
);

const recursivelyRemoveSections = (section) => {
  if (!section) return;

  section._destroy = true;
  const childSections = section.conditionsSectionsAttributes;

  if (childSections) childSections.forEach((childSection) => recursivelyRemoveSections(childSection));
};

export default function formReducer(state, action) {
  switch (action.type) {
    case 'addUnlockRequirementsConditionsSection': {
      const { conditionsSectionsPath } = action;
      const conditionsSectionsAttributes = get(state, conditionsSectionsPath);

      conditionsSectionsAttributes.push(buildNewSection());

      break;
    }
    case 'removeUnlockRequirementsConditionsSection': {
      const { conditionsSectionPath } = action;

      recursivelyRemoveSections(get(state, conditionsSectionPath));

      break;
    }
    case 'addUnlockRequirementsCondition': {
      const { conditionsPath } = action;
      const conditionsAttributes = get(state, conditionsPath);

      conditionsAttributes.push(buildNewCondition());

      break;
    }
    case 'removeUnlockRequirementsCondition': {
      const { conditionPath } = action;

      set(state, `${conditionPath}._destroy`, true);

      break;
    }
    case 'changeUnlockRequirementsConditionType': {
      const { conditionPath, condition } = action;
      const defaultValue = condition.value === BOOLEAN_CONDITION_TYPE ? false : null;

      set(state, `${conditionPath}.key`, null);
      set(state, `${conditionPath}.value`, defaultValue);

      break;
    }
    default: break;
  }

  return state;
}
