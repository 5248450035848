import React from 'react';

import { Badge } from 'react-bootstrap';
import { persistedCollection, persistedMap } from '@services/utils';

import rulesPresenter from './presenter';

export default function RulesList({ rulesAttributes, rawEnums }) {
  const isLastRule = (index) => persistedCollection(rulesAttributes).length - 1 === index;

  return persistedMap(rulesAttributes, (rule, index) => (
    <>
      <span key={rule._uuid}>
        {rulesPresenter({
          type: rule.type,
          operation: rule.operation,
          enums: rawEnums,
          props: rule,
        })}
      </span>
      {!isLastRule(index) && <Badge bg="secondary" className="m-1">AND</Badge>}
    </>
  ));
}
