import React from 'react';

import { FormatWrapper } from '../../shared';
import EditableCell from '../EditableCell';
import TypeModal from '../../TypeModal';

export default function FormatCell({
  name,
  defaultValue,
  pushChangedRow,
}) {
  const onSave = ({ value }) => pushChangedRow({ fieldName: 'format', value });

  return (
    <EditableCell
      name={name}
      defaultValue={defaultValue}
      valueWrapper={FormatWrapper}
    >
      {({ disableEditMode }) => (
        <>
          <FormatWrapper value={defaultValue} />
          <TypeModal
            defaultValue={defaultValue}
            onSave={onSave}
            onHide={disableEditMode}
          />
        </>
      )}
    </EditableCell>
  );
}
