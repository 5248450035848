import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { LocalizationsRoutes } from '@pages/routes';

import {
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
} from '@pages/common';

import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

export default function ColumnsFactory({
  currentApplication, setDuplicatedRecord, onDelete,
}) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    InLiveColumn,
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({ row: { original: { id, name, inUse } } }) => {
        const disabled = isInUse(inUse);
        return (
          <ButtonToolbar>
            <IconButton.Edit
              to={LocalizationsRoutes.Configs.editPath({ id, applicationId: currentApplication.id })}
              minimized
            />
            <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
            <IconButton.Delete
              onClick={onDelete(id, name)}
              disabled={disabled}
              title={disabled ? 'Disabled as the entity is used' : null}
              minimized
            />
          </ButtonToolbar>
        );
      },
    },
  ];
}
