import React from 'react';
import { Col } from 'react-bootstrap';

import { Label, TimePeriodField } from '@controls/form';

import localizeField from './localizeField';

export default function TimePeriodFieldBuilder({
  name: baseName, labelText, inputFields = ['hours', 'minutes'], tooltips = {}, fieldOptions = {},
}) {
  return {
    [baseName]: {
      Inline: ({ name, disabled }) => (
        <TimePeriodField
          name={name || baseName}
          disabled={disabled}
          inputs={inputFields}
        />
      ),
      Labeled: ({
        name, disabled, localeNamespace, locale = {}, tooltipBuilder,
      }) => {
        const { label, tooltip, clientKey } = localizeField(
          baseName, labelText, tooltips, localeNamespace, locale, tooltipBuilder,
        );

        return (
          <Col>
            <Label text={label} fieldSize={8} tooltipText={tooltip} copyableText={clientKey}>
              <TimePeriodField
                name={name || baseName}
                disabled={disabled}
                inputs={inputFields}
              />
            </Label>
          </Col>
        );
      },
      fieldOptions,
    },
  };
}
