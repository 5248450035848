import getCookieByName from '@services/getCookieByName';

const shortEnvironmentNameMap = {
  test: 'TST', // Used in E2E tests
  development: 'DEV',
  feature: 'FB',
  load_testing: 'LTS',
  automation: 'AUT',
  staging: 'STG',
  tt_staging: 'STG',
  production: 'PRD',
  tt_production: 'PRD',
};

const fullEnvironmentNameOverrideMap = {
  feature: 'Feature Branch',
  tt_staging: 'TT Staging',
  tt_production: 'TT Production',
  load_testing: 'Load Testing',
};

const currentEnvironment = () => getCookieByName('frontend_env') || 'development';

function shortCurrentEnvironment() {
  const shortEnvironment = shortEnvironmentNameMap[currentEnvironment()];
  if (!shortEnvironment) throw new Error('Unknown environment');

  return shortEnvironment;
}

function fullCurrentEnvironment() {
  const environment = currentEnvironment();
  return fullEnvironmentNameOverrideMap[environment] || environment;
}

export { currentEnvironment, shortCurrentEnvironment, fullCurrentEnvironment };
