import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  TIMESTAMP_COLUMNS,
  InUseColumn,
} from '@pages/common';

import { MergeRoutes } from '@pages/routes';
import ProductDetails from '@components/merge/shared/ProductDetails';
import IconButton from '@controls/buttons';

export default function ColumnsFactory({ query, setDuplicatedRecord }) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Description', accessor: 'description' },
    { Header: 'Type', accessor: 'type', disableSortBy: true },
    {
      Header: 'Product details',
      Cell: ({ row }) => <ProductDetails product={row.original} />,
    },
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({ row: { original: { id, name } } }) => (
        <ButtonToolbar>
          <IconButton.Edit to={MergeRoutes.Products.editPath({ id, applicationId: query.applicationId })} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        </ButtonToolbar>
      ),
    },
  ];
}
