import React, { useMemo } from 'react';
import { camelCase, difference, pickBy } from 'lodash';

import { ReactTable } from '@pages/common';
import DiffValue from '../DiffValue';
import { destructionRows } from '../diffRows';

const columnBuilder = [
  { Header: 'Attribute/Property', accessor: 'attribute' },
  { Header: 'Action', id: 'action', Cell: 'removed' },
  { Header: 'Context', id: 'context', Cell: '-' },
  {
    Header: 'Values',
    accessor: 'value',
    disableSortBy: true,
    Cell: ({ row }) => <DiffValue value={row.original.value} onClick={row.original.onClick} />,
  },
];

export default function Destruction({ showAssociation, versionTree }) {
  const { version } = versionTree;
  const disabledAttributes = difference(
    Object.keys({ ...version.object.data, ...pickBy(versionTree.trackedAssociations, 'belongsTo') }),
    JSON.parse(version.object.data.enabledFieldsNames).map(camelCase),
  );

  const changesRows = destructionRows({
    versionTree, showAssociation, omitAttributes: [...disabledAttributes, '_uuid'],
  });

  const columns = useMemo(() => columnBuilder, []);

  return (
    <ReactTable
      columns={columns}
      data={changesRows}
      disableSortBy
    />
  );
}
