import React, { useState, useMemo } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { partition, sortBy } from 'lodash';
import ReactSelect from 'react-select';
import { Spinner } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import {
  useQuery, useConfirm, useI18n, useCurrentApplication,
} from '@hooks';
import { Label, SelectField, ServerError } from '@controls/form';
import { ReactTable } from '@pages/common';
import { MyPetCafeRoutes } from '@pages/routes';

import columnsFactory from './entity-banks/columnsFactory';

const buildBankSelectOption = (bank, entityName) => (
  { label: `${bank.name} [${bank.levelsCount} ${entityName}(s)]`, value: bank.id }
);

export default function EntityBanks({
  variantAttributes, disabled, onBankAdd, onBankRemove,
}) {
  const [selectedBankId, setSelectedBankId] = useState(null);
  const { enums } = APP_DATA;
  const { translate } = useI18n();
  const { applicationId } = useCurrentApplication();

  const { response, isLoading } = useQuery({
    request: MyPetCafeRoutes.Adventures.EventBanks.indexRequest,
    queryKey: ['adventureBanks', applicationId, disabled],
    staleTime: 60_000,
  });
  const confirm = useConfirm();

  const bankStatuses = enums['MyPetCafe::Adventures::BankStatuses'];

  const bankTypeName = translate.fallback('adventures.banks.name');
  const entityName = translate.fallback('adventures.entities.name');
  const bankEntityName = translate.fallback('adventures.banks.events.name');

  const onOverrideAdd = () => {
    onBankAdd(selectedBankId);
    return setSelectedBankId(null);
  };

  const onOverrideRemove = (bankId, name) => {
    confirm.showConfirmation({
      title: 'Are you sure?',
      body: `${bankTypeName} '${name}' will be removed from ${entityName} and won't be served to users.`,
    }).then(() => {
      onBankRemove(bankId);
    });
  };

  const columns = useMemo(() => columnsFactory({
    routes: MyPetCafeRoutes.Adventures.EventBanks,
    onOverrideRemove,
    bankEntityName,
  }), []);

  if (isLoading) return <Spinner />;

  const notDestroyedBanks = variantAttributes.bankAffiliationsAttributes.filter(({ _destroy }) => !_destroy);
  const addedBankIds = notDestroyedBanks.map((bank) => bank.bankId);
  const [selectedBanks, notSelectedBanks] = partition(response.items, ({ id }) => addedBankIds.includes(id));

  const defaultBank = variantAttributes.defaultBankId
    ? response.items.find(({ id }) => id === variantAttributes.defaultBankId)
    : null;

  const bankOptions = notSelectedBanks
    .filter(({ id, status }) => (
      status === bankStatuses.ACTIVE && (defaultBank ? defaultBank.id !== id : true)
    ))
    .map((bank) => buildBankSelectOption(bank, bankEntityName));

  return (
    <div className="mt-2 mb-2">
      <h3>{translate.fallback('adventures.banks.pluralName')}</h3>

      <Label text={`Default ${bankTypeName}`}>
        <SelectField
          name="defaultBankId"
          options={defaultBank
            ? [buildBankSelectOption(defaultBank, bankEntityName), ...bankOptions]
            : bankOptions}
          isDisabled={disabled}
        />
      </Label>
      {defaultBank && (
        <ReactTable
          data={[defaultBank]}
          columns={columns.slice(0, 3)}
        />
      )}
      {selectedBanks.length > 0 && (
        <Label text={`${bankTypeName} overrides`} direction="vertical">
          <ReactTable
            data={sortBy(selectedBanks, ({ id }) => addedBankIds.indexOf(id))}
            columns={columns}
          />
        </Label>
      )}
      {defaultBank && (
        <Row>
          <Col xs={9}>
            <ReactSelect
              options={bankOptions}
              onChange={({ value }) => setSelectedBankId(value)}
              value={selectedBankId ? bankOptions.find(({ value }) => value === selectedBankId) : null}
              isDisabled={disabled}
            />
          </Col>
          <Col xs={3}>
            <Button
              className="w-100"
              onClick={onOverrideAdd}
              disabled={!selectedBankId || disabled}
            >
              {`Add ${bankTypeName}`}
            </Button>
          </Col>
        </Row>
      )}
      <ServerError name="bankAffiliationsAttributes" />
    </div>
  );
}
