import React from 'react';

import { Col } from 'react-bootstrap';

import APP_DATA from '@services/appData';
import { fromClassNameToOptions } from '@services/enums';
import {
  Filter,
  StatusFilter,
} from '@pages/common';

export default function HardJourneysFilter() {
  const statusOptions = fromClassNameToOptions('Woodoku::HardJourneyStatuses');
  const { enums } = APP_DATA;
  const statusesEnum = enums['Woodoku::HardJourneyStatuses'];
  const defaultStatuses = [statusesEnum.ACTIVE];

  return (
    <Filter
      defaultComponents={['availabilityStateIn', 'labels', 'createdByMe', 'id', 'name']}
    >
      {({ updateFilterField }) => (
        <Col md={6}>
          <StatusFilter
            name="statusIn"
            label="Status"
            options={statusOptions}
            updateFilterField={updateFilterField}
            defaults={defaultStatuses}
          />
        </Col>
      )}
    </Filter>
  );
}
