import { useEffect, useState } from 'react';

import createClient from '@services/createClient';

export const MESSAGE_TYPES = {
  CHANNEL: 0,
  SYSTEM: {
    SUCCESS: 1,
    FAILURE: 2,
  },
};

export default function useWebSocketChannel({ path, onDataReceived = (d) => d }) {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const processData = (data, type = MESSAGE_TYPES.SYSTEM.FAILURE) => {
      setMessages((state) => [{ type, data, ts: Date.now() }, ...state]);
    };

    const handleChannelData = (data) => {
      processData(onDataReceived(data), MESSAGE_TYPES.CHANNEL);
    };

    try {
      const client = createClient({
        onConnect: () => {
          processData('Connection with WebSockets server is established', MESSAGE_TYPES.SYSTEM.SUCCESS);
        },
        onDisconnect: () => { processData('Connection with WebSockets server is lost'); },
        onError: (error) => { processData(error); },
      });

      client.subscribe(path, handleChannelData);
    } catch (error) {
      console.error(error);
      processData('Something went wrong while establishing connection');
    }
  }, []);

  const clearMessages = () => {
    setMessages([]);
  };

  return { messages, clearMessages };
}
