import React, { useState, useEffect } from 'react';
import { useRouter, Spinner } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import { get } from 'lodash';

import { useFormContext } from '@hooks';
import { persistedCollection } from '@services/utils';
import { WoodokuRoutes, FigureSetsRoutes } from '@pages/routes';

import LevelsListWrapper from '@components/journeys/banks/levels/LevelsListWrapper';
import BulkCopyModal from '@components/journeys/banks/levels/BulkCopyModal';
import { defaultTileTypedLevelOptions } from '@pages/journeys/level-banks/configuration-forms/woodoku/constants';
import { presentLevelGoals } from '@pages/journeys/level-banks/configuration-forms/woodoku/utils';
import Level from './Level';

const goalTypes = APP_DATA.enums['Woodoku::Journeys::LevelBanks::LevelGoalTypes'];

const LEVELS_LIMIT = 50;

export default function Levels({ levelsAttributes, readOnly }) {
  const [tileTypes, setTileTypes] = useState([]);
  const [figureSets, setFigureSets] = useState([]);
  const [figureSetDynamicConfigs, setFigureSetDynamicConfigs] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { dispatch, values } = useFormContext();
  const { query } = useRouter();

  const onEntityAdd = () => dispatch({
    actionType: 'addEmptyEntity',
    levelsPath: 'levelsAttributes',
    options: { ...defaultTileTypedLevelOptions },
  });

  useEffect(() => {
    const promises = [
      FigureSetsRoutes.Sets
        .indexRequest({ ...query, withoutPagination: true })
        .then(({ data: { items } }) => setFigureSets(
          items.map(({ id, name }) => ({ label: name, value: id })),
        )),
      WoodokuRoutes.FigureSetDynamicConfigs
        .indexRequest({ ...query, withoutPagination: true })
        .then(({ data: { items } }) => (
          setFigureSetDynamicConfigs(
            items.map(({ name, id: dcId, figureSetIds }) => ({ label: name, value: dcId, figureSetIds })),
          )
        )),
      WoodokuRoutes.TileTypes
        .indexRequest({ ...query, withoutPagination: true })
        .then(({ data: { items } }) => (
          setTileTypes(items)
        )),
    ];

    Promise.all(promises).then(() => setDataLoaded(true));
  }, []);

  if (!dataLoaded) return <Spinner name="levels data" />;

  return (
    <LevelsListWrapper
      entityAttributes={levelsAttributes}
      onEntityAdd={onEntityAdd}
      levelsPath="levelsAttributes"
      attributesName="levelsAttributes"
      metaErrorsPath={['errors', 'levelsAttributes']}
      entityNameTranslationPath="bonusLevels.levelBanks.levels.name"
      extraEntityIdentityFn={({ levelGoalsAttributes }) => (
        presentLevelGoals(persistedCollection(levelGoalsAttributes), goalTypes, tileTypes)
      )}
      bulkCopyRoutes={WoodokuRoutes.BonusLevels.LevelBanks}
      readOnly={readOnly}
      disableAdding={persistedCollection(levelsAttributes).length >= LEVELS_LIMIT}
      BulkCopyModal={BulkCopyModal}
      bankType="Woodoku::BonusLevels::LevelBanks::Bank"
    >
      {(props) => (
        <Level
          figureSets={figureSets}
          figureSetDynamicConfigs={figureSetDynamicConfigs}
          tileTypes={tileTypes}
          errors={get(values, `meta.errors.levelsAttributes.${props.index}`, {})}
          {...props}
        />
      )}
    </LevelsListWrapper>
  );
}
