import React from 'react';
import { useFlashMessages, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import {
  Form,
  FormGroup,
  AbTestingContext,
  useConfirm,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import {
  Label,
  Field,
  SelectField,
  FormButtonsGroup,
  PriorityField,
  AvailabilityStateFormGroup,
  StaticFieldsFormGroup,
} from '@controls/form';
import {
  rulesReducer,
  streaksEntityVariantsReducer,
  combineReducers,
} from '@reducers';
import { PageHeader, PageSection } from '@pages/common';
import { StreaksRoutes } from '@pages/routes';

import RulesForm from '@pages/rules/Form';
import VariantForm from './VariantForm';

const reducer = combineReducers([
  rulesReducer,
  streaksEntityVariantsReducer,
]);

const { enums, modelsLocales: { spiderSolitaire: { streaks: tooltips } } } = APP_DATA;
const statusesEnum = enums['Streaks::Statuses'];

export default function EntitiesForm({
  data, actionName, onSubmit, onArchive,
}) {
  const statuses = fromClassNameToOptions('Streaks::Statuses');

  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();
  const { error: flashError } = useFlashMessages();
  const indexPath = StreaksRoutes.Entities.indexPath({ applicationId });

  const isArchived = (status) => status === statusesEnum.ARCHIVED;

  const onFormSubmit = (values) => {
    if (isArchived(data.status)) {
      return new Promise((resolve) => {
        flashError('Streak is archived');
        resolve();
      });
    }
    if (!data.id || !isArchived(values.status)) return onSubmit(values);

    return confirm.showConfirmation({
      title: `Streak '${values.name}' will be archived and not available for work. Continue?`,
    })
      .then(() => onArchive());
  };

  return (
    <AbTestingContext
      entityType="Streaks::Entity"
      entityId={data.id}
    >
      <Form
        initialValues={{ ...data, applicationId }}
        onSubmit={onFormSubmit}
        reducer={reducer}
        sharedInputProps={{ disabled: isArchived(data.status) }}
      >
        {({ values }) => (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Streak`}>
              <FormButtonsGroup cancelButtonPath={indexPath} disableSubmit={isArchived(data.status)} />
            </PageHeader>

            <Field type="hidden" name="applicationId" />

            {actionName === 'Update' && (
              <>
                <p />
                <Label text="ID">
                  <Field name="id" disabled />
                </Label>
                <Label text="Variant ID">
                  <Field name="currentVariantAttributes.id" disabled />
                </Label>
              </>
            )}

            <FormGroup>
              <Label text="Name" tooltipText={tooltips.name}>
                <Field type="text" name="name" disabled={false} />
              </Label>
              <Label text="Status" tooltipText={tooltips.status}>
                <SelectField name="status" options={statuses} />
              </Label>
              <AvailabilityStateFormGroup />
              <Label text="Priority" tooltipText={tooltips.priority}>
                <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority || {}} />
              </Label>

              <StaticFieldsFormGroup data={data} />

              <PageSection title="Settings">
                <VariantForm
                  formGroupName="currentVariantAttributes"
                  values={values.currentVariantAttributes}
                  defaultVariant
                />
              </PageSection>
            </FormGroup>

            <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} />
          </>
        )}
      </Form>
    </AbTestingContext>
  );
}
