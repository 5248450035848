import React, { useState, useEffect } from 'react';

import { useRouter } from '@tripledotstudios/react-core';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { LocalizationsRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Localisations config',
    actionName: 'update',
    setData,
  });

  const onSubmit = (values) => LocalizationsRoutes.Configs
    .updateRequest({ ...values, applicationId: query.applicationId })
    .then(responseHandler);

  useEffect(() => {
    LocalizationsRoutes.Configs.editRequest(query).then((response) => setData(response.data));
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} />;
}
