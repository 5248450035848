import { memoize } from 'lodash';

import { fieldBuilder } from '@services/fields-factory';

import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';
import { adRequestDelayFields } from '@components/game-settings/Resource/Shared';

const fieldsFactory = memoize(() => (
  {
    ...fieldBuilder({ name: 'allowShuffle', type: 'checkbox' }),
    ...fieldBuilder({ name: 'lastThreeCards', type: 'number' }),
    ...fieldBuilder({ name: 'winnableSeed', type: 'text' }),
  }
));

export default combineWithSharedFieldsFactory([fieldsFactory, adRequestDelayFields]);
