import React from 'react';
import { isEmpty, omit } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import LevelsListWrapper from '@components/shared/ListWrapper';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup, ReorderableMultiSelectField,
} from '@controls/form';

import { DuplicateButton, PageHeader } from '@pages/common';
import { WoodokuRoutes as Routes } from '@pages/routes';
import reducer from './reducer';

const isNumber = (value) => /(^\d+(?:\.\d+)?$)/.test(value);

const probabilityOptions = (value) => value.map((v) => ({ value: v, label: v }));

export default function AreaSetForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const disabled = !isEmpty(omit(data.inUse, '_uuid'));

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit} reducer={reducer}>
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Shapes Bank Set`}>
            <FormButtonsGroup
              disableSubmit={disabled}
              cancelButtonPath={Routes.ShapesBankSets.indexPath({ applicationId })}
            >
              <DuplicateButton
                routes={Routes.ShapesBankSets}
                data={data}
                entityName="Shapes Bank Set"
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label required text="Name">
            <Field name="name" disabled={disabled} />
          </Label>
          <StaticFieldsFormGroup data={data} />

          <Label required text="Session game probability">
            <ReorderableMultiSelectField
              name="sessionGameProbability"
              isDisabled={disabled}
              isCreatable
              isMulti
              options={probabilityOptions(values.sessionGameProbability)}
              isValidNewOption={isNumber}
            />
          </Label>
          <Label required text="Attempt level probability">
            <ReorderableMultiSelectField
              name="attemptLevelProbability"
              isDisabled={disabled}
              isCreatable
              isMulti
              options={probabilityOptions(values.attemptLevelProbability)}
              isValidNewOption={isNumber}
            />
          </Label>
          <LevelsListWrapper
            disabled={disabled}
            entityAttributes={values.banksAttributes}
            onEntityAdd={() => dispatch({ actionType: 'addEmptyEntity' })}
            attributesName="banksAttributes"
            metaErrorsPath={['banksAttributes']}
            entityNameTranslationPath="shapesBank.name"
            routes={Routes.ShapesBankSets}
            hideListActions
          >
            {() => (
              <div className="d-flex flex-row justify-content-around">
                <div className="flex-fill pe-2">
                  <Label text="Bank Number" direction="vertical">
                    <Field name="bankNumber" disabled={disabled} />
                  </Label>
                </div>
                <div className="flex-fill pe-2">
                  <Label text="One shape weight" direction="vertical">
                    <Field name="firstShapeWeight" type="number" disabled={disabled} />
                  </Label>
                </div>
                <div className="flex-fill pe-2">
                  <Label text="Two shapes weight" direction="vertical">
                    <Field name="secondShapeWeight" type="number" disabled={disabled} />
                  </Label>
                </div>
                <div className="flex-fill pe-2">
                  <Label text="Three shapes weight" direction="vertical">
                    <Field name="thirdShapeWeight" type="number" disabled={disabled} />
                  </Label>
                </div>
              </div>
            )}
          </LevelsListWrapper>
        </>
      )}
    </Form>
  );
}
