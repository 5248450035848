import React from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useI18n } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

export default function New({
  form: Form,
  nameTranslationPath,
  routes,
}) {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(routes.newRequest);
  const { translate } = useI18n();

  const entityName = translate.fallback(nameTranslationPath);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName,
    actionName: 'create',
    setData: setResponse,
  });

  const onSubmit = (values) => routes.createRequest({ ...values, applicationId }).then(responseHandler);

  if (isLoading) return <Spinner />;

  return response && (
    <Form
      data={response}
      title={`New ${entityName}`}
      entityName={entityName}
      onSubmit={onSubmit}
    />
  );
}
