import { useParams } from 'react-router-dom';
import underscore from 'to-snake-case';

import APP_DATA from '@services/appData';

export default function useCurrentApplication() {
  const { applications } = APP_DATA;
  const { applicationId } = useParams();

  const currentApplication = (applications || []).find((a) => a.id === parseInt(applicationId, 10));
  const applicationName = currentApplication && currentApplication.type.split('::').slice(0, -1).join('::');
  const configuredApplicationName = currentApplication?.name;
  const routingScope = `/admin/applications/${applicationId}`;

  return {
    shortApplicationName: currentApplication?.shortName,
    typeName: currentApplication?.typeName,
    frontendTypeName: currentApplication?.frontendTypeName,
    applicationKey: applicationName ? underscore(applicationName) : '',
    applicationId: currentApplication?.id,
    currentApplication: { ...currentApplication, name: applicationName, configuredName: configuredApplicationName },
    applicationName,
    routingScope,
  };
}
