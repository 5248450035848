import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ThemeProvider } from 'styled-components';
import { ProvideFlashMessages } from '@tripledotstudios/react-core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { getThemeName } from '@services/theme';
import { ConfirmProvider, GlobalLoadingProvider, UserContextProvider } from '@hooks';

import classicTheme from '@pages/common/themes/classic';
import darkTheme from '@pages/common/themes/dark';

import AppPicker from './AppPicker';

const THEMES_MAPPING = {
  classic: classicTheme,
  dark: darkTheme,
};
const queryClient = new QueryClient();

export default function App() {
  const theme = THEMES_MAPPING[getThemeName()];

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <ProvideFlashMessages>
          <ConfirmProvider>
            <DndProvider backend={HTML5Backend}>
              <GlobalLoadingProvider>
                <ThemeProvider theme={theme}>
                  <AppPicker />
                </ThemeProvider>
              </GlobalLoadingProvider>
            </DndProvider>
          </ConfirmProvider>
        </ProvideFlashMessages>
      </UserContextProvider>
    </QueryClientProvider>
  );
}
