import { v4 as uuidv4 } from 'uuid';
import { addUuid } from '@services/recursivelyProcessObject';

/* eslint-disable no-param-reassign, array-callback-return */
export default function targetTemplatesReducer(state, action) {
  const { index } = action;
  const target = state.targetsAttributes[index];

  switch (action.actionType) {
    case 'clearValue': {
      target.value = '';
      break;
    }
    case 'removeTargetTemplate': {
      target._destroy = true;
      break;
    }
    case 'restoreTargetTemplate': {
      target._destroy = false;
      break;
    }
    case 'addMissionType': {
      const {
        tiersCount,
        missionType: {
          value,
          label,
          order,
          valuesType,
          tierParameters,
        },
      } = action;

      const tiersArray = Array.from(Array(tiersCount).keys());
      const tierParametersAttributes = tierParameters.map(({ id: tierParameterId, name, type }) => addUuid({
        name,
        type,
        tierParameterId,
      }));

      const tierTargetsAttributes = tiersArray.map((tierNumber) => addUuid({
        tier: tierNumber + 1,
        tierParametersAttributes,
      }));

      state.targetsAttributes.push(addUuid({
        allocationType: 0,
        missionTypeId: value,
        missionTypeName: label,
        missionTypeOrder: order,
        missionTypeValuesType: valuesType,
        tierTargetsAttributes,
        parametersAttributes: [],
        rulesAttributes: [],
        uuid: uuidv4(),
      }));

      break;
    }
    default: break;
  }

  return state;
}
