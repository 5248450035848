import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

import { FormGroup, useFormContext, useI18n } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import { PageSection } from '@pages/common';
import IconButton from '@controls/buttons';
import {
  Field,
  SelectField,
  localizedLabelFactory,
  ServerError,
} from '@controls/form';
import RemoveIcon from '@components/merge/shared/icons/RemoveIcon';
import ListWrapper from '@components/shared/ListWrapper';

const RemovePerkButton = styled(RemoveIcon)`
  width: auto;
  padding: 0 15px 0 0;
`;

const LocalizedLabel = localizedLabelFactory('myPetCafe.winStreaks.eventBanks.events.milestones');
const perksTranslationsPath = 'winStreaks.eventBanks.events.milestones.perks';
export default function Milestones({ milestones, eventIndex, disabled }) {
  const { dispatch } = useFormContext();
  const perkTypes = fromClassNameToOptions('MyPetCafe::WinStreaks::PerkTypes');
  const { translate } = useI18n();
  const perksEntityName = translate.fallback(`${perksTranslationsPath}.name`);

  const levelsPath = `eventsAttributes.${eventIndex}.milestonesAttributes`;

  const onEntityAdd = () => dispatch({
    actionType: 'addEmptyEntity',
    levelsPath,
    options: { perksAttributes: [] },
  });

  return (
    <div className="mb-4">
      <ListWrapper
        withReorderIconPadding
        entityAttributes={milestones}
        onEntityAdd={onEntityAdd}
        attributesName="milestonesAttributes"
        levelsPath={levelsPath}
        metaErrorsPath={['errors', 'eventsAttributes', eventIndex, 'milestonesAttributes']}
        entityNameTranslationPath="winStreaks.eventBanks.events.milestones.name"
        disabled={disabled}
        withMassOperations={false}
      >
        {({ entity, readOnly, index }) => (
          <>
            <LocalizedLabel name="goal">
              <Field type="number" name="goal" disabled={readOnly} />
            </LocalizedLabel>
            <PageSection title={`${perksEntityName}s`}>
              {entity.perksAttributes.map((perk, perkIndex) => !perk._destroy && (
                <FormGroup name={`perksAttributes.${perkIndex}`}>
                  <Row className="mb-3">
                    <Col>
                      <SelectField
                        name="perkType"
                        options={perkTypes}
                        placeholder={translate.fallback(`${perksTranslationsPath}.perkType.label`)}
                      />
                    </Col>
                    <Col>
                      <Field
                        name="subType"
                        placeholder={translate.fallback(`${perksTranslationsPath}.subType.label`)}
                      />
                    </Col>
                    <Col>
                      <Field
                        type="number"
                        step={0.1}
                        name="amount"
                        placeholder={translate.fallback(`${perksTranslationsPath}.amount.label`)}
                      />
                    </Col>
                    <RemovePerkButton
                      onClick={() => dispatch({
                        actionType: 'removeEntity',
                        levelsPath: `${levelsPath}[${index}].perksAttributes`,
                        _uuid: perk._uuid,
                      })}
                      disabled={disabled}
                    />
                  </Row>
                </FormGroup>
              ))}
              <ServerError name="perksAttributes.list" />
              <IconButton.New
                className="float-end"
                onClick={() => dispatch({
                  actionType: 'addEmptyEntity',
                  levelsPath: `${levelsPath}[${index}].perksAttributes`,
                })}
                disabled={disabled}
              >
                Add Perk
              </IconButton.New>
            </PageSection>
          </>
        )}
      </ListWrapper>
    </div>
  );
}
