import React, { useState } from 'react';

import { ServerError } from '@controls/form';
import { Field } from 'formik';

import { useFormContext } from '@hooks';

import { Td } from '../../shared/styled-components';
import CellContent from '../../shared/cells/CellContent';
import onFocus from '../../shared/services/editable-cell/onFocus';

export default function NameCell({
  path,
  value,
  setFieldValue,
  disabled,
  isNewRecord,
}) {
  const fullName = `${path}.name`;
  const { dispatch } = useFormContext();

  const [currentValue, setCurrentValue] = useState(value);
  const [editModeEnabled, setEditModeEnabled] = useState(false);

  const enableEditMode = () => !disabled && setEditModeEnabled(true);
  const disableEditMode = () => setEditModeEnabled(false);

  const saveChangedValue = (changedValue) => {
    setFieldValue(fullName, changedValue);

    if (!isNewRecord) dispatch({ type: 'processTranslationKeyNameChange', path, newValue: changedValue });

    disableEditMode();
  };

  const onKeyDown = (event) => {
    switch (event.key) {
      case 'Escape': {
        disableEditMode();

        break;
      }
      case 'Enter':
        if (event.shiftKey) break;

        event.preventDefault();
        saveChangedValue(currentValue);

        break;
      default:
    }
  };

  const onBlur = () => saveChangedValue(currentValue);

  return (
    <Td
      width="250px"
      onDoubleClick={() => enableEditMode()}
      sticky
    >
      {editModeEnabled
        ? (
          <Field
            value={currentValue}
            name={fullName}
            type="text"
            className="form-control"
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={(event) => setCurrentValue(event.target.value)}
            as={Field}
            autoFocus
          />
        ) : (
          <CellContent
            newValue={isNewRecord ? currentValue : value}
            isNewRecord={isNewRecord}
          />
        )}
      <ServerError name={fullName} />
    </Td>
  );
}
