import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { range } from 'lodash';

import APP_DATA from '@services/appData';

import {
  FormGroup, useRewardOptions, useFormContext,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import {
  Label, Field, SelectField, ServerError, CheckboxField,
} from '@controls/form';

const { modelsLocales: { spiderSolitaire: { streaks: tooltips } } } = APP_DATA;

export default function VariantForm({
  formGroupName = '', values, variantIndex,
}) {
  const { dispatch } = useFormContext();
  const rewardOptions = useRewardOptions();
  const actions = fromClassNameToOptions('Streaks::Actions');
  let durationFieldFullName = 'duration';
  if (formGroupName.length > 0) durationFieldFullName = `${formGroupName}.${durationFieldFullName}`;

  const generateDaysOptions = () => {
    if (!values.duration) return [];
    return range(2, values.duration + 1).map((n) => ({ label: n, value: n }));
  };

  const changeDuration = (event) => {
    let { value } = event.target;
    value = value && parseInt(value, 10);
    if (value && value < 2) value = 2;
    dispatch({
      actionType: 'durationChanged', duration: value, variantIndex,
    });
  };

  return (
    <div>
      <FormGroup name={formGroupName}>
        <>
          <Label text="Number of Days of the Streak" tooltipText={tooltips.duration}>
            <InputGroup>
              <Field
                type="number"
                name="duration"
                step={1}
                onChange={changeDuration}
                inline={false}
              />
              <InputGroup.Text>Days</InputGroup.Text>
            </InputGroup>
            <ServerError name={durationFieldFullName} full />
          </Label>
          <Label text="Include days from previous streak" tooltipText={tooltips.includeDaysFromPrevious}>
            <CheckboxField name="includeDaysFromPrevious" />
          </Label>
          <Label text="Rewarded Ad" tooltipText={tooltips.rewardedAd}>
            <CheckboxField name="rewardedAd" />
          </Label>
          <Label text="Max number of tries" tooltipText={tooltips.maxTries}>
            <Field type="number" name="maxTries" step={1} />
          </Label>
          <Label text="Intermittent Days' popups" tooltipText={tooltips.intermittentDaysPopups}>
            <SelectField
              name="intermittentDaysPopups"
              options={generateDaysOptions()}
              includeAll
              isMulti
            />
          </Label>
          <Label text="Streak action" tooltipText={tooltips.streakAction}>
            <SelectField name="streakAction" options={actions} />
          </Label>
          <Label text="Reward" tooltipText={tooltips.rewardId}>
            <SelectField
              name="rewardId"
              options={rewardOptions}
            />
          </Label>
        </>
      </FormGroup>
    </div>
  );
}
