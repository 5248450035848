import React from 'react';

import styled from 'styled-components';

import { Badge } from 'react-bootstrap';
import ColoredBadge from '../ColoredBadge';

const RemoveButton = styled.span`
  cursor: pointer;
  padding-left: 0.5rem;
`;

export default function FilterBadge({ value, onClick, color }) {
  const removeRutton = <RemoveButton className="align-middle" onClick={onClick}>&times;</RemoveButton>;
  return (
    color ? (
      <ColoredBadge hexColor={color} text={value} pill={false} className="p-2 m-1">
        {removeRutton}
      </ColoredBadge>
    ) : (
      <Badge bg="secondary" className="p-2 m-1">
        <span className="align-middle">{value}</span>
        {removeRutton}
      </Badge>
    )
  );
}
