import React, { useMemo } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InUseColumn,
  Filter,
} from '@pages/common';
import { useQuery } from '@hooks';
import { PaymentsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

const columnsFactory = ({ applicationId }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Store ID', accessor: 'storeId' },
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={PaymentsRoutes.StoreProducts.editPath({ id, applicationId })} readonly minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const { response } = useQuery(PaymentsRoutes.StoreProducts.indexRequest, { includeInUse: true });
  const { query } = useRouter();
  const { applicationId } = query;

  const columns = useMemo(() => columnsFactory({ applicationId }), []);

  return response && (
    <div>
      <PageHeader
        title="Store Products"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
      >
        <IconButton.New to={PaymentsRoutes.StoreProducts.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
