import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { compact, isEqual, omit } from 'lodash';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ExpandIcon } from '@components/merge/shared/icons';
import { Field } from '@controls/form';
import { FaIcon, ReactTable } from '@pages/common';
import { FormGroup, useConfirm } from '@hooks';
import IconButton from '@controls/buttons';

import AdditionalPostcardChanges from './AdditionalPostcardChanges';
import SharedPostcardFields from './SharedPostcardFields';

const EditablePostcardRow = React.memo(({
  postcard,
  index,
  isExpanded,
  toggleExpanded,
  setViewPostcard,
  dispatch,
  orderColumns,
  assetKeys,
  assetTypes,
}) => {
  const orders = postcard.storyOrdersAttributes.reduce((acc, order, orderIndex) => {
    if (order._destroy) { return acc; }

    return [...acc, { ...order, index: orderIndex }];
  }, []);
  const confirm = useConfirm();
  const onDelete = () => {
    confirm
      .showConfirmation({
        title: `Postcard ${postcard.internalId} will be removed and not available for work. Continue?`,
      }).then(() => dispatch({ actionType: 'removePostcard', _uuid: postcard._uuid }));
  };

  return (
    <FormGroup key={postcard.id} name={`postcardsAttributes[${index}]`}>
      <tr>
        <td>
          <ExpandIcon
            value={isExpanded}
            onClick={() => toggleExpanded(postcard._uuid)}
          />
        </td>
        <td>{postcard.position}</td>
        <td>
          <Field name="internalId" />
        </td>
        <td />
        <td>
          <ButtonToolbar>
            <IconButton.Edit
              onClick={() => setViewPostcard(postcard)}
              disabled={compact(postcard.storyOrdersAttributes.map((so) => so.internalId)).length === 0}
              readonly
              minimized
            />
            <IconButton.Delete onClick={onDelete} minimized />
          </ButtonToolbar>
        </td>
      </tr>
      <tr className={`collapse ${isExpanded ? 'show' : ''}`}>
        <td />
        <td colSpan="3">
          <SharedPostcardFields assetKeys={assetKeys} assetTypes={assetTypes} />
          <ReactTable
            additionalProps={{ postcardIndex: index }}
            columns={orderColumns}
            data={orders}
            disableSortBy
          />
          <FaIcon
            color="green"
            icon={faPlus}
            onClick={() => dispatch({ actionType: 'addStoryOrder', _uuid: postcard._uuid })}
            title="Add a new story order"
          />
          <AdditionalPostcardChanges postcard={postcard} index={index} dispatch={dispatch} />
        </td>
      </tr>
    </FormGroup>
  );
}, (prev, next) => {
  const irrelevantProps = ['dispatch', 'toggleExpanded', 'setViewPostcard'];
  return isEqual(omit(prev, irrelevantProps), omit(next, irrelevantProps));
});

export default EditablePostcardRow;
