import React from 'react';
import styled from 'styled-components';

const ErrorsList = styled.ol`
  color: red;
  padding-left: 2em;
`;

export default function ErrorsBlock({ errors }) {
  return (
    <>
      <hr />
      <h5>Errors</h5>
      <ErrorsList>
        {errors.map(({ message }) => (
          <li>
            <span>
              {message}
            </span>
          </li>
        ))}
      </ErrorsList>
    </>
  );
}
