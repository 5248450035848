import React, { useState, useRef, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { Button, Modal } from 'react-bootstrap';

import {
  Form, FormGroup, useCurrentApplication,
} from '@hooks';
import { generateChapterBasedRoutes } from '@pages/routes';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { ServerError } from '@controls/form';
import { isInUse } from '@pages/common/InUse';

const UploadCsvModal = ({
  show, onClose, onSubmit, onUpload, levelLayoutsFile, data,
}) => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Upload level layouts</Modal.Title>
    </Modal.Header>
    <Form initialValues={data} onSubmit={onSubmit}>
      {() => (
        <FormGroup>
          <Modal.Body>
            <input type="file" className="form-control" onChange={onUpload} accept="text/csv" />
            <ServerError name="csvLevelLayouts" multiline />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={!levelLayoutsFile}>Save</Button>
            <Button type="button" variant="secondary" onClick={onClose}>Cancel</Button>
          </Modal.Footer>
        </FormGroup>
      )}
    </Form>
  </Modal>
);

export default function CsvActions({ bank }) {
  const { currentApplication: { id: applicationId }, applicationName } = useCurrentApplication();
  const { Banks: BankRoutes, LevelLayouts: LevelLayoutRoutes } = generateChapterBasedRoutes(applicationName);
  const downloadCsvRef = useRef();

  const [downloadedLevelLayouts, setDownloadedLevelLayouts] = useState({ data: [], headers: [], loaded: false });
  const [levelLayoutsFile, setLevelLayoutsFile] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [data, setData] = useState({});

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Level layouts',
    actionName: 'upload',
    redirectPath: BankRoutes.editPath({ applicationId, id: bank.id }),
    setData,
  });

  const generateCsvFileName = () => `LevelLayoutBank_${bank.id}_${Date.now()}.csv`;

  useEffect(() => {
    if (downloadedLevelLayouts.loaded && downloadCsvRef?.current?.link) {
      downloadCsvRef.current.link.click();
    }
  }, [downloadedLevelLayouts]);

  const onDownloadLevelLayoutsCsv = () => {
    LevelLayoutRoutes.downloadCsvRequest({ applicationId, bankId: bank.id }).then((resp) => {
      setDownloadedLevelLayouts({ ...resp.data, loaded: true });
    });
  };

  const onClose = () => {
    setShowUploadModal(false);
    setLevelLayoutsFile(null);
  };

  const onUpload = (e) => {
    setLevelLayoutsFile(e.target.files[0]);
  };

  const onSubmit = () => {
    LevelLayoutRoutes.uploadCsvRequest({ applicationId, levelLayoutsFile, bankId: bank.id }).then(responseHandler);
  };

  return (
    <>
      <CSVLink
        ref={downloadCsvRef}
        headers={downloadedLevelLayouts.headers}
        data={downloadedLevelLayouts.data}
        filename={generateCsvFileName()}
      />
      <Button onClick={onDownloadLevelLayoutsCsv} className="me-2">Download as csv</Button>
      <Button onClick={() => setShowUploadModal(true)} disabled={isInUse(bank.inUse)}>Upload csv</Button>
      <UploadCsvModal
        show={showUploadModal}
        onClose={onClose}
        onSubmit={onSubmit}
        onUpload={onUpload}
        levelLayoutsFile={levelLayoutsFile}
        data={data}
      />
    </>
  );
}
