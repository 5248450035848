import React from 'react';

import { fromClassNameToOptions } from '@services/enums';
import {
  Label,
  Field,
  SelectField,
} from '@controls/form';
import { PageSection } from '@pages/common';

export default function ModesConfigsTeleportingFields({ tooltips }) {
  const teleportingTooltips = tooltips.teleporting;
  const teleportingModesOptions = fromClassNameToOptions('Tile::ModesConfigs::TeleportingModes');

  return (
    <>
      <PageSection title="Teleporting Schema" />

      <Label text="Taps to enable teleporting" tooltipText={teleportingTooltips.tapCount}>
        <Field type="number" name="tapCount" />
      </Label>

      <Label text="Teleporting mode" tooltipText={teleportingTooltips.mode}>
        <SelectField
          name="mode"
          options={teleportingModesOptions}
          hasPrepopulatedOption
        />
      </Label>
    </>
  );
}
