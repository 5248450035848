import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form, useCurrentApplication } from '@hooks';
import { PageHeader } from '@pages/common';
import { Label, Field, FormButtonsGroup } from '@controls/form';

export default function DailyChallengeConfigsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const { routingScope } = useCurrentApplication();

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit}>
      {() => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Daily Challenge Config`}>
            <FormButtonsGroup cancelButtonPath={`${routingScope}/daily_challenge_configs`} />
          </PageHeader>

          <Field type="hidden" name="applicationId" />
          <Label text="Name"><Field type="text" name="name" /></Label>
          <Label text="Table ID"><Field type="text" name="tableId" /></Label>
          <Label text="Type"><Field type="text" name="type" /></Label>
          <Label text="Environment ID"><Field type="text" name="environmentId" /></Label>
          <Label text="Unlock level"><Field type="number" name="unlockLevel" /></Label>
          <Label text="Number of decks"><Field type="number" name="numberOfDecks" /></Label>
        </>
      )}
    </Form>
  );
}
