import React from 'react';

import DuplicationModalWithWorldSchemaId from '@components/merge/shared/DuplicationModalWithWorldSchemaId';
import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import ItemConfigurationSetsIndex from '../../item-configuration-sets/Index';

export default function List(props) {
  return (
    <ItemConfigurationSetsIndex
      DuplicationModal={DuplicationModalWithWorldSchemaId}
      requestSchemaOptions={requestSchemaOptions}
      {...props}
    />
  );
}
