import React from 'react';
import { UiAssetsIndex } from '@pages/metagame/chapter-based';

export default function Index(props) {
  return (
    <UiAssetsIndex
      entityNameOverride="Figure Packs"
      recordType="figure_packs"
      {...props}
    />
  );
}
