import React from 'react';
import { Card } from 'react-bootstrap';
import {
  faSuitcase, faFileCirclePlus, faCodePullRequest, faArchive,
} from '@fortawesome/free-solid-svg-icons';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  GameSettingsRoutes, MyPetCafeRoutes, FilesRoutes, ArchivedSettingsRoutes,
} from '@pages/routes';
import IconButton from '@controls/buttons';

const buildGameSpecificActions = (gameType, applicationId) => {
  switch (gameType) {
    case 'my_pet_cafe': {
      return (
        <IconButton
          icon={faCodePullRequest}
          variant="warning"
          to={MyPetCafeRoutes.UnlockRequirements.indexPath({ applicationId })}
        >
          Unlock Requirements
        </IconButton>
      );
    }
    default: return null;
  }
};

export default function Toolbar({ applicationId, gameType, supportFiles }) {
  return (
    <Card className="mb-3">
      <Card.Body>
        <ButtonToolbar>
          <IconButton
            to={GameSettingsRoutes.basePath({ gameType, applicationId })}
            icon={faSuitcase}
            className="me-2"
            title="base_config"
          >
            Base Config
          </IconButton>
          <IconButton
            to={ArchivedSettingsRoutes.indexPath({ applicationId })}
            icon={faArchive}
            className="me-2"
            variant="warning"
          >
            Archived Settings
          </IconButton>
          {supportFiles && (
            <IconButton
              to={FilesRoutes.indexPath({ gameType, applicationId })}
              icon={faFileCirclePlus}
            >
              Files
            </IconButton>
          )}

          {buildGameSpecificActions(gameType, applicationId)}
        </ButtonToolbar>
      </Card.Body>
    </Card>
  );
}
