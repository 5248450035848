import { get } from 'lodash';

export default function reorderMeta(meta, fromIndices, toIndices, path = []) {
  if (!meta) return {};

  const result = path.reduceRight((obj, element) => ({ [element]: obj }), {});

  fromIndices.forEach((index) => {
    const newMetaValue = get(meta, path, meta)[index];
    if (!newMetaValue) return;

    get(result, path, result)[toIndices[index]] = newMetaValue;
  });

  return result;
}
