import React from 'react';

import MultipleValuesField from '@controls/form/MultipleValuesField';
import Field from '@controls/form/Field';

export default function DebugIdsRule({ disabled }) {
  return (
    <MultipleValuesField
      name="debugIds"
      disabled={disabled}
      InputComponent={Field}
    />
  );
}
