import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import {
  Form, FormGroup, AbTestingContext, useCurrentApplication,
} from '@hooks';
import {
  Label, Field, FormButtonsGroup, EntityWithExperiment, PriorityField,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { levelsReducer, rulesReducer, combineReducers } from '@reducers';

import VariantForm from './VariantForm';
import RulesForm from '../rules/Form';

const reducer = combineReducers([levelsReducer, rulesReducer]);

export default function LevelSetForm({
  data, onSubmit, actionName,
}) {
  const { query } = useRouter();

  const { currentApplication, routingScope } = useCurrentApplication();

  return (
    <AbTestingContext entityType="LevelSet" entityId={query.id}>
      <Form initialValues={{ ...data, applicationId: currentApplication.id }} onSubmit={onSubmit} reducer={reducer}>
        {({ values }) => (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Level Set`}>
              <FormButtonsGroup cancelButtonPath={`${routingScope}/level_sets`} />
            </PageHeader>

            <Field type="hidden" name="applicationId" />

            {actionName === 'Update' && <EntityWithExperiment className="mb-3" />}

            <FormGroup>
              <Label text="Name"><Field type="text" name="name" /></Label>
              <Label text="Priority">
                <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} />
              </Label>
              <VariantForm
                defaultVariant
                formGroupName="currentVariantAttributes"
                variantAttributes={values.currentVariantAttributes}
              />
            </FormGroup>

            <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} />
          </>
        )}
      </Form>
    </AbTestingContext>
  );
}
