import React from 'react';
import styled from 'styled-components';
import { useRouter } from '@tripledotstudios/react-core';

import { Badge } from 'react-bootstrap';

const StyledSpan = styled.span`
  font-size: 1.5em;
`;

export default function FilterHeader({ text, skipFiltersOnCount = [] }) {
  const { query: { filter: filtersArray } } = useRouter();

  const countFilters = () => {
    if (!filtersArray) return null;

    const appliedFilters = Object.keys(filtersArray).filter((value) => !skipFiltersOnCount.includes(value));

    return appliedFilters.length;
  };

  return (
    <>
      <StyledSpan>
        {text}
      </StyledSpan>
      {filtersArray && <Badge bg="secondary" className="ms-2">{countFilters()}</Badge>}
    </>
  );
}
