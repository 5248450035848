import React from 'react';
import { Alert } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InUseColumn,
} from '@pages/common';

import { LocalizationsRoutes } from '@pages/routes';

const columns = [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Code', accessor: 'code' },
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
];

export default function Index() {
  const { response } = useQuery(LocalizationsRoutes.Languages.indexRequest, { includeInUse: true });

  return response ? (
    <div>
      <PageHeader title="Languages" userGuideUrlKey="localizationsGuideUrl" />

      <Alert variant="info">
        <b>List of languages for which the game would like to set translations</b>
      </Alert>

      <ReactTable columns={columns} data={response.items} />
      <Pagination pageCount={response._meta.pages} />
    </div>
  ) : '';
}
