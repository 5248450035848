import React from 'react';
import styled from 'styled-components';

const Label = styled.span`
  border-bottom: 1px dotted;
  cursor: pointer;
`;

export default function InternalIdLabel({ name, onClick }) {
  return (
    <Label onClick={onClick}>
      {name}
    </Label>
  );
}
