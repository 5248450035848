import React, { useState, useEffect } from 'react';
import { useRouter, Spinner } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { FigureSetsRoutes, WoodokuRoutes as Routes } from '@pages/routes';
import { PageHeader } from '@pages/common';
import IconButton from '@controls/buttons';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import {
  Form,
  useCurrentApplication,
  useQuery,
  AbTestingContext,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';

import {
  Label,
  Field,
  SelectField,
  FormButtonsGroup,
  AvailabilityStateFormGroup,
  StaticFieldsFormGroup,
  EntityWithExperiment,
} from '@controls/form';

import VariantForm from './VariantForm';

const statuses = fromClassNameToOptions('Woodoku::BonusLevels::EventStatuses');
const bankStatuses = APP_DATA.enums['Woodoku::BonusLevels::LevelBankStatuses'];

export default function BonusLevelsEventForm() {
  const { query } = useRouter();

  const { response, setResponse, isLoading } = useQuery(Routes.BonusLevels.Events.instanceRequest, {});
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const [figureSets, setFigureSets] = useState();
  const [levelBanks, setLevelBanks] = useState();

  useEffect(() => {
    FigureSetsRoutes.Sets.indexRequest({ ...query, withoutPagination: true }).then(({ data }) => setFigureSets(
      data.items.map(({ name, id }) => ({ label: name, value: id })),
    ));
    Routes.BonusLevels.LevelBanks.indexRequest({
      ...query,
      withoutPagination: true,
      filter: { statusIn: [bankStatuses.ACTIVE] },
    }).then(({ data }) => setLevelBanks(
      data.items.map(({ name, id }) => ({ label: name, value: id })),
    ));
  }, []);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Bonus Levels Event',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    Routes.BonusLevels.Events.updateRequest({ applicationId, ...values }).then(responseHandler)
  );

  if (!response || isLoading) return <Spinner />;

  const { id } = response;

  return (
    <AbTestingContext
      entityType="Woodoku::BonusLevels::Event"
      entityId={id}
    >
      <Form initialValues={response} onSubmit={onSubmit}>
        {() => (
          <>
            <PageHeader title={`${id ? 'Edit' : 'Create'} Bonus Level Event`}>
              <FormButtonsGroup>
                {id && (
                  <>
                    <IconButton.ActivityLog
                      applicationId={applicationId}
                      id={id}
                      entityType="Woodoku::BonusLevels::Event"
                    />
                    <FormButtonsGroup.Divider />
                  </>
                )}
              </FormButtonsGroup>
            </PageHeader>

            {id && <EntityWithExperiment />}

            <Label required text="Name">
              <Field type="text" name="name" />
            </Label>
            <Label required text="Status">
              <SelectField name="status" options={statuses} />
            </Label>
            <AvailabilityStateFormGroup />
            <StaticFieldsFormGroup data={response} />

            <Label text="Minimum supported version">
              <Field name="minimumSupportedVersion" />
            </Label>

            <VariantForm currentVariant figureSets={figureSets} levelBanks={levelBanks} />
          </>
        )}
      </Form>
    </AbTestingContext>
  );
}
