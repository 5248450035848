import React from 'react';

import {
  useFormContext,
  FormGroup,
  useRewardOptions,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import { Rewards, PageSection } from '@pages/common';
import {
  Field,
  SelectField,
  ServerError,
  localizedLabelFactory,
} from '@controls/form';

import IconButton from '@controls/buttons';

const calculatePositionsMapping = ({ milestones }) => {
  let position = 0;

  return milestones.map(({ _destroy }) => {
    if (_destroy) return null;

    position += 1;
    return position;
  });
};

const LocalizedLabel = localizedLabelFactory('myPetCafe.adventures.banks.events.milestones');

export default function Milestones({ milestones, eventIndex, readOnly }) {
  const { dispatch } = useFormContext();
  const rewardOptions = useRewardOptions();
  const boxTypes = fromClassNameToOptions('MyPetCafe::Adventures::Milestones::BoxTypes');

  const onMilestoneAdd = () => dispatch({ actionType: 'addMilestone', eventIndex });
  const onMilestoneRemove = ({ milestoneIndex }) => dispatch({
    actionType: 'removeMilestone',
    eventIndex,
    milestoneIndex,
  });

  const positionsMapping = calculatePositionsMapping({ milestones });

  return (
    <>
      <PageSection title="Milestones" />
      <ServerError name="milestonesAttributes.list" />
      {milestones.map((milestone, index) => !milestone._destroy && (
        <PageSection
          title={`#${positionsMapping[index]}`}
          actions={(
            <IconButton.Delete
              className="float-end"
              onClick={() => onMilestoneRemove({ milestoneIndex: index })}
              disabled={readOnly}
              minimized
            >
              Remove Milestone
            </IconButton.Delete>
          )}
          key={milestone._uuid}
          className="mb-3"
        >
          <FormGroup name={`milestonesAttributes.${index}`}>
            <LocalizedLabel name="goal">
              <Field type="number" name="goal" disabled={readOnly} />
            </LocalizedLabel>
            <LocalizedLabel name="boxType">
              <SelectField
                name="boxType"
                options={boxTypes}
                isDisabled={readOnly}
              />
            </LocalizedLabel>
            <PageSection title="Rewards" />
            <Rewards
              hideQuantityUntilSelected
              dispatch={dispatch}
              rewardable={milestone}
              rewardItems={rewardOptions}
              readOnly={readOnly}
            />
            <ServerError name="rewardAffiliationsAttributes.list" />
          </FormGroup>
        </PageSection>
      ))}
      <IconButton.New onClick={onMilestoneAdd} disabled={readOnly}>
        Add Milestone
      </IconButton.New>
    </>
  );
}
