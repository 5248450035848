import React from 'react';
import RectangleBoard from './Rectangle';

export default function WoodokuBoardGrid6x9({
  values,
  tileTypeOptions,
  levelIndex,
  onChange,
  errors,
  readOnly,
  occupiedCells,
  massSelectedCellIds,
}) {
  return (
    <RectangleBoard
      width={6}
      height={9}
      values={values}
      tileTypeOptions={tileTypeOptions}
      levelIndex={levelIndex}
      onChange={onChange}
      errors={errors}
      readOnly={readOnly}
      verticalBordersAt={[3]}
      horizontalBordersAt={[3, 6]}
      className="w-60"
      occupiedCells={occupiedCells}
      massSelectedCellIds={massSelectedCellIds}
    />
  );
}
