import React from 'react';
import { PageTitle } from '@pages/common';

import PlayerSegmentsIndex from '@pages/player-segments/Index';

import { PlayerSegmentRoutes as Routes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function playerSegments() {
  return [
    <Route
      key={Routes.indexRawPath}
      path={Routes.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Player Segments" />
          <PlayerSegmentsIndex />
        </RouteContainer>
      )}
    />,
  ];
}
