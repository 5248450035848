import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import styled from 'styled-components';
import {
  camelCase, clone, differenceWith, findIndex, get, isArray, isEmpty, isEqual, isPlainObject, map, mapValues, omit,
  pickBy, reduce, set, sortBy, toPairs, uniq,
} from 'lodash';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { snakeCase } from '@tripledotstudios/react-core';

import RawVersionToggle from '@pages/audit-logs/RawVersionToggle';
import { AuditRoutes } from '@pages/routes';
import { FaButton, FaIcon } from '@pages/common';
import wrapArray from '@services/wrapArray';
import { DIRECTIONS, EVENTS } from './constants';

const Header = styled.div`
  text-align: center;
`;

const ViewContainer = styled.div`
  padding-left: 10px;
`;

const Value = styled.span`
  word-break: break-all;
`;

const colorsMapping = (theme) => ({
  [EVENTS.CREATE]: theme.variants.success,
  [EVENTS.DESTROY]: theme.variants.danger,
  [EVENTS.UPDATE]: theme.variants.warning,
});

const ColoredDiv = styled.div`
  color: ${({ event, theme }) => colorsMapping(theme)[event]};
`;

const displayValue = (value) => {
  try {
    // converts weird cases like "[{\"key\":\"12\",\"value\":45}]"
    if (typeof value === 'string') { return JSON.parse(value); }

    return value;
  } catch (e) {
    return value;
  }
};

const deepDifference = (first, second) => differenceWith(first, second, isEqual);

const deepFindIndex = (array, element) => findIndex(array, (e) => isEqual(element, e));

const Entry = ({ event, fieldName, value }) => (
  <ColoredDiv event={event}>
    <span>{`${fieldName}: `}</span>
    <Value>{JSON.stringify(value)}</Value>
  </ColoredDiv>
);

const CollapsibleNode = ({
  children, collapseId, event, name,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ColoredDiv event={event}>
        {name}
        <FaButton onClick={() => setOpen(!open)}>
          <FaIcon icon={open ? faChevronDown : faChevronRight} />
        </FaButton>
      </ColoredDiv>
      <Collapse in={open}>
        <ViewContainer id={collapseId}>
          {open && children}
        </ViewContainer>
      </Collapse>
    </>
  );
};

const sortMapAttributes = (attributes, iteratee) => map(
  sortBy(
    toPairs(attributes),
    (pair) => pair[0],
  ), (pair) => iteratee(pair[1], pair[0]),
);

const Record = ({
  disableHighlight, direction, event, fullData, loadAssociation, path, record, versionTree,
}) => {
  const { attributes, trackedAssociations } = record;
  const newAttributes = omit(mapValues(attributes, displayValue), map(Object.keys(trackedAssociations), camelCase));
  const complex = pickBy(newAttributes, (value) => isPlainObject(value) || isArray(value));
  const plain = omit(newAttributes, Object.keys(complex));
  const highlight = (key) => {
    if (!event || !versionTree || disableHighlight) return null;

    return event === EVENTS.DESTROY
      || event === EVENTS.CREATE
      || Object.keys(get(versionTree, 'version.objectChanges', [])).indexOf(key) > -1
      ? event
      : null;
  };
  return [
    ...sortMapAttributes(plain, (value, key) => (
      <Entry key={key} fieldName={key} value={value} event={highlight(key)} />
    )),
    ...sortMapAttributes(complex, (value, key) => {
      if (isEmpty(value) || !value) return <Entry value="none" fieldName={key} event={highlight(key)} />;
      if (isArray(value)) {
        let arrayEvent = null;
        let changes = [];
        const diff = versionTree ? map(versionTree.version.objectChanges[key], displayValue) : [];
        if (versionTree && event === EVENTS.UPDATE) {
          if (direction === DIRECTIONS.BEFORE) {
            arrayEvent = EVENTS.DESTROY;
            changes = deepDifference(diff[0], diff[1]);
          } else {
            arrayEvent = EVENTS.CREATE;
            changes = deepDifference(diff[1], diff[0]);
          }
        }
        return (
          <CollapsibleNode key={key} name={key} collapseId={path + key} event={highlight(key)}>
            {map(value, (item, index) => {
              let elementEvent;
              if (event === EVENTS.CREATE || event === EVENTS.DESTROY) {
                elementEvent = event;
              } else {
                // if element is removed or created
                elementEvent = deepFindIndex(changes, item) > -1 ? arrayEvent : null;
                // if index changed
                if (!elementEvent && !isEmpty(diff)) {
                  const beforeItemIndex = deepFindIndex(diff[0], item);
                  const afterItemIndex = deepFindIndex(diff[1], item);
                  if (beforeItemIndex !== afterItemIndex) {
                    elementEvent = EVENTS.UPDATE;
                  }
                }
              }
              if (disableHighlight) { elementEvent = null; }
              return (
                isPlainObject(item) && !isEmpty(item) ? (
                  <CollapsibleNode key={index} name={index} collapseId={path + key} event={elementEvent}>
                    <Record
                      record={{ attributes: omit(item, 'uuid'), trackedAssociations: {} }}
                      disableHighlight={disableHighlight}
                      event={elementEvent}
                      path={`${path}[${index}]`}
                      versionTree={{ version: { objectChanges: {} } }}
                    />
                  </CollapsibleNode>
                ) : <ColoredDiv event={elementEvent} key={item}>{item}</ColoredDiv>
              );
            })}
          </CollapsibleNode>
        );
      }
      return !isEmpty(value) ? (
        <CollapsibleNode key={key} name={key} collapseId={path + key} event={highlight(key)}>
          <Record
            record={{ attributes: value, trackedAssociations: {} }}
            disableHighlight={disableHighlight}
            direction={direction}
            path={`${path}.${key}`}
            event={event}
            versionTree={versionTree}
          />
        </CollapsibleNode>
      ) : 'none';
    }),
    ...reduce(trackedAssociations, (renderedAssociations, association, foreignKey) => {
      let associationEvent = null;
      if (event === EVENTS.UPDATE && (highlight(camelCase(foreignKey)))) { associationEvent = EVENTS.UPDATE; }
      const associationVersions = get(versionTree, `associations[${camelCase(association.name)}]`);
      if (associationVersions) {
        const events = uniq(map(wrapArray(associationVersions), (subTree) => subTree.version.event));
        associationEvent = events.length > 1 ? EVENTS.UPDATE : events[0] || EVENTS.UPDATE;
      }
      if (association.belongsTo && !attributes[foreignKey]) { return renderedAssociations; }
      if (disableHighlight) { associationEvent = null; }

      return [
        ...renderedAssociations, (
          <CollapsibleNode
            key={association.name}
            name={association.name}
            collapseId={record.itemType + attributes.id}
            event={associationEvent}
          >
            <Tree
              associationName={camelCase(association.name)}
              disableHighlight={disableHighlight}
              direction={direction}
              fullData={fullData}
              itemId={attributes.id}
              itemType={record.itemType}
              loadAssociation={loadAssociation}
              path={path}
              versionTree={versionTree}
            />
          </CollapsibleNode>
        ),
      ];
    }, []),
  ];
};

const Tree = ({
  associationName, disableHighlight, direction, fullData, itemType, itemId, loadAssociation, path, versionTree,
}) => {
  const basePath = path.length ? `${path}.${associationName}` : associationName;
  useEffect(() => {
    loadAssociation({
      associationName: snakeCase(associationName), itemType, itemId, path: basePath,
    });
  }, []);
  const data = get(fullData, basePath);
  if (!data) return null;
  const commonProps = { fullData, loadAssociation };

  const subTree = (record) => (
    versionTree
      ? wrapArray(versionTree.associations[associationName] || []).find(
        (assoc) => record.attributes.id === assoc.version.itemId && record.itemType === assoc.version.itemSubtype,
      ) : null
  );

  const highlight = (record) => {
    if (!versionTree || disableHighlight) return null;

    const association = subTree(record);
    if (!association) return null;

    const { version } = association;
    if (!version) return null;

    return version.event;
  };

  if (isEmpty(data.records) && !data.record) return 'none';

  return (
    <ViewContainer>
      {isArray(data.records) ? sortMapAttributes(
        reduce(
          data.records,
          (accum, record) => {
            let entryName = `${record.itemType} #${record.attributes.id}`;
            if (record.displayedName) { entryName += ` ${record.displayedName}`; }
            return { ...accum, [entryName]: record };
          },
          {},
        ),
        (record, entryName) => (
          <CollapsibleNode
            key={record.itemType + record.attributes.id}
            collapseId={path + record.itemType + record.attributes.id}
            event={highlight(record)}
            name={entryName}
          >
            <Record
              record={record}
              disableHighlight={disableHighlight}
              direction={direction}
              event={highlight(record)}
              path={`${basePath}[${record.attributes.id}]`}
              versionTree={subTree(record)}
              {...commonProps}
            />
          </CollapsibleNode>
        ),
      ) : data.record && (
        <Record
          record={data.record}
          disableHighlight={disableHighlight}
          direction={direction}
          event={highlight(data.record)}
          path={basePath}
          versionTree={subTree(data.record)}
          {...commonProps}
        />
      )}
    </ViewContainer>
  );
};

export default function TreePanel({
  direction, title, versionTree, associationName, disableHighlight,
}) {
  const [fullData, setFullData] = useState({});
  const setData = (data, path) => {
    const newData = clone(fullData);
    set(newData, path, data);
    setFullData(newData);
  };
  const { version } = versionTree;
  const { versionAt, transactionId } = version;
  const loadAssociation = ({
    // eslint-disable-next-line no-shadow
    associationName, itemId, itemType, path,
  }) => AuditRoutes.Versions.associationRequest({
    associationName: snakeCase(associationName), direction, itemId, itemType, versionAt, transactionId,
  }, { skipUuids: true }).then((response) => setData(response.data, path));
  return (
    <>
      <Header>{title}</Header>
      <ViewContainer>
        <RawVersionToggle version={versionTree}>
          <Tree
            associationName={camelCase(associationName)}
            disableHighlight={disableHighlight}
            direction={direction}
            fullData={fullData}
            itemType={version.itemSubtype}
            itemId={version.itemId}
            loadAssociation={loadAssociation}
            path=""
            setData={setData}
            versionTree={versionTree}
          />
        </RawVersionToggle>
      </ViewContainer>
    </>
  );
}
