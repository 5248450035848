import { OverridableSettingsTabs as GameSettingsTabs } from '@components/overridable-settings';
import { GameSettingsRoutes } from '@pages/routes';

import Resource from './Resource';

import {
  adsControlDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  adRequestDelayFieldNames,
  appEventsFields,
  appEventsFieldNames,
  liveopsDefaultFieldNames,
  localizationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  analyticsDefaultFieldNames,
  zendeskDefaultFieldNames,
  zendeskFields,
  offlineGamesSettingsDefaultFieldNames,
  offlineAdsFields,
  offlineAdsFieldNames,
  offlineGamesSettingsFields,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
} from './Shared';

const Base = (props) => Resource({
  isBase: true,
  onInitialLoad: GameSettingsRoutes.baseRequest,
  onSubmit: GameSettingsRoutes.updateRequest,
  ...props,
});

const New = (props) => Resource({
  isBase: false,
  onInitialLoad: GameSettingsRoutes.newRequest,
  onSubmit: GameSettingsRoutes.createRequest,
  ...props,
});

const Edit = (props) => Resource({
  isBase: false,
  onInitialLoad: GameSettingsRoutes.editRequest,
  onSubmit: GameSettingsRoutes.updateRequest,
  ...props,
});

export {
  Base,
  New,
  Edit,
  GameSettingsTabs,
  adsControlDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  adRequestDelayFieldNames,
  appEventsFields,
  popupsDefaultArchivedFieldNames,
  appEventsFieldNames,
  liveopsDefaultFieldNames,
  localizationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  popupsDefaultFieldNames,
  analyticsDefaultFieldNames,
  zendeskDefaultFieldNames,
  zendeskFields,
  offlineGamesSettingsDefaultFieldNames,
  offlineGamesSettingsFields,
  offlineAdsFields,
  offlineAdsFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
};
