import React from 'react';
import { useRouter, Spinner } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { FigureSetsRoutes } from '@pages/routes';

import Form from './Form';

export default function FigureSetsShapesNew() {
  const { response, setResponse, isLoading } = useQuery(FigureSetsRoutes.Shapes.newRequest);
  const { query: { applicationId } } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Figure Sets Shape',
    actionName: 'creation',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    FigureSetsRoutes.Shapes.createRequest({ ...values, applicationId }).then(responseHandler)
  );

  if (isLoading) return <Spinner />;

  return response && (
    <Form
      applicationId={applicationId}
      data={response}
      onSubmit={onSubmit}
    />
  );
}
