import React, { useMemo } from 'react';
import { ButtonToolbar, useRouter, Spinner } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import {
  PageHeader,
  Pagination,
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
  ReactTable,
} from '@pages/common';
import { FigureSetsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';
import Filter from './Filter';

import Board from './Board';

const columnsFactory = ({ applicationId }) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  InLiveColumn,
  InUseColumn,
  {
    Header: 'Preview',
    Cell: ({ row: { original: { figureConfig, width, height } } }) => (
      <Board
        clickable={false}
        width={width}
        height={height}
        maxWidth={(width + height) * 8}
        value={figureConfig}
        outline={false}
      />
    ),
  },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, predefined } } }) => {
      const ButtonComponent = predefined ? IconButton.View : IconButton.Edit;

      return (
        <ButtonToolbar>
          <ButtonComponent to={FigureSetsRoutes.Shapes.editPath({ id, applicationId })} minimized />
        </ButtonToolbar>
      );
    },
  },
];

export default function FigureSetShapesIndex() {
  const { response, isLoading } = useQuery(FigureSetsRoutes.Shapes.indexRequest, { includeInUse: true });
  const { query: { applicationId } } = useRouter();

  const columns = useMemo(() => columnsFactory({ applicationId }), []);

  return response && (
    <div>
      <PageHeader
        title="Figure Set Shapes"
        filter={<Filter />}
      >
        <IconButton.New to={FigureSetsRoutes.Shapes.newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? <Spinner /> : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
            defaultSort={{ id: 'createdAt', desc: true }}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </div>
  );
}
