import { addUuid } from '@services/recursivelyProcessObject';

/* eslint-disable no-param-reassign, array-callback-return */
export default function missionTemplateRewardsReducer(state, action) {
  const {
    name,
    missionIndex,
    rewardIndex,
    actionType,
  } = action;

  switch (actionType) {
    case 'addMissionTemplateReward': {
      state.missionsAttributes[missionIndex][name].push(addUuid({
        id: undefined,
        selectionType: 0,
        rewardTypeIds: [],
        _destroy: false,
      }));
      break;
    }
    case 'removeMissionTemplateReward': {
      const missionTemplateReward = state.missionsAttributes[missionIndex][name][rewardIndex];

      if (missionTemplateReward.id) {
        missionTemplateReward._destroy = true;
      } else {
        state.missionsAttributes[missionIndex][name].splice(rewardIndex, 1);
      }

      break;
    }
    case 'clearRewardTypes': {
      state.missionsAttributes[missionIndex][name][rewardIndex].rewardTypeIds = [];
      break;
    }
    default: break;
  }

  return state;
}
