import React from 'react';

import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { RulesList, PriorityColumn } from '@pages/common';

const columnsFactory = ({ routes, bankEntityName, onOverrideRemove }) => [
  {
    Header: 'Name',
    Cell: ({ row }) => {
      const { id, name, applicationId } = row.original;

      return (
        <Link target="_blank" to={routes.editPath({ id, applicationId })}>
          {name}
        </Link>
      );
    },
  },
  {
    Header: `Number of ${bankEntityName}s`,
    Cell: ({ row }) => row.original.entitiesCount,
  },
  {
    Header: `Rewarded ${bankEntityName} numbers`,
    Cell: ({ row }) => row.original.rewardedEntitiesNumbers.join(', '),
  },
  {
    Header: 'Rules',
    Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
  },
  PriorityColumn,
  {
    Header: 'Actions',
    Cell: ({ row }) => {
      const { id, name } = row.original;

      return <Button variant="danger" size="sm" onClick={() => onOverrideRemove(id, name)}>Remove</Button>;
    },
  },
];

export default columnsFactory;
