import React from 'react';
import {
  min, max, lastIndexOf, findIndex, findLastIndex,
} from 'lodash';

import { Form, FormGroup } from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup, ServerError,
} from '@controls/form';
import { PageHeader, Alert } from '@pages/common';
import IconButton from '@controls/buttons';
import { FigureSetsRoutes } from '@pages/routes';

import reducer from './reducer';
import Board from './Board';

export default function FigureSetsShapesForm({ applicationId, data, onSubmit }) {
  const { predefined, _meta = {} } = data;
  const { errors = {} } = _meta;

  const isPersisted = data.id;
  const isEditable = !predefined;

  const postprocessedSubmit = (values) => {
    const { coordinates } = values;

    if (!coordinates) return onSubmit(values);

    const [
      firstRow,
      lastRow,
    ] = ([
      findIndex(coordinates, (row) => row.indexOf('1') !== -1),
      findLastIndex(coordinates, (row) => row.indexOf('1') !== -1),
    ]);

    const normalizedRows = coordinates.slice(firstRow, lastRow + 1);

    const minBlockIndex = min(normalizedRows.map((row) => row.indexOf('1')).filter((value) => value !== -1));
    const maxBlockIndex = max(normalizedRows.map((row) => lastIndexOf(row, '1')));

    const figureConfig = normalizedRows.map((row) => row.slice(minBlockIndex, maxBlockIndex + 1));

    if (!figureConfig.length) return onSubmit({ ...values, figureConfig: '' });

    const height = normalizedRows.length;
    const width = figureConfig[0].length;

    return onSubmit({
      ...values,
      height,
      width,
      figureConfig: figureConfig.map((row) => row.join('')).join('\n'),
    });
  };

  const editPageTitle = isEditable ? 'Edit' : 'View';

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={postprocessedSubmit} reducer={reducer}>
      {({ values: { figureConfig, coordinates }, dispatch }) => (
        <>
          <PageHeader title={`${isPersisted ? editPageTitle : 'New'} Figure Set Shape`}>
            <FormButtonsGroup
              disableSubmit={!isEditable}
              cancelButtonPath={FigureSetsRoutes.Shapes.indexPath({ applicationId })}
            />
          </PageHeader>

          <FormGroup>
            <Label text="Name">
              <Field type="text" name="name" disabled={isPersisted} />
            </Label>

            <StaticFieldsFormGroup data={data} />

            {!isPersisted && (
              <>
                <Alert variant="info">
                  Please note, once you create a shape, there will be no possibility to delete it or change the name of
                  the shape. After creation, you will still be able to edit all shapes except shapes from A to Q
                </Alert>
                <Alert variant="info">
                  Note: a shape can still be generated if it is the same as another shape but rotated.
                  If you aren’t sure whether the shape exists already, please double check.
                </Alert>
              </>
            )}

            <div>
              <ServerError as={Alert} name="figureConfig" />

              {(isEditable && !isPersisted) && (
                <IconButton.Cancel
                  variant="danger"
                  className="float-end"
                  onClick={() => dispatch({ type: 'resetBoard' })}
                  disabled={!(coordinates && coordinates.length) && !/[1]/.test(figureConfig)}
                >
                  Reset
                </IconButton.Cancel>
              )}
              <Board
                width={9}
                height={9}
                currentCoordinates={coordinates}
                value={figureConfig}
                onUpdate={(value) => dispatch({ type: 'updateBoardValue', value })}
                errors={errors.figureConfig}
                clickable={isEditable || !isPersisted}
              />
            </div>
          </FormGroup>
        </>
      )}
    </Form>
  );
}
