import { set } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';
import newRulesSection from '@services/rules/newRulesSection';

export default function formReducer(state, action) {
  switch (action.actionType) {
    case 'removeBundle': {
      const { index } = action;

      state.bundlesAttributes[index]._destroy = true;
      break;
    }
    case 'addNewBundle': {
      state.bundlesAttributes.push(addUuid({
        _destroy: true,
        name: null,
        id: null,
        removable: true,
        ruleSectionsAttributes: [newRulesSection()],
      }));
      break;
    }
    case 'saveBundle': {
      const { index } = action;

      state.bundlesAttributes[index]._destroy = false;
      set(state.meta, `errors.bundlesAttributes[${index}]`, {});
      break;
    }
    case 'setBundleErrors': {
      const { errors, index } = action;

      set(state.meta, `errors.bundlesAttributes[${index}]`, errors);
      break;
    }
    default: break;
  }
  return state;
}
