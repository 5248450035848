import React, { useCallback } from 'react';
import { get } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { combineReducers } from '@reducers';
import rewardsReducer from '@components/merge/reducers/rewardsReducer';
import targetsReducer from '@components/merge/reducers/targetsReducer';
import { MergeRoutes } from '@pages/routes';

import formReducer from './formReducer';
import Experts from './form/Experts';

export default function ExpertSetsForm({ data, onSubmit, actionName }) {
  const { query: { applicationId } } = useRouter();
  const errors = get(data, '_meta.errors', {});
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(applicationId), []);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([formReducer, rewardsReducer, targetsReducer])}
    >
      {({ values, dispatch }) => {
        const populateExperts = async (schemaId) => {
          const response = await MergeRoutes.ExpertSets.buildExpertSetExpertsRequest({
            applicationId,
            schemaId,
          });
          dispatch({ type: 'populate_experts', expertSetExpertsAttributes: response.data.expertSetExpertsAttributes });
        };

        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Experts Set`}>
              <FormButtonsGroup
                cancelButtonPath={MergeRoutes.ExpertSets.indexPath({ applicationId })}
              />
            </PageHeader>

            <Label text="Name"><Field type="text" name="name" /></Label>
            <Label text="Description"><Field type="text" name="description" /></Label>

            <Label text="World Schema">
              <SelectField
                name="schemaId"
                options={fetchSchemaOptions}
                onChange={(e) => populateExperts(e.value)}
                isDisabled={actionName !== 'Create'}
              />
            </Label>

            <StaticFieldsFormGroup data={data} />

            {values.expertSetExpertsAttributes && values.expertSetExpertsAttributes.length > 0 && values.schemaId
              ? <Experts values={values.expertSetExpertsAttributes} schemaId={values.schemaId} errors={errors} />
              : ''}
          </>
        );
      }}
    </Form>
  );
}
