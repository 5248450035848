import { useCurrentApplication } from '@root/hooks';
import { modelPaths } from '@root/pages/routes';

const buildQuickLink = (routes, applicationId) => (selectedValue) => (
  routes.editPath({ id: selectedValue, applicationId })
);

const buildModelBasedQuickLink = (model) => buildQuickLink(modelPaths[model], useCurrentApplication().applicationId);

export { buildQuickLink, buildModelBasedQuickLink };
