import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { FormGroup, useFormContext } from '@hooks';
import { Field } from '@controls/form';
import { PageSection } from '@pages/common';

export default function RewardValues({ data }) {
  const { dispatch } = useFormContext();

  const onMultiplierChanged = (value, rewardTypeId, rewardValueIndex) => {
    dispatch({
      actionType: 'recalculateEveryRewardAmounts',
      value,
      rewardTypeId,
      rewardValueIndex,
    });
  };

  return (
    <>
      <PageSection title="Reward values" />
      <hr />
      <Row className="ps-3 mb-3">
        <Col xs={4}><b>Reward Type</b></Col>
        <Col xs={6}><b>Reward Multiplier</b></Col>
      </Row>
      {data.map(({
        rewardTypeId, rewardName, rewardTypeName, _uuid,
      }, index) => (
        <FormGroup key={_uuid} name={`rewardValuesAttributes.${index}`}>
          <Row className="ps-3 mb-3 align-items-center">
            <Col xs={4}>{`${rewardName} (${rewardTypeName})`}</Col>
            <Col xs={6}>
              <Field
                type="number"
                name="multiplier"
                onChange={(event) => onMultiplierChanged(event.target.value, rewardTypeId, index)}
              />
            </Col>
          </Row>
        </FormGroup>
      ))}
    </>
  );
}
