import React from 'react';

import { Row, Col } from 'react-bootstrap';

import { FormGroup, useFormContext } from '@hooks';
import {
  SelectField, Field, Label, ServerError,
} from '@controls/form';

import { PageSection } from '@pages/common';
import IconButton from '@controls/buttons';
import { persistedCollection, persistedMap } from '@services/utils';

import { tileTypeProbabilityOptions } from '../utils';

export default function Generation({
  index, tileProbabilitiesAttributes, tileTypeOptions, onConfigItemRemove, levelsPath, readOnly,
}) {
  const { dispatch } = useFormContext();

  const notDestroyedTileProbabilities = persistedCollection(tileProbabilitiesAttributes);
  const addedTileProbabilityIds = persistedMap(tileProbabilitiesAttributes, ({ tileId }) => tileId);

  const onTileProbabilityAdd = () => dispatch({
    actionType: 'addLevelTileProbability',
    index,
    levelsPath,
  });

  return (
    <PageSection title="Tile Generation">
      <Label
        text="Non-standard tiles per bank"
        tooltipText={`
          Client will use this value as a parameter to calculate how many non-standard
          tiles will be included in a figure bank
        `}
      >
        <Field name="nonStandardTilesPerBank" step="0.1" type="number" disabled={readOnly} />
      </Label>
      <Label
        text="Multiplier on each loss"
        tooltipText={`
          In levels with score goals, client will use this multiplier to
          increase the score awarded with each move
        `}
      >
        <Field name="eachLossMultiplier" step="0.1" type="number" disabled={readOnly} />
      </Label>

      <h5>Tile probabilities</h5>
      {notDestroyedTileProbabilities.length > 0 && (
        <div>
          <Row className="mt-2 mb-2">
            <Col xs={5}>
              <strong>Tile type</strong>
            </Col>
            <Col xs={5}>
              <strong>Weight</strong>
            </Col>
            <Col xs={2} />
          </Row>

          {tileProbabilitiesAttributes.map(({ tileId, _uuid, _destroy }, probabilityIndex) => (
            !_destroy && (
              <FormGroup key={_uuid} name={`tileProbabilitiesAttributes[${probabilityIndex}]`}>
                <Row className="mb-2">
                  <Field name="id" type="hidden" disabled={readOnly} />
                  <Col xs={5}>
                    <SelectField
                      options={tileTypeProbabilityOptions(tileTypeOptions, addedTileProbabilityIds, tileId)}
                      name="tileId"
                      isDisabled={readOnly}
                    />
                  </Col>
                  <Col xs={5}>
                    <Field type="number" name="weight" disabled={readOnly} />
                  </Col>
                  <Col xs={2}>
                    <IconButton.Delete
                      minimized
                      onClick={() => onConfigItemRemove('tileProbabilitiesAttributes', _uuid)}
                      disabled={readOnly}
                    />
                  </Col>
                </Row>
              </FormGroup>
            )
          ))}
        </div>
      )}
      <ServerError name="tileProbabilitiesAttributes.common" />
      <IconButton.New
        minimized
        onClick={onTileProbabilityAdd}
        disabled={readOnly}
        title="Add new Tile Probability"
      />
    </PageSection>
  );
}
