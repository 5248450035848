import React from 'react';

import { Label, CheckboxField } from '@controls/form';

export default function WoodokuTesterSettingsFields() {
  return (
    <>
      <Label text="Allow edit Journey Level Banks in use">
        <CheckboxField name="editInUseJourneyLevelBanks" />
      </Label>
      <Label text="Allow edit DC Level Banks in use">
        <CheckboxField name="editInUseDcLevelBanks" />
      </Label>
      <Label text="Allow edit Figure Sets in use">
        <CheckboxField name="editInUseFigureSets" />
      </Label>
    </>
  );
}
