import React from 'react';

import InLiveFormGroup from './InLiveFormGroup';
import InUseFormGroup from './InUseFormGroup';
import FormTrails from './FormTrails';

function StaticFieldsFormGroup({ data, labelSize = 4 }) {
  const { inLive, inUse } = data;

  return (
    <>
      <InLiveFormGroup inLive={inLive} labelSize={labelSize} />
      <InUseFormGroup inUse={inUse} labelSize={labelSize} />
      <FormTrails data={data} labelSize={labelSize} />
    </>
  );
}

export default React.memo(StaticFieldsFormGroup);
