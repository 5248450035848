import React, { useState } from 'react';
import { useFlashMessages, useRouter } from '@tripledotstudios/react-core';

import { LocalizationsRoutes } from '@pages/routes';
import { addUuids } from '@services/recursivelyProcessObject';
import IconButton from '@controls/buttons';

import Modal from './upload-csv/Modal';

export default function UploadCSV({ dispatch, isTableChanged }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState();
  const { success, error } = useFlashMessages();
  const { query } = useRouter();

  const hideModal = () => {
    setShowModal(false);
    setErrors(null);
  };

  const processResponse = ({ status, data }) => {
    switch (status) {
      case 200:
        success('Upload has been successfully finished');
        dispatch({
          type: 'addTranslationKeyFromCSV',
          data: addUuids(data),
        });
        hideModal();
        break;
      case 422:
        setErrors(data);
        error('Upload has been failed');
        break;
      default:
        error('Something went wrong');
    }
  };

  const onUpload = (values) => {
    setIsLoading(true);

    LocalizationsRoutes
      .Translations
      .validateCsvRequest({ ...values, applicationId: query.applicationId })
      .then(processResponse)
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <IconButton.Upload
        disabled={isTableChanged}
        onClick={() => setShowModal(true)}
      >
        Upload CSV
      </IconButton.Upload>

      <Modal
        showModal={showModal}
        isLoading={isLoading}
        errors={errors}
        onHide={hideModal}
        onSubmit={onUpload}
      />
    </>
  );
}
