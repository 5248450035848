import React from 'react';
import { camelCase } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useI18n } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { MyPetCafeRoutes } from '@pages/routes';

import Form from './Form';

export default function CreateUpdateConfig({
  fetchFunction,
  saveFunction,
  actionName,
  disableTypeSelect,
  configTypes,
  group,
}) {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse } = useQuery(fetchFunction);
  const { translate } = useI18n();

  const humanizedGroupName = translate.strict(`jsonConfigs.groupNames.${camelCase(group)}`);

  const collectionUrl = `${MyPetCafeRoutes.JsonConfigs.indexPath({ applicationId })}/${group}`;

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'JSON config',
    setData: setResponse,
    actionName,
    collectionUrl,
  });

  const onSubmit = (values) => saveFunction(values).then(responseHandler);

  return response && (
    <Form
      data={response}
      cancelButtonPath={collectionUrl}
      onSubmit={onSubmit}
      configTypes={configTypes}
      disableTypeSelect={disableTypeSelect}
      humanizedGroupName={humanizedGroupName}
    />
  );
}
