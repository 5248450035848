import React, { useState, useMemo } from 'react';

import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import {
  useQuery,
  useI18n,
  useConfirm,
} from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  DuplicationModal,
  LabelsColumn,
  InUseColumn,
  StatusBadge,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { WoodokuRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';

const { enums } = APP_DATA;
const statuses = enums['Woodoku::HardJourneyStatuses'];

const columnsFactory = ({
  applicationId, setDuplicatedRecord, onArchive, routes, hiddenColumns,
}) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Client ID', accessor: 'clientId', disableSortBy: true },
  { Header: 'Name', accessor: 'name' },
  LabelsColumn,
  {
    Header: 'Status',
    Cell: ({ row: { original: { status, statusHumanize } } }) => (
      <StatusBadge options={statuses} value={status} label={statusHumanize} />
    ),
  },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({
      row: {
        original: {
          id, name, status, eventType, inUse,
        },
      },
    }) => (
      <ButtonToolbar>
        <IconButton.Edit to={routes.editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name, eventType })} minimized />
        {status !== statuses.ARCHIVED
          && <IconButton.Archive onClick={onArchive(id, name)} minimized disabled={isInUse(inUse)} />}
      </ButtonToolbar>
    ),
  },
].filter((column) => !hiddenColumns.includes(column.accessor));

const Index = ({
  hiddenColumns = [],
}) => {
  const routes = WoodokuRoutes.HardJourneys;
  const localePath = 'woodoku.hardJourney';

  const { translate } = useI18n();
  const modelName = translate.fallback(`${localePath}.name`);

  const { query: { applicationId } } = useRouter();
  const { response, refetch, isLoading } = useQuery(WoodokuRoutes.HardJourneys.indexRequest, { includeInUse: true });

  const [duplicatedRecord, setDuplicatedRecord] = useState(null);

  const archiveResponseHandler = collectionResponseHandlerFactory({
    entityName: modelName,
    actionName: 'archive',
    refetch,
  });

  const confirm = useConfirm();
  const onArchive = (id, name) => () => {
    confirm.showConfirmation({
      title: `${modelName} '${name}' will be archived and not available for work. Continue?`,
    })
      .then(() => routes.archiveRequest({ applicationId, id })
        .then(archiveResponseHandler));
  };

  const columns = useMemo(() => columnsFactory({
    applicationId, setDuplicatedRecord, onArchive, routes, hiddenColumns,
  }), []);

  return response && (
    <>
      <PageHeader
        title={translate.fallback(`${localePath}.pluralName`)}
        filter={<Filter statuses={statuses} />}
      >
        <IconButton.New to={routes.newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? <Spinner /> : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
          />
          <DuplicationModal
            record={duplicatedRecord}
            entityName={modelName}
            handleClose={() => setDuplicatedRecord(null)}
            onSubmit={(values) => routes.duplicateRequest({ ...values, applicationId })}
            title="Duplicate Hard Journey"
            name="name"
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </>
  );
};

export default Index;
