import React from 'react';
import { generateChapterBasedRoutes } from '@pages/routes';

import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { useQuery } from '@hooks';
import { Spinner } from '@tripledotstudios/react-core';

import Form from './Form';

export default function Edit() {
  const LevelLayoutRoutes = generateChapterBasedRoutes('match3d').LevelLayouts;
  const { response, setResponse, isLoading } = useQuery((data) => LevelLayoutRoutes.editRequest(data));

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Level layouts bank',
    actionName: 'update',
    setData: setResponse,
  });
  const onSubmit = (values) => LevelLayoutRoutes.updateRequest(values)
    .then((result) => responseHandler(result, values));

  if (isLoading) {
    return <Spinner />;
  }

  return <Form data={response} onSubmit={onSubmit} />;
}
