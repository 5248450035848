import React, {
  useState, useMemo, createContext,
} from 'react';
import { useFlashMessages } from '@tripledotstudios/react-core';

import IndexMassOperationsModal from '@components/shared/IndexMassOperationsModal';
import IconButton from '@controls/buttons';

const massOperationsContext = createContext();

function MassOperationsHeaderButtons(contextValue) {
  const {
    selectedItems,
    setSelectedItems,
    massOperationsMode,
    setMassOperationsMode,
    setDisplayMassOperationsModal,
  } = contextValue;

  return ({ children, actions = ['update'] }) => (
    <massOperationsContext.Provider value={contextValue}>
      {massOperationsMode ? (
        <>
          {children}
          {actions.indexOf('update') > -1 && (
            <IconButton.Menu
              disabled={!selectedItems.length}
              onClick={() => setDisplayMassOperationsModal(true)}
              variant="primary"
            >
              Mass update
              {selectedItems.length ? ` (${selectedItems.length})` : ''}
            </IconButton.Menu>
          )}
          <IconButton.Cancel
            onClick={() => {
              setSelectedItems([]);
              setMassOperationsMode(false);
            }}
          />
        </>
      ) : (
        <IconButton.Menu
          outline
          onClick={() => {
            setSelectedItems([]);
            setMassOperationsMode(true);
          }}
        >
          Mass operations
        </IconButton.Menu>
      )}
    </massOperationsContext.Provider>
  );
}

function MassOperationsModal(contextValue) {
  const { success } = useFlashMessages();

  const {
    displayMassOperationsModal,
    setDisplayMassOperationsModal,
    selectedItems,
    setMassOperationsMode,
    setSelectedItems,
  } = contextValue;

  return ({
    pageModelName,
    modelName,
    statusOptions,
    transactional = false,
    includeAvailabilityState = false,
    refetch,
  }) => (
    <massOperationsContext.Provider value={contextValue}>
      <IndexMassOperationsModal
        statusOptions={statusOptions}
        pageModelName={pageModelName}
        modelName={modelName}
        selectedItems={selectedItems}
        show={displayMassOperationsModal}
        setShow={setDisplayMassOperationsModal}
        transactional={transactional}
        includeAvailabilityState={includeAvailabilityState}
        onSuccess={() => {
          setMassOperationsMode(false);
          setSelectedItems([]);
          refetch();
          success(`${pageModelName} Mass Update has been successfully finished`);
        }}
      />
    </massOperationsContext.Provider>
  );
}

export default function useMassOperations() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [massOperationsMode, setMassOperationsMode] = useState(false);
  const [displayMassOperationsModal, setDisplayMassOperationsModal] = useState(false);

  const contextValue = useMemo(() => ({
    selectedItems,
    setSelectedItems,
    massOperationsMode,
    setMassOperationsMode,
    displayMassOperationsModal,
    setDisplayMassOperationsModal,
  }), [selectedItems, massOperationsMode, displayMassOperationsModal]);

  const tableProps = {
    selectable: massOperationsMode,
    selectedItems,
    onSelect: (record, checked) => {
      if (checked) return setSelectedItems([...selectedItems, record.id]);

      return setSelectedItems(selectedItems.filter((id) => id !== record.id));
    },
  };

  return ({
    MassOperationsHeaderButtons: MassOperationsHeaderButtons(contextValue),
    MassOperationsModal: MassOperationsModal(contextValue),
    massOperationsTableProps: tableProps,
    selectedItems,
  });
}
