import React from 'react';
import { isEqual, omit } from 'lodash';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FaIcon, ReactTable } from '@pages/common';
import { ExpandIcon } from '@components/merge/shared/icons';
import { FormGroup } from '@hooks';

import IconButton from '@controls/buttons';
import InternalIdLabel from '@components/merge/shared/InternalIdLabel';

import AdditionalPostcardChanges from './AdditionalPostcardChanges';
import SharedPostcardFields from './SharedPostcardFields';

const StaticPostcardRow = React.memo(({
  dispatch,
  postcard,
  index,
  isExpanded,
  toggleExpanded,
  setViewPostcard,
  orderColumns,
  assetKeys,
  assetTypes,
}) => (
  <>
    <tr>
      <td>
        <ExpandIcon
          value={isExpanded}
          onClick={() => toggleExpanded(postcard._uuid)}
        />
      </td>
      <td>{postcard.position}</td>
      <td>
        <InternalIdLabel
          name={postcard.internalId}
          onClick={() => toggleExpanded(postcard._uuid)}
        />
      </td>
      <td>
        <FaIcon
          color="green"
          icon={faCheck}
        />
      </td>
      <td>
        <IconButton.Edit
          onClick={() => setViewPostcard(postcard)}
          disabled={postcard.storyOrders.length === 0}
          readonly
          minimized
        />
      </td>
    </tr>
    <tr className={`collapse ${isExpanded ? 'show' : ''}`}>
      <td />
      <td colSpan="3">
        <FormGroup name={`postcardsAttributes[${index}]`}>
          <SharedPostcardFields assetKeys={assetKeys} assetTypes={assetTypes} />
        </FormGroup>
        <ReactTable
          columns={orderColumns}
          data={postcard.storyOrders}
          disableSortBy
        />
        <FormGroup name={`postcardsAttributes[${index}]`}>
          <AdditionalPostcardChanges postcard={postcard} index={index} dispatch={dispatch} />
        </FormGroup>
      </td>
    </tr>
  </>
), (prev, next) => {
  const irrelevantProps = ['dispatch', 'toggleExpanded', 'setViewPostcard', 'index'];
  return isEqual(omit(prev, irrelevantProps), omit(next, irrelevantProps));
});

export default StaticPostcardRow;
