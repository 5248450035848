import React from 'react';
import {
  Table, Card, Col, Row,
} from 'react-bootstrap';
import { compact } from 'lodash';

import { Alert } from '@tripledotstudios/react-core';

import { FormGroup, useFormContext, useI18n } from '@hooks';
import { Label, Field, CheckboxField } from '@controls/form';
import IconButton from '@controls/buttons';

import presentGroupRule from '@services/ab-testing/presentGroupRule';
import { addUuid } from '@services/recursivelyProcessObject';

import APP_DATA from '@root/services/appData';
import ExperimentEntity from './ExperimentEntity';

const { enums: { AbExperimentStatuses } } = APP_DATA;

export default function Variant({
  experimentId,
  index,
  isPermanentlyArchived,
  disabled,
  participationsCount,
  variantRule,
  subVariantIds,
  variantRuleType,
  handleTabChange,
}) {
  // eslint-disable-next-line no-unused-vars
  const { dispatch, values } = useFormContext();
  const { translate } = useI18n();
  const noParticipations = participationsCount === 0;

  const variantAttributes = values.variantsAttributes[index];
  const removedVariant = variantAttributes._destroy === true;

  const nonEditableVariant = disabled || removedVariant;
  const indeletableVariant = values.variantsAttributes.filter((v) => !v._destroy).length < 2
    || values.status !== AbExperimentStatuses.INACTIVE || !noParticipations;

  const duplicateVariant = () => {
    const options = {
      name: `${variantAttributes.name}_copy`,
      weight: 0,
      skip: false,
    };

    dispatch({
      type: 'addVariant',
      variant: addUuid(options),
      sourceVariant: variantAttributes,
    });
  };

  const removeVariant = () => {
    dispatch({
      type: 'removeVariant',
      variant: variantAttributes,
      index,
    });
    if (!variantAttributes.id) {
      handleTabChange(`#${index - 1 > 0 ? index - 1 : 0}`);
    }
  };

  const undoRemoveVariant = () => {
    dispatch({
      type: 'undoRemoveVariant',
      variant: variantAttributes,
      index,
    });
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          {removedVariant && (
            <Alert>
              This variant was marked as deleted and will be removed if experiment is saved
              <IconButton.Undo
                onClick={undoRemoveVariant}
                className="btn-sm ms-2 mb-2"
                disabled={!removedVariant}
              />
            </Alert>
          )}
          {!removedVariant && variantAttributes.default && (index > 0) && (
            <Alert variant="info">
              This variant will become the new default if experiment is saved
            </Alert>
          )}
          <Col xs={12}>
            <FormGroup name={`variantsAttributes.${index}`}>
              <Row>
                <Col xs={10}>
                  <Label text="Name" required fieldSize="8">
                    <div className="d-flex justify-content-between align-content-center">
                      <div className="col-9">
                        <Field type="text" name="name" disabled={nonEditableVariant || variantAttributes.default} />
                      </div>
                    </div>
                  </Label>
                  {experimentId && <Label text="Id"><Field type="text" name="id" disabled /></Label>}
                  <Label text="Weight" tooltipText={translate.fallback('abTesting.variants.tooltips.weight')} required>
                    <Field type="number" name="weight" disabled={nonEditableVariant} />
                  </Label>
                  <Label
                    text="Skip experiment for this variant"
                    tooltipText={translate.fallback('abTesting.variants.tooltips.skip')}
                  >
                    <CheckboxField name="skip" disabled={nonEditableVariant} />
                  </Label>
                  <Label text="Variant Participants">
                    {isPermanentlyArchived && noParticipations ? 'N/A' : participationsCount.toLocaleString()}
                  </Label>
                </Col>
                <Col xs={2} className="text-end">
                  {(experimentId && !removedVariant) && (
                    <IconButton.Duplicate
                      onClick={duplicateVariant}
                      className="btn-sm ms-2 mb-2"
                      disabled={nonEditableVariant}
                      minimized
                    >
                      Duplicate Variant
                    </IconButton.Duplicate>
                  )}
                  {!removedVariant && (
                    <IconButton.Delete
                      onClick={removeVariant}
                      className="btn-sm ms-2 mb-2"
                      disabled={indeletableVariant}
                      minimized
                    />
                  )}
                </Col>
              </Row>
              {compact(variantAttributes.subVariantsAttributes.map((subVariant, ruleIndex) => {
                if (subVariant._destroy || variantAttributes.skip) return null;

                const ruleGroup = values.variantRuleGroupsAttributes.find(
                  (g) => {
                    if (g.id) {
                      return g.id === subVariant.abVariantRuleGroupId;
                    }
                    return g.uuid === subVariant.variantRuleGroupUuid;
                  },
                );
                const experimentEntities = subVariant.experimentEntitiesAttributes || [];

                return (
                  <div className="mb-5">
                    {variantRule.type && (
                      <div className="mb-1">
                        {presentGroupRule(ruleGroup)}
                      </div>
                    )}
                    {experimentEntities.length > 0 && (
                      <FormGroup name={`subVariantsAttributes.${ruleIndex}`}>
                        <Table bordered className="mb-0">
                          <thead>
                            <tr>
                              <th>Entity</th>
                              <th>Property Name</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {experimentEntities.map((attributes, i) => (
                              <ExperimentEntity
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={i}
                                variantIndex={index}
                                index={i}
                                experimentId={experimentId}
                                disabled={nonEditableVariant}
                                subVariant={subVariant}
                                subVariantIds={subVariantIds}
                                variantRuleType={variantRuleType}
                                {...attributes}
                              />
                            ))}
                          </tbody>
                        </Table>
                      </FormGroup>
                    )}
                  </div>
                );
              }))}
            </FormGroup>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
