/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { compact } from 'lodash';

import wrapArray from '@services/wrapArray';
import FilterBadge from './FilterBadge';
import FilterField from './FilterField';

export default function TextFilter({
  isMulti = true, label, name, options, updateFilterField, stateFieldValue, ...rest
}) {
  const updateTextFilterField = (filter) => {
    let encodedFilter;
    if (isMulti) {
      encodedFilter = compact(filter[name].map((s) => s.trim()));
    } else {
      encodedFilter = filter[name] ? filter[name].trim() : null;
    }

    updateFilterField({ ...filter, [name]: encodedFilter });
  };

  return (
    <FilterField
      canAdd={({ currentValue }) => currentValue}
      isMulti={isMulti}
      label={label}
      name={name}
      updateFilterField={updateTextFilterField}
      stateFieldValue={stateFieldValue}
      FieldComponent={({ currentValue, onKeyPress, setCurrentValue }) => (
        <input
          className="form-control"
          onChange={(event) => setCurrentValue(event.target.value)}
          value={currentValue}
          onKeyUp={onKeyPress}
          {...rest}
        />
      )}
      BadgesComponent={({ fieldValue, removeValue }) => wrapArray(fieldValue).map((value) => (
        <FilterBadge
          key={value}
          onClick={() => removeValue(value)}
          value={value}
        />
      ))}
    />
  );
}
