import React from 'react';
import { useTheme } from 'styled-components';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { FilesRoutes } from '@pages/routes';

export default function FilesNavigationItem({ applicationId, minimized }) {
  const theme = useTheme();

  return (
    <Sidebar.LinkItem
      color={theme.cerulean}
      icon={faFile}
      to={FilesRoutes.indexPath({ applicationId })}
      title="Files"
      minimized={minimized}
    />
  );
}
