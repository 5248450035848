import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { pick } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';
import updateQuery from '@services/updateQuery';

import { Form } from '@hooks';
import { Field, SelectField } from '@controls/form';
import { userSearch } from '@requests/devices';
import { searchResponseHandlerFactory, noRedirectHandlerFactory } from '@requests/responseHandlers';
import getQueryParams from '@services/getQueryParams';
import IconButton from '@controls/buttons';

const searchFields = [
  { label: 'API Token', value: 'token' },
  { label: 'Install ID', value: 'install_id' },
  { label: 'IDFA', value: 'idfa' },
  { label: 'IDFV', value: 'idfv' },
  { label: 'External ID', value: 'external_id' },
  { label: 'User ID', value: 'app_user_id' },
  { label: 'Debug ID', value: 'debug_id' },
  { label: 'Apple external ID', value: 'apple_external_id' },
  { label: 'Google external ID', value: 'google_external_id' },
  { label: 'Facebook external ID', value: 'facebook_external_id' },
];

export default function UserSearchForm({ setCollection, applicationId }) {
  const [data, setData] = useState({ column: 'token', value: '' });
  const paginationParams = pick(getQueryParams(), ['per', 'page']);
  const responseHandler = searchResponseHandlerFactory({
    entityName: 'App User',
    actionName: 'search',
    redirectPath: (responseData) => (
      `/admin/applications/${applicationId}/users/app_users/${responseData.items[0].id}/edit`
    ),
    setCollection,
    setData,
  });

  const paginationHandler = noRedirectHandlerFactory({
    entityName: 'App User',
    actionName: 'search',
    setData: setCollection,
    showSuccess: false,
    setDataOnSuccess: true,
  });

  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (data.value) {
      userSearch({
        applicationId, column: data.column, value: data.value, ...paginationParams,
      }).then(paginationHandler);
    }
  }, [paginationParams.per, paginationParams.page]);

  useEffect(() => {
    if (query.value) {
      setData({ column: query.column, value: query.value });
      userSearch({
        applicationId, column: query.column, value: query.value, ...paginationParams,
      }).then(responseHandler);
    }
  }, []);

  const handleSubmit = (values) => {
    updateQuery(router, { column: values.column, value: values.value });
    userSearch({ applicationId, ...values }).then(responseHandler);
  };

  return (
    <Form
      initialValues={data}
      onSubmit={handleSubmit}
      disableDirtyChecker
      enableReinitialize
    >
      {() => (
        <Row>
          <Col xs={4}>
            <SelectField name="column" options={searchFields} />
          </Col>
          <Col xs={7}>
            <Field type="text" name="value" />
          </Col>
          <Col xs={1}>
            <IconButton.Search type="submit" />
          </Col>
        </Row>
      )}
    </Form>
  );
}
