import React from 'react';
import { useTheme } from 'styled-components';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { MissionsRoutes, WoodokuRoutes } from '@pages/routes';

export default function MissionsNavigationSection({ applicationId, applicationTypeName, minimized = false }) {
  const theme = useTheme();
  const namespaceName = applicationTypeName === 'woodoku' ? 'Task' : 'Mission';

  return (
    <Sidebar.SectionItem icon={faBullseye} title={`${namespaceName}s`} color={theme.tulipTree} minimized={minimized}>
      <Sidebar.LinkItem
        to={MissionsRoutes.AllocationTemplates.indexPath({ applicationId })}
        title="Allocations"
      />
      <Sidebar.LinkItem
        to={MissionsRoutes.MissionTypes.indexPath({ applicationId })}
        title={`${namespaceName} Types`}
      />
      <Sidebar.LinkItem
        to={MissionsRoutes.Rewards.Sets.indexPath({ applicationId })}
        title="Reward Sets"
      />
      <Sidebar.LinkItem
        to={MissionsRoutes.Rewards.Types.indexPath({ applicationId })}
        title="Reward Types"
      />
      <Sidebar.LinkItem
        to={MissionsRoutes.Settings.editPath({ applicationId })}
        title="Settings"
      />
      {applicationTypeName === 'woodoku' && (
        <Sidebar.LinkItem
          to={WoodokuRoutes.XpLevelConfigs.indexPath({ applicationId })}
          title="XP Level Configs"
        />
      )}
    </Sidebar.SectionItem>
  );
}
