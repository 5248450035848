import React from 'react';

import {
  Field,
  localizedLabelFactory,
} from '@controls/form';

import { PageSection } from '@pages/common';

const LocalizedLabel = localizedLabelFactory('woodoku.machineLearning.model.config');

export default function Woodoku({ readOnly }) {
  return (
    <PageSection title="Model request parameters">
      <LocalizedLabel name="configsAmount">
        <Field name="configsAmount" type="number" disabled={readOnly} />
      </LocalizedLabel>
    </PageSection>
  );
}
