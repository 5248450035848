import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const TrueIcon = styled(FontAwesomeIcon).attrs({ icon: faCheck })`
  path {
    fill: green;
  }
`;

const FalseIcon = styled(FontAwesomeIcon).attrs({ icon: faTimes })`
  path {
    fill: #dc3545;
  }
`;

export default function BooleanIcon({ value, ...rest }) {
  return (
    value ? <TrueIcon {...rest} /> : <FalseIcon {...rest} />
  );
}

BooleanIcon.propTypes = {
  value: PropTypes.bool.isRequired,
};
