import React from 'react';
import { isEmpty, omit } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup,
} from '@controls/form';
import { DuplicateButton, PageHeader } from '@pages/common';

import { WoodokuRoutes as Routes } from '@pages/routes';

import reducer from './reducer';
import FigureSets from './FigureSets';

export default function WoodokuFigureSetDynamicConfigsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const { inUse } = data;

  const isInUse = isEmpty(omit(inUse, '_uuid'));

  return (
    <Form
      initialValues={{ ...data, applicationId }}
      onSubmit={onSubmit}
      reducer={reducer}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Figure Set Dynamic Config`}>
            <FormButtonsGroup cancelButtonPath={Routes.FigureSetDynamicConfigs.indexPath({ applicationId })}>
              <DuplicateButton
                routes={Routes.FigureSetDynamicConfigs}
                data={data}
                entityName="Figure Set Dynamic Config"
              />
            </FormButtonsGroup>
          </PageHeader>

          <Label text="Name"><Field type="text" name="name" /></Label>

          <StaticFieldsFormGroup data={data} />

          <FigureSets values={values} disabled={!isInUse} />
        </>
      )}
    </Form>
  );
}
