import React from 'react';

import { PageTitle } from '@pages/common';
import { LabelRoutes } from '@pages/routes';
import LabelsIndex from '@components/labels/Index';
import LabelsSearch from '@components/labels/Search';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function labels() {
  return [
    <Route
      key={LabelRoutes.indexRawPath}
      path={LabelRoutes.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Labels" />
          <LabelsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={LabelRoutes.searchRawPath}
      path={LabelRoutes.searchRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Labels" action="Search" />
          <LabelsSearch />
        </RouteContainer>
      )}
    />,
  ];
}
