import React from 'react';
import { JourneyRoutes } from '@pages/routes';
import DatesBasedIndex from '../shared/DatesBasedIndex';

const Index = () => (
  <DatesBasedIndex
    routes={JourneyRoutes.PromoEvents}
    localePath="journeys.promoEvents"
    massOperationsModel="Journeys::PromoEvent"
  />
);

export default Index;
