import React from 'react';
import { Route } from 'react-router-dom';

import { PageTitle } from '@pages/common';
import { LorayRoutes as Routes } from '@pages/routes';
import RouteContainer from '@pages/RouteContainer';

import {
  BanksEdit,
  BanksIndex,
  BanksNew,

  ChapterSetsIndex,
  ChapterSetsNew,
  ChapterSetsEdit,

  LevelLayoutsEdit,
} from '@pages/loray';

export default function loray() {
  return [
    //
    // Level Layout Banks
    //
    <Route
      key={Routes.Banks.indexRawPath}
      path={Routes.Banks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Level Layout Banks" />
          <BanksIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Banks.newRawPath}
      path={Routes.Banks.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout Bank" action="New" />
          <BanksNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Banks.editRawPath}
      path={Routes.Banks.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout Bank" action="Edit" />
          <BanksEdit />
        </RouteContainer>
      )}
    />,

    //
    // Chapter Sets
    //
    <Route
      key={Routes.ChapterSets.indexRawPath}
      path={Routes.ChapterSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Chapter Sets" />
          <ChapterSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.ChapterSets.newRawPath}
      path={Routes.ChapterSets.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Chapter Set" action="New" />
          <ChapterSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.ChapterSets.editRawPath}
      path={Routes.ChapterSets.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Chapter Set" action="Edit" />
          <ChapterSetsEdit />
        </RouteContainer>
      )}
    />,

    //
    // Level Layouts
    //
    <Route
      key={Routes.LevelLayouts.editRawPath}
      path={Routes.LevelLayouts.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout" action="Edit" />
          <LevelLayoutsEdit />
        </RouteContainer>
      )}
    />,
  ];
}
