import React from 'react';
import { PageTitle } from '@pages/common';
import {
  TableConfigsIndex,
  TableConfigsNew,
  TableConfigsEdit,

  DailyChallengeConfigsIndex,
  DailyChallengeConfigsNew,
  DailyChallengeConfigsEdit,
} from '@pages/blackjack';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function blackjack() {
  const scope = '/admin/applications/:applicationId/blackjack';

  return [
    <Route
      key={`${scope}/table_configs`}
      path={`${scope}/table_configs`}
      element={(
        <RouteContainer>
          <PageTitle text="Table Configs" />
          <TableConfigsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/table_configs/new`}
      path={`${scope}/table_configs/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Table Config" action="New" />
          <TableConfigsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/table_configs/:id/edit`}
      path={`${scope}/table_configs/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Table Config" action="Edit" />
          <TableConfigsEdit />
        </RouteContainer>
    )}
    />,

    <Route
      key={`${scope}/daily_challenge_configs`}
      path={`${scope}/daily_challenge_configs`}
      element={(
        <RouteContainer>
          <PageTitle text="Daily Challenge Configs" />
          <DailyChallengeConfigsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/daily_challenge_configs/new`}
      path={`${scope}/daily_challenge_configs/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Daily Challenge Config" action="New" />
          <DailyChallengeConfigsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/daily_challenge_configs/:id/edit`}
      path={`${scope}/daily_challenge_configs/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Daily Challenge Config" action="Edit" />
          <DailyChallengeConfigsEdit />
        </RouteContainer>
    )}
    />,
  ];
}
