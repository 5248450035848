import React, { useState, useMemo } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';

import { isInUse } from '@pages/common/InUse';
import { TileRoutes as Routes } from '@pages/routes';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
  DuplicationModal,
  LabelsColumn,
  Filter,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import IconButton from '@controls/buttons';

const columnsFactory = ({ applicationId, onDelete, setDuplicatedRecord }) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  LabelsColumn,
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, inUse, name } } }) => {
      const disabled = isInUse(inUse);

      return (
        <ButtonToolbar>
          <IconButton.Edit minimized to={Routes.AssetBundleSchemaSets.editPath({ id, applicationId })} />
          <IconButton.Duplicate minimized onClick={() => setDuplicatedRecord({ id, name })} />
          <IconButton.Delete
            minimized
            onClick={() => onDelete({ id, name })}
            disabled={disabled}
            title={disabled ? 'Disabled as the entity is used' : null}
          />
        </ButtonToolbar>
      );
    },
  },
];

export default function TileAssetBundleSchemaSetsIndex() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { query: { applicationId } } = useRouter();
  const { response, refetch, isLoading } = useQuery(Routes.AssetBundleSchemaSets.indexRequest, { includeInUse: true });
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Asset Bundles Schema Set',
    actionName: 'deletion',
    refetch,
  });

  const onDelete = ({ id, name }) => {
    confirm.showConfirmation({
      title: `Asset Bundles Schema Set ${name} will be deleted and not available for work. Continue?`,
    })
      .then(() => Routes.AssetBundleSchemaSets.deleteRequest({ applicationId, id })
        .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({ applicationId, setDuplicatedRecord, onDelete }), []);

  return response && (
    <>
      <PageHeader
        title="Asset Bundles Schema Sets"
        filter={<Filter defaultComponents={['availabilityStateIn', 'createdByMe', 'labels', 'id', 'name']} />}
      >
        <IconButton.New to={Routes.AssetBundleSchemaSets.newPath({ applicationId })} />
      </PageHeader>
      {isLoading ? <Spinner /> : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
          />
          <DuplicationModal
            record={duplicatedRecord}
            title="Duplicate Asset Bundles Schema Set"
            entityName="Asset Bundles Schema Set"
            handleClose={() => setDuplicatedRecord(null)}
            onSubmit={(values) => Routes.AssetBundleSchemaSets.duplicateRequest({ ...values, applicationId })}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </>
  );
}
