import React from 'react';
import IndexPage from '@pages/puzzle-time/ConfigIndex';
import { useRouter } from '@tripledotstudios/react-core';
import { getLobbyConfigs, deleteLobbyConfig } from '@requests/puzzle-time/lobbyConfigs';

export default function Index() {
  const { query } = useRouter();

  return (
    <IndexPage
      configName="Lobby Config"
      path={`/admin/applications/${query.applicationId}/puzzle_time/lobby_configs`}
      getConfigFunction={getLobbyConfigs}
      deleteConfigFunction={deleteLobbyConfig}
    />
  );
}
