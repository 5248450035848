import React from 'react';
import { BooleanIcon } from '@pages/common';
import styled from 'styled-components';

import BanksIndex, { banksColumns } from '@pages/metagame/chapter-based/banks/Index';
import BaseImport from '@pages/metagame/chapter-based/banks/BaseImport';
import { TaosRoutes } from '@pages/routes';

const CountColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const Import = ({ refetch }) => <BaseImport routes={TaosRoutes.Banks} refetch={refetch} />;

const taosBanksColumns = (props) => {
  const columns = banksColumns({ ...props, supportExport: true });

  columns[5] = {
    Header: 'Layouts',
    accessor: 'levelLayoutsCount',
    Cell: ({ row: { original: { levelLayoutsCount, validForUse } } }) => (
      <CountColumn>
        {levelLayoutsCount}
        <BooleanIcon
          value={validForUse}
          title={validForUse
            ? 'All the layouts are valid for use in game client'
            : 'Includes layouts not valid for use in game client'}
        />
      </CountColumn>
    ),
  };
  return columns;
};

export default function Index() {
  return (
    <BanksIndex
      Import={Import}
      columnsOverride={taosBanksColumns}
    />
  );
}
