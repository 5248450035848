/* eslint-disable no-undef */
import { wrapReducer } from '@reducers';

import {
  cloneDeep, isPlainObject, map, last, sortBy,
} from 'lodash';

import { v4 as uuidv4 } from 'uuid';

import { addUuid } from '@services/recursivelyProcessObject';

import { reorderList } from '@services/reorder';
import reorderMeta from '@services/reorderMeta';

const reorderModes = (list, startIndex, endIndex) => {
  let index = 1;

  const modes = reorderList(list, startIndex, endIndex);
  const resultModes = modes.map((element) => {
    const mode = element;
    mode.number = index;

    if (!mode._destroy) index += 1;

    return mode;
  });

  const uuids = map(list, '_uuid');
  const reorderedUuids = map(resultModes, '_uuid');

  const [oldPositions, newPositions] = uuids.reduce((memo, uuid) => {
    memo[0].push(uuids.indexOf(uuid));
    memo[1].push(reorderedUuids.indexOf(uuid));

    return memo;
  }, [[], []]);

  return { result: resultModes, changeSet: [oldPositions, newPositions] };
};

const formReducer = (state, action) => {
  if (action.actionType === 'removeMode') {
    const { modesAttributes } = state;
    const { deleteAt } = action;

    const modes = cloneDeep(modesAttributes);

    modes[deleteAt]._destroy = true;

    state.modesAttributes = reorderModes(modes).result;

    return state;
  }

  if (action.actionType === 'moveMode') {
    const { modesAttributes } = state;
    const {
      sourceIndex,
      destinationIndex,
      meta,
      metaErrorsPath,
    } = action;

    const modes = cloneDeep(modesAttributes);

    if (modesAttributes.length <= 1) return state;

    const { result, changeSet: [fromIndices, toIndices] } = reorderModes(modes, sourceIndex, destinationIndex);

    state.modesAttributes = result;

    if (meta.errors && isPlainObject(get(meta, metaErrorsPath))) {
      meta.errors = { ...meta.errors, ...reorderMeta(meta, fromIndices, toIndices, metaErrorsPath).errors };
    }

    return state;
  }

  if (action.actionType === 'applyPositions') {
    const { modesAttributes } = state;
    const { uuidToPositionMapping } = action;

    modesAttributes.forEach((mode) => {
      mode.number = uuidToPositionMapping[mode._uuid];

      return mode;
    });

    state.modesAttributes = sortBy(modesAttributes, 'number');

    return state;
  }

  if (action.actionType === 'addEmptyMode') {
    const { modesAttributes } = state;

    const lastMode = last(modesAttributes.filter(({ _destroy }) => !_destroy));
    const mode = addUuid({
      id: null,
      number: lastMode ? lastMode.number + 1 : 1,
      entryCostEnabled: false,
      inGamePlayonEnabled: false,
      inGameUndoEnabled: false,
      inGameWildcardEnabled: false,
      preGameCardcutEnabled: false,
      preGameWhirlwindEnabled: false,
      preGameExtraWildcardEnabled: false,
      passiveFreeRoundEnabled: false,
      passiveDoubleWinEnabled: false,
      bulbsEnabled: false,
      starsEnabled: false,
      tapBonusCoinsEnabled: false,
      streakBonusCoinsEnabled: false,
      remainingCardCoinsEnabled: false,
      streakBonusWildcardEnabled: false,
      streakBonusExtracardEnabled: false,
      leaderboardPointsEnabled: false,
      soloMissionPointsEnabled: false,
      winStreakPointsEnabled: false,
      entryCostMultiplier: 1.0,
      inGamePlayonMultiplier: 1.0,
      inGameUndoMultiplier: 1.0,
      inGameWildcardMultiplier: 1.0,
      preGameCardcutMultiplier: 1.0,
      preGameWhirlwindMultiplier: 1.0,
      preGameExtraWildcardMultiplier: 1.0,
      passiveFreeRoundMultiplier: 1.0,
      passiveDoubleWinMultiplier: 1.0,
      bulbsMultiplier: 1.0,
      starsMultiplier: 1.0,
      tapBonusCoinsMultiplier: 1.0,
      streakBonusCoinsMultiplier: 1.0,
      remainingCardCoinsMultiplier: 1.0,
      streakBonusWildcardMultiplier: 1.0,
      streakBonusExtracardMultiplier: 1.0,
      leaderboardPointsMultiplier: 1.0,
      soloMissionPointsMultiplier: 1.0,
      winStreakPointsMultiplier: 1.0,
      _destroy: false,
    });

    state.modesAttributes.push(mode);

    return state;
  }

  if (action.actionType === 'duplicateMode') {
    const { modesAttributes } = state;
    const { sourceIndex } = action;

    if ((modesAttributes || []).filter(({ _destroy }) => !_destroy).length >= 3) return state;

    const sourceMode = modesAttributes[sourceIndex];
    const lastMode = last(modesAttributes.filter(({ _destroy }) => !_destroy));

    const duplicatedRecord = {
      ...sourceMode,
      id: null,
      _destroy: false,
      number: lastMode ? lastMode.number + 1 : 1,
      _uuid: uuidv4(),
    };

    state.modesAttributes.push(duplicatedRecord);

    return state;
  }

  return state;
};

export default wrapReducer(formReducer);
