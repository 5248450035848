import { get, set } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';
import newRulesSection from '@services/rules/newRulesSection';
import { appendDisableTypeChange, recursivelyAppendDisableTypeChangeRemoveRuleIds } from '@root/services/rules/utils';
import APP_DATA from '@root/services/appData';

const DEFAULT_RULE_TYPE = 'Rules::CountryRule';
const NAMED_GROUP_RULE_TYPE = 'Rules::NamedGroupRule';
const NAMED_GROUP_DEFAULT_OPERATION = APP_DATA.enums.RuleOperations.IN;

/* eslint-disable no-param-reassign, array-callback-return */
export default function rulesReducer(state, action) {
  const {
    rulesPath,
    sectionPath,
    actionType,
  } = action;

  const rulesAttributes = get(state, rulesPath);
  const ruleSectionsAttributes = get(state, sectionPath);

  const recursivelyRemoveSections = (section) => {
    if (!section) return;

    section._destroy = true;
    const childSections = section.ruleSectionsAttributes;

    if (childSections) childSections.forEach((childSection) => recursivelyRemoveSections(childSection));
  };

  const removeRules = (rules) => (rules.forEach((rule) => { rule._destroy = true; }));

  switch (actionType) {
    case 'addRule': {
      rulesAttributes.push(addUuid({
        id: null,
        type: DEFAULT_RULE_TYPE,
        operation: null,
        _destroy: false,
      }));

      break;
    }
    case 'addNamedGroupRule': {
      rulesAttributes.push(addUuid({
        id: null,
        type: NAMED_GROUP_RULE_TYPE,
        operation: null,
        _destroy: false,
      }));

      break;
    }
    case 'removeRule': {
      const { index } = action;
      const rule = rulesAttributes[index];

      if (rule.id) rule._destroy = true;
      else rulesAttributes.splice(index, 1);

      break;
    }
    case 'addSection': {
      ruleSectionsAttributes.push(newRulesSection());
      break;
    }
    case 'removeSection': {
      const { index } = action;
      const section = ruleSectionsAttributes[index];

      if (section.id) recursivelyRemoveSections(section);
      else ruleSectionsAttributes.splice(index, 1);

      break;
    }
    case 'resetOperation': {
      const { index, operation } = action;
      const rule = rulesAttributes[index];

      rule.operation = operation;

      break;
    }
    case 'convertToSection': {
      const newSection = {
        ...newRulesSection(),
        rulesAttributes: rulesAttributes.map((rule) => addUuid(appendDisableTypeChange(rule))),
        ruleSectionsAttributes: recursivelyAppendDisableTypeChangeRemoveRuleIds(
          ruleSectionsAttributes.map(({ id, ...rest }) => addUuid(rest)),
        ),
      };

      ruleSectionsAttributes.forEach(recursivelyRemoveSections);

      set(state, sectionPath, [...ruleSectionsAttributes, newSection]);

      removeRules(rulesAttributes);

      break;
    }
    case 'addSectionValidationErrors': {
      const { response, sectionsPath } = action;

      set(state, `meta.errors.${sectionsPath}`, response.data._meta.errors.ruleSectionsAttributes);

      break;
    }
    case 'replaceSectionWithNamedRuleGroup': {
      const { response, sectionsPath } = action;
      const currentSection = get(state, sectionsPath);

      currentSection.ruleSectionsAttributes.forEach(recursivelyRemoveSections);
      removeRules(currentSection.rulesAttributes);
      currentSection.rulesAttributes.push(addUuid({
        id: null,
        type: NAMED_GROUP_RULE_TYPE,
        operation: NAMED_GROUP_DEFAULT_OPERATION,
        groupIds: [response.data.id],
        _destroy: false,
      }));

      break;
    }
    default: break;
  }

  return state;
}
