import React from 'react';
import { faCalendarDay, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { MissionsRoutes } from '@pages/routes';
import {
  ActivityRangeColumn,
  TIMESTAMP_COLUMNS,
  InLiveBadge,
  InLiveColumnHeader,
  RulesList,
  StatusBadge,
  PriorityColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';
import { useI18n } from '@hooks';

export default function ColumnsFactory({
  currentApplication,
  statuses,
  setDuplicatedRecord,
  onDelete,
  onShowCurrentAllocation,
  onRegenerateAllocation,
}) {
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  return [
    { Header: 'Id', accessor: 'id', style: { width: 45 } },
    { Header: 'Name', accessor: 'name' },
    PriorityColumn,
    {
      Header: 'Status',
      style: { width: 90 },
      Cell: ({ row }) => {
        const { status, statusHumanize } = row.original;
        return (
          <StatusBadge
            options={statuses}
            value={status}
            label={statusHumanize}
          />
        );
      },
    },
    {
      id: 'availability_header',
      style: { width: 130 },
      Header: () => <InLiveColumnHeader />,
      Cell: ({ row }) => <InLiveBadge inLive={row.original.availabilityStateLive} />,
    },
    ActivityRangeColumn,
    {
      Header: 'Rules',
      style: { width: '20%' },
      Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
    },
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      style: { width: 200 },
      Cell: (
        {
          row: {
            original: {
              id, name, allocationInstanceId, inUse,
            },
          },
        },
      ) => {
        const disabled = isInUse(inUse);
        return (
          <ButtonToolbar>
            <IconButton.Edit
              to={MissionsRoutes.AllocationTemplates.editPath({ id, applicationId: currentApplication.id })}
              minimized
            />
            <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
            <IconButton.Delete
              onClick={onDelete(id, name)}
              disabled={disabled}
              title={disabled ? 'Disabled as the entity is used' : null}
              minimized
            />
            <ButtonToolbar.Divider />
            <IconButton
              icon={faCalendarDay}
              variant="success"
              onClick={() => onShowCurrentAllocation(allocationInstanceId)}
              outline
              minimized
            >
              {`Show today's ${namespaceName}s`}
            </IconButton>

            {allocationInstanceId && (
              <IconButton
                icon={faArrowsRotate}
                variant="warning"
                onClick={() => onRegenerateAllocation(allocationInstanceId)}
                outline
                minimized
              >
                Regenerate allocation
              </IconButton>
            )}
          </ButtonToolbar>
        );
      },
    },
  ];
}
