import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import {
  FormGroup,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import { Field, SelectField } from '@controls/form';

import { MergeRoutes } from '@pages/routes';
import ProductDetails from '@components/merge/shared/ProductDetails';

export default function Slots({ values }) {
  const [products, setProducts] = useState([]);
  const productLabels = fromClassNameToOptions('Merge::ProductLabels');
  const { query } = useRouter();

  useEffect(() => {
    MergeRoutes.Products.indexRequest({
      applicationId: query.applicationId,
      priceType: values.priceType,
      rewardType: values.rewardType,
      withoutPagination: true,
    }).then((response) => {
      setProducts(response.data.items);
    });
  }, [values.priceType, values.rewardType]);

  const productsById = products.reduce((memo, product) => ({ ...memo, [product.id]: product }), {});
  const productOptions = products.map((item) => ({ value: item.id, label: item.name }));

  return (
    <>
      <Row className="mb-2">
        <Col xs={2}><b>Slot</b></Col>
        <Col xs={4}><b>Product</b></Col>
        <Col xs={4}><b>Product details</b></Col>
        <Col xs={2}><b>Label</b></Col>
      </Row>
      <hr />
      {values.slotsAttributes && values.slotsAttributes.map((slot, index) => (
        <FormGroup key={slot._uuid} name={`slotsAttributes[${index}]`}>
          <Row className="mb-2">
            <Col xs={2}>
              <Field name="id" type="hidden" />
              <Field name="position" type="number" readOnly />
            </Col>
            <Col xs={4}>
              <SelectField name="productId" options={productOptions} />
            </Col>
            <Col xs={4}>
              <ProductDetails product={productsById[slot.productId]} />
            </Col>
            <Col xs={2}>
              <SelectField
                name="label"
                options={useCallback(() => productLabels, [values.priceType, values.rewardType])}
                hasPrepopulatedOption
              />
            </Col>
          </Row>
        </FormGroup>
      ))}
    </>
  );
}
