import React, { useState } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  PageHeader,
  Pagination,
  InLiveColumn,
  InUseColumn,
  TIMESTAMP_COLUMNS,
  ReactTable,
  DuplicationModal,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

import { WoodokuRoutes as Routes } from '@pages/routes';

import Filter from './Filter';

const actionsTableColumn = ({ applicationId, setDuplicatedRecord, onDelete }) => ({
  Header: 'Actions',
  Cell: ({ row: { original: { id, name, inUse } } }) => (
    <ButtonToolbar>
      <IconButton.Edit to={Routes.FigureSetDynamicConfigs.editPath({ id, applicationId })} minimized />
      <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
      <IconButton.Delete onClick={onDelete(id, name)} disabled={isInUse(inUse)} minimized />
    </ButtonToolbar>
  ),
});

export default function Index() {
  const { response, refetch } = useQuery(Routes.FigureSetDynamicConfigs.indexRequest, { includeInUse: true });
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Figure Set Dynamic Config',
    actionName: 'deletion',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Figure Set Dynamic Config '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => Routes.FigureSetDynamicConfigs.deleteRequest({ applicationId, id })
        .then(responseHandler));
  };

  const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    InLiveColumn,
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    actionsTableColumn({ applicationId, setDuplicatedRecord, onDelete }),
  ];

  return response && (
    <div>
      <PageHeader title="Figure Set Dynamic Configs" filter={<Filter />}>
        <IconButton.New to={Routes.FigureSetDynamicConfigs.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <DuplicationModal
        entityName="Figure Set Dynamic Config"
        title="Duplicate FigureSet Dynamic Config"
        record={duplicatedRecord}
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={({ id, name }) => (
          Routes.FigureSetDynamicConfigs.duplicateRequest({ id, name, applicationId })
        )}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
