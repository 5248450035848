import React, { useMemo } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { useConfirm } from '@hooks';

import {
  RulesList,
  ActivityRangeColumn,
  NameColumn,
  PriorityColumn,
  StatusAndAvailabilityColumn,
  UsageCountColumn,
} from '@pages/common';
import { GameSettingsRoutes } from '@pages/routes';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import IconButton from '@controls/buttons';

const { enums: { GameSettingsStatuses: statuses } } = APP_DATA;

const columnsFactory = ({
  gameType,
  applicationId,
  onDelete,
  onStatusToggle,
  setDuplicatedRecord,
}) => ([
  NameColumn({ routes: GameSettingsRoutes, gameType }),
  PriorityColumn,
  { ...ActivityRangeColumn, style: { width: 150 } },
  StatusAndAvailabilityColumn({ statuses }),
  {
    Header: 'Rules',
    style: { width: '35%' },
    Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
  },
  UsageCountColumn(),
  {
    Header: 'Actions',
    style: { width: '12rem' },
    Cell: ({ row: { original: { id, name, status } } }) => {
      const isActive = status === statuses.ACTIVE;

      return (
        <ButtonToolbar>
          <IconButton.Edit
            to={GameSettingsRoutes.editPath({ id, gameType, applicationId })}
            minimized
            testId={`edit_button_${name}`}
          />
          <IconButton.Duplicate
            onClick={() => setDuplicatedRecord({ id, name })}
            minimized
            testId={`duplicate_button_${name}`}
          />
          <IconButton.Activate
            isActive={isActive}
            onClick={onStatusToggle(id, name, status)}
            minimized
            testId={`activate_button_${name}`}
          />
          <IconButton.Delete onClick={onDelete(id, name)} minimized testId={`delete_button_${name}`} />
        </ButtonToolbar>
      );
    },
  },
]);

const buildResponseHandler = ({ actionName, refetch }) => collectionResponseHandlerFactory({
  entityName: 'Game Setting',
  actionName,
  refetch,
});

export default function buildColumns({ gameType, refetch, setDuplicatedRecord }) {
  const { query: { applicationId } } = useRouter();

  const confirm = useConfirm();
  const deleteResponseHandler = buildResponseHandler({ actionName: 'deletion', refetch });
  const activateResponseHandler = buildResponseHandler({ actionName: 'activation', refetch });
  const deactivateResponseHandler = buildResponseHandler({ actionName: 'deactivation', refetch });

  const onDelete = (id, name) => () => {
    const title = `Game Settings '${name}' will be deleted and not available for work. Continue?`;

    const onConfirm = () => GameSettingsRoutes
      .deleteRequest({ gameType, id, applicationId })
      .then(deleteResponseHandler);

    confirm.showConfirmation({ title }).then(onConfirm);
  };

  const onStatusToggle = (id, name, status) => () => {
    const isActive = status === statuses.ACTIVE;
    const actionName = isActive ? 'deactivated' : 'activated';
    const title = `Game Settings '${name}' will be ${actionName}. Continue?`;

    const onConfirm = () => GameSettingsRoutes
      .toggleStatusRequest({ id, gameType, applicationId })
      .then(isActive ? deactivateResponseHandler : activateResponseHandler);

    confirm.showConfirmation({ title }).then(onConfirm);
  };

  return useMemo(() => columnsFactory({
    gameType,
    applicationId,
    onDelete,
    onStatusToggle,
    setDuplicatedRecord,
  }), []);
}
