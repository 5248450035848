import { useTheme } from 'styled-components';
import { faCalendarDays, faNewspaper } from '@fortawesome/free-solid-svg-icons';

import { JourneyRoutes, AdsRoutes } from '@pages/routes';

import localizationShortcutsBuilder from './localizations';

export default function Solitare({ applicationId }) {
  const theme = useTheme();

  return [
    [
      {
        title: 'Journeys',
        link: JourneyRoutes.Events.indexPath({ applicationId }),
        icon: faCalendarDays,
        color: theme.purple,
      },
      {
        title: 'Activation Journeys',
        link: JourneyRoutes.ActivationEvents.indexPath({ applicationId }),
        icon: faCalendarDays,
        color: theme.purple,
      },
      {
        title: 'Level Banks',
        link: JourneyRoutes.LevelBanks.indexPath({ applicationId }),
        icon: faCalendarDays,
        color: theme.purple,
      },
    ],
    [
      ...localizationShortcutsBuilder({ applicationId, theme }),
      {
        title: 'Ads Configurations',
        link: AdsRoutes.Configurations.indexPath({ applicationId }),
        icon: faNewspaper,
        color: theme.lightPurple,
      },
    ],
  ];
}
