import { addUuid } from '@services/recursivelyProcessObject';
import { dndRecords } from '@services/reorder';

export default function formReducer(state, action) {
  switch (action.type) {
    case 'changeSchemaId': {
      state.ordersAttributes = [];
      break;
    }
    case 'reorderOrders': {
      dndRecords(state, action);
      break;
    }
    case 'createOrder': {
      const orders = state.ordersAttributes.filter((p) => !p._destroy);
      state.ordersAttributes.push(addUuid({
        id: null,
        internalId: `parallel_order_${state.id || '<config_id>'}_<id>`,
        targetsAttributes: [addUuid({ _destroy: false, quantity: 1 })],
        rewardsAttributes: [],
        position: orders.length > 0 ? Math.max(...orders.map((p) => p.position)) + 1 : 1,
      }));
      break;
    }
    case 'removeOrder': {
      const { index } = action;
      const order = state.ordersAttributes[index];

      order._destroy = true;
      state.ordersAttributes.slice(index + 1).forEach((currentOrder) => {
        if (!currentOrder._destroy) currentOrder.position -= 1;
      });
      break;
    }
    default: break;
  }

  return state;
}
