import React from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';
import { useQuery, useI18n } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { WoodokuRoutes } from '@pages/routes';
import Form from './Form';

const New = () => {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(WoodokuRoutes.HardJourneys.newRequest);

  const { translate } = useI18n();
  const entityName = translate.fallback('woodoku.hardJourney.name');

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName,
    actionName: 'create',
    setData: setResponse,
  });

  const onSubmit = (values) => WoodokuRoutes.HardJourneys.createRequest({ ...values, applicationId })
    .then(responseHandler);

  if (isLoading) return <Spinner />;

  return response && (
    <Form
      actionName="Create"
      data={response}
      onSubmit={onSubmit}
      title={`New ${entityName}`}
      entityName={entityName}
    />
  );
};

export default New;
