/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import { Table } from 'react-bootstrap';

import { BooleanIcon } from '@pages/common';
import CopyToClipboardButton from '@controls/CopyToClipboardButton';

import AnonymisationStatus from './anonymisation/AnonymisationStatus';

export default function UserInfo({ user }) {
  return (
    <Table bordered striped>
      <tbody>
        <tr>
          <td>ID</td>
          <td>{user.id}</td>
        </tr>
        <tr>
          <td>Public ID</td>
          <td>
            {user.publicId}
            <CopyToClipboardButton text={user.publicId} />
          </td>
        </tr>
        <tr>
          <td>Country Code</td>
          <td>{user.countryCode}</td>
        </tr>
        <tr>
          <td>State Code</td>
          <td>{user.stateCode}</td>
        </tr>
        <tr>
          <td>Timezone</td>
          <td>{user.timeZone}</td>
        </tr>
        <tr>
          <td>Installed At</td>
          <td>{user.installedAt}</td>
        </tr>
        <tr>
          <td>{user.anonymised ? 'Anonymisation' : 'Anonymised'}</td>
          <td>
            {user.anonymised ? <AnonymisationStatus user={user} /> : <BooleanIcon value={false} />}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
