import { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useRouter } from '@tripledotstudios/react-core';
import { useQuery as reactQuery } from '@tanstack/react-query';

const selectOptionsParams = {
  processResponse: ({ data }) => data.items.map(({ id, name: label }) => ({ value: id, label })),
  placeholderData: [],
  staleTime: 60_000,
  additionalRequestParams: { withoutPagination: true, includeInUse: false },
};

function useQuery(...args) {
  if (typeof args[0] === 'function') { // this interface is here to support old useQuery calls. Please use keyword args
    const [
      request,
      additionalRequestParams = {},
      processResponse = (response) => response.data,
      reactQueryParams = {},
    ] = args;
    const router = useRouter();
    const [response, setResponse] = useState(null);
    const queryKey = useRef(uuidv4());
    const {
      isLoading, refetch, data,
    } = reactQuery({
      queryFn: ({ signal }) => request({ ...additionalRequestParams, ...router.query }, { signal })
        .then((resp) => {
          const processedResponse = processResponse(resp);
          setResponse(processedResponse);
          return processedResponse;
        }),
      queryKey: [queryKey.current, router.location.search, router.location.pathname],
      refetchOnWindowFocus: false,
      retry: false,
      ...reactQueryParams,
    });
    return {
      response: response || data,
      isLoading,
      setResponse,
      refetch,
    };
  }
  const {
    request, additionalRequestParams, processResponse, ...reactQueryParams
  } = { ...(args[0].toSelectOptions && selectOptionsParams), ...args[0] };
  return useQuery(request, additionalRequestParams, processResponse, reactQueryParams);
}

export default useQuery;
