import { cloneDeep, merge } from 'lodash';
import { v4 } from 'uuid';

export default function preprocessSubmit(values) {
  const {
    variantName, name, experimentEntityId, configAttributes,
  } = values;
  const variantAttributes = configAttributes
    ? {
      configAttributes: {
        currentVariantAttributes: {
          unbubblingConfigurationAttributes: {
            name: `${name}'s basic unbubbling configuration #${v4().slice(0, 5)}`,
          },
        },
      },
    }
    : {
      unbubblingConfigurationAttributes: {
        name: `${values.eventName}'s variant "${variantName}" #${experimentEntityId} unbubbling configuration`,
      },
    };
  return merge(cloneDeep(values), variantAttributes);
}
