import React from 'react';
import { Card } from 'react-bootstrap';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { useFormContext } from '@hooks';
import IconButton from '@controls/buttons';
import styled from 'styled-components';

const CheckboxWrapper = styled.label`
  display: flex;
  margin-left: auto;
  align-self: center;
  align-items: center;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-left: 5px;
`;

const Text = styled.span`
  font-size: 1.2em;
`;

export default function FormButtonsBlock({ setShowCancelModal, showOnlyKeysWithErrors, setShowOnlyKeysWithErrors }) {
  const { isSubmitting, values: { meta } } = useFormContext();

  const handleShowOnlyKeysWithErrorsChange = (event) => {
    setShowOnlyKeysWithErrors(event.currentTarget.checked);
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        <ButtonToolbar>
          <IconButton.Save type="submit" disabled={isSubmitting} />
          <IconButton.Cancel onClick={() => setShowCancelModal(true)} disabled={isSubmitting} />
          {meta?.errors?.keyConfigs
            && (
              <CheckboxWrapper>
                <Text>Show only keys with validation errors</Text>
                <Checkbox checked={showOnlyKeysWithErrors} onChange={handleShowOnlyKeysWithErrorsChange} />
              </CheckboxWrapper>
            )}
        </ButtonToolbar>
      </Card.Body>
    </Card>
  );
}
