import { omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { addUuid } from '@services/recursivelyProcessObject';
import { persistedCollection } from '@services/utils';

export default function getDefaultSubVariant(variant) {
  const defaultSubVariant = persistedCollection(variant.subVariantsAttributes)[0];
  if (!defaultSubVariant) return null;

  return addUuid({
    id: defaultSubVariant.id,
    experimentEntitiesAttributes: defaultSubVariant.experimentEntitiesAttributes
      .map((entity) => ({
        ...omit(entity, ['id']),
        _uuid: uuidv4(),
      })),
  });
}
