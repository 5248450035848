const adPlacementIdsDefaultFieldNames = [
  'adsMaxIosBannerId',
  'adsMaxIosInterstitialId',
  'adsMaxIosExpressInterstitialId',
  'adsMaxIosRewardedVideoId',
  'adsMaxAndroidBannerId',
  'adsMaxAndroidInterstitialId',
  'adsMaxAndroidExpressInterstitialId',
  'adsMaxAndroidRewardedVideoId',
  'adsAmazonIosSdkKey',
  'adsAmazonIosInterstitialId',
  'adsAmazonIosBannerId',
  'adsAmazonIosLeaderboardId',
  'adsAmazonAndroidSdkKey',
  'adsAmazonAndroidInterstitialId',
  'adsAmazonAndroidBannerId',
  'adsAmazonAndroidLeaderboardId',
];

export default adPlacementIdsDefaultFieldNames;
