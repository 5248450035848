import { get } from 'lodash';
import APP_DATA from '@services/appData';
import rulesPresenter from '@pages/common/rules-list/rulesPresenter';

export default function presentGroupRule(ruleGroup) {
  const data = get(ruleGroup, 'ruleSectionsAttributes[0].rulesAttributes[0]');
  if (!data) return '';

  return rulesPresenter({
    type: data.type,
    operation: data.operation,
    props: data,
    enums: APP_DATA.rawEnums,
  });
}
