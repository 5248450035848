/* eslint-disable gs/plz-camel-case-for-action-names */
import { omit } from 'lodash';

export default function formReducer(state, action) {
  if (action.type === 'change_mode_type') {
    const defaults = action.defaults.find(({ type }) => type === action.modeType);
    const cleanDefaults = omit(defaults, 'type', '_uuid');

    if (defaults) {
      Object.assign(state, cleanDefaults);
    }
  }

  return state;
}
