import React from 'react';

import styled from 'styled-components';
import { InputGroup } from 'react-bootstrap';
import { Field, ServerError } from '@controls/form';

const InputGroupText = styled(InputGroup.Text)`
  font-size: 0.8rem;
`;

export default function FieldWithText({
  name,
  text,
  type = 'number',
  className = '',
  ...rest
}) {
  return (
    <>
      <InputGroup className={`mb-1 ${className}`}>
        <InputGroupText>{text}</InputGroupText>
        <Field
          type={type}
          name={name}
          inline={false}
          {...rest}
        />
      </InputGroup>
      <ServerError name={name} />
    </>
  );
}
