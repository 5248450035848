import emptyStoryOrders from '@components/merge/services/emptyStoryOrders';
import React, { useCallback, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { Form, useConfirm } from '@hooks';
import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { combineReducers } from '@reducers';
import rewardsReducer from '@components/merge/reducers/rewardsReducer';
import targetsReducer from '@components/merge/reducers/targetsReducer';
import { MergeRoutes } from '@pages/routes';

import formReducer from './formReducer';
import Postcards from './form/Postcards';

const EmptyPostcardConfigurationsWarning = ({ postcards, invalidConfigurations }) => (
  <Alert variant="warning">
    {postcards.length > 0 && (
      <>
        <b>Some postcards are left unconfigured. They will not be sent to client:</b>
        <ul className="list-unstyled">
          {postcards.map((postcard) => (
            <li key={postcard._uuid}>{postcard.internalId}</li>
          ))}
        </ul>
      </>
    )}
    {invalidConfigurations.length > 0 && (
      <>
        <b>
          Story Orders in the following postcards have not been set up. Are you sure you want
          to save this Postcard set?
        </b>
        <ul className="list-unstyled">
          {invalidConfigurations.map((config) => (
            <li key={config._uuid}>{config.internalId}</li>
          ))}
        </ul>
      </>
    )}
    <b>Continue?</b>
  </Alert>
);

export default function PostcardSetsForm({ data, onSubmit, actionName }) {
  const { query } = useRouter();
  const confirm = useConfirm();
  const [postcardConfigurations, setPostcardConfigurations] = useState({});
  const setPostcardConfiguration = (position, config) => {
    setPostcardConfigurations((currentConfigs) => ({ ...currentConfigs, [position]: config }));
  };

  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(query.applicationId), []);

  const submitWithConfirmation = (values) => {
    const emptyPostcards = values.postcardSetPostcardsAttributes.filter((postcardSetPostcard) => (
      postcardSetPostcard.postcardId === null
    ));

    const invalidConfigurations = Object.values(postcardConfigurations).reduce((memo, config) => {
      if (!config) { return memo; }

      const emptyOrders = emptyStoryOrders(config);
      return emptyOrders.length === 0 ? memo : [...memo, config];
    }, []);

    return emptyPostcards && (emptyPostcards.length > 0 || invalidConfigurations.length > 0)
      ? (
        confirm.showConfirmation({
          title: 'Review changes before saving',
          body: (
            <EmptyPostcardConfigurationsWarning
              postcards={emptyPostcards}
              invalidConfigurations={invalidConfigurations}
            />
          ),
          confirmationText: 'Confirm',
          cancellationText: 'Cancel',
        })
          .then(() => onSubmit(values))
          .catch(() => { })
      ) : onSubmit(values);
  };

  return (
    <Form
      initialValues={data}
      onSubmit={submitWithConfirmation}
      reducer={combineReducers([formReducer, rewardsReducer, targetsReducer])}
    >
      {({ values, dispatch }) => {
        const populatePostcardSetPostcards = async (schemaId) => {
          const response = await MergeRoutes.PostcardSets.buildPostcardSetPostcardsRequest({
            applicationId: query.applicationId,
            schemaId,
          });
          dispatch({
            type: 'populate_postcard_set_postcards',
            postcardSetPostcardsAttributes: response.data.postcardSetPostcardsAttributes,
          });
        };

        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Postcard Set`}>
              <FormButtonsGroup
                cancelButtonPath={MergeRoutes.PostcardSets.indexPath({ applicationId: query.applicationId })}
              />
            </PageHeader>

            <Label text="Name"><Field type="text" name="name" /></Label>
            <Label text="Description"><Field type="text" name="description" /></Label>

            <Label text="World Schema">
              <SelectField
                name="schemaId"
                options={fetchSchemaOptions}
                onChange={(e) => populatePostcardSetPostcards(e.value)}
                isDisabled={actionName !== 'Create'}
              />
            </Label>

            <StaticFieldsFormGroup data={data} />

            {values.postcardSetPostcardsAttributes && values.schemaId && (
              <Postcards
                values={values.postcardSetPostcardsAttributes}
                schemaId={values.schemaId}
                setPostcardConfiguration={setPostcardConfiguration}
                postcardConfigurationsIndex={postcardConfigurations}
              />
            )}
          </>
        );
      }}
    </Form>
  );
}
