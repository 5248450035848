import React, { useState, useEffect, useMemo } from 'react';
import { useField } from 'formik';
import {
  find, isFunction, isNil, extend, isArray,
} from 'lodash';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useFlashMessages } from '@tripledotstudios/react-core';
import APP_DATA from '@services/appData';
import { buildQuickLink } from '@services/fields-factory/quickLinksUtils';
import {
  useFormGroup, useCurrentApplication, useConfirm, useFormContext, useQuery,
} from '@hooks';

import { AssetsRoutes } from '@pages/routes';
import InLiveBadge from '@pages/common/InLiveBadge';
import StatusBadge from '@pages/common/StatusBadge';
import RulesList from '@pages/common/RulesList';
import ReactTable from '@pages/common/ReactTable';
import { AutoCollapse } from '@pages/common/PartialCollapse';
import wrapArray from '@services/wrapArray';

import SelectField from './SelectField';

const bundleColumnsBuilder = () => [
  { Header: 'Bundle name', accessor: 'name', disableSortBy: true },
  { Header: 'Priority', accessor: 'priority', disableSortBy: true },
  {
    Header: 'Rules',
    accessor: 'rules',
    disableSortBy: true,
    Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} nestedForm />,
  },
];

const { enums } = APP_DATA;
const assetsKeysStatuses = enums['AssetsKeys::Statuses'];
const availabilityStates = enums.AvailabilityStates;

export default function AssetsKeySelectField({
  name,
  options,
  isDisabled,
  includeEmpty,
  selectSize = 8,
  showCategories = false,
  types,
  status,
  showStatuses = true,
  isMulti = false,
  isLoading = false,
}) {
  const { applicationId } = useCurrentApplication();
  const { generateName } = useFormGroup();
  const { sharedInputProps } = useFormContext();
  const fullName = generateName(name);
  const [field] = useField(fullName);
  const [assetsKeysOptions, setAssetsKeysOptions] = useState([]);
  const { success, error } = useFlashMessages();
  const confirm = useConfirm();
  const bundleColumns = useMemo(bundleColumnsBuilder, []);
  const disabled = isNil(isDisabled) ? sharedInputProps.disabled : isDisabled;

  const fetchKeys = async () => {
    if (options) { return isFunction(options) ? options() : options; }

    const params = {
      applicationId,
      includeCategories: showCategories,
      withoutPagination: true,
      ...(!isNil(status) && { status }),
    };
    if (!isNil(types)) {
      params.filter = {
        withTypesOrIds: {
          types: isNil(types) ? null : wrapArray(types),
          ids: isNil(field.value) ? null : wrapArray(field.value),
        },
      };
    }

    const response = await AssetsRoutes.Keys.indexRequest(params);
    return response.data.items;
  };

  const { response: assetKeysResponse, isLoadingAssets } = useQuery({
    request: fetchKeys,
    queryKey: ['assets', applicationId, types],
    placeholderData: [],
    staleTime: 60_000,
    processResponse: (r) => r,
  });

  const assetKeys = isArray(options) ? options : assetKeysResponse;

  useEffect(() => {
    if (!assetKeys) return;

    const keysOptions = assetKeys.map(({
      uid, statusHumanize, id, humanizedAvailabilityState,
    }) => ({
      label: `${uid} (${statusHumanize}, ${humanizedAvailabilityState})`, value: id,
    }));
    setAssetsKeysOptions(keysOptions);
  }, [isFunction(options) ? options : JSON.stringify(assetKeys)]);

  const assetsKey = find(assetKeys, ['id', field.value]);

  const isAssetKeyActive = () => !assetsKey || assetsKey.status === assetsKeysStatuses.ACTIVE;
  const isAssetKeyLive = () => !assetsKey || assetsKey.inLive;

  const updateActivatedAssetsKeyUI = (newParams) => {
    extend(assetsKey, newParams);
    const assetsKeyOption = find(assetsKeysOptions, ['value', field.value]);
    assetsKeyOption.label = `${assetsKey.uid} (${assetsKey.statusHumanize}, ${assetsKey.humanizedAvailabilityState})`;
  };

  const updateAsset = ({ requestParams, entityParams }) => {
    AssetsRoutes.Keys.partialUpdateRequest({
      applicationId,
      id: field.value,
      ...requestParams,
    }).then((response) => {
      if (response.status === 204) {
        updateActivatedAssetsKeyUI({ ...requestParams, ...entityParams });
        success('Assets key has been updated successfully');
      } else {
        error('Assets key update has failed');
      }
    });
  };

  const onActivateAsset = () => {
    confirm.showConfirmation({ title: 'The asset will be activated and downloaded by client. Continue?' })
      .then(() => updateAsset({
        requestParams: { status: assetsKeysStatuses.ACTIVE },
        entityParams: { statusHumanize: 'Active' },
      }));
  };

  const onMakingAssetLive = () => {
    confirm.showConfirmation({ title: 'The asset will become available for Live entities. Continue?' })
      .then(() => updateAsset({
        requestParams: { availabilityState: availabilityStates.LIVE },
        entityParams: { humanizedAvailabilityState: 'Live', inLive: true },
      }));
  };

  return useMemo(() => (
    <>
      <Row>
        <Col xs={selectSize + Number(isAssetKeyActive()) * 2 + Number(isAssetKeyLive()) * 2}>
          <SelectField
            name={name}
            options={assetsKeysOptions}
            isLoading={isLoading || isLoadingAssets}
            isDisabled={disabled}
            isMulti={isMulti}
            quickLinkFn={buildQuickLink(AssetsRoutes.Keys, applicationId)}
            onReload={({ selected }) => selected && setAssetsKeysOptions(assetsKeysOptions)}
            includeEmpty={includeEmpty}
          />
          {showStatuses && assetsKey && (
            <>
              <div className="mb-2" />
              <p>
                <strong className="me-2">Status:</strong>
                <StatusBadge
                  options={assetsKeysStatuses}
                  value={assetsKey.status}
                  label={assetsKey.statusHumanize}
                />
              </p>
              <p>
                <strong className="me-2">Availability:</strong>
                <InLiveBadge inLive={assetsKey.inLive} />
              </p>
            </>
          )}
        </Col>
        {!isAssetKeyActive() && (
          <Col xs={12 - selectSize - 2}>
            <Button
              disabled={disabled}
              onClick={onActivateAsset}
            >
              Activate
            </Button>
          </Col>
        )}
        {!isAssetKeyLive() && (
          <Col xs={12 - selectSize - 2}>
            <Button
              disabled={disabled}
              onClick={onMakingAssetLive}
            >
              Live
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          {!isAssetKeyActive() && (
            <span className="text-muted">
              Inactive bundles are not available for client to download
            </span>
          )}
        </Col>
      </Row>
      {assetsKey && (
        <>
          {assetsKey.bundles && (
            <Row className="mt-3">
              <Col>
                <ReactTable
                  columns={bundleColumns}
                  data={assetsKey.bundles}
                />
              </Col>
            </Row>
          )}
          {showCategories && assetsKey.categories.length > 0 && (
            <>
              Used in Categories:
              <AutoCollapse minHeight={65}>
                {assetsKey.categories.map(({
                  id, position, categorySetName, path,
                }) => (
                  <Link key={id} to={path} target="_blank" className="d-block">
                    {`Category ${position} in Category Set ${categorySetName}`}
                  </Link>
                ))}
              </AutoCollapse>
            </>
          )}
        </>
      )}
    </>
  ), [disabled, assetsKeysOptions, assetKeys, JSON.stringify(assetsKey)]);
}
