import React from 'react';

import ReactSelect from 'react-select';
import classNames from 'classnames';
import { isFunction } from 'lodash';

import EMPTY_OPTION from './form/emptyOption';

export default function Select({
  selectedValue,
  options,
  isMulti,
  onChange,
  onBlur,
  hasPrepopulatedOption,
  placeholder,
  className,
  onKeyDown,
  isClearable,
  isDisabled,
  includeEmpty,
  groupByFn,
  filterOption,
  autoFocus,
  components,
  testId,
  ...extraProps
}) {
  const filterSelectedValues = () => {
    if (selectedValue === undefined || selectedValue === null) return hasPrepopulatedOption ? options[0] : [];
    if (isMulti) { return options.filter((option) => selectedValue.includes(option.value)); }
    return options.filter((option) => selectedValue === option.value);
  };

  const groupedResolvedOptions = isFunction(groupByFn) ? groupByFn(options) : options;

  return (
    <div data-testid={testId}>
      <ReactSelect
        value={filterSelectedValues()}
        options={includeEmpty ? [EMPTY_OPTION, ...groupedResolvedOptions] : groupedResolvedOptions}
        isMulti={isMulti}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        className={classNames('react-select', className)}
        classNamePrefix="react-select"
        isClearable={isClearable}
        isDisabled={isDisabled}
        filterOption={filterOption}
        autoFocus={autoFocus}
        components={components}
        {...extraProps}
      />
    </div>
  );
}
