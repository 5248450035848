/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Spinner } from '@tripledotstudios/react-core';

import { useQuery, useI18n } from '@hooks';
import { MissionsRoutes } from '@pages/routes';

export default function CurrentMissionsModal({ show, handleClose, allocationInstanceId }) {
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  const ModalWithMissions = ({ data }) => (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{`Current ${namespaceName}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data ? (
          <pre>{data}</pre>
        ) : <Spinner />}
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="secondary" onClick={handleClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );

  if (!allocationInstanceId) {
    return <ModalWithMissions data={`No allocation has run today for this ${namespaceName} allocation`} />;
  }

  const { response } = useQuery(MissionsRoutes.AllocationInstances.showRequest, { id: allocationInstanceId });

  return <ModalWithMissions data={response && response.data} />;
}
