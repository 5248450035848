import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { SpiderSolitaireRoutes } from '@pages/routes';

import Form from './Form';

export default function CreateUpdateComponent({ getFunction, postFunction, actionName }) {
  const [data, setData] = useState();
  const { query } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'World statistics config',
    actionName,
    redirectPath: SpiderSolitaireRoutes.WorldStatsConfig.indexPath({ applicationId: query.applicationId }),
    setData,
  });

  useEffect(() => {
    getFunction(query).then((response) => setData(response.data));
  }, []);

  const onSubmit = (values) => postFunction(values).then(responseHandler);

  return data ? <Form initialValues={data} onSubmit={onSubmit} /> : '';
}
