import React from 'react';
import { get, isEqual } from 'lodash';

import {
  useFormContext, FormGroup, useCurrentApplication, useQuery,
} from '@hooks';
import {
  Label, Field, SelectField, AssetsKeySelectField,
} from '@controls/form';
import { LocalizationsRoutes } from '@pages/routes';

import LevelBanks from '@pages/journeys/level-banks/EventsBlock';

export const ConfigurationVariant = React.memo(({
  values, defaultVariant, variantIndex, isArchived,
}) => {
  const { dispatch } = useFormContext();
  const { applicationId } = useCurrentApplication();

  const { response: localizationKeys } = useQuery({
    request: LocalizationsRoutes.Keys.indexRequest,
    queryKey: ['localizationKeys', applicationId],
    toSelectOptions: true,
  });

  const onBankAdd = (id) => dispatch({ actionType: 'addLevelBankToJourney', id, variantIndex });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeLevelBankFromJourney', id, variantIndex,
  });

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Default Title">
        <Field type="text" name="defaultTitle" />
      </Label>
      <Label text="Default Subtitle">
        <Field type="text" name="defaultSubtitle" />
      </Label>
      <Label text="iOS Asset Bundle URL">
        <Field type="text" name="iosAssetBundleUrl" />
      </Label>
      <Label text="Android Asset Bundle URL">
        <Field type="text" name="androidAssetBundleUrl" />
      </Label>
      <Label text="Amazon Asset Bundle URL">
        <Field type="text" name="amazonAssetBundleUrl" />
      </Label>
      <Label text="Title Translation Key">
        <SelectField name="titleTranslationKeyId" options={localizationKeys} />
      </Label>
      <Label text="Subtitle Translation Key">
        <SelectField name="subtitleTranslationKeyId" options={localizationKeys} />
      </Label>
      <Label text="Asset: Main Bundle">
        <AssetsKeySelectField
          name="assetKeyId"
        />
      </Label>
      <Label text="Asset: Rewards Bundle">
        <AssetsKeySelectField name="assetRewardsKeyId" />
      </Label>

      <LevelBanks
        disabled={isArchived}
        variantAttributes={get(values, variantAttributesKey, values)}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
        eventType="event"
      />
    </FormGroup>
  );
});

const SudokuConfigurationForm = React.memo(({ values, defaultVariant = false, isArchived }) => (
  <>
    <Label text="Minimum supported version">
      <Field type="text" name="minimumSupportedVersion" />
    </Label>

    <ConfigurationVariant values={values} defaultVariant={defaultVariant} isArchived={isArchived} />
  </>
), (prev, next) => isEqual(prev.values, next.values));

export default SudokuConfigurationForm;
