import React, { useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  Label, Field, FormButtonsGroup, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader, Publish } from '@pages/common';
import { MergeRoutes } from '@pages/routes';
import { combineReducers } from '@reducers';
import rewardsReducer from '@components/merge/reducers/rewardsReducer';

import ProductFrom from './form/ProductFrom';
import formReducer from './formReducer';

export default function ProductsForm({ data, onSubmit, actionName }) {
  const { query } = useRouter();
  const [publishInfo, setPublishInfo] = useState(data.publishInfo);

  const onPublish = () => {
    MergeRoutes.Products.publishRequest(query).then(() => {
      setPublishInfo(null);
    });
  };

  const {
    defaults,
    ...otherData
  } = data;

  return (
    <Form
      initialValues={otherData}
      onSubmit={onSubmit}
      reducer={combineReducers([formReducer, rewardsReducer])}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Product`}>
            <FormButtonsGroup
              cancelButtonPath={MergeRoutes.Products.indexPath({
                applicationId: query.applicationId,
              })}
            />
          </PageHeader>
          <Publish info={publishInfo} onPublish={onPublish} />

          <Label text="Name">
            <Field type="text" name="name" />
          </Label>
          <Label text="Description">
            <Field type="text" name="description" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <hr />
          <ProductFrom
            values={values}
            defaults={defaults}
            isNewRecord={actionName === 'Create'}
          />
        </>
      )}
    </Form>
  );
}
