import React, { useState } from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  PageHeader,
  Pagination,
  ReactTable,
  InUseColumn,
  TIMESTAMP_COLUMNS,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { useQuery, useConfirm, useCurrentApplication } from '@hooks';
import { isInUse } from '@pages/common/InUse';
import { AppEventsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import EditModal from './EditModal';
import NewModal from './NewModal';
import Filter from './AppEventsFilter';

const entityName = 'App Events';

export const AppEventsColumns = ({ onDelete, onEdit }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Key', accessor: 'key' },
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    style: { width: 150 },
    Cell: ({ row: { original: { id, key, inUse } } }) => {
      const disabled = isInUse(inUse);
      return (
        <ButtonToolbar>
          <IconButton.Edit
            onClick={() => onEdit(id)}
            title={disabled ? 'Disabled as the entity is used' : null}
            disabled={disabled}
            minimized
          />
          <IconButton.Delete
            onClick={onDelete(id, key)}
            title={disabled ? 'Disabled as the entity is used' : null}
            disabled={disabled}
            minimized
          />
        </ButtonToolbar>
      );
    },
  },
];

export default function Index() {
  const { indexRequest, deleteRequest } = AppEventsRoutes;
  const { response, refetch } = useQuery(indexRequest, { includeInUse: true });
  const { currentApplication } = useCurrentApplication();
  const [editedRecord, setEditedRecord] = useState(null);
  const [newRecord, setNewRecord] = useState(false);
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName,
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, key) => () => {
    confirm.showConfirmation({
      title: `App Event '${key}' will be deleted and not available for work. Continue?`,
    })
      .then(() => deleteRequest({ id, applicationId: currentApplication.id })
        .then(responseHandler));
  };

  const columns = React.useMemo(() => AppEventsColumns({ onDelete, onEdit: setEditedRecord }), []);

  return response && (
    <div>
      <PageHeader title="App Events" filter={<Filter />}>
        <IconButton.New onClick={() => setNewRecord(true)} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'key', desc: true }}
      />
      <NewModal
        entityName={entityName}
        onHide={() => setNewRecord(false)}
        record={newRecord}
        refetch={refetch}
      />
      <EditModal
        entityName={entityName}
        onHide={() => setEditedRecord(null)}
        record={editedRecord}
        refetch={refetch}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
