import React from 'react';

import styled from 'styled-components';

import { get } from 'lodash';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { ServerError } from '@controls/form';
import { useFormContext } from '@hooks';
import MissionTypeOrderIcon from './MissionTypeOrderIcon';

const MissionName = styled.span`
  ${({ hasErrors }) => hasErrors && 'color: red;'};
`;

export default function NameColumn({ index, rowData, missionTypeOrders }) {
  const { meta } = useFormContext();
  const { missionTypeName, missionTypeOrder } = rowData;
  const missionTypeOrderIconMapping = {
    [missionTypeOrders.ASCENDING]: { icon: faArrowUp, color: 'green' },
    [missionTypeOrders.DESCENDING]: { icon: faArrowDown, color: 'red' },
  };

  const hasErrors = get(meta, `errors.targetsAttributes.${index}`);

  return (
    <>
      <MissionName
        className="pe-1"
        hasErrors={hasErrors}
      >
        {missionTypeName}
      </MissionName>
      <MissionTypeOrderIcon
        order={missionTypeOrder}
        iconMapping={missionTypeOrderIconMapping}
      />
      <ServerError name={`targetsAttributes.${index}.missionTypeId`} />
    </>
  );
}
