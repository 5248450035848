import {
  fetchCollection, newResource, editResource, createResourceMultipart, updateResourceMultipart, deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/payments/product_images`;

export function getProductImages(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function newProductImage(options) {
  return newResource(baseUrl(options), options);
}

export function editProductImage(options) {
  return editResource(baseUrl(options), options);
}

export function createProductImage(values) {
  return createResourceMultipart(baseUrl(values), values);
}

export function updateProductImage(values) {
  return updateResourceMultipart(baseUrl(values), values);
}

export function deleteProductImage(values) {
  return deleteResource(baseUrl(values), values);
}
