import React, { useState, useMemo } from 'react';

import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import {
  useQuery,
  useI18n,
  useConfirm,
  useCurrentApplication,
  useMassOperations,
} from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  PageHeader,
  Pagination,
  Badge,
  ReactTable,
  TIMESTAMP_COLUMNS,
  RulesList,
  InLiveColumn,
  DuplicationModalWithPriority,
  LabelsColumn,
  PriorityColumn,
} from '@pages/common';
import IconButton from '@controls/buttons';
import Comparison from '@components/shared/items-comparison/Comparison';
import { fromClassNameToOptions } from '@services/enums';

import Filter from './DatesBasedFilter';

const SUPPORTS_COMPARISON = ['tile'];

const { enums } = APP_DATA;
const statuses = enums['Journeys::Statuses'];

const statusBadgeColor = (status) => {
  const colorMapping = {
    [statuses.ACTIVE]: 'success',
    [statuses.INACTIVE]: 'secondary',
    [statuses.ARCHIVED]: 'secondary',
  };

  return colorMapping[status];
};

const columnsFactory = ({
  applicationId, setDuplicatedRecord, onArchive, routes, hiddenColumns,
}) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Variant ID', accessor: 'defaultVariantId', disableSortBy: true },
  { Header: 'Client ID', accessor: 'clientId', disableSortBy: true },
  { Header: 'Name', accessor: 'name' },
  LabelsColumn,
  {
    Header: 'Status',
    Cell: ({ row: { original: { status, statusHumanize } } }) => (
      <Badge bg={statusBadgeColor(status, statuses)}>{statusHumanize}</Badge>
    ),
  },
  InLiveColumn,
  PriorityColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Start for client',
    accessor: 'startForClient',
    style: { minWidth: '6em' },
  },
  {
    Header: 'Start for player',
    accessor: 'startAt',
    style: { minWidth: '6em' },
  },
  {
    Header: 'End for player/client',
    accessor: 'endAt',
    style: { minWidth: '6em' },
  },
  {
    Header: 'Rules',
    Cell: ({ row: { original: { ruleSectionsAttributes } } }) => <RulesList rules={ruleSectionsAttributes} />,
  },
  {
    Header: 'Actions',
    Cell: ({
      row: {
        original: {
          id, name, status, eventType,
        },
      },
    }) => (
      <ButtonToolbar>
        <IconButton.Edit to={routes.editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name, eventType })} minimized />
        {status !== statuses.ARCHIVED && <IconButton.Archive onClick={onArchive(id, name)} minimized />}
      </ButtonToolbar>
    ),
  },
].filter((column) => !hiddenColumns.includes(column.accessor));

const DatesBasedIndex = ({
  routes,
  localePath,
  massOperationsModel,
  hiddenColumns = [],
}) => {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { currentApplication } = useCurrentApplication();
  const {
    MassOperationsHeaderButtons,
    MassOperationsModal,
    massOperationsTableProps,
    selectedItems: massSelectedItems,
  } = useMassOperations();

  const { translate } = useI18n();
  const modelName = translate.fallback(`${localePath}.name`);
  const showComparison = SUPPORTS_COMPARISON.includes(currentApplication.typeName);

  const { query: { applicationId } } = useRouter();
  const { response, refetch, isLoading } = useQuery(routes.indexRequest);
  const confirm = useConfirm();

  const archiveResponseHandler = collectionResponseHandlerFactory({
    entityName: modelName,
    actionName: 'archive',
    refetch,
  });

  const onArchive = (id, name) => () => {
    confirm.showConfirmation({
      title: `${modelName} '${name}' will be archived and not available for work. Continue?`,
    })
      .then(() => routes.archiveRequest({ applicationId, id })
        .then(archiveResponseHandler));
  };

  const columns = useMemo(() => columnsFactory({
    applicationId, setDuplicatedRecord, onArchive, routes, hiddenColumns,
  }), []);

  return response && (
    <>
      <PageHeader
        title={translate.fallback(`${localePath}.pluralName`)}
        filter={<Filter statuses={statuses} />}
        menuButtons={(
          <>
            <MassOperationsHeaderButtons>
              <IconButton.LookerJourney
                entityType={massOperationsModel}
                entityIds={massSelectedItems}
                disabled={!massSelectedItems.length}
              />
            </MassOperationsHeaderButtons>

            {showComparison && (
              <Comparison
                routes={routes}
                modelName="Journeys::Event"
                localePath={localePath}
                rulesWarning
              />
            )}
          </>
        )}
      >
        <IconButton.New to={routes.newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? <Spinner /> : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
            {...massOperationsTableProps}
          />
          <MassOperationsModal
            pageModelName={translate.fallback(`${localePath}.pluralName`)}
            modelName={massOperationsModel}
            statusOptions={fromClassNameToOptions('Journeys::Statuses')}
            refetch={refetch}
            transactional
            includeAvailabilityState
          />
          <DuplicationModalWithPriority
            record={duplicatedRecord}
            entityName={modelName}
            handleClose={() => setDuplicatedRecord(null)}
            onSubmit={(values) => routes.duplicateRequest({ ...values, applicationId })}
            entitiesByPriority={response.entitiesByPriority || {}}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </>
  );
};

export default DatesBasedIndex;
