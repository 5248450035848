import React, { useState, useEffect } from 'react';
import { Alert, Spinner, useRouter } from '@tripledotstudios/react-core';

import { LocalizationsRoutes } from '@pages/routes';
import { useFormContext } from '@hooks';
import APP_DATA from '@services/appData';

import { Table, Pagination } from './base-table';
import useAvailabilityTypes from './shared/services/useAvailabilityTypes';
import Filter from './Filter';

const { featureToggles: { remoteLocalizations } } = APP_DATA;

export default function BaseTable() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();
  const { values: { keyConfigs: changedTranslationKeys } } = useFormContext();
  const { type, isTest } = useAvailabilityTypes();

  const fetchTranslations = async () => {
    setIsLoading(true);

    const changedKeyIds = changedTranslationKeys.map(({ id }) => id);
    const response = await LocalizationsRoutes.Translations
      .indexRequest({
        ...router.query,
        includeInUse: true,
        exclude_by: { id: changedKeyIds },
      });

    setData(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTranslations();
  }, [changedTranslationKeys.length, type, router.location.key]);

  return data ? (
    <>
      <Filter />
      <p />
      {isTest && (
        <Alert variant="info">
          Double click to Edit &bull; Shift+Enter for New line &bull; Enter to Confirm &bull; Esc to Cancel
        </Alert>
      )}
      {remoteLocalizations.enabled && (
        <Alert variant="warning">
          You can now find this feature in the new Localizations server
          <a
            href={data.localisationsServerUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="ms-2"
          >
            here
          </a>
        </Alert>
      )}
      <Pagination
        pageCount={data._meta.pages}
        isTableChanged={changedTranslationKeys.length > 0}
      />
      <p />
      {isLoading ? <Spinner /> : <Table translationKeys={data.keyConfigs} />}
    </>
  ) : <Spinner />;
}
