import React from 'react';
import { rgba } from 'polished';

import styled, { useTheme } from 'styled-components';
import MultilineContent from '@controls/MultilineContent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const ColoredContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  background-color: ${({ color }) => rgba(color, 0.15)};
`;

const Mark = styled.span`
  width: 0.75rem;
  margin-right: 0.25rem;
`;

export default function CellWithDiff({
  newValue,
  oldValue,
  valueWrapper,
}) {
  const theme = useTheme();
  const showDiff = oldValue === '' || oldValue === 0 || oldValue;

  return showDiff ? (
    <>
      <ColoredContainer color={theme.variants.danger}>
        <Mark><FontAwesomeIcon icon={faMinus} size="xs" /></Mark>
        <MultilineContent value={oldValue} wrapper={valueWrapper} />
      </ColoredContainer>
      <ColoredContainer color={theme.variants.success}>
        <Mark><FontAwesomeIcon icon={faPlus} size="xs" /></Mark>
        <MultilineContent value={newValue} wrapper={valueWrapper} />
      </ColoredContainer>
    </>
  ) : <MultilineContent value={newValue} wrapper={valueWrapper} />;
}
