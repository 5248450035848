import React from 'react';

import { useConfirm } from '@hooks';
import { InUseCollapse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';
import { Tr, Td } from '../shared/styled-components';
import { FormatWrapper } from '../shared';
import Translations from './Translations';

import Cell from './Cell';

export default function Row({ data, setKeyIdsToSkip }) {
  const {
    name,
    format,
    description,
    inUse,
    translationsAttributes,
    oldValues,
    newKey,
    inactive,
    keyId,
  } = data;

  const confirm = useConfirm();

  const onUndo = () => confirm
    .showConfirmation({ title: `Key '${name}' will not be applied to live translations. Continue?` })
    .then(() => {
      setKeyIdsToSkip((previous) => [...previous, keyId]);
    });

  return (
    <Tr isNewRecord={newKey} removed={inactive}>
      <Td>
        <IconButton.Undo onClick={onUndo} className="ms-1" minimized />
      </Td>
      <Cell
        name="name"
        newValue={name}
        oldValues={oldValues}
        sticky
      />
      <Cell
        name="format"
        newValue={format}
        oldValues={oldValues}
        valueWrapper={FormatWrapper}
      />
      <Td><InUseCollapse inUse={inUse} /></Td>
      <Cell
        name="description"
        newValue={description}
        oldValues={oldValues}
      />
      <Translations
        translationsAttributes={translationsAttributes}
        oldValues={oldValues}
      />
    </Tr>
  );
}
