import React from 'react';
import { Col } from 'react-bootstrap';

import APP_DATA from '@services/appData';

import { Filter, TextFilter, StatusFilter } from '@pages/common';
import { fromClassNameToOptions } from '@services/enums';

const statuses = APP_DATA.enums['Woodoku::BonusLevels::LevelBankStatuses'];
const statusOptions = fromClassNameToOptions('Woodoku::BonusLevels::LevelBankStatuses');
const defaultStatuses = [statuses.ACTIVE];

export default function BonusLevelBanksFilter() {
  return (
    <Filter defaultComponents={['availabilityStateIn', 'createdByMe', 'labels', 'id']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <StatusFilter
              name="statusIn"
              label="Status"
              updateFilterField={updateFilterField}
              options={statusOptions}
              defaults={defaultStatuses}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
