import React from 'react';
import { Alert, ButtonToolbar } from '@tripledotstudios/react-core';

import { Pagination } from '@pages/common';
import { useFormContext } from '@hooks';
import DownloadCSV from './DownloadCSV';
import UploadCSV from './UploadCSV';
import useAvailabilityTypes from '../shared/services/useAvailabilityTypes';

export default function BaseTablePagination({ pageCount, refetch, isTableChanged }) {
  const { dispatch } = useFormContext();
  const { isTest } = useAvailabilityTypes();

  return (
    <Pagination pageCount={pageCount} refetch={refetch}>
      <ButtonToolbar>
        <DownloadCSV isTableChanged={isTableChanged} />
        {isTest && <UploadCSV dispatch={dispatch} isTableChanged={isTableChanged} />}

        {isTableChanged && (
          <Alert
            className="m-0 pt-2 pb-0"
            variant="warning"
          >
            Please save changes and then download or upload translations
          </Alert>
        )}
      </ButtonToolbar>
    </Pagination>
  );
}
