import { create as createSocketclusterClient } from 'socketcluster-client';

import socketclusterConfig from '@config/socketcluster';

class WsClient {
  constructor({ onConnect, onDisconnect }) {
    const { host, port } = socketclusterConfig;
    const isSecure = port === 443;

    this.client = createSocketclusterClient({
      path: '/socketcluster',
      hostname: host,
      port,
      secure: isSecure,
      autoConnect: false,
    });

    const handleConnect = onConnect || (() => { });
    const handleDisconnect = onDisconnect || (() => { });

    this.startListener('connect', handleConnect);
    this.startListener('disconnect', handleDisconnect);

    this.client.connect();
  }

  startListener(eventName, callback) {
    this.constructor.startAsyncIterator(this.client.listener(eventName), callback);
  }

  subscribe(eventName, callback) {
    this.constructor.startAsyncIterator(this.client.subscribe(eventName), callback);
  }

  static startAsyncIterator(stream, callback) {
    (async () => {
      // eslint-disable-next-line no-restricted-syntax
      for await (const data of stream) {
        callback(data);
      }
    })();
  }
}

export default function createClient(data) {
  return new WsClient(data);
}
