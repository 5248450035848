export default function checkAvailabilityOnSubmit({
  values, onSubmit, availabilityStatesEnum, confirm,
}) {
  if (!values.availabilityState || values.availabilityState !== availabilityStatesEnum.LIVE) return onSubmit(values);

  return confirm.showConfirmation({
    title: 'Confirm to save',
    body: 'All the linked entities will have their Availability state changed to "Live" and will become '
      + 'available to players. Continue?',
    confirmationText: 'Confirm',
    cancellationText: 'Cancel',
  })
    .then(() => onSubmit(values))
    .catch(() => { });
}
