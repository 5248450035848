import React from 'react';
import { createRoot } from 'react-dom/client';

// Uncomment the following line if you want to focus on unneeded renders optimization.
// import '@services/wdyr';

import '@services/disableInputScroll';
import App from '@pages/App';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('app');

  const root = createRoot(container);
  root.render(<App />);
});
