import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormGroup, useFormContext } from '@hooks';
import { Label, Field } from '@controls/form';
import IconButton from '@controls/buttons';
import APP_DATA from '@services/appData';

const typesEnum = APP_DATA.enums['WoodokuSaga::ImpactAreaTypes'];
const typesWithAdd = [typesEnum.COLUMN, typesEnum.ROW, typesEnum.CUSTOM];

const Point = ({
  xDisabled = false, yDisabled = false, disabled = false, onXChange = () => null, onYChange = () => null,
}) => {
  const { handleChange } = useFormContext();

  return (
    <Col xs="6" className="p-0">
      <Row>
        <Col xs="6">
          <Label text="X" labelSize={3} fieldSize={9}>
            <Field
              name="x"
              type="number"
              step="1"
              disabled={disabled || xDisabled}
              onChange={(event) => {
                handleChange(event);

                return onXChange(event);
              }}
            />
          </Label>
        </Col>
        <Col xs="6">
          <Label text="Y" labelSize={3} fieldSize={9}>
            <Field
              name="y"
              type="number"
              step="1"
              disabled={disabled || yDisabled}
              onChange={(event) => {
                handleChange(event);

                return onYChange(event);
              }}
            />
          </Label>
        </Col>
      </Row>
    </Col>

  );
};

const DynamicPoint = ({
  dispatch, areaIndex, index, pointsCount, disabled, ...props
}) => (
  <>
    <Point disabled={disabled} {...props} />
    <Col xs="1">
      <IconButton.Delete
        onClick={() => dispatch({ type: 'removePoint', areaIndex, index })}
        minimized
        size="sm"
        outline
        disabled={disabled || pointsCount === 1}
      />
    </Col>
  </>
);

const RowPoint = (props) => <DynamicPoint xDisabled {...props} />;
const ColumnPoint = (props) => <DynamicPoint yDisabled {...props} />;

const CirclePoint = ({
  disabled, areaIndex, index, dispatch,
}) => (
  <>
    <Point
      disabled={index > 0 || disabled}
      onXChange={({ target: { value } }) => index === 0 && dispatch({ type: 'circleXChange', areaIndex, value })}
      onYChange={({ target: { value } }) => index === 0 && dispatch({ type: 'circleYChange', areaIndex, value })}
    />
    {index === 0 && (
      <Col xs="4">
        <Label text="Radius" labelSize={4} fieldSize={7}>
          <Field
            name="radius"
            type="number"
            step="1"
            disabled={disabled}
            onChange={({ target: { value } }) => dispatch({ type: 'radiusChange', areaIndex, value })}
          />
        </Label>
      </Col>
    )}
  </>
);

const typePointComponents = {
  [typesEnum.ROW]: RowPoint,
  [typesEnum.COLUMN]: ColumnPoint,
  [typesEnum.CIRCLE]: CirclePoint,
  [typesEnum.RECT]: Point,
  [typesEnum.CUSTOM]: DynamicPoint,
};

export default function Points({
  areaIndex, type, points, disabled,
}) {
  const { dispatch } = useFormContext();

  const dynamicPoints = typesWithAdd.indexOf(type) > -1;
  const PointComponent = typePointComponents[type] || Point;

  return (
    <>
      {points.map((_, index) => (
        <FormGroup name={`points[${index}]`}>
          <Row>
            <PointComponent
              pointsCount={points.length}
              areaIndex={areaIndex}
              index={index}
              dispatch={dispatch}
              disabled={disabled}
            />
          </Row>
        </FormGroup>
      ))}
      {dynamicPoints && (
        <div>
          <IconButton.New onClick={() => dispatch({ type: 'addPoint', areaIndex })} disabled={disabled}>
            Add
          </IconButton.New>
        </div>
      )}
    </>
  );
}
