import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { set } from 'lodash';

import {
  Form,
  FormGroup,
  useCurrentApplication,
  useRewardOptions,
} from '@hooks';
import Tooltip from '@controls/Tooltip';
import {
  FormButtonsGroup,
  Label,
  Field,
  ServerError,
  StaticFieldsFormGroup,
} from '@controls/form';

import { PageHeader, Rewards, PageSection } from '@pages/common';
import { rewardableReducer, combineReducers } from '@reducers';

import LevelSetsMassOperations from '@pages/level-sets/MassOperations';
import { SpiderSolitaireRoutes } from '@pages/routes';

import formReducer from './formReducer';

/* eslint-disable no-param-reassign */
const prepareLevelAttributes = (collection = []) => {
  if (!collection.length) {
    return [];
  }

  collection.slice(1).map((level, i) => {
    const diff = level.xpToUnlock - collection[i].xpToUnlock;

    set(level, 'xpDiff', diff);
    return level;
  });

  set(collection[0], 'xpDiff', collection[0].xpToUnlock);

  return collection;
};
/* eslint-enable no-param-reassign */

export default function PlayerLevelsSetForm({ initialValues, onSubmit }) {
  const { currentApplication } = useCurrentApplication();
  const applicationId = currentApplication.id;

  const rewardItems = useRewardOptions();

  prepareLevelAttributes(initialValues.levelsAttributes.filter(({ _destroy }) => !_destroy));

  const onSubmitWithSanitizedParams = (values) => {
    const firstLevel = values.levelsAttributes.filter(({ _destroy }) => !_destroy)[0];

    if (firstLevel) {
      set(firstLevel, 'rewardAffiliationsAttributes', []);
    }

    onSubmit(values);
  };

  const prepareLevels = (levels) => levels.reduce((memo, level, index) => {
    if (level._destroy) { return memo; }

    memo.push([level, index]);
    return memo;
  }, []);

  return (
    <Form
      initialValues={{ applicationId, ...initialValues }}
      onSubmit={(values) => onSubmitWithSanitizedParams(values)}
      reducer={combineReducers([formReducer, rewardableReducer])}
    >
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${values.id ? 'Edit' : 'New'} Player Levels Set`}>
            <FormButtonsGroup
              cancelButtonPath={SpiderSolitaireRoutes.PlayerLevelSets.indexPath({ applicationId })}
            />
          </PageHeader>

          <Label text="Name">
            <Field name="name" type="text" />
          </Label>

          <StaticFieldsFormGroup data={initialValues} />

          <div>
            <PageSection title="Mass operations to levels" />
            <LevelSetsMassOperations
              hideQuantityUntilSelected
              values={values}
              dispatch={dispatch}
              diffName="XP"
              rewardItems={rewardItems}
            />
          </div>

          <div>
            <PageSection title="Levels" />
            <p><ServerError name="levelsAttributes.list" /></p>
            <FormGroup name="levelsAttributes">
              <Table>
                <thead>
                  <tr>
                    <th>
                      <Tooltip text="The players level">
                        <span>Number</span>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip text="Total amount of XP required to unlock a level">
                        <span>Total XP</span>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip text="XP diff with the previous level">
                        <span>XP diff</span>
                      </Tooltip>
                    </th>
                    <th>
                      <Tooltip text="The reward that the player will get when reach that level">
                        <span>Rewards</span>
                      </Tooltip>
                    </th>
                    <th>
                      <span>Actions</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {prepareLevels(values.levelsAttributes).map(([level, index], showIndex) => {
                    const {
                      position, xpToUnlock, _uuid, _destroy,
                    } = level;

                    return !_destroy && (
                      <FormGroup name={`${index}`}>
                        <tr key={_uuid}>
                          <td>{position}</td>
                          <td>
                            {xpToUnlock}
                            <ServerError name="xpToUnlock" />
                          </td>
                          <td>
                            <Field
                              name="xpDiff"
                              type="number"
                              onKeyUp={() => dispatch({ actionType: 'recalculate_total_xp' })}
                            />
                          </td>
                          <td>
                            {showIndex !== 0 && (
                              <Rewards
                                hideQuantityUntilSelected
                                dispatch={dispatch}
                                rewardable={level}
                                rewardItems={rewardItems}
                              />
                            )}
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => dispatch({ actionType: 'delete_level', uuid: _uuid })}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      </FormGroup>
                    );
                  })}
                </tbody>
              </Table>
              <div className="text-end">
                <Button onClick={() => dispatch({ actionType: 'add_level' })}>Add</Button>
              </div>
            </FormGroup>
          </div>
        </>
      )}
    </Form>
  );
}
