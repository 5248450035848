import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from 'react-router-dom';

import { PageTitle } from '@pages/common';
import RouteContainer from '@pages/RouteContainer';
import SystemHome from '@pages/system/Home';

import buildTesterDeviceRoutes from '@pages/routes/testerDevices';
import buildAppEventRoutes from '@pages/routes/appEvents';
import buildCommonRoutes from '@pages/routes/common';
import buildGameSettingsRoutes from '@pages/routes/gameSettings';
import buildBlackjackRoutes from '@pages/routes/blackjack';
import buildPuzzleTimeRoutes from '@pages/routes/puzzleTime';
import buildSolitaireRoutes from '@pages/routes/solitaire';
import buildSolitaireScapesRoutes from '@pages/routes/solitaireScapes';
import buildWoodokuRoutes from '@pages/routes/woodoku';
import buildMyPetCafeRoutes from '@pages/routes/myPetCafe';
import buildSudokuRoutes from '@pages/routes/sudoku';
import buildTileRoutes from '@pages/routes/tile';
import buildMatch3dRoutes from '@pages/routes/match3d';
import buildWoodokuSagaRoutes from '@pages/routes/woodokuSaga';
import buildSpiderSolitaireRoutes from '@pages/routes/spiderSolitaire';
import buildMergeRoutes from '@pages/routes/merge';
import buildBlockPartyRoutes from '@pages/routes/blockParty';
import buildSortagaRoutes from '@pages/routes/sortaga';
import buildHexagoRoutes from '@pages/routes/hexago';
import buildWooAspenRoutes from '@pages/routes/woo/aspen';
import buildTaosRoutes from '@pages/routes/taos';
import buildSolCoroRoutes from '@pages/routes/sol/coro';
import buildLorayRoutes from '@pages/routes/loray';
import buidAxumRoutes from '@pages/routes/axum';
import buidLuskRoutes from '@pages/routes/lusk';
import buidVossRoutes from '@pages/routes/voss';

import buildAdminUsersRoutes from '@pages/routes/adminUsers';
import buildAdminRoutes from '@pages/routes/admin';
import buildAuditLogsRoutes from '@pages/routes/auditLogs';
import buildApplicationsRoutes from '@pages/routes/applications';
import buildArchivedSettingsRoutes from '@pages/routes/archivedSettings';
import buildVersionUpdatesRoutes from '@pages/routes/versionUpdates';
import buildUsersRoutes from '@pages/routes/users';
import buildLevelSetsRoutes from '@pages/routes/levelSets';
import buildExperimentsRoutes from '@pages/routes/ab-testing/experiments';
import buildPaymentsRoutes from '@pages/routes/payments';
import buildJourneysRoutes from '@pages/routes/journeys';
import buildDailyChallengeRoutes from '@pages/routes/dailyChallenges';
import buildLocalizationsRoutes from '@pages/routes/localizations';
import buildRewardsRoutes from '@pages/routes/rewards';
import buildDailyRewardsRoutes from '@pages/routes/dailyRewards';
import buildMissionsRoutes from '@pages/routes/missions';
import buildStreaksRoutes from '@pages/routes/streaks';
import buildLabelsRoutes from '@pages/routes/labels';
import buildAdsRoutes from '@pages/routes/ads';
import buildFlexiblePopupRoutes from '@pages/routes/flexiblePopups';
import buildPlayerSegmentRoutes from '@pages/routes/playerSegments';
import buildNamedRuleGroupsRoutes from '@pages/routes/namedRuleGroups';
import buildFilesRoutes from '@pages/routes/files';
import buildSolRoutes from '@pages/routes/sol';
import buildFigureSetsRoutes from '@pages/routes/figureSets';
import buildFavouritesRoutes from '@pages/routes/favourites';
import buildMachineLearningRoutes from '@pages/routes/machineLearning';

import LoggedOutPage from '@pages/LoggedOutPage';
import Layout from './Layout';
import LoadingPage from './Loading';
import ErrorPage from './ErrorPage';
import UIKit from './UIKitPage';

export default (defaultRoute) => createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="*" element={<Navigate to={defaultRoute} />} />
      <Route path="/admin/logged_out" element={<LoggedOutPage />} />
      <Route path="/admin/loading" element={<LoadingPage />} />

      <Route element={<Layout />}>
        <Route
          path="/admin"
          element={(
            <RouteContainer fullWidth>
              <PageTitle text="Home" />
              <SystemHome />
            </RouteContainer>
          )}
        />
        <Route
          key="/admin/ui-kit"
          path="/admin/ui-kit"
          element={(
            <RouteContainer fullWidth>
              <PageTitle text="UI Kit" />
              <UIKit />
            </RouteContainer>
          )}
        />
        {buildCommonRoutes()}
        {buildAppEventRoutes()}
        {buildTesterDeviceRoutes()}
        {buildFavouritesRoutes()}
        {buildGameSettingsRoutes()}
        {buildBlackjackRoutes()}
        {buildPuzzleTimeRoutes()}
        {buildSolitaireRoutes()}
        {buildSolitaireScapesRoutes()}
        {buildWoodokuRoutes()}
        {buildMyPetCafeRoutes()}
        {buildTileRoutes()}
        {buildMatch3dRoutes()}
        {buildWoodokuSagaRoutes()}
        {buildSpiderSolitaireRoutes()}
        {buildMergeRoutes()}
        {buildBlockPartyRoutes()}
        {buildSortagaRoutes()}
        {buildHexagoRoutes()}
        {buildWooAspenRoutes()}
        {buildTaosRoutes()}
        {buildSolCoroRoutes()}
        {buildLorayRoutes()}
        {buidAxumRoutes()}
        {buidLuskRoutes()}
        {buidVossRoutes()}

        {buildAdminUsersRoutes()}
        {buildAdminRoutes()}
        {buildAuditLogsRoutes()}
        {buildApplicationsRoutes()}
        {buildVersionUpdatesRoutes()}
        {buildUsersRoutes()}
        {buildLevelSetsRoutes()}
        {buildExperimentsRoutes()}
        {buildPaymentsRoutes()}
        {buildLocalizationsRoutes()}
        {buildRewardsRoutes()}
        {buildDailyRewardsRoutes()}
        {buildMissionsRoutes()}
        {buildStreaksRoutes()}
        {buildJourneysRoutes()}
        {buildDailyChallengeRoutes()}
        {buildSudokuRoutes()}
        {buildLabelsRoutes()}
        {buildAdsRoutes()}
        {buildFlexiblePopupRoutes()}
        {buildPlayerSegmentRoutes()}
        {buildNamedRuleGroupsRoutes()}
        {buildFilesRoutes()}
        {buildSolRoutes()}
        {buildFigureSetsRoutes()}
        {buildArchivedSettingsRoutes()}
        {buildMachineLearningRoutes()}
      </Route>
    </Route>,
  ),
);
