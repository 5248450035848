import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useCSVReader } from 'react-papaparse';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import {
  ContextMenu, MenuItem, SubMenu, ContextMenuTrigger,
} from 'react-contextmenu/dist/react-contextmenu';
import DataGrid, { Row as GridRow, TextEditor, Cell as GridCell } from 'react-data-grid';
import {
  difference, reduce, map, get,
} from 'lodash';
import { ButtonToolbar, useFlashMessages } from '@tripledotstudios/react-core';

import { getThemeName } from '@services/theme';
import APP_DATA from '@services/appData';

import { Alert } from '@pages/common';
import { persistedMap } from '@services/utils';
import { useFormContext } from '@hooks';
import IconButton from '@controls/buttons';
import { ServerError } from '@controls/form';

const DATA_GRID_DEFAULT_THEME = 'rdg-light';
const DATA_GRID_THEME_NAME_MAPPING = {
  classic: DATA_GRID_DEFAULT_THEME,
  dark: 'rdg-dark',
};

const CellError = styled.div`
  white-space: pre-wrap;
  color: ${({ theme }) => theme.variants.danger};
  padding: 5px;
`;

const CellRenderer = ({ errors, ...props }) => {
  const cellErrors = props.column.idx === 0
    ? get(errors, 'from')
    : get(errors, `set.figures[${props.column.idx - 1}].frequency`);
  return (cellErrors
    ? (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        overlay={(
          <Popover id={`tooltip-affiliation-${props.rowIdx + props.column.idx}`}>
            <CellError>
              {cellErrors.join(', \n')}
            </CellError>
          </Popover>
        )}
      >
        <GridCell {...props} className="cell-with-errors" />
      </OverlayTrigger>
    ) : <GridCell {...props} />
  );
};

const rowRenderer = (props) => {
  const rowErrors = get(props.row.errors, 'set');

  const CellRendererMemo = useMemo(
    () => (cellProps) => <CellRenderer errors={props.row.errors} {...cellProps} />,
    [props.row.errors],
  );

  return (
    <ContextMenuTrigger id="grid-context-menu" collect={() => ({ rowIdx: props.rowIdx })}>
      {rowErrors && rowErrors.length > 0 ? (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          overlay={(
            <Popover id={`tooltip-affiliation-${props.rowIdx}`}>
              <CellError>
                {rowErrors.join(', \n')}
              </CellError>
            </Popover>
          )}
        >
          <GridRow
            {...props}
            className="row-with-errors"
            cellRenderer={CellRendererMemo}
          />
        </OverlayTrigger>
      ) : (
        <GridRow {...props} cellRenderer={CellRendererMemo} />
      )}
    </ContextMenuTrigger>
  );
};

export default function DistributionsForm({ values, disabled, gameType }) {
  const distributions = (values.figuresDistributionsAttributes || []);
  const { dispatch } = useFormContext();
  const { CSVReader } = useCSVReader();
  const { error, flush, success } = useFlashMessages();
  const { gameSpecificData: { [gameType]: { figureSetsData: { defaultDistribution } } } } = APP_DATA;
  const typeHeaders = defaultDistribution.map((dd) => dd.name);
  const fileHeaders = ['Bank', ...typeHeaders];

  const validateFile = (data) => {
    const errors = [];
    const extraColumns = difference(data[0], fileHeaders);
    const missingColumns = difference(fileHeaders, data[0]);
    if (data.length <= 1) { errors.push('Rows are missing'); }
    if (extraColumns.length) { errors.push(`Extra columns present: ${extraColumns}`); }
    if (missingColumns.length) { errors.push(`The following columns are missing: ${missingColumns.join(', ')}`); }
    return errors;
  };

  const handleUpload = ({ data, errors }) => {
    if (errors.length > 0) {
      error('Invalid file format');
    } else {
      const validationErrors = validateFile(data);
      if (validationErrors.length === 0) {
        dispatch({ type: 'upload', data });
        flush();
        success('The file is uploaded');
      } else {
        error(
          `File format issues:\n${validationErrors.join(';\n')}`,
          { timeout: 600 },
        );
      }
    }
  };

  const handleRowDelete = (_e, { rowIdx }) => {
    dispatch({
      type: 'deleteRow', rowIdx,
    });
  };

  const handleRowUpdate = (newRows, { indexes, column }) => {
    dispatch({
      type: 'updateRow', fromRow: indexes[0], column, newRow: newRows[indexes[0]],
    });
  };

  const insertRow = (rowIdx) => {
    dispatch({
      type: 'insertRow', rowIdx,
    });
  };

  const handleRowInsertAbove = (_e, { rowIdx }) => {
    insertRow(rowIdx);
  };

  const handleRowInsertBelow = (_e, { rowIdx }) => {
    insertRow(rowIdx + 1);
  };

  const representRow = (data, index) => {
    const row = reduce(data.set.figures, (acc, currentValue) => {
      acc[currentValue.name] = currentValue.frequency;
      return acc;
    }, {});

    row.bank = data.from;
    row.errors = get(values.meta, `errors.figuresDistributionsAttributes.${index}`);

    return row;
  };

  const rows = distributions.map((row, index) => {
    if (!row._destroy) {
      return representRow(row, index);
    }

    return null;
  }).filter((row) => row);
  const commonAttributes = { maxWidth: 60, ...(disabled ? { cellClass: 'disabled-cell' } : { editor: TextEditor }) };
  const columns = map([
    { key: 'bank', name: 'Bank' },
    ...typeHeaders.map((name) => ({ name, key: name })),
  ], (col) => ({ ...commonAttributes, ...col }));

  return (
    <div>
      <ServerError as={Alert} name="figuresDistributionsAttributes.list" />
      <DataGrid
        columns={columns}
        rows={rows}
        onRowsChange={handleRowUpdate}
        rowRenderer={rowRenderer}
        className={DATA_GRID_THEME_NAME_MAPPING[getThemeName()] || DATA_GRID_DEFAULT_THEME}
      />
      <ButtonToolbar className="mt-3">
        <IconButton.New
          disabled={disabled}
          onClick={(e) => handleRowInsertBelow(e, { rowIdx: distributions.filter((d) => !d._destroy).length - 1 })}
        >
          Add Row
        </IconButton.New>
        <CSVLink
          headers={fileHeaders}
          data={persistedMap(distributions, (row) => [row.from, ...row.set.figures.map((col) => col.frequency)])}
          filename={`Figure Set ${values.name} ${Date.now()}.csv`}
          className="btn-wrapper"
        >
          <IconButton.Download>Download</IconButton.Download>
        </CSVLink>
        <CSVReader onUploadAccepted={handleUpload}>
          {({ getRootProps }) => (
            <IconButton.Upload type="button" disabled={disabled} {...getRootProps()}>
              Upload
            </IconButton.Upload>
          )}
        </CSVReader>
      </ButtonToolbar>

      {!disabled && createPortal(
        <ContextMenu id="grid-context-menu">
          <MenuItem onClick={handleRowDelete}>Delete Row</MenuItem>
          <SubMenu title="Insert Row">
            <MenuItem onClick={handleRowInsertAbove}>Above</MenuItem>
            <MenuItem onClick={handleRowInsertBelow}>Below</MenuItem>
          </SubMenu>
        </ContextMenu>,
        document.body,
      )}
    </div>
  );
}
