import React, { useEffect, useState, useCallback } from 'react';

import APP_DATA from '@services/appData';

import { Form, useCurrentApplication } from '@hooks';
import { requestWithToSelectOptions } from '@services/toSelectOptions';

import {
  Field, FormButtonsGroup, SelectField, AssetsKeySelectField, StaticFieldsFormGroup, localizedLabelFactory,
} from '@controls/form';
import { DuplicateButton, PageHeader } from '@pages/common';
import { FlexiblePopupRoutes as Routes, LocalizationsRoutes } from '@pages/routes';
import { isInUse } from '@pages/common/InUse';

const Label = localizedLabelFactory('flexiblePopups.popup');

const { enums } = APP_DATA;
const assetTypesEnum = enums['Solitaire::AssetTypes'];

const PopupsForm = ({ data, title, onSubmit }) => {
  const [buttonTargetOptions, setButtonTargetOptions] = useState([]);
  const { currentApplication: { id: applicationId, name: applicationName } } = useCurrentApplication();

  useEffect(() => {
    Routes.Actions.indexRequest({ applicationId, withoutPagination: true }).then(({ data: { items } }) => {
      const options = items.map(({ id, name }) => ({ label: name, value: id }));

      return setButtonTargetOptions(options);
    });
  }, []);

  const translationKeys = useCallback(
    () => requestWithToSelectOptions(LocalizationsRoutes.Keys.indexRequest, applicationId),
    [],
  );

  const { inUse } = data;
  const isSolitaire = applicationName === 'Solitaire' || applicationName === 'SolitaireScapes';

  return (
    <Form
      initialValues={{ applicationId, ...data }}
      onSubmit={onSubmit}
    >
      {() => (
        <>
          <PageHeader title={title}>
            <FormButtonsGroup
              cancelButtonPath={Routes.Popups.indexPath({ applicationId })}
              disableSubmit={isInUse(inUse)}
            >
              <DuplicateButton
                entityName="Flexible Popup"
                routes={Routes.Popups}
                name="popupId"
                data={data}
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label name="popupId">
            <Field name="popupId" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <Label name="titleTranslationKeyId">
            {isSolitaire
              ? <SelectField name="titleTranslationKeyId" options={translationKeys} />
              : <Field name="titleTranslationKey" />}
          </Label>
          <Label name="messageTranslationKeyId">
            {isSolitaire
              ? <SelectField includeEmpty name="messageTranslationKeyId" options={translationKeys} />
              : <Field name="messageTranslationKey" />}
          </Label>
          <Label name="buttonTranslationKeyId">
            {isSolitaire
              ? <SelectField name="buttonTranslationKeyId" options={translationKeys} />
              : <Field name="buttonTranslationKey" />}
          </Label>
          <Label name="buttonTargetId">
            <SelectField name="buttonTargetId" options={buttonTargetOptions} />
          </Label>
          <Label name="assetKeyId">
            {isSolitaire
              ? <AssetsKeySelectField name="assetKeyId" types={[assetTypesEnum.FLEXIBLE_POPUP_IMAGE]} includeEmpty />
              : <Field name="assetKey" />}
          </Label>
        </>
      )}
    </Form>
  );
};

export default PopupsForm;
