import React from 'react';
import { useTheme } from 'styled-components';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

export default function LabelsNavigationItem({ routingScope, minimized }) {
  const theme = useTheme();

  return (
    <Sidebar.LinkItem
      to={`${routingScope}/labels`}
      icon={faTags}
      title="Labels"
      color={theme.green}
      minimized={minimized}
    />
  );
}
