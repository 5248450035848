import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { generateChapterBasedRoutes } from '@pages/routes';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { useQuery, useCurrentApplication } from '@hooks';
import Form from './Form';

export default function New() {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { query } = useRouter();
  const { Banks: BankRoutes, LevelLayouts: LevelLayoutRoutes } = generateChapterBasedRoutes('match3d');
  const { response, setResponse } = useQuery((data) => LevelLayoutRoutes.newRequest(data, { skipUuids: true }));

  const responseHandler = createUpdateResponseHandlerFactory({
    actionName: 'creation',
    collectionUrl: BankRoutes.editPath({ applicationId, id: query.bankId }),
    entityName: 'Level layouts bank',
    setData: setResponse,
  });
  const onSubmit = (values) => LevelLayoutRoutes.createRequest(values)
    .then((result) => responseHandler(result, values));

  if (!response) return null;

  return <Form data={{ ...response }} onSubmit={onSubmit} />;
}
