import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { FormGroup, useFormContext } from '@hooks';
import IconButton from '@controls/buttons';

import StreakReward from './StreakReward';

export default function StreaksFormGroup({ streak, position }) {
  const { dispatch } = useFormContext();

  return (
    <>
      {streak.streakRewardsAttributes
        && (
          <FormGroup name={`magicPotStreaksAttributes[${position}]`} key={position}>
            <Row className="mb-2">
              <Col xs="10" xl="11" className="p-0">
                <StreakReward position={position} streak={streak} />
              </Col>
            </Row>
          </FormGroup>
        )}
      <IconButton.New
        onClick={() => dispatch({ type: 'addEmptyEntity', position, streak })}
        minimized
      >
        Add
      </IconButton.New>
    </>
  );
}
