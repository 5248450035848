export default function errorToObject(yupError) {
  return (
    yupError.inner.reduce(
      (errors, error) => {
        const errorsForPath = errors[error.path] || [];
        return { ...errors, [error.path]: [...errorsForPath, error.message] };
      },
      {},
    )
  );
}
