import React from 'react';
import { useField } from 'formik';
import { Button } from 'react-bootstrap';
import JSONInput from 'react-json-editor-ajrm';
import { useFlashMessages } from '@tripledotstudios/react-core';

import { useFormGroup, useFormContext } from '@hooks';

import ServerError from './ServerError';

const safelyParseJson = (value) => {
  if (typeof value === 'object') {
    return value;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return false;
  }
};

export default function PasteableJsonField({ name, ...props }) {
  const { setFieldValue } = useFormContext();
  const { generateName } = useFormGroup();
  const { error: errorFlash, success: successFlash } = useFlashMessages();
  const fullName = generateName(name);

  const [{ value }] = useField(fullName);
  const jsonValue = safelyParseJson(value) || {};

  return (
    <>
      <div className="mb-4">
        <Button
          type="button"
          onClick={() => window.navigator.clipboard.readText().then((text) => {
            const parsedJson = safelyParseJson(text);

            if (parsedJson && typeof parsedJson === 'object') {
              setFieldValue(fullName, JSON.stringify(parsedJson));
              successFlash('Successfully pasted from clipboard');
            } else {
              errorFlash('Pasted JSON object is invalid. Value will not be updated');
            }
          })}
        >
          Paste from clipboard
        </Button>
        <Button
          className="ms-2"
          onClick={() => navigator.clipboard.writeText(JSON.stringify(jsonValue)).then(() => (
            successFlash('Successfully copied to clipboard')
          ))}
        >
          Copy to clipboard
        </Button>
      </div>
      <JSONInput
        placeholder={jsonValue}
        onKeyPressUpdate={false}
        width="100%"
        confirmGood={false}
        {...props}
      />
      <ServerError name={fullName} full />
    </>
  );
}
