import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useCSVReader } from 'react-papaparse';
import { CSVLink } from 'react-csv';
import DataGrid, { Row as GridRow, TextEditor, Cell as GridCell } from 'react-data-grid';
import {
  ContextMenu, MenuItem, SubMenu, ContextMenuTrigger,
} from 'react-contextmenu/dist/react-contextmenu';

import {
  PageSection, ButtonToolbar, useFlashMessages,
} from '@tripledotstudios/react-core';

import { map, get, findIndex } from 'lodash';

import { getThemeName } from '@services/theme';
import { Alert } from '@pages/common';
import { persistedMap } from '@services/utils';
import { useFormContext } from '@hooks';
import IconButton from '@controls/buttons';
import { ServerError } from '@controls/form';

const DATA_GRID_DEFAULT_THEME = 'rdg-light';
const DATA_GRID_THEME_NAME_MAPPING = {
  classic: DATA_GRID_DEFAULT_THEME,
  dark: 'rdg-dark',
};

const CellError = ({ children }) => (
  <div className="px-5" style={{ color: 'red', whiteSpace: 'pre-wrap' }}>
    {children}
  </div>
);

const Cell = ({ errors, ...props }) => {
  const { column } = props;

  const cellErrors = column.idx === 0 ? get(errors, 'from') : get(errors, `set.${column.idx - 1}`);

  return (cellErrors
    ? (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        overlay={(
          <Popover id={`tooltip-affiliation-${props.rowIdx + column.idx}`}>
            <CellError>
              {cellErrors.join(', \n')}
            </CellError>
          </Popover>
        )}
      >
        <GridCell {...props} className="cell-with-errors" />
      </OverlayTrigger>
    ) : <GridCell {...props} />
  );
};

const rowRenderer = (props) => {
  const rowErrors = props.row._errors;
  const setErrors = get(rowErrors, 'set.list');

  const MemoizedCell = useMemo(
    () => (cellProps) => <Cell errors={rowErrors} {...cellProps} />,
    [props.row._errors],
  );

  return (
    <ContextMenuTrigger id="grid-context-menu" collect={() => ({ rowIdx: props.rowIdx, _uuid: props.row._uuid })}>
      {setErrors && setErrors.length > 0 ? (
        <OverlayTrigger
          trigger={['hover', 'focus']}
          overlay={(
            <Popover id={`tooltip-affiliation-${props.rowIdx}`}>
              <CellError>
                {setErrors.join(', \n')}
              </CellError>
            </Popover>
          )}
        >
          <GridRow
            {...props}
            className="row-with-errors"
            cellRenderer={MemoizedCell}
          />
        </OverlayTrigger>
      ) : (
        <GridRow {...props} cellRenderer={MemoizedCell} />
      )}
    </ContextMenuTrigger>
  );
};

const validateFile = (data, availableShapes) => {
  const errors = [];
  if (data.length <= 1) { errors.push('Rows are missing'); }
  if (!data[0].includes('Bank')) { errors.push('Bank column is missing'); }

  data[0].forEach((columnName) => {
    if (columnName === 'Bank') return columnName;
    if (availableShapes.find(({ name }) => name === columnName)) return columnName;

    return errors.push(`Unexisting column ${columnName} found`);
  });

  return errors;
};

export default function DistributionsForm({
  figureAffiliations,
  shapes,
  disabled,
  availableShapes,
}) {
  const { dispatch, values } = useFormContext();
  const { name: figureSetName, meta } = values;

  const { CSVReader } = useCSVReader();
  const { error, flush, success } = useFlashMessages();
  const shapeNames = shapes.map(({ name }) => name);
  const shapeIds = shapes.map(({ id }) => id);
  const fileHeaders = ['Bank', ...shapeNames];

  const handleUpload = ({ data, errors }) => {
    if (errors.length > 0) {
      error('Invalid file format');
    } else {
      const validationErrors = validateFile(data, availableShapes);
      if (validationErrors.length === 0) {
        dispatch({ type: 'upload', data });
        flush();
        success('The file is uploaded');
      } else {
        error(
          `File format issues:\n${validationErrors.join(';\n')}`,
          { timeout: 600 },
        );
      }
    }
  };

  const emptyRowData = useMemo(() => shapes.reduce((memo, shape) => ({ ...memo, [shape.id]: 0 }), {}), [shapes.length]);

  useEffect(() => {
    dispatch({ type: 'normalizeSets', shapeIds });
  }, [shapeIds.length]);

  const removeRow = (uuid) => dispatch({ type: 'removeRow', uuid });
  const insertRow = (index) => dispatch({
    type: 'addRow',
    index,
    shapesConfig: emptyRowData,
  });

  const getDirtyRowIndex = (uuid) => findIndex(figureAffiliations, ({ _uuid }) => _uuid === uuid);

  const rows = persistedMap(
    figureAffiliations, (row) => ({
      from: row.from,
      ...emptyRowData,
      ...row.set,
      _uuid: row._uuid,
      _errors: get(meta, `errors.figureAffiliationsAttributes.${getDirtyRowIndex(row._uuid)}`),
    }),
  );

  const csvData = rows.map(({ from, _uuid, ...set }) => (
    [from, ...shapes.reduce((memo, shape) => [...memo, set[shape.id] || 0], [])]
  ));

  const updateRows = (newRows, { indexes }) => dispatch({
    type: 'updateRow',
    row: newRows[indexes[0]],
  });

  const commonAttributes = { maxWidth: 70, ...(disabled ? { cellClass: 'disabled-cell' } : { editor: TextEditor }) };
  const columns = map([
    { key: 'from', name: 'Bank' },
    ...shapes.map(({ id, name }) => ({ name, key: id })),
  ], (col) => ({ ...commonAttributes, ...col }));

  return (
    <div>
      <PageSection title="Figures Distribution" />

      <ServerError as={Alert} name="figureAffiliationsAttributes.list" />
      {rows.length > 0 && (
        <DataGrid
          columns={columns}
          rows={rows}
          onRowsChange={updateRows}
          rowRenderer={rowRenderer}
          className={DATA_GRID_THEME_NAME_MAPPING[getThemeName()] || DATA_GRID_DEFAULT_THEME}
        />
      )}
      <ButtonToolbar className="mt-3">
        <IconButton.New
          disabled={disabled}
          onClick={() => insertRow(undefined)}
        >
          Add Row
        </IconButton.New>
        <CSVLink
          headers={fileHeaders}
          data={csvData}
          filename={`Figure Set ${figureSetName ? `'${figureSetName}'` : ''} ${Date.now()}.csv`}
          className="btn-wrapper"
        >
          <IconButton.Download>Download</IconButton.Download>
        </CSVLink>
        <CSVReader onUploadAccepted={handleUpload}>
          {({ getRootProps }) => (
            <IconButton.Upload type="button" {...getRootProps()} disabled={disabled}>
              Upload
            </IconButton.Upload>
          )}
        </CSVReader>
      </ButtonToolbar>

      {!disabled && createPortal(
        <ContextMenu id="grid-context-menu">
          <MenuItem onClick={(_a, { _uuid }) => removeRow(_uuid)}>Delete Row</MenuItem>
          <SubMenu title="Insert Row">
            <MenuItem onClick={(_e, { rowIdx }) => insertRow(rowIdx)}>Above</MenuItem>
            <MenuItem onClick={(_e, { rowIdx }) => insertRow(rowIdx + 1)}>Below</MenuItem>
          </SubMenu>
        </ContextMenu>,
        document.body,
      )}
    </div>
  );
}
