import React from 'react';

import { useQuery } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InUseColumn,
} from '@pages/common';

import { LocalizationsRoutes } from '@pages/routes';
import AvailabilityStateBadge from './AvailabilityStateBadge';
import Filter from './Filter';

const columns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  AvailabilityStateBadge,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
];

export default function Index() {
  const { response } = useQuery(
    LocalizationsRoutes.Keys.indexRequest,
    { includeInUse: true, includeOriginalName: true },
  );

  return response && (
    <div>
      <PageHeader title="Keys" userGuideUrlKey="localizationsGuideUrl" filter={<Filter />} />
      <ReactTable columns={columns} data={response.items} />
      <Pagination pageCount={response._meta.pages} />
    </div>
  );
}
