import React from 'react';

import { countryCodesOptions } from '@services/countries';
import SelectField from '@controls/form/SelectField';

export default function CountryRule({ disabled }) {
  return (
    <SelectField
      name="countryCodes"
      options={countryCodesOptions}
      isMulti
      isDisabled={disabled}
      testId="country_rule_code"
    />
  );
}
