import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useRouter } from '@tripledotstudios/react-core';

import { useFormGroup } from '@hooks';
import { getProducts } from '@requests/payments/products';

import SlotsList from './List';
import GameSpecificColumns from './GameSpecificColumns';

export default function Index({ defaultVariant, values, dispatch }) {
  const [products, setProducts] = useState([]);
  const { generateName } = useFormGroup();
  const { query: { applicationId } } = useRouter();
  const { slotsAttributes } = values;
  const { variantId } = values;

  useEffect(() => {
    getProducts({ applicationId, withoutPagination: true, includeInUse: true })
      .then((response) => setProducts(response.data.items));
  }, []);

  const notDestroyedSlots = slotsAttributes.filter(({ _destroy }) => !_destroy);

  const onDragEnd = ({ source, destination }) => dispatch({
    variantId,
    defaultVariant,
    type: 'reorder_slots',
    path: generateName('slotsAttributes'),
    sourceIndex: source.index,
    destinationIndex: destination.index,
  });

  return (
    <>
      {notDestroyedSlots.length ? (
        <>
          <Row className="mb-2">
            <Col xs={1}><b>Slot</b></Col>
            <Col xs={3}><b>Product</b></Col>
            <Col xs={2}><b>Product details</b></Col>
            <GameSpecificColumns />
            <Col xs={3}><b>Label</b></Col>
          </Row>
          <hr />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-slots">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <SlotsList
                    defaultVariant={defaultVariant}
                    values={values}
                    products={products}
                    dispatch={dispatch}
                    variantId={variantId}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : ''}
      <Button onClick={() => dispatch({ type: 'add_slot', defaultVariant, variantId })}>Add slot</Button>
    </>
  );
}
