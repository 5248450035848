const popupsDefaultFieldNames = [
  'iosIdfaExplainerPopupEnabled',
  'iosIdfaNativePopupEnabled',
  'popupsOrder',
  'popupsTriggers',
  'iosIdfaExplainerTitleTranslationKey',
  'iosIdfaExplainerBodyTranslationKey',
  'iosIdfaExplainerButtonTranslationKey',
  'iosIdfaExplainerPopupAcceptsGdpr',
  'iosIdfaFakeNativePopupEnabled',
];

export const popupsDefaultArchivedFieldNames = [
  'iosIdfaRequestEnabled',
];

export default popupsDefaultFieldNames;
