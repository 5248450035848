import React from 'react';

import { Label, CheckboxField } from '@controls/form';

const AllowEditLayoutBanksInUse = () => (
  <Label text="Allow edit Layout Banks in use">
    <CheckboxField name="editInUseLayoutBanks" />
  </Label>
);

export default AllowEditLayoutBanksInUse;
