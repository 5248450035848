import React from 'react';

import IconButton from '@controls/buttons';
import { useFormContext } from '@hooks';

export default function SaveButton({ disable }) {
  const { isSubmitting } = useFormContext();

  return <IconButton.Save type="submit" disabled={disable || isSubmitting} />;
}
