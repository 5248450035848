import React, { useState } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';

import { FlexiblePopupRoutes as Routes } from '@pages/routes';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
  DuplicationModal,
} from '@pages/common';
import IconButton from '@controls/buttons';
import { isInUse } from '@pages/common/InUse';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import Filter from './Filter';

const columnsFactory = ({
  applicationId, onDelete, onDuplicate,
}) => (
  [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Popup ID', accessor: 'popupId' },
    { Header: 'Title', accessor: 'titleKey', disableSortBy: true },
    InLiveColumn,
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({ row: { original: { id, popupId, inUse } } }) => (
        <ButtonToolbar>
          <IconButton.Edit to={Routes.Popups.editPath({ id, applicationId })} minimized />
          <IconButton.Duplicate
            onClick={() => onDuplicate(id, popupId)}
            minimized
          />
          <IconButton.Delete
            onClick={() => onDelete(id, popupId)}
            disabled={isInUse(inUse)}
            minimized
          />
        </ButtonToolbar>
      ),
    },
  ]
);

const Index = () => {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { query: { applicationId } } = useRouter();
  const { response, refetch, isLoading } = useQuery(
    Routes.Popups.indexRequest, { applicationId, includeInUse: true },
  );
  const confirm = useConfirm();

  const deleteResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Flexible Popup',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, popupId) => {
    confirm.showConfirmation({
      title: `Flexible Popup '${popupId}' will be deleted and not available for work. Continue?`,
    })
      .then(() => Routes.Popups.deleteRequest({ id, applicationId })
        .then(deleteResponseHandler));
  };

  const onDuplicate = (id, popupId) => setDuplicatedRecord({ id, popupId });

  const columns = React.useMemo(() => columnsFactory({ applicationId, onDelete, onDuplicate }), []);

  return response && (
    <>
      <PageHeader
        title="Flexible Popups"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
      >
        <IconButton.New to={Routes.Popups.newPath({ applicationId })} />
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <ReactTable
          columns={columns}
          data={response.items}
        />
      )}
      <DuplicationModal
        entityName="Flexible Popup"
        record={duplicatedRecord}
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={({ id, popupId }) => (Routes.Popups.duplicateRequest({ id, popupId, applicationId }))}
        openInNewTab
        name="popupId"
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </>
  );
};

export default Index;
