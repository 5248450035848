import React from 'react';
import { Col } from 'react-bootstrap';

import { fromClassNameToOptions } from '@services/enums';
import {
  Filter,
  TextFilter,
  StatusFilter,
} from '@pages/common';

export default function JourneyLevelBankFilter({ statuses, children }) {
  const statusOptions = fromClassNameToOptions('Journeys::LevelBanks::BankStatuses');

  const defaultStatuses = [statuses.ACTIVE];

  return (
    <Filter
      defaultComponents={['availabilityStateIn', 'createdByMe', 'labels', 'namedRuleGroup', 'id']}
    >
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <StatusFilter
              name="statusIn"
              label="Status"
              updateFilterField={updateFilterField}
              options={statusOptions}
              defaults={defaultStatuses}
            />
          </Col>
          {children && children({ updateFilterField })}
        </>
      )}
    </Filter>
  );
}
