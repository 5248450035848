import React from 'react';

import {
  GameSettingsTabs,
  adsControlDefaultFieldNames,
  adConfigsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  localizationsDefaultFieldNames,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  analyticsDefaultFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
  adRequestDelayFieldNames,
} from '@components/game-settings/Resource';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'allowShuffle',
      'lastThreeCards',
      'winnableSeed',
      ...assetsDefaultFieldNames,
      {
        eventKey: '#client_settings_menu',
        title: 'Client Settings Menu',
        fieldsNames: [
          'hideThreeWasteCardsEnabled',
          'hideEnableUndoEnabled',
          'hideEnableTimerEnabled',
          'hideLeftHandModeEnabled',
          'hideVegasScoringEnabled',
          'hideVegasCumulativeEnabled',
          'hideWinAnimationEnabled',
          'hideDealThreeEnabled',
          'hideMyStatsEnabled',
        ],
      },
    ],
  },
  {
    eventKey: '#onboarding',
    title: 'Onboarding',
    fieldsNames: [],
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      ...adRequestDelayFieldNames,
      ...adPlacementIdsDefaultFieldNames,
      ...adConfigsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: popupsDefaultFieldNames,
  },
  {
    eventKey: '#debug',
    title: 'Debug',
    fieldsNames: [],
  },
  {
    eventKey: '#ads_control',
    title: 'Ads Control',
    fieldsNames: adsControlDefaultFieldNames,
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: analyticsDefaultFieldNames,
  },
  {
    eventKey: '#daily_challenge',
    title: 'Daily Challenge',
    fieldsNames: [],
  },
  {
    eventKey: '#power_ups',
    title: 'Power-ups',
    fieldsNames: [],
  },
  {
    eventKey: '#user_xp',
    title: 'User XP',
    fieldsNames: [],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: popupsDefaultArchivedFieldNames,
  },
  {
    eventKey: '#boosters',
    title: 'Boosters',
    fieldsNames: [],
  },
];

export default function Tabs({
  setContractType, fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      onChange={setContractType}
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
