import { classic } from '@tripledotstudios/react-core';

export default Object.freeze({
  ...classic,
  colors: {
    home: '#d1a45f',
  },
  badges: {
    danger: {
      color: classic.variants.danger,
    },
    warning: {
      backgroundColor: classic.variants.warning,
      color: classic.variants.dark,
    },
    primary: {
      color: classic.variants.primary,
    },
    secondary: {
      color: classic.variants.secondary,
    },
    success: {
      color: classic.variants.success,
    },
  },
  woodoku: {
    board: {
      cell: {
        normal: '#D5AD6C',
        selected: '#7A2500',
        invalid: '#f37672',
        hard: '#330F00',
      },
    },
  },
});
