import React from 'react';

import MultipleValuesField from './MultipleValuesField';
import DateTimeField from './DateTimeField';

export default function MultipleDatesField({ name, ...rest }) {
  return (
    <MultipleValuesField
      name={name}
      InputComponent={DateTimeField}
      wrapValue
      {...rest}
    />
  );
}
