import React from 'react';

import { Label, CheckboxField } from '@controls/form';

export default function ApplicationBaseSettingsFields() {
  return (
    <>
      <Label text="Default 'Live' availability filter">
        <CheckboxField name="defaultLiveAvailabilityFilter" />
      </Label>
      <Label
        text="Enable redirection to LAB Sizing Tool"
        tooltipText="When toggle in ON, extra hint and redirection link to LAB
                    Sizing tool will be displayed during new A/B Experiment creation"
      >
        <CheckboxField name="enableRedirectionToLabSizingTool" />
      </Label>
    </>
  );
}
