import React from 'react';

import { MissionsRoutes } from '@pages/routes';
import Form from './Form';

export default function EditModal({ ...props }) {
  const { editRequest, updateRequest } = MissionsRoutes.MissionTypes;

  return (
    <Form actionName="update" getForm={editRequest} submitForm={updateRequest} {...props} />
  );
}
