import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';

import { useCurrentApplication } from '@hooks';
import { InLiveBadge, Badge, Alert } from '@pages/common';

import editPath from '@services/editPath';
import NoResults from '@root/components/shared/NoResults';

const NameTh = styled.th`
  min-width: 300px;
`;

export default function EntitiesTable({ data }) {
  const { currentApplication } = useCurrentApplication();

  const showHint = data.length > 0 && data.findIndex(({ type }) => type === 'A/B Experiments') >= 0;

  const statusVariant = (statusHumanize) => {
    switch (statusHumanize) {
      case 'Active':
        return 'success';
      case 'Allocation Paused':
        return 'warning';
      default:
        return 'secondary';
    }
  };

  const entityPath = (entity) => editPath({ ...entity.frontendIdentity, application: currentApplication });

  return data.length <= 0 ? <NoResults /> : (
    <>
      {showHint && <Alert variant="info">Permanently Archived experiments are excluded</Alert>}
      <Table bordered striped>
        <thead>
          <tr>
            <th>Type</th>
            <NameTh>Name</NameTh>
            <th>Status</th>
            <th>Availability</th>
          </tr>
        </thead>
        <tbody>
          {data.map(({ type, entities }) => (
            entities.map(({
              name,
              statusHumanize,
              inLive,
              ...entity
            }, index) => (
              <tr>
                <td><b>{index === 0 && type}</b></td>
                <td>
                  <Link key={name} to={entityPath(entity)} target="_blank">
                    {name}
                  </Link>
                </td>
                <td>
                  {statusHumanize && <Badge bg={statusVariant(statusHumanize)}>{statusHumanize}</Badge>}
                </td>
                <td><InLiveBadge inLive={inLive} /></td>
              </tr>
            ))
          ))}
        </tbody>
      </Table>
    </>
  );
}
