import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import Select from '../Select';
import IconButton from '../buttons';

export default function SelectWithAction({
  selectOptions, buttonOnClick, buttonTitle, disabled, className,
}) {
  const [selectedValue, setSelectedValue] = useState(null);

  const onClick = () => {
    buttonOnClick(selectedValue);
    setSelectedValue(null);
  };

  return (
    <Row className={className}>
      <Col xs={5}>
        <Select
          selectedValue={selectedValue}
          onChange={({ value }) => setSelectedValue(value)}
          options={selectOptions}
          isDisabled={disabled}
        />
      </Col>
      <Col xs={7} className="ps-0">
        <IconButton.New
          onClick={onClick}
          disabled={disabled || !selectedValue}
          className="h-100"
        >
          {buttonTitle}
        </IconButton.New>
      </Col>
    </Row>
  );
}
