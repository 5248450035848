import React from 'react';

import MultipleValuesField from '@controls/form/MultipleValuesField';
import Field from '@controls/form/Field';

export default function OsVersionRule({ disabled }) {
  return (
    <MultipleValuesField
      name="osVersions"
      disabled={disabled}
      InputComponent={Field}
    />
  );
}
