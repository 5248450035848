import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { ListGroup, Row, Col } from 'react-bootstrap';
import { Field } from 'formik';
import { useConfirm } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import IconButton from '@controls/buttons';

import deleteLevelLayoutsData from '@requests/my-pet-cafe/levelLayoutsData';

export default function LayoutDataRow({
  levelLayoutId,
  levelHash,
  updatedAt,
  deletionUnlocked,
  refetch,
}) {
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Layout hash',
    actionName: 'update',
    setData: () => null,
  });

  const onRemove = () => {
    confirm
      .showConfirmation({ title: 'Confirm to remove selected level hash', headerColor: 'lightpink' })
      .then(() => deleteLevelLayoutsData({ applicationId, id: levelHash })
        .then(responseHandler)
        .then(refetch));
  };

  return (
    <ListGroup.Item variant="info">
      <Row className="p-1 m-1 align-items-center text-center">
        <Col xs={3} className="text-center">
          <Field type="radio" name={levelLayoutId} value={levelHash} />
        </Col>
        <Col xs={4}>
          {levelHash}
        </Col>
        <Col xs={2}>
          {updatedAt}
        </Col>
        <Col xs={{ span: 2, offset: 1 }}>
          <IconButton.Delete onClick={onRemove} disabled={!deletionUnlocked}>
            Remove
          </IconButton.Delete>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}
