import React from 'react';
import { difference, isObject } from 'lodash';
import camelcase from 'camelcase';
import underscore from 'to-snake-case';

import { useFormContext } from '@hooks';
import IconButton from '@controls/buttons';

export default function FieldsStatusesController({ fieldsNames, enabledFields, children }) {
  const { dispatch } = useFormContext();

  const camelCasedEnabledFields = enabledFields.map(camelcase);
  const tabEnabledFields = [];
  const tabDisabledFields = [];

  const processSection = (section) => {
    const disabledSectionFields = difference(section.fieldsNames, camelCasedEnabledFields);
    const enabledSectionFields = difference(section.fieldsNames, disabledSectionFields);
    if (disabledSectionFields.length > 0) tabDisabledFields.push({ ...section, fieldsNames: disabledSectionFields });
    if (enabledSectionFields.length > 0) tabEnabledFields.push({ ...section, fieldsNames: enabledSectionFields });
  };

  fieldsNames.forEach((field) => {
    if (isObject(field)) return processSection(field);
    if (camelCasedEnabledFields.indexOf(field) >= 0) return tabEnabledFields.push(field);

    return tabDisabledFields.push(field);
  });

  const handleButtonClick = (fieldName, type) => {
    dispatch({ type, fieldName: underscore(fieldName) });
  };

  const removeButtonBuilderFn = ({ fieldName }) => (
    <IconButton.Remove
      onClick={() => { handleButtonClick(underscore(fieldName), 'disableField'); }}
      testId={`disable_${underscore(fieldName)}_button`}
      minimized
    >
      Disable
    </IconButton.Remove>
  );

  const addButtonBuilderFn = ({ fieldName }) => (
    <IconButton.New
      onClick={() => { handleButtonClick(underscore(fieldName), 'enableField'); }}
      testId={`enable_${underscore(fieldName)}_button`}
      minimized
    >
      Enable
    </IconButton.New>
  );

  return (
    <>
      {children(
        {
          enabledFields: tabEnabledFields,
          disabledFields: tabDisabledFields,
          removeButtonBuilderFn,
          addButtonBuilderFn,
        },
      )}
    </>
  );
}
