import React, { useEffect, useState } from 'react';
import { Alert } from '@tripledotstudios/react-core';
import { Modal, Button } from 'react-bootstrap';
import {
  isEmpty,
  pick,
  snakeCase,
  merge,
} from 'lodash';
import styled from 'styled-components';
import camelize from 'camelcase-keys';

import { parser } from '@exodus/schemasafe';

import APP_DATA from '@services/appData';

import { Error, Label, ServerError } from '@controls/form';
import Select from '@controls/Select';

import { FormGroup } from '@hooks';
import {
  tileOutsideBoard,
  tileCollidesWithOther,
  buildCoordinatesSet,
  isLargeTileFamily,
} from '../utils';

const ParamsContainer = styled.div`
  padding-top: .5em;
  `;

const { enums } = APP_DATA;
const layers = enums['Woodoku::TileTypes::Layers'];

const SCHEMA_REFERENCE = 'https://json-schema.org/draft/2020-12/schema';

const jsonSchema = (properties) => {
  if (!properties) return {};

  const camelizedProperties = camelize(properties, { deep: true });

  return {
    $schema: SCHEMA_REFERENCE,
    type: 'object',
    properties: camelizedProperties,
    required: [],
    additionalProperties: false,
  };
};

const parseWithSchema = (schema, value) => {
  try {
    const schemaParser = parser(jsonSchema(schema), { includeErrors: true, removeAdditional: true });
    const toValidate = typeof value === 'string' ? value : JSON.stringify(value);

    return schemaParser(toValidate);
  } catch (error) {
    return { valid: false, error };
  }
};

const TileSelect = ({
  label, options, selectedValue, onChange, disabled = false, fieldName, children, error,
}) => (
  <Label text={label} fieldSize={8}>
    <Select
      isDisabled={disabled}
      includeEmpty
      options={options}
      selectedValue={selectedValue}
      onChange={onChange}
    />
    <ServerError name={fieldName} />
    {!isEmpty(error) && <Error>{error}</Error>}
    <ParamsContainer>
      {children}
    </ParamsContainer>
  </Label>
);

const ParameterInput = ({
  name,
  value,
  config,
  disabled,
  onChange,
}) => {
  const { type } = config;

  const onInputChange = (newValue) => {
    if (type === 'number' || type === 'integer') {
      const parsedValue = newValue.length ? Number(newValue) : '';

      return onChange(name, parsedValue);
    }

    return onChange(name, newValue);
  };

  return (
    <Label direction="vertical" text={snakeCase(name)}>
      <input
        disabled={disabled}
        className="form-control"
        value={value}
        onChange={({ target: { value: newValue } }) => onInputChange(newValue)}
        {...config}
      />
    </Label>
  );
};

const ParametersInputs = ({
  schema,
  values = {},
  disabled,
  onChange,
  errors,
}) => {
  if (!schema) return null;

  const camelizedSchema = camelize(schema, { deep: true });
  const schemaCheckResult = parseWithSchema(schema, '{}');

  if (!schemaCheckResult.valid) {
    return (
      <Alert>
        <strong>Invalid schema!</strong>
        <p>
          {String(schemaCheckResult.error)}
        </p>
      </Alert>
    );
  }

  return (
    <>
      {React.Children.toArray(Object.entries(camelizedSchema).map(([name, parametersConfig]) => {
        const rawParamValue = (values || {})[name];
        const { type } = parametersConfig;
        const inputType = type === 'integer' ? 'number' : type;

        return (
          <ParameterInput
            value={String(rawParamValue).length ? rawParamValue : ''}
            name={name}
            config={{ ...parametersConfig, type: inputType }}
            disabled={disabled}
            onChange={onChange}
          />
        );
      }))}
      {errors && (
        <Alert>{errors}</Alert>
      )}
    </>
  );
};

export default function CellModal({
  title, cells, onSubmit, options, onEditStop, indexes, disableBase, boardWidth, occupiedCells, disableOverlay = false,
  massMode = false,
}) {
  const initialAttributes = massMode ? {} : pick((cells[0] || {}), ['baseTileId', 'overlayTileId', 'parameters']);

  const [baseTileId, setBaseTileId] = useState(initialAttributes.baseTileId || null);
  const [overlayTileId, setOverlayTileId] = useState(initialAttributes.overlayTileId || null);
  const [parameters, setParameters] = useState(initialAttributes.parameters || {});
  const [coordinates, setCoordinates] = useState({});
  const [parameterErrors, setParameterErrors] = useState({});
  const [baseTileError, setBaseTileError] = useState({});
  const [validLargeTileCoordinates, setValidLargeTileCoordinates] = useState(true);
  const tileOption = (tileId) => options.find(({ value }) => value === tileId) || {};
  const [disableOverlayLayer, setDisableOverlayLayer] = useState(
    disableOverlay || isLargeTileFamily(tileOption(baseTileId)?.familyId),
  );
  const baseTileParamsSchema = tileOption(baseTileId).parametersSchema;
  const overlayTileParamsSchema = tileOption(overlayTileId).parametersSchema;

  const validateLargeTilesPositions = (currentTileOption) => {
    if (isEmpty(coordinates) || currentTileOption.familyId !== 4) return true;

    return (!indexes.some((index) => (tileOutsideBoard(index, currentTileOption, boardWidth, setBaseTileError)
      || tileCollidesWithOther(index, coordinates, occupiedCells, setBaseTileError))));
  };

  useEffect(() => {
    setValidLargeTileCoordinates(validateLargeTilesPositions(tileOption(baseTileId)));
  }, [coordinates]);

  const validateParams = () => {
    try {
      const tileParamsValid = (schema, tileId) => {
        if (!schema) return true;

        const validationResult = parseWithSchema(schema, JSON.stringify(parameters[tileId] || {}));

        if (!validationResult.valid) {
          const stringifiedError = String(validationResult.error);

          if (parameterErrors[tileId] !== stringifiedError) {
            setParameterErrors((previousState) => ({ ...previousState, [tileId]: stringifiedError }));
          }

          return false;
        }

        if (parameterErrors[tileId]) {
          setParameterErrors((previousState) => {
            const newState = { ...previousState };
            delete newState[tileId];

            return newState;
          });
        }

        return true;
      };

      return (
        tileParamsValid(baseTileParamsSchema, baseTileId) && tileParamsValid(overlayTileParamsSchema, overlayTileId)
      );
    } catch {
      return false;
    }
  };

  const validParams = validateParams();

  const resetBasicModalValues = () => {
    setBaseTileError(null);
    setOverlayTileId(null);
    setDisableOverlayLayer(false);
  };

  const onEditCancel = () => {
    setBaseTileId(null);
    resetBasicModalValues();
    setParameters({});

    return onEditStop();
  };

  const onTileChange = (newTileId, previousTileId, changeFn, baseTileParameters = {}) => {
    const { [previousTileId]: _, ...newParameters } = parameters;
    setParameters(merge(newParameters, baseTileParameters));
    changeFn(newTileId);
  };

  const onBaseTileChange = (newTileId, previousTileId, changeFn) => {
    resetBasicModalValues();
    const newTileOptions = tileOption(newTileId);
    let baseTileParams = {};
    if (isLargeTileFamily(newTileOptions.familyId)) {
      setDisableOverlayLayer(true);
      const coordinatesSet = buildCoordinatesSet(indexes, newTileOptions, boardWidth);
      setCoordinates(coordinatesSet);
      baseTileParams = { [newTileId]: { coordinates: coordinatesSet } };
    }
    return onTileChange(newTileId, previousTileId, changeFn, baseTileParams);
  };

  const onConfirm = () => {
    if (onSubmit(indexes, { baseTileId, overlayTileId, parameters })) {
      return onEditStop();
    }

    return setParameterErrors('Invalid ref value');
  };

  const onParamsChange = (cellId, name, value) => {
    if (!cellId) return;

    setParameters((previousState) => {
      const previousCellParams = previousState[cellId] || {};
      let newValue = { ...previousCellParams, [name]: value };

      if (!String(value).length) {
        const { [name]: _, ...newCellParams } = previousCellParams;

        newValue = { ...newCellParams, [name]: null };
      }
      const adjustedValue = parseWithSchema(tileOption(cellId)?.parametersSchema, newValue).value || newValue;

      return ({ ...previousState, [cellId]: { ...previousState[cellId], ...adjustedValue } });
    });
  };

  return (
    <Modal show onHide={onEditCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup name={`${indexes}`}>
          <TileSelect
            label="Base Layer"
            fieldName="baseTileId"
            disabled={disableBase}
            options={options.filter(({ layerId }) => layerId === layers.BASE)}
            selectedValue={baseTileId}
            onChange={({ value }) => onBaseTileChange(value, baseTileId, setBaseTileId)}
            error={baseTileError}
          >
            <ParametersInputs
              schema={baseTileParamsSchema}
              values={parameters[baseTileId]}
              errors={parameterErrors[baseTileId]}
              tileId={baseTileId}
              disabled={disableBase}
              onChange={(name, value) => onParamsChange(baseTileId, name, value)}
            />
          </TileSelect>
          <TileSelect
            label="Overlay Layer"
            fieldName="overlayTileId"
            options={options.filter(({ layerId }) => layerId === layers.OVERLAY)}
            selectedValue={overlayTileId}
            disabled={disableOverlayLayer}
            onChange={({ value }) => onTileChange(value, overlayTileId, setOverlayTileId)}
            params={parameters[overlayTileId]}
            onParamsChange={onParamsChange}
          >
            <ParametersInputs
              schema={overlayTileParamsSchema}
              values={parameters[overlayTileId]}
              errors={parameterErrors[overlayTileId]}
              tileId={overlayTileId}
              disabled={disableBase}
              onChange={(name, value) => onParamsChange(overlayTileId, name, value)}
            />
          </TileSelect>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onEditCancel}>Cancel</Button>
        <Button
          variant="primary"
          onClick={onConfirm}
          disabled={!validParams || !validLargeTileCoordinates || (massMode && !(baseTileId || overlayTileId))}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
