import { useCallback } from 'react';
import selectBuilder from '@services/fields-factory/SelectBuilder';

import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { getAppConfigs } from '@requests/my-pet-cafe/appConfigs';

export default function fieldsFactory({ applicationId }) {
  return (
    {
      ...selectBuilder(
        {
          name: 'abTestingId',
          labelText: 'A/B testing id',
          options: useCallback(() => requestWithToSelectOptions(getAppConfigs, applicationId)),
        },
      ),
    }
  );
}
