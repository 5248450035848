const FieldsConfiguration = [
  {
    label: 'Pack Prices',
    name: 'packPrices',
    type: 'array',
    fieldsConfiguration: [
      { label: 'Name', name: 'key', type: 'text' },
      { label: 'Value', name: 'value', type: 'number' },
    ],
  },
  { label: 'Levels for gift', name: 'levelsForGift', type: 'number' },
  { label: 'Start Hints', name: 'startHints', type: 'number' },
  { label: 'Gifted Hints', name: 'giftedHints', type: 'number' },
  { label: 'Rewarded video hints', name: 'rewardedVideoHints', type: 'number' },
];

export default FieldsConfiguration;
