import React from 'react';

import { Row } from 'react-bootstrap';
import { FormGroup } from '@hooks';

import APP_DATA from '@root/services/appData';
import FieldWithText from './FieldWithText';

const { enums } = APP_DATA;
const tierParametersEnum = enums['Missions::MissionTypes::TierParameterTypes'];
const typesMapping = {
  [tierParametersEnum.INTEGER]: { type: 'number', step: 1 },
  [tierParametersEnum.FLOAT]: { type: 'number', step: 0.01 },
  [tierParametersEnum.STRING]: { type: 'text' },
};

export default function TierParameters({ tierParametersAttributes, disabled }) {
  return (
    <Row>
      {tierParametersAttributes && tierParametersAttributes.map(({ name, type, _uuid }, index) => (
        <FormGroup key={_uuid} name={`tierParametersAttributes.${index}`}>
          <FieldWithText
            name="value"
            text={name}
            disabled={disabled}
            {...typesMapping[type]}
          />
        </FormGroup>
      ))}
    </Row>
  );
}
