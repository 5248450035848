import {
  isObject, isArray, isNumber, isString, isBoolean, mapValues,
} from 'lodash';
import { v4 } from 'uuid';

const processObject = (object, changeFn) => {
  // eslint-disable-next-line no-use-before-define
  const newObject = mapValues(object, (value) => processValue(value, changeFn));
  return changeFn(newObject);
};

// eslint-disable-next-line no-use-before-define
const clearObject = (object) => mapValues(object, (value) => clearValues(value));

// eslint-disable-next-line no-use-before-define
const processArray = (array, changeFn) => array.map((element) => processValue(element, changeFn));

const processValue = (value, changeFn) => {
  if (isArray(value)) return processArray(value, changeFn);
  if (isObject(value)) return processObject(value, changeFn);

  return value;
};

export const clearValues = (values) => {
  if (isArray(values)) return [];
  if (isNumber(values)) return 0;
  if (isString(values)) return '';
  if (isBoolean(values)) return false;
  if (isObject(values)) return clearObject(values);

  return null;
};

export const process = (object, changeFn) => processValue(object, changeFn);
export const addUuid = (object) => ({ ...object, _uuid: object._uuid || v4() });
export const addUuids = (object) => process(object, addUuid);
// eslint-disable-next-line no-unused-vars
export const clearUuids = (object) => process(object, ({ _uuid, ...cleanObject }) => cleanObject);
