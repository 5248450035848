import React, { useMemo, useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  PageHeader,
  Pagination,
  ReactTable,
  Filter,
} from '@pages/common';
import DuplicationModal from '@components/merge/shared/DuplicationModalWithWorldSchemaId';
import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';
import ColumnsFactory from './ColumnsFactory';

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(MergeRoutes.LevelSets.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Level Set',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `Level Set '${name}' will be deleted and not available for work. Continue?` })
      .then(() => MergeRoutes.LevelSets.deleteRequest({ id, applicationId: query.applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({
    query, setDuplicatedRecord, onDelete,
  }), []);

  return response && (
    <div>
      <PageHeader title="Level Sets" filter={<Filter defaultComponents={['availabilityStateIn']} />}>
        <IconButton.New to={MergeRoutes.LevelSets.newPath({ applicationId: query.applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Level set"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => (
          MergeRoutes.LevelSets.duplicateRequest({ ...values, applicationId: query.applicationId })
        )}
        title="Duplicate Level set"
        applicationId={query.applicationId}
      />
      <Pagination
        pageCount={response ? response._meta.pages : 1}
      />
    </div>
  );
}
