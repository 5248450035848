/* eslint-disable gs/plz-camel-case-for-action-names */
import {
  last, cloneDeep, map, isNil,
} from 'lodash';
import { reorderList } from '@services/reorder';
import reorderMeta from '@services/reorderMeta';
import { addUuid } from '@services/recursivelyProcessObject';
import { v4 } from 'uuid';

const resolveState = (state, variantIndex) => (
  isNil(variantIndex) ? state.currentVariantAttributes : state.variantsAttributes[variantIndex]
);

const reorderLevels = (list, startIndex, endIndex) => {
  let index = 1;

  const levels = reorderList(list, startIndex, endIndex);
  const resultLevels = levels.map((element) => {
    const level = element;
    level.number = index;

    if (!level._destroy) index += 1;

    return level;
  });

  const uuids = map(list, '_uuid');
  const reorderedUuids = map(resultLevels, '_uuid');

  const [oldPositions, newPositions] = uuids.reduce((memo, uuid) => {
    memo[0].push(uuids.indexOf(uuid));
    memo[1].push(reorderedUuids.indexOf(uuid));

    return memo;
  }, [[], []]);

  return { result: resultLevels, changeSet: [oldPositions, newPositions] };
};

/* eslint-disable no-param-reassign, array-callback-return */

export default function levelsReducer(state, action) {
  const { variantIndex } = action;

  const levelsState = resolveState(state, variantIndex);

  if (action.actionType === 'remove_level') {
    const { deleteUuid } = action;

    const levels = cloneDeep(levelsState.levelsAttributes);
    const index = levels.findIndex(({ _uuid }) => _uuid === deleteUuid);

    levels[index]._destroy = true;

    levelsState.levelsAttributes = reorderLevels(levels).result;

    return state;
  }

  if (action.actionType === 'move_level') {
    const {
      oldIndex,
      newIndex,
      meta,
      metaErrorsPath,
    } = action;

    const levels = cloneDeep(levelsState.levelsAttributes);

    if (levelsState.levelsAttributes.length <= 1) return state;

    const { result, changeSet: [fromIndices, toIndices] } = reorderLevels(levels, oldIndex, newIndex);

    levelsState.levelsAttributes = result;

    if (meta.errors) {
      meta.errors = { ...meta.errors, ...reorderMeta(meta, fromIndices, toIndices, metaErrorsPath).errors };
    }

    return state;
  }

  if (action.actionType === 'add_level') {
    const { defaultOptions = {} } = action;
    const levels = cloneDeep(levelsState.levelsAttributes);
    const levelToAdd = last(levels.filter((l) => !l._destroy)) || defaultOptions;

    levelToAdd.number = (levelToAdd.number || 0) + 1;
    levelToAdd._destroy = false;
    levelToAdd.id = null;
    levelToAdd._uuid = v4();

    levelsState.levelsAttributes.push(levelToAdd);
  }

  if (action.actionType === 'add_empty_level') {
    const { options = {}, position } = action;
    const levels = cloneDeep(levelsState.levelsAttributes);
    const lastLevel = last(levels.filter((l) => !l._destroy));
    const level = addUuid({
      ...options, _destroy: false, id: null, number: lastLevel ? lastLevel.number + 1 : 1,
    });

    if (position && position === 'start') {
      levelsState.levelsAttributes = reorderLevels([level, ...levelsState.levelsAttributes]).result;
      return state;
    }

    levelsState.levelsAttributes.push(addUuid({
      ...options, _destroy: false, id: null, number: lastLevel ? lastLevel.number + 1 : 1,
    }));
  }

  return state;
}
