import React from 'react';
import { FilesRoutes as Routes } from '@pages/routes';
import { PageTitle } from '@pages/common';
import FilesIndex from '@pages/files/Index';
import FilesNew from '@pages/files/New';
import FilesEdit from '@pages/files/Edit';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function files() {
  return [
    <Route
      key={Routes.indexRawPath}
      path={Routes.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Files" />
          <FilesIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.newRawPath}
      path={Routes.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="File" action="New" />
          <FilesNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.editRawPath}
      path={Routes.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="File" action="Edit" />
          <FilesEdit />
        </RouteContainer>
    )}
    />,
  ];
}
