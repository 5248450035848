import React from 'react';
import { Card } from 'react-bootstrap';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useConfirm } from '@hooks';
import { LocalizationsRoutes } from '@pages/routes';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';

import IconButton from '@controls/buttons';

export default function DiffTableButtonGroup({
  setMigrationInProgress, keyConfigs, keyIdsToSkip, setKeyIdsToSkip,
}) {
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();
  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Apply to Live',
    actionName: 'process',
    setData: () => setMigrationInProgress(true),
  });

  const applyTranslationsToLive = () => {
    const changedKeyConfigIds = keyConfigs.map(({ id }) => id);

    LocalizationsRoutes.Translations
      .applyToLiveRequest({ applicationId, changedKeyConfigIds, keyIdsToSkip })
      .then(responseHandler);
  };

  const onApplyToLive = () => confirm
    .showConfirmation({ title: 'All test translations will be applied to live. Continue?' })
    .then(applyTranslationsToLive);

  return (
    <Card className="mb-3">
      <Card.Body>
        <ButtonToolbar>
          <IconButton.Save onClick={onApplyToLive} disabled={keyConfigs.length === 0}>
            Apply to Live
          </IconButton.Save>
          <IconButton.Cancel
            to={LocalizationsRoutes.Translations.indexPath({ applicationId, type: 'test' })}
            onClick={() => setKeyIdsToSkip([])}
          />
        </ButtonToolbar>
      </Card.Body>
    </Card>
  );
}
