import { at, first, join } from 'lodash';
import camelCase from '@services/camelCase';

import APP_DATA from '@services/appData';

import useCurrentApplication from './useCurrentApplication';

const LOCALE_NAMESPACE = 'modelsLocales';
const PATH_DELIMITER = '.';

export default function useI18n() {
  const application = useCurrentApplication();
  let applicationTypeName;

  const buildPath = (path) => join(path, PATH_DELIMITER);
  const getTranslation = (path) => first(at(APP_DATA, buildPath([LOCALE_NAMESPACE, path])));
  const strictTranslateFn = (path) => {
    if (!applicationTypeName && !(application && application.typeName)) return getTranslation(buildPath([path]));

    return (
      getTranslation(buildPath([
        applicationTypeName || application.typeName.split('/').map((s) => camelCase(s, 'lower')).join('.'),
        path,
      ]))
    );
  };
  const fallbackTranslateFn = (path, _applicationTypeName) => {
    applicationTypeName = _applicationTypeName;
    const translation = strictTranslateFn(path);
    if (translation) return translation;

    return getTranslation(buildPath([path]));
  };

  return ({
    translate: {
      strict: strictTranslateFn,
      fallback: fallbackTranslateFn,
    },
  });
}
