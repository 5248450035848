import React from 'react';
import IndexPage from '@pages/puzzle-time/ConfigIndex';
import { useRouter } from '@tripledotstudios/react-core';
import {
  getSingleScreenTutorialConfigs,
  deleteSingleScreenTutorialConfig,
} from '@requests/puzzle-time/singleScreenTutorialConfigs';

export default function Index() {
  const { query } = useRouter();

  return (
    <IndexPage
      configName="Single Screen Tutorial Config"
      path={`/admin/applications/${query.applicationId}/puzzle_time/single_screen_tutorial_configs`}
      getConfigFunction={getSingleScreenTutorialConfigs}
      deleteConfigFunction={deleteSingleScreenTutorialConfig}
    />
  );
}
