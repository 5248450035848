import React from 'react';
import IndexPage from '@pages/puzzle-time/ConfigIndex';
import { useRouter } from '@tripledotstudios/react-core';
import { getNewGamePopupConfigs, deleteNewGamePopupConfig } from '@requests/puzzle-time/newGamePopupConfigs';

export default function Index() {
  const { query } = useRouter();

  return (
    <IndexPage
      configName="New game popup Config"
      path={`/admin/applications/${query.applicationId}/puzzle_time/new_game_popup_configs`}
      getConfigFunction={getNewGamePopupConfigs}
      deleteConfigFunction={deleteNewGamePopupConfig}
    />
  );
}
