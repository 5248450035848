import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { FormGroup, useCurrentApplication } from '@hooks';
import { Field, Label } from '@controls/form';

import { populateChapters } from '@pages/metagame/chapter-based/chapter-sets/shared';

export default function BulkUpdateModal({
  chapters, dispatch, handleClose, values,
}) {
  const { currentApplication } = useCurrentApplication();

  const onSubmit = async () => {
    const { levelsNumber } = (values.massUpdate || {});
    dispatch({ actionType: 'massUpdate', chapters });
    if (levelsNumber) {
      await populateChapters({
        application: currentApplication, chapters, dispatch, overrides: { levelsNumber },
      });
    }
    handleClose();
  };

  return (
    <Modal show={!!chapters} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Chapter(s)</Modal.Title>
      </Modal.Header>
      <FormGroup name="massUpdate">
        <Modal.Body>
          <b>Chapters to Update:</b>
          <div className="mt-1 mb-2">
            {(chapters || []).map((chapter) => (
              <div key={chapter.id} className="mb-1">
                {chapter.name || `Chapter #${chapter.position}`}
              </div>
            ))}
          </div>
          <Label text="Number of levels">
            <Field name="levelsNumber" type="number" />
          </Label>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={onSubmit}>Update</Button>
          <Button type="button" variant="secondary" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </FormGroup>
    </Modal>
  );
}
