import camelCase from 'lodash/camelCase';
import reduce from 'lodash/reduce';

export default function settingToKeys(key, translate) {
  const locale = translate.fallback(`gameSettings.${camelCase(key)}`);
  const nestedKeys = reduce(locale, (keys, config) => {
    if (typeof config === 'object' && config.clientKey) {
      return [...keys, config.clientKey];
    }
    return keys;
  }, []);
  return nestedKeys.length ? nestedKeys : [key];
}
