import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useFormContext } from '@hooks';
import {
  Field,
  SelectField,
  localizedLabelFactory,
  TagsField,
  TimePeriodField,
} from '@controls/form';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { MissionsRoutes, MyPetCafeRoutes } from '@pages/routes';

import LevelsListWrapper from '@components/journeys/banks/levels/LevelsListWrapper';

import Rules from '@components/missions/allocation-templates/form/target-templates/target-template/Rules';
import Milestones from './Milestones';

const LocalizedLabel = localizedLabelFactory('myPetCafe.soloMissions.eventBanks.events.fields');

export default function Events({ events, isArchived, readOnly: bankReadOnly }) {
  const [missionTypeOptions, setMissionTypeOptions] = useState([]);
  const { dispatch } = useFormContext();
  const { query: { applicationId } } = useRouter();

  const onEntityAdd = () => dispatch({
    actionType: 'addEmptyEntity',
    options: {
      milestonesAttributes: [],
      parametersAttributes: [],
      duration: 0,
      eventGap: 0,
    },
  });

  useEffect(() => {
    (async () => {
      const missionTypes = await requestWithToSelectOptions(MissionsRoutes.MissionTypes.indexRequest, applicationId);

      setMissionTypeOptions(missionTypes);
    })();
  }, []);

  return (
    <LevelsListWrapper
      entityAttributes={events}
      onEntityAdd={onEntityAdd}
      attributesName="eventsAttributes"
      metaErrorsPath={['errors', 'eventsAttributes']}
      entityNameTranslationPath="soloMissions.eventBanks.levels.name"
      bulkCopyRoutes={MyPetCafeRoutes.SoloMissions.EventBanks}
      disabled={isArchived}
      readOnly={bankReadOnly}
    >
      {({ index, entity, readOnly }) => (
        <>
          <LocalizedLabel name="name">
            <Field type="text" name="name" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="missionTypeId">
            <SelectField
              name="missionTypeId"
              options={missionTypeOptions}
              isDisabled={readOnly}
            />
          </LocalizedLabel>
          <LocalizedLabel name="parameters">
            <TagsField
              name="parametersAttributes"
              propertyName="value"
              placeholder="Add a parameter..."
              disabled={readOnly}
              inline
            />
          </LocalizedLabel>
          <LocalizedLabel name="rules">
            <Rules
              path={`eventsAttributes.${index}`}
              rulesAttributes={entity.rulesAttributes}
              disabled={readOnly}
            />
          </LocalizedLabel>
          <LocalizedLabel name="duration">
            <TimePeriodField name="duration" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="eventGap">
            <TimePeriodField name="eventGap" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="assetKey">
            <Field type="text" name="assetKey" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="portraitSpineAssetKey">
            <Field type="text" name="portraitSpineAssetKey" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="portraitSpineExpressionName">
            <Field type="text" name="portraitSpineExpressionName" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="titleLocalisationKey">
            <Field type="text" name="titleLocalisationKey" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="missionDescriptionLocalisationKey">
            <Field type="text" name="missionDescriptionLocalisationKey" disabled={readOnly} />
          </LocalizedLabel>
          <Milestones
            eventIndex={index}
            milestones={entity.milestonesAttributes}
            disabled={readOnly || isArchived}
          />
        </>
      )}
    </LevelsListWrapper>
  );
}
