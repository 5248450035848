import React, { useCallback } from 'react';
import { Field, SelectField, Label } from '@controls/form';
import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';

import { DuplicationModal } from '@pages/common';

export default function DuplicationModalWithWorldSchemaId({
  entityName, handleClose, onSubmit, record, title, applicationId, isPrioritised = false, buildRedirectPath,
}) {
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(applicationId), []);

  return (
    <DuplicationModal
      buildRedirectPath={buildRedirectPath}
      record={record}
      entityName={entityName}
      onSubmit={onSubmit}
      title={title || `Duplicate ${entityName}`}
      handleClose={handleClose}
    >
      <Label text="Name"><Field type="text" name="name" /></Label>
      {isPrioritised && <Label text="Priority"><Field type="number" name="priority" /></Label>}
      <Label text="World Schema">
        <SelectField
          name="newSchemaId"
          options={fetchSchemaOptions}
        />
      </Label>
    </DuplicationModal>
  );
}
