import { localizedEnumValueString as enumValue } from '@services/enums';

export const TILE_GOALS = 'Tile::ModesConfigs::Goals';
export const TILE_TELEPORTING = 'Tile::ModesConfigs::Teleporting';

const gameModeOptions = [
  { label: 'Goals', value: TILE_GOALS },
  { label: 'Teleporting', value: TILE_TELEPORTING },
];

export const generateGoalsName = (tilesPercent, tileTypesPercents) => {
  let newName = 'Goals';
  newName += tilesPercent ? `_${tilesPercent}%P` : '';
  newName += tileTypesPercents.length ? `_${tileTypesPercents.map(
    (percent, index) => `${percent}%T${index + 1}`).join('_')}` : '';
  return newName;
};

export const generateTeleportingName = (tapCount, mode) => {
  let newName = 'Teleporting';
  newName += tapCount ? `_${tapCount}` : '';
  newName += typeof mode === 'number' ? `_${enumValue('Tile::ModesConfigs::TeleportingModes', mode)}` : '';
  return newName;
};

export default gameModeOptions;
