import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

export default function buildOnSubmit({
  applicationId, data, entityName, statusesEnum, onSubmitFunction, confirm, routes,
}) {
  const archiveResponseHandler = redirectIfSuccessHandlerFactory({
    entityName,
    actionName: 'archive',
    setData: () => null,
  });

  return (values) => {
    const { id, name } = values;
    if (data.status !== statusesEnum.ARCHIVED && values.status === statusesEnum.ARCHIVED) {
      return confirm.showConfirmation({
        body: `${entityName} '${name}' will be archived and not available for work. Continue?`,
      }).then(() => routes.archiveRequest({ id, applicationId }).then(archiveResponseHandler));
    }

    return onSubmitFunction(values);
  };
}
