import React from 'react';
import classnames from 'classnames';
import getFieldLocale from '@services/getFieldLocale';

const defaultTooltipBuilder = (tooltip, clientKey) => {
  if (!tooltip && !clientKey) return null;

  return (
    <>
      {tooltip && tooltip}
      {clientKey && (
        <p className={classnames('mb-0', { 'mt-2': tooltip })}><strong>{`(${clientKey})`}</strong></p>
      )}
    </>
  );
};

export default function localizeField(baseName, labelText, tooltips, namespace, locale, tooltipBuilder) {
  const fieldLocale = getFieldLocale(namespace, baseName);
  const container = fieldLocale || locale;

  const clientKey = container && container.clientKey;
  const label = labelText || (container && container.label);

  const resolvedTooltipBuilder = tooltipBuilder || defaultTooltipBuilder;
  const tooltip = (tooltips && tooltips[baseName])
    || resolvedTooltipBuilder(container && container.tooltip, clientKey);

  return {
    label, tooltip, clientKey, raw: container,
  };
}
