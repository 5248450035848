import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { get, omit } from 'lodash';

import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useFormContext } from '@hooks';

const EditToggle = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  outline: none;
`;

const InlineFieldContainer = styled.div`
  display: block;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: start;
`;

const Value = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
`;

export default function InlineField({
  displayValue = null, submitOnEnter = false, children, disabled, name,
}) {
  const {
    setFieldValue, submitForm, values, isSubmitting,
  } = useFormContext();
  const value = displayValue || values[name];
  const [active, setActive] = useState(null);
  useEffect(() => {
    if (!isSubmitting && !get(values.meta, `errors.${name}`)) {
      setActive(null);
    }
  }, [isSubmitting]);

  const onKeyDown = async (event) => {
    switch (event.key) {
      case 'Escape': {
        const newMeta = { ...values.meta, errors: omit(get(values.meta, 'errors', {}), name) };
        setFieldValue('meta', newMeta);
        setFieldValue(name, active);
        setActive(null);
        break;
      }
      case 'Enter':
        if (submitOnEnter && !event.ctrlKey && !event.shiftKey) {
          submitForm();
        }
        break;
      default:
    }
  };

  return (
    <InlineFieldContainer onKeyDown={onKeyDown}>
      {active !== null
        ? children
        : (
          <ValueContainer>
            {!disabled && (
              <EditToggle onClick={() => setActive(values[name] || true)}>
                <FontAwesomeIcon size="lg" icon={faPencilAlt} />
              </EditToggle>
            )}
            <Value>{value}</Value>
          </ValueContainer>
        )}
    </InlineFieldContainer>
  );
}
