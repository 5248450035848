import React from 'react';
import { Col } from 'react-bootstrap';
import { TextFilter } from '@pages/common';

export default function NameFilter({ updateFilterField }) {
  return (
    <Col md={6}>
      <TextFilter
        name="nameContAny"
        label="Name"
        updateFilterField={updateFilterField}
      />
    </Col>
  );
}
