import { React, useMemo } from 'react';

import { FormGroup } from '@hooks';
import { localizedLabelFactory, Field, SelectField } from '@controls/form';
import { fromClassNameToOptions } from '@services/enums';

import BiddingFloorFields from './bidding-floors/Fields';

const tagOptions = fromClassNameToOptions('Ads::Configuration::MaxInterstitialsAnalyticLabels');

export default function ConfigurationItemFields({
  values, disabled, rewardedVideo,
}) {
  const labelKey = rewardedVideo ? 'ads.configuration.rewardedVideoMaxAdUnitsConfigurationAttributes.itemsAttributes'
    : 'ads.configuration.maxAdUnitsConfigurationAttributes.itemsAttributes';
  const LocalizedLabel = useMemo(() => localizedLabelFactory(labelKey), [labelKey]);

  return (
    <>
      <LocalizedLabel name="iosAdUnitId" labelSize={4} fieldSize={8}>
        <Field name="iosAdUnitId" disabled={disabled} />
      </LocalizedLabel>
      <LocalizedLabel name="androidAdUnitId" labelSize={4} fieldSize={8}>
        <Field name="androidAdUnitId" disabled={disabled} />
      </LocalizedLabel>
      <LocalizedLabel name="tag" labelSize={4} fieldSize={8}>
        <SelectField name="tag" options={tagOptions} disabled={disabled} />
      </LocalizedLabel>
      <LocalizedLabel name="retryEnabled" labelSize={4} fieldSize={8}>
        <Field name="retryEnabled" disabled={disabled} type="checkbox" />
      </LocalizedLabel>
      <LocalizedLabel name="sequentialLoadingEnabled" labelSize={4} fieldSize={8}>
        <Field name="sequentialLoadingEnabled" disabled={disabled} type="checkbox" />
      </LocalizedLabel>
      <LocalizedLabel name="biddingFloor" labelSize={4} fieldSize={8}>
        <FormGroup name="biddingFloor">
          <BiddingFloorFields
            values={values.biddingFloor}
            disabled={disabled}
            clientKey="cpm_floor"
            labelKey={`${labelKey}.biddingFloor`}
            rewardedVideo={rewardedVideo}
          />
        </FormGroup>
      </LocalizedLabel>
    </>
  );
}
