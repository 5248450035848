import React from 'react';

import styled from 'styled-components';

import { Row, Col } from 'react-bootstrap';
import { useFormContext } from '@hooks';
import { useRouter } from '@tripledotstudios/react-core';

import { Badge } from '@pages/common';

import IconButton from '@controls/buttons';
import { LocalizationsRoutes } from '@pages/routes';
import useAvailabilityTypes from '../shared/services/useAvailabilityTypes';
import NewTranslationKey from './NewTranslationKey';

const StyledBadge = styled(Badge)`
  padding: 8px 10px;
  vertical-align: -webkit-baseline-middle;
`;

export default function TopActionButtons({ hasDiff }) {
  const { isTest } = useAvailabilityTypes();
  const { values: { keyConfigs } } = useFormContext();
  const { query } = useRouter();

  return isTest && (
    <Row className="mb-3">
      <Col>
        {hasDiff ? (
          <IconButton.Edit
            variant="warning"
            className="mb-2"
            to={`${LocalizationsRoutes.Translations.indexPath({ ...query, type: 'live' })}?diff=true`}
            disabled={keyConfigs.length > 0}
          >
            Review difference
          </IconButton.Edit>
        ) : (
          <StyledBadge bg="success">No difference between Test and Live</StyledBadge>
        )}
      </Col>
      <Col><NewTranslationKey /></Col>
    </Row>
  );
}
