import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { formResponseHandler } from '@requests/responseHandlers';
import { TileRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { response, setResponse } = useQuery(Routes.ModesConfigs.editRequest);
  const { query: { applicationId } } = useRouter();

  const responseHandler = formResponseHandler({
    entityName: 'Mode Config',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.ModesConfigs.updateRequest({ ...values, applicationId }).then(responseHandler);

  return response && <Form data={response} onSubmit={onSubmit} actionName="Update" />;
}
