import React from 'react';
import { Col } from 'react-bootstrap';

import {
  Filter,
  TextFilter,
  InUseSelectFilter,
} from '@pages/common';

export default function KeysFilter() {
  return (
    <Filter defaultComponents={['availabilityStateIn', 'id']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <InUseSelectFilter updateFilterField={updateFilterField} />
          </Col>
        </>
      )}
    </Filter>
  );
}
