import React from 'react';
import { compact } from 'lodash';

import { PageHeader } from '@pages/common';
import { generateChapterBasedRoutes } from '@pages/routes';
import { useQuery, useCurrentApplication, Form } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { Label, Field, LabelsFormGroup } from '@controls/form';
import { combineReducers, chapterSetReducer, rewardableReducer } from '@reducers';

import FormButtons from './FormButtons';

const processResponse = (response) => ({ ...response.data, validChapters: {} });

export default function New({ ChaptersComponent, reducer }) {
  const { currentApplication, applicationName, typeName: appType } = useCurrentApplication();
  const ChapterSetRoutes = generateChapterBasedRoutes(appType).ChapterSets;
  const { response: data, setResponse } = useQuery(ChapterSetRoutes.newRequest, {}, processResponse);
  const fullData = { ...data, applicationName, applicationId: currentApplication.id };

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Chapter set',
    actionName: 'create',
    redirectPath: ({ id }) => ChapterSetRoutes.editPath({ applicationId: currentApplication.id, id }),
    setData: setResponse,
  });

  const onSubmit = async (values) => {
    const response = await ChapterSetRoutes.createRequest(values);
    responseHandler(response, values);
  };

  if (!data) { return null; }

  return (
    <Form
      initialValues={fullData}
      onSubmit={onSubmit}
      enableReinitialize
      reducer={combineReducers(compact([chapterSetReducer, rewardableReducer, reducer]))}
    >
      {() => (
        <>
          <PageHeader title="New Chapter Set">
            <FormButtons cancelButtonPath={ChapterSetRoutes.indexPath({ applicationId: currentApplication.id })} />
          </PageHeader>

          <Label text="Name*">
            <Field name="name" fast />
          </Label>

          <LabelsFormGroup />

          <ChaptersComponent />
        </>
      )}
    </Form>
  );
}
