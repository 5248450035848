import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';
import { BooleanIcon } from '@pages/common';
import {
  Label, Field, SelectField, ServerError,
} from '@controls/form';
import { useQuery } from '@hooks';
import { WoodokuSagaRoutes } from '@pages/routes';
import { CopyToClipboardAddon } from '@controls/CopyToClipboardButton';
import { fromClassNameToOptions } from '@services/enums';
import { BanksEdit } from '@pages/metagame/chapter-based';
import entityIdentity from '@components/shared/entityIdentity';

const figureSetOptions = () => {
  const { query: { applicationId } } = useRouter();
  const { response } = useQuery({
    request: WoodokuSagaRoutes.FigureSets.indexRequest,
    queryKey: ['figureSets', applicationId],
    toSelectOptions: true,
  });
  return response;
};

const difficultyOptions = fromClassNameToOptions('WoodokuSaga::LevelLayoutDifficultyTypes');

const EntityHeader = ({ entity, entityName }) => {
  const {
    contentHash, name, validForUse, updatedAt, figureSetId, difficulty, index,
  } = entity;

  const figureSetIdName = figureSetOptions().filter((item) => item.value === figureSetId);
  const difficultyName = difficultyOptions.filter((item) => item.value === difficulty);

  return (
    <table className="table table-borderless mb-0">
      <tbody>
        <tr className="d-flex align-items-center">
          <td className="bg-transparent text-break p-0 pe-2 col-1">
            {entityIdentity({ entity: { ...entity, name: null }, entityName })}
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-1">
            <div>
              {'Hash: '}
              {contentHash}
            </div>
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {'Layout Name: '}
            {name}
            <ServerError name={`levelLayoutsAttributes.${index}.name`} full />
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {'Figure Set Override: '}
            {figureSetIdName[0]?.label || 'None'}
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-1">
            {'Difficulty: '}
            {difficultyName[0]?.label}
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {'Valid for use: '}
            <BooleanIcon value={validForUse} className="ms-1" />
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {'Updated at: '}
            {updatedAt}
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-1" aria-label="section" />
        </tr>
      </tbody>
    </table>
  );
};

const levelLayoutFields = ({ entity: { contentHash }, disabled }) => (
  <div className="d-flex flex-row justify-content-around">
    <div className="flex-fill pe-2">
      <Label text="Layout Name">
        <Field name="name" />
      </Label>
      <Label text="Hash">
        <span className="d-flex align-items-center">
          <InputGroup>
            <Field name="contentHash" disabled />
            <CopyToClipboardAddon text={contentHash} />
          </InputGroup>
        </span>
      </Label>
      <Label text="Figure Set Override">
        <SelectField
          name="figureSetId"
          isDisabled={disabled}
          includeEmpty
          options={figureSetOptions()}
        />
      </Label>
      <Label text="Difficulty">
        <SelectField
          name="difficulty"
          isDisabled={disabled}
          options={difficultyOptions}
        />
      </Label>
    </div>
  </div>
);

export default function Edit(props) {
  return (
    <BanksEdit
      EntityHeader={EntityHeader}
      levelLayoutFields={levelLayoutFields}
      {...props}
    />
  );
}
