import React from 'react';

import {
  FormButtonsGroup,
  StaticFieldsFormGroup,
  Label,
  Field,
  SelectField,
} from '@controls/form';
import { Form, useCurrentApplication } from '@hooks';
import { PageHeader } from '@pages/common';
import { TesterDeviceRoutes } from '@pages/routes';
import { fromClassNameToOptions } from '@services/enums';
import Favourite from '@components/favourites/Favourite';

export default function AppUpdatesForm({ data, onSubmit }) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const logLevelsOptions = fromClassNameToOptions('Accounts::TesterDevicesLogLevels');

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <>
          <PageHeader
            title={`${data.id ? 'Edit' : 'New'} Tester Device`}
            leadingAccessories={(
              <Favourite
                favorableId={values.id}
                applicationId={applicationId}
                type="Accounts::TesterDevice"
              />
            )}
          >
            <FormButtonsGroup
              cancelButtonPath={TesterDeviceRoutes.indexPath({ applicationId })}
            />
          </PageHeader>
          <Label text="IDFV">
            <Field type="text" name="idfv" />
          </Label>
          <Label text="Name">
            <Field type="text" name="name" />
          </Label>
          <Label
            text="Assign to experiments"
            tooltipText="Disabling also makes variant rule always evaluated to false"
          >
            <Field type="checkbox" name="assignToExperiments" />
          </Label>
          <Label
            text="Send overrides"
            tooltipText="Applies to all entities with Overrides logic (e.g. Game Settings, Ad Configs)"
          >
            <Field type="checkbox" name="sendOverrides" />
          </Label>
          <Label text="Enabled as test device">
            <Field type="checkbox" name="enabled" />
          </Label>
          <StaticFieldsFormGroup data={data} />
          <Label text="Log Level">
            <SelectField
              name="logLevel"
              options={logLevelsOptions}
            />
          </Label>
          <Label text="Enable ad log">
            <Field type="checkbox" name="adLogEnabled" />
          </Label>
          <Label text="Disable top priority overrides">
            <Field type="checkbox" name="superOverridesDisabled" />
          </Label>
          <Label text="Automation device">
            <Field type="checkbox" name="automationDevice" />
          </Label>
          {values.automationDevice && (
            <Label text="Base CDN Url">
              <Field name="baseCdnUrl" />
            </Label>
          )}
        </>
      )}
    </Form>
  );
}
