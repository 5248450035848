import React from 'react';
import { Tab } from 'react-bootstrap';

import { Form, useConfirm } from '@hooks';
import { useRouter } from '@tripledotstudios/react-core';
import {
  Label, Field, FormButtonsGroup, StaticFieldsFormGroup,
} from '@controls/form';
import TabsWrapper from '@controls/UncontrolledTabs';
import { MergeRoutes } from '@pages/routes';
import { PageHeader } from '@pages/common';
import worldSchemaReducer from '@reducers/merge/worldSchemaReducer';

import { Items, Postcards, Experts } from './tabs';

export default function WorldSchemasForm({ data, onSubmit }) {
  const { query } = useRouter();
  const confirm = useConfirm();

  const {
    items,
    postcards,
    postcardSets,
    experts,
    createdAt,
    updatedAt,
    ...otherData
  } = data;

  const storyOrderIdsToUuid = (values, options) => values
    .reduce((acc, v) => {
      const option = options.find((o) => o.label === v.toString());
      return option ? [...acc, option.value] : acc;
    }, []);
  const storyOrderOption = (order) => ({ label: order.internalId, value: order._uuid });
  const buildStoryOrdersOptions = (storyOrders) => storyOrders.map(storyOrderOption);
  const buildStoryOrdersAttributes = (storyOrders) => {
    const options = [];
    return storyOrders.map((order) => {
      const result = {
        ...order,
        prerequisites: storyOrderIdsToUuid(order.prerequisites, options),
        prerequisiteOptions: [...options],
      };
      options.push(storyOrderOption(order));
      return result;
    });
  };
  const buildPostcardChangesAttributes = (postcard) => postcard.postcardChangesAttributes.map((change) => ({
    ...change,
    storyOrders: storyOrderIdsToUuid(change.storyOrders, postcard.storyOrdersOptions),
  }));
  const postcardsAttributes = postcards
    .map((postcard) => ({
      ...postcard,
      storyOrdersOptions: buildStoryOrdersOptions(postcard.storyOrders),
    })).map((postcard) => ({
      ...postcard,
      postcardChangesAttributes: buildPostcardChangesAttributes(postcard),
    })).map((postcard) => (
      postcard.isEditable ? {
        ...postcard,
        storyOrdersAttributes: buildStoryOrdersAttributes(postcard.storyOrders),
      } : postcard
    ));

  // Yeah, it's a frontend validation in a modal. We don't validate the first 3 postcards since IRL they are hardcoded
  // for the rest of them we want to display a warning but still allow to save the form if the user insists
  const validatedSubmit = (values) => {
    const errorMessage = values.postcardsAttributes.reduce((acc, postcard) => {
      if (postcard.position <= 3) return acc;

      const errors = [];
      if (!postcard.polaroidImageCdnUrl && !postcard.polaroidImage) { errors.push('No Polaroid uploaded'); }
      if (!postcard.logoImageCdnUrl && !postcard.logoImage) { errors.push('No Logo uploaded'); }
      if (!postcard.mainAssetKeyId) { errors.push('No Asset Bundle linked'); }

      return errors.length === 0
        ? acc
        : [...acc, `Postcard ${postcard.internalId || `#${postcard.position}`}: ${errors.join(', ')}`];
    }, []).join('\n');
    if (errorMessage === '') { return onSubmit(values); }

    return confirm.showConfirmation({
      body: `The following postcards are misconfigured\n${errorMessage}\nAre you sure you want to save this Schema?`,
    }).then(() => onSubmit(values));
  };

  return (
    <Form
      initialValues={{
        ...otherData, postcardsAttributes, expertsAttributes: experts,
      }}
      onSubmit={validatedSubmit}
      reducer={worldSchemaReducer}
    >
      {({ dispatch, handleChange, values }) => (
        <>
          <PageHeader title="Edit World Schema">
            <FormButtonsGroup
              cancelButtonPath={MergeRoutes.WorldSchemas.indexPath({ applicationId: query.applicationId })}
            />
          </PageHeader>

          <Label text="Name"><Field type="text" name="name" /></Label>
          <Label text="Description"><Field type="text" name="description" /></Label>
          <Label text="Min version"><Field type="text" name="minVersion" /></Label>
          <Label text="Max version"><Field type="text" name="maxVersion" /></Label>

          <StaticFieldsFormGroup data={data} />

          {data.id && (
            <TabsWrapper>
              <Tab eventKey="#items" title="Items" className="mt-4">
                <Items values={items} />
              </Tab>
              <Tab eventKey="#postcards" title="Postcards" className="mt-4">
                <Postcards
                  dispatch={dispatch}
                  handleChange={handleChange}
                  values={values}
                />
              </Tab>
              <Tab eventKey="#experts" title="Experts" className="mt-4">
                <Experts
                  dispatch={dispatch}
                  experts={values.expertsAttributes}
                  items={items}
                />
              </Tab>
            </TabsWrapper>
          )}
        </>
      )}
    </Form>
  );
}
