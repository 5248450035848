import React from 'react';
import { PageTitle } from '@pages/common';

import { WoodokuRoutes } from '@pages/routes';
import {
  DynamicConfigsIndex,
  DynamicConfigsEdit,
  DynamicConfigsNew,

  FigureSetDynamicConfigsIndex,
  FigureSetDynamicConfigsEdit,
  FigureSetDynamicConfigsNew,

  TileTypesIndex,
  TileTypesNew,
  TileTypesEdit,

  ShapesBankSetsIndex,
  ShapesBankSetsNew,
  ShapesBankSetsEdit,

  XpLevelConfigsIndex,
  XpLevelConfigsNew,
  XpLevelConfigsEdit,

  TriggerConditionsIndex,
  TriggerConditionsNew,
  TriggerConditionsEdit,

  PerfectFitIndex,
  PerfectFitNew,
  PerfectFitEdit,

  BonusLevelsLevelBanksIndex,
  BonusLevelsLevelBanksNew,
  BonusLevelsLevelBanksEdit,

  BonusLevelsEventForm,
  BonusLevelsEventExperimentScoped,

  HardJourneysIndex,
  HardJourneysNew,
  HardJourneysEdit,
} from '@pages/woodoku';

import DailyChallengesExperimentScoped from '@pages/woodoku/daily-challenges/ExperimentScoped';
import JourneysExperimentScoped from '@pages/woodoku/journeys/ExperimentScoped';
import ActivationJourneysExperimentScoped from '@pages/woodoku/activation-journeys/ExperimentScoped';
import EndOfJourneyContentExperimentScoped from '@pages/woodoku/end-of-journey-content/ExperimentScoped';
import PromoJourneysExperimentScoped from '@pages/woodoku/promo-journeys/ExperimentScoped';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function woodoku() {
  return [
    [
      <Route
        key={WoodokuRoutes.DynamicConfigs.indexRawPath}
        path={WoodokuRoutes.DynamicConfigs.indexRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Dynamic Configs" />
            <DynamicConfigsIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.DynamicConfigs.newRawPath}
        path={WoodokuRoutes.DynamicConfigs.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Dynamic Config" action="New" />
            <DynamicConfigsNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.DynamicConfigs.editRawPath}
        path={WoodokuRoutes.DynamicConfigs.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Dynamic Config" action="Edit" />
            <DynamicConfigsEdit />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.FigureSetDynamicConfigs.indexRawPath}
        path={WoodokuRoutes.FigureSetDynamicConfigs.indexRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Figure Set Dynamic Configs" />
            <FigureSetDynamicConfigsIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.FigureSetDynamicConfigs.newRawPath}
        path={WoodokuRoutes.FigureSetDynamicConfigs.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Figure Set Dynamic Config" action="New" />
            <FigureSetDynamicConfigsNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.FigureSetDynamicConfigs.editRawPath}
        path={WoodokuRoutes.FigureSetDynamicConfigs.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Figure Set Dynamic Config" action="Edit" />
            <FigureSetDynamicConfigsEdit />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.TileTypes.indexRawPath}
        path={WoodokuRoutes.TileTypes.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="Tile Types" />
            <TileTypesIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.TileTypes.newRawPath}
        path={WoodokuRoutes.TileTypes.newRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle entity="Tile Type" action="New" />
            <TileTypesNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.TileTypes.editRawPath}
        path={WoodokuRoutes.TileTypes.editRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle entity="Tile Type" action="Edit" />
            <TileTypesEdit />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.Journeys.EventConfigs.experimentScopedRawPath}
        path={WoodokuRoutes.Journeys.EventConfigs.experimentScopedRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Edit Journey variant" />
            <JourneysExperimentScoped />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.ActivationJourneys.EventConfigs.experimentScopedRawPath}
        path={WoodokuRoutes.ActivationJourneys.EventConfigs.experimentScopedRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Edit Activation Journey variant" />
            <ActivationJourneysExperimentScoped />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.PromoJourneys.EventConfigs.experimentScopedRawPath}
        path={WoodokuRoutes.PromoJourneys.EventConfigs.experimentScopedRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Edit Promo Journey variant" />
            <PromoJourneysExperimentScoped />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.EndOfJourneyContent.EventConfigs.experimentScopedRawPath}
        path={WoodokuRoutes.EndOfJourneyContent.EventConfigs.experimentScopedRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Edit Promo Journey variant" />
            <EndOfJourneyContentExperimentScoped />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.DailyChallenges.EventConfigs.experimentScopedRawPath}
        path={WoodokuRoutes.DailyChallenges.EventConfigs.experimentScopedRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Edit Daily Challenge Event Variant" />
            <DailyChallengesExperimentScoped />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.ShapesBankSets.indexRawPath}
        path={WoodokuRoutes.ShapesBankSets.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="All Shapes Bank Sets" />
            <ShapesBankSetsIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.ShapesBankSets.newRawPath}
        path={WoodokuRoutes.ShapesBankSets.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="All Shapes Bank Sets" action="New" />
            <ShapesBankSetsNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.ShapesBankSets.editRawPath}
        path={WoodokuRoutes.ShapesBankSets.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="All Shapes Bank Sets" action="Edit" />
            <ShapesBankSetsEdit />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.XpLevelConfigs.indexRawPath}
        path={WoodokuRoutes.XpLevelConfigs.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="JSON Configs" />
            <XpLevelConfigsIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.XpLevelConfigs.newRawPath}
        path={WoodokuRoutes.XpLevelConfigs.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="JSON Config" action="New" />
            <XpLevelConfigsNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.XpLevelConfigs.editRawPath}
        path={WoodokuRoutes.XpLevelConfigs.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="JSON Config" action="Edit" />
            <XpLevelConfigsEdit />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.TriggerConditions.indexRawPath}
        path={WoodokuRoutes.TriggerConditions.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="Trigger Conditions" />
            <TriggerConditionsIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.TriggerConditions.newRawPath}
        path={WoodokuRoutes.TriggerConditions.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Trigger Conditions" action="New" />
            <TriggerConditionsNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.PerfectFit.editRawPath}
        path={WoodokuRoutes.PerfectFit.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Perfect Fit" action="Edit" />
            <PerfectFitEdit />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.PerfectFit.indexRawPath}
        path={WoodokuRoutes.PerfectFit.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="Perfect Fits" />
            <PerfectFitIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.PerfectFit.newRawPath}
        path={WoodokuRoutes.PerfectFit.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Perfect Fit" action="New" />
            <PerfectFitNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.TriggerConditions.editRawPath}
        path={WoodokuRoutes.TriggerConditions.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Trigger Conditions" action="Edit" />
            <TriggerConditionsEdit />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.BonusLevels.LevelBanks.indexRawPath}
        path={WoodokuRoutes.BonusLevels.LevelBanks.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="Bonus Level Banks" />
            <BonusLevelsLevelBanksIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.BonusLevels.LevelBanks.newRawPath}
        path={WoodokuRoutes.BonusLevels.LevelBanks.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Bonus Level Bank" action="New" />
            <BonusLevelsLevelBanksNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.BonusLevels.LevelBanks.editRawPath}
        path={WoodokuRoutes.BonusLevels.LevelBanks.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Bonus Level Bank" action="Edit" />
            <BonusLevelsLevelBanksEdit />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.BonusLevels.Events.instanceRawPath}
        path={WoodokuRoutes.BonusLevels.Events.instanceRawPath}
        element={(
          <RouteContainer>
            <PageTitle action="Edit" entity="Bonus Event Config" />
            <BonusLevelsEventForm />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.BonusLevels.Events.experimentScopedRawPath}
        path={WoodokuRoutes.BonusLevels.Events.experimentScopedRawPath}
        element={(
          <RouteContainer>
            <PageTitle action="Edit" entity="Bonus Event Config Variant" />
            <BonusLevelsEventExperimentScoped />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.HardJourneys.indexRawPath}
        path={WoodokuRoutes.HardJourneys.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle translationPath="woodoku.hardJourney.pluralName" />
            <HardJourneysIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.HardJourneys.newRawPath}
        path={WoodokuRoutes.HardJourneys.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle action="New" translationPath="woodoku.hardJourney.name" />
            <HardJourneysNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={WoodokuRoutes.HardJourneys.editRawPath}
        path={WoodokuRoutes.HardJourneys.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle action="Edit" translationPath="woodoku.hardJourney.name" />
            <HardJourneysEdit />
          </RouteContainer>
        )}
      />,
    ],
  ];
}
