import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import {
  createSingleScreenTutorialConfig,
  newSingleScreenTutorialConfig,
} from '@requests/puzzle-time/singleScreenTutorialConfigs';

import Form from './Form';

export default function New() {
  const [data, setData] = useState();
  const { query } = useRouter();

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Single Screen Tutorial Config',
    actionName: 'creation',
    redirectPath: `/admin/applications/${query.applicationId}/puzzle_time/single_screen_tutorial_configs`,
    setData,
  });

  const onSubmit = (values) => createSingleScreenTutorialConfig(values).then(redirectIfSuccessHandler);

  useEffect(() => {
    newSingleScreenTutorialConfig(query).then((response) => setData(response.data));
  }, []);

  return data ? (
    <Form
      data={data}
      onSubmit={onSubmit}
      actionName="Create"
    />
  )
    : '';
}
