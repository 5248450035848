import React from 'react';

import TextareaAutosize from 'react-textarea-autosize';
import EditableCell from '../EditableCell';
import onFocus from '../../shared/services/editable-cell/onFocus';

const Input = (props) => <input type="text" {...props} />;

export default function TextCell({
  name,
  defaultValue,
  pushChangedRow,
  sticky,
  multiline,
}) {
  const TextInput = multiline ? TextareaAutosize : Input;

  return (
    <EditableCell
      name={name}
      defaultValue={defaultValue}
      pushChangedRow={pushChangedRow}
      sticky={sticky}
    >
      {({
        value,
        onChange,
        onKeyDown,
        onBlur,
      }) => (
        <TextInput
          className="form-control w-100"
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus
        />
      )}
    </EditableCell>
  );
}
