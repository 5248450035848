import React, { memo } from 'react';
import { FormTrails } from '@root/controls/form';
import InUseFormGroup from '@root/controls/form/InUseFormGroup';

const StaticFieldsFormGroup = ({ data, data: { inUse }, labelSize = 4 }) => (
  <>
    <InUseFormGroup inUse={inUse} labelSize={labelSize} />
    <FormTrails data={data} labelSize={labelSize} />
  </>
);

export default memo(StaticFieldsFormGroup);
