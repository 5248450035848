import React, { useCallback } from 'react';
import { get } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { MergeRoutes } from '@pages/routes';

import formReducer from './formReducer';
import ConfigurationsTable from './form/Table';

export default function ItemConfigurationSetsForm({
  actionName, data, excludedColumns, onSubmit, requestSchemaOptions, routePostfix, schemaType, schemaTypeName,
}) {
  const { query } = useRouter();
  const errors = get(data, '_meta.errors', {});
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(query.applicationId), []);

  return (
    <Form initialValues={{ ...data, schemaType }} onSubmit={onSubmit} reducer={formReducer}>
      {({ values, dispatch }) => {
        const populateItems = async (schemaId) => {
          const response = await MergeRoutes.ItemConfigurationSets.buildItemsRequest({
            applicationId: query.applicationId,
            schemaId,
            schemaType,
          });
          dispatch({
            type: 'populate_items',
            itemConfigurationsAttributes: response.data.itemConfigurationsAttributes,
          });
        };

        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Item Set`}>
              <FormButtonsGroup
                cancelButtonPath={MergeRoutes
                  .ItemConfigurationSets
                  .indexPath({ applicationId: query.applicationId, routePostfix })}
              />
            </PageHeader>

            <Label text="Name">
              <Field type="text" name="name" />
            </Label>
            <Label text="Description">
              <Field type="text" name="description" />
            </Label>
            <Label text={schemaTypeName}>
              <SelectField
                name="schemaId"
                options={fetchSchemaOptions}
                onChange={(e) => populateItems(e.value)}
                isDisabled={actionName !== 'Create'}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <ConfigurationsTable
              values={values.itemConfigurationsAttributes}
              errors={errors}
              excludedColumns={excludedColumns}
            />
          </>
        );
      }}
    </Form>
  );
}
