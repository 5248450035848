import React from 'react';

import languageOptions from '@services/languages';
import SelectField from '@controls/form/SelectField';

export default function LanguageRule({ disabled }) {
  return (
    <SelectField
      name="languageCodes"
      options={languageOptions}
      isMulti
      isDisabled={disabled}
    />
  );
}
