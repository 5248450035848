import React, { useRef } from 'react';

import IconButton from '@controls/buttons';

export default function UploadButton({
  className, request, label = 'Upload', accept,
}) {
  const fileInput = useRef(null);
  return (
    <>
      <IconButton.Upload
        onClick={() => fileInput.current.click()}
        className={className}
      >
        {label}
      </IconButton.Upload>
      <input
        type="file"
        hidden
        accept={accept}
        ref={fileInput}
        onChange={() => {
          request(fileInput.current.files[0]);
          fileInput.current.value = null;
        }}
      />
    </>
  );
}
