import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { createLobbyConfig, newLobbyConfig } from '@requests/puzzle-time/lobbyConfigs';

import Form from '@pages/puzzle-time/GameConfigForm';

export default function New() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const indexPath = `/admin/applications/${query.applicationId}/puzzle_time/lobby_configs`;

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Lobby Config',
    actionName: 'creation',
    redirectPath: indexPath,
    setData,
  });

  const onSubmit = (values) => createLobbyConfig(values).then(redirectIfSuccessHandler);

  useEffect(() => {
    newLobbyConfig(query).then((response) => setData(response.data));
  }, []);

  return data ? (
    <Form
      data={data}
      onSubmit={onSubmit}
      actionName="Create"
      indexPath={indexPath}
      configName="Lobby Config"
    />
  )
    : '';
}
