import React, { } from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import EventSchemaCell from '@components/merge/shared/EventSchemaCell';
import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

const ColumnsFactory = ({ query, setDuplicatedRecord, onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Description', accessor: 'description' },
  {
    Header: 'Event Schema',
    Cell: ({ row }) => <EventSchemaCell schema={row.original.eventSchema} />,
  },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({
      row: {
        original: {
          id, name, inUse,
        },
      },
    }) => {
      const disabled = isInUse(inUse);
      return (
        <ButtonToolbar>
          <IconButton.Edit
            to={MergeRoutes.Events.LevelSets.editPath({ id, applicationId: query.applicationId })}
            minimized
          />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
          <IconButton.Delete onClick={onDelete(id, name)} disabled={disabled} minimized />
        </ButtonToolbar>
      );
    },
  },
];

export default ColumnsFactory;
