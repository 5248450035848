import React from 'react';
import { Route } from 'react-router-dom';

import { PageTitle } from '@pages/common';
import Index from '@pages/favourites/Index';
import { FavouritesRoutes } from '@pages/routes';
import RouteContainer from '@pages/RouteContainer';

export default function favourites() {
  return [
    <Route
      key={FavouritesRoutes.indexRawPath}
      path={FavouritesRoutes.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Favourites" action="List" />
          <Index />
        </RouteContainer>
      )}
    />,
  ];
}
