import React from 'react';
import { PageTitle } from '@pages/common';

import {
  LobbyConfigsIndex, LobbyConfigsNew, LobbyConfigsEdit,
  NewGamePopupConfigsIndex, NewGamePopupConfigsNew, NewGamePopupConfigsEdit,
  AdsConfigsIndex, AdsConfigsNew, AdsConfigsEdit,
  EscapeConfigsIndex, EscapeConfigsNew, EscapeConfigsEdit,
  Configs2048Index, Configs2048New, Configs2048Edit,
  HexagonPuzzleConfigsIndex, HexagonPuzzleConfigsNew, HexagonPuzzleConfigsEdit,
  HexagonSquareConfigsIndex, HexagonSquareConfigsNew, HexagonSquareConfigsEdit,
  SingleScreenTutorialConfigsIndex, SingleScreenTutorialConfigsNew, SingleScreenTutorialConfigsEdit,
} from '@pages/puzzle-time';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function puzzleTime() {
  const scope = '/admin/applications/:applicationId/puzzle_time';

  return [
    <Route
      key={`${scope}/lobby_configs`}
      path={`${scope}/lobby_configs`}
      element={(
        <RouteContainer>
          <PageTitle text="Lobby Configs" />
          <LobbyConfigsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/lobby_configs/new`}
      path={`${scope}/lobby_configs/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Lobby Config" action="New" />
          <LobbyConfigsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/lobby_configs/:id/edit`}
      path={`${scope}/lobby_configs/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Lobby Config" action="Edit" />
          <LobbyConfigsEdit />
        </RouteContainer>
    )}
    />,

    <Route
      key={`${scope}/new_game_popup_configs`}
      path={`${scope}/new_game_popup_configs`}
      element={(
        <RouteContainer>
          <PageTitle text="New Game Popup Configs" />
          <NewGamePopupConfigsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/new_game_popup_configs/new`}
      path={`${scope}/new_game_popup_configs/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="New Game Popup" action="New" />
          <NewGamePopupConfigsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/new_game_popup_configs/:id/edit`}
      path={`${scope}/new_game_popup_configs/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="New Game Popup" action="Edit" />
          <NewGamePopupConfigsEdit />
        </RouteContainer>
    )}
    />,

    <Route
      key={`${scope}/ads_configs`}
      path={`${scope}/ads_configs`}
      element={(
        <RouteContainer>
          <PageTitle text="Ads Configs" />
          <AdsConfigsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/ads_configs/new`}
      path={`${scope}/ads_configs/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Ads Config" action="New" />
          <AdsConfigsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/ads_configs/:id/edit`}
      path={`${scope}/ads_configs/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Ads Config" action="Edit" />
          <AdsConfigsEdit />
        </RouteContainer>
    )}
    />,

    <Route
      key={`${scope}/escape_configs`}
      path={`${scope}/escape_configs`}
      element={(
        <RouteContainer>
          <PageTitle text="Escape Configs" />
          <EscapeConfigsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/escape_configs/new`}
      path={`${scope}/escape_configs/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Escape Config" action="New" />
          <EscapeConfigsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/escape_configs/:id/edit`}
      path={`${scope}/escape_configs/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Escape Config" action="Edit" />
          <EscapeConfigsEdit />
        </RouteContainer>
    )}
    />,

    <Route
      key={`${scope}/configs_2048`}
      path={`${scope}/configs_2048`}
      element={(
        <RouteContainer>
          <PageTitle text="2048 Configs" />
          <Configs2048Index />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/configs_2048/new`}
      path={`${scope}/configs_2048/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="2048 Config" action="New" />
          <Configs2048New />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/configs_2048/:id/edit`}
      path={`${scope}/configs_2048/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="2048 Config" action="Edit" />
          <Configs2048Edit />
        </RouteContainer>
    )}
    />,

    <Route
      key={`${scope}/hexagon_puzzle_configs`}
      path={`${scope}/hexagon_puzzle_configs`}
      element={(
        <RouteContainer>
          <PageTitle text="Hexagon Puzzle Configs" />
          <HexagonPuzzleConfigsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/hexagon_puzzle_configs/new`}
      path={`${scope}/hexagon_puzzle_configs/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Hexagon Puzzle Config" action="New" />
          <HexagonPuzzleConfigsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/hexagon_puzzle_configs/:id/edit`}
      path={`${scope}/hexagon_puzzle_configs/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Hexagon Puzzle Config" action="Edit" />
          <HexagonPuzzleConfigsEdit />
        </RouteContainer>
    )}
    />,

    <Route
      key={`${scope}/hexagon_square_configs`}
      path={`${scope}/hexagon_square_configs`}
      element={(
        <RouteContainer>
          <PageTitle text="Hexagon Square Configs" />
          <HexagonSquareConfigsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/hexagon_square_configs/new`}
      path={`${scope}/hexagon_square_configs/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Hexagon Square Config" action="New" />
          <HexagonSquareConfigsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/hexagon_square_configs/:id/edit`}
      path={`${scope}/hexagon_square_configs/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Hexagon Square Config" action="Edit" />
          <HexagonSquareConfigsEdit />
        </RouteContainer>
    )}
    />,

    <Route
      key={`${scope}/single_screen_tutorial_configs`}
      path={`${scope}/single_screen_tutorial_configs`}
      element={(
        <RouteContainer>
          <PageTitle text="Single Screen Tutorials" />
          <SingleScreenTutorialConfigsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/single_screen_tutorial_configs/new`}
      path={`${scope}/single_screen_tutorial_configs/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Single Screen Tutorial" action="New" />
          <SingleScreenTutorialConfigsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/single_screen_tutorial_configs/:id/edit`}
      path={`${scope}/single_screen_tutorial_configs/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Single Screen Tutorial" action="Edit" />
          <SingleScreenTutorialConfigsEdit />
        </RouteContainer>
    )}
    />,
  ];
}
