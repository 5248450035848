import APP_DATA from '@services/appData';

const { enums } = APP_DATA;

export const tileTypedLevelGoalTypes = {
  score: 0,
  collect: 1,
};

export const defaultTileTypedLevelOptions = {
  tileProbabilitiesAttributes: [],
  levelGoalsAttributes: [],
  boardCellsAttributes: [],
  tutorialEnabled: true,
  randomInitSteps: 0,
  boardGridType: enums['Woodoku::BoardGridTypes'].BOARD_ID_0,
};

export const BASE_BOARD_GRID_COLUMN_SIZE = 9;

const boardGridTypes = APP_DATA.enums['Woodoku::BoardGridTypes'];

export const ROTATABLE_BOARD_GRID_TYPES = [boardGridTypes.BOARD_ID_0, boardGridTypes.BOARD_ID_1];

export const LARGE_TILE_FAMILY_ID = 4;
