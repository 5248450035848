import React from 'react';
import { useTheme } from 'styled-components';
import {
  faWindowMaximize,
  faProjectDiagram,
  faDisplay,
  faSquarePlus,
  faWindowRestore,
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import {
  ApplicationItemSet,
  AdsNavigationSection,
} from './common';

export default function PuzzleTime({ applicationId, routingScope, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="puzzle_time"
      routingScope={routingScope}
      minimized={minimized}
    >
      <Sidebar.SectionItem
        icon={faProjectDiagram}
        title="Subgame Configs"
        color={theme.purple}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/puzzle_time/escape_configs`}
          title="Block Escape Configs"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/puzzle_time/configs_2048`}
          title="2048 Configs"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/puzzle_time/hexagon_puzzle_configs`}
          title="Hexa Puzzle Configs"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/puzzle_time/hexagon_square_configs`}
          title="Square Puzzle Configs"
        />
      </Sidebar.SectionItem>

      <Sidebar.LinkItem
        to={`${routingScope}/puzzle_time/lobby_configs`}
        icon={faWindowMaximize}
        title="Lobby Configs"
        color={theme.yellow}
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to={`${routingScope}/puzzle_time/new_game_popup_configs`}
        icon={faSquarePlus}
        title="New Game Popup Configs"
        color={theme.lightBlue}
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to={`${routingScope}/puzzle_time/single_screen_tutorial_configs`}
        icon={faDisplay}
        title="Single Screen Tutorial Configs"
        color={theme.tulipTree}
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to={`${routingScope}/puzzle_time/ads_configs`}
        icon={faWindowRestore}
        title="Ads Configs"
        color={theme.turquoise}
        minimized={minimized}
      />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
