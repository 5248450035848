import React, { useState } from 'react';

import { Form, useQuery } from '@hooks';
import { Spinner } from '@tripledotstudios/react-core';
import { LocalizationsRoutes } from '@pages/routes';

import ChangedTable from './ChangedTable';
import BaseTable from './BaseTable';
import CancelModal from './CancelModal';

import { useLanguagesContext } from './shared/services/useLanguages';
import formReducer from './reducers/formReducer';
import DiffTable from './DiffTable';
import TopActionButtons from './changed-table/TopActionButtons';

export default function TranslationsForm({
  initialValues,
  rawData,
  setMigrationInProgress,
  onSubmit,
}) {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { response: diffResponse } = useQuery(LocalizationsRoutes.Translations.loadDiffRequest);
  const [keyIdsToSkip, setKeyIdsToSkip] = useState([]);
  const { allLanguages, filteredLanguages } = useLanguagesContext();

  const filteredKeyConfigs = diffResponse?.keyConfigs?.filter((keyConfig) => !keyIdsToSkip.includes(keyConfig.keyId));
  const hasDiff = rawData.hasDiff && filteredKeyConfigs?.length > 0;

  return allLanguages && filteredLanguages ? (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      reducer={formReducer}
    >
      {({ resetForm }) => (
        <>
          <ChangedTable rawData={rawData} setShowCancelModal={setShowCancelModal} />
          <TopActionButtons hasDiff={hasDiff} />
          <DiffTable
            setMigrationInProgress={setMigrationInProgress}
            setKeyIdsToSkip={setKeyIdsToSkip}
            keyIdsToSkip={keyIdsToSkip}
            keyConfigs={diffResponse?.keyConfigs}
            filteredKeyConfigs={filteredKeyConfigs}
          />
          <BaseTable />
          <CancelModal
            show={showCancelModal}
            setShow={setShowCancelModal}
            onSave={onSubmit}
            onDiscard={() => resetForm({ values: initialValues })}
          />
        </>
      )}
    </Form>
  ) : <Spinner />;
}
