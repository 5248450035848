import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
} from '@pages/common';
import { AdminUsersRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';

export const columns = () => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Email', accessor: 'email' },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={`${id}/edit`} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const { response } = useQuery(AdminUsersRoutes.indexRequest);

  return response ? (
    <div>
      <PageHeader title="Users" filter={<Filter />}>
        <IconButton.New to="new" />
      </PageHeader>

      <ReactTable
        columns={columns()}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  ) : '';
}
