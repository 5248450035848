import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { filter, sortBy } from 'lodash';

import { useFormContext, FormGroup } from '@hooks';
import {
  SelectField, Field, ServerError, Label,
} from '@controls/form';
import IconButton from '@controls/buttons';
import { fromClassNameToOptions } from '@services/enums';
import APP_DATA from '@services/appData';

import { streakRewardTypes, POSITION_WITH_REWARDS_MULTIPLIER } from '../constants';

const ContentRow = styled(Row)`
  display: flex;
  margin-bottom: 5px;
  align-content: start;
`;

export default function StreakReward({ streak, position }) {
  const { rewardTypes } = APP_DATA;
  const { dispatch } = useFormContext();

  const streakRewardTypeOptions = fromClassNameToOptions('WoodokuSaga::StreakRewardTypes');
  const typeOptions = (position === POSITION_WITH_REWARDS_MULTIPLIER)
    ? streakRewardTypeOptions : streakRewardTypeOptions.slice(0, -1);

  const gameRewards = sortBy(
    filter(rewardTypes, (_value, className) => className.includes('::WoodokuSaga::')), ['name'],
  );
  const gameItemTypesOptions = Object.values(gameRewards).map(
    (gameRewardItem) => ({ label: gameRewardItem.name, value: gameRewardItem.class_name }),
  );

  return (
    <>
      <ServerError name="streakRewardsAttributes.list" />
      {streak.streakRewardsAttributes.map((streakReward, index) => (
        !streakReward._destroy && (
          <FormGroup name={`streakRewardsAttributes[${index}]`} key={streakReward._uuid}>
            <ContentRow className="ms-1">
              <Col xs={4} className="pe-0 align-self-start">
                <Label text="Reward">
                  <SelectField
                    name="rewardType"
                    options={typeOptions}
                    onChange={({ value }) => dispatch({
                      type: 'changeRewardType', index, value, position,
                    })}
                  />
                </Label>
              </Col>
              {streakReward.rewardType === streakRewardTypes.REWARDS_MULTIPLIER ? (
                <>
                  <Col xs={2} className="pe-0 align-self-start">
                    <Label
                      className="pt-0"
                      text="Multiplier count"
                      tooltipText="Multiple selected rewards by given value"
                    >
                      <Field
                        className="ms-4"
                        name="multiplierCount"
                        type="number"
                        step="0.1"
                      />
                    </Label>
                  </Col>
                  <Col xs={4} className="pe-0 align-self-start">
                    <Label
                      fieldSize={8}
                      className="pt-0"
                      text="Multiplier item"
                      tooltipText="Select items to be multiplied"
                    >
                      <SelectField
                        name="gameItemsTypes"
                        isMulti
                        options={gameItemTypesOptions}
                      />
                    </Label>
                  </Col>
                </>
              ) : (
                <Col xs={2} className="pe-0 align-self-start">
                  <Label text="Amount">
                    <Field
                      name="amount"
                      type="number"
                    />
                  </Label>
                </Col>
              )}

              <Col xs={1} className="pe-0 align-self-start">
                <IconButton.Delete
                  onClick={() => dispatch({ type: 'removeEntity', position, index })}
                  minimized
                />
              </Col>

            </ContentRow>
          </FormGroup>
        )
      ))}
    </>
  );
}
