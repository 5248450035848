import { addUuid } from '@services/recursivelyProcessObject';
import { persistedCollection } from '@services/utils';

export default function formReducer(state, action) {
  const rows = persistedCollection(state.rowsAttributes);
  switch (action.type) {
    case 'addRow': {
      const lastRow = rows[rows.length - 1];

      state.rowsAttributes.push(
        addUuid({
          to: lastRow ? lastRow.to + 1 : 1,
          from: lastRow ? lastRow.to + 1 : 1,
          rewardAffiliationsAttributes: [{ quantity: 1 }],
        }),
      );
      break;
    }
    case 'removeRow': {
      const { index } = action;
      const rowToDelete = state.rowsAttributes[index];
      rowToDelete._destroy = true;

      if (state.rowsAttributes[index + 1]) state.rowsAttributes[index + 1].from = rowToDelete.from;
      if (rows.length > 1) rows[rows.length - 1].to = 1;
      break;
    }
    case 'changeToField': {
      const { value, index } = action;
      const numValue = Number(value);

      if (!numValue) {
        state.rowsAttributes[index].to = null;
      }

      state.rowsAttributes[index].to = value === '' ? null : numValue;

      const nextNotDestroyedRowIndex = state.rowsAttributes.findIndex((row, i) => (
        !row._destroy && i > index
      ));

      state.rowsAttributes[nextNotDestroyedRowIndex].from = numValue + 1;
      break;
    }
    default: break;
  }

  return state;
}
