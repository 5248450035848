import { addUuid } from './recursivelyProcessObject';

/* eslint-disable no-param-reassign */
export default function removeNestedIds(record) {
  Object.keys(record).forEach((key) => {
    if (!key.endsWith('Attributes')) return;

    if (Array.isArray(record[key])) {
      record[key] = record[key].map((attrs) => removeNestedIds(attrs));
    } else {
      const { id, _uuid, ...attributes } = record[key];
      record[key] = removeNestedIds(attributes);
    }
  });

  const { id, _uuid, ...recordAttributes } = record;

  return addUuid(recordAttributes);
}
