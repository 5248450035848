import MyPetCafe from './MyPetCafe';
import Sudoku from './Sudoku';
import Tile from './Tile';
import Solitaire from './Solitaire';
import Woodoku from './Woodoku';
import BlockParty from './BlockParty';

export default {
  my_pet_cafe: MyPetCafe,
  sudoku: Sudoku,
  tile: Tile,
  solitaire: Solitaire,
  woodoku: Woodoku,
  block_party: BlockParty,
};
