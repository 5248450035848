import React from 'react';
import { Modal } from 'react-bootstrap';
import { Alert } from '@tripledotstudios/react-core';

import { Label, FileField } from '@controls/form';
import { Form } from '@hooks';
import IconButton from '@controls/buttons';

import ErrorsBlock from './ErrorsBlock';

export default function CSVModal({
  showModal,
  isLoading,
  errors,
  onHide,
  onSubmit,
}) {
  return (
    <Modal show={showModal} onHide={onHide} dialogClassName="mw-50">
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>CSV Uploader</Modal.Title>
      </Modal.Header>
      <Form initialValues={{}} onSubmit={onSubmit}>
        {({ values: { translationsCSV } }) => (
          <>
            <Modal.Body>
              <Label text="Translations (CSV)">
                <FileField name="translationsCSV" accept="text/csv" fullError />
              </Label>
              <Alert variant="info">
                <ul className="mb-0 ps-3">
                  <li>Upload a CSV file to create new or update existing keys or translation strings in the system</li>
                  <li>Upload will fail if the uploaded translation language is not added in the system</li>
                  <li>CSV file must have UTF-8 encoding.</li>
                </ul>
              </Alert>
              {errors && <ErrorsBlock errors={errors} />}
            </Modal.Body>
            <Modal.Footer>
              <IconButton.Cancel variant="secondary" onClick={onHide} disabled={isLoading} />
              <IconButton.Upload type="submit" disabled={isLoading || !translationsCSV} />
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
}
