import React, { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { Col, Tab } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { Form } from '@hooks';
import {
  FormButtonsGroup, Field, Label, PriorityField, SelectField, AvailabilityStateFormGroup,
} from '@controls/form';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { PageHeader, DuplicateButton } from '@pages/common';
import { combineReducers, rulesReducer } from '@reducers';
import RulesForm from '@pages/rules/Form';
import TabsWrapper from '@controls/Tabs';
import AbTestingSelect from '@pages/common/ab-testing/AbTestingSelect';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { MyPetCafeRoutes } from '@pages/routes';

import {
  InitialDataTab,
  SolitaireRewardTab,
} from './tabs';

const jsonConfigTypes = APP_DATA.enums['MyPetCafe::JsonConfigTypes'];

export default function AppConfigsForm({
  data, cancelButtonPath, onSubmit, isBase,
}) {
  const [configOptionsByType, setConfigOptionsByType] = useState({});
  const { query } = useRouter();
  const { applicationId } = query;

  useEffect(() => {
    requestWithToSelectOptions(
      () => (MyPetCafeRoutes.JsonConfigs.indexRequest({ applicationId, withoutPagination: true })),
      applicationId,
      {},
      {
        toSelectOptionsFn: ({ data: { items } }) => {
          const groupedItems = groupBy(items, 'typeId');

          return Object.keys(groupedItems).reduce((memo, key) => {
            // eslint-disable-next-line no-param-reassign
            memo[key] = groupedItems[key].map(({ name, id }) => ({ label: name, value: id }));
            return memo;
          }, {});
        },
      },
    ).then((value) => setConfigOptionsByType(value));
  }, []);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Remote DB',
    actionName: 'update',
    setData: () => { },
  });

  const handleSubmit = (values) => onSubmit(values).then(responseHandler);

  const configOptions = (type) => configOptionsByType[type] || [];

  return (
    <Form
      initialValues={{ ...data, isBase, applicationId }}
      onSubmit={handleSubmit}
      reducer={combineReducers([rulesReducer])}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Remote DB`}>
            <FormButtonsGroup cancelButtonPath={cancelButtonPath}>
              <DuplicateButton
                routes={MyPetCafeRoutes.AppConfigs}
                data={data}
                entityName="Remote DB"
                modalType="withPriority"
              />
            </FormButtonsGroup>
          </PageHeader>

          <AbTestingSelect
            applicationId={applicationId}
            entityId={values.id}
            entityType="MyPetCafe::AppConfig"
            propertyName="abTestingId"
          />

          <Col xs={11}>
            <Field name="applicationId" type="hidden" />
            <Label text="Name" fieldSize={8}>
              <Field type="text" name="name" />
            </Label>
            <Label text="Priority" fieldSize={8}>
              <PriorityField
                name="priority"
                entitiesByPriority={data.entitiesByPriority}
                disabled={isBase}
              />
            </Label>
            <AvailabilityStateFormGroup disabled={isBase} />
          </Col>

          <TabsWrapper>
            <Tab eventKey="#initial_data" title="Initial Data" className="mt-4">
              <InitialDataTab />
            </Tab>
            <Tab eventKey="#cafe_income" title="Cafe Income" className="mt-4">
              <Label text="Config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="cafeIncomeConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_CAFE_INCOME)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#star_chest_reward" title="Star Chest Reward" className="mt-4">
              <Label text="Config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="starChestRewardConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_STAR_CHEST_REWARD)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#star_chest_order" title="Star Chest Order" className="mt-4">
              <Label text="Config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="starChestOrderConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_STAR_CHEST_ORDER)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#booster" title="Booster" className="mt-4">
              <Label text="Config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="boosterConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_BOOSTER)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#boosters_unlock" title="Boosters unlock" className="mt-4">
              <Label text="Config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="boostersUnlockConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_BOOSTERS_UNLOCK)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#tutorial" title="Tutorial" className="mt-4">
              <Label text="Solitaire Tutorial Config" labelSize={3} fieldSize={9}>
                <SelectField
                  includeEmpty
                  name="solitaireTutorialConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_SOLITAIRE_TUTORIAL)}
                />
              </Label>
              <Label text="Cafe Tutorial Config" labelSize={3} fieldSize={9}>
                <SelectField
                  includeEmpty
                  name="cafeTutorialConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_CAFE_TUTORIAL)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#solitaire_score" title="Solitaire score" className="mt-4">
              <Label text="Config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="solitaireScoreConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_SOLITAIRE_SCORE)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#solitaire_reward" title="Solitaire reward" className="mt-4">
              <SolitaireRewardTab />
            </Tab>
            <Tab eventKey="#chapter_reward" title="Chapter Reward" className="mt-4">
              <Label text="Config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="chapterRewardConfigId"
                  options={configOptions(jsonConfigTypes.CHAPTER_CONFIG_CHAPTER_REWARDS)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#chapter_requirements" title="Chapter Requirements" className="mt-4">
              <Label text="Config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="chapterRequirementsConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_CHAPTER_REQUIREMENTS)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#lifecycle_offers" title="Lifecycle offers" className="mt-4">
              <Label text="Redeemable offer config" labelSize={3} fieldSize={9}>
                <SelectField
                  includeEmpty
                  name="redeemableOfferConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_REDEEMABLE_OFFER)}
                />
              </Label>
              <Label text="Redeemable reward set config" labelSize={3} fieldSize={9}>
                <SelectField
                  includeEmpty
                  name="redeemableRewardConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_REDEEMABLE_REWARD)}
                />
              </Label>
              <Label text="Redeemable reward inventory group config" labelSize={3} fieldSize={9}>
                <SelectField
                  includeEmpty
                  name="redeemableRewardInventoryGroupConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_REDEEMABLE_REWARD_INVENTORY_GROUP)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#deco_progress" title="Deco Progress" className="mt-4">
              <Label text="Config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="decoProgressConfigId"
                  options={configOptions(jsonConfigTypes.DECO_PROGRESS)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#world_map" title="World Map" className="mt-4">
              <Label text="Config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="worldMapConfigId"
                  options={configOptions(jsonConfigTypes.WORLD_MAP)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#pet_reward" title="Pet Reward" className="mt-4">
              <Label text="Pet Data config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="petDataConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_PET_DATA)}
                />
              </Label>
              <Label text="Pet Slot config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="petSlotConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_PET_SLOT)}
                />
              </Label>
              <Label text="Pet Space config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="petSpaceConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_PET_SPACE)}
                />
              </Label>
              <Label text="Pet Animation config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="petAnimationConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_PET_ANIMATION)}
                />
              </Label>
              <Label text="Pet Sorting Order config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="petSortingOrderConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_PET_SORTING_ORDER)}
                />
              </Label>
              <Label text="Pet Menu Section config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="petMenuSectionConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_PET_MENU_SECTION)}
                />
              </Label>
            </Tab>
            <Tab eventKey="#leaderboard_reward_icon" title="Leaderboard reward icon" className="mt-4">
              <Label text="Config" labelSize={2} fieldSize={10}>
                <SelectField
                  includeEmpty
                  name="leaderboardRewardIconConfigId"
                  options={configOptions(jsonConfigTypes.REMOTE_DB_LEADERBOARD_REWARD_ICON)}
                />
              </Label>
            </Tab>
          </TabsWrapper>

          {!isBase && <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} />}
        </>
      )}
    </Form>
  );
}
