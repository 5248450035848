import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { response, setResponse } = useQuery(MergeRoutes.Events.Schemas.editRequest);
  const { query } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Event Schemas',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    MergeRoutes.Events.Schemas.updateRequest({ ...values, applicationId: query.applicationId })
      .then(responseHandler)
  );

  return response && <Form data={response} onSubmit={onSubmit} />;
}
