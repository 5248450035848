import { isArray } from 'lodash';

function processKey({ key, nestingLevel }) {
  const intKey = parseInt(key, 10);
  const prefix = `${'\t'.repeat(nestingLevel)}`;

  if (Number.isNaN(intKey)) return `${prefix}${key}:`;

  return `\n${prefix}Element ${intKey + 1}:\n`;
}

export default function prettifyErrors(rawData) {
  if (isArray(rawData)) return rawData;

  let parsedErrors = '';

  const parseErrors = (data, nestingLevel = 1) => {
    Object.entries(data).forEach(([key, value]) => {
      parsedErrors += processKey({ key, nestingLevel });

      if (isArray(value)) parsedErrors += ` ${value.join(', ')}\n`;
      else parseErrors(value, nestingLevel + 1);
    });
  };

  parseErrors(rawData);

  return parsedErrors;
}
