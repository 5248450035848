import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import { Filter, TextFilter, SelectFilter } from '@pages/common';

import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import updateQuery from '@services/updateQuery';
import { MergeRoutes as Routes } from '@pages/routes';

export default function PostcardConfigurationsFilter({ router, query }) {
  const [worldSchemas, setWorldSchemas] = useState([]);
  const [schemaPostcards, setSchemaPostcards] = useState([]);

  const reloadSchemaPostcardFilterOptions = (schemaIds) => {
    if (isEmpty(schemaIds)) {
      setSchemaPostcards([]);
      return;
    }

    Promise.all(schemaIds.map((schemaId) => (
      Routes.WorldSchemas.editRequest({ id: schemaId, applicationId: query.applicationId })
    ))).then((results) => {
      setSchemaPostcards(results.flatMap(({ data }) => (
        data.postcards.map((item) => ({ value: item.id, label: `${item.internalId} - ${data.name}` }))
      )));
    });
  };

  const updateWorldSchemaFilterField = ({ schemaIdIn }) => {
    const { schemaPostcardIdIn, ...prevFilters } = query.filter || {};
    reloadSchemaPostcardFilterOptions(schemaIdIn);
    updateQuery(router, { filter: { ...prevFilters, schemaIdIn } });
  };

  useEffect(() => {
    (async () => {
      setWorldSchemas(await requestSchemaOptions(query.applicationId));
    })();
    if (query.filter && query.filter.schemaIdIn) {
      reloadSchemaPostcardFilterOptions(query.filter.schemaIdIn);
    }
  }, []);

  const onFiltersReset = () => {
    setSchemaPostcards([]);
  };

  return (
    <Filter onFiltersReset={onFiltersReset} defaultComponents={['availabilityStateIn']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <SelectFilter
              name="schemaIdIn"
              label="World Schema = "
              options={worldSchemas}
              updateFilterField={updateWorldSchemaFilterField}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="schemaPostcardIdIn"
              label="Postcard = "
              placeholder={schemaPostcards.length > 0 ? 'Select...' : 'Select World Schema...'}
              options={schemaPostcards}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name = "
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
