import React from 'react';

import { MyPetCafeRoutes } from '@pages/routes';

import CreateUpdateConfig from './CreateUpdateConfig';

export default function Edit({ configTypes, group }) {
  return (
    <CreateUpdateConfig
      fetchFunction={MyPetCafeRoutes.JsonConfigs.editRequest}
      saveFunction={MyPetCafeRoutes.JsonConfigs.updateRequest}
      actionName="update"
      configTypes={configTypes}
      group={group}
      disableTypeSelect
    />
  );
}
