import React, { useEffect, useRef } from 'react';
import { Col } from 'react-bootstrap';

export default function Field({
  Labeled, fieldName, isFocused, disabled, values, localeNamespace, button,
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (isFocused) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isFocused]);

  return (
    <>
      <Col
        xs={11}
        ref={ref}
        className={isFocused && 'border border-2 rounded border-warning pt-3'}
      >
        <Labeled
          name={fieldName}
          disabled={disabled}
          values={values}
          localeNamespace={localeNamespace}
        />
      </Col>

      <Col xs={1}>
        {button({ fieldName })}
      </Col>
    </>
  );
}
