import React from 'react';
import { Col } from 'react-bootstrap';

import { fromClassNameToOptions } from '@services/enums';
import { Filter, SelectFilter } from '@pages/common';

const typeOptions = fromClassNameToOptions('Woo::Aspen::PurchaseConfigs::PlacementTypes');

export default function DifficultySchemasFilter() {
  return (
    <Filter defaultComponents={['name', 'availabilityStateIn', 'id', 'createdByMe', 'labels']}>
      {({ updateFilterField }) => (
        <Col md={6}>
          <SelectFilter
            name="placementTypeIn"
            label="Type = "
            options={typeOptions}
            updateFilterField={updateFilterField}
          />
        </Col>
      )}
    </Filter>
  );
}
