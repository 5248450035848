import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

import { useConfirm, useCurrentApplication } from '@hooks';
import { AbTestingRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

const DropdownMenu = styled(Dropdown.Menu)`
  max-height: 30em;
  overflow-x: auto;
`;

export default function ExperimentsDropdown({
  confirmationText, handleAdd, propertyEntityUsage, disabled, testId, entityType, entityId, propertyName,
}) {
  const confirm = useConfirm();
  const { currentApplication: { id: applicationId } } = useCurrentApplication();

  const onClick = ({ id, name }) => {
    confirm.showConfirmation({
      title: 'Confirm',
      body: confirmationText(name),
    }).then(() => handleAdd(id));
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        disabled={disabled}
        data-testid={testId || undefined}
      >
        Add to experiment
      </Dropdown.Toggle>
      <DropdownMenu>
        <IconButton.New
          className="mx-3 mb-1"
          size="sm"
          href={AbTestingRoutes.Experiments.newPath({
            applicationId, query: { entity: { entityType, entityId, propertyName } },
          })}
          target="_blank"
        >
          New Experiment
        </IconButton.New>
        {propertyEntityUsage.availableExperiments.map((experiment) => (
          <Dropdown.Item key={experiment.id} onClick={() => onClick(experiment)}>
            {`${experiment.name} (${experiment.statusHumanize})`}
          </Dropdown.Item>
        ))}
        {!propertyEntityUsage.availableExperiments.length && (
          <Dropdown.Item key={0} onClick={(e) => e.preventDefault()}>No available options</Dropdown.Item>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}
