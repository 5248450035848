import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { useCurrentApplication } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { generateChapterBasedRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const { currentApplication, applicationName, typeName } = useCurrentApplication();
  const BankRoutes = generateChapterBasedRoutes(typeName).Banks;
  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Level layouts bank',
    actionName: 'create',
    redirectPath: ({ id }) => BankRoutes.editPath({ applicationId: currentApplication.id, id }),
    setData,
  });

  const onSubmit = (values) => BankRoutes.createRequest(values).then((response) => responseHandler(response, values));

  useEffect(() => {
    BankRoutes.newRequest(query).then((response) => setData(response.data));
  }, []);

  return data && (
    <Form
      data={data}
      application={currentApplication}
      applicationName={applicationName}
      applicationType={typeName}
      onSubmit={onSubmit}
    />
  );
}
