import React from 'react';

import {
  GameSettingsTabs,
  adsControlDefaultFieldNames,
  localizationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  analyticsDefaultFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
} from '@components/game-settings/Resource';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'spDefaultMode',
      'spShuffleAnimation',
      'spShuffleAnimationMultiplier',
      'spShuffleAnimationEasyGamesMultiplier',
      'spShuffleAnimationEasyGamesCount',
      'lobbyConfigId',
      'newGamePopupConfigId',
      'adsConfigId',
      'config2048Id',
      'escapeConfigId',
      'hexagonPuzzleConfigId',
      'hexagonSquareConfigId',
      'singleScreenTutorialConfigId',
      ...assetsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      ...adPlacementIdsDefaultFieldNames,
      ...adConfigsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: popupsDefaultFieldNames,
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#ads_control',
    title: 'Ads Control',
    fieldsNames: adsControlDefaultFieldNames,
  },
  {
    eventKey: '#ad_placements',
    title: 'Ad Placements',
    fieldsNames: adConfigsDefaultFieldNames,
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: analyticsDefaultFieldNames,
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: popupsDefaultArchivedFieldNames,
  },
];

export default function Tabs({
  setContractType, fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      onChange={setContractType}
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
