import { partition } from 'lodash';

export default function parseEntityUsageData(data, statuses, attribute) {
  if (!data.all) return { availableExperiments: [], usedExperiments: [] };

  const optionsToReject = (attribute ? data.used[attribute] : data.used) || [];
  const parts = partition(data.all, (e) => (optionsToReject.indexOf(e.id) === -1));

  const excludedStatuses = [
    statuses.ACTIVE,
    statuses.ALLOCATION_PAUSED,
    statuses.ARCHIVED,
    statuses.PERMANENTLY_ARCHIVED,
  ];

  return {
    availableExperiments: parts[0].filter(({ status }) => !excludedStatuses.includes(status)),
    usedExperiments: parts[1],
  };
}
