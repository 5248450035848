import React from 'react';
import { isEqual } from 'lodash';

import { useFormContext } from '@hooks';

import {
  SettingsTable,
  BoardStart,
  Generation,
  Goals,
} from '@pages/journeys/level-banks/configuration-forms/woodoku/tile-typed-level';

const Level = React.memo(({
  entity: level,
  index,
  readOnly,
  figureSets,
  figureSetDynamicConfigs,
  tileTypes,
  errors,
}) => {
  const { dispatch } = useFormContext();
  const { tileProbabilitiesAttributes, levelGoalsAttributes } = level;

  const tileTypeOptions = tileTypes.map(({
    name, id, typeId, layerId, familyId, parametersSchema, height, width,
  }) => ({
    label: `${name} (id: ${typeId})`,
    value: id,
    layerId,
    familyId,
    parametersSchema: JSON.parse(parametersSchema),
    height,
    width,
  }));

  const onConfigItemRemove = (collectionName, uuid) => (
    dispatch({
      actionType: 'removeConfigItem',
      index,
      collectionName,
      uuid,
      levelsPath: 'levelsAttributes',
    })
  );

  return (
    <>
      <SettingsTable
        withFigureSetRandomizer
        index={index}
        figureSets={figureSets}
        figureSetDynamicConfigs={figureSetDynamicConfigs}
        level={level}
        levelsPath="levelsAttributes"
        readOnly={readOnly}
        includeRewards={false}
      />

      <Generation
        index={index}
        tileProbabilitiesAttributes={tileProbabilitiesAttributes}
        tileTypeOptions={tileTypeOptions}
        onConfigItemRemove={onConfigItemRemove}
        levelsPath="levelsAttributes"
        readOnly={readOnly}
      />

      <Goals
        index={index}
        levelGoalsAttributes={levelGoalsAttributes}
        tileTypeOptions={tileTypeOptions}
        onConfigItemRemove={onConfigItemRemove}
        levelsPath="levelsAttributes"
        readOnly={readOnly}
      />

      <BoardStart
        index={index}
        boardCellsAttributes={level.boardCellsAttributes}
        tileTypeOptions={tileTypeOptions}
        errors={errors}
        levelsPath="levelsAttributes"
        readOnly={readOnly}
        boardGridType={level.boardGridType}
      />
    </>
  );
}, (prev, next) => (
  prev.readOnly === next.readOnly && isEqual(prev.figureSets, next.figureSets)
  && isEqual(prev.figureSetDynamicConfigs, next.figureSetDynamicConfigs) && isEqual(prev.tileTypes, next.tileTypes)
  && isEqual(prev.entity, next.entity) && isEqual(prev.errors, next.errors)
));

export default Level;
