import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useI18n } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { MissionsRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse } = useQuery(MissionsRoutes.Settings.editRequest);
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  const responseHandler = noRedirectHandlerFactory({
    entityName: `${namespaceName} settings`,
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    MissionsRoutes.Settings
      .updateRequest({ ...values, applicationId })
      .then(responseHandler)
  );

  return <Form data={response} onSubmit={onSubmit} namespaceName={namespaceName} />;
}
