import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { Label, Field } from '@controls/form';
import { useConfirm } from '@hooks';

export default function MassOperations({ dispatch, values }) {
  const confirm = useConfirm();

  return (
    <Label text="Add levels to end:" fieldSize={8}>
      <Row>
        <Col xs={3}>
          <Label text="Number of levels" direction="vertical">
            <Field name="numberOfNewLevels" type="number" />
          </Label>
        </Col>
        <Col xs={3}>
          <Label text="XP diff" direction="vertical">
            <Field name="diff" type="number" />
          </Label>
        </Col>
        <Col className="pt-4">
          <Button
            disabled={!values.diff || !(values.numberOfNewLevels && values.numberOfNewLevels > 0)}
            onClick={() => confirm.showConfirmation({
              title: `${values.numberOfNewLevels} levels will be added to the set. Continue?`,
            }).then(() => dispatch({ type: 'addNewLevels' }))}
          >
            Add
          </Button>
        </Col>
      </Row>
    </Label>
  );
}
