import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';

import requestEventSchemaOptions from '@components/merge/services/requestEventSchemaOptions';
import { Filter, TextFilter, SelectFilter } from '@pages/common';

export default function OrderConfigurationsFilter({ applicationId }) {
  const [eventSchemas, setEventSchemas] = useState([]);
  useEffect(() => {
    requestEventSchemaOptions(applicationId).then(setEventSchemas);
  }, []);

  return (
    <Filter defaultComponents={['availabilityStateIn']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <SelectFilter
              name="schemaIdIn"
              label="Event Schema = "
              options={eventSchemas}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name = "
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
