/* eslint-disable gs/plz-camel-case-for-action-names */
export default function formReducer(state, action) {
  if (action.type === 'change_store_id') {
    const { price } = action;
    const { storePostfix, storeProductIdPattern } = state;

    if (storePostfix && price) {
      const formattedPrice = parseFloat(price).toFixed(2);

      state.storeId = storeProductIdPattern.replace(/<price>/, formattedPrice)
        .replace(/<postfix>/, storePostfix);
    }
  }

  return state;
}
