import React from 'react';

import DuplicationModalWithWorldSchemaId from '@components/merge/shared/DuplicationModalWithWorldSchemaId';
import StartingGridsIndex from '../../starting-grids/Index';

export default function List(props) {
  return (
    <StartingGridsIndex
      DuplicationModal={DuplicationModalWithWorldSchemaId}
      {...props}
    />
  );
}
