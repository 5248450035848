import React, { useState, useEffect } from 'react';
import { Row, Button } from 'react-bootstrap';
import {
  omitBy,
  isFunction,
  isEmpty,
  isEqual,
} from 'lodash';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { useRouter, ButtonToolbar } from '@tripledotstudios/react-core';

import updateQuery from '@services/updateQuery';

import { Collapsible } from '@controls/form';
import IconButton from '@controls/buttons';
import FilterHeader from './FilterHeader';

const FilterContent = ({
  resetFilters, updateFilterField, children, className, clear, applyDefaultFilters, appliedFiltersAreDefault,
  showApplyDefaultFilters,
}) => (
  <div className={className}>
    <ButtonToolbar className="mb-3">
      <IconButton
        disabled={!clear}
        icon={faBan}
        onClick={resetFilters}
        variant="danger"
      >
        Clear filters
      </IconButton>
      {showApplyDefaultFilters && (
        <Button
          onClick={applyDefaultFilters}
          variant="success"
          disabled={appliedFiltersAreDefault}
        >
          Apply default filters
        </Button>
      )}
    </ButtonToolbar>
    <Row>
      {isFunction(children) ? children({ updateFilterField }) : children}
    </Row>
  </div>
);

const BaseFilter = ({
  children,
  onFiltersReset,
  collapsible = false,
  skipFiltersOnCount = [],
  className,
  showApplyDefaultFilters = true,
}) => {
  const router = useRouter();
  const { query } = router;
  const [filterObject, setFilterObject] = useState(query.filter || {});
  const [defaultFilters, setDefaultFilters] = useState({});

  const updateQueryFilter = (filter) => updateQuery(router, { filter }, () => { }, { method: 'replace' });

  const updateFilterField = (newFilter, setAsDefault = false) => {
    if (setAsDefault) {
      return setDefaultFilters((savedDefaultFilters) => ({ ...savedDefaultFilters, ...newFilter }));
    }

    return setFilterObject((currentFilterObject) => omitBy(
      { ...currentFilterObject, ...newFilter },
      (value) => value === null,
    ));
  };

  const resetFilters = () => {
    if (isFunction(onFiltersReset)) onFiltersReset();

    setFilterObject({});
  };

  const applyDefaultFilters = () => setFilterObject(defaultFilters);

  useEffect(() => {
    updateQueryFilter(filterObject);
  }, [JSON.stringify(filterObject)]);

  useEffect(() => {
    setFilterObject({ ...defaultFilters, ...filterObject });
  }, [JSON.stringify(defaultFilters)]);

  useEffect(() => {
    if (query.filter === undefined) {
      updateQueryFilter(filterObject);
    }
  }, [query.filter]);

  const filterContent = (
    <FilterContent
      updateFilterField={updateFilterField}
      resetFilters={resetFilters}
      clear={!isEmpty(filterObject)}
      appliedFiltersAreDefault={isEqual(filterObject, defaultFilters)}
      className={className}
      applyDefaultFilters={applyDefaultFilters}
      showApplyDefaultFilters={showApplyDefaultFilters}
    >
      {children}
    </FilterContent>
  );

  return (
    collapsible
      ? (
        <Collapsible
          header={<FilterHeader text="Filter" skipFiltersOnCount={skipFiltersOnCount} />}
          eventKey="filters"
          className={className}
        >
          {filterContent}
        </Collapsible>
      )
      : filterContent
  );
};

export default BaseFilter;
