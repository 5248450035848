/* eslint-disable @tripledotstudios/gs/plz-camel-case-for-action-names */
import { get, set } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';

// eslint-disable-next-line no-extra-boolean-cast
const pathSegment = (path) => (!!path ? `${path}.` : '');

/* eslint-disable no-param-reassign, array-callback-return */
export default function rewardableReducer(state, action) {
  switch (action.actionType) {
    case 'add_reward': {
      const { path } = action;
      get(state, `${pathSegment(path)}rewardAffiliationsAttributes`).push(addUuid({
        _destroy: false,
        id: null,
        quantity: 1,
      }));
      break;
    }
    case 'remove_reward': {
      const { path, index } = action;
      set(state, `${pathSegment(path)}rewardAffiliationsAttributes[${index}]._destroy`, true);
      break;
    }
    default: break;
  }
  return state;
}
