import React, { useState, useMemo } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { partition, sortBy, isEqual } from 'lodash';
import ReactSelect from 'react-select';
import { useFlashMessages, Spinner } from '@tripledotstudios/react-core';

import { JourneyRoutes as Routes } from '@pages/routes';
import APP_DATA from '@services/appData';
import {
  useQuery, useConfirm, useI18n, useCurrentApplication,
} from '@hooks';
import {
  Label, labelableSelect, SelectField, ServerError,
} from '@controls/form';
import { ReactTable, PageSection } from '@pages/common';

import columnsFactory from './columnsFactory';

const SelectWithLabels = labelableSelect(ReactSelect);
const SelectFieldWithLabels = labelableSelect(SelectField);

const buildLevelBankSelectOption = (bank, levelEntityName, showLevelsCount) => {
  const label = showLevelsCount ? `${bank.name} [${bank.levelsCount} ${levelEntityName}(s)]` : bank.name;

  return ({ label, value: bank.id, labels: bank.labels });
};

const validateBankAdding = (levelBankId, addedBanks, banks, typeName) => {
  if (!addedBanks.length) {
    return { success: true };
  }

  const bankToAdd = banks.find(({ id }) => id === levelBankId);

  if (addedBanks.some(({ levelsCount }) => levelsCount !== bankToAdd.levelsCount)) {
    return { success: false, error: 'Number of levels does not match' };
  }

  if (typeName === 'woodoku') {
    const rewardsAreIncorrect = addedBanks.some((bank) => (
      !isEqual(bank.levelNumbersWithBundleAssetReward, bankToAdd.levelNumbersWithBundleAssetReward)
    ));

    if (rewardsAreIncorrect) {
      return { success: false, error: 'Level numbers with Bundle Asset rewards do not match' };
    }
  } else {
    const rewardsAreIncorrect = addedBanks.some((bank) => (
      !isEqual(bank.rewardedLevelNumbers, bankToAdd.rewardedLevelNumbers)
    ));

    if (rewardsAreIncorrect) {
      return { success: false, error: 'Rewarded level numbers do not match' };
    }
  }

  return { success: true };
};

const bankStatuses = APP_DATA.enums['Journeys::LevelBanks::BankStatuses'];

const EventsBlock = React.memo(({
  variantAttributes, disabled, onBankAdd, onBankRemove, eventType, validateLevels = false, banksQuery = null,
  translationPath = null, showLevelsCount = true, showRewardedLevels = true, routes = Routes,
}) => {
  const [selectedLevelBankId, setSelectedLevelBankId] = useState(null);
  const { translate } = useI18n();

  const notDestroyedLevelBanks = variantAttributes.levelBankAffiliationsAttributes.filter(({ _destroy }) => !_destroy);
  const addedBankIds = notDestroyedLevelBanks.map(({ levelBankId }) => levelBankId);

  const confirm = useConfirm();
  const { error } = useFlashMessages();
  const { typeName, applicationId } = useCurrentApplication();

  const { response, isLoading } = banksQuery || useQuery({
    request: Routes.LevelBanks.indexRequest,
    additionalRequestParams: {
      withoutPagination: true,
      filter: disabled
        ? { idIn: [variantAttributes.defaultLevelBankId, ...addedBankIds] }
        : { statusIn: [bankStatuses.ACTIVE, bankStatuses.INACTIVE] },
    },
    queryKey: ['journeyLevelBanks', applicationId, disabled],
    staleTime: 60_000,
  });

  const bankEntityName = translate.fallback('journeys.levelBanks.name');
  const banksName = translate.fallback('journeys.levelBanks.pluralName');
  const eventEntityName = translate.fallback(translationPath) || translate.fallback(`journeys.${eventType}s.name`);
  const levelEntityName = translate.fallback('journeys.levelBanks.levels.name');

  const onOverrideRemove = (levelBankId, name) => {
    confirm.showConfirmation({
      title: 'Are you sure?',
      body: `${bankEntityName} '${name}' will be removed from ${eventEntityName} and won't be served to users.`,
    }).then(() => onBankRemove(levelBankId));
  };

  const columns = useMemo(() => (
    columnsFactory({
      onOverrideRemove,
      levelEntityName,
      showLevelsCount,
      showRewardedLevels,
      routes,
      disabled,
    })
  ), []);

  if (isLoading) return <Spinner name={banksName} />;
  if (!response) return null;

  const [selectedLevelBanks, notSelectedLevelBanks] = partition(response.items, ({ id }) => addedBankIds.includes(id));

  const defaultBank = variantAttributes.defaultLevelBankId
    ? response.items.find(({ id }) => id === variantAttributes.defaultLevelBankId)
    : null;

  const onOverrideAdd = () => {
    const selectedBanks = defaultBank ? [defaultBank, ...selectedLevelBanks] : selectedLevelBanks;
    const validationResult = validateBankAdding(selectedLevelBankId, selectedBanks, response.items, typeName);

    if (!validateLevels || validationResult.success) {
      onBankAdd(selectedLevelBankId);
      return setSelectedLevelBankId(null);
    }

    return error(validationResult.error);
  };

  const levelBankOptions = notSelectedLevelBanks
    .filter(({ status }) => (disabled ? true : status === bankStatuses.ACTIVE))
    .map((bank) => buildLevelBankSelectOption(bank, levelEntityName, showLevelsCount));

  return (
    <div className="mt-2 mb-2">
      <PageSection title={banksName} />

      <Label text={`Default ${bankEntityName}`}>
        <SelectFieldWithLabels
          name="defaultLevelBankId"
          options={levelBankOptions.filter(({ value }) => value !== selectedLevelBankId)}
          isDisabled={disabled}
        />
      </Label>
      {defaultBank && (
        <ReactTable
          data={[defaultBank]}
          columns={columns.slice(0, 1 + showLevelsCount + showRewardedLevels)}
        />
      )}
      {selectedLevelBanks.length > 0 && (
        <Label text={`${bankEntityName} overrides`} direction="vertical">
          <ReactTable
            data={sortBy(selectedLevelBanks, ({ id }) => addedBankIds.indexOf(id))}
            columns={columns}
          />
        </Label>
      )}
      {defaultBank && (
        <Row>
          <Col xs={9}>
            <SelectWithLabels
              options={levelBankOptions.filter(({ value }) => value !== defaultBank.id)}
              onChange={({ value }) => setSelectedLevelBankId(value)}
              value={selectedLevelBankId ? levelBankOptions.find(({ value }) => value === selectedLevelBankId) : null}
              isDisabled={disabled}
              className="react-select"
              classNamePrefix="react-select"
            />
          </Col>
          <Col xs={3}>
            <Button
              className="w-100"
              onClick={onOverrideAdd}
              disabled={!selectedLevelBankId || disabled}
            >
              {`Add ${bankEntityName}`}
            </Button>
          </Col>
        </Row>
      )}
      <ServerError name="levelBankAffiliationsAttributes" />
    </div>
  );
}, (prev, next) => (
  isEqual(prev.variantAttributes, next.variantAttributes)
  && prev.disabled === next.disabled
  && prev.banksQuery === next.banksQuery
));

export default EventsBlock;
