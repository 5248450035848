import React, { useState } from 'react';

import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { get } from 'lodash';
import { useField } from 'formik';

import { useFormGroup } from '@hooks';

import ServerError from './ServerError';

// ReactSelect does not allow options duplication. When you create a new option creatable tries to add an option that
// looks like { label: "Create \"0.2\"", value: 0.2 } and it does not work if value: 0.2 already exists.
// Therefore I decided to add a random string to the value in order to overcome the aforementioned limitation
const salt = '96a1ead37b6d6e0971151265152ea58c';

const reorder = (array, from, to) => {
  const newArray = array.slice();
  newArray.splice(to < 0 ? array.length + to : to, 0, newArray.splice(from, 1)[0]);

  return newArray;
};

const MultiValue = SortableElement((props) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return <components.MultiValue {...props} innerProps={{ onMouseDown }} />;
});

const MemoizedSelect = React.memo(({
  options,
  setValue,
  field,
  name,
  isCreatable,
  ...selectOptions
}) => {
  const getOption = (value) => options.find(({ value: optionValue }) => optionValue === value);
  const [selected, setSelected] = useState((field.value || []).map(getOption));
  const ReorderableSelect = SortableContainer(isCreatable ? CreatableSelect : Select);
  const [resolvedOptions, setResolvedOptions] = useState(options);

  const onChange = (selectedOptions) => {
    setSelected(selectedOptions);

    return setValue(selectedOptions.map(({ value }) => value));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = reorder(selected, oldIndex, newIndex);

    setSelected(newValue);

    return setValue(newValue.map(({ value }) => value));
  };

  const handleCreate = (saltedValue) => {
    const value = saltedValue.replace(salt, '');
    const newOption = { value, label: value };

    setResolvedOptions([...resolvedOptions, newOption]);
    setSelected([...selected, newOption]);
    return setValue([...selected.map((el) => el.value), value]);
  };

  const getNewOptionData = (inputValue, optionLabel) => ({ value: `${salt}${inputValue}`, label: optionLabel });

  return (
    <>
      <ReorderableSelect
        axis="xy"
        onSortEnd={onSortEnd}
        distance={4}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        isMulti
        options={resolvedOptions}
        value={selected}
        onChange={onChange}
        components={{ MultiValue }}
        className="react-select"
        classNamePrefix="react-select"
        onCreateOption={handleCreate}
        getNewOptionData={getNewOptionData}
        noOptionsMessage={() => null}
        {...selectOptions}
      />
      <ServerError name={name} />
      <span className="text-muted">Selected values are reorderable via Drag and Drop</span>
    </>
  );
}, (prev, next) => (
  prev.isDisabled === next.isDisabled
    && get(prev.field, 'name') === get(next.field, 'name')
    && get(prev.field, 'value') === get(next.field, 'value')
));

export default function ReorderableMultiSelectField({ name, ...props }) {
  const { generateName } = useFormGroup();
  const [field, , { setTouched, setValue }] = useField(generateName(name));

  return (
    <MemoizedSelect
      {...{
        ...props, field, setValue, setTouched, name,
      }}
    />
  );
}
