import React, {
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';

import APP_DATA from '@services/appData';

const UserContext = createContext();

export function UserContextProvider({ children }) {
  const [user, setUser] = useState(APP_DATA.user);

  const resetUserData = () => setUser(false);
  const userData = useMemo(() => ({ user, resetUserData }), [user]);

  return (
    <UserContext.Provider value={userData}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
