import React from 'react';
import { Link } from 'react-router-dom';
import editPath from '@root/services/editPath';
import { Modal } from 'react-bootstrap';
import { IconButton } from '@tripledotstudios/react-core';

export default function MassOperationIncompleteModalContent({
  items,
  pageModelName,
  modelName,
  currentApplication,
  onConfirm,
}) {
  const entityPath = (entity) => editPath({
    ...entity,
    application: currentApplication,
    id: entity.id,
    type: modelName,
  });

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{`Status of some ${pageModelName} can't be changed due to validation error`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          {`List of ${pageModelName}:`}
          {items.map((entity) => (
            <div>
              <Link to={entityPath(entity)} target="_blank">
                {`${modelName} "${entity.name}"`}
              </Link>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <IconButton.Confirm onClick={onConfirm} variant="success">Ok</IconButton.Confirm>
      </Modal.Footer>
    </>
  );
}
