import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import { Button, Col } from 'react-bootstrap';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FormGroup, useConfirm, useCurrentApplication } from '@hooks';
import {
  FaButton, ReactTable, RemoveButton, PriorityColumn,
} from '@pages/common';
import { AbTestingRoutes } from '@pages/routes';
import { DEFAULT_TAG, AB_VARIANT_ONLY_TAG, RULES_LIST } from '@pages/rules/list';
import IconButton from '@controls/buttons';
import { SelectField, ServerError } from '@controls/form';
import Tooltip from '@controls/Tooltip';
import APP_DATA from '@services/appData';
import getAllOperation from '@services/rules/getAllOperations';
import getOperationsForSelect from '@services/rules/getOperationsForSelect';
import typesForSelectByTags from '@services/rules/typesForSelectByTags';
import getDefaultSubVariant from './getDefaultSubVariant';

const {
  rawEnums: { RuleOperations: RawRuleOperations },
  modelsLocales: { abTesting: { tooltips: { variantRules: variantRulesTooltip } } },
} = APP_DATA;
const allOperations = getAllOperation(RawRuleOperations);

const RowFormGroup = ({ row, children }) => (
  <FormGroup
    name={`variantRuleGroupsAttributes.${row.original.index}.ruleSectionsAttributes[0].rulesAttributes[0]`}
  >
    {children}
  </FormGroup>
);

const columns = ({ RuleComponent, isArchived }) => [
  {
    id: 'leftActions',
    style: { width: 50 },
    Cell: ({ row: { index, original: { moveUp, moveDown } }, rows }) => (
      <div className="d-flex flex-column">
        <FaButton onClick={moveUp} disabled={isArchived || index === 0}>
          <FontAwesomeIcon icon={faChevronUp} />
        </FaButton>
        <FaButton disabled={isArchived || index === rows.length - 1} onClick={moveDown}>
          <FontAwesomeIcon icon={faChevronDown} />
        </FaButton>
      </div>
    ),
  },
  {
    Header: 'Operator',
    Cell: ({ row }) => {
      const { original: { variantRule } } = row;
      return (
        <RowFormGroup row={row}>
          <SelectField
            name="operation"
            isDisabled={isArchived}
            options={variantRule.options || getOperationsForSelect(allOperations, variantRule.operations)}
            hasPrepopulatedOption
          />
        </RowFormGroup>
      );
    },
  },
  {
    Header: 'Value',
    Cell: ({ row }) => (
      <RowFormGroup row={row}>
        <RuleComponent disabled={isArchived} />
      </RowFormGroup>
    ),
  },
  PriorityColumn,
  {
    Header: '',
    id: 'actions',
    Cell: ({ row }) => <RemoveButton onClick={row.original.onRemove} disabled={isArchived} />,
    style: { width: 75, textAlign: 'center' },
  },
];

const getDefaultSubVariants = (values) => (
  values.variantsAttributes[0].defaultSubVariant
    ? values.variantsAttributes
      .reduce((acc, variant) => ({
        ...acc,
        // in some cases there are no subVariants but there is the default subVariant
        [variant.id]: getDefaultSubVariant(variant) || variant.defaultSubVariant,
      }), {})
    : {}
);

export default function VariantRuleGroups({
  values, dispatch, isArchived, variantRule,
}) {
  const { applicationKey, currentApplication } = useCurrentApplication();
  const { operations, options, component: RuleComponent } = variantRule;
  const memoizedColumns = useMemo(() => columns({ RuleComponent, isArchived }), [values.variantRuleType]);
  const [submittingRule, setSubmittingRule] = useState(false);
  const confirm = useConfirm();

  const findOperation = (operationValue) => allOperations.find(({ value }) => value === operationValue);
  const currentOperation = findOperation(get(values, 'newVariantRule.operation'));

  const rulesRows = values.variantRuleType
    ? values.variantRuleGroupsAttributes.reduce(
      (rows, row, index) => (
        row._destroy
          ? rows
          : [
            ...rows,
            {
              ...row,
              index,
              variantRule,
              operation: row.ruleSectionsAttributes[0].rulesAttributes[0].operation,
              onRemove: () => {
                confirm.showConfirmation({
                  title: 'The rule group and all its entities will be deleted and not available for work. Continue?',
                }).then(() => dispatch({ type: 'removeVariantRuleGroup', index }));
              },
              moveUp: () => { dispatch({ type: 'moveUpVariantRuleGroup', index }); },
              moveDown: () => { dispatch({ type: 'moveDownVariantRuleGroup', index }); },
            },
          ]
      ),
      [],
    ) : [];

  const validateRuleGroup = async (params, onComplete) => {
    setSubmittingRule(true);
    try {
      const validationResult = values.id
        ? await AbTestingRoutes.Experiments.createVariantRuleGroupRequest({
          ...params,
          id: values.id,
          defaultSubVariants: getDefaultSubVariants(values),
        }) : await AbTestingRoutes.Experiments.validateVariantRuleGroupRequest(params);
      onComplete(validationResult);
    } finally {
      setSubmittingRule(false);
    }
  };

  const addRuleGroup = async () => {
    const params = {
      applicationId: currentApplication.id,
      ruleSectionsAttributes: [{
        rulesAttributes: [{ ...values.newVariantRule, type: values.variantRuleType }],
        ruleSectionsAttributes: [],
      }],
    };
    const onComplete = (validationResult) => {
      dispatch({
        type: 'addVariantRuleGroup',
        validationResult,
        ruleType: variantRule.type,
      });
    };
    await validateRuleGroup(params, onComplete);
  };

  const removeRuleGroup = async () => {
    const params = {
      applicationId: currentApplication.id,
      ruleSectionsAttributes: [],
    };
    const onComplete = (validationResult) => {
      dispatch({
        type: 'removeVariantRuleType',
        validationResult,
      });
    };
    await confirm.showConfirmation({
      title: 'All rule groups and their entities will be deleted and not available for work. Continue?',
    });
    await validateRuleGroup(params, onComplete);
  };

  return (
    <div className="mb-5">
      <div className="mb-2">
        <Tooltip text={variantRulesTooltip}>
          Variant Rules
        </Tooltip>
      </div>
      <div className="d-flex">
        <SelectField
          name="newVariantRuleType"
          className="me-1 w-25"
          options={typesForSelectByTags(RULES_LIST, [DEFAULT_TAG, AB_VARIANT_ONLY_TAG, applicationKey])}
          isDisabled={isArchived}
        />
        <Button
          className="me-1"
          variant="success"
          onClick={() => { dispatch({ type: 'changeVariantRuleType' }); }}
          disabled={values.variantRuleType === values.newVariantRuleType}
        >
          Change Rule
        </Button>
      </div>
      {values.variantRuleType && (
        <div className="mt-2">
          <ServerError name="variantRuleGroupsAttributes.list" />
          <ReactTable data={rulesRows} columns={memoizedColumns} />
          {!isArchived && (
            <div>
              <div className="d-flex">
                <Col xs={3} className="pe-0 ps-0">
                  {(operations || options) && (
                    <SelectField
                      name="newVariantRule.operation"
                      isDisabled={submittingRule}
                      options={options || getOperationsForSelect(allOperations, operations)}
                      hasPrepopulatedOption
                    />
                  )}
                </Col>
                <Col>
                  {currentOperation && !currentOperation.withoutArguments && (
                    <FormGroup name="newVariantRule">
                      <RuleComponent disabled={submittingRule} />
                    </FormGroup>
                  )}
                </Col>
                <div>
                  <IconButton.New onClick={addRuleGroup} disabled={submittingRule}>
                    Add
                  </IconButton.New>
                </div>
              </div>
              <Button
                className="mt-2"
                variant="danger"
                onClick={removeRuleGroup}
                disabled={!values.variantRuleType || submittingRule}
              >
                Remove Rule
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
