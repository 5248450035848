import React from 'react';
import { Col } from 'react-bootstrap';

import { Label, Field, AssetsKeySelectField } from '@controls/form';

import localizeField from './localizeField';

export default function AssetKeySelectBuilder({
  name: baseName,
  labelText,
  options,
  extraFieldName,
  fieldOptions = {},
  tooltips = {},
  extraFieldLabel = 'Asset ID',
  selectSize = 7,
  ...props
}) {
  return {
    [baseName]: {
      Inline: ({ name, disabled }) => (
        <div>
          <AssetsKeySelectField
            name={name || baseName}
            options={options}
            isDisabled={disabled}
            selectSize={selectSize}
            {...props}
          />
          {extraFieldName && (
            <Label text={extraFieldLabel}>
              <Field disabled={disabled} name={extraFieldName} />
            </Label>
          )}
        </div>
      ),
      Labeled: ({
        name, disabled, localeNamespace, locale = {}, tooltipBuilder,
      }) => {
        const { label, tooltip, clientKey } = localizeField(
          baseName, labelText, tooltips, localeNamespace, locale, tooltipBuilder,
        );

        return (
          <Col>
            <Label text={label} fieldSize={8} tooltipText={tooltip} copyableText={clientKey}>
              <AssetsKeySelectField
                name={name || baseName}
                options={options}
                isDisabled={disabled}
                {...props}
              />
            </Label>
            {extraFieldName && (
              <Label text={extraFieldLabel} fieldSize={8}>
                <Field disabled={disabled} name={extraFieldName} />
              </Label>
            )}
          </Col>
        );
      },
      fieldOptions,
    },
  };
}
