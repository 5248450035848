import React from 'react';

import styled, { css } from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { useFlashMessages } from '@tripledotstudios/react-core';

const MaybeClickableIcon = styled(FontAwesomeIcon)`
  ${({ $isClickable }) => $isClickable && css`
    cursor: pointer;
    color: ${({ theme }) => theme.variants.primary};
  `}
`;

export default function ControlsTooltip({
  children,
  text,
  delay,
  show,
  icon,
  placement = 'right',
  copyableText = null,
}) {
  const { success } = useFlashMessages();
  const renderTooltip = (props) => (
    <Tooltip show={show} {...props}>{text}</Tooltip>
  );

  const handleClick = () => {
    if (copyableText) {
      success(`Successfully copied "${copyableText}" to clipboard`);
    }
  };

  const maybeWrapInCopyToClipboard = (component) => {
    if (!copyableText) return component;

    return (
      <CopyToClipboard text={copyableText}>
        {component}
      </CopyToClipboard>
    );
  };

  return (
    <>
      {children}
      <OverlayTrigger placement={placement} overlay={renderTooltip} delay={delay}>
        {maybeWrapInCopyToClipboard(icon || (
          <MaybeClickableIcon
            icon={faQuestionCircle}
            className="ms-2"
            onClick={handleClick}
            $isClickable={copyableText}
          />
        ))}
      </OverlayTrigger>
    </>
  );
}
