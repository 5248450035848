import { memoize } from 'lodash';

import { WoodokuSagaRoutes } from '@pages/routes';
import { selectBuilder } from '@services/fields-factory';

import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';
import { adRequestDelayFields } from '@components/game-settings/Resource/Shared';
import selectOptionsWithQuickLink from '@services/selectOptionsWithQuickLink';

const popupsOptions = [
  { label: 'IDFA', value: 'IDFA' },
  { label: 'Languages', value: 'Language_Menu' },
  { label: 'PP/T&C', value: 'Privacy_Policy' },
  { label: 'GDPR', value: 'GDPR' },
  { label: 'Push Notifications', value: 'Allow_Notifications' },
  { label: 'Rate Us', value: 'Rate_Us' },
  { label: 'Google CMP', value: 'Google_CMP' },
  { label: 'Push notifications retry', value: 'PushNotifications_Retry_OptIn' },
];

const Fields = memoize(({
  areaSetOptions,
  chapterSetOptions,
  figureSetOptions,
  abilitiesSetOptions,
  levelRewardSetsOptions,
}) => ({
  ...selectBuilder({ name: 'abilitiesSetId', ...abilitiesSetOptions }),
  ...selectBuilder({ name: 'areaSetId', ...areaSetOptions }),
  ...selectBuilder({ name: 'chapterSetId', ...chapterSetOptions }),
  ...selectBuilder({ name: 'figureSetId', ...figureSetOptions }),
  ...selectBuilder({ name: 'levelRewardsSetId', ...levelRewardSetsOptions }),
}), (args) => JSON.stringify(args));

const fieldsFactory = ({ applicationId }) => {
  const abilitiesSetOptions = selectOptionsWithQuickLink(WoodokuSagaRoutes.Abilities.Sets, applicationId);
  const areaSetOptions = selectOptionsWithQuickLink(WoodokuSagaRoutes.AreaSets, applicationId);
  const chapterSetOptions = selectOptionsWithQuickLink(WoodokuSagaRoutes.ChapterSets, applicationId);
  const figureSetOptions = selectOptionsWithQuickLink(WoodokuSagaRoutes.FigureSets, applicationId);
  const levelRewardSetsOptions = selectOptionsWithQuickLink(WoodokuSagaRoutes.LevelRewardSets, applicationId);

  return Fields({
    applicationId,
    areaSetOptions,
    chapterSetOptions,
    figureSetOptions,
    abilitiesSetOptions,
    levelRewardSetsOptions,
  });
};

export default combineWithSharedFieldsFactory([fieldsFactory, adRequestDelayFields], { popupsOptions });
