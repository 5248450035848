import React from 'react';

import { PageSection } from '@pages/common';

export default function HomeCard({
  title, tooltip, className, children,
}) {
  return (
    <PageSection title={title} tooltip={tooltip} className={className}>
      {children}
    </PageSection>
  );
}
