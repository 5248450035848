import { memoize } from 'lodash';

import { LocalizationsRoutes, Match3dRoutes } from '@pages/routes';
import { fieldBuilder, selectBuilder } from '@services/fields-factory';
import { fromClassNameToOptions } from '@services/enums';

import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';
import selectOptionsWithQuickLink from '@services/selectOptionsWithQuickLink';

const popupsOptions = [
  { label: 'IDFA', value: 'IDFA' },
  { label: 'GDPR', value: 'GDPR' },
  { label: 'Push Notifications', value: 'Allow_Notifications' },
  { label: 'Rate Us', value: 'Rate_Us' },
  { label: 'Tutorial', value: 'Tutorial' },
  { label: 'Google CMP', value: 'Google_CMP' },
  { label: 'Push notifications retry', value: 'PushNotifications_Retry_OptIn' },
];

const Fields = memoize(({
  interLevelFlows,
  chapterSetOptions,
  localizationConfigOptions,
  personalLevelOptions,
}) => ({
  ...fieldBuilder({ name: 'hintPowerUpLevelUnlocked', type: 'number' }),
  ...fieldBuilder({ name: 'hintPowerUpStartingBalance', type: 'number' }),
  ...fieldBuilder({ name: 'hintPowerUpPriceForCoins', type: 'number' }),
  ...fieldBuilder({ name: 'freezePowerUpLevelUnlocked', type: 'number' }),
  ...fieldBuilder({ name: 'freezePowerUpStartingBalance', type: 'number' }),
  ...fieldBuilder({ name: 'freezePowerUpPriceForCoins', type: 'number' }),
  ...fieldBuilder({ name: 'maxLives', type: 'number' }),
  ...fieldBuilder({ name: 'livesRefreshRateMinutes', type: 'number' }),
  ...fieldBuilder({ name: 'priceForFiveLives', type: 'number' }),
  ...fieldBuilder({ name: 'gameOverExtraTimePrice', type: 'number' }),
  ...fieldBuilder({ name: 'freeCoins', type: 'number' }),
  ...fieldBuilder({ name: 'freeCoinsRefreshRateMinutes', type: 'number' }),
  ...fieldBuilder({ name: 'adsInterstitialFrequency', type: 'number' }),
  ...fieldBuilder({ name: 'onboardingSkipMap', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dragLerp', type: 'number' }),
  ...fieldBuilder({ name: 'dragHeight', type: 'number' }),
  ...fieldBuilder({ name: 'inertiaForce', type: 'number' }),
  ...fieldBuilder({ name: 'outOfLidForce', type: 'number' }),
  ...fieldBuilder({ name: 'pullDuration', type: 'number' }),
  ...fieldBuilder({ name: 'rejectionPower', type: 'number' }),
  ...fieldBuilder({ name: 'gravityForce', type: 'number' }),
  ...fieldBuilder({ name: 'podiumPrefill', type: 'checkbox' }),
  ...fieldBuilder({ name: 'accelerometer', type: 'checkbox' }),
  ...fieldBuilder({ name: 'tapFunction', type: 'checkbox' }),
  ...fieldBuilder({ name: 'shopshelfUnlock', type: 'number' }),
  ...fieldBuilder({ name: 'shopshelfAvailable', type: 'checkbox' }),
  ...selectBuilder({ name: 'interLevelFlow', options: interLevelFlows }),
  ...selectBuilder({ name: 'chapterSetId', ...chapterSetOptions }),
  ...selectBuilder({ name: 'localizationsConfigId', ...localizationConfigOptions }),
  ...selectBuilder({ name: 'personalLevelSetId', ...personalLevelOptions }),
}), (args) => JSON.stringify(args));

const fieldsFactory = ({ applicationId }) => {
  const interLevelFlows = fromClassNameToOptions('Match3d::InterLevelFlows');
  const chapterSetOptions = selectOptionsWithQuickLink(Match3dRoutes.ChapterSets, applicationId);
  const localizationConfigOptions = selectOptionsWithQuickLink(LocalizationsRoutes.Configs, applicationId);
  const personalLevelOptions = selectOptionsWithQuickLink(Match3dRoutes.PersonalLevelSets, applicationId);

  return Fields({
    applicationId,
    chapterSetOptions,
    interLevelFlows,
    localizationConfigOptions,
    personalLevelOptions,
  });
};

export default combineWithSharedFieldsFactory(fieldsFactory, { popupsOptions });
