import React from 'react';

import { useI18n } from '@hooks';
import {
  Label, Field, SelectField, TimePeriodField,
} from '@controls/form';
import { PageSection } from '@pages/common';
import getFieldLocale from '@services/getFieldLocale';

const dayOptions = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 0, label: 'Sunday' },
];

export default function JourneyNotificationAlert({ applicationTypeName }) {
  const { translate } = useI18n();
  const journeyEventName = translate.fallback('journeys.events.name', applicationTypeName);
  const locale = getFieldLocale('journeys', 'notificationAlerts');

  return (
    <>
      <PageSection
        title={`Notification alert set up for ${journeyEventName}`}
      />
      <Label
        text={locale.journeyCheckDay.label}
        tooltipText={locale.journeyCheckDay.tooltip}
      >
        <SelectField name="journeyCheckDay" options={dayOptions} />
      </Label>
      <Label
        text={locale.journeyCheckTime.label}
        tooltipText={locale.journeyCheckTime.tooltip}
      >
        <TimePeriodField name="journeyCheckTime" />
      </Label>
      <Label
        text={locale.journeyCheckPeriod.label}
        tooltipText={locale.journeyCheckPeriod.tooltip}
      >
        <Field name="journeyCheckPeriod" type="number" appendText="Hours" />
      </Label>
    </>
  );
}
