import React, { useState } from 'react';

import { camelCase, get, isNil } from 'lodash';
import { ServerError } from '@controls/form';
import CellContent from '../shared/cells/CellContent';

import { Td } from '../shared/styled-components';

export default function EditableCell({
  path,
  name,
  value,
  oldValues,
  sticky,
  setFieldValue,
  valueWrapper,
  disabled,
  isNewRecord,
  children,
}) {
  const fullName = `${path}.${name}`;
  const oldValueFromResponse = get(oldValues, camelCase(name));

  const [currentValue, setCurrentValue] = useState(value);
  const [oldValue, setOldValue] = useState(oldValueFromResponse);
  const [defaultValue, setDefaultValue] = useState(value);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [changed, setChanged] = useState(!isNil(oldValue));

  const enableEditMode = () => !disabled && setEditModeEnabled(true);
  const disableEditMode = () => setEditModeEnabled(false);

  const saveChangedValue = (changedValue) => {
    if (!oldValue && oldValue !== 0) setOldValue(defaultValue);

    setFieldValue(fullName, currentValue);
    setFieldValue(`${path}.markedAsChanged`, true);
    setDefaultValue(changedValue);
    disableEditMode();
    setChanged(true);
  };

  const onKeyDown = (event) => {
    switch (event.key) {
      case 'Escape': {
        disableEditMode();
        setCurrentValue(defaultValue);

        break;
      }
      case 'Enter':
        if (event.shiftKey) break;

        event.preventDefault();
        saveChangedValue(currentValue);

        break;
      default:
    }
  };

  const onBlur = () => saveChangedValue(currentValue);

  return (
    <Td
      width="250px"
      onDoubleClick={() => enableEditMode()}
      sticky={sticky}
      isChanged={changed}
    >
      {editModeEnabled
        ? children({
          fullName,
          oldValue,
          onKeyDown,
          onBlur,
          disableEditMode,
          saveChangedValue,
          currentValue,
          setCurrentValue,
        })
        : (
          <CellContent
            newValue={currentValue}
            oldValue={oldValue}
            valueWrapper={valueWrapper}
            isNewRecord={isNewRecord}
          />
        )}
      <ServerError name={fullName} />
    </Td>
  );
}
