import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import {
  at, every, isEmpty, map, pick,
} from 'lodash';
import {
  ButtonToolbar, IconButton, Spinner, useRouter,
} from '@tripledotstudios/react-core';

import {
  Collapsible, Field, Label, SelectField,
} from '@controls/form';
import { CancelButton } from '@controls/form/buttons';
import {
  BooleanIcon, InLiveBadge, PageHeader, ReactTable,
} from '@pages/common';
import { fromClassNameToOptions, localizedEnumValueString } from '@services/enums';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { TileRoutes } from '@pages/routes';
import { Form, useQuery, useSelectedRows } from '@hooks';
import { MassSelectCheckbox } from '@hooks/useSelectedRows';

import LabelLink from '../../common/LabelLink';
import ReplacementModal from './ReplacementModal';

const SelectAllWrapper = styled.div`
  margin-left: -20px;
  margin-top: 15px;
`;

const CheckboxWrapper = styled.div`
  display: block;
  margin-right: 5px;
  position: relative;
  left: -20px;
  top: 0.8rem;
  height: 0;
`;

const tileTypeOptions = fromClassNameToOptions('Tile::TileTypes');

const columnsFactory = ({ selectedRows, toggleLayoutRow }) => [
  {
    id: 'toggle',
    Cell: ({ row: { original } }) => (
      <input
        type="checkbox"
        checked={selectedRows[original.id]}
        onChange={(e) => toggleLayoutRow(e, original.index)}
      />
    ),
  },
  {
    Header: 'Level Layout number',
    accessor: 'position',
  },
  {
    Header: 'Layout Name',
    accessor: 'name',
  },
  {
    Header: 'Type',
    accessor: 'tileType',
    Cell: ({ row: { original } }) => localizedEnumValueString('Tile::TileTypes', original.tileType),
  },
  {
    Header: 'Valid for use',
    accessor: 'validForUse',
    Cell: ({ row: { original } }) => <BooleanIcon value={original.validForUse} />,
  },
  {
    Header: 'Seeds amount',
    accessor: 'seedCount',
  },
];

export default function New() {
  const { query: { applicationId } } = useRouter();
  const [searchResults, setSearchResults] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  // for now we only need it to authorize the action
  const { isLoading, response: newResponse } = useQuery(
    TileRoutes.LayoutReplacements.newRequest,
    { applicationId },
    (r) => r,
  );

  const levelLayouts = searchResults?.results?.flatMap(({ layouts }) => layouts) || [];
  const {
    selectedRows,
    setSelectedRows,
    toggleRow,
    selectedItems,
  } = useSelectedRows(levelLayouts);
  const layoutColumns = useMemo(() => columnsFactory({
    selectedRows, toggleLayoutRow: toggleRow,
  }), [toggleRow]);

  const searchResponseHandler = noRedirectHandlerFactory({
    entityName: 'Level layouts',
    actionName: 'search',
    showSuccess: false,
    setData: setSearchResults,
  });
  const findLayouts = (values) => {
    setIsSearching(true);
    return TileRoutes.LayoutReplacements
      .indexRequest({ ...values, applicationId })
      .then((response) => {
        setSelectedRows({});
        setSearchResults(({ _meta, ...state }) => ({ ...state, ...response.data }));
        return response;
      }).then(searchResponseHandler)
      .finally(() => setIsSearching(false));
  };

  const isBankSelected = (layouts) => every(at(selectedRows, map(layouts, 'id')));

  const toggleBankRow = (layouts) => {
    const bankSelected = isBankSelected(layouts);
    return setSelectedRows(
      (rows) => ({
        ...rows,
        ...layouts.reduce((acc, l) => ({ ...acc, [l.id]: !bankSelected }), {}),
      }),
    );
  };

  if (isLoading) return <Spinner />;

  let layoutIndex = 0;
  return newResponse.status < 300 && (
    <>
      <PageHeader title="Search Level Layout in Layout Banks">
        <CancelButton to={TileRoutes.Banks.indexPath({ applicationId })} />
      </PageHeader>
      <Form initialValues={{ ...newResponse.data, ...searchResults }} onSubmit={findLayouts}>
        {() => (
          <Row>
            <Col sm={4}>
              <Label text="Hash" labelSize={4} fieldSize={8}>
                <Field name="contentHash" />
              </Label>
            </Col>
            <Col sm={4}>
              <Label text="Layout Type" labelSize={4} fieldSize={8}>
                <SelectField name="tileType" options={tileTypeOptions} />
              </Label>
            </Col>
            <Col sm={4}>
              <ButtonToolbar>
                <IconButton.Search type="submit">Find</IconButton.Search>
                <IconButton.Restore
                  type="button"
                  onClick={() => { setIsOpen(true); }}
                  disabled={isEmpty(selectedItems)}
                >
                  Replace selected Level Layouts
                </IconButton.Restore>
              </ButtonToolbar>
            </Col>
          </Row>
        )}
      </Form>
      {isSearching ? <Spinner /> : (
        <>
          {searchResults?.results?.length > 0 && (
            <>
              <SelectAllWrapper>
                <MassSelectCheckbox
                  setSelectedRows={setSelectedRows}
                  selectedRows={selectedRows}
                  items={levelLayouts}
                />
              </SelectAllWrapper>
              {searchResults.results.map(({ bank, layouts }) => {
                const bankUrl = TileRoutes.Banks.editPath({ applicationId, id: bank.id });
                return (
                  <Row className="mt-3">
                    <CheckboxWrapper>
                      <input
                        type="checkbox"
                        checked={isBankSelected(layouts)}
                        onChange={() => toggleBankRow(layouts)}
                      />
                    </CheckboxWrapper>
                    <Collapsible
                      className="mb-3"
                      header={(
                        <div>
                          <b>Layout Bank ID: </b>
                          <Link to={bankUrl} target="_blank">{bank.id}</Link>
                          <b> Name: </b>
                          <Link to={bankUrl} target="_blank">{bank.name}</Link>
                          <b> Availability: </b>
                          <InLiveBadge inLive={bank.inLive} />
                          {bank.labels.length > 0 && (
                            <>
                              <b> Labels: </b>
                              {bank.labels.map((label) => (
                                <LabelLink key={label.name} label={label} />
                              ))}
                            </>
                          )}
                        </div>
                      )}
                      eventKey={bank._uuid}
                    >
                      <ReactTable
                        columns={layoutColumns}
                        data={layouts.map((layout) => {
                          layoutIndex += 1;
                          return ({ ...layout, index: layoutIndex - 1 });
                        })}
                      />
                    </Collapsible>
                  </Row>
                );
              })}
            </>
          )}
          {searchResults && searchResults.results?.length === 0 && (
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                No Results
              </Col>
            </Row>
          )}
        </>
      )}
      {isOpen && (
        <ReplacementModal
          isOpen={isOpen}
          selectedRows={selectedRows}
          handleClose={() => { setIsOpen(false); }}
          searchResults={searchResults}
          onSuccess={() => { findLayouts(pick(searchResults, ['contentHash', 'tileType'])); }}
        />
      )}
    </>
  );
}
