import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';
import {
  RulesList,
  InLiveBadge,
  InLiveColumnHeader,
  TIMESTAMP_COLUMNS,
  PriorityColumn,
} from '@pages/common';
import IconButton from '@controls/buttons';

export default function columnsFactory({
  onDelete,
  setDuplicatedRecord,
}) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    PriorityColumn,
    {
      id: 'availability_header',
      Header: () => <InLiveColumnHeader />,
      Cell: ({ row }) => <InLiveBadge inLive={row.original.availabilityStateLive} />,
    },
    {
      Header: 'Rules',
      Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
    },
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({ row: { original: { id, name } } }) => (
        <ButtonToolbar>
          <IconButton.Edit to={`${id}/edit`} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
          <IconButton.Delete onClick={onDelete(id, name)} minimized />
        </ButtonToolbar>
      ),
    },
  ];
}
