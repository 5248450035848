import React from 'react';

import { Form } from '@hooks';
import { useRouter } from '@tripledotstudios/react-core';
import {
  Label, Field, FormButtonsGroup, StaticFieldsFormGroup,
} from '@controls/form';
import { AdsRoutes } from '@pages/routes';
import { PageHeader } from '@pages/common';

export default function PlacementsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();

  return (
    <Form initialValues={data} onSubmit={onSubmit}>
      {() => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Interstitial Placement`}>
            <FormButtonsGroup
              cancelButtonPath={AdsRoutes.Interstitials.Placements.indexPath({ applicationId })}
            />
          </PageHeader>

          <Label text="Name" required>
            <Field type="text" name="name" />
          </Label>
          <Label text="Key" required>
            <Field type="text" name="key" />
          </Label>

          <StaticFieldsFormGroup data={data} />
        </>
      )}
    </Form>
  );
}
