import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  Label,
  Field,
  FormButtonsGroup,
  StaticFieldsFormGroup,
} from '@controls/form';

import { PageHeader, DuplicateButton } from '@pages/common';
import { MissionsRoutes } from '@pages/routes';

import RewardValues from './RewardValues';
import Tiers from './Tiers';

import formReducer from './formReducer';

export default function RewardSetsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  return (
    <Form
      initialValues={data}
      reducer={formReducer}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Rewards Set`} userGuideUrlKey="missionsGuideUrl">
            <FormButtonsGroup
              cancelButtonPath={MissionsRoutes.Rewards.Sets.indexPath({ applicationId })}
            >
              <DuplicateButton routes={MissionsRoutes.Rewards.Sets} data={data} entityName="Rewards Set" />
            </FormButtonsGroup>
          </PageHeader>

          <div className="ps-3">
            <Label text="Name"><Field type="text" name="name" /></Label>

            <StaticFieldsFormGroup data={data} />
          </div>

          <RewardValues data={values.rewardValuesAttributes} />
          <Tiers data={values.tiersAttributes} />
        </>
      )}
    </Form>
  );
}
