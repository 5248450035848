import { addUuid } from '@services/recursivelyProcessObject';

export default function solitaireReducer(state, action) {
  const { actionType, suitsAttributes, index } = action;
  const { configAttributes: { levelsAttributes } } = state;

  const uncheckAllSuits = () => (
    suitsAttributes.forEach((suit) => {
      levelsAttributes[index][suit] = false;
    })
  );

  if (actionType === 'anySuitChecked') {
    const { value } = action;

    levelsAttributes[index].anySuitSelected = value;

    if (value) uncheckAllSuits();
  }

  if (actionType === 'handleNumberOfCardsChanged') {
    const { value } = action;

    if (value === 4) {
      levelsAttributes[index].anySuitSelected = true;
    }

    uncheckAllSuits();
  }

  if (actionType === 'suitChanged') {
    const { value, suitName } = action;

    if (value) {
      levelsAttributes[index].anySuitSelected = false;
    }

    levelsAttributes[index][suitName] = value;
  }

  if (actionType === 'addSolitaireReward') {
    const { uuid } = action;

    const targetLevel = levelsAttributes.find(({ _uuid }) => _uuid === uuid);

    targetLevel.rewardsAttributes = [...(targetLevel.rewardsAttributes || []), addUuid({ _destroy: false })];
  }

  return state;
}
