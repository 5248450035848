import { uniq, compact } from 'lodash';

const presentUnlockedExperts = (postcard, experts) => {
  if (!postcard) return [];

  const expertsMap = Object.fromEntries(experts.map(({ id, internalId }) => [id, internalId]));
  const findExpertReward = (entity) => (
    entity.rewardsAttributes.reduce((memo, reward) => (
      (!reward._destroy && reward.type === 'Merge::Rewards::Expert' && reward.value)
        ? [...memo, expertsMap[reward.value]]
        : memo
    ), [])
  );

  return uniq([
    ...findExpertReward(postcard),
    ...postcard.storyOrdersAttributes.flatMap((storyOrder) => storyOrder.tasksAttributes.flatMap(findExpertReward)),
  ]);
};

const presentLinesRequired = (postcard, items) => {
  if (!postcard) return [];

  const schemaItemIds = compact(postcard.storyOrdersAttributes.flatMap((storyOrder) => (
    storyOrder.tasksAttributes
      .flatMap((task) => task.targetsAttributes.map((target) => !target._destroy && target.schemaItemId))
  )));

  return uniq(schemaItemIds.map((itemId) => itemId && items.find((i) => i.id === itemId).line));
};

export { presentLinesRequired, presentUnlockedExperts };
