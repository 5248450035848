import {
  fetchCollection, newResource, editResource, createResource, updateResource, deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/puzzle_time/configs_2048`;

export function get2048Configs(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function new2048Config(options) {
  return newResource(baseUrl(options), options);
}

export function edit2048Config(data, options) {
  return editResource(baseUrl(data), data, options);
}

export function create2048Config(query, values) {
  return createResource(baseUrl(query), values);
}

export function update2048Config(query, values) {
  return updateResource(baseUrl(query), values);
}

export function delete2048Config(values) {
  return deleteResource(baseUrl(values), values);
}
