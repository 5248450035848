import { cloneDeep, get, isArray } from 'lodash';

import { addUuid } from '@services/recursivelyProcessObject';
import { BASE_BOARD_GRID_COLUMN_SIZE } from '../configuration-forms/woodoku/constants';
import {
  isLargeTile,
  largeTileCoordinates,
  largeTileEntity,
  isLargeTileFamily,
} from '../configuration-forms/woodoku/utils';

const DEFAULT_LEVELS_PATH = 'configAttributes.levelsAttributes';

const rotateBoardCell = (position) => {
  const rowIndex = Math.floor(position / BASE_BOARD_GRID_COLUMN_SIZE);
  const cellIndex = position - (rowIndex * BASE_BOARD_GRID_COLUMN_SIZE);
  return (cellIndex + 1) * BASE_BOARD_GRID_COLUMN_SIZE - (rowIndex + 1);
};

/* eslint-disable no-param-reassign */
export default function woodokuReducer(state, action) {
  const { actionType, index, levelsPath } = action;
  const resolvedLevelsPath = levelsPath || DEFAULT_LEVELS_PATH;
  const levelsAttributes = get(state, resolvedLevelsPath);

  if (actionType === 'globalCheckboxAttributeChange') {
    const { attributeName, value } = action;

    levelsAttributes.forEach((level) => {
      level[attributeName] = value;

      return level;
    });
  }

  if (actionType === 'addReward') {
    levelsAttributes[index].rewardsAttributes.push(addUuid({}));
  }

  if (actionType === 'rotateDynamicConfigValues') {
    const { dynamicConfigId, figureSetOptions } = action;

    const level = levelsAttributes[index];
    const savedStartIndex = level.startIndex;

    level.startIndex = null;

    if (!dynamicConfigId) {
      const { figureSetId } = (figureSetOptions[savedStartIndex] || {});

      level.loseThreshold = NaN;
      level.loseStep = NaN;

      if (figureSetId) level.figureSetId = figureSetId;

      return state;
    }

    level.figureSetId = null;
  }

  if (actionType === 'rotateBoard') {
    const attributes = levelsAttributes[index].boardCellsAttributes;
    const { tileTypeOptions } = action;

    attributes.forEach((attribute) => {
      const { baseTileId, parameters = {} } = attribute;
      const tileEntity = largeTileEntity(tileTypeOptions, baseTileId);

      if (isLargeTileFamily(tileEntity.familyId)) {
        attribute.position = rotateBoardCell(attribute.position) - tileEntity.width + 1;
        attribute.parameters[baseTileId].coordinates = largeTileCoordinates(attribute.position, tileEntity);
      } else {
        const referenceId = get(parameters, `${baseTileId}.referenceId`);
        if (referenceId) {
          attribute.parameters[baseTileId].referenceId = rotateBoardCell(Number(referenceId) - 1) + 1;
        }
        attribute.position = rotateBoardCell(attribute.position);
      }
    });
  }

  if (actionType === 'clearCells') {
    const { cellIndices = [] } = action;

    const boardCellAttributes = levelsAttributes[index].boardCellsAttributes;

    if (boardCellAttributes.length >= 1) {
      if (cellIndices.length === 0) {
        boardCellAttributes.forEach((boardCell) => {
          boardCell.baseTileId = null;
          boardCell.overlayTileId = null;
          boardCell.parameters = {};
        });
      } else {
        cellIndices.forEach((cellIndex) => {
          const boardCell = boardCellAttributes.find(({ position }) => position === cellIndex);

          if (boardCell) {
            boardCell.baseTileId = null;
            boardCell.overlayTileId = null;
            boardCell.parameters = {};
          }
        });
      }
    }
  }

  if (actionType === 'updateCells') {
    const { cellIndices, cellConfig, tileTypeOptions } = action;
    const attributes = levelsAttributes[index].boardCellsAttributes;
    const { baseTileId } = cellConfig;
    const cellConfigCopy = cloneDeep(cellConfig);
    const largeTile = isLargeTile(tileTypeOptions, baseTileId);
    const groupedCoordinates = get(cellConfigCopy, `parameters[${baseTileId}].coordinates`);

    cellIndices.forEach((cellIndex) => {
      const currentCell = attributes.find(({ position }) => position === cellIndex);

      if (currentCell) {
        currentCell.baseTileId = baseTileId;
        currentCell.overlayTileId = cellConfig.overlayTileId;
        currentCell.parameters = {
          ...cellConfigCopy.parameters,
          ...(baseTileId && {
            [baseTileId]: {
              ...cellConfigCopy.parameters[baseTileId],
              ...(largeTile && !isArray(groupedCoordinates) && { coordinates: groupedCoordinates[cellIndex] }),
            },
          }),
        };
      } else {
        attributes.push(addUuid({
          ...cellConfigCopy,
          position: cellIndex,
          _destroy: false,
          parameters: {
            ...cellConfigCopy.parameters,
            ...(baseTileId && {
              [baseTileId]: {
                ...cellConfigCopy.parameters[baseTileId],
                ...(largeTile && { coordinates: groupedCoordinates[cellIndex] }),
              },
            }),
          },
        }));
      }
    });
  }

  if (actionType === 'addLevelTileProbability') {
    const attributes = levelsAttributes[index].tileProbabilitiesAttributes;

    attributes.push(addUuid({ tileId: null, weight: '', _destroy: false }));
  }

  if (actionType === 'addLevelGoal') {
    const attributes = levelsAttributes[index].levelGoalsAttributes;

    attributes.push(addUuid({
      goalType: null, tileId: null, targetScore: '', _destroy: false,
    }));
  }

  if (actionType === 'removeConfigItem') {
    const { collectionName, uuid } = action;

    const collection = get(levelsAttributes[index], collectionName);

    (collection.find(({ _uuid }) => _uuid === uuid) || {})._destroy = true;
  }

  return state;
}
/* eslint-enable no-param-reassign */
