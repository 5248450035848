import React from 'react';

import {
  ApplicationItemSet,
  AssetsNavigationItem,
  AdsNavigationSection,
  FilesNavigationItem,
  JourneysNavigationSection,
  DailyChallengesNavigationSection,
} from './common';

export default function BlockParty({ applicationId, routingScope, minimized = false }) {
  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="block_party"
      routingScope={routingScope}
      minimized={minimized}
    >
      <FilesNavigationItem minimized={minimized} applicationId={applicationId} />
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />

      <JourneysNavigationSection
        includeActivationEvents
        routingScope={routingScope}
        minimized={minimized}
      />

      <DailyChallengesNavigationSection routingScope={routingScope} minimized={minimized} includeLevelBanks={false} />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
