import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  TIMESTAMP_COLUMNS,
  InLiveBadge,
  InLiveColumnHeader,
  InUseColumn,
} from '@pages/common';
import { MyPetCafeRoutes } from '@pages/routes';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

export default function ColumnsFactory({
  applicationId, group, onDelete,
}) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Type', accessor: 'type' },
    {
      id: 'availability_header',
      Header: () => <InLiveColumnHeader />,
      Cell: ({ row }) => <InLiveBadge inLive={row.original.availabilityStateLive} />,
    },
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({
        row: {
          original: {
            id, name, inUse, downloadUrl,
          },
        },
      }) => (
        <ButtonToolbar>
          <IconButton.Edit to={`${MyPetCafeRoutes.JsonConfigs.editPath({ id, applicationId })}/${group}`} minimized />

          {downloadUrl && (
            <IconButton.Download href={downloadUrl} target="_blank" minimized />
          )}

          <IconButton.Delete onClick={onDelete(id, name)} disabled={isInUse(inUse)} minimized />
        </ButtonToolbar>
      ),
    },
  ];
}
