import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import {
  FormButtonsGroup,
  StaticFieldsFormGroup,
  Label,
  Field,
  ImageFileField,
  LabelsFormGroup,
} from '@controls/form';

import { Form } from '@hooks';

import { PageHeader, DuplicateButton } from '@pages/common';
import { WooAspenRoutes as Routes } from '@pages/routes';

export default function WooAspenChaptersPictureForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
    >
      {() => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Picture`}>
            <FormButtonsGroup cancelButtonPath={Routes.Chapters.Pictures.indexPath({ applicationId })}>
              <DuplicateButton
                routes={Routes.Chapters.Pictures}
                data={data}
                entityName="Picture"
              />
            </FormButtonsGroup>
          </PageHeader>

          <Label required text="Name">
            <Field type="text" name="name" />
          </Label>

          <StaticFieldsFormGroup data={data} />
          <LabelsFormGroup disabled={false} />

          <Label text="Picture">
            <ImageFileField name="file" accept="image/png" />
          </Label>
        </>
      )}
    </Form>
  );
}
