import React from 'react';

import {
  ApplicationItemSet,
} from './common';

export default function SolitaireCom({ applicationId, routingScope, minimized = false }) {
  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="solitaire_com"
      routingScope={routingScope}
      minimized={minimized}
    />
  );
}
