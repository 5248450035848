import { React, useMemo } from 'react';

import { FormGroup } from '@hooks';
import { localizedLabelFactory } from '@controls/form';
import BiddingFloorFields from '../ad-units/bidding-floors/Fields';

export default function ConfigurationFields({ values, disabled, children }) {
  const labelKey = 'ads.configuration.impressionRestrictionsConfigurationAttributes';
  const LocalizedLabel = useMemo(() => localizedLabelFactory(labelKey), [labelKey]);

  return (
    <>
      {children}

      <LocalizedLabel name="biddingFloor" labelSize={5} fieldSize={7}>
        <FormGroup name="biddingFloor">
          <BiddingFloorFields
            name="biddingFloor"
            values={values.biddingFloor}
            disabled={disabled}
            clientKey="min_cpm_value"
            labelKey="ads.configuration.impressionRestrictionsConfigurationAttributes.biddingFloor"
          />
        </FormGroup>
      </LocalizedLabel>
    </>
  );
}
