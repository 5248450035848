import React, { useMemo, useState } from 'react';

import { fromClassNameToOptions } from '@services/enums';
import { useRouter } from '@tripledotstudios/react-core';
import {
  useQuery,
  useConfirm,
  useCurrentApplication,
} from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  PageHeader,
  Pagination,
  DuplicationModal,
} from '@pages/common';
import { AssetsRoutes } from '@pages/routes';
import Tooltip from '@controls/Tooltip';
import IconButton from '@controls/buttons';
import ReactTable from '@pages/common/ReactTable';

import Filter from './Filter';
import { ASSET_TYPE_HINT, ASSET_TYPE_LABEL } from './constants';
import ColumnsFactory from './ColumnsFactory';

const typeColumn = ({ assetTypeOptions }) => ({
  Header: () => (
    <Tooltip
      text={ASSET_TYPE_HINT}
    >
      {ASSET_TYPE_LABEL}
    </Tooltip>
  ),
  accessor: 'filteringTypesValues',
  disableSortBy: true,
  style: { width: '20em' },
  Cell: ({ row: { original: { filteringTypesValues } } }) => (
    filteringTypesValues.map((value) => assetTypeOptions.find((option) => option.value === value).label).join(', ')
  ),
});

export default function Index() {
  const { response, refetch } = useQuery(AssetsRoutes.Keys.indexRequest, { includeInUse: true });
  const { query } = useRouter();
  const { currentApplication, applicationName } = useCurrentApplication();
  const assetTypeOptions = fromClassNameToOptions(`${applicationName}::AssetTypes`);
  const confirm = useConfirm();

  const removalResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Asset Key',
    actionName: 'removal',
    refetch,
  });

  const handleKeyDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Asset Key '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => AssetsRoutes.Keys.deleteRequest({ id, applicationId: currentApplication.id })
        .then(removalResponseHandler));
  };

  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const onDuplicate = (values) => (
    AssetsRoutes.Keys.duplicateRequest({ ...values, applicationId: currentApplication.id })
  );
  const columnList = ColumnsFactory({ currentApplication, handleKeyDelete, setDuplicatedRecord });
  if (assetTypeOptions.length) {
    columnList.splice(2, 0, typeColumn({ assetTypeOptions }));
  }
  const columns = useMemo(() => columnList, []);

  return response && (
    <>
      <PageHeader title="Asset Keys" filter={<Filter assetTypeOptions={assetTypeOptions} />}>
        <IconButton.New to={AssetsRoutes.Keys.newPath(query)} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
      />
      <Pagination
        pageCount={response._meta.pages || 1}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Asset Key"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={onDuplicate}
        name="uid"
      />
    </>
  );
}
