/* eslint-disable react/destructuring-assignment */
import React, { memo, useContext, useState } from 'react';
import { isEqual, omit } from 'lodash';
import {
  Accordion, useAccordionButton, Row, Col,
} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { SelectField, Label } from '@controls/form';
import { generateChapterBasedRoutes } from '@pages/routes';

import APP_DATA from '@services/appData';

import { FormGroup, useCurrentApplication } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';

const LevelsContainer = styled.div`
  display: flex;
`;

const FullWidthAccordion = styled(Accordion)`
  flex-grow: 1;
`;

const LevelsTable = styled.div`
  padding: 10px 0;
  flex-grow: 1;
`;

const Level = styled.div`
  border: 1px solid lightgray;
  display: flex;
  flex-flow: column;
  margin-bottom: 1px;
  padding: 5px;
`;

const LevelRow = styled.div`
  display: flex;
`;

const LevelsHeader = styled.div`
  font-weight: bold;
  display: flex;
  margin-bottom: 10px;
`;

const Position = styled.div`
  padding: 0 5px;
  width: 5%;
`;

const LevelName = styled.div`
  padding: 0 5px;
  width: 22%;
`;

const LevelHash = styled.div`
  padding: 0 5px;
  width: 8%;
`;

const LevelType = styled.div`
  padding: 0 5px;
  width: 10%;
`;

const ModeConfig = styled.div`
  padding: 0 5px;
  width: 15%;
`;

const VisualsConfig = styled.div`
  padding: 0 5px;
  width: 10%;
`;

const Actions = styled.div`
  padding: 0 5px;
  width: 5%;
`;

const TextValueCell = styled.div`
  padding-top: 8px;
`;

const LevelHashValue = styled(TextValueCell)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AccordionButton = ({ eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;

  return (
    <FontAwesomeIcon
      size="lg"
      icon={faChevronDown}
      style={isCurrentEventKey && { transform: 'rotate(180deg)' }}
      onClick={decoratedOnClick}
    />
  );
};

const MemoizedLevel = memo(({
  position,
  levelLayoutsOptions,
  dispatch,
  chapterPath,
  applicationId,
  applicationName,
  levelLayoutId,
  levelLayout,
  modesConfigsOptions,
  supportsModes,
  levelIndex,
  visualizationTypeOptions,
  levelsTotal,
}) => {
  const LevelLayoutRoutes = generateChapterBasedRoutes(applicationName).LevelLayouts;
  const calculatedPosition = levelsTotal + position;

  return (
    <Level>
      <LevelRow>
        <Position>
          <TextValueCell>
            {calculatedPosition}
          </TextValueCell>
        </Position>
        <LevelName>
          <SelectField
            name="levelLayoutId"
            options={levelLayoutsOptions}
            onChange={() => dispatch({ actionType: 'changeLevelLayout', chapterPath, levelIndex })}
          />
        </LevelName>
        <LevelHash>
          <LevelHashValue title={levelLayout.contentHash}>
            {levelLayout.contentHash}
          </LevelHashValue>
        </LevelHash>
        <LevelType>
          {levelLayout.tileTypeHumanize}
        </LevelType>
        <ModeConfig>
          {(supportsModes) && (
            <SelectField
              name="modeConfigId"
              options={modesConfigsOptions}
              includeEmpty
            />
          )}
        </ModeConfig>
        <VisualsConfig>
          <SelectField
            name="visualizationType"
            options={visualizationTypeOptions}
          />
        </VisualsConfig>
        <Actions>
          <TextValueCell>
            {levelLayoutId && (
              <Link
                to={LevelLayoutRoutes.editPath({ applicationId, id: levelLayoutId })}
                target="_blank"
                title="See layout page in separate tab"
              >
                <FontAwesomeIcon size="lg" icon={faFileAlt} />
              </Link>
            )}
          </TextValueCell>
        </Actions>
      </LevelRow>
    </Level>
  );
}, (prev, next) => isEqual(omit(prev, ['dispatch']), omit(next, ['dispatch'])));

const tileTypes = APP_DATA.enums['Tile::TileTypes'];

const Levels = ({
  chapter, chapterPath, dispatch, levelLayouts, modesConfigs, banks, levelsTotal,
}) => {
  const { applicationName, currentApplication } = useCurrentApplication();
  const [expanded, setExpanded] = useState(chapter.populated || false);
  const visualizationTypeOptions = fromClassNameToOptions('Tile::Level::VisualizationTypes');

  const tileTypesSupportingMode = [tileTypes.GOALS, tileTypes.TELEPORTING];

  const levels = chapter.levelsAttributes || [];
  const levelLayoutsOptions = levelLayouts.map((layout) => {
    const bankName = banks.find(({ value }) => value === layout.bankId)?.label;

    return ({
      label: `${layout.name} (${layout.position}) - ${bankName}`,
      value: layout.id,
    });
  });

  const selectedLayoutIds = levels.map(({ levelLayoutId }) => levelLayoutId);
  const selectedLevelLayouts = levelLayouts.filter(({ id }) => selectedLayoutIds.includes(id));

  const renderGlobalSchemaSelect = (type) => selectedLevelLayouts.some(({ tileType }) => tileType === type);
  const filterModesConfigsOptions = (type) => modesConfigs.filter(({ tileType }) => tileType === type);

  return (
    <LevelsContainer>
      {levels.length ? (
        <FullWidthAccordion defaultActiveKey={chapter.populated && '0'} onSelect={() => setExpanded(!expanded)}>
          <AccordionButton eventKey="0" />
          <hr />
          {(chapter.populated || expanded) && (
            <Accordion.Collapse eventKey="0">
              <>
                <Row>
                  {(renderGlobalSchemaSelect(tileTypes.GOALS)) && (
                    <Col xs={3}>
                      <Label text="Global Goals Schema" direction="vertical">
                        <SelectField
                          name="goalsModeConfigId"
                          options={filterModesConfigsOptions(tileTypes.GOALS)}
                          includeEmpty
                        />
                      </Label>
                    </Col>
                  )}

                  {(renderGlobalSchemaSelect(tileTypes.TELEPORTING)) && (
                    <Col xs={3}>
                      <Label text="Global Teleporting Schema" direction="vertical">
                        <SelectField
                          name="teleportingModeConfigId"
                          options={filterModesConfigsOptions(tileTypes.TELEPORTING)}
                          includeEmpty
                        />
                      </Label>
                    </Col>
                  )}
                </Row>

                <LevelsTable>
                  <LevelsHeader>
                    <Position>
                      Level
                    </Position>
                    <LevelName>
                      Layout to use
                    </LevelName>
                    <LevelHash>
                      Layout hash
                    </LevelHash>
                    <LevelType>
                      Layout Type
                    </LevelType>
                    <ModeConfig>
                      Mode Config
                    </ModeConfig>
                    <VisualsConfig>
                      Visuals
                    </VisualsConfig>
                  </LevelsHeader>
                  {levels.map((level, levelIndex) => {
                    const {
                      levelLayoutId,
                      position,
                      _destroy,
                      _uuid,
                    } = level;
                    const levelLayout = levelLayouts.find(({ id }) => (levelLayoutId === id)) || {};
                    const supportsModes = tileTypesSupportingMode.includes(levelLayout.tileType);
                    const modesConfigsOptions = filterModesConfigsOptions(levelLayout.tileType);
                    const groupName = `levelsAttributes[${levelIndex}]`;

                    return !_destroy && (
                      <FormGroup name={groupName} key={_uuid}>
                        <MemoizedLevel
                          {...{
                            applicationId: currentApplication.id,
                            applicationName,
                            chapterPath,
                            dispatch,
                            levelLayoutsOptions,
                            level,
                            levelLayoutId,
                            levelLayout,
                            position,
                            modesConfigsOptions,
                            supportsModes,
                            levelIndex,
                            visualizationTypeOptions,
                            levelsTotal,
                          }}
                        />
                      </FormGroup>
                    );
                  })}
                </LevelsTable>
              </>
            </Accordion.Collapse>
          )}
        </FullWidthAccordion>
      ) : ''}
    </LevelsContainer>
  );
};

export default Levels;
