import React from 'react';

import { useQuery } from '@hooks';
import { TileRoutes } from '@pages/routes';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import Form from './Form';

const BanksNew = () => {
  const { response, setResponse } = useQuery(TileRoutes.DifficultySchemas.newRequest);
  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'DDA schema',
    actionName: 'creation',
    setData: setResponse,
  });

  const onSubmit = (values) => TileRoutes.DifficultySchemas.createRequest(values)
    .then((data) => responseHandler(data, values));

  return response && <Form data={response} onSubmit={onSubmit} setData={setResponse} />;
};

export default BanksNew;
