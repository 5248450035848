import React, { useState, useEffect } from 'react';
import {
  Button, Row, Col, Table,
} from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { FormGroup, useFormContext } from '@hooks';
import {
  Label, Field, ServerError,
} from '@controls/form';
import { ItemsSelectField } from '@components/merge/shared/items/Select';

import { RemoveIcon } from '@components/merge/shared/icons';
import Th from '@components/merge/shared/Th';

export default function TransformsInto({
  path, schemaRequest, values, disabled,
}) {
  const { query } = useRouter();
  const [items, setItems] = useState();
  const { dispatch } = useFormContext();

  useEffect(() => {
    schemaRequest({
      id: values.schemaId,
      applicationId: query.applicationId,
    }).then((response) => {
      setItems(response.data.items.map((item) => ({ value: item.id, label: item.internalId, line: item.line })));
    });
  }, [values.schemaId]);

  return (
    <>
      <Label text="Bubbling Timeout">
        <Field name="bubblingTimeout" type="number" />
      </Label>

      <Row>
        <Col xs={10}>
          <b>Transforms Into Items</b>
          <ServerError name="transformsInto" />

          <Table className="mt-2">
            <thead>
              <tr>
                <Th width="10%" />
                <Th width="40%">Item</Th>
                <Th width="40%">Probability</Th>
                <Th width="10%" />
              </tr>
            </thead>
            <tbody>
              {values.transformsInto.map((record, index) => (
                <FormGroup key={record._uuid} name={`transformsInto[${index}]`}>
                  <tr>
                    <td>{index + 1}</td>
                    <td><ItemsSelectField name="schemaItemId" items={items} className="me-1" /></td>
                    <td><Field name="probability" type="number" step="0.1" /></td>
                    <td>
                      <RemoveIcon disabled={disabled} onClick={() => dispatch({ path, type: 'removeItem', index })} />
                    </td>
                  </tr>
                </FormGroup>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Button
        disabled={disabled}
        variant="success"
        className="mb-2"
        onClick={() => dispatch({ path, type: 'addItem' })}
      >
        Add
      </Button>
    </>
  );
}
