import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { create2048Config, new2048Config } from '@requests/puzzle-time/configs2048';

import fieldsConfiguration from './FieldsConfiguration';
import Form from '../GameConfigsForm';

export default function New() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const indexPath = `/admin/applications/${query.applicationId}/puzzle_time/configs_2048`;

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: '2048 Config',
    actionName: 'creation',
    redirectPath: indexPath,
    setData,
  });

  const onSubmit = (values) => create2048Config(query, values).then(redirectIfSuccessHandler);

  useEffect(() => {
    new2048Config(query).then((response) => setData(response.data));
  }, []);

  return data ? (
    <Form
      data={data}
      onSubmit={onSubmit}
      fieldsConfiguration={fieldsConfiguration}
      actionName="Create"
      indexPath={indexPath}
      configName="2048 Config"
    />
  ) : '';
}
