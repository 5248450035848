import React from 'react';
import { Row, Col } from 'react-bootstrap';

import {
  Label,
  Field,
  MultipleValuesField,
} from '@controls/form';
import { PageSection } from '@pages/common';
import Tooltip from '@controls/Tooltip';

const TileTypePercentInput = ({
  name, index, className, ...rest
}) => (
  <Label text={`Type ${index + 1}`} className="justify-content-between">
    <Field type="number" name={name} appendText="%" className={className} {...rest} />
  </Label>
);

export default function ModesConfigsGoalsFields({ tooltips }) {
  const goalsTooltips = tooltips.goals;

  return (
    <>
      <PageSection title="Goals Schema" />

      <Label text="Percent of tiles participating in Goals" tooltipText={goalsTooltips.tilesPercent}>
        <Field type="number" name="tilesPercent" appendText="%" />
      </Label>

      <PageSection title="Participating tile types" tooltip={goalsTooltips.tileTypesPercents}>
        <Row className="justify-content-between">
          <Col xs={2}>
            <span className="fw-bold">Tile Type</span>
          </Col>

          <Col xs={10}>
            <span className="fw-bold">
              Goal Weight
              <Tooltip text={goalsTooltips.tileTypePercent} />
            </span>
          </Col>
        </Row>
        <p />

        <MultipleValuesField
          name="tileTypesPercents"
          InputComponent={TileTypePercentInput}
          inputSize={4}
          removeButtonSize={8}
          removeButtonClassName="float-start mt-2"
          handleCollectionErrors={false}
        />
      </PageSection>
    </>
  );
}
