import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { StreaksRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const [data, setData] = useState(null);
  const { query } = useRouter();
  const { applicationId } = query;
  const { indexPath, newRequest, createRequest } = StreaksRoutes.Entities;

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Streak',
    actionName: 'create',
    redirectPath: indexPath({ applicationId }),
    setData,
  });

  const onSubmit = (values) => createRequest({ ...values, applicationId }).then(responseHandler);

  useEffect(() => {
    newRequest(query).then((response) => setData(response.data));
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} entityName="Streak" actionName="Create" />;
}
