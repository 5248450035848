import React from 'react';
import { PageTitle } from '@pages/common';
import { Route } from 'react-router-dom';

import RouteContainer from '@pages/RouteContainer';

import FigureSetsSetsIndex from '@pages/figure-sets/sets/Index';
import FigureSetsSetsNew from '@pages/figure-sets/sets/New';
import FigureSetsSetsEdit from '@pages/figure-sets/sets/Edit';

import FigureSetsShapesIndex from '@pages/figure-sets/shapes/Index';
import FigureSetsShapesNew from '@pages/figure-sets/shapes/New';
import FigureSetsShapesEdit from '@pages/figure-sets/shapes/Edit';

import { FigureSetsRoutes as Routes } from '@pages/routes';

export default function figureSets() {
  return [
    // Sets
    <Route
      key={Routes.Sets.indexRawPath}
      path={Routes.Sets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Figure Sets" />
          <FigureSetsSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Sets.newRawPath}
      path={Routes.Sets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Figure Set" action="New" />
          <FigureSetsSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Sets.editRawPath}
      path={Routes.Sets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Figure Set" action="Edit" />
          <FigureSetsSetsEdit />
        </RouteContainer>
      )}
    />,

    // Shapes
    <Route
      key={Routes.Shapes.indexRawPath}
      path={Routes.Shapes.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Figure Set Shapes" />
          <FigureSetsShapesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Shapes.newRawPath}
      path={Routes.Shapes.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Figure Set Shape" action="New" />
          <FigureSetsShapesNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Shapes.editRawPath}
      path={Routes.Shapes.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Figure Set Shape" action="Edit" />
          <FigureSetsShapesEdit />
        </RouteContainer>
      )}
    />,
  ];
}
