const languages = [
  {
    label: 'Abkhazian (AB)',
    value: 'ab',
  },
  {
    label: 'Afar (AA)',
    value: 'aa',
  },
  {
    label: 'Afrikaans (AF)',
    value: 'af',
  },
  {
    label: 'Akan (AK)',
    value: 'ak',
  },
  {
    label: 'Albanian (SQ)',
    value: 'sq',
  },
  {
    label: 'Amharic (AM)',
    value: 'am',
  },
  {
    label: 'Arabic (AR)',
    value: 'ar',
  },
  {
    label: 'Arabic/Algeria (AR-DZ)',
    value: 'ar-DZ',
  },
  {
    label: 'Arabic/Bahrain (AR-BH)',
    value: 'ar-BH',
  },
  {
    label: 'Arabic/Egypt (AR-EG)',
    value: 'ar-EG',
  },
  {
    label: 'Arabic/Iraq (AR-IQ)',
    value: 'ar-IQ',
  },
  {
    label: 'Arabic/Jordan (AR-JO)',
    value: 'ar-JO',
  },
  {
    label: 'Arabic/Kuwait (AR-KW)',
    value: 'ar-KW',
  },
  {
    label: 'Arabic/Lebanon (AR-LB)',
    value: 'ar-LB',
  },
  {
    label: 'Arabic/Libya (AR-LY)',
    value: 'ar-LY',
  },
  {
    label: 'Arabic/Morocco (AR-MA)',
    value: 'ar-MA',
  },
  {
    label: 'Arabic/Oman (AR-OM)',
    value: 'ar-OM',
  },
  {
    label: 'Arabic/Qatar (AR-QA)',
    value: 'ar-QA',
  },
  {
    label: 'Arabic/Saudi Arabia (AR-SA)',
    value: 'ar-SA',
  },
  {
    label: 'Arabic/Syria (AR-SY)',
    value: 'ar-SY',
  },
  {
    label: 'Arabic/Tunisia (AR-TN)',
    value: 'ar-TN',
  },
  {
    label: 'Arabic/U.A.E. (AR-AE)',
    value: 'ar-AE',
  },
  {
    label: 'Arabic/Yemen (AR-YE)',
    value: 'ar-YE',
  },
  {
    label: 'Aragonese (AN)',
    value: 'an',
  },
  {
    label: 'Armenian (HY)',
    value: 'hy',
  },
  {
    label: 'Assamese (AS)',
    value: 'as',
  },
  {
    label: 'Avaric (AV)',
    value: 'av',
  },
  {
    label: 'Avestan (AE)',
    value: 'ae',
  },
  {
    label: 'Aymara (AY)',
    value: 'ay',
  },
  {
    label: 'Azerbaijani (AZ)',
    value: 'az',
  },
  {
    label: 'Bambara (BM)',
    value: 'bm',
  },
  {
    label: 'Bashkir (BA)',
    value: 'ba',
  },
  {
    label: 'Basque (EU)',
    value: 'eu',
  },
  {
    label: 'Basque/Spain (EU-ES)',
    value: 'eu-ES',
  },
  {
    label: 'Belarusian (BE)',
    value: 'be',
  },
  {
    label: 'Bengali (BN)',
    value: 'bn',
  },
  {
    label: 'Bihari (BH)',
    value: 'bh',
  },
  {
    label: 'Bislama (BI)',
    value: 'bi',
  },
  {
    label: 'Bosnian (BS)',
    value: 'bs',
  },
  {
    label: 'Breton (BR)',
    value: 'br',
  },
  {
    label: 'Bulgariaa (BG)',
    value: 'bg',
  },
  {
    label: 'Burmese (MY)',
    value: 'my',
  },
  {
    label: 'Catalan (CA)',
    value: 'ca',
  },
  {
    label: 'Chamorro (CH)',
    value: 'ch',
  },
  {
    label: 'Chechen (CE)',
    value: 'ce',
  },
  {
    label: 'Chichewa (NY)',
    value: 'ny',
  },
  {
    label: 'Chinese/General (ZH)',
    value: 'zh',
  },
  {
    label: 'Chinese/Hans (ZH-HANS)',
    value: 'zh-Hans',
  },
  {
    label: 'Chinese/Simplified (ZH-CN)',
    value: 'zh-CN',
  },
  {
    label: 'Chinese/Traditional (ZH-TW)',
    value: 'zh-TW',
  },
  {
    label: 'Chuvash (CV)',
    value: 'cv',
  },
  {
    label: 'Cornish (KW)',
    value: 'kw',
  },
  {
    label: 'Corsican (CO)',
    value: 'co',
  },
  {
    label: 'Cree (CR)',
    value: 'cr',
  },
  {
    label: 'Croatian (HR)',
    value: 'hr',
  },
  {
    label: 'Croatian/Bosnia and Herzegovina (HR-BA)',
    value: 'hr-BA',
  },
  {
    label: 'Czech (CS)',
    value: 'cs',
  },
  {
    label: 'Danish (DA)',
    value: 'da',
  },
  {
    label: 'Divehi (DV)',
    value: 'dv',
  },
  {
    label: 'Dutch (NL)',
    value: 'nl',
  },
  {
    label: 'Dutch/Belgium (NL-BE)',
    value: 'nl-BE',
  },
  {
    label: 'Dutch/Netherlands (NL-NL)',
    value: 'nl-NL',
  },
  {
    label: 'Dzongkha (DZ)',
    value: 'dz',
  },
  {
    label: 'English (EN)',
    value: 'en',
  },
  {
    label: 'English/Australia (EN-AU)',
    value: 'en-AU',
  },
  {
    label: 'English/Belize (EN-BZ)',
    value: 'en-BZ',
  },
  {
    label: 'English/Canada (EN-CA)',
    value: 'en-CA',
  },
  {
    label: 'English/Caribbean (EN-CB)',
    value: 'en-CB',
  },
  {
    label: 'English/Ireland (EN-IE)',
    value: 'en-IE',
  },
  {
    label: 'English/Jamaica (EN-JM)',
    value: 'en-JM',
  },
  {
    label: 'English/New Zealand (EN-NZ)',
    value: 'en-NZ',
  },
  {
    label: 'English/Republic of the Philippines (EN-PH)',
    value: 'en-PH',
  },
  {
    label: 'English/South Africa (EN-ZA)',
    value: 'en-ZA',
  },
  {
    label: 'English/Trinidad (EN-TT)',
    value: 'en-TT',
  },
  {
    label: 'English/United Kingdom (EN-GB)',
    value: 'en-GB',
  },
  {
    label: 'English/United States (EN-US)',
    value: 'en-US',
  },
  {
    label: 'English/Zimbabwe (EN-ZW)',
    value: 'en-ZW',
  },
  {
    label: 'Esperanto (EO)',
    value: 'eo',
  },
  {
    label: 'Estonian (ET)',
    value: 'et',
  },
  {
    label: 'Ewe (EE)',
    value: 'ee',
  },
  {
    label: 'Faeroese (FO)',
    value: 'fo',
  },
  {
    label: 'Fijian (FJ)',
    value: 'fj',
  },
  {
    label: 'Finnish (FI)',
    value: 'fi',
  },
  {
    label: 'French (FR)',
    value: 'fr',
  },
  {
    label: 'French/Belgium (FR-BE)',
    value: 'fr-BE',
  },
  {
    label: 'French/Canada (FR-CA)',
    value: 'fr-CA',
  },
  {
    label: 'French/France (FR-FR)',
    value: 'fr-FR',
  },
  {
    label: 'French/Luxembourg (FR-LU)',
    value: 'fr-LU',
  },
  {
    label: 'French/Principality of Monaco (FR-MC)',
    value: 'fr-MC',
  },
  {
    label: 'French/Switzerland (FR-CH)',
    value: 'fr-CH',
  },
  {
    label: 'Fulah (FF)',
    value: 'ff',
  },
  {
    label: 'Galician (GL)',
    value: 'gl',
  },
  {
    label: 'Galician/Spain (GL-ES)',
    value: 'gl-ES',
  },
  {
    label: 'Georgian (KA)',
    value: 'ka',
  },
  {
    label: 'German (DE)',
    value: 'de',
  },
  {
    label: 'German/Austria (DE-AT)',
    value: 'de-AT',
  },
  {
    label: 'German/Germany (DE-DE)',
    value: 'de-DE',
  },
  {
    label: 'German/Liechtenstein (DE-LI)',
    value: 'de-LI',
  },
  {
    label: 'German/Luxembourg (DE-LU)',
    value: 'de-LU',
  },
  {
    label: 'German/Switzerland (DE-CH)',
    value: 'de-CH',
  },
  {
    label: 'Greek (EL)',
    value: 'el',
  },
  {
    label: 'Guaraní (GN)',
    value: 'gn',
  },
  {
    label: 'Gujarati (GU)',
    value: 'gu',
  },
  {
    label: 'Haitian (HT)',
    value: 'ht',
  },
  {
    label: 'Hausa (HA)',
    value: 'ha',
  },
  {
    label: 'Hebrew (HE)',
    value: 'he',
  },
  {
    label: 'Herero (HZ)',
    value: 'hz',
  },
  {
    label: 'Hindi (HI)',
    value: 'hi',
  },
  {
    label: 'Hiri Motu (HO)',
    value: 'ho',
  },
  {
    label: 'Hungarian (HU)',
    value: 'hu',
  },
  {
    label: 'Interlingua (IA)',
    value: 'ia',
  },
  {
    label: 'Indonesian (ID)',
    value: 'id',
  },
  {
    label: 'Interlingue (IE)',
    value: 'ie',
  },
  {
    label: 'Irish (GA)',
    value: 'ga',
  },
  {
    label: 'Igbo (IG)',
    value: 'ig',
  },
  {
    label: 'Inupiaq (IK)',
    value: 'ik',
  },
  {
    label: 'Ido (IO)',
    value: 'io',
  },
  {
    label: 'Icelandic (IS)',
    value: 'is',
  },
  {
    label: 'Italian (IT)',
    value: 'it',
  },
  {
    label: 'Italian/Italy (IT-IT)',
    value: 'it-IT',
  },
  {
    label: 'Italian/Switzerland (IT-CH)',
    value: 'it-CH',
  },
  {
    label: 'Inuktitut (IU)',
    value: 'iu',
  },
  {
    label: 'Japanese (JA)',
    value: 'ja',
  },
  {
    label: 'Javanese (JV)',
    value: 'jv',
  },
  {
    label: 'Kalaallisut (KL)',
    value: 'kl',
  },
  {
    label: 'Kannada (KN)',
    value: 'kn',
  },
  {
    label: 'Kanuri (KR)',
    value: 'kr',
  },
  {
    label: 'Kashmiri (KS)',
    value: 'ks',
  },
  {
    label: 'Kazakh (KK)',
    value: 'kk',
  },
  {
    label: 'Central Khmer (KM)',
    value: 'km',
  },
  {
    label: 'Kikuyu (KI)',
    value: 'ki',
  },
  {
    label: 'Kinyarwanda (RW)',
    value: 'rw',
  },
  {
    label: 'Kirghiz (KY)',
    value: 'ky',
  },
  {
    label: 'Komi (KV)',
    value: 'kv',
  },
  {
    label: 'Kongo (KG)',
    value: 'kg',
  },
  {
    label: 'Korean (KO)',
    value: 'ko',
  },
  {
    label: 'Kurdish (KU)',
    value: 'ku',
  },
  {
    label: 'Kuanyama (KJ)',
    value: 'kj',
  },
  {
    label: 'Latin (LA)',
    value: 'la',
  },
  {
    label: 'Luxembourgish (LB)',
    value: 'lb',
  },
  {
    label: 'Ganda (LG)',
    value: 'lg',
  },
  {
    label: 'Limburgan (LI)',
    value: 'li',
  },
  {
    label: 'Lingala (LN)',
    value: 'ln',
  },
  {
    label: 'Lao (LO)',
    value: 'lo',
  },
  {
    label: 'Latvian (LV)',
    value: 'lv',
  },
  {
    label: 'Luba-Katanga (LU)',
    value: 'lu',
  },
  {
    label: 'Lithuanian (LT)',
    value: 'lt',
  },
  {
    label: 'Manx (GV)',
    value: 'gv',
  },
  {
    label: 'Macedonian (MK)',
    value: 'mk',
  },
  {
    label: 'Malagasy (MG)',
    value: 'mg',
  },
  {
    label: 'Malay (MS)',
    value: 'ms',
  },
  {
    label: 'Malay/Brunei Darussalam (MS-BN)',
    value: 'ms-BN',
  },
  {
    label: 'Malay/Malaysia (MS-MY)',
    value: 'ms-MY',
  },
  {
    label: 'Malayalam (ML)',
    value: 'ml',
  },
  {
    label: 'Maltese (MT)',
    value: 'mt',
  },
  {
    label: 'Maori (MI)',
    value: 'mi',
  },
  {
    label: 'Marathi (MR)',
    value: 'mr',
  },
  {
    label: 'Marshallese (MH)',
    value: 'mh',
  },
  {
    label: 'Mongolian (MN)',
    value: 'mn',
  },
  {
    label: 'Nauru (NA)',
    value: 'na',
  },
  {
    label: 'Navajo (NV)',
    value: 'nv',
  },
  {
    label: 'North Ndebele (ND)',
    value: 'nd',
  },
  {
    label: 'Nepali (NE)',
    value: 'ne',
  },
  {
    label: 'Ndonga (NG)',
    value: 'ng',
  },
  {
    label: 'Northern Sotho (NS)',
    value: 'ns',
  },
  {
    label: 'Norwegian (NB)',
    value: 'nb',
  },
  {
    label: 'Norwegian/Nynorsk (NN)',
    value: 'nn',
  },
  {
    label: 'Sichuan Yi (II)',
    value: 'ii',
  },
  {
    label: 'South Ndebele (NR)',
    value: 'nr',
  },
  {
    label: 'Occitan (OC)',
    value: 'oc',
  },
  {
    label: 'Ojibwa (OJ)',
    value: 'oj',
  },
  {
    label: 'Church Slavic (CU)',
    value: 'cu',
  },
  {
    label: 'Oromo (OM)',
    value: 'om',
  },
  {
    label: 'Oriya (OR)',
    value: 'or',
  },
  {
    label: 'Ossetian (OS)',
    value: 'os',
  },
  {
    label: 'Pali (PI)',
    value: 'pi',
  },
  {
    label: 'Pashto (PS)',
    value: 'ps',
  },
  {
    label: 'Persian (FA)',
    value: 'fa',
  },
  {
    label: 'Polish (PL)',
    value: 'pl',
  },
  {
    label: 'Portuguese (PT)',
    value: 'pt',
  },
  {
    label: 'Portuguese/Brazil (PT-BR)',
    value: 'pt-BR',
  },
  {
    label: 'Portuguese/Portugal (PT-PT)',
    value: 'pt-PT',
  },
  {
    label: 'Punjabi (PA)',
    value: 'pa',
  },
  {
    label: 'Quechua (QU)',
    value: 'qu',
  },
  {
    label: 'Quechua/Bolivia (QU-BO)',
    value: 'qu-BO',
  },
  {
    label: 'Quechua/Ecuador (QU-EC)',
    value: 'qu-EC',
  },
  {
    label: 'Quechua/Peru (QU-PE)',
    value: 'qu-PE',
  },
  {
    label: 'Rhaeto-Romanic (RM)',
    value: 'rm',
  },
  {
    label: 'Romanian (RO)',
    value: 'ro',
  },
  {
    label: 'Rundi (RN)',
    value: 'rn',
  },
  {
    label: 'Russian (RU)',
    value: 'ru',
  },
  {
    label: 'Russian/Republic of Moldova (RU-MO)',
    value: 'ru-MO',
  },
  {
    label: 'Sanskrit (SA)',
    value: 'sa',
  },
  {
    label: 'Sardinian (SC)',
    value: 'sc',
  },
  {
    label: 'Sindhi (SD)',
    value: 'sd',
  },
  {
    label: 'Northern Sami (SE)',
    value: 'se',
  },
  {
    label: 'Samoan (SM)',
    value: 'sm',
  },
  {
    label: 'Sango (SG)',
    value: 'sg',
  },
  {
    label: 'Serbian (SR)',
    value: 'sr',
  },
  {
    label: 'Serbian/Bosnia and Herzegovina (SR-BA)',
    value: 'sr-BA',
  },
  {
    label: 'Serbian/Serbia and Montenegro (SR-SP)',
    value: 'sr-SP',
  },
  {
    label: 'Scottish Gaelic (GD)',
    value: 'gd',
  },
  {
    label: 'Shona (SN)',
    value: 'sn',
  },
  {
    label: 'Sinhala (SI)',
    value: 'si',
  },
  {
    label: 'Slovak (SK)',
    value: 'sk',
  },
  {
    label: 'Slovenian (SL)',
    value: 'sl',
  },
  {
    label: 'Somali (SO)',
    value: 'so',
  },
  {
    label: 'Southern Sotho (ST)',
    value: 'st',
  },
  {
    label: 'Spanish (ES)',
    value: 'es',
  },
  {
    label: 'Spanish/Argentina (ES-AR)',
    value: 'es-AR',
  },
  {
    label: 'Spanish/Bolivia (ES-BO)',
    value: 'es-BO',
  },
  {
    label: 'Spanish/Chile (ES-CL)',
    value: 'es-CL',
  },
  {
    label: 'Spanish/Colombia (ES-CO)',
    value: 'es-CO',
  },
  {
    label: 'Spanish/Costa Rica (ES-CR)',
    value: 'es-CR',
  },
  {
    label: 'Spanish/Dominican Republic (ES-DO)',
    value: 'es-DO',
  },
  {
    label: 'Spanish/Ecuador (ES-EC)',
    value: 'es-EC',
  },
  {
    label: 'Spanish/El Salvador (ES-SV)',
    value: 'es-SV',
  },
  {
    label: 'Spanish/Guatemala (ES-GT)',
    value: 'es-GT',
  },
  {
    label: 'Spanish/Honduras (ES-HN)',
    value: 'es-HN',
  },
  {
    label: 'Spanish/Mexico (ES-MX)',
    value: 'es-MX',
  },
  {
    label: 'Spanish/Nicaragua (ES-NI)',
    value: 'es-NI',
  },
  {
    label: 'Spanish/Panama (ES-PA)',
    value: 'es-PA',
  },
  {
    label: 'Spanish/Paraguay (ES-PY)',
    value: 'es-PY',
  },
  {
    label: 'Spanish/Peru (ES-PE)',
    value: 'es-PE',
  },
  {
    label: 'Spanish/Puerto Rico (ES-PR)',
    value: 'es-PR',
  },
  {
    label: 'Spanish/Spain (ES-ES)',
    value: 'es-ES',
  },
  {
    label: 'Spanish/Uruguay (ES-UY)',
    value: 'es-UY',
  },
  {
    label: 'Spanish/Venezuela (ES-VE)',
    value: 'es-VE',
  },
  {
    label: 'Spanish/Latin Americas (ES-US)',
    value: 'es-US',
  },
  {
    label: 'Sundanese (SU)',
    value: 'su',
  },
  {
    label: 'Swahili (SW)',
    value: 'sw',
  },
  {
    label: 'Swati (SS)',
    value: 'ss',
  },
  {
    label: 'Swedish (SV)',
    value: 'sv',
  },
  {
    label: 'Swedish/Finland (SV-FI)',
    value: 'sv-FI',
  },
  {
    label: 'Swedish/Sweden (SV-SE)',
    value: 'sv-SE',
  },
  {
    label: 'Tamil (TA)',
    value: 'ta',
  },
  {
    label: 'Tatar (TT)',
    value: 'tt',
  },
  {
    label: 'Telugu (TE)',
    value: 'te',
  },
  {
    label: 'Tajik (TG)',
    value: 'tg',
  },
  {
    label: 'Thai (TH)',
    value: 'th',
  },
  {
    label: 'Tigrinya (TI)',
    value: 'ti',
  },
  {
    label: 'Tibetan (BO)',
    value: 'bo',
  },
  {
    label: 'Turkmen (TK)',
    value: 'tk',
  },
  {
    label: 'Tagalog (TL)',
    value: 'tl',
  },
  {
    label: 'Tswana (TN)',
    value: 'tn',
  },
  {
    label: 'Tonga (TO)',
    value: 'to',
  },
  {
    label: 'Turkish (TR)',
    value: 'tr',
  },
  {
    label: 'Tsonga (TS)',
    value: 'ts',
  },
  {
    label: 'Twi (TW)',
    value: 'tw',
  },
  {
    label: 'Tahitian (TY)',
    value: 'ty',
  },
  {
    label: 'Uighur (UG)',
    value: 'ug',
  },
  {
    label: 'Ukrainian (UK)',
    value: 'uk',
  },
  {
    label: 'Urdu (UR)',
    value: 'ur',
  },
  {
    label: 'Uzbek (UZ)',
    value: 'uz',
  },
  {
    label: 'Venda (VE)',
    value: 've',
  },
  {
    label: 'Vietnamese (VI)',
    value: 'vi',
  },
  {
    label: 'Volapük (VO)',
    value: 'vo',
  },
  {
    label: 'Walloon (WA)',
    value: 'wa',
  },
  {
    label: 'Welsh (CY)',
    value: 'cy',
  },
  {
    label: 'Wolof (WO)',
    value: 'wo',
  },
  {
    label: 'Frisian (FY)',
    value: 'fy',
  },
  {
    label: 'Xhosa (XH)',
    value: 'xh',
  },
  {
    label: 'Yiddish (YI)',
    value: 'yi',
  },
  {
    label: 'Yoruba (YO)',
    value: 'yo',
  },
  {
    label: 'Zhuang (ZA)',
    value: 'za',
  },
  {
    label: 'Zulu (ZU)',
    value: 'zu',
  },
];

export default languages;
