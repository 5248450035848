import { memoize } from 'lodash';

import { fieldGroupBuilder, fieldBuilder } from '@services/fields-factory';

const offlineGamesSettingsFields = memoize(() => fieldGroupBuilder(
  {
    name: 'offlineGames',
    fieldsConfiguration: {
      ...fieldBuilder({ name: 'offlineGameThreshold', type: 'number' }),
      ...fieldBuilder({ name: 'offlineGamePeriod', type: 'number' }),
    },
  },
));
export default offlineGamesSettingsFields;
