import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { VersionUpdateRoutes } from '@pages/routes';

export default function Edit({
  entityName,
  routesName,
  form: Form,
}) {
  const { updateRequest, editRequest } = VersionUpdateRoutes[routesName];
  const { response, setResponse } = useQuery(editRequest);
  const { query: { applicationId } } = useRouter();

  const responseHandler = noRedirectHandlerFactory({
    entityName,
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => updateRequest({ ...values, applicationId }).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
