import React from 'react';
import { faSuitcase, faUserGraduate, faVial } from '@fortawesome/free-solid-svg-icons';

import { VerticalTabs } from '@tripledotstudios/react-core';

import { PageHeader } from '@pages/common';

import SystemSettingsTabContent from './system/TabContent';
import AdminSettingsTabContent from './admin/TabContent';
import TesterSettingsTabContent from './tester/TabContent';

const SETTINGS = [
  {
    name: 'System',
    icon: faSuitcase,
    content: <SystemSettingsTabContent />,
  },
  {
    name: 'Admin',
    icon: faUserGraduate,
    content: <AdminSettingsTabContent />,
  },
  {
    name: 'Tester',
    icon: faVial,
    content: <TesterSettingsTabContent />,
  },
];

export default function Edit() {
  return (
    <>
      <PageHeader title="Settings" />
      <VerticalTabs collection={SETTINGS} />
    </>
  );
}
