import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const JsonContainer = styled.div`
  word-break: break-word;
  white-space: break-spaces;
`;

export default function RawVersionToggle({ children, version }) {
  const [rawMode, setRawMode] = useState(false);
  return (
    <>
      {rawMode ? (
        <JsonContainer>
          {JSON.stringify(version, null, 2) || ''}
        </JsonContainer>
      ) : children}
      <div>
        <Button onClick={() => setRawMode((mode) => !mode)} className="mt-2">
          {`Show ${rawMode ? 'tree' : 'raw'}`}
        </Button>
      </div>
    </>
  );
}
