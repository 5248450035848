import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { FormGroup } from '@hooks';

import { Field, ServerError } from '@controls/form';
import { RemoveButton } from '@root/pages/common';
import Select from '@root/controls/Select';
import { REWARDABLE_LIVE_OPS_TOKEN_CLASS } from './constants';

const ContentRow = styled(Row)`
  display: flex;
  margin-bottom: 5px;
  align-content: start;
`;

const ActionsCol = styled(Col)`
  display: flex;
  flex-flow: column;
  align-items: start;
  align-self: start;
`;

const Reward = React.memo(({
  dispatch,
  reward,
  rewardItems,
  readOnly,
  index,
  persistedRewardsCount,
  path,
}) => {
  const minRewards = 1;
  const remove = () => dispatch({ type: 'removeConfigReward', index, path });
  const selectedValue = rewardItems.find(({ value, rewardId }) => (
    reward.rewardType === REWARDABLE_LIVE_OPS_TOKEN_CLASS ? rewardId === reward.rewardId : value === reward.rewardType
  ))?.value;

  return (
    <div>
      {!reward._destroy && (
        <FormGroup key={reward._uuid}>
          <ContentRow>
            <Field name="id" type="hidden" disabled={readOnly} />
            <Col xs={6} className="pe-0 align-self-start">
              <Select
                selectedValue={selectedValue}
                options={rewardItems}
                onChange={(option) => dispatch({
                  type: 'setConfigReward', index, path, option,
                })}
              />
            </Col>
            <ActionsCol>
              <RemoveButton
                className="pt-2"
                onClick={remove}
                title="Delete the reward"
                disabled={readOnly || persistedRewardsCount <= minRewards}
              />
            </ActionsCol>
            <ServerError name={`configsRewardsAttributes[${index}]`} />
          </ContentRow>
        </FormGroup>
      )}
    </div>
  );
}, (prev, next) => isEqual(prev.rewardItems, next.rewardItems) && isEqual(prev.reward, next.reward));

export default Reward;
