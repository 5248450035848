import React from 'react';
import PropTypes from 'prop-types';

import TrailFields from '@pages/common/TrailFields';

import Label from './Label';

export default function FormTrails({ data, labelSize = 4 }) {
  return (
    <>
      {data.createdAt && (
        <Label text="Created at (UTC)" labelSize={labelSize}>
          <TrailFields prefix="created" record={data} />
        </Label>
      )}
      {data.updatedAt && (
        <Label text="Updated at (UTC)" labelSize={labelSize}>
          <TrailFields prefix="updated" record={data} />
        </Label>
      )}
    </>
  );
}

FormTrails.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
