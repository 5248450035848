import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { get } from 'lodash';

import { useFormContext } from '@hooks';
import CopyToClipboardButton from '@controls/CopyToClipboardButton';

import FileField from './FileField';

export default function ImageFileField({
  name, imageUrlFieldName = `${name}CdnUrl`, disabled, accept = 'image/jpeg, image/png, image/gif',
}) {
  const { values } = useFormContext();
  const imageCdnUrl = get(values, imageUrlFieldName);

  return (
    <>
      <FileField
        name={name}
        accept={accept}
        disabled={disabled}
        fullError
      />
      {imageCdnUrl && (
        <>
          <Row>
            <Col>
              {imageCdnUrl}
              <CopyToClipboardButton text={imageCdnUrl} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Image src={imageCdnUrl} rounded className="w-25" />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
