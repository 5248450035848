import React from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useI18n } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';

export default function Edit({
  form: Form,
  nameTranslationPath,
  routes,
}) {
  const { query: { id, applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(routes.editRequest, { id });
  const { translate } = useI18n();

  const entityName = translate.fallback(nameTranslationPath);

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName,
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => routes.updateRequest({ ...values, applicationId }).then(responseHandler);

  if (isLoading) return <Spinner />;

  return response && (
    <Form
      data={response}
      title={`Edit ${entityName}`}
      entityName={entityName}
      onSubmit={onSubmit}
    />
  );
}
