import React from 'react';

import { ChapterSetsNew } from '../../metagame/chapter-based';
import Chapters from './Chapters';
import formReducer from './formReducer';

const TileChapterSetsNew = (props) => (
  <ChapterSetsNew ChaptersComponent={Chapters} reducer={formReducer} {...props} />
);

export default TileChapterSetsNew;
