import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes } from '@pages/routes';
import Form from './Form';

export default function Edit({
  nameFieldLabel, requestSchemaOptions, routePostfix, schemaRequest, schemaType, schemaTypeName,
}) {
  const { response, setResponse } = useQuery(MergeRoutes.StartingGrids.editRequest);
  const { query } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Starting Grids',
    actionName: 'update',
    redirectPath: MergeRoutes.StartingGrids.indexPath({ applicationId: query.applicationId, routePostfix }),
    setData: setResponse,
  });

  const onSubmit = (values) => (
    MergeRoutes.StartingGrids
      .updateRequest({ ...values, applicationId: query.applicationId })
      .then(responseHandler)
  );

  return response && (
    <Form
      actionName="Update"
      data={response}
      nameFieldLabel={nameFieldLabel}
      onSubmit={onSubmit}
      requestSchemaOptions={requestSchemaOptions}
      routePostfix={routePostfix}
      schemaRequest={schemaRequest}
      schemaType={schemaType}
      schemaTypeName={schemaTypeName}
    />
  );
}
