import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UtcClock } from '@tripledotstudios/react-core';

import { fullCurrentEnvironment, shortCurrentEnvironment } from '@services/currentEnvironment';
import loadBrandConfig from '@config/navigationBrand';
import GameServerLogo from '@components/shared/GameServerLogo';

const EnvironmentBadge = styled.h6`
  border-radius: 1.5em;
  line-height: 1.8;
`;

const Logo = ({ minimized }) => (minimized ? (
  <div className="d-flex align-items-center flex-column">
    <GameServerLogo width="30" height="30" className="me-1 ms-1" />
    <UtcClock minimized style={{ fontSize: '10px' }} />
  </div>
) : (
  <div className="d-flex align-items-center flex-column">
    <div className="d-flex ">
      <GameServerLogo width="30" height="30" className="me-2" />
      <h3 className="fw-bold text-dark mb-0">Game Server</h3>
    </div>
    <UtcClock style={{ fontSize: '10px' }} />
  </div>
));

export default function Brand({ root, minimized = false }) {
  const config = loadBrandConfig();

  return (
    <>
      <Link to={root} className="d-flex align-items-center mx-auto mt-3 text-decoration-none">
        <Logo minimized={minimized} />
      </Link>

      {config && (
        <EnvironmentBadge
          className={`text-center text-uppercase mb-0 mt-3 text-${config.text} bg-${config.background}`}
        >
          {minimized ? shortCurrentEnvironment() : fullCurrentEnvironment()}
        </EnvironmentBadge>
      )}
    </>
  );
}
