import React, { useState, useMemo } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { WooAspenRoutes as Routes } from '@pages/routes';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  Filter,
  LabelsColumn,
  InUseColumn,
  DuplicationModal,
  InLiveColumn,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { useCurrentApplication, useQuery, useConfirm } from '@hooks';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

const columnsFactory = ({ applicationId, onDelete, setDuplicatedRecord }) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  LabelsColumn,
  InUseColumn,
  InLiveColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, inUse, name } } }) => {
      const disabled = isInUse(inUse);

      return (
        <ButtonToolbar>
          <IconButton.Edit minimized to={Routes.PlayOnPopUps.editPath({ id, applicationId })} />
          <IconButton.Duplicate minimized onClick={() => setDuplicatedRecord({ id, name })} />
          <IconButton.Delete
            minimized
            onClick={() => onDelete(id, name)}
            disabled={disabled}
            title={disabled ? 'Disabled as the entity is used' : null}
          />
        </ButtonToolbar>
      );
    },
  },
];

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const confirm = useConfirm();
  const { currentApplication } = useCurrentApplication();
  const { query: { applicationId } } = useRouter();
  const { response, refetch } = useQuery(Routes.PlayOnPopUps.indexRequest, { includeInUse: true });

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Play on Pop-up',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => {
    confirm.showConfirmation({
      title: `Play on Pop-up '${name}' will be deleted and not available for work. Continue?`,
    }).then(() => Routes.PlayOnPopUps.deleteRequest({ id, applicationId: currentApplication.id })
      .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({ applicationId, setDuplicatedRecord, onDelete }), []);

  return response && (
    <div>
      <PageHeader
        title="Play on Pop-ups"
        filter={<Filter defaultComponents={['name', 'availabilityStateIn', 'id', 'createdByMe', 'labels']} />}
      >
        <IconButton.New to={Routes.PlayOnPopUps.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <DuplicationModal
        record={duplicatedRecord}
        title="Duplicate Play on Pop-up"
        entityName="Play on Pop-up"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => Routes.PlayOnPopUps.duplicateRequest({ ...values, applicationId })}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
