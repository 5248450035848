import React from 'react';

import { WoodokuRoutes } from '@pages/routes';

import CreateUpdateConfig from './CreateUpdateConfig';

export default function Edit({ configTypes, group }) {
  return (
    <CreateUpdateConfig
      fetchFunction={WoodokuRoutes.XpLevelConfigs.editRequest}
      saveFunction={WoodokuRoutes.XpLevelConfigs.updateRequest}
      actionName="update"
      configTypes={configTypes}
      group={group}
      disableTypeSelect
    />
  );
}
