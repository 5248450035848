import React, { useEffect, useState } from 'react';

import { PageSection, Alert } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

// For now this feature exists in Woo::Aspen only.
import { WooAspenRoutes, RewardsRoutes } from '@root/pages/routes';
import { FormGroup, useCurrentApplication, useQuery } from '@hooks';
import { persistedCollection } from '@root/services/utils';
import { buildQuickLink } from '@root/services/fields-factory/quickLinksUtils';

import {
  Field,
  SelectField,
  ServerError,
  Label,
} from '@controls/form';
import IconButton from '@controls/buttons';

const { rewardTypes } = APP_DATA;

export default function LiveopsTokenContent({
  values,
  isInUse,
  dispatch,
  rewardItems,
}) {
  const { forbiddenChildrenIds } = values;
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const [containerItems, setContainerItems] = useState([]);

  const tokenQuickLinkFn = (focusedEntityId) => (
    WooAspenRoutes.LiveOpsTokens.indexPath({ applicationId, query: { focusedEntityId } })
  );

  const { response: liveopsTokensResponse } = useQuery(
    WooAspenRoutes.LiveOpsTokens.indexRequest,
    { applicationId },
  );

  useEffect(() => {
    const options = rewardItems
      .filter(({ id: respId, rewardType: itemRewardType }) => (
        !forbiddenChildrenIds.includes(respId) && rewardTypes[itemRewardType].type !== 'liveops_token'))
      .map(({
        id: itemId, content, data, name, displayRewardType,
      }) => ({
        content,
        data,
        label: `${name} (${displayRewardType})`,
        value: itemId,
      }));

    setContainerItems(options);
  }, [rewardItems]);

  if (!liveopsTokensResponse) return (<div />);

  const { items } = liveopsTokensResponse;

  const liveopsTokenOptions = items.map(({ id, name, key }) => ({ value: id, label: `${name} (${key})` }));

  return (
    <>
      <ServerError as={Alert} name="liveopsTokenAffiliationsAttributes" full />
      {values.liveopsTokenAffiliationsAttributes.map((affiliation, index, list) => (
        !affiliation._destroy && (
          <FormGroup name={`liveopsTokenAffiliationsAttributes[${index}]`} key={affiliation._uuid}>
            <div className="d-flex flex-row" style={{ gap: '1em' }}>
              <div style={{ flex: 7 }}>
                <Label
                  text={`Alternative ${persistedCollection(list).indexOf(affiliation) + 1}`}
                  direction="vertical"
                >
                  <SelectField
                    name="tokenId"
                    isDisabled={isInUse}
                    options={liveopsTokenOptions}
                    quickLinkFn={tokenQuickLinkFn}
                  />
                </Label>
              </div>

              <div style={{ flex: 1 }}>
                <Label text="Quantity" direction="vertical">
                  <Field name="quantity" type="number" disabled={isInUse} />
                </Label>
              </div>

              <div className="d-flex flex-column" style={{ flex: 1 }}>
                <Label text="" direction="vertical">
                  <IconButton.Delete
                    minimized
                    disabled={isInUse}
                    onClick={() => !isInUse && dispatch({
                      actionType: 'removeItem', index, path: 'liveopsTokenAffiliationsAttributes',
                    })}
                    className="mb-3"
                  >
                    Delete the item
                  </IconButton.Delete>
                </Label>
              </div>
            </div>
          </FormGroup>
        )
      ))}

      <IconButton.New
        disabled={isInUse}
        onClick={() => !isInUse && dispatch({ actionType: 'addItem', path: 'liveopsTokenAffiliationsAttributes' })}
      >
        Add a new item
      </IconButton.New>

      <PageSection title="Fallback Reward" className="mt-4" />
      <div className="d-flex flex-row" style={{ gap: '1em' }}>
        <div style={{ flex: 4 }}>
          <Label text="Item" direction="vertical">
            <SelectField
              name="fallbackRewardId"
              isDisabled={isInUse}
              options={containerItems}
              quickLinkFn={buildQuickLink(RewardsRoutes.Items, applicationId)}
            />
          </Label>
        </div>

        <div style={{ flex: 2 }}>
          <Label text="Quantity" direction="vertical">
            <Field name="fallbackRewardQuantity" type="number" disabled={isInUse} />
          </Label>
        </div>
      </div>
    </>
  );
}
