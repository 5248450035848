import React from 'react';

import { Field } from '@controls/form';

export default function NoPreloadedPowerupRule({ disabled }) {
  return (
    <Field
      type="text"
      name="value"
      disabled={disabled}
    />
  );
}
