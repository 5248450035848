import React from 'react';
import MultipleValuesField from '@controls/form/MultipleValuesField';
import Field from '@controls/form/Field';

export default function IdfaRule({ disabled }) {
  return (
    <MultipleValuesField
      name="idfa"
      disabled={disabled}
      InputComponent={Field}
    />
  );
}
