import { getRewardItems } from '@requests/rewards/items';
import { useEffect, useState } from 'react';
import { gachaRewardItemTypes } from '@pages/my-pet-cafe/constants';
import useCurrentApplication from './useCurrentApplication';

const APP_SPECIFIC_FILTERS = {
  my_pet_cafe: { rewardTypeNotIn: gachaRewardItemTypes },
};

export default function useRewardOptions(filters) {
  const { currentApplication: { id, typeName } } = useCurrentApplication();
  const [rewardItems, setRewardItems] = useState([]);
  const fetchRewardItemOptions = async () => {
    const response = await getRewardItems({
      applicationId: id,
      withoutPagination: true,
      includeInUse: false,
      includeContent: true,
      filter: {
        ...APP_SPECIFIC_FILTERS[typeName],
        ...filters,
      },
    });
    const options = response.data.items.map((item) => ({
      content: item.content,
      data: item.data,
      operation: item.operation,
      type: item.type,
      rewardType: item.rewardType,
      label: `${item.name} (${item.displayRewardType})`,
      value: item.id,
    }));
    setRewardItems(options);
  };
  useEffect(() => {
    (async () => fetchRewardItemOptions())();
  }, []);
  return rewardItems;
}
