import React from 'react';
import { get } from 'lodash';

import { Field, localizedLabelFactory, ServerError } from '@controls/form';
import { FormGroup, useFormContext, useFormGroup } from '@hooks';
import IconButton from '@controls/buttons';

const LocalizedLabel = localizedLabelFactory('tile.gameSettings.multipleRevives.schema');

export default function MultipleRevivesSchema({ name, disabled }) {
  const { generateName } = useFormGroup();
  const { values, setFieldValue } = useFormContext();

  const fieldName = generateName(name);

  const schema = JSON.parse(JSON.stringify(get(values, fieldName)));

  const onAdd = () => {
    const newSchema = [...schema, { to: 0 }];

    newSchema[0].from = 1;
    if (schema.length) newSchema[schema.length - 1].to = '';

    setFieldValue(fieldName, newSchema);
  };

  const onToFieldChange = (value, index) => {
    const numValue = Number(value);

    if (!numValue) {
      return setFieldValue(`${fieldName}.${index}.to`, null);
    }

    setFieldValue(`${fieldName}.${index}.to`, numValue);
    setFieldValue(`${fieldName}.${index + 1}.from`, numValue + 1);

    return numValue;
  };

  const onRemove = (index) => {
    const newSchema = [...schema];
    const elementToDelete = newSchema.splice(index, 1)[0];

    if (newSchema[index]) newSchema[index].from = elementToDelete.from;
    if (newSchema.length) newSchema[newSchema.length - 1].to = 0;

    return setFieldValue(fieldName, [...newSchema]);
  };

  return (
    <>
      {schema.map((_, index) => (
        <div className="mb-4">
          <FormGroup name={`schema.${index}`}>
            <LocalizedLabel name="coins">
              <Field type="number" name="coins" disabled={disabled} />
            </LocalizedLabel>
            <LocalizedLabel name="from">
              <Field type="number" name="from" disabled />
            </LocalizedLabel>
            <LocalizedLabel name="to">
              {(index === schema.length - 1) ? (
                'All Next'
              ) : (
                <Field
                  type="number"
                  name="to"
                  onChange={({ target: { value } }) => onToFieldChange(value, index)}
                  disabled={disabled}
                />
              )}
            </LocalizedLabel>
          </FormGroup>

          <div className="w-100 text-end">
            <IconButton.Delete
              minimized
              onClick={() => onRemove(index)}
              className="float-end"
            />
          </div>

          <hr />
        </div>
      ))}

      <ServerError name="schema.list" />

      <IconButton.New onClick={onAdd} disabled={disabled}>
        Add
      </IconButton.New>
    </>
  );
}
