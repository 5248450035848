import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { PageSection } from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

import { Label, Field, SelectField } from '@controls/form';
import { useFormContext, FormGroup } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';

const tierParameterOptions = fromClassNameToOptions('Missions::MissionTypes::TierParameterTypes');

export default function TierParameters() {
  const { values: { tierParametersAttributes, inUse }, dispatch } = useFormContext();
  const isUsed = isInUse(inUse);

  return (
    <>
      <PageSection
        title="Tier Parameters"
        tooltip="Provide the list of extra parameters which user should use for each Tier in allocation config"
      />
      {tierParametersAttributes.map(({ _destroy }, index) => !_destroy && (
        <FormGroup name={`tierParametersAttributes.${index}`}>
          <div>
            <Label text="Name" fieldSize={8}>
              <Field name="name" disabled={isUsed} />
            </Label>
            <Label text="Type" fieldSize={8}>
              <SelectField name="type" options={tierParameterOptions} isDisabled={isUsed} />
            </Label>
            <Row className="mb-3">
              <Col>
                {!isUsed && (
                  <IconButton.Remove
                    className="float-end"
                    onClick={() => dispatch({ actionType: 'removeTierParameter', index })}
                  />
                )}
              </Col>
            </Row>
            <hr />
          </div>
        </FormGroup>
      ))}
      <IconButton.New
        onClick={() => dispatch({ actionType: 'addTierParameter' })}
      >
        Add
      </IconButton.New>
    </>
  );
}
