import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Spinner } from '@tripledotstudios/react-core';
import { Field, Label } from '@controls/form';
import { Form, FormGroup } from '@hooks';
import { duplicateResponseHandlerFactory, noRedirectHandlerFactory } from '@requests/responseHandlers';
import IconButton from '@controls/buttons';

const DefaultFields = ({ entityName, name }) => (
  <Label text={`${entityName} ${name}`}><Field type="text" name={name} /></Label>
);

export default function DuplicationModal({
  children, entityName, handleClose, onSubmit, record = {}, title, buildRedirectPath, name = 'name',
  onSuccess = () => { }, redirect = true, openInNewTab = false,
}) {
  const [data, setData] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const redirectResponseHandler = duplicateResponseHandlerFactory({
    buildRedirectPath,
    entityName,
    setData,
    openInNewTab,
  });

  const noRedirectResponseHandler = noRedirectHandlerFactory({
    entityName,
    actionName: 'duplication',
    setData,
  });

  const onClose = () => {
    setData({ ...data, _meta: {} });
    setSubmitted(false);
    handleClose();
  };

  const initialValues = { ...record, ...data };
  if (record && record.name) { initialValues.name = `${record.name}`; }
  if (record && record.uid) { initialValues.uid = `${record.uid}`; }

  const onSubmitWithHandler = (values) => setSubmitted(true, onSubmit(values).then(redirect
    ? redirectResponseHandler : noRedirectResponseHandler)
    .then((response) => {
      if (response.status < 300) {
        onSuccess(response);
        onClose();
      }
      setSubmitted(false);
    }));
  const modalTitle = title || `Duplicate ${entityName}`;

  return (
    <Modal show={!!record} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Form initialValues={initialValues} onSubmit={onSubmitWithHandler}>
        {() => (
          <FormGroup>
            <Modal.Body>
              {children || <DefaultFields entityName={entityName} name={name} />}
            </Modal.Body>
            <Modal.Footer>
              <IconButton.Save type="submit" disabled={submitted}>
                {submitted ? (
                  <div style={{
                    position: 'relative', top: '-2px', height: '15px', width: '40px', display: 'inline-block',
                  }}
                  >
                    <Spinner height="14px" width="14px" />
                  </div>
                ) : 'Confirm'}
              </IconButton.Save>
              <IconButton.Cancel type="button" variant="secondary" onClick={onClose} disabled={submitted} />
            </Modal.Footer>
          </FormGroup>
        )}
      </Form>
    </Modal>
  );
}
