/* eslint-disable gs/plz-camel-case-for-action-names */
import { addUuid } from '@services/recursivelyProcessObject';

export default function formReducer(state, action) {
  const updateLevelNumbers = () => {
    let number = 1;

    state.levelsAttributes.map((element) => {
      element.number = number;

      if (!element._destroy) number += 1;

      return element;
    });
  };

  const updateLastXp = () => {
    const lastElement = [...state.levelsAttributes].reverse().find((element) => !element._destroy);

    state.levelsAttributes.forEach((element) => {
      if (element._uuid === lastElement._uuid) element.xp = 0;

      return element;
    });
  };

  if (action.type === 'initialize_levels') {
    state.levelsAttributes = [addUuid({ number: 1, xp: 0, rewardsAttributes: [] })];
  }

  if (action.type === 'add_level') {
    state.levelsAttributes.push(addUuid({ xp: 0, rewardsAttributes: [addUuid({})] }));
    updateLevelNumbers();
  }

  if (action.type === 'remove_level') {
    const { index } = action;

    state.levelsAttributes[index]._destroy = true;
    updateLevelNumbers();
    updateLastXp();
  }

  return state;
}
