import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { ArchivedSettingsRoutes } from '@pages/routes';
import { TrailFields } from '@pages/common';
import IconButton from '@controls/buttons';
import settingToKeys from './settingToKeys';

export default function ColumnsFactory({ applicationId, onReactivate, translate }) {
  return [
    { Header: 'Name', accessor: 'name' },
    {
      Header: 'Key(s)',
      accessor: 'key',
      style: { whiteSpace: 'break-spaces' },
      Cell: ({ row: { original } }) => settingToKeys(original.key, translate).join('\n'),
    },
    { Header: 'Behaviour', accessor: 'strategy', Cell: ({ row }) => row.original.strategyHumanize },
    {
      Header: 'Archived at (UTC)',
      accessor: 'createdAt',
      style: { width: '15%' },
      Cell: ({ row }) => <TrailFields prefix="created" record={row.original} />,
    },
    {
      Header: 'Actions',
      Cell: ({ row: { original: { id, name, restorable } } }) => (
        <ButtonToolbar>
          <IconButton.Edit to={ArchivedSettingsRoutes.editPath({ id, applicationId })} minimized />
          <IconButton.Restore onClick={() => onReactivate({ id, name })} minimized disabled={!restorable}>
            Reactivate
          </IconButton.Restore>
        </ButtonToolbar>
      ),
    },
  ];
}
