import React from 'react';

import { fromClassNameToOptions } from '@services/enums';
import { SelectField, Label } from '@controls/form';

import { PageSection } from '@pages/common';

export default function HardLevel({ readOnly }) {
  const specialLevelIndicatorOptions = fromClassNameToOptions('Woodoku::Journeys::SpecialLevelIndicators');

  return (
    <PageSection title="Hard Level">
      <Label
        text="Special Level Indicator Type"
        tooltipText="“Hard Level” type for different placements (map, game popup, game screen)"
      >
        <SelectField
          includeEmpty
          name="specialLevelIndicator"
          options={specialLevelIndicatorOptions}
          isDisabled={readOnly}
        />
      </Label>
    </PageSection>
  );
}
