import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import LevelsListWrapper from '@components/shared/ListWrapper';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { combineReducers, rewardableReducer } from '@reducers';
import {
  Form, useRewardOptions,
} from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { LocalizationsRoutes, AssetsRoutes, WoodokuSagaRoutes as Routes } from '@pages/routes';
import areaSetReducer from './reducer';
import Area from './Area';

const reducer = combineReducers([areaSetReducer, rewardableReducer]);

const assetTypesEnum = APP_DATA.enums['WoodokuSaga::AssetTypes'];

export default function AreaSetForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const [localizationKeysOptions, setLocalizationKeysOptions] = useState([]);
  const [assetKeyOptions, setAssetKeyOptions] = useState([]);

  useEffect(() => {
    requestWithToSelectOptions(LocalizationsRoutes.Keys.indexRequest, applicationId, [])
      .then(setLocalizationKeysOptions);
    AssetsRoutes.Keys.indexRequest({ applicationId, withTypesOrIds: { types: [assetTypesEnum.AREA_MAIN] } })
      .then(({ data: { items } }) => setAssetKeyOptions(items));
  }, []);

  const entityIdentity = ({ entity: { sceneAssetKeyId, sceneAssetId, position }, entityName }) => {
    let identity = [entityName, `#${position}`, sceneAssetId].filter((value) => value);

    if (assetKeyOptions.length) {
      const asset = assetKeyOptions.find(({ id }) => id === sceneAssetKeyId);

      if (asset) {
        identity = [...identity, `(${asset.uid})`];
      }
    }

    return identity.join(' ');
  };

  const rewardItems = useRewardOptions();

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit} reducer={reducer}>
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Area set`}>
            <FormButtonsGroup
              cancelButtonPath={Routes.AreaSets.indexPath({ applicationId })}
            />
          </PageHeader>
          <Label required text="Name">
            <Field name="name" />
          </Label>
          <StaticFieldsFormGroup data={data} />
          <LevelsListWrapper
            entityAttributes={values.areasAttributes}
            onEntityAdd={() => dispatch({ actionType: 'addEmptyEntity' })}
            attributesName="areasAttributes"
            metaErrorsPath={['errors', 'configAttributes', 'areasAttributes']}
            entityNameTranslationPath="areaSet.levels.name"
            routes={Routes.AreaSets}
            entityIdentity={entityIdentity}
          >
            {({ entity, index }) => (
              <Area
                area={entity}
                dispatch={dispatch}
                rewardItems={rewardItems}
                assetKeysOptions={assetKeyOptions}
                localizationKeysOptions={localizationKeysOptions}
                index={index}
              />
            )}
          </LevelsListWrapper>
        </>
      )}
    </Form>
  );
}
