import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Level Sets',
    actionName: 'update',
    setData,
  });

  const onSubmit = (values) => (
    MergeRoutes.LevelSets.updateRequest({ ...values, applicationId: query.applicationId }).then(responseHandler)
  );

  useEffect(() => {
    MergeRoutes.LevelSets.editRequest(query).then((response) => setData(response.data));
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} actionName="Update" />;
}
