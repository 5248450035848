import React from 'react';
import { isNil } from 'lodash';

import APP_DATA from '@services/appData';

import { FormGroup, useCurrentApplication, useQuery } from '@hooks';
import { Label, SelectField } from '@controls/form';
import { FilesRoutes } from '@pages/routes';

const fileTypesEnum = APP_DATA.enums['BlockParty::FileTypesEnum'];

export const Variant = ({ disabled }) => {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();

  const { response: levelFileOptions } = useQuery({
    request: FilesRoutes.indexRequest,
    additionalRequestParams: { withoutPagination: true, filter: { fileTypeEq: fileTypesEnum.DAILY_CHALLENGE_LEVELS } },
    queryKey: ['dcLevelFiles', applicationId],
    toSelectOptions: true,
  });

  return (
    <Label required text="Levels File">
      <SelectField name="levelsFileId" options={levelFileOptions} isDisabled={disabled} />
    </Label>
  );
};

export default function BlockParty({ variantAttributes, variantIndex, disabled }) {
  const formGroupName = isNil(variantIndex) ? 'currentVariantAttributes' : `variantsAttributes[${variantIndex}]`;

  return (
    <FormGroup name="configAttributes">
      <FormGroup name={formGroupName}>
        <Variant variantAttributes={variantAttributes} variantIndex={variantIndex} disabled={disabled} />
      </FormGroup>
    </FormGroup>
  );
}
