import { useCallback } from 'react';
import { fieldBuilder, selectBuilder } from '@services/fields-factory';

import { getLobbyConfigs } from '@requests/puzzle-time/lobbyConfigs';
import { getNewGamePopupConfigs } from '@requests/puzzle-time/newGamePopupConfigs';
import { getAdsConfigs } from '@requests/puzzle-time/adsConfigs';
import { get2048Configs } from '@requests/puzzle-time/configs2048';
import { getEscapeConfigs } from '@requests/puzzle-time/escapeConfigs';
import { getHexagonPuzzleConfigs } from '@requests/puzzle-time/hexagonPuzzleConfigs';
import { getHexagonSquareConfigs } from '@requests/puzzle-time/hexagonSquareConfigs';
import { getSingleScreenTutorialConfigs } from '@requests/puzzle-time/singleScreenTutorialConfigs';
import { requestWithToSelectOptions } from '@services/toSelectOptions';

import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';

const defaultModeOptions = [{ label: 'Number', value: 0 }, { label: 'Color', value: 1 }];

const fieldsFactory = ({ applicationId }) => (
  {
    ...fieldBuilder({ name: 'spShuffleAnimation', type: 'checkbox' }),
    ...fieldBuilder({ name: 'spShuffleAnimationMultiplier', type: 'number' }),
    ...fieldBuilder({ name: 'spShuffleAnimationEasyGamesMultiplier', type: 'number' }),
    ...fieldBuilder({ name: 'spShuffleAnimationEasyGamesCount', type: 'number' }),
    ...selectBuilder({ name: 'spDefaultMode', options: defaultModeOptions }),
    ...selectBuilder(
      {
        name: 'lobbyConfigId',
        options: useCallback(() => requestWithToSelectOptions(getLobbyConfigs, applicationId), []),
      },
    ),
    ...selectBuilder(
      {
        name: 'newGamePopupConfigId',
        options: useCallback(() => requestWithToSelectOptions(getNewGamePopupConfigs, applicationId), []),
      },
    ),
    ...selectBuilder(
      {
        name: 'adsConfigId',
        options: useCallback(() => requestWithToSelectOptions(getAdsConfigs, applicationId), []),
      },
    ),
    ...selectBuilder(
      {
        name: 'config2048Id',
        options: useCallback(() => requestWithToSelectOptions(get2048Configs, applicationId), []),
      },
    ),
    ...selectBuilder(
      {
        name: 'escapeConfigId',
        options: useCallback(() => requestWithToSelectOptions(getEscapeConfigs, applicationId), []),
      },
    ),
    ...selectBuilder(
      {
        name: 'hexagonPuzzleConfigId',
        options: useCallback(() => requestWithToSelectOptions(getHexagonPuzzleConfigs, applicationId), []),
      },
    ),
    ...selectBuilder(
      {
        name: 'hexagonSquareConfigId',
        options: useCallback(() => requestWithToSelectOptions(getHexagonSquareConfigs, applicationId), []),
      },
    ),
    ...selectBuilder(
      {
        name: 'singleScreenTutorialConfigId',
        options: useCallback(() => requestWithToSelectOptions(getSingleScreenTutorialConfigs, applicationId), []),
      },
    ),
  }
);

export default combineWithSharedFieldsFactory(fieldsFactory);
