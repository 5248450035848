/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useContext, createContext, useEffect,
} from 'react';
import { isNil, reduce } from 'lodash';
import { Formik, Form as FormikForm } from 'formik';

import APP_DATA from '@services/appData';

// eslint-disable-next-line import/no-cycle
import RouteLeavingGuard from '@pages/common/RouteLeavingGuard';

const formContext = createContext();
export function useFormContext() {
  return useContext(formContext);
}

const FormComponent = ({
  className = null,
  disableDirtyChecker = false,
  customDirty = false,
  initialValues = {},
  // eslint-disable-next-line no-unused-vars
  reducer = ((state, _action) => state),
  formikBag,
  onSubmit,
  routeLeavingGuardProps,
  children,
  onLoad,
  sharedInputProps,
}) => {
  const {
    values, setValues, dirty, resetForm, isSubmitting, setSubmitting,
  } = formikBag;
  const dispatch = (action) => {
    if (action.silent) { return resetForm({ values: reducer(values, action, APP_DATA) }); }
    return setValues((currentValues) => reducer(currentValues, action, APP_DATA));
  };

  useEffect(() => {
    resetForm({ values: { ...values, meta: initialValues._meta || {} } });
  }, [JSON.stringify(initialValues)]);

  const applySharedInputProps = (props) => reduce(
    props,
    (acc, value, prop) => ({ ...acc, [prop]: isNil(value) ? sharedInputProps[prop] : value }),
    {},
  );

  const contextValue = {
    ...formikBag,
    sharedInputProps,
    applySharedInputProps,
    dispatch,
    meta: values.meta || {},
  };

  useEffect(() => {
    if (onLoad) { onLoad(contextValue); }
  }, []);

  const onSave = () => {
    setSubmitting(true);
    return onSubmit(values, formikBag);
  };

  return (
    <formContext.Provider value={contextValue}>
      <formContext.Consumer>
        {(formReducerBag) => {
          const params = { ...formReducerBag, ...formikBag };

          return values && (
            <FormikForm className={className}>
              {children(params)}
              <RouteLeavingGuard
                when={(dirty || customDirty) && !disableDirtyChecker && !isSubmitting}
                onSave={onSave}
                onDiscard={() => resetForm()}
                {...routeLeavingGuardProps}
              />
            </FormikForm>
          );
        }}
      </formContext.Consumer>
    </formContext.Provider>
  );
};

export function Form({
  initialValues = {},
  onSubmit,
  enableReinitialize,
  sharedInputProps = {},
  ...rest
}) {
  const { _meta, ...nonErrors } = initialValues;
  // TODO: Make a proper fix for those warnings on frontend
  // null should not be used as input value
  // const escapedValues = mapValues(initialValues, (value) => (value === null ? undefined : value));
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ ...nonErrors, meta: _meta }}
      enableReinitialize={enableReinitialize}
    >
      {(formikBag) => (
        <FormComponent
          formikBag={formikBag}
          onSubmit={onSubmit}
          initialValues={initialValues}
          sharedInputProps={sharedInputProps}
          {...rest}
        />
      )}
    </Formik>
  );
}
