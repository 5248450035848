import { addUuid } from '@services/recursivelyProcessObject';
import { wrapReducer } from '@reducers';

/* eslint-disable no-param-reassign, array-callback-return */
const formReducer = (state, action) => {
  const { actionType } = action;

  if (actionType === 'addLevelBankToJourney') {
    const { id } = action;

    state.levelBankAffiliationsAttributes.push(addUuid({
      levelBankId: id, _destroy: false,
    }));
  }

  if (actionType === 'removeLevelBankFromJourney') {
    const { id } = action;

    const affiliations = state.levelBankAffiliationsAttributes.filter(({ levelBankId }) => levelBankId === id);

    affiliations.forEach((affiliation) => {
      affiliation._destroy = true;

      return affiliation;
    });
  }

  return state;
};

export default wrapReducer(formReducer);
