import React from 'react';

import { Badge } from 'react-bootstrap';
import { PageHeader as TDSPageHeader, useRouter } from '@tripledotstudios/react-core';
import { isEmpty } from 'lodash';

import IconButton from '@controls/buttons';
import IconTooltip from '@controls/tooltips';

const SKIP_FILTERS_COUNT = ['versionsEventIn', 'createdByMe'];

function CollapsibleToggle(props) {
  const { query: { filter = {} } } = useRouter();

  const appliedFiltersCount = Object.keys(filter).filter((filterKey) => !SKIP_FILTERS_COUNT.includes(filterKey)).length;

  return (
    <IconButton.Filter {...props}>
      Filter
      {!isEmpty(filter) && <Badge bg="secondary" className="ms-2">{appliedFiltersCount}</Badge>}
    </IconButton.Filter>
  );
}

export default function PageHeader({
  title,
  userGuideUrlKey,
  filter = null,
  menuButtons,
  leadingAccessories,
  children,
}) {
  return (
    <TDSPageHeader
      title={title}
      leadingAccessories={(
        <>
          <IconTooltip.Guide userGuideUrlKey={userGuideUrlKey} size="lg" />
          <IconTooltip.ContactUs size="lg" />
          {leadingAccessories}
        </>
      )}
      collapsibleToggle={CollapsibleToggle}
      trailingAccessories={children}
      menuButtons={menuButtons}
    >
      {filter}
    </TDSPageHeader>
  );
}
