import React, { useCallback } from 'react';
import { memoize } from 'lodash';

import {
  fieldBuilder,
  fieldGroupBuilder,
  selectBuilder,
  fieldArrayBuilder,
} from '@services/fields-factory';
import { fromClassNamesToOptions } from '@services/enums';
import { AdsRoutes, CrossPromoApplicationsRoutes } from '@pages/routes';
import APP_DATA from '@services/appData';
import selectOptionsWithQuickLink from '@root/services/selectOptionsWithQuickLink';
import { requestWithToSelectOptions } from '@root/services/toSelectOptions';

import NewcomersRestrictionsFields from './newcomers-restrictions/Fields';
import BackToBackFrequencyRestrictionsFields from './back-to-back-frequency-restrictions/Fields';
import FrequencyRestrictionsFields from './frequency-restrictions/Fields';
import ImpressionRestrictions from './impression-restrictions/Fields';
import AdUnitsConfigurationFields from './ad-units/ConfigurationFields';
import CustomAdUnitsCampaignFields from './custom-ad-units/CampaignFields';

const [bannerPositionOptions, networkOptions, postbidCpmFloorMultiplierTypes, levelTypes] = fromClassNamesToOptions([
  'Ads::BannerPositions',
  'Ads::AppHarbrNetworks',
  'Ads::Configuration::PostbidCpmFloorMultiplierTypes',
  'Ads::Configuration::PostbidCpmFloorMultiplierLevelTypes',
]);

const postbidCpmFloorMultiplierTypeEnum = APP_DATA.enums['Ads::Configuration::PostbidCpmFloorMultiplierTypes'];
const postbidCpmFloorMultiplierLevelTypeEnum = APP_DATA.enums['Ads::Configuration::PostbidCpmFloorMultiplierLevelTypes'];

const postbidCpmFloorMultiplierTypeRangeHiddenFn = (values, video) => (
  (values?.data?.postbidCpmFloorMultiplierType
    ?? values?.[video ? 'videoPostbidCpmFloorMultiplier' : 'postbidCpmFloorMultiplier']?.postbidCpmFloorMultiplierType)
  !== postbidCpmFloorMultiplierTypeEnum.RANGE
);
const postbidCpmFloorMultiplierTypeWaterfallHiddenFn = (values, video) => (
  (values?.data?.postbidCpmFloorMultiplierType
    ?? values?.[video ? 'videoPostbidCpmFloorMultiplier' : 'postbidCpmFloorMultiplier']?.postbidCpmFloorMultiplierType)
  !== postbidCpmFloorMultiplierTypeEnum.WATERFALL);
const postbidCpmFloorMultiplierTypeNotWaterfallHiddenFn = (values, video) => {
  const selectedValue = (values?.data?.postbidCpmFloorMultiplierType
    ?? values?.[video ? 'videoPostbidCpmFloorMultiplier' : 'postbidCpmFloorMultiplier']?.postbidCpmFloorMultiplierType);
  const excludedTypes = [postbidCpmFloorMultiplierTypeEnum.RANGE, postbidCpmFloorMultiplierTypeEnum.STATIC];
  return (
    !excludedTypes.includes(selectedValue)
  );
};

const Fields = memoize(({
  rewardedVideosPlacementBankOptions,
  interstitialsPlacementBankOptions,
  crossPromoApplicationsOptions,
  badgePlacementBanksOptions,
}) => (
  {
    // Rewarded Videos
    ...fieldBuilder({ name: 'rewardedVideoLimit', type: 'number' }),
    ...fieldBuilder({ name: 'rewardedVideoLimitResetHours', type: 'number', fieldOptions: { appendText: 'hours' } }),
    ...selectBuilder({ name: 'rewardedVideoPlacementBankId', ...rewardedVideosPlacementBankOptions }),
    ...fieldBuilder({ name: 'adsEnableInterstitialInsteadRewarded', type: 'checkbox' }),
    ...fieldGroupBuilder(
      {
        name: 'videoGooglePostbidIds',
        fieldsConfiguration: {
          ...fieldBuilder({ name: 'googleAndroidId' }),
          ...fieldBuilder({ name: 'googleIosId' }),
        },
      },
    ),
    ...fieldBuilder({ name: 'videoConsecutiveAdsLimit', type: 'number', alertText: 'is available from tripledot-platform-ads 2.5' }),
    ...fieldGroupBuilder(
      {
        name: 'videoPostbidCpmFloorMultiplier',
        fieldsConfiguration: {
          ...selectBuilder({
            name: 'postbidCpmFloorMultiplierType',
            options: postbidCpmFloorMultiplierTypes,
          }),
          ...fieldBuilder({
            name: 'cpmMultiplier',
            type: 'number',
            fieldOptions: { hiddenFn: (values) => postbidCpmFloorMultiplierTypeNotWaterfallHiddenFn(values, true) },
          }),
          ...fieldBuilder({
            name: 'cpmMultiplierStep',
            type: 'number',
            fieldOptions: { appendText: '$', hiddenFn: (values) => postbidCpmFloorMultiplierTypeRangeHiddenFn(values, true) },
          }),
          ...fieldBuilder({
            name: 'maximumCpmMultiplier',
            type: 'number',
            fieldOptions: { appendText: '$', hiddenFn: (values) => postbidCpmFloorMultiplierTypeRangeHiddenFn(values, true) },
            alertText: 'is available from tripledot-platform-ads 2.4.0',
          }),
          ...fieldBuilder({
            name: 'initial',
            type: 'number',
            fieldOptions: { hiddenFn: (values) => postbidCpmFloorMultiplierTypeRangeHiddenFn(values, true) },
            alertText: 'is available from tripledot-platform-ads 2.5',
          }),
          ...fieldArrayBuilder(
            {
              name: 'levels',
              tooltipText: 'Waterfall feature is available from tripledot-platform-ads TBD',
              isHorizontal: true,
              handleOnly: true,
              fieldOptions: { hiddenFn: (values) => postbidCpmFloorMultiplierTypeWaterfallHiddenFn(values, true) },
              customAlertName: 'Waterfall feature',
              alertText: 'is available from tripledot-platform-ads 2.5',
              fieldsConfiguration: [
                {
                  name: 'type',
                  as: 'select',
                  componentData: levelTypes,
                  default: postbidCpmFloorMultiplierLevelTypeEnum.FIXED,
                },
                { name: 'value', type: 'number', appendText: '$' },
              ],
            },
          ),
        },
      },
    ),
    ...fieldBuilder({
      name: 'videoGooglePostbidRequestCountLimit',
      type: 'number',
      alertText: 'Google Postbid Requests Limit Counter is available from tripledot-platform-ads 2.5.0',
    }),
    ...fieldGroupBuilder(
      {
        name: 'rewardedVideoMaxAdUnitsConfigurationAttributes',
        componentFn: ({ values, disabled }) => (
          <AdUnitsConfigurationFields values={values} disabled={disabled} rewardedVideo />
        ),
        options: {
          preferModalInExperiments: true,
        },
      },
    ),

    // Interstitials
    ...selectBuilder({ name: 'interstitialsPlacementBankId', ...interstitialsPlacementBankOptions }),
    ...fieldBuilder({ name: 'consecutiveAdsLimit', type: 'number', alertText: 'is available from tripledot-platform-ads 2.4.0' }),
    ...fieldGroupBuilder(
      {
        name: 'newcomersRestrictionsConfigurationAttributes',
        componentFn: ({ values, disabled }) => (
          <NewcomersRestrictionsFields values={values} disabled={disabled} />
        ),
        options: {
          preferModalInExperiments: true,
        },
      },
    ),
    ...fieldGroupBuilder(
      {
        name: 'maxAdUnitsConfigurationAttributes',
        tooltipText: 'is available from tripledot-platform-ads 2.1.0',
        componentFn: ({ values, disabled }) => (
          <AdUnitsConfigurationFields values={values} disabled={disabled} />
        ),
        options: {
          preferModalInExperiments: true,
        },
      },
    ),
    ...fieldGroupBuilder({
      name: 'pauseDurationToResumeWithAd',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'min', type: 'number', fieldOptions: { appendText: 'seconds' } }),
        ...fieldBuilder({ name: 'max', type: 'number', fieldOptions: { appendText: 'seconds' } }),
      },
    }),
    ...fieldGroupBuilder(
      {
        name: 'backToBackFrequencyRestrictionsConfigurationAttributes',
        componentFn: ({ values, disabled }) => (
          <BackToBackFrequencyRestrictionsFields values={values} disabled={disabled} />
        ),
        options: {
          preferModalInExperiments: true,
        },
      },
    ),
    ...fieldGroupBuilder(
      {
        name: 'frequencyRestrictionsConfigurationAttributes',
        componentFn: ({ values, disabled }) => (
          <FrequencyRestrictionsFields values={values} disabled={disabled} />
        ),
        options: {
          preferModalInExperiments: true,
        },
      },
    ),
    ...fieldGroupBuilder(
      {
        name: 'impressionRestrictionsConfigurationAttributes',
        componentFn: ({ values, disabled }) => (
          <ImpressionRestrictions values={values} disabled={disabled} />
        ),
        options: {
          preferModalInExperiments: true,
        },
      },
    ),
    ...fieldGroupBuilder(
      {
        name: 'googlePostbidIds',
        fieldsConfiguration: {
          ...fieldBuilder({ name: 'googleAndroidId' }),
          ...fieldBuilder({ name: 'googleIosId' }),
        },
      },
    ),
    ...fieldGroupBuilder(
      {
        name: 'interstitialTimeLimit',
        fieldsConfiguration: {
          ...fieldBuilder({ name: 'time', type: 'number', fieldOptions: { appendText: 'seconds' } }),
          ...fieldBuilder({ name: 'allNetworks', type: 'checkbox' }),
          ...selectBuilder({
            name: 'networks',
            isMulti: true,
            options: networkOptions,
            fieldOptions: { enabledIf: '!allNetworks' },
          }),
          ...fieldBuilder({ name: 'iosSdkKey', type: 'text' }),
          ...fieldBuilder({ name: 'androidSdkKey', type: 'text' }),
        },
      },
    ),
    ...fieldArrayBuilder(
      {
        name: 'customAdUnits',
        tooltipText: '',
        isHorizontal: true,
        forceFieldLabels: true,
        fieldsConfiguration: [
          {
            name: 'campaignId',
            as: 'component',
            componentFn: ({ name, disabled }) => [CustomAdUnitsCampaignFields, { name, disabled }],
          },
          {
            name: 'impressionOrderNumber',
            as: 'array',
            componentData: {
              tooltipText: 'Input the order numbers via "," symbol',
            },
          },
          { name: 'lifetimeMax', type: 'number' },
          { name: 'dailyMax', type: 'number' },
          {
            name: 'crossPromoApplicationIds',
            as: 'select',
            isMultiSelect: true,
            componentData: crossPromoApplicationsOptions || [],
          },
        ],
        options: {
          preferModalInExperiments: true,
        },
      },
    ),
    ...fieldGroupBuilder(
      {
        name: 'postbidCpmFloorMultiplier',
        fieldsConfiguration: {
          ...selectBuilder({
            name: 'postbidCpmFloorMultiplierType',
            options: postbidCpmFloorMultiplierTypes,
          }),
          ...fieldBuilder({
            name: 'cpmMultiplier',
            type: 'number',
            fieldOptions: { hiddenFn: (values) => postbidCpmFloorMultiplierTypeNotWaterfallHiddenFn(values, false) },
          }),
          ...fieldBuilder({
            name: 'cpmMultiplierStep',
            type: 'number',
            fieldOptions: { appendText: '$', hiddenFn: (values) => postbidCpmFloorMultiplierTypeRangeHiddenFn(values, false) },
          }),
          ...fieldBuilder({
            name: 'maximumCpmMultiplier',
            type: 'number',
            fieldOptions: { appendText: '$', hiddenFn: (values) => postbidCpmFloorMultiplierTypeRangeHiddenFn(values, false) },
            alertText: 'is available from tripledot-platform-ads 2.4.0',
          }),
          ...fieldBuilder({
            name: 'initial',
            type: 'number',
            fieldOptions: { hiddenFn: (values) => postbidCpmFloorMultiplierTypeRangeHiddenFn(values, false) },
            alertText: 'is available from tripledot-platform-ads 2.5',
          }),
          ...fieldArrayBuilder(
            {
              name: 'levels',
              tooltipText: 'Waterfall feature is available from tripledot-platform-ads TBD',
              isHorizontal: true,
              handleOnly: true,
              fieldOptions: { hiddenFn: (values) => postbidCpmFloorMultiplierTypeWaterfallHiddenFn(values, false) },
              customAlertName: 'Waterfall feature',
              alertText: 'is available from tripledot-platform-ads 2.5',
              fieldsConfiguration: [
                {
                  name: 'type',
                  as: 'select',
                  componentData: levelTypes,
                  default: postbidCpmFloorMultiplierLevelTypeEnum.FIXED,
                },
                { name: 'value', type: 'number', appendText: '$' },
              ],
            },
          ),
        },
      },
    ),
    ...fieldBuilder({
      name: 'googlePostbidRequestCountLimit',
      type: 'number',
      alertText: 'Google Postbid Requests Limit Counter is available from tripledot-platform-ads 2.5.0',
    }),

    // Waterfall
    ...fieldGroupBuilder(
      {
        name: 'requestRefreshPeriodConfiguration',
        fieldsConfiguration: {
          ...fieldBuilder({ name: 'value', type: 'number', fieldOptions: { appendText: 'seconds' } }),
          ...fieldBuilder({ name: 'enableIncrement', type: 'checkbox' }),
          ...fieldBuilder({ name: 'incrementValue', type: 'number' }),
          ...fieldBuilder({ name: 'maxValue', type: 'number', fieldOptions: { appendText: 'seconds' } }),
        },
      },
    ),

    // Banners
    ...fieldGroupBuilder(
      {
        name: 'portraitBannersConfiguration',
        fieldsConfiguration: {
          ...fieldBuilder({ name: 'enabled', type: 'checkbox' }),
          ...selectBuilder({ name: 'position', options: bannerPositionOptions }),
        },
      },
    ),
    ...fieldGroupBuilder(
      {
        name: 'landscapeBannersConfiguration',
        fieldsConfiguration: {
          ...fieldBuilder({ name: 'enabled', type: 'checkbox' }),
          ...selectBuilder({ name: 'position', options: bannerPositionOptions }),
        },
      },
    ),
    ...fieldBuilder({
      name: 'adsBannerCpmFloor',
      type: 'number',
      fieldOptions: { appendText: '$' },
      alertText: 'is available from tripledot-platform-ads 1.3.0',
    }),

    // Badges
    ...selectBuilder({ name: 'adBadgePlacementsBankId', ...badgePlacementBanksOptions }),
  }
), (args) => JSON.stringify(args));

export default function fieldsFactory({ applicationId }) {
  const rewardedVideosPlacementBankOptions = selectOptionsWithQuickLink(
    AdsRoutes.RewardedVideos.PlacementBanks, applicationId,
  );
  const interstitialsPlacementBankOptions = selectOptionsWithQuickLink(
    AdsRoutes.Interstitials.PlacementBanks, applicationId,
  );
  const crossPromoApplicationsOptions = useCallback(
    () => requestWithToSelectOptions(CrossPromoApplicationsRoutes.indexRequest, applicationId),
    [],
  );
  const badgePlacementBanksOptions = selectOptionsWithQuickLink(AdsRoutes.Badges.PlacementBanks, applicationId);

  return Fields({
    applicationId,
    rewardedVideosPlacementBankOptions,
    interstitialsPlacementBankOptions,
    crossPromoApplicationsOptions,
    badgePlacementBanksOptions,
  });
}
