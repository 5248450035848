import {
  last,
  cloneDeep,
  isPlainObject,
  sortBy,
  get,
} from 'lodash';
import reorderMeta from '@services/reorderMeta';
import { addUuid } from '@services/recursivelyProcessObject';
import removeNestedIds from '@services/removeNestedIds';
import reorderEntities from '@services/reorderEntities';
import removeEntity from '@services/removeEntity';

/* eslint-disable no-param-reassign, array-callback-return */
export default function reducer(state, action) {
  const { actionType } = action;

  if (actionType === 'removeEntity') {
    const { _uuid: uuidToDelete } = action;

    return removeEntity(state, 'eventsAttributes', uuidToDelete, 'number');
  }

  if (actionType === 'bulkRemoveEntities') {
    const { eventsAttributes } = state;
    const { uuids } = action;

    const events = cloneDeep(eventsAttributes);

    events.forEach((event) => {
      if (uuids.includes(event._uuid)) event._destroy = true;

      return event;
    });

    state.eventsAttributes = reorderEntities(events, 'number').result;

    return state;
  }

  if (actionType === 'restoreEntity') {
    const { eventsAttributes } = state;
    const { _uuid: uuidToDelete } = action;

    const events = cloneDeep(eventsAttributes);

    delete events.find(({ _uuid }) => _uuid === uuidToDelete)._destroy;

    state.eventsAttributes = reorderEntities(events, 'number').result;

    return state;
  }

  if (actionType === 'moveEntity') {
    const { eventsAttributes } = state;
    const {
      sourceIndex,
      destinationIndex,
      meta,
      metaErrorsPath,
    } = action;

    const events = cloneDeep(eventsAttributes);

    if (eventsAttributes.length <= 1) return state;

    const { result, changeSet: [fromIndices, toIndices] } = reorderEntities(
      events, 'number', sourceIndex, destinationIndex,
    );

    state.eventsAttributes = result;

    if (meta.errors && isPlainObject(get(meta, metaErrorsPath))) {
      meta.errors = { ...meta.errors, ...reorderMeta(meta, fromIndices, toIndices, metaErrorsPath).errors };
    }

    return state;
  }

  if (actionType === 'applyPositions') {
    const { eventsAttributes } = state;
    const { uuidToPositionMapping } = action;

    eventsAttributes.forEach((event) => {
      event.number = uuidToPositionMapping[event._uuid];

      return event;
    });

    state.eventsAttributes = sortBy(eventsAttributes, 'number');

    return state;
  }

  if (actionType === 'duplicateEntity') {
    const { eventsAttributes } = state;
    const { _uuid: originalUuid } = action;

    const { id, _uuid, ...originalEvent } = eventsAttributes.find((event) => event._uuid === originalUuid);
    const lastEvent = last(eventsAttributes.filter(({ _destroy }) => !_destroy));

    const duplicatedEvent = removeNestedIds(cloneDeep(originalEvent));
    eventsAttributes.push(addUuid({ ...duplicatedEvent, _destroy: false, number: lastEvent.number + 1 }));
  }

  if (actionType === 'addEmptyEntity') {
    const { eventsAttributes } = state;

    const lastEvent = last(eventsAttributes.filter(({ _destroy }) => !_destroy));
    const newEvent = addUuid({
      id: null,
      number: lastEvent ? lastEvent.number + 1 : 1,
      milestonesAttributes: [],
      parametersAttributes: [],
      _destroy: false,
    });

    eventsAttributes.push(newEvent);
  }

  if (actionType === 'addMilestone') {
    const { eventsAttributes } = state;
    const { eventIndex } = action;

    const event = eventsAttributes[eventIndex];

    const newMilestone = addUuid({
      id: null,
      rewardAffiliationsAttributes: [],
      _destroy: false,
    });

    event.milestonesAttributes.push(newMilestone);
  }

  if (actionType === 'removeMilestone') {
    const { eventsAttributes } = state;
    const { eventIndex, milestoneIndex } = action;

    const milestone = eventsAttributes[eventIndex].milestonesAttributes[milestoneIndex];
    milestone._destroy = true;
  }

  return state;
}
