import React, { useState, useEffect } from 'react';

import { useRouter } from '@tripledotstudios/react-core';
import { Field, SelectField } from '@controls/form';
import { FormGroup } from '@hooks';

import { requestWithToSelectOptions } from '@services/toSelectOptions';

import { ChapterRow, ColumnTitle, LevelsConfigFields } from '@pages/metagame/chapter-based/chapter-sets/shared';
import { WooAspenRoutes as Routes } from '@pages/routes';

export default function ChapterFields({
  banks, chapter, dispatch, isDirty, levelsTotal, populateChapter,
}) {
  const { query: { applicationId } } = useRouter();
  const [picturesOptions, setPicturesOptions] = useState();

  useEffect(() => {
    requestWithToSelectOptions(
      Routes.Chapters.Pictures.indexRequest,
      applicationId,
      { includeInUse: false },
    ).then(setPicturesOptions);
  }, []);

  return (
    <>
      <ChapterRow>
        <div style={{ width: '10%' }}>
          <ColumnTitle>ID</ColumnTitle>
          <div>{chapter.id}</div>
        </div>
        <div style={{ width: '45%' }}>
          <ColumnTitle>Chapter name</ColumnTitle>
          <Field name="name" fast />
        </div>
        <FormGroup name="configAttributes">
          <div style={{ width: '45%' }} className="ms-3">
            <ColumnTitle>Background</ColumnTitle>
            <SelectField name="backgroundPictureId" options={picturesOptions} includeEmpty />
          </div>
        </FormGroup>
      </ChapterRow>
      <LevelsConfigFields
        banks={banks}
        chapter={chapter}
        isDirty={isDirty}
        levelsTotal={levelsTotal}
        populateChapter={populateChapter}
        dispatch={dispatch}
      />
    </>
  );
}
