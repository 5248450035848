import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import { useRouter } from '@tripledotstudios/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { useLocalStorage } from '@hooks';
import updateQuery from '@services/updateQuery';

const defaultCollectionSizeOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export default function Pagination({
  children, className, pageCount, collectionSizeOptions = defaultCollectionSizeOptions,
}) {
  const router = useRouter();
  const { query: { page: routerPage } } = router;
  const currentRouterPage = parseInt(routerPage, 10);
  const [itemsPerPage, setItemsPerPage] = useLocalStorage('itemsPerPage', collectionSizeOptions[0].value);
  const [currentPage, setCurrentPage] = useState(currentRouterPage || 1);

  const onPageClick = (event) => {
    setCurrentPage((event.selected + 1) || 1);
  };

  const onPerClick = (event) => {
    setItemsPerPage(event.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    updateQuery(router, { per: itemsPerPage, page: currentPage });
  }, [itemsPerPage, currentPage]);

  return (
    <Row className={classnames('align-items-center', className)}>
      <Col xl={10} lg={9} className="d-flex">
        <ReactPaginate
          initialPage={currentPage - 1}
          forcePage={currentPage - 1}
          containerClassName="pagination mb-0"
          breakClassName="page-item"
          breakLabel={<button type="button" className="page-link">...</button>}
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          activeClassName="active"
          onPageChange={onPageClick}
          pageCount={pageCount}
          previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
          nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
          disableInitialCallback
        />

        {children}
      </Col>

      <Col xl={2} lg={3} className="d-flex justify-content-end">
        <Select
          className="react-select zindex-5"
          classNamePrefix="react-select"
          value={collectionSizeOptions.find(({ value }) => value === itemsPerPage)}
          options={collectionSizeOptions}
          onChange={onPerClick}
          styles={{
            singleValue: (styles) => ({
              ...styles,
              ':before': { content: '"Per page: "', fontWeight: 'bold' },
            }),
          }}
        />
      </Col>
    </Row>
  );
}
