import React from 'react';

import { useQuery } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { editRewardItem, updateRewardItem } from '@requests/rewards/items';
import Form from './Form';

export default function Edit() {
  const { response: data, setResponse } = useQuery(editRewardItem);

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Game items',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => updateRewardItem(values).then((response) => responseHandler(response, values));

  return data && (
    <Form
      data={data}
      onSubmit={onSubmit}
      setData={setResponse}
    />
  );
}

