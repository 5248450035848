import React from 'react';
import { get } from 'lodash';

import { Label, Field, SelectField } from '@controls/form';
import { FormGroup, useFormContext, useFormGroup } from '@hooks';
import getFieldLocale from '@services/getFieldLocale';

const TRIGGER_OPTIONS = [
  { label: 'After X Lobby/Stats/Classic screen views in a day', value: 'surfacing_after_x_lobby_stats_views' },
  { label: 'After X Classic games in a day ', value: 'surfacing_after_x_classic_games' },
  { label: 'After DC win', value: 'surfacing_after_daily_challenge_win' },
];

const ConfigurationFields = ({ disabled, selectedTrigger }) => {
  switch (selectedTrigger) {
    case 'surfacing_after_x_lobby_stats_views':
      return (
        <Label text="X" direction="vertical" tooltipText={getFieldLocale('gameSettings', 'xLobbyStatsView').tooltip}>
          <Field disabled={disabled} name="xLobbyStatsView" type="number" />
        </Label>
      );
    case 'surfacing_after_x_classic_games':
      return (
        <Label text="X" direction="vertical" tooltipText={getFieldLocale('gameSettings', 'xClassicGames').tooltip}>
          <Field disabled={disabled} name="xClassicGames" type="number" />
        </Label>
      );
    default:
      return ('');
  }
};

export default function JourneySurfacingPopupsBlock({ name: groupName, disabled }) {
  const { values } = useFormContext();
  const { generateName } = useFormGroup();

  const selectedTrigger = get(values, generateName(`${groupName}.triggerName`));

  return (
    <FormGroup name={groupName}>
      <SelectField
        isDisabled={disabled}
        name="triggerName"
        options={TRIGGER_OPTIONS}
      />
      <p />
      <ConfigurationFields
        selectedTrigger={selectedTrigger}
        disabled={disabled}
      />
    </FormGroup>
  );
}
