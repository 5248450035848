import React from 'react';

import APP_DATA from '@services/appData';

import { useCurrentApplication, useI18n } from '@hooks';
import { combineReducers } from '@reducers';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import ExperimentScopedTabs from '@pages/ab-testing/experiments/ExperimentScopedTabs';
import formReducer from './formReducer';

const statusesEnum = APP_DATA.enums['Journeys::Statuses'];
const isArchived = (status) => status === statusesEnum.ARCHIVED;

export default function ExperimentScoped({
  data, setData, onSubmit, children, localePath, appSpecificConfigs,
}) {
  const { applicationKey, currentApplication: { id: applicationId } } = useCurrentApplication();
  const { translate } = useI18n();
  const { reducers = [], preprocessSubmit } = appSpecificConfigs[applicationKey];

  const variantEntityName = translate.fallback(`${localePath}.name`);

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: variantEntityName,
    actionName: 'update',
    setData,
  });

  const handleSubmit = (values) => {
    const processedValues = preprocessSubmit ? preprocessSubmit(values) : values;
    return onSubmit({ applicationId, ...processedValues }).then(responseHandler);
  };

  return (
    <ExperimentScopedTabs
      response={data}
      entityName={variantEntityName}
      handleSubmit={handleSubmit}
      reducer={combineReducers([formReducer, ...reducers])}
      disableSubmit={({ status }) => isArchived(status)}
    >
      {children}
    </ExperimentScopedTabs>
  );
}
