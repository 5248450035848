import React from 'react';
import { Col } from 'react-bootstrap';

import {
  Filter,
  TextFilter,
  SelectFilter,
  InUseSelectFilter,
} from '@pages/common';

import gameModeOptions from './gameModeHelper';

export default function ModesConfigsFilter() {
  return (
    <Filter defaultComponents={['availabilityStateIn', 'id']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="typeIn"
              label="Mode"
              updateFilterField={updateFilterField}
              options={gameModeOptions}
            />
          </Col>
          <Col md={6}>
            <InUseSelectFilter updateFilterField={updateFilterField} />
          </Col>
        </>
      )}
    </Filter>
  );
}
