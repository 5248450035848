import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { BooleanIcon } from '@pages/common';
import {
  Label, Field, ServerError, SelectField,
} from '@controls/form';
import { CopyToClipboardAddon } from '@controls/CopyToClipboardButton';
import { BanksEdit } from '@pages/metagame/chapter-based';
import entityIdentity from '@components/shared/entityIdentity';
import { fromClassNameToOptions, localizedEnumValueString } from '@services/enums';

import LevelQRCode from './LevelQRCode';

const difficultyOptions = fromClassNameToOptions('Woo::Aspen::LevelLayoutDifficulties');

const humanizedDifficulty = (value) => localizedEnumValueString('Woo::Aspen::LevelLayoutDifficulties', value);

const EntityHeader = ({ entity, entityName }) => {
  const {
    contentHash, name, validForUse, updatedAt, index, difficulty,
  } = entity;

  return (
    <table className="table table-borderless mb-0">
      <tbody>
        <tr className="d-flex align-items-center">
          <td className="bg-transparent text-break p-0 col-1">
            {entityIdentity({ entity: { ...entity, name: null }, entityName })}
          </td>
          <td className="bg-transparent text-break p-0 ps-2 col-3">
            <div>
              {'Hash: '}
              {contentHash}
            </div>
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {'Layout Name: '}
            {name}
            <ServerError name={`levelLayoutsAttributes.${index}.name`} full />
          </td>
          <td className="bg-transparent text-break col-2 align-middle">
            <td className="p-0 col-5">
              {'Difficulty: '}
              {humanizedDifficulty(difficulty)}
              <ServerError name={`levelLayoutsAttributes.${index}.difficulty`} full />
            </td>
            <td className="p-0 col-7">
              {'Valid for use: '}
              <BooleanIcon value={validForUse} className="ms-0" />
            </td>
          </td>
          <td className="bg-transparent text-break p-0 ps-2 col-3">
            {'Updated at: '}
            {updatedAt}
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-1" aria-label="section" />
        </tr>
      </tbody>
    </table>
  );
};

const levelLayoutFields = ({
  entity: { id, contentHash }, applicationId, LevelLayoutRoutes, disabled,
}) => (
  <div className="d-flex flex-row justify-content-around">
    <div className="flex-fill pe-2">
      {id && (
        <Label text="Level Editor link">
          <LevelQRCode levelId={id} routes={LevelLayoutRoutes} applicationId={applicationId} />
        </Label>
      )}

      <Label text="Layout Name">
        <Field name="name" disabled={disabled} />
      </Label>
      <Label text="Difficulty">
        <SelectField
          name="difficulty"
          isDisabled={disabled}
          options={difficultyOptions}
        />
      </Label>
      <Label text="Hash">
        <span className="d-flex align-items-center">
          <InputGroup>
            <Field name="contentHash" disabled />
            <CopyToClipboardAddon text={contentHash} />
          </InputGroup>
        </span>
      </Label>
    </div>
  </div>
);

export default function Edit(props) {
  return (
    <BanksEdit
      EntityHeader={EntityHeader}
      levelLayoutFields={levelLayoutFields}
      {...props}
    />
  );
}
