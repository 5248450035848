import { useCallback } from 'react';
import selectBuilder from '@services/fields-factory/SelectBuilder';

import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { getManifestSets } from '@requests/my-pet-cafe/manifestSets';

export default function fieldsFactory({ applicationId }) {
  return (
    {
      ...selectBuilder(
        {
          name: 'abTestingId',
          labelText: 'A/B testing id',
          options: useCallback(() => requestWithToSelectOptions(getManifestSets, applicationId), []),
        },
      ),
    }
  );
}
