import React from 'react';
import { WoodokuRoutes as Routes } from '@pages/routes';
import { Col, Row } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import IconButton from '@controls/buttons';
import { fromClassNameToOptions } from '@services/enums';
import { Form, FormGroup } from '@hooks';
import { DuplicateButton, PageHeader, PageSection } from '@pages/common';
import {
  FormButtonsGroup,
  Field,
  Label,
  StaticFieldsFormGroup,
  SelectField,
  ServerError,
  LabelsFormGroup,
} from '@controls/form';
import reducer from './reducer';

const conditionTypeOptions = fromClassNameToOptions('Woodoku::TriggerConditionTypes');
const rowOperators = fromClassNameToOptions('Woodoku::TriggerConditions::RowOperators');

export default function TriggerConditionsFrom({
  data,
  onSubmit,
}) {
  const { query } = useRouter();
  const { applicationId } = query;

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit} reducer={reducer}>
      {({ values, dispatch }) => {
        const firstVisibleRowIndex = values.rowsAttributes.findIndex((r) => !r._destroy);
        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Trigger Conditions`}>
              <FormButtonsGroup cancelButtonPath={Routes.TriggerConditions.indexPath({ applicationId })}>
                <DuplicateButton
                  routes={Routes.TriggerConditions}
                  data={data}
                  entityName="Trigger Conditions"
                />
              </FormButtonsGroup>
            </PageHeader>

            <Label text="Name" fieldSize={8}>
              <Field name="name" />
            </Label>

            <LabelsFormGroup />

            <StaticFieldsFormGroup data={data} />

            <PageSection
              title="Conditions"
              tooltip="Add conditions which should be met in order to activate this trigger.
               Please note that in order to activate trigger, all added below conditions should be met"
            />
            <ServerError name="rowsAttributes.list" />
            {values.rowsAttributes.map((row, index) => (
              !row._destroy && (
                <FormGroup key={row._uuid} name={`rowsAttributes.${index}`}>
                  {firstVisibleRowIndex !== index && <div className="mb-2">AND</div>}
                  <Row className="mb-2">
                    <Col xs={4}>
                      <SelectField
                        options={conditionTypeOptions}
                        name="key"
                      />
                    </Col>
                    <Col xs={2}>
                      <SelectField
                        options={rowOperators}
                        name="operator"
                      />
                    </Col>
                    <Col xs={4}>
                      <Field type="number" name="value" />
                    </Col>
                    <Col xs={2}>
                      <IconButton.Delete
                        minimized
                        onClick={() => dispatch({
                          actionType: 'removeRow',
                          index,
                        })}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              )
            ))}
            <IconButton.New
              className="mt-3"
              onClick={() => dispatch({ actionType: 'addRow' })}
            >
              Add
            </IconButton.New>
          </>
        );
      }}
    </Form>
  );
}
