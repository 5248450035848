import React, { useState } from 'react';
import { ButtonToolbar, useRouter, Spinner } from '@tripledotstudios/react-core';
import { TileRoutes } from '@pages/routes';
import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InUseColumn,
  InLiveColumn,
  DuplicationModal,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { useQuery, useConfirm } from '@hooks';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

import Filter from './Filter';

export const difficultySchemasColumns = ({ applicationId, onDelete, onDuplicate }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'DDA schema name', accessor: 'name' },
  { Header: 'Type', accessor: 'schemaType' },
  InUseColumn,
  InLiveColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, inUse, name } } }) => {
      const disabled = isInUse(inUse);
      return (
        <ButtonToolbar>
          <IconButton.Edit
            to={TileRoutes.DifficultySchemas.editPath({ id, applicationId })}
            readonly={disabled}
            minimized
          />
          <IconButton.Duplicate onClick={() => onDuplicate({ id, name })} minimized />
          <IconButton.Delete
            onClick={onDelete(id, name)}
            disabled={disabled}
            title={disabled ? 'Disabled as the entity is used' : null}
            minimized
          />
        </ButtonToolbar>
      );
    },
  },
];

const DifficultySchemasIndex = () => {
  const { response, refetch, isLoading } = useQuery(TileRoutes.DifficultySchemas.indexRequest, { includeInUse: true });
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'DDA schema',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `DDA schema '${name}' will be deleted and not available for work. Continue?` })
      .then(() => TileRoutes.DifficultySchemas.deleteRequest({ applicationId, id })
        .then(responseHandler));
  };

  const columns = React.useMemo(() => difficultySchemasColumns({
    applicationId, onDelete, onDuplicate: setDuplicatedRecord,
  }), []);

  return response ? (
    <div>
      <PageHeader title="DDA Schemas" filter={<Filter />}>
        <IconButton.New to={TileRoutes.DifficultySchemas.newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? <Spinner /> : (
        <>
          <ReactTable
            columns={columns}
            data={response ? response.items : []}
            defaultSort={{ id: 'createdAt', desc: true }}
          />
          <DuplicationModal
            entityName="DDA Schema"
            record={duplicatedRecord}
            onSubmit={({ id, name }) => TileRoutes.DifficultySchemas.duplicateRequest({ id, name, applicationId })}
            handleClose={() => setDuplicatedRecord(null)}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </div>
  ) : '';
};

export default DifficultySchemasIndex;
