import React from 'react';
import { useQuery } from '@hooks';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function New() {
  const { response, setResponse } = useQuery(Routes.Abilities.Names.newRequest);

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Ability Name',
    actionName: 'creation',
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.Abilities.Names.createRequest(values).then(redirectIfSuccessHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      actionName="Create"
    />
  );
}
