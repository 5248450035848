import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { FormGroup, useFormContext } from '@hooks';
import { Label, SelectField } from '@controls/form';
import { fromClassNameToOptions } from '@services/enums';
import IconButton from '@controls/buttons';

import Points from './Points';

export default function AbilityImpactsForm({ values, disabled }) {
  const { dispatch } = useFormContext();
  const typeOptions = fromClassNameToOptions('WoodokuSaga::ImpactAreaTypes');
  let position = 0;

  return (
    <>
      {values.impactAreasAttributes.map(({ areaType, points, _destroy }, index) => {
        if (index === 0) position = 0;
        if (_destroy) return null;

        position += 1;
        return (
          <FormGroup name={`impactAreasAttributes[${index}]`}>
            <Row className="mb-2">
              <Col xs="2" xl="1">
                <IconButton.Delete
                  onClick={() => dispatch({ type: 'removeEntity', index })}
                  minimized
                  disabled={disabled}
                />
                <h4 className="d-inline ms-2">{position}</h4>
              </Col>
              <Col xs="10" xl="11" className="p-0">
                <Card>
                  <Card.Body>
                    <Label required text="Type" labelSize={2} fieldSize={6}>
                      <SelectField
                        name="areaType"
                        options={typeOptions}
                        onChange={({ value }) => dispatch({ type: 'areaTypeChange', index, value })}
                        isDisabled={disabled}
                      />
                    </Label>
                    <Label required text="Position" labelSize={2} fieldSize={10}>
                      <Points type={areaType} points={points} areaIndex={index} disabled={disabled} />
                    </Label>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </FormGroup>
        );
      })}
      <div className="text-end mt-4">
        <IconButton.New onClick={() => dispatch({ type: 'addEmptyEntity' })} disabled={disabled}>
          Add
        </IconButton.New>
      </div>
    </>
  );
}
