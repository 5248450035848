export default function Reducer(state, action) {
  if (action.type === 'changeEventSchemaId') {
    const variantAttributes = state.configAttributes.currentVariantAttributes;
    variantAttributes.itemConfigurationSetId = null;
    variantAttributes.levelSetId = null;
    variantAttributes.ordersConfigurationId = null;
    variantAttributes.startingGridId = null;
    variantAttributes.unbubblingConfigurationAttributes.schemaId = action.schemaId;
  }

  return state;
}
