import React from 'react';

import { FormGroup } from '@hooks';
import { PageSection } from '@pages/common';

import Section from './Section';

export default function ConditionsFormGroup({ rootValues, values, disabled }) {
  return (
    <>
      <PageSection title="Conditions" />

      <FormGroup name="conditionsSectionAttributes">
        <Section
          rootValues={rootValues || values}
          attributes={values.conditionsSectionAttributes}
          nestingLevel={0}
          disabled={disabled}
        />
      </FormGroup>
    </>
  );
}
