import React, {
  useState, useEffect, createContext, useContext, useMemo,
} from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { AbTestingRoutes } from '@pages/routes';

const abTestingContext = createContext();

export function AbTestingContext({ entityType, entityId, children }) {
  const { query } = useRouter();
  const { applicationId } = query;

  const [entityUsage, setEntityUsage] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AbTestingRoutes.Experiments.entityUsageRequest({ applicationId, entity: { entityType, entityId } })
      .then((response) => setEntityUsage(response.data))
      .finally(() => setIsLoading(false));
  }, []);

  const abTestingBag = useMemo(() => ({
    entityData: { entityType, entityId },
    entityUsage,
    isLoading,
  }), [entityType, entityId, entityUsage, isLoading]);

  return (
    <abTestingContext.Provider value={abTestingBag}>
      {children}
    </abTestingContext.Provider>
  );
}

export function useAbTestingContext() {
  return useContext(abTestingContext);
}
