import React from 'react';
import { isEqual } from 'lodash';

import APP_DATA from '@services/appData';

import {
  Label, SelectField, TimePeriodField, DateTimeField,
} from '@controls/form';
import { PageSection } from '@pages/common';

const NotLongLeftPopupField = ({ sharedTooltips, activationEvent }) => {
  const FieldComponent = activationEvent ? TimePeriodField : DateTimeField;
  const tooltip = activationEvent ? sharedTooltips.notLongLeftBefore : sharedTooltips.notLongLeftPopup;
  const name = activationEvent ? 'notLongLeftBefore' : 'notLongLeftTime';

  return (
    <Label
      text={activationEvent ? 'Not Long Left Pop-up appears before' : 'Not Long Left Pop-up appears from'}
      tooltipText={tooltip}
    >
      <FieldComponent name={name} />
    </Label>
  );
};

const JourneySharedPopupSections = React.memo(({ localizationKeys, activationEvent = false }) => {
  const { modelsLocales: { shared: { journeys: { tooltips: sharedTooltips } } } } = APP_DATA;

  return (
    <>
      <PageSection title="Start Pop-up" />
      <Label
        text="Start Pop-up Heading translation key"
        tooltipText={sharedTooltips.surfacingHeadingTranslationKeyId}
      >
        <SelectField name="surfacingHeadingTranslationKeyId" options={localizationKeys} includeEmpty />
      </Label>
      <Label
        text="Start Pop-up Message translation key"
        tooltipText={sharedTooltips.surfacingMessageTranslationKeyId}
      >
        <SelectField name="surfacingMessageTranslationKeyId" options={localizationKeys} includeEmpty />
      </Label>

      <PageSection title="Not Long Left Pop-up" />
      <NotLongLeftPopupField sharedTooltips={sharedTooltips} activationEvent={activationEvent} />
      <Label
        text="Not Long Left Pop-up Heading translation key"
        tooltipText={sharedTooltips.notLongLeftHeadingTranslationKeyId}
      >
        <SelectField name="notLongLeftHeadingTranslationKeyId" options={localizationKeys} includeEmpty />
      </Label>
      <Label
        text="Not Long Left Pop-up Message translation key"
        tooltipText={sharedTooltips.notLongLeftMessageTranslationKeyId}
      >
        <SelectField name="notLongLeftMessageTranslationKeyId" options={localizationKeys} includeEmpty />
      </Label>

      <PageSection title="End Pop-up" />
      <Label
        text="End Pop-up Heading translation key"
        tooltipText={sharedTooltips.endHeadingTranslationKeyId}
      >
        <SelectField name="endHeadingTranslationKeyId" options={localizationKeys} includeEmpty />
      </Label>
      <Label
        text="End Pop-up Message translation key"
        tooltipText={sharedTooltips.endMessageTranslationKeyId}
      >
        <SelectField name="endMessageTranslationKeyId" options={localizationKeys} includeEmpty />
      </Label>
    </>
  );
}, (prev, next) => isEqual(prev.localizationKeys, next.localizationKeys));

export default JourneySharedPopupSections;
