import { memoize } from 'lodash';

import { fieldBuilder, selectBuilder } from '@services/fields-factory';
import { WooAspenRoutes as Routes } from '@pages/routes';

import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';
import selectOptionsWithQuickLink from '@services/selectOptionsWithQuickLink';

import { adRequestDelayFields } from '@components/game-settings/Resource/Shared';

export const popupsOptions = [
  { label: 'IDFA', value: 'IDFA' },
  { label: 'GDPR', value: 'GDPR' },
  { label: 'Push Notifications', value: 'Allow_Notifications' },
  { label: 'Google CMP', value: 'Google_CMP' },
  { label: 'Push notifications retry', value: 'PushNotifications_Retry_OptIn' },
];

const Fields = memoize(({ chapterSetOptions }) => ({
  ...fieldBuilder({ name: 'everythingJson', type: 'text', fieldOptions: { component: 'textarea' } }),
  ...selectBuilder({ name: 'chapterSetId', ...chapterSetOptions }),
}), (args) => JSON.stringify(args));

const fieldsFactory = ({ applicationId }) => {
  const chapterSetOptions = selectOptionsWithQuickLink(Routes.ChapterSets, applicationId);

  return Fields({
    applicationId,
    chapterSetOptions,
  });
};

export default combineWithSharedFieldsFactory([fieldsFactory, adRequestDelayFields], { popupsOptions });
