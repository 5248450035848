import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { IconButton, ButtonToolbar } from '@tripledotstudios/react-core';

import BulkDuplicateModal from '@pages/metagame/chapter-based/chapter-sets/BulkDuplicateModal';
import Chapters from '@pages/metagame/chapter-based/chapter-sets/Chapters';
import ChapterFields from './ChapterFields';

import BulkUpdateModal from './BulkUpdateModal';

const MassOperations = ({
  chapterSetId, dispatch, selectedItems, setMassOperationsMode, values, rewardItems,
}) => {
  const [bulkUpdateRecords, setBulkUpdateRecords] = useState(null);
  const [bulkCopyChaptersRecords, setBulkCopyChaptersRecords] = useState([]);

  return (
    <>
      <ButtonToolbar className="d-inline-flex">
        <Button
          onClick={() => setBulkUpdateRecords(selectedItems)}
          disabled={selectedItems.length === 0}
        >
          Mass Update
        </Button>
        <IconButton.Duplicate
          variant="primary"
          onClick={() => setBulkCopyChaptersRecords(selectedItems)}
          disabled={selectedItems.length === 0}
        >
          Copy to Chapter Set
        </IconButton.Duplicate>
        <IconButton.Cancel onClick={() => setMassOperationsMode(false)} variant="secondary">
          Cancel
        </IconButton.Cancel>
      </ButtonToolbar>
      <div className="text-secondary mt-1">
        Select the chapters for mass update or copy to another Chapter set with checkboxes
      </div>
      <BulkUpdateModal
        chapters={bulkUpdateRecords}
        dispatch={dispatch}
        handleClose={() => {
          setBulkUpdateRecords(null);
          setMassOperationsMode(false);
        }}
        rewardItems={rewardItems}
        values={values}
      />
      <BulkDuplicateModal
        chapterSetId={chapterSetId}
        chapters={bulkCopyChaptersRecords}
        dispatch={dispatch}
        handleClose={() => {
          setBulkCopyChaptersRecords([]);
          setMassOperationsMode(false);
        }}
      />
    </>
  );
};

export default function LorayChapters() {
  return (
    <Chapters
      ChapterFields={ChapterFields}
      MassOperations={MassOperations}
    />
  );
}
