import React from 'react';
import { Col } from 'react-bootstrap';

import { Label } from '@controls/form';

import HintField from './HintField';

export default function HintFieldBuilder({
  name: baseName, labelText, fieldOptions, tooltips = {},
}) {
  return {
    [baseName]: {
      Inline: ({ name }) => (
        <HintField name={name || baseName} {...fieldOptions} />
      ),
      Labeled: ({ name, disabled }) => (
        <Col>
          <Label text={labelText} fieldSize={8} tooltipText={tooltips[baseName]}>
            <HintField
              name={name || baseName}
              disabled={disabled}
              {...fieldOptions}
            />
          </Label>
        </Col>
      ),
    },
  };
}
