import React from 'react';
import { Link } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import { Row, Col } from 'react-bootstrap';
import Tooltip from '@controls/Tooltip';
import OverlayTooltip from '@controls/OverlayTooltip';
import styled, { css } from 'styled-components';

const TextLabel = styled.label`
  font-weight: bold;

  ${({ required }) => required && css`
    ::after {
      content: '*';
      margin-left: 3px;
      vertical-align: text-top;
    }
  `}
`;

export default function Label({
  direction = 'horizontal', labelSize = 4, fieldSize = 6, tooltipPlacement = 'right', className = '', text, tooltipText,
  children, link, linkText, copyableText, externalLink = false, required = false, wrapperClassName = '',
  withMargins = true,
}) {
  const labelProps = { required };
  if (children) labelProps.labelFor = children.id;

  const renderLabel = (labelClassName) => {
    const resolvedClassName = classNames(className, labelClassName);

    if (tooltipText) {
      return (
        /* eslint-disable react/no-danger */
        <TextLabel className={resolvedClassName} {...labelProps}>
          <Tooltip
            text={<div dangerouslySetInnerHTML={{ __html: ReactDOMServer.renderToStaticMarkup(tooltipText) }} />}
            placement={tooltipPlacement}
            copyableText={copyableText}
          >
            {text}
          </Tooltip>
        </TextLabel>
        /* eslint-enable react/no-danger */
      );
    }
    if (link) {
      return (
        <TextLabel className={resolvedClassName} {...labelProps}>
          {text}
          <Link
            to={externalLink ? { pathname: link } : link}
            target="_blank"
            className="ms-2"
          >
            <OverlayTooltip text={linkText || link} placement="bottom">
              <FontAwesomeIcon icon={faLink} />
            </OverlayTooltip>
          </Link>
        </TextLabel>
      );
    }

    return <TextLabel className={resolvedClassName} {...labelProps}>{text}</TextLabel>;
  };

  return direction === 'vertical' ? (
    <div className={classNames('flex flex-column', wrapperClassName, { 'mb-3': withMargins })}>
      <div>
        {renderLabel()}
      </div>
      <div>
        {children}
      </div>
    </div>
  ) : (
    <Row className={classNames(wrapperClassName, { 'mb-3': withMargins })}>
      <Col xs={labelSize}>
        {renderLabel('col-form-label')}
      </Col>

      <Col xs={fieldSize}>
        {children}
      </Col>
    </Row>
  );
}
