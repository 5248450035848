import React from 'react';

import { useQuery } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { Match3dRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { response: data, setResponse } = useQuery(Routes.PersonalLevelSets.editRequest);

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Star level set',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    Routes.PersonalLevelSets.updateRequest(values).then((response) => responseHandler(response, values))
  );

  return data && <Form data={data} onSubmit={onSubmit} setData={setResponse} />;
}
