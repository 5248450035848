import React from 'react';
import { PageTitle } from '@pages/common';

import WorldSchemasIndex from '@components/merge/world-schemas/Index';
import WorldSchemasEdit from '@components/merge/world-schemas/Edit';

import EventSchemasIndex from '@components/merge/events/schemas/Index';
import EventSchemasEdit from '@components/merge/events/schemas/Edit';
import OrdersConfigurationsIndex from '@components/merge/events/orders-configurations/Index';
import OrdersConfigurationsEdit from '@components/merge/events/orders-configurations/Edit';
import OrdersConfigurationsNew from '@components/merge/events/orders-configurations/New';
import LevelSetsIndex from '@components/merge/events/level-sets/Index';
import LevelSetsEdit from '@components/merge/events/level-sets/Edit';
import LevelSetsNew from '@components/merge/events/level-sets/New';

import PostcardSetsIndex from '@components/merge/postcard-sets/Index';
import PostcardSetsNew from '@components/merge/postcard-sets/New';
import PostcardSetsEdit from '@components/merge/postcard-sets/Edit';

import ExpertSetsIndex from '@components/merge/expert-sets/Index';
import ExpertSetsNew from '@components/merge/expert-sets/New';
import ExpertSetsEdit from '@components/merge/expert-sets/Edit';

import WorldsIndex from '@components/merge/worlds/Index';
import WorldsNew from '@components/merge/worlds/New';
import WorldsEdit from '@components/merge/worlds/Edit';

import LevelsIndex from '@components/merge/level-sets/Index';
import LevelsNew from '@components/merge/level-sets/New';
import LevelsEdit from '@components/merge/level-sets/Edit';

import UnbubblingConfigurationsIndex from '@components/merge/unbubbling-configurations/Index';
import UnbubblingConfigurationsNew from '@components/merge/unbubbling-configurations/New';
import UnbubblingConfigurationsEdit from '@components/merge/unbubbling-configurations/Edit';

import ProductsIndex from '@components/merge/products/Index';
import ProductsNew from '@components/merge/products/New';
import ProductsEdit from '@components/merge/products/Edit';

import ProductCatalogsIndex from '@components/merge/product-catalogs/Index';
import ProductCatalogsNew from '@components/merge/product-catalogs/New';
import ProductCatalogsEdit from '@components/merge/product-catalogs/Edit';

import InventoryConfigurationsIndex from '@components/merge/inventory-configurations/Index';
import InventoryConfigurationsNew from '@components/merge/inventory-configurations/New';
import InventoryConfigurationsEdit from '@components/merge/inventory-configurations/Edit';

import * as BaseGameStartingGridsViews from '@components/merge/base-game/starting-grids';
import * as EventsStartingGridsViews from '@components/merge/events/starting-grids';
import * as BaseGameItemConfigurationSetsViews from '@components/merge/base-game/item-configuration-sets';
import * as EventsItemConfigurationSetsViews from '@components/merge/events/item-configuration-sets';

import BundleCatalogsIndex from '@components/merge/bundle-catalogs/Index';
import BundleCatalogsNew from '@components/merge/bundle-catalogs/New';
import BundleCatalogsEdit from '@components/merge/bundle-catalogs/Edit';

import PostcardConfigurationsIndex from '@components/merge/postcard-configurations/Index';
import PostcardConfigurationsNew from '@components/merge/postcard-configurations/New';
import PostcardConfigurationsEdit from '@components/merge/postcard-configurations/Edit';

import ExpertConfigurationsIndex from '@components/merge/expert-configurations/Index';
import ExpertConfigurationsNew from '@components/merge/expert-configurations/New';
import ExpertConfigurationsEdit from '@components/merge/expert-configurations/Edit';

import CutscenesConfigurationsIndex from '@components/merge/cutscenes-configurations/Index';
import CutscenesConfigurationsNew from '@components/merge/cutscenes-configurations/New';
import CutscenesConfigurationsEdit from '@components/merge/cutscenes-configurations/Edit';

import ContactsIndex from '@components/merge/contacts/Index';

import { JourneysExperimentScoped } from '@pages/merge';
import { MergeRoutes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

const StartingGridRoutes = ({
  Edit, New, Index, routePostfix, ...componentProps
}) => [
  <Route
    key={`${MergeRoutes.StartingGrids.indexRawPath}/${routePostfix}`}
    path={`${MergeRoutes.StartingGrids.indexRawPath}/${routePostfix}`}
    element={(
      <RouteContainer fullWidth>
        <PageTitle text="Starting Grids" />
        <Index routePostfix={routePostfix} {...componentProps} />
      </RouteContainer>
    )}
  />,
  <Route
    key={`${MergeRoutes.StartingGrids.newRawPath}/${routePostfix}`}
    path={`${MergeRoutes.StartingGrids.newRawPath}/${routePostfix}`}
    element={(
      <RouteContainer>
        <PageTitle entity="Starting Grid" action="New" />
        <New routePostfix={routePostfix} {...componentProps} />
      </RouteContainer>
    )}
  />,
  <Route
    key={`${MergeRoutes.StartingGrids.editRawPath}/${routePostfix}`}
    path={`${MergeRoutes.StartingGrids.editRawPath}/${routePostfix}`}
    element={(
      <RouteContainer>
        <PageTitle entity="Starting Grid" action="Edit" />
        <Edit routePostfix={routePostfix} {...componentProps} />
      </RouteContainer>
    )}
  />,
];

const ItemConfigurationSetRoutes = ({
  Edit, New, Index, routePostfix, entityName, ...componentProps
}) => [
  <Route
    key={`${MergeRoutes.ItemConfigurationSets.indexRawPath}/${routePostfix}`}
    path={`${MergeRoutes.ItemConfigurationSets.indexRawPath}/${routePostfix}`}
    element={(
      <RouteContainer fullWidth>
        <PageTitle text={`${entityName}s`} />
        <Index routePostfix={routePostfix} entityName={entityName} {...componentProps} />
      </RouteContainer>
    )}
  />,
  <Route
    key={`${MergeRoutes.ItemConfigurationSets.newRawPath}/${routePostfix}`}
    path={`${MergeRoutes.ItemConfigurationSets.newRawPath}/${routePostfix}`}
    element={(
      <RouteContainer fullWidth>
        <PageTitle entity={entityName} action="New" />
        <New routePostfix={routePostfix} entityName={entityName} {...componentProps} />
      </RouteContainer>
    )}
  />,
  <Route
    key={`${MergeRoutes.ItemConfigurationSets.editRawPath}/${routePostfix}`}
    path={`${MergeRoutes.ItemConfigurationSets.editRawPath}/${routePostfix}`}
    element={(
      <RouteContainer fullWidth>
        <PageTitle entity={entityName} action="Edit" />
        <Edit routePostfix={routePostfix} entityName={entityName} {...componentProps} />
      </RouteContainer>
    )}
  />,
];

export default function merge() {
  return [
    // World Schemas
    <Route
      key={MergeRoutes.WorldSchemas.indexRawPath}
      path={MergeRoutes.WorldSchemas.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="World Schemas" />
          <WorldSchemasIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.WorldSchemas.editRawPath}
      path={MergeRoutes.WorldSchemas.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="World Schema" action="Edit" />
          <WorldSchemasEdit />
        </RouteContainer>
      )}
    />,

    // Event Schemas
    <Route
      key={MergeRoutes.Journeys.EventConfigs.experimentScopedRawPath}
      path={MergeRoutes.Journeys.EventConfigs.experimentScopedRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Journey variant" />
          <JourneysExperimentScoped />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.Events.Schemas.indexRawPath}
      path={MergeRoutes.Events.Schemas.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Event Schemas" />
          <EventSchemasIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.Events.Schemas.editRawPath}
      path={MergeRoutes.Events.Schemas.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Event Schema" action="Edit" />
          <EventSchemasEdit />
        </RouteContainer>
      )}
    />,

    // Orders Configurations
    <Route
      key={MergeRoutes.Events.OrdersConfigurations.indexRawPath}
      path={MergeRoutes.Events.OrdersConfigurations.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Orders Configurations" />
          <OrdersConfigurationsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.Events.OrdersConfigurations.newRawPath}
      path={MergeRoutes.Events.OrdersConfigurations.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Orders Configuration" action="New" />
          <OrdersConfigurationsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.Events.OrdersConfigurations.editRawPath}
      path={MergeRoutes.Events.OrdersConfigurations.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Orders Configuration" action="Edit" />
          <OrdersConfigurationsEdit />
        </RouteContainer>
      )}
    />,

    // Reward Track Configurations
    <Route
      key={MergeRoutes.Events.LevelSets.indexRawPath}
      path={MergeRoutes.Events.LevelSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Reward Track Configurations" />
          <LevelSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.Events.LevelSets.newRawPath}
      path={MergeRoutes.Events.LevelSets.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Reward Track Configuration" action="New" />
          <LevelSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.Events.LevelSets.editRawPath}
      path={MergeRoutes.Events.LevelSets.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Reward Track Configuration" action="Edit" />
          <LevelSetsEdit />
        </RouteContainer>
      )}
    />,

    // Postcard Sets
    <Route
      key={MergeRoutes.PostcardSets.indexRawPath}
      path={MergeRoutes.PostcardSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Postcard Sets" />
          <PostcardSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.PostcardSets.newRawPath}
      path={MergeRoutes.PostcardSets.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Postcard Set" action="New" />
          <PostcardSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.PostcardSets.editRawPath}
      path={MergeRoutes.PostcardSets.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Postcard Set" action="Edit" />
          <PostcardSetsEdit />
        </RouteContainer>
      )}
    />,

    // Expert Sets
    <Route
      key={MergeRoutes.ExpertSets.indexRawPath}
      path={MergeRoutes.ExpertSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Expert Sets" />
          <ExpertSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.ExpertSets.newRawPath}
      path={MergeRoutes.ExpertSets.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Expert Set" action="New" />
          <ExpertSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.ExpertSets.editRawPath}
      path={MergeRoutes.ExpertSets.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Expert Set" action="Edit" />
          <ExpertSetsEdit />
        </RouteContainer>
      )}
    />,

    // Worlds
    <Route
      key={MergeRoutes.Worlds.indexRawPath}
      path={MergeRoutes.Worlds.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Worlds" />
          <WorldsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.Worlds.newRawPath}
      path={MergeRoutes.Worlds.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="World" action="New" />
          <WorldsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.Worlds.editRawPath}
      path={MergeRoutes.Worlds.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="World" action="Edit" />
          <WorldsEdit />
        </RouteContainer>
      )}
    />,

    // Levels
    <Route
      key={MergeRoutes.LevelSets.indexRawPath}
      path={MergeRoutes.LevelSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Level Sets" />
          <LevelsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.LevelSets.newRawPath}
      path={MergeRoutes.LevelSets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Set" action="New" />
          <LevelsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.LevelSets.editRawPath}
      path={MergeRoutes.LevelSets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Set" action="Edit" />
          <LevelsEdit />
        </RouteContainer>
      )}
    />,

    // Unbubbling Configurations
    <Route
      key={MergeRoutes.UnbubblingConfigurations.indexRawPath}
      path={MergeRoutes.UnbubblingConfigurations.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Unbubbling Configurations" />
          <UnbubblingConfigurationsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.UnbubblingConfigurations.newRawPath}
      path={MergeRoutes.UnbubblingConfigurations.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Unbubbling Configuration" action="New" />
          <UnbubblingConfigurationsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.UnbubblingConfigurations.editRawPath}
      path={MergeRoutes.UnbubblingConfigurations.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Unbubbling Configuration" action="Edit" />
          <UnbubblingConfigurationsEdit />
        </RouteContainer>
      )}
    />,

    // Products
    <Route
      key={MergeRoutes.Products.indexRawPath}
      path={MergeRoutes.Products.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Products" />
          <ProductsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.Products.newRawPath}
      path={MergeRoutes.Products.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Product" action="New" />
          <ProductsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.Products.editRawPath}
      path={MergeRoutes.Products.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Product" action="Edit" />
          <ProductsEdit />
        </RouteContainer>
      )}
    />,

    // Currency Catalogs
    <Route
      key={MergeRoutes.ProductCatalogs.indexRawPath}
      path={MergeRoutes.ProductCatalogs.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Currency Catalogs" />
          <ProductCatalogsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.ProductCatalogs.newRawPath}
      path={MergeRoutes.ProductCatalogs.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Currency Catalog" action="New" />
          <ProductCatalogsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.ProductCatalogs.editRawPath}
      path={MergeRoutes.ProductCatalogs.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Currency Catalog" action="Edit" />
          <ProductCatalogsEdit />
        </RouteContainer>
      )}
    />,

    // Inventory Configurations
    <Route
      key={MergeRoutes.InventoryConfigurations.indexRawPath}
      path={MergeRoutes.InventoryConfigurations.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Inventory Configurations" />
          <InventoryConfigurationsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.InventoryConfigurations.newRawPath}
      path={MergeRoutes.InventoryConfigurations.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Inventory Configuration" action="New" />
          <InventoryConfigurationsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.InventoryConfigurations.editRawPath}
      path={MergeRoutes.InventoryConfigurations.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Inventory Configuration" action="Edit" />
          <InventoryConfigurationsEdit />
        </RouteContainer>
      )}
    />,

    // Starting Grids
    ...StartingGridRoutes({
      ...BaseGameStartingGridsViews,
      routePostfix: 'worlds',
      nameFieldLabel: 'Name',
      schemaType: 'Merge::WorldSchema',
      schemaTypeName: 'World Schema',
      schemaRequest: MergeRoutes.WorldSchemas.editRequest,
    }),
    ...StartingGridRoutes({
      ...EventsStartingGridsViews,
      routePostfix: 'events',
      nameFieldLabel: 'Name/Topic',
      schemaType: 'Merge::Events::Schema',
      schemaTypeName: 'Event Schema',
      schemaRequest: MergeRoutes.Events.Schemas.editRequest,
    }),

    // Item Configuration Sets
    ...ItemConfigurationSetRoutes({
      ...BaseGameItemConfigurationSetsViews,
      entityName: 'Item Configuration Set',
      excludedColumns: [],
      routePostfix: 'worlds',
      schemaType: 'Merge::WorldSchema',
      schemaTypeName: 'World Schema',
    }),
    ...ItemConfigurationSetRoutes({
      ...EventsItemConfigurationSetsViews,
      entityName: 'Event Item Set',
      excludedColumns: ['category', 'purchasable'],
      routePostfix: 'events',
      schemaType: 'Merge::Events::Schema',
      schemaTypeName: 'Event Schema',
    }),

    // Bundle Catalogs
    <Route
      key={MergeRoutes.BundleCatalogs.indexRawPath}
      path={MergeRoutes.BundleCatalogs.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Bundle Catalogs" />
          <BundleCatalogsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.BundleCatalogs.newRawPath}
      path={MergeRoutes.BundleCatalogs.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Bundle Catalog" action="New" />
          <BundleCatalogsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.BundleCatalogs.editRawPath}
      path={MergeRoutes.BundleCatalogs.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Bundle Catalog" action="Edit" />
          <BundleCatalogsEdit />
        </RouteContainer>
      )}
    />,

    // Postcard configuration
    <Route
      key={MergeRoutes.PostcardConfigurations.indexRawPath}
      path={MergeRoutes.PostcardConfigurations.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Postcard Configurations" />
          <PostcardConfigurationsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.PostcardConfigurations.newRawPath}
      path={MergeRoutes.PostcardConfigurations.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Postcard Configuration" action="New" />
          <PostcardConfigurationsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.PostcardConfigurations.editRawPath}
      path={MergeRoutes.PostcardConfigurations.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Postcard Configuration" action="Edit" />
          <PostcardConfigurationsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.Contacts.indexRawPath}
      path={MergeRoutes.Contacts.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Contacts" />
          <ContactsIndex />
        </RouteContainer>
      )}
    />,

    // Expert configuration
    <Route
      key={MergeRoutes.ExpertConfigurations.indexRawPath}
      path={MergeRoutes.ExpertConfigurations.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Expert Configurations" />
          <ExpertConfigurationsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.ExpertConfigurations.newRawPath}
      path={MergeRoutes.ExpertConfigurations.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Expert Configuration" action="New" />
          <ExpertConfigurationsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.ExpertConfigurations.editRawPath}
      path={MergeRoutes.ExpertConfigurations.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Expert Configuration" action="Edit" />
          <ExpertConfigurationsEdit />
        </RouteContainer>
      )}
    />,

    // Cutscenes Configurations
    <Route
      key={MergeRoutes.CutscenesConfigurations.indexRawPath}
      path={MergeRoutes.CutscenesConfigurations.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Cutscenes Configurations" />
          <CutscenesConfigurationsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.CutscenesConfigurations.newRawPath}
      path={MergeRoutes.CutscenesConfigurations.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Cutscene Configuration" action="New" />
          <CutscenesConfigurationsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MergeRoutes.CutscenesConfigurations.editRawPath}
      path={MergeRoutes.CutscenesConfigurations.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Cutscene Configuration" action="Edit" />
          <CutscenesConfigurationsEdit />
        </RouteContainer>
      )}
    />,
  ];
}
