import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { useFormContext, useFormGroup } from '@hooks';
import { SelectField } from '@controls/form';
import { NestedConditions, variants } from '@components/nested-conditions';
import IconButton from '@controls/buttons';

import { fieldsFor, typeOptions, keyOptionsFor } from './fields/registry';

const renderConditionFields = (type) => {
  if (!type) return null;

  const Fields = fieldsFor(type);
  return <Fields />;
};

const StyledCol = styled(Col)`
  border-right: 1px solid ${({ theme }) => theme.bootstrap.hr};
`;

export default function Condition({ attributes, nestingLevel }) {
  const { dispatch } = useFormContext();
  const { generateName } = useFormGroup();

  const conditionPath = generateName();
  const onConditionRemove = () => dispatch({ type: 'removeUnlockRequirementsCondition', conditionPath });
  const onConditionTypeChange = (condition) => (
    dispatch({ type: 'changeUnlockRequirementsConditionType', conditionPath, condition }));
  return (
    <NestedConditions.Condition variant={variants[nestingLevel]}>
      <Row>
        <Col sm={2}>
          <SelectField
            name="type"
            options={typeOptions}
            onChange={onConditionTypeChange}
            isDisabled={attributes.id}
          />
        </Col>
        <Col sm={4}>
          <SelectField
            name="key"
            options={keyOptionsFor(attributes.type)}
            hasPrepopulatedOption
          />
        </Col>
        <StyledCol sm={5}>
          {renderConditionFields(attributes.type)}
        </StyledCol>
        <Col sm={1}>
          <IconButton.Remove
            onClick={onConditionRemove}
            variant={variants[nestingLevel - 1]}
            opacity={0.7}
            minimized
          >
            Remove Condition
          </IconButton.Remove>
        </Col>
      </Row>
    </NestedConditions.Condition>
  );
}
