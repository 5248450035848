import React from 'react';
import camelCase from 'lodash/camelCase';
import Select from '@controls/Select';

import { useI18n } from '@hooks';

export const buildSettingLabel = ({
  field,
  localeNamespace,
  translate,
}) => {
  const camelizedField = camelCase(field);
  const locale = translate.fallback(`${localeNamespace}.${camelizedField}`);
  if (!locale) return camelizedField;

  const result = [locale.label];
  if (locale.clientKey) result.push(`(${locale.clientKey})`);

  return result.join(' ');
};

const buildSettingLabelWithStatus = ({ archivedFields, ...args }) => {
  const label = buildSettingLabel(args);
  return archivedFields.indexOf(args.field) > -1 ? `${label} (Archived)` : label;
};

export default function SettingsFieldsSelect({
  archivedFields = [], fields, localeNamespace, name = 'fieldName', includeArchived = true, ...props
}) {
  const { translate } = useI18n();

  let fieldsToShow = fields;
  if (!includeArchived) fieldsToShow = fieldsToShow.filter((field) => archivedFields.indexOf(field) < 0);
  const fieldOptions = fieldsToShow.map((field) => ({
    label: buildSettingLabelWithStatus({
      archivedFields,
      field,
      localeNamespace,
      translate,
    }),
    value: field,
  }));

  return fields && <Select name={name} options={fieldOptions} {...props} />;
}
