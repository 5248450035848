import camelize from 'camelcase-keys';
import underscore from 'snakecase-keys';
import {
  isPlainObject,
  isNull,
  omit,
  every,
} from 'lodash';

import qs from 'qs';

import axios from '@services/axios';
import {
  process as recursivelyProcess,
  addUuids as recursivelyAddUuids,
} from '@services/recursivelyProcessObject';
import renderPlainAlert from './plainAlert';

axios.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.paramsSerializer = {
    serialize: (params) => (
      qs.stringify(underscore(params, {
        exclude: [/^__.*$/],
      }), {
        arrayFormat: 'brackets',
        encode: true,
      })),
  };

  return config;
});

export function prepareInboundData(data, { skipUuids = false } = {}) {
  const processedData = skipUuids ? data : recursivelyAddUuids(data);

  return camelize(
    processedData, {
      exclude: ['_destroy', '_meta', '_uuid', '_data', /^-?\d+$/],
      deep: true,
    });
}

export function prepareOutboundData(data) {
  const dataWithoutUuids = recursivelyProcess(data, ({ _uuid, ...rest }) => rest);

  return underscore(dataWithoutUuids, { exclude: ['_destroy'], deep: true });
}

function withDefaultData(options) {
  return { ...options, data: options.data || {} };
}

function handleError(error) {
  if (error.response.status === 401) {
    window.location = '/admin/sign_in';

    throw new Error('Unauthorized');
  }

  if (error.response.status === 403) {
    renderPlainAlert({ message: error.response.data.reason });

    throw new Error('Access denied');
  }

  if (error.response.status === 404) {
    renderPlainAlert({ message: error.response.data.reason });

    throw new Error('Not found');
  }

  return error.response;
}

function createFormData(formData, path, data) {
  if (isNull(data)) {
    formData.append(path, '');
  } else if (isPlainObject(data)) {
    Object.entries(underscore(omit(data, ['_uuid']), { exclude: ['_destroy'], deep: false })).forEach(([key, val]) => {
      createFormData(formData, path ? `${path}[${key}]` : key, val);
    });
  } else if (Array.isArray(data)) {
    if (data.length === 0) {
      formData.append(`${path}[]`, null);
    }

    data.forEach((val, idx) => {
      const newPath = every(data, isPlainObject) ? `${path}[][${idx}]` : `${path}[]`;

      createFormData(formData, newPath, val);
    });
  } else {
    formData.append(path, data);
  }
}

function prepareFormData(data) {
  const formData = new FormData();
  createFormData(formData, null, data);
  return formData;
}

function processInbound(request, options) {
  return request.catch((error) => handleError(error)).then((response) => (
    {
      data: prepareInboundData(response.data, options),
      rawData: response.data,
      headers: response.headers,
      status: response.status,
    }
  ));
}

export function get(url, options = {}) {
  return processInbound(axios.get(url, withDefaultData(options)), options);
}

export function patch(url, data, options = {}) {
  const req = axios.patch(
    url,
    prepareOutboundData(data),
    withDefaultData(options),
  );
  return processInbound(req);
}

export function put(url, data, options = {}) {
  const req = axios.put(
    url,
    options.withRawData ? data : prepareOutboundData(data),
    withDefaultData(options),
  );
  return processInbound(req);
}

export function post(url, data, options = {}) {
  return processInbound(axios.post(
    url,
    prepareOutboundData(data),
    withDefaultData(options),
  ), options);
}

export function destroy(url, options = {}) {
  return processInbound(axios.delete(url, options));
}

export function putMultipart(url, data) {
  const request = axios.put(
    url,
    prepareFormData(data),
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );

  return processInbound(request);
}

export function postMultipart(url, data) {
  const request = axios.post(
    url,
    prepareFormData(data),
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );

  return processInbound(request);
}

export function fetchCollection(url, params = {}, options = {}) {
  return get(url, { ...options, params });
}

export function newResource(baseUrl, options = {}) {
  return get(`${baseUrl}/new`, options);
}

export function editResource(baseUrl, data, options = {}) {
  return get(`${baseUrl}/${data.id}/edit`, options);
}

export function getResource(baseUrl, data, options = {}) {
  return get(`${baseUrl}/${data.id}`, options);
}

export function createResource(url, data, options = {}) {
  return post(url, data, options);
}

export function createResourceMultipart(url, data, options = {}) {
  return postMultipart(url, data, options);
}

export function updateResource(baseUrl, data, options = {}) {
  return put(`${baseUrl}/${data.id}`, data, options);
}

export function updateResourceMultipart(baseUrl, data, options = {}) {
  return putMultipart(`${baseUrl}/${data.id}`, data, options);
}

export function deleteResource(baseUrl, data, options = {}) {
  return destroy(`${baseUrl}/${data.id}`, options);
}
