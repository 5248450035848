import { get } from 'lodash';

import { addUuid } from '@services/recursivelyProcessObject';

const defaultTagValue = '2';

const buildNewAdUnitsConfigurationItem = () => (
  addUuid({
    id: null,
    iosAdUnitId: null,
    androidAdUnitId: null,
    tag: defaultTagValue,
    retryEnabled: true,
    sequentialLoadingEnabled: true,
    biddingFloor: {
      biddingFloorType: 'static',
      staticFloorValue: null,
    },
  })
);

/* eslint-disable no-param-reassign, array-callback-return */
export default function adUnitsConfigurationReducer(state, action) {
  const { adUnitsConfigurationItemsPath } = action;
  const adUnitItemsAttributes = get(state, adUnitsConfigurationItemsPath);

  if (action.actionType === 'addAdUnitsConfigurationItem') {
    adUnitItemsAttributes.push(buildNewAdUnitsConfigurationItem());
  }

  if (action.actionType === 'removeAdUnitsConfigurationItem') {
    const { deleteAt } = action;

    adUnitItemsAttributes[deleteAt]._destroy = true;
  }

  return state;
}
