import React from 'react';

import { MyPetCafeRoutes } from '@pages/routes';
import New from '@components/journeys/banks/New';
import Form from './Form';

export default function SoloMissionsEventBankNew() {
  return (
    <New
      form={Form}
      nameTranslationPath="soloMissions.eventBanks.name"
      routes={MyPetCafeRoutes.SoloMissions.EventBanks}
    />
  );
}
