import React from 'react';
import { PageTitle } from '@pages/common';

import {
  BanksEdit, BanksIndex, BanksNew, ChapterSetsIndex, ChapterSetsNew, ChapterSetsEdit, FigurePacksIndex,
  LevelLayoutsEdit, LevelLayoutsNew, PersonalLevelSetsEdit, PersonalLevelSetsNew, PersonalLevelSetsIndex, TerrainsIndex,
  UiThemesIndex, UiConfigsEdit, UiConfigsIndex, UiConfigsNew,
} from '@pages/match3d';
import { Match3dRoutes as Routes } from '@pages/routes';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function match3d() {
  return [
    <Route
      key={Routes.Banks.indexRawPath}
      path={Routes.Banks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Level Layout Banks" />
          <BanksIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.Banks.newRawPath}
      path={Routes.Banks.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout Bank" action="New" />
          <BanksNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.Banks.editRawPath}
      path={Routes.Banks.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout Bank" action="Edit" />
          <BanksEdit />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.ChapterSets.indexRawPath}
      path={Routes.ChapterSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Chapter Sets" />
          <ChapterSetsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.ChapterSets.newRawPath}
      path={Routes.ChapterSets.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Chapter Set" action="New" />
          <ChapterSetsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.ChapterSets.editRawPath}
      path={Routes.ChapterSets.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Chapter Set" action="Edit" />
          <ChapterSetsEdit />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.UiConfigs.indexRawPath}
      path={Routes.UiConfigs.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="UI Configurations" />
          <UiConfigsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.UiConfigs.newRawPath}
      path={Routes.UiConfigs.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="UI Configuration" action="New" />
          <UiConfigsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.UiConfigs.editRawPath}
      path={Routes.UiConfigs.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="UI Configuration" action="Edit" />
          <UiConfigsEdit />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.PersonalLevelSets.indexRawPath}
      path={Routes.PersonalLevelSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Star Level Sets" />
          <PersonalLevelSetsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.PersonalLevelSets.newRawPath}
      path={Routes.PersonalLevelSets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Star Level Set" action="New" />
          <PersonalLevelSetsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.PersonalLevelSets.editRawPath}
      path={Routes.PersonalLevelSets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Star Level Set" action="Edit" />
          <PersonalLevelSetsEdit />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.LevelLayouts.newRawPath}
      path={Routes.LevelLayouts.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout" action="New" />
          <LevelLayoutsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.LevelLayouts.editRawPath}
      path={Routes.LevelLayouts.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout" action="Edit" />
          <LevelLayoutsEdit />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.Terrains.indexRawPath}
      path={Routes.Terrains.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Terrains" />
          <TerrainsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.FigurePacks.indexRawPath}
      path={Routes.FigurePacks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Figure Packs" />
          <FigurePacksIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={Routes.UiThemes.indexRawPath}
      path={Routes.UiThemes.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="UI Themes" />
          <UiThemesIndex />
        </RouteContainer>
    )}
    />,
  ];
}
