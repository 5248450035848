import React from 'react';
import { useTheme } from 'styled-components';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { AppEventsRoutes } from '@pages/routes';

export default function AppEventsNavigationItem({ applicationId, minimized }) {
  const theme = useTheme();

  return (
    <Sidebar.LinkItem
      color={theme.cornflowerBlue}
      icon={faBullhorn}
      to={AppEventsRoutes.indexPath({ applicationId })}
      title="App Events"
      minimized={minimized}
    />
  );
}
