import React, { useMemo } from 'react';

import { isNil } from 'lodash';
import { useConfirm, useFormContext } from '@hooks';
import { InUseCollapse, isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

import { Tr, Td } from '../shared/styled-components';
import { TextCell, FormatCell, NameCell } from './cells';
import { useLanguagesContext } from '../shared/services/useLanguages';

import Translations from './Translations';

export default function Row({ rowData, index, setShowOnlyKeysWithErrors }) {
  const {
    id,
    name,
    format,
    inUse,
    description,
    translationsAttributes,
    oldValues,
    _destroy,
    _uuid,
  } = rowData;

  const { allLanguages } = useLanguagesContext();
  const { setFieldValue, dispatch } = useFormContext();

  const confirm = useConfirm();
  const keyPath = `keyConfigs.${index}`;
  const destroyPath = `${keyPath}._destroy`;
  const isNewRecord = !id;
  const isDisabledByInUse = isNil(inUse) || isInUse(inUse);

  const languageIds = allLanguages.map(({ id: languageId }) => languageId);
  const filteredTranslations = translationsAttributes.filter(({ languageId }) => languageIds.includes(languageId));

  const onUndo = () => { setShowOnlyKeysWithErrors(false); dispatch({ type: 'undoRowChanges', _uuid }); };
  const onRemove = () => confirm
    .showConfirmation({ title: `Key '${name}' could be used in live game client. Continue?` })
    .then(() => setFieldValue(destroyPath, true));

  return (
    useMemo(() => (
      <Tr isNewRecord={isNewRecord} removed={_destroy}>
        <Td width="100px">
          {id && !_destroy && (
            <IconButton.Delete
              disabled={isDisabledByInUse}
              onClick={onRemove}
              minimized
            />
          )}
          <IconButton.Undo
            onClick={onUndo}
            className="ms-1"
            minimized
          />
        </Td>
        <NameCell
          path={keyPath}
          value={name}
          setFieldValue={setFieldValue}
          disabled={_destroy || isDisabledByInUse}
          isNewRecord={isNewRecord}
        />
        <FormatCell
          name="format"
          path={keyPath}
          value={format}
          oldValues={oldValues}
          setFieldValue={setFieldValue}
          disabled={_destroy}
          isNewRecord={isNewRecord}
        />
        <Td><InUseCollapse inUse={inUse} /></Td>
        <TextCell
          name="description"
          path={keyPath}
          value={description}
          oldValues={oldValues}
          setFieldValue={setFieldValue}
          disabled={_destroy}
          isNewRecord={isNewRecord}
          multiline
        />
        <Translations
          keyPath={keyPath}
          translations={filteredTranslations}
          oldValues={oldValues}
          setFieldValue={setFieldValue}
          disabled={_destroy}
          isNewRecord={isNewRecord}
        />
      </Tr>
    ), [
      name,
      format,
      description,
      inUse,
      translationsAttributes,
      keyPath,
      setFieldValue,
      _destroy,
    ])
  );
}
