/* eslint-disable gs/plz-camel-case-for-action-names */
import { addUuid } from '@services/recursivelyProcessObject';

export default function formReducer(state, action) {
  if (action.type === 'initialize_cells') {
    state.cellsAttributes = Array(63).fill({}).map((_, index) => (addUuid({ position: index + 1, cellLocked: true })));
  }

  return state;
}
