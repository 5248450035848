import React from 'react';
import { Col } from 'react-bootstrap';
import { merge, get } from 'lodash';

import APP_DATA from '@services/appData';
import { Label } from '@controls/form';
import { useCurrentApplication } from '@hooks';

import { AvailabilitySelectFilter, availabilitySelectFilterDefaultValue } from './AvailabilitySelectFilter';
import LabelsSelectFilter from './LabelsSelectFilter';
import InUseSelectFilter from './InUseSelectFilter';
import CreatedByMeFilter from './CreatedByMeFilter';
import DateTimeFilter from './DateTimeFilter';
import BaseFilter from './BaseFilter';
import IdFilter from './IdFilter';
import NamedRuleGroupFilter from './NamedRuleGroupFilter';
import NameFilter from './NameFilter';

const userCreatedByMe = get(APP_DATA, 'user.defaultFilters.createdByMe');
const userLabelIds = get(APP_DATA, 'user.defaultFilters.labelIds');
const userApplicationId = get(APP_DATA, 'user.defaultApplicationId');

export default function Filter({
  defaultComponents = [], defaults = {}, children, withUpdatedAfterFilter = true, ...props
}) {
  const { currentApplication: { id: applicationId, adminSettings } } = useCurrentApplication();

  const DefaultFilters = {
    inUse: {
      filter: InUseSelectFilter,
      key: 'inUseFilter',
    },
    id: {
      filter: IdFilter,
      key: 'idFilter',
    },
    availabilityStateIn: {
      filter: AvailabilitySelectFilter,
      default: { availabilityStateIn: availabilitySelectFilterDefaultValue(adminSettings) },
      key: 'availabilityStateInFilter',

    },
    labels: {
      filter: LabelsSelectFilter,
      default: userLabelIds && userApplicationId === applicationId && { 'labels.idIn': userLabelIds },
      key: 'labelsFilter',
    },
    createdByMe: {
      filter: CreatedByMeFilter,
      default: userCreatedByMe && { createdByMe: userCreatedByMe },
      key: 'createdByMe',
    },
    namedRuleGroup: {
      filter: NamedRuleGroupFilter,
    },
    name: {
      filter: NameFilter,
    },
  };

  const newChildren = (innerProps) => {
    const renderedDefaultComponents = defaultComponents.map((key) => {
      const definition = DefaultFilters[key];

      if (definition) {
        merge(defaults, definition.default);
        return <definition.filter {...innerProps} key={key} />;
      }

      throw new Error(`Invalid default component: ${key}`);
    });

    return (
      <>
        <Col md={6}>
          <Label direction="vertical" text="Created after">
            <DateTimeFilter {...innerProps} name="createdAt" />
          </Label>
        </Col>
        {withUpdatedAfterFilter && (
          <Col md={6}>
            <Label direction="vertical" text="Updated after">
              <DateTimeFilter {...innerProps} name="updatedAt" />
            </Label>
          </Col>
        )}
        {renderedDefaultComponents}
        {children && children(innerProps)}
      </>
    );
  };

  return (
    <BaseFilter {...props} defaults={defaults}>
      {newChildren}
    </BaseFilter>
  );
}
