import React, { useCallback } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { combineReducers } from '@reducers';
import rewardsReducer from '@components/merge/reducers/rewardsReducer';

import { MergeRoutes } from '@pages/routes';
import formReducer from './formReducer';
import Levels from './form/Levels';

export default function LevelSetsForm({ data, onSubmit, actionName }) {
  const { query } = useRouter();
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(query.applicationId), []);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([formReducer, rewardsReducer])}
    >
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Level Set`}>
            <FormButtonsGroup
              cancelButtonPath={MergeRoutes.LevelSets.indexPath({ applicationId: query.applicationId })}
            />
          </PageHeader>

          <Label text="Name"><Field type="text" name="name" /></Label>
          <Label text="Description"><Field type="text" name="description" /></Label>

          <Label text="World Schema">
            <SelectField
              name="schemaId"
              options={fetchSchemaOptions}
              onChange={() => dispatch({ type: 'initialize_levels' })}
              isDisabled={actionName !== 'Create'}
            />
          </Label>

          <StaticFieldsFormGroup data={data} />

          {values.schemaId
            ? <Levels values={values.levelsAttributes} schemaId={values.schemaId} />
            : ''}
        </>
      )}
    </Form>
  );
}
