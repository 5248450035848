import React, { useCallback } from 'react';
import { compact, isEmpty, get } from 'lodash';
import { Tab, Tabs } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { FormGroup, useFormContext } from '@hooks';
import TransformsInto from '@components/merge/unbubbling-configurations/form/TransformsInto';
import { MergeRoutes } from '@pages/routes';
import {
  Field, Label, SelectField,
} from '@controls/form';
import requestSchemaOptions from '@components/merge/services/requestEventSchemaOptions';
import ConfigurationTab from './merge/ConfigurationTab';
import EnergyTab from './merge/EnergyTab';

const hasErrors = (errors, fieldNames) => fieldNames.some((fieldName) => errors[fieldName]);
const mainTabFields = ['itemConfigurationSetId', 'ordersConfigurationId', 'levelSetId', 'startingGridId'];
const energyTabFields = [
  'energyStartBalance',
  'energyPurchaseCostMultiplier',
  'energyPurchaseAvailableAmount',
  'energyPurchaseRefreshTime',
  'energyPurchaseCurrency',
  'energyPurchaseCost',
];

export const ConfigurationVariant = React.memo(({
  actionName, defaultVariant, values, variantIndex, isArchived,
}) => {
  const { dispatch } = useFormContext();
  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';
  const unbubblingPath = [
    ...(
      defaultVariant
        ? ['configAttributes', 'currentVariantAttributes']
        : ['variantsAttributes', variantIndex.toString()]
    ),
    'unbubblingConfigurationAttributes',
  ];
  const { query: { applicationId } } = useRouter();
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(applicationId), []);
  const formValues = variantAttributesKey ? get(values, variantAttributesKey) : values;
  const { schemaId } = formValues;
  const { meta } = useFormContext();
  const errors = get(meta.errors, compact(['configAttributes', variantAttributesKey]), {});

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Description">
        <Field name="description" as="textarea" />
      </Label>
      <Label text="Event Schema">
        <SelectField
          name="schemaId"
          options={fetchSchemaOptions}
          onChange={(option) => dispatch({ type: 'changeEventSchemaId', schemaId: option.value, variantIndex })}
          isDisabled={actionName !== 'Create'}
        />
      </Label>
      <Label text="Minimum User Level">
        <Field name="minimumUserLevel" type="number" />
      </Label>

      {schemaId && (
        <Tabs defaultActiveKey="configs">
          <Tab
            className="pt-4"
            eventKey="configs"
            tabClassName={hasErrors(errors, mainTabFields) ? 'text-danger' : ''}
            title="Main"
          >
            <ConfigurationTab schemaId={schemaId} />
          </Tab>
          <Tab
            className="pt-4"
            eventKey="energy"
            tabClassName={hasErrors(errors, energyTabFields) ? 'text-danger' : ''}
            title="Energy Configuration"
          >
            <EnergyTab />
          </Tab>
          <Tab
            className="pt-4"
            eventKey="ububbling"
            tabClassName={isEmpty(errors.unbubblingConfigurationAttributes) ? '' : 'text-danger'}
            title="Unbubbling configuration"
          >
            {schemaId && (
              <FormGroup name="unbubblingConfigurationAttributes">
                <TransformsInto
                  disabled={isArchived}
                  path={unbubblingPath}
                  schemaRequest={MergeRoutes.Events.Schemas.editRequest}
                  values={{ ...formValues.unbubblingConfigurationAttributes, schemaId }}
                />
              </FormGroup>
            )}
          </Tab>
        </Tabs>
      )}
    </FormGroup>
  );
});

const MergeConfigurationForm = React.memo(({ defaultVariant = false, ...props }) => (
  <ConfigurationVariant defaultVariant={defaultVariant} {...props} />
));

export default MergeConfigurationForm;
