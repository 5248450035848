import React, { useState } from 'react';

import { Form } from '@hooks';

import {
  Label, Field, FormButtonsGroup, StaticFieldsFormGroup,
} from '@controls/form';
import { useRouter } from '@tripledotstudios/react-core';

import { DuplicateButton, PageHeader, PageSection } from '@pages/common';
import { isInUse } from '@pages/common/InUse';

import { MyPetCafeRoutes as Routes } from '@pages/routes';

import ModesList from './ModesList';
import reducer from './formReducer';

export default function MyPetCafeBetUpConfigurationsForm({ record, onSubmit }) {
  const [modesReordering, setModesReordering] = useState(false);
  const { query: { applicationId } } = useRouter();

  const { inUse } = record;

  const entityInUse = isInUse(inUse);

  return (
    <Form initialValues={record} onSubmit={onSubmit} reducer={reducer}>
      {({ values: { modesAttributes } }) => (
        <>
          <PageHeader title={`${record.id ? 'Edit' : 'New'} Bet Up Configuration`}>
            <FormButtonsGroup cancelButtonPath={Routes.BetUpConfigurations.indexPath({ applicationId })}>
              <DuplicateButton
                routes={Routes.BetUpConfigurations}
                data={record}
                entityName="Bet Up Configuration"
              />
            </FormButtonsGroup>
          </PageHeader>

          <Label text="Name">
            <Field name="name" />
          </Label>

          <StaticFieldsFormGroup data={record} />

          <hr />
          <PageSection title="Bet Up modes" />
          <ModesList
            modesAttributes={modesAttributes}
            reordering={modesReordering}
            setReordering={setModesReordering}
            readOnly={entityInUse}
          />
          <p />
        </>
      )}
    </Form>
  );
}
