import { isEmpty, omit, pick } from 'lodash';

export function buildConverterFromIndexResponse(options = {}, extraAttributes = []) {
  const valueAttribute = options.valueAttribute || 'id';
  const labelAttribute = options.labelAttribute || 'name';

  return (response) => (
    response?.data?.items?.map((item) => (
      {
        value: item[valueAttribute],
        label: item[labelAttribute],
        labels: item.labels || [],
        ...pick(item, extraAttributes),
      }
    ))
  );
}

const toSelectOptionsFromIndexResponse = buildConverterFromIndexResponse();

export const requestWithToSelectOptions = async (
  request,
  applicationId,
  params = {},
  { toSelectOptionsFn = null, unused = false } = {},
) => {
  const fullParams = unused ? { ...params, includeInUse: true } : params;
  let response = await request({ applicationId, withoutPagination: true, ...fullParams });

  if (unused) {
    response = {
      ...response,
      data: { items: response?.data?.items?.filter((record) => isEmpty(omit(record.inUse, '_uuid'))) },
    };
  }
  return (toSelectOptionsFn || toSelectOptionsFromIndexResponse)(response);
};
