import { isNil } from 'lodash';

const humanizedEnumValue = (operation, operationsEnum) => {
  const operationDefinition = Object.values(operationsEnum).find(({ value }) => operation === value);

  if (!operationDefinition) return '';

  return operationDefinition.translation.toLowerCase();
};

const valueBasedRule = (text, operation, props) => `${text} ${operation} ${isNil(props.value) ? '' : props.value}`;

const betUpMode = (operation, props) => valueBasedRule('Bet Up Mode', operation, props);
const firstTry = () => 'First try is enabled';
const noPreloadedPowerupRule = (operation, props) => valueBasedRule('No preloaded powerup', operation, props);
const usePreloadedPowerupRule = (operation, props) => valueBasedRule('Use preloaded powerup', operation, props);

const RulesPresenters = {
  'Missions::Targets::Rules::BetUpModeRule': betUpMode,
  'Missions::Targets::Rules::FirstTryRule': firstTry,
  'Missions::Targets::Rules::NoPreloadedPowerupRule': noPreloadedPowerupRule,
  'Missions::Targets::Rules::UsePreloadedPowerupRule': usePreloadedPowerupRule,
};

export default function Presenter({
  type,
  operation,
  enums,
  props,
}) {
  const operationsEnum = enums['Missions::RuleOperations'];
  const presenter = RulesPresenters[type];
  const humanizedOperation = operation === null ? '' : humanizedEnumValue(operation, operationsEnum);
  if (presenter) return presenter(humanizedOperation, props);

  return null;
}
