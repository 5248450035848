import React from 'react';
import { Route } from 'react-router-dom';

import { PageTitle } from '@pages/common';
import { HexagoRoutes as Routes } from '@pages/routes';
import JourneysExperimentScoped from '@pages/hexago/journeys/ExperimentScoped';
import RouteContainer from '@pages/RouteContainer';
import ActivationJourneysExperimentScoped from '@pages/hexago/activation-journeys/ExperimentScoped';
import {
  BanksEdit,
  BanksIndex,
  BanksNew,

  ChapterSetsIndex,
  ChapterSetsNew,
  ChapterSetsEdit,

  LevelLayoutsEdit,
} from '@pages/hexago';

export default function hexago() {
  return [
    [
      <Route
        key={Routes.Journeys.EventConfigs.experimentScopedRawPath}
        path={Routes.Journeys.EventConfigs.experimentScopedRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Edit Journey variant" />
            <JourneysExperimentScoped />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.ActivationJourneys.EventConfigs.experimentScopedRawPath}
        path={Routes.ActivationJourneys.EventConfigs.experimentScopedRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Edit Activation Journey variant" />
            <ActivationJourneysExperimentScoped />
          </RouteContainer>
      )}
      />,
      //
      // Level Layout Banks
      //
      <Route
        key={Routes.Banks.indexRawPath}
        path={Routes.Banks.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="Level Layout Banks" />
            <BanksIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.Banks.newRawPath}
        path={Routes.Banks.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Level Layout Bank" action="New" />
            <BanksNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.Banks.editRawPath}
        path={Routes.Banks.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Level Layout Bank" action="Edit" />
            <BanksEdit />
          </RouteContainer>
        )}
      />,

      //
      // Chapter Sets
      //
      <Route
        key={Routes.ChapterSets.indexRawPath}
        path={Routes.ChapterSets.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="Chapter Sets" />
            <ChapterSetsIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.ChapterSets.newRawPath}
        path={Routes.ChapterSets.newRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle entity="Chapter Set" action="New" />
            <ChapterSetsNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.ChapterSets.editRawPath}
        path={Routes.ChapterSets.editRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle entity="Chapter Set" action="Edit" />
            <ChapterSetsEdit />
          </RouteContainer>
        )}
      />,

      //
      // Level Layouts
      //
      <Route
        key={Routes.LevelLayouts.editRawPath}
        path={Routes.LevelLayouts.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Level Layout" action="Edit" />
            <LevelLayoutsEdit />
          </RouteContainer>
        )}
      />,
    ],
  ];
}
