import React, { useEffect, useState } from 'react';

import { RouterProvider } from 'react-router-dom';

import APP_DATA from '@services/appData';

import { MaintenanceModeRoutes } from '@pages/routes';
import { useUser } from '@hooks';

import router from './router';
import MaintenanceMode from './MaintenanceMode';

const { featureToggles: { maintenanceMode } } = APP_DATA;

export default function AppPicker() {
  const [disabledForUser, setDisabledForUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();
  const { defaultApplicationId } = user || {};

  useEffect(() => {
    if (!user) window.location.href = '/auth/sso';

    if (maintenanceMode.enabled) {
      setIsLoading(true);

      MaintenanceModeRoutes
        .showRequest()
        .then(({ data }) => {
          setDisabledForUser(data.disabledForUser);
          setIsLoading(false);
        });
    }
  }, []);

  if (isLoading || !user) return null;
  if (maintenanceMode.enabled && !disabledForUser) return <MaintenanceMode />;

  const defaultRoute = `/admin${defaultApplicationId ? `/applications/${defaultApplicationId}/home` : ''}`;

  return <RouterProvider router={router(defaultRoute)} future={{ v7_startTransition: true }} />;
}
