import React from 'react';

import {
  Form, useCurrentApplication,
} from '@hooks';
import {
  Label, Field, FormButtonsGroup, ColorField, StaticFieldsFormGroup,
} from '@controls/form';
import { generateChapterBasedRoutes } from '@pages/routes';
import { PageHeader } from '@pages/common';

export default function UiConfigForm({ data, onSubmit }) {
  const { applicationName, currentApplication: { id: applicationId } } = useCurrentApplication();
  const Routes = generateChapterBasedRoutes(applicationName).UiConfigs;
  const fullData = {
    ...data,
    applicationId,
    applicationName,
    rewardAffiliationsAttributes: [],
  };

  return (
    <Form
      initialValues={fullData}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${values.id ? 'Edit' : 'New'} UI Configuration`}>
            <FormButtonsGroup cancelButtonPath={Routes.indexPath({ applicationId })} />
          </PageHeader>

          <Label text="Name*" fieldSize={8}>
            <Field name="name" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <Label text="Primary color*" fieldSize={8}>
            <ColorField name="primaryColor" />
          </Label>
          <Label text="Secondary color*" fieldSize={8}>
            <ColorField name="secondaryColor" />
          </Label>
          <Label text="Platform primary color*" fieldSize={8}>
            <ColorField name="platformPrimaryColor" />
          </Label>
          <Label text="Platform secondary color*" fieldSize={8}>
            <ColorField name="platformSecondaryColor" />
          </Label>
          <Label text="Banner primary color*" fieldSize={8}>
            <ColorField name="bannerPrimaryColor" />
          </Label>
          <Label text="Banner secondary color*" fieldSize={8}>
            <ColorField name="bannerSecondaryColor" />
          </Label>
        </>
      )}
    </Form>
  );
}
