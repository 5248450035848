import { modelPaths } from '@pages/routes';
import { isEmpty } from 'lodash';

const SKIP_LINKS_FOR_MODELS = [
  // no separate edit page
  'Ads::AdUnitCampaign',
  'Metagame::ChapterBased::UiAsset',
  'Localizations::Language',
  'Missions::Rewards::Type',
  'Missions::MissionType',
  'Merge::Contact',
  'Merge::Postcard',
  'Merge::Expert',
  // custom link
  'MyPetCafe::JsonConfig',
  'AppEvent',
  'Label',
];

export default function editPath({
  application, id, type, query, action = null, ...rest
}) {
  if (SKIP_LINKS_FOR_MODELS.indexOf(type) > -1 || isEmpty(modelPaths[type])) {
    return null;
  }

  const modelPath = modelPaths[type];
  switch (action) {
    case 'base':
      return modelPath.basePath({ ...rest, applicationId: application.id, query });
    case 'instance':
      return modelPath.instancePath({ applicationId: application.id });
    case 'index':
      return modelPath.indexPath({ applicationId: application.id, query });
    default:
      return modelPath.editPath({
        applicationId: application.id,
        id,
        ...rest,
        query,
      });
  }
}
