import React, { memo } from 'react';
import { get } from 'lodash';

import { FormGroup } from '@hooks';
import { PageSection } from '@pages/common';
import getFieldLocale from '@services/getFieldLocale';
import processProps from '@services/settings/processProps';

import areEnabledPropsEqual from './areEnabledPropsEqual';

import fieldBuilder from './FieldBuilder';
import selectBuilder from './SelectBuilder';
import reorderableFieldBuilder from './ReorderableFieldBuilder';
import fieldArrayBuilder from './FieldArrayBuilder';
import arrayFieldBuilder from './ArrayFieldBuilder';
import assetsKeySelectBuilder from './AssetKeySelectBuilder';
import timePeriodFieldBuilder from './TimePeriodFieldBuilder';
import warningBuilder from './WarningBuilder';

// duplicating MAPPING from app/frontend/services/settings/buildFieldsFromConfig.js
// to avoid circular dependency
const MAPPING = {
  field: fieldBuilder,
  select: selectBuilder,
  reorderableField: reorderableFieldBuilder,
  fieldArray: fieldArrayBuilder,
  arrayField: arrayFieldBuilder,
  assetsKeySelect: assetsKeySelectBuilder,
  timePeriodField: timePeriodFieldBuilder,
  warning: warningBuilder,
};

const MemoizedSection = memo(({
  disabled,
  title,
  fields,
  baseName,
  container,
}) => (
  <PageSection title={title}>
    {React.Children.toArray(fields.map(({ builder, name, props }) => {
      const builderComponent = MAPPING[builder];

      const FieldComponent = builderComponent({
        name,
        ...processProps(props, {}),
      })[name].Labeled;

      return (
        <FormGroup name={baseName}>
          <FieldComponent locale={get(container, name)} disabled={disabled} />
        </FormGroup>
      );
    }))}
  </PageSection>
), (prev, next) => prev.disabled === next.disabled && prev.fields.length === next.fields.length);

const Section = ({ baseName, fields }) => (
  ({
    values, localeNamespace, locale = {}, disabled,
  }) => {
    const fieldLocale = getFieldLocale(localeNamespace, baseName);
    const container = fieldLocale || locale;
    if (!container) return '';

    const { title } = container;

    const filteredFields = fields.filter(({ props: { fieldOptions } }) => {
      if (!fieldOptions) return true;

      const { hiddenFn } = fieldOptions;

      // eslint-disable-next-line no-new-func
      const isHidden = hiddenFn && new Function('values', hiddenFn)(values);

      return !isHidden;
    });

    return (
      <MemoizedSection
        disabled={disabled}
        title={title}
        fields={filteredFields}
        values={values}
        baseName={baseName}
        container={container}
      />
    );
  }
);

export default function SectionBuilder({
  name: baseName, fieldOptions = {}, fields,
}) {
  return {
    [baseName]: {
      Inline: memo(Section({ baseName, fields }), areEnabledPropsEqual),
      Labeled: memo(Section({ baseName, fields }), areEnabledPropsEqual),
      fieldOptions,
    },
  };
}
