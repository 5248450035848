import {
  fetchCollection, newResource, editResource, createResource, updateResource, deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/puzzle_time/lobby_configs`;

export function getLobbyConfigs(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function newLobbyConfig(options) {
  return newResource(baseUrl(options), options);
}

export function editLobbyConfig(data, options) {
  return editResource(baseUrl(data), data, options);
}

export function createLobbyConfig(values) {
  return createResource(baseUrl(values), values);
}

export function updateLobbyConfig(values) {
  return updateResource(baseUrl(values), values);
}

export function deleteLobbyConfig(values) {
  return deleteResource(baseUrl(values), values);
}
