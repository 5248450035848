import React from 'react';

import { useQuery } from '@hooks';

import ExperimentScopedWrapper from '@pages/journeys/events/ExperimentScoped';

import { ConfigurationVariant } from '@pages/journeys/events/configuration-forms/WoodokuConfigurationForm';

import { WoodokuRoutes as Routes } from '@pages/routes';

export default function ExperimentScoped() {
  const { response, setResponse } = useQuery(Routes.Journeys.EventConfigs.experimentScopedRequest);

  const onSubmit = (values) => Routes.Journeys.EventConfigs.updateVariantsRequest(values);

  return (
    <ExperimentScopedWrapper data={response} setData={setResponse} onSubmit={onSubmit}>
      {({ variantAttributes, variantIndex }) => (
        <ConfigurationVariant values={variantAttributes} variantIndex={variantIndex} />
      )}
    </ExperimentScopedWrapper>
  );
}
