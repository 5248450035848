import React from 'react';
import { Col } from 'react-bootstrap';

import { Filter, TextFilter, UserSelectFilter } from '@pages/common';

export default function BanksFilter() {
  return (
    <Filter defaultComponents={['availabilityStateIn', 'createdByMe', 'labels', 'id']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col>
            <UserSelectFilter updateFilterField={updateFilterField} name="adminUsersIdIn" />
          </Col>
        </>
      )}
    </Filter>
  );
}
