import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Alert } from '@tripledotstudios/react-core';

const useTimer = ({ initialTimeLeft }) => {
  const [initialized, setInitialized] = useState(false);
  const [ended, setEnded] = useState(false);
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft || 0);

  useEffect(() => {
    setInitialized(false);
    setEnded(false);
    setTimeLeft(initialTimeLeft);
  }, [initialTimeLeft]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      } else {
        setEnded(true);
      }

      setInitialized(true);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return { initialized, ended, timeLeft };
};

export default function Publish({ info, onPublish }) {
  const { initialized, ended, timeLeft } = useTimer({
    initialTimeLeft: (info || {}).secondsUntilReady,
  });

  if (!initialized) { return <Alert variant="secondary"><b>Avaiting publishing status</b></Alert>; }

  if (!info || ended) {
    return (
      <Alert variant="success"><b>Nothing to publish</b></Alert>
    );
  }

  const message = `Publish will automatically happen in ${timeLeft} second(s)`;

  return (
    <Alert variant="warning">
      {message}
      <Button variant="link" onClick={onPublish}>
        Publish manually
      </Button>
    </Alert>
  );
}
