import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Label } from '@controls/form';
import { Form } from '@hooks';

import IconButton from '@controls/buttons';

import Table from './search-enabled-fields/SettingsTable';
import SettingsFieldsSelect from './SettingsFieldsSelect';

export default function SearchEnabledFields({
  fieldsData, data, onSubmit, localeNamespace, entityStatuses,
}) {
  return fieldsData && (
    <Form initialValues={{ fieldName: '' }} onSubmit={onSubmit} disableDirtyChecker>
      {({ setFieldValue, values: { fieldName } }) => (
        <>
          <Label
            text="Select Setting"
            tooltipText="You can search by field name or client key"
            direction="vertical"
          >
            <Row>
              <Col xs="9">
                <SettingsFieldsSelect
                  archivedFields={fieldsData.archivedFields}
                  fields={fieldsData.fields}
                  selectedValue={fieldName}
                  localeNamespace={localeNamespace}
                  onChange={({ value }) => setFieldValue('fieldName', value)}
                />
              </Col>
              <Col xs="3" className="ps-0">
                <IconButton.Search type="submit" className="h-100" disabled={!fieldName} block />
              </Col>
            </Row>
          </Label>
          {data && <Table data={data} fieldName={fieldName} entityStatuses={entityStatuses} />}
        </>
      )}
    </Form>
  );
}
