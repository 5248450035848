import React from 'react';
import IndexPage from '@pages/puzzle-time/ConfigIndex';
import { useRouter } from '@tripledotstudios/react-core';
import { get2048Configs, delete2048Config } from '@requests/puzzle-time/configs2048';

export default function Index() {
  const { query } = useRouter();

  return (
    <IndexPage
      configName="2048 Config"
      path={`/admin/applications/${query.applicationId}/puzzle_time/configs_2048`}
      getConfigFunction={get2048Configs}
      deleteConfigFunction={delete2048Config}
    />
  );
}
