import React, { useState, useEffect } from 'react';

import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APP_DATA from '@services/appData';
import { FaButton } from '@pages/common';
import { useQuery } from '@hooks';
import OverlayTooltip from '@controls/OverlayTooltip';
import { FavouritesRoutes } from '@root/pages/routes';

export default function Favourite({ favorableId, applicationId, type }) {
  const { user } = APP_DATA;
  const { response } = useQuery(FavouritesRoutes.forAdminUserRequest,
    {
      adminUserId: user.id, favorableId, favorableType: type, applicationId,
    });

  const [isFavourite, setIsFavourite] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    setIsFavourite(response?.isFavourite);
    setId(response?.id);
  }, [response]);

  const onRemove = () => {
    FavouritesRoutes.deleteRequest({ id })
      .then((data) => {
        if (data.status < 300) setIsFavourite(false);
      });
  };

  const onAdd = () => {
    FavouritesRoutes.createRequest({
      adminUserId: user.id, applicationId, favorableId, favorableType: type,
    })
      .then((data) => {
        if (data.status < 300) {
          setIsFavourite(true);
          setId(data.data.id);
        }
      });
  };

  return (
    <FaButton onClick={isFavourite ? onRemove : onAdd}>
      <OverlayTooltip
        text={isFavourite ? 'Remove from Favourites in order not to see this user on page Favourites'
          : 'Add to Favourites in order to see this user on page Favourites'}
        placement="bottom"
      >
        <FontAwesomeIcon icon={isFavourite ? faStarSolid : faStarRegular} size="xl" style={{ color: '#FFC107' }} />
      </OverlayTooltip>
    </FaButton>
  );
}
