import { v4 } from 'uuid';

export default function formReducer(state, action) {
  const { actionType } = action;

  switch (actionType) {
    case 'addMissionConfig': {
      const { tierIndex, missionType, rewardTypeOptions } = action;
      const tier = state.tiersAttributes[tierIndex];

      const rewardConfigs = rewardTypeOptions.map(
        ({ value, label, rewardTypeName }) => ({
          rewardTypeId: value,
          rewardName: label,
          rewardTypeName,
          baseValue: null,
          _uuid: v4(),
        }),
      );

      const missionConfig = {
        tierId: tier.id,
        missionTypeId: missionType.value,
        missionTypeName: missionType.label,
        rewardConfigsAttributes: rewardConfigs,
        _uuid: v4(),
      };

      state.tiersAttributes[tierIndex].missionConfigsAttributes.push(missionConfig);

      return state;
    }
    case 'recalculateSingleRewardAmount': {
      const {
        tierIndex,
        missionConfigIndex,
        rewardConfigIndex,
        value,
      } = action;

      const { rewardValuesAttributes } = state;

      const { rewardTypeId } = state
        .tiersAttributes[tierIndex]
        .missionConfigsAttributes[missionConfigIndex]
        .rewardConfigsAttributes[rewardConfigIndex];

      const { multiplier } = rewardValuesAttributes.find((rewardValue) => rewardValue.rewardTypeId === rewardTypeId);

      state
        .tiersAttributes[tierIndex]
        .missionConfigsAttributes[missionConfigIndex]
        .rewardConfigsAttributes[rewardConfigIndex]
        .baseValue = value;

      state
        .tiersAttributes[tierIndex]
        .missionConfigsAttributes[missionConfigIndex]
        .rewardConfigsAttributes[rewardConfigIndex]
        .rewardAmount = Math.round(multiplier * value);

      return state;
    }
    case 'recalculateEveryRewardAmounts': {
      const { value, rewardTypeId, rewardValueIndex } = action;
      const { tiersAttributes } = state;

      state.rewardValuesAttributes[rewardValueIndex].multiplier = value;

      tiersAttributes.forEach(({ missionConfigsAttributes }, tierIndex) => {
        missionConfigsAttributes.forEach(({ rewardConfigsAttributes }, missionConfigIndex) => {
          rewardConfigsAttributes.forEach(({ rewardTypeId: localTypeId, baseValue }, index) => {
            if (localTypeId === rewardTypeId) {
              state
                .tiersAttributes[tierIndex]
                .missionConfigsAttributes[missionConfigIndex]
                .rewardConfigsAttributes[index]
                .rewardAmount = Math.round(baseValue * value);
            }
          });
        });
      });

      return state;
    }
    case 'removeMissionConfig': {
      const { tierIndex, missionConfigIndex } = action;

      state.tiersAttributes[tierIndex].missionConfigsAttributes[missionConfigIndex]._destroy = true;

      return state;
    }
    case 'restoreMissionConfig': {
      const { tierIndex, missionConfigIndex } = action;

      state.tiersAttributes[tierIndex].missionConfigsAttributes[missionConfigIndex]._destroy = false;

      return state;
    }
    default: return state;
  }
}
