import React from 'react';
import { useFlashMessages, Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { FlexiblePopupRoutes as Routes } from '@pages/routes';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { isInUse } from '@pages/common/InUse';

import Form from './Form';

export default function Edit() {
  const { query: { id, applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(Routes.Actions.editRequest, { id });
  const { error } = useFlashMessages();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Flexible Popup Action',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => {
    const { inUse, name } = values;

    if (isInUse(inUse)) {
      return new Promise((resolve) => {
        error(`Flexible Popup Action '${name}' is in use`);
        resolve();
      });
    }

    return Routes.Actions.updateRequest({ ...values, applicationId }).then(responseHandler);
  };

  if (isLoading) return <Spinner />;

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      title="Edit Flexible Popup Action"
    />
  );
}
