import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

const StyledIcon = styled(FontAwesomeIcon)`
  border-radius: 3px;
  height: 15px;
  width: 15px;
  padding: 8px;
  color: white;
`;

export default function DropdownItem({
  variant,
  icon,
  onClick,
  children,
  ...rest
}) {
  return (
    <Dropdown.Item className="mb-1 d-flex align-items-center" onClick={onClick} {...rest}>
      <StyledIcon className={`bg-${variant} me-2`} icon={icon} />
      {children}
    </Dropdown.Item>
  );
}
