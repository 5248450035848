import React from 'react';
import ReactSelect from 'react-select';

import NotSetOption from './notSetOption';

export default function MultiSelectFilter({
  options, name, filters, setFilters, includeNotSet = false,
}) {
  const selectOptions = includeNotSet ? [...options, NotSetOption] : options;
  const handleFilterChange = (selectedOptions) => (
    setFilters((prev) => {
      if (selectedOptions && selectedOptions.length > 0) {
        const selectedValues = selectedOptions.map(({ value }) => value);
        return { ...prev, [name]: { type: 'in', values: selectedValues } };
      }

      const { [name]: _, ...rest } = prev;
      return rest;
    })
  );
  const currentOptions = selectOptions.filter(({ value }) => filters[name] && filters[name].values.includes(value));

  return (
    <ReactSelect
      value={currentOptions}
      onChange={handleFilterChange}
      options={selectOptions}
      className="react-select"
      classNamePrefix="react-select"
      isClearable
      isMulti
    />
  );
}
