import React from 'react';

import { Form, FormGroup } from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup,
} from '@controls/form';
import { DuplicateButton, PageHeader } from '@pages/common';
import { FigureSetsRoutes } from '@pages/routes';

import reducer from './reducer';
import EnabledShapesTable from './EnabledShapesTable';
import FigureDistributionsTable from './FigureDistributionsTable';

export default function FigureSetsSetsForm({ applicationId, data, onSubmit }) {
  const { availableShapes, readOnly } = data;

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit} reducer={reducer}>
      {({ values: { enabledShapeIds, figureAffiliationsAttributes } }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Figure Set`}>
            <FormButtonsGroup cancelButtonPath={FigureSetsRoutes.Sets.indexPath({ applicationId })}>
              <DuplicateButton
                routes={FigureSetsRoutes.Sets}
                data={data}
                entityName="Figure Set"
              />
            </FormButtonsGroup>
          </PageHeader>

          <FormGroup>
            <Label text="Name">
              <Field type="text" name="name" />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <EnabledShapesTable shapes={availableShapes} ids={enabledShapeIds} disabled={readOnly} />

            <FigureDistributionsTable
              figureAffiliations={figureAffiliationsAttributes}
              availableShapes={availableShapes}
              shapes={availableShapes.filter(({ id }) => enabledShapeIds.includes(id))}
              disabled={readOnly}
            />
          </FormGroup>
        </>
      )}
    </Form>
  );
}
