import React from 'react';

import { includes } from 'lodash';
import { faTools, faBolt } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { currentEnvironment } from '@services/currentEnvironment';

const UI_KIT_ENVIRONMENTS = [
  'development',
  'staging',
  'feature',
];

export default function Tools({ minimized = false }) {
  return (
    <Sidebar.SectionItem
      icon={faTools}
      title="Tools"
      autoCollapse
      minimized={minimized}
    >
      {includes(UI_KIT_ENVIRONMENTS, currentEnvironment()) && (
        <Sidebar.LinkItem
          to="/admin/ui-kit"
          icon={faBolt}
          title="UI Kit"
          minimized={minimized}
        />
      )}
      <Sidebar.ExternalLinkItem href="/admin/docs" title="API Docs" />
      <Sidebar.ExternalLinkItem href="/admin/flipper" title="Feature Toggles" />
      <Sidebar.ExternalLinkItem href="/admin/tasks" title="Maintenance Tasks" />
      <Sidebar.ExternalLinkItem href="/admin/sidekiq" title="Sidekiq" />
    </Sidebar.SectionItem>
  );
}
