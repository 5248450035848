import * as blackjackGameSettings from '@components/game-settings/blackjack';
import * as mergeGameSettings from '@root/components/game-settings/merge';
import * as tileGameSettings from '@root/components/game-settings/tile';
import * as myPetCafeGameSettings from '@root/components/game-settings/my_pet_cafe';
import * as puzzleTimeGameSettings from '@root/components/game-settings/puzzle_time';
import * as solitaireGameSettings from '@components/game-settings/solitaire';
import * as solitaireScapesGameSettings from '@root/components/game-settings/solitaire_scapes';
import * as spiderSolitaireGameSettings from '@root/components/game-settings/spider_solitaire';
import * as sudokuGameSettings from '@components/game-settings/sudoku';
import * as woodokuGameSettings from '@root/components/game-settings/woodoku';
import * as match3dGameSettings from '@root/components/game-settings/match3d';
import * as lpBingoGameSettings from '@root/components/game-settings/lp_bingo';
import * as woodokuSagaGameSettings from '@root/components/game-settings/woodoku_saga';
import * as blockPartyGameSettings from '@root/components/game-settings/block_party';
import * as sortagaGameSettings from '@root/components/game-settings/sortaga';
import * as reactorGameSettings from '@root/components/game-settings/reactor';
import * as solitaireScapesCollectionGameSettings from '@root/components/game-settings/solitaire_scapes_collection';
import * as wordHopGameSettings from '@root/components/game-settings/word_hop';
import * as solitaireComGameSettings from '@root/components/game-settings/solitaire_com';
import * as hexagoGameSettings from '@root/components/game-settings/hexago';
import * as wooAspenGameSettings from '@root/components/game-settings/woo/aspen';
import * as taosGameSettings from '@root/components/game-settings/taos';
import * as solCoroGameSettings from '@root/components/game-settings/sol/coro';
import * as lorayGameSettings from '@root/components/game-settings/loray';
import * as axumGameSettings from '@root/components/game-settings/axum';
import * as luskGameSettings from '@components/game-settings/lusk';
import * as vossGameSettings from '@components/game-settings/voss';

import { New, Edit, Base } from './Resource';
import List from './List';
import SearchEnabledSettings from './SearchEnabledSettings';

const gameConfigsList = [
  {
    gameType: 'blackjack',
    entityType: 'Blackjack::GameSettings',
    ...blackjackGameSettings,
  },
  {
    gameType: 'tile',
    entityType: 'Tile::GameSettings',
    ...tileGameSettings,
  },
  {
    gameType: 'merge',
    entityType: 'Merge::GameSettings',
    ...mergeGameSettings,
  },
  {
    gameType: 'my_pet_cafe',
    entityType: 'MyPetCafe::GameSettings',
    ...myPetCafeGameSettings,
  },
  {
    gameType: 'puzzle_time',
    entityType: 'PuzzleTime::GameSettings',
    ...puzzleTimeGameSettings,
  },
  {
    gameType: 'solitaire',
    entityType: 'Solitaire::GameSettings',
    ...solitaireGameSettings,
  },
  {
    gameType: 'solitaire_scapes',
    entityType: 'SolitaireScapes::GameSettings',
    ...solitaireScapesGameSettings,
  },
  {
    gameType: 'spider_solitaire',
    entityType: 'SpiderSolitaire::GameSettings',
    ...spiderSolitaireGameSettings,
  },
  {
    gameType: 'sudoku',
    entityType: 'Sudoku::GameSettings',
    ...sudokuGameSettings,
  },
  {
    gameType: 'woodoku',
    entityType: 'Woodoku::GameSettings',
    ...woodokuGameSettings,
  },
  {
    gameType: 'match3d',
    entityType: 'Match3d::GameSettings',
    ...match3dGameSettings,
  },
  {
    gameType: 'lp_bingo',
    entityType: 'LpBingo::GameSettings',
    ...lpBingoGameSettings,
  },
  {
    gameType: 'discovery',
    entityType: 'WoodokuSaga::GameSettings',
    ...woodokuSagaGameSettings,
  },
  {
    gameType: 'block_party',
    entityType: 'BlockParty::GameSettings',
    ...blockPartyGameSettings,
  },
  {
    gameType: 'sortaga',
    entityType: 'Sortaga::GameSettings',
    ...sortagaGameSettings,
  },
  {
    gameType: 'reactor',
    entityType: 'Reactor::GameSettings',
    ...reactorGameSettings,
  },
  {
    gameType: 'solitaire_scapes_collection',
    entityType: 'SolitaireScapesCollection::GameSettings',
    ...solitaireScapesCollectionGameSettings,
  },
  {
    gameType: 'word_hop',
    entityType: 'WordHop::GameSettings',
    ...wordHopGameSettings,
  },
  {
    gameType: 'solitaire_com',
    entityType: 'SolitaireCom::GameSettings',
    ...solitaireComGameSettings,
  },
  {
    gameType: 'hexago',
    entityType: 'Hexago::GameSettings',
    ...hexagoGameSettings,
  },
  {
    gameType: 'woo/aspen',
    entityType: 'Woo::Aspen::GameSettings',
    ...wooAspenGameSettings,
  },
  {
    gameType: 'taos',
    entityType: 'Taos::GameSettings',
    ...taosGameSettings,
  },
  {
    gameType: 'sol/coro',
    entityType: 'Sol::Coro::GameSettings',
    ...solCoroGameSettings,
  },
  {
    gameType: 'loray',
    entityType: 'Loray::GameSettings',
    ...lorayGameSettings,
  },
  {
    gameType: 'axum',
    entityType: 'Axum::GameSettings',
    ...axumGameSettings,
  },
  {
    gameType: 'lusk',
    entityType: 'Lusk::GameSettings',
    ...luskGameSettings,
  },
  {
    gameType: 'voss',
    entityType: 'Voss::GameSettings',
    ...vossGameSettings,
  },
];

const fieldsFactories = Object.fromEntries(
  gameConfigsList.map((gameConfig) => [gameConfig.entityType, gameConfig.fieldsFactory]),
);

export {
  gameConfigsList,
  fieldsFactories,
  List,
  Base,
  New,
  Edit,
  SearchEnabledSettings,
};
