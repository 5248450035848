import React from 'react';
import styled from 'styled-components';

const Img = styled.img`
  border-radius: 7px;
`;

export default function ApplicationIcon({
  imageCdnUrl,
  className,
  width = 25,
  height = 25,
}) {
  const src = imageCdnUrl || '/images/games/default_app_icon.png';
  return (
    <Img
      width={width}
      height={height}
      src={src}
      className={className}
      alt="Application Icon"
    />
  );
}
