import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useRouter } from '@tripledotstudios/react-core';

import { FormGroup } from '@hooks';
import buildVariantUrl from '@services/ab-testing/urlBuilder';
import prepareInUseEntities from '@services/ab-testing/entitiesHelper';
import { InUseLinks } from '@pages/common/InUse';

import buildField from './FieldsFactory';

const EntityLink = styled(Link)`
  ${({ disabled }) => disabled && `
    color: gray !important;
    text-decoration: none !important;
    -webkit-text-decoration: none !important;
    pointer-events: none;
    cursor: not-allowed;
    &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none !important;
      -webkit-text-decoration: none !important;
    }
  `}
`;

export default function ExperimentEntity({
  propertyName = null,
  entityVariantId = null,
  _destroy = false,
  experimentId,
  name,
  entityName,
  type,
  entityType,
  entityId,
  entityContext,
  frontendIdentity,
  data,
  variantIndex,
  index,
  disabled,
  subVariant,
  subVariantIds,
  variantRuleType,
  i18nPath,
}) {
  const isDisabled = disabled || _destroy;
  const { query } = useRouter();
  const { applicationId } = query;
  const experimentEntities = prepareInUseEntities(frontendIdentity, entityName, i18nPath);

  // eslint-disable-next-line no-nested-ternary
  const value = type === 'property' ? (
    buildField({
      entityType,
      propertyName,
      data,
      baseName: name,
      applicationId,
      disabled: isDisabled,
      entityContext,
      variantIndex,
      index,
    })
  ) : (
    !subVariant.id
      ? (
        <span className="text-muted">
          Save the experiment to edit this variant experiment entities
        </span>
      ) : (
        <EntityLink
          to={buildVariantUrl({
            entityId,
            experimentId,
            variantId: entityVariantId,
            entityType,
            routingScope: `/admin/applications/${applicationId}`,
            subVariantIds,
            variantRuleType,
          })}
          onClick={(e) => isDisabled && e.preventDefault()}
          disabled={isDisabled}
          target="_blank"
        >
          Go to edit
        </EntityLink>
      )
  );

  return (
    <FormGroup name={`experimentEntitiesAttributes.${index}`}>
      <tr className={_destroy && 'table-secondary'}>
        <td>
          <InUseLinks inUse={experimentEntities} />
        </td>
        <td>{propertyName || '-'}</td>
        <td style={type === 'property' ? { width: '45%' } : {}}>{value}</td>
      </tr>
    </FormGroup>
  );
}
