/* eslint-disable gs/no-space-literal */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Row, Col,
} from 'react-bootstrap';
import {
  compact, flattenDeep, map, reduce,
} from 'lodash';

import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import {
  useFormContext, useCurrentApplication,
} from '@hooks';

import { MergeRoutes } from '@pages/routes';
import requestEventSchemaOptions from '@components/merge/services/requestEventSchemaOptions';
import { PageHeader } from '@pages/common';
import RewardsSummary from '@components/merge/shared/rewards/Summary';

import Orders from './Orders';

const presentLinesRequired = (orders, items) => {
  const itemsCount = reduce(compact(orders.flatMap((order) => (
    order.targetsAttributes.map((target) => !target._destroy && target)),
  )), (hist, target) => {
    const count = target.quantity || 0;
    return {
      ...hist,
      [target.schemaItemId]: hist[target.schemaItemId] ? hist[target.schemaItemId] + count : count,
    };
  }, {});

  return compact(map(itemsCount, (count, itemId) => {
    const item = items.find((i) => i.id === parseInt(itemId, 10));
    if (!item) return '';

    return `${item.internalId} (${count})`;
  })).join(', ');
};

export default function ConfigurationForm({ values, errors, actionName }) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const [items, setItems] = useState();
  const { dispatch } = useFormContext();

  useEffect(() => {
    if (!values.schemaId) return;

    MergeRoutes.Events.Schemas.editRequest({
      id: values.schemaId,
      applicationId,
    }).then((response) => {
      setItems(response.data.items);
    });
  }, [values.schemaId]);

  const itemsOptions = items && items.map((item) => ({ value: item.id, label: item.internalId, line: item.line }));
  const visibleOrders = values.ordersAttributes.filter((o) => !o._destroy);

  const fetchSchemaOptions = useCallback(() => requestEventSchemaOptions(applicationId), []);
  return (
    <>
      <PageHeader title={`${values.id ? 'Edit' : 'New'} Orders configuration`}>
        <FormButtonsGroup
          cancelButtonPath={MergeRoutes.Events.OrdersConfigurations.indexPath({ applicationId })}
        />
      </PageHeader>

      <Label text="Name">
        <Field type="text" name="name" />
      </Label>
      <Label text="Description">
        <Field type="text" name="description" />
      </Label>

      <Label text="Event Schema">
        <SelectField
          name="schemaId"
          options={fetchSchemaOptions}
          onChange={() => dispatch({ type: 'changeSchemaId' })}
          isDisabled={actionName !== 'Create'}
        />
      </Label>

      <StaticFieldsFormGroup data={values} />

      <Label text="Number of orders displayed">
        <Field type="number" name="numberOfOrdersDisplayed" />
      </Label>

      {items && (
        <>
          <Row className="mb-2">
            <Col xs={4}>
              <b className="me-2">Orders Summary:</b>
              {visibleOrders.length}
            </Col>
            <Col xs={4}>
              <b className="me-2">Items:</b>
              {presentLinesRequired(visibleOrders, items)}
            </Col>
            <Col xs={4}>
              <b className="me-2">Rewards:</b>
              <RewardsSummary
                rewardAttributes={flattenDeep(map(values.ordersAttributes, 'rewardsAttributes'))}
                itemOptions={itemsOptions}
                expertOptions={[]}
              />
            </Col>
          </Row>

          <Orders
            values={values.ordersAttributes}
            errors={errors.ordersAttributes}
            items={itemsOptions}
            dispatch={dispatch}
          />
        </>
      )}
    </>
  );
}
