import React, { useMemo, useState } from 'react';
import { merge } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
  Filter,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';
import ColumnsFactory from './ColumnsFactory';

export default function Index({
  DuplicationModal, routePostfix, schemaType, schemaTypeName,
}) {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(
    (params) => MergeRoutes.StartingGrids.indexRequest(merge(params, { filter: { schemaTypeEq: schemaType } })),
    { includeInUse: true },
  );
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Starting Grid',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `Starting Grid '${name}' will be deleted and not available for work. Continue?` })
      .then(() => MergeRoutes.StartingGrids.deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({
    applicationId, setDuplicatedRecord, onDelete, routePostfix, schemaTypeName,
  }), []);

  return response && (
    <div>
      <PageHeader
        title="Starting Grids"
        filter={(<Filter defaultComponents={['availabilityStateIn']} />)}
      >
        <IconButton.New
          to={MergeRoutes.StartingGrids.newPath({ applicationId, routePostfix })}
        />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
      />
      <DuplicationModal
        buildRedirectPath={(id) => MergeRoutes.StartingGrids.editPath({ id, applicationId, routePostfix })}
        record={duplicatedRecord}
        entityName="Starting Grid"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => (
          MergeRoutes.StartingGrids.duplicateRequest({ ...values, applicationId })
        )}
        title="Duplicate Starting Grid"
        applicationId={applicationId}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
