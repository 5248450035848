import React from 'react';
import { PageTitle } from '@pages/common';

import AppEventsIndex from '@pages/app-events/Index';
import { AppEventsRoutes } from '@pages/routes';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function appEvents() {
  return [
    <Route
      key={AppEventsRoutes.indexRawPath}
      path={AppEventsRoutes.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="App Events" />
          <AppEventsIndex />
        </RouteContainer>
      )}
    />,
  ];
}
