/* eslint-disable gs/plz-camel-case-for-action-names */
import { get, set, pick } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';

export default function rewardsReducer(state, action) {
  if (action.type === 'add_reward') {
    const { path } = action;
    get(state, path).push(addUuid({}));
  }

  if (action.type === 'remove_reward') {
    const { path } = action;
    set(state, `${path}._destroy`, true);
  }

  if (action.type === 'change_reward_type') {
    const { path, defaults } = action;

    const rewardAttributes = pick(get(state, path), ['id', '_uuid']);
    set(state, path, {
      ...rewardAttributes, value: '', ...defaults,
    });
  }

  if (action.type === 'add_gift_bag_item') {
    const { path } = action;

    if (!get(state, path)) set(state, path, []);
    get(state, path).push(addUuid({ type: 'Merge::Rewards::Item', quantity: 1 }));
  }

  return state;
}
