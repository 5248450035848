import React from 'react';
import { get } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { useFormContext, FormGroup, useQuery } from '@hooks';
import {
  Label, Field, SelectField, AssetsKeySelectField,
} from '@controls/form';
import { LocalizationsRoutes } from '@pages/routes';
import LevelBanks from '@pages/journeys/level-banks/EventsBlock';
import APP_DATA from '@services/appData';

const allowedAssetTypes = [APP_DATA.enums['Tile::AssetTypes'].SCRAPBOOK];
const tileIconsAssetTypes = [APP_DATA.enums['Tile::AssetTypes'].TILE_ICONS];

export const ConfigurationVariant = React.memo(({
  values, defaultVariant, variantIndex, isArchived,
}) => {
  const { query: { applicationId } } = useRouter();
  const { dispatch } = useFormContext();

  const onBankAdd = (id) => dispatch({ actionType: 'addLevelBankToJourney', id, variantIndex });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeLevelBankFromJourney', id, variantIndex,
  });

  const { response: localizationKeys } = useQuery({
    request: LocalizationsRoutes.Keys.indexRequest,
    queryKey: ['localizationKeys', applicationId],
    toSelectOptions: true,
  });

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Title key">
        <SelectField name="titleTranslationKeyId" options={localizationKeys} includeEmpty />
      </Label>
      <Label
        text={'"New DC" popup description'}
        tooltipText={'Description text the user will see on "New DC" popup of this scrapbook.'}
      >
        <SelectField name="newDcTranslationKeyId" options={localizationKeys} includeEmpty />
      </Label>
      <Label text="Assets">
        <AssetsKeySelectField name="assetKeyId" types={allowedAssetTypes} />
      </Label>
      <Label
        text="Tile Icons asset bundle"
        tooltipText="Specifies the asset bundle containing a pack of tile icons"
      >
        <AssetsKeySelectField name="tileIconsAssetKeyId" types={tileIconsAssetTypes} includeEmpty />
      </Label>

      <LevelBanks
        disabled={isArchived}
        variantAttributes={get(values, variantAttributesKey, values)}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
        eventType="event"
      />
    </FormGroup>
  );
});

const TileConfigurationForm = React.memo(({ values, defaultVariant = false, isArchived }) => (
  <>
    <Label text="Minimum supported version">
      <Field type="text" name="minimumSupportedVersion" />
    </Label>

    <ConfigurationVariant values={values} defaultVariant={defaultVariant} isArchived={isArchived} />
  </>
));

export default TileConfigurationForm;
