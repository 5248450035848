import React from 'react';
import { Route } from 'react-router-dom';

import MachineLearningModelsIndex from '@root/pages/machine-learning-models/Index';
import MachineLearningModelsNew from '@root/pages/machine-learning-models/New';
import MachineLearningModelsEdit from '@root/pages/machine-learning-models/Edit';

import { PageTitle } from '@pages/common';
import RouteContainer from '@pages/RouteContainer';
import { MachineLearningRoutes as Routes } from '@pages/routes';

export default function machineLearning() {
  return [
    <Route
      key={Routes.Models.indexRawPath}
      path={Routes.Models.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="ML Models" />
          <MachineLearningModelsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Models.newRawPath}
      path={Routes.Models.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="ML Model" action="New" />
          <MachineLearningModelsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Models.editRawPath}
      path={Routes.Models.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="ML Model" action="Edit" />
          <MachineLearningModelsEdit />
        </RouteContainer>
      )}
    />,
  ];
}
