import React from 'react';
import { Row, Col } from 'react-bootstrap';
import underscore from 'to-snake-case';
import { useFlashMessages, useRouter } from '@tripledotstudios/react-core';

import { useAbTestingContext } from '@hooks';
import APP_DATA from '@services/appData';
import parseEntityUsageData from '@services/ab-testing/parseEntityUsageData';
import { AbTestingRoutes } from '@pages/routes';

import UsedExperiments from './UsedExperiments';
import ExperimentsDropdown from './ExperimentsDropdown';

export default function FieldWithExperiment({ name, children }) {
  const abTestingContext = useAbTestingContext();
  if (!abTestingContext) return children;

  const { entityData, entityUsage } = abTestingContext;
  const { entityId, entityType } = entityData;
  const router = useRouter();
  const { enums } = APP_DATA;
  const { success, error } = useFlashMessages();

  if (!entityUsage) return '';

  const propertyEntityUsage = parseEntityUsageData(entityUsage, enums.AbExperimentStatuses, name);
  const usedProperties = propertyEntityUsage.usedExperiments;

  const handleAdditionOfPropertyToExperiment = (id) => {
    AbTestingRoutes.Experiments.addPropertyToExperimentRequest({
      id,
      applicationId: router.query.applicationId,
      entity: { entityType, entityId, propertyName: underscore(name) },
    }).then((response) => {
      switch (response.status) {
        case 200:
        case 201:
        case 204:
          success('Experiment has been updated successfully');
          router.push(0);
          break;
        case 403:
          error(response.data.reason);
          break;
        default:
          error('Something went wrong', { details: response.data.message });
      }
    });
  };

  return (
    <>
      <Row data-testid={`${name}_row`}>
        <Col xs={10}>
          <Row>
            {children}
          </Row>
        </Col>
        <Col xs={2}>
          <ExperimentsDropdown
            handleAdd={handleAdditionOfPropertyToExperiment}
            confirmationText={(experimentName) => (
              `The property will be added to a/b experiment "${experimentName}". Continue?`
            )}
            propertyEntityUsage={propertyEntityUsage}
            testId={`add_to_experiment_${underscore(name)}`}
            propertyName={underscore(name)}
            entityType={entityType}
            entityId={entityId}
          />
        </Col>
      </Row>

      <UsedExperiments usedProperties={usedProperties} />
    </>
  );
}
