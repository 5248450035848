import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import appData from '@services/appData';

import {
  FormButtonsGroup,
  StaticFieldsFormGroup,
  Label,
  Field,
  LabelsFormGroup,
  SelectField,
  ServerError,
} from '@controls/form';
import { fromClassNamesToOptions } from '@services/enums';
import { persistedCollection } from '@services/utils';

import IconButton from '@controls/buttons';
import FaButton from '@pages/common/FaButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { Form, FormGroup, useRewardOptions } from '@hooks';
import { rewardableReducer, combineReducers } from '@reducers';

import {
  PageHeader, DuplicateButton, PageSection, Rewards, Alert,
} from '@pages/common';
import { WooAspenRoutes as Routes } from '@pages/routes';

import reducer from './reducer';

const purchaseTypes = appData.enums['Woo::Aspen::PurchaseConfigs::PurchaseTypes'];

const [placementTypeOptions, purchaseTypeOptions] = fromClassNamesToOptions([
  'Woo::Aspen::PurchaseConfigs::PlacementTypes',
  'Woo::Aspen::PurchaseConfigs::PurchaseTypes',
]);

const Option = ({
  dispatch, rewardItems, option, onRemove, index, collectableRewardOptions, options, meta,
}) => (
  <div className="position-relative">
    <div className="position-absolute d-flex flex-column" style={{ left: '-2em', top: 0 }}>
      <FaButton onClick={() => dispatch({ type: 'moveUp', index, meta })} disabled={index === 0}>
        <FontAwesomeIcon icon={faChevronUp} />
      </FaButton>
      <FaButton
        onClick={() => dispatch({ type: 'moveDown', index, meta })}
        disabled={option.position === options.length}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </FaButton>
    </div>
    <PageSection
      title={`Option ${option.position}`}
      actions={<IconButton.Delete onClick={onRemove} minimized />}
    >
      <FormGroup name={index}>
        <Label
          required
          text="Purchase Type"
          tooltipText="Select type if the currency which should be user to the proposal"
        >
          <SelectField name="purchaseType" options={purchaseTypeOptions} />
        </Label>

        {option.purchaseType === purchaseTypes.ECONOMY && (
          <>
            <Label
              required
              text="Type of currency"
              tooltipText="Select type is the currently which should be used for the offer"
            >
              <SelectField name="currency" options={collectableRewardOptions} />
            </Label>
            <Label
              required
              text="Price"
              tooltipText="Provide price for the user"
            >
              <Field name="price" />
            </Label>
            <Label
              required
              text="Old price"
              tooltipText="Provide old price for the user to show the value which he can get if he buys the offer"
            >
              <Field name="oldPrice" />
            </Label>
          </>
        )}

        <PageSection title="Rewards" />
        <Rewards
          dispatch={dispatch}
          rewardable={option}
          rewardItems={rewardItems}
        />
      </FormGroup>
    </PageSection>
  </div>
);

export default function WooAspenPurchaseConfigsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const rewardItems = useRewardOptions();

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([rewardableReducer, reducer])}
    >
      {({ values, dispatch, meta }) => {
        const persistedOptions = persistedCollection(values.optionsAttributes);

        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Purchase Config`}>
              <FormButtonsGroup cancelButtonPath={Routes.PurchaseConfigs.indexPath({ applicationId })}>
                {data.id && (
                  <>
                    <IconButton.ActivityLog
                      applicationId={applicationId}
                      id={data.id}
                      entityType="Woo::Aspen::PurchaseConfig"
                    />
                    <FormButtonsGroup.Divider />
                  </>
                )}
                <DuplicateButton
                  routes={Routes.PurchaseConfigs}
                  data={data}
                  entityName="Purchase Config"
                />
              </FormButtonsGroup>
            </PageHeader>

            <Label required text="Name">
              <Field type="text" name="name" />
            </Label>

            <Label required text="Placement Type">
              <SelectField name="placementType" options={placementTypeOptions} />
            </Label>

            <StaticFieldsFormGroup data={data} />
            <LabelsFormGroup disabled={false} />

            <PageSection title="Purchase options" />

            <ServerError name="optionsAttributes.list" as={Alert} />

            {values.optionsAttributes.map((option, index) => {
              if (option._destroy) return null;

              return (
                <FormGroup name="optionsAttributes" key={option._uuid}>
                  <Option
                    collectableRewardOptions={data.collectableRewardOptions}
                    option={option}
                    dispatch={dispatch}
                    rewardItems={rewardItems}
                    options={persistedOptions}
                    onRemove={() => dispatch({ type: 'removeOption', uuid: option._uuid })}
                    index={index}
                    meta={meta}
                  />
                </FormGroup>
              );
            })}

            <IconButton.New className="float-end" onClick={() => dispatch({ type: 'addOption' })}>
              Add Purchase option
            </IconButton.New>
          </>
        );
      }}
    </Form>
  );
}
