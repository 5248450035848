import React from 'react';

import { cloneDeep } from 'lodash';
import PostcardGraphModal from '@components/merge/shared/PostcardGraphModal';
import transformPostcardAttributes from '../../transformPostcardAttributes';

export default function PostcardGraph({ postcard, onHide }) {
  const storyOrders = postcard.isEditable
    ? transformPostcardAttributes(cloneDeep(postcard)).storyOrdersAttributes
    : postcard.storyOrders;
  return (
    <PostcardGraphModal
      internalId={postcard.internalId}
      onHide={onHide}
    />
  );
}
