import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { useQuery } from '@hooks';
import { streaksEntityVariantsReducer, combineReducers } from '@reducers';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { StreaksRoutes } from '@pages/routes';
import ExperimentScopedTabs from '@pages/ab-testing/experiments/ExperimentScopedTabs';

import VariantForm from './VariantForm';

const reducer = combineReducers([streaksEntityVariantsReducer]);

const statusesEnum = APP_DATA.enums['Streaks::Statuses'];

export default function ExperimentScoped() {
  const {
    Entities: { experimentScopedRequest },
    EntityVariants: { updateVariantsRequest },
  } = StreaksRoutes;

  const { response, setResponse } = useQuery(experimentScopedRequest);
  const { query } = useRouter();
  const { applicationId } = query;
  const isArchived = (status) => status === statusesEnum.ARCHIVED;

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Streak Variant',
    actionName: 'update',
    setData: setResponse,
  });

  const handleSubmit = (values) => updateVariantsRequest({ applicationId, ...values }).then((data) => (
    responseHandler(values.id, data)
  ));

  return (
    <ExperimentScopedTabs
      entityName="Streak"
      handleSubmit={handleSubmit}
      reducer={reducer}
      response={response}
      disableSubmit={({ status }) => isArchived(status)}
    >
      {({ variantAttributes, variantIndex, dispatch }) => (
        <VariantForm values={variantAttributes} dispatch={dispatch} variantIndex={variantIndex} />
      )}
    </ExperimentScopedTabs>
  );
}
