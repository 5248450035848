/* eslint-disable gs/plz-camel-case-for-action-names */
import { addUuid } from '@services/recursivelyProcessObject';
import { set } from 'lodash';

export default function formReducer(state, action) {
  if (action.type === 'change_product_type') {
    state.productRewardAttributes = { ...action.defaults.productReward, rewardAttributes: { value: '' } };
    state.priceType = action.defaults.priceType;
    state.priceValue = action.defaults.priceValue;
    state.storeProductId = null;
  }

  if (action.type === 'reset_product_price') {
    state.priceValue = '';
    state.storeProductId = null;
  }

  if (action.type === 'reset_product_value') {
    set(state, 'productRewardAttributes.rewardAttributes.value', '');
  }

  if (action.type === 'initalize_bundle_product_value') {
    set(state, 'productRewardAttributes.rewardAttributes.rewardsAttributes', []);
    set(state, 'productRewardAttributes.rules', []);
  }

  if (action.type === 'add_bundle_rule') {
    state.productRewardAttributes.rules.push(addUuid({}));
  }

  if (action.type === 'remove_bundle_rule') {
    const { index } = action;

    state.productRewardAttributes.rules.splice(index, 1);
  }

  if (action.type === 'change_bundle_product_rule_type') {
    const { index } = action;

    set(state, `productRewardAttributes.rules[${index}].value`, '');
  }

  return state;
}
