import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import WorldSchemaCell from '@components/merge/shared/WorldSchemaCell';
import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

const ColumnsFactory = ({ query, setDuplicatedRecord, onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Description', accessor: 'description' },
  {
    Header: 'World Schema',
    Cell: ({ row }) => <WorldSchemaCell schema={row.original.worldSchema} />,
  },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({
      row: {
        original: {
          id, name, inUse, worldSchema: { id: schemaId },
        },
      },
    }) => {
      const disabled = isInUse(inUse);
      return (
        <ButtonToolbar>
          <IconButton.Edit
            to={MergeRoutes.UnbubblingConfigurations.editPath({ id, applicationId: query.applicationId })}
            minimized
          />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name, newSchemaId: schemaId })} minimized />
          <IconButton.Delete onClick={onDelete(id, name)} disabled={disabled} minimized />
        </ButtonToolbar>
      );
    },
  },
];

export default ColumnsFactory;
