import { get } from 'lodash';

import APP_DATA from '@services/appData';
import { addUuid } from '@services/recursivelyProcessObject';
import { persistedCollection } from '@services/utils';

const { gameSpecificData } = APP_DATA;

export default (gameType) => (state, action) => {
  const { figureSetsData: { defaultDistribution } } = gameSpecificData[gameType];

  switch (action.type) {
    case 'upload': {
      const [headers, ...rows] = action.data;
      const headerIndex = headers.reduce((acc, header, index) => ({ ...acc, [header]: index }), {});
      state.figuresDistributionsAttributes.forEach((row) => { row._destroy = true; });
      rows.filter((row) => row.length > 1).forEach((row, i) => state.figuresDistributionsAttributes.push(
        addUuid({
          position: i,
          from: row[0],
          set: {
            figures: defaultDistribution.map((distributionRow) => ({
              ...distributionRow,
              frequency: row[headerIndex[distributionRow.name]],
            })),
          },
        }),
      ));
      break;
    }
    case 'insertRow': {
      const { rowIdx } = action;
      state.figuresDistributionsAttributes.splice(rowIdx, 0, addUuid(
        { from: 0, position: rowIdx, set: { figures: defaultDistribution } },
      ));

      for (let i = rowIdx + 1; i < state.figuresDistributionsAttributes.length; i += 1) {
        state.figuresDistributionsAttributes[i].position += 1;
      }

      if (get(state, 'meta.errors.figuresDistributionsAttributes')) {
        for (let i = state.figuresDistributionsAttributes.length; i >= rowIdx; i -= 1) {
          state.meta.errors.figuresDistributionsAttributes[i + 1] = state.meta.errors.figuresDistributionsAttributes[i];
        }
        delete state.meta.errors.figuresDistributionsAttributes[rowIdx];
      }
      break;
    }
    case 'deleteRow': {
      const { rowIdx } = action;
      for (let i = rowIdx; i < state.figuresDistributionsAttributes.length; i += 1) {
        state.figuresDistributionsAttributes[i].position -= 1;
      }
      const removedRow = state.figuresDistributionsAttributes.splice(rowIdx, 1)[0];
      state.figuresDistributionsAttributes.push({ ...removedRow, _destroy: true });
      break;
    }
    case 'updateRow': {
      const { fromRow, column, newRow } = action;
      const { key } = column;
      const targetRow = persistedCollection(state.figuresDistributionsAttributes)[fromRow];
      if (key === 'bank') {
        targetRow.from = newRow[key];
      } else {
        targetRow.set.figures.find((fig) => fig.name === key).frequency = newRow[key];
      }
      break;
    }
    default: break;
  }
  return state;
};
