import { addUuid } from '@services/recursivelyProcessObject';
import removeEntity from '@services/removeEntity';

export default function formReducer(state, action) {
  switch (action.type) {
    case 'addCategory': {
      state.categoriesAttributes.push(addUuid({ position: state.categoriesAttributes.length + 1 }));
      break;
    }
    case 'removeCategory': {
      const { uuid } = action;

      return removeEntity(state, 'categoriesAttributes', uuid, 'position');
    }
    default: break;
  }

  return state;
}
