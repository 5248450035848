import React from 'react';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Col, Row } from 'react-bootstrap';
import { useFlashMessages } from '@tripledotstudios/react-core';

import { FormGroup, useConfirm } from '@hooks';
import { DndIcon } from '@pages/common';
import { ColumnTitle } from '@pages/metagame/chapter-based/chapter-sets/shared';
import Targets from '@components/merge/shared/Targets';
import Rewards from '@components/merge/shared/Rewards';
import { pickRewardsConfigurations } from '@components/merge/services/RewardsConfigurations';

const COLUMN_PADDING_SIZE = 10;

const TaskHeader = styled(Col)`
  font-size: 1.3rem;
  margin-bottom: 10px;
`;

const SeparatedCol = styled(Col)`
  border-right: 1px solid ${({ theme }) => theme.gray};
`;

const AddNewTask = styled(Button)`
  margin-top: 10px;
`;

const Task = styled.div`
  align-items: flex-start;
  display: flex;
  padding: ${COLUMN_PADDING_SIZE / 2}px ${COLUMN_PADDING_SIZE}px ${COLUMN_PADDING_SIZE}px 2.5rem;
`;

const rewardsConfigurations = pickRewardsConfigurations([
  'Item', 'Xp', 'Coins', 'Gems', 'Energy', 'EnergyRefresh', 'Expert', 'GiftBag', 'RefreshAllProducers',
]);

export default function StoryOrder({
  value, items, experts, dispatch,
}) {
  const confirm = useConfirm();
  const { error } = useFlashMessages();
  const storyOrderIndex = value.position - 1;
  const onDragEnd = ({ source, destination }) => {
    dispatch({
      type: 'reorderTasks',
      path: `storyOrdersAttributes[${storyOrderIndex}].tasksAttributes`,
      sourceIndex: source.index,
      destinationIndex: destination.index,
    });
  };
  const addNewTask = () => {
    if (value.tasksAttributes.filter((t) => !t._destroy).length >= 10) {
      error('One Story Order can have up to 10 tasks max');
    } else {
      dispatch({ type: 'addTask', storyOrderIndex });
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-expert-orders">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {value.tasksAttributes.map((task, taskIndex) => {
              if (task._destroy) { return null; }

              const removeTask = () => {
                confirm.showConfirmation({ title: 'Task and its content will be removed. Continue?' })
                  .then(() => dispatch({
                    type: 'removeTask',
                    index: taskIndex,
                    storyOrderIndex,
                  }));
              };
              const { rewardsAttributes } = value.tasksAttributes[taskIndex];
              return (
                <Draggable key={task._uuid} draggableId={task._uuid} index={taskIndex}>
                  {(draggableProvided) => (
                    <Task ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                      <DndIcon {...draggableProvided.dragHandleProps} />
                      <div className="d-flex flex-column flex-grow-1">
                        <Row className="d-flex justify-content-between flex-grow-1">
                          <TaskHeader>{`Task #${task.position} (id: ${task.internalId})`}</TaskHeader>
                          <Button variant="danger" size="sm" onClick={removeTask}>Remove Task</Button>
                        </Row>
                        <FormGroup name={`tasksAttributes[${taskIndex}]`}>
                          <Row className="d-flex">
                            <SeparatedCol>
                              <ColumnTitle>Required Items</ColumnTitle>
                              <Targets values={task.targetsAttributes} items={items} />
                            </SeparatedCol>
                            <Col>
                              <ColumnTitle>Rewards</ColumnTitle>
                              <Rewards
                                values={rewardsAttributes}
                                items={items}
                                experts={experts}
                                rewardsConfigurations={rewardsConfigurations}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                      </div>
                    </Task>
                  )}
                </Draggable>
              );
            })}
            <AddNewTask onClick={addNewTask} variant="success">Add Task</AddNewTask>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
