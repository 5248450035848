import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';

import APP_DATA from '@services/appData';
import { Label } from '@controls/form';

import FilterField from './FilterField';

const { user } = APP_DATA;

export default function CreatedByMeFilter({
  updateFilterField, className, ...rest
}) {
  const [firstRender, setFirstRender] = useState(true);
  const { defaultFilters = {} } = user;

  const onInit = ({ fieldValue }) => {
    if (firstRender && fieldValue === 'true') {
      updateFilterField({ versionsEventIn: ['create', 'duplicate'], versionsWhodunnitEq: user.id });
      setFirstRender(false);
    }
  };

  useEffect(() => {
    if (defaultFilters.createdByMe) {
      updateFilterField({
        versionsEventIn: ['create', 'duplicate'],
        versionsWhodunnitEq: user.id,
        createdByMe: true,
      }, true);
    }
  }, []);

  const onChange = ({ target: { checked } }) => updateFilterField({
    versionsEventIn: checked ? ['create', 'duplicate'] : null,
    versionsWhodunnitEq: checked ? user.id : null,
    createdByMe: checked,
  });

  return (
    <Col md={6} className="ms-0 pt-3">
      <FilterField
        canAdd={({ currentValue }) => currentValue}
        onInit={onInit}
        hideButton
        name="createdByMe"
        updateFilterField={updateFilterField}
        // eslint-disable-next-line react/no-unstable-nested-components
        FieldComponent={({ fieldValue }) => (
          <Label fieldSize={8} labelSize={4} text="Created by me">
            <input
              className="mt-2"
              onChange={onChange}
              checked={fieldValue === 'true'}
              type="checkbox"
              {...rest}
            />
          </Label>
        )}
      />
    </Col>
  );
}
