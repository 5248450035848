import { get, set } from 'lodash';

import buildNewTranslationKey from '../changed-table/cells/buildNewTranslationKey';

export default function formReducer(state, action) {
  const { keyConfigs, meta } = state;

  switch (action.type) {
    case 'pushChangedRow': {
      const { rowData } = action;

      return { keyConfigs: [...keyConfigs, rowData], meta };
    }
    case 'addTranslationKey': {
      const { newTranslationKey } = action;

      return { keyConfigs: [...keyConfigs, newTranslationKey], meta };
    }
    case 'addTranslationKeyFromCSV': {
      return { keyConfigs: action.data };
    }

    case 'updateFormat': {
      const { fullName, value } = action;

      set({ keyConfigs }, fullName, value);

      return { keyConfigs };
    }

    case 'processTranslationKeyNameChange': {
      const { path, newValue } = action;
      const translationKey = get(state, path);
      const newTranslationKey = buildNewTranslationKey({ translationKey, newValue });

      set(translationKey, '_destroy', true);

      return { keyConfigs: [...keyConfigs, newTranslationKey], meta };
    }

    case 'processPersistedTranslationKeyNameChange': {
      const { rowData, newValue } = action;
      const newTranslationKey = buildNewTranslationKey({ translationKey: rowData, newValue });

      set(rowData, '_destroy', true);

      return { keyConfigs: [...keyConfigs, newTranslationKey, rowData], meta };
    }

    case 'populateInUse': {
      const { data, inUseOffset, newOffset } = action;

      keyConfigs.slice(inUseOffset, newOffset).forEach(({ keyId }, index) => {
        set(keyConfigs, `${inUseOffset + index}.inUse`, data[keyId] || {});
      });

      return { keyConfigs };
    }

    case 'undoRowChanges': {
      const { _uuid } = action;

      state.keyConfigs = keyConfigs.filter((config) => config._uuid !== _uuid);

      return state;
    }

    default:
      return state;
  }
}
