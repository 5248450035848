import React from 'react';
import { useTheme } from 'styled-components';
import { Sidebar } from '@tripledotstudios/react-core';

import { faBullseye } from '@fortawesome/free-solid-svg-icons';

export default function DailyChallengesNavigationSection({
  routingScope,
  minimized = false,
  includeLevelBanks = true,
}) {
  const theme = useTheme();

  return (
    <Sidebar.SectionItem
      icon={faBullseye}
      title="Daily Challenges"
      color={theme.green}
      minimized={minimized}
    >
      <Sidebar.LinkItem
        to={`${routingScope}/daily_challenges/events`}
        title="Events"
      />
      {includeLevelBanks && (
        <Sidebar.LinkItem
          to={`${routingScope}/daily_challenges/level_banks`}
          title="Level Banks"
        />
      )}
    </Sidebar.SectionItem>
  );
}
