import React, { useState, useEffect } from 'react';

import { useRouter } from '@tripledotstudios/react-core';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { LocalizationsRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Localisations config',
    actionName: 'create',
    redirectPath: LocalizationsRoutes.Configs.indexPath(query),
    setData,
  });

  const onSubmit = (values) => LocalizationsRoutes.Configs
    .createRequest({ ...values, applicationId: query.applicationId })
    .then(responseHandler);

  useEffect(() => {
    LocalizationsRoutes.Configs.newRequest(query).then((response) => setData(response.data));
  }, []);

  return data && (
    <Form
      data={data}
      onSubmit={onSubmit}
    />
  );
}
