import React from 'react';
import { useTheme } from 'styled-components';
import { Sidebar } from '@tripledotstudios/react-core';

import { faListOl, faWindowMaximize } from '@fortawesome/free-solid-svg-icons';
import { FlexiblePopupRoutes, SolRoutes } from '@pages/routes';
import {
  ApplicationItemSet,
  AdsNavigationSection,
  FilesNavigationItem,
  AssetsNavigationItem,
  RewardsNavigationItem,
} from './common';

export default function SolitaireScapes({ applicationId, routingScope, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="solitaire_scapes"
      routingScope={routingScope}
      minimized={minimized}
    >
      <FilesNavigationItem minimized={minimized} applicationId={applicationId} />
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />
      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />
      <Sidebar.SectionItem
        icon={faWindowMaximize}
        title="Flexible Popups"
        color={theme.green}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={FlexiblePopupRoutes.Popups.indexPath({ applicationId })}
          title="Flexible Popups"
        />
        <Sidebar.LinkItem
          to={FlexiblePopupRoutes.Actions.indexPath({ applicationId })}
          title="Actions"
        />
      </Sidebar.SectionItem>
      <Sidebar.SectionItem
        icon={faListOl}
        title="Mastery Level"
        color={theme.purple}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={SolRoutes.PlayerLevelSets.indexPath({ applicationId })}
          title="Player Levels Sets"
        />
        <Sidebar.LinkItem
          to={SolRoutes.WinSets.indexPath({ applicationId })}
          title="Xth Win Sets"
        />
        <Sidebar.LinkItem
          to={SolRoutes.FirstTryStreakConfigs.indexPath({ applicationId })}
          title="First Try Streak Configs"
        />
      </Sidebar.SectionItem>
      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
