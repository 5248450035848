import React from 'react';
import { get } from 'lodash';

import APP_DATA from '@services/appData';
import Label from './Label';

const buildTooltip = (tooltip, clientKey) => {
  if (!tooltip && !clientKey) return null;

  return (
    <>
      {tooltip && <p>{tooltip}</p>}
      {clientKey && <strong>{`(${clientKey})`}</strong>}
    </>
  );
};

const localizeField = (locale, fieldName) => {
  const fieldLocale = get(locale, fieldName);
  if (!fieldLocale) return {};

  const tooltip = buildTooltip(fieldLocale.tooltip, fieldLocale.clientKey);

  return {
    text: fieldLocale.label,
    tooltipText: tooltip,
    copyableText: fieldLocale.clientKey,
  };
};

export default function LocalizedLabel(localeNamespace) {
  const locale = get(APP_DATA, `modelsLocales.${localeNamespace}`);

  return ({ name, children, ...rest }) => (
    <Label key={name} {...rest} {...localizeField(locale, name)}>
      {children}
    </Label>
  );
}
