import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useRouter } from '@tripledotstudios/react-core';

import { combineReducers } from '@reducers';
import { Form, FormGroup } from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup, ServerError,
} from '@controls/form';
import {
  DuplicateButton, PageHeader, PageSection, ReactTable,
} from '@pages/common';
import { SolRoutes as Routes } from '@pages/routes';

import formReducer from './reducer';

const reducer = combineReducers([formReducer]);

const columns = () => [
  {
    Header: 'Xth Win',
    accessor: 'wonGamesCount',
    className: 'w-15',
    Cell: ({ row: { original: { path } } }) => (
      <FormGroup name={path}>
        <Field name="wonGamesCount" type="number" />
      </FormGroup>
    ),
  },
  {
    Header: 'XP',
    accessor: 'xp',
    className: 'w-15',
    Cell: ({ row: { original: { path } } }) => (
      <FormGroup name={path}>
        <Field name="xp" type="number" />
      </FormGroup>
    ),
  },
  {
    Header: '',
    className: 'w-10',
    id: 'actions',
    Cell: ({ row }) => {
      const { dispatch, _uuid } = row.original;
      return (
        <Button
          className="mt-0 mb-0"
          variant="danger"
          icon={faTrashAlt}
          onClick={() => dispatch({ type: 'removeLevel', uuid: _uuid })}
          title="Delete the level"
          size="sm"
        >
          Delete
        </Button>
      );
    },
  },
];

const prepareLevels = (levels, dispatch) => levels.reduce((acc, level, index) => {
  if (level._destroy) { return acc; }

  return [
    ...acc,
    {
      ...level,
      path: `levelsAttributes[${index}]`,
      index,
      dispatch,
    },
  ];
}, []);

export default function WinSetForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();

  const memoizedColumns = useMemo(columns, []);

  return (
    <Form
      initialValues={{ ...data, applicationId }}
      onSubmit={onSubmit}
      reducer={reducer}
    >
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Xth Win Set`}>
            <FormButtonsGroup cancelButtonPath={Routes.WinSets.indexPath({ applicationId })}>
              <DuplicateButton
                routes={Routes.WinSets}
                data={data}
                entityName="Xth Win Set"
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label required text="Name">
            <Field name="name" />
          </Label>
          <StaticFieldsFormGroup data={data} />

          <PageSection title="XP bonus for Xth Win of the day" />
          <p><ServerError name="levelsAttributes.list" /></p>
          <ReactTable data={prepareLevels(values.levelsAttributes, dispatch)} columns={memoizedColumns} />
          <div className="text-end">
            <Button onClick={() => dispatch({ type: 'addLevel' })}>Add</Button>
          </div>
        </>
      )}
    </Form>
  );
}
