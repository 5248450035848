import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { generateChapterBasedRoutes } from '@pages/routes';
import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InLiveBadge,
  InLiveColumnHeader,
  InUseColumn,
  LabelsColumn,
  DownloadButton,
  DuplicationModal,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  useCurrentApplication, useQuery, useConfirm, useMassOperations,
} from '@hooks';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';
import CollapsibleDescription from './CollapsibleDescription';

import Filter from './Filter';

const CountColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const NameColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const banksColumns = ({
  applicationId, BankRoutes, onDelete, onDuplicate, supportExport,
}) => ([
  { Header: 'Id', accessor: 'id' },
  {
    Header: 'Bank name',
    accessor: 'name',
    Cell: ({ row }) => (
      <NameColumn>
        <p>{row.original.name}</p>
        <CollapsibleDescription text={row.original.description} />
      </NameColumn>
    ),
  },
  LabelsColumn,
  InUseColumn,
  {
    id: 'availability_header',
    Header: () => <InLiveColumnHeader />,
    Cell: ({ row }) => <InLiveBadge inLive={row.original.availabilityStateLive} />,
  },
  {
    Header: 'Layouts',
    accessor: 'levelLayoutsCount',
    Cell: ({ row: { original: { levelLayoutsCount } } }) => <CountColumn>{levelLayoutsCount}</CountColumn>,
  },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, inUse, name } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={BankRoutes.editPath({ applicationId, id })} readonly minimized />
        <IconButton.Duplicate onClick={() => onDuplicate({ id, name })} minimized />
        {supportExport && (
          <DownloadButton
            request={(_, options) => BankRoutes.downloadRequest({ applicationId, id }, options)}
            minimized
          />
        )}
        <IconButton.Delete
          onClick={onDelete(id, name)}
          disabled={isInUse(inUse)}
          title={isInUse(inUse) ? 'Disabled as the entity is used' : null}
          minimized
        />
      </ButtonToolbar>
    ),
  },
]);

export default function Index({
  columnsOverride = null, Import, replacementPath, supportExport,
}) {
  const { typeName: gameType, applicationId } = useCurrentApplication();
  const BankRoutes = generateChapterBasedRoutes(gameType).Banks;
  const { response, refetch } = useQuery(BankRoutes.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Level layout bank',
    actionName: 'delete',
    refetch,
  });
  const {
    MassOperationsHeaderButtons,
    MassOperationsModal,
    massOperationsTableProps,
    selectedItems,
  } = useMassOperations();

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Bank '${name}' and its content will be deleted and not available for work. Continue?`,
    })
      .then(() => BankRoutes.deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columnsFactory = columnsOverride || banksColumns;
  const columns = React.useMemo(() => columnsFactory({
    applicationId, BankRoutes, onDelete, onDuplicate: setDuplicatedRecord,
  }), []);

  return response && (
    <div>
      <PageHeader title="Level Layouts Banks" filter={<Filter />}>
        <ButtonToolbar>
          {supportExport && (
            <MassOperationsHeaderButtons actions={[]}>
              <DownloadButton
                request={(_, options) => BankRoutes.bulkDownloadRequest({ applicationId, ids: selectedItems }, options)}
                disabled={selectedItems.length === 0}
                label={`Mass Download ${selectedItems.length ? ` (${selectedItems.length})` : ''}`}
              />
            </MassOperationsHeaderButtons>
          )}
          <IconButton.New to={BankRoutes.newPath({ applicationId })} />
          {Import && <Import refetch={refetch} />}
          {replacementPath && (
            <IconButton.Restore to={replacementPath({ applicationId })} variant="primary">
              Replace Level Layout
            </IconButton.Restore>
          )}
        </ButtonToolbar>
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
        {...massOperationsTableProps}
      />
      <DuplicationModal
        entityName="Bank"
        handleClose={() => setDuplicatedRecord(null)}
        record={duplicatedRecord}
        title="Duplicate Bank"
        onSubmit={(values) => BankRoutes.duplicateRequest({ ...values, applicationId })}
      />
      <MassOperationsModal
        pageModelName="Bank"
        modelName="Metagame::ChapterBased::Bank"
        transactional
        includeAvailabilityState
        refetch={refetch}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
