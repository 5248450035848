import React from 'react';

import { AppEventsRoutes } from '@pages/routes';

import Form from './Form';

export default function NewModal(props) {
  const { newRequest, createRequest } = AppEventsRoutes;

  return (
    <Form actionName="creation" getForm={newRequest} submitForm={createRequest} {...props} />
  );
}
