import React, { useCallback, memo } from 'react';
import { memoize } from 'lodash';
import { Alert } from '@tripledotstudios/react-core';
import { Label, SelectField } from '@controls/form';
import getFieldLocale from '@services/getFieldLocale';
import areEnabledPropsEqual from '@services/fields-factory/areEnabledPropsEqual';
import localizeField from '@services/fields-factory/localizeField';
import { fromClassNamesToOptions } from '@services/enums';

import APP_DATA from '@services/appData';

import {
  fieldBuilder,
  selectBuilder,
  arrayFieldBuilder,
  fieldGroupBuilder,
  fieldArrayBuilder,
  timePeriodFieldBuilder,
  buildQuickLinkFn,
  warningBuilder,
} from '@services/fields-factory';

import dividerBuilder from '@services/fields-factory/field-group-builder/dividerBuilder';

import {
  AssetsRoutes,
  LocalizationsRoutes,
  WoodokuRoutes,
  FilesRoutes,
  FigureSetsRoutes,
} from '@pages/routes';

import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';
import {
  zendeskFields,
  offlineAdsFields,
  offlineGamesSettingsFields,
  adRequestDelayFields,
} from '@components/game-settings/Resource/Shared';
import { Col } from 'react-bootstrap';

import selectOptionsWithQuickLink from '@services/selectOptionsWithQuickLink';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import RateUsPopupsBlock from './RateUsPopupsBlock';
import JourneySurfacingPopupsBlock from './JourneySurfacingPopupsBlock';
import HomeScreenWidgetsVariant from './HomeScreenWidgetsVariant';

const popupsTriggersOptions = [
  { label: 'IDFA', value: 'IDFA' },
  { label: 'GDPR', value: 'GDPR' },
  { label: 'Push Notifications', value: 'Allow_Notifications' },
  { label: 'PP/T&C', value: 'Privacy_Policy' },
  { label: 'Google CMP', value: 'Google_CMP' },
  { label: 'Push notifications retry', value: 'PushNotifications_Retry_OptIn' },
];

const popupsOptions = [
  { label: 'IDFA', value: 'IDFA' },
  { label: 'Languages (DEPRECATED since 3.20.00)', value: 'Language_Menu' },
  { label: 'PP/T&C (DEPRECATED since 3.20.00)', value: 'Privacy_Policy' },
  { label: 'GDPR', value: 'GDPR' },
  { label: 'Push Notifications', value: 'Allow_Notifications' },
  { label: 'Rate Us (DEPRECATED since 3.20.00)', value: 'Rate_Us' },
  { label: 'Native App Update (DEPRECATED since 3.20.00)', value: 'Native_app_update' },
  { label: 'Google CMP', value: 'Google_CMP' },
  { label: 'Push notifications retry', value: 'PushNotifications_Retry_OptIn' },
];

const iconStyleOptions = [
  { label: 'Default', value: 'default' },
  { label: 'Bespoke', value: 'minimalist' },
];

const rateUsPopupTypes = [
  { label: '5 Stars', value: 'rate_us_popup_5_stars' },
];

const privacyPopupTypes = [
  { label: 'Native', value: 'native' },
  { label: 'In game', value: 'in_game' },
];

const fileTypesEnum = APP_DATA.enums['Woodoku::FileTypesEnum'];
const playOnPurchaseTypeEnum = APP_DATA.enums['Woodoku::PowerUps::PurchaseType'];
const playOnTypeEnum = APP_DATA.enums['Woodoku::PowerUps::Type'];
const playOnHiddenFn = (values) => (
  values?.data?.purchaseType ?? values?.playOnConfiguration?.purchaseType) !== playOnPurchaseTypeEnum.COINS;

const playOnTypeRocketHiddenFn = (values) => (
  values?.data?.type ?? values?.playOnConfiguration?.type) !== playOnTypeEnum.ROCKET;

const playOnTypeFigureBankHiddenFn = (values) => (
  values?.data?.type ?? values?.playOnConfiguration?.type) !== playOnTypeEnum.FIGURE_BANK;

const xpLevelConfigField = ({ options, applicationId }) => {
  const quickLinkFn = buildQuickLinkFn(WoodokuRoutes.XpLevelConfigs, applicationId);
  const fieldName = 'xpLevelConfigId';
  return ({
    [fieldName]: {
      Inline: memo(({ name, disabled }) => {
        const fieldLocale = getFieldLocale('woodoku.gameSettings', fieldName);
        return (
          <>
            <SelectField
              name={name}
              options={options}
              quickLinkFn={quickLinkFn}
              isDisabled={disabled}
            />
            <Alert className="mt-2" variant="primary">
              {fieldLocale.warning}
            </Alert>
          </>
        );
      }, areEnabledPropsEqual),
      Labeled: memo(({
        disabled,
        localeNamespace,
        locale = {},
        tooltipBuilder,
      }) => {
        const {
          label,
          tooltip,
          clientKey,
          raw,
        } = localizeField(
          fieldName, null, {}, localeNamespace, locale, tooltipBuilder,
        );

        return (
          <Col>
            <Label text={label} copyableText={clientKey} fieldSize={8} tooltipText={tooltip}>
              <SelectField
                name={fieldName}
                options={options}
                quickLinkFn={quickLinkFn}
                isDisabled={disabled}
              />
            </Label>
            <Alert className="mt-2" variant="primary">
              {raw.warning}
            </Alert>
          </Col>
        );
      }, areEnabledPropsEqual),
    },
  });
};

const Fields = memoize(({
  applicationId,
  unlockJourneyOptions,
  dcGemCountersCountdownDirections,
  flexiblePopupButtonTypes,
  gameEndLoseJourneyFlows,
  playOnAreasOfEffect,
  playOnTypes,
  powerUpPurchaseTypes,
  powerUpTypes,
  translationOptions,
  figureSetOptions,
  dynamicConfigOptions,
  journeyNotifyTypes,
  startLobbyOrderOptions,
  firstBankValidModesOptions,
  fileQuestOptions,
  filePercentileOptions,
  lobbyVariantOptions,
  skinVariantOptions,
  replayTimeOptions,
  purchaseTypeOptions,
  coinRewardMomentOptions,
  shapesBankSetOptions,
  xpLevelConfigOptions,
}) => ({
  ...fieldBuilder({ name: 'rewardByPlacing', type: 'number' }),
  ...fieldBuilder({ name: 'rewardByClearing', type: 'number' }),
  ...fieldBuilder({ name: 'rewardByCombo', type: 'number' }),
  ...fieldBuilder({ name: 'rewardByStreak', type: 'number' }),
  ...fieldBuilder({ name: 'adPlacement', type: 'text' }),
  ...fieldBuilder({ name: 'oneValidMoveRule', type: 'checkbox' }),
  ...fieldBuilder({ name: 'adNewUserCooldown', type: 'number' }),
  ...fieldBuilder({ name: 'dcBlocksToWinMax', type: 'number' }),
  ...fieldBuilder({ name: 'dcBlocksToWinMin', type: 'number' }),
  ...fieldBuilder({ name: 'dcSpecialTilesChance', type: 'number' }),
  ...fieldBuilder({ name: 'dcSpecialBlockChanceMax', type: 'number' }),
  ...fieldBuilder({ name: 'dcSpecialBlockChanceMin', type: 'number' }),
  ...fieldBuilder({ name: 'dcSymmetricalConfig', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dcStartingBlocksRegionMax', type: 'number' }),
  ...fieldBuilder({ name: 'dcBlocksMax', type: 'number' }),
  ...fieldBuilder({ name: 'dcBlocksMin', type: 'number' }),
  ...fieldBuilder({ name: 'tinyGemRequirements', type: 'number' }),
  ...fieldBuilder({ name: 'smallGemRequirements', type: 'number' }),
  ...fieldBuilder({ name: 'mediumGemRequirements', type: 'number' }),
  ...fieldBuilder({ name: 'largeGemRequirements', type: 'number' }),
  ...fieldBuilder({ name: 'adsInterstitialRetryTimeout', type: 'number' }),
  ...fieldBuilder({ name: 'adsRewardedVideoRetryTimeout', type: 'number' }),
  ...fieldBuilder({ name: 'dynamicConfigCooldown', type: 'number' }),
  ...fieldBuilder({ name: 'dynamicConfigDefaultDifficulty', type: 'number' }),
  ...fieldBuilder({ name: 'showLanguageSelectPopupOnLaunch', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dcSurfaceAfter', type: 'number' }),
  ...fieldBuilder({ name: 'dcSurfacePersists', type: 'checkbox' }),
  ...fieldBuilder({ name: 'statsToday', type: 'checkbox' }),
  ...fieldBuilder({ name: 'statsWeek', type: 'checkbox' }),
  ...fieldBuilder({ name: 'statsMonth', type: 'checkbox' }),
  ...fieldBuilder({ name: 'statsYear', type: 'checkbox' }),
  ...fieldBuilder({ name: 'splashscreenTimeout', type: 'number' }),
  ...selectBuilder({ name: 'iconStyle', options: iconStyleOptions }),
  ...fieldBuilder({ name: 'showPurseInLobby', type: 'checkbox' }),
  ...fieldBuilder({ name: 'showPurseInJourney', type: 'checkbox' }),
  ...fieldBuilder({ name: 'initialCoins', type: 'number' }),
  ...selectBuilder({ name: 'coinsRewardMoment', options: coinRewardMomentOptions }),
  ...fieldBuilder({ name: 'coinRewardForJourneyWin', type: 'number' }),
  ...fieldBuilder({ name: 'coinRewardForJourneyLose', type: 'number' }),
  ...fieldBuilder({ name: 'coinRewardForDcWin', type: 'number' }),
  ...fieldBuilder({ name: 'coinRewardForClassicNoHighScore', type: 'number' }),
  ...fieldBuilder({ name: 'coinRewardForClassicDailyHighScore', type: 'number' }),
  ...fieldBuilder({ name: 'coinRewardForClassicWeeklyHighScore', type: 'number' }),
  ...fieldBuilder({ name: 'coinRewardForClassicMonthlyHighScore', type: 'number' }),
  ...fieldBuilder({ name: 'coinRewardForClassicYearlyHighScore', type: 'number' }),
  ...fieldBuilder({ name: 'coinRewardForClassicAllTimeHighScore', type: 'number' }),
  ...fieldBuilder({ name: 'gameStartAnimationInClassic', type: 'checkbox' }),
  ...fieldBuilder({ name: 'gameStartAnimationInDc', type: 'checkbox' }),
  ...fieldBuilder({ name: 'gameStartAnimationInJourney', type: 'checkbox' }),
  ...fieldBuilder({ name: 'nativeAppUpdate', type: 'number' }),
  ...timePeriodFieldBuilder({ name: 'newJourneyPopupDelay', inputFields: ['seconds'] }),
  ...fieldBuilder({ name: 'xForUnlockJourney', type: 'number' }),
  ...fieldBuilder({ name: 'classicScreenNewDesign', type: 'checkbox' }),
  ...fieldBuilder({ name: 'lobbyScreenEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'onboardingTutorialRowStepEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'onboardingTutorialColumnStepEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'onboardingTutorialRegionStepEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'onboardingTutorialComboStepEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'onboardingTutorialGameEndStepEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'flexiblePopupEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dcAndClassicScreensEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'gemCollectionButtonLobby', type: 'checkbox' }),
  ...fieldBuilder({ name: 'calendarButtonLobby', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dataStorageCheckEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'minDaysSinceLastPopupExposure', type: 'number' }),
  ...fieldBuilder({ name: 'gameScreenRestartButtonEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'questionnaireConfigJson', type: 'text', fieldOptions: { component: 'textarea' } }),
  ...fieldBuilder({ name: 'classicLobbyForceContinueButtonEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dcModeEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'liveopsEnabled', type: 'checkbox' }),
  ...selectBuilder({ name: 'unlockJourney', options: unlockJourneyOptions }),
  ...selectBuilder({ name: 'dcGemCountersIncrement', options: dcGemCountersCountdownDirections }),
  ...selectBuilder({ name: 'gameEndLoseJourneyFlow', options: gameEndLoseJourneyFlows }),
  ...selectBuilder({ name: 'classicPlaceableShapesConfigId', ...shapesBankSetOptions }),
  ...selectBuilder({ name: 'dcPlaceableShapesConfigId', ...shapesBankSetOptions }),
  ...selectBuilder({ name: 'journeyPlaceableShapesConfigId', ...shapesBankSetOptions }),
  ...fieldArrayBuilder(
    {
      name: 'miniGamesUrls',
      reorderable: true,
      handleOnly: true,
      fieldsConfiguration: [
        { type: 'text', label: 'url', default: '' },
      ],
    },
  ),
  ...fieldArrayBuilder(
    {
      name: 'rateUsPopups',
      tooltipText: '',
      isHorizontal: true,
      fieldsConfiguration: [
        {
          label: 'Pop-up type', name: 'type', as: 'select', componentData: rateUsPopupTypes,
        },
        { label: 'Redirect to the App Store', name: 'redirectToAppStore', type: 'checkbox' },
        {
          label: 'Trigger',
          as: 'component',
          componentFn: ({ name, disabled }) => [RateUsPopupsBlock, { name, disabled }],
        },
        { label: 'Block the interstitial before pop-up trigger', name: 'blockInterstitial', type: 'checkbox' },
      ],
    },
  ),
  ...fieldArrayBuilder(
    {
      name: 'journeySurfacingPopupTriggers',
      tooltipText: '',
      isHorizontal: true,
      forceFieldLabels: true,
      fieldsConfiguration: [
        {
          label: 'Trigger',
          as: 'component',
          componentFn: ({ name, disabled }) => [JourneySurfacingPopupsBlock, { name, disabled }],
        },
      ],
    },
  ),
  ...selectBuilder(
    {
      name: 'lobby',
      options: [
        { label: 'metagame_lobby', value: 'metagame_lobby' },
      ],
    },
  ),
  ...selectBuilder({ name: 'dcFigureSetId', ...figureSetOptions }),
  ...selectBuilder({ name: 'dynamicConfigId', ...dynamicConfigOptions }),
  ...fieldGroupBuilder(
    {
      name: 'dcAdaptiveDifficulty',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'goalAmountInitial', type: 'number' }),
        ...fieldBuilder({ name: 'goalAmountMax', type: 'number' }),
        ...fieldBuilder({ name: 'goalAmountMin', type: 'number' }),
        ...fieldBuilder({ name: 'attemptsThreshold', type: 'number' }),
        ...fieldBuilder({ name: 'goalAmountIncrement', type: 'number' }),
        ...fieldBuilder({ name: 'goalAmountDecrement', type: 'number' }),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'flexiblePopup',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'id', type: 'text' }),
        ...selectBuilder({ name: 'titleTranslationKeyId', options: translationOptions }),
        ...selectBuilder({ name: 'messageTranslationKeyId', options: translationOptions, includeEmpty: true }),
        ...selectBuilder({ name: 'buttonTranslationKeyId', options: translationOptions }),
        ...fieldBuilder({ name: 'buttonTarget', type: 'text' }),
        ...selectBuilder({ name: 'buttonTargetType', options: flexiblePopupButtonTypes }),
        ...fieldBuilder({ name: 'gameStartX', type: 'number' }),
        ...fieldBuilder({ name: 'minTriggeredDaysY', type: 'number' }),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'playOnConfiguration',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'available', type: 'checkbox' }),
        ...fieldBuilder({ name: 'classic', type: 'checkbox' }),
        ...fieldBuilder({ name: 'dc', type: 'checkbox' }),
        ...fieldBuilder({ name: 'journey', type: 'checkbox' }),
        ...fieldBuilder({ name: 'surfaceFromAttempt', type: 'number' }),
        ...fieldBuilder({ name: 'purchaseAttempts', type: 'number' }),
        ...timePeriodFieldBuilder({ name: 'purchaseCooldown', type: 'number' }),
        ...selectBuilder({ name: 'purchaseType', options: powerUpPurchaseTypes }),
        ...arrayFieldBuilder({
          name: 'rocketCoinsCost',
          type: 'text',
          fieldOptions: {
            hiddenFn: playOnHiddenFn,
          },
        }),
        ...selectBuilder({ name: 'type', options: playOnTypes }),
        ...selectBuilder({
          name: 'rocketLayout',
          options: playOnAreasOfEffect,
          fieldOptions: {
            hiddenFn: playOnTypeRocketHiddenFn,
          },
        }),
        ...fieldBuilder({
          name: 'banksAmount',
          type: 'number',
          fieldOptions: {
            hiddenFn: playOnTypeFigureBankHiddenFn,
          },
        }),
        ...warningBuilder({
          name: 'warning',
          variant: 'danger',
          fieldOptions: {
            hiddenFn: playOnHiddenFn,
          },
        }),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'firstBankValidConfiguration',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'firstBankValidRule', type: 'checkbox' }),
        ...selectBuilder({
          name: 'firstBankValidModes',
          isReorderable: true,
          isMulti: true,
          options: firstBankValidModesOptions,
        }),
      },
    },
  ),
  ...fieldGroupBuilder({
    name: 'privacyPolicyPopup',
    fieldsConfiguration: {
      ...selectBuilder({ name: 'popupType', options: privacyPopupTypes }),
      ...selectBuilder({ name: 'titleTranslationKeyId', options: translationOptions, includeEmpty: true }),
      ...selectBuilder({ name: 'bodyTranslationKeyId', options: translationOptions, includeEmpty: true }),
      ...selectBuilder({ name: 'buttonTranslationKeyId', options: translationOptions, includeEmpty: true }),
    },
  }),
  ...selectBuilder({ name: 'journeyNotifyType', options: journeyNotifyTypes }),
  ...selectBuilder(
    {
      name: 'startLobbyOrder',
      isMulti: true,
      isReorderable: true,
      options: startLobbyOrderOptions,
    },
  ),
  ...fieldGroupBuilder({
    name: 'questsConfig',
    fieldsConfiguration: {
      ...fieldBuilder({ name: 'available', type: 'checkbox' }),
      ...selectBuilder({ name: 'configFileId', ...fileQuestOptions }),
    },
  }),
  ...fieldGroupBuilder({
    name: 'shareButtonEnabled',
    fieldsConfiguration: {
      ...fieldBuilder({ name: 'newHighScore', type: 'checkbox' }),
      ...fieldBuilder({ name: 'dcWin', type: 'checkbox' }),
      ...fieldBuilder({ name: 'journeyWin', type: 'checkbox' }),
    },
  }),
  ...fieldGroupBuilder({
    name: 'journeyBadgesConfiguration',
    fieldsConfiguration: {
      ...fieldBuilder({ name: 'showOnAwards', type: 'checkbox' }),
      ...fieldBuilder({ name: 'showOnMap', type: 'checkbox' }),
    },
  }),
  ...fieldBuilder({ name: 'journeyGameScreenThemeEnabled', type: 'checkbox' }),
  ...fieldGroupBuilder({
    name: 'percentileForScoresConfig',
    fieldsConfiguration: {
      ...selectBuilder({ name: 'scoresConfigId', ...filePercentileOptions }),
      ...dividerBuilder({ title: 'Placement' }),
      ...fieldBuilder({ name: 'showInWidget', type: 'checkbox' }),
      ...fieldBuilder({ name: 'showInEndGamePopup', type: 'checkbox' }),
      ...dividerBuilder({ title: 'Score type' }),
      ...fieldBuilder({ name: 'statsTypeEnabledToday', type: 'checkbox' }),
      ...fieldBuilder({ name: 'statsTypeEnabledWeek', type: 'checkbox' }),
      ...fieldBuilder({ name: 'statsTypeEnabledMonth', type: 'checkbox' }),
      ...fieldBuilder({ name: 'statsTypeEnabledYear', type: 'checkbox' }),
      ...fieldBuilder({ name: 'statsTypeEnabledAlltime', type: 'checkbox' }),
    },
  }),
  ...fieldGroupBuilder({
    name: 'homeScreenConfig',
    fieldsConfiguration: {
      ...selectBuilder({ name: 'lobbyVariant', options: lobbyVariantOptions }),
      ...fieldBuilder(
        {
          name: 'widgetsVariant',
          as: 'component',
          componentFn: ({ disabled }) => <HomeScreenWidgetsVariant disabled={disabled} />,
        },
      ),
      ...selectBuilder({ name: 'skinVariant', options: skinVariantOptions }),
    },
  }),
  ...fieldGroupBuilder(
    {
      name: 'dcReplayConfig',
      fieldsConfiguration: {
        ...selectBuilder({ name: 'replayTimeRange', options: replayTimeOptions }),
        ...selectBuilder({ name: 'purchaseType', options: purchaseTypeOptions }),
        ...fieldBuilder({ name: 'tutorialRequireCurrentDcCompleted', type: 'checkbox' }),
      },
    },
  ),
  ...xpLevelConfigField({ ...xpLevelConfigOptions, applicationId }),
  ...fieldGroupBuilder(
    {
      name: 'powerUpConfiguration',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'classicModeEnabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'dcModeEnabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'journeyModeEnabled', type: 'checkbox' }),
        ...selectBuilder({ name: 'purchaseType', options: powerUpPurchaseTypes }),
        ...fieldArrayBuilder(
          {
            name: 'powerUpEntities',
            isHorizontal: true,
            fieldsConfiguration: [
              { name: 'powerUpType', as: 'select', componentData: powerUpTypes },
              { name: 'initialAmount', type: 'number' },
              { name: 'purchaseAmount', type: 'number' },
              {
                name: 'coinsCost',
                as: 'array',
                disabledFn: (values) => values.purchaseType !== 0,
                default: [],
              },
            ],
          },
        ),
      },
    },
  ),
}), (args) => JSON.stringify(args));

const fieldsFactory = ({ applicationId }) => {
  const [
    unlockJourneyOptions,
    dcGemCountersCountdownDirections,
    flexiblePopupButtonTypes,
    gameEndLoseJourneyFlows,
    playOnAreasOfEffect,
    playOnTypes,
    powerUpPurchaseTypes,
    powerUpTypes,
    journeyNotifyTypes,
    startLobbyOrderOptions,
    firstBankValidModesOptions,
    lobbyVariantOptions,
    skinVariantOptions,
    replayTimeOptions,
    purchaseTypeOptions,
    coinRewardMomentOptions,
  ] = fromClassNamesToOptions([
    'Woodoku::UnlockJourneyOptions',
    'Woodoku::DcGemCountersCountdownDirections',
    'Woodoku::FlexiblePopupButtonTypes',
    'Woodoku::GameEndLoseJourneyFlows',
    'Woodoku::PowerUps::PlayOnAreasOfEffect',
    'Woodoku::PowerUps::Type',
    'Woodoku::PowerUps::PurchaseType',
    'Woodoku::PowerUps::PowerUpType',
    'Woodoku::JourneyNotifyTypes',
    'Woodoku::StartLobbyOrderOptions',
    'Woodoku::FirstBankValidModesOptions',
    'Woodoku::HomeScreenConfig::LobbyVariants',
    'Woodoku::HomeScreenConfig::SkinVariants',
    'Woodoku::DailyChallenges::ReplayTimeRanges',
    'Woodoku::DailyChallenges::ReplayPurchaseTypes',
    'Woodoku::CoinsRewardMoment',
  ]);

  const translationOptions = useCallback(
    () => (requestWithToSelectOptions(LocalizationsRoutes.Keys.indexRequest, applicationId)),
    [],
  );
  const assetKeyOptions = useCallback(
    () => AssetsRoutes.Keys.indexRequest({ applicationId, per: 200 }).then(({ data }) => data.items),
    [],
  );
  const figureSetOptions = selectOptionsWithQuickLink(FigureSetsRoutes.Sets, applicationId);
  const dynamicConfigOptions = selectOptionsWithQuickLink(WoodokuRoutes.DynamicConfigs, applicationId);
  const fileQuestOptions = selectOptionsWithQuickLink(
    FilesRoutes, applicationId, { filter: { fileTypeEq: fileTypesEnum.QUEST } },
  );
  const filePercentileOptions = selectOptionsWithQuickLink(
    FilesRoutes, applicationId, { filter: { fileTypeEq: fileTypesEnum.PERCENTILE_FOR_SCORES } },
  );
  const shapesBankSetOptions = selectOptionsWithQuickLink(WoodokuRoutes.ShapesBankSets, applicationId);
  const xpLevelConfigOptions = selectOptionsWithQuickLink(WoodokuRoutes.XpLevelConfigs, applicationId);

  return Fields({
    applicationId,
    unlockJourneyOptions,
    dcGemCountersCountdownDirections,
    flexiblePopupButtonTypes,
    gameEndLoseJourneyFlows,
    playOnAreasOfEffect,
    playOnTypes,
    powerUpPurchaseTypes,
    powerUpTypes,
    assetKeyOptions,
    translationOptions,
    figureSetOptions,
    dynamicConfigOptions,
    journeyNotifyTypes,
    startLobbyOrderOptions,
    firstBankValidModesOptions,
    fileQuestOptions,
    filePercentileOptions,
    lobbyVariantOptions,
    skinVariantOptions,
    replayTimeOptions,
    purchaseTypeOptions,
    coinRewardMomentOptions,
    shapesBankSetOptions,
    xpLevelConfigOptions,
  });
};

export default combineWithSharedFieldsFactory(
  [
    fieldsFactory,
    zendeskFields,
    offlineAdsFields,
    offlineGamesSettingsFields,
    adRequestDelayFields,
  ],
  {
    popupsOptions,
    popupsTriggersOptions,
  },
);
