import React, { useState } from 'react';
import {
  Modal, Button,
} from 'react-bootstrap';

import { Label } from '@controls/form';
import { ItemsSelect } from '@components/merge/shared/items/Select';
import { useFormContext } from '@hooks';

export default function CellModal({
  show, cell, stopEditing, items, index,
}) {
  const [data, setData] = useState(cell);
  const fieldName = `cellsAttributes[${index}]`;

  const { setFieldValue } = useFormContext();

  const onCancel = () => {
    setData(cell);
    stopEditing();
  };
  const onConfirm = () => {
    setFieldValue(fieldName, data);
    stopEditing();
  };
  const handleChange = (columnId, value) => {
    const changes = columnId === 'schemaItemId' && value === null
      ? { schemaItemId: value, itemLocked: false }
      : { [columnId]: value };
    setData((previousState) => ({ ...previousState, ...changes }));
  };

  return (
    <Modal
      show={show}
      onHide={onCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Grid Cell #
          {cell.position}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Label text="Cell is Locked">
          <input
            type="checkbox"
            checked={data.cellLocked}
            className="mt-2"
            onChange={({ target }) => handleChange('cellLocked', target.checked)}
          />
        </Label>
        <Label text="Item">
          <ItemsSelect
            includeEmpty
            value={data.schemaItemId}
            items={items}
            onChange={(e) => handleChange('schemaItemId', e.value)}
          />
        </Label>
        <Label text="Item is Locked">
          <input
            type="checkbox"
            checked={data.itemLocked}
            disabled={!data.schemaItemId}
            className="mt-2"
            onChange={({ target }) => handleChange('itemLocked', target.checked)}
          />
        </Label>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
        <Button variant="primary" onClick={onConfirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}
