import React from 'react';

import {
  Form, FormGroup, useCurrentApplication,
} from '@hooks';
import { PageHeader } from '@pages/common';
import {
  FormButtonsGroup, Label, Field, ImageFileField, StaticFieldsFormGroup,
} from '@controls/form';

export default function ProductImagesForm({
  data, onSubmit,
}) {
  const { currentApplication, routingScope } = useCurrentApplication();

  return (
    <Form initialValues={{ ...data, applicationId: currentApplication.id }} onSubmit={onSubmit}>
      {() => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Product Image`}>
            <FormButtonsGroup cancelButtonPath={`${routingScope}/payments/product_images`} />
          </PageHeader>

          <Field type="hidden" name="applicationId" />

          <FormGroup>
            <Label text="Name"><Field type="text" name="name" /></Label>

            <StaticFieldsFormGroup data={data} />
            <Label text="Image">
              <ImageFileField name="image" />
            </Label>
          </FormGroup>
        </>
      )}
    </Form>
  );
}
