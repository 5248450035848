import {
  fetchCollection, newResource, editResource, createResource, updateResource, deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/puzzle_time/escape_configs`;

export function getEscapeConfigs(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function newEscapeConfig(options) {
  return newResource(baseUrl(options), options);
}

export function editEscapeConfig(data, options) {
  return editResource(baseUrl(data), data, options);
}

export function createEscapeConfig(query, values) {
  return createResource(baseUrl(query), values);
}

export function updateEscapeConfig(query, values) {
  return updateResource(baseUrl(query), values);
}

export function deleteEscapeConfig(values) {
  return deleteResource(baseUrl(values), values);
}
