import React from 'react';

import { DownloadButton } from '@pages/common';
import { LocalizationsRoutes } from '@pages/routes';
import { useRouter } from '@tripledotstudios/react-core';

export default function DownloadCSV({ isTableChanged }) {
  const router = useRouter();

  return (
    <DownloadButton
      disabled={isTableChanged}
      className="ms-2"
      request={() => LocalizationsRoutes.Translations.downloadRequest(router.query)}
    />
  );
}
