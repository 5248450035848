import React from 'react';

import SelectField from '@controls/form/SelectField';
import { fromClassNameToOptions } from '@services/enums';

const optionsEnumName = 'Accounts::BuildVariants';

export default function BuildVariantRule({ disabled }) {
  const storeTypes = fromClassNameToOptions(optionsEnumName);

  return (
    <SelectField
      name="buildVariants"
      options={storeTypes}
      isMulti
      isDisabled={disabled}
    />
  );
}
