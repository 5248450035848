import {
  last,
  cloneDeep,
  isPlainObject,
  sortBy,
  get,
  set,
} from 'lodash';
import reorderMeta from '@services/reorderMeta';
import { addUuid } from '@services/recursivelyProcessObject';
import removeNestedIds from '@services/removeNestedIds';
import reorderEntities from '@services/reorderEntities';
import removeEntity from '@services/removeEntity';

/* eslint-disable no-param-reassign, array-callback-return */
// TODO [m.velimovich]: Think of how to make this more generic to use it in all the list wrappers
// or at least to share this reducer with Adventures
export default function reducer(state, action) {
  const { actionType, levelsPath = 'eventsAttributes' } = action;

  const entities = get(state, levelsPath);

  if (actionType === 'removeEntity') {
    const { _uuid: uuidToDelete } = action;

    return removeEntity(state, levelsPath, uuidToDelete, 'number');
  }

  if (actionType === 'bulkRemoveEntities') {
    const { uuids } = action;

    const clonedEntities = cloneDeep(entities);

    clonedEntities.forEach((entity) => {
      if (uuids.includes(entity._uuid)) entity._destroy = true;

      return entity;
    });

    set(state, levelsPath, reorderEntities(clonedEntities, 'number').result);

    return state;
  }

  if (actionType === 'restoreEntity') {
    const { _uuid: uuidToDelete } = action;

    const clonedEntities = cloneDeep(entities);

    delete clonedEntities.find(({ _uuid }) => _uuid === uuidToDelete)._destroy;

    set(state, levelsPath, reorderEntities(clonedEntities, 'number').result);

    return state;
  }

  if (actionType === 'moveEntity') {
    const {
      sourceIndex,
      destinationIndex,
      meta,
      metaErrorsPath,
    } = action;

    const clonedEntities = cloneDeep(entities);

    if (entities.length <= 1) return state;

    const { result, changeSet: [fromIndices, toIndices] } = reorderEntities(
      clonedEntities, 'number', sourceIndex, destinationIndex,
    );

    set(state, levelsPath, result);

    if (meta.errors && isPlainObject(get(meta, metaErrorsPath))) {
      meta.errors = { ...meta.errors, ...reorderMeta(meta, fromIndices, toIndices, metaErrorsPath).errors };
    }

    return state;
  }

  if (actionType === 'applyPositions') {
    const { uuidToPositionMapping } = action;

    entities.forEach((entity) => {
      entity.number = uuidToPositionMapping[entity._uuid];

      return entity;
    });

    set(state, levelsPath, sortBy(entities, 'number'));

    return state;
  }

  if (actionType === 'duplicateEntity') {
    const { _uuid: originalUuid } = action;

    const { id, _uuid, ...originalEntity } = entities.find((entity) => entity._uuid === originalUuid);
    const lastEntity = last(entities.filter(({ _destroy }) => !_destroy));

    const duplicatedEntity = removeNestedIds(cloneDeep(originalEntity));
    entities.push(addUuid({ ...duplicatedEntity, _destroy: false, number: lastEntity.number + 1 }));

    return state;
  }

  if (actionType === 'addEmptyEntity') {
    const { options = {} } = action;

    const lastEntity = last(entities.filter(({ _destroy }) => !_destroy));

    entities.push(addUuid({
      id: null,
      number: lastEntity ? lastEntity.number + 1 : 1,
      ...options,
      _destroy: false,
    }));

    return state;
  }

  return state;
}
