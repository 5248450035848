import React, { useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

import { useFormContext } from '@hooks/useFormContext';

import Field from './Field';

const EMPTY = '';

export default function ArrayField({
  name, disabled, onChange, tooltipText, ...rest
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = useRef(null);

  const { setFieldValue } = useFormContext();

  const onInputChange = ({ target }) => {
    const userInput = target.value;

    if (userInput === EMPTY) {
      setFieldValue(target.name, []);
      if (onChange) { onChange([]); }
    } else {
      const value = userInput.split(',').map((el) => el.trim());
      setFieldValue(target.name, value);
      if (onChange) { onChange(value); }
    }

    setShowTooltip(false);
  };

  const onFocus = ({ target }) => {
    if (!tooltipText) return;
    if (target.value) return;

    setShowTooltip(true);
  };

  const onBlur = () => {
    if (!tooltipText) return;

    setShowTooltip(false);
  };

  return (
    <>
      <Field
        type="text"
        name={name}
        onChange={onInputChange}
        disabled={disabled}
        innerRef={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        {...rest}
      />
      <Overlay
        target={ref.current}
        show={tooltipText && showTooltip}
        placement="bottom"
      >
        {(props) => <Tooltip {...props}>{tooltipText}</Tooltip>}
      </Overlay>
    </>
  );
}
