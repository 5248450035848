import React from 'react';
import { Col } from 'react-bootstrap';

import { Filter, TextFilter, SelectFilter } from '@pages/common';

export default function FilesFilter({ typeOptions }) {
  return (
    <Filter defaultComponents={['availabilityStateIn', 'createdByMe']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="fileTypeIn"
              label="Type"
              updateFilterField={updateFilterField}
              options={typeOptions}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
