import React, { useMemo, useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes as Routes } from '@pages/routes';
import DuplicationModal from '@components/merge/shared/DuplicationModalWithWorldSchemaId';
import IconButton from '@controls/buttons';
import ColumnsFactory from './ColumnsFactory';
import Filter from './Filter';

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(Routes.ExpertConfigurations.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Expert Configuration',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Expert Configuration '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => Routes.ExpertConfigurations.deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({
    applicationId, setDuplicatedRecord, onDelete,
  }), []);

  return response && (
    <div>
      <PageHeader title="Expert Configurations" filter={<Filter />}>
        <IconButton.New to={Routes.ExpertConfigurations.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Expert Configuration"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => (
          Routes.ExpertConfigurations.duplicateRequest({ ...values, applicationId })
        )}
        title="Duplicate Expert Configuration"
        applicationId={applicationId}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
