import React from 'react';
import { Col } from 'react-bootstrap';

import { Filter, SelectFilter, TextFilter } from '@pages/common';

export default function TesterDevicesFilter() {
  return (
    <Filter>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <SelectFilter
              name="enabledIn"
              label="Enabled as test device"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="idfvCont"
              label="IDFV = "
              isMulti={false}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="nameCont"
              label="Name = "
              isMulti={false}
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
