import React from 'react';

import { useCurrentApplication, useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { TesterDeviceRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { response, setResponse } = useQuery(TesterDeviceRoutes.newRequest);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Tester Device',
    actionName: 'create',
    redirectPath: '/admin/tester_devices',
    setData: setResponse,
  });

  const onSubmit = (values) => TesterDeviceRoutes.createRequest({ ...values, applicationId }).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
