import React, { Fragment } from 'react';
import styled from 'styled-components';

import APP_DATA from '@services/appData';

import OperationBadge from './shared/OperationBadge';
import presenter from './rulesPresenter';

const Rule = styled.span`
  padding: 0.25rem;
  line-height: 1;
  border-radius: 0.25rem;
  display: inline-flex;
`;

const { rawEnums: enums } = APP_DATA;

export default function SingleLevelRules({
  sectionOperation,
  rules,
  emptyRulesMark = '',
}) {
  const filteredRules = rules.filter((rule) => !rule._destroy);
  const rulesLength = filteredRules.length;

  const isLastRule = (index) => rulesLength - 1 === index;

  return rulesLength > 0 ? (
    <>
      {filteredRules.map(({
        id,
        type,
        operation,
        ...props
      }, index) => (
        <Fragment key={id}>
          <Rule className="me-1">
            {presenter({
              type,
              operation,
              enums,
              props,
            })}
          </Rule>
          {!isLastRule(index) && <OperationBadge operation={sectionOperation} className="me-1" />}
        </Fragment>
      ))}
    </>
  ) : emptyRulesMark;
}
