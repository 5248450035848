import React from 'react';
import { UiAssetsIndex } from '@pages/metagame/chapter-based';

export default function Index(props) {
  return (
    <UiAssetsIndex
      entityNameOverride="UI themes"
      recordType="ui_themes"
      {...props}
    />
  );
}
