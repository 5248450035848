import React from 'react';
import { useTheme } from 'styled-components';

import {
  faListOl, faLayerGroup, faMedal,
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { TileRoutes } from '@pages/routes';

import { matchPath } from 'react-router-dom';
import {
  ApplicationItemSet,
  AssetsNavigationItem,
  MissionsNavigationSection,
  AdsNavigationSection,
  JourneysNavigationSection,
  RewardsNavigationItem,
  FilesNavigationItem,
} from './common';

export default function Tile({ routingScope, applicationId, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="tile"
      routingScope={routingScope}
      minimized={minimized}
    >
      <FilesNavigationItem minimized={minimized} applicationId={applicationId} />
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />
      <Sidebar.SectionItem
        icon={faListOl}
        title="Levels & Chapters"
        color={theme.yellow}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={TileRoutes.Banks.indexPath({ applicationId })}
          title="Layout Banks"
          isActive={(_, location) => (
            [
              TileRoutes.Banks.indexRawPath,
              TileRoutes.LayoutReplacements.newRawPath,
              TileRoutes.BankBulkImports.editRawPath,
            ].some((path) => matchPath({ path }, location.pathname))
          )}
        />
        <Sidebar.LinkItem
          to={TileRoutes.ChapterSets.indexPath({ applicationId })}
          title="Chapter Sets"
          isActive={(_, location) => (
            [
              TileRoutes.ChapterSets.indexRawPath,
              TileRoutes.ChapterSetImports.newRawPath,
              TileRoutes.ChapterSetImports.editRawPath,
            ].some((path) => matchPath({ path }, location.pathname))
          )}
        />
        <Sidebar.LinkItem
          to={TileRoutes.DifficultySchemas.indexPath({ applicationId })}
          title="DDA Schemas"
        />
        <Sidebar.LinkItem
          to={TileRoutes.ModesConfigs.indexPath({ applicationId })}
          title="Modes Configs"
        />
        <Sidebar.LinkItem
          to={TileRoutes.DifficultySchemaSets.indexPath({ applicationId })}
          title="DDA Schema Sets"
        />
        <Sidebar.LinkItem
          to={TileRoutes.RewardSets.indexPath({ applicationId })}
          title="Reward Schema Sets"
        />
        <Sidebar.LinkItem
          to={TileRoutes.RewardsForWinsSchemas.indexPath({ applicationId })}
          title="Coins for wins schemas"
        />
        <Sidebar.LinkItem
          to={TileRoutes.AssetBundleSchemaSets.indexPath({ applicationId })}
          title="Asset Bundle Schema Sets"
        />
      </Sidebar.SectionItem>

      <JourneysNavigationSection
        routingScope={routingScope}
        minimized={minimized}
        sectionName="Scrapbooks"
        eventsName="Scrapbooks"
        levelBanksName="Picture Banks"
      />

      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />

      <Sidebar.SectionItem
        icon={faMedal}
        title="Daily Reward Configs"
        color={theme.orange}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/daily_rewards/banks`}
          title="Daily Reward Banks"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/daily_rewards/configs`}
          title="Daily Reward Configs"
        />
      </Sidebar.SectionItem>

      <Sidebar.LinkItem
        to={TileRoutes.CategorySets.indexPath({ applicationId })}
        icon={faLayerGroup}
        title="Category Sets"
        color={theme.conifer}
        minimized={minimized}
      />

      <MissionsNavigationSection applicationId={applicationId} minimized={minimized} />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
