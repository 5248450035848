import React from 'react';
import { Spinner } from '@tripledotstudios/react-core';
import {
  FormGroup, useFormContext, useFormGroup, useQuery, useConfirm,
  useI18n,
} from '@hooks';
import { SelectWithAction, ServerError } from '@controls/form';
import { PageSection } from '@pages/common';
import { AdsRoutes } from '@pages/routes';

import IconButton from '@controls/buttons';
import ConfigurationFields from './ConfigurationFields';
import { indexedPlacements, placementsToAddOptions } from '../utils';

export default function Fields({ values, disabled }) {
  const { translate } = useI18n();
  const { dispatch } = useFormContext();
  const { generateName } = useFormGroup();
  const confirm = useConfirm();
  const overridesPath = generateName('overridesAttributes');

  const { response: placementsResponse, isLoading: placementsAreLoading } = useQuery(
    AdsRoutes.Interstitials.Placements.indexRequest, { withoutPagination: true },
  );

  const addOverride = (selectedPlacementId) => {
    dispatch({ type: 'addOverride', overridesPath, placementId: selectedPlacementId });
  };

  const removeOverride = (placementIndex) => {
    confirm.showConfirmation({
      title: 'Back-to-back frequency restriction Override will be removed. Continue?',
    }).then(() => dispatch({ type: 'removeOverride', overridesPath, placementIndex }));
  };

  const placements = indexedPlacements(placementsResponse);
  const locale = translate.fallback(
    'ads.configuration.backToBackFrequencyRestrictionsConfigurationAttributes.overridesAttributes',
  );

  return placementsAreLoading ? <Spinner /> : (
    <>
      <ConfigurationFields
        values={values}
        disabled={disabled}
        dispatch={dispatch}
        attributesPath={generateName()}
      />

      <PageSection title={locale.label} tooltip={locale.tooltip} />
      <ServerError name="overridesAttributes.list" />
      {values.overridesAttributes.map((overrideAttributes, index) => (
        !overrideAttributes._destroy && (
          <PageSection
            title={placements[overrideAttributes.placementId].name}
            actions={<IconButton.Remove size="sm" onClick={() => removeOverride(index)} disabled={disabled} />}
            key={overrideAttributes._uuid}
          >
            <FormGroup name={`overridesAttributes[${index}]`}>
              <ConfigurationFields
                values={values}
                disabled={disabled}
                dispatch={dispatch}
                attributesPath={generateName()}
              />
            </FormGroup>
          </PageSection>
        )
      ))}

      <SelectWithAction
        selectOptions={placementsToAddOptions(values, placementsResponse)}
        buttonOnClick={addOverride}
        buttonTitle="Add Override"
        cassName="mb-3"
        disabled={disabled}
      />
    </>
  );
}
