import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { FormGroup, useQuery } from '@hooks';
import { Label, SelectField, AssetsKeySelectField } from '@controls/form';

import { LocalizationsRoutes, FilesRoutes } from '@pages/routes';

const { enums } = APP_DATA;

const assetTypesEnum = enums['Sortaga::AssetTypes'];
const fileTypesEnum = enums['Sortaga::FileTypesEnum'];

export default function SortagaConfigurationForm({
  defaultVariant = false, isArchived,
}) {
  const { query: { applicationId } } = useRouter();

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  const { response: localizationKeys } = useQuery({
    request: LocalizationsRoutes.Keys.indexRequest,
    queryKey: ['localizationKeys', applicationId],
    toSelectOptions: true,
  });
  const { response: levelFileOptions } = useQuery({
    request: FilesRoutes.indexRequest,
    additionalRequestParams: { withoutPagination: true, filter: { fileTypeEq: fileTypesEnum.JOURNEY_LEVELS } },
    queryKey: ['journeyLevelFiles', applicationId],
    toSelectOptions: true,
  });

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Title Translation Key">
        <SelectField name="titleTranslationKeyId" options={localizationKeys} isDisabled={isArchived} />
      </Label>
      <Label text="Subtitle Translation Key">
        <SelectField name="subtitleTranslationKeyId" options={localizationKeys} isDisabled={isArchived} />
      </Label>

      <Label text="Asset: Main Bundle">
        <AssetsKeySelectField
          name="assetKeyId"
          types={assetTypesEnum.JOURNEY_MAIN}
        />
      </Label>
      <Label text="Asset: Rewards Bundle">
        <AssetsKeySelectField
          name="assetRewardsKeyId"
          types={assetTypesEnum.JOURNEY_REWARDS}
        />
      </Label>
      <Label
        text="Journey levels"
        tooltipText="Select file with levels configuration for this Event (config.journey_levels_url)"
        copyableText="config.journey_levels_url"
      >
        <SelectField
          name="levelsFileId"
          options={levelFileOptions}
          quickLinkFn={(id) => FilesRoutes.editPath({ applicationId, id })}
        />
      </Label>
    </FormGroup>
  );
}
