import React from 'react';

import { useTheme } from 'styled-components';
import { faListOl } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { LorayRoutes as Routes } from '@pages/routes';

import {
  ApplicationItemSet,
  AssetsNavigationItem,
  AdsNavigationSection,
  RewardsNavigationItem,
  FilesNavigationItem,
} from './common';

export default function Loray({ applicationId, routingScope, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="loray"
      routingScope={routingScope}
      minimized={minimized}
    >
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />

      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />

      <Sidebar.SectionItem
        icon={faListOl}
        title="Levels & Chapters"
        color={theme.yellow}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={Routes.Banks.indexPath({ applicationId })}
          title="Layout Banks"
        />
        <Sidebar.LinkItem
          to={Routes.ChapterSets.indexPath({ applicationId })}
          title="Chapter Sets"
        />
      </Sidebar.SectionItem>

      <FilesNavigationItem minimized={minimized} applicationId={applicationId} />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
