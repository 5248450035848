import React, { useMemo } from 'react';
import {
  difference, isNil, map, camelCase,
} from 'lodash';
import { v4 } from 'uuid';

import { ReactTable } from '@pages/common';
import { DIRECTIONS } from '../constants';
import { updateRows } from '../diffRows';
import DiffValue from '../DiffValue';

const ValueCell = ({ value, enabled, onClick }) => (
  isNil(enabled)
    ? <DiffValue value={value} onClick={onClick} centered />
    : (
      <div>
        <div>
          <span className="me-2">state:</span>
          {enabled ? 'enabled' : 'disabled'}
        </div>
        {!isNil(value) && value !== 'null' && (
          <div>
            {'value: '}
            <DiffValue value={value} onClick={onClick} />
          </div>
        )}
      </div>
    )
);

const columnBuilder = [
  { Header: 'Attribute/Property', accessor: 'attribute' },
  { Header: 'Action', id: 'action', Cell: 'edited' },
  {
    Header: 'Context',
    id: 'context',
    Cell: '-',
  },
  {
    Header: 'State Before',
    accessor: 'before',
    disableSortBy: true,
    colSpan: ({ row }) => (row.original.mergeDiff ? 2 : null),
    Cell: ({ row }) => (
      <ValueCell
        {...row.original.before}
        attribute={row.original.attribute}
        onClick={row.original.onClick}
      />
    ),
  },
  {
    Header: 'State After',
    accessor: 'after',
    disableSortBy: true,
    skip: ({ row }) => row.original.mergeDiff,
    Cell: ({ row }) => (
      <ValueCell
        {...row.original.after}
        attribute={row.original.attribute}
        onClick={row.original.onClick}
      />
    ),
  },
];

export default function Update({ showAssociation, versionTree }) {
  const { version, trackedAssociations } = versionTree;
  const { enabledFieldsNames = [] } = version.objectChanges;
  const enabledFields = map(difference(enabledFieldsNames[1], enabledFieldsNames[0]), camelCase);
  let changesRows = updateRows({
    versionTree, showAssociation, omitAttributes: ['enabledFieldsNames', '_uuid'],
  });
  changesRows = map(changesRows, (row) => {
    const enabled = enabledFields.indexOf(row.attribute) > -1;
    return ({
      ...row,
      after: { value: row.after, enabled: enabled ? true : null },
      before: { value: enabled ? null : row.before, enabled: enabled ? false : null },
    });
  });
  difference(enabledFields, map(changesRows, 'attribute')).forEach((attribute) => {
    const association = trackedAssociations[attribute];
    if (association) {
      const rowIndex = changesRows.findIndex((r) => r.attribute === association.name);
      if (rowIndex > -1) { changesRows.splice(rowIndex, 1); }
      changesRows.push({
        _uuid: v4(),
        attribute: association.name,
        mergeDiff: false,
        before: { enabled: false },
        after: { enabled: true, value: true },
        onClick: () => showAssociation({
          associationName: association.name,
          direction: DIRECTIONS.AFTER,
          versionTree,
        }),
      });
    } else {
      changesRows.push({
        _uuid: v4(),
        attribute,
        after: { enabled: true, value: version.object.data[attribute] },
        before: { enabled: false },
      });
    }
  });
  map(difference(enabledFieldsNames[0], enabledFieldsNames[1]), camelCase).forEach((attribute) => {
    const association = trackedAssociations[attribute];
    changesRows.push({
      _uuid: v4(),
      attribute: association ? association.name : attribute,
      after: { enabled: false },
      before: { enabled: true },
    });
  });

  const columns = useMemo(() => columnBuilder, []);

  return (
    <ReactTable
      columns={columns}
      data={changesRows}
      disableSortBy
    />
  );
}
