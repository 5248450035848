import React from 'react';
import { Card } from 'react-bootstrap';

import { ColoredCard } from '@components/shared';

export default function NestedCondition({ variant, children }) {
  return (
    <ColoredCard variant={variant} className="mb-3">
      <Card.Body>
        {children}
      </Card.Body>
    </ColoredCard>
  );
}
