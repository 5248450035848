import {
  get, pick, isNil, reduce,
} from 'lodash';

import APP_DATA from '@services/appData';

const levelModes = APP_DATA.enums['Solitaire::Journeys::GameModes'];
const valuableModeFields = {
  [levelModes.CLASSIC]: [],
  [levelModes.EXPRESS]: ['limitType', 'maxCard', 'reduceStacksBy'],
  [levelModes.TRIAL]: ['limitType'],
  [levelModes.CHALLENGE]: [
    'limitType',
    'targetAction',
    'targetDenomination',
    'actionStackCount',
    'anySuitSelected',
    'actionStackCount',
    'spadesSelected',
    'heartsSelected',
    'diamondsSelected',
    'clubsSelected',
  ],
  [levelModes.RIDDLE]: ['limitType', 'levelLayoutId'],
  [levelModes.STOCKPILE_CYCLE]: ['cycleLimit'],
};
const valuableFields = ['name', 'seed', 'mode', 'dealThreeCards'];

function findDuplicatesByAttribute(levels, byAttribute, attributeHandler = (value) => value) {
  const nonUniqValues = new Set();
  const nonUniqValueLevels = {};
  levels.forEach((level) => {
    if (level._destroy) return;

    const value = attributeHandler(get(level, byAttribute));
    if (isNil(value) || value === '') return;

    if (nonUniqValueLevels[value]) {
      if (!nonUniqValues.has(value)) nonUniqValues.add(value);
      nonUniqValueLevels[value].push(level);
    } else {
      nonUniqValueLevels[value] = [level];
    }
  });

  return pick(nonUniqValueLevels, ...nonUniqValues);
}

function findDuplicatesByAllAttributes(levels) {
  const nonUniqValues = new Set();
  const nonUniqValueLevels = {};

  levels.forEach((level) => {
    if (level._destroy) return;

    const valueObj = pick(
      level,
      [...valuableModeFields[level.mode], ...valuableFields],
    );
    const value = reduce(valueObj, (acc, v, k) => `${acc}${k}:${v}`, '');

    if (nonUniqValueLevels[value]) {
      if (!nonUniqValues.has(value)) nonUniqValues.add(value);
      nonUniqValueLevels[value].push(level);
    } else {
      nonUniqValueLevels[value] = [level];
    }
  });

  return pick(nonUniqValueLevels, ...nonUniqValues);
}

export default function findDuplicates(levels) {
  const rewardKeys = findDuplicatesByAttribute(levels, 'rewardsAttributes', (reward) => (
    reward.map(({ value }) => value).join(',')),
  );
  const attributesDuplications = {};
  const nonUniqEntityNames = [];

  let i = 0;
  const seeds = findDuplicatesByAttribute(levels, 'seed');
  Object.keys(seeds).forEach((seed) => {
    const modes = findDuplicatesByAttribute(seeds[seed], 'mode');
    Object.keys(modes).forEach((mode) => {
      const others = findDuplicatesByAllAttributes(modes[mode]);
      Object.keys(others).forEach((other) => {
        if (others[other].length === 0) return;
        i += 1;
        attributesDuplications[`#${i}`] = others[other];
      });
    });
  });

  if (Object.keys(attributesDuplications).length <= 0 && (Object.keys(rewardKeys).length <= 0)) {
    return {
      nonUniqEntityNames,
      nonUniqValueLevels: {},
    };
  }

  if (Object.keys(rewardKeys).length) nonUniqEntityNames.push('reward key(s)');
  if (Object.keys(attributesDuplications).length) nonUniqEntityNames.push('attribute(s)');

  return {
    nonUniqEntityNames,
    nonUniqValueLevels: { attributesDuplications, rewardKeys },
  };
}
