const ItemsFilter = (values, filters) => {
  let filteredValues = values.map((value, index) => ({ ...value, index }));

  Object.entries(filters).forEach(([filterType, filterOptions]) => {
    switch (filterOptions.type) {
      case 'eq':
        filteredValues = filteredValues.filter((row) => row[filterType] === filterOptions.value);
        break;
      case 'in':
        filteredValues = filteredValues.filter((row) => filterOptions.values.includes(row[filterType]));
        break;
      case 'item_in_array':
        filteredValues = filteredValues.filter((row) => (
          row[filterType].map(({ schemaItemId }) => schemaItemId).includes(filterOptions.value)
        ));
        break;
      default:
    }
  });

  return filteredValues;
};

export default ItemsFilter;
