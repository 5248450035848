import { memoize, omit } from 'lodash';

import {
  fieldBuilder,
  fieldGroupBuilder,
  selectBuilder,
} from '@services/fields-factory';
import { RewardsRoutes, SpiderSolitaireRoutes, LocalizationsRoutes } from '@pages/routes';
import APP_DATA from '@services/appData';
import { fromClassNamesToOptions } from '@services/enums';
import selectOptionsWithQuickLink from '@services/selectOptionsWithQuickLink';
import combineWithSharedFieldsFactory, { defaultPopupsOptions } from '@components/game-settings/combineWithSharedFieldsFactory';
import hintFieldBuilder from './HintFieldBuilder';

const popupsOptions = [
  ...defaultPopupsOptions,
  { label: 'Cross Promo', value: 'Cross_promo' },
];

const numberOfSuitesOptions = [1, 2, 4].map((o) => ({ label: o, value: o }));

const Fields = memoize(({
  levelSetOptions,
  localizationConfigOptions,
  streakTogglesOptions,
  suitsOptions,
  oneSuitWorldStatOptions,
  twoSuitsWorldStatOptions,
  fourSuitsWorldStatOptions,
  cardFrontOptions,
  cardBackOptions,
  tableDesignOptions,
  avatarOptions,
}) => ({
  ...fieldBuilder({ name: 'undo', type: 'checkbox' }),
  ...fieldBuilder({ name: 'unrestrictedUndo', type: 'checkbox' }),
  ...fieldBuilder({ name: 'unrestrictedDeal', type: 'checkbox' }),
  ...fieldBuilder({ name: 'cardsHighlighting', type: 'checkbox' }),
  ...fieldBuilder({ name: 'tapToMove', type: 'checkbox' }),
  ...fieldBuilder({ name: 'leftHanded', type: 'checkbox' }),
  ...fieldBuilder({ name: 'lossTimeout', type: 'number' }),
  ...fieldBuilder({ name: 'sounds', type: 'checkbox' }),
  ...fieldBuilder({ name: 'everythingJson', type: 'text', fieldOptions: { component: 'textarea' } }),
  ...fieldBuilder({ name: 'xpGameWinSuit1', type: 'number' }),
  ...fieldBuilder({ name: 'xpGameWinSuit2', type: 'number' }),
  ...fieldBuilder({ name: 'xpGameWinSuit4', type: 'number' }),
  ...fieldBuilder({ name: 'xpDailyChallengeWin', type: 'number' }),
  ...fieldBuilder({ name: 'showHintCounter', type: 'checkbox' }),
  ...fieldBuilder({ name: 'oneSuit', type: 'text' }),
  ...fieldBuilder({ name: 'twoSuits', type: 'text' }),
  ...fieldBuilder({ name: 'fourSuits', type: 'text' }),
  ...fieldBuilder({ name: 'worldAveragesShown', type: 'checkbox' }),
  ...fieldBuilder({ name: 'spideretteMode', type: 'checkbox' }),
  ...fieldBuilder({ name: 'spideretteSeedsOneSuit', type: 'text' }),
  ...fieldBuilder({ name: 'spideretteSeedsTwoSuits', type: 'text' }),
  ...fieldBuilder({ name: 'spideretteSeedsFourSuits', type: 'text' }),
  ...fieldBuilder({ name: 'tutorials', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dcBubble', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dcBubbleStartDay', type: 'number' }),
  ...fieldBuilder({ name: 'spideretteSettings', type: 'checkbox' }),
  ...fieldBuilder({ name: 'mercyMechanicToggle', type: 'checkbox' }),
  ...fieldBuilder({ name: 'mercyGamesTrigger', type: 'number' }),
  ...fieldBuilder({ name: 'mercyLossPercentage', type: 'number' }),
  ...fieldBuilder({ name: 'mercyBankOneSuit', type: 'text' }),
  ...fieldBuilder({ name: 'mercyBankTwoSuit', type: 'text' }),
  ...fieldBuilder({ name: 'mercyBankFourSuit', type: 'text' }),
  ...fieldBuilder({ name: 'mercyBankOneSuitSpiderette', type: 'text' }),
  ...fieldBuilder({ name: 'mercyBankTwoSuitSpiderette', type: 'text' }),
  ...fieldBuilder({ name: 'mercyBankFourSuitSpiderette', type: 'text' }),
  ...hintFieldBuilder({ name: 'autoHints', fieldOptions: { trigger: true } }),
  ...hintFieldBuilder({ name: 'hintButtonPulse' }),
  ...selectBuilder({ name: 'numberOfSuits', options: numberOfSuitesOptions }),
  ...selectBuilder({ name: 'defaultSuitOneSuitGame', options: suitsOptions }),
  ...selectBuilder({ name: 'localizationsConfigId', ...localizationConfigOptions }),
  ...selectBuilder({ name: 'playerLevelsSetId', ...levelSetOptions }),
  ...selectBuilder({ name: 'dcWorldStatsConfigOneSuitId', ...oneSuitWorldStatOptions }),
  ...selectBuilder({ name: 'dcWorldStatsConfigTwoSuitsId', ...twoSuitsWorldStatOptions }),
  ...selectBuilder({ name: 'dcWorldStatsConfigFourSuitsId', ...fourSuitsWorldStatOptions }),
  ...selectBuilder({ name: 'defaultCardFrontId', includeEmpty: true, ...cardFrontOptions }),
  ...selectBuilder({ name: 'defaultCardBackId', includeEmpty: true, ...cardBackOptions }),
  ...selectBuilder({ name: 'defaultTableId', includeEmpty: true, ...tableDesignOptions }),
  ...selectBuilder({ name: 'defaultAvatarId', includeEmpty: true, ...avatarOptions }),
  ...fieldGroupBuilder(
    {
      name: 'streak',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'enabled', type: 'checkbox' }),
        ...selectBuilder({ name: 'playerSummary', options: streakTogglesOptions }),
        ...selectBuilder({ name: 'newGameMenu', options: streakTogglesOptions }),
      },
    },
  ),
}), (args) => JSON.stringify(omit(args, ['suitsOptions', 'streakTogglesOptions'])));

const fieldsFactory = ({ applicationId }) => {
  const suitsTypes = APP_DATA.enums['SpiderSolitaire::DailyChallenges::WorldStatsConfigs::SuitsTypes'];
  const [streakTogglesOptions, suitsOptions] = fromClassNamesToOptions([
    'SpiderSolitaire::StreakToggles',
    'SpiderSolitaire::Suits',
  ]);
  const localizationConfigOptions = selectOptionsWithQuickLink(LocalizationsRoutes.Configs, applicationId);
  const levelSetOptions = selectOptionsWithQuickLink(SpiderSolitaireRoutes.PlayerLevelSets, applicationId, { per: 50 });
  const oneSuitWorldStatOptions = selectOptionsWithQuickLink(
    SpiderSolitaireRoutes.WorldStatsConfig, applicationId, { suitsType: suitsTypes.ONE_SUIT },
  );
  const twoSuitsWorldStatOptions = selectOptionsWithQuickLink(
    SpiderSolitaireRoutes.WorldStatsConfig, applicationId, { suitsType: suitsTypes.TWO_SUITS },
  );
  const fourSuitsWorldStatOptions = selectOptionsWithQuickLink(
    SpiderSolitaireRoutes.WorldStatsConfig, applicationId, { suitsType: suitsTypes.FOUR_SUITS },
  );
  const cardFrontOptions = selectOptionsWithQuickLink(
    RewardsRoutes.Items,
    applicationId,
    { per: 50, filter: { rewardTypeEq: 'Rewards::Types::SpiderSolitaire::CardFront' } },
  );
  const cardBackOptions = selectOptionsWithQuickLink(
    RewardsRoutes.Items,
    applicationId,
    { per: 50, filter: { rewardTypeEq: 'Rewards::Types::SpiderSolitaire::CardBack' } },
  );
  const tableDesignOptions = selectOptionsWithQuickLink(
    RewardsRoutes.Items,
    applicationId,
    { per: 50, filter: { rewardTypeEq: 'Rewards::Types::SpiderSolitaire::TableDesign' } },
  );
  const avatarOptions = selectOptionsWithQuickLink(
    RewardsRoutes.Items,
    applicationId,
    { per: 50, filter: { rewardTypeEq: 'Rewards::Types::SpiderSolitaire::Avatar' } },
  );
  return Fields({
    applicationId,
    levelSetOptions,
    localizationConfigOptions,
    streakTogglesOptions,
    suitsOptions,
    suitsTypes,
    oneSuitWorldStatOptions,
    twoSuitsWorldStatOptions,
    fourSuitsWorldStatOptions,
    cardFrontOptions,
    cardBackOptions,
    tableDesignOptions,
    avatarOptions,
  });
};

export default combineWithSharedFieldsFactory(fieldsFactory, { popupsOptions });
