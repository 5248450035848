import React from 'react';
import { has, get } from 'lodash';

import APP_DATA from '@services/appData';

import Index from '@components/journeys/banks/Index';
import { useCurrentApplication } from '@hooks';

import { JourneyRoutes as Routes } from '@pages/routes';

import appFilters from './app-filters';
import appActions from './app-actions';

const statuses = APP_DATA.enums['Journeys::LevelBanks::BankStatuses'];

export default function LevelBanksIndex() {
  const { applicationKey } = useCurrentApplication();
  const massOperationsEnabled = has(appActions.massOperations, applicationKey);
  const massOperationsProps = massOperationsEnabled ? get(appActions.massOperations, applicationKey) : {};
  const AdditionalFilters = appFilters[applicationKey];

  return (
    <Index
      AdditionalFilters={AdditionalFilters}
      statuses={statuses}
      routes={Routes.LevelBanks}
      translationsPath="journeys.levelBanks"
      modelName="Journeys::LevelBanks::Bank"
      requestParams={{ withoutLevels: true }}
      massOperationsEnabled={massOperationsEnabled}
      massOperationsProps={{ ...massOperationsProps }}
    />
  );
}
