import React, { useState, useMemo, useEffect } from 'react';
import ReactSelect from 'react-select';
import { Button, Table } from 'react-bootstrap';
import { Field, ServerError } from '@controls/form';

export default function RewardItem({
  initialData, onChange, rewardItemsConfiguration, readOnly = false,
}) {
  const [data, setData] = useState(initialData);
  const addData = (dataToAdd) => setData((prevData) => ({ ...prevData, ...dataToAdd }));

  useEffect(() => onChange(data), [data]);

  const findConfiguration = (targetValue) => (
    rewardItemsConfiguration.find(({ value: configValue }) => configValue === targetValue)
  );

  const currentConfiguration = findConfiguration(data.type);

  const handleSelectType = ({ value: selectedValue }) => {
    const newConfiguration = findConfiguration(selectedValue);

    setData(({ id }) => ({ id, type: selectedValue, ...newConfiguration.buildDefaults() }));
  };

  const handleDelete = () => {
    addData({ _destroy: true });
  };

  const InputComponent = useMemo(() => {
    if (currentConfiguration) {
      return currentConfiguration.component;
    }

    return () => null;
  }, [currentConfiguration]);

  return (
    data._destroy !== true ? (
      <>
        <Table bordered>
          <tbody>
            <tr>
              <td><b>Type</b></td>
              <td className="w-75">
                <ReactSelect
                  value={currentConfiguration}
                  onChange={handleSelectType}
                  options={rewardItemsConfiguration}
                  isDisabled={readOnly}
                  className="react-select"
                  classNamePrefix="react-select"
                />
                <ServerError name="type" />
              </td>
            </tr>
            <InputComponent data={data} addData={addData} readOnly={readOnly} />
            <tr>
              <td colSpan="2" className="text-end">
                <Button onClick={handleDelete} variant="danger" size="sm" disabled={readOnly}>Remove</Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <Field type="hidden" name="id" />
      </>
    ) : ''
  );
}
