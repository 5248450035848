import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { reorderList } from '@services/reorder';

export default function ReorderableList({
  options, onChange, variant, droppableId,
}) {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const values = options.map((option) => option.value);
    onChange(reorderList(
      values,
      result.source.index,
      result.destination.index,
    ));
  };

  return (
    <ListGroup>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {options.map(({ label, value }, index) => (
                <Draggable
                  key={value}
                  draggableId={`${droppableId}-${value}`}
                  index={index}
                >
                  {(draggableProvided) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <ListGroup.Item
                        key={value}
                        variant={variant}
                      >
                        {label}
                      </ListGroup.Item>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </ListGroup>
  );
}
