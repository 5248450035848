import React from 'react';
import styled from 'styled-components';
import { Field } from '@controls/form';
import { Rewards } from '@pages/common';

import { ChapterRow, ColumnTitle, LevelsConfigFields } from '../../metagame/chapter-based/chapter-sets/shared';

const COLUMN_PADDING_SIZE = 10;

const StaticValue = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
`;

const IdColumn = styled.div`
  padding-right: ${COLUMN_PADDING_SIZE}px;
  width: 5%;
`;

const NameColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 40%;
`;

const RewardColumn = styled.div`
  padding-left: ${COLUMN_PADDING_SIZE}px;
  width: 55%;
`;

export default function ChapterFields({
  banks, chapter, dispatch, isDirty, levelsTotal, populateChapter, rewardItems,
}) {
  return (
    <>
      <ChapterRow>
        <IdColumn>
          <ColumnTitle>Id</ColumnTitle>
          <StaticValue>{chapter.id}</StaticValue>
        </IdColumn>
        <NameColumn>
          <ColumnTitle>Chapter name</ColumnTitle>
          <Field name="name" fast />
        </NameColumn>
        <RewardColumn>
          <ColumnTitle>Reward for the chapter</ColumnTitle>
          <Rewards
            dispatch={dispatch}
            rewardable={chapter}
            rewardItems={rewardItems}
          />
        </RewardColumn>
      </ChapterRow>
      <LevelsConfigFields
        banks={banks}
        chapter={chapter}
        isDirty={isDirty}
        levelsTotal={levelsTotal}
        populateChapter={populateChapter}
        dispatch={dispatch}
      />
    </>
  );
}
