import React, { useMemo } from 'react';

import { Th } from './styled-components';

export default function LanguageHeaders({ languages }) {
  return (
    useMemo(() => (
      languages.map(({ name, code, _uuid }) => (
        <Th key={_uuid} width="250px">{`${name} ${code}`}</Th>
      ))
    ),
    [languages])
  );
}
