import React from 'react';
import styled from 'styled-components';

import ListWrapper from '@components/shared/ListWrapper';
import { localizedEnumValueString } from '@services/enums';
import entityIdentity from '@components/shared/entityIdentity';
import BulkCopyModal from './BulkCopyModal';

const LevelIndicatorLabel = styled.div`
  margin-left: 1rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.red};
`;

const EntityHeader = ({ entity, entityName }) => {
  const { difficultyIndicator, specialLevelIndicator } = entity;
  const difficulty = localizedEnumValueString('Solitaire::Journeys::LevelDifficulties', difficultyIndicator);
  const specialLevel = localizedEnumValueString('Woodoku::Journeys::SpecialLevelIndicators', specialLevelIndicator);

  return (
    <>
      {entityIdentity({ entityName, entity })}
      {[specialLevel, difficulty].map((labelText) => labelText && (
        <LevelIndicatorLabel>
          {labelText}
        </LevelIndicatorLabel>
      ))}
    </>
  );
};

export default function LevelsListWrapper({
  entityAttributes,
  levelsPath,
  readOnly,
  children,
  onEntityAdd,
  disableAdding,
  AddEntityBlock,
  attributesName,
  metaErrorsPath,
  entityNameTranslationPath,
  bulkCopyRoutes,
  extraEntityIdentityFn,
  disabled,
  withBulkCopy = true,
  bankType = 'Journeys::LevelBanks::Bank',
}) {
  return (
    <ListWrapper
      {...{
        entityAttributes,
        readOnly,
        children,
        onEntityAdd,
        disableAdding,
        AddEntityBlock,
        attributesName,
        levelsPath,
        metaErrorsPath,
        entityNameTranslationPath,
        bulkCopyRoutes,
        extraEntityIdentityFn,
        disabled,
        entityIdentity,
        EntityHeader,
        bankType,
        BulkCopyModal: withBulkCopy && BulkCopyModal,
      }}
    />
  );
}
