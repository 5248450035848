import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Alert } from '@tripledotstudios/react-core';

import { PageHeader, PageSection } from '@pages/common';
import {
  Label,
  Field,
  FileField,
  FormButtonsGroup,
  PriorityField,
  SelectField,
  AvailabilityStateFormGroup,
} from '@controls/form';
import { Form, useCurrentApplication } from '@hooks';
import { combineReducers, rulesReducer } from '@reducers';
import AbTestingSelect from '@pages/common/ab-testing/AbTestingSelect';
import RulesForm from '@pages/rules/Form';
import { MyPetCafeRoutes } from '@pages/routes';
import { requestWithToSelectOptions } from '@services/toSelectOptions';

import { GAME_CORE_LABEL } from '@root/pages/applications/constants';
import ErrorsCard from './ErrorsCard';

const ManifestField = ({ name, title, pathname }) => (
  <Label text={title} labelSize={4} fieldSize={8}>
    <FileField name={name} downloadUrl={pathname} accept="application/json" fullError />
  </Label>
);

export default function LevelManifestSetsForm({
  data,
  cancelButtonPath,
  onSubmit,
  isBase,
}) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();

  const manifestSetTypesOptions = async () => {
    const options = await requestWithToSelectOptions(
      MyPetCafeRoutes.LevelManifestSetTypes.indexRequest,
      applicationId,
    );

    return [
      { label: GAME_CORE_LABEL, value: '' },
      ...options,
    ];
  };

  return (
    <Form
      initialValues={{
        ...data,
        isBase,
        applicationId,
        ...(data.manifestSetTypeId === null && { manifestSetTypeId: '' }),
      }}
      onSubmit={onSubmit}
      reducer={combineReducers([rulesReducer])}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Level Manifest`}>
            <FormButtonsGroup cancelButtonPath={cancelButtonPath} />
          </PageHeader>

          <Row className="gx-3">
            <Col xs={8}>
              <PageSection title="Level Manifests" size="md">
                <AbTestingSelect
                  applicationId={applicationId}
                  entityId={values.id}
                  entityType="MyPetCafe::ManifestSet"
                  propertyName="abTestingId"
                />
                <Field name="id" type="hidden" />
                <Field name="applicationId" type="hidden" />
                <Label text="Name" fieldSize={8}><Field name="name" type="text" /></Label>
                <Label text="Priority" fieldSize={8}>
                  <PriorityField
                    name="priority"
                    entitiesByPriority={data.entitiesByPriority}
                    disabled={isBase}
                  />
                </Label>
                <AvailabilityStateFormGroup fieldSize={8} disabled={isBase} />
                <Label text="Type" fieldSize={8} required>
                  <SelectField
                    name="manifestSetTypeId"
                    options={manifestSetTypesOptions}
                    isDisabled={values.id}
                    hasPrepopulatedOption
                  />
                </Label>
                {values.manifestSetTypeId ? (
                  <ManifestField
                    name="typedLevelManifest"
                    title="Level Manifest (JSON)"
                    pathname={values.typedManifestUrl}
                  />
                ) : (
                  <>
                    <ManifestField
                      name="levelManifest"
                      title="Level Manifest (JSON)"
                      pathname={values.manifestUrl}
                    />

                    <ManifestField
                      name="tutorialLevelManifest"
                      title="Tutorial Level Manifest (JSON)"
                      pathname={values.tutorialManifestUrl}
                    />
                  </>
                )}
                {isBase
                  ? ''
                  : <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} />}
              </PageSection>
            </Col>

            <Col xs={4}>
              <PageSection title="Details" size="md">
                {data?._meta ? (
                  <>
                    <ErrorsCard
                      title="Manifest details"
                      data={data._meta.errors.manifestErrors}
                    />
                    <ErrorsCard
                      title="Tutorial manifest details"
                      data={data._meta.errors.tutorialManifestErrors}
                    />
                  </>
                ) : <Alert variant="info" className="mb-0">No details available at the moment</Alert>}
              </PageSection>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
}
