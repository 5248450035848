import React from 'react';

import { isArray } from 'lodash';
import styled from 'styled-components';

const ErrorsList = styled.ol`color: red;`;

export default function ErrorsCard({ title, data }) {
  const renderRegularError = (error) => <li><p>{error}</p></li>;
  const renderArrayError = (errors) => (
    <li>
      {errors.map((error) => <p>{error}</p>)}
    </li>
  );

  return data && data.length > 0 ? (
    <>
      <h5>{title}</h5>
      <hr />
      <ErrorsList>
        {JSON.parse(data).map((element) => (
          isArray(element)
            ? renderArrayError(element)
            : renderRegularError(element)))}
      </ErrorsList>
    </>
  ) : '';
}
