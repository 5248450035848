import React from 'react';

import { useRouter } from '@tripledotstudios/react-core';
import { useConfirm } from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import { MissionsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

export default function GenerateAllocationButton() {
  const { query } = useRouter();
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Allocation',
    actionName: 'regeneration',
    refetch: () => {},
  });

  const onGenerateAllocation = () => {
    confirm
      .showConfirmation({ title: 'New allocation will be generated' })
      .then(() => MissionsRoutes.Settings.generateAllocationsRequest(query))
      .then(responseHandler);
  };

  return (
    <IconButton.Restore onClick={onGenerateAllocation}>Generate allocations</IconButton.Restore>
  );
}
