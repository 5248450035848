import React from 'react';
import { at, find } from 'lodash';
import { Row, Col } from 'react-bootstrap';

import { Field, SelectField } from '@controls/form';
import OverlayTooltip from '@controls/OverlayTooltip';
import { ItemsSelectField } from '@components/merge/shared/items/Select';
import GiftBag from '@components/merge/shared/rewards/GiftBag';

const availableRewardsConfigurations = {
  Item: {
    label: 'Item',
    value: 'Merge::Rewards::Item',
    defaults: { quantity: 1, locked: false },
    component: ({ value, items }) => {
      const foundItem = find(items, (item) => item.value === value.value);
      const tooltip = foundItem ? foundItem.label : '';

      return (
        <Row>
          <OverlayTooltip text={tooltip} placement="bottom">
            <Col xs={8} className="pe-0">
              <ItemsSelectField name="value" items={items} className="me-1" />
            </Col>
          </OverlayTooltip>
          <OverlayTooltip text={value.quantity || ''} placement="bottom">
            <Col xs={4} className="ps-1">
              <Field name="quantity" type="number" className="mt-1" />
            </Col>
          </OverlayTooltip>
        </Row>
      );
    },
  },

  Xp: {
    label: 'XP',
    value: 'Merge::Rewards::Xp',
    component: () => (
      <Field name="value" type="number" className="me-1" />
    ),
  },

  Expert: {
    label: 'Expert',
    value: 'Merge::Rewards::Expert',
    component: ({ experts: options }) => (
      <SelectField name="value" options={options} className="me-1" />
    ),
  },

  Coins: {
    label: 'Coins',
    value: 'Merge::Rewards::Coins',
    component: () => (
      <Field name="value" type="number" className="me-1" />
    ),
  },

  Gems: {
    label: 'Gems',
    value: 'Merge::Rewards::Gems',
    component: () => (
      <Field name="value" type="number" className="me-1" />
    ),
  },

  Energy: {
    label: 'Energy',
    value: 'Merge::Rewards::Energy',
    component: () => (
      <Field name="value" type="number" className="me-1" />
    ),
  },

  EnergyRefresh: {
    label: 'Energy Refresh',
    value: 'Merge::Rewards::EnergyRefresh',
    component: () => null,
  },

  InventorySlot: {
    label: 'Inventory Slot',
    value: 'Merge::Rewards::InventorySlot',
    component: () => (
      <Field name="value" type="number" className="me-1" />
    ),
  },

  GiftBag: {
    label: 'Gift Bag',
    value: 'Merge::Rewards::GiftBag',
    component: ({ value, items }) => <GiftBag value={value} items={items} />,
  },

  RefreshAllProducers: {
    label: 'Refresh all producers',
    value: 'Merge::Rewards::RefreshAllProducers',
    component: () => null,
  },

  EventProgress: {
    label: 'Event Progress',
    value: 'Merge::Rewards::EventProgress',
    component: () => (
      <Field name="value" type="number" className="me-1" />
    ),
  },
};

const allRewardsConfigurations = () => Object.values(availableRewardsConfigurations);
const pickRewardsConfigurations = (types) => at(availableRewardsConfigurations, types);

export { allRewardsConfigurations, pickRewardsConfigurations };
