import React from 'react';

import { newManifestSet, createManifestSet } from '@requests/my-pet-cafe/manifestSets';
import CreateUpdateSet from './CreateUpdateSet';

export default function New() {
  return (
    <CreateUpdateSet
      fetchFunction={newManifestSet}
      saveFunction={createManifestSet}
      actionName="creation"
    />
  );
}
