import React, { useMemo } from 'react';

import { PageSection, ReactTable, Alert } from '@pages/common';
import { ServerError } from '@controls/form';
import { useFormContext } from '@hooks';
import OverlayTooltip from '@controls/OverlayTooltip';

import ShapeBoard from '@pages/figure-sets/shapes/Board';

const SHAPE_TOOLTIP_SIZE = '4em';

const columnsFactory = ({ shapes, onShapeToggle, disabled }) => shapes.map(({
  name, id, width, height, figureConfig,
}) => ({
  id,
  Header: (
    <OverlayTooltip
      text={(
        <div style={{ minWidth: SHAPE_TOOLTIP_SIZE, minHeight: SHAPE_TOOLTIP_SIZE }} className="d-flex">
          <ShapeBoard
            clickable={false}
            width={width}
            height={height}
            maxWidth="100%"
            value={figureConfig}
            outline={false}
          />
        </div>
      )}
      placement="top"
    >
      <span role="button">{name}</span>
    </OverlayTooltip>
  ),
  Cell: ({ row: { original } }) => (
    <input
      type="checkbox"
      checked={original.includes(id)}
      disabled={disabled}
      onChange={({ target: { checked } }) => onShapeToggle(id, checked)}
    />
  ),
}));

export default function EnabledShapesTable({ shapes, ids, disabled }) {
  const { dispatch } = useFormContext();

  const onShapeToggle = (id, value) => dispatch({ type: 'toggleShape', id, value });

  const tableColumns = useMemo(() => columnsFactory({ shapes, onShapeToggle, disabled }), []);

  return (
    <>
      <PageSection title="Enabled shapes" />
      <ServerError as={Alert} name="enabledShapeIds" />
      <ReactTable
        columns={tableColumns}
        data={[ids]}
      />
    </>
  );
}
