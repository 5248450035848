import React, { useCallback } from 'react';

import {
  fieldBuilder,
  selectBuilder,
  buildQuickLinkFn,
} from '@services/fields-factory';

import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { TimePeriodField } from '@controls/form';

import { MergeRoutes } from '@pages/routes';

export default function FieldsFactory({ applicationId, schemaId }) {
  const params = [applicationId, { schemaId }];
  const buildParams = (values) => (
    [applicationId, { schemaId, ...values }]
  );

  return {
    ...selectBuilder(
      {
        name: 'postcardSetId',
        labelText: 'Postcard Set',
        options: useCallback(() => requestWithToSelectOptions(
          MergeRoutes.PostcardSets.indexRequest,
          ...params,
        ), [schemaId]),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.PostcardSets, applicationId),
      },
    ),
    ...selectBuilder(
      {
        name: 'expertSetId',
        labelText: 'Expert Set',
        options: useCallback(() => requestWithToSelectOptions(
          MergeRoutes.ExpertSets.indexRequest,
          ...params,
        ), [schemaId]),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.ExpertSets, applicationId),
      },
    ),
    ...selectBuilder(
      {
        name: 'itemConfigurationSetId',
        labelText: 'Item Configuration Set',
        options: useCallback(() => requestWithToSelectOptions(
          MergeRoutes.ItemConfigurationSets.indexRequest,
          ...params,
        ), [schemaId]),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.ItemConfigurationSets, applicationId),
      },
    ),
    ...selectBuilder(
      {
        name: 'levelSetId',
        labelText: 'Level Set',
        options: useCallback(() => requestWithToSelectOptions(
          MergeRoutes.LevelSets.indexRequest,
          ...params,
        ), [schemaId]),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.LevelSets, applicationId),
      },
    ),
    ...selectBuilder(
      {
        name: 'unbubblingConfigurationId',
        labelText: 'Unbubbling Configuration',
        options: useCallback(() => requestWithToSelectOptions(
          MergeRoutes.UnbubblingConfigurations.indexRequest,
          ...params,
        ), [schemaId]),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.UnbubblingConfigurations, applicationId),
      },
    ),
    ...selectBuilder(
      {
        name: 'inventoryConfigurationId',
        labelText: 'Inventory Configuration',
        options: useCallback(() => (
          requestWithToSelectOptions(MergeRoutes.InventoryConfigurations.indexRequest, ...params)
        ), [schemaId]),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.InventoryConfigurations, applicationId),
      },
    ),
    ...selectBuilder(
      {
        name: 'startingGridId',
        labelText: 'Starting Grid',
        options: useCallback(() => (
          requestWithToSelectOptions(MergeRoutes.StartingGrids.indexRequest, ...params)
        ), [schemaId]),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.StartingGrids, applicationId),
      },
    ),
    ...selectBuilder(
      {
        name: 'cutscenesConfigurationId',
        labelText: 'Cutscenes Configuration',
        options: useCallback(() => (
          requestWithToSelectOptions(MergeRoutes.CutscenesConfigurations.indexRequest, ...params)
        ), [schemaId]),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.CutscenesConfigurations, applicationId),
      },
    ),

    // Shop
    ...selectBuilder(
      {
        name: 'coinsCatalogId',
        labelText: 'Coins Catalog',
        options: useCallback(() => (
          requestWithToSelectOptions(MergeRoutes.ProductCatalogs.indexRequest, ...buildParams({ rewardType: 'coins' }))
        ), []),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.ProductCatalogs, applicationId),
      },
    ),
    ...selectBuilder(
      {
        name: 'gemsCatalogId',
        labelText: 'Gems Catalog',
        options: useCallback(() => (
          requestWithToSelectOptions(MergeRoutes.ProductCatalogs.indexRequest, ...buildParams({ rewardType: 'gems' }))
        ), []),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.ProductCatalogs, applicationId),
      },
    ),
    ...selectBuilder(
      {
        name: 'energyChestProductId',
        labelText: 'Energy Chest Product',
        options: useCallback(() => (
          requestWithToSelectOptions(MergeRoutes.Products.indexRequest, ...buildParams({ rewardType: 'energy_chest' }))
        ), [schemaId]),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.Products, applicationId),
      },
    ),
    ...selectBuilder(
      {
        name: 'energyProductId',
        labelText: 'Energy Product',
        options: useCallback(() => (
          requestWithToSelectOptions(
            MergeRoutes.Products.indexRequest,
            ...buildParams({ rewardType: 'energy', schemaId: null }),
          )
        ), []),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.Products, applicationId),
      },
    ),
    ...selectBuilder(
      {
        name: 'piggyBankProductId',
        labelText: 'Piggy Bank Product',
        options: useCallback(() => (
          requestWithToSelectOptions(MergeRoutes.Products.indexRequest, ...buildParams({ rewardType: 'piggy_bank' }))
        ), [schemaId]),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.Products, applicationId),
      },
    ),
    ...fieldBuilder(
      {
        name: 'shopItemsRandomizationTimer',
        labelText: 'Items: Randomization Timer',
        type: 'number',
        componentFn: ({ name, disabled }) => (
          <TimePeriodField name={name} disabled={disabled} inputs={['hours']} />
        ),
      },
    ),
    ...selectBuilder(
      {
        name: 'bundleCatalogId',
        labelText: 'Bundle Catalog',
        options: useCallback(() => (
          requestWithToSelectOptions(MergeRoutes.BundleCatalogs.indexRequest, ...params)
        ), [schemaId]),
        quickLinkFn: buildQuickLinkFn(MergeRoutes.BundleCatalogs, applicationId),
      },
    ),
  };
}
