import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';

import { adRequestDelayFields } from '@components/game-settings/Resource/Shared';

export const popupsOptions = [
  { label: 'IDFA', value: 'IDFA' },
  { label: 'GDPR', value: 'GDPR' },
  { label: 'Push Notifications', value: 'Allow_Notifications' },
  { label: 'Rate Us', value: 'Rate_Us' },
  { label: 'Google CMP', value: 'Google_CMP' },
  { label: 'Push notifications retry', value: 'PushNotifications_Retry_OptIn' },
];

export default combineWithSharedFieldsFactory([adRequestDelayFields], { popupsOptions });
