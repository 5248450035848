import React from 'react';
import { faDiagramPredecessor } from '@fortawesome/free-solid-svg-icons';
import { get, isEmpty, max } from 'lodash';
import { useFormContext, useFormGroup } from '@hooks';
import { persistedCollection } from '@services/utils';
import DropdownItem from '@root/controls/buttons/dropdown/DropdownItem';

const RULE_SECTIONS_PATH = 'ruleSectionsAttributes';

const calculateRuleSectionsDeep = (attributes, depth = 0) => {
  if (!attributes || !attributes.length) return depth;

  const nestingLevels = attributes.map(({ ruleSectionsAttributes }) => (
    calculateRuleSectionsDeep(persistedCollection(ruleSectionsAttributes), depth + 1)
  ));

  return max(nestingLevels);
};

export default function ConvertToSectionComponent({
  ActionComponent = DropdownItem,
  rulesPath,
  sectionsPath,
  sectionPath,
  maxNestingLevel,
  disabled,
}) {
  const { generateName } = useFormGroup();
  const { dispatch, values } = useFormContext();

  const splittedFormGroupName = generateName('').split('.').slice(0, 2);
  const baseRuleSectionPath = splittedFormGroupName[0] === RULE_SECTIONS_PATH
    ? RULE_SECTIONS_PATH
    : splittedFormGroupName.join('.');

  const rules = persistedCollection(get(values, rulesPath, []));
  const sections = persistedCollection(get(values, sectionPath, []));

  let currentNestingLevel = 1;
  let baseNestingLevel = 1;

  if (sectionsPath !== baseRuleSectionPath) {
    currentNestingLevel = calculateRuleSectionsDeep(persistedCollection(get(values, sectionPath, [])), 2);
  } else {
    baseNestingLevel = calculateRuleSectionsDeep(persistedCollection(get(values, baseRuleSectionPath, [])));
  }

  const disableRootSection = sectionsPath === baseRuleSectionPath && baseNestingLevel > maxNestingLevel;

  const isDisabled = () => (
    disabled || (isEmpty(rules) && isEmpty(sections)) || currentNestingLevel > maxNestingLevel || disableRootSection
  );

  const convertToSection = () => dispatch({
    actionType: 'convertToSection',
    rulesPath,
    sectionPath,
    sectionsPath,
  });

  return (
    <ActionComponent
      icon={faDiagramPredecessor}
      variant="warning"
      onClick={convertToSection}
      disabled={isDisabled()}
    >
      Convert to a section
    </ActionComponent>
  );
}
