import React from 'react';

import { FormatWrapper } from '../../shared';
import EditableCell from '../EditableCell';
import TypeModal from '../../TypeModal';

import CellContent from '../../shared/cells/CellContent';

export default function FormatCell({
  name,
  path,
  value,
  oldValues,
  setFieldValue,
  disabled,
  isNewRecord,
}) {
  return (
    <EditableCell
      name={name}
      path={path}
      value={value}
      oldValues={oldValues}
      setFieldValue={setFieldValue}
      valueWrapper={FormatWrapper}
      disabled={disabled}
      isNewRecord={isNewRecord}
    >
      {({
        currentValue,
        setCurrentValue,
        oldValue,
        saveChangedValue,
        disableEditMode,
      }) => (
        <>
          <CellContent
            newValue={currentValue}
            oldValue={oldValue}
            valueWrapper={FormatWrapper}
            isNewRecord={isNewRecord}
          />
          <TypeModal
            defaultValue={currentValue}
            onSave={({ value: valueFromModal }) => {
              setCurrentValue(valueFromModal);
              saveChangedValue(valueFromModal);
            }}
            onHide={disableEditMode}
          />
        </>
      )}
    </EditableCell>
  );
}
