import React from 'react';
import { PageTitle } from '@pages/common';
import AuditLogIndex from '@pages/audit-logs/Index';
import { AuditRoutes } from '@pages/routes';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function auditLogs() {
  return [
    <Route
      key={AuditRoutes.Logs.indexRawPath}
      path={AuditRoutes.Logs.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Activity Logs" />
          <AuditLogIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={AuditRoutes.ApplicationLogs.indexRawPath}
      path={AuditRoutes.ApplicationLogs.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Application Activity Logs" />
          <AuditLogIndex />
        </RouteContainer>
      )}
    />,
  ];
}
