import React, { useMemo } from 'react';

import { Spinner } from '@tripledotstudios/react-core';

import { FavouritesRoutes } from '@pages/routes';
import {
  PageHeader,
  Pagination,
  ReactTable,
} from '@pages/common';
import { useQuery, useConfirm } from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import ColumnsFactory from './ColumnsFactory';

export default function Index() {
  const { response, isLoading, refetch } = useQuery(FavouritesRoutes.indexRequest);
  const confirm = useConfirm();
  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Favourite',
    actionName: 'deletion',
    refetch,
  });
  const onDelete = (id, type) => () => {
    confirm.showConfirmation({
      title: `'${type}' will be deleted only from your Favourites. Continue?`,
    })
      .then(() => FavouritesRoutes.deleteRequest({ id })
        .then(responseHandler));
  };
  const columns = useMemo(() => ColumnsFactory({ onDelete }), []);

  return response && (
    <>
      <PageHeader title="Favourites" />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </>
  );
}
