import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

import { SelectField } from '@controls/form';
import { ColoredCard } from '@components/shared';

export default function Section({
  variant, operations, disabled, children,
}) {
  return (
    <ColoredCard variant={variant} className="mb-3">
      <Card.Header>
        <Row>
          <Col sm={2}>
            <SelectField
              name="operation"
              options={operations}
              isDisabled={disabled}
            />
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        {children}
      </Card.Body>
    </ColoredCard>
  );
}
