import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  PageHeader, Pagination, RulesList, DuplicationModalWithPriority,
} from '@pages/common';
import IconButton from '@controls/buttons';

import { LevelSetRoutes as Routes } from '@pages/routes';

export default function Index() {
  const { response, refetch } = useQuery(Routes.Sets.indexRequest);
  const { query: { applicationId } } = useRouter();
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Level set',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `Level set '${name}' will be deleted and not available for work. Continue?` })
      .then(() => Routes.Sets.deleteRequest({ applicationId, id })
        .then(responseHandler));
  };

  const onDuplicate = (id, name) => () => setDuplicatedRecord({ id, name });

  return response && (
    <div>
      <PageHeader title="Level Sets">
        <IconButton.New to={Routes.Sets.newPath({ applicationId })} />
      </PageHeader>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Priority</th>
            <th>Rules</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {response.items.map(({
            id, name, priority, ruleSectionsAttributes,
          }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{name}</td>
              <td>{priority}</td>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td>
                <RulesList rules={ruleSectionsAttributes} />
              </td>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td>
                <ButtonToolbar>
                  <IconButton.Edit to={Routes.Sets.editPath({ id, applicationId })} minimized />
                  <IconButton.Duplicate onClick={onDuplicate(id, name)} minimized />
                  <IconButton.Delete onClick={onDelete(id, name)} minimized />
                </ButtonToolbar>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <DuplicationModalWithPriority
        record={duplicatedRecord}
        entityName="Level Set"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => Routes.Sets.duplicateRequest({ ...values, applicationId })}
        entitiesByPriority={response.entitiesByPriority || {}}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
