import React, { useState } from 'react';

import { faScaleUnbalancedFlip } from '@fortawesome/free-solid-svg-icons';
import { useCurrentApplication } from '@hooks';

import ItemsModal from '@components/shared/items-comparison/ItemsModal';
import IconButton from '@controls/buttons';

export default function Comparison({
  routes, localePath, modelName, rulesWarning,
}) {
  const [isOpened, setOpened] = useState(false);

  const { applicationName, currentApplication } = useCurrentApplication();

  const toggleOpened = () => setOpened((prevIsOpened) => !prevIsOpened);
  const handleHide = () => setOpened(false);

  return (
    <>
      <IconButton icon={faScaleUnbalancedFlip} onClick={toggleOpened}>
        Compare
      </IconButton>
      <ItemsModal
        frontendRoutes={routes}
        applicationId={currentApplication.id}
        isOpened={isOpened}
        handleHide={handleHide}
        localePath={localePath}
        modelName={modelName}
        applicationName={applicationName}
        rulesWarning={rulesWarning}
      />
    </>
  );
}
