import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form, useCurrentApplication } from '@hooks';
import {
  Label, Field, FormButtonsGroup, FieldArrayWithLabel, ServerError,
} from '@controls/form';
import { PageHeader } from '@pages/common';

export default function TableConfigsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const { routingScope } = useCurrentApplication();

  const fieldsConfiguration = [
    { label: 'Table ID', name: 'tableId', type: 'text' },
    { label: 'Type', name: 'type', type: 'text' },
    { label: 'Environment ID', name: 'environmentId', type: 'text' },
    { label: 'Unlock Level', name: 'unlockLevel', type: 'number' },
    { label: 'Min denomination', name: 'minDenomination', type: 'number' },
    { label: 'Max denomination', name: 'maxDenomination', type: 'number' },
    { label: 'Number of decks', name: 'numberOfDecks', type: 'number' },
    {
      label: 'Hints available',
      name: 'hintsAvailable',
      type: 'checkbox',
      default: false,
    },
    {
      label: 'Insurance available',
      name: 'insuranceAvailable',
      type: 'checkbox',
      default: false,
    },
    { label: 'Insurance payout numerator', name: 'insurancePayoutNumerator', type: 'number' },
    { label: 'Insurance payout denominator', name: 'insurancePayoutDenominator', type: 'number' },
    { label: 'Blackjack payout numerator', name: 'blackjackPayoutNumerator', type: 'number' },
    { label: 'Blackjack payout denominator', name: 'blackjackPayoutDenominator', type: 'number' },
  ];

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit}>
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Table Config`}>
            <FormButtonsGroup cancelButtonPath={`${routingScope}/table_configs`} />
          </PageHeader>

          <Field type="hidden" name="applicationId" />
          <Label text="Name"><Field type="text" name="name" /></Label>
          <FieldArrayWithLabel
            collectionName="configs"
            values={values.configs}
            fieldsConfiguration={fieldsConfiguration}
            isHorizontal
            reorderable
            showPosition
          />
          <ServerError name="configs" />
        </>
      )}
    </Form>
  );
}
