import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import { useAbTestingContext } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import parseEntityUsageData from '@services/ab-testing/parseEntityUsageData';
import { AbTestingRoutes } from '@pages/routes';

import UsedExperiments from './UsedExperiments';
import ExperimentsDropdown from './ExperimentsDropdown';

const { enums: { AbExperimentStatuses } } = APP_DATA;

export default function EntityWithExperiment({ disabled = false, className }) {
  const { entityData, entityUsage, isLoading } = useAbTestingContext();
  const { entityId, entityType } = entityData;
  const router = useRouter();
  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Entity',
    actionName: 'adding to the experiment',
  });

  if (isLoading) return <Spinner name="A/B Experiments that use this entity" />;

  const parsedEntityUsage = parseEntityUsageData(entityUsage, AbExperimentStatuses);
  const { usedExperiments } = parsedEntityUsage;

  const handleAdditionOfEntityToExperiment = (id) => {
    AbTestingRoutes.Experiments.addEntityToExperimentRequest({
      id,
      applicationId: router.query.applicationId,
      entity: { entityType, entityId },
    }).then(responseHandler);
  };

  return (
    <>
      <Row className={className}>
        <Col xs={2}>
          <ExperimentsDropdown
            confirmationText={(experimentName) => (
              `The entity will be added to a/b experiment "${experimentName}". Continue?`
            )}
            handleAdd={handleAdditionOfEntityToExperiment}
            propertyEntityUsage={parsedEntityUsage}
            entityType={entityType}
            entityId={entityId}
            disabled={disabled}
          />
        </Col>
      </Row>

      <UsedExperiments usedProperties={usedExperiments} />
    </>
  );
}
