import React, { useMemo, useState } from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import {
  useQuery,
  useI18n,
  useConfirm,
  useCurrentApplication,
  useMassOperations,
} from '@hooks';
import {
  PageHeader,
  ReactTable,
  Pagination,
  DuplicationModalWithPriority,
} from '@pages/common';
import IconButton from '@controls/buttons';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import Comparison from '@components/shared/items-comparison/Comparison';

import Filter from './Filter';
import ColumnsFactory from './ColumnsFactory';

const SUPPORTS_COMPARISON = ['tile', 'woodoku', 'solitaire'];
const APPS_WITHOUT_LEVELS_COUNT = ['block_party'];

export default function Index({
  AdditionalFilters,
  statuses,
  routes,
  translationsPath,
  modelName,
  showLevelsCount = true,
  requestParams = {},
  showLabels = true,
  massOperationsEnabled = false,
  massOperationsProps = {},
}) {
  const { currentApplication } = useCurrentApplication();
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();
  const { response, refetch, isLoading } = useQuery(routes.indexRequest, { ...requestParams, includeInUse: true });
  const { translate } = useI18n();
  const {
    MassOperationsHeaderButtons,
    MassOperationsModal,
    massOperationsTableProps,
  } = useMassOperations();

  const entityName = translate.fallback(`${translationsPath}.name`);
  const levelEntityName = translate.fallback(`${translationsPath}.levels.name`);

  const archiveResponseHandler = collectionResponseHandlerFactory({
    entityName,
    actionName: 'archive',
    refetch,
  });

  const onArchive = (id, name) => () => {
    confirm.showConfirmation({
      title: `${entityName} '${name}' will be archived and not available for work. Continue?`,
    })
      .then(() => routes.archiveRequest({ id, applicationId })
        .then(archiveResponseHandler));
  };

  const displayLevelsCount = showLevelsCount && !APPS_WITHOUT_LEVELS_COUNT.includes(currentApplication.typeName);

  const columns = useMemo(() => ColumnsFactory({
    applicationId,
    setDuplicatedRecord,
    onArchive,
    statuses,
    routes,
    levelEntityName,
    showLevelsCount: displayLevelsCount,
    showLabels,
  }), []);
  const showComparison = SUPPORTS_COMPARISON.includes(currentApplication.typeName);

  return response && (
    <>
      <PageHeader
        title={translate.fallback(`${translationsPath}.pluralName`)}
        filter={(
          <Filter statuses={statuses}>
            {({ updateFilterField }) => (
              AdditionalFilters && <AdditionalFilters updateFilterField={updateFilterField} />
            )}
          </Filter>
        )}
        menuButtons={(
          <>
            {showComparison && (
              <Comparison
                routes={routes}
                modelName={modelName}
                localePath={translationsPath}
                rulesWarning
              />
            )}
            {massOperationsEnabled && <MassOperationsHeaderButtons />}
          </>
        )}
      >
        <IconButton.New to={routes.newPath({ applicationId })} />
      </PageHeader>
      {isLoading ? <Spinner /> : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
            defaultSort={{ id: 'updatedAt', desc: true }}
            {...(massOperationsEnabled && massOperationsTableProps)}
          />
          {massOperationsEnabled && (
            <MassOperationsModal
              refetch={refetch}
              {...massOperationsProps}
            />
          )}
          <DuplicationModalWithPriority
            record={duplicatedRecord}
            entityName={entityName}
            handleClose={() => setDuplicatedRecord(null)}
            onSubmit={(values) => routes.duplicateRequest({ ...values, applicationId })}
            entitiesByPriority={response.entitiesByPriority || {}}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </>
  );
}
