import { memoize } from 'lodash';

import { fieldBuilder } from '@services/fields-factory';

import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';

const fieldsFactory = memoize(() => ({
  ...fieldBuilder({ name: 'everythingJson', type: 'text', fieldOptions: { component: 'textarea' } }),
}));

export default combineWithSharedFieldsFactory(fieldsFactory, {});
