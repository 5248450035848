import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { filter } from 'lodash';

import { Label, SelectField } from '@controls/form';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { Form, FormGroup, useCurrentApplication } from '@hooks';
import entityIdentity from '@components/shared/entityIdentity';
import APP_DATA from '@services/appData';
import { JourneyRoutes, DailyChallengesRoutes, WoodokuRoutes } from '@pages/routes';

const bankStatuses = APP_DATA.enums['Journeys::LevelBanks::BankStatuses'];
const bankTypeOptions = [
  { value: 'Journeys::LevelBanks::Bank', label: 'Journey' },
  { value: 'DailyChallenges::LevelBanks::Bank', label: 'Daily Challenge' },
  { value: 'Woodoku::BonusLevels::LevelBanks::Bank', label: 'Bonus Level' },
];
const bankTypeToRoutesMapping = {
  'Journeys::LevelBanks::Bank': JourneyRoutes.LevelBanks,
  'DailyChallenges::LevelBanks::Bank': DailyChallengesRoutes.LevelBanks,
  'Woodoku::BonusLevels::LevelBanks::Bank': WoodokuRoutes.BonusLevels.LevelBanks,
};

export default function BulkCopyModal({
  bankId, handleClose, entities = [], entityName, bulkCopyRoutes, bankType,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bankRoutes, setBankRoutes] = useState(bulkCopyRoutes || JourneyRoutes.LevelBanks);
  const { currentApplication: { id: applicationId, type } } = useCurrentApplication();
  const [data, setData] = useState({});

  const responseHandler = noRedirectHandlerFactory({
    entityName,
    actionName: 'copying',
    setData,
  });

  const onSubmit = (values) => {
    setIsSubmitting(true);
    const { bankId: targetBankId, bankType: targetBankType } = values;

    return bulkCopyRoutes.bulkDuplicateLevelsRequest({
      bankType: targetBankType || bankType,
      bankId: targetBankId,
      ids: entities.map(({ id }) => id),
      applicationId,
      id: Number(bankId),
    }).then(responseHandler)
      .finally(() => { setIsSubmitting(false); });
  };

  const bankOptions = async () => {
    const options = await requestWithToSelectOptions(
      bankRoutes.indexRequest,
      applicationId,
      { includeInUse: false, withoutLevels: true, filter: { statusIn: [bankStatuses.ACTIVE] } },
    );

    return filter(options, ({ value }) => value !== Number(bankId));
  };
  const onHide = () => {
    setData({});
    handleClose();
  };

  return (
    <Modal show={!!entities} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{`Copy ${entityName}(s)`}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit} initialValues={data}>
        {() => (
          <FormGroup rootGroup>
            <Modal.Body>
              <Label text={`${entityName}(s) to copy`} labelSize={5} fieldSize={7}>
                <div className="mt-1 mb-2">
                  {(entities || []).map(({ id, name, number }) => (
                    <div key={id} className="mb-1">
                      {entityIdentity({ entity: { number, name }, entityName })}
                    </div>
                  ))}
                </div>
              </Label>
              {type === 'Woodoku::Application' && (
                <Label
                  text={`${entityName} bank type`}
                  tooltipText="Select level bank type to be able to select required Level bank"
                  fieldSize={8}
                  labelSize={4}
                >
                  <SelectField
                    name="bankType"
                    options={bankTypeOptions}
                    onChange={({ value }) => setBankRoutes(bankTypeToRoutesMapping[value])}
                    defaultValue={bankType}
                  />
                </Label>
              )}
              <Label
                text={`${entityName} bank`}
                tooltipText="Select level bank from Journey / Daily Challenge /
                Bonus Levels to which you want to copy levels."
                fieldSize={8}
                labelSize={4}
              >
                <SelectField name="bankId" options={bankOptions} />
              </Label>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" disabled={isSubmitting}>Confirm</Button>
              <Button type="button" variant="secondary" onClick={onHide}>Cancel</Button>
            </Modal.Footer>
          </FormGroup>
        )}
      </Form>
    </Modal>
  );
}
