import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { getAppConfigs, duplicateAppConfig, deleteAppConfig } from '@requests/my-pet-cafe/appConfigs';

import ConfigIndex from '@pages/my-pet-cafe/configs/Index';

export default function Index() {
  const { query } = useRouter();

  return (
    <ConfigIndex
      legend="Remote DB's"
      entityName="Remote DB"
      linkScope={`/admin/applications/${query.applicationId}/my_pet_cafe/app_configs`}
      getConfigsFunction={getAppConfigs}
      duplicateConfigFunction={duplicateAppConfig}
      deleteConfigFunction={deleteAppConfig}
    />
  );
}
