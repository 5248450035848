import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Expert Configuration',
    actionName: 'update',
    redirectPath: Routes.ExpertConfigurations.indexPath({ applicationId: query.applicationId }),
    setData,
  });

  const onSubmit = (values) => (
    Routes.ExpertConfigurations
      .updateRequest({ ...values, applicationId: query.applicationId })
      .then(responseHandler)
  );

  useEffect(() => {
    Routes.ExpertConfigurations.editRequest(query).then((response) => setData(response.data));
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} actionName="Update" />;
}
