/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Alert, useRouter } from '@tripledotstudios/react-core';

import { useConfirm } from '@hooks';
import { ReactTable } from '@pages/common';
import { AppUserRoutes } from '@pages/routes';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';

export default function SocialNetworkAccounts({ id }) {
  const [data, setData] = useState();
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();
  const queryData = { applicationId, id };

  const buildUnlinkHandler = (provider) => {
    const redirectIfSuccessHandler = noRedirectHandlerFactory({
      entityName: `${provider} unlink`,
      actionName: 'processing',
      setData,
    });

    return () => {
      confirm.showConfirmation({
        title: 'Confirm to unlink',
        body: (
          <Alert variant="warning">
            Please note that by clicking on confirmation button, the connection between user and account will be lost
          </Alert>
        ),
        confirmationText: 'Confirm',
        cancellationText: 'Cancel',
      }).then(() => AppUserRoutes.unlinkSocialProfileRequest(queryData).then(redirectIfSuccessHandler));
    };
  };

  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: 'provider' },
      { Header: 'ID', accessor: 'externalId' },
      { Header: 'Firstly connected', accessor: 'createdAt' },
      {
        Header: 'Actions',
        id: 'action',
        Cell: ({ row: { original } }) => {
          const { provider } = original;

          return (
            <Button variant="primary" onClick={buildUnlinkHandler(provider)}>
              {`Unlink ${provider}`}
            </Button>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    AppUserRoutes.socialNetworkAccountsRequest(queryData).then((response) => setData(response.data));
  }, []);

  if (!data) return null;

  return (
    <ReactTable
      columns={columns}
      data={data.snUsers}
      disableSortBy
      tableProps={{
        bordered: true,
        striped: true,
        responsive: true,
      }}
    />
  );
}
