import React from 'react';
import { JourneyRoutes } from '@pages/routes';
import SharedEdit from '../shared/Edit';

import Form from './Form';

const Edit = () => (
  <SharedEdit localePath="journeys.activationEvents" Form={Form} routes={JourneyRoutes.ActivationEvents} />
);

export default Edit;
