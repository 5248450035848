import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { VersionUpdateRoutes } from '@pages/routes';

export default function New({
  entityName,
  routesName,
  form: Form,
}) {
  const { indexPath, createRequest, newRequest } = VersionUpdateRoutes[routesName];
  const { response, setResponse } = useQuery(newRequest);
  const { query: { applicationId } } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName,
    actionName: 'creation',
    redirectPath: indexPath({ applicationId }),
    setData: setResponse,
  });

  const onSubmit = (values) => createRequest({ ...values, applicationId }).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
