/* eslint-disable gs/plz-camel-case-for-action-names */
import { max, last, sortBy } from 'lodash';

import { addUuid } from '@services/recursivelyProcessObject';

const getCurrentLevels = (state) => state.levelsAttributes.filter(({ _destroy }) => !_destroy);

const recalculateTotalXp = (state) => {
  const collection = getCurrentLevels(state);
  sortBy(collection, 'position');

  const lastLevel = last(collection) || { xpDiff: 0 };
  let xpDiffSum = 0;

  collection.forEach((level) => {
    xpDiffSum += level.xpDiff || lastLevel.xpDiff;

    level.xpToUnlock = xpDiffSum;
  });

  return collection;
};

export default function formReducer(state, { actionType, uuid }) {
  if (actionType === 'add_level') {
    const levels = getCurrentLevels(state);
    const maxPosition = max(levels.map(({ position }) => position)) || 0;
    const lastLevel = last(levels) || { xpDiff: 0 };

    state.levelsAttributes.push(
      addUuid({ position: maxPosition + 1, xpDiff: lastLevel.xpDiff, rewardAffiliationsAttributes: [] }),
    );

    recalculateTotalXp(state);
  }

  if (actionType === 'delete_level') {
    const levelToDelete = state.levelsAttributes.find((level) => !level._destroy && level._uuid === uuid);
    levelToDelete._destroy = true;

    let initialPosition = 1;
    getCurrentLevels(state).forEach((level) => {
      level.position = initialPosition;
      initialPosition += 1;
    });

    recalculateTotalXp(state);
  }

  if (actionType === 'add_new_levels') {
    const maxPosition = max(getCurrentLevels(state).map(({ position }) => position)) || 0;
    const addedLevels = [];

    [...Array(state.numberOfNewLevels)].forEach((_element, index) => {
      addedLevels.push(addUuid({
        position: maxPosition + 1 + index,
        xpDiff: state.diff,
        rewardAffiliationsAttributes: state.rewardAffiliationsAttributes,
      }));

      state.levelsAttributes.push(last(addedLevels));
    });

    state.diff = '';
    state.numberOfNewLevels = '';
    state.rewardAffiliationsAttributes = [];

    recalculateTotalXp(state);
  }

  if (actionType === 'recalculate_total_xp') {
    recalculateTotalXp(state);
  }

  return state;
}
