import React, { useContext, createContext } from 'react';

const formGroupContext = createContext();
const filterNameFn = (value) => value !== undefined && value !== null;

export function useFormGroup() {
  const generateName = (fieldName) => fieldName;

  return useContext(formGroupContext) || {
    generateName,
  };
}

export function FormGroup({
  name: proposedName = '',
  children = null,
  rootGroup = false,
}) {
  const parent = rootGroup ? null : useFormGroup();

  // Casting to string here is needed to prevent 0 index values being falsey when calculating 'fullName' below
  const name = String(proposedName);

  // Using filter instead of compact to prevent removing 0 as field name (for array inputs)
  // TODO [e.iskrenkov]: check if that works with the new interstitials.
  const generateName = (fieldName = null) => {
    const fullName = name ? [name, fieldName].filter(filterNameFn).join('.') : fieldName;

    if (!parent) return fullName;

    return parent.generateName(fullName);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const formGroupBag = {
    generateName,
  };

  return <formGroupContext.Provider value={formGroupBag}>{children}</formGroupContext.Provider>;
}
