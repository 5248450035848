import React, { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import {
  useQuery,
  useConfirm,
  useMassOperations,
} from '@hooks';

import {
  PageHeader,
  PageSection,
  Pagination,
  ReactTable,
  RulesList,
  DuplicationModalWithPriority,
  ActivityRangeColumn,
  NameColumn,
  StatusAndAvailabilityColumn,
  UsageCountColumn,
  PriorityColumn,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { AdsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';
import Filter from '@components/overridable-settings/Filter';
import { fromClassNameToOptions } from '@services/enums';

const columnsFactory = ({
  statuses, editPath, applicationId, onStatusToggle, onDelete, setDuplicatedRecord,
}) => ([
  NameColumn({ routes: AdsRoutes.Configurations }),
  PriorityColumn,
  { ...ActivityRangeColumn, style: null },
  StatusAndAvailabilityColumn({ statuses }),
  {
    Header: 'Rules',
    Cell: ({ row: { original: { ruleSectionsAttributes } } }) => <RulesList rules={ruleSectionsAttributes} />,
  },
  UsageCountColumn(),
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, status } } }) => {
      const isActive = status === statuses.ACTIVE;

      return (
        <ButtonToolbar>
          <IconButton.Edit to={editPath({ id, applicationId })} minimized />
          <IconButton.Activate isActive={isActive} onClick={onStatusToggle(id, name, status)} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
          <IconButton.Delete onClick={onDelete(id, name)} minimized />
        </ButtonToolbar>
      );
    },
  },
]);

export default function Index() {
  const {
    indexRequest, toggleStatusRequest, deleteRequest, duplicateRequest, newPath, editPath, basePath, searchFieldPath,
  } = AdsRoutes.Configurations;
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch, isLoading } = useQuery(indexRequest);
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();
  const {
    MassOperationsHeaderButtons,
    MassOperationsModal,
    massOperationsTableProps,
  } = useMassOperations();

  const { enums } = APP_DATA;
  const statuses = enums['Ads::ConfigurationStatuses'];

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Ads Configuration',
    actionName: 'deletion',
    refetch,
  });
  const activateResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Ads Configuration',
    actionName: 'activation',
    refetch,
  });
  const deactivateResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Ads Configuration',
    actionName: 'deactivation',
    refetch,
  });

  const onStatusToggle = (id, name, status) => () => {
    const isActive = status === statuses.ACTIVE;
    const actionName = isActive ? 'deactivate' : 'activate';

    confirm.showConfirmation({ title: `Ads Configuration '${name}' will be ${actionName}d. Continue?` })
      .then(() => toggleStatusRequest({ id, applicationId })
        .then(isActive ? deactivateResponseHandler : activateResponseHandler));
  };

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Ads Configuration '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({
    statuses, editPath, applicationId, onStatusToggle, onDelete, setDuplicatedRecord,
  }), []);

  return (
    isLoading ? <Spinner /> : (
      response && (
      <>
        <PageHeader
          title="Ads Configurations"
          userGuideUrlKey="adsConfigurationsGuideUrl"
          filter={<Filter statusesEnum="Ads::ConfigurationStatuses" />}
          menuButtons={(
            <>
              <MassOperationsHeaderButtons />

              <IconButton.Search
                to={searchFieldPath({ applicationId })}
                variant="secondary"
                outline
              >
                Search
              </IconButton.Search>
            </>
          )}
        >
          <IconButton.New to={newPath({ applicationId })} />
        </PageHeader>

        <Card className="mb-3">
          <Card.Body>
            <IconButton to={basePath({ applicationId })} icon={faSuitcase}>Base Config</IconButton>
          </Card.Body>
        </Card>

        <PageSection title="Overrides" />
        <ReactTable
          columns={columns}
          data={response.items}
          {...massOperationsTableProps}
        />
        <MassOperationsModal
          pageModelName="Ads Configurations"
          modelName="Ads::Configuration"
          statusOptions={fromClassNameToOptions('Ads::ConfigurationStatuses')}
          transactional
          includeAvailabilityState
          refetch={refetch}
        />
        <DuplicationModalWithPriority
          record={duplicatedRecord}
          entityName="Ads Configuration"
          handleClose={() => setDuplicatedRecord(null)}
          onSubmit={(values) => duplicateRequest({ ...values, applicationId })}
          entitiesByPriority={response.entitiesByPriority || {}}
        />
        <Pagination
          pageCount={response._meta.pages}
        />
      </>
      )
    )
  );
}
