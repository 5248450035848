import React, { useState, useEffect } from 'react';

import { AdminUsersRoutes } from '@pages/routes';

import { useCurrentApplication } from '@hooks';
import { requestWithToSelectOptions, buildConverterFromIndexResponse } from '@services/toSelectOptions';
import SelectFilter from './SelectFilter';

export default function UserSelectFilter({ updateFilterField, name }) {
  const { currentApplication } = useCurrentApplication();
  const [adminUsers, setAdminUsers] = useState([]);

  const getAdminUsers = async () => {
    const options = await requestWithToSelectOptions(
      AdminUsersRoutes.indexRequest,
      currentApplication.id,
      {},
      { toSelectOptionsFn: buildConverterFromIndexResponse({ labelAttribute: 'email' }) },
    );
    setAdminUsers(options);
  };

  useEffect(() => {
    getAdminUsers();
  }, []);

  return (
    adminUsers.length > 0 && (
      <SelectFilter
        name={name}
        label="User"
        updateFilterField={updateFilterField}
        options={adminUsers}
      />
    )
  );
}
