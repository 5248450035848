import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  TIMESTAMP_COLUMNS,
  InLiveBadge,
  InLiveColumnHeader,
  InUseColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';

import { MissionsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

export default function ColumnsFactory({ applicationId, setDuplicatedRecord, onDelete }) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    {
      id: 'availability_header',
      style: { width: 150 },
      Header: () => <InLiveColumnHeader />,
      Cell: ({ row }) => <InLiveBadge inLive={row.original.inLive} />,
    },
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({ row: { original: { id, name, inUse } } }) => {
        const disabled = isInUse(inUse);
        return (
          <ButtonToolbar>
            <IconButton.Edit to={MissionsRoutes.Rewards.Sets.editPath({ id, applicationId })} minimized />
            <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
            <IconButton.Delete
              onClick={onDelete(id, name)}
              disabled={disabled}
              title={disabled ? 'Disabled as the entity is used' : null}
              minimized
            />
          </ButtonToolbar>
        );
      },
    },
  ];
}
