import React from 'react';
import { isEmpty } from 'lodash';
import { Card, ListGroup } from 'react-bootstrap';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { useConfirm, useFormGroup, useFormContext } from '@hooks';

import ServerError from './ServerError';
import Label from './Label';
import IconButton from '../buttons';

const FilesList = ({
  attributes, setFieldValue, generateName, disabled,
}) => {
  const confirm = useConfirm();

  if (!attributes || isEmpty(attributes.filter(({ _destroy }) => !_destroy))) return null;

  const onFileRemove = (index, file) => {
    const destroyPath = generateName(`${index}._destroy`);

    if (file) {
      setFieldValue(destroyPath, true);
    } else {
      confirm.showConfirmation({
        title: 'File will be removed and not available for work. Continue?',
      }).then(() => setFieldValue(destroyPath, true));
    }
  };

  return (
    <Card>
      <Card.Header>Files</Card.Header>

      <ListGroup variant="flush">
        {attributes.map((attachment, index) => {
          const { file, meta, _destroy } = attachment;
          const filename = file ? file.name : meta.filename;

          const buildVariant = () => {
            if (_destroy) return 'danger';
            if (file) return 'success';

            return null;
          };

          return (
            <ListGroup.Item
              className="d-flex justify-content-between align-items-start"
              variant={buildVariant()}
            >
              <span className="align-self-center">
                {filename}
                <ServerError name={`${index}.file`} />
              </span>

              <ButtonToolbar>
                {meta && (
                  <IconButton.Download href={meta.downloadUrl} download={filename} minimized />
                )}
                {!_destroy && (
                  <IconButton.Delete onClick={() => onFileRemove(index, file)} disabled={disabled} minimized />
                )}
              </ButtonToolbar>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </Card>
  );
};

export default function MultipleFilesField({
  name, label, attributes, accept, disabled,
}) {
  const { setFieldValue } = useFormContext();
  const { generateName } = useFormGroup();

  const currentFilesAmount = (attributes && attributes.length) || 0;

  const onChange = (e) => {
    const { files } = e.target;

    Object.values(files).forEach((file, index) => {
      setFieldValue(generateName(`${currentFilesAmount + index}.${name}`), file);
    });
  };

  return (
    <>
      <Label text={label}>
        <input
          type="file"
          className="form-control"
          name={name}
          onChange={onChange}
          accept={accept}
          disabled={disabled}
          multiple
        />
      </Label>

      <FilesList
        attributes={attributes}
        setFieldValue={setFieldValue}
        generateName={generateName}
        disabled={disabled}
      />
    </>
  );
}
