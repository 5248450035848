import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import { ServerError } from '@controls/form';

const RewardItemsConfigurations = [
  {
    label: 'Bundle Asset',
    value: 'Payments::Rewards::BundleAssetReward',
    buildDefaults: () => ({ value: null }),
    component: ({ data, addData, readOnly }) => {
      const handleValueChange = (event) => {
        const { value } = event.target;

        addData({ value });
      };

      return (
        <Col xs={7}>
          <Row>
            <Col xs={4} className="mt-2">Key</Col>
            <Col>
              <Form.Control
                type="text"
                value={data.value}
                onChange={handleValueChange}
                disabled={readOnly}
              />
              <ServerError name="value" />
            </Col>
          </Row>
        </Col>
      );
    },
  },
];

export default RewardItemsConfigurations;
