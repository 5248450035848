import React, { useEffect, useState } from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { LocalizationsRoutes } from '@pages/routes';
import { PageTitle, PageHeader } from '@pages/common';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';

import { LanguagesProvider } from './shared/services/useLanguages';
import Form from './Form';
import ProgressModal from './ProgressModal';

export default function Index() {
  const [rawData, setRawData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [changedTranslationKeys, setChangedTranslationKeys] = useState();
  const [migrationInProgress, setMigrationInProgress] = useState(false);

  const router = useRouter();
  const { query: { applicationId, type } } = router;

  const pageTitle = `Translations (${type === 'live' ? 'Live' : 'Test'})`;

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Translations',
    actionName: 'update',
    setData: setChangedTranslationKeys,
  });

  const fetchTranslationsInitialValues = () => {
    setIsLoading(true);
    return (
      LocalizationsRoutes.Translations
        .newRequest(router.query)
        .then(({ data }) => {
          const {
            keyConfigs,
            dataMigrationInProgress,
          } = data;

          setMigrationInProgress(dataMigrationInProgress);
          setChangedTranslationKeys({ keyConfigs });
          setRawData(data);
        }).finally(() => setIsLoading(false))
    );
  };

  const onSubmit = (values) => (
    LocalizationsRoutes.Translations
      .saveRequest({ ...values, applicationId })
      .then(responseHandler)
      .then(({ status }) => {
        if (status === 200) fetchTranslationsInitialValues();
      })
  );

  useEffect(() => {
    fetchTranslationsInitialValues();
  }, [type]);

  return (
    changedTranslationKeys && !isLoading ? (
      <>
        <PageTitle text={pageTitle} />
        <PageHeader title={`Localisations: ${pageTitle}`} userGuideUrlKey="localizationsGuideUrl">
          <h5>{`Applied to Live at ${rawData.applyToLiveAt}`}</h5>
        </PageHeader>

        {migrationInProgress ? (
          <ProgressModal
            fetchTranslationsInitialValues={fetchTranslationsInitialValues}
          />
        ) : (
          <LanguagesProvider>
            <Form
              initialValues={changedTranslationKeys}
              rawData={rawData}
              setMigrationInProgress={setMigrationInProgress}
              onSubmit={onSubmit}
            />
          </LanguagesProvider>
        )}
      </>
    ) : <Spinner />
  );
}
