import React from 'react';
import { PageTitle } from '@pages/common';
import { MyPetCafeRoutes } from '@pages/routes';

import { Route } from 'react-router-dom';

import {
  JsonConfigsIndex,
  JsonConfigsNew,
  JsonConfigsEdit,
} from '@components/my-pet-cafe/json-configs';
import RouteContainer from '@pages/RouteContainer';

export default function jsonConfigs({ configTypes, group }) {
  const indexPath = `${MyPetCafeRoutes.JsonConfigs.indexRawPath}/${group}`;
  const newPath = `${MyPetCafeRoutes.JsonConfigs.newRawPath}/${group}`;
  const editPath = `${MyPetCafeRoutes.JsonConfigs.editRawPath}/${group}`;

  return [
    <Route
      key={indexPath}
      path={indexPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="JSON Configs" />
          <JsonConfigsIndex key={group} configTypes={configTypes} group={group} />
        </RouteContainer>
      )}
    />,
    <Route
      key={newPath}
      path={newPath}
      element={(
        <RouteContainer>
          <PageTitle entity="JSON Config" action="New" />
          <JsonConfigsNew configTypes={configTypes} group={group} />
        </RouteContainer>
      )}
    />,
    <Route
      key={editPath}
      path={editPath}
      element={(
        <RouteContainer>
          <PageTitle entity="JSON Config" action="Edit" />
          <JsonConfigsEdit configTypes={configTypes} group={group} />
        </RouteContainer>
      )}
    />,
  ];
}
