import React from 'react';

import { FormGroup, useFormContext, useFormGroup } from '@hooks';
import { ServerError } from '@controls/form';
import { AddIcon } from '@components/merge/shared/icons';

import Target from './Target';

export default function Targets({ values, items }) {
  const { dispatch } = useFormContext();
  const { generateName } = useFormGroup();
  const path = generateName('targetsAttributes');

  const onTargetAdd = () => dispatch({ type: 'add_target', path });
  const onTargetDelete = (index) => (
    dispatch({ type: 'remove_target', path: generateName(`targetsAttributes[${index}]`) })
  );

  return (
    <>
      <ServerError name={path} full />
      {values.map((target, index) => (
        !target._destroy ? (
          <FormGroup key={target._uuid} name={`targetsAttributes[${index}]`}>
            <Target items={items} onTargetDelete={() => onTargetDelete(index)} />
          </FormGroup>
        ) : ''
      ))}
      <AddIcon onClick={onTargetAdd} />
    </>
  );
}
