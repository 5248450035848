import React from 'react';
import { faFlag, faDiagramProject, faUsersRectangle } from '@fortawesome/free-solid-svg-icons';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { useFormGroup, useFormContext } from '@hooks';
import IconButton from '@controls/buttons';

import { Dropdown } from 'react-bootstrap';
import DropdownToggle from '@root/controls/buttons/dropdown/DropdownToggle';
import DeleteButton from '../DeleteButton';
import ConvertToSectionComponent from '../ConvertToSectionComponent';
import CreateNewNamedRuleGroupComponent from '../CreateNewNamedRuleGroupComponent';

const MAX_NESTING_LEVEL = 2;

export default function ButtonsBlock({
  index,
  nestingLevel,
  hideNamedGroupsRule,
  setShowCreateNamedRuleGroupModal,
  setNamedRuleGroupSection,
  createNewNamedRuleGroupEnabled,
  disabled,
}) {
  const { generateName } = useFormGroup();
  const { dispatch } = useFormContext();

  const rulesPath = generateName('rulesAttributes');
  const sectionPath = generateName('ruleSectionsAttributes');

  const sectionsPath = sectionPath.split('.').slice(0, -2).join('.');

  const addRule = () => dispatch({ actionType: 'addRule', rulesPath });
  const addSection = () => dispatch({ actionType: 'addSection', sectionPath });
  const addNamedGroupRule = () => dispatch({ actionType: 'addNamedGroupRule', rulesPath });

  const removeSection = () => dispatch({
    actionType: 'removeSection',
    sectionPath: sectionsPath,
    index,
  });
  const isAcceptableNestingLevel = nestingLevel < MAX_NESTING_LEVEL;
  const displayDropdownMenu = isAcceptableNestingLevel || createNewNamedRuleGroupEnabled;

  return (
    <ButtonToolbar>
      <IconButton
        icon={faFlag}
        testId={`add_rule_button_level_${nestingLevel}`}
        variant="success"
        onClick={addRule}
        disabled={disabled}
      >
        Add Rule
      </IconButton>
      <ButtonToolbar>
        {isAcceptableNestingLevel && (
          <IconButton
            icon={faDiagramProject}
            testId={`add_rules_section_button_level_${nestingLevel}`}
            onClick={addSection}
            disabled={disabled}
          >
            Add Section
          </IconButton>
        )}

        {!hideNamedGroupsRule && (
          <IconButton icon={faUsersRectangle} variant="info" onClick={addNamedGroupRule} disabled={disabled}>
            Add Named Rule Group
          </IconButton>
        )}
      </ButtonToolbar>

      {nestingLevel !== 0 && (
        <>
          <ButtonToolbar.Divider />
          <DeleteButton
            nestingLevel={nestingLevel - 1}
            onClick={removeSection}
            disabled={disabled}
          />
        </>
      )}
      {displayDropdownMenu && (
        <Dropdown className="ms-auto">
          <DropdownToggle />
          <Dropdown.Menu className="shadow mb-5 bg-body rounded">
            {isAcceptableNestingLevel && (
              <ConvertToSectionComponent
                rulesPath={rulesPath}
                sectionsPath={sectionsPath}
                sectionPath={sectionPath}
                disabled={disabled}
                maxNestingLevel={MAX_NESTING_LEVEL}
              />
            )}
            {createNewNamedRuleGroupEnabled && (
              <CreateNewNamedRuleGroupComponent
                rulesPath={rulesPath}
                sectionsPath={sectionsPath}
                sectionPath={sectionPath}
                setShowCreateNamedRuleGroupModal={setShowCreateNamedRuleGroupModal}
                setNamedRuleGroupSection={setNamedRuleGroupSection}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </ButtonToolbar>
  );
}
