import React from 'react';

import { FilesRoutes } from '@pages/routes';
import Resource from './Resource';

export default function New() {
  const { createRequest, newRequest } = FilesRoutes;

  return (
    <Resource
      fetchRequest={newRequest}
      saveRequest={createRequest}
      actionName="creation"
    />
  );
}
