import { useCallback } from 'react';
import { memoize } from 'lodash';

import APP_DATA from '@services/appData';
import {
  fieldBuilder, selectBuilder, fieldGroupBuilder, fieldArrayBuilder,
} from '@services/fields-factory';
import { requestWithToSelectOptions, buildConverterFromIndexResponse } from '@services/toSelectOptions';
import { MyPetCafeRoutes, FlexiblePopupRoutes } from '@pages/routes';

import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';
import selectOptionsWithQuickLink from '@services/selectOptionsWithQuickLink';
import { adRequestDelayFields } from '../Resource/Shared';

const popupsOptions = [
  { label: 'IDFA', value: 'IDFA' },
  { label: 'GDPR', value: 'GDPR' },
  { label: 'Rate Us', value: 'Rate_Us' },
  { label: 'Push Notification', value: 'Allow_Notifications' },
  { label: 'Google CMP', value: 'Google_CMP' },
  { label: 'Push notifications retry', value: 'PushNotifications_Retry_OptIn' },
];

const rateUsStartThresholdOptions = [1, 2, 3, 4, 5].map((number) => ({ label: number, value: number }));

const Fields = memoize(({
  antiChurnConfigOptions,
  antiChurnConfigQuickLink,
  betUpConfigOptions,
  flexiblePopupOptions,
  unlockRequirementOptions,
}) => ({
  ...fieldBuilder({ name: 'appsflyerEventWhitelist', type: 'text' }),
  ...fieldBuilder({ name: 'overrideAddressablesVersion', type: 'text' }),
  ...fieldBuilder({ name: 'baseIncomeMultiplier', type: 'number' }),
  ...fieldBuilder({ name: 'baseIncomeInterval', type: 'number' }),
  ...fieldBuilder({ name: 'maxBonusAccumulation', type: 'number' }),
  ...fieldBuilder({ name: 'bonusInterval', type: 'number' }),
  ...fieldBuilder({ name: 'maxBonusMultiplier', type: 'number' }),
  ...fieldBuilder({ name: 'startLevelDownload', type: 'number' }),
  ...fieldBuilder({ name: 'levelDownloadOffset', type: 'number' }),
  ...fieldBuilder({ name: 'bulkPurchaseAmount', type: 'number' }),
  ...fieldBuilder({ name: 'uncountedRewardLevel', type: 'number' }),
  ...fieldBuilder({ name: 'unlockAtRemainingCard', type: 'number' }),
  ...fieldBuilder({ name: 'localizationVersion', type: 'text' }),
  ...fieldBuilder({ name: 'localizationUrl', type: 'text' }),
  ...fieldBuilder({ name: 'enablePitySystem', type: 'checkbox' }),
  ...fieldBuilder({ name: 'playOnMatchingCount', type: 'number' }),
  ...fieldBuilder({ name: 'playOnMatchingCountIncrement', type: 'number' }),
  ...fieldBuilder({ name: 'playOnMatchingCountMax', type: 'number' }),
  ...fieldBuilder({ name: 'playOnMatchingChance', type: 'number' }),
  ...fieldBuilder({ name: 'playOnMatchingChanceIncrement', type: 'number' }),
  ...fieldBuilder({ name: 'playOnMatchingChanceMax', type: 'number' }),
  ...fieldBuilder({ name: 'giftboxAutoCollect', type: 'checkbox' }),
  ...fieldBuilder({ name: 'enableAutoPopupQuestMenu', type: 'checkbox' }),
  ...fieldBuilder({ name: 'autoPopupQuestAfterQuestId', type: 'text' }),
  ...fieldBuilder({ name: 'autoPopupQuestUntilQuestId', type: 'text' }),
  ...fieldBuilder({ name: 'maxDifferenceTurnOffPopUp', type: 'number' }),
  ...fieldBuilder({ name: 'isPopupEnabledAgainIfFulfilled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'showFacebookSettingsAfterPlayCount', type: 'text' }),
  ...fieldBuilder({ name: 'sagaMapAchievementRequirement', type: 'text' }),
  ...fieldBuilder({ name: 'worldMapChapterRequirement', type: 'text' }),
  ...fieldBuilder({ name: 'worldMapLevelRequirement', type: 'number' }),
  ...fieldBuilder({ name: 'worldMapShowWidgetLevelRequirement', type: 'number' }),
  ...fieldBuilder({ name: 'worldMapWatchedCutsceneRequirement', type: 'text' }),
  ...fieldBuilder({ name: 'automaticChapterDownloadLevelRequirement', type: 'text' }),
  ...fieldBuilder({ name: 'fmvEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'fmvIsSkippable', type: 'checkbox' }),
  ...fieldBuilder({ name: 'fmvLevelRequirement', type: 'number' }),
  ...fieldBuilder({ name: 'dailyBonusMinLevelToUnlock', type: 'number' }),
  ...fieldBuilder({ name: 'dailyBonusEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyBonusRounding', type: 'number' }),
  ...fieldBuilder({ name: 'dailyBonusMultiplier', type: 'number' }),
  ...fieldBuilder({ name: 'dailyBonusUnlockWhenLowOnCoins', type: 'checkbox' }),
  ...fieldBuilder({ name: 'menuFlowPostWinFlow', type: 'text' }),
  ...fieldBuilder({ name: 'menuFlowAutoEnter', type: 'checkbox' }),
  ...fieldBuilder({ name: 'instantQuestSkipEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'instantQuestConsecutiveSkipRequirement', type: 'number' }),
  ...fieldBuilder({ name: 'ftueTargetLevel', type: 'number' }),
  ...fieldBuilder({ name: 'ftueTargetLevelOffset', type: 'number' }),
  ...fieldBuilder({ name: 'ftueMinimumLevel', type: 'number' }),
  ...fieldBuilder({ name: 'ftueEntryCostRequirementThresholdMultiplier', type: 'number' }),
  ...fieldBuilder({ name: 'ftueNonWinAttemptCooldown', type: 'number' }),
  ...fieldBuilder({ name: 'ftueMaximumTriggerCount', type: 'number' }),
  ...fieldBuilder({ name: 'powerupRounding', type: 'number' }),
  ...fieldBuilder({ name: 'ftueMaximumPowerup', type: 'number' }),
  ...fieldBuilder({ name: 'coinRounding', type: 'number' }),
  ...fieldBuilder({ name: 'ftueMaximumCoin', type: 'number' }),
  ...fieldBuilder({ name: 'ftueRewardSet', type: 'text' }),
  ...fieldBuilder({ name: 'tapParticleName', type: 'text' }),
  ...fieldBuilder({ name: 'gdprEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'gdprVersion', type: 'number' }),
  ...fieldBuilder({ name: 'rateUsCoinReward', type: 'number' }),
  ...fieldBuilder({ name: 'rateUsLevelTrigger', type: 'number' }),
  ...fieldBuilder({ name: 'gameServerResetTime', type: 'text' }),
  ...fieldBuilder({ name: 'enableDailyMissions', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyMissionsLevelTrigger', type: 'number' }),
  ...fieldBuilder({ name: 'enableDailyMissionNotification', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyMissionNotificationResetTime', type: 'text' }),
  ...fieldBuilder({ name: 'dailyMissionCutsceneTrigger', type: 'text' }),
  ...fieldBuilder({ name: 'dailyMissionShowWidgetLevelRequirement', type: 'number' }),
  ...selectBuilder({ name: 'dailyMissionUnlockRequirementId', ...unlockRequirementOptions }),
  ...fieldBuilder({ name: 'betUpEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'inventoryWidgetEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'inventoryLevelRequirement', type: 'number' }),
  ...fieldBuilder({ name: 'inventoryCutsceneRequirement', type: 'text' }),
  ...selectBuilder({ name: 'inventoryUnlockRequirementId', ...unlockRequirementOptions }),
  ...selectBuilder({ name: 'customDecoUnlockRequirementId', ...unlockRequirementOptions }),
  ...fieldBuilder({ name: 'customDecorsEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'customDecorsLevelRequirement', type: 'number' }),
  ...fieldBuilder({ name: 'customDecorsCutsceneRequirement', type: 'text' }),
  ...fieldBuilder({ name: 'seasonsPassEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'seasonsPassLevelRequirement', type: 'number' }),
  ...fieldBuilder({ name: 'seasonsPassCutsceneRequirement', type: 'text' }),
  ...selectBuilder({ name: 'seasonsPassUnlockRequirementId', ...unlockRequirementOptions }),
  ...fieldBuilder({ name: 'showUnreleasedDeco', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyAdventureEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyAdventureLevelRequirement', type: 'number' }),
  ...fieldBuilder({ name: 'dailyAdventureCutsceneRequirement', type: 'text' }),
  ...selectBuilder({ name: 'rateUsStarThreshold', options: rateUsStartThresholdOptions }),
  ...selectBuilder({
    name: 'antiChurnRewardConfigId',
    options: antiChurnConfigOptions,
    quickLinkFn: antiChurnConfigQuickLink,
  }),
  ...selectBuilder({ name: 'betUpConfigurationId', ...betUpConfigOptions }),
  ...fieldBuilder({ name: 'isQuestProgressBarEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'questProgressBarLevelRequirement', type: 'number' }),
  ...fieldBuilder({ name: 'questProgressBarWatchedCutsceneRequirement', type: 'text' }),
  ...fieldBuilder({
    name: 'questProgressBarAppearanceDurationInSeconds',
    type: 'number',
    fieldOptions: { appendText: 'Seconds', step: '0.1' },
  }),
  ...fieldBuilder({ name: 'isQuestProgressBarLimitEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'questProgressBarAppearanceNumberPerDay', type: 'number' }),
  ...fieldBuilder({ name: 'isDecoProgressEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'decoProgressLevelRequirement', type: 'number' }),
  ...fieldBuilder({ name: 'decoProgressWatchedCutsceneRequirement', type: 'string' }),
  ...selectBuilder({ name: 'decoProgressUnlockRequirementId', ...unlockRequirementOptions }),
  ...fieldGroupBuilder(
    {
      name: 'maxChapterSettings',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'actualMaxChapter', type: 'number' }),
        ...fieldBuilder({ name: 'maxChapterOrder', type: 'number' }),
      },
    },
  ),
  ...selectBuilder({ name: 'worldMapUnlockRequirementId', ...unlockRequirementOptions }),
  ...fieldBuilder({ name: 'isStarPointHelperEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'isPlayonCountHelperEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'isPreloadedCardcutHelperEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'isPreloadedWhirlwindHelperEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'isPreloadedWildcardHelperEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'isUndoCountHelperEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'isWildcardCountHelperEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'min2StarPointHelperThreshold', type: 'number', fieldOptions: { step: '0.1' } }),
  ...fieldBuilder({ name: 'min3StarPointHelperThreshold', type: 'number', fieldOptions: { step: '0.1' } }),
  ...fieldBuilder({ name: 'minPlayonCountThreshold', type: 'number' }),
  ...fieldBuilder({ name: 'minUndoCountThreshold', type: 'number' }),
  ...fieldBuilder({ name: 'minWildcardCountThreshold', type: 'number' }),
  ...fieldBuilder({ name: 'questSuggestionEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'questSuggestionStart', type: 'string' }),
  ...fieldBuilder({ name: 'questSuggestionUntil', type: 'string' }),
  ...fieldBuilder({ name: 'questSuggestionDefaultShowDelay', type: 'number' }),
  ...fieldBuilder({ name: 'toggleSaveBetupModeAfterTutorial', type: 'checkbox' }),
  ...fieldBuilder({ name: 'toggleResetBetupModeAfterPlay', type: 'checkbox' }),
  ...fieldBuilder({ name: 'isPetRewardEnabled', type: 'checkbox' }),
  ...selectBuilder({ name: 'petRewardUnlockRequirementId', ...unlockRequirementOptions }),
  ...fieldGroupBuilder(
    {
      name: 'flexiblePopupsSettings',
      hasBackground: false,
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'flexiblePopupsEnabled', type: 'checkbox' }),
        ...fieldArrayBuilder(
          {
            name: 'flexiblePopups',
            tooltipText: '',
            isHorizontal: true,
            reorderable: true,
            handleOnly: true,
            fieldsConfiguration: [
              {
                name: 'flexiblePopupId',
                as: 'select',
                componentData: flexiblePopupOptions.options,
                quickLinkFn: flexiblePopupOptions.quickLinkFn,
              },
              { name: 'minimumSession', type: 'number' },
              { name: 'solitaireLevelRequirement', type: 'number' },
              { name: 'watchedCutsceneRequirement', type: 'text' },
            ],
          },
        ),
      },
    },
  ),
}), (args) => JSON.stringify(args));

const fieldsFactory = ({ applicationId }) => {
  const { enums } = APP_DATA;
  const jsonConfigTypes = enums['MyPetCafe::JsonConfigTypes'];
  const antiChurnConfigOptions = useCallback(() => requestWithToSelectOptions(
    () => MyPetCafeRoutes.JsonConfigs.indexRequest({ applicationId, type: [jsonConfigTypes.ANTI_CHURN_REWARDS] }),
    applicationId,
  ), []);
  const antiChurnConfigQuickLink = (selectedValue) => (
    `${MyPetCafeRoutes.JsonConfigs.editPath({ id: selectedValue, applicationId })}/game_settings`
  );
  const betUpConfigOptions = selectOptionsWithQuickLink(
    MyPetCafeRoutes.BetUpConfigurations,
    applicationId,
    { applicationId },
  );
  const flexiblePopupOptions = selectOptionsWithQuickLink(
    FlexiblePopupRoutes.Popups,
    applicationId,
    {},
    { toSelectOptionsFn: buildConverterFromIndexResponse({ labelAttribute: 'popupId' }) },
  );
  const unlockRequirementOptions = selectOptionsWithQuickLink(MyPetCafeRoutes.UnlockRequirements,
    applicationId,
    { applicationId, withoutPagination: true },
  );

  return Fields({
    antiChurnConfigOptions,
    antiChurnConfigQuickLink,
    betUpConfigOptions,
    flexiblePopupOptions,
    unlockRequirementOptions,
  });
};

export default combineWithSharedFieldsFactory([fieldsFactory, adRequestDelayFields], { popupsOptions });
