import React, { useEffect, useState } from 'react';

import { FormGroup, useCurrentApplication } from '@hooks';
import APP_DATA from '@services/appData';
import { fromClassNameToOptions } from '@services/enums';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { LocalizationsRoutes } from '@pages/routes';
import { ReactTable } from '@pages/common';

import TargetTemplate from './target-templates/TargetTemplate';
import NewMissionType from './target-templates/NewMissionType';
import TargetColumns from './target-templates/TargetColumns';

const APPLICATIONS_SUPPORTED_RULES = ['my_pet_cafe'];

const { enums, rawEnums } = APP_DATA;
const missionTypeOrders = enums['Missions::MissionTypeOrderDirections'];

export default function TargetTemplates({ targets }) {
  const { applicationKey, currentApplication } = useCurrentApplication();
  const [localizationKeys, setLocalizationKeys] = useState([]);

  const allocationTypeOptions = fromClassNameToOptions('Missions::TargetTemplateAllocationTypes');

  const supportRules = APPLICATIONS_SUPPORTED_RULES.includes(applicationKey);
  const columns = React.useMemo(() => TargetColumns({
    missionTypeOrders,
    allocationTypeOptions,
    rawEnums,
    supportRules,
  }), []);
  useEffect(() => {
    requestWithToSelectOptions(LocalizationsRoutes.Keys.indexRequest, currentApplication.id, [])
      .then(setLocalizationKeys);
  }, []);

  return targets && (
    <>
      <ReactTable
        columns={columns}
        data={targets}
        disableSortBy
        tableProps={{
          bordered: true,
          hover: false,
          striped: true,
        }}
        renderRowSubComponent={({ row: { index, original } }) => {
          const {
            tierTargetsAttributes,
            missionTypeValuesType,
            rulesAttributes,
            allocationType,
            _destroy,
            _uuid,
          } = original;

          return (
            <FormGroup name={`targetsAttributes[${index}]`} key={_uuid}>
              <TargetTemplate
                index={index}
                tierTargetsAttributes={tierTargetsAttributes}
                valuesType={missionTypeValuesType}
                rulesAttributes={rulesAttributes}
                allocationType={allocationType}
                supportRules={supportRules}
                localizationKeys={localizationKeys}
                _destroy={_destroy}
              />
            </FormGroup>
          );
        }}
      />
      <NewMissionType />
    </>
  );
}
