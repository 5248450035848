import {
  fetchCollection, newResource, editResource, createResourceMultipart, updateResourceMultipart, deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/solitaire/level_layouts`;

export function getLevelLayouts(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function newLevelLayout(options) {
  return newResource(baseUrl(options), options);
}

export function editLevelLayout(data, options) {
  return editResource(baseUrl(data), data, options);
}

export function createLevelLayout(query, values) {
  return createResourceMultipart(baseUrl(query), values);
}

export function updateLevelLayout(query, values) {
  return updateResourceMultipart(baseUrl(query), values);
}

export function deleteLevelLayout(values) {
  return deleteResource(baseUrl(values), values);
}
