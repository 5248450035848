import React from 'react';
import { faUsersRectangle } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';
import { NamedRuleGroupsRoutes } from '@pages/routes';

import { useFormContext } from '@hooks';
import { persistedCollection } from '@services/utils';
import { useRouter, useFlashMessages } from '@tripledotstudios/react-core';
import DropdownItem from '@root/controls/buttons/dropdown/DropdownItem';

export default function CreateNewNamedRuleGroupComponent({
  ActionComponent = DropdownItem,
  rulesPath,
  sectionsPath,
  sectionPath,
  disabled = false,
  setShowCreateNamedRuleGroupModal,
  setNamedRuleGroupSection,
}) {
  const { dispatch, values } = useFormContext();
  const { query: { applicationId } } = useRouter();

  const rules = persistedCollection(get(values, rulesPath, []));
  const sections = persistedCollection(get(values, sectionPath, []));
  const isDisabled = () => (disabled || (rules.length + sections.length < 2));

  const createNewNamedRuleGroupFromSection = () => {
    const namedRuleGroupSectionPath = sectionPath.split('.').slice(0, -1).join('.');
    const sectionAttributes = [get(values, namedRuleGroupSectionPath, {})];

    setNamedRuleGroupSection(namedRuleGroupSectionPath);

    NamedRuleGroupsRoutes
      .validateRuleSectionsRequest({ ruleSectionsAttributes: sectionAttributes, applicationId })
      .then((response) => {
        if (response.status === 204) {
          return setShowCreateNamedRuleGroupModal(true);
        }
        if (response.status === 422) {
          return (dispatch({ actionType: 'addSectionValidationErrors', sectionsPath, response }));
        }
        const { error } = useFlashMessages();
        return error('Unable to valiate Rules Section');
      });
  };

  return (
    <ActionComponent
      icon={faUsersRectangle}
      variant="primary"
      onClick={createNewNamedRuleGroupFromSection}
      disabled={isDisabled()}
    >
      Create Named Rule Group
    </ActionComponent>
  );
}
