import React from 'react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { AuditRoutes } from '@pages/routes';

export default function AuditLogItem({ currentApplicationId, minimized }) {
  const filter = currentApplicationId ? { applicationIdIn: [currentApplicationId] } : {};
  const auditLogsPath = currentApplicationId
    ? AuditRoutes.ApplicationLogs.indexPath({ applicationId: currentApplicationId })
    : AuditRoutes.Logs.indexPath();

  return (
    <Sidebar.LinkItem
      to={auditLogsPath}
      params={{ filter }}
      icon={faList}
      title="Activity Log"
      minimized={minimized}
    />
  );
}
