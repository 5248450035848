import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { PageHeader } from '@pages/common';
import { Form, useCurrentApplication } from '@hooks';
import {
  Label, Field, FormButtonsGroup, FieldArrayWithLabel,
} from '@controls/form';

export default function AdsConfigsForm({ data, onSubmit }) {
  const defaultModeOptions = [{ label: 'or', value: 'or' }, { label: 'and', value: 'and' }];

  const fieldsConfiguration = [
    { label: 'Game', name: 'game', type: 'text' },
    { label: 'Frequency cap', name: 'frequencyCap', type: 'number' },
    { label: 'Level cap', name: 'levelCap', type: 'number' },
    {
      label: 'Mode', name: 'mode', as: 'select', componentData: defaultModeOptions,
    },
  ];

  const { query: { applicationId } } = useRouter();
  const { routingScope } = useCurrentApplication();

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit}>
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Ads Config`}>
            <FormButtonsGroup cancelButtonPath={`${routingScope}/puzzle_time/ads_configs`} />
          </PageHeader>

          <Field type="hidden" name="applicationId" />

          <Label text="Name"><Field type="text" name="name" /></Label>
          <FieldArrayWithLabel
            collectionName="cells"
            values={values.cells}
            fieldsConfiguration={fieldsConfiguration}
            isHorizontal
          />
        </>
      )}
    </Form>
  );
}
