import React, { useRef } from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from '@tripledotstudios/react-core';

import IconButton from '@controls/buttons';

export default function DownloadButton({
  className, request, download, label = 'Download', ...rest
}) {
  const { query } = useRouter();
  const downloadLink = useRef();
  const downloadFile = async () => {
    const response = await request(query, { transformResponse: (res) => res, responseType: 'blob' });
    const blob = new Blob([response.rawData]);
    const fileDownloadUrl = URL.createObjectURL(blob);

    downloadLink.current.href = fileDownloadUrl;
    // eslint-disable-next-line prefer-destructuring
    downloadLink.current.download = download || response.headers['content-disposition'].split(/; |=|"/)[3];
    downloadLink.current.click();
    URL.revokeObjectURL(fileDownloadUrl);
  };

  return (
    <>
      <IconButton
        icon={faDownload}
        variant="success"
        className={className}
        onClick={downloadFile}
        {...rest}
      >
        {label}
      </IconButton>

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/anchor-has-content */}
      <a className="d-none" ref={downloadLink} />
    </>
  );
}
