import React, { useMemo } from 'react';

import { Form } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import {
  Label, Field, SelectField, FormButtonsGroup, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { useRouter } from '@tripledotstudios/react-core';

import { MergeRoutes } from '@pages/routes';
import formReducer from './formReducer';
import Slots from './form/Slots';

export default function ProductCatalogsForm({ data, onSubmit, actionName }) {
  const { query } = useRouter();
  const priceTypeOptions = fromClassNameToOptions('Merge::ProductPriceTypes');
  const rewardTypes = useMemo(() => [
    {
      label: 'Coins',
      value: 'coins',
      priceTypes: priceTypeOptions.filter((option) => ['usd', 'gems'].includes(option.value)),
    },
    {
      label: 'Gems',
      value: 'gems',
      priceTypes: priceTypeOptions.filter((option) => ['usd'].includes(option.value)),
    },
  ], []);

  return (
    <Form initialValues={data} onSubmit={onSubmit} reducer={formReducer}>
      {({ values, dispatch }) => {
        const priceTypes = !values.rewardType ? [] : rewardTypes.find((price) => (
          price.value === values.rewardType
        )).priceTypes;

        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Currency Catalog`}>
              <FormButtonsGroup
                cancelButtonPath={MergeRoutes.ProductCatalogs.indexPath(query)}
              />
            </PageHeader>

            <Label text="Name">
              <Field type="text" name="name" />
            </Label>
            <Label text="Description">
              <Field type="text" name="description" />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <Label text="Product Type">
              <SelectField
                name="rewardType"
                options={rewardTypes}
                onChange={() => dispatch({ type: 'change_product_type' })}
                isDisabled={actionName !== 'Create'}
              />
            </Label>
            <Label text="Price Type">
              <SelectField
                name="priceType"
                options={priceTypes}
                isDisabled={actionName !== 'Create'}
                onChange={() => dispatch({ type: 'change_price_type' })}
              />
            </Label>

            {values.priceType && values.rewardType && <Slots values={values} />}
          </>
        );
      }}
    </Form>
  );
}
