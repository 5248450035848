import React from 'react';
import { Col } from 'react-bootstrap';

import { Filter, SelectFilter } from '@pages/common';

export default function ProductsFilter({ response }) {
  return (
    <Filter defaultComponents={['availabilityStateIn']}>
      {({ updateFilterField }) => (
        <Col md={6}>
          <SelectFilter
            name="productRewardTypeEndAny"
            label="Item Type = "
            options={response.rewardTypeOptions}
            updateFilterField={updateFilterField}
          />
        </Col>
      )}
    </Filter>
  );
}
