import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Orders configuration',
    actionName: 'create',
    redirectPath: Routes.Events.OrdersConfigurations.indexPath({ applicationId: query.applicationId }),
    setData,
  });

  const onSubmit = (values) => (
    Routes.Events.OrdersConfigurations
      .createRequest({ ...values, applicationId: query.applicationId })
      .then(responseHandler)
  );

  useEffect(() => {
    Routes.Events.OrdersConfigurations
      .newRequest(query).then((response) => setData(response.data));
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} actionName="Create" />;
}
