/* eslint-disable no-promise-executor-return */
import React, { useState } from 'react';
import {
  Row, Col, Button, Modal,
} from 'react-bootstrap';
import {
  Alert, ButtonToolbar, Spinner, useRouter,
} from '@tripledotstudios/react-core';

import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import FormTrails from '@controls/form/FormTrails';
import { AppUserRoutes } from '@pages/routes';
import {
  useQuery, useCurrentApplication, Form, useConfirm,
} from '@hooks';
import { DownloadButton, PageHeader, PageSection } from '@pages/common';
import {
  Field, FormButtonsGroup, Label, FileField,
} from '@controls/form';
import IconButton from '@controls/buttons';

const appTypesWithoutProgress = ['woodoku'];

const DataModal = ({ showModal, onHide, jsonData }) => {
  if (!showModal) return null;

  return (
    <Modal show={showModal} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre>{jsonData}</pre>
      </Modal.Body>
    </Modal>
  );
};

export default function Cloudsave() {
  const {
    response: data, refetch, isLoading, setResponse,
  } = useQuery(AppUserRoutes.cloudsaveRequest);
  const confirm = useConfirm();
  const [userId, setUserId] = useState(null);
  const [cloudsaveCopyErrorText, setCloudsaveCopyErrorText] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { query: { applicationId, id } } = useRouter();
  const showProgress = appTypesWithoutProgress.indexOf(useCurrentApplication().typeName) < 0;

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Cloudsave',
    actionName: 'update',
    setData: setResponse,
  });
  const onCloudsaveUpdate = (values) => {
    confirm.showConfirmation({ title: 'Cloudsave data for this user will be changed, do you want to continue?' })
      .then(() => AppUserRoutes.updateCloudsaveRequest({ ...values, applicationId, id }).then(responseHandler));
  };

  // eslint-disable-next-line no-promise-executor-return
  const fetchData = () => new Promise((resolve) => resolve(refetch()));

  if (isLoading) return <Spinner />;

  const resetUserId = () => {
    setCloudsaveCopyErrorText(null);

    return setUserId(null);
  };

  const onUserIdInputChange = (value) => {
    if (cloudsaveCopyErrorText) {
      setCloudsaveCopyErrorText(null);
    }

    if (value.length) return setUserId(value);

    return resetUserId();
  };

  const onCloudsaveCopy = () => {
    if (!userId) return null;

    return AppUserRoutes.copyCloudsaveRequest({ applicationId, id, userIdToCopy: userId })
      .then(({ data: copyCloudsaveRequestData }) => {
        if (copyCloudsaveRequestData.error) return setCloudsaveCopyErrorText(copyCloudsaveRequestData.error);

        return fetchData().then(resetUserId);
      });
  };

  return (
    <>
      {data
        ? (
          <Form enableReinitialize onSubmit={onCloudsaveUpdate} initialValues={data}>
            {({ values }) => (
              <>
                <PageHeader title="User" className="pt-1 mt-3">
                  <FormButtonsGroup hideCancel />
                </PageHeader>
                {showProgress && (
                  <>
                    <Label text="Balance"><Field name="balance" /></Label>
                    <Label text="Hard balance"><Field name="hardBalance" /></Label>
                    <Label text="Xp"><Field name="xp" /></Label>
                    <Label text="Level"><Field name="level" /></Label>
                  </>
                )}
                <Label text="Version">
                  {values.version}
                </Label>
                <Label text="Minimum Supported Version">
                  {values.minSupportedVersion}
                </Label>
                <Label text="Data">
                  <div className="d-flex">
                    <Button
                      className="me-2 align-self-start"
                      variant="outline-info"
                      onClick={() => setShowModal(true)}
                    >
                      Show
                    </Button>
                    <DownloadButton
                      className="me-2 align-self-start"
                      label="Download"
                      request={() => ({ rawData: values.jsonData })}
                      download={`cloudsave_${id}_v${values.version}_${new Date().toJSON().slice(0, 10)}.json`}
                    />
                    <FileField name="dataFile" accept="application/json" className="flex-grow-1" />
                  </div>
                </Label>
                <FormTrails data={values} />
              </>
            )}
          </Form>
        ) : <Alert variant="info">There is no Cloudsave for current User</Alert>}
      <PageSection title="Copy cloudsave details from another user">
        {cloudsaveCopyErrorText && (
          <Alert>{cloudsaveCopyErrorText}</Alert>
        )}
        <Row>
          <Col xs={4}>
            <Label text="App User ID" direction="vertical" className={cloudsaveCopyErrorText ? 'text-danger' : ''}>
              <input
                type="number"
                step={1}
                className={`form-control ${cloudsaveCopyErrorText && 'border border-danger'}`}
                value={userId || ''}
                onChange={({ target: { value } }) => onUserIdInputChange(value)}
              />
            </Label>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <ButtonToolbar>
              <IconButton.Save onClick={onCloudsaveCopy} disabled={!userId || id === userId} />
              <IconButton.Cancel onClick={resetUserId} disabled={!userId} />
            </ButtonToolbar>
          </Col>
        </Row>
        <DataModal showModal={showModal} onHide={() => setShowModal(false)} jsonData={data?.jsonData} />
      </PageSection>
    </>
  );
}
