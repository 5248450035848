import React from 'react';

import APP_SPECIFIC_CONFIGS from './configuration-forms';
import SharedExperimentScoped from '../shared/ExperimentScoped';

export default function ExperimentScoped({ ...props }) {
  return (
    <SharedExperimentScoped {...props} localePath="journeys.promoEvents" appSpecificConfigs={APP_SPECIFIC_CONFIGS} />
  );
}
