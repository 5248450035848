import {
  find, findIndex, last, reject, remove, without,
} from 'lodash';
import { produce } from 'immer';

import { dndRecords } from '@services/reorder';
import { addUuid } from '@services/recursivelyProcessObject';

const findByUuid = (collection, uuid) => find(collection, (e) => e._uuid === uuid);
const findUuidIndex = (collection, uuid) => findIndex(collection, (e) => e._uuid === uuid);

/* eslint-disable no-param-reassign, array-callback-return */
const worldSchemaReducer = (state, action, appData) => {
  const triggerTypes = appData.enums['Merge::PostcardChangesTriggerTypes'];

  switch (action.actionType) {
    case 'addExpert': {
      state.expertsAttributes.push(addUuid({
        internalId: '',
        itemLine: null,
      }));
      break;
    }
    case 'removeExpert': {
      const { _uuid } = action;

      const expertIndex = findUuidIndex(state.expertsAttributes, _uuid);
      state.expertsAttributes[expertIndex]._destroy = true;
      break;
    }
    case 'addPostcard': {
      state.postcardsAttributes.push(addUuid({
        id: null,
        internalId: '',
        position: Math.max(
          ...[...state.postcardsAttributes.filter((p) => !p._destroy)].map((p) => p.position),
        ) + 1,
        storyOrdersAttributes: [],
        storyOrdersOptions: [],
        postcardChangesAttributes: [],
        isEditable: true,
      }));
      break;
    }
    case 'removePostcard': {
      const { _uuid } = action;

      const postcardIndex = findUuidIndex(state.postcardsAttributes, _uuid);
      state.postcardsAttributes[postcardIndex]._destroy = true;
      for (let i = postcardIndex + 1; i < state.postcardsAttributes.length; i += 1) {
        state.postcardsAttributes[i].position -= 1;
      }
      break;
    }
    case 'addStoryOrder': {
      const { _uuid } = action;
      const postcard = findByUuid(state.postcardsAttributes, _uuid);
      const lastStoryOrder = last(postcard.storyOrdersAttributes);
      const newStoryOrder = addUuid({
        id: null,
        internalId: '',
        position: lastStoryOrder ? lastStoryOrder.position + 1 : 1,
        prerequisites: [],
        prerequisiteOptions: postcard.storyOrdersAttributes
          .filter((so) => !so._destroy)
          .map((so) => ({ value: so._uuid, label: so.internalId })),
      });
      postcard.storyOrdersAttributes.push(newStoryOrder);
      postcard.storyOrdersOptions.push({ value: newStoryOrder._uuid, label: 'unset' });
      break;
    }
    case 'removeStoryOrder': {
      const { postcardIndex, storyOrderIndex } = action;
      const postcard = state.postcardsAttributes[postcardIndex];
      const storyOrder = postcard.storyOrdersAttributes[storyOrderIndex];

      storyOrder._destroy = true;
      remove(postcard.storyOrdersOptions, (option) => option.value === storyOrder._uuid);
      for (let i = storyOrderIndex + 1; i < postcard.storyOrdersAttributes.length; i += 1) {
        const order = postcard.storyOrdersAttributes[i];
        if (!order._destroy) {
          order.position -= 1;
          order.prerequisiteOptions = reject(order.prerequisiteOptions, (o) => o.value === storyOrder._uuid);
          order.prerequisites = without(order.prerequisites, storyOrder._uuid);
        }
      }
      break;
    }
    case 'editStoryOrderInternalId': {
      const { postcardIndex, storyOrderIndex, value } = action;
      const postcard = state.postcardsAttributes[postcardIndex];
      const storyOrder = postcard.storyOrdersAttributes[storyOrderIndex];
      storyOrder.internalId = value;
      find(postcard.storyOrdersOptions, (option) => option.value === storyOrder._uuid).label = value;
      for (let i = storyOrderIndex + 1; i < postcard.storyOrdersAttributes.length; i += 1) {
        const prerequisiteOption = postcard.storyOrdersAttributes[i].prerequisiteOptions.find(
          (o) => o.value === storyOrder._uuid,
        );
        if (prerequisiteOption) prerequisiteOption.label = value;
      }
      break;
    }
    case 'addPostcardChange': {
      const { postcardIndex } = action;
      const postcard = state.postcardsAttributes[postcardIndex];
      const changes = postcard.postcardChangesAttributes.filter((p) => !p._destroy);
      postcard.postcardChangesAttributes.push(addUuid({
        id: null,
        triggerType: triggerTypes.ALL_ORDERS_COMPLETED,
        resultInternalId: null,
        triggerInternalId: null,
        storyOrders: [],
        position: changes.length > 0 ? Math.max(...changes.map((p) => p.position)) + 1 : 1,
      }));
      break;
    }
    case 'removePostcardChange': {
      const { postcardIndex, index } = action;
      const postcard = state.postcardsAttributes[postcardIndex];
      const postcardChange = postcard.postcardChangesAttributes[index];

      postcardChange._destroy = true;
      for (let i = index + 1; i < postcard.postcardChangesAttributes.length; i += 1) {
        const change = postcard.postcardChangesAttributes[i];
        if (!change._destroy) {
          change.position -= 1;
        }
      }
      break;
    }
    case 'reorderPostcardChanges': {
      dndRecords(state, action);
      break;
    }
    default: break;
  }
  return state;
};

export default produce(worldSchemaReducer);
