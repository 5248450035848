import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledFaButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background: none;
  outline: none !important;
  line-height: 0;
  height: fit-content;
 
  &:disabled {
    opacity: 0.6;
    &:hover {
      path {
        fill: inherit;
      }
    }
  }
`;

export const FaIcon = styled(FontAwesomeIcon)`
  margin-right: 2px;
  path {
    fill: ${({ color }) => color};
  }
`;

export default function FaButton({ disabled, onClick, ...rest }) {
  return (
    <StyledFaButton
      disabled={disabled}
      onClick={(e) => !disabled && onClick(e)}
      {...rest}
    />
  );
}
