/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { get } from 'lodash';

import { FormGroup, useFormContext, useFormGroup } from '@hooks';
import { Label, Field } from '@controls/form';

const LastInput = ({ field, form, ...props }) => (
  <input {...props} {...field} value="1" />
);

// Last input added aftifitially and always equals to 1.
// The form doesn't send it to backend.
// The system appends 1 as the last element of trophies distribution array on API decorator level.
export default function SolitaireDailyChallengeTrophiesDistribution({ disabled }) {
  const { values } = useFormContext();
  const { generateName } = useFormGroup();

  const trophiesAmount = get(values, generateName('dailyChallengeTrophiesAmount'));

  return (
    <FormGroup name="dailyChallengeTrophiesDistribution">
      {Array.from({ length: trophiesAmount - 1 }, (_, index) => (
        <>
          <Label direction="vertical" text={`Trophy ${index + 1}`}>
            <Field name={index} disabled={disabled} type="number" />
          </Label>
        </>
      ))}

      <Label direction="vertical" text={`Trophy ${trophiesAmount}`}>
        <Field disabled name={trophiesAmount - 1} component={LastInput} />
      </Label>
    </FormGroup>
  );
}
