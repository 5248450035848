import React from 'react';
import styled from 'styled-components';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import {
  Badge,
  ActivityRangeColumn,
  InLiveColumn,
  InUseColumn,
  RulesList,
  LabelsColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { AssetsRoutes } from '@pages/routes';
import Tooltip from '@controls/Tooltip';
import OverlayTooltip from '@controls/OverlayTooltip';
import IconButton from '@controls/buttons';
import CopyToClipboardButton from '@controls/CopyToClipboardButton';

const UidWrapper = styled.div`
  max-width: 30em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .uid-text {
    margin-left: 0.7em;
  }
`;

const assetKeysStatuses = APP_DATA.enums['AssetsKeys::Statuses'];

export default function ColumnsFactory({ currentApplication, handleKeyDelete, setDuplicatedRecord }) {
  return [
    { Header: 'ID', accessor: 'id', style: { width: '4em' } },
    {
      Header: 'UID',
      Cell: ({ row: { original: { uid } } }) => (
        <UidWrapper>
          <CopyToClipboardButton text={uid} />

          <OverlayTooltip text={uid} placement="bottom-start">
            <span className="uid-text">
              {uid}
            </span>
          </OverlayTooltip>
        </UidWrapper>
      ),
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({ row: { original: { status, statusHumanize } } }) => {
        const variant = status === assetKeysStatuses.ACTIVE ? 'success' : 'secondary';

        return <Badge bg={variant}>{statusHumanize}</Badge>;
      },
    },
    LabelsColumn,
    InLiveColumn,
    {
      ...ActivityRangeColumn,
      style: { width: '14em' },
      Header: (
        <Tooltip text="Dates when asset bundle will be automatically activated and then deactivated">
          Active at
        </Tooltip>
      ),
    },
    InUseColumn,
    {
      id: 'Bundle rules',
      Header: 'Rules',
      Cell: ({ row }) => {
        const { bundles } = row.original;
        return bundles.map((bundle) => (
          <div key={bundle.id} className="mb-0">
            <b>{`${bundle.name}: `}</b>
            {bundle.ruleSectionsAttributes.length > 0 && <RulesList rules={bundle.ruleSectionsAttributes} />}
          </div>
        ));
      },
    },
    {
      Header: 'Actions',
      style: { width: '9em' },
      Cell: ({ row: { original } }) => {
        const { id, uid, inUse } = original;
        const isUsed = isInUse(inUse);

        return (
          <ButtonToolbar>
            <IconButton.Edit to={AssetsRoutes.Keys.editPath({ id, applicationId: currentApplication.id })} minimized />
            <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, uid })} minimized />
            <IconButton.Delete
              onClick={handleKeyDelete(id, uid)}
              disabled={isUsed}
              title={isUsed ? 'Disabled as the entity is used' : null}
              minimized
            />
          </ButtonToolbar>
        );
      },
    },
  ];
}
