import React from 'react';
import { PageTitle } from '@pages/common';

// Events
import EventsIndex from '@pages/journeys/events/Index';
import EventsNew from '@pages/journeys/events/New';
import EventsEdit from '@pages/journeys/events/Edit';

// ActivationEvents
import ActivationEventsIndex from '@pages/journeys/activation-events/Index';
import ActivationEventsNew from '@pages/journeys/activation-events/New';
import ActivationEventsEdit from '@pages/journeys/activation-events/Edit';

// PromoEvents
import PromoEventsIndex from '@pages/journeys/promo-events/Index';
import PromoEventsNew from '@pages/journeys/promo-events/New';
import PromoEventsEdit from '@pages/journeys/promo-events/Edit';

// End Of Journey Content Events
import EndOfJourneyEventsIndex from '@pages/journeys/end-of-journey-events/Index';
import EndOfJourneyEventsNew from '@pages/journeys/end-of-journey-events/New';
import EndOfJourneyEventsEdit from '@pages/journeys/end-of-journey-events/Edit';

// Level Banks
import LevelBanksIndex from '@pages/journeys/level-banks/Index';
import LevelBanksNew from '@pages/journeys/level-banks/New';
import LevelBanksEdit from '@pages/journeys/level-banks/Edit';

import { JourneyRoutes as Routes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function journeys() {
  return [
    //
    // Events
    //
    <Route
      key={Routes.Events.indexRawPath}
      path={Routes.Events.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle translationPath="journeys.events.pluralName" />
          <EventsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Events.newRawPath}
      path={Routes.Events.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="New" translationPath="journeys.events.name" />
          <EventsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Events.editRawPath}
      path={Routes.Events.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="Edit" translationPath="journeys.events.name" />
          <EventsEdit />
        </RouteContainer>
      )}
    />,

    //
    // Activation Events
    //
    <Route
      key={Routes.ActivationEvents.indexRawPath}
      path={Routes.ActivationEvents.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle translationPath="journeys.activationEvents.pluralName" />
          <ActivationEventsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.ActivationEvents.newRawPath}
      path={Routes.ActivationEvents.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="New" translationPath="journeys.activationEvents.name" />
          <ActivationEventsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.ActivationEvents.editRawPath}
      path={Routes.ActivationEvents.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="Edit" translationPath="journeys.activationEvents.name" />
          <ActivationEventsEdit />
        </RouteContainer>
      )}
    />,

    //
    // Promo Events
    //
    <Route
      key={Routes.PromoEvents.indexRawPath}
      path={Routes.PromoEvents.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle translationPath="journeys.promoEvents.pluralName" />
          <PromoEventsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.PromoEvents.newRawPath}
      path={Routes.PromoEvents.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="New" translationPath="journeys.promoEvents.name" />
          <PromoEventsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.PromoEvents.editRawPath}
      path={Routes.PromoEvents.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="Edit" translationPath="journeys.promoEvents.name" />
          <PromoEventsEdit />
        </RouteContainer>
      )}
    />,

    //
    // End Of Journey Events
    //
    <Route
      key={Routes.EndOfJourneyEvents.indexRawPath}
      path={Routes.EndOfJourneyEvents.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle translationPath="journeys.endOfJourneyEvents.pluralName" />
          <EndOfJourneyEventsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.EndOfJourneyEvents.newRawPath}
      path={Routes.EndOfJourneyEvents.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="New" translationPath="journeys.endOfJourneyEvents.name" />
          <EndOfJourneyEventsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.EndOfJourneyEvents.editRawPath}
      path={Routes.EndOfJourneyEvents.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="Edit" translationPath="journeys.endOfJourneyEvents.name" />
          <EndOfJourneyEventsEdit />
        </RouteContainer>
      )}
    />,

    //
    // Level Banks
    //
    <Route
      key={Routes.LevelBanks.indexRawPath}
      path={Routes.LevelBanks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle translationPath="journeys.levelBanks.pluralName" />
          <LevelBanksIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.LevelBanks.newRawPath}
      path={Routes.LevelBanks.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="New" translationPath="journeys.levelBanks.name" />
          <LevelBanksNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.LevelBanks.editRawPath}
      path={Routes.LevelBanks.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="Edit" translationPath="journeys.levelBanks.name" />
          <LevelBanksEdit />
        </RouteContainer>
      )}
    />,
  ];
}
