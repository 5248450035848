import React from 'react';

import { get } from 'lodash';
import { useFormContext, FormGroup } from '@hooks';
import { Label, Field } from '@controls/form';
import EntityBanks from './EntityBanks';

export default function ConfigurationForm({
  values, defaultVariant, variantIndex, isArchived,
}) {
  const { dispatch } = useFormContext();

  const onBankAdd = (id) => dispatch({
    actionType: 'addBankToAdventure', id, variantIndex,
  });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeBankFromAdventure', id, variantIndex,
  });

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Number of days">
        <Field name="numberOfDays" type="number" />
      </Label>
      <Label text="Starting cohort day">
        <Field name="startingCohortDay" type="number" />
      </Label>
      <Label text="Adventure widget asset ID">
        <Field name="widgetAssetId" />
      </Label>

      <EntityBanks
        variantAttributes={get(values, variantAttributesKey, values)}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
        disabled={isArchived}
      />
    </FormGroup>
  );
}
