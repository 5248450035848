import React from 'react';

import { MergeRoutes } from '@pages/routes';
import Form from './Form';

export default function NewModal({ recordType, ...props }) {
  const { newRequest, createRequest } = MergeRoutes.Contacts;

  return (
    <Form actionName="creation" entityName="Contact" getForm={newRequest} submitForm={createRequest} {...props} />
  );
}
