import React, { useMemo } from 'react';
import { flattenDeep, sumBy } from 'lodash';
import { Row, Col } from 'react-bootstrap';

import APP_DATA from '@services/appData';

import { persistedCollection } from '@services/utils';

import { PageSection } from '@pages/common';

import { useCurrentApplication } from '@hooks';
import { AutoCollapse } from '@pages/common/PartialCollapse';

const { enums } = APP_DATA;

const tileLevelVisualsEnum = enums['Tile::Level::VisualizationTypes'];

const countedRewards = (rewards, rewardItems) => {
  if (!rewardItems) return {};

  const reducedRewards = rewards.reduce((memo, reward) => {
    // eslint-disable-next-line no-param-reassign
    memo[reward.itemId] = (memo[reward.itemId] || 0) + Number(reward.quantity);
    return memo;
  }, {});

  return Object.entries(reducedRewards).sort((left, right) => right[1] - left[1]).map(([rewardId, count]) => {
    if (rewardId === 'undefined' || !count) return null;
    const resolvedReward = rewardItems.find(({ value }) => Number(value) === Number(rewardId));
    if (!resolvedReward) return null;

    const { label } = resolvedReward;

    return `${label} (${count})`;
  }).filter((text) => text);
};

const CountedRewardsList = ({ rewards, rewardItems }) => {
  const humanizedRewards = countedRewards(rewards, rewardItems);
  if (!humanizedRewards.length) return <strong>None</strong>;

  return (
    <ul>
      {React.Children.toArray(humanizedRewards.map((countedRewardText) => (
        <li>
          {countedRewardText}
        </li>
      )))}
    </ul>
  );
};

export default function ChaptersSummary({
  values, rewardItems, levelLayouts, chapterFieldsData = {},
}) {
  const { applicationKey } = useCurrentApplication();

  const isTileGame = applicationKey === 'tile';
  const { assetsKeys = [] } = chapterFieldsData;

  const persistedLevels = persistedCollection(
    flattenDeep(values.map(({ levelsAttributes }) => levelsAttributes || [])),
  );

  const levelLayoutTypes = useMemo(() => (
    isTileGame && persistedLevels.reduce((memo, level) => {
      const levelLayout = levelLayouts.find(({ contentHash }) => contentHash === level.contentHash);
      if (levelLayout) {
        // eslint-disable-next-line no-param-reassign
        memo[levelLayout.tileTypeHumanize] = (memo[levelLayout.tileTypeHumanize] || 0) + 1;
      }

      return memo;
    }, {})
  ), [levelLayouts.length, persistedLevels.length]);

  const usedAssetKeys = useMemo(() => (
    isTileGame && values.reduce((memo, { configAttributes: { uiThemeAssetBundleKeyId } }) => {
      const assetKey = assetsKeys.find(({ id }) => id === uiThemeAssetBundleKeyId);
      if (assetKey) {
        // eslint-disable-next-line no-param-reassign
        memo[assetKey.uid] = (memo[assetKey.uid] || 0) + 1;
      }

      return memo;
    }, {})
  ), [sumBy(values, 'configAttributes.uiThemeAssetBundleKeyId'), assetsKeys.length]);

  const chapterRewards = persistedCollection(
    flattenDeep(values.map(({ rewardAffiliationsAttributes }) => rewardAffiliationsAttributes)),
  );
  const levelRewards = persistedCollection(
    flattenDeep(persistedLevels.map(({ rewardAffiliationsAttributes }) => rewardAffiliationsAttributes)),
  );

  const hardLevelsCount = persistedLevels.filter(({ visualizationType }) => (
    visualizationType === tileLevelVisualsEnum.HARD),
  ).length;

  return (
    <div className="mb-4">
      <PageSection title="Summary" size="md" />
      <Row className="mt-4">
        {!isTileGame && (
          <Col xs={3}>
            <h4>Overall rewards</h4>
            <Row>
              <Col xs={6}><strong>Rewards for Chapters:</strong></Col>
              <Col xs={6}>
                <CountedRewardsList rewards={chapterRewards} rewardItems={rewardItems} />
              </Col>
            </Row>
            <Row>
              <Col xs={6}><strong>Rewards for Levels:</strong></Col>
              <Col xs={6}>
                <CountedRewardsList rewards={levelRewards} rewardItems={rewardItems} />
              </Col>
            </Row>
          </Col>
        )}
        <Col xs={3}>
          <div>
            <strong>Number of chapters: </strong>
            {values.length}
          </div>
          {isTileGame && (
            <div>
              <strong>Number of hard levels: </strong>
              {hardLevelsCount || <strong>None</strong>}
            </div>
          )}
        </Col>
        {isTileGame && (
          <Col xs={3}>
            <div>
              <strong>Number of levels for each Layout Type: </strong>
              {React.Children.toArray(Object.entries(levelLayoutTypes).map(([layoutType, levelsCount]) => (
                <div>
                  <strong>{`- ${layoutType}: `}</strong>
                  {levelsCount}
                </div>
              )))}
            </div>
          </Col>
        )}
        {isTileGame && (
          <Col xs={3}>
            <strong>Theme bundles used:</strong>
            <AutoCollapse minHeight={110}>
              {React.Children.toArray(Object.entries(usedAssetKeys).map(([assetKey, levelsCount]) => (
                <div>
                  <strong>{`- ${assetKey}: `}</strong>
                  {levelsCount}
                </div>
              )))}
            </AutoCollapse>
          </Col>
        )}
      </Row>
    </div>
  );
}
