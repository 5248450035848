import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { SaveButton, CancelButton } from './buttons';

export default function FormButtonsGroup({
  cancelButtonPath, hideCancel, disableSubmit, children,
}) {
  return (
    <ButtonToolbar>
      {children}
      {!hideCancel && <CancelButton to={cancelButtonPath} />}
      <SaveButton disable={disableSubmit} />
    </ButtonToolbar>
  );
}

FormButtonsGroup.Divider = ButtonToolbar.Divider;
