import React, { useMemo } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader, Pagination, ReactTable, TIMESTAMP_COLUMNS, InUseColumn,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { isInUse } from '@pages/common/InUse';
import { SpiderSolitaireRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

const columnsFactory = ({ applicationId, onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, inUse } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={SpiderSolitaireRoutes.PlayerLevelSets.editPath({ id, applicationId })} minimized />
        <IconButton.Delete onClick={onDelete(id, name)} disabled={isInUse(inUse)} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function PlayerLevelsSetsIndex() {
  const { response, refetch } = useQuery(SpiderSolitaireRoutes.PlayerLevelSets.indexRequest, { includeInUse: true });
  const { query: { applicationId } } = useRouter();

  const confirm = useConfirm();

  const removalResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Player Levels Sets',
    actionName: 'removal',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Player Levels Sets '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => SpiderSolitaireRoutes.PlayerLevelSets.deleteRequest({ id, applicationId })
        .then(removalResponseHandler));
  };

  const columns = useMemo(() => columnsFactory({ applicationId, onDelete }), []);

  return response && (
    <>
      <PageHeader title="Player Levels Sets">
        <IconButton.New to={SpiderSolitaireRoutes.PlayerLevelSets.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </>
  );
}
