import React from 'react';

import { Field, Label, localizedLabelFactory } from '@controls/form';
import { TrackableField } from '@pages/common/TrackableFields';
import { FormGroup } from '@hooks';

import Matrix from './BasedOnUsageMatrix';

const LocalizedLabel = localizedLabelFactory('tile.difficultySchema.basedOnUsageConfig');

export default function Fields({ isDirty, disabled }) {
  return (
    <FormGroup name="basedOnUsageConfigAttributes">
      <Label text="Number of previous levels used in calculation">
        <Field
          name="prevLevelsAmount"
          type="number"
          disabled={disabled}
        />
      </Label>
      <LocalizedLabel name="trueWinRateBucketSize">
        <TrackableField
          name="trueWinRateBucketSize"
          type="number"
          dirty={isDirty('trueWinRateBucketSize')}
          disabled={disabled}
        />
      </LocalizedLabel>
      <Label text="Weight of Retry Usage">
        <Field
          name="retryWeight"
          type="number"
          disabled={disabled}
        />
      </Label>
      <Label text="Weight of Undo Usage">
        <Field
          name="powerupUndoWeight"
          type="number"
          disabled={disabled}
        />
      </Label>
      <Label text="Weight of Shuffle Usage">
        <Field
          name="powerupShuffleWeight"
          type="number"
          disabled={disabled}
        />
      </Label>
      <Label text="Weight of Magic Match Usage">
        <Field
          name="powerupMagicmatchWeight"
          type="number"
          disabled={disabled}
        />
      </Label>
      <Label text="Weight of Revive Usage">
        <Field
          name="reviveWeight"
          type="number"
          disabled={disabled}
        />
      </Label>
      <LocalizedLabel name="maxLevelUsagePrev">
        <Field
          name="maxLevelUsagePrev"
          type="number"
          disabled={disabled}
        />
      </LocalizedLabel>
      <LocalizedLabel name="maxLevelUsageCurrent">
        <Field
          name="maxLevelUsageCurrent"
          type="number"
          disabled={disabled}
        />
      </LocalizedLabel>
      <Label text="N Types min">
        <Field
          name="typesMin"
          type="number"
          disabled={disabled}
        />
      </Label>
      <Label text="N Types max">
        <Field
          name="typesMax"
          type="number"
          disabled={disabled}
        />
      </Label>
      <Matrix disabled={disabled} />
    </FormGroup>
  );
}
