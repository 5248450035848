import { dark } from '@tripledotstudios/react-core';

export default Object.freeze({
  ...dark,
  colors: {
    home: '#d1a45f',
  },
  badges: {
    danger: {
      color: dark.variants.danger,
    },
    warning: {
      backgroundColor: dark.variants.warning,
      color: dark.variants.dark,
    },
    primary: {
      color: dark.variants.primary,
    },
    secondary: {
      color: dark.variants.secondary,
    },
    success: {
      color: dark.variants.success,
    },
  },
  woodoku: {
    board: {
      cell: {
        normal: '#D5AD6C60',
        selected: '#7A250060',
        invalid: '#f3767260',
        hard: '#330F0060',
      },
    },
  },
});
