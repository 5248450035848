import React from 'react';

import { Label, CheckboxField } from '@controls/form';

export default function JourneyKeyMetricsServingSettings() {
  return (
    <Label
      text="Enable redirection to Journey Key Metrics"
      tooltipText="When toggle in ON, system displays in Journey list view
        additional action button which redirects to Looker Journey Key Metrics"
    >
      <CheckboxField name="enableRedirectionToJourneyKeyMetrics" />
    </Label>
  );
}
