import React, { useState, useMemo } from 'react';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import {
  PageHeader,
  Pagination,
  RulesList,
  ReactTable,
  InLiveBadge,
  InLiveColumnHeader,
  DuplicationModalWithPriority,
  TIMESTAMP_COLUMNS,
  Filter,
  PriorityColumn,
} from '@pages/common';
import { Card } from 'react-bootstrap';
import { GAME_CORE_LABEL } from '@pages/applications/constants';

import { useQuery, useConfirm } from '@hooks';

import { getManifestSets, duplicateManifestSet, deleteManifestSet } from '@requests/my-pet-cafe/manifestSets';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import IconButton from '@controls/buttons';

import LevelLimitForm from './LevelLimitForm';

const columnsFactory = ({ setDuplicatedRecord, onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  PriorityColumn,
  {
    id: 'availability_header',
    Header: () => <InLiveColumnHeader />,
    Cell: ({ row }) => <InLiveBadge inLive={row.original.availabilityStateLive} />,
  },
  {
    Header: 'Type',
    accessor: 'manifestSetTypeId',
    Cell: ({ row }) => (row.original.manifestSetType ? row.original.manifestSetType : GAME_CORE_LABEL),
  },
  {
    Header: 'Levels count',
    disableSortBy: true,
    Cell: ({ row }) => (
      row.original.manifestSetType ? (
        <p className="mb-1">
          <span>Levels: </span>
          <b>{row.original.typedLevelsCount}</b>
        </p>
      ) : (
        <>
          <p className="mb-1">
            <span>Levels: </span>
            <b>{row.original.levelsCount}</b>
          </p>
          <p>
            <span>Tutorial Levels: </span>
            <b>{row.original.tutorialLevelsCount}</b>
          </p>
        </>
      )
    ),
  },
  {
    Header: 'Rules',
    Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
  },
  { Header: 'Manifests updated at', accessor: 'manifestsUpdatedAt' },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row }) => {
      const { id, name } = row.original;

      return (
        <ButtonToolbar>
          <IconButton.Edit to={`${id}/edit`} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
          <IconButton.Delete onClick={onDelete(id, name)} minimized />
        </ButtonToolbar>
      );
    },
  },
];

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(getManifestSets);
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Level manifest set',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Level manifest set '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => deleteManifestSet({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({
    setDuplicatedRecord, onDelete,
  }), []);

  return response ? (
    <div>
      <PageHeader
        title="Level Manifests"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
      >
        <IconButton.New to="new" />
      </PageHeader>

      <Card className="mb-3">
        <Card.Body>
          <IconButton to="base" icon={faSuitcase}>Base Config</IconButton>

          <div className="float-end">
            <LevelLimitForm levelLimit={response.levelLimit} />
          </div>
        </Card.Body>
      </Card>

      <ReactTable
        columns={columns}
        defaultSort={{ id: 'priority', desc: true }}
        data={response ? response.items : []}
      />
      <DuplicationModalWithPriority
        record={duplicatedRecord}
        entityName="Manifest Set"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => duplicateManifestSet({ ...values, applicationId })}
        entitiesByPriority={response.entitiesByPriority || {}}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  ) : '';
}
