import React from 'react';

import { WoodokuRoutes } from '@pages/routes';

import CreateUpdateConfig from './CreateUpdateConfig';

export default function New({ configTypes, group }) {
  return (
    <CreateUpdateConfig
      fetchFunction={WoodokuRoutes.XpLevelConfigs.newRequest}
      saveFunction={WoodokuRoutes.XpLevelConfigs.createRequest}
      actionName="creation"
      configTypes={configTypes}
      group={group}
      disableTypeSelect={false}
    />
  );
}
