import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Table, Button } from 'react-bootstrap';

import { useConfirm, useQuery } from '@hooks';
import { AppUserRoutes } from '@pages/routes';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { PageSection } from '@pages/common';

import ManualReward from './rewards/ManualReward';

const RewardsTable = ({ rewards, onDelete }) => (
  <>
    <PageSection title="Rewards" />

    <Table bordered striped responsive>
      <thead>
        <tr>
          <th>ID</th>
          <th>Rewards</th>
          <th>Created at (UTC)</th>
          <th>Collected at (UTC)</th>
          <th>Reason</th>
          <th>Source context</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {rewards.map((reward) => (
          <tr key={reward.id}>
            <td>{reward.id}</td>
            <td>
              <pre>
                {reward.rewardData}
              </pre>
            </td>
            <td>{reward.createdAt}</td>
            <td>{reward.collectedAt}</td>
            <td>
              {reward.manual ? 'Manual' : 'Purchase'}
            </td>
            <td>
              {reward.transactionId > 0 && <div>{`Transaction ID: ${reward.transactionId}`}</div>}
              {reward.receiptId && <div>{`Receipt ID: ${reward.receiptId}`}</div>}
              {reward.productId && <div>{`Product ID: ${reward.productId}`}</div>}
              {reward.storeProductStoreId && <div>{`Store Product ID: ${reward.storeProductStoreId}`}</div>}
              {reward.authorName && <div>{`Author ID: ${reward.authorName}`}</div>}
            </td>
            <td>{reward.status}</td>
            <td>
              {reward.manual && reward.status === 'Uncollected' && (
                <Button
                  className="mt-0 mb-0"
                  variant="danger"
                  onClick={onDelete(reward.id)}
                  size="sm"
                >
                  Discard
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </>
);

export default function Rewards() {
  const confirm = useConfirm();
  const { query: { id: appUserId, applicationId } } = useRouter();
  const { response, refetch } = useQuery(AppUserRoutes.UserRewards.indexRequest, { appUserId, applicationId });

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'User Rewards',
    actionName: 'discard',
    refetch,
  });

  const deleteReward = (id) => AppUserRoutes.UserRewards.deleteRequest({ id, appUserId, applicationId });
  const onDelete = (id) => () => {
    confirm
      .showConfirmation({ title: 'Reward will be discarded and not delivered to player. Continue?' })
      .then(() => deleteReward(id).then(responseHandler));
  };

  return response ? (
    <>
      <ManualReward />
      <RewardsTable rewards={response.items} onDelete={onDelete} />
    </>
  ) : '';
}
