import React from 'react';

import { Form, useCurrentApplication } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';

import {
  Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup, localizedLabelFactory,
} from '@controls/form';
import { DuplicateButton, PageHeader } from '@pages/common';
import { FlexiblePopupRoutes as Routes } from '@pages/routes';
import { isInUse } from '@pages/common/InUse';

import {
  MyPetCafeActionsFormFields, SolitaireActionsFormFields, SolitaireScapesActionsFormFields,
} from './application-specific';

const ApplicationSpecificFormFields = ({ applicationName, actionType, LabelComponent }) => {
  if (applicationName === 'MyPetCafe') {
    return <MyPetCafeActionsFormFields actionType={actionType} LabelComponent={LabelComponent} />;
  }
  if (applicationName === 'Solitaire') {
    return <SolitaireActionsFormFields actionType={actionType} LabelComponent={LabelComponent} />;
  }
  if (applicationName === 'SolitaireScapes') {
    return <SolitaireScapesActionsFormFields actionType={actionType} LabelComponent={LabelComponent} />;
  }
  return '';
};

const Label = localizedLabelFactory('flexiblePopups.action');

export default function ActionsForm({ data, title, onSubmit }) {
  const {
    currentApplication: { id: applicationId, name: applicationName },
  } = useCurrentApplication();
  const actionTypes = fromClassNameToOptions(`${applicationName}::FlexiblePopups::ActionTypes`);

  const { inUse } = data;

  return (
    <Form
      initialValues={{ applicationId, ...data }}
      onSubmit={onSubmit}
    >
      {({ values: { actionType } }) => (
        <>
          <PageHeader title={title}>
            <FormButtonsGroup
              cancelButtonPath={Routes.Actions.indexPath({ applicationId })}
              disableSubmit={isInUse(inUse)}
            >
              <DuplicateButton routes={Routes.Actions} data={data} entityName="Flexible Popup Action" />
            </FormButtonsGroup>
          </PageHeader>
          <Label name="name">
            <Field name="name" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <Label name="actionType">
            <SelectField name="actionType" options={actionTypes} />
          </Label>

          <ApplicationSpecificFormFields
            actionType={actionType}
            applicationName={applicationName}
            LabelComponent={Label}
          />
        </>
      )}
    </Form>
  );
}
