import React from 'react';

import { get } from 'lodash';
import { Row, Col, Card } from 'react-bootstrap';

import styled from 'styled-components';

import APP_DATA from '@services/appData';
import {
  FormGroup,
  useFormGroup,
  useFormContext,
  useCurrentApplication,
} from '@hooks';
import SelectField from '@controls/form/SelectField';
import typesForSelectByTags from '@services/rules/typesForSelectByTags';
import getAllOperation from '@services/rules/getAllOperations';
import getOperationsForSelect from '@services/rules/getOperationsForSelect';
import { ColoredCard } from '@components/shared';

import { RULES_LIST, DEFAULT_TAG } from '../list';
import DeleteButton from '../DeleteButton';

import colors from '../colors';

const RuleComponentCol = styled(Col)`
  width: 100px;
`;

const DeleteCol = styled.div`
  width: 3.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const { rawEnums: { RuleOperations: RawRuleOperations } } = APP_DATA;

export default function BaseRule({
  newRecord,
  type,
  nestingLevel,
  componentIndex,
  hideControls,
  excludeTags,
  disabled,
  _destroy = false,
}) {
  const { applicationKey } = useCurrentApplication();
  const { dispatch, values } = useFormContext();
  const { generateName } = useFormGroup();
  const rulesPath = generateName('rulesAttributes');

  const typeToComponentMap = Object.fromEntries(RULES_LIST.map((config) => [config.type, config]));

  const findComponentConfigByType = (ruleType) => typeToComponentMap[ruleType];
  const { operations, options, component: Component } = findComponentConfigByType(type);

  const allOperations = getAllOperation(RawRuleOperations);
  const findOperation = (operationValue) => allOperations.find(({ value }) => value === operationValue);
  const currentOperation = findOperation(get(values, generateName(`rulesAttributes.${componentIndex}.operation`)));

  const currentRule = get(values, generateName(`rulesAttributes.${componentIndex}`));

  const onTypeChange = (rule) => {
    const { operations: newOperations, options: newOptions } = findComponentConfigByType(rule.value);

    if (!newOperations && !newOptions) return;

    const targetOperation = newOperations ? newOperations[0] : newOptions[0].value;

    dispatch({
      actionType: 'resetOperation',
      rulesPath,
      index: componentIndex,
      operation: targetOperation,
    });
  };

  const onDelete = () => {
    dispatch({
      actionType: 'removeRule',
      rulesPath,
      index: componentIndex,
    });
  };

  return (
    _destroy !== true ? (
      <FormGroup name={`rulesAttributes.${componentIndex}`}>
        <ColoredCard color={colors[nestingLevel + 1]} className="mb-3">
          <Card.Body>
            <Row>
              <Col xs={3} className="pe-0">
                <SelectField
                  name="type"
                  options={typesForSelectByTags(RULES_LIST, [DEFAULT_TAG, applicationKey], excludeTags)}
                  onChange={onTypeChange}
                  hasPrepopulatedOption
                  isDisabled={!newRecord || disabled || currentRule?.disableTypeChange}
                />
              </Col>
              <Col xs={3} className="pe-0">
                {(operations || options) && (
                  <SelectField
                    name="operation"
                    options={options || getOperationsForSelect(allOperations, operations)}
                    hasPrepopulatedOption
                    isDisabled={disabled}
                  />
                )}
              </Col>
              <RuleComponentCol className={`pe-${hideControls ? 3 : 0}`}>
                {currentOperation && !currentOperation.withoutArguments && (
                  <Component
                    componentIndex={componentIndex}
                    disabled={disabled}
                  />
                )}
              </RuleComponentCol>
              {!hideControls && (
                <DeleteCol className="border-start">
                  <DeleteButton
                    nestingLevel={nestingLevel}
                    onClick={onDelete}
                    disabled={disabled}
                    data-testid={`remove_rules_button_level_${nestingLevel}`}
                  />
                </DeleteCol>
              )}
            </Row>
          </Card.Body>
        </ColoredCard>
      </FormGroup>
    ) : null
  );
}
