import React from 'react';

import { localizedLabelFactory, Field } from '@controls/form';

const LocalizedLabel = localizedLabelFactory(
  'ads.configuration.backToBackFrequencyRestrictionsConfigurationAttributes',
);

const ConfigurationFields = ({ disabled }) => (
  <LocalizedLabel name="value" labelSize={5} fieldSize={7}>
    <Field type="number" name="value" disabled={disabled} />
  </LocalizedLabel>
);

export default ConfigurationFields;
