import React from 'react';
import styled from 'styled-components';
import { memoize } from 'lodash';

import { Field } from '@controls/form';
import {
  fieldGroupBuilder, arrayFieldBuilder, fieldArrayBuilder, fieldBuilder, warningBuilder,
} from '@services/fields-factory';

const urlColor = ({ theme, value }) => {
  if (!value) return 'inherited';
  if (value.match(/^res:\/\//)) return theme.variants.secondary;
  if (value.match(/^https?:\/\//)) return theme.variants.primary;

  return 'inherited';
};

const ColoredUrl = styled.input`
  &:focus {
    color: ${urlColor};
  }
  color: ${urlColor};
`;

const coloredFieldFn = memoize(({ name, disabled }) => ([
  () => (
    <Field name={name} disabled={disabled}>
      {({ field }) => (
        <ColoredUrl type="text" placeholder="url" {...field} disabled={disabled} className="form-control" />
      )}
    </Field>
  ),
  {},
]), JSON.stringify);

const offlineAdsFields = memoize(() => fieldGroupBuilder({
  name: 'offlineAds',
  fieldsConfiguration: {
    ...warningBuilder({ name: 'warning' }),
    ...fieldArrayBuilder({
      name: 'ads',
      reorderable: true,
      handleOnly: true,
      showPosition: true,
      fieldsConfiguration: [{
        name: 'adUrl',
        as: 'component',
        componentFn: coloredFieldFn,
      },
      {
        name: 'redirectUrl',
        as: 'component',
        componentFn: coloredFieldFn,
      }],
    }),
    ...arrayFieldBuilder({ name: 'order' }),
    ...fieldBuilder({ name: 'sessionThreshold', type: 'number', fieldOptions: { step: '0.1' } }),
  },
}));

export const offlineAdsFieldNames = ['offlineAds'];

export default offlineAdsFields;
