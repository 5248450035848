import React from 'react';

import { PageSection } from '@pages/common';
import { FormGroup } from '@hooks';
import IconButton from '@controls/buttons';
import { Label, AssetsKeySelectField, ServerError } from '@controls/form';

const Chapter = ({ chapter, onRemove, index }) => (
  <PageSection
    title={`Chapter ${chapter.position}`}
    actions={<IconButton.Delete onClick={onRemove} minimized />}
  >
    <FormGroup name={index}>
      <Label text="UI theme bundle" direction="vertical">
        <AssetsKeySelectField name="assetsKeyId" />
      </Label>
    </FormGroup>
  </PageSection>
);

export default function Chapters({ values, dispatch }) {
  return (
    <>
      <ServerError name="chaptersAttributes.list" />

      {values.chaptersAttributes.map((chapter, index) => {
        if (chapter._destroy) return null;

        return (
          <FormGroup name="chaptersAttributes" key={chapter._uuid}>
            <Chapter
              chapter={chapter}
              onRemove={() => dispatch({ type: 'removeChapter', uuid: chapter._uuid })}
              index={index}
            />
          </FormGroup>
        );
      })}

      <IconButton.New className="float-end" onClick={() => dispatch({ type: 'addChapter' })}>
        Add Chapter
      </IconButton.New>
    </>
  );
}
