import React from 'react';

import { formResponseHandler } from '@requests/responseHandlers';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';
import { useQuery } from '@hooks';

import Form from './Form';

export default function Edit() {
  const { response, setResponse } = useQuery(Routes.MagicPots.editRequest);

  const responseHandler = formResponseHandler({
    entityName: 'Magic Pot',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.MagicPots.updateRequest(values).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      actionName="Update"
    />
  );
}
