import React from 'react';

import APP_DATA from '@services/appData';

import MultipleDatesField from '@controls/form/MultipleDatesField';
import DateTimeField from '@controls/form/DateTimeField';
import ServerError from '@controls/form/ServerError';

const { enums: { RuleOperations: operations } } = APP_DATA;

export default function Fields({ name, disabled, operation }) {
  const dateFormat = ['dd/MM/yyyy'];

  return ([operations.IN, operations.NOT_IN].includes(operation) ? (
    <MultipleDatesField
      name={name}
      dateFormat={dateFormat}
      showTimeSelect={false}
      disabled={disabled}
    />
  ) : (
    <>
      <DateTimeField
        name={`${name}.0.value`}
        dateFormat={dateFormat}
        showTimeSelect={false}
        disabled={disabled}
      />
      <ServerError name={name} />
    </>
  )
  );
}
