import appData from '@services/appData';

import { addUuid } from '@services/recursivelyProcessObject';
import removeEntity from '@services/removeEntity';
import { persistedCollection } from '@services/utils';
import { moveUp, moveDown } from '@services/reorder';

const purchaseTypes = appData.enums['Woo::Aspen::PurchaseConfigs::PurchaseTypes'];

export default function formReducer(state, action) {
  switch (action.type) {
    case 'addOption': {
      state.optionsAttributes.push(addUuid({
        position: persistedCollection(state.optionsAttributes).length + 1,
        purchaseType: purchaseTypes.ECONOMY,
        rewardAffiliationsAttributes: [],
      }));
      break;
    }
    case 'removeOption': {
      const { uuid } = action;

      return removeEntity(state, 'optionsAttributes', uuid, 'position');
    }
    case 'moveUp': {
      moveUp(state, ['optionsAttributes'], action, 'position');

      break;
    }
    case 'moveDown': {
      moveDown(state, ['optionsAttributes'], action, 'position');

      break;
    }
    default: break;
  }

  return state;
}
