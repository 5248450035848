/* eslint-disable gs/plz-camel-case-for-action-names */
import { get, set } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';

export default function targetsReducer(state, action) {
  if (action.type === 'add_target') {
    const { path } = action;
    get(state, path).push(addUuid({ _destroy: false, id: null, quantity: 1 }));
  }

  if (action.type === 'remove_target') {
    const { path } = action;
    set(state, `${path}._destroy`, true);
  }

  return state;
}
