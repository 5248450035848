import React from 'react';

import Edit from '../Edit';
import Form from './Form';

export default function ForceUpdatesEdit() {
  return (
    <Edit
      form={Form}
      routesName="ForceUpdates"
      entityName="Force updates"
    />
  );
}
