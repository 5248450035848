import React from 'react';
import styled from 'styled-components';
import { Button, Col, Row } from 'react-bootstrap';

import { Rewards } from '@pages/common';
import { Label, Field } from '@controls/form';
import { useConfirm } from '@hooks';

const ButtonCol = styled(Col)`
  flex-grow: 0;
  flex-shrink: 1;
  padding-top: 27px;
`;

export default function MassOperations({
  dispatch, values, rewardItems, diffName, hideQuantityUntilSelected,
}) {
  const confirm = useConfirm();

  return (
    <Label text="Add levels to end:" fieldSize={8}>
      <Row>
        <Col xs={3}>
          <Label text="Number of levels" direction="vertical">
            <Field name="numberOfNewLevels" type="number" />
          </Label>
        </Col>
        <Col xs={3}>
          <Label text={`${diffName} diff`} direction="vertical">
            <Field name="diff" type="number" />
          </Label>
        </Col>
        <Col>
          <Label text="Rewards" direction="vertical">
            <Rewards
              dispatch={dispatch}
              rewardable={values}
              rewardItems={rewardItems}
              hideQuantityUntilSelected={hideQuantityUntilSelected}
            />
          </Label>
        </Col>
        <ButtonCol>
          <Button
            disabled={!values.diff || !(values.numberOfNewLevels && values.numberOfNewLevels > 0)}
            onClick={() => confirm.showConfirmation({
              title: `${values.numberOfNewLevels} levels will be added to the set. Continue?`,
            }).then(() => dispatch({ actionType: 'add_new_levels' }))}
          >
            Add
          </Button>
        </ButtonCol>
      </Row>
    </Label>
  );
}
