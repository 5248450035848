import React from 'react';

import { AdsRoutes } from '@pages/routes';

import Form from './Form';

export default function EditModal({ record, onHide, refetch }) {
  const { editRequest, updateRequest } = AdsRoutes.AdUnitCampaigns;

  return (
    <Form
      record={record}
      onHide={onHide}
      refetch={refetch}
      request={editRequest}
      submitForm={updateRequest}
      actionName="update"
    />
  );
}
