import React from 'react';
import { Col } from 'react-bootstrap';

import { Filter, TextFilter } from '@pages/common';

export default function AppEventsFilter() {
  return (
    <Filter>
      {({ updateFilterField }) => (
        <Col md={6}>
          <TextFilter
            name="keyContAny"
            label="Key = "
            updateFilterField={updateFilterField}
          />
        </Col>
      )}
    </Filter>
  );
}
