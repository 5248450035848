import React from 'react';
import { get } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { useFormContext, FormGroup, useQuery } from '@hooks';
import {
  Label,
  SelectField,
  Field,
  AssetsKeySelectField,
  FieldArrayWithLabel,
  ServerError,
  CheckboxField,
} from '@controls/form';
import { PageSection } from '@pages/common';

import { LocalizationsRoutes, AssetsRoutes, FigureSetsRoutes } from '@pages/routes';

import LevelBanks from '@pages/journeys/level-banks/EventsBlock';

const { enums } = APP_DATA;

const popupFieldsConfigurationEnd = [
  {
    label: 'Display in X hours before the end',
    name: 'beforeHours',
    type: 'number',
  },
];

const popupFieldsConfigurationStart = [
  {
    label: 'Display in X hours before the start',
    name: 'beforeHours',
    type: 'number',
  },
];

export const ConfigurationVariant = React.memo(({
  values, defaultVariant = false, variantIndex, isArchived,
}) => {
  const { query } = useRouter();
  const { dispatch } = useFormContext();

  const { applicationId } = query;

  const onBankAdd = (id) => dispatch({ actionType: 'addLevelBankToJourney', id, variantIndex });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeLevelBankFromJourney', id, variantIndex,
  });

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  const assetTypesEnum = enums['Woodoku::AssetTypes'];

  const usedAssetTypes = [
    assetTypesEnum.JOURNEY_GEMS,
    assetTypesEnum.DC_GEMS,
  ];

  const { response: assetKeys } = useQuery({
    request: ({ ...params }) => AssetsRoutes.Keys.indexRequest({
      ...params,
      filter: { withTypesOrIds: { types: usedAssetTypes, ids: null } },
    }),
    processResponse: (response) => response.data.items,
    queryKey: ['journeyAssets', applicationId],
    toSelectOptions: true,
  });

  const { response: figureSets, isLoading: figureSetsLoading } = useQuery({
    request: FigureSetsRoutes.Sets.indexRequest,
    queryKey: ['figureSets', applicationId],
    toSelectOptions: true,
  });

  const { response: localizationKeys } = useQuery({
    request: LocalizationsRoutes.Keys.indexRequest,
    queryKey: ['localizationKeys', applicationId],
    toSelectOptions: true,
  });

  const filterAssetKeys = (types) => {
    const wrappedTypes = [types].flat();

    return (
      assetKeys.filter(({ filteringTypesValues }) => (
        !filteringTypesValues.length || wrappedTypes.some((type) => filteringTypesValues.includes(type))
      ))
    );
  };

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Block Offline Play">
        <CheckboxField name="blockOfflinePlay" />
      </Label>
      <Label text="Prize Translation Key">
        <SelectField name="prizeTranslationKeyId" options={localizationKeys} />
      </Label>
      <Label text="NAG Translation Key">
        <SelectField name="nagTranslationKeyId" options={localizationKeys} />
      </Label>
      <Label
        text="Title Translation Key"
        tooltipText="Header configuration on Promo Event Map and Real Reward Event Map"
      >
        <SelectField name="titleTranslationKeyId" options={localizationKeys} />
      </Label>
      <Label text="Figure Set">
        <SelectField
          name="figureSetId"
          options={figureSets}
          isLoading={figureSetsLoading}
        />
      </Label>
      <Label
        text="Ads off for Event level X and Below"
        tooltipText="Level X below which Interstitial Ads will be disabled in Event.
                    Config will override Level X in game setting Ads off for Event level X and Below"
      >
        <Field type="number" name="adsOffJourneyLevelX" />
      </Label>
      <Label
        text="Disable Help"
        tooltipText="If it is active, client turns off boosters, play on, etc.
                    (any features that help users complete levels)"
      >
        <CheckboxField name="helpDisabled" />
      </Label>
      <Label text="Real Reward Event" tooltipText="Car Promo Event">
        <CheckboxField name="realRewardEventEnabled" />
      </Label>

      <PageSection title="Asset Bundles:" />
      <Label text="Asset: Main Bundle">
        <AssetsKeySelectField
          name="assetKeyId"
          options={filterAssetKeys(assetTypesEnum.JOURNEY_MAIN)}
        />
      </Label>
      <Label text="Asset: Gems Bundle">
        <AssetsKeySelectField
          name="assetGemsKeyId"
          options={filterAssetKeys([assetTypesEnum.JOURNEY_GEMS, assetTypesEnum.DC_GEMS])}
        />
      </Label>
      <Label text="Asset: Journey Popups">
        <AssetsKeySelectField
          name="assetJourneyPopupsKeyId"
          options={filterAssetKeys([assetTypesEnum.JOURNEY_POPUP])}
          includeEmpty
        />
      </Label>
      <Label text="Asset: Journey lobby theme">
        <AssetsKeySelectField
          name="assetJourneyLobbyThemeKeyId"
          options={filterAssetKeys([assetTypesEnum.JOURNEY_LOBBY_THEME])}
          includeEmpty
        />
      </Label>

      <PageSection title="Staring Soon pop-up" />
      <ServerError name="startingSoonPopups.list" />
      <FieldArrayWithLabel
        isHorizontal
        collectionName="startingSoonPopups"
        values={get(values, variantAttributesKey, values).startingSoonPopups || []}
        fieldsConfiguration={popupFieldsConfigurationStart}
        disabled={isArchived}
      />

      <PageSection title="Not long left pop-up" />
      <ServerError name="notLongLeftPopups.list" />
      <FieldArrayWithLabel
        isHorizontal
        collectionName="notLongLeftPopups"
        values={get(values, variantAttributesKey, values).notLongLeftPopups || []}
        fieldsConfiguration={popupFieldsConfigurationEnd}
        disabled={isArchived}
      />

      <LevelBanks
        disabled={isArchived}
        validateLevels
        variantAttributes={get(values, variantAttributesKey, values)}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
        eventType="promoEvent"
      />
    </FormGroup>
  );
});

const WoodokuConfigurationForm = React.memo(({ values, defaultVariant = false, isArchived }) => (
  <>
    <Label text="Use local time">
      <CheckboxField name="useLocalTime" />
    </Label>

    <ConfigurationVariant values={values} defaultVariant={defaultVariant} isArchived={isArchived} />
  </>
));

export default WoodokuConfigurationForm;
