import React from 'react';

import { PageHeader } from '@pages/common';
import {
  Form,
  useCurrentApplication,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import { useRouter } from '@tripledotstudios/react-core';

import {
  FormButtonsGroup,
  Label,
  Field,
  SelectField,
  StaticFieldsFormGroup,
} from '@controls/form';

import { SpiderSolitaireRoutes } from '@pages/routes';

import SettingsTable from './SettingsTable';

export default function WorldStatsConfigsForm({ initialValues, onSubmit }) {
  const { query } = useRouter();
  const { currentApplication } = useCurrentApplication();

  const suitsTypesOptions = fromClassNameToOptions('SpiderSolitaire::DailyChallenges::WorldStatsConfigs::SuitsTypes');

  return (
    <Form
      initialValues={{ applicationId: currentApplication.id, ...initialValues }}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${values.id ? 'Edit' : 'New'} World Statistics Config`}>
            <FormButtonsGroup
              cancelButtonPath={(
                SpiderSolitaireRoutes.WorldStatsConfig.indexPath({ applicationId: query.applicationId })
              )}
            />
          </PageHeader>

          <Label text="Name">
            <Field name="name" type="text" />
          </Label>

          <StaticFieldsFormGroup data={initialValues} />

          <Label text="Type">
            <SelectField name="suitsType" options={suitsTypesOptions} />
          </Label>
          <SettingsTable />
        </>
      )}
    </Form>
  );
}
