import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faAddressCard, faStar } from '@fortawesome/free-solid-svg-icons';

import { PageHeader, PageSection } from '@pages/common';
import { AdminRoutes } from '@pages/routes';
import { useUser } from '@hooks';
import { ApplicationIcon } from '@components/shared';

import APP_DATA from '@services/appData';
import IconButton from '@controls/buttons';

const applicationHomePath = (application) => `/admin/applications/${application.id}/home`;

const WelcomeSection = ({ defaultApplication }) => (
  <Card className="text-center mb-3" data-testid="application_card_default">
    <Card.Body className="py-4">
      <Card.Title className="mb-4">
        <h4>Welcome to Game Server!</h4>
      </Card.Title>

      {defaultApplication ? (
        <>
          <Card.Text className="mb-2">
            <ApplicationIcon
              imageCdnUrl={defaultApplication.imageCdnUrl}
              className="mb-2"
              width={35}
              height={35}
            />

            <p className="mb-2">
              {`Your default application is ${defaultApplication.name}`}
            </p>
          </Card.Text>

          <IconButton
            icon={faRocket}
            to={applicationHomePath(defaultApplication)}
          >
            {`Go to ${defaultApplication.name}`}
          </IconButton>
        </>
      ) : (
        <>
          <Card.Text>You can set your default application in profile</Card.Text>
          <IconButton icon={faAddressCard} to={AdminRoutes.profilePath()}>Go to Profile</IconButton>
        </>
      )}
    </Card.Body>
  </Card>
);

const ApplicationsSection = ({ applications, defaultApplicationId }) => (
  <>
    <PageSection title="All Applications" />

    <Row className="gy-0 gx-3">
      {applications.map((application) => (
        <Col sm={12} md={6} lg={4} xxl={3}>
          <Link to={applicationHomePath(application)}>
            <Card className="mb-3" data-testid={`application_card_${application.id}`}>
              <Card.Body>
                <ApplicationIcon
                  imageCdnUrl={application.imageCdnUrl}
                  className="mb-2"
                  width={30}
                  height={30}
                />

                <Card.Title className="mb-0">
                  {defaultApplicationId === application.id && (
                    <FontAwesomeIcon icon={faStar} size="sm" className="me-1" />
                  )}

                  {application.name}
                </Card.Title>
                <Card.Text className="text-muted">
                  {application.typeCodename}
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  </>
);

export default function Home() {
  const { applications } = APP_DATA;

  const { user } = useUser();
  const { defaultApplicationId } = user || {};
  const defaultApplication = defaultApplicationId && applications.find((app) => app.id === defaultApplicationId);

  return (
    <>
      <PageHeader title="Home" />

      <WelcomeSection defaultApplication={defaultApplication} />
      <ApplicationsSection applications={applications} defaultApplicationId={defaultApplicationId} />
    </>
  );
}
