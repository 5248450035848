import React from 'react';

import APP_DATA from '@services/appData';

import Index from '@components/journeys/banks/Index';
import { DailyChallengesRoutes } from '@pages/routes';
import { fromClassNameToOptions } from '@root/services/enums';
import { useI18n } from '@root/hooks';

const statuses = APP_DATA.enums['DailyChallenges::LevelBankStatuses'];

export default function DailyChallengeLevelBanksIndex() {
  const { translate } = useI18n();

  return (
    <Index
      statuses={statuses}
      routes={DailyChallengesRoutes.LevelBanks}
      translationsPath="dailyChallenges.levelBanks"
      modelName="DailyChallenges::LevelBanks::Bank"
      showLevelsCount={false}
      massOperationsEnabled
      massOperationsProps={{
        pageModelName: translate.fallback('dailyChallenges.levelBanks.pluralName'),
        modelName: 'DailyChallenges::LevelBanks::Bank',
        statusOptions: fromClassNameToOptions('DailyChallenges::LevelBankStatuses'),
      }}
    />
  );
}
