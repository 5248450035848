import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form, AbTestingContext } from '@hooks';
import {
  Label,
  Field,
  FormButtonsGroup,
  StaticFieldsFormGroup,
  PriorityField,
  AvailabilityStateFormGroup,
  SelectField,
  LabelsFormGroup,
} from '@controls/form';

import { AdsRoutes } from '@pages/routes';
import { PageHeader, UsageCountLabel, DuplicateButton } from '@pages/common';

import RulesForm from '@pages/rules/Form';
import StartEndFields from '@components/overridable-settings/StartEndFields';

import {
  rulesReducer,
  newcomersRestrictionsReducer,
  frequencyRestrictionsReducer,
  basicRestrictionsReducer,
  adUnitsConfigurationReducer,
  combineReducers,
} from '@reducers';
import { reducer as overridableSettingsReducer } from '@components/overridable-settings';

import { fromClassNameToOptions } from '@services/enums';

import ConfigurationTabs from './form/Tabs';

const reducer = combineReducers(
  [
    overridableSettingsReducer,
    rulesReducer,
    newcomersRestrictionsReducer,
    frequencyRestrictionsReducer,
    basicRestrictionsReducer,
    adUnitsConfigurationReducer,
  ],
);

export default function ConfigurationsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const statuses = fromClassNameToOptions('Ads::ConfigurationStatuses');

  const { isBase } = data;
  const isUpdate = Boolean(data.id);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={reducer}
    >
      {({ values, dirty }) => (
        <>
          <PageHeader
            title={`${isUpdate ? 'Edit' : 'New'} ${isBase ? 'Base Ads Configuration' : 'Ads Configuration Override'}`}
            userGuideUrlKey="adsConfigurationsGuideUrl"
          >
            <FormButtonsGroup cancelButtonPath={AdsRoutes.Configurations.indexPath({ applicationId })}>
              {!isBase && (
                <DuplicateButton
                  entityName="Ads Configuration"
                  routes={AdsRoutes.Configurations}
                  data={data}
                  disabled={dirty}
                  modalType="withPriority"
                />
              )}
            </FormButtonsGroup>
          </PageHeader>

          <AbTestingContext entityType="Ads::Configuration" entityId={data.id}>
            <Label text="Name" required>
              <Field type="text" name="name" disabled={isBase} />
            </Label>

            <Label text="Status" required>
              <SelectField name="status" options={statuses} isDisabled={isBase} />
            </Label>

            <Label text="Priority" required>
              <PriorityField
                name="priority"
                entitiesByPriority={data.entitiesByPriority}
                disabled={isBase}
              />
            </Label>

            <AvailabilityStateFormGroup disabled={isBase} />

            <StaticFieldsFormGroup data={data} />

            <UsageCountLabel data={data} />

            <LabelsFormGroup />

            <StartEndFields isBase={isBase} />

            <Label text="Notes">
              <Field name="notes" as="textarea" />
            </Label>

            <ConfigurationTabs
              data={data}
              applicationId={applicationId}
              isBase={isBase}
              enabledFieldsNames={values.enabledFieldsNames}
              errors={(data._meta && data._meta.errors) || {}}
            />

            {!isBase && <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} />}
          </AbTestingContext>
        </>
      )}
    </Form>
  );
}
