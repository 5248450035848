import React from 'react';
import { Alert } from '@tripledotstudios/react-core';

import { DuplicateButton, PageHeader } from '@pages/common';
import {
  Label,
  Field,
  SelectField,
  FormButtonsGroup,
  PriorityField,
  DateTimeField,
  AvailabilityStateFormGroup,
  StaticFieldsFormGroup,
  localizedLabelFactory,
} from '@controls/form';
import { MissionsRoutes } from '@pages/routes';
import { useI18n, useCurrentApplication } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import RewardSets from './RewardSets';

const LocalizedLabel = localizedLabelFactory('missions.allocationTemplate');

export default function GeneralFields({ values }) {
  const { applicationId } = useCurrentApplication();
  const statusOptions = fromClassNameToOptions('Missions::AllocationTemplateStatuses');
  const { id } = values;
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  return (
    <>
      <PageHeader title={`${id ? 'Edit' : 'New'} ${namespaceName} Allocation`} userGuideUrlKey="missionsGuideUrl">
        <FormButtonsGroup cancelButtonPath={MissionsRoutes.AllocationTemplates.indexPath({ applicationId })}>
          <DuplicateButton
            routes={MissionsRoutes.AllocationTemplates}
            data={values}
            entityName={`${namespaceName} Allocation`}
            modalType="withPriority"
          />
        </FormButtonsGroup>
      </PageHeader>

      <Label text="Name"><Field type="text" name="name" /></Label>
      <Label text="Status">
        <SelectField name="status" options={statusOptions} />
      </Label>
      <AvailabilityStateFormGroup />
      <Label text="Active From">
        <DateTimeField
          name="activeFrom"
          dateFormat={['dd/MM/yyyy']}
          showTimeSelect={false}
        />
      </Label>
      <Label text="Active Until">
        <DateTimeField
          name="activeTo"
          dateFormat={['dd/MM/yyyy']}
          showTimeSelect={false}
          isClearable
        />
      </Label>
      <Label text="Priority">
        <PriorityField name="priority" entitiesByPriority={values.entitiesByPriority} />
      </Label>
      <Label text={`Generate ${namespaceName}s with unique types`}>
        <Field type="checkbox" name="uniqueMissionTypes" />
      </Label>
      <LocalizedLabel name="dailyTasksDisplayNumber">
        <Field type="number" step={1} name="dailyTasksDisplayNumber" />
      </LocalizedLabel>
      <Alert variant="info">
        {`Please note that less ${namespaceName}s could be generated than configured if this checkbox is selected`}
      </Alert>

      <RewardSets />
      <StaticFieldsFormGroup data={values} />
    </>
  );
}
