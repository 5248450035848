import React from 'react';

import { Label, CheckboxField } from '@controls/form';
import AllowEditLayoutBanksInUse from './shared/AllowEditLayoutBanksInUse';

export default function TileTesterSettingsFields() {
  return (
    <>
      <AllowEditLayoutBanksInUse />
      <Label text="Allow edit Scrapbook Picture banks in use">
        <CheckboxField name="editInUseJourneyLevelBanks" />
      </Label>
    </>
  );
}
