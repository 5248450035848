import React, { memo } from 'react';
import { Col } from 'react-bootstrap';

import { Label, SelectField, ReorderableMultiSelectField } from '@controls/form';

import localizeField from './localizeField';
import areEnabledPropsEqual from './areEnabledPropsEqual';
import { buildModelBasedQuickLink } from './quickLinksUtils';

export default function SelectBuilder({
  name: baseName,
  labelText,
  options,
  quickLinkModel,
  quickLinkFn,
  hasPrepopulatedOption,
  includeEmpty = false,
  tooltips = {},
  selectOptions = {},
  fieldOptions = {},
  isMulti = false,
  isReorderable = false,
}) {
  const Component = (isMulti && isReorderable) ? ReorderableMultiSelectField : SelectField;
  const currentQuickLinkFn = quickLinkModel ? buildModelBasedQuickLink(quickLinkModel) : quickLinkFn;

  return {
    [baseName]: {
      Inline: memo(({ name, disabled }) => (
        <Component
          includeEmpty={includeEmpty}
          name={name || baseName}
          options={options}
          quickLinkFn={currentQuickLinkFn}
          isDisabled={disabled}
          isMulti={isMulti}
          {...selectOptions}
        />
      ), areEnabledPropsEqual),
      Labeled: memo(({
        name, disabled, localeNamespace, locale = {}, tooltipBuilder,
      }) => {
        const { label, tooltip, clientKey } = localizeField(
          baseName, labelText, tooltips, localeNamespace, locale, tooltipBuilder,
        );

        return (
          <Col>
            <Label text={label} copyableText={clientKey} fieldSize={8} tooltipText={tooltip}>
              <Component
                includeEmpty={includeEmpty}
                name={name || baseName}
                options={options}
                quickLinkFn={currentQuickLinkFn}
                isDisabled={disabled}
                hasPrepopulatedOption={hasPrepopulatedOption}
                isMulti={isMulti}
                {...selectOptions}
              />
            </Label>
          </Col>
        );
      }, areEnabledPropsEqual),
      fieldOptions,
    },
  };
}
