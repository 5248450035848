import React, {
  useContext, createContext, useState, useMemo,
} from 'react';

const loadingContext = createContext();

export function useGlobalLoading() {
  return useContext(loadingContext);
}

export function GlobalLoadingProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const value = useMemo(() => ({
    isLoading,
    setIsLoading,
    withReload: (effect = () => {}) => {
      setIsLoading(true);
      setTimeout(() => {
        effect();
        setIsLoading(false);
      }, 10);
    },
  }), [isLoading]);
  return (
    <loadingContext.Provider value={value}>
      {children}
    </loadingContext.Provider>
  );
}
