import { useRouter } from '@tripledotstudios/react-core';
import React, { useState } from 'react';
import { uniq, without } from 'lodash';

import { Label } from '@controls/form';
import IconButton from '@controls/buttons';

const FilterField = ({
  label,
  name,
  FieldComponent,
  BadgesComponent,
  canAdd,
  hideButton,
  updateFilterField,
  stateFieldValue,
  onInit = () => { },
  isMulti = true,
}) => {
  const { query } = useRouter();
  const [currentValue, setCurrentValue] = useState('');
  const fieldValue = (stateFieldValue !== undefined ? stateFieldValue : (query.filter || {})[name]) || [];
  onInit({ fieldValue });

  const addValue = () => {
    setCurrentValue('');
    updateFilterField({ [name]: isMulti ? uniq([...fieldValue, currentValue]) : currentValue });
  };

  const removeValue = (value) => {
    updateFilterField({ [name]: isMulti ? without(fieldValue, value) : null });
  };

  const onKeyPress = (event) => {
    if (event.key !== 'Enter') return;

    addValue();
  };

  const renderContent = () => (
    <div className="d-flex align-items-start">
      <div className="flex-grow-1 me-1">
        <FieldComponent
          name={name}
          updateFilterField={updateFilterField}
          currentValue={currentValue}
          fieldValue={fieldValue}
          filter={query.filter}
          setCurrentValue={setCurrentValue}
          onKeyPress={onKeyPress}
        />
      </div>
      {!hideButton && (
        <IconButton.New
          disabled={!canAdd({ currentValue })}
          onClick={addValue}
          outline
        >
          {isMulti ? 'Add Filter' : 'Apply'}
        </IconButton.New>
      )}
    </div>
  );

  return (
    <div>
      {label ? (
        <Label fieldSize={8} labelSize={4} text={label} direction="vertical">
          {renderContent()}
        </Label>
      ) : renderContent()}
      {BadgesComponent && (
        <div className="mx-n1">
          <BadgesComponent fieldValue={fieldValue} removeValue={removeValue} />
        </div>
      )}
    </div>
  );
};

export default FilterField;
