import React from 'react';

import { useRouter } from '@tripledotstudios/react-core';
import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { AssetsRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const { query } = useRouter();
  const { response, setResponse } = useQuery(
    AssetsRoutes.Keys.newRequest,
    { id: query.id, applicationId: query.applicationId },
  );
  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Asset Key',
    actionName: 'create',
    redirectPath: AssetsRoutes.Keys.indexPath(query),
    setData: setResponse,
  });

  const handleSubmit = (values) => (
    AssetsRoutes.Keys
      .createRequest({ ...values, applicationId: query.applicationId })
      .then(responseHandler)
  );

  return response && <Form action="New" data={response} onSubmit={handleSubmit} />;
}
