import React, { useMemo } from 'react';

import { ReactTable } from '@pages/common';
import DiffValue from '../DiffValue';
import { creationRows } from '../diffRows';

const columnBuilder = [
  { Header: 'Attribute/Property', accessor: 'attribute' },
  { Header: 'Action', id: 'action', Cell: 'set' },
  { Header: 'Context', accessor: 'context' },
  {
    Header: 'Values',
    accessor: 'value',
    disableSortBy: true,
    Cell: ({ row }) => <DiffValue value={row.original.value} onClick={row.original.onClick} />,
  },
];

export default function Creation({ showAssociation, versionTree }) {
  const changesRows = creationRows({ versionTree, showAssociation });
  const columns = useMemo(() => columnBuilder, []);

  return (
    <ReactTable
      columns={columns}
      data={changesRows}
      disableSortBy
    />
  );
}
