import React from 'react';

import { useRouter } from '@tripledotstudios/react-core';
import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { AssetsRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { query } = useRouter();
  const { response, setResponse } = useQuery(
    AssetsRoutes.Keys.editRequest,
    { id: query.id, applicationId: query.applicationId },
  );

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Asset Key',
    actionName: 'update',
    redirectPath: `/admin/applications/${query.applicationId}/asset_bundle`,
    setData: setResponse,
  });

  const handleSubmit = (values) => AssetsRoutes.Keys.updateRequest(values).then(responseHandler);

  return response && <Form action="Edit" data={response} onSubmit={handleSubmit} />;
}
