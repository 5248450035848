import React from 'react';
import { faUser, faAddressCard, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Sidebar, useRouter } from '@tripledotstudios/react-core';

import { useUser } from '@hooks';
import { AdminRoutes } from '@pages/routes';
import signOut from '@requests/signOut';

export default function User({ minimized = false }) {
  const { user, resetUserData } = useUser();
  const router = useRouter();

  const afterSignOut = () => {
    resetUserData();

    router.push('admin/logged_out');
  };

  const signOutUser = () => {
    signOut().then(afterSignOut);
  };

  return (
    <Sidebar.SectionItem
      icon={faUser}
      title={user?.userName || 'User'}
      minimized={minimized}
    >
      <Sidebar.LinkItem
        to={AdminRoutes.profilePath()}
        title="Profile"
        icon={faAddressCard}
      />
      <Sidebar.ActionItem
        onClick={signOutUser}
        title="Sign Out"
        icon={faSignOutAlt}
      />
    </Sidebar.SectionItem>
  );
}
