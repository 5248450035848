import React from 'react';

import { ChapterSetsEdit } from '../../metagame/chapter-based';
import Chapters from './Chapters';
import formReducer from './formReducer';

const TileChapterSetsEdit = (props) => (
  <ChapterSetsEdit ChaptersComponent={Chapters} reducer={formReducer} {...props} />
);

export default TileChapterSetsEdit;
