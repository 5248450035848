import React from 'react';
import { get } from 'lodash';
import { fromClassNameToOptions } from '@services/enums';
import APP_DATA from '@services/appData';

import { useFormContext, useFormGroup } from '@hooks';
import { SelectField } from '@controls/form';

export default function HomeScreenWidgetsVariant({ disabled }) {
  const { values } = useFormContext();
  const { generateName } = useFormGroup();
  const widgetsVariantOptions = fromClassNameToOptions('Woodoku::HomeScreenConfig::WidgetsVariants');
  const { enums } = APP_DATA;

  const lobbyVariant = get(values, generateName('lobbyVariant'));
  const display = lobbyVariant === enums['Woodoku::HomeScreenConfig::LobbyVariants'].ALL_MODES;

  return display && (
    <SelectField name="widgetsVariant" options={widgetsVariantOptions} isDisabled={disabled} />
  );
}
