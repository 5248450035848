import React from 'react';

export default function TrailFields({ prefix, record }) {
  const user = record[`${prefix}By`];
  return (
    <span>
      {record[`${prefix}At`]}
      {user && ` (by ${user})`}
    </span>
  );
}
