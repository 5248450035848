import React from 'react';
import {
  camelCase,
  capitalize,
  get,
  map,
} from 'lodash';

import { FormGroup, useFormContext } from '@hooks';

import {
  Field,
  Label,
  CheckboxField,
  AssetsKeySelectField,
  TimePeriodField,
  SelectField,
} from '@controls/form';

const FieldComponent = ({ disabled, field, name }) => {
  switch (field.type) {
    case 'integer':
    case 'float':
      return <Field disabled={disabled} name={name} type="number" />;
    case 'bool':
      return <CheckboxField disabled={disabled} name={name} />;
    case 'string':
      return <Field disabled={disabled} name={name} type="text" />;
    case 'asset':
      return <AssetsKeySelectField isDisabled={disabled} name={name} {...field.props} />;
    case 'time_period':
      return <TimePeriodField disabled={disabled} name={name} inputs={field.inputs || ['seconds']} />;
    case 'select':
      return <SelectField isDisabled={disabled} name={name} {...field.props} />;
    default:
      return null;
  }
};

const allowedField = (values, field) => !field.unless || !values[camelCase(field.unless)];

export default function RewardFields({ disabled, name, rewardSchema }) {
  const { values } = useFormContext();
  const fieldsValues = get(values, name) || {};

  return (
    <FormGroup name={name}>
      {map(rewardSchema.attributes, (field, fieldName) => allowedField(fieldsValues, field) && (
        <Label text={capitalize(field.name || fieldName)} key={fieldName} tooltipText={field.hint} required>
          <FieldComponent disabled={disabled} field={field} name={fieldName} />
        </Label>
      ))}
    </FormGroup>
  );
}
