import React from 'react';
import { get } from 'lodash';
import { Alert } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import APP_DATA from '@services/appData';

import { useFormContext, FormGroup, useQuery } from '@hooks';
import {
  Label,
  Field,
  SelectField,
  AssetsKeySelectField,
  CheckboxField,
} from '@controls/form';
import { PageSection } from '@pages/common';

import { FigureSetsRoutes, LocalizationsRoutes } from '@pages/routes';

import PopupSections from '@pages/journeys/shared/PopupSections';
import LocalPushNotificationSettings from '@pages/journeys/shared/woodoku/LocalPushNotificationSettings';

import LevelBanks from '@pages/journeys/level-banks/EventsBlock';

const WoodokuConfigurationForm = ({
  values, defaultVariant = false, variantIndex, isArchived,
}) => {
  const {
    enums,
    modelsLocales: { woodoku: { journeys: { activationEvents: { tooltips } } } },
  } = APP_DATA;

  const { query } = useRouter();
  const { dispatch } = useFormContext();

  const { applicationId } = query;

  const onBankAdd = (id) => dispatch({ actionType: 'addLevelBankToJourney', id, variantIndex });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeLevelBankFromJourney', id, variantIndex,
  });

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  const assetTypesEnum = enums['Woodoku::AssetTypes'];

  const { response: localizationKeys } = useQuery({
    request: LocalizationsRoutes.Keys.indexRequest,
    queryKey: ['localizationKeys', applicationId],
    toSelectOptions: true,
  });
  const { response: figureSets, isLoading: figureSetsLoading } = useQuery({
    request: FigureSetsRoutes.Sets.indexRequest,
    queryKey: ['figureSets', applicationId],
    toSelectOptions: true,
  });

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Activation Journey Cohort Day Limit" tooltipText={tooltips.cohortDayActivationRule}>
        <Field type="number" name="cohortDayActivationRule" appendText="Days" />
      </Label>
      <Label text="Default Title">
        <Field name="defaultTitle" />
      </Label>
      <Label text="Default Subtitle">
        <Field name="defaultSubtitle" />
      </Label>
      <Label text="Title Translation Key">
        <SelectField name="titleTranslationKeyId" options={localizationKeys} />
      </Label>
      <Label text="Subtitle Translation Key">
        <SelectField name="subtitleTranslationKeyId" options={localizationKeys} />
      </Label>
      <Label text="Show Timer On UI" tooltipText={tooltips.config.showTimer}>
        <Field type="checkbox" name="showTimer" />
      </Label>
      <Label text="Block Offline Play">
        <CheckboxField name="blockOfflinePlay" />
      </Label>
      <Label text="Figure Set">
        <SelectField
          name="figureSetId"
          options={figureSets}
          isLoading={figureSetsLoading}
        />
      </Label>
      <Label text="Ads off for Journey level X and Below" tooltipText={tooltips.config.adsOffJourneyLevelX}>
        <Field type="number" name="adsOffJourneyLevelX" />
      </Label>

      <PageSection title="Asset Bundles:" />
      <Label text="Asset: Main Bundle">
        <AssetsKeySelectField
          name="assetKeyId"
          types={assetTypesEnum.JOURNEY_MAIN}
        />
      </Label>
      <Label text="Asset: Rewards Bundle">
        <AssetsKeySelectField
          name="assetRewardsKeyId"
          types={assetTypesEnum.JOURNEY_REWARDS}
        />
      </Label>
      <Label text="Asset: Gems Bundle">
        <AssetsKeySelectField
          name="assetGemsKeyId"
          types={[assetTypesEnum.JOURNEY_GEMS, assetTypesEnum.DC_GEMS]}
        />
      </Label>
      <Label text="Asset: Journey Popups">
        <AssetsKeySelectField
          name="assetJourneyPopupsKeyId"
          types={[assetTypesEnum.JOURNEY_POPUP]}
          includeEmpty
        />
        <Alert variant="info" className="mt-2">
          <FontAwesomeIcon className="mr-2" icon={faInfoCircle} />
          &quot;Journey Popups&quot; is available from version 2.1.0
        </Alert>
      </Label>
      <Label text="Asset: Journey lobby theme">
        <AssetsKeySelectField
          name="assetJourneyLobbyThemeKeyId"
          types={[assetTypesEnum.JOURNEY_LOBBY_THEME]}
          includeEmpty
        />
        <Alert variant="info" className="mt-2">
          <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
          &quot;Journey UI Theme&quot; is available from version v3.09
        </Alert>
      </Label>

      <PopupSections activationEvent localizationKeys={localizationKeys} />

      <LocalPushNotificationSettings
        values={get(values, variantAttributesKey, values)}
        localizationKeyOptions={localizationKeys}
        disabled={isArchived}
      />

      <LevelBanks
        disabled={isArchived}
        validateLevels
        variantAttributes={get(values, variantAttributesKey, values)}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
        eventType="event"
      />
    </FormGroup>
  );
};

export default WoodokuConfigurationForm;
