import React, { useMemo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

import useRewardOptions from '@hooks/useRewardOptions';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import {
  Form, FormGroup, useConfirm, useCurrentApplication, useFormContext,
} from '@hooks';
import {
  Label, Field, FormButtonsGroup, StaticFieldsFormGroup,
} from '@controls/form';
import {
  PageHeader, PageSection, ReactTable, Rewards,
} from '@pages/common';
import { match3dPersonalLevelSetsReducer } from '@reducers';
import LevelSetsMassOperations from '@pages/level-sets/MassOperations';

const LevelList = styled.div`
  margin-top: 15px;
`;

const ButtonCol = styled(Col)`
  flex-grow: 0;
  flex-shrink: 1;
  padding-top: 27px;
`;

const columns = ({ rewardItems }) => [
  { Header: 'Order', accessor: 'position', className: 'w-15' },
  { Header: 'Stars Total', accessor: 'runningTotal', className: 'w-15' },
  {
    Header: 'Stars diff',
    className: 'w-15',
    Cell: ({ row }) => <FormGroup name={row.original.path}><Field name="diff" type="number" /></FormGroup>,
  },
  {
    Header: 'Reward',
    id: 'reward',
    Cell: ({ row: { original } }) => {
      const { dispatch, path } = original;
      return (
        <FormGroup name={path}>
          <Rewards
            dispatch={dispatch}
            rewardable={original}
            rewardItems={rewardItems}
          />
        </FormGroup>
      );
    },
  },
  {
    Header: '',
    className: 'w-10',
    id: 'actions',
    Cell: ({ row }) => {
      const { dispatch, index } = row.original;
      return (
        <Button
          className="mt-0 mb-0"
          variant="danger"
          icon={faTrashAlt}
          onClick={() => dispatch({ actionType: 'remove_level', index })}
          title="Delete the level"
          size="sm"
        >
          Delete
        </Button>
      );
    },
  },
];

const Fields = ({ values, rewardItems }) => {
  const { dispatch } = useFormContext();
  const { routingScope } = useCurrentApplication();
  const confirm = useConfirm();

  if (!values) return null;
  let runningTotal = 0;
  const levels = values.personalLevelsAttributes.reduce((acc, level, index) => {
    if (level._destroy) { return acc; }

    runningTotal += level.diff;
    return [
      ...acc,
      {
        ...level,
        path: `personalLevelsAttributes[${index}]`,
        runningTotal,
        dispatch,
        index,
      },
    ];
  }, []);

  return (
    <>
      <PageHeader title={`${values.id ? 'Edit' : 'New'} Star Level Set`}>
        <FormButtonsGroup cancelButtonPath={`${routingScope}/match3d/personal_level_sets`} />
      </PageHeader>

      <Field name="applicationId" type="hidden" />
      <Label text="Name*" fieldSize={8}>
        <Field name="name" />
      </Label>

      <StaticFieldsFormGroup data={values} />

      <PageSection title="Star Levels" bordered>
        <h5 className="mb-3">
          Mass Operations to levels
        </h5>
        <LevelSetsMassOperations dispatch={dispatch} rewardItems={rewardItems} values={values} diffName="Stars" />
        <Label text="Change number of levels:" fieldSize={8}>
          <Row>
            <Col>
              <Label text="Total number of levels" direction="vertical">
                <Field name="totalNumberOfLevels" type="number" />
              </Label>
            </Col>
            <ButtonCol>
              <Button
                disabled={!values.totalNumberOfLevels}
                onClick={() => confirm.showConfirmation({
                  title: 'Empty levels will be added / Extra levels will be deleted. Continue?',
                }).then(() => dispatch({ actionType: 'change_number_of_levels' }))}
              >
                change
              </Button>
            </ButtonCol>
          </Row>
        </Label>
        <LevelList>
          <ReactTable
            columns={useMemo(
              () => columns({ dispatch, rewardItems }),
              [JSON.stringify(rewardItems)],
            )}
            data={levels}
            tableProps={{}}
          />
        </LevelList>
        <Button onClick={() => dispatch({ actionType: 'add_level' })}>Add level</Button>
      </PageSection>
    </>
  );
};

export default function PersonalLevelSetForm({ data, onSubmit }) {
  const { currentApplication, applicationName } = useCurrentApplication();
  const fullData = {
    ...data,
    applicationName,
    applicationId: currentApplication.id,
    rewardAffiliationsAttributes: [],
  };
  const rewardItems = useRewardOptions();

  return (
    <Form
      initialValues={fullData}
      enableReinitialize
      onSubmit={onSubmit}
      reducer={match3dPersonalLevelSetsReducer}
    >
      {({ values }) => <Fields values={values} rewardItems={rewardItems} />}
    </Form>
  );
}
