import React from 'react';
import styled from 'styled-components';

const LinkButton = styled.button`
  background: none;
  border: none;
  outline: none !important;
  text-decoration: underline;
  color: ${({ theme }) => theme.variants.primary};
  padding: 0;
  ${({ centered }) => centered && 'margin: 0 auto; display: block;'}
`;

const LimitedWidthCell = styled.span`
  word-break: break-word;
  white-space: break-spaces;
`;

export default function DiffValue({ value, onClick, centered }) {
  if (onClick) {
    return (
      <LimitedWidthCell>
        <LinkButton onClick={onClick} centered={centered}>show</LinkButton>
      </LimitedWidthCell>
    );
  }

  return (
    <LimitedWidthCell>
      {value === 'null' ? '' : JSON.stringify(value, null, 2) || ''}
    </LimitedWidthCell>
  );
}
