import React from 'react';
import { useTheme } from 'styled-components';
import {
  faCalendarDays,
  faGlobeAmericas,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { JourneyRoutes, MergeRoutes } from '@pages/routes';

import {
  ApplicationItemSet,
  AssetsNavigationItem,
  AdsNavigationSection,
} from './common';

const postfixedRouteNav = ({
  applicationId, routePostfix, color, routeSet, title,
}) => {
  const path = routeSet.indexPath({ applicationId });
  return (
    <Sidebar.LinkItem
      isActive={(match, location) => {
        if (match) return true;

        return location.pathname.startsWith(path) && location.pathname.endsWith(`/${routePostfix}`);
      }}
      to={`${path}/${routePostfix}`}
      title={title}
      color={color}
    />
  );
};

export default function Merge({ applicationId, routingScope, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="merge"
      routingScope={routingScope}
      minimized={minimized}
    >
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />
      <Sidebar.SectionItem
        icon={faGlobeAmericas}
        title="Merge Worlds"
        color={theme.purple}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/merge/world_schemas`}
          title="World Schemas"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/merge/postcard_sets`}
          title="Postcard Sets"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/merge/postcard_configurations`}
          title="Postcard Configurations"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/merge/expert_sets`}
          title="Expert Sets"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/merge/expert_configurations`}
          title="Expert Configurations"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/merge/cutscenes_configurations`}
          title="Cutscenes Configurations"
        />
        {postfixedRouteNav({
          applicationId,
          routePostfix: 'worlds',
          title: 'Item Sets',
          routeSet: MergeRoutes.ItemConfigurationSets,
        })}
        <Sidebar.LinkItem
          to={`${routingScope}/merge/level_sets`}
          title="Level Sets"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/merge/unbubbling_configurations`}
          title="Unbubbling Configurations"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/merge/inventory_configurations`}
          title="Inventory Configurations"
        />
        {postfixedRouteNav({
          applicationId,
          routePostfix: 'worlds',
          title: 'Starting Grids',
          routeSet: MergeRoutes.StartingGrids,
        })}
        <Sidebar.LinkItem
          to={`${routingScope}/merge/worlds`}
          title="Worlds"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/merge/contacts`}
          title="Contacts"
        />
      </Sidebar.SectionItem>

      <Sidebar.SectionItem
        icon={faCalendarDays}
        title="Merge Events"
        color={theme.tulipTree}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={JourneyRoutes.Events.indexPath({ applicationId })}
          title="Events"
        />
        <Sidebar.LinkItem
          to={MergeRoutes.Events.Schemas.indexPath({ applicationId })}
          title="Event Schemas"
        />
        <Sidebar.LinkItem
          to={MergeRoutes.Events.OrdersConfigurations.indexPath({ applicationId })}
          title="Orders Configurations"
        />
        <Sidebar.LinkItem
          to={MergeRoutes.Events.LevelSets.indexPath({ applicationId })}
          title="Reward Track Configurations"
        />
        {postfixedRouteNav({
          applicationId,
          routePostfix: 'events',
          title: 'Starting Grids',
          routeSet: MergeRoutes.StartingGrids,
        })}
        {postfixedRouteNav({
          applicationId,
          routePostfix: 'events',
          title: 'Event Item Sets',
          routeSet: MergeRoutes.ItemConfigurationSets,
        })}
      </Sidebar.SectionItem>

      <Sidebar.SectionItem
        icon={faMoneyBill}
        title="Payments"
        color={theme.lightGreen}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/merge/bundle_catalogs`}
          title="Bundle Catalogs"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/merge/product_catalogs`}
          title="Currency Catalogs"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/merge/products`}
          title="Products"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/payments/store_products`}
          title="Store Products"
        />
      </Sidebar.SectionItem>

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
