import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { Label, Field, ServerError } from '@controls/form';
import { CopyToClipboardAddon } from '@controls/CopyToClipboardButton';
import { BanksEdit } from '@pages/metagame/chapter-based';
import entityIdentity from '@components/shared/entityIdentity';
import IconButton from '@controls/buttons';
import CsvActions from '@pages/metagame/chapter-based/level-layouts/CsvActions';
import { generateChapterBasedRoutes } from '@pages/routes';
import { useCurrentApplication } from '@hooks';

const EntityHeader = ({ entity, entityName }) => {
  const {
    contentHash, name, updatedAt, index,
  } = entity;

  return (
    <table className="table table-borderless mb-0">
      <tbody>
        <tr className="d-flex align-items-center">
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {entityIdentity({ entity: { ...entity, name: null }, entityName })}
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-3 ">
            <div>
              {'Hash: '}
              {contentHash}
            </div>
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-4 ">
            {'Layout Name: '}
            {name}
            <ServerError name={`levelLayoutsAttributes.${index}.name`} full />
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {'Updated at: '}
            {updatedAt}
          </td>
          <td className="bg-transparent text-break p-0 col-1" aria-label="section" />
        </tr>
      </tbody>
    </table>
  );
};

const levelLayoutFields = ({ entity: { contentHash } }) => (
  <div className="d-flex flex-row justify-content-around">
    <div className="flex-fill pe-2">
      <Label text="Layout Name">
        <Field name="name" />
      </Label>
      <Label text="Hash">
        <span className="d-flex align-items-center">
          <InputGroup>
            <Field name="contentHash" disabled />
            <CopyToClipboardAddon text={contentHash} />
          </InputGroup>
        </span>
      </Label>
    </div>
  </div>
);

const ExtraListButtons = ({ values, disabled }) => {
  const { applicationId, applicationName } = useCurrentApplication();
  const { LevelLayouts: LevelLayoutRoutes } = generateChapterBasedRoutes(applicationName);
  const newPath = `${LevelLayoutRoutes.newPath({ applicationId })}?bankId=${values.id}`;

  return (
    <div>
      <CsvActions bank={values} />
      <IconButton.New
        className="ms-2"
        disabled={disabled}
        to={newPath}
      />
    </div>
  );
};

export default function Edit(props) {
  return (
    <BanksEdit
      ExtraListButtons={ExtraListButtons}
      EntityHeader={EntityHeader}
      levelLayoutFields={levelLayoutFields}
      {...props}
    />
  );
}
