import React from 'react';

import { LabelRoutes } from '@pages/routes';
import Form from './Form';

export default function NewModal({ ...props }) {
  const { newRequest, createRequest } = LabelRoutes;

  return (
    <Form actionName="creation" getForm={newRequest} submitForm={createRequest} {...props} />
  );
}
