import { get, isNil } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';

const resolveState = (state, variantIndex) => (
  isNil(variantIndex)
    ? get(state, 'configAttributes.currentVariantAttributes', {}) : state.variantsAttributes[variantIndex]
);

/* eslint-disable no-param-reassign, array-callback-return */
export default function formReducer(state, action) {
  const { actionType, variantIndex } = action;
  const variant = resolveState(state, variantIndex);

  if (actionType === 'addLevelBankToDailyChallenge') {
    const { id } = action;

    variant.levelBankAffiliationsAttributes.push(addUuid({
      levelBankId: id, eventConfigVariantId: variant.id, _destroy: false,
    }));
  }

  if (actionType === 'removeLevelBankFromDailyChallenge') {
    const { id } = action;

    const affiliations = variant.levelBankAffiliationsAttributes.filter(({ levelBankId }) => levelBankId === id);

    if (affiliations.length) {
      affiliations.forEach((affiliation) => {
        affiliation._destroy = true;

        return affiliation;
      });
    }
  }

  return state;
}
