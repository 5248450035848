import React from 'react';
import { Link } from 'react-router-dom';
import { useRouter } from '@tripledotstudios/react-core';
import schemaLabel from '@components/merge/services/worldSchemaLabel';

export default function WorldSchemaCell({ schema }) {
  const { query } = useRouter();

  return (
    <Link
      to={`/admin/applications/${query.applicationId}/merge/world_schemas/${schema.id}/edit`}
      target="_blank"
    >
      {schemaLabel(schema)}
    </Link>
  );
}
