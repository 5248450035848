import React from 'react';
import { isEqual } from 'lodash';

import { useFormContext } from '@hooks';
import { Label, Field } from '@controls/form';
import { JourneyRoutes } from '@pages/routes';

import { PageSection } from '@pages/common';

import rewardItemsConfiguration from '@pages/journeys/shared/DefaultRewardItemsConfigurations';
import RewardItems from '@pages/journeys/shared/RewardItems';

import LevelsListWrapper from '@components/journeys/banks/levels/LevelsListWrapper';

const Sudoku = React.memo(({ values, isArchived, readOnly: isReadOnly }) => {
  const { dispatch } = useFormContext();

  const { configAttributes: { levelsAttributes } } = values;

  const onLevelAdd = () => dispatch({ actionType: 'addEmptyEntity', options: { rewardsAttributes: [] } });
  const onRewardAdd = (uuid) => dispatch({ actionType: 'addSudokuReward', uuid });

  return (
    <LevelsListWrapper
      entityAttributes={levelsAttributes}
      onEntityAdd={onLevelAdd}
      attributesName="levelsAttributes"
      metaErrorsPath={['errors', 'configAttributes', 'levelsAttributes']}
      entityNameTranslationPath="journeys.levelBanks.levels.name"
      bulkCopyRoutes={JourneyRoutes.LevelBanks}
      readOnly={isReadOnly}
      disabled={isArchived}
    >
      {({ readOnly, entity: { _uuid, number, rewardsAttributes } }) => (
        <>
          <div className="d-flex flex-row justify-content-around">
            <div className="flex-fill pe-2">
              <Label text="Mode" direction="vertical">
                <Field name="mode" disabled={readOnly} />
              </Label>
            </div>
            <div className="flex-fill pe-2">
              <Label text="Field size" direction="vertical" className="flex-fill">
                <Field name="fieldSize" disabled={readOnly} />
              </Label>
            </div>
            <div className="flex-fill">
              <Label text="Difficulty" direction="vertical" className="flex-fill">
                <Field name="difficulty" disabled={readOnly} />
              </Label>
            </div>
          </div>

          <PageSection title="Rewards">
            <RewardItems
              levelIndex={number}
              rewards={rewardsAttributes}
              onAdd={() => onRewardAdd(_uuid)}
              rewardItemsConfiguration={rewardItemsConfiguration()}
              readOnly={readOnly || isArchived}
            />
          </PageSection>
        </>
      )}
    </LevelsListWrapper>
  );
}, (prev, next) => isEqual(prev.values, next.values));

export default Sudoku;
