import React from 'react';

import { MyPetCafeRoutes } from '@pages/routes';
import Edit from '@components/journeys/banks/Edit';
import Form from './Form';

export default function WinStreaksEventBanksEdit() {
  return (
    <Edit
      form={Form}
      nameTranslationPath="winStreaks.eventBanks.name"
      routes={MyPetCafeRoutes.WinStreaks.EventBanks}
    />
  );
}
