import React, { useState } from 'react';
import { get } from 'lodash';
import { IconButton, useRouter } from '@tripledotstudios/react-core';

import { Modal } from 'react-bootstrap';
import { Form, FormGroup, useFormContext } from '@hooks';
import Field from '@controls/form/Field';
import Label from '@controls/form/Label';
import { recursivelyRemoveRuleIds } from '@services/rules/utils';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { NamedRuleGroupsRoutes } from '../routes';

export default function CreateNewNamedRuleGroupModal({ setShowCreateNamedRuleGroupModal, namedRuleGroupSectionPath }) {
  const [data, setData] = useState({ name: null, replaceSection: false });
  const { createRequest } = NamedRuleGroupsRoutes;
  const { query: { applicationId } } = useRouter();
  const { dispatch, values: formValues } = useFormContext();

  const onHide = () => setShowCreateNamedRuleGroupModal(false);

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Named Rule Group',
    actionName: 'create',
    setData,
  });

  const onSubmit = (values) => {
    const { name, replaceSection } = values;
    const section = get(formValues, namedRuleGroupSectionPath, {});
    const namedRuleGroupAttributes = [...recursivelyRemoveRuleIds([section])];
    createRequest({ ruleSectionsAttributes: namedRuleGroupAttributes, name, applicationId })
      .then((response) => {
        if (response.status === 201) {
          if (replaceSection) {
            dispatch({
              actionType: 'replaceSectionWithNamedRuleGroup', sectionsPath: namedRuleGroupSectionPath, response,
            });
          }
          responseHandler(response);
          return setShowCreateNamedRuleGroupModal(false);
        }
        return responseHandler(response);
      });
  };

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Named Rule Group from Rules section</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit} initialValues={data}>
        {() => (
          <FormGroup rootGroup>
            <Modal.Body>
              <Label text="Name" className="mb-0">
                <Field name="name" type="text" />
              </Label>
              <Label text="Replace rules section with the Named Rule Group" className="mb-0">
                <Field type="checkbox" name="replaceSection" />
              </Label>
            </Modal.Body>
            <Modal.Footer>
              <IconButton.Cancel onClick={onHide} />
              <IconButton.Save type="submit" />
            </Modal.Footer>
          </FormGroup>
        )}
      </Form>
    </Modal>
  );
}
