import React from 'react';
import { Alert, useRouter } from '@tripledotstudios/react-core';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { maxBy } from 'lodash';
import { persistedCollection } from '@services/utils';

import {
  FormButtonsGroup,
  StaticFieldsFormGroup,
  Label,
  Field,
  ServerError,
  LabelsFormGroup,
} from '@controls/form';

import {
  Form,
  FormGroup,
  useRewardOptions,
} from '@hooks';

import {
  PageHeader, PageSection, Rewards, FaButton, DuplicateButton,
} from '@pages/common';

import { TileRoutes as Routes } from '@pages/routes';

import IconButton from '@controls/buttons';

import { combineReducers, rewardableReducer, chapterRangeListReducer } from '@reducers';

import reducer from './formReducer';
import MidchapterConfigs from './MidchapterConfigs';

const Container = styled.div`
  display: flex;
  flex-flow: row;
`;
const ChapterRangeContainer = styled.div`
  flex-grow: 1;
`;
const LeftActionsColumn = styled.div`
  display: flex;
  flex-flow: column;
  padding-right: 10px;
  width: 5%;
  flex-grow: 0;
`;

const TileRewardSetForm = ({ data, onSubmit }) => {
  const { query: { applicationId } } = useRouter();
  const rewardOptions = useRewardOptions();

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([rewardableReducer, chapterRangeListReducer, reducer])}
    >
      {({ values: { chapterRangesAttributes }, dispatch, meta }) => {
        const notDestroyedRanges = persistedCollection(chapterRangesAttributes);
        const maxPosition = (maxBy(notDestroyedRanges, 'position') || {}).position || 0;
        const rangesCount = chapterRangesAttributes.length;

        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Reward Schema Set`}>
              <FormButtonsGroup cancelButtonPath={Routes.RewardSets.indexPath({ applicationId })}>
                <DuplicateButton
                  routes={Routes.RewardSets}
                  data={data}
                  entityName="Reward Schema Set"
                />
              </FormButtonsGroup>
            </PageHeader>

            <Label required text="Name">
              <Field type="text" name="name" />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <LabelsFormGroup disabled={false} />

            <PageSection title="Chapter Ranges" />
            <FormGroup name="chapterRangesAttributes">
              <ServerError name="list" as={Alert} />

              {React.Children.toArray(chapterRangesAttributes.map((rangeAttributes, index) => {
                if (rangeAttributes._destroy) return '';

                const {
                  _uuid,
                  position,
                  midchapterConfigsAttributes,
                } = rangeAttributes;

                const isLastRange = position === maxPosition;
                const isFirstRange = position === 0;

                const moveUp = async () => dispatch({ type: 'moveUp', index, meta });
                const moveDown = async () => dispatch({ type: 'moveDown', index, meta });

                return (
                  <Container>
                    <LeftActionsColumn>
                      <FaButton onClick={moveUp} disabled={index === 0}>
                        <FontAwesomeIcon icon={faChevronUp} />
                      </FaButton>
                      <FaButton
                        className="mt-1"
                        onClick={moveDown}
                        disabled={index === rangesCount - 1}
                      >
                        <FontAwesomeIcon icon={faChevronDown} />
                      </FaButton>
                    </LeftActionsColumn>
                    <ChapterRangeContainer>
                      <PageSection
                        size="lg"
                        title={`Chapter Range ${position + 1}`}
                        actions={(
                          <IconButton.Remove
                            disabled={notDestroyedRanges.length <= 1}
                            onClick={() => dispatch({ type: 'removeChapterRange', _uuid })}
                          >
                            {`Remove Chapter Range ${position + 1}`}
                          </IconButton.Remove>
                        )}
                      >
                        <FormGroup name={index}>
                          <Row>
                            <Col xs={6}>
                              <Label
                                text="From"
                                direction="vertical"
                                tooltipText={isFirstRange && (
                                  'First chapter range is always configured to be available from Chapter 1'
                                )}
                              >
                                <Field
                                  disabled
                                  name="fromChapter"
                                  type="number"
                                />
                              </Label>
                            </Col>
                            <Col xs={6}>
                              <Label
                                text="To"
                                direction="vertical"
                                tooltipText={isLastRange && (
                                  'The last chapter range is always applicable for all '
                                  + 'Chapters from "From" until the last'
                                )}
                              >
                                <Field
                                  name="toChapter"
                                  type="number"
                                  onChange={({ target: { value } }) => (
                                    dispatch({ type: 'toChapterChange', value, _uuid })
                                  )}
                                  placeholder={isLastRange && 'All next'}
                                  disabled={isLastRange}
                                />
                              </Label>
                            </Col>
                          </Row>
                          <PageSection title="Rewards" size="md" />
                          <Rewards
                            hideQuantityUntilSelected
                            dispatch={dispatch}
                            rewardable={rangeAttributes}
                            rewardItems={rewardOptions}
                          />
                          <MidchapterConfigs
                            attributes={midchapterConfigsAttributes}
                            dispatch={dispatch}
                            rewardOptions={rewardOptions}
                            chapterRangeUuid={_uuid}
                            chapterRangePosition={position}
                          />
                        </FormGroup>
                      </PageSection>
                    </ChapterRangeContainer>
                  </Container>
                );
              }))}
            </FormGroup>
            <IconButton.New
              onClick={() => {
                dispatch({
                  type: 'addChapterRange',
                  additionalAttributes: { midchapterConfigsAttributes: [], rewardAffiliationsAttributes: [] },
                });
              }}
            >
              Add Chapter Range
            </IconButton.New>
          </>
        );
      }}
    </Form>
  );
};

export default TileRewardSetForm;
