import React, { useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { Modal } from 'react-bootstrap';
import { useBlocker } from 'react-router-dom';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useGlobalLoading } from '@hooks';

import IconButton from '@controls/buttons';

export default function RouteLeavingGuard({
  when,
  onSave,
  onDiscard,
}) {
  const { location, push } = useRouter();
  const { withReload } = useGlobalLoading();
  const whenValue = when instanceof Function ? when() : when;
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      if (currentLocation.pathname === nextLocation.pathname) return false;

      return whenValue;
    });

  const closeModal = () => {
    blocker.reset?.();
  };

  const handleSaveClick = async () => {
    const response = await onSave();
    blocker.reset?.();
    if (response.status < 300) {
      withReload(() => push(blocker.location.pathname));
    }
  };

  const handleDiscardClick = () => {
    onDiscard();
    blocker.proceed?.();
  };

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === 'blocked' && !whenValue) {
      blocker.reset?.();
    }
  }, [blocker, whenValue]);

  return (
    <Modal
      show={blocker.state === 'blocked' && location.pathname !== blocker.location.pathname}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Review changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You have made changes. Do you want to discard or save them?
      </Modal.Body>
      <Modal.Footer>
        <IconButton.Cancel variant="secondary" onClick={closeModal} />
        <IconButton icon={faTimes} variant="danger" onClick={handleDiscardClick}>Discard</IconButton>
        <IconButton.Save variant="primary" onClick={handleSaveClick} />
      </Modal.Footer>
    </Modal>
  );
}
