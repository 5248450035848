import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { DuplicateButton, PageHeader, Rewards } from '@pages/common';
import { DailyRewardsRoutes as Routes } from '@pages/routes';
import ListWrapper from '@components/shared/ListWrapper';
import IconButton from '@controls/buttons';

import {
  FormButtonsGroup,
  StaticFieldsFormGroup,
  Label,
  Field,
  LabelsFormGroup,
} from '@controls/form';

import { Form, useRewardOptions } from '@hooks';
import { rewardableReducer, combineReducers } from '@reducers';

import entityIdentity from '@components/shared/entityIdentity';
import { persistedCollection } from '@services/utils';

import reducer from './formReducer';
import BulkCopyModal from './BulkCopyModal';

const MAX_REWARDS_AMOUNT = 4;

const rewardsSummary = ({ rewardAffiliationsAttributes }, rewardItems) => {
  const persistedRewards = persistedCollection(rewardAffiliationsAttributes);
  if (!rewardItems.length || !persistedRewards.length) { return null; }

  const rewardName = (itemId) => rewardItems.find(({ value }) => value === Number(itemId)).label;

  const rewardsToPresent = persistedRewards.reduce((memo, reward) => {
    if (reward.itemId) {
      const newQuantity = (memo[reward.itemId] || 0) + reward.quantity;

      return { ...memo, [reward.itemId]: newQuantity };
    }

    return memo;
  }, {});

  if (!Object.keys(rewardsToPresent).length) { return null; }

  const summary = Object.entries(rewardsToPresent).map(([itemId, quantity]) => (
    `${rewardName(itemId)} (${quantity})`
  )).join(', ');

  return (
    <span className="ms-2">
      {`Rewards: ${summary}`}
    </span>
  );
};

const EntityHeader = ({ entity, entityName, rewardItems }) => (
  <div className="w-100">
    <span>
      {entityIdentity({ entity: { ...entity, name: null }, entityName })}
    </span>
    {rewardsSummary(entity, rewardItems)}
  </div>
);

const buildEntityHeader = (rewardItems, props) => (<EntityHeader {...props} rewardItems={rewardItems} />);

export default function DailyRewardsBankForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const rewardItems = useRewardOptions();

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([reducer, rewardableReducer])}
    >
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Daily Rewards Bank`}>
            <FormButtonsGroup cancelButtonPath={Routes.Banks.indexPath({ applicationId })}>
              {data.id && (
                <>
                  <IconButton.ActivityLog
                    applicationId={applicationId}
                    id={data.id}
                    entityType="DailyRewards::Bank"
                  />
                  <FormButtonsGroup.Divider />
                </>
              )}
              <DuplicateButton
                routes={Routes.Banks}
                data={data}
                entityName="Daily Rewards Bank"
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label text="Name">
            <Field type="text" name="name" />
          </Label>

          <LabelsFormGroup />

          <StaticFieldsFormGroup data={data} />

          <ListWrapper
            entityAttributes={values.daysAttributes}
            EntityHeader={(props) => buildEntityHeader(rewardItems, props)}
            onEntityAdd={() => dispatch({ actionType: 'addEmptyEntity' })}
            entityNameTranslationPath="dailyRewards.bank.days.entityName"
            attributesName="daysAttributes"
            BulkCopyModal={BulkCopyModal}
            onBulkDuplicate={(entities) => dispatch({ actionType: 'bulkDuplicate', entities })}
          >
            {({ entity }) => (
              <Rewards
                dispatch={dispatch}
                rewardable={entity}
                maxRewards={MAX_REWARDS_AMOUNT}
                rewardItems={rewardItems}
                className="mb-3"
                hideQuantityUntilSelected
              />
            )}
          </ListWrapper>
        </>
      )}
    </Form>
  );
}
