import React from 'react';

import { Form, FormGroup, useRewardOptions } from '@hooks';
import { useRouter } from '@tripledotstudios/react-core';

import {
  Label, Field, FormButtonsGroup, StaticFieldsFormGroup,
} from '@controls/form';

import { WoodokuSagaRoutes as Routes } from '@pages/routes';
import { PageHeader, PageSection, Rewards } from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { localizedEnumValueString } from '@services/enums';

import { rewardableReducer } from '@reducers';

export default function LevelRewardSetForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();

  const rewardItems = useRewardOptions();
  const disabled = isInUse(data.inUse);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={rewardableReducer}
    >
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Level Reward Set`}>
            <FormButtonsGroup
              cancelButtonPath={Routes.LevelRewardSets.indexPath({ applicationId })}
            />
          </PageHeader>

          <Label text="Name" required>
            <Field type="text" name="name" disabled={disabled} />
          </Label>

          <StaticFieldsFormGroup data={data} />

          {values.levelsAttributes && values.levelsAttributes.map((level, index) => {
            const localizedDifficulty = localizedEnumValueString('WoodokuSaga::Difficulties', level.difficulty);

            return (
              <FormGroup name={`levelsAttributes[${index}]`}>
                <PageSection title={`${localizedDifficulty} Level Rewards`}>
                  <Field type="hidden" name="difficulty" />

                  <Rewards
                    dispatch={dispatch}
                    rewardable={level}
                    rewardItems={rewardItems}
                    readOnly={disabled}
                  />
                </PageSection>
              </FormGroup>
            );
          })}
        </>
      )}
    </Form>
  );
}
