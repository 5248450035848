import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Product',
    actionName: 'create',
    redirectPath: MergeRoutes.Products.indexPath({ applicationId: query.applicationId }),
    setData,
  });

  const onSubmit = (values) => (
    MergeRoutes.Products.createRequest({ ...values, applicationId: query.applicationId }).then(responseHandler)
  );

  useEffect(() => {
    MergeRoutes.Products.newRequest(query).then((response) => setData(response.data));
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} actionName="Create" />;
}
