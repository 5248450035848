import React from 'react';
import { JourneyRoutes } from '@pages/routes';
import DatesBasedIndex from '../shared/DatesBasedIndex';

const Index = () => (
  <DatesBasedIndex
    routes={JourneyRoutes.EndOfJourneyEvents}
    localePath="journeys.endOfJourneyEvents"
    massOperationsModel="Journeys::EndOfJourneyEvent"
    hiddenColumns={['startAt', 'endAt']}
  />
);

export default Index;
