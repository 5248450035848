import React, { useCallback, useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Label, SelectField } from '@controls/form';
import { ItemsSelectField } from '@components/merge/shared/items/Select';
import { useFormContext, FormGroup } from '@hooks';

import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { MergeRoutes } from '@pages/routes';

export default function ItemBasedProduct({ values, isNewRecord, children }) {
  const [items, setItems] = useState([]);

  const { dispatch } = useFormContext();
  const { query } = useRouter();
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(query.applicationId), []);

  useEffect(() => {
    if (!values.schemaId) return;

    MergeRoutes.WorldSchemas.editRequest({
      id: values.schemaId,
      applicationId: query.applicationId,
    }).then((response) => {
      setItems(response.data.items.map((item) => ({ value: item.id, label: item.internalId, line: item.line })));
    });
  }, [values.schemaId]);

  return (
    <>
      <Label text="World Schema">
        <SelectField
          name="schemaId"
          options={fetchSchemaOptions}
          onChange={() => dispatch({ type: 'reset_product_value' })}
          isDisabled={!isNewRecord}
        />
      </Label>
      <FormGroup name="rewardAttributes">
        <Label text="Item">
          <ItemsSelectField name="value" items={items} />
        </Label>
      </FormGroup>
      {children}
    </>
  );
}
