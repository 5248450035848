import React from 'react';

import { PageHeader } from '@pages/common';
import { Form, useI18n } from '@hooks';
import { Label, DateTimeField, FormButtonsGroup } from '@controls/form';
import GenerateAllocationButton from './GenerateAllocationsButton';

export default function SettingsForm({ data, onSubmit }) {
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  return data && (
    <Form initialValues={data} onSubmit={onSubmit}>
      {() => (
        <>
          <PageHeader title={`Edit ${namespaceName} Settings`} userGuideUrlKey="missionsGuideUrl">
            <FormButtonsGroup hideCancel>
              {data.isGenerateButtonVisible && <GenerateAllocationButton />}
            </FormButtonsGroup>
          </PageHeader>

          <Label text="Time of daily allocation (UTC)" fieldSize={8}>
            <DateTimeField
              name="dailyAllocationStartsAt"
              dateFormat={['h:mm aa']}
              showTimeSelectOnly
            />
          </Label>
        </>
      )}
    </Form>
  );
}
