import React from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';

import APP_DATA from '@services/appData';

import { BooleanIcon, PageSection } from '@pages/common';
import CopyToClipboardButton from '@controls/CopyToClipboardButton';
import { Collapsible } from '@controls/form';
import { useCurrentApplication } from '@hooks';
import { Alert } from '@tripledotstudios/react-core';

const cpmLabels = APP_DATA.enums['Ads::Configuration::MaxInterstitialsAnalyticLabels'];

const deviceIdfv = (device) => device.idfv || 'null';

const WrappedTd = styled.td`
  word-break: break-all;
`;

export default function DevicesInfo({ devices }) {
  const { currentApplication } = useCurrentApplication();
  const usesCpmStats = currentApplication.adsSupport
    && currentApplication.adsSupport.enableAds
    && currentApplication.adsSupport.enableAdsStatsCollection;

  if (!devices.length) {
    return (
      <Alert variant="warning">
        User has no devices assigned
      </Alert>
    );
  }

  return (
    <>
      {devices.map((device) => (
        <div className="mb-3">
          <Collapsible
            header={`ID: ${device.id}`}
            rightSideText={`IDFV: ${deviceIdfv(device)} | App Version: ${device.appVersion} `}
          >
            <div className="row">
              <div className={`col-${usesCpmStats ? 6 : 12}`}>
                <Table bordered striped key={device.id}>
                  <thead>
                    <tr>
                      <th colSpan="2">Device Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Id</th>
                      <td>{device.id}</td>
                    </tr>
                    <tr>
                      <th>Application</th>
                      <td>{device.application}</td>
                    </tr>
                    <tr>
                      <th>App Version</th>
                      <td>{device.appVersion}</td>
                    </tr>
                    <tr>
                      <th>Previous version</th>
                      <td>{device.previousAppVersion}</td>
                    </tr>
                    <tr>
                      <th>Install Mode</th>
                      <td>{device.installMode}</td>
                    </tr>
                    <tr>
                      <th>Install Version</th>
                      <td>{device.installVersion}</td>
                    </tr>
                    <tr>
                      <th>Install Id</th>
                      <td>
                        {device.installId}
                        <CopyToClipboardButton text={device.installId} />
                      </td>
                    </tr>
                    <tr>
                      <th>External Id</th>
                      <td>
                        {device.externalId}
                        <CopyToClipboardButton text={device.externalId} />
                      </td>
                    </tr>
                    <tr>
                      <th>Tester Device</th>
                      <td><BooleanIcon value={device.tester} /></td>
                    </tr>
                    <tr>
                      <th>Root Device</th>
                      <td><BooleanIcon value={device.snRoot} /></td>
                    </tr>
                    <tr>
                      <th>Session Id</th>
                      <td>{device.sessionId}</td>
                    </tr>
                    <tr>
                      <th>Token</th>
                      <td>
                        {device.token}
                        <CopyToClipboardButton text={device.token} />
                      </td>
                    </tr>
                    <tr>
                      <th>IDFA</th>
                      <td>
                        {device.idfa || 'null'}
                        <CopyToClipboardButton text={device.idfa} />
                      </td>
                    </tr>
                    <tr>
                      <th>IDFV</th>
                      <td>
                        {deviceIdfv(device)}
                        <CopyToClipboardButton text={device.idfv} />
                      </td>
                    </tr>
                    <tr>
                      <th>IP address</th>
                      <td>{device.ip}</td>
                    </tr>
                    <tr>
                      <th>Device Type</th>
                      <td>{device.type}</td>
                    </tr>
                    <tr>
                      <th>Platform</th>
                      <td>{device.osFamily}</td>
                    </tr>
                    <tr>
                      <th>OS Version</th>
                      <td>{device.os}</td>
                    </tr>
                    <tr>
                      <th>Build Variant</th>
                      <td>{device.buildVariant}</td>
                    </tr>
                    <tr>
                      <th>Model</th>
                      <td>{device.model}</td>
                    </tr>
                    <tr>
                      <th>RAM capacity (MB)</th>
                      <td>{device.ramCapacity}</td>
                    </tr>
                    <tr>
                      <th>Language code</th>
                      <td>{device.languageCode}</td>
                    </tr>
                    <tr>
                      <th>Media Source</th>
                      <WrappedTd>{device.mediaSource}</WrappedTd>
                    </tr>
                    <tr>
                      <th>Campaign Name</th>
                      <WrappedTd>{device.campaignName}</WrappedTd>
                    </tr>
                    <tr>
                      <th>Debug ID</th>
                      <td>{device.debugId}</td>
                    </tr>
                    <tr>
                      <th>Last visit at (UTC)</th>
                      <td>{device.lastVisitAt}</td>
                    </tr>
                    <tr>
                      <th>Previous visit at (UTC)</th>
                      <td>{device.previousVisitAt}</td>
                    </tr>
                    <tr>
                      <th>App version updated at (UTC)</th>
                      <td>{device.appVersionUpdatedAt}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              {usesCpmStats && (
                <div className="col-6">
                  <PageSection title="CPM Stats Info">
                    {Object.values(cpmLabels).map((label) => (
                      <Collapsible header={`Bucket ${label}`} className="mb-2">
                        <Table bordered>
                          <thead>
                            <tr>
                              <th colSpan="6">1</th>
                            </tr>
                            <tr className="table-active">
                              <th>ID</th>
                              <td>Is FB</td>
                              <td>CPM</td>
                              <th>ID</th>
                              <td>Is FB</td>
                              <td>CPM</td>
                            </tr>
                            { }
                            {[...Array(25).keys()].map((index) => {
                              const cpmStat = device.cpmStats[label][index] || ['', ''];
                              const nextPageCpmStat = device.cpmStats[label][index + 25] || ['', ''];
                              return (
                                <tr className={index % 2 === 0 ? '' : 'table-active'}>
                                  <th>{index + 1}</th>
                                  <td>{cpmStat[0]}</td>
                                  <td>{cpmStat[1]}</td>
                                  <th>{index + 26}</th>
                                  <td>{nextPageCpmStat[0]}</td>
                                  <td>{nextPageCpmStat[1]}</td>
                                </tr>
                              );
                            })}
                          </thead>
                        </Table>
                      </Collapsible>
                    ))}
                  </PageSection>
                </div>
              )}
            </div>
          </Collapsible>
        </div>
      ))}
    </>
  );
}
