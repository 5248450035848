import React from 'react';
import { Row, Col } from 'react-bootstrap';

import HomeCard from './HomeCard';
import findShortcuts from './shortcuts/finder';
import ShortcutLink from './ShortcutLink';

export default function Shortcuts({ application, className }) {
  const shortcutsSet = findShortcuts(application);
  if (!shortcutsSet) return null;

  return (
    <HomeCard title="Shortcuts" className={className}>
      <Row>
        {shortcutsSet.map((shortcutsColumn, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col sm={12} md={4} key={index}>
            {shortcutsColumn.map(({
              color, icon, title, link, externalLink,
            }) => (
              <ShortcutLink
                key={title}
                application={application}
                color={color}
                icon={icon}
                title={title}
                link={link}
                externalLink={externalLink}
              />
            ))}
          </Col>
        ))}
      </Row>
    </HomeCard>
  );
}
