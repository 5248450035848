import React from 'react';

import { useCurrentApplication } from '@hooks';
import MergeManualReward from './merge/ManualReward';
import GameItemBasedReward from './GameItemBasedReward';

export default function ManualReward() {
  const { currentApplication: { type } } = useCurrentApplication();

  if (type === 'Merge::Application') return <MergeManualReward />;

  return <GameItemBasedReward />;
}
