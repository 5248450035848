import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { formResponseHandler } from '@requests/responseHandlers';
import { WoodokuRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const { response, setResponse } = useQuery(Routes.FigureSetDynamicConfigs.newRequest);
  const { query: { applicationId } } = useRouter();
  const responseHandler = formResponseHandler({
    entityName: 'Figure Set Dynamic Config',
    actionName: 'creation',
    setData: setResponse,
  });
  const onSubmit = (values) => Routes
    .FigureSetDynamicConfigs
    .createRequest({ ...values, applicationId })
    .then(responseHandler);

  return response && <Form data={response} onSubmit={onSubmit} actionName="Create" />;
}
