import React from 'react';
import { Table } from 'react-bootstrap';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { getDailyChallengeConfigs, deleteDailyChallengeConfig } from '@requests/blackjack/dailyChallengeConfigs';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { PageHeader, Pagination } from '@pages/common';
import IconButton from '@controls/buttons';

export default function Index() {
  const { response, refetch } = useQuery(getDailyChallengeConfigs);
  const { query } = useRouter();
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Daily challenge config',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Daily challenge config '${name}' will be deleted and not available for work. Continue?`,
    }).then(() => deleteDailyChallengeConfig({ applicationId: query.applicationId, id }).then(responseHandler));
  };

  return response ? (
    <div>
      <PageHeader title="Daily Challenge Configs">
        <IconButton.New to="new" />
      </PageHeader>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {response.items.map(({ id, name }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{name}</td>
              <td>
                <ButtonToolbar>
                  <IconButton.Edit to={`${id}/edit`} minimized />
                  <IconButton.Delete onClick={onDelete(id, name)} minimized />
                </ButtonToolbar>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  ) : '';
}
