import React from 'react';
import { useTheme } from 'styled-components';
import {
  faListOl,
  faGlobeAmericas,
  faArrowAltCircleUp,
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { SpiderSolitaireRoutes, StreaksRoutes } from '@pages/routes';

import {
  ApplicationItemSet,
  AssetsNavigationItem,
  MissionsNavigationSection,
  AdsNavigationSection,
  RewardsNavigationItem,
} from './common';

export default function SpiderSolitaire({ routingScope, applicationId, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="spider_solitaire"
      routingScope={routingScope}
      minimized={minimized}
    >
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />

      <Sidebar.LinkItem
        to={`${routingScope}/spider_solitaire/player_levels/sets`}
        icon={faListOl}
        title="Player Levels Sets"
        color={theme.purple}
        minimized={minimized}
      />

      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />

      <Sidebar.LinkItem
        to={SpiderSolitaireRoutes.WorldStatsConfig.indexPath({ applicationId })}
        icon={faGlobeAmericas}
        title="World Statistics"
        color={theme.cornflowerBlue}
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to={StreaksRoutes.Entities.indexPath({ applicationId })}
        icon={faArrowAltCircleUp}
        title="Streaks"
        color={theme.lightGreen}
        minimized={minimized}
      />

      <MissionsNavigationSection applicationId={applicationId} minimized={minimized} />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
