import React from 'react';

import {
  GameSettingsTabs,
  adsControlDefaultFieldNames,
  localizationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  popupsDefaultFieldNames,
  analyticsDefaultFieldNames,
  bakedInConfigsTab,
  popupsDefaultArchivedFieldNames,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
} from '@components/game-settings/Resource';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'playerInitialBalance',
      'denominationCreditPercentage',
      'deckYellowCardMinRange',
      'deckYellowCardMaxRange',
      'catalogueOn',
      'tableConfigId',
      'dailyChallengeConfigId',
      'handsBetweenTableSurfacing',
      'tableSurfacingForHands',
      'promptForReviewAfterHands',
      'resurfaceGetChipsAdPopupOnStoreClose',
      ...assetsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      ...adPlacementIdsDefaultFieldNames,
      ...adConfigsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#mercy',
    title: 'Mercy',
    fieldsNames: [
      'gamesNumberWithMercy',
      'mercyChanceForFirstGames',
      'mercyData',
    ],
  },
  {
    eventKey: '#hints',
    title: 'Hints',
    fieldsNames: [
      'autoHintTimeValue',
      'hintTooltipIdle',
      'hintTooltipEventNumsPerUser',
    ],
  },
  {
    eventKey: '#notifications',
    title: 'Notifications',
    fieldsNames: [
      'showNotificationPopup',
      'notificationPromptAfterHands',
      'notificationChipRewards',
    ],
  },
  {
    eventKey: '#daily_challenge',
    title: 'Daily challenge',
    fieldsNames: [
      'dailyChallengeOn',
      'dailyChallengeAdOnFirstAttemptOfTodays',
      'dailyChallengeAdOnSubsequentAttemptOfTodays',
      'dailyChallengeAdOnFirstAttemptOfPreviousDays',
      'dailyChallengeAdOnSubsequentAttemptOfPreviousDays',
      'dailyChallengeReplayFromStart',
      'dailyChallengeStraightToToday',
      'dailyChallengeReward',
      'dailyChallengeDealsToSurface',
      'dailyChallengeDealsToSurfaceOnFirstSession',
      'notificationPromptAfterDailyChallenges',
    ],
  },
  {
    eventKey: '#player_settings',
    title: 'Player Settings',
    fieldsNames: [
      'defaultsSoundEnabled',
      'defaultsInsuranceEnabled',
      'defaultsHintsEnabled',
      'defaultsAutoHintsEnabled',
      'defaultsDealerHitsOnSoftSeventeenEnabled',
      'defaultsDecksCount',
      'defaultsLanguage',
      'settingsDefaultsNotificationDailyBonus',
      'settingsDefaultsNotificationDailyChallenge',
      'settingsDefaultsNotificationSpecialUpdates',
      'settingsDefaultsMusic',
      'settingsDefaultsAmbientNoise',
    ],
  },
  {
    eventKey: '#ads',
    title: 'Ads',
    fieldsNames: [
      'adCallTiming',
      'adFrequency',
      'adFrequencyCoefficient',
      'adProbabilityLogisticFunctionK',
      'minimumDealsBetweenAds',
      'amountOfCoinsPerVideo',
      'amountOfCoinsWithoutAds',
      'timerForCoinsWithoutAds',
      'adsUseAdFreqAsImpressionPerHands',
      'adsSkipAdOnBlackjack',
      'adsSkipAdWhenBalanceLessThenBet',
      'adsSkipAdWhenUserGotLevelUp',
    ],
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: popupsDefaultFieldNames,
  },
  {
    eventKey: '#ads_control',
    title: 'Ads Control',
    fieldsNames: adsControlDefaultFieldNames,
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: analyticsDefaultFieldNames,
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: popupsDefaultArchivedFieldNames,
  },
];

export default function Tabs({
  setContractType, fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      onChange={setContractType}
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
