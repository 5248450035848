import React from 'react';

import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import colors from './colors';

const StyledButton = styled(Button)`
  background-color: ${({ color }) => `${color}D9`};
  border-color: ${({ color }) => color} !important;
  :hover,
  :active,
  :visited,
  :focus,
  :disabled {
    background-color: ${({ color }) => color} !important;
  }
`;

export default function DeleteButton({
  nestingLevel, onClick, disabled, ...buttonProps
}) {
  return (
    <StyledButton
      className="pull-end"
      color={colors[nestingLevel]}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      <FontAwesomeIcon icon={faTrashAlt} />
    </StyledButton>
  );
}
