import React from 'react';

import { FormGroup } from '@hooks';
import { Field, LabeledField } from '@controls/form';

export default function CoinsReward({ rewardAttributes, displayPreDiscountAmount }) {
  if (!rewardAttributes) return '';

  const { amount, preDiscountAmount } = rewardAttributes;

  const PreDiscountAmountBlock = displayPreDiscountAmount ? (
    <LabeledField type="number" name="preDiscountAmount" label="Pre Discount Amount" value={preDiscountAmount} />
  ) : '';

  return (
    <FormGroup name="coinsRewardAttributes">
      <Field type="hidden" name="id" />
      <Field type="hidden" name="type" value="Payments::Rewards::CoinsReward" />
      <LabeledField type="number" name="amount" label="Coins Amount" value={amount} />
      {PreDiscountAmountBlock}
    </FormGroup>
  );
}
