import React from 'react';
import { PageTitle } from '@pages/common';

import { JourneysExperimentScoped } from '@pages/sudoku';
import { SudokuRoutes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function sudoku() {
  return [
    <Route
      key={SudokuRoutes.Journeys.EventConfigs.experimentScopedRawPath}
      path={SudokuRoutes.Journeys.EventConfigs.experimentScopedRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Journey variant" />
          <JourneysExperimentScoped />
        </RouteContainer>
      )}
    />,
  ];
}
