import { reorderList } from '@services/reorder';
import { map } from 'lodash';

export default function reorderEntities(list, positionAttribute, startIndex, endIndex) {
  let index = 1;

  const entities = reorderList(list, startIndex, endIndex);
  const resultEntities = entities.map((element) => {
    const entity = element;
    entity[positionAttribute] = entity._destroy ? entity[positionAttribute] : index;

    if (!entity._destroy) index += 1;

    return entity;
  });

  const uuids = map(list, '_uuid');
  const reorderedUuids = map(resultEntities, '_uuid');

  const [oldPositions, newPositions] = uuids.reduce((memo, uuid) => {
    memo[0].push(uuids.indexOf(uuid));
    memo[1].push(reorderedUuids.indexOf(uuid));

    return memo;
  }, [[], []]);

  return { result: resultEntities, changeSet: [oldPositions, newPositions] };
}
