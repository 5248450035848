import React from 'react';
import { Modal } from 'react-bootstrap';
import { upperFirst } from 'lodash';

import { WooAspenRoutes as Routes } from '@pages/routes';

import { Form, useCurrentApplication, useQuery } from '@hooks';
import { Label, Field, LabelsFormGroup } from '@controls/form';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { prepareInboundData } from '@requests/common';
import IconButton from '@controls/buttons';

const ENTITY_NAME = 'LiveOps Token';

export default function EditModal({ record, onHide, refetch }) {
  if (!record) return null;

  const isUpdate = Boolean(record?.id);
  const actionName = isUpdate ? 'update' : 'create';
  const { currentApplication } = useCurrentApplication();

  const { disabled } = record;

  const {
    newRequest,
    createRequest,
    editRequest,
    updateRequest,
  } = Routes.LiveOpsTokens;

  const fetchRequest = (params) => {
    if (isUpdate) return editRequest({ ...params, id: record.id });

    return newRequest(params);
  };

  const submitForm = (values) => {
    if (isUpdate) return updateRequest({ ...values, id: record.id });

    return createRequest(values);
  };

  const { response: data, setResponse } = useQuery(fetchRequest);
  const fullData = { ...record, ...data, applicationId: currentApplication.id };

  const responseHandler = collectionResponseHandlerFactory({
    actionName,
    refetch,
    entityName: ENTITY_NAME,
  });

  const onSubmit = async (values) => {
    const response = await submitForm(values);
    responseHandler(response);
    if (response.status < 300) {
      onHide();
    } else {
      setResponse((currentValues) => ({ ...currentValues, ...values, ...prepareInboundData(response.data) }));
    }
  };

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{`${upperFirst(actionName)} ${ENTITY_NAME}`}</Modal.Title>
      </Modal.Header>
      {data && (
        <Form
          initialValues={fullData}
          onSubmit={onSubmit}
        >
          {() => (
            <>
              <Modal.Body>
                <Label required text="Name">
                  <Field name="name" disabled={disabled} />
                </Label>
                <Label required text="Key">
                  <Field name="key" disabled={disabled} />
                </Label>

                <LabelsFormGroup />
              </Modal.Body>
              <Modal.Footer>
                <IconButton.Save type="submit" />
                <IconButton.Cancel onClick={onHide} />
              </Modal.Footer>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
}
