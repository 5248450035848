import React from 'react';

import { Label, Field } from '@controls/form';
import { FormGroup } from '@hooks';

export default function SolitaireRewardTab() {
  return (
    <FormGroup name="solitaireRewardAttributes">
      <Label text="ID" fieldSize={8}>
        Primary
      </Label>
      <Label text="Combo tap base normalizer" fieldSize={8}>
        <Field type="number" name="comboTapBaseNormalizer" />
      </Label>
      <Label text="Combo increment reward coin percentage" fieldSize={8}>
        <Field type="number" name="comboIncrementRewardCoinPercentage" />
      </Label>
      <Label text="Deck rewardc coin percentage" fieldSize={8}>
        <Field type="number" name="deckRewardCoinPercentage" />
      </Label>
      <Label text="Win bonus calculation percent collected coin" fieldSize={8}>
        <Field type="number" name="winBonusCalculationPercentCollectedCoin" />
      </Label>
      <Label text="Win bonus minimum reward coin" fieldSize={8}>
        <Field type="number" name="winBonusMinimumRewardCoin" />
      </Label>
      <Label text="Win bonus max random entry cost percentage" fieldSize={8}>
        <Field type="number" name="winBonusMaxRandomEntryCostPercentage" />
      </Label>
    </FormGroup>
  );
}
