import APP_DATA from '@services/appData';
import deepFreeze from '@services/deepFreeze';

import BuildVariantRule from './collection/BuildVariantRule';
import CountryRule from './collection/CountryRule';
import CityRule from './collection/CityRule';
import CohortDayRule from './collection/CohortDayRule';
import UserIdsRule from './collection/UserIdsRule';
import PlatformRule from './collection/PlatformRule';
import GameVersionRule from './collection/GameVersionRule';
import InstallVersionRule from './collection/InstallVersionRule';
import LatUserRule from './collection/LatUserRule';
import OsVersionRule from './collection/OsVersionRule';
import LanguageRule from './collection/LanguageRule';
import InstallDateRule from './collection/InstallDateRule';
import InstallDateLocalTimezoneRule from './collection/InstallDateLocalTimezoneRule';
import IdfaRule from './collection/IdfaRule';
import IdfvRule from './collection/IdfvRule';
import DeviceModelRule from './collection/DeviceModelRule';
import ExperimentVariantRule from './collection/ExperimentVariantRule';
import PlayerBucketRule from './collection/PlayerBucketRule';
import MediaSourceRule from './collection/MediaSourceRule';
import CampaignNameRule from './collection/CampaignNameRule';
import LastPaymentAmountRule from './collection/LastPaymentAmountRule';
import TotalPaymentAmountRule from './collection/TotalPaymentAmountRule';
import DaysSinceLastPaymentRule from './collection/DaysSinceLastPaymentRule';
import SessionsCountRule from './collection/SessionsCountRule';
import DebugIdsRule from './collection/DebugIdsRule';
import DeviceTypeRule from './collection/DeviceTypeRule';
import DaysSincePreviousVisitRule from './collection/DaysSincePreviousVisitRule';
import PreviousGameVersionRule from './collection/PreviousGameVersionRule';
import DaysSinceVersionUpdateRule from './collection/DaysSinceVersionUpdateRule';
import VersionUpdateDateRule from './collection/VersionUpdateDateRule';
import RamCapacityRule from './collection/RamCapacityRule';
import PlayerSegmentRule from './collection/PlayerSegmentRule';
import NamedGroupRule from './collection/NamedGroupRule';
import DaysSinceStartAtRule from './collection/DaysSinceStartAtRule';

const DEFAULT_TAG = 'all';
const AB_VARIANT_ONLY_TAG = 'ab_variant_only';
const NAMED_GROUP_RULE_TAG = 'named_rule_group';

const { enums: { RuleOperations } } = APP_DATA;

const RULES_LIST = deepFreeze([
  {
    type: 'Rules::BuildVariantRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'Build Variant',
    component: BuildVariantRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::CountryRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'Country',
    component: CountryRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::CityRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'City',
    component: CityRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::CohortDayRule',
    operations: [
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
      RuleOperations.EQUAL,
    ],
    label: 'Cohort day',
    component: CohortDayRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::UserIdsRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
    ],
    label: 'User ids',
    component: UserIdsRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::PlatformRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'Platform',
    component: PlatformRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::GameVersionRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
    ],
    label: 'Current game version',
    component: GameVersionRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::InstallVersionRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
    ],
    label: 'Install game version',
    component: InstallVersionRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::LatUserRule',
    options: [
      { label: 'IS', value: RuleOperations.IN },
      { label: 'IS NOT', value: RuleOperations.NOT_IN },
    ],
    label: 'LAT user',
    component: LatUserRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::OsVersionRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
    ],
    label: 'OS version',
    component: OsVersionRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::LanguageRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'Language',
    component: LanguageRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::InstallDateRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER_OR_EQUAL,
    ],
    label: 'Install date (UTC)',
    component: InstallDateRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::InstallDateLocalTimezoneRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER_OR_EQUAL,
    ],
    label: 'Install date (Local Timezone)',
    component: InstallDateLocalTimezoneRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::IdfaRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'IDFA',
    component: IdfaRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::IdfvRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'IDFV',
    component: IdfvRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::DeviceModelRule',
    operations: [
      RuleOperations.LIKE,
      RuleOperations.NOT_LIKE,
    ],
    label: 'Device model',
    component: DeviceModelRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::ExperimentVariantRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'Experiment variant',
    component: ExperimentVariantRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::PlayerBucketRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'Player bucket',
    component: PlayerBucketRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::MediaSourceRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
      RuleOperations.BLANK,
      RuleOperations.PRESENT,
    ],
    label: 'Media source',
    component: MediaSourceRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::CampaignNameRule',
    operations: [
      RuleOperations.CONTAINS,
      RuleOperations.DOES_NOT_CONTAIN,
      RuleOperations.BLANK,
      RuleOperations.PRESENT,
    ],
    label: 'Campaign name',
    component: CampaignNameRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::TotalPaymentAmountRule',
    operations: [
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
      RuleOperations.EQUAL,
    ],
    label: 'Total payment amount',
    component: TotalPaymentAmountRule,
    tags: ['merge', 'blackjack', 'my_pet_cafe', 'match3d', 'tile', 'woodoku', 'woodoku_saga'],
  },
  {
    type: 'Rules::LastPaymentAmountRule',
    operations: [
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
      RuleOperations.EQUAL,
    ],
    label: 'Last payment amount',
    component: LastPaymentAmountRule,
    tags: ['merge', 'blackjack', 'my_pet_cafe', 'match3d', 'tile', 'woodoku', 'woodoku_saga'],
  },
  {
    type: 'Rules::DaysSinceLastPaymentRule',
    operations: [
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
      RuleOperations.EQUAL,
    ],
    label: 'Days since last payment',
    component: DaysSinceLastPaymentRule,
    tags: ['merge', 'blackjack', 'my_pet_cafe', 'match3d', 'tile', 'woodoku', 'woodoku_saga'],
  },
  {
    type: 'Rules::SessionsCountRule',
    operations: [
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
      RuleOperations.EQUAL,
    ],
    label: 'Sessions count',
    component: SessionsCountRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::DebugIdsRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'Debug ids',
    component: DebugIdsRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::DeviceTypeRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'Device type',
    component: DeviceTypeRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::DaysSincePreviousVisitRule',
    operations: [
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
      RuleOperations.EQUAL,
    ],
    label: 'Days since previous visit',
    component: DaysSincePreviousVisitRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::PreviousGameVersionRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
    ],
    label: 'Previous game version',
    component: PreviousGameVersionRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::DaysSinceVersionUpdateRule',
    operations: [
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
      RuleOperations.EQUAL,
    ],
    label: 'Days since version update',
    component: DaysSinceVersionUpdateRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::VersionUpdateDateRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER_OR_EQUAL,
    ],
    label: 'Version update date (UTC)',
    component: VersionUpdateDateRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::RamCapacityRule',
    operations: [
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
      RuleOperations.EQUAL,
    ],
    label: 'Device RAM capacity',
    component: RamCapacityRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::PlayerSegmentRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'Player Segment',
    component: PlayerSegmentRule,
    tags: [DEFAULT_TAG],
  },
  {
    type: 'Rules::NamedGroupRule',
    operations: [
      RuleOperations.IN,
      RuleOperations.NOT_IN,
    ],
    label: 'Named Group Rule',
    component: NamedGroupRule,
    tags: [DEFAULT_TAG, NAMED_GROUP_RULE_TAG],
  },
  {
    type: 'Rules::DaysSinceStartAtRule',
    operations: [
      RuleOperations.GREATER_OR_EQUAL,
      RuleOperations.LESS_OR_EQUAL,
      RuleOperations.GREATER,
      RuleOperations.LESS,
      RuleOperations.EQUAL,
    ],
    label: 'Days since start at',
    component: DaysSinceStartAtRule,
    tags: [AB_VARIANT_ONLY_TAG],
  },
]);

export {
  RULES_LIST,
  AB_VARIANT_ONLY_TAG,
  DEFAULT_TAG,
  NAMED_GROUP_RULE_TAG,
};
