import React from 'react';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from 'styled-components';
import { Sidebar } from '@tripledotstudios/react-core';

export default function PaymentsNavigationSection({ routingScope, catalogs = true, minimized = false }) {
  const theme = useTheme();

  return (
    <Sidebar.SectionItem icon={faMoneyBill} title="Payments" color={theme.lightGreen} minimized={minimized}>
      {catalogs && (
        <Sidebar.LinkItem
          to={`${routingScope}/payments/catalogs`}
          title="Catalogs"
          params={{ filter: 'default' }}
        />
      )}
      <Sidebar.LinkItem
        to={`${routingScope}/payments/store_products`}
        title="Store Products"
      />
      <Sidebar.LinkItem
        to={`${routingScope}/payments/product_images`}
        title="Product Images"
      />
      <Sidebar.LinkItem
        to={`${routingScope}/payments/products`}
        title="Products"
      />
    </Sidebar.SectionItem>
  );
}
