import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { formResponseHandler } from '@requests/responseHandlers';

import Form from './Form';

export default function CreateUpdateComponent({
  actionName,
  getFunction,
  postFunction,
}) {
  const { response, setResponse } = useQuery(getFunction);
  const { query: { applicationId } } = useRouter();

  const responseHandler = formResponseHandler({
    entityName: 'Reward set',
    setData: setResponse,
    actionName,
  });
  const onSubmit = (values) => postFunction({ ...values, applicationId }).then(responseHandler);

  return response && <Form data={response} onSubmit={onSubmit} />;
}
