import React from 'react';
import { Alert } from '@tripledotstudios/react-core';

import { FormGroup } from '@hooks';
import { PageSection, Rewards } from '@pages/common';
import { ServerError } from '@controls/form';
import IconButton from '@controls/buttons';

const TileRewardSetMidchapterConfigs = ({
  attributes, dispatch, rewardOptions, chapterRangeUuid, chapterRangePosition,
}) => (
  <>
    <PageSection title="Midchapter Rewards" className="mt-3" size="md" />
    <FormGroup name="midchapterConfigsAttributes">
      {attributes && React.Children.toArray(attributes.map((configAttributes, index) => {
        if (configAttributes._destroy) return '';

        const {
          _uuid,
          position,
        } = configAttributes;

        return (
          <PageSection
            disabled
            size="sm"
            title={`${chapterRangePosition + 1}.${position + 1}`}
            actions={(
              <IconButton.Remove
                onClick={() => dispatch({ type: 'removeMidchapterConfig', chapterRangeUuid, _uuid })}
              >
                {`Remove ${chapterRangePosition + 1}.${position + 1}`}
              </IconButton.Remove>
            )}
          >
            <FormGroup name={index}>
              <PageSection title="Rewards" />
              <ServerError name="rewardAffiliationsAttributes.list" as={Alert} />
              <Rewards
                hideQuantityUntilSelected
                dispatch={dispatch}
                rewardable={configAttributes}
                rewardItems={rewardOptions}
              />
            </FormGroup>
          </PageSection>
        );
      }))}
      <IconButton.New onClick={() => dispatch({ type: 'addMidchapterConfig', chapterRangeUuid })}>
        Add Midchapter Reward
      </IconButton.New>
    </FormGroup>
  </>
);

export default TileRewardSetMidchapterConfigs;
