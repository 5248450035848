import React from 'react';
import { Alert, Spinner } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { AuditRoutes } from '@pages/routes';
import Versions from '@pages/audit-logs/Versions';
import APP_DATA from '@services/appData';

import HomeCard from './HomeCard';

const VersionsList = ({ application }) => {
  const { applications } = APP_DATA;
  const { isLoading, response } = useQuery(AuditRoutes.Versions.latestRequest, { applicationId: application.id });

  if (isLoading || !applications) return <Spinner />;

  return (
    response?.items?.length ? (
      <Versions versions={response.items} applications={applications} />
    ) : (
      <Alert variant="info" className="mb-0">
        <b>There are no latest changes</b>
      </Alert>
    )
  );
};

export default function LatestChanges({ application, className }) {
  return (
    <HomeCard
      title="Latest Changes"
      tooltip="Only 10 latest changes for Live and Active entities are shown"
      className={className}
      key={application.id}
    >
      <VersionsList application={application} />
    </HomeCard>
  );
}
