import React, { memo } from 'react';
import { Container, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import isEqualProps from '@services/isEqualProps';
import { GameConfigEntity as DndType } from '@types/dragNDrop';
import localizeField from '@services/fields-factory/localizeField';

import IconButton from '@controls/buttons';

import { buildModelBasedQuickLink } from '@root/services/fields-factory/quickLinksUtils';
import LabeledField from '../LabeledField';
import ServerError from '../ServerError';

const DragHandle = styled(FontAwesomeIcon)`
  cursor: grab;
  margin-top: 10px;
`;
const Position = styled.div`
  padding: 5px 10px;
  font-weight: bold;
`;

function ArrayFieldEntity({
  index,
  collectionName,
  fieldsConfiguration,
  disableLabel,
  isHorizontal,
  arrayHelpers,
  reorderable,
  handleOnly,
  showPosition,
  disabled,
  meta,
  tooltips = {},
  localeNamespace,
  collectionLocale = {},
  tooltipBuilder,
  setKeys,
}) {
  const [, drag, dragPreview] = useDrag({ type: DndType, item: { index } });

  /* eslint-disable no-param-reassign */
  const drop = useDrop({
    accept: DndType,
    drop: (item) => {
      const oldIndex = item.index;
      const newIndex = index;

      if (meta.errors) {
        const newMeta = { errors: { [arrayHelpers.name]: {} } };

        newMeta.errors[arrayHelpers.name][newIndex] = meta.errors[arrayHelpers.name][oldIndex];
        newMeta.errors[arrayHelpers.name][oldIndex] = meta.errors[arrayHelpers.name][newIndex];

        meta.errors = { ...meta.errors, ...newMeta.errors };
      }
      arrayHelpers.move(oldIndex, newIndex);
      setKeys((previous) => {
        const buff = previous[oldIndex];
        previous[oldIndex] = previous[newIndex];
        previous[newIndex] = buff;
        return previous;
      });
    },
  })[1];

  const onRemove = () => {
    arrayHelpers.remove(index);
    setKeys((previous) => {
      previous.splice(index, 1);
      return previous;
    });
  };
  /* eslint-enable no-param-reassign */

  const resolvedName = (name, i) => (name ? `${collectionName}.${i}.${name}` : `${collectionName}.${i}`);
  const handleRef = (node) => {
    drop(node);
    if (handleOnly) {
      dragPreview(node);
    } else {
      drag(node);
    }
  };
  const renderPosition = () => <Position>{index}</Position>;
  const isSingleField = fieldsConfiguration.length === 1;

  return (
    <Container className="bg-light mb-3 card-body d-flex px-5 py-4" ref={reorderable ? handleRef : null}>
      {reorderable && (
        <div ref={reorderable && handleOnly ? drag : null}>
          <DragHandle icon={faExchangeAlt} className="pe-3" />
        </div>
      )}
      <ServerError name={resolvedName('common', index)} className="ms-3 mb-2" />
      {showPosition && renderPosition()}
      <div className={`d-flex flex-grow-1 flex-${isSingleField ? 'row' : 'column'}`}>
        {fieldsConfiguration.map(({
          as, type, name, label, componentData, isMultiSelect, step, componentFn, appendText, includeEmptyOption,
          hasPrepopulatedOption, disabledFn, quickLinkFn, quickLinkModel = null, inputs,
        }) => {
          const resolvedType = type || 'text';

          const { label: labelText, tooltip, clientKey } = localizeField(
            name, label, tooltips, localeNamespace, collectionLocale[name], tooltipBuilder,
          );

          const renderField = () => (
            <LabeledField
              key={label}
              className="w-auto flex-grow-1"
              as={as}
              type={resolvedType}
              name={resolvedName(name, index)}
              label={labelText}
              step={step}
              appendText={appendText}
              componentData={componentData}
              isMultiSelect={isMultiSelect}
              disableLabel={disableLabel}
              disabled={disabled}
              disabledFn={disabledFn}
              componentFn={componentFn}
              tooltipText={tooltip}
              includeEmptyOption={includeEmptyOption}
              hasPrepopulatedOption={hasPrepopulatedOption}
              clientKey={clientKey}
              quickLinkFn={quickLinkModel ? buildModelBasedQuickLink(quickLinkModel) : quickLinkFn}
              inputs={inputs}
            />
          );

          return (isHorizontal ? renderField() : (<Col className="d-inline" key={label}>{renderField()}</Col>));
        })}
        {!disabled && (
          <IconButton.Remove
            onClick={onRemove}
            className={`btn-sm ms-3 align-self-${isSingleField ? 'baseline' : 'end'}`}
          />
        )}
      </div>
    </Container>
  );
}

export default memo(ArrayFieldEntity, isEqualProps(['index', 'meta', 'disabled', 'fieldsConfiguration']));
