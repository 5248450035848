import React from 'react';
import styled, { useTheme } from 'styled-components';
import { rgba } from 'polished';
import { Card } from 'react-bootstrap';

// To add transparancy to the hex based color we can add a number to the end of the string
//
const StyledCard = styled(Card)`
  border-left: 10px solid ${({ color }) => rgba(color, 0.4)};
`;

export default function ColoredCard({
  variant = null, color = null, children, ...rest
}) {
  const theme = useTheme();
  const resolvedColor = color || theme.variants[variant];

  return (
    <StyledCard color={resolvedColor} {...rest}>
      {children}
    </StyledCard>
  );
}
