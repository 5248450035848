import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { createNewGamePopupConfig, newNewGamePopupConfig } from '@requests/puzzle-time/newGamePopupConfigs';

import Form from '@pages/puzzle-time/GameConfigForm';

export default function New() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const indexPath = `/admin/applications/${query.applicationId}/puzzle_time/new_game_popup_configs`;

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'New game popup config',
    actionName: 'creation',
    redirectPath: indexPath,
    setData,
  });

  const onSubmit = (values) => createNewGamePopupConfig(values).then(redirectIfSuccessHandler);

  useEffect(() => {
    newNewGamePopupConfig(query).then((response) => setData(response.data));
  }, []);

  return data ? (
    <Form
      data={data}
      onSubmit={onSubmit}
      actionName="Create"
      indexPath={indexPath}
      configName="Game Popup Config"
    />
  )
    : '';
}
