import { memoize } from 'lodash';
import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';

import { fieldBuilder, selectBuilder, fieldArrayBuilder } from '@services/fields-factory';
import selectOptionsWithQuickLink from '@services/selectOptionsWithQuickLink';
import { BlackjackRoutes } from '@pages/routes';

const adCallTimingOptions = [{ label: 'Before hand', value: 0 }, { label: 'First decision point', value: 1 }];
const defaultsDecksCountOptions = [
  { label: '2', value: 2 },
  { label: '4', value: 4 },
  { label: '6', value: 6 },
  { label: '8', value: 8 },
];
const defaultsLanguageOptions = [{ label: 'English', value: 0 }, { label: 'Japanese', value: 1 }];

const Fields = memoize(({ dailyChallengeOptions, tableConfigOptions }) => ({
  ...fieldBuilder({ name: 'playerInitialBalance', type: 'number' }),
  ...fieldBuilder({ name: 'adFrequency', type: 'number' }),
  ...fieldBuilder({ name: 'adFrequencyCoefficient', type: 'number' }),
  ...fieldBuilder({ name: 'amountOfCoinsPerVideo', type: 'number' }),
  ...fieldBuilder({ name: 'amountOfCoinsWithoutAds', type: 'number' }),
  ...fieldBuilder({ name: 'timerForCoinsWithoutAds', type: 'number' }),
  ...fieldBuilder({ name: 'denominationCreditPercentage', type: 'number' }),
  ...fieldBuilder({ name: 'deckYellowCardMinRange', type: 'number' }),
  ...fieldBuilder({ name: 'deckYellowCardMaxRange', type: 'number' }),
  ...fieldBuilder({ name: 'showNotificationPopup', type: 'checkbox' }),
  ...fieldBuilder({ name: 'catalogueOn', type: 'checkbox' }),
  ...fieldBuilder({ name: 'autoHintTimeValue', type: 'number' }),
  ...fieldBuilder({ name: 'hintTooltipIdle', type: 'number' }),
  ...fieldBuilder({ name: 'gamesNumberWithMercy', type: 'number' }),
  ...fieldBuilder({ name: 'mercyChanceForFirstGames', type: 'number' }),
  ...fieldBuilder({ name: 'dailyChallengeOn', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyChallengeAdOnFirstAttemptOfTodays', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyChallengeAdOnSubsequentAttemptOfTodays', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyChallengeAdOnFirstAttemptOfPreviousDays', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyChallengeAdOnSubsequentAttemptOfPreviousDays', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyChallengeReplayFromStart', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyChallengeReward', type: 'number' }),
  ...fieldBuilder({ name: 'dailyChallengeStraightToToday', type: 'checkbox' }),
  ...fieldBuilder({ name: 'adProbabilityLogisticFunctionK', type: 'number' }),
  ...fieldBuilder({ name: 'minimumDealsBetweenAds', type: 'number' }),
  ...fieldBuilder({ name: 'defaultsSoundEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'defaultsInsuranceEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'defaultsHintsEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'defaultsAutoHintsEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'defaultsDealerHitsOnSoftSeventeenEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'settingsDefaultsNotificationDailyBonus', type: 'checkbox' }),
  ...fieldBuilder({ name: 'settingsDefaultsNotificationDailyChallenge', type: 'checkbox' }),
  ...fieldBuilder({ name: 'settingsDefaultsNotificationSpecialUpdates', type: 'checkbox' }),
  ...fieldBuilder({ name: 'settingsDefaultsMusic', type: 'checkbox' }),
  ...fieldBuilder({ name: 'settingsDefaultsAmbientNoise', type: 'checkbox' }),
  ...fieldBuilder({ name: 'handsBetweenTableSurfacing', type: 'number' }),
  ...fieldBuilder({ name: 'tableSurfacingForHands', type: 'number' }),
  ...fieldBuilder({ name: 'resurfaceGetChipsAdPopupOnStoreClose', type: 'checkbox' }),
  ...fieldBuilder({ name: 'adsUseAdFreqAsImpressionPerHands', type: 'checkbox' }),
  ...fieldBuilder({ name: 'adsSkipAdOnBlackjack', type: 'checkbox' }),
  ...fieldBuilder({ name: 'adsSkipAdWhenBalanceLessThenBet', type: 'checkbox' }),
  ...fieldBuilder({ name: 'adsSkipAdWhenUserGotLevelUp', type: 'checkbox' }),
  ...selectBuilder({ name: 'adCallTiming', options: adCallTimingOptions }),
  ...selectBuilder({ name: 'defaultsDecksCount', options: defaultsDecksCountOptions }),
  ...selectBuilder({ name: 'defaultsLanguage', options: defaultsLanguageOptions }),
  ...selectBuilder({ name: 'tableConfigId', ...tableConfigOptions }),
  ...selectBuilder({ name: 'dailyChallengeConfigId', ...dailyChallengeOptions }),
  ...fieldArrayBuilder(
    {
      name: 'hintTooltipEventNumsPerUser',
      fieldsConfiguration: [
        { type: 'number', label: 'Value' },
      ],
    },
  ),
  ...fieldArrayBuilder(
    {
      name: 'mercyData',
      fieldsConfiguration: [
        { name: 'losses', label: 'Losses', type: 'number' },
        {
          name: 'chance', label: 'Chance', type: 'number', step: '0.1',
        },
      ],
    },
  ),
  ...fieldArrayBuilder(
    {
      name: 'notificationChipRewards',
      fieldsConfiguration: [
        { label: 'Key', name: 'key' },
        { label: 'Value', name: 'value', type: 'number' },
      ],
    },
  ),
  ...fieldArrayBuilder(
    {
      name: 'dailyChallengeDealsToSurface',
      fieldsConfiguration: [
        { type: 'number', label: 'Value' },
      ],
    },
  ),
  ...fieldArrayBuilder(
    {
      name: 'dailyChallengeDealsToSurfaceOnFirstSession',
      fieldsConfiguration: [
        { type: 'number', label: 'Value' },
      ],
    },
  ),
  ...fieldArrayBuilder(
    {
      name: 'notificationPromptAfterHands',
      fieldsConfiguration: [
        { type: 'number', label: 'Value' },
      ],
    },
  ),
  ...fieldArrayBuilder(
    {
      name: 'promptForReviewAfterHands',
      fieldsConfiguration: [
        { type: 'number', label: 'Value' },
      ],
    },
  ),
  ...fieldArrayBuilder(
    {
      name: 'notificationPromptAfterDailyChallenges',
      fieldsConfiguration: [
        { type: 'number', label: 'Value' },
      ],
    },
  ),
}), (args) => JSON.stringify(args));

function fieldsFactory({ applicationId }) {
  const tableConfigOptions = selectOptionsWithQuickLink(BlackjackRoutes.TableConfigs, applicationId);
  const dailyChallengeOptions = selectOptionsWithQuickLink(BlackjackRoutes.DailyChallengeConfigs, applicationId);
  return Fields({
    dailyChallengeOptions,
    tableConfigOptions,
  });
}

export default combineWithSharedFieldsFactory(fieldsFactory, {});
