import React from 'react';
import { Link } from 'react-router-dom';

import { useCurrentApplication } from '@hooks';
import { LabelRoutes } from '@pages/routes';

import ColoredBadge from './ColoredBadge';

export default function LabelLink({ label }) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();

  return (
    <Link key={label.name} target="_blank" to={`${LabelRoutes.searchPath({ applicationId })}?labelId=${label.id}`}>
      <ColoredBadge
        key={label.name}
        text={label.name}
        hexColor={label.color}
        className="me-1 mb-1"
        style={{ fontSize: '0.8em' }}
      />
    </Link>
  );
}
