import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';

import APP_DATA from '@services/appData';

import { useCurrentApplication, useQuery } from '@hooks';
import { LabelRoutes } from '@pages/routes';
import SelectFilter from './SelectFilter';

const { user } = APP_DATA;

export const OPERATIONS = [
  { label: 'IN', value: 'labelsIdIn' },
  { label: 'NOT IN', value: 'labelsIdNotIn' },
];

export default function LabelsSelectFilter({
  updateFilterField, label = 'Label', ...props
}) {
  const { applicationId } = useCurrentApplication();
  const { defaultFilters = {}, defaultApplicationId } = user;

  useEffect(() => {
    if (defaultApplicationId === applicationId && (defaultFilters.labelIds?.length || defaultFilters.labelOperation)) {
      updateFilterField({
        __merge__labels: { [defaultFilters.labelOperation || OPERATIONS[0].value]: defaultFilters.labelIds },
      }, true);
    }
  }, []);
  const { response: options } = useQuery({
    request: () => LabelRoutes.indexRequest({ applicationId, withoutPagination: true }),
    queryKey: ['labels', applicationId],
    toSelectOptions: true,
    processResponse: ({ data }) => data.items.map(({ id: value, name: text, color }) => ({
      value,
      color,
      label: text,
    })),
  });

  return (
    <Col md={6}>
      <SelectFilter
        name="labels"
        label={label}
        updateFilterField={updateFilterField}
        operations={OPERATIONS}
        options={options}
        {...props}
      />
    </Col>
  );
}
