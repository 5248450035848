import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { newProductImage, createProductImage } from '@requests/payments/productImages';

import Form from './Form';

export default function New() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Product Image',
    actionName: 'creation',
    collectionUrl: `/admin/applications/${query.applicationId}/payments/product_images`,
    setData,
  });

  const onSubmit = (values) => createProductImage(values).then(responseHandler);

  useEffect(() => {
    newProductImage(query).then((response) => {
      setData(response.data);
    });
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} />;
}
