import React from 'react';
import { Alert } from '@tripledotstudios/react-core';

import { PageSection, Rewards } from '@pages/common';
import { ServerError } from '@controls/form';

import IconButton from '@controls/buttons';

const LeaderboardChallengeRank = ({
  entity,
  challenge,
  dispatch,
  rewardOptions,
}) => (
  <PageSection
    title={`Rank ${entity.number}`}
    actions={(
      <IconButton.Remove
        className="me-2"
        onClick={() => dispatch({ actionType: 'removeRank', uuid: entity._uuid, challengeUuid: challenge._uuid })}
        size="sm"
      />
    )}
  >
    <ServerError name="rewardAffiliationsAttributes.list" as={Alert} />

    <Rewards
      dispatch={dispatch}
      rewardable={entity}
      rewardItems={rewardOptions}
    />
  </PageSection>
);

export default LeaderboardChallengeRank;
