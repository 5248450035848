import React from 'react';
import styled from 'styled-components';

import { Table } from 'react-bootstrap';

const StyledTable = styled(Table)`
  width: max-content;
  border-spacing: 0;
  border-collapse: separate;
  margin-bottom: 0;
`;

export default function StyledComponentsTable({ children }) {
  return (
    <StyledTable bordered>
      {children}
    </StyledTable>
  );
}
