import React from 'react';
import { lowerCase, isArray } from 'lodash';

import { fromClassNameToOptions } from '@services/enums';
import EditableCell from './EditableCell';

import ItemsListModal from '../ItemsListModal';

const extractErrors = (errors) => {
  const result = [];

  if (isArray(errors)) {
    return errors;
  }

  Object.entries(errors).map(([index, error]) => (
    Object.entries(error).map(([key, message]) => result.push(`${key}[${index}] ${message}`))
  ));

  return result;
};

export default function ItemsListCell({
  row, name, items, errors, isEditable = true, validateTotalProbability = true,
}) {
  const values = row[name];
  const error = errors[name] ? extractErrors(errors[name]) : '';

  const emptyAbilityOption = { label: 'No', value: null };
  let abilities = fromClassNameToOptions('Merge::ItemAdditionalAbilities');
  abilities = [emptyAbilityOption, ...abilities];

  const humanAdditional = (additional) => (
    lowerCase(abilities.find((item) => item.value === additional).label)
  );
  const humanItem = (item) => (
    items.find((i) => i.value === item.schemaItemId).label
  );
  const text = values.reduce((memo, item) => (
    memo.concat(
      `${humanItem(item)}(${item.probability})`,
      item.additional !== undefined ? `(${humanAdditional(item.additional)})` : '',
      ';\n',
    )
  ), '');

  return (
    <EditableCell text={text} isEditable={isEditable} error={error}>
      {({ stopEditing }) => (
        <ItemsListModal
          fieldName={name}
          values={values}
          abilities={abilities}
          items={items}
          stopEditing={stopEditing}
          validateTotalProbability={validateTotalProbability}
        />
      )}
    </EditableCell>
  );
}
