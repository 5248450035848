import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import {
  Label, Field, FormButtonsGroup, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { Form } from '@hooks';
import { MyPetCafeRoutes } from '@pages/routes';

export default function LevelManifestSetForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();

  return (
    <Form initialValues={data} onSubmit={onSubmit}>
      {() => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Level Manifest Type`}>
            <FormButtonsGroup
              cancelButtonPath={MyPetCafeRoutes.LevelManifestSetTypes.indexPath({ applicationId })}
            />
          </PageHeader>

          <Label text="Name" required>
            <Field type="text" name="name" />
          </Label>

          <Label text="Key" required>
            <Field type="text" name="key" />
          </Label>

          <StaticFieldsFormGroup data={data} />
        </>
      )}
    </Form>
  );
}
