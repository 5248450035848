import { get, set, cloneDeep } from 'lodash';

import reorderEntities from '@services/reorderEntities';

export default function removeEntity(state, path, uuidToDelete, positionAttribute = 'position') {
  const currentEntities = get(state, path);
  const clonedEntities = cloneDeep(currentEntities);

  clonedEntities.find(({ _uuid }) => _uuid === uuidToDelete)._destroy = true;
  set(state, path, reorderEntities(clonedEntities, positionAttribute).result);

  return state;
}
