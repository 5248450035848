import React from 'react';

import { useFormContext } from '@hooks';
import { CheckboxField } from '@controls/form';
import { Col } from 'react-bootstrap';

const calculateDisabled = ({
  level, name, suitsAttributes, sharedInputProps,
}) => {
  if (sharedInputProps.disabled) return true;

  const checkedSuits = suitsAttributes.filter((suit) => level[suit]);

  if (name === 'anySuitSelected') return false;
  if (level.actionStackCount === 4) return true;

  return checkedSuits.length === level.actionStackCount && !checkedSuits.includes(name);
};

export default function SuitCheckbox({
  label,
  name,
  level,
  onChange,
  suitsAttributes,
  disabled,
}) {
  const { sharedInputProps } = useFormContext();

  return (
    <Col xs={2}>
      <CheckboxField
        name={name}
        className="me-2"
        onChange={({ target: { checked } }) => onChange(checked, name)}
        disabled={disabled || calculateDisabled({
          sharedInputProps, name, level, suitsAttributes,
        })}
      />
      <b>{label}</b>
    </Col>
  );
}
