import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Label, Field, StaticFieldsFormGroup } from '@controls/form';

export default function Header({ data }) {
  return (
    <>
      <Row>
        <Col>
          <Label labelSize={2} text="Name">
            <Field type="text" name="name" />
          </Label>
        </Col>
      </Row>

      <StaticFieldsFormGroup data={data} labelSize={2} />
    </>
  );
}
