import React, { useMemo } from 'react';
import { remove } from 'lodash';

import { ReactTable } from '@pages/common';
import { destructionRows } from '../diffRows';
import DiffValue from '../DiffValue';

const columnBuilder = [
  { Header: 'Attribute/Property', accessor: 'attribute' },
  { Header: 'Action', id: 'action', Cell: 'removed' },
  { Header: 'Context', accessor: 'context' },
  {
    Header: 'Values',
    accessor: 'value',
    disableSortBy: true,
    Cell: ({ row }) => <DiffValue value={row.original.value} onClick={row.original.onClick} />,
  },
];

export default function CreationDestruction({ showAssociation, versionTree }) {
  let changesRows = destructionRows({ versionTree, showAssociation });
  remove(changesRows, (row) => row.attribute === 'variants');
  if (versionTree.associations.variants) {
    versionTree.associations.variants.forEach((variant) => {
      const context = `${variant.version.object.name} (id: ${variant.version.itemId})`;
      changesRows = changesRows.concat(destructionRows({
        context, versionTree: variant, showAssociation,
      }));
    });
  }
  const columns = useMemo(() => columnBuilder, []);

  return (
    <ReactTable
      columns={columns}
      data={changesRows}
      disableSortBy
    />
  );
}
