import rulesPresenter from '../target-templates/target-template/rules/presenter';

export default function targetOptions({ targets, rawEnums }) {
  const buildParametersList = (parameters) => {
    const presenterdParameters = parameters
      .filter(({ _destroy }) => !_destroy)
      .map(({ value }) => value)
      .join(', ');

    return presenterdParameters.length > 0 ? `(${presenterdParameters})` : '';
  };

  const buildRulesList = (rulesAttributes) => {
    const presentedRules = rulesAttributes
      .filter(({ _destroy }) => !_destroy)
      .map((rule) => (
        rulesPresenter({
          type: rule.type,
          operation: rule.operation,
          enums: rawEnums,
          props: rule,
        })
      )).join(', ');

    return presentedRules.length > 0 ? `(${presentedRules})` : '';
  };

  return targets.map(({
    id,
    missionTypeName,
    parametersAttributes,
    rulesAttributes,
    _uuid,
  }) => {
    const label = `${missionTypeName} ${buildParametersList(parametersAttributes)} ${buildRulesList(rulesAttributes)}`;

    return { label, value: id || _uuid };
  });
}
