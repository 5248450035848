import { get, set } from 'lodash';
import { moveUp, moveDown } from '@services/reorder';

const reducer = (state, action) => {
  const swapRangeValues = (processedState, sourcePath, targetPath) => {
    const source = get(processedState, sourcePath);
    const target = get(processedState, targetPath);
    const { toChapter, fromChapter } = source;
    set(processedState, sourcePath, { ...source, toChapter: target.toChapter, fromChapter: target.fromChapter });
    set(processedState, targetPath, { ...target, toChapter, fromChapter });
  };

  switch (action.type) {
    case 'moveDown': {
      moveDown(state, ['chapterRangesAttributes'], action, 'position', swapRangeValues);

      return state;
    }
    case 'moveUp': {
      moveUp(state, ['chapterRangesAttributes'], action, 'position', swapRangeValues);

      return state;
    }
    default: break;
  }

  return state;
};

export default reducer;
