import React from 'react';

import { DuplicationModal } from '@pages/common';
import requestSchemaOptions from '@components/merge/services/requestEventSchemaOptions';
import ItemConfigurationSetsIndex from '../../item-configuration-sets/Index';

export default function List(props) {
  return (
    <ItemConfigurationSetsIndex
      DuplicationModal={DuplicationModal}
      requestSchemaOptions={requestSchemaOptions}
      {...props}
    />
  );
}
