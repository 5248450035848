import React from 'react';

import { Rewards, PageSection } from '@pages/common';
import { useRewardOptions, FormGroup } from '@hooks';
import { localizedEnumValueString } from '@services/enums';

export default function DifficultyConfigs({ entity, dispatch }) {
  const rewardOptions = useRewardOptions();

  return (
    <div>
      {entity.difficultyConfigsAttributes.map((difficultyConfig, index) => (
        <PageSection title={localizedEnumValueString('Woo::Aspen::LevelLayoutDifficulties', index)}>
          <FormGroup name={`difficultyConfigsAttributes[${index}]`}>
            <Rewards
              hideQuantityUntilSelected
              dispatch={dispatch}
              rewardable={difficultyConfig}
              rewardItems={rewardOptions}
            />
          </FormGroup>
        </PageSection>
      ))}
    </div>
  );
}
