import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { TIMESTAMP_COLUMNS, ColoredBadge } from '@pages/common';

import IconButton from '@controls/buttons';

export default function ColumnsFactory({ setEditedRecordId, onDelete }) {
  return [
    { Header: 'Id', accessor: 'id' },
    {
      Header: 'Preview',
      Cell: ({ row: { original: { name, color } } }) => (
        <ColoredBadge hexColor={color} text={name} />
      ),
    },
    { Header: 'Type', accessor: 'labelTypeHumanize', disableSortBy: true },
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({ row: { original: { id, name } } }) => (
        <ButtonToolbar>
          <IconButton.Edit
            onClick={() => setEditedRecordId(id)}
            minimized
          />
          <IconButton.Delete
            onClick={() => onDelete(id, name)}
            minimized
          />
        </ButtonToolbar>
      ),
    },
  ];
}
