import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { Filter, TextFilter, SelectFilter } from '@pages/common';
import { AuditRoutes, AdminUsersRoutes } from '@pages/routes';

import { EVENTS, NON_ADMIN_AUTHORS } from './constants';

export default function AuditLogsFilter({ applications }) {
  const { query } = useRouter();

  const [users, setUsers] = useState([]);
  const [auditEntities, setAuditEntities] = useState([]);

  const authors = [
    { label: 'System', value: NON_ADMIN_AUTHORS.SYSTEM },
    { label: 'Unknown', value: NON_ADMIN_AUTHORS.UNKNOWN },
  ].concat(users);

  useEffect(() => {
    AdminUsersRoutes.indexRequest({ ...query, withoutPagination: true }).then((usersResponse) => {
      setUsers(usersResponse.data.items.map(({ id, email }) => ({ value: id, label: email })));
    });
  }, []);

  useEffect(() => {
    AuditRoutes.Entities.indexRequest(query).then((entitiesResponse) => setAuditEntities(entitiesResponse.data.items));
  }, [query]);

  return (
    <Filter showApplyDefaultFilters={false} withUpdatedAfterFilter={false}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <SelectFilter
              name="applicationIdIn"
              label="Application = "
              options={applications}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="recordTypeIn"
              label="Entity type = "
              options={auditEntities}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="eventIn"
              label="Action type = "
              options={[
                { label: 'Created', value: EVENTS.CREATE },
                { label: 'Updated', value: EVENTS.UPDATE },
                { label: 'Deleted', value: EVENTS.DESTROY },
                { label: 'Duplicated', value: EVENTS.DUPLICATE },
              ]}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="itemIdIn"
              label="ID = "
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="whodunnitIn"
              label="Author ="
              options={authors}
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
