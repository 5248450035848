import React, { useMemo, useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
  DuplicationModal,
  Filter,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { MissionsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import ColumnsFactory from './ColumnsFactory';

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { query: { applicationId } } = useRouter();
  const { response, refetch } = useQuery(MissionsRoutes.Rewards.Sets.indexRequest, { includeInUse: true });
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Reward set',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `Reward Set '${name}' will be deleted and not available for work. Continue?` })
      .then(() => MissionsRoutes.Rewards.Sets.deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({ applicationId, setDuplicatedRecord, onDelete }), []);

  return response && (
    <div>
      <PageHeader
        title="Reward Sets"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
        userGuideUrlKey="missionsGuideUrl"
      >
        <IconButton.New to={MissionsRoutes.Rewards.Sets.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Reward Set"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => (
          MissionsRoutes.Rewards.Sets.duplicateRequest({ ...values, applicationId })
        )}
        title="Duplicate Reward Set"
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
