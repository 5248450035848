import React from 'react';

import { Field } from '@controls/form';
import EditableCell from './EditableCell';

export default function NumberCell({
  row, name, errors, isEditable = true,
}) {
  const value = row[name];
  const error = errors[name];

  return (
    <EditableCell text={value} isEditable={isEditable} error={error}>
      {({ stopEditing, onKeyPress }) => (
        <Field onBlur={stopEditing} name={name} type="number" onKeyPress={onKeyPress} autoFocus />
      )}
    </EditableCell>
  );
}
