import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { MergeRoutes as Routes } from '@pages/routes';
import WorldSchemaCell from '@components/merge/shared/WorldSchemaCell';
import IconButton from '@controls/buttons';

export default function ColumnsFactory({ applicationId, setDuplicatedRecord, onDelete }) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Expert', accessor: 'expertInternalId', disableSortBy: true },
    {
      Header: 'World Schema',
      Cell: ({ row: { original: { worldSchema } } }) => <WorldSchemaCell schema={worldSchema} />,
    },
    InLiveColumn,
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({
        row: {
          original: {
            id, name, inUse, worldSchema: { id: schemaId },
          },
        },
      }) => {
        const disabled = isInUse(inUse);
        return (
          <ButtonToolbar>
            <IconButton.Edit to={Routes.ExpertConfigurations.editPath({ id, applicationId })} minimized />
            <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name, newSchemaId: schemaId })} minimized />
            <IconButton.Delete onClick={onDelete(id, name)} disabled={disabled} minimized />
          </ButtonToolbar>
        );
      },
    },
  ];
}
