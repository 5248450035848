import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { Form } from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup, ServerError,
} from '@controls/form';
import { PageHeader, PageSection } from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';
import AreasFormGroup from './areas/FormGroup';
import reducer from './formReducer';

export default function AbilityImpactsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const disabled = isInUse(data.inUse);

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit} reducer={reducer}>
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Ability Impact Areas`}>
            <FormButtonsGroup
              cancelButtonPath={Routes.Abilities.Impacts.indexPath({ applicationId })}
            />
          </PageHeader>
          <Label required text="Name">
            <Field name="name" disabled={disabled} />
          </Label>
          <StaticFieldsFormGroup data={data} />
          <PageSection title="Impact Areas" />
          <ServerError name="impactAreasAttributes" />
          <AreasFormGroup values={values} disabled={disabled} />
        </>
      )}
    </Form>
  );
}
