import React from 'react';

import {
  GameSettingsTabs,
  liveopsDefaultFieldNames,
  localizationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  adRequestDelayFieldNames,
  popupsDefaultFieldNames,
  analyticsDefaultFieldNames,
  bakedInConfigsTab,
  popupsDefaultArchivedFieldNames,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
} from '@components/game-settings/Resource';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'everythingJson',
      'cloudSaveTimeLogUpdate',
      ...assetsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#currency',
    title: 'Currency',
    fieldsNames: [
      'initCoinsBalance',
      'initGemsBalance',
      'energyTickInterval',
      'energyCapacity',
    ],
  },
  {
    eventKey: '#default_settings',
    title: 'Default Settings',
    fieldsNames: [
      'musicEnabled',
      'soundEnabled',
      'threeDimensionalPostcardTest',
      'sessionStartNumberForProducersRefresh',
      'defaultNotificationsSettings',
    ],
  },
  {
    eventKey: '#stickers',
    title: 'Stickers',
    fieldsNames: [
      'stickersVariable',
      'stickersCurrency',
    ],
  },
  {
    eventKey: '#gameplay_other',
    title: 'Gameplay Other',
    fieldsNames: [
      'activeProducerResetTime',
    ],
  },
  {
    eventKey: '#orders_carousel',
    title: 'Orders carousel',
    fieldsNames: [
      'totalCountOfOrders',
      'maxCountOfRandomOrders',
      'storyOrderCompletedNewOrderChance',
      'storyOrderCompletedPostcardCompletedNewOrderChance',
      'randomOrderCompletedNewOrderChance',
      'randomOrderCompletedPostcardCompletedNewOrderChance',
      'startSessionNewOrderChance',
      'startSessionPostcardCompletedNewOrderChance',
      'firstTodaySessionNewOrderChance',
      'firstTodaySessionPostcardCompletedNewOrderChance',
      'expertPrioritizationStoryOrderNumber',
    ],
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      ...adPlacementIdsDefaultFieldNames,
      ...adConfigsDefaultFieldNames,
      ...adRequestDelayFieldNames,
      'adRemoteSettings',
    ],
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: popupsDefaultFieldNames,
  },
  {
    eventKey: '#liveops',
    title: 'LiveOps',
    fieldsNames: liveopsDefaultFieldNames,
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: analyticsDefaultFieldNames,
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: popupsDefaultArchivedFieldNames,
  },
];

export default function Tabs({
  setContractType, fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      onChange={setContractType}
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
