import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { TileRoutes } from '@pages/routes';
import AsyncFileProcessingForm from '@components/shared/AsyncFileProcessingForm';

export default function ImportForm({
  onSubmit, data, disabled, refetch,
}) {
  const { query: { applicationId } } = useRouter();

  return (
    <AsyncFileProcessingForm
      data={data}
      onSubmit={onSubmit}
      disabled={disabled}
      refetch={refetch}
      title="Upload Layout Banks"
      cancelPath={TileRoutes.Banks.indexPath({ applicationId })}
      accept="application/JSON, application/zip"
      websocketsPath={`bank_bulk_import_log_${data.id}`}
    />
  );
}
