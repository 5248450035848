import React from 'react';
import { useFlashMessages } from '@tripledotstudios/react-core';

import { MyPetCafeRoutes } from '@pages/routes';

import APP_DATA from '@services/appData';

import {
  Form,
  useCurrentApplication,
  useConfirm,
} from '@hooks';
import { DuplicateButton, PageHeader } from '@pages/common';
import { persistedMap } from '@services/utils';

import { fromClassNameToOptions } from '@services/enums';

import {
  Label,
  Field,
  SelectField,
  PriorityField,
  StaticFieldsFormGroup,
  FormButtonsGroup,
  TimePeriodField,
  LabelsFormGroup,
} from '@controls/form';
import IconButton from '@controls/buttons';

import RulesForm from '@pages/rules/Form';
import {
  combineReducers, rulesReducer, rewardableReducer, clientRulesReducer,
} from '@reducers';
import buildOnSubmit from '@services/journeys/banks/buildOnSubmit';

import reducer from './reducer';
import Events from './Events';

const statusesEnum = APP_DATA.enums['MyPetCafe::SoloMissions::EventBankStatuses'];
const statuses = fromClassNameToOptions('MyPetCafe::SoloMissions::EventBankStatuses');
const SECONDS_IN_DAY = 86400;

const { modelsLocales: { myPetCafe: { soloMissions: { eventBanks: { tooltips } } } } } = APP_DATA;

export default function SoloMissionsEventBankForm({
  data, title, onSubmit: onPageSubmit, entityName,
}) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const confirm = useConfirm();
  const { error } = useFlashMessages();

  const { id, status } = data;

  const isArchived = status === statusesEnum.ARCHIVED;

  const preprocessedSubmit = (values) => {
    const { eventsAttributes } = values;

    const durationsSum = persistedMap(
      eventsAttributes, ({ duration, eventGap }) => duration + eventGap,
    ).reduce((acc, val) => acc + val, 0);

    if (!(durationsSum % SECONDS_IN_DAY)) return onPageSubmit(values);

    return confirm.showConfirmation({
      body: (
        <p>
          Same events will be started at different time in each next iteration,
          are you sure that you want to save the config?
        </p>
      ),
    }).then(() => onPageSubmit(values)).catch(() => { });
  };

  const onSubmit = buildOnSubmit({
    applicationId,
    data,
    entityName,
    statusesEnum,
    onSubmitFunction: preprocessedSubmit,
    confirm,
    error,
    routes: MyPetCafeRoutes.SoloMissions.EventBanks,
  });

  const { readOnly, isStatusEditingDenied = null } = data;

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([rulesReducer, rewardableReducer, reducer, clientRulesReducer])}
      sharedInputProps={{ disabled: readOnly || isArchived }}
    >
      {({ values }) => (
        <>
          <PageHeader title={title}>
            <FormButtonsGroup
              cancelButtonPath={MyPetCafeRoutes.SoloMissions.EventBanks.indexPath({ applicationId })}
            >
              {id && (
                <>
                  <IconButton.ActivityLog
                    applicationId={applicationId}
                    id={id}
                    entityType="MyPetCafe::SoloMissions::EventBank"
                  />
                  <FormButtonsGroup.Divider />
                </>
              )}
              <DuplicateButton
                routes={MyPetCafeRoutes.SoloMissions.EventBanks}
                data={data}
                entityName={entityName}
                modalType="withPriority"
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label text="Name">
            <Field type="text" name="name" disabled={readOnly || false} />
          </Label>
          <Label text="Status">
            <SelectField name="status" options={statuses} isDisabled={isStatusEditingDenied || readOnly} />
          </Label>
          <Label text="Priority">
            <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} />
          </Label>

          <LabelsFormGroup disabled={readOnly || false} />

          <Label
            text="Non opt-in period"
            tooltipText={tooltips.notOptInPeriod}
          >
            <TimePeriodField name="notOptInPeriod" />
          </Label>
          <StaticFieldsFormGroup data={data} />

          <Events events={values.eventsAttributes} isArchived={isArchived} readOnly={readOnly} />
          <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} disabled={isArchived} />
        </>
      )}
    </Form>
  );
}
