import React from 'react';
import { isEqual } from 'lodash';

import { useFormContext } from '@hooks';

import {
  SettingsTable,
  BoardStart,
  Generation,
  Goals,
} from './tile-typed-level';

const TileTypedLevel = React.memo(({
  entity: level,
  index,
  readOnly,
  figureSets,
  figureSetDynamicConfigs,
  shapesBankSetOptions,
  tileTypes,
  errors,
}) => {
  const { dispatch } = useFormContext();
  const { tileProbabilitiesAttributes, levelGoalsAttributes } = level;

  const tileTypeOptions = tileTypes.map(({
    name, id, typeId, layerId, familyId, includeInGoals, includeInProbabilities, parametersSchema, height, width,
  }) => ({
    label: `${name} (id: ${typeId})`,
    value: id,
    layerId,
    familyId,
    includeInGoals,
    includeInProbabilities,
    parametersSchema: JSON.parse(parametersSchema),
    height,
    width,
  }));

  const onConfigItemRemove = (collectionName, uuid) => dispatch({
    actionType: 'removeConfigItem', index, collectionName, uuid,
  });

  return (
    <>
      <SettingsTable
        index={index}
        figureSets={figureSets}
        figureSetDynamicConfigs={figureSetDynamicConfigs}
        shapesBankSetOptions={shapesBankSetOptions}
        level={level}
        readOnly={readOnly}
        withHardLevel
      />

      <Generation
        index={index}
        tileProbabilitiesAttributes={tileProbabilitiesAttributes}
        tileTypeOptions={tileTypeOptions.filter(({ includeInProbabilities }) => includeInProbabilities)}
        onConfigItemRemove={onConfigItemRemove}
        readOnly={readOnly}
      />

      <Goals
        index={index}
        levelGoalsAttributes={levelGoalsAttributes}
        tileTypeOptions={tileTypeOptions.filter(({ includeInGoals }) => includeInGoals)}
        onConfigItemRemove={onConfigItemRemove}
        readOnly={readOnly}
      />

      <BoardStart
        index={index}
        boardCellsAttributes={level.boardCellsAttributes}
        tileTypeOptions={tileTypeOptions}
        errors={errors}
        readOnly={readOnly}
        boardGridType={level.boardGridType}
      />
    </>
  );
}, (prev, next) => (
  prev.readOnly === next.readOnly && isEqual(prev.figureSets, next.figureSets)
  && isEqual(prev.figureSetDynamicConfigs, next.figureSetDynamicConfigs) && isEqual(prev.tileTypes, next.tileTypes)
  && isEqual(prev.entity, next.entity) && isEqual(prev.errors, next.errors)
));

export default TileTypedLevel;
