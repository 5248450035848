import React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from '@controls/form';

export default function Fields() {
  return (
    <Col xs={2}>
      <Field name="preDiscountAmount" type="number" />
    </Col>
  );
}
