import React, { useMemo, useState } from 'react';
import { camelCase, get, isEmpty } from 'lodash';
import { Modal } from 'react-bootstrap';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { AssetsRoutes } from '@pages/routes';
import {
  ReactTable, RulesList, PageSection, PriorityColumn,
} from '@pages/common';
import RulesForm from '@pages/rules/Form';

import { FormGroup, useConfirm, useCurrentApplication } from '@hooks';
import {
  Field,
  Label,
  PriorityField,
  Error,
  ServerError,
} from '@controls/form';
import IconButton from '@controls/buttons';

const columnsFactory = ({ setBundleIndex, handleBundleDelete }) => [
  { Header: 'Bundle name', accessor: 'name' },
  PriorityColumn,
  {
    Header: 'Rules',
    Cell: ({ row }) => {
      if (!row.original) return '';

      return <RulesList rules={row.original.ruleSectionsAttributes} />;
    },
  },
  {
    Header: 'Actions',
    Cell: ({ row: { original } }) => (
      <ButtonToolbar>
        <IconButton.Edit onClick={() => { setBundleIndex(original.index); }} minimized />
        <IconButton.Delete onClick={() => handleBundleDelete(original)} minimized />

        {!isEmpty(original.errors) && <Error className="">Validation failed</Error>}
      </ButtonToolbar>
    ),
  },
];

export default function BundleTable({ data, dispatch }) {
  const [shownIndex, setBundleIndex] = useState();
  const hideModal = () => setBundleIndex(null);
  const confirm = useConfirm();
  const { currentApplication } = useCurrentApplication();
  const shownBundle = data.bundlesAttributes[shownIndex];
  const addBundle = () => {
    dispatch({ actionType: 'addNewBundle' });
    setBundleIndex(data.bundlesAttributes.length);
  };
  const saveBundle = async () => {
    const response = await AssetsRoutes.Bundles.validateRequest({
      ...shownBundle, applicationId: currentApplication.id,
    });
    if (response.status === 422) {
      dispatch({ actionType: 'setBundleErrors', index: shownIndex, errors: response.data._meta.errors });
    } else {
      dispatch({ actionType: 'saveBundle', index: shownIndex });
      hideModal();
    }
  };

  const handleBundleDelete = (bundle) => {
    confirm.showConfirmation({
      title: `Asset Bundle '${bundle.name}' will be deleted and not available for work. Continue?`,
    }).then(() => dispatch({ actionType: 'removeBundle', index: bundle.index }));
  };

  const bundles = data.bundlesAttributes.reduce((acc, bundle, index) => {
    if (bundle._destroy) { return acc; }

    return [
      ...acc,
      {
        ...bundle,
        dispatch,
        index,
        errors: get(data.meta, `errors.bundlesAttributes[${index}]`),
      },
    ];
  }, []);

  const bundleColumns = useMemo(() => columnsFactory({ setBundleIndex, handleBundleDelete }), []);

  const bundlePath = shownBundle ? `bundlesAttributes[${shownIndex}]` : null;

  return (
    <>
      <PageSection title="Bundles" />
      <ServerError name="bundlesAttributes.list" />
      <ReactTable
        columns={bundleColumns}
        data={bundles}
        tableProps={{ fixed: true }}
      />
      <IconButton.New onClick={addBundle}>Add Bundle</IconButton.New>
      <Modal show={shownBundle} onHide={hideModal} dialogClassName="mw-75">
        <Modal.Header closeButton>
          <Modal.Title>Bundle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup name={bundlePath}>
            <Label text="Name">
              <Field name="name" />
            </Label>
            <Label text="Priority">
              <PriorityField
                name="priority"
                entitiesByPriority={data.entitiesByPriority[camelCase(data.uid)] || []}
              />
            </Label>
            <Label text="iOS URL">
              <Field name="iosUrl" type="text" />
            </Label>
            <Label text="Android URL">
              <Field name="androidUrl" type="text" />
            </Label>
            <Label text="Amazon URL">
              <Field name="amazonUrl" type="text" />
            </Label>
            {shownBundle && (
              <RulesForm
                ruleSectionsAttributes={shownBundle.ruleSectionsAttributes}
              />
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <IconButton.Save onClick={saveBundle}>Confirm</IconButton.Save>
        </Modal.Footer>
      </Modal>
    </>
  );
}
