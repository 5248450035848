import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { LevelSetRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const [data, setData] = useState(null);
  const { query: { id, applicationId } } = useRouter();

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Level Set',
    actionName: 'update',
    redirectPath: Routes.Sets.indexPath({ applicationId }),
    setData,
  });

  const onSubmit = (values) => Routes.Sets.updateRequest({ ...values, applicationId })
    .then(redirectIfSuccessHandler);

  useEffect(() => {
    Routes.Sets.editRequest({ id, applicationId }).then((response) => setData(response.data));
  }, []);

  return data && (
    <Form
      data={data}
      onSubmit={onSubmit}
      actionName="Update"
    />
  );
}
