import React from 'react';
import { Alert, Spinner } from '@tripledotstudios/react-core';

import { PageSection } from '@pages/common';
import { FormButtonsGroup } from '@controls/form';
import { Form, useQuery } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { AdminRoutes } from '@pages/routes';

export default function TesterSettingsForm({ applicationId, applicationName, Fields }) {
  const { isLoading, response, setResponse } = useQuery(AdminRoutes.Settings.Tester.editRequest, { applicationId });
  const entityName = `${applicationName} Tester Settings`;

  const responseHandler = noRedirectHandlerFactory({
    entityName,
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    AdminRoutes.Settings.Tester.updateRequest({ ...values, applicationId }).then(responseHandler)
  );

  if (isLoading) return <Spinner />;

  return response ? (
    <Form initialValues={response} onSubmit={onSubmit}>
      {() => (
        <>
          <PageSection title={entityName} size="md" actions={<FormButtonsGroup hideCancel />} />
          <Fields />
        </>
      )}
    </Form>
  ) : (
    <Alert variant="danger">Not available for editing</Alert>
  );
}
