import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';
import {
  TIMESTAMP_COLUMNS,
  InLiveBadge,
  InLiveColumnHeader,
  InUseColumn,
  LabelsColumn,
} from '@pages/common';
import IconButton from '@controls/buttons';

export default function columnsFactory({
  currentApplication, ChapterSetRoutes, setDuplicatedRecord, onDelete,
}) {
  return ([
    { Header: 'ID', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    LabelsColumn,
    InUseColumn,
    {
      id: 'availability_header',
      Header: () => <InLiveColumnHeader />,
      Cell: ({ row }) => <InLiveBadge inLive={row.original.availabilityStateLive} />,
    },
    { Header: 'Levels', accessor: 'levelsCount' },
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({ row }) => {
        const { id, removable, name } = row.original;
        return (
          <ButtonToolbar>
            <IconButton.Edit to={ChapterSetRoutes.editPath({ applicationId: currentApplication.id, id })} minimized />
            <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
            <IconButton.Delete
              onClick={onDelete(id, name)}
              disabled={!removable}
              title={removable ? null : 'Disabled as the entity is used'}
              minimized
            />
          </ButtonToolbar>
        );
      },
    },
  ]);
}
