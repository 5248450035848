import React from 'react';
import { Route } from 'react-router-dom';

import { AssetsRoutes, ExternalDataImportRoutes } from '@pages/routes';

import {
  Index as AssetsKeysIndex,
  New as AssetsKeysNew,
  Edit as AssetsKeysEdit,
} from '@components/assets/keys';

import { PageTitle } from '@pages/common';

import ArtifactsIndex from '@pages/external-data-import/artifacts/Index';
import RouteContainer from '@pages/RouteContainer';

export default function common() {
  return [
    <Route
      key={AssetsRoutes.Keys.indexRawPath}
      path={AssetsRoutes.Keys.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Assets List" />
          <AssetsKeysIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={AssetsRoutes.Keys.newRawPath}
      path={AssetsRoutes.Keys.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="New Asset" />
          <AssetsKeysNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={AssetsRoutes.Keys.editRawPath}
      path={AssetsRoutes.Keys.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Asset" />
          <AssetsKeysEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={ExternalDataImportRoutes.Artifacts.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Import Artifacts" />
          <ArtifactsIndex />
        </RouteContainer>
      )}
      path={ExternalDataImportRoutes.Artifacts.indexRawPath}
    />,
  ];
}
