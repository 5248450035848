import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { isEmpty, uniqBy } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { Filter, TextFilter, SelectFilter } from '@pages/common';

import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import updateQuery from '@services/updateQuery';
import { MergeRoutes as Routes } from '@pages/routes';

export default function ExpertConfigurationFilter() {
  const [worldSchemas, setWorldSchemas] = useState([]);
  const [schemaExperts, setSchemaExperts] = useState([]);

  const router = useRouter();
  const { query } = router;
  const { applicationId } = query;

  const reloadWorldExpertFilterOptions = (schemaIds) => {
    if (isEmpty(schemaIds)) {
      return setSchemaExperts([]);
    }

    return Promise.all(schemaIds.map((schemaId) => (
      Routes.WorldSchemas.editRequest({ id: schemaId, applicationId })
    ))).then((results) => {
      const flattenExperts = results.flatMap(({ data }) => data.experts);
      const uniqExperts = uniqBy(flattenExperts, 'internalId');

      setSchemaExperts(uniqExperts.map(({ internalId }) => ({ value: internalId, label: internalId })));
    });
  };

  const updateWorldSchemaFilterField = ({ schemaIdIn }) => {
    const { schemaExpertInternalIdIn, ...prevFilters } = query.filter || {};
    reloadWorldExpertFilterOptions(schemaIdIn);
    updateQuery(router, { filter: { ...prevFilters, schemaIdIn } });
  };

  const onFiltersReset = () => {
    setSchemaExperts([]);
  };

  useEffect(() => {
    (async () => {
      setWorldSchemas(await requestSchemaOptions(applicationId));
    })();
    if (query.filter && query.filter.schemaIdIn) {
      reloadWorldExpertFilterOptions(query.filter.schemaIdIn);
    }
  }, []);

  return (
    <Filter onFiltersReset={onFiltersReset} defaultComponents={['availabilityStateIn']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <SelectFilter
              name="schemaIdIn"
              label="World = "
              options={worldSchemas}
              updateFilterField={updateWorldSchemaFilterField}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="schemaExpertInternalIdIn"
              label="Expert = "
              placeholder={schemaExperts.length > 0 ? 'Select...' : 'Select World Schema...'}
              options={schemaExperts}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name contains "
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
