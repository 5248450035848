import React from 'react';
import { Spinner } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import {
  FormGroup, useFormContext, useFormGroup, useQuery, useConfirm,
} from '@hooks';
import { localizedLabelFactory, SelectWithAction, SelectField } from '@controls/form';
import { PageSection } from '@pages/common';
import { AdsRoutes } from '@pages/routes';
import { fromClassNamesToOptions } from '@services/enums';

import IconButton from '@controls/buttons';
import Select from '@controls/Select';
import ConfigurationFields from './ConfigurationFields';
import { indexedPlacements, placementsToAddOptions } from '../utils';

const LocalizedLabel = localizedLabelFactory('ads.configuration.newcomersRestrictionsConfigurationAttributes');

export default function Fields({ values, disabled }) {
  const { dispatch } = useFormContext();
  const { generateName } = useFormGroup();
  const confirm = useConfirm();
  const overridesPath = generateName('overridesAttributes');

  const [relevancyOptions, blockTypeOptions] = fromClassNamesToOptions(
    [
      'Ads::Configuration::NewcomersRestrictions::RelevancyOptions',
      'Ads::Configuration::NewcomersRestrictions::BlockTypes',
    ],
  );

  const { response: placementsResponse, isLoading: placementsAreLoading } = useQuery(
    AdsRoutes.Interstitials.Placements.indexRequest, { withoutPagination: true },
  );

  const addOverride = (selectedPlacementId) => {
    dispatch({ type: 'addNewcomersRestrictionsOverride', overridesPath, placementId: selectedPlacementId });
  };

  const removeOverride = (placementIndex) => {
    confirm.showConfirmation({
      title: 'Newcomers Restrictions Override will be removed. Continue?',
    }).then(() => dispatch({ type: 'removeNewcomersRestrictionsOverride', overridesPath, placementIndex }));
  };

  const placements = indexedPlacements(placementsResponse);
  const {
    modelsLocales: { ads: { configuration: { newcomersRestrictionsConfigurationAttributes: locale } } },
  } = APP_DATA;

  return placementsAreLoading ? <Spinner /> : (
    <>
      <ConfigurationFields
        values={values}
        disabled={disabled}
        blockTypeOptions={blockTypeOptions}
        dispatch={dispatch}
        attributesPath={generateName()}
      >
        <LocalizedLabel name="relevancy" labelSize={5} fieldSize={7}>
          <SelectField
            name="relevancy"
            options={relevancyOptions}
            hasPrepopulatedOption
            isDisabled={disabled}
          />
        </LocalizedLabel>
      </ConfigurationFields>

      <PageSection title={locale.overridesAttributes.label} tooltip={locale.overridesAttributes.tooltip} />

      {values.overridesAttributes.map((overrideAttributes, index) => (
        !overrideAttributes._destroy && (
          <PageSection
            title={placements[overrideAttributes.placementId].name}
            actions={<IconButton.Remove size="sm" onClick={() => removeOverride(index)} disabled={disabled} />}
            key={overrideAttributes._uuid}
          >
            <FormGroup name={`overridesAttributes[${index}]`}>
              <ConfigurationFields
                values={overrideAttributes}
                disabled={disabled}
                blockTypeOptions={blockTypeOptions}
                dispatch={dispatch}
                attributesPath={generateName(`overridesAttributes[${index}]`)}
              >
                <LocalizedLabel name="relevancy" labelSize={5} fieldSize={7}>
                  <Select
                    options={relevancyOptions}
                    isDisabled
                    selectedValue={values.relevancy}
                  />
                </LocalizedLabel>
              </ConfigurationFields>
            </FormGroup>
          </PageSection>
        )
      ))}

      <SelectWithAction
        selectOptions={placementsToAddOptions(values, placementsResponse)}
        buttonOnClick={addOverride}
        buttonTitle="Add Override"
        cassName="mb-3"
        disabled={disabled}
      />
    </>
  );
}
