import React from 'react';
import { Col } from 'react-bootstrap';

import { useCurrentApplication } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';

import { Filter, TextFilter, SelectFilter } from '@pages/common';

export default function ActionsFilter({ defaultComponents }) {
  const { currentApplication: { name: applicationName } } = useCurrentApplication();
  const actionTypeOptions = fromClassNameToOptions(`${applicationName}::FlexiblePopups::ActionTypes`);

  return (
    <Filter defaultComponents={defaultComponents}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameCont"
              label="Name"
              isMulti={false}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="actionTypeIn"
              label="Type"
              options={actionTypeOptions}
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
