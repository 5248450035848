import React from 'react';
import { get } from 'lodash';

import APP_DATA from '@services/appData';

import {
  FormGroup, useFormContext, useFormGroup,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import {
  Label, SelectField, TimePeriodField, FieldArrayWithLabel,
} from '@controls/form';
import { PageSection } from '@pages/common';

const ConfigurationFields = ({ notificationType }) => {
  const { enums } = APP_DATA;
  const typesEnum = enums['Woodoku::Journeys::LocalPushNotificationTypes'];

  if (notificationType === typesEnum.TIME_BEFORE_END) {
    return (
      <Label text="X hrs before the end" direction="vertical">
        <TimePeriodField name="timeBeforeEventEnd" />
      </Label>
    );
  }

  return '';
};

const Configuration = ({ name: groupName }) => {
  const notificationTypeOptions = fromClassNameToOptions('Woodoku::Journeys::LocalPushNotificationTypes');
  const { values } = useFormContext();
  const { generateName } = useFormGroup();

  const notificationType = get(values, generateName(`${groupName}.notificationType`));

  return (
    <FormGroup name={groupName}>
      <SelectField name="notificationType" options={notificationTypeOptions} className="mb-2" />

      <ConfigurationFields notificationType={notificationType} />
    </FormGroup>
  );
};

export default function LocalPushNotificationSettings({ values, localizationKeyOptions, disabled }) {
  const { modelsLocales: { woodoku: { journeys: { tooltips } } } } = APP_DATA;

  const fieldsConfiguration = [
    {
      label: 'Push Type',
      as: 'component',
      componentFn: ({ name, disabled: isDisabled }) => [Configuration, { name, disabled: isDisabled }],
    },
    {
      label: 'Title Translation Key',
      name: 'titleTranslationKeyId',
      as: 'select',
      componentData: localizationKeyOptions,
    },
    {
      label: 'Subtitle Translation Key',
      name: 'subtitleTranslationKeyId',
      as: 'select',
      includeEmptyOption: true,
      componentData: localizationKeyOptions,
    },
    {
      label: 'Message Translation Key',
      name: 'messageTranslationKeyId',
      as: 'select',
      componentData: localizationKeyOptions,
    },
    {
      label: 'Rich Push Image URL',
      name: 'bigPictureUrl',
    },
  ];

  return (
    <div className="mt-4">
      <PageSection title="Push Notifications" />

      <FieldArrayWithLabel
        isHorizontal
        collectionName="localPushNotifications"
        values={values.localPushNotifications || []}
        fieldsConfiguration={fieldsConfiguration}
        tooltips={tooltips.localPushNotifications}
        disabled={disabled}
      />
    </div>
  );
}
