import React, { useState } from 'react';

import { FormGroup } from '@hooks';
import ServerError from '@controls/form/ServerError';
import PageSection from '../common/PageSection';
import Alert from '../common/Alert';
import Section from './section/Section';
import { NAMED_GROUP_RULE_TAG } from './list';
import CreateNewNamedRuleGroupModal from './CreateNewNamedRuleGroupModal';

export default function RulesForm({
  ruleSectionsAttributes,
  hideControls = false,
  hideNamedGroupsRule = false,
  nestedForm = false,
  createNewNamedRuleGroupEnabled = true,
  disabled,
}) {
  const excludeTags = [];
  const [showCreateNamedRuleGroupModal, setShowCreateNamedRuleGroupModal] = useState(false);
  const [namedRuleGroupSectionPath, setNamedRuleGroupSection] = useState('');

  if (hideNamedGroupsRule) {
    excludeTags.push(NAMED_GROUP_RULE_TAG);
  }

  return (
    <>
      <PageSection title="Rules" userGuideUrlKey="rulesGuideUrl" />
      <ServerError as={Alert} name="rootRulesSection" />

      {ruleSectionsAttributes && ruleSectionsAttributes.map((section, index) => {
        const {
          ruleSectionsAttributes: childSections,
          rulesAttributes,
          _uuid,
        } = section;

        return (
          <>
            <React.Fragment key={_uuid}>
              <FormGroup name={`ruleSectionsAttributes.${index}`} rootGroup={nestedForm}>
                <Section
                  nestingLevel={0}
                  rulesAttributes={rulesAttributes}
                  ruleSectionsAttributes={childSections}
                  hideControls={hideControls}
                  hideNamedGroupsRule={hideNamedGroupsRule}
                  excludeTags={excludeTags}
                  disabled={disabled}
                  createNewNamedRuleGroupEnabled={createNewNamedRuleGroupEnabled}
                  setShowCreateNamedRuleGroupModal={setShowCreateNamedRuleGroupModal}
                  setNamedRuleGroupSection={setNamedRuleGroupSection}
                />
              </FormGroup>
            </React.Fragment>
            {createNewNamedRuleGroupEnabled && showCreateNamedRuleGroupModal && (
              <CreateNewNamedRuleGroupModal
                setShowCreateNamedRuleGroupModal={setShowCreateNamedRuleGroupModal}
                namedRuleGroupSectionPath={namedRuleGroupSectionPath}
              />
            )}
          </>
        );
      })}
    </>
  );
}
