import { produce } from 'immer';

/* eslint-disable no-param-reassign, array-callback-return */
export default (reducers = []) => produce((draft, action, appData) => {
  reducers.forEach((reducer) => {
    reducer(draft, action, appData);
  });

  return draft;
});
