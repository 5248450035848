import React from 'react';

import { MissionsRoutes } from '@pages/routes';
import CreateUpdateComponent from './CreateUpdateComponent';

export default function New() {
  return (
    <CreateUpdateComponent
      actionName="creation"
      getFunction={MissionsRoutes.Rewards.Sets.newRequest}
      postFunction={MissionsRoutes.Rewards.Sets.createRequest}
    />
  );
}
