/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import {
  Table,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { FormGroup, useFormContext } from '@hooks';
import { PageSection } from '@pages/common';

import RewardConfig from './RewardConfig';

export default function MissionConfig({
  data,
  tierIndex,
  missionConfigIndex,
}) {
  const { dispatch } = useFormContext();
  const { missionTypeName, default: defaultConfig, _destroy } = data;

  const onRemove = () => dispatch({ actionType: 'removeMissionConfig', tierIndex, missionConfigIndex });
  const onRestore = () => dispatch({ actionType: 'restoreMissionConfig', tierIndex, missionConfigIndex });

  const RemoveRestoreBlock = () => (
    _destroy ? (
      <Button className="float-end" onClick={onRestore}>Restore</Button>
    ) : (
      <Button variant="danger" className="float-end" onClick={onRemove}>Remove</Button>
    )
  );

  const renderTableWithRewards = () => (
    <Table variant="borderless">
      <thead>
        <tr className="text-center">
          <th>Reward</th>
          <th>Weight</th>
          <th>Base Value</th>
          <th>Reward Amount</th>
        </tr>
      </thead>
      <tbody>
        {data.rewardConfigsAttributes.map((rewardConfig, index) => (
          <FormGroup key={rewardConfig._uuid} name={`rewardConfigsAttributes.${index}`}>
            <RewardConfig
              data={rewardConfig}
              tierIndex={tierIndex}
              missionConfigIndex={missionConfigIndex}
              rewardConfigIndex={index}
            />
          </FormGroup>
        ))}
      </tbody>
    </Table>
  );

  return (
    <Row>
      <Col>
        <PageSection
          title={defaultConfig ? 'Default' : missionTypeName}
          tooltip={defaultConfig
            ? 'Default configuration is used for all mission types until some specific configuration is defined' : null}
        />
        {_destroy ? '' : renderTableWithRewards()}
        <Row>
          <Col>{defaultConfig ? '' : <RemoveRestoreBlock />}</Col>
        </Row>
        <hr />
      </Col>
    </Row>
  );
}
