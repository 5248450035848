import React from 'react';
import { Tab } from 'react-bootstrap';
import { get } from 'lodash';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { Form, FormGroup } from '@hooks';
import Tabs from '@controls/Tabs';
import { FormButtonsGroup } from '@controls/form';
import { PageHeader } from '@pages/common';
import { AbTestingRoutes } from '@pages/routes';
import presentGroupRule from '@services/ab-testing/presentGroupRule';

export default function ExperimentsScopedTabs({
  response, cancellationPath, entityName, handleSubmit, children, reducer, disableSubmit = () => false,
}) {
  const { query } = useRouter();
  const { applicationId, experimentId } = query;
  const cancelButtonPath = cancellationPath
    || AbTestingRoutes.Experiments.editPath({ applicationId, id: experimentId });
  if (!response) return <Spinner />;

  const tabClass = (index) => (get(response, `_meta.errors.variantsAttributes[${index}]`) ? 'text-danger' : '');
  return (
    <Form
      initialValues={response}
      onSubmit={(values, { setSubmitting }) => handleSubmit(values).finally(() => setSubmitting(false))}
      reducer={reducer}
    >
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`Edit ${entityName} Variant`} className="mt-1">
            <FormButtonsGroup
              cancelButtonPath={cancelButtonPath}
              disableSubmit={disableSubmit(values)}
            />
          </PageHeader>
          <Tabs className="mb-3" mountOnEnter>
            {values.variantsAttributes.map((variantAttributes, i) => {
              const {
                id, variantName, variantRuleGroup,
              } = variantAttributes;
              const ruleLabel = presentGroupRule(variantRuleGroup);
              const tabTitle = ruleLabel ? `${variantName} (${ruleLabel})` : variantName;

              return (
                <Tab key={id} eventKey={`#${id}`} title={tabTitle} tabClassName={tabClass(i)}>
                  <FormGroup name={`variantsAttributes[${i}]`}>
                    {children({
                      variantAttributes,
                      dispatch,
                      variantIndex: i,
                    })}
                  </FormGroup>
                </Tab>
              );
            })}
          </Tabs>
        </>
      )}
    </Form>
  );
}
