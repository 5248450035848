import React from 'react';

import { Label, ReorderableListField } from '@controls/form';

import localizeField from './localizeField';

const buildField = ({ name, disabled, options }) => (
  <div className="mb-3">
    <ReorderableListField
      name={name}
      disabled={disabled}
      options={options}
    />
  </div>
);

export default function ReorderableFieldBuilder({
  name: baseName, labelText, options, fieldOptions = {}, tooltips = {},
}) {
  return {
    [baseName]: {
      Inline: ({ name, disabled }) => (
        buildField({ name: name || baseName, disabled, options })
      ),
      Labeled: ({
        name, disabled, localeNamespace, locale = {}, tooltipBuilder,
      }) => {
        const { label, tooltip, clientKey } = localizeField(
          baseName, labelText, tooltips, localeNamespace, locale, tooltipBuilder,
        );

        return (
          <Label
            text={label}
            fieldSize={8}
            tooltipText={tooltip}
            copyableText={clientKey}
          >
            {buildField({ name: name || baseName, disabled, options })}
          </Label>
        );
      },
      fieldOptions,
    },
  };
}
