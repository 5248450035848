import React, { useMemo } from 'react';

import { Collapsible } from '@controls/form';
import CardHeader from '@components/shared/CardHeader';

import { Table } from '../shared/styled-components';
import { TableHead, TableContainer } from '../shared';
import { useLanguagesContext } from '../shared/services/useLanguages';
import useAvailabilityTypes from '../shared/services/useAvailabilityTypes';

import Row from './Row';

export default function BaseTable({ translationKeys }) {
  const { filteredLanguages } = useLanguagesContext();
  const { type } = useAvailabilityTypes();

  return (
    useMemo(() => (
      <Collapsible
        header={<CardHeader text="Translation Keys" variant="success" />}
        eventKey="translations-table"
        opened
      >
        <TableContainer>
          <Table>
            <TableHead languages={filteredLanguages} type={type} />
            <tbody>
              {translationKeys.map((rowData) => (
                <Row key={rowData._uuid} rowData={rowData} />
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Collapsible>
    ), [translationKeys, filteredLanguages, type])
  );
}
