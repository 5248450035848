import React from 'react';
import {
  reduce, sortBy, compact, camelCase, upperFirst,
} from 'lodash';
import { InputGroup } from 'react-bootstrap';
import { useQuery, useCurrentApplication, Form } from '@hooks';
import { CopyToClipboardAddon } from '@controls/CopyToClipboardButton';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { generateChapterBasedRoutes } from '@pages/routes';
import {
  Label, Field, StaticFieldsFormGroup, LabelsFormGroup,
} from '@controls/form';
import { combineReducers, chapterSetReducer, rewardableReducer } from '@reducers';
import { serializableChapter } from '@services/chapterSets';
import { PageHeader, DuplicateButton } from '@pages/common';

import ActivityLogButton from '@root/controls/buttons/ActivityLogButton';
import FormButtons from './FormButtons';

const processResponse = (response) => {
  const { data } = response;
  const validChapters = data.chaptersAttributes.reduce(
    (acc, chapter) => ({ ...acc, [chapter._uuid]: serializableChapter(chapter) }),
    {},
  );
  return { ...data, validChapters };
};

export default function Edit({ ChaptersComponent, reducer }) {
  const entityName = 'Chapter set';
  const {
    currentApplication,
    typeName: appType,
    applicationName,
  } = useCurrentApplication();
  const ChapterSetRoutes = generateChapterBasedRoutes(appType).ChapterSets;
  const { response: data, setResponse } = useQuery(ChapterSetRoutes.editRequest, {}, processResponse);

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName,
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => {
    const validChapters = reduce(
      values.chaptersAttributes,
      (acc, dirtyChapter) => {
        const persistedChapter = values.validChapters[dirtyChapter._uuid];

        if (persistedChapter) {
          acc.push({ ...dirtyChapter, ...persistedChapter });
        } else {
          acc.push(dirtyChapter);
        }

        return acc;
      },
      [],
    );

    return ChapterSetRoutes
      .updateRequest({ ...values, chaptersAttributes: sortBy(validChapters, 'position') })
      .then((response) => responseHandler(response, values));
  };

  const fullData = {
    ...data,
    applicationName,
    applicationId: currentApplication.id,
  };

  if (!data) { return null; }

  return (
    <Form
      initialValues={fullData}
      onSubmit={onSubmit}
      enableReinitialize
      reducer={combineReducers(compact([chapterSetReducer, rewardableReducer, reducer]))}
    >
      {({ dirty }) => (
        <>
          <PageHeader title="Edit Chapter Set">
            <ActivityLogButton
              className="me-2"
              applicationId={currentApplication.id}
              id={fullData.id}
              entityType={`${applicationName}::${upperFirst(camelCase(entityName))}`}
            />
            <DuplicateButton
              className="me-2"
              disabled={dirty}
              routes={ChapterSetRoutes}
              data={fullData}
              entityName="Chapter Set"
            />
            <FormButtons cancelButtonPath={ChapterSetRoutes.indexPath({ applicationId: currentApplication.id })} />
          </PageHeader>

          <Label text="Name*">
            <Field name="name" fast />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <LabelsFormGroup disabled={false} />

          <Label text="CDN Link">
            <InputGroup>
              <Field name="cdnUrl" disabled />
              <CopyToClipboardAddon text={data.cdnUrl} />
            </InputGroup>
          </Label>
          <ChaptersComponent chapterSetId={data.id} />
        </>
      )}
    </Form>
  );
}
