import { clone, pick } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { addUuid } from '@services/recursivelyProcessObject';

export default function buildNewTranslationKey({ translationKey, newValue }) {
  const clonedTranslationKey = clone(translationKey);

  const newTranslations = translationKey
    .translationsAttributes
    .map((translation) => addUuid(pick(translation, ['value', 'languageId'])));

  clonedTranslationKey.id = null;
  clonedTranslationKey.keyId = null;
  clonedTranslationKey.name = newValue;
  clonedTranslationKey.markedAsChanged = true;
  clonedTranslationKey.translationsAttributes = newTranslations;
  clonedTranslationKey._uuid = uuidv4();

  return clonedTranslationKey;
}
