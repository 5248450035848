import { wrapReducer } from '@reducers';
import { addUuid } from '@services/recursivelyProcessObject';

const formReducer = (state, action) => {
  switch (action.type) {
    case 'addEmptyEntity': {
      state.entitiesAttributes.push(addUuid({
        id: null,
        nameId: null,
        impactId: null,
      }));
      break;
    }
    case 'removeEntity': {
      const { index } = action;

      state.entitiesAttributes[index]._destroy = true;
      break;
    }
    default: break;
  }

  return state;
};

export default wrapReducer(formReducer);
