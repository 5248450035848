import React from 'react';
import { Col } from 'react-bootstrap';

import { fromClassNameToOptions } from '@services/enums';
import {
  Filter,
  TextFilter,
  StatusFilter,
} from '@pages/common';
import { useCurrentApplication } from '@hooks';

export default function JourneysFilter({ statuses }) {
  const { applicationName } = useCurrentApplication();
  const statusOptions = fromClassNameToOptions('Journeys::Statuses');

  const defaultStatuses = [
    statuses.ACTIVE,
    statuses.INACTIVE,
  ];

  return (
    <Filter
      defaultComponents={['availabilityStateIn', 'labels', 'createdByMe', 'namedRuleGroup', 'id']}
    >
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <StatusFilter
              name="statusIn"
              label="Status"
              updateFilterField={updateFilterField}
              options={statusOptions}
              defaults={defaultStatuses}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name={`__config_of_${applicationName}::Journeys::ActivationEventConfig_type_default_variant_id_in`}
              label="Variant ID"
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
