import React from 'react';
import { PageTitle } from '@pages/common';

// Events
import EventsIndex from '@pages/daily-challenges/Index';
import EventsNew from '@pages/daily-challenges/New';
import EventsEdit from '@pages/daily-challenges/Edit';

// Level Banks
import LevelBanksIndex from '@pages/daily-challenges/level-banks/Index';
import LevelBanksNew from '@pages/daily-challenges/level-banks/New';
import LevelBanksEdit from '@pages/daily-challenges/level-banks/Edit';

import { DailyChallengesRoutes as Routes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function dailyChallegnes() {
  return [
    //
    // Events
    //
    <Route
      key={Routes.Events.indexRawPath}
      path={Routes.Events.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle translationPath="dailyChallenges.events.pluralName" />
          <EventsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Events.newRawPath}
      path={Routes.Events.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="New" translationPath="dailyChallenges.events.name" />
          <EventsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Events.editRawPath}
      path={Routes.Events.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="Edit" translationPath="dailyChallenges.events.name" />
          <EventsEdit />
        </RouteContainer>
      )}
    />,

    //
    // Level Banks
    //
    <Route
      key={Routes.LevelBanks.indexRawPath}
      path={Routes.LevelBanks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle translationPath="dailyChallenges.levelBanks.pluralName" />
          <LevelBanksIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.LevelBanks.newRawPath}
      path={Routes.LevelBanks.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="New" translationPath="dailyChallenges.levelBanks.name" />
          <LevelBanksNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.LevelBanks.editRawPath}
      path={Routes.LevelBanks.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="Edit" translationPath="dailyChallenges.levelBanks.name" />
          <LevelBanksEdit />
        </RouteContainer>
      )}
    />,
  ];
}
