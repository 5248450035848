/* eslint-disable react/destructuring-assignment */
import woodokuShortcutsBuilder from './woodoku';
import blackjackShortcutsBuilder from './blackjack';
import solitaireShortcutsBuilder from './solitaire';
import mergeShortcutsBuilder from './merge';
import tileShortcutsBuilder from './tile';
import myPetCafeShortcutsBuilder from './myPetCafe';
import commonShortcuts from './common';

const applicationsMapping = {
  'Woodoku::Application': woodokuShortcutsBuilder,
  'Blackjack::Application': blackjackShortcutsBuilder,
  'Solitaire::Application': solitaireShortcutsBuilder,
  'Merge::Application': mergeShortcutsBuilder,
  'Tile::Application': tileShortcutsBuilder,
  'MyPetCafe::Application': myPetCafeShortcutsBuilder,
};

export default function Finder(application) {
  const shortcutsBuilder = applicationsMapping[application.type];
  if (!shortcutsBuilder) return null;

  const fullShortcutsBuilder = shortcutsBuilder({ applicationId: application.id });
  fullShortcutsBuilder.push(commonShortcuts());

  return fullShortcutsBuilder;
}
