import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';

import { Filter, TextFilter, SelectFilter } from '@pages/common';

export default function ItemConfigurationSetsFilter({
  applicationId, schemaTypeName, requestSchemaOptions,
}) {
  const [worldSchemasOptions, setWorldSchemasOptions] = useState([]);

  useEffect(() => {
    requestSchemaOptions(applicationId).then(setWorldSchemasOptions);
  }, []);

  return (
    <Filter defaultComponents={['availabilityStateIn']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <SelectFilter
              name="schemaIdIn"
              label={`${schemaTypeName} = `}
              options={worldSchemasOptions}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
