import React, { useState } from 'react';

import { StreaksRoutes } from '@pages/routes';
import {
  PageHeader,
  Pagination,
  ReactTable,
  Filter,
  DuplicationModalWithPriority,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { useCurrentApplication, useQuery, useConfirm } from '@hooks';
import IconButton from '@controls/buttons';

import ColumnsFactory from './ColumnsFactory';

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);

  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { editPath, indexRequest, archiveRequest } = StreaksRoutes.Entities;
  const { response, refetch } = useQuery(indexRequest);

  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Streak',
    actionName: 'archive',
    refetch,
  });

  const onArchive = (id, name) => () => {
    confirm.showConfirmation({
      title: `Streak '${name}' will be archived and not available for work. Continue?`,
    })
      .then(() => archiveRequest({ applicationId, id })
        .then(responseHandler));
  };

  return response && (
    <div>
      <PageHeader title="Streaks" filter={<Filter defaultComponents={['availabilityStateIn']} />}>
        <IconButton.New to={StreaksRoutes.Entities.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={ColumnsFactory({
          applicationId, editPath, setDuplicatedRecord, onArchive,
        })}
        data={response.items}
        defaultSort={{ id: 'priority', desc: true }}
      />
      <DuplicationModalWithPriority
        entityName="Streak"
        record={duplicatedRecord}
        handleClose={() => setDuplicatedRecord(null)}
        entitiesByPriority={response.entitiesByPriority || {}}
        onSubmit={(values) => StreaksRoutes.Entities.duplicateRequest({ ...values, applicationId })}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
