import React from 'react';
import { Col } from 'react-bootstrap';

import { fromClassNameToOptions } from '@services/enums';
import { Filter, SelectFilter } from '@pages/common';

export default function DifficultySchemasFilter() {
  const typeOptions = fromClassNameToOptions('LabelTypes');

  return (
    <Filter defaultComponents={['createdByMe']}>
      {({ updateFilterField }) => (
        <Col md={6}>
          <SelectFilter
            name="labelTypeIn"
            label="Type = "
            options={typeOptions}
            updateFilterField={updateFilterField}
          />
        </Col>
      )}
    </Filter>
  );
}
