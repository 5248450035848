import React, { useMemo, useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes as Routes } from '@pages/routes';
import IconButton from '@controls/buttons';

import EditModal from './EditModal';
import NewModal from './NewModal';
import ColumnsFactory from './ColumnsFactory';

export default function Index() {
  const [editedRecord, setEditedRecord] = useState(null);
  const [newRecord, setNewRecord] = useState(false);
  const { response, refetch } = useQuery(Routes.Contacts.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Contact',
    actionName: 'deletion',
    refetch,
  });

  const onDelete = ({ id, internalId }) => () => {
    confirm.showConfirmation({
      title: `Contact '${internalId}' will be deleted and not available for work. Continue?`,
    })
      .then(() => Routes.Contacts.deleteRequest({ id, applicationId: query.applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({ setEditedRecord, onDelete }), []);

  return response ? (
    <div>
      <PageHeader title="Contacts">
        <IconButton.New onClick={() => setNewRecord(true)} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
      />
      <NewModal
        onHide={() => setNewRecord(false)}
        record={newRecord}
        refetch={refetch}
      />
      <EditModal
        onHide={() => setEditedRecord(null)}
        record={editedRecord}
        refetch={refetch}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  ) : '';
}
