import React, { memo } from 'react';

import { Label, Field } from '@controls/form';
import { TitledAlert } from '@components/shared';

import localizeField from './localizeField';
import areEnabledPropsEqual from './areEnabledPropsEqual';

export default function FieldBuilder({
  name: baseName, labelText, type, componentFn, alertText, fieldOptions = {}, tooltips = {},
}) {
  const addFormControlClass = type !== 'checkbox';

  return {
    [baseName]: {
      Inline: memo(({ name, disabled }) => (
        (componentFn && componentFn({ name: name || baseName, disabled })) || (
          <>
            <Field
              name={name || baseName}
              type={type}
              disabled={disabled}
              formControlClass={addFormControlClass}
              {...fieldOptions}
            />
            <TitledAlert name={name} alertText={alertText} />
          </>

        )
      ), areEnabledPropsEqual),
      Labeled: memo(({
        name, disabled, localeNamespace, locale = {}, tooltipBuilder,
      }) => {
        const { label, tooltip, clientKey } = localizeField(
          baseName, labelText, tooltips, localeNamespace, locale, tooltipBuilder,
        );

        return (
          <>
            <Label
              text={label}
              copyableText={clientKey}
              tooltipText={tooltip}
              fieldSize={8}
            >
              {(componentFn && componentFn({ name: name || baseName, disabled })) || (
                <Field
                  name={name || baseName}
                  type={type}
                  disabled={disabled}
                  formControlClass={addFormControlClass}
                  {...fieldOptions}
                />
              )}
            </Label>
            <TitledAlert name={label} alertText={alertText} />
          </>
        );
      }, areEnabledPropsEqual),
      options: fieldOptions || {},
      fieldOptions,
    },
  };
}
