import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';

import { WoodokuRoutes as Routes } from '@pages/routes';

import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';

import ExperimentScopedTabs from '@pages/ab-testing/experiments/ExperimentScopedTabs';
import { Variant } from '@pages/daily-challenges/config-forms/Woodoku';

import formReducer from '@pages/daily-challenges/formReducer';

export default function ExperimentScoped() {
  const { response, setResponse } = useQuery(Routes.DailyChallenges.EventConfigs.experimentScopedRequest);
  const { query: { applicationId } } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Daily Challenge Event Variant',
    actionName: 'update',
    setData: setResponse,
  });

  const handleSubmit = (values) => (
    Routes.DailyChallenges.EventConfigs.updateVariantsRequest({ applicationId, ...values }).then(responseHandler)
  );

  return (
    <ExperimentScopedTabs
      response={response}
      entityName="Daily Challenge Event"
      handleSubmit={handleSubmit}
      reducer={formReducer}
    >
      {({ variantAttributes, variantIndex }) => (
        <Variant variantAttributes={variantAttributes} variantIndex={variantIndex} />
      )}
    </ExperimentScopedTabs>
  );
}
