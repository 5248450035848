import { LocalizationsRoutes } from '@pages/routes';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const buildShorcut = ({
  applicationId,
  type,
  title,
  theme,
}) => (
  {
    title,
    link: LocalizationsRoutes.Translations.indexPath({ applicationId, type }),
    icon: faGlobe,
    color: theme.pink,
  }
);

export default function Localizations({ applicationId, theme }) {
  return [
    buildShorcut({
      applicationId,
      title: 'Translations (Live)',
      type: 'live',
      theme,
    }),
    buildShorcut({
      applicationId,
      title: 'Translations (Test)',
      type: 'test',
      theme,
    }),
  ];
}
