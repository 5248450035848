import React from 'react';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';

import { useFormContext } from '@hooks';
import { persistedMap } from '@services/utils';
import { PageSection } from '@pages/common';
import IconButton from '@controls/buttons';
import { InUseLinks } from '@pages/common/InUse';
import prepareInUseEntities from '@services/ab-testing/entitiesHelper';

export default function ExperimentEntitiesList({ disabled }) {
  const { values, dispatch } = useFormContext();

  const entities = get(values, 'variantsAttributes[0].subVariantsAttributes[0].experimentEntitiesAttributes', []);
  return entities.length > 0 && (
    <>
      <PageSection title="Experiment Entities" />
      <Table bordered data-testid="experiment_entities">
        <thead>
          <tr>
            <th>Entity</th>
            <th>Property Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {persistedMap(entities, ({
            entityName, type, entityId, propertyName, entityType, i18nPath, frontendIdentity,
          }) => {
            const action = {
              type: 'removeEntity',
              entity: {
                type,
                entityType,
                entityId,
                propertyName: propertyName || '',
              },
            };
            const onClick = (event) => {
              event.preventDefault();
              dispatch(action);
            };
            const experimentEntities = prepareInUseEntities(frontendIdentity, entityName, i18nPath);

            return (
              <tr key={`${entityType}-${entityId}-${propertyName}`}>
                <td>
                  <InUseLinks inUse={experimentEntities} />
                </td>
                <td>{propertyName || '-'}</td>
                <td>
                  <IconButton.Delete variant="danger" size="sm" onClick={onClick} disabled={disabled}>
                    Remove
                  </IconButton.Delete>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
