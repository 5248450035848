import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useI18n } from '@hooks';
import { formResponseHandler } from '@requests/responseHandlers';
import { MissionsRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { query: { id, applicationId } } = useRouter();
  const { response, setResponse } = useQuery(MissionsRoutes.AllocationTemplates.editRequest, { id });

  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  const responseHandler = formResponseHandler({
    entityName: `${namespaceName} Allocation`,
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    MissionsRoutes.AllocationTemplates
      .updateRequest({ ...values, applicationId }).then(responseHandler)
  );

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      actionName="Update"
    />
  );
}
