import React from 'react';

import requestSchemaOptions from '@components/merge/services/requestEventSchemaOptions';
import StartingGridsNew from '../../starting-grids/New';

export default function New(props) {
  return (
    <StartingGridsNew
      requestSchemaOptions={requestSchemaOptions}
      {...props}
    />
  );
}
