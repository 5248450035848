import React from 'react';
import { PageTitle } from '@pages/common';
import { Route } from 'react-router-dom';

import { LocalizationsRoutes } from '@pages/routes';
import { ConfigsIndex, ConfigsNew, ConfigsEdit } from '@components/localizations/configs';
import LanguagesIndex from '@components/localizations/languages/Index';
import TranslationsIndex from '@components/localizations/translations/Index';
import KeysIndex from '@components/localizations/keys/Index';

import RouteContainer from '@pages/RouteContainer';

export default function localizations() {
  return [
    <Route
      key={LocalizationsRoutes.Languages.indexRawPath}
      path={LocalizationsRoutes.Languages.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Languages" />
          <LanguagesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={LocalizationsRoutes.Translations.indexRawPath}
      path={LocalizationsRoutes.Translations.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <TranslationsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={LocalizationsRoutes.Configs.indexRawPath}
      path={LocalizationsRoutes.Configs.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Localization Configs" />
          <ConfigsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={LocalizationsRoutes.Configs.newRawPath}
      path={LocalizationsRoutes.Configs.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Localization Config" action="New" />
          <ConfigsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={LocalizationsRoutes.Configs.editRawPath}
      path={LocalizationsRoutes.Configs.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Localization Config" action="Edit" />
          <ConfigsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={LocalizationsRoutes.Keys.indexRawPath}
      path={LocalizationsRoutes.Keys.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Keys" />
          <KeysIndex />
        </RouteContainer>
      )}
    />,
  ];
}
