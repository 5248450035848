import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { Row, Col, Button } from 'react-bootstrap';
import { Field, ServerError } from '@controls/form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

const RemoveButton = styled(Button)`
  margin-left: -1.2em;
`;

export default function RewardItem({
  initialData, onChange, rewardItemsConfiguration, readOnly,
}) {
  const [data, setData] = useState(initialData);
  const addData = (dataToAdd) => setData((prevData) => ({ ...prevData, ...dataToAdd }));

  useEffect(() => onChange(data), [data]);

  const findConfiguration = (targetValue) => (
    rewardItemsConfiguration.find(({ value: configValue }) => configValue === targetValue)
  );

  const currentConfiguration = findConfiguration(data.type);

  const handleSelectType = ({ value: selectedValue }) => {
    const newConfiguration = findConfiguration(selectedValue);

    setData(({ id }) => ({ id, type: selectedValue, ...newConfiguration.buildDefaults() }));
  };

  const handleDelete = () => {
    addData({ _destroy: true });
  };

  const InputComponent = useMemo(() => {
    if (currentConfiguration) {
      return currentConfiguration.component;
    }

    return () => null;
  }, [currentConfiguration]);

  return (
    data._destroy !== true ? (
      <Row className="mb-2">
        <Col xs={4}>
          <ReactSelect
            value={currentConfiguration}
            onChange={handleSelectType}
            options={rewardItemsConfiguration}
            isDisabled={readOnly}
            className="react-select"
            classNamePrefix="react-select"
          />
          <ServerError name="type" />
        </Col>
        <InputComponent data={data} addData={addData} readOnly={readOnly} />
        <Col xs={1}>
          <RemoveButton onClick={handleDelete} disabled={readOnly} variant="default">
            <FontAwesomeIcon icon={faTrashAlt} />
          </RemoveButton>
        </Col>
        <Field type="hidden" name="id" />
      </Row>
    ) : ''
  );
}
