import React from 'react';
import { Modal } from 'react-bootstrap';

import { PageSection, Alert } from '@pages/common';
import { Form, useCurrentApplication, useQuery } from '@hooks';
import { Label, Field, ServerError } from '@controls/form';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { prepareInboundData } from '@requests/common';

import IconButton from '@controls/buttons';

export default function AdUnitCampaignsForm({
  actionName, request, onHide, submitForm, record, refetch,
}) {
  if (!record) { return null; }

  const { currentApplication } = useCurrentApplication();
  const { response: data, setResponse } = useQuery((params) => request({ ...params, id: record }));

  const fullData = { ...data, applicationId: currentApplication.id };

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Ad Unit Campaign',
    actionName,
    refetch,
  });

  const onSubmit = async (values) => {
    const response = await submitForm(values);
    responseHandler(response);

    if (response.status < 300) {
      onHide();
    } else {
      setResponse((currentValues) => ({ ...currentValues, ...values, ...prepareInboundData(response.data) }));
    }
  };

  return (
    <Modal show={!!record} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Ad Unit Campaign</Modal.Title>
      </Modal.Header>

      {data && (
        <Form
          initialValues={fullData}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => (
            <>
              <Modal.Body>
                <Label text="Name" required fieldSize={8}>
                  <Field name="name" />
                </Label>

                <PageSection title="Ad Unit Keys" />
                <ServerError name="platformKeys" as={Alert} />

                <Label text="iOS" direction="vertical">
                  <Field name="iosKey" />
                </Label>
                <Label text="Android" direction="vertical">
                  <Field name="androidKey" />
                </Label>
                <Label text="Amazon" direction="vertical">
                  <Field name="amazonKey" />
                </Label>
              </Modal.Body>
              <Modal.Footer>
                <IconButton.Save type="submit" />
                <IconButton.Cancel onClick={onHide} />
              </Modal.Footer>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
}
