import React from 'react';

import APP_DATA from '@services/appData';
import { useRouter, useFlashMessages } from '@tripledotstudios/react-core';
import { Form } from '@hooks';
import { fromClassNamesToOptions } from '@services/enums';
import {
  Label,
  Field,
  CheckboxField,
  FormButtonsGroup,
  SelectField,
  StaticFieldsFormGroup,
} from '@controls/form';
import { isInUse } from '@pages/common/InUse';
import { PageHeader } from '@pages/common';
import { WoodokuRoutes } from '@pages/routes';

const overlayTileType = APP_DATA.enums['Woodoku::TileTypes::Layers'].OVERLAY;

export default function TileTypesForm({ data, onSubmit, headerTitle }) {
  const { query: { applicationId } } = useRouter();
  const {
    layer: layerTooltip,
    includeInGoals: includeInGoalsTooltip,
    includeInProbabilities: includeInProbabilitiesTooltip,
    standalone: standaloneTooltip,
    height: heightTooltip,
    width: widthTooltip,
    parametersSchema: configurationSchemaTooltip,
  } = APP_DATA.modelsLocales.woodoku.tileTypes.tooltips;

  const [familyOptions, layerOptions] = fromClassNamesToOptions([
    'Woodoku::TileTypes::Families',
    'Woodoku::TileTypes::Layers',
  ]);
  const { error } = useFlashMessages();

  const preProcessingSubmit = (values) => {
    let { parametersSchema } = values;

    if (parametersSchema?.length) {
      try {
        parametersSchema = JSON.parse(parametersSchema);
      } catch (e) {
        error("Parameters schema isn't a valid JSON");
        return false;
      }
    }

    return onSubmit({
      ...values,
      standalone: values.layer === overlayTileType ? values.standalone : false,
      parametersSchema: values.parametersSchema ? JSON.parse(values.parametersSchema) : null,
    });
  };
  const itemIsInUse = isInUse(data.inUse);

  return (
    <Form initialValues={data} onSubmit={preProcessingSubmit}>
      {({ values }) => (
        <>
          <PageHeader title={headerTitle}>
            <FormButtonsGroup
              cancelButtonPath={WoodokuRoutes.TileTypes.indexPath({ applicationId })}
            />
          </PageHeader>

          <Label text="Name">
            <Field type="text" name="name" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <Label text="ID">
            <Field type="text" name="typeId" />
          </Label>
          <Label text="Family">
            <SelectField options={familyOptions} name="family" />
          </Label>
          <Label text="Layer Type" tooltipText={layerTooltip}>
            <SelectField options={layerOptions} name="layer" />
          </Label>
          <Label text="Max Number Of Clears">
            <Field type="number" name="maxNumberOfClears" />
          </Label>
          <Label text="Can be configured as a goal" tooltipText={includeInGoalsTooltip}>
            <CheckboxField name="includeInGoals" />
          </Label>
          <Label text="Can be configured for tile probability" tooltipText={includeInProbabilitiesTooltip}>
            <CheckboxField name="includeInProbabilities" />
          </Label>
          {values.layer === overlayTileType && (
            <Label text="Stand-alone tile" tooltipText={standaloneTooltip}>
              <CheckboxField name="standalone" />
            </Label>
          )}
          <Label text="Height" tooltipText={heightTooltip}>
            <Field type="number" name="height" disabled={itemIsInUse} />
          </Label>
          <Label text="Width" tooltipText={widthTooltip}>
            <Field type="number" name="width" disabled={itemIsInUse} />
          </Label>
          <Label text="Tile Configuration Schema" tooltipText={configurationSchemaTooltip} isHorizontal>
            <Field name="parametersSchema" as="textarea" htmlFieldProps={{ rows: 20 }} />
          </Label>
        </>
      )}
    </Form>
  );
}
