import load from '@services/loadEnvSpecificConfig';

const config = {
  feature: {
    text: 'dark',
    background: 'warning',
  },
  staging: {
    text: 'light',
    background: 'secondary',
  },
  tt_staging: {
    text: 'light',
    background: 'secondary',
  },
  production: {
    text: 'light',
    background: 'danger',
  },
  tt_production: {
    text: 'light',
    background: 'danger',
  },
  load_testing: {
    text: 'light',
    background: 'primary',
  },
};

export default function NavigationBrand() { return (load(config)); }
