import { merge } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';
import { serializableLevel } from '@pages/journeys/level-banks/app-utils/tile';
import { persistedCollection, persistedMap } from '@root/services/utils';
import { v4 } from 'uuid';

export default function tileReducer(state, action, appData) {
  const orderingTypes = appData.enums['Metagame::ChapterBased::ChapterOrderingTypes'];

  const { actionType } = action;
  const { configAttributes: { levelsAttributes } } = state;

  if (actionType === 'applyLevelValues') {
    const { uuid, pictureSegmentsAttributes } = action;

    const targetLevel = levelsAttributes.find(({ _uuid }) => _uuid === uuid);
    const persistedRewardAttributes = persistedMap(
      targetLevel.pictureSegmentsAttributes,
      (segmentAttributes) => (
        segmentAttributes.rewardAffiliationsAttributes.map((rewardAttributes) => (
          { ...rewardAttributes, id: null, _uuid: v4() }
        ),
        )),
    );

    const destroyPictureSegments = (startIndex = 0) => {
      targetLevel.pictureSegmentsAttributes = targetLevel.pictureSegmentsAttributes.reduce(
        (levels, level, levelIndex) => {
          if (levelIndex < startIndex) { return [...levels, level]; }
          return [...levels, { ...level, _destroy: true }];
        },
        [],
      );
    };

    const addEmptyPictureSegments = (levelsCount, keepRewardAttributes = false) => {
      const maxPosition = persistedCollection(targetLevel.pictureSegmentsAttributes).length;

      for (let i = 0; i < levelsCount; i += 1) {
        targetLevel.pictureSegmentsAttributes.push(addUuid({
          position: maxPosition + 1 + i,
          rewardAffiliationsAttributes:
            (keepRewardAttributes && !!persistedRewardAttributes[i]) ? persistedRewardAttributes[i] : [],
        }));
      }
    };

    const pictureSegmentsWithPersistedRewards = pictureSegmentsAttributes.reduce((
      (attributes, segmentAttributes, index) => {
        attributes.push({ ...segmentAttributes, rewardAffiliationsAttributes: persistedRewardAttributes[index] || [] });
        return attributes;
      }), []);

    if (orderingTypes.MANUALLY === targetLevel.orderingType) {
      const { _appliedValues } = targetLevel;

      if (_appliedValues.bankId !== targetLevel.bankId) {
        destroyPictureSegments();

        addEmptyPictureSegments(targetLevel.pictureSegmentsNumber, true);
      } else {
        const segmentsNumberChange = targetLevel.pictureSegmentsNumber - (_appliedValues.pictureSegmentsNumber || 0);
        if (segmentsNumberChange < 0) {
          destroyPictureSegments(targetLevel.pictureSegmentsAttributes.length + segmentsNumberChange);
        } else {
          addEmptyPictureSegments(segmentsNumberChange);
        }
      }
    } else {
      destroyPictureSegments();
      targetLevel.pictureSegmentsAttributes = targetLevel.pictureSegmentsAttributes.concat(
        pictureSegmentsWithPersistedRewards,
      );
    }

    targetLevel.populated = true;
    targetLevel._appliedValues = serializableLevel(targetLevel);
  }

  if (actionType === 'resetLevelValues') {
    const { uuid } = action;

    levelsAttributes.forEach((level) => {
      if (level._uuid !== uuid) return level;

      return merge(level, level._appliedValues);
    });
  }

  if (actionType === 'changeLevelLayout') {
    const { uuid, segmentIndex } = action;

    const targetLevel = levelsAttributes.find(({ _uuid }) => _uuid === uuid);

    targetLevel.orderingType = orderingTypes.MANUALLY;
    targetLevel._appliedValues = serializableLevel(targetLevel);
    targetLevel.pictureSegmentsAttributes[segmentIndex].modeConfigId = null;
  }

  return state;
}
