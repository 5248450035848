import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { PageHeader } from '@pages/common';
import { Form, FormGroup } from '@hooks';
import {
  FormButtonsGroup, Label, Field, PasteableJsonField, StaticFieldsFormGroup,
} from '@controls/form';
import { SolitaireRoutes } from '@pages/routes';

export default function LevelLayoutsForm({ data, onSubmit, action }) {
  const { query } = useRouter();

  const renderSpecificActionFields = () => {
    switch (action) {
      case 'new':
        return (
          <Row>
            <Col>
              <PasteableJsonField viewOnly name="configJson" theme="light_mitsuketa_tribute" />
            </Col>
          </Row>
        );
      case 'edit':
        return (
          <>
            <StaticFieldsFormGroup data={data} labelSize={2} />
            <Row className="mt-3">
              <Col><span className="fw-bold h6">Content</span></Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Card body>
                  <div className="fw-bold mb-5">JSON content</div>
                  <PasteableJsonField viewOnly name="configJson" theme="light_mitsuketa_tribute" />
                </Card>
              </Col>
            </Row>
          </>
        );
      default: return '';
    }
  };

  return (
    <Form initialValues={{ ...data }} onSubmit={onSubmit}>
      {() => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Level Layout`}>
            <FormButtonsGroup cancelButtonPath={SolitaireRoutes.LevelLayouts.indexPath(query)} />
          </PageHeader>

          <FormGroup>
            <Label text="Name" labelSize={2}><Field type="text" name="name" /></Label>

            {renderSpecificActionFields()}
          </FormGroup>
        </>
      )}
    </Form>
  );
}
