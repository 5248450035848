import { mapValues } from 'lodash';
import camelize from 'camelcase-keys';

const APP_DATA = {};

const data = JSON.parse(document.getElementById('app').dataset.applicationData);

APP_DATA.configs = data.configs;
APP_DATA.rawEnums = data.enums;
APP_DATA.enums = mapValues(data.enums, (klassEnum) => mapValues(klassEnum, 'value'));
APP_DATA.modelsLocales = camelize(data.modelsLocales, { deep: true, exclude: ['match3d'] });
APP_DATA.enumLocales = camelize(data.enumLocales, { deep: true });
APP_DATA.featureToggles = camelize(data.featureToggles, { deep: true });
APP_DATA.adminSettings = camelize(data.adminSettings, { deep: true });
APP_DATA.applications = camelize(data.applications, { deep: true });
APP_DATA.contactUsRecepients = camelize(data.contactUsRecepients, { deep: true });
APP_DATA.gameSpecificData = camelize(data.gameSpecificData, { deep: true });
APP_DATA.rewardTypes = data.rewardTypes;
APP_DATA.rewardOperations = data.rewardOperations;
APP_DATA.user = camelize(data.user, { deep: true });

export default APP_DATA;
