import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { rgba } from 'polished';
import { max } from 'lodash';
import { Spinner, IconButton } from '@tripledotstudios/react-core';

import { faScaleUnbalancedFlip } from '@fortawesome/free-solid-svg-icons';
import { useQuery, useI18n } from '@hooks';

import { ItemsComparisonRoutes } from '@pages/routes';
import Select from '@controls/Select';

import DiffViewer from './DiffViewer';

const Panel = styled.div`
  width: 50%;
  padding: 6px 8px 0px 8px;
  margin-right: 16px;
  border-radius: 6px;
  background-color: ${({ color }) => rgba(color, 0.55)};
`;

export default function ItemsComparison({
  applicationId, frontendRoutes, localePath, modelName, applicationName,
}) {
  const theme = useTheme();
  const { translate } = useI18n();

  const [leftValue, setLeftValue] = useState(null);
  const [rightValue, setRightValue] = useState(null);
  const [loadingComparison, setLoadingComparison] = useState(false);
  const handleChangeLeft = (data) => {
    setLeftValue(data);
  };
  const handleChangeRight = (data) => {
    setRightValue(data);
  };
  const withCutFilteresRequest = ({ filter, ...query }) => (
    frontendRoutes.indexRequest({ filter: { statusIn: [0, 1] }, ...query })
  );

  const { response, isLoading } = useQuery(withCutFilteresRequest, { withoutPagination: true });
  const itemsToCompare = response?.items.map((a) => ({ label: `(${a.id}) ${a.name}`, value: a.id })) || [];

  const [itemsDataLeft, setDataLeft] = useState(null);
  const [itemsDataRight, setDataRight] = useState(null);
  const [itemsDataReceived, setItemsDataReceived] = useState(false);

  const ascendingOrderOfIds = (leftValue < rightValue);
  const [allOpened, setAllOpened] = useState(false);
  const toggleOpened = () => setAllOpened((prevIsOpened) => !prevIsOpened);

  const processResponse = (comparisonResponse) => {
    const [leftData, rightData] = comparisonResponse.data;

    return (
      setDataLeft(ascendingOrderOfIds ? leftData : rightData),
      setDataRight(ascendingOrderOfIds ? rightData : leftData),
      setItemsDataReceived(true),
      setLoadingComparison(false)
    );
  };
  const localName = translate.fallback(`${localePath}.name`);

  const cleanOutput = () => (
    (setLoadingComparison(false),
    setDataLeft(null),
    setDataRight(null),
    setItemsDataReceived(false))
  );

  const onSubmit = () => (
    (cleanOutput(),
    setLoadingComparison(true),
    ItemsComparisonRoutes
      .compareEntitiesRequest({
        ids: [leftValue, rightValue],
        withoutPagination: true,
        applicationId,
        localName,
        modelName,
        applicationName,
      }, { skipUuids: true })
      .then((comparisonResponse) => processResponse(comparisonResponse)))
  );

  const [sameItemsSelected, setSameItemsSelected] = useState(false);
  const validateIds = () => (
    ((leftValue === rightValue) || (leftValue === null) || (rightValue === null))
      ? (setSameItemsSelected(true),
      cleanOutput()
      )
      : (onSubmit(),
      setSameItemsSelected(false)));
  const itemsAreEqual = itemsDataReceived && (JSON.stringify(itemsDataLeft) === JSON.stringify(itemsDataRight));

  return (
    <>
      <div className="d-flex mb-3">
        <Panel color={theme.variants.danger}>
          <Select
            className="mb-2"
            placeholder={`Select ${localName} to compare`}
            selectedValue={leftValue}
            options={itemsToCompare}
            onChange={(e) => handleChangeLeft(e.value)}
            isLoading={isLoading}
          />
        </Panel>
        <Panel color={theme.variants.success}>
          <Select
            className="mb-2"
            placeholder={`Select ${localName} to compare`}
            selectedValue={rightValue}
            options={itemsToCompare}
            onChange={(e) => handleChangeRight(e.value)}
            isLoading={isLoading}
          />
        </Panel>
        <IconButton icon={faScaleUnbalancedFlip} className="mb-2" onClick={validateIds} />
      </div>

      {loadingComparison
        ? <Spinner />
        : (
          <>
            {sameItemsSelected && (
            <div className="text-danger d-flex justify-content-center">
              { `Please, select 2 different ${translate.fallback(`${localePath}.pluralName`)} to compare!`}
            </div>
            )}
            { itemsAreEqual && (
            <div className="text-success d-flex justify-content-center">
              {`${translate.fallback(`${localePath}.pluralName`)} are identical`}
            </div>
            )}

            {itemsDataReceived && (
            <div>
              <div className="d-flex justify-content-end">
                <button type="button" className="btn btn-outline-info" onClick={toggleOpened}>
                  {allOpened ? 'Collapse All' : 'Expand All'}
                </button>
              </div>
              {Object.keys(itemsDataLeft).map((key) => {
                const maxItemsNumberInSet = itemsDataReceived
            && max([itemsDataLeft[key].length, itemsDataRight[key].length]);
                return (
                  Array.from({ length: maxItemsNumberInSet }, (_, index) => {
                    const noItemNumber = (key === 'generalAttributes' || key === 'rules');
                    const capitalItemName = key.charAt(0).toUpperCase() + key.slice(1);
                    return (
                      <DiffViewer
                        key={index}
                        itemNumber={noItemNumber ? '' : (index + 1)}
                        leftItem={itemsDataLeft[key][index] || {}}
                        rightItem={itemsDataRight[key][index] || {}}
                        itemName={capitalItemName}
                        allOpened={allOpened}
                      />
                    );
                  })
                );
              })}
            </div>
            )}
          </>
        )}

    </>
  );
}
