import React from 'react';

import {
  GameSettingsTabs,
  localizationsDefaultFieldNames,
  appEventsFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
  popupsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  adRequestDelayFieldNames,
} from '@components/game-settings/Resource';

const _popupsDefaultFieldNames = popupsDefaultFieldNames.filter((f) => (
  !['iosIdfaFakeNativePopupEnabled'].includes(f)
));

const _adRequestDelayFieldNames = adRequestDelayFieldNames.filter((f) => (
  !['adsBannerPrefetchDelay'].includes(f)
));

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'everythingJson',
      ...assetsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      {
        title: 'AD placements',
        fieldsNames: [
          ...adPlacementIdsDefaultFieldNames,
        ],
      },
      {
        title: 'AD Basics',
        fieldsNames: [
          ..._adRequestDelayFieldNames,
          ...adConfigsDefaultFieldNames,
        ],
      },
    ],
  },
  {
    eventKey: '#popups',
    title: 'Popups and Triggers',
    fieldsNames: [
      {
        title: 'Basic Pop-ups',
        fieldsNames: [
          ..._popupsDefaultFieldNames,
        ],
      },
    ],
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: appEventsFieldNames,
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  bakedInConfigsTab,
];

export default function Tabs({
  fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
