import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { combineReducers, levelsReducer, rewardsReducer } from '@reducers';
import { LevelSetRoutes as Routes } from '@pages/routes';
import ExperimentScopedTabs from '@pages/ab-testing/experiments/ExperimentScopedTabs';
import VariantForm from './VariantForm';

const reducer = combineReducers([
  rewardsReducer,
  levelsReducer,
]);

export default function ExperimentScoped() {
  const { response, setResponse } = useQuery(Routes.Sets.experimentScopedRequest);
  const { query } = useRouter();
  const { applicationId } = query;

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Level set variant',
    actionName: 'update',
    setData: setResponse,
  });

  const handleSubmit = (values) => Routes.Variants.updateVariantsRequest({ applicationId, ...values })
    .then(responseHandler);

  return (
    <ExperimentScopedTabs
      response={response}
      entityName="Level set"
      handleSubmit={handleSubmit}
      reducer={reducer}
    >
      {({ variantAttributes, variantIndex }) => (
        <VariantForm variantAttributes={variantAttributes} variantIndex={variantIndex} />
      )}
    </ExperimentScopedTabs>
  );
}
