import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  InLiveColumn,
  TIMESTAMP_COLUMNS,
  RulesList,
  StatusBadge,
  InUseColumn,
  LabelsColumn,
  PriorityColumn,
} from '@pages/common';

import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

export default function ColumnsFactory({
  applicationId, setDuplicatedRecord, statuses, onArchive, routes, levelEntityName, showLevelsCount, showLabels = true,
}) {
  return [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    PriorityColumn,
    {
      Header: 'Status',
      Cell: ({ row: { original: { status, statusHumanize } } }) => (
        <StatusBadge options={statuses} value={status} label={statusHumanize} />
      ),
    },
    InLiveColumn,
    ...(showLevelsCount ? [{ Header: `${levelEntityName}s count`, accessor: 'levelsCount' }] : []),
    InUseColumn,
    ...(showLabels ? [LabelsColumn] : []),
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Rules',
      Cell: ({ row: { original: { ruleSectionsAttributes } } }) => <RulesList rules={ruleSectionsAttributes} />,
    },
    {
      Header: 'Actions',
      Cell: ({
        row: {
          original: {
            id, name, status, inUse,
          },
        },
      }) => (
        <ButtonToolbar>
          <IconButton.Edit to={routes.editPath({ id, applicationId })} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
          <IconButton.Archive
            onClick={onArchive(id, name)}
            disabled={isInUse(inUse) || status === statuses.ARCHIVED}
            minimized
          />
        </ButtonToolbar>
      ),
    },
  ];
}
