import React, { useState, useEffect } from 'react';
import {
  at, get, flatten, transform,
} from 'lodash';

import APP_DATA from '@services/appData';
import {
  useCurrentApplication,
  useFormGroup,
  useFormContext,
} from '@hooks';
import { AbTestingRoutes } from '@pages/routes';
import { buildConverterFromIndexResponse } from '@services/toSelectOptions';
import SelectField from '@controls/form/SelectField';
import Label from '@controls/form/Label';
import LabelWithLink from './experiment-variant-rule/LabelWithLink';

const statuses = APP_DATA.enums.AbExperimentStatuses;
const archivedStatuses = [statuses.ARCHIVED, statuses.PERMANENTLY_ARCHIVED];

const VariantSelect = ({ variantsOptions, disabled }) => (
  <Label text="Variants" fieldSize={8} direction="vertical">
    <SelectField
      name="variantIds"
      options={variantsOptions}
      isMulti
      isDisabled={disabled}
    />
  </Label>
);

export default function ExperimentVariantRule({ disabled }) {
  const [experimentsOptions, setExperimentsOptions] = useState([]);
  const [variantsOptionsMap, setVariantsOptionsMap] = useState();
  const [variantsOptions, setVariantsOptions] = useState(null);

  const { generateName } = useFormGroup();
  const { setFieldValue, values } = useFormContext();
  const { currentApplication } = useCurrentApplication();

  const resetVariantOptions = (experimentIds, optionsMap = variantsOptionsMap) => {
    const newVariantOptions = flatten(at(optionsMap, experimentIds));
    setVariantsOptions(newVariantOptions);
    return newVariantOptions;
  };

  const addedExperimentIds = get(values, generateName('experimentIds'), []);

  useEffect(() => {
    AbTestingRoutes.Experiments.indexRequest({ applicationId: currentApplication.id, withoutPagination: true })
      .then((r) => {
        const experiments = r.data.items.map((experiment) => (
          { ...experiment, name: `${experiment.name} (${experiment.statusHumanize})` }
        ));
        const convertExperimentOptions = buildConverterFromIndexResponse({}, ['status']);
        setExperimentsOptions(convertExperimentOptions({ data: { items: experiments } }));

        const _variantsOptionsMap = transform(experiments, (result, experiment) => {
        // eslint-disable-next-line no-param-reassign
          result[experiment.id] = experiment.variants.map((v) => ({
            label: `${v.name} (${experiment.name})`, value: v.id,
          }));

          return result;
        }, {});

        setVariantsOptionsMap(_variantsOptionsMap);
        resetVariantOptions(
          addedExperimentIds,
          _variantsOptionsMap,
        );
      });
  }, []);

  const filterExperimentOptions = (options) => options.filter((option) => (
    (!archivedStatuses.includes(option.status) || addedExperimentIds.includes(option.value))
    && option.value !== values.originalExperimentId
  ));

  const handleExperimentsReload = (selected) => {
    if (variantsOptionsMap && selected) {
      const newVariantOptions = resetVariantOptions(selected.map((o) => o.value));
      const variantPath = generateName('variantIds');
      const currentVariants = get(values, variantPath, []);
      const newVariantIds = newVariantOptions.map((v) => v.value);
      setFieldValue(variantPath, currentVariants.filter((id) => newVariantIds.indexOf(id) > -1));
    }
  };

  return experimentsOptions && variantsOptionsMap ? (
    <>
      <SelectField
        className="mb-2"
        name="experimentIds"
        options={filterExperimentOptions(experimentsOptions)}
        onChange={handleExperimentsReload}
        isDisabled={disabled}
        isMulti
        components={{ MultiValueLabel: LabelWithLink }}
      />

      {variantsOptions && <VariantSelect disabled={disabled} variantsOptions={variantsOptions} />}
    </>
  ) : '';
}
