import React from 'react';
import { groupBy, toInteger, findIndex } from 'lodash';

import {
  Modal,
  ListGroup,
  Card,
  Badge,
} from 'react-bootstrap';

import APP_DATA from '@services/appData';

import { localizedEnumValueString } from '@services/enums';

const { enums } = APP_DATA;
const notificationStatus = enums['Accounts::Anonymisation::Notification::Statuses'];

const STATUS_BADGE_VARIANTS = {
  [notificationStatus.SKIPPED]: 'secondary',
  [notificationStatus.SCHEDULED]: 'warning',
  [notificationStatus.PROCESSING]: 'info',
  [notificationStatus.FAILED]: 'danger',
  [notificationStatus.PROCESSED]: 'success',
};

const StatusBadge = ({ status }) => {
  const statusString = localizedEnumValueString('Accounts::Anonymisation::Notification::Statuses', status);

  return (
    <Badge bg={STATUS_BADGE_VARIANTS[status]}>
      {statusString}
    </Badge>
  );
};

const buildStatusMessage = (status) => {
  switch (status.status) {
    case notificationStatus.SCHEDULED:
      if (status.attempt > 1) {
        return (
          <>
            <p>Server is trying to send another request</p>
            <p>{`Current attempt: ${status.attempt}`}</p>
            <p>{`Last error: ${status.message}`}</p>
          </>
        );
      }

      return 'The notification is scheduled';
    case notificationStatus.PROCESSING:
      return 'The notification is being processed';
    case notificationStatus.PROCESSED:
      return (
        <>
          <p>The notification has been successfully processed</p>
          {status.message && <p>{`Message: ${status.message}`}</p>}
        </>
      );
    case notificationStatus.FAILED:
      return (
        <>
          <p>All requests are failed, please contact this partner via email manually</p>
          <p>{`Error: ${status.message}`}</p>
        </>
      );
    case notificationStatus.SKIPPED:
      return `The notification was skipped. ${status.message}`;
    default:
      return null;
  }
};

const SingleNotificationPartnerStatus = ({ name, status }) => (
  <>
    <Card.Header className="d-flex justify-content-between align-items-start">
      <div className="me-auto">{name}</div>
      <StatusBadge status={status.status} />
    </Card.Header>

    <Card.Body>
      {buildStatusMessage(status)}
    </Card.Body>
  </>
);

const MultiNotificationPartnerStatus = ({ name, statuses }) => (
  <>
    <Card.Header>
      {name}
    </Card.Header>

    <Card.Body>
      <ListGroup>
        {statuses.map((status) => (
          <ListGroup.Item key={status.id} className="d-flex justify-content-between align-items-start">
            <div className="me-auto">
              <div className="fw-bold">{`Device #${status.deviceId}`}</div>
              <div>{buildStatusMessage(status)}</div>
            </div>

            <StatusBadge status={status.status} />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card.Body>
  </>
);

const PartnerStatus = ({ partner, statuses }) => {
  const partnerName = localizedEnumValueString('Accounts::Anonymisation::Notification::Partners', toInteger(partner));
  const isGeneralStatus = findIndex(statuses, (status) => status.deviceId !== null) === -1;

  return (
    <Card className="mb-3">
      {isGeneralStatus ? (
        <SingleNotificationPartnerStatus name={partnerName} status={statuses[0]} />
      ) : (
        <MultiNotificationPartnerStatus name={partnerName} statuses={statuses} />
      )}
    </Card>
  );
};

export default function AnonymisationStatusModal({
  statuses,
  show,
  setShow,
}) {
  const closeModal = () => setShow(false);

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Anonymisation status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.entries(groupBy(statuses, 'partner'))
          .map(([partner, partnerStatuses]) => (
            <PartnerStatus partner={partner} statuses={partnerStatuses} />
          ))}
      </Modal.Body>
    </Modal>
  );
}
