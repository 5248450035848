import React from 'react';

import { useQuery } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { AdminUsersRoutes } from '@pages/routes';

import APP_DATA from '@services/appData';

import Form from './Form';

export default function Profile() {
  const { editRequest, updateRequest } = AdminUsersRoutes;
  const { user: { id } } = APP_DATA;
  const { response, setResponse } = useQuery(editRequest, { id });

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'User',
    actionName: 'update',
    collectionUrl: '/admin/admin_users',
    setData: setResponse,
  });

  const onSubmit = (values) => updateRequest(values).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      actionName="Update"
    />
  );
}
