import React from 'react';

import Field from '@controls/form/Field';
import Label from '@controls/form/Label';

export default function PlayerBucketRule({ disabled }) {
  return (
    <>
      <Label text="Lower bound"><Field type="number" name="lowerBound" disabled={disabled} /></Label>
      <Label text="Upper bound"><Field type="number" name="upperBound" disabled={disabled} /></Label>
    </>
  );
}
