import APP_DATA from '@services/appData';

const { adminSettings: { enableBakedInConfigs } } = APP_DATA;

const bakedInConfigsTab = enableBakedInConfigs ? {
  eventKey: '#baked_in_configs',
  title: 'Baked-In configs',
  fieldsNames: ['bakedInConfigsJson'],
} : {};

export default bakedInConfigsTab;
