import React from 'react';
import { PageTitle } from '@pages/common';

import AdminUsersEdit from '@pages/admin-users/Edit';
import AdminUsersIndex from '@pages/admin-users/Index';
import AdminUsersNew from '@pages/admin-users/New';

import { AdminUsersRoutes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function adminUsers() {
  return [
    <Route
      key={AdminUsersRoutes.indexRawPath}
      path={AdminUsersRoutes.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Admin Users" />
          <AdminUsersIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdminUsersRoutes.newRawPath}
      path={AdminUsersRoutes.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Admin User" action="New" />
          <AdminUsersNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdminUsersRoutes.editRawPath}
      path={AdminUsersRoutes.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Admin User" action="Edit" />
          <AdminUsersEdit />
        </RouteContainer>
      )}
    />,
  ];
}
