import {
  React,
  useState,
  useEffect,
} from 'react';
import { isEmpty } from 'lodash';
import { IconButton } from '@tripledotstudios/react-core';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

import { useCurrentApplication } from '@hooks';

import { MassOperationsRoutes } from '@pages/routes';

import APP_DATA from '@services/appData';
import cleanedUrl from '@root/services/cleanedUrl';

import { add, parseISO, format } from 'date-fns';

function recalculateHrefValue(responseData, lookerAbName, setButtonHref) {
  const { data: { items } } = responseData;
  if (isEmpty(items)) {
    setButtonHref('#');
    return;
  }
  const { adminSettings: { lookerJourneyKeyMetricsUrl } } = APP_DATA;

  let variantIds = [];
  let minDate;
  let maxDate;
  items.forEach((item) => {
    variantIds = variantIds.concat(item.configAttributes.variantIds);

    if ((item.startAt) && (!minDate || minDate > parseISO(item.startAt))) {
      minDate = parseISO(item.startAt);
    }
    if ((item.endAt) && (!maxDate || maxDate < parseISO(item.endAt))) {
      maxDate = parseISO(item.endAt);
    }
  });
  const now = Date.now();
  if (!minDate) {
    minDate = add(now, { month: -1 });
  }
  if (!maxDate) {
    maxDate = add(now, { day: 1 });
  } else {
    maxDate = add(maxDate, { day: 1 });
  }
  const dateRange = [format(minDate, 'yyyy/MM/dd'), format(maxDate, 'yyyy/MM/dd')];
  const params = new URLSearchParams({
    App: lookerAbName,
    'Variant ID': variantIds.join(','),
    'Created Date': dateRange.join(' to '),
  }).toString();

  setButtonHref([cleanedUrl(lookerJourneyKeyMetricsUrl), params].join('?'));
}

function noLookerJourneyButton(appAdminSettings) {
  const { adminSettings } = APP_DATA;

  return !appAdminSettings.enableRedirectionToJourneyKeyMetrics || isEmpty(adminSettings.lookerJourneyKeyMetricsUrl);
}

export default function LookerJourneyButton({
  entityType,
  entityIds,
}) {
  const { currentApplication } = useCurrentApplication();
  if (noLookerJourneyButton(currentApplication.adminSettings)) { return ''; }

  const [buttonHref, setButtonHref] = useState('#');

  useEffect(() => {
    const requestData = { applicationId: currentApplication.id, ids: entityIds, modelName: entityType };
    if (isEmpty(entityIds)) {
      setButtonHref('#');
    } else {
      MassOperationsRoutes.indexRequest(requestData)
        .then((response) => recalculateHrefValue(response.data, currentApplication.lookerAbName, setButtonHref));
    }
  }, [JSON.stringify(entityIds)]);

  return (
    <IconButton
      title="Analyse"
      icon={faChartLine}
      variant="info"
      outline
      href={buttonHref}
      target="_blank"
      disabled={!entityIds || !entityIds.length}
    />
  );
}
