import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { ApplicationRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { query } = useRouter();
  const { applicationId } = query;
  const { response, setResponse } = useQuery(ApplicationRoutes.editRequest, { id: applicationId });

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Application',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => ApplicationRoutes.updateRequest(values).then(responseHandler);

  return response && (
    <Form
      actionType="Update"
      data={response}
      onSubmit={onSubmit}
    />
  );
}
