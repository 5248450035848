import { cloneDeep, get, set } from 'lodash';
import { serializableAttributes } from '@services/difficultySchemas';

export default function difficultySchemasReducer(state, action) {
  const { actionType } = action;

  if (actionType === 'generateMatrix') {
    const newState = cloneDeep(state);
    const { meta = {} } = state;
    const { response } = action;

    const configName = 'basedOnUsageConfigAttributes';

    if (response.status === 422) {
      return {
        ...newState,
        meta: { errors: { [configName]: response.data._meta.errors } },
      };
    }

    const newRanges = [
      ...state[configName].factorRangesAttributes.reduce(
        (ranges, range) => (range.id ? [...ranges, { ...range, _destroy: true }] : ranges),
        [],
      ),
      ...response.data.factorRangesAttributes,
    ];

    if (get(meta, 'errors.factorRangesAttributes')) {
      newState.meta = set(meta, 'errors.factorRangesAttributes', {});
    }

    newState.originalData = serializableAttributes(newState);
    newState[configName].factorRangesAttributes = newRanges;

    return newState;
  }

  return state;
}
