import React from 'react';
import { Link } from 'react-router-dom';

import { MergeRoutes } from '@pages/routes';
import { useCurrentApplication } from '@hooks';
import schemaLabel from '@components/merge/services/worldSchemaLabel';

export default function EventSchemaCell({ schema }) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();

  return (
    <Link to={MergeRoutes.Events.Schemas.editPath({ applicationId, id: schema.id })} target="_blank">
      {schemaLabel(schema)}
    </Link>
  );
}
