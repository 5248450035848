import variants from './variants';

import NestedConditionsSection from './Section';
import NestedCondition from './Condition';

const NestedConditions = {
  Section: NestedConditionsSection,
  Condition: NestedCondition,
};

export {
  variants,
  NestedConditions,
};
