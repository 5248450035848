import React from 'react';
import StatusBadge from './StatusBadge';
import InLiveBadge from './InLiveBadge';

export default function StatusAndAvailabilityColumn({ statuses }) {
  return {
    Header: 'Status and Availability',
    style: { width: 130 },
    Cell: ({
      row: {
        original: { status, statusHumanize, availabilityStateLive },
      },
    }) => (
      <>
        <p className="mb-1">
          <StatusBadge options={statuses} value={status} label={statusHumanize} className="me-1" />
        </p>
        <p className="mb-0">
          <InLiveBadge inLive={availabilityStateLive} />
        </p>
      </>
    ),
  };
}

