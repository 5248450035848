import React from 'react';

import {
  GameSettingsTabs,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  adsControlDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  adRequestDelayFieldNames,
  analyticsDefaultFieldNames,
  zendeskDefaultFieldNames,
  offlineGamesSettingsDefaultFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  offlineAdsFieldNames,
  assetsDefaultFieldNames,
} from '@components/game-settings/Resource';

const _popupsDefaultFieldNames = popupsDefaultFieldNames.filter((f) => (
  !['iosIdfaFakeNativePopupEnabled', 'iosIdfaRequestEnabled'].includes(f)
));

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'lobbyScreenEnabled',
      'gameScreenRestartButtonEnabled',
      'splashscreenTimeout',
      ...assetsDefaultFieldNames,
      'localizationsConfigId',
      'dataStorageCheckEnabled',
      'homeScreenConfig',
      'iconStyle',
      'startLobbyOrder',
      'liveopsEnabled',
      'classicLobbyForceContinueButtonEnabled',
    ],
  },
  {
    eventKey: '#popups_and_triggers',
    title: 'Popups and Triggers',
    fieldsNames: [
      {
        title: 'Basic Pop-ups',
        fieldsNames: [
          ..._popupsDefaultFieldNames,
          'nativeAppUpdate',
          'privacyPolicyPopup',
        ],
      },
      {
        title: 'Custom Pop-ups',
        fieldsNames: [
          'rateUsPopups',
          'minDaysSinceLastPopupExposure',
          'journeySurfacingPopupTriggers',
          'flexiblePopup',
          'flexiblePopupEnabled',
          'questionnaireConfigJson',
          ...offlineGamesSettingsDefaultFieldNames,
          'newJourneyPopupDelay',
        ],
      },
    ],
  },
  {
    eventKey: '#difficulty',
    title: 'Difficulty',
    fieldsNames: [
      'rewardByPlacing',
      'rewardByClearing',
      'rewardByCombo',
      'rewardByStreak',
      'firstBankValidConfiguration',
      'classicPlaceableShapesConfigId',
      'dcPlaceableShapesConfigId',
      'journeyPlaceableShapesConfigId',
    ],
  },
  {
    eventKey: '#cosmetics',
    title: 'Cosmetics',
    fieldsNames: [],
  },
  {
    eventKey: '#classic',
    title: 'Classic',
    fieldsNames: [
      'dcSurfaceAfter',
      'dcSurfacePersists',
      'percentileForScoresConfig',
    ],
  },
  {
    eventKey: '#daily_challenge',
    title: 'Daily Challenge',
    fieldsNames: [
      'dcFigureSetId',
      'tinyGemRequirements',
      'smallGemRequirements',
      'mediumGemRequirements',
      'largeGemRequirements',
      'dcReplayConfig',
      'dcModeEnabled',
    ],
  },
  {
    eventKey: '#progression_events',
    title: 'Progression Events',
    fieldsNames: [
      'unlockJourney',
      'xForUnlockJourney',
      'gameEndLoseJourneyFlow',
      'journeyNotifyType',
      'journeyBadgesConfiguration',
      'journeyGameScreenThemeEnabled',
    ],
  },
  {
    eventKey: '#economy',
    title: 'Economy',
    fieldsNames: [
      'initialCoins',
      'showPurseInLobby',
      'showPurseInJourney',
      'coinRewardForDcWin',
      'coinRewardForClassicNoHighScore',
      'coinRewardForClassicWeeklyHighScore',
      'coinRewardForClassicMonthlyHighScore',
      'coinRewardForClassicYearlyHighScore',
      'coinRewardForClassicAllTimeHighScore',
      'coinRewardForClassicDailyHighScore',
      'coinsRewardMoment',
      'coinRewardForJourneyWin',
      'coinRewardForJourneyLose',
    ],
  },
  {
    eventKey: '#power_ups',
    title: 'Power-ups',
    fieldsNames: [
      'playOnConfiguration',
      'powerUpConfiguration',
    ],
  },
  {
    eventKey: '#minigames',
    title: 'Minigames',
    fieldsNames: ['miniGamesUrls'],
  },
  {
    eventKey: '#quests',
    title: 'Quests',
    fieldsNames: ['questsConfig'],
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      {
        title: 'AD placements',
        fieldsNames: [
          ...adPlacementIdsDefaultFieldNames,
        ],
      },
      {
        title: 'AD Basics',
        fieldsNames: [
          'adsMediation',
          ...adRequestDelayFieldNames,
          'appsflyerClientAdRevenueTimeframe',
          'appsflyerClientAdRevenueThreshold',
        ],
      },
      {
        title: 'Offline ADs',
        fieldsNames: [
          ...offlineAdsFieldNames,
        ],
      },
    ],
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: analyticsDefaultFieldNames,
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: pushNotificationsDefaultFieldNames,
  },
  {
    eventKey: '#social',
    title: 'Social',
    fieldsNames: ['shareButtonEnabled'],
  },
  {
    eventKey: '#tasks',
    title: 'Tasks',
    fieldsNames: [],
  },
  {
    eventKey: '#pop_ins',
    title: 'Pop-ins',
    fieldsNames: [],
  },
  {
    eventKey: '#liveops_events',
    title: 'LiveOps Events',
    fieldsNames: [],
  },
  {
    eventKey: '#debug',
    title: 'Debug',
    fieldsNames: [],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: [
      ...popupsDefaultArchivedFieldNames,
      'dcSpecialBlockChanceMax',
      'dcSpecialBlockChanceMin',
      'gameStartAnimationInClassic',
      'gameStartAnimationInDc',
      'gameStartAnimationInJourney',
      'dcGemCountersIncrement',
      'onboardingTutorialComboStepEnabled',
      'onboardingTutorialGameEndStepEnabled',
      'iosIdfaFakeNativePopupEnabled',
      'dynamicConfigCooldown',
      'dynamicConfigDefaultDifficulty',
      'dynamicConfigId',
      'dcBlocksToWinMax',
      'dcBlocksToWinMin',
      'lobby',
      'adPlacement',
      'adNewUserCooldown',
      'adsInterstitialRetryTimeout',
      'adsRewardedVideoRetryTimeout',
      ...adsControlDefaultFieldNames,
      ...zendeskDefaultFieldNames,
      'dcAndClassicScreensEnabled',
      'classicScreenNewDesign',
      'dcSpecialTilesChance',
      'dcSymmetricalConfig',
      'dcStartingBlocksRegionMax',
      'dcBlocksMax',
      'dcBlocksMin',
      'dcAdaptiveDifficulty',
      'onboardingTutorialRowStepEnabled',
      'onboardingTutorialColumnStepEnabled',
      'onboardingTutorialRegionStepEnabled',
      'oneValidMoveRule',
      'statsToday',
      'statsWeek',
      'statsMonth',
      'statsYear',
      'gemCollectionButtonLobby',
      'calendarButtonLobby',
    ],
  },
];

export default function Tabs({
  setContractType, fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      onChange={setContractType}
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
