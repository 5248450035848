import React, { useCallback } from 'react';
import { Tab } from 'react-bootstrap';
import { get } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import {
  Form, useConfirm, checkAvailabilityOnSubmit,
} from '@hooks';
import {
  Label, Field, SelectField, FormButtonsGroup, PriorityField, AvailabilityStateFormGroup, StaticFieldsFormGroup,
} from '@controls/form';
import TabsWrapper from '@controls/UncontrolledTabs';
import RulesForm from '@pages/rules/Form';
import { PageHeader } from '@pages/common';

import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { MergeRoutes } from '@pages/routes';
import { combineReducers, rulesReducer } from '@reducers';

import formReducer from './formReducer';
import WorldTab from './form/Tab';

const availabilityStatesEnum = APP_DATA.enums.AvailabilityStates;

export default function WorldsForm({ data, onSubmit, actionName }) {
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();

  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(applicationId), []);
  const onSubmitWrapper = (values) => checkAvailabilityOnSubmit({
    values, onSubmit, confirm, availabilityStatesEnum,
  });

  const errors = get(data, '_meta.errors', {});
  const renderTab = (key, title, schemaId, fieldsNames) => {
    const hasErrors = fieldsNames.some((fieldName) => errors[fieldName]);

    return (
      <Tab
        eventKey={key}
        title={title}
        className="mt-4"
        tabClassName={hasErrors ? 'text-danger' : ''}
      >
        <WorldTab schemaId={schemaId} applicationId={applicationId} fieldsNames={fieldsNames} />
      </Tab>
    );
  };

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmitWrapper}
      reducer={combineReducers([formReducer, rulesReducer])}
    >
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} World`}>
            <FormButtonsGroup cancelButtonPath={MergeRoutes.Worlds.indexPath({ applicationId })} />
          </PageHeader>

          <Label text="Name"><Field type="text" name="name" /></Label>
          <Label text="Description"><Field type="text" name="description" /></Label>
          <Label text="Priority">
            <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} />
          </Label>
          <Label text="World Schema">
            <SelectField
              name="schemaId"
              options={fetchSchemaOptions}
              onChange={() => dispatch({ type: 'changeSchemaId' })}
              isDisabled={actionName !== 'Create'}
            />
          </Label>
          <AvailabilityStateFormGroup />

          <StaticFieldsFormGroup data={data} />

          {values.schemaId && (
            <TabsWrapper>
              {renderTab('#main', 'Main', values.schemaId, [
                'itemConfigurationSetId',
                'postcardSetId',
                'expertSetId',
                'levelSetId',
                'unbubblingConfigurationId',
                'inventoryConfigurationId',
                'startingGridId',
                'cutscenesConfigurationId',
              ])}
              {renderTab('#shop', 'Shop', values.schemaId, [
                'gemsCatalogId',
                'coinsCatalogId',
                'energyChestProductId',
                'energyProductId',
                'piggyBankProductId',
                'shopItemsRandomizationTimer',
                'bundleCatalogId',
              ])}
            </TabsWrapper>
          )}

          <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} />
        </>
      )}
    </Form>
  );
}
