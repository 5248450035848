import React from 'react';
import styled, { useTheme } from 'styled-components';
import { rgba } from 'polished';

import { useFormContext } from '@hooks';
import IconButton from '@controls/buttons';

const StyledTr = styled.tr`
  ${({ color }) => `background-color: ${color};`}
`;

export default function RestorableTableRow({
  isNewRecord, removed, deleteActionType, restoreActionType, index, children, ...rest
}) {
  const { dispatch } = useFormContext();
  const theme = useTheme();

  const onDelete = () => dispatch({ type: deleteActionType, index });
  const onRestore = () => dispatch({ type: restoreActionType, index });

  const resolveColor = () => {
    if (removed) return rgba(theme.variants.danger, 0.15);
    if (isNewRecord) return rgba(theme.variants.success, 0.15);

    return theme.bootstrap.background;
  };

  return (
    <StyledTr color={resolveColor()} {...rest}>
      {children}
      <td>
        {removed ? (
          <IconButton.Restore onClick={onRestore} minimized />
        ) : (
          <IconButton.Delete onClick={onDelete} minimized />
        )}
      </td>
    </StyledTr>
  );
}
