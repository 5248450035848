import React from 'react';

import { ItemsSelect } from '@components/merge/shared/items/Select';

export default function ItemSelectFilter({
  options, name, filters, setFilters, operationType = 'eq',
}) {
  const handleFilterChange = (option) => (
    setFilters((prev) => {
      if (option) return { ...prev, [name]: { type: operationType, value: option.value } };

      const { [name]: _, ...rest } = prev;
      return rest;
    })
  );
  const currentOption = options.find(({ value }) => filters[name] && filters[name].value === value);

  return (
    <ItemsSelect
      value={currentOption ? currentOption.value : null}
      onChange={handleFilterChange}
      items={options}
      isClearable
    />
  );
}
