import React from 'react';

import { get } from 'lodash';
import { useFormContext, useFormGroup } from '@hooks';
import Fields from './multiple-dates/Fields';

export default function InstallDateRule({ disabled }) {
  const { values } = useFormContext();
  const { generateName } = useFormGroup();
  const operation = get(values, generateName('operation'));

  return (
    <Fields
      name="installDates"
      operation={operation}
      disabled={disabled}
    />
  );
}
