import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import Tooltip from '@controls/Tooltip';

const Icon = styled(FontAwesomeIcon).attrs({ icon: faExclamationTriangle })`
  path {
    fill: #dc3545;
  }
  ${({ float }) => (float ? `float: ${float}` : '')}
`;

export default function ErrorIcon({ error, float, tooltipPlacement }) {
  return (
    <Tooltip text={error} placement={tooltipPlacement} icon={<Icon float={float} />} />
  );
}
