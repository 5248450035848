import React from 'react';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { IconTooltip } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

const { contactUsRecepients } = APP_DATA;

export default function ContactUsTooltip({ ...rest }) {
  const handleClick = () => {
    const currentPageUrl = window.location.href;
    const mailto = contactUsRecepients.join(',');
    const subject = '[GS Support] Feedback';
    const body = encodeURIComponent(`


----

Location: ${currentPageUrl}
    `);

    window.location.href = `mailto:${mailto}?subject=${subject}&body=${body}`;
  };

  return <IconTooltip icon={faComments} text="Click to contact us" onClick={handleClick} {...rest} />;
}
