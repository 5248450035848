import React from 'react';
import styled from 'styled-components';
import { pick } from 'lodash';

import { FormGroup, useCurrentApplication } from '@hooks';
import { generateChapterBasedRoutes } from '@pages/routes';
import { TrackableField, TrackableSelectField } from '@pages/common/TrackableFields';
import { ErrorIcon, RemoveButton } from '@pages/common';
import Tooltip from '@controls/Tooltip';
import IconButton from '@controls/buttons';
import { Label, Field } from '@controls/form';

const COLUMN_PADDING_SIZE = 10;

export const Title = styled.span`
  font-weight: bold;
`;

export const ChapterRow = styled.div`
  align-items: flex-start;
  display: flex;
  padding: 10px 0;
`;

const SizeField = styled(TrackableField)`
  width: 70px;
`;

const ComplexField = styled.div`
  display: flex;
  align-items: center;
`;

const LevelRange = styled.div`
  margin-left: 10px;
`;

const SizeColumn = styled.div`
  flex-grow: 1;
`;

const LayoutBankColumn = styled.div`
  flex-grow: 2;
`;

const RightActionsColumn = styled.div`
  padding-left: ${COLUMN_PADDING_SIZE}px;
  padding-top: 36px;
`;

const HintIconContainer = styled.div`
  padding-top: .75em;
  padding-right: 1em;
  display: inline-block;
`;

const BankSelectField = styled(TrackableSelectField)`
  width: 40%;
`;

const RemoveBankButton = styled(RemoveButton)`
  width: 5%;
  padding: 0%;
`;

export const ColumnTitle = ({ children, tooltip }) => {
  const text = <Title>{children}</Title>;

  return (
    <div className="mb-3">
      {tooltip ? (
        <Tooltip text={tooltip}>{text}</Tooltip>
      ) : text}
    </div>
  );
};

export const LevelsConfigFields = ({
  banks, chapter, isDirty, levelsTotal, populateChapter, dispatch,
}) => {
  const { currentApplication, typeName: gameType } = useCurrentApplication();
  const { Banks } = generateChapterBasedRoutes(gameType);
  const { chapterBankAffiliationsAttributes = [], levelsAttributes = [] } = chapter;
  const bankQuickLinkFn = (id) => Banks.editPath({ id, applicationId: currentApplication.id });

  return (
    <ChapterRow>
      <SizeColumn>
        <ColumnTitle>Number of levels</ColumnTitle>
        <ComplexField>
          <SizeField
            name="levelsNumber"
            dirty={isDirty('levelsNumber')}
            type="number"
            min={1}
          />
          <LevelRange>{`From ${levelsTotal + 1} to ${levelsTotal + chapter.levelsNumber}`}</LevelRange>
        </ComplexField>
      </SizeColumn>
      <LayoutBankColumn>
        <ColumnTitle>Layout Banks to use</ColumnTitle>
        <div>
          {chapterBankAffiliationsAttributes.map(({
            bankId,
            applyAll,
            _uuid,
            _destroy,
          }, index) => (
            !_destroy && (
              <FormGroup name={`chapterBankAffiliationsAttributes.${index}`}>
                <div className="mb-2">
                  {chapterBankAffiliationsAttributes.filter(({ _destroy: destroy }) => !destroy).length > 1 && (
                    <RemoveBankButton
                      title="Remove bank from Chapter"
                      size="lg"
                      className="d-inline-block"
                      onClick={() => dispatch({
                        actionType: 'removeChapterBankAffiliation',
                        chapterUuid: chapter._uuid,
                        _uuid,
                      })}
                    />
                  )}
                  <BankSelectField
                    name="bankId"
                    className="ms-2 d-inline-block"
                    dirty={isDirty('bankId')}
                    options={banks}
                    quickLinkFn={bankQuickLinkFn}
                  />
                  <div className="d-inline-block ms-2 w-50">
                    {bankId && (
                      <>
                        <Label
                          text="Apply all"
                          withMargins={false}
                          direction="vertical"
                          wrapperClassName="d-inline-block text-center"
                        >
                          <Field type="checkbox" name="applyAll" />
                        </Label>
                        <Label
                          text="Range of levels"
                          withMargins={false}
                          direction="vertical"
                          wrapperClassName="d-inline-block text-center ms-2 w-75"
                        >
                          <div className="d-flex justify-content-center">
                            <Field
                              wrapperClassName="me-2"
                              type="number"
                              name="fromLevel"
                              disabled={applyAll}
                              prependText="From"
                            />

                            <Field type="number" name="toLevel" disabled={applyAll} prependText="To" />
                          </div>
                        </Label>
                      </>
                    )}
                  </div>
                </div>
              </FormGroup>
            )
          ))}
        </div>
        <IconButton.New
          className="mt-2"
          onClick={() => dispatch({ actionType: 'addChapterBankAffiliation', chapterUuid: chapter._uuid })}
        >
          Add Layout Bank
        </IconButton.New>
      </LayoutBankColumn>
      <RightActionsColumn>
        {levelsAttributes.length === 0 && (
          <HintIconContainer>
            <ErrorIcon error='Press "Apply" to create level configurations' float="right" />
          </HintIconContainer>
        )}
        <IconButton.Apply className="float-end" onClick={populateChapter}>Apply to levels</IconButton.Apply>
      </RightActionsColumn>
    </ChapterRow>
  );
};

const newChapterAttributes = ['bankId', 'position', 'levelsNumber', '_uuid'];

export const populateChapters = async ({
  application,
  chapters,
  dispatch,
  overrides = {},
}) => {
  const { typeName: gameType } = application;
  const { Chapters } = generateChapterBasedRoutes(gameType);

  Chapters.newRequest({
    applicationId: application.id,
    chaptersAttributes: chapters.map((chapter) => ({
      ...pick(chapter, newChapterAttributes),
      chapterBankAffiliationsAttributes: chapter.chapterBankAffiliationsAttributes.filter(({ _destroy }) => !_destroy),
      ...overrides,
    }), {}),
  }).then((response) => dispatch({ actionType: 'populateChapter', response }));
};
