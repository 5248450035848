import React from 'react';
import { Route } from 'react-router-dom';

import { PageTitle } from '@pages/common';

import RouteContainer from '@pages/RouteContainer';
import { WooAspenRoutes as Routes } from '@pages/routes';

import {
  BanksEdit,
  BanksIndex,
  BanksNew,

  LevelLayoutsEdit,

  ChapterSetsIndex,
  ChapterSetsNew,
  ChapterSetsEdit,

  RewardSetsIndex,
  RewardSetsNew,
  RewardSetsEdit,

  PlayOnPopUpsIndex,
  PlayOnPopUpsNew,
  PlayOnPopUpsEdit,

  ChapterPicturesIndex,
  ChapterPicturesNew,
  ChapterPicturesEdit,

  PurchaseConfigsIndex,
  PurchaseConfigsNew,
  PurchaseConfigsEdit,

  LiveOpsTokensIndex,
} from '@pages/woo/aspen';

export default function aspen() {
  return [
    [
      // Layout Banks
      <Route
        key={Routes.Banks.indexRawPath}
        path={Routes.Banks.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="Level Layout Banks" />
            <BanksIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.Banks.newRawPath}
        path={Routes.Banks.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Level Layout Bank" action="New" />
            <BanksNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.Banks.editRawPath}
        path={Routes.Banks.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Level Layout Bank" action="Edit" />
            <BanksEdit />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.LevelLayouts.editRawPath}
        path={Routes.LevelLayouts.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Level Layout" action="Edit" />
            <LevelLayoutsEdit />
          </RouteContainer>
        )}
      />,

      // Chapter Sets
      <Route
        key={Routes.ChapterSets.indexRawPath}
        path={Routes.ChapterSets.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="Chapter Sets" />
            <ChapterSetsIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.ChapterSets.newRawPath}
        path={Routes.ChapterSets.newRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle entity="Chapter Set" action="New" />
            <ChapterSetsNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.ChapterSets.editRawPath}
        path={Routes.ChapterSets.editRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle entity="Chapter Set" action="Edit" />
            <ChapterSetsEdit />
          </RouteContainer>
        )}
      />,

      // Reward Sets
      <Route
        key={Routes.RewardSets.indexRawPath}
        path={Routes.RewardSets.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle entity="Reward Schema Set" action="List" />
            <RewardSetsIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.RewardSets.newRawPath}
        path={Routes.RewardSets.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Reward Schema Set" action="New" />
            <RewardSetsNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.RewardSets.editRawPath}
        path={Routes.RewardSets.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Reward Schema Set" action="Edit" />
            <RewardSetsEdit />
          </RouteContainer>
        )}
      />,

      // Play on pop-up
      <Route
        key={Routes.PlayOnPopUps.indexRawPath}
        path={Routes.PlayOnPopUps.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle entity="Play on pop-ups" action="List" />
            <PlayOnPopUpsIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.PlayOnPopUps.newRawPath}
        path={Routes.PlayOnPopUps.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Play on pop-up" action="New" />
            <PlayOnPopUpsNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.PlayOnPopUps.editRawPath}
        path={Routes.PlayOnPopUps.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Play on pop-up" action="Edit" />
            <PlayOnPopUpsEdit />
          </RouteContainer>
        )}
      />,

      // Chapter Pictures
      <Route
        key={Routes.Chapters.Pictures.indexRawPath}
        path={Routes.Chapters.Pictures.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle entity="Pictures" action="List" />
            <ChapterPicturesIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.Chapters.Pictures.newRawPath}
        path={Routes.Chapters.Pictures.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Picture" action="New" />
            <ChapterPicturesNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.Chapters.Pictures.editRawPath}
        path={Routes.Chapters.Pictures.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Picture" action="Edit" />
            <ChapterPicturesEdit />
          </RouteContainer>
        )}
      />,

      // Purchase Configs
      <Route
        key={Routes.PurchaseConfigs.indexRawPath}
        path={Routes.PurchaseConfigs.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle entity="Purchase Configs" action="List" />
            <PurchaseConfigsIndex />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.PurchaseConfigs.newRawPath}
        path={Routes.PurchaseConfigs.newRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Purchase Config" action="New" />
            <PurchaseConfigsNew />
          </RouteContainer>
        )}
      />,
      <Route
        key={Routes.PurchaseConfigs.editRawPath}
        path={Routes.PurchaseConfigs.editRawPath}
        element={(
          <RouteContainer>
            <PageTitle entity="Purchase Config" action="Edit" />
            <PurchaseConfigsEdit />
          </RouteContainer>
        )}
      />,

      // LiveOps tokens
      <Route
        key={Routes.LiveOpsTokens.indexRawPath}
        path={Routes.LiveOpsTokens.indexRawPath}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="LiveOps Tokens" />
            <LiveOpsTokensIndex />
          </RouteContainer>
        )}
      />,
    ],
  ];
}
