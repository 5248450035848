import React, { useMemo, useState } from 'react';
import {
  Button, Card, ListGroup, Col,
} from 'react-bootstrap';
import styled from 'styled-components';
import { isEmpty, orderBy } from 'lodash';

import { useWebSocketChannel } from '@hooks';
import { MESSAGE_TYPES } from '@hooks/useWebSocketChannel';
import {
  BaseFilter,
  TextFilter,
  SelectFilter,
  ScrollToTopButton,
} from '@pages/common';
import Select from '@controls/Select';

const OrderSelect = styled(Select)`
  min-width: 150px;
`;

const EVENT_SOURCE = {
  client: 'client',
  server: 'server',
};

const CHANNEL_MESSAGE_VARIANT_MAPPING = {
  [EVENT_SOURCE.client]: 'light',
  [EVENT_SOURCE.server]: 'secondary',
};

const DEFAULT_ORDER = 'desc';
const ORDER_OPTIONS = [
  { label: 'DESC', value: DEFAULT_ORDER },
  { label: 'ASC', value: 'asc' },
];

const INITIAL_FILTERS_STATE = {
  eventSource: null,
  eventName: [],
  match: [],
};

const EVENT_SOURCE_OPTIONS = [
  { label: 'Client', value: EVENT_SOURCE.client },
  { label: 'Server', value: EVENT_SOURCE.server },
];

const MessageListItem = ({ variant, data }) => (
  <ListGroup.Item variant={variant}>
    <pre className="mb-0">{data}</pre>
  </ListGroup.Item>
);

const Message = ({ message }) => {
  switch (message.type) {
    case MESSAGE_TYPES.CHANNEL: {
      return (
        <MessageListItem
          variant={CHANNEL_MESSAGE_VARIANT_MAPPING[message.data.meta.source]}
          data={JSON.stringify(message.data.payload, null, '  ')}
        />
      );
    }
    case MESSAGE_TYPES.SYSTEM.SUCCESS: {
      return <MessageListItem variant="success" data={message.data} />;
    }
    case MESSAGE_TYPES.SYSTEM.FAILURE: {
      return <MessageListItem variant="danger" data={message.data} />;
    }
    default:
      return null;
  }
};

export default function EventsTracer({ id }) {
  const [filters, setFilters] = useState(INITIAL_FILTERS_STATE);
  const [order, setOrder] = useState(DEFAULT_ORDER);

  const { messages, clearMessages } = useWebSocketChannel({
    path: `app_users/${id}/debug/events`,
  });

  const shouldFilterBy = (filterName) => !isEmpty(filters[filterName]);

  const filterMessages = (filterableMessages) => (
    filterableMessages.filter((message) => {
      if (message.type !== MESSAGE_TYPES.CHANNEL) return true;

      if (shouldFilterBy('eventSource')) {
        if (!filters.eventSource.includes(message.data.meta.source)) return false;
      }

      if (shouldFilterBy('eventName')) {
        switch (message.data.meta.source) {
          case EVENT_SOURCE.client:
            if (!message.data.payload.events.some((event) => filters.eventName.includes(event.name))) return false;
            break;
          case EVENT_SOURCE.server:
            if (!filters.eventName.includes(message.data.payload.event_name)) return false;
            break;
          default:
            break;
        }
      }

      if (shouldFilterBy('match')) {
        if (!filters.match.some((matcher) => JSON.stringify(message.data.payload).includes(matcher))) return false;
      }

      return true;
    })
  );

  const displayableMessages = useMemo(() => {
    const orderedMessages = order === DEFAULT_ORDER ? messages : orderBy(messages, 'ts', order);

    return filterMessages(orderedMessages);
  }, [messages.length, filters, order]);

  const updateFilterField = (newFilter) => setFilters({ ...filters, ...newFilter });

  return (
    <>
      <Card className="mb-3">
        <Card.Body className="d-flex">
          <Button onClick={clearMessages} className="me-2">Clear</Button>
          <OrderSelect
            selectedValue={order}
            onChange={(data) => setOrder(data.value)}
            options={ORDER_OPTIONS}
            hasPrepopulatedOption
          />
        </Card.Body>
      </Card>

      <BaseFilter
        onFiltersReset={() => setFilters(INITIAL_FILTERS_STATE)}
        collapsible
        className="mb-3"
      >
        {() => (
          <>
            <Col md={6}>
              <SelectFilter
                name="eventSource"
                label="Event Source"
                options={EVENT_SOURCE_OPTIONS}
                stateFieldValue={filters.eventSource}
                updateFilterField={updateFilterField}
              />
            </Col>
            <Col md={6}>
              <TextFilter
                name="eventName"
                label="Event Name"
                stateFieldValue={filters.eventName}
                updateFilterField={updateFilterField}
              />
            </Col>
            <Col md={6}>
              <TextFilter
                name="match"
                label="Event Contains"
                stateFieldValue={filters.match}
                updateFilterField={updateFilterField}
              />
            </Col>
          </>
        )}
      </BaseFilter>

      <ListGroup>
        {displayableMessages.map((message) => (
          <Message message={message} key={message.ts} />
        ))}
      </ListGroup>

      <ScrollToTopButton />
    </>
  );
}
