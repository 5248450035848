import React from 'react';
import { snakeCase } from '@tripledotstudios/react-core';

import { PageSection } from '@pages/common';

export default function DividerBuilder({ title }) {
  const name = `${snakeCase(title)}_section`;

  return { [name]: { Labeled: () => <PageSection title={title} /> } };
}
