import React from 'react';
import { Table } from 'react-bootstrap';
import { PageSection } from '@tripledotstudios/react-core';

import { fromClassNameToOptions } from '@services/enums';
import APP_DATA from '@services/appData';
import { WoodokuRoutes as Routes, FigureSetsRoutes } from '@pages/routes';
import { useCurrentApplication, useFormContext } from '@hooks';
import {
  SelectField, Field, ServerError, Label,
} from '@controls/form';
import RewardItems from '@pages/journeys/shared/RewardItems';
import rewardItemsConfiguration
  from '@pages/journeys/level-banks/configuration-forms/woodoku/RewardItemsConfigurations';
import HardLevel from './HardLevel';
import { boardSize } from '../utils';

const { modelsLocales: { woodoku: { figureSetDynamicConfigs: { tooltips } } } } = APP_DATA;

const getFigureSetOptions = ({ figureSets, figureSetDynamicConfigs, figureSetDynamicConfigId }) => {
  if (!figureSetDynamicConfigId) return figureSets;

  const config = figureSetDynamicConfigs.find(({ value }) => value === figureSetDynamicConfigId);

  return config?.figureSetIds?.map((figureSetId, figureSetIndex) => {
    const figureSetOption = figureSets.find(({ value }) => value === figureSetId);

    return { label: `(${figureSetIndex + 1}) ${figureSetOption.label}`, value: figureSetIndex, figureSetId };
  });
};

const boardGridTypeOptions = fromClassNameToOptions('Woodoku::BoardGridTypes');

export default function SettingsTable({
  index,
  figureSets,
  figureSetDynamicConfigs,
  shapesBankSetOptions,
  level,
  includeRewards = true,
  levelsPath,
  readOnly,
  withFigureSetRandomizer = false,
  withHardLevel = false,
}) {
  const { dispatch } = useFormContext();
  const { applicationId } = useCurrentApplication();
  const { figureSetDynamicConfigId, rewardsAttributes } = level;
  const figureSetOptions = getFigureSetOptions({ figureSets, figureSetDynamicConfigs, figureSetDynamicConfigId });

  const onRewardAdd = (rewardIndex) => dispatch({ actionType: 'addReward', index: rewardIndex });

  const boardSizeHasChanged = (nextValue) => boardSize(level.boardGridType) !== boardSize(nextValue);

  return (
    <Table bordered>
      <tbody>
        <tr>
          <td className="w-25"><b>Name (optional)</b></td>
          <td aria-label="section">
            <Field name="name" disabled={readOnly} />
          </td>
        </tr>
        <tr>
          <td className="w-25"><b>Seeds</b></td>
          <td aria-label="section">
            <Field name="seed" disabled={readOnly} />
          </td>
        </tr>
        <tr>
          <td className="w-25"><b>In-game mechanic tutorial: on/off</b></td>
          <td aria-label="section">
            <Field type="checkbox" name="tutorialEnabled" disabled={readOnly} />
          </td>
        </tr>
        <tr>
          <td className="w-25"><b>Board grid type</b></td>
          <td aria-label="section">
            <SelectField
              name="boardGridType"
              options={boardGridTypeOptions}
              disabled={readOnly}
              onChange={({ value }) => boardSizeHasChanged(value) && dispatch({
                actionType: 'clearCells',
                index,
                levelsPath,
              })}
            />
          </td>
        </tr>
        {withHardLevel && (
          <tr>
            <td className="w-25" colSpan="2" aria-label="section">
              <HardLevel readOnly={readOnly} />
            </td>
          </tr>
        )}
        <tr>
          <td colSpan="2">
            <PageSection title="Figure Set">
              {withFigureSetRandomizer && (
                <Label
                  text="Figure set randomiser"
                  tooltipText={'The number of times then the randomizer should apply \'prewarm\' logic'}
                >
                  <Field name="randomInitSteps" type="number" disabled={readOnly} />
                </Label>
              )}

              <Label
                text="Figure Set Dynamic Config"
                tooltipText={tooltips.figureSetDynamicConfig}
              >
                <SelectField
                  includeEmpty
                  isDisabled={readOnly}
                  name="figureSetDynamicConfigId"
                  options={figureSetDynamicConfigs}
                  onChange={({ value }) => dispatch({
                    actionType: 'rotateDynamicConfigValues',
                    dynamicConfigId: value,
                    index,
                    levelsPath,
                    figureSetOptions,
                  })}
                  quickLinkFn={(id) => Routes.FigureSetDynamicConfigs.editPath({ applicationId, id })}
                />
              </Label>

              <Label
                text="Losses before Figure Set Dynamic Config Applied"
                tooltipText={tooltips.loseThreshold}
              >
                <Field type="number" name="loseThreshold" disabled={readOnly || !figureSetDynamicConfigId} step="1" />
              </Label>

              <Label
                text="Dynamic Step per Loss"
                tooltipText={tooltips.dynamicStepPerLoss}
              >
                <Field
                  type="number"
                  name="loseStep"
                  disabled={readOnly || !figureSetDynamicConfigId}
                  step="0.1"
                />
              </Label>

              <Label
                text="Start Figure Set"
                tooltipText={tooltips.startFigureSet}
              >
                <SelectField
                  name={figureSetDynamicConfigId ? 'startIndex' : 'figureSetId'}
                  options={figureSetOptions}
                  isDisabled={readOnly}
                  quickLinkFn={(_v, option) => (
                    FigureSetsRoutes.Sets.editPath({ applicationId, id: option.figureSetId || option.value })
                  )}
                />
              </Label>
              {shapesBankSetOptions && (
                <Label text="All placeable shapes bank set">
                  <SelectField
                    name="shapesBankSetId"
                    options={shapesBankSetOptions}
                    isDisabled={readOnly}
                    includeEmpty
                    quickLinkFn={(id) => Routes.ShapesBankSets.editPath({ applicationId, id })}
                  />
                </Label>
              )}
            </PageSection>
          </td>
        </tr>
        {includeRewards && (
          <tr>
            <td><b>Rewards</b></td>
            <td aria-label="section">
              <ServerError name="rewardsAttributes.base" />
              <RewardItems
                readOnly={readOnly}
                levelIndex={index}
                rewards={rewardsAttributes}
                onAdd={() => onRewardAdd(index)}
                rewardItemsConfiguration={rewardItemsConfiguration}
              />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
