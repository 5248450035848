import React from 'react';

import { AppEventsRoutes } from '@pages/routes';
import Form from './Form';

export default function EditModal(props) {
  const { editRequest, updateRequest } = AppEventsRoutes;

  return (
    <Form actionName="update" getForm={editRequest} submitForm={updateRequest} {...props} />
  );
}
