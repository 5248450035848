import React from 'react';

import LabelLink from './LabelLink';

const LabelsColumn = {
  Header: 'Labels',
  style: { width: '9rem' },
  Cell: ({ row: { original: { labels } } }) => (
    <>
      {labels.map((label) => (
        <LabelLink key={label.name} label={label} />
      ))}
    </>
  ),
};

export default LabelsColumn;
