import React from 'react';

import { FilesRoutes } from '@pages/routes';
import Resource from './Resource';

export default function Edit() {
  const { updateRequest, editRequest } = FilesRoutes;

  return (
    <Resource
      fetchRequest={editRequest}
      saveRequest={updateRequest}
      actionName="update"
    />
  );
}
