import React, { useEffect, useState } from 'react';
import { at } from 'lodash';
import { Card, Row, Col } from 'react-bootstrap';

import IconButton from '@controls/buttons';
import {
  Form, FormGroup, useCurrentApplication, useQuery,
} from '@hooks';
import APP_DATA from '@services/appData';
import {
  FormButtonsGroup, Label, Field, SelectField, StaticFieldsFormGroup, AssetsKeySelectField, ServerError,
} from '@controls/form';
import { TileRoutes, LocalizationsRoutes, AssetsRoutes } from '@pages/routes';
import { PageHeader } from '@pages/common';
import { requestWithToSelectOptions } from '@services/toSelectOptions';

import { combineReducers } from '@root/reducers';
import moveItemsReducer from '@root/reducers/moveItemsReducer';
import formReducer from './formReducer';
import CategoryPositionCol from './CategoryPositionCol';

export default function CategorySetsForm({ data, onSubmit }) {
  const [translationOptions, setTranslationOptions] = useState([]);
  const { currentApplication: { id: applicationId, type: applicationType } } = useCurrentApplication();
  const [assetKeysStatuses, assetTypes] = at(
    APP_DATA.enums,
    ['AssetsKeys::Statuses', `${applicationType.split('::')[0]}::AssetTypes`],
  );
  useEffect(() => {
    requestWithToSelectOptions(LocalizationsRoutes.Keys.indexRequest, applicationId)
      .then((options) => setTranslationOptions(options));
  }, []);
  const defaultAssetParams = {
    applicationId,
    withoutPagination: true,
    includeCategories: false,
    status: assetKeysStatuses.ACTIVE,
  };
  const { response: categoryAssetKeysResponse, isLoading: categoryAssetsLoading } = useQuery(
    AssetsRoutes.Keys.indexRequest,
    { ...defaultAssetParams, filter: { types: [assetTypes.CATEGORY] } },
  );
  const { response: chapterSetAssetKeysResponse, isLoading: chapterSetAssetsLoading } = useQuery(
    AssetsRoutes.Keys.indexRequest,
    { ...defaultAssetParams, filter: { types: [assetTypes.CHAPTER_SET] } },
  );
  const { modelsLocales: { tile: { categories: { tooltips } } } } = APP_DATA;

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([formReducer, moveItemsReducer])}
    >
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Category Set`}>
            <FormButtonsGroup
              cancelButtonPath={TileRoutes.CategorySets.indexPath({ applicationId })}
            />
          </PageHeader>

          <Field type="hidden" name="id" />
          <Label text="Name">
            <Field type="text" name="name" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <h4>Categories</h4>
          <ServerError name="categoriesAttributes.list" />

          {values.categoriesAttributes.map((category, index) => (!category._destroy && (
            <Card className="mb-3" key={category._uuid}>
              <Card.Body>
                <Row>
                  <CategoryPositionCol
                    index={index}
                    uuid={category._uuid}
                    categoriesCount={values.categoriesAttributes.length}
                  />
                  <Col xs="10" xl="11">
                    <FormGroup name={`categoriesAttributes[${index}]`}>
                      <Label text="Title Key" tooltipText={tooltips.titleTranslationKeyId}>
                        <SelectField
                          name="titleTranslationKeyId"
                          options={translationOptions}
                        />
                      </Label>
                      <Label text="Category Asset bundle" tooltipText={tooltips.assetsKeyId}>
                        <AssetsKeySelectField
                          name="assetsKeyId"
                          options={categoryAssetKeysResponse?.items || []}
                          isLoading={categoryAssetsLoading}
                        />
                      </Label>
                      <Label text="Asset Bundles for use" tooltipText={tooltips.assetsKeysForUseIds}>
                        <AssetsKeySelectField
                          name="assetsKeysForUseIds"
                          options={chapterSetAssetKeysResponse?.items || []}
                          isLoading={chapterSetAssetsLoading}
                          isMulti
                        />
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )))}

          <IconButton.New onClick={() => dispatch({ type: 'addCategory' })}>Add</IconButton.New>
        </>
      )}
    </Form>
  );
}
