import {
  fetchCollection, newResource, editResource, createResource, updateResource, deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/blackjack/table_configs`;

export function getTableConfigs(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function newTableConfig(options) {
  return newResource(baseUrl(options), options);
}

export function editTableConfig(data, options) {
  return editResource(baseUrl(data), data, options);
}

export function createTableConfig(values) {
  return createResource(baseUrl(values), values);
}

export function updateTableConfig(values) {
  return updateResource(baseUrl(values), values);
}

export function deleteTableConfig(values) {
  return deleteResource(baseUrl(values), values);
}
