/* eslint-disable gs/plz-camel-case-for-action-names */
import {
  get, last, omit, set,
} from 'lodash';
import * as yup from 'yup';

import { addUuid } from '@services/recursivelyProcessObject';
import errorToObject from '@services/errorToObject';
import combineReducers from '../combineReducers';
import rewardableReducer from '../rewardableReducer';

const getPersistedLevels = (state) => state.personalLevelsAttributes.filter((c) => !c._destroy);

const addLevelsSchema = yup.object().shape({
  diff: yup.number().label('diff').positive().integer(),
  numberOfNewLevels: yup.number().label('number of new levels').required().positive()
    .integer(),
});

const changeNumberOfLevelsSchema = yup.object().shape({
  totalNumberOfLevels: yup.number().label('total number of levels').required().positive()
    .integer(),
});

/* eslint-disable no-param-reassign, array-callback-return */
const personalLevelSetsReducer = (state, action) => {
  switch (action.actionType) {
    case 'remove_level': {
      const { index } = action;

      state.personalLevelsAttributes[index]._destroy = true;
      for (let i = index + 1; i < state.personalLevelsAttributes.length; i += 1) {
        state.personalLevelsAttributes[i].position -= 1;
      }
      break;
    }
    case 'add_level': {
      const lastLevel = last(getPersistedLevels(state));
      state.personalLevelsAttributes.push(addUuid({
        _destroy: false,
        id: null,
        diff: 0,
        position: lastLevel ? lastLevel.position + 1 : 1,
        rewardAffiliationsAttributes: [],
      }));
      break;
    }
    case 'add_new_levels': {
      const lastLevel = last(getPersistedLevels(state));
      const { diff, numberOfNewLevels } = state;
      try {
        addLevelsSchema.validateSync({ diff, numberOfNewLevels }, { abortEarly: false });
      } catch (error) {
        if (error instanceof yup.ValidationError) { set(state, 'meta.errors', errorToObject(error)); }
        break;
      }
      for (let i = 0; i < numberOfNewLevels; i += 1) {
        state.personalLevelsAttributes.push(addUuid({
          _destroy: false,
          id: null,
          position: get(lastLevel, 'position', 0) + 1 + i,
          diff: state.diff || 0,
          rewardAffiliationsAttributes: state.rewardAffiliationsAttributes,
        }));
      }
      state.diff = '';
      state.numberOfNewLevels = '';
      state.rewardAffiliationsAttributes = [];
      set(state, 'meta.errors', omit(get(state, 'meta.errors'), ['diff', 'numberOfNewLevels']));
      break;
    }
    case 'change_number_of_levels': {
      const persistedLevels = getPersistedLevels(state);
      const { totalNumberOfLevels } = state;
      const levelNumberDiff = totalNumberOfLevels - persistedLevels.length;
      try {
        changeNumberOfLevelsSchema.validateSync({ totalNumberOfLevels }, { abortEarly: false });
      } catch (error) {
        if (error instanceof yup.ValidationError) { set(state, 'meta.errors', errorToObject(error)); }
        break;
      }
      if (levelNumberDiff > 0) {
        const lastLevel = last(persistedLevels);
        for (let i = 0; i < levelNumberDiff; i += 1) {
          state.personalLevelsAttributes.push(addUuid({
            _destroy: false,
            id: null,
            position: get(lastLevel, 'position', 0) + 1 + i,
            diff: 0,
            rewardAffiliationsAttributes: [],
          }));
        }
      } else {
        for (let i = 0; i < -levelNumberDiff; i += 1) {
          state.personalLevelsAttributes[persistedLevels.length - i - 1]._destroy = true;
        }
      }
      set(state, 'meta.errors.totalNumberOfLevels', null);
      state.totalNumberOfLevels = '';
      break;
    }
    default: break;
  }
  return state;
};

export default combineReducers([personalLevelSetsReducer, rewardableReducer]);
