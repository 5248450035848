import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import APP_DATA from '@services/appData';

import { useCurrentApplication, useFormContext } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import { Label } from '@controls/form';

import Select from '@controls/Select';

import { useLanguagesContext } from '../shared/services/useLanguages';

const { enums } = APP_DATA;

export default function NewTranslationKeyModal({ onHide }) {
  const { allLanguages } = useLanguagesContext();
  const translationTypesOptions = fromClassNameToOptions('Localizations::TranslationTypes');
  const [selectedTranslationType, setSelectedTranslationType] = useState(enums['Localizations::TranslationTypes'].TEXT);
  const [values, setValues] = useState({});
  const { currentApplication } = useCurrentApplication();
  const { dispatch } = useFormContext();
  const nameFieldRef = useRef();

  const onAdd = () => {
    const translations = allLanguages.map(({ id, isDefault }) => {
      const englishTranslationValue = get(values, 'englishTranslation', '');

      return {
        languageId: id,
        value: isDefault ? englishTranslationValue : '',
        _uuid: uuidv4(),
      };
    });

    const newTranslationKey = {
      name: get(values, 'name', ''),
      description: get(values, 'description', ''),
      format: selectedTranslationType,
      applicationId: currentApplication.id,
      translationsAttributes: translations,
      markedAsChanged: true,
      _uuid: uuidv4(),
    };

    dispatch({ type: 'addTranslationKey', newTranslationKey });
    onHide();
  };

  const onChange = (event) => {
    const { name, value } = event.target;

    const newValues = values;
    newValues[name] = value;

    setValues(newValues);
  };

  useEffect(() => {
    if (nameFieldRef && nameFieldRef.current) nameFieldRef.current.focus();
  }, []);

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Translation key</Modal.Title>
      </Modal.Header>
      <Modal.Body className="ps-4 pe-4">
        <Label text="Key ID" fieldSize={8}>
          <input
            ref={nameFieldRef}
            name="name"
            type="text"
            className="form-control w-100"
            onChange={onChange}
          />
        </Label>
        <Label text="Type" fieldSize={8}>
          <Select
            selectedValue={selectedTranslationType}
            onChange={({ value }) => setSelectedTranslationType(value)}
            options={translationTypesOptions}
            placeholder="Select translation's type"
          />
        </Label>
        <Label text="Description" fieldSize={8}>
          <TextareaAutosize
            name="description"
            type="text"
            className="form-control w-100"
            onChange={onChange}
          />
        </Label>
        <Label text="English EN" fieldSize={8}>
          <TextareaAutosize
            name="englishTranslation"
            type="text"
            className="form-control w-100"
            onChange={onChange}
          />
        </Label>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onAdd}>Add</Button>
      </Modal.Footer>
    </Modal>
  );
}
