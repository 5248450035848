import React from 'react';

import { JourneyRoutes } from '@pages/routes';
import APP_SPECIFIC_CONFIGS from './configuration-forms';
import SharedForm from '../shared/DatesBasedForm';

const JourneysPromoEventsForm = ({
  ...props
}) => (
  <SharedForm
    {...props}
    localePath="journeys.promoEvents"
    routes={JourneyRoutes.PromoEvents}
    appSpecificConfigs={APP_SPECIFIC_CONFIGS}
    massOperationsModel="Journeys::PromoEvent"
  />
);

export default JourneysPromoEventsForm;
