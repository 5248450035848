import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { BooleanIcon } from '@pages/common';
import {
  Label, Field, ServerError,
} from '@controls/form';
import { CopyToClipboardAddon } from '@controls/CopyToClipboardButton';
import { BanksEdit } from '@pages/metagame/chapter-based';
import entityIdentity from '@components/shared/entityIdentity';

const EntityHeader = ({ entity, entityName }) => {
  const {
    contentHash, name, validForUse, updatedAt, index,
  } = entity;
  return (
    <table className="table table-borderless mb-0">
      <tbody>
        <tr className="d-flex align-items-center">
          <td className="bg-transparent text-break p-0 pe-2 col-1">
            {entityIdentity({ entity: { ...entity, name: null }, entityName })}
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-1">
            <div>
              {'Hash: '}
              {contentHash}
            </div>
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {'Layout Name: '}
            {name}
            <ServerError name={`levelLayoutsAttributes.${index}.name`} full />
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {'Valid for use: '}
            <BooleanIcon value={validForUse} className="ms-1" />
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {'Updated at: '}
            {updatedAt}
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-1" aria-label="section" />
        </tr>
      </tbody>
    </table>
  );
};

const levelLayoutFields = ({ entity: { contentHash } }) => (
  <div className="d-flex flex-row justify-content-around">
    <div className="flex-fill pe-2">
      <Label text="Layout Name">
        <Field name="name" />
      </Label>
      <Label text="Hash">
        <span className="d-flex align-items-center">
          <InputGroup>
            <Field name="contentHash" disabled />
            <CopyToClipboardAddon text={contentHash} />
          </InputGroup>
        </span>
      </Label>
    </div>
  </div>
);

export default function Edit(props) {
  return (
    <BanksEdit
      EntityHeader={EntityHeader}
      levelLayoutFields={levelLayoutFields}
      {...props}
    />
  );
}
