import React from 'react';

import { PageHeader } from '@pages/common';
import { useQuery, useCurrentApplication } from '@hooks';
import { PlayerSegmentRoutes } from '@pages/routes';

import PlayerSegmentsTable from './PlayerSegmentsTable';

export default function Index() {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { response, isLoading } = useQuery(
    PlayerSegmentRoutes.indexRequest,
    { applicationId, withoutPagination: true, includeInUse: true },
  );

  return (
    <>
      <PageHeader title="Player Segments" />
      <PlayerSegmentsTable response={response} isLoading={isLoading} />
    </>
  );
}
