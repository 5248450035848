import React from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';

import { FlexiblePopupRoutes as Routes } from '@pages/routes';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

const New = () => {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(Routes.Popups.newRequest);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Flexible Popup',
    actionName: 'create',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    Routes.Popups.createRequest({ ...values, applicationId }).then(responseHandler)
  );

  if (isLoading) return <Spinner />;

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      title="Flexible Popup"
    />
  );
};

export default New;
