import React from 'react';
import { useRouter, Spinner } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';

import { FlexiblePopupRoutes as Routes } from '@pages/routes';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function New() {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(Routes.Actions.newRequest);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Flexible Popup Action',
    actionName: 'create',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    Routes.Actions.createRequest({ ...values, applicationId }).then(responseHandler)
  );

  if (isLoading) return <Spinner />;

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      title="Flexible Popup Action"
    />
  );
}
