import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { WoodokuRoutes } from '@pages/routes';

import Form from './Form';

export default function CreateUpdateConfig({
  fetchFunction,
  saveFunction,
  actionName,
  disableTypeSelect,
  configTypes,
}) {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse } = useQuery(fetchFunction);

  const collectionUrl = `${WoodokuRoutes.XpLevelConfigs.indexPath({ applicationId })}`;

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'XP Level Config',
    setData: setResponse,
    actionName,
    collectionUrl,
  });

  const onSubmit = (values) => saveFunction(values).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      configTypes={configTypes}
      disableTypeSelect={disableTypeSelect}
    />
  );
}
