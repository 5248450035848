import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { createEscapeConfig, newEscapeConfig } from '@requests/puzzle-time/escapeConfigs';

import fieldsConfiguration from './FieldsConfiguration';
import Form from '../GameConfigsForm';

export default function New() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const indexPath = `/admin/applications/${query.applicationId}/puzzle_time/escape_configs`;

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Block Escape Config',
    actionName: 'creation',
    redirectPath: indexPath,
    setData,
  });

  const onSubmit = (values) => createEscapeConfig(query, values).then(redirectIfSuccessHandler);

  useEffect(() => {
    newEscapeConfig(query).then((response) => setData(response.data));
  }, []);

  return data ? (
    <Form
      data={data}
      onSubmit={onSubmit}
      fieldsConfiguration={fieldsConfiguration}
      actionName="Create"
      indexPath={indexPath}
      configName="Block Escape Config"
    />
  )
    : '';
}
