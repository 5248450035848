import React, { useState } from 'react';

import { useQuery } from '@hooks';
import Select from '@controls/Select';
import IconButton from '@controls/buttons';
import { PlayerSegmentRoutes } from '@pages/routes';

import PlayerSegmentsTable from '@pages/player-segments/PlayerSegmentsTable';
import { PageSection } from '@pages/common';

export default function DeviceSegments({ user, applicationId }) {
  const [deviceId, setDeviceId] = useState(user.devices[0].id);
  const { response, refetch, isLoading } = useQuery(
    PlayerSegmentRoutes.forPlayerRequest, { applicationId, id: user.id, deviceId },
  );

  const deviceOptions = user.devices.map(({ id, appVersion }) => ({ value: id, label: `${id} (${appVersion})` }));
  const onSelectChange = ({ value }) => {
    setDeviceId(value);
    refetch();
  };

  return (
    <>
      <PageSection
        title="Player Segments"
        actions={(
          <>
            <span className="align-middle mt-2 me-2">Device:</span>
            <Select
              selectedValue={deviceId}
              onChange={(event) => onSelectChange(event)}
              options={deviceOptions}
              className="me-2"
            />
            <IconButton.Restore
              onClick={() => refetch()}
              disabled={isLoading}
            >
              Re-fetch Segments
            </IconButton.Restore>
          </>
        )}
      />
      {response && (
        <PlayerSegmentsTable
          response={response}
          isLoading={isLoading}
        />
      )}
    </>
  );
}
