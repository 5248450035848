import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';
import { TIMESTAMP_COLUMNS, InUseColumn } from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

export default function uiConfigurationsColumns({
  applicationId,
  onDelete,
  onDuplicate,
  Routes,
}) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({ row }) => {
        const { id, inUse, name } = row.original;
        const isUsed = isInUse(inUse);
        return (
          <ButtonToolbar>
            <IconButton.Edit to={Routes.editPath({ applicationId, id })} minimized />
            <IconButton.Duplicate onClick={() => onDuplicate({ id, name })} minimized />
            <IconButton.Delete
              onClick={onDelete(id, name)}
              disabled={isUsed}
              title={isUsed ? 'Disabled as the entity is used' : null}
              minimized
            />
          </ButtonToolbar>
        );
      },
    },
  ];
}
