import React from 'react';
import { useQuery } from '@hooks';

import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';

import { WoodokuSagaRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { response, setResponse } = useQuery(Routes.AreaSets.editRequest);

  const redirectIfSuccessHandler = createUpdateResponseHandlerFactory({
    entityName: 'Area Set',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.AreaSets.updateRequest(values).then(redirectIfSuccessHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      actionName="Update"
    />
  );
}
