import React from 'react';

import { fromClassNameToOptions } from '@services/enums';
import {
  Field, Label, SelectField, TimePeriodField,
} from '@controls/form';

export default function EnergyTab() {
  const currencyOptions = fromClassNameToOptions('Merge::FreeCurrencies');

  return (
    <>
      <Label text="Start Energy Balance">
        <Field name="energyStartBalance" type="number" />
      </Label>
      <Label text="Cost Multiplier">
        <Field name="energyPurchaseCostMultiplier" type="number" />
      </Label>
      <Label text="Amount of Energy units available for purchase">
        <Field name="energyPurchaseAvailableAmount" type="number" />
      </Label>
      <Label text="Refresh Time">
        <TimePeriodField name="energyPurchaseRefreshTime" type="number" inputs={['hours']} />
      </Label>
      <Label text="Currency">
        <SelectField name="energyPurchaseCurrency" options={currencyOptions} />
      </Label>
      <Label text="Amount">
        <Field name="energyPurchaseCost" type="number" />
      </Label>
    </>
  );
}
