import React, { memo } from 'react';
import { Col } from 'react-bootstrap';

import { Label, ArrayField } from '@controls/form';

import localizeField from './localizeField';
import areEnabledPropsEqual from './areEnabledPropsEqual';

export default function ArrayFieldBuilder({
  name: baseName, labelText, type, fieldOptions = {}, tooltips = {},
}) {
  return {
    [baseName]: {
      Inline: memo(({ name, disabled }) => (
        <ArrayField
          name={name || baseName}
          type={type}
          disabled={disabled}
        />
      ), areEnabledPropsEqual),
      Labeled: memo(({
        name, disabled, localeNamespace, locale, tooltipBuilder,
      }) => {
        const { label, tooltip, clientKey } = localizeField(
          baseName, labelText, tooltips, localeNamespace, locale, tooltipBuilder,
        );

        return (
          <Col>
            <Label text={label} fieldSize={8} tooltipText={tooltip} copyableText={clientKey}>
              <ArrayField
                name={name || baseName}
                type={type}
                disabled={disabled}
                {...fieldOptions}
              />
            </Label>
          </Col>
        );
      }, areEnabledPropsEqual),
      fieldOptions,
    },
  };
}
