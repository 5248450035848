import React from 'react';

import styled from 'styled-components';
import { pick, some } from 'lodash';
import { Button } from 'react-bootstrap';

import { FormGroup, useConfirm, useFormContext } from '@hooks';
import { Field, ServerError } from '@controls/form';
import { TileRoutes } from '@pages/routes';

const MatrixContainer = styled.div`
  width: 75%;
`;

const RangeRow = styled.div`
  display: flex;
  padding: 10px 0;
`;

export default function Matrix({ disabled }) {
  const confirm = useConfirm();
  const { dispatch, values } = useFormContext();
  const { basedOnUsageConfigAttributes } = values;
  const { factorRangesAttributes } = basedOnUsageConfigAttributes;

  const generateMatrix = async () => {
    const params = {
      applicationId: values.applicationId,
      schemaType: values.schemaType,
      ...pick(basedOnUsageConfigAttributes, ['trueWinRateBucketSize']),
    };

    const response = await TileRoutes.DifficultySchemas.difficultyMatrixRequest(params);
    dispatch({ actionType: 'generateMatrix', response });
  };

  const generateMatrixWithConfirmation = () => (
    some(factorRangesAttributes, (range) => some(pick(range, ['diff'])))
      ? confirm.showConfirmation({ title: 'The matrix below will be created. Continue?' }).then(generateMatrix)
      : generateMatrix()
  );

  return (
    <>
      <Button onClick={generateMatrixWithConfirmation} type="button" disabled={disabled}>
        Generate the difficulty matrix
      </Button>
      <ServerError name="factorRangesAttributes" />
      {factorRangesAttributes.length > 0 && (
        <MatrixContainer>
          <RangeRow>
            <div className="fw-bold w-50">
              WinRate (%)
            </div>
            <div className="fw-bold w-50">
              N Types diff
            </div>
          </RangeRow>
          {factorRangesAttributes.map((factorRange, index) => !factorRange._destroy && (
            <FormGroup name={`factorRangesAttributes[${index}]`} key={factorRange._uuid}>
              <RangeRow>
                <div className="w-50">
                  {`${factorRange.from} - ${factorRange.to}`}
                </div>
                <div className="w-50">
                  <Field name="diff" type="number" disabled={disabled} />
                </div>
              </RangeRow>
            </FormGroup>
          ))}
        </MatrixContainer>
      )}
    </>
  );
}
