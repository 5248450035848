import { get, set } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';

/* eslint-disable no-param-reassign, array-callback-return */
export default function clientRulesReducer(state, action) {
  switch (action.actionType) {
    case 'addClientRule': {
      const { path } = action;

      get(state, path).rulesAttributes.push(addUuid({}));

      break;
    }
    case 'removeClientRule': {
      const { ruleIndex, path } = action;

      get(state, path).rulesAttributes[ruleIndex]._destroy = true;

      break;
    }
    case 'changeClientRuleType': {
      const { index, type, path } = action;

      set(state, `${path}.rulesAttributes[${index}]._destroy`, true);
      get(state, path).rulesAttributes.splice(index, 0, { type });

      break;
    }
    default: break;
  }

  return state;
}
