/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { get, isPlainObject } from 'lodash';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DndIcon } from '@pages/common';
import { Collapsible, ServerError } from '@controls/form';
import Tooltip from '@controls/Tooltip';
import { useFormContext, useFormGroup } from '@hooks';

import Mode from './Mode';

const MODES_THRESHOLD = 3;

export default function ModesList({
  modesAttributes, readOnly, reordering, setReordering,
}) {
  const [uuidToPositionMapping, setUuidToPositionMapping] = useState(null);
  const [expandedModes, setExpandedModes] = useState([]);
  const { dispatch, meta } = useFormContext();
  const { generateName } = useFormGroup();
  const modesErrors = get(meta, `errors.${generateName('modesAttributes')}`, {});

  const toggleReordering = () => {
    if (reordering) {
      setUuidToPositionMapping(null);
    } else {
      const mapping = modesAttributes.reduce((memo, { _uuid, number }) => {
        // eslint-disable-next-line no-param-reassign
        memo[_uuid] = number;

        return memo;
      }, {});

      setUuidToPositionMapping(mapping);
    }

    return setReordering(!reordering);
  };

  const onModeAdd = () => dispatch({
    actionType: 'addEmptyMode',
  });

  const onModeRemove = (index, e) => {
    e.stopPropagation();

    return dispatch({
      actionType: 'removeMode',
      deleteAt: index,
    });
  };

  const onModeDuplicate = (index, e) => {
    e.stopPropagation();

    return dispatch({
      actionType: 'duplicateMode',
      sourceIndex: index,
    });
  };

  const onReorderingCancel = () => {
    dispatch({ actionType: 'applyPositions', uuidToPositionMapping });

    toggleReordering();
  };

  const ReorderButtonsBlock = () => (
    reordering ? (
      <>
        <Button onClick={() => toggleReordering()} variant="success">
          Finish reordering
        </Button>
        <Button
          className="ms-2"
          variant="danger"
          onClick={() => onReorderingCancel()}
        >
          Cancel reordering
        </Button>
      </>
    ) : (
      <Button onClick={() => toggleReordering()} disabled={readOnly} variant="primary">
        Reorder
      </Button>
    )
  );

  const onModeToggle = (clickedUuid, modeUuid) => {
    if (reordering) {
      return null;
    }

    if (clickedUuid) {
      return setExpandedModes([...expandedModes, clickedUuid]);
    }

    return setExpandedModes(expandedModes.filter((uuid) => uuid !== modeUuid));
  };

  const onModesReorder = ({ source, destination }) => {
    dispatch({
      actionType: 'moveMode',
      sourceIndex: source.index,
      destinationIndex: destination.index,
      metaErrorsPath: ['errors', 'modesAttributes'],
      meta,
    });
  };

  const notDestroyedModes = (modesAttributes || []).filter(({ _destroy }) => !_destroy);

  const modesThresholdReached = notDestroyedModes.length >= MODES_THRESHOLD;

  const disableAddingReason = () => {
    if (readOnly) {
      return 'Entity is in use';
    }

    if (modesThresholdReached) {
      return `Maximum count of modes is ${MODES_THRESHOLD}`;
    }

    if (reordering) {
      return 'Cannot add mode during reordering';
    }

    return null;
  };

  const addingModesIsDisabled = Boolean(disableAddingReason());

  return (
    <>
      {notDestroyedModes.length > 1 ? <ReorderButtonsBlock /> : ''}
      <ServerError name="modesAttributes.list" />
      <DragDropContext onDragEnd={onModesReorder}>
        <Droppable droppableId="bet-up-mode-droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {(modesAttributes || []).map((mode, index) => {
                if (mode._destroy) {
                  return null;
                }

                const titleModeNumber = uuidToPositionMapping ? uuidToPositionMapping[mode._uuid] : mode.number;
                const isExpanded = !reordering && expandedModes.includes(mode._uuid);

                return (
                  <Draggable key={mode._uuid} draggableId={mode._uuid} index={index} isDragDisabled={!reordering}>
                    {(draggableProvided) => (
                      <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                        <DndIcon
                          {...draggableProvided.dragHandleProps}
                          disabled={!reordering}
                        />
                        <Collapsible
                          header={`Mode ${titleModeNumber}`}
                          eventKey={mode._uuid}
                          activeKey={isExpanded ? mode._uuid : ''}
                          rightSideText={(
                            <>
                              <Button
                                size="sm"
                                onClick={(e) => onModeDuplicate(index, e)}
                                disabled={addingModesIsDisabled}
                                className="me-2"
                              >
                                Duplicate
                              </Button>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={(e) => onModeRemove(index, e)}
                                disabled={readOnly}
                              >
                                Remove
                              </Button>
                            </>
                          )}
                          rightSideTextProps={{ top: 7 }}
                          withErrors={isPlainObject(modesErrors) && modesErrors[index]}
                          onSelect={(clickedUuid) => onModeToggle(clickedUuid, mode._uuid)}
                        >
                          <Mode
                            index={index}
                            mode={mode}
                            expanded={isExpanded}
                          />
                        </Collapsible>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <p />
      <div className="text-end">
        {addingModesIsDisabled ? (
          <Tooltip text={disableAddingReason()} placement="top">
            <Button onClick={onModeAdd} disabled="true">Add Mode</Button>
          </Tooltip>
        ) : (
          <Button onClick={onModeAdd}>Add Mode</Button>
        )}
      </div>
    </>
  );
}

