/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react';

import APP_DATA from '@services/appData';

import {
  Label, Field, TimePeriodField, SelectField,
} from '@controls/form';
import { FormGroup, useFormContext } from '@hooks';

import ItemBasedProduct from './ItemBasedProduct';
import PriceFields from './PriceFields';
import BundleProduct from './BundleProduct';

const priceTypesEnum = APP_DATA.enums['Merge::ProductPriceTypes'];

export default function ProductFrom({ values, defaults, isNewRecord }) {
  const productRewardsConfigurations = useMemo(() => [
    {
      label: 'Coins pack',
      value: 'Merge::ProductRewards::Coins',
      component: () => (
        <FormGroup name="rewardAttributes">
          <Label text="Amount">
            <Field type="number" name="value" />
          </Label>
        </FormGroup>
      ),
      currencies: [priceTypesEnum.USD, priceTypesEnum.GEMS],
    },
    {
      label: 'Gems pack',
      value: 'Merge::ProductRewards::Gems',
      component: () => (
        <FormGroup name="rewardAttributes">
          <Label text="Amount">
            <Field type="number" name="value" />
          </Label>
        </FormGroup>
      ),
      currencies: [priceTypesEnum.USD],
    },
    {
      label: 'Energy balance',
      value: 'Merge::ProductRewards::Energy',
      component: () => (
        <>
          <Label text="Cost multiplier">
            <Field name="costMultiplier" type="number" step="0.01" />
          </Label>
          <FormGroup name="rewardAttributes">
            <Label text="Amount of Energy units available for purchase">
              <Field name="value" type="number" />
            </Label>
          </FormGroup>
          <Label text="Refresh time">
            <TimePeriodField name="refreshTime" type="number" inputs={['hours']} />
          </Label>
        </>
      ),
      currencies: [priceTypesEnum.COINS, priceTypesEnum.GEMS],
    },
    {
      label: 'Energy chest',
      value: 'Merge::ProductRewards::EnergyChest',
      component: (props) => (
        <ItemBasedProduct {...props}>
          <Label text="Purchase Limit">
            <Field name="quantity" type="number" />
          </Label>
          <Label text="Refresh time">
            <TimePeriodField name="refreshTime" type="number" inputs={['hours']} />
          </Label>
        </ItemBasedProduct>
      ),
      currencies: [priceTypesEnum.COINS, priceTypesEnum.GEMS],
    },
    {
      label: 'Piggy Bank',
      value: 'Merge::ProductRewards::PiggyBank',
      component: (props) => (
        <ItemBasedProduct {...props}>
          <Label text="Purchase Limit">
            <Field name="quantity" type="number" />
          </Label>
          <Label text="Refresh time">
            <TimePeriodField name="refreshTime" type="number" inputs={['hours']} />
          </Label>
        </ItemBasedProduct>
      ),
      currencies: [priceTypesEnum.FREE],
    },
    {
      label: 'Bundle',
      value: 'Merge::ProductRewards::Bundle',
      component: (props) => <BundleProduct {...props} />,
      currencies: [priceTypesEnum.USD],
    },
  ], []);

  const rewardConfiguration = productRewardsConfigurations.find(({ value: seletedValue }) => (
    values.productRewardAttributes && seletedValue === values.productRewardAttributes.type
  ));
  const { dispatch } = useFormContext();

  const onTypeChange = (e) => {
    const rewardDefaults = defaults.find(({ type }) => type === e.value) || {};
    dispatch({ type: 'change_product_type', defaults: rewardDefaults });
  };

  return (
    <>
      <FormGroup name="productRewardAttributes">
        <Label text="Type">
          <SelectField
            name="type"
            options={productRewardsConfigurations}
            onChange={(e) => onTypeChange(e)}
            isDisabled={!isNewRecord}
          />
        </Label>

        {rewardConfiguration && (
          rewardConfiguration.component({ values: values.productRewardAttributes, isNewRecord, productId: values.id })
        )}
      </FormGroup>
      <hr />

      {rewardConfiguration && <PriceFields values={values} currencies={rewardConfiguration.currencies} />}
    </>
  );
}
