import { useEffect, useRef } from 'react';

export default function useDidMountEffect(targetFunction, dependencies) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) targetFunction();
    else didMount.current = true;
  }, dependencies);
}
