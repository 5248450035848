import React, { useMemo } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { MergeRoutes } from '@pages/routes';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import {
  PageHeader,
  Pagination,
  ReactTable,
} from '@pages/common';
import IconButton from '@controls/buttons';
import ColumnsFactory from './ColumnsFactory';

export default function Index() {
  const { query: { applicationId } } = useRouter();

  const { response, refetch } = useQuery(
    MergeRoutes.CutscenesConfigurations.indexRequest, { applicationId, includeInUse: true },
  );
  const confirm = useConfirm();

  const deleteResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Cutscenes Configuration',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => {
    confirm.showConfirmation({
      title: `'${name}' Cutscenes Configuration will be deleted and not available for work. Continue?`,
    })
      .then(() => MergeRoutes.CutscenesConfigurations.deleteRequest({ id, applicationId })
        .then(deleteResponseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({ applicationId, onDelete }), []);

  return response && (
    <>
      <PageHeader title="Cutscenes Configurations">
        <IconButton.New to={MergeRoutes.CutscenesConfigurations.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </>
  );
}
