import React from 'react';
import { get, camelCase } from 'lodash';

import { Label, Field, SelectField } from '@controls/form';
import {
  FormGroup,
  useFormContext,
  useFormGroup,
  useCurrentApplication,
} from '@hooks';

import APP_DATA from '@services/appData';

const TRIGGER_OPTIONS = [
  { label: 'App start X Game start Y', value: 'rate_us_app_start_x_game_start_y' },
  { label: 'New high score > X', value: 'rate_us_high_score_more_x' },
  { label: 'On collection of a tiny gem', value: 'rate_us_tiny_gem' },
  { label: 'On collection of a small gem', value: 'rate_us_small_gem' },
  { label: 'On collection of a journey award', value: 'rate_us_journey_award' },
];

const ConfigurationFields = ({ disabled, selectedTrigger, locale }) => {
  switch (selectedTrigger) {
    case 'rate_us_app_start_x_game_start_y':
      return (
        <>
          <Label text="App Starts" direction="vertical" tooltipText={locale.rateUsAppStartX.tooltip}>
            <Field disabled={disabled} name="rateUsAppStartX" type="number" />
          </Label>
          <Label text="Game Starts" direction="vertical" tooltipText={locale.rateUsGameStartY.tooltip}>
            <Field disabled={disabled} name="rateUsGameStartY" type="number" />
          </Label>
        </>
      );
    case 'rate_us_high_score_more_x':
      return (
        <Label text="X" direction="vertical" tooltipText={locale.newHighScoreMoreX.tooltip}>
          <Field disabled={disabled} name="newHighScoreMoreX" type="number" />
        </Label>
      );
    default:
      return ('');
  }
};

export default function RateUsPopupsBlock({ name: groupName, disabled }) {
  const { values } = useFormContext();
  const { applicationKey } = useCurrentApplication();
  const { modelsLocales } = APP_DATA;
  const { generateName } = useFormGroup();

  const { gameSettings: locale } = modelsLocales[camelCase(applicationKey)];

  const selectedTrigger = get(values, generateName(`${groupName}.triggerName`));

  return (
    <FormGroup name={groupName}>
      <SelectField
        isDisabled={disabled}
        name="triggerName"
        options={TRIGGER_OPTIONS}
      />
      <p />
      <ConfigurationFields
        selectedTrigger={selectedTrigger}
        disabled={disabled}
        locale={locale}
      />
    </FormGroup>
  );
}
