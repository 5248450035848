import React from 'react';
import { Col } from 'react-bootstrap';
import TextFilter from './TextFilter';

export default function IdFilter({ updateFilterField }) {
  return (
    <Col md={6}>
      <TextFilter
        name="idIn"
        label="ID"
        updateFilterField={updateFilterField}
      />
    </Col>
  );
}
