import React, { useState } from 'react';

import { Form, useCurrentApplication } from '@hooks';
import { Field, Label } from '@controls/form';
import { Row, Col } from 'react-bootstrap';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import updateManifestsSetsSettings from '@requests/my-pet-cafe/manifests-sets-settings';
import IconButton from '@controls/buttons';

export default function LevelLimitForm({ levelLimit }) {
  const { currentApplication } = useCurrentApplication();
  const [data, setData] = useState({ levelLimit, applicationId: currentApplication.id });

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Manifest sets settings',
    actionName: 'update',
    setData,
  });

  return (
    <Form
      initialValues={data}
      onSubmit={(values) => updateManifestsSetsSettings(values).then(responseHandler)}
    >
      {() => (
        <Row>
          <Col xs={7} className="pull-end">
            <Label labelSize={6} fieldSize={6} className="mb-0 text-end" text="Level limit:">
              <Field name="levelLimit" type="numer" />
            </Label>
          </Col>
          <Col xs={5}>
            <IconButton.Save type="submit">Save Level Limit</IconButton.Save>
          </Col>
        </Row>
      )}
    </Form>
  );
}
