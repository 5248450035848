import React from 'react';
import {
  flatten, values, omit, some,
} from 'lodash';

import editPath from '@services/editPath';
import { useCurrentApplication, useI18n } from '@hooks';
import InUseLink from '@pages/common/in-use/InUseLink';
import { AutoCollapse } from './PartialCollapse';

export const InUseCollapse = ({ inUse, newTab = false }) => (
  <AutoCollapse minHeight={105}>
    <div className="d-flex flex-column">
      <InUseLinks inUse={inUse} newTab={newTab} />
    </div>
  </AutoCollapse>
);

export const isInUse = (inUse) => some(inUse, (subsets) => Array.isArray(subsets) && subsets.length > 0);

export const InUseLinks = ({ inUse = null, newTab = false }) => {
  const { currentApplication } = useCurrentApplication();
  const { translate } = useI18n();

  return (
    inUse
    && React.Children.toArray(
      flatten(values(omit(inUse, '_uuid'))).map((entity) => {
        const path = editPath({ ...entity, application: currentApplication, ...entity.frontendIdentity });
        const entityName = translate.fallback(`${entity.i18nPath}.name`)
          || translate.fallback(`${entity.i18nPath}.auditName`);

        if (!entityName) {
          console.error(`Translation for ${entity.i18nPath} is not found`);

          return '';
        }

        return (
          <InUseLink
            entityName={entityName}
            newTab={newTab}
            path={path}
            {...entity}
          />
        );
      }),
    )
  );
};
