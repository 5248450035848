import React from 'react';

import { Label, Field, CheckboxField } from '@controls/form';
import { FormGroup } from '@hooks';

export default function InitialDataTab() {
  return (
    <FormGroup name="initialDataAttributes">
      <Field name="id" type="hidden" />
      <Label text="Initial coin" fieldSize={8}>
        <Field type="number" name="initialCoin" />
      </Label>
      <Label text="Player name" fieldSize={8}>
        <Field type="text" name="playerName" />
      </Label>
      <Label text="Initial quest currency" fieldSize={8}>
        <Field type="number" name="initialQuestCurrency" />
      </Label>
      <Label text="Last unlocked level" fieldSize={8}>
        <Field type="number" name="lastUnlockedLevel" />
      </Label>
      <Label text="Default BGM" fieldSize={8}>
        <CheckboxField name="defaultBgm" />
      </Label>
      <Label text="Default SFX" fieldSize={8}>
        <CheckboxField name="defaultSfx" />
      </Label>
      <Label text="Saga map page" fieldSize={8}>
        <Field type="number" name="sagaMapPage" />
      </Label>
      <Label text="Last Claimed Star Chest Order" fieldSize={8}>
        <Field type="number" name="lastClaimedStarChestOrder" />
      </Label>
      <Label text="Notification enabled" fieldSize={8}>
        <CheckboxField name="notificationEnabled" />
      </Label>
      <Label text="VO enabled" fieldSize={8}>
        <CheckboxField name="voiceOverEnabled" />
      </Label>
      <Label text="Autoplay Cutscene Paused" fieldSize={8}>
        <CheckboxField name="autoPlayCutscenePaused" />
      </Label>
      <Label text="Free first play" fieldSize={8}>
        <Field type="number" name="cafeBoosterInventory" />
      </Label>
    </FormGroup>
  );
}
