import React from 'react';

import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import StartingGridsEdit from '../../starting-grids/Edit';

export default function Edit(props) {
  return (
    <StartingGridsEdit
      requestSchemaOptions={requestSchemaOptions}
      {...props}
    />
  );
}
