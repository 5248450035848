import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  TIMESTAMP_COLUMNS, RulesList,
  InLiveBadge, InLiveColumnHeader,
  PriorityColumn,
} from '@pages/common';
import WorldSchemaCell from '@components/merge/shared/WorldSchemaCell';
import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

const ColumnsFactory = ({ applicationId, setDuplicatedRecord, onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Description', accessor: 'description' },
  PriorityColumn,
  {
    id: 'availability_header',
    Header: () => <InLiveColumnHeader />,
    Cell: ({ row }) => <InLiveBadge inLive={row.original.availabilityStateLive} />,
  },
  {
    Header: 'Rules',
    Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
  },
  {
    Header: 'World Schema',
    Cell: ({ row }) => <WorldSchemaCell schema={row.original.worldSchema} />,
  },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row }) => {
      const { id, name } = row.original;

      return (
        <ButtonToolbar>
          <IconButton.Edit to={MergeRoutes.Worlds.editPath({ id, applicationId })} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
          <IconButton.Delete onClick={onDelete(id, name)} minimized />
        </ButtonToolbar>
      );
    },
  },
];

export default ColumnsFactory;
