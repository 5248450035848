import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import { useQuery } from '@hooks';

import { MyPetCafeRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse } = useQuery(Routes.BetUpConfigurations.newRequest, { applicationId });

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Bet Up Configuration',
    actionName: 'create',
    redirectPath: Routes.BetUpConfigurations.indexPath({ applicationId }),
    setData: setResponse,
  });

  const handleSubmit = (values) => (
    Routes.BetUpConfigurations
      .createRequest({ ...values, applicationId })
      .then(responseHandler)
  );

  return response && <Form record={response} onSubmit={handleSubmit} />;
}
