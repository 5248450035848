import React, { useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { TileRoutes } from '@pages/routes';
import { formResponseHandler } from '@requests/responseHandlers';
import Form from './Form';

export default function New() {
  const [data, setData] = useState({ _meta: {} });
  const { query: { applicationId } } = useRouter();

  const responseHandler = formResponseHandler({
    entityName: 'Chapter Set Import',
    actionName: 'creation',
    setData,
  });

  const onSubmit = (values) => TileRoutes.ChapterSetImports
    .createRequest({ ...values, applicationId })
    .then(responseHandler);

  return <Form onSubmit={onSubmit} data={data} />;
}
