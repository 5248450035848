import React, { useCallback } from 'react';
import { memoize } from 'lodash';

import { fromClassNamesToOptions } from '@services/enums';
import { TileRoutes, LocalizationsRoutes } from '@pages/routes';
import {
  fieldBuilder,
  fieldGroupBuilder,
  selectBuilder,
  timePeriodFieldBuilder,
  fieldArrayBuilder,
} from '@services/fields-factory';
import { zendeskFields } from '@components/game-settings/Resource';

import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';
import { adRequestDelayFields } from '@components/game-settings/Resource/Shared';

import selectOptionsWithQuickLink from '@services/selectOptionsWithQuickLink';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import MultipleRevivesSchema from './MultipleRevivesSchema';

const popupsOptions = [
  { label: 'IDFA', value: 'IDFA' },
  { label: 'GDPR', value: 'GDPR' },
  { label: 'Push Notifications', value: 'Allow_Notifications' },
  { label: 'Rate Us', value: 'Rate_Us' },
  { label: 'PP/T&C', value: 'Privacy_Policy' },
  { label: 'Google CMP', value: 'Google_CMP' },
  { label: 'Push notifications retry', value: 'PushNotifications_Retry_OptIn' },
];

const Fields = memoize(({
  chapterSetOptions,
  difficultySchemaSetOptions,
  rewardSetOptions,
  categorySetOptions,
  localizationConfigOptions,
  navFlowsAfterWin,
  navFlowsAfterSplashScreen,
  dailyChallengesTransferLocations,
  hapticFeedbackType,
  translationOptions,
  rewardFlows,
  gameFlows,
}) => ({
  ...fieldBuilder({ name: 'minLevelsDownloaded', type: 'number' }),
  ...fieldBuilder({ name: 'themesToDownloadAhead', type: 'number' }),
  ...fieldBuilder({ name: 'enableChapterUnlockAnimation', type: 'checkbox' }),
  ...fieldBuilder({ name: 'enableLevelStartAnimation', type: 'checkbox' }),
  ...fieldBuilder({ name: 'enableMapStickySnapping', type: 'checkbox' }),
  ...fieldBuilder({ name: 'enableFtTutorial', type: 'checkbox' }),
  ...fieldBuilder({ name: 'ctaCooldown', type: 'number' }),
  ...fieldBuilder({ name: 'randomLevelsEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'randomLevelsExcludeFromStart', type: 'number' }),
  ...fieldBuilder({ name: 'randomLevelsExcludeFromEnd', type: 'number' }),
  ...fieldBuilder({ name: 'dailyChallengesFreeSegments', type: 'number' }),
  ...fieldBuilder({ name: 'dailyChallengesRewardedSegments', type: 'number' }),
  ...fieldBuilder({ name: 'dailyChallengesEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyChallengesPowerUpsDisabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'dailyChallengesUnlockLevel', type: 'number' }),
  ...fieldBuilder({ name: 'dailyChallengesEverydayReminderPopupEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'newDailyChallengeReminderPopupEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'enableMapShowLockedChapters', type: 'checkbox' }),
  ...fieldBuilder({ name: 'powerUpUndoUnlockLevel', type: 'number' }),
  ...fieldBuilder({ name: 'powerUpUndoUnlockCount', type: 'number' }),
  ...fieldBuilder({ name: 'powerUpShuffleUnlockLevel', type: 'number' }),
  ...fieldBuilder({ name: 'powerUpShuffleUnlockCount', type: 'number' }),
  ...fieldBuilder({ name: 'powerUpMagicMatchUnlockLevel', type: 'number' }),
  ...fieldBuilder({ name: 'powerUpMagicMatchUnlockCount', type: 'number' }),
  ...fieldBuilder({ name: 'powerUpTutorialEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'enableRewardProgressWinPopup', type: 'checkbox' }),
  ...selectBuilder({ name: 'dailyChallengesUnlockFlow', options: dailyChallengesTransferLocations }),
  ...selectBuilder({ name: 'newDailyChallengeFlow', options: dailyChallengesTransferLocations }),
  ...selectBuilder({ name: 'navFlowAfterGameWin', options: navFlowsAfterWin }),
  ...selectBuilder({ name: 'flowAfterSplashScreen', options: navFlowsAfterSplashScreen }),
  ...selectBuilder({ name: 'hapticTileTap', options: hapticFeedbackType, includeEmpty: true }),
  ...selectBuilder({ name: 'hapticTileTapMatch', options: hapticFeedbackType, includeEmpty: true }),
  ...selectBuilder({ name: 'hapticPowerupUndo', options: hapticFeedbackType, includeEmpty: true }),
  ...selectBuilder({ name: 'hapticPowerupShuffle', options: hapticFeedbackType, includeEmpty: true }),
  ...selectBuilder({ name: 'hapticPowerupMagicmatch', options: hapticFeedbackType, includeEmpty: true }),
  ...selectBuilder({ name: 'rewardFlow', options: rewardFlows }),
  ...selectBuilder({ name: 'ftueAfterLevelOneFlow', options: gameFlows }),
  ...fieldBuilder({ name: 'coinsEnable', type: 'checkbox' }),
  ...fieldBuilder({ name: 'coinsInitialInventory', type: 'number' }),
  ...fieldBuilder({ name: 'coinsPowerupUndoCoins', type: 'number' }),
  ...fieldBuilder({ name: 'coinsPowerupMagicmatchCoins', type: 'number' }),
  ...fieldBuilder({ name: 'coinsPowerupShuffleCoins', type: 'number' }),
  ...fieldBuilder({ name: 'coinsItemReviveCoins', type: 'number' }),
  ...timePeriodFieldBuilder({ name: 'splashScreenDurationMin', inputFields: ['seconds'] }),
  ...timePeriodFieldBuilder({ name: 'splashScreenDurationMax', inputFields: ['seconds'] }),
  ...fieldBuilder({ name: 'increaseTilesSize', type: 'checkbox' }),
  ...fieldBuilder({ name: 'shopEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'enableGameSaving', type: 'checkbox' }),
  ...selectBuilder({ name: 'chapterSetId', ...chapterSetOptions }),
  ...selectBuilder({ name: 'ddaSchemaSetId', ...difficultySchemaSetOptions }),
  ...selectBuilder({ name: 'rewardSetId', ...rewardSetOptions }),
  ...selectBuilder({ name: 'categorySetId', ...categorySetOptions }),
  ...selectBuilder({ name: 'localizationsConfigId', ...localizationConfigOptions }),
  ...selectBuilder({ name: 'newDailyChallengesLocalizationKey', options: translationOptions }),
  ...fieldBuilder({ name: 'enableBannersMetaScreens', type: 'checkbox' }),
  ...fieldGroupBuilder(
    {
      name: 'ctaMagicMatch',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'enabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'noInputDuration', type: 'number' }),
        ...fieldBuilder({ name: 'gameProgress', type: 'number' }),
        ...fieldBuilder({ name: 'minTilesInHandCount', type: 'number' }),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'ctaShuffle',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'enabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'noInputDuration', type: 'number' }),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'dailyChallengesPushNotificationAfterDcResetConfiguration',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'enabled', type: 'checkbox' }),
        ...timePeriodFieldBuilder({ name: 'seconds', inputFields: ['hours'] }),
        ...selectBuilder({ name: 'titleLocalizationKeyId', options: translationOptions }),
        ...selectBuilder({ name: 'subtitleLocalizationKeyId', includeEmpty: true, options: translationOptions }),
        ...selectBuilder({ name: 'messageLocalizationKeyId', options: translationOptions },
        ),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'dailyChallengesPushNotificationBeforeDcResetConfiguration',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'enabled', type: 'checkbox' }),
        ...timePeriodFieldBuilder({ name: 'seconds', inputFields: ['hours'] }),
        ...selectBuilder({ name: 'titleLocalizationKeyId', options: translationOptions }),
        ...selectBuilder({ name: 'subtitleLocalizationKeyId', includeEmpty: true, options: translationOptions }),
        ...selectBuilder({ name: 'messageLocalizationKeyId', options: translationOptions }),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'inactiveButtonsSettings',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'enableRewardedButtonVisualStates', type: 'checkbox' }),
        ...fieldBuilder({ name: 'enablePowerUpRewardedAdPopup', type: 'checkbox' }),
        ...fieldBuilder({ name: 'enableLockedPowerUpVisuals', type: 'checkbox' }),
        ...timePeriodFieldBuilder({ name: 'stateChangePeriod', inputFields: ['seconds'] }),
      },
    },
  ),
  ...fieldGroupBuilder({
    name: 'privacyPolicyPopup',
    fieldsConfiguration: {
      ...selectBuilder({ name: 'titleTranslationKeyId', options: translationOptions, includeEmpty: true }),
      ...selectBuilder({ name: 'bodyTranslationKeyId', options: translationOptions, includeEmpty: true }),
      ...selectBuilder({ name: 'buttonTranslationKeyId', options: translationOptions, includeEmpty: true }),
    },
  }),
  ...fieldArrayBuilder({
    name: 'oneSignalTags',
    fieldsConfiguration: [
      { label: 'Key', name: 'key', type: 'text' },
      { label: 'Value', name: 'value', type: 'number' },
    ],
  }),
  ...fieldBuilder({ name: 'polaroidsJournalEnabled', type: 'checkbox' }),
  ...fieldGroupBuilder({
    name: 'multipleRevives',
    fieldsConfiguration: {
      ...fieldBuilder({ name: 'enable', type: 'checkbox' }),
      ...fieldBuilder(
        {
          name: 'schema',
          as: 'component',
          componentFn: ({ name, disabled }) => <MultipleRevivesSchema name={name} disabled={disabled} />,
        },
      ),
    },
  }),
}), (args) => JSON.stringify(args));

const fieldsFactory = ({ applicationId }) => {
  const [
    navFlowsAfterWin,
    navFlowsAfterSplashScreen,
    dailyChallengesTransferLocations,
  ] = fromClassNamesToOptions([
    'Tile::NavFlowsAfterWin',
    'Tile::NavFlowsAfterSplashScreen',
    'Tile::DailyChallengesPopupTransferLocations',
  ], { transformLabel: (name) => `to ${name}` });
  const [hapticFeedbackType, rewardFlows, gameFlows] = fromClassNamesToOptions([
    'Tile::HapticFeedbackType',
    'Tile::RewardFlows',
    'Tile::GameFlows',
  ]);
  const localizationConfigOptions = selectOptionsWithQuickLink(LocalizationsRoutes.Configs, applicationId);
  const chapterSetOptions = selectOptionsWithQuickLink(TileRoutes.ChapterSets, applicationId);
  const difficultySchemaSetOptions = selectOptionsWithQuickLink(TileRoutes.DifficultySchemaSets, applicationId);
  const rewardSetOptions = selectOptionsWithQuickLink(TileRoutes.RewardSets, applicationId);
  const categorySetOptions = selectOptionsWithQuickLink(TileRoutes.CategorySets, applicationId);
  const translationOptions = useCallback(
    () => requestWithToSelectOptions(LocalizationsRoutes.Keys.indexRequest, applicationId),
    [],
  );

  return Fields({
    chapterSetOptions,
    difficultySchemaSetOptions,
    rewardSetOptions,
    categorySetOptions,
    localizationConfigOptions,
    navFlowsAfterWin,
    navFlowsAfterSplashScreen,
    dailyChallengesTransferLocations,
    hapticFeedbackType,
    translationOptions,
    rewardFlows,
    gameFlows,
    applicationId,
  });
};

export default combineWithSharedFieldsFactory([fieldsFactory, adRequestDelayFields, zendeskFields], { popupsOptions });
