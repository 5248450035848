import React from 'react';
import { useTheme } from 'styled-components';
import { Sidebar } from '@tripledotstudios/react-core';
import { WoodokuRoutes } from '@pages/routes';

import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

export default function JourneysNavigationSection({
  routingScope,
  minimized = false,
  includeActivationEvents = false,
  includeLevelBanks = true,
  includePromoEvents = false,
  includeEndOfJourneyEvents = false,
  includeHardJourneys = false,
  sectionName = 'Journeys',
  eventsName = 'Events',
  levelBanksName = 'Level banks',
  activationEventsName = 'Activation Events',
  promoEventsName = 'Promo Events',
  endOfJourneyEventsName = 'End of Journey Content',
  hardJourneysName = 'Hard Journeys',
  applicationId,
}) {
  const theme = useTheme();

  return (
    <Sidebar.SectionItem
      icon={faCalendarDays}
      title={sectionName}
      color={theme.purple}
      minimized={minimized}
    >
      {includeActivationEvents && (
        <Sidebar.LinkItem
          to={`${routingScope}/journeys/activation_events`}
          title={activationEventsName}
          isActive={(match, location) => (
            match || /journeys\/activation_event_configs\/\d+\/experiment_scoped/.test(location.pathname)
          )}
        />
      )}
      <Sidebar.LinkItem
        to={`${routingScope}/journeys/events`}
        title={eventsName}
        isActive={(match, location) => (
          match || /journeys\/event_configs\/\d+\/experiment_scoped/.test(location.pathname)
        )}
      />
      {includePromoEvents && (
        <Sidebar.LinkItem
          to={`${routingScope}/journeys/promo_events`}
          title={promoEventsName}
          isActive={(match, location) => (
            match || /journeys\/promo_event_configs\/\d+\/experiment_scoped/.test(location.pathname)
          )}
        />
      )}
      {includeEndOfJourneyEvents && (
        <Sidebar.LinkItem
          to={`${routingScope}/journeys/end_of_journey_events`}
          title={endOfJourneyEventsName}
          isActive={(match, location) => (
            match || /journeys\/end_of_journey_event_configs\/\d+\/experiment_scoped/.test(location.pathname)
          )}
        />
      )}
      {includeHardJourneys && (
        <Sidebar.LinkItem
          to={WoodokuRoutes.HardJourneys.indexPath({ applicationId })}
          title={hardJourneysName}
        />
      )}
      {includeLevelBanks && (
        <Sidebar.LinkItem
          to={`${routingScope}/journeys/level_banks`}
          title={levelBanksName}
        />
      )}
    </Sidebar.SectionItem>
  );
}
