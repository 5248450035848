import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import ListWrapper from '@components/shared/ListWrapper';
import {
  FormButtonsGroup,
  Label,
  Field,
  StaticFieldsFormGroup,
} from '@controls/form';

import { Form, FormGroup, useRewardOptions } from '@hooks';
import { DuplicateButton, PageHeader } from '@pages/common';
import { MyPetCafeRoutes, MissionsRoutes } from '@pages/routes';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { combineReducers, rewardableReducer } from '@reducers';

import reducer from './formReducer';
import Challenge from './challenges/Challenge';

export default function LeaderboardsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const [levelManifestTypes, setLevelManifestTypes] = useState([]);
  const [missionTypes, setMissionTypes] = useState([]);

  const rewardOptions = useRewardOptions();

  useEffect(() => {
    requestWithToSelectOptions(MyPetCafeRoutes.LevelManifestSetTypes.indexRequest, applicationId)
      .then(setLevelManifestTypes);
    requestWithToSelectOptions(MissionsRoutes.MissionTypes.indexRequest, applicationId)
      .then(setMissionTypes);
  }, []);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([reducer, rewardableReducer])}
    >
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Leaderboard`}>
            <FormButtonsGroup cancelButtonPath={MyPetCafeRoutes.Leaderboards.Configs.indexPath({ applicationId })}>
              <DuplicateButton routes={MyPetCafeRoutes.Leaderboards.Configs} data={data} entityName="Leaderboard" />
            </FormButtonsGroup>
          </PageHeader>
          <Label text="Name">
            <Field type="text" name="name" />
          </Label>
          <Label
            text="Unity Leaderboard ID"
            tooltipText="Enter Unity Leaderboard ID to connect configuration with required Unity leaderboard"
          >
            <Field type="text" name="unityLeaderboardId" />
          </Label>
          <Label text="Non opt-in period">
            <Field type="number" name="nonOptInPeriodInSeconds" appendText="seconds" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <FormGroup name="challengesAttributes">
            <ListWrapper
              entityAttributes={values.challengesAttributes}
              entityIdentity={({ entityName, entity }) => `${entityName} #${entity.number}`}
              entityNameTranslationPath="leaderboards.config.challenges.entityName"
              onEntityAdd={() => dispatch({ actionType: 'addEmptyEntity' })}
            >
              {({ entity }) => (
                <Challenge
                  entity={entity}
                  levelManifestTypes={levelManifestTypes}
                  missionTypes={missionTypes}
                  dispatch={dispatch}
                  rewardOptions={rewardOptions}
                />
              )}
            </ListWrapper>
          </FormGroup>
        </>
      )}
    </Form>
  );
}
