import { pick } from 'lodash';

const mapLevels = (data, callback) => {
  const { configAttributes: { levelsAttributes, ...restConfigAttributes } } = data;

  const modifiedLevels = levelsAttributes.map(callback);
  const modifiedData = { ...data, configAttributes: { ...restConfigAttributes, levelsAttributes: modifiedLevels } };

  return modifiedData;
};

export const serializableLevelAttributes = [
  'bankId',
  'pictureSegmentsNumber',
  'orderingType',
];

export const serializableLevel = (level) => pick(level, serializableLevelAttributes);

export const preprocessData = (data) => (
  mapLevels(data, (level) => ({ ...level, _appliedValues: serializableLevel(level) }))
);

export const postprocessData = (data) => (
  mapLevels(data, (level) => {
    const modifiedLevel = { ...level, ...level._appliedValues };
    delete modifiedLevel._appliedValues;
    delete modifiedLevel.populated;

    return modifiedLevel;
  })
);

const onSubmit = (values, onFormSubmit) => onFormSubmit(postprocessData(values));

export default { onSubmit, preprocessData };
