import React from 'react';

import {
  GameSettingsTabs,
  localizationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  appEventsFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
} from '@components/game-settings/Resource';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: assetsDefaultFieldNames,
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: popupsDefaultFieldNames,
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      ...adPlacementIdsDefaultFieldNames,
      ...adConfigsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: appEventsFieldNames,
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: popupsDefaultArchivedFieldNames,
  },
];

export default function Tabs({
  fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
