import React from 'react';
import { Tooltip } from '@tripledotstudios/react-core';
import { Link } from 'react-router-dom';

import { LabelLink } from '@pages/common';

import { useCurrentApplication } from '@hooks';

export default function NameColumn({ routes, hideIdInTooltip, ...props }) {
  return {
    Header: 'Name',
    accessor: 'name',
    style: { width: '20%' },
    Cell: (
      {
        row: {
          original: {
            name,
            id,
            updatedAt,
            labels,
          },
        },
      },
    ) => {
      const { applicationId } = useCurrentApplication();

      return (
        <>
          <p className="mb-1">
            <Link to={routes.editPath({ applicationId, id, ...props })}>
              <Tooltip text={`${hideIdInTooltip ? '' : `ID: ${id}, `}Updated at (UTC): ${updatedAt}`}>
                {name}
              </Tooltip>
            </Link>
          </p>

          {labels && labels.length > 0 && (
            <p className="mb-0">
              {labels.map((label) => (
                <LabelLink key={label.name} label={label} />
              ))}
            </p>
          )}
        </>
      );
    },
  };
}
