import React, { useMemo } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import {
  PageHeader, Pagination, ReactTable, Filter,
} from '@pages/common';
import { MergeRoutes } from '@pages/routes';
import ColumnsFactory from './ColumnsFactory';

export default function Index() {
  const { response } = useQuery(MergeRoutes.WorldSchemas.indexRequest, { includeInUse: true });
  const { query } = useRouter();

  const columns = useMemo(() => ColumnsFactory({ query }), []);

  return response ? (
    <div>
      <PageHeader
        title="World Schemas"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
      />

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
      />
      <Pagination
        pageCount={response ? response._meta.pages : 1}
      />
    </div>
  ) : '';
}
