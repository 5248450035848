import React from 'react';
import { isEmpty } from 'lodash';
import { Nav, Tab } from 'react-bootstrap';
import { Alert } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import Form from './Form';

const AdminApplicationSettingsTabs = ({ applications }) => (
  <Tab.Container defaultActiveKey={applications[0].id}>
    <Nav variant="tabs" className="mb-3">
      {applications.map(({ id, label }) => (
        <Nav.Item>
          <Nav.Link eventKey={id}>
            {label}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>

    <Tab.Content>
      {applications.map(({
        id, label, type, typeName,
      }) => (
        <Tab.Pane eventKey={id}>
          <Form
            applicationId={id}
            applicationName={label}
            applicationType={type}
            applicationTypeName={typeName}
          />
        </Tab.Pane>
      ))}
    </Tab.Content>
  </Tab.Container>
);

const NoApplicationsMessage = () => (
  <Alert variant="warning">
    There are no applications available for configuration
  </Alert>
);

export default function Tabs() {
  const { applications } = APP_DATA;

  return (
    isEmpty(applications) ? <NoApplicationsMessage /> : <AdminApplicationSettingsTabs applications={applications} />
  );
}
