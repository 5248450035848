import { get } from 'lodash';
import { stringify } from '@tripledotstudios/react-core';
import getQueryParams from '@services/getQueryParams';

export default function updateQuery(router, changes, refetch = () => { }, options = {}) {
  const currentQuery = getQueryParams();
  const query = stringify({ ...currentQuery, ...changes });
  if (stringify(currentQuery) === query) { return; }

  const path = `${router.pathname}?${query}`;
  const method = get(options, 'method', 'push');

  switch (method) {
    case 'push': {
      router.push(path);
      break;
    }
    case 'replace': {
      router.replace(path);
      break;
    }
    default: {
      throw new Error(`Unknown '${method}' method passed for updateQuery`);
    }
  }

  refetch();
}
