import React from 'react';

import Badge from './Badge';

export default function StatusBadge({
  options, value, label, className,
}) {
  const variantsMapping = {
    [options.ACTIVE]: 'success',
  };
  if (options.ALLOCATION_PAUSED) variantsMapping[options.ALLOCATION_PAUSED] = 'warning';

  return <Badge bg={variantsMapping[value] || 'secondary'} className={className}>{label}</Badge>;
}
