import React from 'react';
import ReactSelect from 'react-select';

import NotSetOption from './notSetOption';

export default function SelectFilter({
  options, name, filters, setFilters, includeNotSet = false,
}) {
  const selectOptions = includeNotSet ? [...options, NotSetOption] : options;
  const handleFilterChange = (option) => (
    setFilters((prev) => {
      if (option) return { ...prev, [name]: { type: 'eq', value: option.value } };

      const { [name]: _, ...rest } = prev;
      return rest;
    })
  );
  const currentOption = selectOptions.find(({ value }) => filters[name] && filters[name].value === value);

  return (
    <ReactSelect
      value={currentOption}
      onChange={handleFilterChange}
      options={selectOptions}
      className="react-select"
      classNamePrefix="react-select"
      isClearable
    />
  );
}
