import React from 'react';
import { Route } from 'react-router-dom';

import { PageTitle } from '@pages/common';
import { SortagaRoutes } from '@pages/routes';
import JourneysExperimentScoped from '@pages/sortaga/journeys/ExperimentScoped';
import DailyChallengesExperimentScoped from '@pages/sortaga/daily-challenges/ExperimentScoped';
import RouteContainer from '@pages/RouteContainer';
import ActivationJourneysExperimentScoped from '@pages/sortaga/activation-journeys/ExperimentScoped';

export default function sortaga() {
  return [
    [
      <Route
        key={SortagaRoutes.Journeys.EventConfigs.experimentScopedRawPath}
        path={SortagaRoutes.Journeys.EventConfigs.experimentScopedRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Edit Journey variant" />
            <JourneysExperimentScoped />
          </RouteContainer>
        )}
      />,
      <Route
        key={SortagaRoutes.ActivationJourneys.EventConfigs.experimentScopedRawPath}
        path={SortagaRoutes.ActivationJourneys.EventConfigs.experimentScopedRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Edit Activation Journey variant" />
            <ActivationJourneysExperimentScoped />
          </RouteContainer>
    )}
      />,
      <Route
        key={SortagaRoutes.DailyChallenges.EventConfigs.experimentScopedRawPath}
        path={SortagaRoutes.DailyChallenges.EventConfigs.experimentScopedRawPath}
        element={(
          <RouteContainer>
            <PageTitle text="Edit Daily Challenge Event Variant" />
            <DailyChallengesExperimentScoped />
          </RouteContainer>
        )}
      />,
    ],
  ];
}
