import React from 'react';
import styled from 'styled-components';

import APP_DATA from '@services/appData';

import { FormGroup } from '@hooks';
import { Field, AssetsKeySelectField } from '@controls/form';

import { ChapterRow, ColumnTitle, LevelsConfigFields } from '../../metagame/chapter-based/chapter-sets/shared';

const {
  modelsLocales: { tile: { chapters: { tooltips } } },
} = APP_DATA;

const COLUMN_PADDING_SIZE = 10;

const StaticValue = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
`;

const IdColumn = styled.div`
  padding-right: ${COLUMN_PADDING_SIZE}px;
  width: 5%;
`;

const NameColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 15%;
`;

const UIThemeAssetBundleKeyColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 40%;
`;

const EmptyColumn = styled.div`
  width: 5%;
`;

const IconsOffsetColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 15%;
`;

export default function ChapterFields({
  banks,
  chapter,
  assetsKeys,
  dispatch,
  isDirty,
  levelsTotal,
  populateChapter,
}) {
  return (
    <>
      <ChapterRow>
        <IdColumn>
          <ColumnTitle>Id</ColumnTitle>
          <StaticValue>{chapter.id}</StaticValue>
        </IdColumn>
        <NameColumn>
          <ColumnTitle>Chapter name</ColumnTitle>
          <Field name="name" fast />
        </NameColumn>
        <UIThemeAssetBundleKeyColumn>
          <ColumnTitle>UI theme bundle</ColumnTitle>
          <FormGroup name="configAttributes">
            <AssetsKeySelectField
              name="uiThemeAssetBundleKeyId"
              options={assetsKeys}
              showStatuses={false}
              showCategories
              selectSize={7}
              includeEmpty
            />
          </FormGroup>
        </UIThemeAssetBundleKeyColumn>
      </ChapterRow>
      <ChapterRow>
        <EmptyColumn />
        <IconsOffsetColumn>
          <ColumnTitle tooltip={tooltips.configAttributes.iconsOffset}>Tile icons offset</ColumnTitle>
          <FormGroup name="configAttributes">
            <Field name="iconsOffset" type="number" min={0} max={100} />
          </FormGroup>
        </IconsOffsetColumn>
      </ChapterRow>
      <LevelsConfigFields
        banks={banks}
        chapter={chapter}
        isDirty={isDirty}
        levelsTotal={levelsTotal}
        populateChapter={populateChapter}
        dispatch={dispatch}
      />
    </>
  );
}
