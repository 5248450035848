import React, { useState } from 'react';

import { Form } from '@hooks';
import { FormButtonsGroup } from '@controls/form';
import { PageHeader } from '@pages/common';

import { LocalizationsRoutes } from '@pages/routes';
import { useRouter } from '@tripledotstudios/react-core';
import configsReducer from './reducer';
import Header from './Header';
import Languages from './Languages';

export default function LocalizationsConfigsForm({ data, onSubmit }) {
  const { query } = useRouter();
  const [languagesOrderDirty, setLanguagesOrderDirty] = useState(false);
  const onLanguagesReorder = () => setLanguagesOrderDirty(true);

  return (
    <Form
      initialValues={{ ...data }}
      onSubmit={onSubmit}
      reducer={configsReducer}
      customDirty={languagesOrderDirty}
    >
      {({ values }) => (
        <>
          <PageHeader
            title={`Localizations: ${data.id ? 'Edit' : 'New'} Settings Config`}
            userGuideUrlKey="localizationsGuideUrl"
          >
            <FormButtonsGroup
              cancelButtonPath={LocalizationsRoutes.Configs.indexPath(query)}
            />
          </PageHeader>

          <div className="pb-5">
            <Header data={values} />
            <Languages data={values} onReorder={onLanguagesReorder} />
          </div>
        </>
      )}
    </Form>
  );
}
