import {
  get,
  fetchCollection,
  updateResource,
  deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/my_pet_cafe/level_layouts`;

export function getHomePageSettings(data) {
  return get(`${baseUrl(data)}/home_settings`);
}

export function getLevelLayouts(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function updateLevelLayout(values) {
  return updateResource(baseUrl(values), values);
}

export function deleteLevelLayout(values) {
  return deleteResource(baseUrl(values), values);
}

export function downloadAllLevels(data) {
  return get(`${baseUrl(data)}/download_all_levels`);
}

export function getLevelLayoutsIds(data) {
  return get(`${baseUrl(data)}/layout_ids`);
}
