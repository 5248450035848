import { memoize } from 'lodash';

export const indexedPlacements = memoize((placementsResponse) => (placementsResponse && placementsResponse.items.reduce(
  (acc, placement) => ({ ...acc, [placement.id]: placement }),
  {},
)) || {});

export const placementsToAddOptions = memoize((values, placementsResponse) => {
  const addedPlacementIds = values.overridesAttributes.reduce((memo, { placementId, _destroy }) => (
    _destroy ? memo : [...memo, placementId]
  ), []);

  return placementsResponse.items.reduce((memo, { id, name, key }) => {
    if (!addedPlacementIds.includes(id)) {
      memo.push({ label: `${name} (${key})`, value: id });
    }

    return memo;
  }, []);
});
