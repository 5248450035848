import React, { useState } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import {
  Label, Field, FormButtonsGroup, StaticFieldsFormGroup, ServerError,
} from '@controls/form';
import {
  PageHeader, PageSection, Alert,
} from '@pages/common';
import {
  Form, FormGroup, useQuery, useConfirm,
} from '@hooks';
import { isInUse } from '@pages/common/InUse';
import { AdsRoutes } from '@pages/routes';

import Select from '@controls/Select';
import IconButton from '@controls/buttons';
import RestorableTableRow from '@controls/tables/RestorableTableRow';

import formReducer from './formReducer';

const PlacementsFormGroup = ({ placementAffiliationsAttributes, dispatch }) => {
  const [selectedPlacementId, setSelectedPlacementId] = useState(null);

  const { response: placementsResponse, isLoading } = useQuery(
    AdsRoutes.Badges.Placements.indexRequest, { withoutPagination: true },
  );

  const indexedPlacements = (placementsResponse && placementsResponse.items.reduce(
    (acc, placement) => ({ ...acc, [placement.id]: placement }),
    {},
  )) || {};

  const placementsToAddOptions = () => {
    const addedPlacementIds = placementAffiliationsAttributes.map(({ placementId }) => placementId);

    return placementsResponse.items.reduce((memo, { id, name, key }) => {
      if (!addedPlacementIds.includes(id)) {
        memo.push({ label: `${name} (${key})`, value: id });
      }

      return memo;
    }, []);
  };

  const onPlacementAdd = () => {
    dispatch({ type: 'addBadgesPlacement', placementId: selectedPlacementId });
    setSelectedPlacementId(null);
  };

  return isLoading ? <Spinner /> : (
    placementsResponse && (
      <>
        <ServerError as={Alert} name="placementAffiliationsAttributes" />

        <Row className="mb-3">
          <Col xs={3}>
            <Select
              selectedValue={selectedPlacementId}
              onChange={({ value }) => setSelectedPlacementId(value)}
              options={placementsToAddOptions()}
            />
          </Col>
          <Col xs={9} className="pl-0">
            <IconButton.New
              onClick={onPlacementAdd}
              disabled={!selectedPlacementId}
              className="h-100"
            >
              Add Placement
            </IconButton.New>
          </Col>
        </Row>

        <Table className="mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {placementAffiliationsAttributes.map(({ id, placementId, _destroy }, index) => {
              const placement = indexedPlacements[placementId];
              if (!placement) return '';

              return (
                <FormGroup
                  name={`placementAffiliationsAttributes[${index}]`}
                  key={placement.key}
                >
                  <RestorableTableRow
                    isNewRecord={!id}
                    removed={_destroy}
                    deleteActionType="removeBadgesPlacement"
                    restoreActionType="restoreBadgesPlacement"
                    index={index}
                  >
                    <td>
                      <Field type="hidden" name="id" />
                      {index + 1}
                    </td>
                    <td>
                      {placement.name}
                      <b>{` (${placement.key})`}</b>
                    </td>
                  </RestorableTableRow>
                </FormGroup>
              );
            })}
          </tbody>
        </Table>
      </>
    )
  );
};

export default function PlacementBanksForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();

  const disabled = isInUse(data.inUse);

  const confirmSubmit = (values) => {
    if (!values.placementAffiliationsAttributes.some(({ _destroy }) => _destroy)) return onSubmit(values);

    return confirm.showConfirmation({
      title: 'Some Placements will be removed from the Badge Placements Bank. Continue?',
    }).then(() => onSubmit(values));
  };

  return (
    <Form initialValues={data} onSubmit={confirmSubmit} reducer={formReducer}>
      {({ dispatch, values: { placementAffiliationsAttributes } }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Badge Placements Bank`}>
            <FormButtonsGroup
              cancelButtonPath={AdsRoutes.Badges.PlacementBanks.indexPath({ applicationId })}
              disableSubmit={disabled}
            />
          </PageHeader>

          <Label text="Name" required>
            <Field type="text" name="name" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <PageSection title="Placements">
            <PlacementsFormGroup
              placementAffiliationsAttributes={placementAffiliationsAttributes}
              dispatch={dispatch}
            />
          </PageSection>
        </>
      )}
    </Form>
  );
}
