import React from 'react';

import { useQuery } from '@hooks';

import ExperimentScopedWrapper from '@pages/journeys/events/ExperimentScoped';
import ConfigurationForm from '@pages/journeys/events/configuration-forms/MyPetCafeConfigurationForm';

import { MyPetCafeRoutes as Routes } from '@pages/routes';

export default function ExperimentScoped() {
  const { response, setResponse } = useQuery(Routes.Journeys.EventConfigs.experimentScopedRequest);

  const onSubmit = (values) => Routes.Journeys.EventConfigs.updateVariantsRequest(values);

  return (
    <ExperimentScopedWrapper data={response} setData={setResponse} onSubmit={onSubmit}>
      {({ variantAttributes, variantIndex }) => (
        <ConfigurationForm values={variantAttributes} variantIndex={variantIndex} />
      )}
    </ExperimentScopedWrapper>
  );
}
