import React, { useState } from 'react';

import APP_DATA from '@services/appData';

import { useQuery, useCurrentApplication } from '@hooks';
import { GameSettingsRoutes } from '@pages/routes';
import { PageHeader, SearchEnabledFields } from '@pages/common';

const { enums: { GameSettingsStatuses: gameSettingsStatuses } } = APP_DATA;

const routeGameType = (gameType) => {
  if (gameType === 'woodoku_saga') return 'discovery';

  return gameType;
};

export default function SearchEnabledSettings() {
  const [data, setData] = useState();
  const { frontendTypeName, currentApplication: { id: applicationId } } = useCurrentApplication();
  const { response: fieldsData } = useQuery(
    GameSettingsRoutes.fieldNamesRequest, { gameType: routeGameType(frontendTypeName) },
  );

  const onSubmit = (values) => {
    GameSettingsRoutes
      .searchFieldRequest({ ...values, gameType: frontendTypeName, applicationId })
      .then(({ data: rawData }) => setData(rawData));
  };

  return fieldsData && (
    <>
      <PageHeader title="Game Settings Search" />
      <SearchEnabledFields
        fieldsData={fieldsData}
        data={data}
        onSubmit={onSubmit}
        localeNamespace="gameSettings"
        entityStatuses={gameSettingsStatuses}
      />
    </>
  );
}
