import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import Modal from './RulesListModal';

export default function MultiLevelRules({ rules, nestedForm }) {
  const [showModal, setShowModal] = useState(false);
  const onModalClose = () => setShowModal(false);

  return (
    <>
      <Button
        variant="outline-primary"
        size="sm"
        onClick={() => setShowModal(true)}
      >
        See applied rules
      </Button>
      <Modal
        show={showModal}
        rules={rules}
        nestedForm={nestedForm}
        onClose={onModalClose}
      />
    </>
  );
}
