import React from 'react';

import { Field } from '@controls/form';

export default function BetUpModeRule({ disabled }) {
  return (
    <Field
      type="number"
      name="value"
      disabled={disabled}
    />
  );
}
