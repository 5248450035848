import {
  fieldBuilder,
  selectBuilder,
  reorderableFieldBuilder,
  fieldArrayBuilder,
  arrayFieldBuilder,
  assetsKeySelectBuilder,
  fieldGroupBuilder,
  timePeriodFieldBuilder,
  warningBuilder,
  sectionBuilder,
} from '@services/fields-factory';

import processProps from './processProps';

const MAPPING = {
  field: fieldBuilder,
  select: selectBuilder,
  reorderableField: reorderableFieldBuilder,
  fieldArray: fieldArrayBuilder,
  arrayField: arrayFieldBuilder,
  assetsKeySelect: assetsKeySelectBuilder,
  timePeriodField: timePeriodFieldBuilder,
  warning: warningBuilder,
  section: sectionBuilder,
};

function buildGroupSetting(configuration, options) {
  const { name: groupName, groupConfiguration } = configuration;

  const groupFields = groupConfiguration.fields
    .map(({ builder, name, props }) => {
      const processedProps = processProps(props, options);

      return MAPPING[builder]({ name, ...processedProps });
    });

  return fieldGroupBuilder(
    {
      name: groupName,
      fieldsConfiguration: Object.assign({}, ...groupFields),
    },
  );
}

export default function buildFieldsFromConfig(configurations, options) {
  const fieldsArray = configurations.map((configuration) => {
    const { builder, name, props } = configuration;
    const processedProps = processProps(props, options);

    if (builder === 'group') return buildGroupSetting(configuration, options);

    return MAPPING[builder]({ name, ...processedProps });
  });

  return Object.assign({}, ...fieldsArray);
}
