import { useState } from 'react';

export default function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    const item = localStorage.getItem(key);

    let parsedItemValue;
    // eslint-disable-next-line no-empty
    try { parsedItemValue = JSON.parse(item); } catch (e) { }

    return parsedItemValue || initialValue;
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    localStorage.setItem(key, JSON.stringify(valueToStore));
    setStoredValue(valueToStore);
  };

  return [storedValue, setValue];
}
