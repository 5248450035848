import {
  fetchCollection, newResource, editResource, createResource, updateResource, deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/rewards/items`;

export const getRewardItems = (data) => fetchCollection(baseUrl(data), data);

export const newRewardItem = (data, options) => newResource(baseUrl(data), options);

export const editRewardItem = (data, options) => editResource(baseUrl(data), data, options);

export const createRewardItem = (data) => createResource(baseUrl(data), data);

export const updateRewardItem = (data) => updateResource(baseUrl(data), data);

export const deleteRewardItem = (data) => deleteResource(baseUrl(data), data);
