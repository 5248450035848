import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes } from '@pages/routes';

import Form from './Form';
import transformPostcardAttributes from './transformPostcardAttributes';

export default function Edit() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'World Schemas',
    actionName: 'update',
    setData,
  });

  const onSubmit = (values) => {
    const editableValues = cloneDeep(values);
    editableValues.postcardsAttributes.forEach(transformPostcardAttributes);

    return (
      MergeRoutes.WorldSchemas.updateRequest({ ...editableValues, applicationId: query.applicationId })
        .then(responseHandler)
    );
  };

  useEffect(() => {
    MergeRoutes.WorldSchemas.editRequest(query).then((response) => setData(response.data));
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} />;
}
