import React from 'react';
import { BooleanIcon } from '@pages/common';
import styled from 'styled-components';

import BanksIndex, { banksColumns } from '../../metagame/chapter-based/banks/Index';

const CountColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const woodokuSagaBanksColumns = (props) => {
  const columns = banksColumns(props);
  columns[5] = {
    Header: 'Layouts',
    accessor: 'levelLayoutsCount',
    Cell: ({ row: { original: { levelLayoutsCount, validForUse } } }) => (
      <CountColumn>
        {levelLayoutsCount}
        <BooleanIcon
          value={validForUse}
          title={validForUse
            ? 'All the layouts are valid for use in game client'
            : 'Includes layouts not valid for use in game client'}
        />
      </CountColumn>
    ),
  };
  return columns;
};

export default function Index() {
  return (
    <BanksIndex columnsOverride={woodokuSagaBanksColumns} />
  );
}
