import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import {
  newLevelLayout, createLevelLayout,
} from '../requests/levelLayoutsRequests';
import Form from './Form';

export default function New() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Level layout',
    actionName: 'create',
    redirectPath: `/admin/applications/${query.applicationId}/solitaire/level_layouts`,
    setData,
  });

  const onSubmit = (values) => createLevelLayout(query, values).then(responseHandler);

  useEffect(() => {
    newLevelLayout(query).then((response) => setData(response.data));
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} action="new" />;
}
