/* eslint-disable gs/plz-camel-case-for-action-names */
import { maxBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { dndRecords } from '@services/reorder';

const resolveVariant = (state, variantId) => {
  if (!state.variantsAttributes) return state;

  return state.variantsAttributes.find((v) => v.id === variantId);
};

export default function catalogReducer(state, action) {
  const { defaultVariant, variantId } = action;

  const variant = defaultVariant ? state.currentVariantAttributes : resolveVariant(state, variantId);

  if (action.type === 'add_slot') {
    const { slotsAttributes } = variant;

    const maxPositionSlot = maxBy(slotsAttributes.filter(({ _destroy }) => !_destroy), 'position');
    const maxPosition = maxPositionSlot ? maxPositionSlot.position : 0;

    slotsAttributes.push({
      _uuid: uuidv4(),
      position: maxPosition + 1,
      catalogVariantId: variantId,
      _destroy: false,
    });

    return state;
  }

  if (action.type === 'remove_slot') {
    const { slotsAttributes } = variant;

    const { position: positionToRemove } = action;

    slotsAttributes.filter(({ position }) => position >= positionToRemove).forEach((slot) => {
      if (slot.position === positionToRemove) {
        slot._destroy = true;

        return slot;
      }

      slot.position -= 1;

      return slot;
    });

    variant.slotsAttributes = slotsAttributes;

    return state;
  }

  if (action.type === 'reorder_slots') {
    dndRecords(state, action);
    return state;
  }

  return state;
}
