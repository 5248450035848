import { memoize } from 'lodash';

import {
  fieldBuilder,
  selectBuilder,
  fieldGroupBuilder,
  arrayFieldBuilder,
} from '@services/fields-factory';
import { LocalizationsRoutes } from '@pages/routes';
import { fromClassNameToOptions } from '@services/enums';
import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';
import { adRequestDelayFields } from '@components/game-settings/Resource/Shared';
import selectOptionsWithQuickLink from '@services/selectOptionsWithQuickLink';

const popupsOptions = [
  { label: 'GDPR', value: 'GDPR' },
  { label: 'Rate Us', value: 'Rate_Us' },
  { label: 'IDFA', value: 'IDFA' },
  { label: 'Push Notifications', value: 'Allow_Notifications' },
  { label: 'Google CMP', value: 'Google_CMP' },
  { label: 'Push notifications retry', value: 'PushNotifications_Retry_OptIn' },
];

const Fields = memoize(({
  freeCurrencies,
  localizationConfigOptions,
}) => ({
  ...fieldBuilder({ name: 'everythingJson', type: 'text', fieldOptions: { component: 'textarea' } }),
  ...fieldBuilder({ name: 'initCoinsBalance', type: 'number' }),
  ...fieldBuilder({ name: 'initGemsBalance', type: 'number' }),
  ...fieldBuilder({ name: 'energyTickInterval', type: 'number' }),
  ...fieldBuilder({ name: 'energyCapacity', type: 'number' }),
  ...fieldBuilder({ name: 'musicEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'soundEnabled', type: 'checkbox' }),
  ...fieldBuilder({ name: 'stickersVariable', type: 'number', step: '0.1' }),
  ...fieldBuilder({ name: 'cloudSaveTimeLogUpdate', type: 'number' }),
  ...fieldBuilder({ name: 'activeProducerResetTime', type: 'number' }),
  ...fieldBuilder({ name: 'totalCountOfOrders', type: 'number' }),
  ...fieldBuilder({ name: 'maxCountOfRandomOrders', type: 'number' }),
  ...fieldBuilder({ name: 'storyOrderCompletedNewOrderChance', type: 'number' }),
  ...fieldBuilder({ name: 'storyOrderCompletedPostcardCompletedNewOrderChance', type: 'number' }),
  ...fieldBuilder({ name: 'randomOrderCompletedNewOrderChance', type: 'number' }),
  ...fieldBuilder({ name: 'randomOrderCompletedPostcardCompletedNewOrderChance', type: 'number' }),
  ...fieldBuilder({ name: 'startSessionNewOrderChance', type: 'number' }),
  ...fieldBuilder({ name: 'startSessionPostcardCompletedNewOrderChance', type: 'number' }),
  ...fieldBuilder({ name: 'firstTodaySessionNewOrderChance', type: 'number' }),
  ...fieldBuilder({ name: 'firstTodaySessionPostcardCompletedNewOrderChance', type: 'number' }),
  ...fieldBuilder({ name: 'threeDimensionalPostcardTest', type: 'checkbox' }),
  ...fieldBuilder({ name: 'expertPrioritizationStoryOrderNumber', type: 'number' }),
  ...selectBuilder({ name: 'stickersCurrency', options: freeCurrencies }),
  ...selectBuilder({ name: 'localizationsConfigId', ...localizationConfigOptions }),
  ...arrayFieldBuilder({ name: 'sessionStartNumberForProducersRefresh' }),
  ...fieldGroupBuilder(
    {
      name: 'adRemoteSettings',
      labelText: 'Ads Settings',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'adsInterstitialEnabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'adsRewardedVideoEnabled', type: 'checkbox' }),
      },
    },
  ),
  ...fieldGroupBuilder(
    {
      name: 'defaultNotificationsSettings',
      labelText: 'Notifications',
      fieldsConfiguration: {
        ...fieldBuilder({ name: 'notificationsEnabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'energyRefreshedNotificationsEnabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'producerRefreshedNotificationsEnabled', type: 'checkbox' }),
        ...fieldBuilder({ name: 'chestOpenedNotificationsEnabled', type: 'checkbox' }),
      },
    },
  ),
}), (args) => JSON.stringify(args));

function fieldsFactory({ applicationId }) {
  const freeCurrencies = fromClassNameToOptions('Merge::FreeCurrencies');
  const localizationConfigOptions = selectOptionsWithQuickLink(LocalizationsRoutes.Configs, applicationId);

  return Fields({
    applicationId,
    freeCurrencies,
    localizationConfigOptions,
  });
}

export default combineWithSharedFieldsFactory([fieldsFactory, adRequestDelayFields], { popupsOptions });
