import React from 'react';
import { useQuery } from '@hooks';

import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';

import { WoodokuSagaRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function AbilityImpactsEdit() {
  const { response, setResponse } = useQuery(Routes.Abilities.Impacts.editRequest);

  const redirectIfSuccessHandler = createUpdateResponseHandlerFactory({
    entityName: 'Ability Impact Area',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.Abilities.Impacts.updateRequest(values).then(redirectIfSuccessHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      actionName="Update"
    />
  );
}
