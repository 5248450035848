import React, { useCallback } from 'react';
import styled from 'styled-components';
import { at, compact, sum } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  Field, FormButtonsGroup, Label, SelectField, ServerError, StaticFieldsFormGroup,
} from '@controls/form';
import { Form, useCurrentApplication } from '@hooks';
import { generateChapterBasedRoutes } from '@pages/routes';
import { PageHeader } from '@pages/common';

const SectionTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 15px;
`;

const LevelLayoutsForm = ({ data, onSubmit }) => {
  const { currentApplication } = useCurrentApplication();
  const { Banks: BankRoutes, Terrains: TerrainRoutes } = generateChapterBasedRoutes('match3d');

  const fetchUiAssetOptions = useCallback(async () => {
    const response = await TerrainRoutes.indexRequest({
      applicationId: currentApplication.id,
      withoutPagination: true,
    });
    return response.data.items.map((uiAsset) => ({
      label: `${uiAsset.name} (${uiAsset.key})`,
      value: uiAsset.id,
    }));
  }, []);
  const { contentHash, position, bank } = data;
  const bankUrl = BankRoutes.editPath({ applicationId: currentApplication.id, id: bank.id });

  return (
    <Form initialValues={{ ...data, bankId: data.bank.id, applicationId: currentApplication.id }} onSubmit={onSubmit}>
      {({ values }) => {
        const totalRatio = sum(compact(at(values, ['sizeRatioSmall', 'sizeRatioMedium', 'sizeRatioLarge'])));
        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Level Layout`}>
              <FormButtonsGroup cancelButtonPath={bankUrl} />
            </PageHeader>

            <Label text="Bank">
              <Link to={bankUrl}>{bank.name}</Link>
            </Label>
            <Label text="Name*">
              <Field name="name" />
            </Label>
            {contentHash && (
              <Label text="Hash">
                {contentHash}
              </Label>
            )}
            {position && (
              <Label text="Position in the bank">
                {position}
              </Label>
            )}
            <SectionTitle>
              Content:
            </SectionTitle>

            <StaticFieldsFormGroup data={data} />

            <Label text="Number of pairs">
              <Field disabled={bank.inUse} name="pairs" type="number" />
            </Label>
            <Label
              text={(
                <>
                  Size ratio
                  <ServerError name="totalSize" />
                </>
              )}
            >
              <Row>
                <Col xs={3}>
                  <Label text="Small" direction="vertical">
                    <Field disabled={bank.inUse} name="sizeRatioSmall" type="number" />
                  </Label>
                </Col>
                <Col xs={3}>
                  <Label text="Medium" direction="vertical">
                    <Field disabled={bank.inUse} name="sizeRatioMedium" type="number" />
                  </Label>
                </Col>
                <Col xs={3}>
                  <Label text="Large" direction="vertical">
                    <Field disabled={bank.inUse} name="sizeRatioLarge" type="number" />
                  </Label>
                </Col>
                <Col xs={3}>
                  <Label text="Total" direction="vertical">
                    <span className={totalRatio > 100 || totalRatio <= 0 ? 'text-danger' : null}>
                      {`${totalRatio}%`}
                    </span>
                  </Label>
                </Col>
              </Row>
            </Label>
            <Label
              text={(
                <>
                  Time
                  <ServerError name="time" />
                </>
              )}
            >
              <Row>
                <Col xs={3}>
                  <Label text="Min" direction="vertical">
                    <Field disabled={bank.inUse} name="timeMinutes" type="number" />
                  </Label>
                </Col>
                <Col xs={3}>
                  <Label text="Sec" direction="vertical">
                    <Field disabled={bank.inUse} name="timeSeconds" type="number" />
                  </Label>
                </Col>
              </Row>
            </Label>
            <Label text="Terrain">
              <SelectField
                isDisabled={bank.inUse}
                name="uiAssetId"
                options={fetchUiAssetOptions}
              />
            </Label>
          </>
        );
      }}
    </Form>
  );
};

export default LevelLayoutsForm;
