import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Alert } from '@tripledotstudios/react-core';

import { useFormContext, useFormGroup } from '@hooks';
import { SelectField, ServerError } from '@controls/form';
import { NestedConditions, variants } from '@components/nested-conditions';
import IconButton from '@controls/buttons';

import conditionsTypesOptions from './types';
import findFieldsFor from './fields/registry';

const renderConditionFields = (rootValues, type, disabled) => {
  if (!type) return null;

  const Fields = findFieldsFor(type);
  return <Fields rootValues={rootValues} disabled={disabled} />;
};

const StyledCol = styled(Col)`
  border-right: 1px solid ${({ theme }) => theme.bootstrap.hr};
`;

export default function Condition({
  rootValues, attributes, nestingLevel, disabled,
}) {
  const { dispatch } = useFormContext();
  const { generateName } = useFormGroup();

  const conditionDestroyPath = generateName('_destroy');
  const onConditionRemove = () => dispatch({ type: 'removeFrequencyRestrictionsCondition', conditionDestroyPath });

  return (
    <NestedConditions.Condition variant={variants[nestingLevel]}>
      <Row>
        <StyledCol sm={3}>
          <SelectField
            name="type"
            options={conditionsTypesOptions}
            hasPrepopulatedOption
            isDisabled={attributes.id || disabled}
          />
        </StyledCol>
        <StyledCol sm={8}>
          <ServerError name="id" as={Alert} />
          {renderConditionFields(rootValues, attributes.type, disabled)}
        </StyledCol>
        <Col sm={1}>
          <IconButton.Remove
            onClick={onConditionRemove}
            disabled={disabled}
            variant={variants[nestingLevel - 1]}
            opacity={0.7}
            minimized
          >
            Remove Condition
          </IconButton.Remove>
        </Col>
      </Row>
    </NestedConditions.Condition>
  );
}
