import React from 'react';

import {
  GameSettingsTabs,
  localizationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  analyticsDefaultFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
} from '@components/game-settings/Resource';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'numberOfSuits',
      'defaultSuitOneSuitGame',
      'undo',
      'unrestrictedUndo',
      'unrestrictedDeal',
      'cardsHighlighting',
      'tapToMove',
      'leftHanded',
      'lossTimeout',
      'sounds',
      'everythingJson',
      'playerLevelsSetId',
      'showHintCounter',
      'autoHints',
      'oneSuit',
      'twoSuits',
      'fourSuits',
      'hintButtonPulse',
      'worldAveragesShown',
      'dcWorldStatsConfigOneSuitId',
      'dcWorldStatsConfigTwoSuitsId',
      'dcWorldStatsConfigFourSuitsId',
      'spideretteSettings',
      'spideretteMode',
      'spideretteSeedsOneSuit',
      'spideretteSeedsTwoSuits',
      'spideretteSeedsFourSuits',
      'missionsEnabled',
      'tutorials',
      'defaultCardFrontId',
      'defaultCardBackId',
      'defaultTableId',
      'defaultAvatarId',
      ...assetsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#xp',
    title: 'XP',
    fieldsNames: [
      'xpGameWinSuit1',
      'xpGameWinSuit2',
      'xpGameWinSuit4',
      'xpDailyChallengeWin',
    ],
  },
  {
    eventKey: '#daily_challenges',
    title: 'Daily Challenges',
    fieldsNames: [
      'dcBubble',
      'dcBubbleStartDay',
    ],
  },
  {
    eventKey: '#mercy',
    title: 'Mercy Bank',
    fieldsNames: [
      'mercyMechanicToggle',
      'mercyGamesTrigger',
      'mercyLossPercentage',
      'mercyBankOneSuit',
      'mercyBankTwoSuit',
      'mercyBankFourSuit',
      'mercyBankOneSuitSpiderette',
      'mercyBankTwoSuitSpiderette',
      'mercyBankFourSuitSpiderette',
    ],
  },
  {
    eventKey: '#streak',
    title: 'Streak',
    fieldsNames: [
      'streak',
    ],
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: popupsDefaultFieldNames,
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      ...adPlacementIdsDefaultFieldNames,
      ...adConfigsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: analyticsDefaultFieldNames,
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: popupsDefaultArchivedFieldNames,
  },
];

export default function Tabs({
  setContractType, fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      className="mt-4"
      onChange={setContractType}
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
