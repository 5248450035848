import React from 'react';
import { Link } from 'react-router-dom';

import editPath from '@services/editPath';
import RulesList from '../RulesList';
import StatusBadge from '../StatusBadge';
import InLiveBadge from '../InLiveBadge';

const LabelRow = ({ text }) => (
  <tr>
    <td colSpan="5">
      <b>{text}</b>
    </td>
  </tr>
);

export default function Entities({
  label, collection, entityStatuses, fieldName, application, trProps = () => {},
}) {
  return collection && (
    <>
      <LabelRow text={label} />
      {collection.map((entity) => {
        const {
          name,
          priority,
          status,
          inLive,
          statusHumanize,
          ruleSectionsAttributes,
        } = entity;
        return (
          <tr {...trProps(entity)}>
            <td>
              <Link
                key={name}
                to={editPath({ ...entity.frontendIdentity, application, query: fieldName ? { focus: fieldName } : {} })}
                target="_blank"
              >
                {name}
              </Link>
            </td>
            <td>{priority}</td>
            <td>
              <StatusBadge
                options={entityStatuses}
                value={status}
                label={statusHumanize}
              />
            </td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td><InLiveBadge inLive={inLive} /></td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td><RulesList rules={ruleSectionsAttributes} /></td>
          </tr>
        );
      })}
    </>
  );
}
