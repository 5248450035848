import React from 'react';
import { Col } from 'react-bootstrap';

import { fromClassNameToOptions } from '@services/enums';
import { Filter, TextFilter, StatusFilter } from '@pages/common';

export default function JourneysFilter({ statuses }) {
  const statusOptions = fromClassNameToOptions('MyPetCafe::Adventures::EntityStatuses');

  const defaultStatuses = [
    statuses.ACTIVE,
    statuses.INACTIVE,
  ];

  return (
    <Filter defaultComponents={['availabilityStateIn', 'labels']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <StatusFilter
              name="statusIn"
              label="Status"
              updateFilterField={updateFilterField}
              options={statusOptions}
              defaults={defaultStatuses}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
