import React, { useCallback } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { MergeRoutes } from '@pages/routes';

import formReducer from './formReducer';
import Products from './form/Products';

const toHours = (hour) => hour * 60 * 60;
const resetTimeOptions = [
  { label: '12 AM', value: toHours(12) },
  { label: '1 PM', value: toHours(13) },
  { label: '2 PM', value: toHours(14) },
  { label: '3 PM', value: toHours(15) },
  { label: '4 PM', value: toHours(16) },
  { label: '5 PM', value: toHours(17) },
  { label: '6 PM', value: toHours(18) },
  { label: '7 PM', value: toHours(19) },
  { label: '8 PM', value: toHours(20) },
  { label: '9 PM', value: toHours(21) },
  { label: '10 PM', value: toHours(22) },
  { label: '11 PM', value: toHours(23) },
];

export default function BundleCatalogsForm({ data, onSubmit, actionName }) {
  const { query: { applicationId } } = useRouter();
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(applicationId), []);

  return (
    <Form initialValues={data} onSubmit={onSubmit} reducer={formReducer}>
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Bundle Catalog`}>
            <FormButtonsGroup
              cancelButtonPath={MergeRoutes.BundleCatalogs.indexPath({ applicationId })}
            />
          </PageHeader>

          <Label text="Name">
            <Field type="text" name="name" />
          </Label>
          <Label text="Description">
            <Field type="text" name="description" />
          </Label>
          <Label text="World Schema">
            <SelectField
              name="schemaId"
              options={fetchSchemaOptions}
              onChange={() => dispatch({ type: 'initialize_products' })}
              isDisabled={actionName !== 'Create'}
            />
          </Label>
          <Label text="Reset time after purchase">
            <SelectField
              name="resetTimeAfterPurchase"
              options={resetTimeOptions}
            />
          </Label>

          <StaticFieldsFormGroup data={data} />

          {values.schemaId
            && values.catalogProductsAttributes
            && <Products schemaId={values.schemaId} values={values.catalogProductsAttributes} />}
        </>
      )}
    </Form>
  );
}
