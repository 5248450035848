import { addUuid } from '@services/recursivelyProcessObject';
import appData from '@services/appData';

const rowOperators = appData.enums['Woodoku::TriggerConditions::RowOperators'];

export default function reducer(state, action) {
  switch (action.actionType) {
    case 'removeRow': {
      const { index } = action;

      state.rowsAttributes[index]._destroy = true;
      break;
    }
    case 'addRow': {
      state.rowsAttributes.push(addUuid({ _destroy: false, id: null, operator: rowOperators.GE }));
      break;
    }
    default: return state;
  }
  return state;
}
