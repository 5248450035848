import React, { useMemo } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import {
  useQuery,
  useConfirm,
  useCurrentApplication,
} from '@hooks';

import { PageHeader, Pagination, ReactTable } from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { FilesRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';
import Filter from './FilesFilter';
import columnsFactory from './columnsFactory';

const {
  indexRequest,
  newPath,
  editPath,
  deleteRequest,
} = FilesRoutes;

export default function Index() {
  const { response, refetch } = useQuery(indexRequest, { includeInUse: true });
  const { applicationKey: gameType } = useCurrentApplication();
  const { query: { applicationId } } = useRouter();

  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'File',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => {
    const title = `File "${name}" will be deleted and not available for work. Continue?`;
    const onConfirm = () => deleteRequest({ id, applicationId }).then(responseHandler);

    confirm.showConfirmation({ title }).then(onConfirm);
  };

  const columns = useMemo(() => columnsFactory({
    applicationId,
    editPath,
    gameType,
    onDelete,
  }), []);

  return response && (
    <div>
      <PageHeader
        title="Files"
        filter={<Filter typeOptions={response.typeOptions} />}
      >
        <IconButton.New to={newPath({ applicationId, gameType })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
