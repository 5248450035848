import React from 'react';

import { useFormContext } from '@hooks';
import {
  Field,
  localizedLabelFactory,
  TimePeriodField,
} from '@controls/form';
import { MyPetCafeRoutes } from '@pages/routes';

import LevelsListWrapper from '@components/journeys/banks/levels/LevelsListWrapper';

import Milestones from './Milestones';

const LocalizedLabel = localizedLabelFactory('myPetCafe.winStreaks.eventBanks.events.fields');

export default function Events({ events, isArchived, readOnly: bankReadOnly }) {
  const { dispatch } = useFormContext();
  const onEntityAdd = () => dispatch({
    actionType: 'addEmptyEntity',
    options: {
      milestonesAttributes: [],
      resetAfterLose: false,
      duration: 0,
      eventGap: 0,
    },
  });

  return (
    <LevelsListWrapper
      entityAttributes={events}
      onEntityAdd={onEntityAdd}
      attributesName="eventsAttributes"
      metaErrorsPath={['errors', 'eventsAttributes']}
      entityNameTranslationPath="winStreaks.eventBanks.levels.name"
      bulkCopyRoutes={MyPetCafeRoutes.WinStreaks.EventBanks}
      disabled={isArchived}
      readOnly={bankReadOnly}
    >
      {({ index, entity, readOnly }) => (
        <>
          <LocalizedLabel name="name">
            <Field type="text" name="name" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="duration">
            <TimePeriodField name="duration" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="eventGap">
            <TimePeriodField name="eventGap" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="resetAfterLose">
            <Field type="checkbox" name="resetAfterLose" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="assetKey">
            <Field type="text" name="assetKey" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="prefabKey">
            <Field type="text" name="prefabKey" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="titleLocalisationKey">
            <Field type="text" name="titleLocalisationKey" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="missionDescriptionLocalisationKey">
            <Field type="text" name="missionDescriptionLocalisationKey" disabled={readOnly} />
          </LocalizedLabel>
          <Milestones
            eventIndex={index}
            milestones={entity.milestonesAttributes}
            disabled={readOnly || isArchived}
          />
        </>
      )}
    </LevelsListWrapper>
  );
}
