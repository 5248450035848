import React, { useMemo } from 'react';

import { ArchivedSettingsRoutes } from '@pages/routes';
import {
  PageHeader, Pagination, ReactTable,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  useCurrentApplication, useQuery, useConfirm, useI18n,
} from '@hooks';
import IconButton from '@controls/buttons';

import ColumnsFactory from './ColumnsFactory';
import Filter from './Filter';

export default function Index() {
  const { applicationId } = useCurrentApplication();
  const { indexRequest, deleteRequest, newPath } = ArchivedSettingsRoutes;
  const { response, refetch } = useQuery(indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { translate } = useI18n();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Game Setting',
    actionName: 'reactivation',
    refetch,
  });

  const onReactivate = ({ id, name }) => {
    confirm.showConfirmation({
      title: 'Do you want to reactivate the setting?',
      body: `"${name}" will be moved back to its game settings tab in Game Settings(Base Settings and Overrides) as `
        + 'a disabled setting.',
    }).then(() => deleteRequest({ id, applicationId })
      .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({ applicationId, onReactivate, translate }), []);

  return response && (
    <div>
      <PageHeader title="Archived Settings" filter={<Filter />}>
        <IconButton.New to={newPath({ applicationId })}>Archive setting</IconButton.New>
      </PageHeader>
      <ReactTable
        columns={columns}
        data={response.items}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
