import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { AdsRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const { query: { applicationId } } = useRouter();
  const { indexPath, newRequest, createRequest } = AdsRoutes.Badges.Placements;
  const { response, setResponse } = useQuery(newRequest, { applicationId });

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Ad Badges Placement',
    actionName: 'create',
    redirectPath: indexPath({ applicationId }),
    setData: setResponse,
  });

  const onSubmit = (values) => (
    createRequest({ ...values, applicationId }).then(responseHandler)
  );

  return response && (
    <Form data={response} onSubmit={onSubmit} />
  );
}
