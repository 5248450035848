import React, { useState, useEffect } from 'react';
import { camelCase } from 'lodash';
import { Row, Col, Card } from 'react-bootstrap';
import { Alert } from '@tripledotstudios/react-core';
import styled from 'styled-components';

import { PageHeader, PageSection } from '@pages/common';
import { Form, FormGroup, useI18n } from '@hooks';
import {
  FormButtonsGroup, Label, Field, SelectField, CheckboxField, ImageFileField,
} from '@controls/form';
import { ApplicationRoutes } from '@pages/routes';
import { buildConverterFromIndexResponse } from '@services/toSelectOptions';
import RemoveIcon from '@components/merge/shared/icons/RemoveIcon';

import { JOURNEY_NOTIFICATION_APPLICATIONS } from './constants';

const JourneyApplications = [
  'Sudoku::Application',
  'Woodoku::Application',
  'Solitaire::Application',
  'Tile::Application',
  'Sortaga::Application',
  'BlockParty::Application',
];

const LevelsAndChaptersApplications = [
  'Tile::Application',
];

const AdsApplications = [
  'Blackjack::Application',
  'LpBingo::Application',
  'Match3d::Application',
  'Merge::Application',
  'PuzzleTime::Application',
  'Solitaire::Application',
  'SolitaireScapes::Application',
  'SpiderSolitaire::Application',
  'Sudoku::Application',
  'Tile::Application',
  'Woodoku::Application',
  'BlockParty::Application',
  'Sortaga::Application',
  'Reactor::Application',
  'SolitaireScapesCollection::Application',
  'WordHop::Application',
  'SolitaireCom::Application',
  'Hexago::Application',
  'Woo::Aspen::Application',
  'Taos::Application',
  'WoodokuSaga::Application',
  'Sol::Coro::Application',
  'Loray::Application',
  'Axum::Application',
  'MyPetCafe::Application',
  'Lusk::Application',
  'Voss::Application',
];

const FigureSetsApplications = [
  'Woodoku::Application',
  'WoodokuSaga::Application',
];

const DebugSlackApplications = [
  'Woodoku::Application',
  'Woo::Aspen::Application',
];

const GoogleGamesAuthApplications = [
  'Woodoku::Application',
  'Reactor::Application',
];

const SlackNotificationFormGroup = ({ name, label }) => (
  <FormGroup name={name}>
    <Label text={label}>
      <Field type="text" name="defaultUrl" />
    </Label>
  </FormGroup>
);

const StatusLabel = styled.div`
  ::before {
    content: '';
    display: inline-block;
    height: .65em;
    width: .65em;
    background: ${({ $enabled, theme }) => ($enabled ? theme.variants.success : theme.variants.danger)};
    border-radius: 50%;
    margin-right: .3em;
  }
`;

const IntegrationStatuses = ({ statuses }) => (
  <Row className="gy-0 gx-3">
    {statuses.map((status) => (
      <Col sm={12} md={6} lg={4} xxl={3}>
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>{status.name}</Card.Title>

            <Card.Text>
              <StatusLabel $enabled={status.enabled}>
                {status.enabled ? 'Enabled' : 'Disabled'}
              </StatusLabel>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    ))}
  </Row>
);

export default function ApplicationForm({ data, actionType, onSubmit }) {
  const [applicationsTypes, setApplicationsTypes] = useState();
  const [applicationType, setApplicationType] = useState(data.type);
  const { translate } = useI18n();
  const journeyEventName = translate.fallback('journeys.events.name');

  const defaultSlackNotifications = [
    { name: 'gameSettingsSlackIntegrationAttributes', label: 'Game Settings' },
    { name: 'abExperimentsSlackIntegrationAttributes', label: 'A/B Experiments' },
    { name: 'adsConfigurationsSlackIntegrationAttributes', label: 'Ads Configuration' },
    { name: 'appUpdatesSlackIntegrationAttributes', label: 'App Update' },
  ];

  if (JourneyApplications.includes(applicationType)) {
    defaultSlackNotifications.push({
      name: 'journeyEventsSlackIntegrationAttributes', label: 'Journey Events',
    });
  }

  if (LevelsAndChaptersApplications.includes(applicationType)) {
    defaultSlackNotifications.push({
      name: 'levelsAndChaptersSlackIntegrationAttributes', label: 'Levels & Chapters',
    });
  }

  if (FigureSetsApplications.includes(applicationType)) {
    defaultSlackNotifications.push({
      name: 'figureSetsSlackIntegrationAttributes', label: 'Figure Sets',
    });
  }

  if (JOURNEY_NOTIFICATION_APPLICATIONS.includes(applicationType)) {
    defaultSlackNotifications.push({
      name: 'journeyCheckSlackIntegrationAttributes',
      label: `No configured ${journeyEventName} Alert`,
    });
  }

  const buildVariantSpecificFields = (attributesName) => {
    if (data.id) return data[attributesName];

    const applicationTypeSpecificFields = data.applicationTypeSpecificFields.find(
      (object) => object.applicationType === applicationType,
    );
    return applicationTypeSpecificFields[attributesName];
  };

  const changeApplicationType = ({ value }) => {
    setApplicationType(value);
  };

  useEffect(() => {
    ApplicationRoutes.getTypesRequest().then((response) => {
      setApplicationsTypes(
        buildConverterFromIndexResponse({ valueAttribute: 'value' })(response),
      );
    });
  }, []);

  return applicationsTypes ? (
    <Form initialValues={data} onSubmit={onSubmit}>
      {({ setFieldValue }) => {
        const onEnableAdsChange = (event) => {
          const field = event.target;
          const newValue = field.value === 'false';
          setFieldValue(field.name, newValue);
          if (!newValue) {
            setFieldValue('enableAdsStatsCollection', false);
          }
        };
        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Application`}>
              <FormButtonsGroup cancelButtonPath="/admin/applications" />
            </PageHeader>

            <FormGroup>
              <Label text="Name" required><Field type="text" name="name" /></Label>
              <Label text="Type" required>
                <SelectField
                  name="type"
                  isDisabled={actionType === 'Update'}
                  options={applicationsTypes}
                  onChange={changeApplicationType}
                  includeEmpty
                />
              </Label>
              <Label text="Signature Key" required><Field type="text" name="signatureKey" /></Label>
              <Label text="Public App ID" required><Field type="text" name="publicAppId" /></Label>

              <PageSection title="Store IDs">
                {buildVariantSpecificFields('storeIdAttributes').map((config) => (
                  <Label text={config.label}>
                    <Field type="text" name={camelCase(config.name)} />
                  </Label>
                ))}
              </PageSection>

              <PageSection title="Feature Toggles">
                <Alert>Please only change these settings if you really know what you&apos;re doing</Alert>

                <Label text="Enable force updates">
                  <CheckboxField name="enableForceUpdates" />
                </Label>

                <Label text="Enable HAWK payments processing">
                  <CheckboxField name="enableLiveopsPayments" />
                </Label>

                <Label text="Use HAWK /all_products link">
                  <CheckboxField name="enableLiveopsProducts" />
                </Label>

                <Label text="Send transactions to Player Service">
                  <CheckboxField name="sendTransactionsToPlayerService" />
                </Label>

                <Label text="Send new user installs to Notifire">
                  <CheckboxField name="enableNotifireUserSync" />
                </Label>

                {AdsApplications.includes(applicationType) && (
                  <>
                    <Label
                      text="Enable Ads"
                      tooltipText="Disabling this will result in Ads not being served to client in /init response"
                    >
                      <CheckboxField
                        name="enableAds"
                        onChange={onEnableAdsChange}
                      />
                    </Label>
                    <Label
                      text="Collect Interstitial Events Requests for CPM floor"
                    >
                      <CheckboxField name="enableAdsStatsCollection" />
                    </Label>
                  </>
                )}
              </PageSection>

              <PageSection title="External services">
                {data.id && <IntegrationStatuses statuses={data.integrations} />}

                <PageSection title="Facebook">
                  <Label text="Facebook Secret">
                    <Field type="text" name="facebookSecret" placeholder={data.fbSecretPlaceholder} />
                  </Label>
                  <Label text="Facebook App ID">
                    <Field type="text" name="facebookAppId" />
                  </Label>
                </PageSection>

                <PageSection
                  title="Hawk LiveOps"
                  userGuideUrlKey="liveopsIntegrationGuideUrl"
                >
                  <Label text="Liveops Token">
                    <Field type="text" name="liveopsToken" placeholder={data.liveopsTokenPlaceholder} />
                  </Label>
                  <Label text="Products endpoint">
                    <Field name="productsEndpoint" />
                  </Label>
                </PageSection>

                <PageSection title="Player Service">
                  <Label text="Player Service Token">
                    <Field type="text" name="playerServiceToken" placeholder={data.playerServiceTokenPlaceholder} />
                  </Label>
                </PageSection>

                <PageSection title="Chartboost">
                  {buildVariantSpecificFields('chartboostAppIdAttributes').map((config) => (
                    <Label text={config.label}>
                      <Field type="text" name={camelCase(config.name)} />
                    </Label>
                  ))}
                </PageSection>

                <PageSection title="OneSignal">
                  {buildVariantSpecificFields('oneSignalApiKeyAttributes').map((config) => {
                    const name = camelCase(camelCase(config.name));

                    return (
                      <Label text={config.label}>
                        <Field
                          type="text"
                          name={name}
                          placeholder={data[`${name}Placeholder`]}
                        />
                      </Label>
                    );
                  })}

                  {buildVariantSpecificFields('oneSignalAppIdAttributes').map((config) => (
                    <Label text={config.label}>
                      <Field type="text" name={camelCase(config.name)} />
                    </Label>
                  ))}
                </PageSection>

                <PageSection title="Looker">
                  <Label text="Looker A/B name">
                    <Field type="text" name="lookerAbName" />
                  </Label>
                </PageSection>

                {GoogleGamesAuthApplications.includes(applicationType) && (
                  <PageSection title="Google Games Auth">
                    <Label text="Client ID">
                      <Field name="googleGamesClientId" />
                    </Label>
                    <Label text="Client Secret">
                      <Field name="googleGamesClientSecret" placeholder={data.googleGamesClientSecretPlaceholder} />
                    </Label>
                    <Label text="Redirect URI">
                      <Field name="googleGamesRedirectUri" />
                    </Label>
                  </PageSection>
                )}
              </PageSection>

              {(applicationType === 'Solitaire::Application') && (
                <PageSection title="External">
                  <Label
                    text="Game Insights S3 path"
                    tooltipText="May be nested, or just bucket name. Leave blank to disable insights synchronization"
                  >
                    <Field type="text" name="insightsDataPath" />
                  </Label>
                </PageSection>
              )}

              {(DebugSlackApplications.includes(applicationType)) && (
                <PageSection title="Debug slack notifications">
                  <Label text="Debug slack token">
                    <Field name="debugSlackToken" placeholder={data.debugSlackTokenPlaceholder} />
                  </Label>
                  <Label text="Debug slack channel">
                    <Field name="debugSlackChannel" />
                  </Label>
                </PageSection>
              )}

              <PageSection
                title="Slack Integration URLs"
                userGuideUrlKey="slackNotificationsGuideUrl"
              >
                {defaultSlackNotifications.map(({ name, label }) => (
                  <SlackNotificationFormGroup name={name} label={label} />
                ))}
              </PageSection>
              <PageSection title="SDK keys">
                <Label text="MAX mediation SDK key">
                  <Field type="text" name="maxMediationSdkKey" />
                </Label>
                <Label text="AppsFlyer dev key">
                  <Field type="text" name="appsflyerDevKey" />
                </Label>
                <Label text="Google AdMob iOS app id">
                  <Field type="text" name="googleAdmobIosAppId" />
                </Label>
                <Label text="Google AdMob Android app id">
                  <Field type="text" name="googleAdmobAndroidAppId" />
                </Label>
              </PageSection>
              <FormGroup name="appImageAttributes">
                <Label text="Game icon">
                  <ImageFileField name="image" accept="image/png" />
                  <RemoveIcon
                    onClick={() => {
                      setFieldValue('appImageAttributes._destroy', true);
                      setFieldValue('imageCdnUrl', null);
                    }}
                  />
                </Label>
              </FormGroup>
            </FormGroup>
          </>
        );
      }}
    </Form>
  ) : '';
}
