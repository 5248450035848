import React from 'react';
import { useTheme } from 'styled-components';
import { Sidebar } from '@tripledotstudios/react-core';
import { faListOl } from '@fortawesome/free-solid-svg-icons';

import { SolRoutes } from '@pages/routes';
import {
  ApplicationItemSet,
  AssetsNavigationItem,
  AdsNavigationSection,
} from '../common';

export default function Coro({ applicationId, routingScope, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="sol/coro"
      routingScope={routingScope}
      minimized={minimized}
    >
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />
      <Sidebar.SectionItem
        icon={faListOl}
        title="Mastery Level"
        color={theme.purple}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={SolRoutes.PlayerLevelSets.indexPath({ applicationId })}
          title="Player Levels Sets"
        />
      </Sidebar.SectionItem>

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
