import { isNull } from 'lodash';
import { addUuid } from '../recursivelyProcessObject';

export const appendDisableTypeChange = ({ id, ...rest }) => ({ ...rest, disableTypeChange: !isNull(id) });
export const recursivelyAppendDisableTypeChangeRemoveRuleIds = (sections) => sections.map((section) => (
  {
    ...section,
    operation: 0,
    rulesAttributes: section.rulesAttributes.map((rule) => addUuid(appendDisableTypeChange(rule))),
  }
));

export const recursivelyRemoveRuleIds = (sections) => sections.map(({
  id, ruleSectionsAttributes, rulesAttributes, ...rest
}) => (
  {
    ...rest,
    rulesAttributes: rulesAttributes.map(({ id: _ruleId, ...ruleRest }) => addUuid(ruleRest)),
    ruleSectionsAttributes: recursivelyRemoveRuleIds(ruleSectionsAttributes.map((rule) => addUuid((rule)))),
  }));
