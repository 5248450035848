import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { MissionsRoutes } from '@pages/routes';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { useCurrentApplication, useFormContext } from '@hooks';
import Rewards from './Rewards';

const APPLICATIONS_SUPPORTED_SEASON_REWARDS = ['my_pet_cafe'];

export default function RewardsConfiguration({
  missionIndex,
  mission,
  selectionTypes,
  selectionTypeOptions,
}) {
  const [rewardTypeOptions, setRewardTypeOptions] = useState([]);
  const { values: { mpcConfigAttributes } } = useFormContext();
  const { applicationKey } = useCurrentApplication();
  const { query: { applicationId } } = useRouter();

  const supportSeasonRewards = APPLICATIONS_SUPPORTED_SEASON_REWARDS.includes(applicationKey);

  const adjustRewardOptions = ({ data: { items } }) => (
    items.map(({ id, rewardType, name }) => (
      {
        value: id,
        label: `${name} (${rewardType})`,
      }
    ))
  );

  useEffect(() => {
    requestWithToSelectOptions(
      MissionsRoutes.Rewards.Types.indexRequest,
      applicationId,
      {},
      { toSelectOptionsFn: adjustRewardOptions },
    ).then((options) => setRewardTypeOptions(options));
  }, []);

  return (
    <>
      <Rewards
        name="rewardsAttributes"
        label="Rewards"
        rewards={mission.rewardsAttributes}
        rewardTypeOptions={rewardTypeOptions}
        selectionTypeOptions={selectionTypeOptions}
        selectionTypes={selectionTypes}
        missionIndex={missionIndex}
      />
      {supportSeasonRewards && (
        <Rewards
          name="mpcSeasonRewardsAttributes"
          label="Season Rewards"
          rewards={mission.mpcSeasonRewardsAttributes}
          rewardTypeOptions={mpcConfigAttributes.rewardSetId ? rewardTypeOptions : []}
          selectionTypeOptions={selectionTypeOptions}
          selectionTypes={selectionTypes}
          missionIndex={missionIndex}
        />
      )}
    </>
  );
}
