/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { SelectField, Field, ServerError } from '@controls/form';
import Tooltip from '@controls/Tooltip';

import APP_DATA from '@services/appData';
import { fromClassNamesToOptions } from '@services/enums';

import { FormGroup, useFormContext } from '@hooks';
import { AddIcon, RemoveIcon } from '@components/merge/shared/icons';

const ruleTypesEnum = APP_DATA.enums['Merge::BundleRuleTypes'];
const { modelsLocales: { merge: { products: { tooltips } } } } = APP_DATA;

export default function BundleRules({ values, bundles, postcards }) {
  const { dispatch } = useFormContext();
  const [ruleTypes, specificBundlesRuleOperations] = fromClassNamesToOptions(['Merge::BundleRuleTypes',
    'Merge::BundlesPurchasesSpecificBundlesRuleOperations']);

  const RuleFields = {
    [ruleTypesEnum.BUNDLES_PURCHASES_SPECIFIC_BUNDLES]: () => (
      <>
        <SelectField name="value" options={bundles} isMulti placeholder="Bundles" />
        <Row className="mt-2">
          <Col xs={11}>
            <SelectField name="operation" options={specificBundlesRuleOperations} hasPrepopulatedOption />
          </Col>
          <Col xs={1} className="pt-2 ps-0">
            <Tooltip
              placement="bottom"
              text={tooltips.bundleSpecificRuleOperations}
            />
          </Col>
        </Row>
      </>
    ),
    [ruleTypesEnum.CURRENT_POSTCARD]: () => (
      <Row>
        <Col xs={6} className="pe-0">
          <SelectField name="value.from" options={postcards} placeholder="From" />
        </Col>
        <Col xs={6} className="pe-0">
          <SelectField name="value.to" options={postcards} placeholder="To" />
        </Col>
      </Row>
    ),
    default: () => (
      <Row>
        <Col xs={6} className="pe-0">
          <Field name="value.from" type="number" placeholder="From" />
        </Col>
        <Col xs={6} className="pe-0">
          <Field name="value.to" type="number" placeholder="To" />
        </Col>
      </Row>
    ),
  };

  const onRewardAdd = () => dispatch({ type: 'add_bundle_rule' });
  const onRewardDelete = (index) => dispatch({ type: 'remove_bundle_rule', index });

  return (
    <Row className="mb-3">
      <Col xs={4}>
        <b>Rules</b>
      </Col>
      <Col xs={8}>
        <ServerError name="rulesErrors" className="mb-2" />

        {values.map((rule, index) => {
          const fieldsComponent = rule.type && (RuleFields[rule.type] || RuleFields.default);

          return (
            <FormGroup key={rule._uuid} name={`rules[${index}]`}>
              <Row key={rule._uuid} className="mb-2 d-flex align-items-center">
                <Col xs={5} className="pe-0">
                  <SelectField
                    name="type"
                    options={ruleTypes}
                    onChange={() => dispatch({ type: 'change_bundle_product_rule_type', index })}
                  />
                </Col>
                <Col xs={6} className="pe-0">
                  {rule.type && fieldsComponent()}
                </Col>
                <Col xs={1}>
                  <RemoveIcon onClick={() => onRewardDelete(index)} />
                </Col>
              </Row>
            </FormGroup>
          );
        })}

        <AddIcon onClick={onRewardAdd} />
      </Col>
    </Row>
  );
}
