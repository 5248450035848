import React from 'react';
import { WoodokuRoutes } from '@pages/routes';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useI18n } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import Form from './Form';

export default function Edit() {
  const { query: { id, applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(WoodokuRoutes.HardJourneys.editRequest, { id });
  const { translate } = useI18n();

  const entityName = translate.fallback('woodoku.hardJourney.name');

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName,
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => WoodokuRoutes.HardJourneys.updateRequest({ ...values, applicationId })
    .then(responseHandler);

  if (isLoading) return <Spinner />;

  return response && (
    <Form
      data={response}
      title={`Edit ${entityName}`}
      entityName={entityName}
      onSubmit={onSubmit}
    />
  );
}
