import React from 'react';
import { Button } from 'react-bootstrap';

import { FormGroup, useFormContext } from '@hooks';
import { Field, SelectField } from '@controls/form';

export default function Entity({
  index, figureSetsOptions, _destroy,
}) {
  const { dispatch } = useFormContext();

  const handleDelete = (_e, deleteAt) => {
    dispatch({ actionType: 'removeEntity', deleteAt });
  };

  return (
    _destroy !== true
      ? (
        <tr>
          <FormGroup name={`dynamicConfigEntitiesAttributes[${index}]`}>
            <td>
              <Field type="text" name="name" />
            </td>
            <td>
              <Field type="number" name="difficulty" />
            </td>
            <td>
              <SelectField
                name="figureSetId"
                options={figureSetsOptions}
              />
            </td>
            <td>
              <Button
                variant="danger"
                onClick={(e) => handleDelete(e, index)}
                className="mt-0 mb-0 pull-end"
              >
                Delete
              </Button>
            </td>
          </FormGroup>
        </tr>
      )
      : ''
  );
}
