import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import {
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  RulesList,
  StatusBadge,
  PriorityColumn,
} from '@pages/common';
import IconButton from '@controls/buttons';

const statuses = APP_DATA.enums['Streaks::Statuses'];

export default function ColumnsFactory({
  applicationId, editPath, setDuplicatedRecord, onArchive,
}) {
  return [
    { Header: 'Id', accessor: 'id', style: { width: 45 } },
    { Header: 'Variant ID', accessor: 'defaultVariantId', style: { width: 45 } },
    { Header: 'Name', accessor: 'name' },
    {
      Header: 'Status',
      style: { width: 90 },
      Cell: ({ row }) => {
        const { status, statusHumanize } = row.original;
        return (
          <StatusBadge
            options={statuses}
            value={status}
            label={statusHumanize}
          />
        );
      },
    },
    InLiveColumn,
    PriorityColumn,
    { Header: 'Duration', accessor: 'duration', style: { width: 90 } },
    { Header: 'Streak Action', accessor: 'streakActionHumanize', style: { width: 150 } },
    {
      Header: 'Rules',
      style: { width: '20%' },
      Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
    },
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      style: { width: 120 },
      Cell: ({ row: { original: { id, name, status } } }) => (
        <ButtonToolbar>
          <IconButton.Edit to={editPath({ id, applicationId })} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />

          {status !== statuses.ARCHIVED && (
            <IconButton.Archive onClick={onArchive(id, name)} minimized />
          )}
        </ButtonToolbar>
      ),
    },
  ];
}
