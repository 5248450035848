import React from 'react';

import { InUseCollapse } from '@pages/common/InUse';

const InUseColumn = {
  Header: 'In Use',
  Cell: ({ row: { original: { inUse } } }) => <InUseCollapse inUse={inUse} />,
};

export default InUseColumn;
