import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from 'react';

import { get } from 'lodash';
import { LocalizationsRoutes } from '@pages/routes';
import { useRouter } from '@tripledotstudios/react-core';

const LanguagesContext = createContext();

export function LanguagesProvider({ children }) {
  const [filteredLanguages, setFilteredLanguages] = useState();
  const [allLanguages, setAllLanguages] = useState();

  const router = useRouter();
  const { query: { applicationId, filter } } = router;
  const codeIContAny = get(filter, 'codeIContAny');

  const fetchFilteredLanguages = () => {
    const params = {
      ...{ filter: { codeIContAny }, applicationId },
      withoutPagination: true,
    };

    LocalizationsRoutes.Languages
      .indexRequest(params)
      .then((response) => setFilteredLanguages(response.data.items));
  };

  const fetchAllLanguages = () => (
    LocalizationsRoutes.Languages
      .indexRequest({ applicationId, withoutPagination: true })
      .then((response) => setAllLanguages(response.data.items))
  );

  useEffect(() => {
    fetchAllLanguages();
  }, []);

  useEffect(() => {
    fetchFilteredLanguages();
  }, [codeIContAny]);

  const languagesBag = useMemo(() => ({
    filteredLanguages,
    allLanguages,
  }), [filteredLanguages, allLanguages]);

  return (
    <LanguagesContext.Provider value={languagesBag}>
      {children}
    </LanguagesContext.Provider>
  );
}

export function useLanguagesContext() {
  return useContext(LanguagesContext);
}
