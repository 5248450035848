import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { FormGroup, useI18n } from '@hooks';
import {
  Label,
  Field,
  ServerError,
  CheckboxField,
} from '@controls/form';
import { PageSection } from '@pages/common';

const COST_CONFIGURATION_FIELD_BASE_NAMES = [
  'entryCost',
  'inGamePlayon',
  'inGameUndo',
  'inGameWildcard',
  'preGameCardcut',
  'preGameWhirlwind',
  'preGameExtraWildcard',
  'passiveFreeRound',
  'passiveDoubleWin',
];

const REWARD_CONFIGURATION_FIELD_BASE_NAMES = [
  'bulbs',
  'stars',
  'tapBonusCoins',
  'streakBonusCoins',
  'remainingCardCoins',
  'streakBonusWildcard',
  'streakBonusExtracard',
  'leaderboardPoints',
  'soloMissionPoints',
  'winStreakPoints',
];

const ToggleableInput = ({
  name, index, fieldLabel, mode,
}) => (
  <div className="mb-3">
    <Row>
      <Col xs={3}>
        <CheckboxField
          name={`${name}Enabled`}
          id={`${index}.${name}`}
        />
        <label htmlFor={`${index}.${name}`}>
          {fieldLabel}
        </label>
      </Col>
      <Col xs={4}>
        <Field
          type="number"
          name={`${name}Multiplier`}
          inline={false}
          disabled={!mode[`${name}Enabled`]}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <ServerError name={`${name}Multiplier`} />
      </Col>
    </Row>
  </div>
);

export default function Mode({ mode, index, expanded }) {
  const { translate } = useI18n();

  return expanded && (
    <FormGroup name={`modesAttributes.${index}`}>
      <Label text="Unlock level">
        <Field name="unlockLevel" type="number" />
      </Label>
      <PageSection title="Cost configurations" />
      <Row>
        <Col xs={3}>
          Cost type
        </Col>
        <Col xs={4}>
          Multiplier
        </Col>
      </Row>
      {COST_CONFIGURATION_FIELD_BASE_NAMES.map((fieldName) => (
        <ToggleableInput
          key={`${mode._uuid}_${fieldName}`}
          name={fieldName}
          index={index}
          fieldLabel={translate.strict(`betUpConfiguration.modes.fieldNames.${fieldName}`)}
          mode={mode}
        />
      ))}
      <PageSection title="Reward configurations" />
      <Row>
        <Col xs={3}>
          Reward type
        </Col>
        <Col xs={4}>
          Multiplier
        </Col>
      </Row>
      {REWARD_CONFIGURATION_FIELD_BASE_NAMES.map((fieldName) => (
        <ToggleableInput
          key={`${mode._uuid}_${fieldName}`}
          name={fieldName}
          index={index}
          fieldLabel={translate.strict(`betUpConfiguration.modes.fieldNames.${fieldName}`)}
          mode={mode}
        />
      ))}
      <ServerError name="entity" />
    </FormGroup>
  );
}
