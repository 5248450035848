import React from 'react';
import { cloneDeep, isEqual, omit } from 'lodash';

import { JourneyRoutes, JourneyRoutes as Routes } from '@pages/routes';
import { clearUuids } from '@services/recursivelyProcessObject';
import APP_DATA from '@services/appData';

import {
  Form,
  FormGroup,
  useCurrentApplication,
  useConfirm,
} from '@hooks';

import { fromClassNameToOptions } from '@services/enums';

import {
  Label,
  Field,
  SelectField,
  FormButtonsGroup,
  PriorityField,
  StaticFieldsFormGroup,
  LabelsFormGroup,
} from '@controls/form';

import { DuplicateButton, PageHeader } from '@pages/common';
import RulesForm from '@pages/rules/Form';
import IconButton from '@controls/buttons';

import { combineReducers, rulesReducer, rewardableReducer } from '@reducers';
import buildOnSubmit from '@services/journeys/banks/buildOnSubmit';

import formReducer from './formReducer';

import APP_UTILS from './app-utils';
import APP_SPECIFIC_CONFIGS from './configuration-forms';
import APP_REDUCERS from './app-reducers';

export default function LevelBanksForm({
  data, title, onSubmit: onPageSubmit, entityName,
}) {
  const { applicationKey, currentApplication: { id: applicationId } } = useCurrentApplication();
  const { enums } = APP_DATA;
  const statusesEnum = enums['Journeys::LevelBanks::BankStatuses'];
  const statuses = fromClassNameToOptions('Journeys::LevelBanks::BankStatuses');
  const confirm = useConfirm();

  const { status, readOnly = null, isStatusEditingDenied = null } = data;

  const isArchived = status === statusesEnum.ARCHIVED;

  const AppSpecificConfiguration = APP_SPECIFIC_CONFIGS[applicationKey];

  const reducer = combineReducers([
    rulesReducer, formReducer, rewardableReducer, APP_REDUCERS[applicationKey],
  ].filter((r) => r));

  const onFormSubmit = buildOnSubmit({
    applicationId,
    data,
    entityName,
    statusesEnum,
    onSubmitFunction: onPageSubmit,
    confirm,
    routes: Routes.LevelBanks,
  });

  const {
    onSubmit = (values) => onFormSubmit(values), preprocessData = (values) => values,
  } = APP_UTILS[applicationKey] || {};

  const markDirtyLevels = (values) => {
    const base = { ...values, applicationId };

    if (!Object.prototype.hasOwnProperty.call(values.configAttributes, 'levelsAttributes')) return base;

    const originalLevels = clearUuids(data.configAttributes.levelsAttributes).reduce(
      (acc, level) => ({ ...acc, [level.id]: omit(level, 'number') }),
      {},
    );
    const levelsAttributes = clearUuids(cloneDeep(values.configAttributes.levelsAttributes)).map((level) => {
      if (!level.id || !isEqual(originalLevels[level.id], omit(level, 'number'))) {
        return { ...level, dirty: true };
      }
      return level;
    });
    return {
      ...base,
      configAttributes: { ...values.configAttributes, levelsAttributes },
    };
  };

  return (
    <Form
      initialValues={preprocessData(data)}
      onSubmit={(values) => onSubmit(markDirtyLevels(values), onFormSubmit, confirm)}
      reducer={reducer}
      sharedInputProps={{ disabled: readOnly || isArchived }}
    >
      {({ values }) => (
        <>
          <PageHeader title={title}>
            <FormButtonsGroup
              cancelButtonPath={Routes.LevelBanks.indexPath({ applicationId })}
            >
              {data.id && (
                <>
                  <IconButton.ActivityLog
                    applicationId={applicationId}
                    id={data.id}
                    entityType="Journeys::LevelBanks::Bank"
                  />
                  <FormButtonsGroup.Divider />
                </>
              )}
              <DuplicateButton
                routes={JourneyRoutes.LevelBanks}
                data={data}
                entityName={entityName}
                modalType="withPriority"
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label required text="Name">
            <Field type="text" name="name" disabled={readOnly} />
          </Label>
          <Label required text="Status">
            <SelectField
              name="status"
              options={statuses}
              isDisabled={isStatusEditingDenied || readOnly || isArchived}
            />
          </Label>
          <Label required text="Priority">
            <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} />
          </Label>

          <LabelsFormGroup disabled={readOnly} />

          <StaticFieldsFormGroup data={data} />

          <FormGroup name="configAttributes" className="mb-4">
            <AppSpecificConfiguration values={values} isArchived={isArchived} readOnly={readOnly} />
          </FormGroup>

          <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} disabled={readOnly || isArchived} />
        </>
      )}
    </Form>
  );
}
