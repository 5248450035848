import React from 'react';

import { Label, CheckboxField } from '@controls/form';

export default function SolitaireTesterSettingsFields() {
  return (
    <Label text="Allow edit Journey Level Banks in use">
      <CheckboxField name="editInUseJourneyLevelBanks" />
    </Label>
  );
}
