import camelize from 'camelcase-keys';

export default function allOperations(RawRuleOperations) {
  return (
    Object.values(RawRuleOperations).map(
      ({ translation, value, ...rest }) => (
        { label: translation.toUpperCase(), value, ...camelize(rest, { deep: true }) }
      ),
    )
  );
}
