import React from 'react';

import BaseRule from '../collection/BaseRule';

export default function ChildRules({
  nestingLevel,
  rulesAttributes,
  hideControls,
  excludeTags,
  disabled,
}) {
  return (
    <>
      {rulesAttributes.map((attributes, index) => (
        <BaseRule
          key={`${attributes._uuid}`}
          newRecord={!attributes.id}
          type={attributes.type}
          nestingLevel={nestingLevel}
          componentIndex={index}
          hideControls={hideControls}
          excludeTags={excludeTags}
          disabled={disabled}
          _destroy={attributes._destroy}
        />
      ))}
    </>
  );
}
