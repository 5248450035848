import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { FormGroup } from '@hooks';
import { SelectField } from '@controls/form';
import IconButton from '@controls/buttons';
import RemoveButton from '@pages/common/RemoveButton';

const FeatureToUnlock = ({
  record,
  options,
}) => {
  const {
    path, _uuid, configAttributes, dispatch,
  } = record;

  const add = (uuid) => () => {
    dispatch({
      type: 'addConfigAttributes',
      uuid,
    });
  };
  const remove = (uuid) => () => dispatch({
    type: 'removeConfigAttributes',
    uuid,
  });

  return (
    <div>
      {configAttributes?.featureToUnlock
        ? (
          <FormGroup name={`${path}.configAttributes`}>
            <Row>
              <Col xs={9}>
                <SelectField
                  name="featureToUnlock"
                  isMulti
                  options={options}
                  path={`${path}.configAttributes`}
                />
              </Col>
              <Col xs={3}>
                <RemoveButton
                  onClick={remove(_uuid)}
                  title="Delete the feature"
                />
              </Col>
            </Row>
          </FormGroup>
        )
        : (
          <IconButton.New onClick={add(_uuid)}>
            Add Feature
          </IconButton.New>
        )}
    </div>
  );
};

export default FeatureToUnlock;
