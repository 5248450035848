import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Spinner } from '@tripledotstudios/react-core';

import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { RemoveButton, DndIcon, PageSection } from '@pages/common';
import { useFormContext, FormGroup } from '@hooks';
import { SelectField, ServerError } from '@controls/form';
import { FigureSetsRoutes } from '@pages/routes';

export default function FigureSets({ values, disabled }) {
  const [figureSetOptions, setFigureSetOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { dispatch, meta } = useFormContext();

  const { applicationId, figureSetAffiliationsAttributes } = values;

  useEffect(() => {
    setLoading(true);

    requestWithToSelectOptions(FigureSetsRoutes.Sets.indexRequest, applicationId)
      .then((result) => {
        setLoading(false);
        setFigureSetOptions(result);
      });
  }, []);

  const onFigureSetAdd = () => dispatch({ actionType: 'addFigureSet' });
  const onFigureSetRemove = (uuid) => !disabled && dispatch({ actionType: 'removeFigureSet', uuid });
  const onFigureSetReorder = ({ source, destination }) => !disabled && dispatch({
    actionType: 'reorderFigureSet',
    sourceIndex: source.index,
    destinationIndex: destination.index,
    metaErrorsPath: ['errors', 'figureSetAffiliationsAttributes'],
    meta,
  });

  if (loading) return <Spinner />;

  return (
    <>
      <PageSection title="Figure Sets" />
      <ServerError name="figureSetAffiliationsAttributes.list" />
      <DragDropContext onDragEnd={onFigureSetReorder}>
        <Droppable droppableId="bet-up-mode-droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <FormGroup name="figureSetAffiliationsAttributes">
                <Row className="text-center mt-4">
                  <Col xs={1}><strong>Order</strong></Col>
                  <Col xs={10}><strong>Figure Set</strong></Col>
                  <Col xs={1}><strong>Actions</strong></Col>
                </Row>
                {React.Children.toArray(figureSetAffiliationsAttributes.map(({
                  id, _uuid, position, _destroy,
                }, index) => (
                  !_destroy && (
                    <Draggable key={_uuid} draggableId={_uuid} index={index} isDragDisabled={disabled}>
                      {(draggableProvided) => (
                        <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} className="mt-2">
                          <DndIcon {...draggableProvided.dragHandleProps} disabled={disabled} />
                          <FormGroup name={index}>
                            <Row>
                              <Col xs={1} className="text-center pt-2">{position + 1}</Col>
                              <Col xs={10}>
                                <SelectField
                                  name="figureSetId"
                                  options={figureSetOptions}
                                  isDisabled={id && disabled}
                                />
                              </Col>
                              <Col xs={1} className="text-center pt-2">
                                <RemoveButton onClick={() => onFigureSetRemove(_uuid)} disabled={disabled} />
                              </Col>
                            </Row>
                          </FormGroup>
                          <hr />
                        </div>
                      )}
                    </Draggable>
                  )
                )))}
                {provided.placeholder}
              </FormGroup>
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="text-end mt-4">
        <Button onClick={onFigureSetAdd}>Add</Button>
      </div>
    </>
  );
}
