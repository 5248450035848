import { ServerError } from '@controls/form';
import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { FormGroup } from '@hooks';

import Th from '@components/merge/shared/Th';
import { MergeRoutes } from '@pages/routes';
import PostcardGraphModal from '@components/merge/shared/PostcardGraphModal';

import Postcard from './Postcard';

export default function Postcards({
  values, schemaId, setPostcardConfiguration, postcardConfigurationsIndex,
}) {
  const { query } = useRouter();
  const { applicationId } = query;
  const [viewPostcard, setViewPostcard] = useState();

  const [items, setItems] = useState();
  const [experts, setExperts] = useState();
  const [postcardConfigurations, setPostcardConfigurations] = useState();

  const handleViewPostcard = (schemaPostcardId) => {
    MergeRoutes.WorldSchemas.editRequest({ id: schemaId, applicationId }).then((response) => {
      setViewPostcard(response.data.postcards.find((schemaPostcard) => schemaPostcard.id === schemaPostcardId));
    });
  };

  useEffect(() => {
    MergeRoutes.WorldSchemas.editRequest({ id: schemaId, applicationId }).then((response) => {
      setItems(response.data.items);
      setExperts(response.data.experts);
    });
    MergeRoutes.PostcardConfigurations.indexRequest({
      schemaId, applicationId, withoutPagination: true,
    }).then((response) => {
      setPostcardConfigurations(response.data.items);
    });
  }, [schemaId]);

  return (
    <>
      <ServerError name="postcardSetPostcardsAttributes.list" />
      <Table>
        <thead>
          <tr>
            <th>Position</th>
            <th>Schema Postcard</th>
            <th>Postcard Configuration</th>
            <th>Expert unlocked</th>
            <th>Lines required</th>
            <Th width="20%">Rewards Summary</Th>
            <Th width="10%">Level to unlock</Th>
            <Th />
          </tr>
        </thead>
        <tbody>
          {items && experts && postcardConfigurations && values.map((postcardSetPostcard, index) => (
            <FormGroup key={postcardSetPostcard._uuid} name={`postcardSetPostcardsAttributes[${index}]`}>
              <Postcard
                index={index}
                postcardSetPostcard={postcardSetPostcard}
                postcardConfigurations={postcardConfigurations}
                experts={experts}
                items={items}
                handleViewPostcard={handleViewPostcard}
                setPostcardConfiguration={setPostcardConfiguration}
                postcardConfigurationsIndex={postcardConfigurationsIndex}
              />
            </FormGroup>
          ))}
        </tbody>
      </Table>
      {viewPostcard && (
        <PostcardGraphModal
          internalId={viewPostcard.internalId}
          onHide={() => setViewPostcard(null)}
        />
      )}
    </>
  );
}
