import React, { useState, useMemo } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { SolRoutes } from '@pages/routes';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import {
  PageHeader,
  Pagination,
  ReactTable,
  DuplicationModal,
  TIMESTAMP_COLUMNS,
  InUseColumn,
  Filter,
  InLiveColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

const columnsFactory = ({
  applicationId, setDuplicatedRecord, onDelete,
}) => ([
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  InUseColumn,
  InLiveColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({
      row: {
        original: {
          id, type, name, inUse,
        },
      },
    }) => {
      const disabled = isInUse(inUse);

      return (
        <ButtonToolbar>
          <IconButton.Edit to={SolRoutes.CategorySets.editPath({ id, applicationId })} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, type, name })} minimized />
          <IconButton.Delete
            onClick={() => onDelete(id, name)}
            disabled={disabled}
            title={disabled ? 'Disabled as the entity is used' : null}
            minimized
          />
        </ButtonToolbar>
      );
    },
  },
]);

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(SolRoutes.CategorySets.indexRequest, { includeInUse: true });
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();

  const deleteResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Category Set',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => {
    confirm.showConfirmation({
      title: `'${name}' Category Set will be deleted and not available for work. Continue?`,
    })
      .then(() => SolRoutes.CategorySets.deleteRequest({ id, applicationId })
        .then(deleteResponseHandler));
  };

  const onDuplicate = (values) => SolRoutes.CategorySets.duplicateRequest({ ...values, applicationId });

  const columns = useMemo(() => columnsFactory({
    applicationId, setDuplicatedRecord, onDelete,
  }), []);

  return response && (
    <>
      <PageHeader
        title="Category Sets"
        filter={<Filter defaultComponents={['availabilityStateIn', 'createdByMe', 'name', 'id']} />}
      >
        <IconButton.New to={SolRoutes.CategorySets.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Category Set"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={onDuplicate}
        title="Duplicate Category Set"
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </>
  );
}
