import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { Link } from 'react-router-dom';

import { useQuery } from '@hooks';
import { TileRoutes } from '@pages/routes';
import AsyncFileProcessingForm from '@components/shared/AsyncFileProcessingForm';
import {
  Field, Label, SelectField,
} from '@controls/form';

function FormFields() {
  const { query: { applicationId } } = useRouter();
  const { response: chapterSetOptions } = useQuery({
    request: TileRoutes.ChapterSets.indexRequest,
    queryKey: ['chapterSetOptions', applicationId],
    toSelectOptions: true,
  });

  return (
    <>
      <Label text="Name">
        <Field type="text" name="name" />
      </Label>
      <Label text="Chapter Set">
        <SelectField name="chapterSetId" options={chapterSetOptions} />
      </Label>
    </>
  );
}

function Result({ values }) {
  const { query: { applicationId } } = useRouter();

  return values.resultChapterSetId && (
    <Label text="Result">
      <Link to={TileRoutes.ChapterSets.editPath({ id: values.resultChapterSetId, applicationId })}>
        {values.name}
      </Link>
    </Label>
  );
}

export default function ImportForm({
  onSubmit, data, disabled, refetch,
}) {
  const { query: { applicationId } } = useRouter();

  return (
    <AsyncFileProcessingForm
      data={data}
      onSubmit={onSubmit}
      disabled={disabled}
      refetch={refetch}
      title="Create Chapter Set from CSV"
      cancelPath={TileRoutes.ChapterSets.indexPath({ applicationId })}
      FormFields={FormFields}
      Result={Result}
      accept="text/csv"
      websocketsPath={`chapter_set_import_log_${data.id}`}
    />
  );
}
