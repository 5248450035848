import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import IconButton from '@controls/buttons';

export default function CancelButton({ to = null, text }) {
  const { push } = useRouter();
  const onClick = () => (to ? push(to) : push(-1));

  return <IconButton.Cancel onClick={onClick} outline>{text || 'Cancel'}</IconButton.Cancel>;
}
