import React, { useState } from 'react';

import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FaIcon } from '@pages/common/FaButton';

import CellModal from './CellModal';

const Cell = React.memo(({ cell, items, index }) => {
  const [editMode, setEditMode] = useState(false);
  const startEditing = () => setEditMode(true);
  const stopEditing = () => setEditMode(false);
  const itemName = (items.find((item) => item.value === cell.schemaItemId) || {}).label;

  return (
    <td onDoubleClick={startEditing} className={cell.cellLocked ? 'table-secondary' : ''}>
      <div>{cell.position}</div>
      <div className="mt-1 d-flex justify-content-center">
        <div>{itemName}</div>
        <div>{cell.itemLocked ? <FaIcon className="ms-2" icon={faLock} /> : ''}</div>
      </div>
      <CellModal show={editMode} cell={cell} stopEditing={stopEditing} items={items} index={index} />
    </td>
  );
});

export default Cell;
