import React, { useMemo } from 'react';

import Cell from './Cell';

export default function Translations({ translationsAttributes, oldValues }) {
  return (
    useMemo(() => (
      translationsAttributes.map(({ value, _uuid }, translationIndex) => (
        <Cell
          key={_uuid}
          name={`translationsAttributes${translationIndex}Value`}
          newValue={value}
          oldValues={oldValues}
        />
      ))
    ), [])
  );
}
