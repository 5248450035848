import React, { useEffect } from 'react';

import { Form, useCurrentApplication } from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader, PageSection } from '@pages/common';
import CoinsReward from '@pages/payments/rewards/CoinsReward';
import { PaymentsRoutes } from '@pages/routes';

import formReducer from './formReducer';

const FormBody = ({
  currentApplication, actionName, values, dispatch,
}) => {
  const isUpdate = actionName === 'Update';

  useEffect(() => {
    if (isUpdate) return;

    dispatch({ type: 'change_store_id', price: values.price });
  }, [values.price, values.priceType]);

  return (
    <>
      <PageHeader title={`${values.id ? 'Edit' : 'New'} Store Product`}>
        <FormButtonsGroup
          cancelButtonPath={PaymentsRoutes.StoreProducts.indexPath({ applicationId: currentApplication.id })}
        />
      </PageHeader>

      <Label text="Store Id"><Field type="text" name="storeId" disabled /></Label>
      <Label text="Price (USD)">
        <Field
          type="number"
          name="price"
          disabled={isUpdate}
        />
      </Label>

      <StaticFieldsFormGroup data={values} />

      {values.storeProductFallbackEnabled && (
        <PageSection title="Rewards">
          <CoinsReward rewardAttributes={values.coinsRewardAttributes} />
        </PageSection>
      )}
    </>
  );
};

export default function StoreProductForm({
  data, onSubmit, actionName,
}) {
  const { currentApplication } = useCurrentApplication();

  return (
    <Form
      initialValues={{ ...data, applicationId: currentApplication.id }}
      onSubmit={onSubmit}
      reducer={formReducer}
    >
      {(formikBag) => <FormBody currentApplication={currentApplication} actionName={actionName} {...formikBag} />}
    </Form>
  );
}
