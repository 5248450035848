import React, { useMemo } from 'react';
import { IconButton } from '@tripledotstudios/react-core';

import { FormGroup } from '@hooks';
import {
  Label, Field, SelectField, ServerError, AssetsKeySelectField,
} from '@controls/form';
import {
  PageSection, ReactTable, RemoveButton, Rewards,
} from '@pages/common';

const actionsColumns = ({ localizationKeysOptions, areaIndex }) => [
  { Header: 'Position', accessor: 'position', style: { width: '10%' } },
  {
    Header: 'Internal ID',
    accessor: 'internalId',
    style: { width: '20%' },
    Cell: ({
      row: { original: { index } },
    }) => (
      <FormGroup name={`actionsAttributes[${index}]`}>
        <Field name="internalId" />
      </FormGroup>
    ),
  },
  {
    Header: 'Amount of items required',
    id: 'itemsRequired',
    style: { width: '20%' },
    Cell: ({ row: { original: { index } } }) => (
      <FormGroup name={`actionsAttributes[${index}]`}>
        <Field
          name="requiredItemsAmount"
          type="number"
        />
      </FormGroup>
    ),
  },
  {
    Header: 'Name key',
    id: 'nameKey',
    style: { width: '20%' },
    Cell: ({ row: { original: { index } } }) => (
      <FormGroup name={`actionsAttributes[${index}]`}>
        <SelectField
          name="nameTranslationKeyId"
          options={localizationKeysOptions}
        />
      </FormGroup>
    ),
  },
  {
    Header: 'Message key',
    id: 'messageKey',
    style: { width: '20%' },
    Cell: ({ row: { original: { index } } }) => (
      <FormGroup name={`actionsAttributes[${index}]`}>
        <SelectField
          name="messageTranslationKeyId"
          options={localizationKeysOptions}
        />
      </FormGroup>
    ),
  },
  {
    Header: 'Actions',
    id: 'actions',
    style: { width: '10%' },
    Cell: ({ row: { original: { index, dispatch } } }) => (
      <RemoveButton
        onClick={() => dispatch({
          actionType: 'removeAction',
          areaIndex,
          actionIndex: index,
        })}
      />
    ),
  },
];

export default function Area({
  area, dispatch, localizationKeysOptions, assetKeysOptions, rewardItems, index: areaIndex,
}) {
  const columns = useMemo(() => actionsColumns({ localizationKeysOptions, areaIndex }), [localizationKeysOptions]);
  const actions = area.actionsAttributes.reduce((acc, action, index) => {
    if (action._destroy) { return acc; }

    return [...acc, { ...action, index, dispatch }];
  }, []);

  return (
    <>
      <div className="d-flex flex-row justify-content-around">
        <div className="flex-fill pe-2">
          <Label text="Scene Asset ID" direction="vertical">
            <Field name="sceneAssetId" />
          </Label>
        </div>
        <div className="flex-fill pe-2">
          <Label text="Asset ID: Scene" direction="vertical">
            <AssetsKeySelectField
              includeEmpty
              name="sceneAssetKeyId"
              options={assetKeysOptions}
            />
          </Label>
        </div>
        <div className="flex-fill pe-2">
          <Label text="Area name key" direction="vertical" className="flex-fill">
            <SelectField name="nameTranslationKeyId" options={localizationKeysOptions} />
          </Label>
        </div>
        <div className="flex-fill">
          <Label text="Game item to complete actions" direction="vertical" className="flex-fill">
            <SelectField name="priceRewardItemId" options={rewardItems} />
          </Label>
        </div>
      </div>
      <PageSection title="Actions" />
      <ServerError name="actionsAttributes.list" />
      <ReactTable data={actions} columns={columns} />
      <IconButton.New
        onClick={() => dispatch({ actionType: 'addAction', areaIndex })}
        title="Add an action"
      />
      <PageSection title="Rewards for area" className="mt-3" />
      <Rewards
        hideQuantityUntilSelected
        dispatch={dispatch}
        rewardable={area}
        rewardItems={rewardItems}
      />
    </>
  );
}
