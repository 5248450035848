import React from 'react';
import { capitalize, words } from 'lodash';

import findDuplicates from '@services/journeys/solitaire/searchLevelDuplicates';

const ConfirmBody = ({ nonUniqValueLevels }) => (
  Object.keys(nonUniqValueLevels).map((key) => (
    Object.keys(nonUniqValueLevels[key]).length > 0 && (
      <>
        {Object.keys(nonUniqValueLevels[key]).map((value) => (
          <>
            <div className="mb-1">
              <strong>
                {`${capitalize(words(key).join(' '))} ${value} selected for:`}
              </strong>
            </div>

            {nonUniqValueLevels[key][value].map((level) => (
              <div>
                {`Level #${level.number}`}
              </div>
            ))}
          </>
        ))}
      </>
    )
  ))
);

const onSubmit = (values, onFormSubmit, confirm) => {
  const { configAttributes: { levelsAttributes } } = values;
  const duplicates = findDuplicates(levelsAttributes);

  if (duplicates.nonUniqEntityNames.length === 0) {
    return onFormSubmit(values);
  }

  return confirm.showConfirmation({
    title: `Duplicate ${duplicates.nonUniqEntityNames.join(' and ')} detected`,
    body: <ConfirmBody nonUniqValueLevels={duplicates.nonUniqValueLevels} />,
    confirmationText: 'Confirm',
    cancellationText: 'Cancel',
  }).then(() => onFormSubmit(values));
};

export default { onSubmit };
