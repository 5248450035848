import React from 'react';
import { isPlainObject } from 'lodash';
import { Row, Col } from 'react-bootstrap';

import DateTimeInput from '@controls/form/DateTimeInput';
import FilterField from './FilterField';

const filterName = (name, postfix) => `${name}${postfix}`;

const DateField = ({
  isEndOfRange, name, postfix, updateFilterField, filter, placeholderText,
}) => {
  const onChange = (newValue) => {
    updateFilterField({
      [filterName(name, postfix)]: newValue,
    });
  };
  const value = isPlainObject(filter) ? filter[filterName(name, postfix)] : null;

  return (
    <DateTimeInput
      name={name}
      value={value}
      onChange={onChange}
      placeholderText={placeholderText}
      isEndOfRange={isEndOfRange}
      isClearable
    />
  );
};

const FieldComponent = (props) => (
  <Row>
    <Col xs={6}>
      <DateField
        postfix="Gteq"
        placeholderText="From"
        {...props}
      />
    </Col>
    <Col xs={6}>
      <DateField
        isEndOfRange
        postfix="Lteq"
        placeholderText="To"
        {...props}
      />
    </Col>
  </Row>
);

export default function DateTimeFilter({
  name, updateFilterField, ...rest
}) {
  return (
    <FilterField
      canAdd={({ currentValue }) => currentValue}
      isMulti={false}
      label={null}
      name={name}
      hideButton
      updateFilterField={updateFilterField}
      FieldComponent={FieldComponent}
      BadgesComponent={() => null}
      {...rest}
    />
  );
}
