import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { formResponseHandler } from '@requests/responseHandlers';
import { WoodokuRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { response, setResponse } = useQuery(Routes.TriggerConditions.newRequest);
  const { query: { applicationId } } = useRouter();

  const responseHandler = formResponseHandler({
    entityName: 'Trigger Conditions',
    actionName: 'creation',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    Routes.TriggerConditions.createRequest({ ...values, applicationId }).then(responseHandler)
  );

  return response && <Form data={response} onSubmit={onSubmit} actionName="Create" />;
}
