import React from 'react';
import styled from 'styled-components';

import {
  Form,
  useSettingsContext,
  AbTestingContext,
  useCurrentApplication,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import {
  FormButtonsGroup,
  Label,
  Field,
  SelectField,
  EntityWithExperiment,
  PriorityField,
  AvailabilityStateFormGroup,
  StaticFieldsFormGroup,
} from '@controls/form';
import RulesForm from '@pages/rules/Form';
import { PageHeader } from '@pages/common';
import { catalogReducer, rulesReducer, combineReducers } from '@reducers';
import { PaymentsRoutes } from '@pages/routes';
import { useRouter } from '@tripledotstudios/react-core';

import Variants from './Variants';

const VariantsContainer = styled.div`
  margin-bottom: 2em;
`;

const reducer = combineReducers([catalogReducer, rulesReducer]);

const ApplicationSpecificFields = ({ applicationType }) => {
  const catalogTypes = fromClassNameToOptions('MyPetCafe::CatalogTypes');

  return applicationType === 'my_pet_cafe' && (
    <Label text="Type">
      <SelectField name="catalogType" options={catalogTypes} />
    </Label>
  );
};

export default function CatalogForm({
  data, onSubmit, actionName,
}) {
  const { currentApplication: { id: applicationId, typeName: applicationType } } = useCurrentApplication();
  const { query } = useRouter();
  const { catalogStatuses } = useSettingsContext();

  return (
    <Form
      initialValues={{ ...data, applicationId }}
      onSubmit={onSubmit}
      reducer={reducer}
    >
      {({ values, dispatch }) => (
        <AbTestingContext entityType="Payments::Catalog" entityId={values.id}>
          <PageHeader title={`${values.id ? 'Edit' : 'New'} Catalog`}>
            <FormButtonsGroup cancelButtonPath={PaymentsRoutes.Catalogs.indexPath(query)} />
          </PageHeader>

          <Field type="hidden" name="applicationId" />

          {actionName === 'Update' && <EntityWithExperiment className="mb-3" />}

          <Label text="Name"><Field type="text" name="name" /></Label>
          <Label text="Status"><SelectField name="status" options={catalogStatuses} /></Label>
          <Label text="Priority">
            <PriorityField entitiesByPriority={data.entitiesByPriority} type="number" name="priority" />
          </Label>

          <AvailabilityStateFormGroup />

          <ApplicationSpecificFields applicationType={applicationType} />

          <StaticFieldsFormGroup data={data} />

          <VariantsContainer>
            <Variants
              defaultVariant
              variantAttributes={values.currentVariantAttributes}
              formGroupName="currentVariantAttributes"
              dispatch={dispatch}
            />
          </VariantsContainer>

          <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} />
        </AbTestingContext>
      )}
    </Form>
  );
}
