import React, { useState, useMemo } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  PageHeader,
  Pagination,
  InLiveColumn,
  TIMESTAMP_COLUMNS,
  ReactTable,
  DuplicationModal,
  InUseColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { Field, Label } from '@controls/form';
import IconButton from '@controls/buttons';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';

import Filter from './Filter';

const columnsFactory = ({ applicationId, setDuplicatedRecord, onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Key', accessor: 'key' },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({
      row: {
        original: {
          id, inUse, name, key,
        },
      },
    }) => (
      <ButtonToolbar>
        <IconButton.Edit to={Routes.Abilities.Names.editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name, key: `${key}` })} minimized />
        <IconButton.Delete onClick={() => onDelete(id, name)} disabled={isInUse(inUse)} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch, isLoading } = useQuery(Routes.Abilities.Names.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query, query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Ability Name',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => {
    confirm.showConfirmation({ title: `Ability Name '${name}' will be deleted and not available for work. Continue?` })
      .then(() => Routes.Abilities.Names.deleteRequest({ applicationId, id }).then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({ applicationId, setDuplicatedRecord, onDelete }), []);

  return (
    <div>
      <PageHeader title="Ability Names" filter={<Filter />}>
        <IconButton.New to={Routes.Abilities.Names.newPath({ applicationId })} />
      </PageHeader>

      {isLoading
        ? <Spinner />
        : (
          <>
            <ReactTable
              columns={columns}
              data={response.items}
              defaultSort={{ id: 'createdAt', desc: true }}
            />
            <DuplicationModal
              record={duplicatedRecord}
              entityName="Ability Name"
              handleClose={() => setDuplicatedRecord(null)}
              title="Duplicate Ability Name"
              onSubmit={(values) => (
                Routes.Abilities.Names.duplicateRequest({ ...values, applicationId: query.applicationId })
              )}
            >
              <Label text="Name"><Field type="text" name="name" /></Label>
              <Label text="Key"><Field type="text" name="key" /></Label>
            </DuplicationModal>

            <Pagination
              pageCount={response._meta.pages}
            />
          </>
        )}
    </div>
  );
}
