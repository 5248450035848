import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { FormGroup } from '@hooks';
import { SelectField } from '@controls/form';

import InternalIdLabel from '@components/merge/shared/InternalIdLabel';
import { MergeRoutes as Routes } from '@pages/routes';

export default function FormExperts({ values, schemaId }) {
  const { query: { applicationId } } = useRouter();
  const [expertConfigurationsMapping, setExpertConfigurationsMapping] = useState({});

  useEffect(() => {
    Routes.ExpertConfigurations.indexRequest({ applicationId, schemaId, withoutPagination: true }).then((response) => {
      const { data: { items } } = response;

      const mapping = items.reduce((memo, item) => {
        const newOptions = [...(memo[item.schemaExpertId] || []), { label: item.name, value: item.id }];
        // eslint-disable-next-line no-param-reassign
        memo[item.schemaExpertId] = newOptions;

        return memo;
      }, {});

      setExpertConfigurationsMapping(mapping);
    });
  }, [schemaId]);

  return (
    <Table>
      <thead>
        <tr>
          <th width="15%">Expert</th>
          <th>Configuration</th>
        </tr>
      </thead>
      <tbody>
        {values.map((expert, index) => (
          <FormGroup key={expert._uuid} name={`expertSetExpertsAttributes[${index}]`}>
            <tr>
              <td>
                <InternalIdLabel name={expert.internalId} />
              </td>
              <td>
                <SelectField
                  includeEmpty
                  name="expertId"
                  options={expertConfigurationsMapping[expert.schemaExpertId]}
                />
              </td>
            </tr>
          </FormGroup>
        ))}
      </tbody>
    </Table>
  );
}
