import React from 'react';

import { useQuery } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { AdminUsersRoutes } from '@pages/routes';

import Form from './Form';

export default function AdminUsersNew() {
  const { response, setResponse } = useQuery(AdminUsersRoutes.newRequest);

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'User',
    actionName: 'creation',
    collectionUrl: '/admin/admin_users',
    setData: setResponse,
  });

  const onSubmit = (values) => AdminUsersRoutes.createRequest({ ...values, uid: values.email }).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      actionName="Create"
    />
  );
}
