import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'react-bootstrap';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const CollapseContainer = styled.div`
  &:not(.show) {
    display: block !important;
    min-height: ${({ minHeight }) => minHeight}px;
    overflow: hidden;
  }
  &:not(.show):not(.collapsing) {
    height: 0 !important;
  }
`;

const CollapseButton = styled(FontAwesomeIcon)`
  margin-top: 5px;
  margin-left: 5px;
`;

export default function PartialCollapse({ disableCollapse = false, children, minHeight }) {
  const [open, setOpen] = useState(false);
  const id = `collapse-${uuidv4()}`;
  if (disableCollapse) { return children; }

  return (
    <>
      <Collapse in={open}>
        <CollapseContainer id={id} minHeight={minHeight}>
          {children}
        </CollapseContainer>
      </Collapse>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <span
        onClick={() => setOpen(!open)}
        aria-controls={id}
        aria-expanded={open}
      >
        {open
          ? (
            <>
              Collapse
              <CollapseButton icon={faChevronUp} size="sm" />
            </>
          ) : (
            <>
              Expand
              <CollapseButton icon={faChevronDown} size="sm" />
            </>
          )}
      </span>
    </>
  );
}

export const AutoCollapse = (props) => {
  const { children, minHeight, disableCollapse } = props;
  const [collapseEnabled, setCollapseEnabled] = useState(false);
  const collapseContainer = useRef();

  useEffect(() => {
    if (collapseContainer.current) {
      const shouldCollapse = get(collapseContainer.current, 'offsetHeight') > minHeight;
      setCollapseEnabled(shouldCollapse);
    }
  }, [children, collapseContainer.current]);

  if (!collapseEnabled) return <div ref={collapseContainer}>{children}</div>;

  return (
    <PartialCollapse {...props} disableCollapse={!collapseEnabled || disableCollapse}>
      {children}
    </PartialCollapse>
  );
};
