import React, { useMemo } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import {
  useQuery,
  useConfirm,
} from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { WoodokuRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';
import ColumnsFactory from './ColumnsFactory';

export default function JsonConfigsIndex({ configTypes, group }) {
  const { response, refetch } = useQuery(
    WoodokuRoutes.XpLevelConfigs.indexRequest,
    { includeInUse: true, type: configTypes },
  );

  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'XP Level config',
    actionName: 'deletion',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `XP Level config '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => WoodokuRoutes.XpLevelConfigs.deleteRequest({ id, applicationId }))
      .then(responseHandler);
  };

  const columns = useMemo(() => ColumnsFactory({
    applicationId, group, onDelete,
  }), []);

  return response && (
    <div>
      <PageHeader
        title="XP Level Configs"
        filter={<Filter />}
      >
        <IconButton.New to={WoodokuRoutes.XpLevelConfigs.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
