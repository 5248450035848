import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { FlashMessages, Spinner } from '@tripledotstudios/react-core';

import Sidebar from '@controls/navigation/Sidebar';
import { useGlobalLoading } from '@hooks';
import FlashAnnouncements from './FlashAnnouncements';

const MainContainer = styled.main`
  min-width: 0;
`;

export default function Layout() {
  const { isLoading } = useGlobalLoading();

  return (
    <div className="d-flex">
      <FlashMessages />
      <FlashAnnouncements />
      <Sidebar />
      <MainContainer className="container-fluid p-3 flex-grow-1">
        {isLoading ? <Spinner /> : <Outlet />}
      </MainContainer>
    </div>
  );
}
