import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import styled from 'styled-components';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FaIcon } from '@pages/common/FaButton';
import { useFormGroup, useFormContext } from '@hooks';
import RemoveButton from '@pages/common/RemoveButton';
import { snakeCase } from '@tripledotstudios/react-core';

import ServerError from './ServerError';

const StyledFaIcon = styled(FaIcon)`
  cursor: pointer;
`;

export default function MultipleValuesField({
  name,
  InputComponent,
  wrapValue = false,
  inputSize = 10,
  removeButtonSize = 2,
  removeButtonClassName = 'float-end mt-2',
  disabled,
  handleCollectionErrors = true,
  testId,
  ...rest
}) {
  const { values, setFieldValue } = useFormContext();
  const { generateName } = useFormGroup();

  const fullName = generateName(name);
  const valuesArray = get(values, fullName, []);
  const rootTestId = testId || snakeCase(name);

  const onAddValue = () => setFieldValue(fullName, [...valuesArray, wrapValue ? { value: null } : '']);
  const onRemoveValue = (index) => {
    const newArray = valuesArray.filter((_, elementIndex) => elementIndex !== index);

    setFieldValue(fullName, newArray);
  };

  return (
    <Container className="ps-0 pe-0" data-testid={rootTestId}>
      {React.Children.toArray(valuesArray.map((_value, index) => (
        <Row>
          <Col xs={disabled ? 12 : inputSize}>
            <InputComponent
              name={wrapValue ? `${name}.${index}.value` : `${name}.${index}`}
              index={index}
              className="mb-3"
              disabled={disabled}
              {...rest}
            />
          </Col>
          {!disabled && (
            <Col xs={removeButtonSize}>
              <div className={removeButtonClassName}>
                <RemoveButton
                  title="Remove"
                  onClick={() => onRemoveValue(index)}
                  disabled={disabled}
                  data-testid={`remove_value_${rootTestId}`}
                />
              </div>
            </Col>
          )}
        </Row>
      )))}
      {!disabled && (
        <StyledFaIcon
          className="ms-2"
          color="green"
          icon={faPlus}
          onClick={onAddValue}
          title="Add"
          data-testid={`add_value_${rootTestId}`}
        />
      )}
      <ServerError className="mt-2" name={name} handleCollectionErrors={handleCollectionErrors} />
    </Container>
  );
}
