import React, { useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Spinner, IconButton } from '@tripledotstudios/react-core';

import {
  PageHeader, Pagination, ReactTable, DuplicationModal,
} from '@pages/common';
import { generateChapterBasedRoutes } from '@pages/routes';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  useCurrentApplication, useQuery, useConfirm, useI18n,
} from '@hooks';
import Comparison from '@components/shared/items-comparison/Comparison';

import Filter from './Filter';
import columnsFactory from './columnsFactory';

const SUPPORTS_COMPARISON = ['tile'];

export default function ChapterSetsIndex({ importPath }) {
  const { translate } = useI18n();
  const { typeName: appType, currentApplication } = useCurrentApplication();
  const ChapterSetRoutes = generateChapterBasedRoutes(appType).ChapterSets;
  const { response, refetch, isLoading } = useQuery(ChapterSetRoutes.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const [deletionInProgress, setDeletionInProgress] = useState(false);

  const deleteResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Chapter Set',
    actionName: 'removal',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `Chapter set '${name}' will be deleted and not available for work. Continue?` })
      .then(() => setDeletionInProgress(true))
      .then(() => ChapterSetRoutes.deleteRequest({ applicationId: currentApplication.id, id })
        .then(deleteResponseHandler))
      .finally(() => setDeletionInProgress(false));
  };

  const columns = useMemo(() => columnsFactory({
    currentApplication, ChapterSetRoutes, setDuplicatedRecord, onDelete,
  }), []);

  if (!response) return null;

  const localePath = 'chapterSet';
  const showComparison = SUPPORTS_COMPARISON.includes(currentApplication.typeName);

  const onDuplicate = (values) => (
    ChapterSetRoutes.duplicateRequest({ ...values, applicationId: currentApplication.id })
  );

  return (
    <div>
      <PageHeader
        title={translate.fallback(`${localePath}.pluralName`)}
        filter={<Filter />}
        menuButtons={(
          <>
            {importPath && (
              <Button as={Link} to={importPath({ applicationId: currentApplication.id })}>
                Create a new chapter set from CSV
              </Button>
            )}
            {showComparison && (
              <Comparison
                routes={ChapterSetRoutes}
                modelName="Metagame::ChapterBased::ChapterSet"
                localePath={localePath}
              />
            )}
          </>
        )}
      >
        <IconButton.New to={ChapterSetRoutes.newPath({ applicationId: currentApplication.id })} />
      </PageHeader>
      {isLoading || deletionInProgress ? <Spinner /> : (
        <>
          <ReactTable
            columns={columns}
            data={response ? response.items : []}
            defaultSort={{ id: 'createdAt', desc: true }}
          />
          <DuplicationModal
            record={duplicatedRecord}
            entityName="Chapter set"
            handleClose={() => setDuplicatedRecord(null)}
            onSubmit={onDuplicate}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </div>
  );
}
