import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { AbTestingRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const { newRequest, indexPath, createRequest } = AbTestingRoutes.Experiments;
  const { applicationId } = useRouter().query;
  const { response, setResponse } = useQuery(newRequest, { applicationId });

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'A/B experiment',
    actionName: 'creation',
    redirectPath: indexPath({ applicationId }),
    setData: setResponse,
  });

  const onSubmit = (values) => createRequest({ applicationId, ...values }).then(responseHandler);

  return response && (
    <Form action="New" data={response} onSubmit={onSubmit} />
  );
}
