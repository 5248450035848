import React from 'react';

import { useCurrentApplication } from '@hooks';

import { Columns as BlackjackColumns } from './blackjack';

export default function GameSpecificColumns() {
  const { applicationName } = useCurrentApplication();

  switch (applicationName) {
    case 'Blackjack': return <BlackjackColumns />;
    default: return '';
  }
}
