import React from 'react';

import { AppUserRoutes } from '@pages/routes';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import Form from '@pages/users/app-users/Form';

export default function GeneralInfo({ data, setData }) {
  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'App User',
    actionName: 'update',
    redirectPath: '/admin/devices',
    setData,
  });

  const onSubmit = (values) => (
    AppUserRoutes.updateRequest(values).then(redirectIfSuccessHandler)
  );

  return <Form data={data} onSubmit={onSubmit} setData={setData} />;
}
