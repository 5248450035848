import {
  last, cloneDeep, isPlainObject, sortBy, get, set,
} from 'lodash';
import reorderMeta from '@services/reorderMeta';
import { addUuid } from '@services/recursivelyProcessObject';
import reorderEntities from '@services/reorderEntities';
import removeEntity from '@services/removeEntity';
import removeNestedIds from '@services/removeNestedIds';

const DEFAULT_LEVELS_PATH = 'levelLayoutsAttributes';

/* eslint-disable no-param-reassign, array-callback-return */
export default function formReducer(state, action) {
  const { levelsPath } = action;
  const resolvedLevelsPath = levelsPath || DEFAULT_LEVELS_PATH;
  const levelsAttributes = get(state, resolvedLevelsPath);

  const setLevels = (value) => set(state, resolvedLevelsPath, value);

  if (action.actionType === 'removeEntity') {
    const { _uuid: uuidToDelete } = action;

    return removeEntity(state, resolvedLevelsPath, uuidToDelete, 'position');
  }

  if (action.actionType === 'bulkRemoveEntities') {
    const { uuids } = action;

    const levels = cloneDeep(levelsAttributes);

    levels.forEach((level) => {
      if (uuids.includes(level._uuid)) level._destroy = true;

      return level;
    });

    setLevels(reorderEntities(levels, 'position').result);

    return state;
  }

  if (action.actionType === 'restoreEntity') {
    const { _uuid: uuidToDelete } = action;

    const levels = cloneDeep(levelsAttributes);

    delete levels.find(({ _uuid }) => _uuid === uuidToDelete)._destroy;

    setLevels(reorderEntities(levels, 'position').result);

    return state;
  }

  if (action.actionType === 'moveEntity') {
    const {
      sourceIndex,
      destinationIndex,
      meta,
      metaErrorsPath,
    } = action;

    const levels = cloneDeep(levelsAttributes);

    if (levelsAttributes.length <= 1) return state;

    const { result, changeSet: [fromIndices, toIndices] } = reorderEntities(
      levels,
      'position',
      sourceIndex,
      destinationIndex,
    );

    setLevels(result);

    if (meta.errors && isPlainObject(get(meta, metaErrorsPath))) {
      meta.errors = { ...meta.errors, ...reorderMeta(meta, fromIndices, toIndices, metaErrorsPath).errors };
    }

    return state;
  }

  if (action.actionType === 'applyPositions') {
    const { uuidToPositionMapping } = action;

    levelsAttributes.forEach((level) => {
      level.position = uuidToPositionMapping[level._uuid];

      return level;
    });

    setLevels(sortBy(levelsAttributes, 'position'));

    return state;
  }

  if (action.actionType === 'duplicateEntity') {
    const { _uuid: originalUuid } = action;

    const {
      id, _uuid, name, ...originalLevel
    } = levelsAttributes.find((level) => level._uuid === originalUuid);
    const lastLevel = last(levelsAttributes.filter(({ _destroy }) => !_destroy));

    levelsAttributes.push(addUuid({
      ...removeNestedIds(originalLevel),
      _destroy: false,
      name: `${name}`,
      position: lastLevel.position + 1,
      cloneSourceId: id,
    }));
  }

  return state;
}
