import React from 'react';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { ApplicationRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const { response, setResponse } = useQuery(ApplicationRoutes.newRequest);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Application',
    actionName: 'create',
    redirectPath: '/admin/applications',
    setData: setResponse,
  });

  const onSubmit = (values) => ApplicationRoutes.createRequest(values).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
