import { wrapReducer } from '@reducers';
import { addUuid } from '@services/recursivelyProcessObject';

import APP_DATA from '@services/appData';

const typesEnum = APP_DATA.enums['WoodokuSaga::ImpactAreaTypes'];

const formReducer = (state, action) => {
  switch (action.type) {
    case 'addEmptyEntity': {
      state.impactAreasAttributes.push(addUuid({
        id: null,
        areaType: null,
        points: [],
      }));
      break;
    }
    case 'removeEntity': {
      const { index } = action;

      state.impactAreasAttributes[index]._destroy = true;
      break;
    }
    case 'areaTypeChange': {
      const { index, value: areaType } = action;
      const areas = state.impactAreasAttributes;

      areas[index].points = [{ x: 0, y: 0, radius: 0 }];
      if (areaType === typesEnum.RECT || areaType === typesEnum.CIRCLE) areas[index].points.push({ x: 0, y: 0 });

      break;
    }
    case 'radiusChange': {
      const { areaIndex, value } = action;
      const radius = Number(value);
      const { points } = state.impactAreasAttributes[areaIndex];

      points[0].radius = radius;
      points[1].x = points[0].x + radius;

      break;
    }
    case 'circleXChange': {
      const { areaIndex, value } = action;
      const { points } = state.impactAreasAttributes[areaIndex];

      if (!value.length) {
        points[0].x = '';
        break;
      }

      const x = Number(value);

      points[0].x = x;
      points[1].x = x + points[0].radius;

      break;
    }
    case 'circleYChange': {
      const { areaIndex, value } = action;
      const { points } = state.impactAreasAttributes[areaIndex];

      if (!value.length) {
        points[0].y = '';
        break;
      }

      const y = Number(value);

      points[0].y = y;
      points[1].y = y;

      break;
    }
    case 'addPoint': {
      const { areaIndex } = action;

      state.impactAreasAttributes[areaIndex].points.push({ x: 0, y: 0 });
      break;
    }
    case 'removePoint': {
      const { areaIndex, index } = action;

      state.impactAreasAttributes[areaIndex].points.splice(index, 1);

      break;
    }
    default: break;
  }

  return state;
};

export default wrapReducer(formReducer);
