import React from 'react';
import { useTheme } from 'styled-components';
import { Sidebar } from '@tripledotstudios/react-core';

import {
  faListOl, faTable, faCouch, faGem, faAtom, faFlaskVial,
} from '@fortawesome/free-solid-svg-icons';

import { WoodokuSagaRoutes as Routes } from '@pages/routes';
import {
  AdsNavigationSection, ApplicationItemSet, AssetsNavigationItem, RewardsNavigationItem,
} from './common';

export default function WoodokuSaga({ applicationId, routingScope, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="discovery"
      routingScope={routingScope}
      minimized={minimized}
    >
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />
      <Sidebar.LinkItem
        to={Routes.AreaSets.indexPath({ applicationId })}
        icon={faCouch}
        title="Area Sets"
        color={theme.green}
        minimized={minimized}
      />
      <Sidebar.LinkItem
        to={Routes.LevelRewardSets.indexPath({ applicationId })}
        icon={faGem}
        title="Level Reward Sets"
        color={theme.red}
        minimized={minimized}
      />
      <Sidebar.SectionItem
        icon={faAtom}
        title="Abilities"
        color={theme.lightPurple}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={Routes.Abilities.Impacts.indexPath({ applicationId })}
          title="Impact Areas"
        />
        <Sidebar.LinkItem
          to={Routes.Abilities.Names.indexPath({ applicationId })}
          title="Ability Names"
        />
        <Sidebar.LinkItem
          to={Routes.Abilities.Sets.indexPath({ applicationId })}
          title="Ability Sets"
        />
      </Sidebar.SectionItem>
      <Sidebar.SectionItem
        icon={faListOl}
        title="Levels & Chapters"
        color={theme.yellow}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={Routes.Banks.indexPath({ applicationId })}
          title="Layout Banks"
        />
        <Sidebar.LinkItem
          to={Routes.ChapterSets.indexPath({ applicationId })}
          title="Chapter Sets"
        />
      </Sidebar.SectionItem>
      <Sidebar.LinkItem
        to={Routes.FigureSets.indexPath({ applicationId })}
        icon={faTable}
        title="Figure Sets"
        color={theme.yellow}
        minimized={minimized}
      />
      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />
      <Sidebar.LinkItem
        to={Routes.MagicPots.indexPath({ applicationId })}
        icon={faFlaskVial}
        title="Magic Pots"
        color={theme.purple}
        minimized={minimized}
      />
      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
