import React, { useState } from 'react';

import MultilineContent from '@controls/MultilineContent';
import { useFormContext } from '@hooks';

import { Td } from '../../shared/styled-components';
import useAvailabilityTypes from '../../shared/services/useAvailabilityTypes';
import onFocus from '../../shared/services/editable-cell/onFocus';

const Input = (props) => <input type="text" {...props} />;

export default function NameCell({ defaultValue, rowData, isDisabled }) {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const { isTest } = useAvailabilityTypes();
  const { dispatch } = useFormContext();

  const enableEditMode = () => setEditModeEnabled(true);
  const disableEditMode = () => setEditModeEnabled(false);
  const isChanged = () => currentValue !== defaultValue;

  const pushRowIfChanged = () => {
    if (isChanged()) {
      dispatch({
        type: 'processPersistedTranslationKeyNameChange',
        rowData,
        newValue: currentValue,
      });
    }
  };

  const onChange = (event) => setCurrentValue(event.target.value);
  const onKeyDown = (event) => {
    switch (event.key) {
      case 'Escape': {
        disableEditMode();
        setCurrentValue(defaultValue);

        break;
      }
      case 'Enter':
        if (event.shiftKey) break;
        pushRowIfChanged();

        event.preventDefault();
        disableEditMode();

        break;
      default:
    }
  };

  const onBlur = (event) => {
    pushRowIfChanged();

    event.preventDefault();
    disableEditMode();
  };

  return (
    <Td
      width="250px"
      onDoubleClick={() => isTest && !isDisabled && enableEditMode()}
      sticky
    >
      {editModeEnabled && isTest ? (
        <Input
          className="form-control w-100"
          value={currentValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus
        />
      ) : <MultilineContent value={currentValue} />}
    </Td>
  );
}
