import React from 'react';

import { baseAppConfig, updateAppConfig } from '@requests/my-pet-cafe/appConfigs';
import CreateUpdateConfig from './CreateUpdateConfig';

export default function Base() {
  return (
    <CreateUpdateConfig
      fetchFunction={baseAppConfig}
      saveFunction={updateAppConfig}
      actionName="update"
      isBase
    />
  );
}
