import React from 'react';

import { useQuery } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { TileRoutes } from '@pages/routes';
import Form from './Form';

const BankEdit = () => {
  const { response, setResponse } = useQuery(TileRoutes.DifficultySchemas.editRequest);

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'DDA schema',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => TileRoutes.DifficultySchemas.updateRequest(values)
    .then((data) => responseHandler(data, values));

  return response && <Form data={response} onSubmit={onSubmit} setData={setResponse} />;
};

export default BankEdit;
