import React from 'react';
import { Link } from 'react-router-dom';

import { ArchivedSettingsRoutes } from '@pages/routes';
import Badge from '../Badge';
import LabelRow from './LabelRow';

export default function ArchivedSettingRow({ application, setting }) {
  return setting && (
    <>
      <LabelRow text="Archived Setting" />
      <tr>
        <td>
          <Link
            key={setting.name}
            to={ArchivedSettingsRoutes.editPath({ applicationId: application.id, id: setting.id })}
            target="_blank"
          >
            {setting.name}
          </Link>
        </td>
        <td>N/A</td>
        <td>
          <Badge bg="secondary">Archived</Badge>
        </td>
        <td>N/A</td>
        <td>N/A</td>
      </tr>
    </>
  );
}
