import React, { useState } from 'react';

import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { Alert, useRouter } from '@tripledotstudios/react-core';
import { PageHeader, PageSection } from '@pages/common';

import {
  Form,
  FormGroup,
  useConfirm,
  useCurrentApplication,
} from '@hooks';
import { FormButtonsGroup, Label, CheckboxField } from '@controls/form';
import IconButton from '@controls/buttons';
import Favourite from '@components/favourites/Favourite';

import ExperimentsTable from './ExperimentsTable';
import UserInfo from './UserInfo';
import DevicesInfo from './DevicesInfo';
import DeviceSegments from './DeviceSegments';
import AnonymisationConfirmationModal from './anonymisation/AnonymisationConfirmationModal';

export default function AppUsersForm({ data, onSubmit, setData }) {
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();
  const { routingScope } = useCurrentApplication();
  const [anonymisationConfirmationShown, setAnonymisationConfirmationShown] = useState(false);

  const renderExperimentsData = (values) => {
    const { experimentParticipationsAttributes: participations } = values;

    return (participations.length > 0) ? (
      <ExperimentsTable values={values} />
    ) : (
      <Alert variant="info">
        <b>There are no A/B experiment participations yet</b>
      </Alert>
    );
  };

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        const onCheckboxChanged = (event) => {
          const field = event.target;

          confirm.showConfirmation({ title: 'Confirm action' }).then(() => {
            setFieldValue(field.name, field.value === 'false');
          });
        };

        return (
          <>
            <PageHeader
              title="User"
              className="pt-1 mt-3"
              leadingAccessories={(
                <Favourite
                  favorableId={values.id}
                  applicationId={applicationId}
                  type="Accounts::AppUser"
                />
              )}
            >
              <FormButtonsGroup
                cancelButtonPath={`${routingScope}/devices`}
              >
                <IconButton
                  icon={faUserSecret}
                  variant="warning"
                  onClick={() => setAnonymisationConfirmationShown(true)}
                  disabled={data.anonymised}
                >
                  Anonymise
                </IconButton>
                <FormButtonsGroup.Divider />
              </FormButtonsGroup>
            </PageHeader>
            <UserInfo user={data} />

            <PageSection title="Devices" />
            <DevicesInfo devices={data.devices} />

            <PageSection title="Settings" />
            <FormGroup>
              <Label text="Blocked"><CheckboxField name="blocked" onChange={onCheckboxChanged} /></Label>
            </FormGroup>

            <PageSection title="Experiments" />
            {renderExperimentsData(values)}

            {data.devices.length > 0
              ? <DeviceSegments user={data} applicationId={applicationId} />
              : <Alert variant="info" className="mb-0"><b>There are no devices</b></Alert>}

            <AnonymisationConfirmationModal
              show={anonymisationConfirmationShown}
              setShow={setAnonymisationConfirmationShown}
              applicationId={applicationId}
              userId={data.id}
              devices={data.devices}
              setData={setData}
            />
          </>
        );
      }}
    </Form>
  );
}
