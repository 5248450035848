import React, { useState } from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import { FormGroup, useFormContext, useI18n } from '@hooks';
import { Collapsible } from '@controls/form';
import { get } from 'lodash';

import Select from '@controls/Select';
import MissionConfig from './MissionConfig';

export default function Tier({
  data,
  tierIndex,
  missionTypeOptions,
  rewardTypeOptions,
}) {
  const [selectedMissionTypeId, setSelectedMissionTypeId] = useState();
  const { meta: { errors }, dispatch } = useFormContext();
  const { number, missionConfigsAttributes } = data;
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  const eventKey = `tier-${number}`;
  const headerName = `Tier ${number}`;
  const hasErrors = errors && get(errors, `tiersAttributes.${tierIndex}`);

  const filterMissionTypes = () => {
    const usedIds = missionConfigsAttributes.map(({ missionTypeId }) => missionTypeId);

    return missionTypeOptions.filter((option) => !usedIds.includes(option.value));
  };

  const onAddMissionConfig = () => {
    if (!selectedMissionTypeId) return;

    const selectedMissionType = missionTypeOptions.find(({ value }) => value === selectedMissionTypeId);

    setSelectedMissionTypeId(null);

    dispatch({
      actionType: 'addMissionConfig',
      missionType: selectedMissionType,
      rewardTypeOptions,
      tierIndex,
    });
  };

  return (
    <Collapsible
      header={headerName}
      eventKey={eventKey}
      withErrors={hasErrors}
    >
      <>
        {missionConfigsAttributes.map((missionConfig, index) => (
          <FormGroup key={missionConfig._uuid} name={`missionConfigsAttributes.${index}`}>
            <MissionConfig
              data={missionConfig}
              tierIndex={tierIndex}
              missionConfigIndex={index}
            />
          </FormGroup>
        ))}
        <Row>
          <Col xs={3}>
            <Select
              selectedValue={selectedMissionTypeId}
              options={filterMissionTypes()}
              onChange={(event) => setSelectedMissionTypeId(event.value)}
              placeholder={`Select ${namespaceName} type`}
            />
          </Col>
          <Col xs={9}>
            <Button onClick={onAddMissionConfig}>Add rewards</Button>
          </Col>
        </Row>
      </>
    </Collapsible>
  );
}
