import React from 'react';
import { Col } from 'react-bootstrap';

import {
  Filter,
  TextFilter,
  DateTimeFilter,
  StatusFilter,
} from '@pages/common';

import { Label } from '@controls/form';
import { fromClassNameToOptions } from '@services/enums';

export default function OverrideableSettingsFilter({ statusesEnum }) {
  const statusOptions = fromClassNameToOptions(statusesEnum, { withoutFiltering: true });

  return (
    <Filter
      defaultComponents={['availabilityStateIn', 'labels', 'createdByMe', 'namedRuleGroup', 'id']}
    >
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <StatusFilter
              name="statusIn"
              label="Status"
              options={statusOptions}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <Label direction="vertical" text="Start at">
              <DateTimeFilter
                name="startAt"
                updateFilterField={updateFilterField}
              />
            </Label>
          </Col>
          <Col md={6}>
            <Label direction="vertical" text="End at">
              <DateTimeFilter
                name="endAt"
                updateFilterField={updateFilterField}
              />
            </Label>
          </Col>
        </>
      )}
    </Filter>
  );
}
