import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import {
  FormGroup, Form, useFormContext,
} from '@hooks';
import { Field, SelectField } from '@controls/form';
import { allRewardsConfigurations } from '@components/merge/services/RewardsConfigurations';
import { MergeRoutes, AppUserRoutes } from '@pages/routes';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import rewardsReducer from '@components/merge/reducers/rewardsReducer';
import { PageSection } from '@pages/common';

import IconButton from '@controls/buttons';

const Reward = ({ value, rewardsConfigurations }) => {
  const [rewardConfiguration, setRewardConfiguration] = useState();
  const { query } = useRouter();
  const [items, setItems] = useState();
  const [experts, setExperts] = useState();
  const { isSubmitting } = useFormContext();
  const { dispatch } = useFormContext();
  const findRewardConfiguration = (type) => (
    rewardsConfigurations.find(({ value: seletedValue }) => seletedValue === type)
  );

  useEffect(() => {
    setRewardConfiguration(findRewardConfiguration(value.type));
  }, [value.type]);

  useEffect(() => {
    MergeRoutes.WorldSchemas.forUserRequest({
      appUserId: query.id,
      applicationId: query.applicationId,
    }).then((response) => {
      setItems(response.data.items.map((item) => ({ value: item.id, label: item.internalId })));
      setExperts(response.data.experts.map((expert) => ({ value: expert.id, label: expert.internalId })));
    });
  }, []);

  const onChange = (e) => {
    dispatch({
      type: 'change_reward_type',
      path: 'rewardAttributes',
      defaults: findRewardConfiguration(e.value).defaults || {},
    });
  };

  return (
    <Row className="mb-2">
      <Col xs={5} className="pe-0">
        <Field name="id" type="hidden" className="me-1" />
        <SelectField name="type" options={rewardsConfigurations} onChange={onChange} className="me-1" />
      </Col>
      <Col xs={5} className="pe-0">
        {rewardConfiguration ? rewardConfiguration.component({ value, items, experts }) : ''}
      </Col>
      <Col xs={2}>
        <IconButton.Save type="submit" disabled={isSubmitting}>Submit</IconButton.Save>
      </Col>
    </Row>
  );
};

export default function ManualReward() {
  const [data, setData] = useState({ rewardAttributes: {} });
  const { query } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'User Rewards',
    actionName: 'create',
    setData,
  });
  const onSubmit = (values) => AppUserRoutes.UserRewards.createRequest({
    ...values, appUserId: query.id, applicationId: query.applicationId,
  }).then(responseHandler);

  return (
    <PageSection title="Manual Reward">
      <Form
        initialValues={data}
        onSubmit={onSubmit}
        reducer={rewardsReducer}
      >
        {({ values }) => (
          <FormGroup name="rewardAttributes">
            <Reward value={values.rewardAttributes} rewardsConfigurations={allRewardsConfigurations()} />
          </FormGroup>
        )}
      </Form>
    </PageSection>
  );
}
