import React from 'react';

import { Col } from 'react-bootstrap';

export default function LabelCol({ children }) {
  return (
    <Col xs="1" className="text-end pe-0 ps-0">
      {children}
    </Col>
  );
}
