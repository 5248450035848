import React, {
  useState, useCallback, createContext, useContext, useMemo,
} from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@controls/buttons';

const ConfirmContext = createContext({});

const ModalBody = styled(Modal.Body)`
  white-space: pre-wrap;
`;

const DEFAULT_OPTIONS = {
  title: 'Confirm to submit',
  body: 'Are you sure?',
  confirmationText: 'Yes',
  cancellationText: 'No',
};

const ConfirmModal = ({
  show, options, onCancel, onConfirm,
}) => {
  const {
    title,
    body,
    confirmationText,
    cancellationText,
    headerColor,
  } = options;

  return (
    <Modal show={show} onHide={onCancel} className="confirm-modal">
      <Modal.Header style={{ backgroundColor: headerColor || '' }} closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>{body}</ModalBody>
      <Modal.Footer>
        <IconButton icon={faTimes} variant="danger" onClick={onCancel}>
          {cancellationText}
        </IconButton>
        <IconButton icon={faCheck} variant="success" onClick={onConfirm}>
          {confirmationText}
        </IconButton>
      </Modal.Footer>
    </Modal>
  );
};

const ConfirmProvider = ({ children, defaultOptions = {} }) => {
  const [options, setOptions] = useState({ ...DEFAULT_OPTIONS, ...defaultOptions });
  const [resolveReject, setResolveReject] = useState([]);
  const [resolve, reject] = resolveReject;

  const confirm = useCallback((opts = {}) => new Promise((rs, rj) => {
    setOptions({ ...options, ...opts });
    setResolveReject([rs, rj]);
  }), []);

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    reject();
    handleClose();
  }, [reject]);

  const handleConfirm = useCallback(() => {
    resolve();
    handleClose();
  }, [resolve]);

  const confirmationBag = useMemo(() => ({ showConfirmation: confirm }), [confirm]);

  return (
    <ConfirmContext.Provider value={confirmationBag}>
      {children}

      <ConfirmModal
        show={resolveReject.length === 2}
        options={options}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </ConfirmContext.Provider>
  );
};

const useConfirm = () => useContext(ConfirmContext);

export { ConfirmProvider, useConfirm };
