import React, { useMemo } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader,
  Pagination,
  RulesList,
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  ReactTable,
  Filter,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { VersionUpdateRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

const columnsFactory = ({ applicationId, editPath, onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  {
    Header: 'Rules',
    id: 'rules',
    style: { width: '30%' },
    Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
  },
  InLiveColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={editPath({ id, applicationId })} minimized />
        <IconButton.Delete onClick={onDelete(id)} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function ForceUpdatesIndex() {
  const {
    ForceUpdates: {
      deleteRequest, editPath, newPath, indexRequest,
    },
  } = VersionUpdateRoutes;
  const { response, refetch } = useQuery(indexRequest);
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Force update',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id) => () => {
    confirm.showConfirmation({ title: 'Force update will be deleted and not available for work. Continue?' })
      .then(() => deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({
    applicationId, editPath, onDelete,
  }), []);

  return response && (
    <div>
      <PageHeader
        title="Force Updates"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
        userGuideUrlKey="versionUpdatesGuideUrl"
      >
        <IconButton.New to={newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
