import APP_DATA from '@root/services/appData';
import { addUuid } from '@services/recursivelyProcessObject';

const { enums } = APP_DATA;
const tierParametersEnum = enums['Missions::MissionTypes::TierParameterTypes'];

/* eslint-disable no-param-reassign, array-callback-return */
export default function tierParametersReducer(state, action) {
  switch (action.actionType) {
    case 'addTierParameter': {
      state.tierParametersAttributes.push(addUuid({
        name: '',
        type: tierParametersEnum.FLOAT,
        _destroy: false,
      }));

      break;
    }

    case 'removeTierParameter': {
      const { index } = action;
      const tierParameter = state.tierParametersAttributes[index];

      if (tierParameter.id) {
        tierParameter._destroy = true;
      } else {
        state.tierParametersAttributes.splice(index, 1);
      }
      break;
    }
    default: break;
  }

  return state;
}
