import React from 'react';
import { matchPath } from 'react-router-dom';
import { Sidebar, useRouter } from '@tripledotstudios/react-core';

import ApplicationSelect from '@controls/navigation/ApplicationSelect';
import Brand from '@controls/navigation/Brand';

import AuditLogItem from '@controls/navigation/AuditLogItem';
import ToolsItemSet from '@controls/navigation/item-sets/Tools';
import UserItemSet from '@controls/navigation/item-sets/User';

import APP_DATA from '@services/appData';

import itemSetByApplicationType from './item-sets/applications/finder';

export default function GameServerSidebar() {
  const router = useRouter();
  const { applications } = APP_DATA;

  const getApplicationId = () => {
    const match = matchPath({ path: '/admin/applications/:applicationId/*' }, router.pathname);

    return match ? parseInt(match.params.applicationId, 10) : null;
  };

  const findApplication = (id) => (applications && applications.find((a) => a.value === id));

  const getAppDefaultRoute = (application) => {
    if (!application) return '/admin';

    return `/admin/applications/${application.value}/home`;
  };

  const handleAppChange = ({ value }) => {
    const application = findApplication(value);
    const path = getAppDefaultRoute(application);

    router.push(path);
  };

  const currentApplicationId = getApplicationId();
  const currentApplication = findApplication(currentApplicationId);
  const currentApplicationDefaultRoute = getAppDefaultRoute(currentApplication);

  const routingScope = `/admin/applications/${currentApplicationId}`;

  const ApplicationItemSet = itemSetByApplicationType(currentApplication);

  return applications && (
    <Sidebar>
      {({ minimized }) => (
        <>
          <Brand root={currentApplicationDefaultRoute} minimized={minimized} />

          <Sidebar.Separator />

          <ApplicationSelect
            applications={applications}
            currentApplicationId={currentApplicationId}
            handleAppChange={handleAppChange}
            minimized={minimized}
          />

          <Sidebar.Separator />

          <Sidebar.OverflowSection>
            <ApplicationItemSet
              routingScope={routingScope}
              applicationId={currentApplicationId}
              minimized={minimized}
            />
          </Sidebar.OverflowSection>

          <Sidebar.BottomSection>
            <AuditLogItem currentApplicationId={currentApplicationId} minimized={minimized} />

            <ToolsItemSet minimized={minimized} />
            <UserItemSet minimized={minimized} />
          </Sidebar.BottomSection>
        </>
      )}
    </Sidebar>
  );
}
