import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Alert } from '@tripledotstudios/react-core';

import { PageSection, CopyableData } from '@pages/common';
import { AppUserRoutes } from '@pages/routes';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';

import IconButton from '@controls/buttons';

export default function AnonymisationConfirmationModal({
  show,
  setShow,
  applicationId,
  userId,
  devices,
  setData,
}) {
  const [idfaCopiedForDeviceIds, setIdfaCopiedForDeviceIds] = useState([]);

  const anonymisableDevices = devices.filter((device) => !device.isLat);
  const confirmationDisabled = anonymisableDevices.some((device) => !idfaCopiedForDeviceIds.includes(device.id));

  const onCancel = () => {
    setIdfaCopiedForDeviceIds([]);
    setShow(false);
  };

  const anonymisationResponseHandler = noRedirectHandlerFactory({
    entityName: 'User',
    actionName: 'anonymisation',
    setData,
  });

  const guardedOnConfirm = () => {
    if (confirmationDisabled) return;

    AppUserRoutes.anonymiseRequest({ applicationId, id: userId })
      .then(anonymisationResponseHandler)
      .then(() => setShow(false));
  };

  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to anonymise this user?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <>
          <Alert>This action is not revertible!</Alert>

          <p>{`You are going to anonymise User #${userId}`}</p>

          <p>
            IDFA, IDFV and IP address of all user devices will not be accessible anymore.
            If user logged in via Social Network, their account will be unlinked
          </p>

          <p>
            Please, do not forget to copy IDFAs before you continue as there will be NO other chance to find it in order
            to send data deletion request to 3rd party partners
          </p>

          {anonymisableDevices.map((device) => (
            <PageSection title={`Device #${device.id}`}>
              <CopyableData
                key={device.id}
                data={device.idfa}
                onCopy={() => setIdfaCopiedForDeviceIds([...idfaCopiedForDeviceIds, device.id])}
              />
            </PageSection>
          ))}
        </>
      </Modal.Body>

      <Modal.Footer>
        <IconButton.Cancel onClick={onCancel}>No</IconButton.Cancel>
        <IconButton.Confirm onClick={guardedOnConfirm} disabled={confirmationDisabled}>Yes</IconButton.Confirm>
      </Modal.Footer>
    </Modal>
  );
}
