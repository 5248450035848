import React, { useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Row, Col } from 'react-bootstrap';
import { useQuery } from '@hooks';
import { getLevelLayoutsIds } from '@requests/my-pet-cafe/level-layouts';
import updateQuery from '@services/updateQuery';

import Select from '@controls/Select';

export default function SearchBar({ showOnlyModifiedLevels, setShowOnlyModifiedLevels }) {
  const optionsForOrdering = [
    { label: 'ASC', value: 'asc' },
    { label: 'DESC', value: 'desc' },
  ];

  const optionsForFields = [
    { label: 'Level layout ID', value: 'level_layout_id' },
    { label: 'Updated date', value: 'updated_at' },
  ];

  const { response } = useQuery(getLevelLayoutsIds);

  const [ordering, setOrdering] = useState(optionsForOrdering[0].value);
  const [field, setField] = useState(optionsForFields[0].value);
  const [layoutId, setLayoutId] = useState();
  const router = useRouter();

  const onClickLayoutId = (data) => {
    const levelLayoutId = data && data.value;

    setLayoutId(levelLayoutId);
    updateQuery(router, { levelLayoutId }, () => { }, { method: 'replace' });
  };

  const onClickOrdering = (data) => {
    const selected = data.value;

    setOrdering(selected);
    updateQuery(router, { ordering: selected, field }, () => { }, { method: 'replace' });
  };

  const onClickField = (data) => {
    const selected = data.value;

    setField(selected);
    updateQuery(router, { ordering, field: selected }, () => { }, { method: 'replace' });
  };

  return response ? (
    <>
      <Row className="align-items-center text-center">
        <Col xs={3}>
          <Select
            selectedValue={layoutId}
            onChange={onClickLayoutId}
            options={response.layoutIds}
            placeholder="Search level layout ID"
            isClearable
          />
        </Col>
        <Col xs={2}>
          <input
            type="checkbox"
            className="me-2"
            onClick={() => setShowOnlyModifiedLevels(!showOnlyModifiedLevels)}
          />
          <span>Show only modified levels</span>
        </Col>
        <Col xs={{ offset: 2, span: 1 }}>
          Sort by
        </Col>
        <Col xs={2}>
          <Select
            selectedValue={field}
            onChange={onClickField}
            options={optionsForFields}
            hasPrepopulatedOption
          />
        </Col>
        <Col xs={2}>
          <Select
            selectedValue={ordering}
            onChange={onClickOrdering}
            options={optionsForOrdering}
            hasPrepopulatedOption
          />
        </Col>
      </Row>
      <hr />
    </>
  ) : '';
}
