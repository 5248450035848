import React, { useState, useMemo } from 'react';

import { LocalizationsRoutes } from '@pages/routes';

import { useQuery, useConfirm, useCurrentApplication } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
  DuplicationModal,
  Filter,
} from '@pages/common';

import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import IconButton from '@controls/buttons';
import ColumnsFactory from './ColumnsFactory';

export default function ConfigsIndex() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(LocalizationsRoutes.Configs.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { currentApplication } = useCurrentApplication();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Localization config',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Localization Config '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => LocalizationsRoutes.Configs.deleteRequest({ id, applicationId: currentApplication.id })
        .then(responseHandler));
  };

  const onDuplicate = (values) => (
    LocalizationsRoutes.Configs.duplicateRequest({ ...values, applicationId: currentApplication.id })
  );

  const columns = useMemo(() => ColumnsFactory({
    currentApplication, setDuplicatedRecord, onDelete,
  }), []);

  return response ? (
    <div>
      <PageHeader
        title="Localisations: Settings Configs"
        userGuideUrlKey="localizationsGuideUrl"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
      >
        <IconButton.New to={LocalizationsRoutes.Configs.newPath({ applicationId: currentApplication.id })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Localization config"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={onDuplicate}
        title="Duplicate Localization config"
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  ) : '';
}
