import React from 'react';
import { PageTitle } from '@pages/common';

import AppUsersIndex from '@pages/users/app-users/Index';
import AppUsersEdit from '@pages/users/app-users/Edit';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function users() {
  const scope = '/admin/applications/:applicationId';

  return [
    <Route
      key={`${scope}/devices`}
      path={`${scope}/devices`}
      element={(
        <RouteContainer>
          <PageTitle text="Devices" />
          <AppUsersIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/users/app_users/:id/edit`}
      path={`${scope}/users/app_users/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="App User" action="Edit" />
          <AppUsersEdit />
        </RouteContainer>
    )}
    />,
  ];
}
