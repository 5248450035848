import React from 'react';
import { PageTitle } from '@pages/common';

import LevelSetsIndex from '@pages/level-sets/Index';
import LevelSetsNew from '@pages/level-sets/New';
import LevelSetsEdit from '@pages/level-sets/Edit';
import ExperimentScoped from '@pages/level-sets/ExperimentScoped';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function levelSets() {
  const scope = '/admin/applications/:applicationId/level_sets';

  return [
    <Route
      key={scope}
      path={scope}
      element={(
        <RouteContainer>
          <PageTitle text="Level Sets" />
          <LevelSetsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/new`}
      path={`${scope}/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Set" action="New" />
          <LevelSetsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/:id/edit`}
      path={`${scope}/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Set" action="Edit" />
          <LevelSetsEdit />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/:id/experiment_scoped/:experimentId`}
      path={`${scope}/:id/experiment_scoped/:experimentId`}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Set A/B Experiment Variant" action="Edit" />
          <ExperimentScoped />
        </RouteContainer>
    )}
    />,
  ];
}
