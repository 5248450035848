import React from 'react';

import requestSchemaOptions from '@components/merge/services/requestEventSchemaOptions';
import ItemConfigurationSetsNew from '../../item-configuration-sets/New';

export default function New(props) {
  return (
    <ItemConfigurationSetsNew
      requestSchemaOptions={requestSchemaOptions}
      {...props}
    />
  );
}
