import React from 'react';

import { Col } from 'react-bootstrap';
import { Filter, TextFilter, SelectFilter } from '@pages/common';
import { fromClassNameToOptions } from '@services/enums';
import { useLanguagesContext } from './shared/services/useLanguages';

export default function TranslationsFilter() {
  const typeOptions = fromClassNameToOptions('Localizations::TranslationTypes');
  const { allLanguages } = useLanguagesContext();

  const languageOptions = allLanguages.map(
    ({ name, code }) => ({ label: `${name} (${code.toUpperCase()})`, value: code }),
  );
  const enLanguageId = allLanguages.find((l) => l.isDefault).id;

  return (
    <Filter
      skipFiltersOnCount={['translationsLanguageIdEq']}
      collapsible
    >
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <SelectFilter
              name="codeIContAny"
              label="Language = "
              placeholder="<Language name> (code)"
              className="zindex-10"
              options={languageOptions}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="keyNameIContAny"
              label="Key = "
              placeholder="Start to write..."
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="translationsValueIContAny"
              label="EN translation includes = "
              placeholder="Start to write..."
              updateFilterField={(newFilter) => (
                updateFilterField({ ...newFilter, translationsLanguageIdEq: enLanguageId })
              )}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="formatMatchesAny"
              label="Type = "
              placeholder="Select type"
              className="zindex-10"
              options={typeOptions}
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
