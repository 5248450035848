import APP_DATA from '@root/services/appData';

import Board9x9 from './9x9';
import Board6x9 from './6x9';

const boardGridTypes = APP_DATA.enums['Woodoku::BoardGridTypes'];

export default {
  [boardGridTypes.BOARD_ID_0]: Board9x9, // 9x9 sudoku
  [boardGridTypes.BOARD_ID_1]: Board9x9, // 9x9 no sudoku
  [boardGridTypes.BOARD_ID_2]: Board6x9, // 6x9 sudoku
  [boardGridTypes.BOARD_ID_3]: Board6x9, // 6x9 no sudoku
};
