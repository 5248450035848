import React from 'react';

import { useFormContext, useRewardOptions, FormGroup } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import { Rewards, PageSection } from '@pages/common';
import {
  Field,
  SelectField,
  localizedLabelFactory,
  ServerError,
} from '@controls/form';
import { gachaRewardItemTypes } from '@pages/my-pet-cafe/constants';

import ListWrapper from '@components/shared/ListWrapper';

const LocalizedLabel = localizedLabelFactory('myPetCafe.soloMissions.eventBanks.events.milestones');

export default function Milestones({ milestones, eventIndex, disabled }) {
  const { dispatch } = useFormContext();
  const rewardOptions = useRewardOptions();
  const gachaRewardOptions = useRewardOptions({ rewardTypeIn: gachaRewardItemTypes, rewardTypeNotIn: null });

  const boxTypes = fromClassNameToOptions('MyPetCafe::SoloMissions::Milestones::BoxTypes');

  const levelsPath = `eventsAttributes.${eventIndex}.milestonesAttributes`;

  const onEntityAdd = () => dispatch({
    actionType: 'addEmptyEntity',
    levelsPath,
    options: {
      generalRewardAttributes: { rewardAffiliationsAttributes: [] },
      gachaRewardAttributes: { rewardAffiliationsAttributes: [] },
    },
  });

  return (
    <div className="mb-4">
      <ListWrapper
        withReorderIconPadding
        entityAttributes={milestones}
        onEntityAdd={onEntityAdd}
        attributesName="milestonesAttributes"
        levelsPath={levelsPath}
        metaErrorsPath={['errors', 'eventsAttributes', eventIndex, 'milestonesAttributes']}
        entityNameTranslationPath="soloMissions.eventBanks.events.milestones.name"
        disabled={disabled}
        withMassOperations={false}
      >
        {({ entity: { generalRewardAttributes, gachaRewardAttributes }, readOnly }) => (
          <>
            <LocalizedLabel name="goal">
              <Field type="number" name="goal" disabled={readOnly} />
            </LocalizedLabel>
            <LocalizedLabel name="boxType">
              <SelectField
                includeEmpty
                name="boxType"
                options={boxTypes}
                isDisabled={readOnly}
              />
            </LocalizedLabel>
            <PageSection title="Rewards" />
            <FormGroup name="generalRewardAttributes">
              <Rewards
                hideQuantityUntilSelected
                dispatch={dispatch}
                rewardable={generalRewardAttributes}
                rewardItems={rewardOptions}
                readOnly={readOnly}
              />
            </FormGroup>
            <PageSection title="Gacha box for replacing Rewards" />
            <FormGroup name="gachaRewardAttributes">
              <Rewards
                hideQuantityUntilSelected
                dispatch={dispatch}
                rewardable={gachaRewardAttributes}
                rewardItems={gachaRewardOptions}
                fixedQuantity
              />
            </FormGroup>
            <ServerError name="rewardAffiliationsAttributes.list" />
          </>
        )}
      </ListWrapper>
    </div>
  );
}
