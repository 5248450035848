import React, { useEffect, useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { Form } from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup, ServerError,
} from '@controls/form';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { PageHeader, PageSection } from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';
import AbilitiesFormGroup from './entities/FormGroup';
import reducer from './formReducer';

export default function AbilitySetsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const [allNameOptions, setAllNameOptions] = useState([]);
  const [impactOptions, setImpactOptions] = useState([]);
  const disabled = isInUse(data.inUse);

  useEffect(() => {
    requestWithToSelectOptions(
      Routes.Abilities.Names.indexRequest,
      applicationId,
      { includeInUse: false, withoutPagination: true },
    ).then(setAllNameOptions);
  }, []);
  useEffect(() => {
    requestWithToSelectOptions(
      Routes.Abilities.Impacts.indexRequest,
      applicationId,
      { includeInUse: false, withoutPagination: true },
    ).then(setImpactOptions);
  }, []);

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit} reducer={reducer}>
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Ability Set`}>
            <FormButtonsGroup
              cancelButtonPath={Routes.Abilities.Sets.indexPath({ applicationId })}
            />
          </PageHeader>
          <Label required text="Name">
            <Field name="name" disabled={disabled} />
          </Label>
          <StaticFieldsFormGroup data={data} />
          <PageSection
            title="Abilities"
            tooltip="Create a list of abilities and the area of effect which should be used for this ability"
          />
          <ServerError name="entitiesAttributes" />
          <AbilitiesFormGroup
            values={values}
            impactOptions={impactOptions}
            allNameOptions={allNameOptions}
            disabled={disabled}
          />
        </>
      )}
    </Form>
  );
}
