import React from 'react';
import { components } from 'react-select';
import some from 'lodash/some';
import every from 'lodash/every';
import toLower from 'lodash/toLower';

import ColoredBadge from '@pages/common/ColoredBadge';

const LabeledText = ({ label, labels }) => (
  <>
    {label}
    {labels?.map(({ name, color }) => (
      <ColoredBadge pill={false} hexColor={color} text={name} className="ms-1 p-1" />),
    )}
  </>
);

const MultiValueLabel = ({ data: { label, labels }, ...props }) => (
  <components.MultiValueLabel {...props}>
    <LabeledText label={label} labels={labels} />
  </components.MultiValueLabel>
);

const SingleValue = ({ data: { label, labels }, ...props }) => (
  <components.SingleValue {...props}>
    <LabeledText label={label} labels={labels} />
  </components.SingleValue>
);

const Option = ({ data: { label, labels }, ...props }) => (
  <components.Option {...props}>
    <LabeledText label={label} labels={labels} />
  </components.Option>
);

const componentsOverride = { MultiValueLabel, SingleValue, Option };

const shouldDisplay = (string, substring) => toLower(string).includes(substring);
const matchEveryPart = ({ data: { label, labels } }, inputValue) => {
  const pattern = toLower(inputValue).split(' ');

  return every(
    pattern,
    (subString) => (
      shouldDisplay(label, subString)
      || some(labels, ({ name }) => shouldDisplay(name, subString))
    ),
  );
};

export default function labelableSelect(Component) {
  return (props) => <Component {...props} components={componentsOverride} filterOption={matchEveryPart} />;
}
