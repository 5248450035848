import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { PaymentsRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Store Product',
    actionName: 'updating',
    collectionUrl: PaymentsRoutes.StoreProducts.indexPath({ applicationId: query.applicationId }),
    setData,
  });

  const onSubmit = (values) => PaymentsRoutes.StoreProducts.updateRequest(values).then(responseHandler);

  useEffect(() => {
    PaymentsRoutes.StoreProducts.editRequest(query).then((response) => {
      setData(response.data);
    });
  }, []);

  return data && <Form actionName="Update" data={data} onSubmit={onSubmit} />;
}
