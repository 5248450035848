const plainAlert = ({ message, timeout = 2000 }) => {
  const alert = document.createElement('div');

  alert.innerHTML = `
    <div class="alert-fixed" id="plain-alert">
      <div class="alert alert-danger alert-dismissible fade show shadow-lg" role="alert">
        <p class="mt-2">${message}</p>
        <span class="btn-close" type="button" onclick="document.getElementById('plain-alert').remove()" />
      </div>
    </div>`;

  document.body.appendChild(alert);

  setTimeout(() => alert.remove(), timeout);
};

export default plainAlert;
