import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import { useFormContext } from '@hooks';

export default function CancelModal({
  show,
  setShow,
  onSave,
  onDiscard,
}) {
  const { values } = useFormContext();

  const closeModal = () => setShow(false);

  const handleSaveClick = () => {
    onSave(values);
    closeModal();
  };

  const handleDiscardClick = () => {
    onDiscard();
    closeModal();
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Review changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You have made changes. Do you want to discard or save them?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        <Button variant="danger" onClick={handleDiscardClick}>Discard</Button>
        <Button variant="primary" onClick={handleSaveClick}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}
