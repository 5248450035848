import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader, Pagination, ReactTable,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import IconButton from '@controls/buttons';

import {
  getLevelLayouts, deleteLevelLayout,
} from '../requests/levelLayoutsRequests';
import ColumnsFactory from './ColumnsFactory';

export default function LevelLayoutsIndex() {
  const { response, refetch } = useQuery(getLevelLayouts, { includeInUse: true });
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Level layout',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `Level Layout '${name}' will be deleted and not available for work. Continue?` })
      .then(() => deleteLevelLayout({ applicationId, id })
        .then(responseHandler));
  };

  return response ? (
    <div>
      <PageHeader title="Level Layouts">
        <IconButton.New to="new" />
      </PageHeader>

      <ReactTable
        columns={ColumnsFactory({ onDelete })}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  ) : '';
}
