import React, { useMemo } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { WoodokuRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';

const columnsFactory = ({ applicationId, onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Type ID', accessor: 'typeId' },
  { Header: 'Family', accessor: 'family' },
  { Header: 'Layer', accessor: 'layer' },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, inUse } } }) => {
      const disabled = isInUse(inUse);

      return (
        <ButtonToolbar>
          <IconButton.Edit to={WoodokuRoutes.TileTypes.editPath({ id, applicationId })} minimized />
          <IconButton.Delete onClick={onDelete(id, name)} disabled={disabled} minimized />
        </ButtonToolbar>
      );
    },
  },
];

export default function Index() {
  const { response, refetch } = useQuery(WoodokuRoutes.TileTypes.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Tile Type',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `Tile Type '${name}' will be deleted and not available for work. Continue?` })
      .then(() => WoodokuRoutes.TileTypes.deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({ applicationId, onDelete }), []);

  return response && (
    <div>
      <PageHeader title="Tile Types" filter={<Filter />}>
        <IconButton.New to={WoodokuRoutes.TileTypes.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
