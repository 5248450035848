import { useState } from 'react';
import { includes, xor, union } from 'lodash';

export default function useExpanded() {
  const [expanded, setExpanded] = useState([]);

  const toggleExpanded = (key) => (setExpanded((prev) => xor(prev, [key])));
  const expand = (key) => (setExpanded((prev) => union(prev, [key])));
  const isExpanded = (key) => includes(expanded, key);

  return {
    expanded,
    setExpanded,
    toggleExpanded,
    expand,
    isExpanded,
  };
}
