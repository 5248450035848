import React, { useState, useMemo } from 'react';

import { useCurrentApplication } from '@hooks';
import IconButton from '@controls/buttons';

import DuplicationModalWithPriority from './DuplicationModalWithPriority';
import DuplicationModal from './DuplicationModal';

export default function DuplicateButton({
  data, entityName, routes, onSubmit, name = 'name', modalType = 'plain', ...buttonProps
}) {
  const [record, setRecord] = useState(null);
  const { applicationId } = useCurrentApplication();

  if (!data.id) return null;

  const renderModal = useMemo(() => {
    const modalProps = {
      record,
      entityName,
      name,
      handleClose: () => setRecord(null),
      onSubmit: onSubmit || ((value) => routes.duplicateRequest({ ...value, applicationId })),
    };
    switch (modalType) {
      case 'plain': return <DuplicationModal {...modalProps} />;
      case 'withPriority': return (
        <DuplicationModalWithPriority {...modalProps} entitiesByPriority={data.entitiesByPriority || {}} />
      );
      case null: throw new Error('You must provide duplication modal type');
      default: {
        const Modal = modalType;
        return <Modal {...modalProps} />;
      }
    }
  }, [JSON.stringify(record)]);

  return (
    <>
      <IconButton.Duplicate
        onClick={() => setRecord(data)}
        {...buttonProps}
      />
      {renderModal}
    </>
  );
}
