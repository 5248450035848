import React from 'react';
import { get } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { useFormContext, FormGroup, useQuery } from '@hooks';
import {
  Label,
  Field,
  SelectField,
} from '@controls/form';

import { FigureSetsRoutes } from '@pages/routes';

import LevelBanks from '@pages/journeys/level-banks/EventsBlock';

export const ConfigurationVariant = React.memo(({
  values, defaultVariant = false, variantIndex, isArchived,
}) => {
  const { query } = useRouter();
  const { dispatch } = useFormContext();

  const { applicationId } = query;

  const onBankAdd = (id) => dispatch({ actionType: 'addLevelBankToJourney', id, variantIndex });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeLevelBankFromJourney', id, variantIndex,
  });

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  const { response: figureSets, isLoading: figureSetsLoading } = useQuery({
    request: FigureSetsRoutes.Sets.indexRequest,
    queryKey: ['figureSets', applicationId],
    toSelectOptions: true,
  });

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Figure Set">
        <SelectField
          name="figureSetId"
          options={figureSets}
          isLoading={figureSetsLoading}
        />
      </Label>

      <LevelBanks
        disabled={isArchived}
        validateLevels={false}
        variantAttributes={get(values, variantAttributesKey, values)}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
        eventType="endOfJourneyEvent"
      />
    </FormGroup>
  );
});

const WoodokuConfigurationForm = React.memo(({ values, defaultVariant = false, isArchived }) => (
  <>
    <Label
      text="Minimum supported version"
      tooltipText="Could be used to encourage players to update the client version"
    >
      <Field type="text" name="minimumSupportedVersion" />
    </Label>
    <ConfigurationVariant values={values} defaultVariant={defaultVariant} isArchived={isArchived} />
  </>
));

export default WoodokuConfigurationForm;
