import React from 'react';

import { Alert as BootstrapAlert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faTriangleExclamation,
  faCircleExclamation,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';

const ICONS = {
  success: faCircleCheck,
  danger: faTriangleExclamation,
  warning: faCircleExclamation,
  info: faCircleInfo,
};

export default function Alert({ variant = 'danger', children }) {
  return (
    <BootstrapAlert variant={variant}>
      <FontAwesomeIcon icon={ICONS[variant]} className="me-2" size="lg" />
      {children}
    </BootstrapAlert>
  );
}
