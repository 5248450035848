import React from 'react';
import { useFlashMessages, PageSection } from '@tripledotstudios/react-core';

import { WoodokuRoutes } from '@pages/routes';
import { DuplicateButton, PageHeader } from '@pages/common';
import IconButton from '@controls/buttons';

import { GlobalAttributes } from '@pages/journeys/level-banks/configuration-forms/woodoku/index';

import APP_DATA from '@services/appData';

import {
  Form, useCurrentApplication, useConfirm,
} from '@hooks';

import {
  Label,
  Field,
  SelectField,
  StaticFieldsFormGroup,
  FormButtonsGroup,
  LabelsFormGroup,
} from '@controls/form';

import { fromClassNameToOptions } from '@services/enums';

import buildOnSubmit from '@services/journeys/banks/buildOnSubmit';

import { combineReducers, rulesReducer } from '@reducers';
import formReducer from '@pages/journeys/level-banks/formReducer';
import woodokuBankReducer from '@pages/journeys/level-banks/app-reducers/woodokuReducer';

import Levels from './form/Levels';

const { enums } = APP_DATA;
const statusesEnum = enums['Woodoku::BonusLevels::LevelBankStatuses'];
const statuses = fromClassNameToOptions('Woodoku::BonusLevels::LevelBankStatuses');
const tilesGenerationOptions = fromClassNameToOptions('Woodoku::DailyChallenges::TilesGenerationTypes', {
  transformLabel: (label) => {
    switch (label) {
      case 'Default':
        return 'Default (spawn acc to tile_spawn_chance)';
      case 'Exclude Reached Goal':
        return 'Stop spawn when collected';
      default:
        return label;
    }
  },
});

export default function LevelBankForm({
  data, title, onSubmit: onPageSubmit,
}) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const confirm = useConfirm();
  const { error } = useFlashMessages();

  const { status, readOnly } = data;

  const isArchived = status === statusesEnum.ARCHIVED;

  const onSubmit = buildOnSubmit({
    applicationId,
    data,
    entityName: 'Bonus Levels Bank',
    statusesEnum,
    onSubmitFunction: onPageSubmit,
    confirm,
    error,
    routes: WoodokuRoutes.BonusLevels.LevelBanks,
  });

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([rulesReducer, formReducer, woodokuBankReducer])}
      sharedInputProps={{ disabled: readOnly || isArchived }}
    >
      {({ values: { id, levelsAttributes } }) => (
        <>
          <PageHeader title={title}>
            <FormButtonsGroup
              cancelButtonPath={WoodokuRoutes.BonusLevels.LevelBanks.indexPath({ applicationId })}
            >
              {id && (
                <>
                  <IconButton.ActivityLog
                    applicationId={applicationId}
                    id={id}
                    entityType="Woodoku::BonusLevels::LevelBanks::Bank"
                  />
                  <FormButtonsGroup.Divider />
                </>
              )}
              <DuplicateButton
                routes={WoodokuRoutes.BonusLevels.LevelBanks}
                data={data}
                entityName="Bonus Levels Bank"
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label text="Name">
            <Field type="text" name="name" disabled={readOnly} />
          </Label>
          <Label text="Status">
            <SelectField name="status" options={statuses} />
          </Label>

          <LabelsFormGroup disabled={readOnly} />

          <StaticFieldsFormGroup data={data} />
          <GlobalAttributes levelsAttributes={levelsAttributes} levelsPath="levelsAttributes" readOnly={readOnly} />

          <PageSection title="Settings">
            <Label
              text="Spawn generation type"
              tooltipText="When “Stop spawn when collected” is selected → the system stops
            spawning special tile in FB when tile is collected"
            >
              <SelectField
                options={tilesGenerationOptions}
                name="tilesGenerationType"
              />
            </Label>
          </PageSection>

          <Levels levelsAttributes={levelsAttributes} readOnly={readOnly || isArchived} />
        </>
      )}
    </Form>
  );
}
