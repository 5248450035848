import React from 'react';
import { get } from 'lodash';
import { Alert } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import APP_DATA from '@services/appData';

import { useFormContext, FormGroup, useQuery } from '@hooks';
import {
  Label,
  Field,
  SelectField,
  AssetsKeySelectField,
} from '@controls/form';
import { PageSection } from '@pages/common';

import {
  LocalizationsRoutes, AssetsRoutes, FigureSetsRoutes, WoodokuRoutes,
} from '@pages/routes';

import PopupSections from '@pages/journeys/shared/PopupSections';
import LocalPushNotificationSettings from '@pages/journeys/shared/woodoku/LocalPushNotificationSettings';

import LevelBanks from '@pages/journeys/level-banks/EventsBlock';

export const ConfigurationVariant = ({
  values, defaultVariant = false, variantIndex, isArchived,
}) => {
  const { enums, modelsLocales: { woodoku: { journeys: { tooltips } } } } = APP_DATA;
  const hardJourneyStatuses = APP_DATA.enums['Woodoku::HardJourneyStatuses'];

  const { query } = useRouter();
  const { dispatch } = useFormContext();

  const { applicationId } = query;

  const onBankAdd = (id) => dispatch({ actionType: 'addLevelBankToJourney', id, variantIndex });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeLevelBankFromJourney', id, variantIndex,
  });

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  const assetTypesEnum = enums['Woodoku::AssetTypes'];

  const usedAssetTypes = [
    assetTypesEnum.JOURNEY_MAIN,
    assetTypesEnum.JOURNEY_REWARDS,
    assetTypesEnum.JOURNEY_GEMS,
    assetTypesEnum.DC_GEMS,
    assetTypesEnum.JOURNEY_POPUP,
    assetTypesEnum.JOURNEY_LOBBY_THEME,
  ];

  const { response: assetKeys } = useQuery({
    request: ({ ...params }) => AssetsRoutes.Keys.indexRequest({
      ...params,
      filter: { withTypesOrIds: { types: usedAssetTypes, ids: null } },
    }),
    processResponse: (response) => response.data.items,
    queryKey: ['journeyAssets', applicationId],
    toSelectOptions: true,
  });

  const { response: figureSets, isLoading: figureSetsLoading } = useQuery({
    request: FigureSetsRoutes.Sets.indexRequest,
    queryKey: ['figureSets', applicationId],
    toSelectOptions: true,
  });

  const { response: hardJourneys, isLoading: hardJourneysLoading } = useQuery({
    request: WoodokuRoutes.HardJourneys.indexRequest,
    additionalRequestParams: { withoutPagination: true, filter: { statusIn: [hardJourneyStatuses.ACTIVE] } },
    queryKey: ['hardJourneys', applicationId],
    toSelectOptions: true,
  });

  const { response: localizationKeys } = useQuery({
    request: LocalizationsRoutes.Keys.indexRequest,
    queryKey: ['localizationKeys', applicationId],
    toSelectOptions: true,
  });

  const filterAssetKeys = (types) => {
    const wrappedTypes = [types].flat();

    return (
      assetKeys.filter(({ filteringTypesValues }) => (
        !filteringTypesValues.length || wrappedTypes.some((type) => filteringTypesValues.includes(type))
      ))
    );
  };

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Default Title">
        <Field name="defaultTitle" />
      </Label>
      <Label text="Default Subtitle">
        <Field name="defaultSubtitle" />
      </Label>
      <Label text="Title Translation Key">
        <SelectField name="titleTranslationKeyId" options={localizationKeys} />
      </Label>
      <Label text="Subtitle Translation Key">
        <SelectField name="subtitleTranslationKeyId" options={localizationKeys} />
      </Label>
      <Label text="Show Timer On UI" tooltipText={tooltips.config.showTimer}>
        <Field type="checkbox" name="showTimer" />
      </Label>
      <Label text="Figure Set">
        <SelectField
          name="figureSetId"
          options={figureSets}
          isLoading={figureSetsLoading}
        />
      </Label>
      <Label text="Ads off for Journey level X and Below" tooltipText={tooltips.config.adsOffJourneyLevelX}>
        <Field type="number" name="adsOffJourneyLevelX" />
      </Label>

      <Label text="Hard Journey Config" tooltipText={tooltips.config.hardJourney}>
        <SelectField
          includeEmpty
          name="hardJourneyId"
          options={hardJourneys}
          isLoading={hardJourneysLoading}
        />
      </Label>

      <PageSection title="Asset Bundles:" />
      <Label text="Asset: Main Bundle">
        <AssetsKeySelectField
          name="assetKeyId"
          options={filterAssetKeys(assetTypesEnum.JOURNEY_MAIN)}
        />
      </Label>
      <Label text="Asset: Rewards Bundle">
        <AssetsKeySelectField
          name="assetRewardsKeyId"
          options={filterAssetKeys(assetTypesEnum.JOURNEY_REWARDS)}
        />
      </Label>
      <Label text="Asset: Gems Bundle">
        <AssetsKeySelectField
          name="assetGemsKeyId"
          options={filterAssetKeys([assetTypesEnum.JOURNEY_GEMS, assetTypesEnum.DC_GEMS])}
        />
      </Label>
      <Label text="Asset: Journey Popups">
        <AssetsKeySelectField
          name="assetJourneyPopupsKeyId"
          options={filterAssetKeys([assetTypesEnum.JOURNEY_POPUP])}
          includeEmpty
        />
        <Alert variant="info" className="mt-2">
          <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
          &quot;Journey Popups&quot; is available from version 2.1.0
        </Alert>
      </Label>
      <Label text="iOS Asset Bundle URL">
        <Field type="text" name="iosAssetBundleUrl" />
      </Label>
      <Label text="Android Asset Bundle URL">
        <Field type="text" name="androidAssetBundleUrl" />
      </Label>
      <Label text="Amazon Asset Bundle URL">
        <Field type="text" name="amazonAssetBundleUrl" />
      </Label>
      <Label text="Asset: Journey lobby theme">
        <AssetsKeySelectField
          name="assetJourneyLobbyThemeKeyId"
          options={filterAssetKeys([assetTypesEnum.JOURNEY_LOBBY_THEME])}
          includeEmpty
        />
        <Alert variant="info" className="mt-2">
          <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
          &quot;Journey UI Theme&quot; is available from version v3.09
        </Alert>
      </Label>

      <PopupSections localizationKeys={localizationKeys} />

      <LocalPushNotificationSettings
        values={get(values, variantAttributesKey, values)}
        localizationKeyOptions={localizationKeys}
        disabled={isArchived}
      />

      <LevelBanks
        disabled={isArchived}
        validateLevels
        variantAttributes={get(values, variantAttributesKey, values)}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
        eventType="event"
      />
    </FormGroup>
  );
};

const WoodokuConfigurationForm = React.memo(({ values, defaultVariant = false, isArchived }) => (
  <>
    <Label
      text="Minimum supported version"
      tooltipText="Could be used to encourage players to update the client version"
    >
      <Field type="text" name="minimumSupportedVersion" />
    </Label>

    <ConfigurationVariant values={values} defaultVariant={defaultVariant} isArchived={isArchived} />
  </>
));

export default WoodokuConfigurationForm;
