import ExperimentScopedTabs from '@pages/ab-testing/experiments/ExperimentScopedTabs';
import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { catalogReducer } from '@reducers';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { PaymentsRoutes as Routes } from '@pages/routes';

import Variant from './Variant';

export default function ExperimentScoped() {
  const { response, setResponse } = useQuery(Routes.Catalogs.experimentScopedRequest);
  const { query: { applicationId } } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Payments catalog variant',
    actionName: 'update',
    setData: setResponse,
  });

  const handleSubmit = (values) => Routes.CatalogVariants.updateVariantsRequest({ ...values, applicationId })
    .then(responseHandler);

  return (
    <ExperimentScopedTabs
      response={response}
      entityName="Payment catalog"
      handleSubmit={handleSubmit}
      reducer={catalogReducer}
    >
      {({ variantAttributes, variantIndex, dispatch }) => (
        <Variant data={variantAttributes} dispatch={dispatch} variantIndex={variantIndex} />
      )}
    </ExperimentScopedTabs>
  );
}
