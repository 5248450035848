import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import { useRouter } from '@tripledotstudios/react-core';

import Tooltip from '@controls/Tooltip';
import { AppUserRoutes } from '@pages/routes';
import { PageSection } from '@pages/common';

const AggregatedStats = styled.div`
  font-size: 1.2rem;

  b {
    font-size: inherit;
  }
`;

const PER_PAGE = 10;

const IntentsTable = ({ intents }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const onPageClick = ({ selected: selectedPage }) => setCurrentPage(selectedPage);
  const offset = currentPage * PER_PAGE;
  const paginatedIntents = intents.slice(offset, offset + PER_PAGE);
  const pageCount = Math.ceil(intents.length / PER_PAGE);

  return (
    <>
      <PageSection
        title="Intents Log"
        tooltip="List of intents that the client sends to server when the player initiates the purchase by
                 pressing 'Buy' button. If there is no transaction related to the intent then it means that the
                 purchase wasn't proceed yet."
      />
      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Server Product ID</th>
            <th>Store Product ID</th>
            <th>Created at (UTC)</th>
            <th>Updated at (UTC)</th>
            <th>Transaction ID</th>
            <th>
              <Tooltip text="The intent is troublesome if the client tried to duplicate it (sends another one for the
                             same server product without finishing the existed one)"
              >
                Troublesome
              </Tooltip>
            </th>
            <th>
              <Tooltip text="Shows if the troublesome intent was revoked by client">
                Revoked at (UTC)
              </Tooltip>
            </th>
            <th>
              Config ref
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedIntents.map((intent) => (
            <tr key={intent.id}>
              <td>{intent.id}</td>
              <td>{intent.productId}</td>
              <td>{intent.storeProductStoreId}</td>
              <td>{intent.createdAt}</td>
              <td>{intent.updatedAt}</td>
              <td>{intent.transactionId}</td>
              <td>{intent.troublesome ? 'yes' : 'no'}</td>
              <td>{intent.troublesomeAt}</td>
              <td>{intent.configRef}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ReactPaginate
        containerClassName="pagination mb-4"
        breakClassName="page-item"
        breakLabel={<Button className="page-link">...</Button>}
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeClassName="active"
        onPageChange={onPageClick}
        pageCount={pageCount}
        disableInitialCallback
      />
    </>
  );
};

const TransactionsTable = ({ paymentsCache, transactions }) => (
  <>
    <PageSection
      title="Transactions"
      tooltip="List of transactions created for the player. Every transaction relates to particular reward entry
               and intent entry."
    />
    {paymentsCache && (
      <AggregatedStats className="mb-3">
        <b>Total Spend: </b>
        {paymentsCache.totalPaymentAmount}
      </AggregatedStats>
    )}
    <Table bordered striped responsive>
      <thead>
        <tr>
          <th>ID</th>
          <th>External Transaction ID</th>
          <th>Store Product ID</th>
          <th>Game Version</th>
          <th>Sandbox?</th>
          <th>Purchase Metadata</th>
          <th>Created at (UTC)</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction) => (
          <tr key={transaction.id}>
            <td>{transaction.id}</td>
            <td>{transaction.uid}</td>
            <td>{transaction.storeProductStoreId}</td>
            <td>{transaction.appVersion}</td>
            <td>{transaction.sandbox ? 'Yes' : 'No'}</td>
            <td>{transaction.intentMeta}</td>
            <td>{transaction.createdAt}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </>
);

export default function Purchases({ id }) {
  const [data, setData] = useState();
  const { query: { applicationId } } = useRouter();

  useEffect(() => {
    AppUserRoutes.paymentsRequest({ applicationId, id }).then((response) => setData(response.data));
  }, []);

  return data ? (
    <>
      <IntentsTable intents={data.intents} />
      <TransactionsTable paymentsCache={data.paymentsCache} transactions={data.transactions} />
    </>
  ) : '';
}
