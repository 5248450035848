import React from 'react';
import { Label, Field } from '@controls/form';

export default function JiraSettings() {
  return (
    <Label text="Jira Project ID">
      <Field name="jiraProjectId" />
    </Label>
  );
}
