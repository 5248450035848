import React from 'react';
import { useCurrentApplication, useQuery } from '@hooks';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import { ArchivedSettingsRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { response, setResponse } = useQuery(Routes.editRequest);
  const { applicationId } = useCurrentApplication();

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Setting',
    actionName: 'Update',
    setData: setResponse,
    redirectPath: Routes.indexPath({ applicationId }),
  });

  const onSubmit = (values) => Routes.updateRequest(values).then(redirectIfSuccessHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      actionName="Edit"
    />
  );
}
