import React from 'react';
import { useRouter, Spinner } from '@tripledotstudios/react-core';

import { FigureSetsRoutes, WoodokuRoutes as Routes } from '@pages/routes';

import { FormGroup } from '@hooks';

import { Label, SelectField } from '@controls/form';

export default function BonusLevelsVariantForm({ currentVariant, figureSets, levelBanks }) {
  const { query: { applicationId } } = useRouter();

  if (!figureSets || !levelBanks) return <Spinner />;

  return (
    <FormGroup name={currentVariant ? 'currentVariantAttributes' : ''}>
      <Label required text="Figure Set">
        <SelectField
          name="figureSetId"
          options={figureSets}
          quickLinkFn={(figureSetId) => FigureSetsRoutes.Sets.editPath({ applicationId, id: figureSetId })}
        />
      </Label>
      <Label required text="Level Bank">
        <SelectField
          name="levelBankId"
          options={levelBanks}
          quickLinkFn={(bankId) => Routes.BonusLevels.LevelBanks.editPath({ applicationId, id: bankId })}
        />
      </Label>
    </FormGroup>
  );
}
