import APP_DATA from '@services/appData';
import deepFreeze from '@services/deepFreeze';

import BetUpModeRule from './BetUpModeRule';
import UsePreloadedPowerupRule from './UsePreloadedPowerupRule';
import NoPreloadedPowerupRule from './NoPreloadedPowerupRule';
import FirstTryRule from './FirstTryRule';

const DEFAULT_TAG = 'all';

const { enums: { 'Missions::RuleOperations': MissionsRuleOperations } } = APP_DATA;

const RULES_LIST = deepFreeze([
  {
    type: 'Missions::Targets::Rules::BetUpModeRule',
    operations: [
      MissionsRuleOperations.EQ,
      MissionsRuleOperations.GT,
      MissionsRuleOperations.GTEQ,
      MissionsRuleOperations.LT,
      MissionsRuleOperations.LTEQ,
    ],
    label: 'Bet Up Mode',
    component: BetUpModeRule,
    tags: ['my_pet_cafe'],
  },
  {
    type: 'Missions::Targets::Rules::UsePreloadedPowerupRule',
    operations: [
      MissionsRuleOperations.EQ,
      MissionsRuleOperations.NOT_EQ,
    ],
    label: 'Use Preloaded Powerup',
    component: UsePreloadedPowerupRule,
    tags: ['my_pet_cafe'],
  },
  {
    type: 'Missions::Targets::Rules::NoPreloadedPowerupRule',
    operations: [
      MissionsRuleOperations.EQ,
      MissionsRuleOperations.NOT_EQ,
    ],
    label: 'No Preloaded Powerup',
    component: NoPreloadedPowerupRule,
    tags: ['my_pet_cafe'],
  },
  {
    type: 'Missions::Targets::Rules::FirstTryRule',
    operations: [],
    label: 'First Try',
    component: FirstTryRule,
    tags: ['my_pet_cafe'],
  },
]);

export { RULES_LIST, DEFAULT_TAG };
