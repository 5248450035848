/* eslint-disable gs/plz-camel-case-for-action-names */
const DEFAULT_ENTITY = {
  difficulty: 0,
  name: '',
  figureSetId: null,
};

/* eslint-disable no-param-reassign, array-callback-return */
export default function dynamicConfigsReducer(state, action) {
  if (action.actionType === 'addEntity') {
    state.dynamicConfigEntitiesAttributes.push(DEFAULT_ENTITY);
  }

  if (action.actionType === 'removeEntity') {
    const { deleteAt } = action;

    state.dynamicConfigEntitiesAttributes[deleteAt]._destroy = true;
  }

  return state;
}
