import React from 'react';
import { Tab, Badge } from 'react-bootstrap';
import {
  findIndex, intersection, camelCase, includes, isString, without,
} from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { useFormContext } from '@hooks';
import TabsWrapper from '@controls/Tabs';
import TabContent from './TabContent';

export default function Tabs({
  fieldsFactory, enabledFieldsNames, tabsConfig = [], errors, abTestable, localeNamespace, focusedField, ...props
}) {
  const { values } = useFormContext();
  const { tabs: backendConfiguredTabs = {}, archivedSettings = [] } = values._data || {};
  const { query: { focus: routerFocusedFieldName } } = useRouter();
  const focusedFieldName = focusedField || routerFocusedFieldName;

  const allTabs = tabsConfig.map((tab) => {
    if (!tab.eventKey) return tab;

    const tabName = camelCase(tab.eventKey.substring(1));
    const tabsFromBackend = backendConfiguredTabs[tabName] || [];

    return { ...tab, fieldsNames: without([...tab.fieldsNames, ...tabsFromBackend], ...archivedSettings) };
  });

  const focusedTab = focusedFieldName && allTabs.find((tab) => (
    tab.fieldsNames?.find((fieldOrSection) => (
      isString(fieldOrSection)
        ? fieldOrSection === focusedFieldName
        : includes(fieldOrSection.fieldsNames, focusedFieldName)
    ))
  ));

  const focusedTabKey = (focusedTab && focusedTab.eventKey) || undefined;

  const findErrors = (fields) => (
    fields.some((fieldOrSection) => (
      isString(fieldOrSection)
        ? errors[fieldOrSection]
        : findIndex(fieldOrSection.fieldsNames, (field) => errors[field]) >= 0
    ))
  );

  return (
    <TabsWrapper {...props} defaultActiveKey={focusedTabKey} focusedKey={focusedTabKey}>
      {React.Children.toArray(allTabs.map(({ eventKey, title, fieldsNames }) => {
        if (eventKey === undefined) return null;

        const camelCasedFieldNames = enabledFieldsNames.map(camelCase);

        let enabledFieldsCountInTab = 0;
        const allFieldNames = fieldsNames.map((fieldOrSection) => {
          if (isString(fieldOrSection)) {
            const fieldName = camelCase(fieldOrSection);
            if (camelCasedFieldNames.indexOf(fieldName) >= 0) enabledFieldsCountInTab += 1;
            return fieldName;
          }

          const sectionFieldNames = fieldOrSection.fieldsNames.map(camelCase);
          enabledFieldsCountInTab += intersection(camelCasedFieldNames, sectionFieldNames).length;

          return { ...fieldOrSection, fieldsNames: sectionFieldNames };
        });

        const tabHasErrors = findErrors(allFieldNames);

        const titleElement = (
          <>
            {title}
            {enabledFieldsCountInTab !== 0 && (
              <Badge pill bg={tabHasErrors ? 'danger' : 'primary'} className="ms-2">
                {enabledFieldsCountInTab}
              </Badge>
            )}
          </>
        );

        return (
          <Tab
            mountOnEnter
            unmountOnExit
            className="mt-3"
            key={eventKey}
            eventKey={eventKey}
            title={titleElement}
            tabClassName={tabHasErrors ? 'text-danger' : ''}
            tabAttrs={{ 'data-testid': `tab_header_${eventKey.replace('#', '')}` }}
          >
            <TabContent
              abTestable={abTestable}
              fieldsFactory={fieldsFactory}
              fieldsNames={allFieldNames}
              localeNamespace={localeNamespace}
              focus={focusedFieldName}
            />
          </Tab>
        );
      }))}
    </TabsWrapper>
  );
}
