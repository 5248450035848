import React from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { uniqBy } from 'lodash';

import { RemoveIcon } from '@components/merge/shared/icons';
import Tooltip from '@controls/Tooltip';

import { FormGroup, useConfirm } from '@hooks';
import { Field, SelectField, ServerError } from '@controls/form';
import { BooleanIcon } from '@pages/common';
import IconButton from '@controls/buttons';

const Th = styled.th`
  ${(({ width }) => `width: ${width}`)}
`;

export default function ExpertsTab({ experts, items, dispatch }) {
  const confirm = useConfirm();

  const onExpertRemove = (_uuid, internalId) => {
    confirm.showConfirmation({
      title: `Expert ${internalId} will be removed and not available for work. Continue?`,
    }).then(() => dispatch({ actionType: 'removeExpert', _uuid }));
  };

  const itemsOptions = uniqBy(items, 'line').map(({ line }) => ({
    label: line,
    value: line,
  }));

  return experts.length > 0 && (
    <>
      <Table>
        <thead>
          <tr>
            <Th width="15%">Internal ID</Th>
            <Th width="10%">
              <Tooltip text="There is at least one expert configuration. The expert can't be edited.">
                Configured
              </Tooltip>
            </Th>
            <Th width="15%">Specialist items line</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {experts.map(({
            internalId, isConfigured, _uuid, _destroy,
          }, index) => (
            !_destroy && (
              <FormGroup name={`expertsAttributes.${index}`}>
                <tr>
                  <td>
                    <Field name="internalId" disabled={isConfigured} />
                  </td>
                  <td>
                    <BooleanIcon value={isConfigured} />
                  </td>
                  <td>
                    <SelectField
                      name="itemLine"
                      options={itemsOptions}
                      includeEmpty
                    />
                  </td>
                  <td>
                    {!isConfigured && (
                      <RemoveIcon
                        className="text-start"
                        onClick={() => onExpertRemove(_uuid, internalId)}
                      />
                    )}
                  </td>
                </tr>
              </FormGroup>
            )
          ))}
        </tbody>
      </Table>
      <ServerError name="expertsAttributes" />
      <IconButton.New onClick={() => dispatch({ actionType: 'addExpert' })}>Add Expert</IconButton.New>
    </>
  );
}
