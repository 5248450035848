import React from 'react';
import { faUsersRectangle as icon } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from 'styled-components';

import { NamedRuleGroupsRoutes } from '@pages/routes';
import { Sidebar } from '@tripledotstudios/react-core';

export default function NamedRuleGroupsNavigationItem({ applicationId, minimized }) {
  const theme = useTheme();

  return (
    <Sidebar.LinkItem
      color={theme.newYorkPink}
      icon={icon}
      to={NamedRuleGroupsRoutes.indexPath({ applicationId })}
      title="Named Rule Groups"
      minimized={minimized}
    />
  );
}
