import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { Label, Field, SelectField } from '@controls/form';
import { useFormContext } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';

import { PaymentsRoutes } from '@pages/routes';

const { enums, modelsLocales: { merge: { products: { tooltips } } } } = APP_DATA;
const priceTypesEnum = enums['Merge::ProductPriceTypes'];

const UsdPriceValue = ({ values, applicationId }) => {
  const [storeProducts, setStoreProducts] = useState([]);

  useEffect(() => {
    PaymentsRoutes.StoreProducts.indexRequest({ applicationId, withoutPagination: true }).then((response) => {
      setStoreProducts(response.data.items);
    });
  }, []);

  const currentProduct = storeProducts.find(({ id }) => id === values.storeProductId);
  const storeProductOptions = storeProducts.map((item) => ({ value: item.id, label: item.storeId }));

  return (
    <>
      <Label text="Store Product" tooltipText={tooltips.storeProductId}>
        <SelectField name="storeProductId" options={storeProductOptions} />
      </Label>
      <Row>
        <Col xs={4}>
          <b>Amount</b>
        </Col>
        <Col xs={8}>
          {currentProduct && currentProduct.price}
        </Col>
      </Row>
    </>
  );
};

export default function PriceFields({ values, currencies }) {
  const { dispatch } = useFormContext();
  const { query } = useRouter();

  const priceTypes = fromClassNameToOptions('Merge::ProductPriceTypes').filter((priceType) => (
    currencies.includes(priceType.value)
  ));

  const renderPriceValueField = () => {
    switch (values.priceType) {
      case priceTypesEnum.USD:
        return <UsdPriceValue values={values} applicationId={query.applicationId} />;
      case priceTypesEnum.COINS:
      case priceTypesEnum.GEMS:
        return (
          <Label text="Amount">
            <Field name="priceValue" type="number" />
          </Label>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Label text="Currency">
        <SelectField
          name="priceType"
          options={priceTypes}
          onChange={() => dispatch({ type: 'reset_product_price' })}
        />
      </Label>

      {renderPriceValueField()}
    </>
  );
}
