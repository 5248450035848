import React from 'react';
import styled, { useTheme } from 'styled-components';
import { rgba } from 'polished';

const StyledTr = styled.tr`
  ${({ color }) => `background-color: ${color};`}
`;

export default function Tr({ isNewRecord, removed, children }) {
  const theme = useTheme();

  const resolveColor = () => {
    if (removed) return rgba(theme.variants.danger, 0.15);
    if (isNewRecord) return rgba(theme.variants.success, 0.15);

    return theme.bootstrap.background;
  };

  return (
    <StyledTr color={resolveColor()}>
      {children}
    </StyledTr>
  );
}
