import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { Form } from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';

export default function AbilityNamesForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const disabled = isInUse(data.inUse);

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit}>
      {() => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Ability Name`}>
            <FormButtonsGroup
              cancelButtonPath={Routes.Abilities.Names.indexPath({ applicationId })}
            />
          </PageHeader>
          <Label required text="Name">
            <Field name="name" disabled={disabled} />
          </Label>
          <StaticFieldsFormGroup data={data} />
          <Label required text="Key">
            <Field type="text" name="key" disabled={disabled} />
          </Label>
        </>
      )}
    </Form>
  );
}
