import React, { useEffect, useState, useCallback } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useFormContext } from '@hooks';
import { Collapsible } from '@controls/form';
import CardHeader from '@components/shared/CardHeader';

import { LocalizationsRoutes } from '@pages/routes';
import { Table } from '../shared/styled-components';
import { TableHead, TableContainer } from '../shared';
import { useLanguagesContext } from '../shared/services/useLanguages';

import Row from './Row';

const IN_USE_BATCH_SIZE = 50;

export default function ChangedTable({ showOnlyKeysWithErrors, setShowOnlyKeysWithErrors }) {
  const [inUseOffset, setInUseOffset] = useState(0);
  const { query: { applicationId } } = useRouter();
  const { allLanguages } = useLanguagesContext();
  const { values: { keyConfigs, meta }, dispatch } = useFormContext();
  const errorKeys = Object.keys(meta?.errors?.keyConfigs || {}).map((k) => parseInt(k, 10));

  const updateInUseOffsetStart = useCallback((newOffset) => {
    if (newOffset < keyConfigs.length) {
      setInUseOffset(newOffset);
    }
  }, [keyConfigs.length]);

  useEffect(() => {
    let isMounted = true;
    const newOffset = inUseOffset + IN_USE_BATCH_SIZE;

    (async () => {
      const ids = keyConfigs.slice(inUseOffset, newOffset).map(({ id }) => id);
      const { data } = await LocalizationsRoutes.Keys.inUseRequest({ applicationId, ids });

      if (!isMounted) return;

      dispatch({
        type: 'populateInUse',
        data,
        inUseOffset,
        newOffset,
      });

      updateInUseOffsetStart(newOffset);
    })();
    return () => { isMounted = false; };
  }, [inUseOffset, updateInUseOffsetStart]);

  return (
    <Collapsible
      header={<CardHeader text="Changed keys" variant="warning" />}
      eventKey="changed-keys"
      className="mb-3"
      opened
    >
      <TableContainer>
        <Table>
          <TableHead languages={allLanguages} />
          <tbody>
            {keyConfigs.map((rowData, index) => (
              (!showOnlyKeysWithErrors || errorKeys.includes(index)) && (
                <Row
                  key={rowData._uuid}
                  rowData={rowData}
                  index={index}
                  setShowOnlyKeysWithErrors={setShowOnlyKeysWithErrors}
                />
              )
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Collapsible>
  );
}
