import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import APP_DATA from '@services/appData';

import { FormGroup, useCurrentApplication } from '@hooks';
import { Field, Label, SelectField } from '@controls/form';

import { populateChapters } from '@pages/metagame/chapter-based/chapter-sets/shared';

const tileTypes = APP_DATA.enums['Tile::TileTypes'];

export default function BulkUpdateModal({
  chapters, dispatch, handleClose, modesConfigs, values,
}) {
  const { currentApplication } = useCurrentApplication();
  const filterModesConfigsOptions = (type) => modesConfigs.filter(({ tileType }) => tileType === type);

  const onSubmit = async () => {
    const { levelsNumber } = (values.massUpdate || {});
    dispatch({ actionType: 'massUpdate', chapters });
    if (levelsNumber) {
      await populateChapters({
        application: currentApplication, chapters, dispatch, overrides: { levelsNumber },
      });
    }
    handleClose();
  };

  return (
    <Modal show={!!chapters && chapters.length > 0} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Chapter(s)</Modal.Title>
      </Modal.Header>
      <FormGroup name="massUpdate">
        <Modal.Body>
          <b>Chapters to Update:</b>
          <div className="mt-1 mb-2">
            {(chapters || []).map((chapter) => (
              <div key={chapter.id} className="mb-1">
                {chapter.name || `Chapter #${chapter.position}`}
              </div>
            ))}
          </div>
          <Label text="Tile icons offset">
            <Field name="configAttributes.iconsOffset" type="number" min={0} max={100} />
          </Label>
          <Label text="Number of levels">
            <Field name="levelsNumber" type="number" />
          </Label>
          <Label text="Global Goals Schema">
            <SelectField
              name="goalsModeConfigId"
              options={filterModesConfigsOptions(tileTypes.GOALS)}
              includeEmpty
            />
          </Label>
          <Label text="Global Teleporting Schema">
            <SelectField
              name="teleportingModeConfigId"
              options={filterModesConfigsOptions(tileTypes.TELEPORTING)}
              includeEmpty
            />
          </Label>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={onSubmit}>Update</Button>
          <Button type="button" variant="secondary" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </FormGroup>
    </Modal>
  );
}
