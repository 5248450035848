import React from 'react';

import { Col } from 'react-bootstrap';
import { useFormContext, useI18n } from '@hooks';
import IconButton from '@controls/buttons';

export default function ButtonGroup({ missionIndex: index }) {
  const { dispatch } = useFormContext();
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  const duplicateMission = () => dispatch({ actionType: 'duplicateMissionTemplate', index });
  const removeMission = () => dispatch({ actionType: 'removeMissionTemplate', index });

  return (
    <Col xs="2">
      <IconButton.Delete className="w-100" variant="danger" onClick={removeMission}>
        {`Remove ${namespaceName}`}
      </IconButton.Delete>
      <IconButton.Duplicate className="w-100 mt-1" onClick={duplicateMission}>
        {`Duplicate ${namespaceName}`}
      </IconButton.Duplicate>
    </Col>
  );
}
