import React from 'react';
import { Alert, Spinner } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { PlayerSegmentRoutes } from '@pages/routes';
import { ReactTable } from '@pages/common';

import HomeCard from './HomeCard';

const DELETED_SEGMENTS_AMOUNT = 5;

const columns = [
  { Header: 'Segment Id', accessor: 'externalId' },
  {
    Header: 'Segment',
    Cell: ({ row: { original: { name } } }) => <span className="me-1">{name}</span>,
  },
];

const SegmentsTable = ({ applicationId }) => {
  const { isLoading, response } = useQuery(
    PlayerSegmentRoutes.indexRequest,
    {
      applicationId,
      sort: { id: 'deleted_at', desc: true },
      filter: {
        deletedAtNotNull: true,
      },
      per: DELETED_SEGMENTS_AMOUNT,
    },
  );

  if (isLoading) return <Spinner />;

  return (
    response?.items?.length ? (
      <ReactTable
        columns={columns}
        data={response.items}
        disableSortBy
        className="mb-0"
      />
    ) : (
      <Alert variant="info" className="mb-0">
        <b>There are no deleted segments</b>
      </Alert>
    )
  );
};

export default function DeletedPlayerSegments({ application, className }) {
  return (
    <HomeCard
      title="Deleted Player Segments"
      tooltip="Only 5 oldest deleted player segments are shown"
      className={className}
      key={application.id}
    >
      <SegmentsTable applicationId={application.id} />
    </HomeCard>
  );
}
