import React, { useState } from 'react';
import { Table, Card } from 'react-bootstrap';
import { PageHeader, Pagination } from '@pages/common';
import { useRouter } from '@tripledotstudios/react-core';

import IconButton from '@controls/buttons';

import UserSearchForm from './UserSearchForm';

const DevicesTable = ({ appScope, items }) => (
  items.length > 1 ? (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>App User ID</th>
          <th>Created At</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {items.map(({ id, createdAt }) => (
          <tr key={id}>
            <td>{id}</td>
            <td>{createdAt}</td>
            <td>
              <IconButton.Edit to={`${appScope}/users/app_users/${id}/edit`} readonly minimized>
                Go to User
              </IconButton.Edit>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  ) : ''
);

export default function Index() {
  const [collection, setCollection] = useState();
  const { query } = useRouter();
  const { applicationId } = query;

  return (
    <div>
      <PageHeader title="App Users" />
      <Card>
        <Card.Body>
          <UserSearchForm setCollection={setCollection} applicationId={applicationId} />
        </Card.Body>
      </Card>
      <p />
      {collection && (
      <>
        <DevicesTable appScope={`/admin/applications/${applicationId}`} items={collection.items} />
        <Pagination pageCount={collection._meta.pages} />
      </>
      )}
    </div>
  );
}
