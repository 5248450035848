import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';

import { CopyToClipboardAddon } from '@controls/CopyToClipboardButton';

export default function CopyableData({ data, onCopy }) {
  return (
    <InputGroup>
      <Form.Control value={data} disabled />
      <CopyToClipboardAddon text={data} onCopy={onCopy} />
    </InputGroup>
  );
}
