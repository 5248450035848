import React from 'react';
import { Col } from 'react-bootstrap';

import { Filter, TextFilter } from '@pages/common';

export default function AreaNamesFilter() {
  return (
    <Filter defaultComponents={['availabilityStateIn']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="keyContAny"
              label="Key"
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
