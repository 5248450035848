import { addUuid } from '@services/recursivelyProcessObject';

export default function newRulesSection() {
  return addUuid({
    id: null,
    operation: 0,
    rulesAttributes: [],
    ruleSectionsAttributes: [],
    _destroy: false,
  });
}
