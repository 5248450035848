import React from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { MyPetCafeRoutes } from '@pages/routes';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function Edit() {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(MyPetCafeRoutes.SoloMissions.Configs.editRequest);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Solo Mission Config',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => MyPetCafeRoutes.SoloMissions.Configs
    .updateRequest({ ...values, applicationId })
    .then(responseHandler);

  if (isLoading) return <Spinner />;

  return (
    <Form
      data={response}
      onSubmit={onSubmit}
      title="Edit Solo Mission Config"
    />
  );
}
