import React from 'react';
import { Row } from 'react-bootstrap';
import {
  at, isEqual, isUndefined, isString,
} from 'lodash';

import { FieldWithExperiment } from '@controls/form';
import { PageSection } from '@pages/common';

import Field from './Field';

const flattenSections = (fields) => fields.flatMap(
  (fieldOrSection) => (typeof fieldOrSection === 'string' ? fieldOrSection : fieldOrSection.fieldsNames),
);

export default React.memo(({
  fieldsFactory, fields, values, button, abTestable, disabled, localeNamespace, focus,
}) => {
  const maybeWrapInAbTesting = (fieldName) => {
    const { options = {}, Labeled } = fieldsFactory[fieldName];
    const isAbTestable = isUndefined(options.abTestable) ? abTestable : options.abTestable;
    const fieldProps = {
      Labeled,
      fieldName,
      isFocused: focus === fieldName,
      disabled,
      values,
      localeNamespace,
      button,
    };

    return (
      <React.Fragment key={fieldName}>
        {isAbTestable ? (
          <FieldWithExperiment name={fieldName}>
            <Field {...fieldProps} />
          </FieldWithExperiment>
        ) : (
          <Row data-testid={`${fieldName}_row`}>
            <Field {...fieldProps} />
          </Row>
        )}
      </React.Fragment>
    );
  };

  return (
    fields && fields.map((fieldOrSection) => {
      if (isString(fieldOrSection)) return maybeWrapInAbTesting(fieldOrSection);

      return (
        <React.Fragment key={fieldOrSection.title}>
          <PageSection title={fieldOrSection.title} size="md">
            {fieldOrSection.fieldsNames.map(maybeWrapInAbTesting)}
          </PageSection>
        </React.Fragment>
      );
    })
  );
}, (prev, next) => (
  isEqual(prev.fields, next.fields) && isEqual(prev.focus, next.focus)
  && isEqual(at(prev.values, flattenSections(prev.fields)), at(next.values, flattenSections(next.fields)))
));
