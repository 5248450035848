import React from 'react';
import IndexPage from '@pages/puzzle-time/ConfigIndex';
import { useRouter } from '@tripledotstudios/react-core';
import { getEscapeConfigs, deleteEscapeConfig } from '@requests/puzzle-time/escapeConfigs';

export default function Index() {
  const { query } = useRouter();

  return (
    <IndexPage
      configName="Block Escape Config"
      path={`/admin/applications/${query.applicationId}/puzzle_time/escape_configs`}
      getConfigFunction={getEscapeConfigs}
      deleteConfigFunction={deleteEscapeConfig}
    />
  );
}
