import React from 'react';
import { Alert, useRouter } from '@tripledotstudios/react-core';

import {
  FormButtonsGroup,
  AvailabilityStateFormGroup,
  StaticFieldsFormGroup,
  Label,
  Field,
  SelectField,
  PriorityField,
} from '@controls/form';

import { Form, useRewardOptions } from '@hooks';
import { fromClassNamesToOptions } from '@services/enums';
import {
  PageHeader, Rewards, PageSection, DuplicateButton,
} from '@pages/common';
import RulesForm from '@pages/rules/Form';
import { rewardableReducer, combineReducers, rulesReducer } from '@reducers';
import { VersionUpdateRoutes } from '@pages/routes';

export default function AppUpdatesForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const [statuses, types] = fromClassNamesToOptions([
    'VersionUpdates::AppUpdateStatusesEnum',
    'VersionUpdates::AppUpdateTypesEnum',
  ]);

  const rewardItems = useRewardOptions();
  const isUpdate = Boolean(data.id);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([rulesReducer, rewardableReducer])}
    >
      {({ values, dispatch, dirty }) => (
        <>
          <PageHeader title={`${isUpdate ? 'Edit' : 'New'} App Update`} userGuideUrlKey="versionUpdatesGuideUrl">
            <FormButtonsGroup cancelButtonPath={VersionUpdateRoutes.AppUpdates.indexPath({ applicationId })}>
              <DuplicateButton
                entityName="App update"
                routes={VersionUpdateRoutes.AppUpdates}
                data={data}
                disabled={dirty}
                modalType="withPriority"
                g
              />
            </FormButtonsGroup>
          </PageHeader>
          <Alert variant="info">
            <b>&quot;App Update&quot; is ignored by clients with platform version older than 5.3.5</b>
          </Alert>
          <Label text="Name">
            <Field type="text" name="name" />
          </Label>
          <Label text="Priority">
            <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} />
          </Label>
          <AvailabilityStateFormGroup />
          <Label text="Status">
            <SelectField name="status" options={statuses} />
          </Label>
          <StaticFieldsFormGroup data={data} />
          <Label text="Update type">
            <SelectField name="type" options={types} />
          </Label>
          <PageSection title="Rewards" />
          <Rewards
            dispatch={dispatch}
            rewardable={values}
            rewardItems={rewardItems}
            className="mb-3"
            hideQuantityUntilSelected
          />
          <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} />
        </>
      )}
    </Form>
  );
}
