import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
} from '@pages/common';
import { WoodokuRoutes } from '@pages/routes';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

export default function ColumnsFactory({
  applicationId, onDelete, setDuplicatedRecord,
}) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    InLiveColumn,
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({
        row: {
          original: {
            id, name, inUse,
          },
        },
      }) => (
        <ButtonToolbar>
          <IconButton.Edit to={WoodokuRoutes.PerfectFit.editPath({ id, applicationId })} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
          <IconButton.Delete onClick={onDelete(id, name)} disabled={isInUse(inUse)} minimized />
        </ButtonToolbar>
      ),
    },
  ];
}
