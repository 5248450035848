import React, { useMemo } from 'react';
import { camelCase } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import {
  useQuery,
  useConfirm,
  useI18n,
} from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { MyPetCafeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';
import ColumnsFactory from './ColumnsFactory';

export default function JsonConfigsIndex({ configTypes, group }) {
  const { response, refetch } = useQuery(
    MyPetCafeRoutes.JsonConfigs.indexRequest,
    { includeInUse: true, type: configTypes },
  );
  const { translate } = useI18n();

  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();
  const humanizedGroupName = translate.strict(`jsonConfigs.groupNames.${camelCase(group)}`);

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'JSON config',
    actionName: 'deletion',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `JSON config '${name}' will be deleted and not available for work. Continue?` })
      .then(() => MyPetCafeRoutes.JsonConfigs.deleteRequest({ id, applicationId }))
      .then(responseHandler);
  };

  const columns = useMemo(() => ColumnsFactory({
    applicationId, group, onDelete,
  }), []);

  return response ? (
    <div>
      <PageHeader
        title={`${humanizedGroupName} JSON Configs`}
        filter={<Filter configTypes={configTypes} />}
      >
        <IconButton.New to={`${MyPetCafeRoutes.JsonConfigs.newPath({ applicationId })}/${group}`} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  ) : '';
}
