import React from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import classNames from 'classnames';

import Cell from '../Cell';
import { fetchLargeTileCoordinates, largeTilesParentCells } from '../../utils';

const GridContainer = styled.div`
  table {
    &.read-only {
      opacity: 0.7;
    }
    td {
      color: ${({ theme: { woodoku: { board: { cell } } } }) => cell.selected};
      width: 7em;
      height: 7em;
      border: 1px solid ${({ theme: { woodoku: { board: { cell } } } }) => cell.selected};
      background-color: ${({ theme: { woodoku: { board: { cell } } } }) => cell.normal};
    }
    ${({ verticalBordersAt, theme: { woodoku: { board: { cell } } } }) => verticalBordersAt.map((index) => `
      td:nth-child(${index}) {
        border-right: 4px solid ${cell.selected};

        &.selected {
          border-color: ${cell.hard};
        }
      }
    `)}
    ${({ horizontalBordersAt, theme: { woodoku: { board: { cell } } } }) => horizontalBordersAt.map((index) => `
      tr:nth-child(${index}) > .board-cell {
        border-bottom: 4px solid ${cell.selected};

        &.selected {
          border-color: ${cell.hard};
        }
      }
    `)}
    td:nth-child(1) {
      border-left: 4px solid ${({ theme: { woodoku: { board: { cell } } } }) => cell.selected};
    }
    td:nth-child(${(({ width }) => width)}) {
      border-right: 4px solid ${({ theme: { woodoku: { board: { cell } } } }) => cell.selected};
    }
    tr:nth-child(1) {
      border-top: 4px solid ${({ theme: { woodoku: { board: { cell } } } }) => cell.selected};
    }
    tr:nth-child(${(({ height }) => height)}) {
      border-top: 1px solid ${({ theme: { woodoku: { board: { cell } } } }) => cell.selected};
      border-bottom: 4px solid ${({ theme: { woodoku: { board: { cell } } } }) => cell.selected};
    }
  }
`;

export default function WoodokuRectangleBoardGrid({
  width,
  height,
  values,
  tileTypeOptions,
  levelIndex,
  onChange,
  errors,
  readOnly,
  className,
  occupiedCells,
  massSelectedCellIds,
  verticalBordersAt = [],
  horizontalBordersAt = [],
}) {
  const cellRows = Array(width * height).fill({}).reduce((memo, defaultCell, index) => {
    const chunk = Math.floor(index / width);
    if (!memo[chunk]) memo.push([]);

    const persistedCell = values.find((element) => element && element.position === index);
    const cellConfig = persistedCell || defaultCell;

    memo[chunk].push({ cell: { ...cellConfig, position: index } });
    return memo;
  }, []);

  const valuesWithRef = values.filter(({ baseTileId, parameters = {} }) => {
    const refCellId = get(parameters, `${baseTileId}.referenceId`);

    return Boolean(refCellId);
  });

  const largeTiles = values.filter(({ baseTileId, parameters = {} }) => (
    Boolean(fetchLargeTileCoordinates(parameters, baseTileId))
  ));
  return (
    <GridContainer
      className="board-grid"
      width={width}
      height={height}
      verticalBordersAt={verticalBordersAt}
      horizontalBordersAt={horizontalBordersAt}
    >
      <Table
        bordered
        className={classNames(['mx-auto my-0', className], { 'read-only': readOnly })}
      >
        <tbody>
          {React.Children.toArray(cellRows.map((row) => (
            <tr>
              {React.Children.toArray(row.map(({ cell }) => {
                const refParentCells = valuesWithRef.filter(({ baseTileId, parameters = {} }) => {
                  const refCellId = get(parameters, `${baseTileId}.referenceId`);

                  return Number(refCellId) === cell.position + 1;
                });

                const parentCells = [...refParentCells, ...largeTilesParentCells(largeTiles, cell.position)];
                return (
                  <Cell
                    occupiedCells={occupiedCells}
                    errors={errors[cell.position]}
                    cell={cell}
                    parentCells={parentCells}
                    tileTypeOptions={tileTypeOptions}
                    levelIndex={levelIndex}
                    onChange={(index, cellConfig) => onChange(index, cellConfig)}
                    readOnly={readOnly}
                    boardWidth={width}
                    selected={massSelectedCellIds.includes(cell.position)}
                  />
                );
              }))}
            </tr>
          )))}
        </tbody>
      </Table>
    </GridContainer>
  );
}
