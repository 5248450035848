import React from 'react';
import { Table } from 'react-bootstrap';
import { get, isEqual } from 'lodash';

import { FormGroup } from '@hooks';

import useExpanded from '@components/merge/hooks/useExpanded';
import { ExpandIcon } from '@components/merge/shared/icons';
import InternalIdLabel from '@components/merge/shared/InternalIdLabel';
import Th from '@components/merge/shared/Th';

import StoryOrder from './StoryOrder';

export default React.memo(({
  values, items, experts, errors, dispatch,
}) => {
  const { isExpanded, toggleExpanded } = useExpanded();

  return values && values.length > 0 ? (
    <Table>
      <thead>
        <tr>
          <Th width="50px" />
          <th>Position</th>
          <th>StoryOrders</th>
          <th>Prerequisites</th>
          <th>Tasks</th>
        </tr>
      </thead>
      <tbody>
        {values.map((storyOrder, index) => {
          const expanded = isExpanded(storyOrder.internalId) || get(errors, `[${index}]`);
          const onClick = () => toggleExpanded(storyOrder.internalId);

          return (
            <React.Fragment key={storyOrder._uuid}>
              <tr>
                <td>
                  <ExpandIcon value={expanded} onClick={onClick} />
                </td>
                <td>{storyOrder.position}</td>
                <td>
                  <InternalIdLabel
                    onClick={onClick}
                    name={storyOrder.internalId}
                  />
                </td>
                <td>{storyOrder.prerequisites.join(', ')}</td>
                <td>{storyOrder.tasksAttributes.length}</td>
              </tr>
              <tr className={`collapse ${expanded ? 'show' : ''}`}>
                <td />
                <td colSpan="4">
                  <FormGroup name={`storyOrdersAttributes[${index}]`}>
                    <StoryOrder value={storyOrder} items={items} experts={experts} dispatch={dispatch} />
                  </FormGroup>
                </td>
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </Table>
  ) : '';
}, (prev, next) => (
  prev.values === next.values
  && prev.errors === next.errors
  && isEqual(prev.items) === isEqual(next.items)
  && isEqual(prev.experts) === isEqual(next.experts)
));
