import React from 'react';

import { FormGroup } from '@hooks';
import Variant from './Variant';

export default function Variants({
  defaultVariant,
  variantAttributes,
  formGroupName,
  dispatch,
}) {
  return (
    <FormGroup name={formGroupName}>
      <Variant defaultVariant={defaultVariant} data={variantAttributes} dispatch={dispatch} />
    </FormGroup>
  );
}
