import React from 'react';

import { isNil } from 'lodash';

import styled from 'styled-components';

const ErrorsContainer = styled.pre`
  font-size: 1rem;
  font-family: "Segoe UI", Helvetica;
  color: red;
`;

export default function ErrorsCard({ meta }) {
  if (!meta?.errors || isNil(meta.errors.configDetails)) return '';

  const { errors: { configDetails: [rawData] } } = meta;
  const parsedData = JSON.parse(rawData);

  return <ErrorsContainer>{parsedData}</ErrorsContainer>;
}
