import React from 'react';

import APP_DATA from '@services/appData';

import Index from '@components/journeys/banks/Index';
import { MyPetCafeRoutes } from '@pages/routes';
import { fromClassNameToOptions } from '@root/services/enums';
import { useI18n } from '@root/hooks';

const statuses = APP_DATA.enums['MyPetCafe::Adventures::BankStatuses'];

export default function AdventureBanksIndex() {
  const { translate } = useI18n();
  const translationsPath = 'adventures.banks';

  return (
    <Index
      statuses={statuses}
      routes={MyPetCafeRoutes.Adventures.EventBanks}
      translationsPath={translationsPath}
      showLabels={false}
      massOperationsEnabled
      massOperationsProps={{
        pageModelName: translate.fallback(`${translationsPath}.pluralName`),
        modelName: 'MyPetCafe::Adventures::Bank',
        statusOptions: fromClassNameToOptions('MyPetCafe::Adventures::BankStatuses'),
      }}
    />
  );
}
