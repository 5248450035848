import React from 'react';

import APP_DATA from '@services/appData';

import InLiveBadge from '@pages/common/InLiveBadge';

import Label from './Label';

const { modelsLocales: { shared: { tooltips: { availabilityState: availabilityStateTooltip } } } } = APP_DATA;

export default function InLiveFormGroup({ inLive, labelSize = 4 }) {
  return inLive !== undefined && (
    <Label labelSize={labelSize} text="Availability" tooltipText={availabilityStateTooltip}>
      <InLiveBadge inLive={inLive} />
    </Label>
  );
}
