import React from 'react';

import SelectField from '@controls/form/SelectField';
import DevicesEnum from '@enumerations/deviceOSfamilies';

const platforms = [
  { label: 'Other', value: DevicesEnum.OTHER },
  { label: 'IOS', value: DevicesEnum.IOS },
  { label: 'Android', value: DevicesEnum.ANDROID },
  { label: 'Amazon', value: DevicesEnum.AMAZON },
  { label: 'Web', value: DevicesEnum.WEB },
];

export default function PlatformRule({ disabled }) {
  return (
    <SelectField
      name="platforms"
      options={platforms}
      isMulti
      isDisabled={disabled}
    />
  );
}
