import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IconButton, Spinner } from '@tripledotstudios/react-core';
import { uniq, chunk } from 'lodash';

import { useCurrentApplication, useFormContext, useSelectedRows } from '@hooks';
import { generateChapterBasedRoutes } from '@pages/routes';
import useRewardOptions from '@hooks/useRewardOptions';
import { itemId, MassSelectCheckbox } from '@hooks/useSelectedRows';

import Chapter from './Chapter';
import ChaptersSummary from './ChaptersSummary';
import { Title } from './shared';

const AddNewChapter = styled(IconButton.New)`
  margin-top: 10px;
  margin-left: 5%;
`;

const MassSelectContainer = styled.div`
  display: inline-block;
  width: 125px;
`;

export default function Chapters({
  chapterSetId,
  chapterFieldsData,
  ChapterFields,
  levelsData,
  Levels,
  MassOperations,
  enableUiAssets = false,
  displaySummary = true,
}) {
  const { currentApplication, typeName: appType } = useCurrentApplication();
  const [banks, setBanks] = useState([]);
  const [levelLayouts, setLevelLayouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { values, dispatch } = useFormContext();
  const {
    Banks: BankRoutes,
    UiThemes: UiThemeRoutes,
    LevelLayouts: LevelLayoutRoutes,
  } = generateChapterBasedRoutes(appType);

  const [uiAssets, setUiAssets] = useState([]);
  const [massOperationsMode, setMassOperationsMode] = useState(false);

  const configuredBankIds = uniq(
    (values.chaptersAttributes || [])
      .flatMap(({ chapterBankAffiliationsAttributes }) => (
        chapterBankAffiliationsAttributes.map(({ bankId }) => bankId)
      )),
  );

  const fetchUiAssetOptions = async () => {
    if (!enableUiAssets) return;

    const response = await UiThemeRoutes.indexRequest({
      applicationId: currentApplication.id,
      withoutPagination: true,
      includeInUse: false,
    });
    const options = response.data.items.map((uiAsset) => ({
      label: `${uiAsset.name} (${uiAsset.key})`,
      value: uiAsset.id,
    }));
    setUiAssets(options);
  };
  const fetchBankOptions = async () => {
    const response = await BankRoutes.indexRequest({
      applicationId: currentApplication.id,
      withoutPagination: true,
      validForUse: true,
      includeInUse: false,
      withoutVersions: true,
    });
    const options = response.data.items.map((bank) => ({
      label: `${bank.name} (${bank.levelLayoutsCount})`,
      value: bank.id,
    }));
    setBanks(options);
  };
  useEffect(() => {
    setLoading(true);
    (async () => Promise.all([
      fetchBankOptions(),
      fetchUiAssetOptions(),
    ]).catch(() => setLoading(false)).then(() => setLoading(false)))();
  }, []);

  useEffect(() => {
    chunk(configuredBankIds, 65).forEach((bankIds) => {
      LevelLayoutRoutes.indexRequest({
        applicationId: currentApplication.id, filter: { bankIdIn: bankIds }, withoutPagination: true,
      }).then(({ data: { items } }) => setLevelLayouts((configuredLayouts) => [...configuredLayouts, ...items]));
    });
  }, [configuredBankIds.reduce((a, b) => (a || 0) + (b || 0), 0)]);

  const rewardItems = useRewardOptions();

  const {
    selectedItems, selectedRows, selectableItems, setSelectedRows,
  } = useSelectedRows(values.chaptersAttributes, ({ _destroy }) => !_destroy);

  if (loading) return <Spinner />;

  const addNewChapter = () => dispatch({ actionType: 'addChapter' });
  let levelsTotal = 0;

  const MassOperationsButtonsBlock = massOperationsMode ? (
    <>
      <MassSelectContainer>
        <MassSelectCheckbox
          items={selectableItems}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </MassSelectContainer>
      <MassOperations
        chapterSetId={chapterSetId}
        chapterFieldsData={chapterFieldsData}
        uiAssets={uiAssets}
        dispatch={dispatch}
        levelsData={levelsData}
        rewardItems={rewardItems}
        selectedItems={selectedItems}
        setMassOperationsMode={setMassOperationsMode}
        values={values}
      />
    </>
  ) : (
    <IconButton.Menu onClick={() => setMassOperationsMode(true)}>
      Mass operations
    </IconButton.Menu>
  );

  return (
    <div>
      {displaySummary && (
        <ChaptersSummary
          values={values.chaptersAttributes}
          validChapters={values.validChapters}
          rewardItems={rewardItems}
          chapterFieldsData={chapterFieldsData}
          levelLayouts={levelLayouts}
        />
      )}

      {MassOperations && (
        <div className="mb-2">
          {MassOperationsButtonsBlock}
        </div>
      )}
      <Title>Chapters structure:</Title>
      <div className="mt-3">
        {React.Children.toArray(selectableItems.map((chapterAttributes, index) => {
          if (chapterAttributes._destroy) return null;

          const chapter = (
            <Chapter
              banks={banks}
              uiAssets={uiAssets}
              index={index}
              levelsTotal={levelsTotal}
              rewardItems={rewardItems}
              ChapterFields={ChapterFields}
              chapterFieldsData={chapterFieldsData}
              Levels={Levels}
              levelsData={levelsData}
              preloadedLevelLayouts={levelLayouts}
              CustomLeftColumnActions={massOperationsMode && (
                <input
                  type="checkbox"
                  className="mt-4"
                  checked={chapterAttributes.selectedRows[itemId(chapterAttributes)] || false}
                  onClick={(e) => chapterAttributes.toggleRow(e, index)}
                  onChange={() => { }}
                />
              )}
            />
          );
          levelsTotal += values.validChapters[chapterAttributes._uuid]?.levelsNumber || 0;
          return chapter;
        }))}
      </div>
      <AddNewChapter onClick={addNewChapter} variant="success">Add chapter</AddNewChapter>
    </div>
  );
}
