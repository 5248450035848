import React from 'react';

import { Form, useCurrentApplication, useQuery } from '@hooks';
import { Label, Field } from '@controls/form';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { prepareInboundData } from '@requests/common';
import { Button, Modal } from 'react-bootstrap';

export default function UiAssetForm({
  actionName, entityName, getForm, onHide, submitForm, record, refetch,
}) {
  if (!record) { return null; }

  const { currentApplication } = useCurrentApplication();
  const { response: data, setResponse } = useQuery((params) => getForm({ ...params, id: record }));

  const fullData = { ...data, applicationId: currentApplication.id };

  const responseHandler = collectionResponseHandlerFactory({
    actionName,
    refetch,
    entityName,
  });

  const onSubmit = async (values) => {
    const response = await submitForm(values);
    responseHandler(response);
    if (response.status < 300) {
      onHide();
    } else {
      setResponse((currentValues) => ({ ...currentValues, ...values, ...prepareInboundData(response.data) }));
    }
  };

  return (
    <Modal show={!!record} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{entityName}</Modal.Title>
      </Modal.Header>
      {data && (
        <Form
          initialValues={fullData}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <>
              <Modal.Body>
                <Label text="Name*">
                  <Field name="name" />
                </Label>
                <Label text="Key*">
                  <Field name="key" />
                </Label>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" disabled={isSubmitting}>Confirm</Button>
                <Button type="button" variant="secondary" onClick={onHide}>Cancel</Button>
              </Modal.Footer>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
}
