import { generateChapterBasedRoutes } from '@pages/routes';
import { camelCase, upperFirst } from 'lodash';
import React from 'react';

import { useCurrentApplication } from '@hooks';
import Form from './Form';

export default function NewModal({ recordType, ...props }) {
  const { applicationName } = useCurrentApplication();
  const Routes = generateChapterBasedRoutes(applicationName)[upperFirst(camelCase(recordType))];

  return <Form actionName="creation" getForm={Routes.newRequest} submitForm={Routes.createRequest} {...props} />;
}
