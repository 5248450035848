import React from 'react';
import { useQuery } from '@hooks';

import { formResponseHandler } from '@requests/responseHandlers';

import { SolRoutes as Routes } from '@pages/routes';
import { recalculateTotalXp } from './reducer';

import Form from './Form';

export default function Edit() {
  const { response, setResponse } = useQuery(Routes.PlayerLevelSets.editRequest);

  const redirectIfSuccessHandler = formResponseHandler({
    entityName: 'Player Level Set',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.PlayerLevelSets.updateRequest(values).then(redirectIfSuccessHandler);

  return response && (
    <Form
      data={recalculateTotalXp(response)}
      onSubmit={onSubmit}
      actionName="Update"
    />
  );
}
