import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isEqual } from 'lodash';

import { Field, SelectField } from '@controls/form';
import { RemoveIcon } from '@components/merge/shared/icons';

export default React.memo(({
  // eslint-disable-next-line no-unused-vars
  index, value, items, experts, onRewardDelete, onRewardTypeChange, path, rewardsConfigurations,
}) => {
  const [rewardConfiguration, setRewardConfiguration] = useState();
  const findRewardConfiguration = (type) => (
    rewardsConfigurations.find(({ value: selectedValue }) => selectedValue === type)
  );

  useEffect(() => {
    setRewardConfiguration(findRewardConfiguration(value.type));
  }, [value.type]);

  return (
    <Row className="mb-2">
      <Col xs={5} className="pe-0">
        <Field name="id" type="hidden" className="me-1" />
        <SelectField
          name="type"
          options={rewardsConfigurations}
          onChange={(e) => onRewardTypeChange(index, e)}
          className="me-1"
          isDisabled={value.id}
        />
      </Col>
      <Col xs={6} className="pe-0">
        {rewardConfiguration ? rewardConfiguration.component({ value, items, experts }) : ''}
      </Col>
      <Col xs={1}>
        <RemoveIcon onClick={() => onRewardDelete(index)} />
      </Col>
    </Row>
  );
}, (prev, next) => (
  prev.path === next.path
  && prev.index === next.index
  && prev.value === next.value
  && isEqual(prev.items, next.items)
  && isEqual(prev.experts, next.experts)
));
