import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  get, isEmpty, isEqual, uniqBy,
} from 'lodash';

import styled from 'styled-components';

import { populateChapters } from '@pages/metagame/chapter-based/chapter-sets/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import {
  useCurrentApplication, useFormContext, FormGroup, useConfirm,
} from '@hooks';
import { FaButton, RemoveButton } from '@pages/common';
import { serializableChapter } from '@services/chapterSets';
import { dirtyAttributes } from '@services/dirtyAttributes';
import CommonLevels from './Levels';

const COLUMN_PADDING_SIZE = 10;

const ChapterContainer = styled.div`
  display: flex;
`;

const ChapterContent = styled.div`
  background-color: ${({ theme }) => theme.bootstrap.background};
  border: 2px solid ${({ theme }) => theme.bootstrap.hr};
  border-radius: 8px;
  padding: 10px 25px;
  margin-bottom: 5px;
  flex-grow: 1;
`;

const ChapterRow = styled.div`
  align-items: flex-start;
  display: flex;
  padding: 10px 0;
`;

const WarningRow = styled(ChapterRow)`
  justify-content: flex-end;
  align-items: center;
`;

const MoveDownButton = styled(FaButton)`
  margin-top: 10px;
`;

const ShiftPositionControl = styled.div`
  display: flex;
  flex-flow: column;
`;

const LeftActionsColumn = styled.div`
  display: flex;
  justify-content: space-around;
  padding-right: ${COLUMN_PADDING_SIZE}px;
  width: 5%;
`;

const MemoizedChapter = React.memo(({
  chapterFieldsData, ChapterFields, levelsData, Levels, banks, index, levelsTotal,
  rewardItems, uiAssets, dispatch, values, meta, CustomLeftColumnActions, preloadedLevelLayouts,
}) => {
  const { currentApplication } = useCurrentApplication();
  const [levelLayouts, setLevelLayouts] = useState([]);

  const confirm = useConfirm();

  const moveUp = async () => {
    dispatch({ actionType: 'moveUp', index, meta });
  };

  const moveDown = async () => {
    dispatch({ actionType: 'moveDown', index, meta });
  };

  const removeChapter = () => {
    confirm.showConfirmation({ title: 'Chapter and its content will be removed. Continue?' })
      .then(() => dispatch({ actionType: 'removeChapter', index }));
  };

  const resetChapter = () => {
    dispatch({ actionType: 'resetChapter', index });
  };

  const chapterPath = `chaptersAttributes[${index}]`;
  const chapter = get(values, chapterPath);
  const chaptersCount = values.chaptersAttributes.length;
  const validChapter = values.validChapters[chapter._uuid];

  const unsavedAttributes = dirtyAttributes(serializableChapter(chapter), validChapter);

  const notDestroyedBanks = get(
    validChapter,
    'chapterBankAffiliationsAttributes',
    [],
  ).filter(({ _destroy }) => !_destroy);

  useEffect(() => {
    if (isEmpty(validChapter, 'chapterBankAffiliationsAttributes')) return setLevelLayouts([]);

    const chapterBankIds = notDestroyedBanks.map(({ bankId }) => bankId);
    const validLayouts = uniqBy(
      preloadedLevelLayouts.filter(({ bankId }) => chapterBankIds.includes(bankId)),
      'id',
    );

    return setLevelLayouts(validLayouts);
  }, [preloadedLevelLayouts.length, JSON.stringify(validChapter)]);

  const isDirty = (field) => !!validChapter && unsavedAttributes.indexOf(field) > -1;
  const requiresApply = !!serializableChapter(chapter) && !isEqual(validChapter, serializableChapter(chapter));

  const populateChapter = async () => {
    await populateChapters({
      application: currentApplication, chapters: [values.chaptersAttributes[index]], dispatch,
    });
  };

  return (
    <ChapterContainer>
      <FormGroup name={chapterPath}>
        <LeftActionsColumn>
          {CustomLeftColumnActions || (
            <>
              <RemoveButton size="lg" onClick={removeChapter} title="Delete the chapter" />
              <ShiftPositionControl>
                <FaButton onClick={moveUp} disabled={index === 0}>
                  <FontAwesomeIcon icon={faChevronUp} />
                </FaButton>
                <MoveDownButton onClick={moveDown} disabled={index === chaptersCount - 1}>
                  <FontAwesomeIcon icon={faChevronDown} />
                </MoveDownButton>
              </ShiftPositionControl>
            </>
          )}
        </LeftActionsColumn>
        <ChapterContent>
          <ChapterFields
            banks={banks}
            chapter={chapter}
            dispatch={dispatch}
            isDirty={isDirty}
            index={index}
            levelsTotal={levelsTotal}
            populateChapter={populateChapter}
            rewardItems={rewardItems}
            uiAssets={uiAssets}
            chapterPath={chapterPath}
            {...chapterFieldsData}
          />
          {requiresApply && (
            <WarningRow>
              {validChapter && (
                <>
                  <span className="text-warning float-end me-2">
                    will not be saved without &quot;Apply to levels&quot; operation
                  </span>
                  <Button variant="warning" type="button" onClick={resetChapter}>
                    Reset
                  </Button>
                </>
              )}
            </WarningRow>
          )}
          {Levels ? (
            <Levels
              levelsTotal={levelsTotal}
              chapter={chapter}
              chapterPath={chapterPath}
              dispatch={dispatch}
              levelLayouts={levelLayouts}
              rewardItems={rewardItems}
              banks={banks}
              {...levelsData}
            />
          ) : (
            <CommonLevels
              levelsTotal={levelsTotal}
              chapter={chapter}
              chapterPath={chapterPath}
              dispatch={dispatch}
              levelLayouts={levelLayouts}
              rewardItems={rewardItems}
              banks={banks}
            />
          )}
        </ChapterContent>
      </FormGroup>
    </ChapterContainer>
  );
}, (prev, next) => (
  isEqual(prev.values.chaptersAttributes[prev.index], next.values.chaptersAttributes[next.index])
  && prev.preloadedLevelLayouts.length === next.preloadedLevelLayouts.length
  && isEqual(prev.chapterFieldsData, next.chapterFieldsData)
  && isEqual(prev.rewardItems, next.rewardItems)
  && isEqual(prev.CustomLeftColumnActions, next.CustomLeftColumnActions)
  && isEqual(prev.levelsData, next.levelsData)
  && isEqual(prev.chapterFieldsData, next.chapterFieldsData)
  && isEqual(prev.values.validChapters, next.values.validChapters)
));

export default function Chapter(props) {
  const { dispatch, values, meta } = useFormContext();

  return <MemoizedChapter {...props} dispatch={dispatch} values={values} meta={meta} />;
}
