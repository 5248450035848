import React, { useState } from 'react';
import styled from 'styled-components';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';

import OverlayTooltip from '@controls/OverlayTooltip';
import CellModal from './CellModal';
import { isLargeTile } from '../utils';

const CellDiv = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60px;
  max-height: 60px;
`;
const CellContentWrapper = styled.div`
  position: relative;
  top: -15%;
  align-items: center;
  flex-direction: column;
  height: 50px;
`;
const CellNumberSpan = styled.span`
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: 10px;
`;
const CellTd = styled.td`
  display: table-cell;
  vertical-align: middle;
  position: relative;

  &.selected {
    background-color: ${({ theme: { woodoku: { board: { cell } } } }) => cell.selected};
    color: #FFF;
    border-color: ${({ theme: { woodoku: { board: { cell } } } }) => cell.hard};
  }

  &.selected.with-errors {
    background-color: rgba(220, 53, 69, 0.75);
    color: #FFF;
  }

  &.with-errors {
    background-color: rgba(220, 53, 69, 0.4);
  }
`;

const Cell = React.memo(({
  cell, tileTypeOptions, onChange, readOnly, parentCells, errors = {}, boardWidth, occupiedCells, selected,
}) => {
  const [editMode, setEditMode] = useState(false);
  const { baseTileId, overlayTileId } = cell;
  const cellItemNames = [baseTileId, overlayTileId].map((tileId) => (
    (tileTypeOptions.find(({ value }) => value === tileId) || {}).label
  ));

  const refItemNames = parentCells.map(({ baseTileId: parentBaseTileId, position }) => {
    const tile = tileTypeOptions.find(({ value }) => value === parentBaseTileId) || {};

    return `ref_${tile.label}; Cell #${position + 1}`;
  });

  const largeTileElement = parentCells.filter(({ baseTileId: parentBaseTileId, parameters }) => {
    const refCellId = get(parameters, `${parentBaseTileId}.coordinates`);

    return refCellId?.length > 0 && refCellId.includes(cell.position);
  });
  const onDoubleClick = () => setEditMode(!readOnly);
  const largeTile = (!isEmpty(largeTileElement) || isLargeTile(tileTypeOptions, baseTileId));
  const cellModalTarget = isEmpty(largeTileElement) ? cell : parentCells[0];

  return (
    <CellTd
      onDoubleClick={onDoubleClick}
      className={classNames(['board-cell'], { 'with-errors': !isEmpty(errors), 'bg-info': largeTile, selected })}
      data-cell-index={cell.position}
    >
      <CellNumberSpan>{cell.position + 1}</CellNumberSpan>
      <CellContentWrapper className="d-flex mt-4 justify-content-center">
        {[...refItemNames, ...cellItemNames].map((itemName) => (
          itemName && (
            <OverlayTooltip text={itemName} placement="top">
              <CellDiv>{itemName}</CellDiv>
            </OverlayTooltip>
          )
        ))}
      </CellContentWrapper>
      {editMode && (
        <CellModal
          show
          errors={errors}
          disableBase={!largeTile && cell.length >= 1}
          title={`Edit Grid Cell #${cellModalTarget.position + 1} ${largeTile ? '(large tile)' : ''}`}
          cells={[cellModalTarget]}
          onEditStop={() => setEditMode(false)}
          onSubmit={onChange}
          options={tileTypeOptions}
          indexes={[cellModalTarget.position]}
          boardWidth={boardWidth}
          occupiedCells={occupiedCells}
        />
      )}
    </CellTd>
  );
});

export default Cell;
