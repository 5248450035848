import { useTheme } from 'styled-components';
import { faBook } from '@fortawesome/free-solid-svg-icons';

export default function common() {
  const theme = useTheme();

  return [
    {
      title: 'Knowledge Base',
      link: 'https://tripledotstudios.atlassian.net/wiki/spaces/KB/overview',
      icon: faBook,
      color: theme.lightblue,
      externalLink: true,
    },
  ];
}
