import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup,
} from '@controls/form';
import { DuplicateButton, PageHeader } from '@pages/common';
import { combineReducers } from '@reducers';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { WoodokuRoutes, FigureSetsRoutes } from '@pages/routes';

import EntitiesForm from './EntitiesForm';

import reducer from './reducer';

export default function DynamicConfigsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const [figureSetOptions, setFigureSetOptions] = useState([]);

  useEffect(() => {
    requestWithToSelectOptions(FigureSetsRoutes.Sets.indexRequest, applicationId).then(setFigureSetOptions);
  }, []);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([reducer])}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Dynamic Config`}>
            <FormButtonsGroup cancelButtonPath={WoodokuRoutes.DynamicConfigs.indexPath({ applicationId })}>
              <DuplicateButton
                routes={WoodokuRoutes.DynamicConfigs}
                data={data}
                entityName="Dynamic Config"
              />
            </FormButtonsGroup>
          </PageHeader>

          <Label text="Name"><Field type="text" name="name" /></Label>

          <StaticFieldsFormGroup data={data} />

          <Label text="Moves Up"><Field type="number" name="movesUp" /></Label>
          <Label text="Moves Down"><Field type="number" name="movesDown" /></Label>

          <EntitiesForm values={values} figureSetsOptions={figureSetOptions} />
        </>
      )}
    </Form>
  );
}
