import { cloneDeep, last } from 'lodash';

import { addUuid } from '@services/recursivelyProcessObject';
import { persistedCollection, persistedMap } from '@services/utils';

const lastPosition = (state) => {
  const lastTemplate = last(persistedCollection(state.missionsAttributes));
  return lastTemplate ? lastTemplate.position : 0;
};

const duplicateRewards = (clone, associationName) => persistedMap(
  clone[associationName],
  (reward) => addUuid({ ...reward, _uuid: undefined }),
);

/* eslint-disable no-param-reassign, array-callback-return */
export default function missionTemplatesReducer(state, action) {
  switch (action.actionType) {
    case 'addMissionTemplate': {
      state.missionsAttributes.push(addUuid({
        tier: 1,
        selectionType: 0,
        position: lastPosition(state) + 1,
        targetIds: [],
        rewardsAttributes: [{
          selectionType: 0,
          rewardTypeIds: [],
          _destroy: false,
        }],
        mpcSeasonRewardsAttributes: [{
          selectionType: 0,
          rewardTypeIds: [],
          _destroy: false,
        }],
        _destroy: false,
      }));
      break;
    }
    case 'duplicateMissionTemplate': {
      const { index } = action;
      const missionTemplate = state.missionsAttributes[index];
      const clone = cloneDeep(missionTemplate);
      clone.rewardsAttributes = duplicateRewards(clone, 'rewardsAttributes');
      if (clone.mpcSeasonRewardsAttributes?.length > 0) {
        clone.mpcSeasonRewardsAttributes = duplicateRewards(clone, 'mpcSeasonRewardsAttributes');
      }

      state.missionsAttributes.push(addUuid({
        ...clone,
        id: undefined,
        _uuid: undefined,
        position: lastPosition(state) + 1,
      }));
      break;
    }
    case 'removeMissionTemplate': {
      const { index } = action;
      const missionTemplate = state.missionsAttributes[index];

      for (let i = index + 1; i < state.missionsAttributes.length; i += 1) {
        state.missionsAttributes[i].position -= 1;
      }
      if (missionTemplate.id) {
        missionTemplate._destroy = true;
      } else {
        state.missionsAttributes.splice(index, 1);
      }
      break;
    }
    case 'clearMissionTypes': {
      const { index } = action;
      state.missionsAttributes[index].targetIds = [];
      break;
    }
    default: break;
  }

  return state;
}
