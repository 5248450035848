import { addUuid } from '@services/recursivelyProcessObject';

export default function reducer(state, action) {
  switch (action.actionType) {
    case 'removeRow': {
      const { index } = action;

      state.rowsAttributes[index]._destroy = true;
      break;
    }
    case 'addRow': {
      state.rowsAttributes.push(addUuid({}));
      break;
    }
    default: return state;
  }
  return state;
}
