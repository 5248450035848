import React, { memo } from 'react';
import { get, omit, isEqual } from 'lodash';
import { Container } from 'react-bootstrap';

import getFieldLocale from '@services/getFieldLocale';
import areEnabledPropsEqual from '@services/fields-factory/areEnabledPropsEqual';
import { FormGroup, useFormGroup } from '@hooks';
import { PageSection } from '@pages/common';

const FieldGroup = React.memo(({
  formGroupKey,
  baseName,
  fieldsConfiguration,
  disabled,
  groupLocale,
  tooltipBuilder,
  values,
  componentFn,
  hasBackground = true,
}) => {
  const buildComponent = () => (
    componentFn && componentFn({ name: baseName, disabled, values: values[formGroupKey] || values.data })
  );

  return (
    <Container className={`${hasBackground && 'bg-light'} mb-3 p-3`}>
      <FormGroup name={baseName}>
        {buildComponent() || Object.entries(fieldsConfiguration).map(([name, fieldsFactory]) => {
          const { fieldOptions = {} } = fieldsFactory;
          const { enabledIf, hiddenFn, disableLabel = false } = fieldOptions;
          const groupValue = (values[formGroupKey] || values.data);
          let toggledManually = false;
          if (enabledIf) {
            toggledManually = enabledIf.startsWith('!') ? groupValue[enabledIf.substring(1)] : !groupValue[enabledIf];
          }
          const FieldComponent = disableLabel ? fieldsFactory.Inline : fieldsFactory.Labeled;

          const hidden = (() => {
            switch (typeof hiddenFn) {
              case 'string':
                // eslint-disable-next-line no-new-func
                return (new Function('values', hiddenFn))(values);
              case 'function':
                return hiddenFn(values);
              default:
                return false;
            }
          })();

          return (
            !hidden && (
              <FieldComponent
                name={name}
                key={`${formGroupKey}.${name}`}
                disabled={disabled || toggledManually}
                locale={groupLocale && get(groupLocale, name)}
                tooltipBuilder={tooltipBuilder}
                values={values[formGroupKey] || values.data}
              />
            )
          );
        })}
      </FormGroup>
    </Container>
  );
}, (prev, next) => (
  isEqual(omit(next, ['fieldsConfiguration']), omit(prev, ['fieldsConfiguration']))
));

const getNameAndLocale = (localeNamespace, baseName) => {
  const { generateName } = useFormGroup();
  const formGroupKey = generateName(baseName);
  const groupLocale = getFieldLocale(localeNamespace, baseName);
  return { formGroupKey, groupLocale };
};

export default function FieldGroupBuilder({
  name: baseName,
  labelText,
  fieldsConfiguration,
  tooltips,
  hasBackground,
  componentFn,
  options = {},
}) {
  return {
    [baseName]: {
      Inline: memo(({
        name: overrideName, disabled, values, localeNamespace,
      }) => {
        const name = overrideName || baseName;
        const { formGroupKey, groupLocale } = getNameAndLocale(localeNamespace, baseName);

        return (
          <FieldGroup
            formGroupKey={formGroupKey}
            baseName={name}
            fieldsConfiguration={fieldsConfiguration}
            disabled={disabled}
            groupLocale={groupLocale}
            values={values}
            hasBackground={hasBackground}
            componentFn={componentFn}
          />
        );
      }, areEnabledPropsEqual),
      Labeled: memo(({
        name: overrideName, disabled, localeNamespace, tooltipBuilder, values,
      }) => {
        const name = overrideName || baseName;
        const { formGroupKey, groupLocale } = getNameAndLocale(localeNamespace, baseName);
        const label = labelText || (groupLocale && groupLocale.label);
        const tooltip = (tooltips && tooltips[name]) || (groupLocale && groupLocale.tooltip);

        return (
          <>
            <PageSection title={label} tooltip={tooltip} className="mt-2" />

            <FieldGroup
              formGroupKey={formGroupKey}
              baseName={name}
              fieldsConfiguration={fieldsConfiguration}
              disabled={disabled}
              groupLocale={groupLocale}
              localeNamespace={localeNamespace}
              tooltipBuilder={tooltipBuilder}
              values={values}
              hasBackground={hasBackground}
              componentFn={componentFn}
            />
          </>
        );
      }, areEnabledPropsEqual),
      options,
    },
  };
}
