import React from 'react';

import APP_DATA from '@services/appData';

import { Row, Col } from 'react-bootstrap';
import { SelectField } from '@controls/form';
import { useFormContext } from '@hooks';

import targetOptions from './targetOptions';

const { rawEnums } = APP_DATA;

export default function TypeSelect({
  missionIndex,
  mission,
  targets,
  selectionTypes,
  selectionTypeOptions,
}) {
  const { dispatch } = useFormContext();

  const switchMissionSelectionType = (event) => {
    if (event.value === selectionTypes.ONE_OF) return;

    dispatch({ actionType: 'clearMissionTypes', index: missionIndex });
  };

  return (
    <Row className="mb-2 align-items-center">
      <Col xs="1" className="text-end pe-0 ps-0">Type</Col>
      <Col xs="11">
        <Row className="align-items-center">
          <Col xs="4">
            <SelectField
              name="selectionType"
              options={selectionTypeOptions}
              onChange={switchMissionSelectionType}
            />
          </Col>
          <Col xs="7">
            {mission.selectionType === selectionTypes.ONE_OF && (
              <SelectField
                name="targetIds"
                options={targetOptions({ targets, rawEnums })}
                isMulti
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
