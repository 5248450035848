import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { WooAspenRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { query: { applicationId } } = useRouter();
  const { editRequest, updateRequest } = Routes.Chapters.Pictures;
  const { response, setResponse } = useQuery(editRequest);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Picture',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => updateRequest({ ...values, applicationId }).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
