import React, { useEffect } from 'react';

import APP_DATA from '@services/appData';
import { fromClassNameToOptions } from '@services/enums';

import SelectFilter from './SelectFilter';

const { user } = APP_DATA;

const defaultValues = (defaultFilterStatusOptions, options) => {
  const selectedStatuses = user.defaultFilters.statuses;
  if (!selectedStatuses) return null;

  const selectedValues = selectedStatuses.reduce((result, status) => {
    const selectedLabel = defaultFilterStatusOptions.find((({ value }) => value === status)).label;
    const selectedOption = options.find(({ label }) => label === selectedLabel);
    if (selectedOption) result.push(String(selectedOption.value));

    return result;
  }, []);
  if (selectedValues.length <= 0) return null;

  return selectedValues;
};

export default function StatusFilter({
  name = 'statusIn', updateFilterField, options, defaults = null, ...props
}) {
  const defaultFilterStatusOptions = fromClassNameToOptions('DefaultFilterStatuses');
  const selectedDefaultValues = defaultValues(defaultFilterStatusOptions, options);

  useEffect(() => {
    if (selectedDefaultValues) {
      updateFilterField({ [name]: selectedDefaultValues }, true);
    } else if (defaults) {
      updateFilterField({ [name]: defaults }, true);
    }
  }, []);

  return (
    <SelectFilter
      name={name}
      updateFilterField={updateFilterField}
      options={options}
      {...props}
    />
  );
}
