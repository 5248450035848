import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isEqual } from 'lodash';

import APP_DATA from '@services/appData';
import { fromClassNameToOptions } from '@services/enums';

import {
  useFormContext,
  useCurrentApplication,
  FormGroup,
  useRewardOptions,
} from '@hooks';
import { PaymentsRoutes, JourneyRoutes } from '@pages/routes';
import { Rewards, PageSection } from '@pages/common';

import { gachaRewardItemTypes } from '@pages/my-pet-cafe/constants';

import {
  Label,
  Field,
  SelectField,
  ServerError,
} from '@controls/form';

import LevelsListWrapper from '@components/journeys/banks/levels/LevelsListWrapper';

const repeatingTypeEnum = APP_DATA.enums['MyPetCafe::Journeys::RepeatingMilestoneTypes'];

const MyPetCafe = React.memo(({ values, readOnly: isReadOnly, isArchived }) => {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { dispatch } = useFormContext();
  const [products, setProducts] = useState([]);
  const repeatingTypeOptions = fromClassNameToOptions('MyPetCafe::Journeys::RepeatingMilestoneTypes');

  const rewardOptions = useRewardOptions();
  const gachaRewardOptions = useRewardOptions({ rewardTypeIn: gachaRewardItemTypes, rewardTypeNotIn: null });

  const { configAttributes: { levelsAttributes } } = values;

  useEffect(() => {
    PaymentsRoutes.Products.indexRequest({ applicationId, withoutPagination: true }).then((response) => {
      setProducts(response.data.items);
    });
  }, []);

  const productOptions = products.map(({ id, name }) => ({ value: id, label: name }));

  const onMilestoneAdd = () => (
    dispatch({
      actionType: 'addEmptyEntity',
      options: {
        freeRewardAttributes: {
          rewardAffiliationsAttributes: [],
          gachaRewardAttributes: { rewardAffiliationsAttributes: [] },
        },
        paidRewardAttributes: {
          rewardAffiliationsAttributes: [],
          gachaRewardAttributes: { rewardAffiliationsAttributes: [] },
        },
      },
    })
  );

  return (
    <>
      <Label text="Product">
        <SelectField name="productId" options={productOptions} />
      </Label>
      <LevelsListWrapper
        entityAttributes={levelsAttributes}
        onEntityAdd={onMilestoneAdd}
        attributesName="levelsAttributes"
        metaErrorsPath={['errors', 'configAttributes', 'levelsAttributes']}
        entityNameTranslationPath="journeys.levelBanks.levels.name"
        bulkCopyRoutes={JourneyRoutes.LevelBanks}
        disabled={isArchived}
        readOnly={isReadOnly}
      >
        {({
          readOnly,
          entity: {
            repeatingType,
            freeRewardAttributes,
            paidRewardAttributes,
          },
        }) => (
          <>
            <Label text="Points required">
              <Field type="number" name="pointsRequired" step="1" disabled={readOnly} />
            </Label>
            <Row>
              <Col xs={6}>
                <Label text="Repeating Milestone" direction="vertical">
                  <SelectField
                    hasPrepopulatedOption
                    name="repeatingType"
                    options={repeatingTypeOptions}
                    isDisabled={readOnly}
                  />
                </Label>
              </Col>
              <Col xs={6}>
                <Label text="Number of repeats" direction="vertical">
                  <Field
                    type="number"
                    name="numberOfRepeats"
                    disabled={readOnly || repeatingType !== repeatingTypeEnum.FIXED}
                  />
                </Label>
              </Col>
            </Row>

            <PageSection title="Rewards" />
            <ServerError name="rewardsAttributes.list" />

            <PageSection title="Free Rewards">
              <FormGroup name="freeRewardAttributes">
                <Label text="Rewards" direction="vertical">
                  <Rewards
                    hideQuantityUntilSelected
                    dispatch={dispatch}
                    rewardable={freeRewardAttributes}
                    rewardItems={rewardOptions}
                    readOnly={readOnly || isArchived}
                  />
                </Label>

                <Label text="Gacha box for replacing Rewards" direction="vertical">
                  <FormGroup name="gachaRewardAttributes" className="mt-2">
                    <Rewards
                      hideQuantityUntilSelected
                      fixedQuantity
                      dispatch={dispatch}
                      rewardable={freeRewardAttributes.gachaRewardAttributes}
                      rewardItems={gachaRewardOptions}
                      readOnly={readOnly || isArchived}
                    />
                  </FormGroup>
                </Label>
              </FormGroup>
            </PageSection>

            <PageSection title="Paid Rewards">
              <FormGroup name="paidRewardAttributes">
                <Label text="Rewards" direction="vertical">
                  <Rewards
                    hideQuantityUntilSelected
                    dispatch={dispatch}
                    rewardable={paidRewardAttributes}
                    rewardItems={rewardOptions}
                    readOnly={readOnly || isArchived}
                  />
                </Label>

                <Label text="Gacha box for replacing Rewards" direction="vertical">
                  <FormGroup name="gachaRewardAttributes" className="mt-2">
                    <Rewards
                      hideQuantityUntilSelected
                      fixedQuantity
                      dispatch={dispatch}
                      rewardable={paidRewardAttributes.gachaRewardAttributes}
                      rewardItems={gachaRewardOptions}
                      readOnly={readOnly || isArchived}
                    />
                  </FormGroup>
                </Label>
              </FormGroup>
            </PageSection>

          </>
        )}
      </LevelsListWrapper>
    </>
  );
}, (prev, next) => isEqual(prev.values, next.values));

export default MyPetCafe;
