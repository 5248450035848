import React, { useMemo, useState } from 'react';
import { omit, upperCase } from 'lodash';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import { useQuery, useConfirm } from '@hooks';

import {
  PageHeader,
  Pagination,
  ReactTable,
  ExpanderColumn,
  InUseColumn,
  TIMESTAMP_COLUMNS,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { AdsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import NewModal from './NewModal';
import EditModal from './EditModal';

const renderPlatformKeys = ({ row }) => {
  const { rawEnums } = APP_DATA;
  const platformsEnum = rawEnums['Accounts::Platforms'];

  const columns = [
    { Header: 'Platform', accessor: 'platform', disableSortBy: true },
    { Header: 'Ad unit key', accessor: 'adUnitKey', disableSortBy: true },
  ];
  const data = Object.entries(omit(row.original.keys, '_uuid')).map(([key, value]) => (
    { platform: platformsEnum[upperCase(key)].translation, adUnitKey: value }
  ));

  return (
    <ReactTable
      columns={columns}
      data={data}
      className="mb-0"
    />
  );
};

const columnsFactory = ({ onEdit, onDelete }) => ([
  ExpanderColumn,
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, inUse } } }) => {
      const disabled = isInUse(inUse);

      return (
        <ButtonToolbar>
          <IconButton.Edit
            onClick={() => onEdit(id)}
            title={disabled ? 'Disabled as the entity is used' : null}
            disabled={disabled}
            minimized
          />
          <IconButton.Delete
            onClick={onDelete(id, name)}
            title={disabled ? 'Disabled as the entity is used' : null}
            disabled={disabled}
            minimized
          />
        </ButtonToolbar>
      );
    },
  },
]);

export default function Index() {
  const { indexRequest, deleteRequest } = AdsRoutes.AdUnitCampaigns;
  const { response, refetch, isLoading } = useQuery(indexRequest, { includeInUse: true });
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();

  const [editedRecord, setEditedRecord] = useState(null);
  const [newRecord, setNewRecord] = useState(false);

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Ad Unit Campaign',
    actionName: 'deletion',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Ad Unit Campaign '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({ onEdit: setEditedRecord, onDelete }), []);

  return (
    <>
      <PageHeader title="Ad Unit Campaigns">
        <IconButton.New onClick={() => setNewRecord(true)} />
      </PageHeader>

      {isLoading ? <Spinner /> : (
        response && (
          <>
            <ReactTable
              columns={columns}
              data={response.items}
              renderRowSubComponent={renderPlatformKeys}
            />
            <NewModal
              record={newRecord}
              onHide={() => setNewRecord(false)}
              refetch={refetch}
            />
            <EditModal
              record={editedRecord}
              onHide={() => setEditedRecord(null)}
              refetch={refetch}
            />
            <Pagination
              pageCount={response._meta.pages}
            />
          </>
        )
      )}
    </>
  );
}
