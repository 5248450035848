import SolitaireConfigurationForm from './SolitaireConfigurationForm';
import WoodokuConfigurationForm from './WoodokuConfigurationForm';
import SortagaConfigurationForm from './SortagaConfigurationForm';
import BlockPartyConfigurationForm from './BlockPartyConfigurationForm';
import HexagoConfigurationForm from './HexagoConfigurationForm';

export default {
  solitaire: { ConfigurationForm: SolitaireConfigurationForm },
  woodoku: { ConfigurationForm: WoodokuConfigurationForm },
  sortaga: { ConfigurationForm: SortagaConfigurationForm },
  block_party: { ConfigurationForm: BlockPartyConfigurationForm },
  hexago: { ConfigurationForm: HexagoConfigurationForm },
};
