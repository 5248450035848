import React, { useCallback } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { MergeRoutes } from '@pages/routes';

import formReducer from './formReducer';
import Cells from './form/Cells';

export default function StartingGridsForm({
  actionName,
  data,
  nameFieldLabel,
  onSubmit,
  routePostfix,
  requestSchemaOptions,
  schemaType,
  schemaTypeName,
  schemaRequest,
}) {
  const { query } = useRouter();
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(query.applicationId), []);
  const indexPath = MergeRoutes.StartingGrids.indexPath({ applicationId: query.applicationId, routePostfix });

  return (
    <Form initialValues={{ ...data, schemaType }} onSubmit={onSubmit} reducer={formReducer}>
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Starting Grid`}>
            <FormButtonsGroup cancelButtonPath={indexPath} />
          </PageHeader>

          <Label text={nameFieldLabel}>
            <Field type="text" name="name" />
          </Label>
          <Label text="Description">
            <Field type="text" name="description" />
          </Label>
          <Label text={schemaTypeName}>
            <SelectField
              name="schemaId"
              options={fetchSchemaOptions}
              onChange={() => dispatch({ type: 'initialize_cells' })}
              isDisabled={actionName !== 'Create'}
            />
          </Label>

          <StaticFieldsFormGroup data={data} />

          {!!values.schemaId && (
            <Cells values={values.cellsAttributes} schemaId={values.schemaId} schemaRequest={schemaRequest} />
          )}
        </>
      )}
    </Form>
  );
}
