import React from 'react';
import { isEqual } from 'lodash';

const REWARD_TYPES_WITH_BALANCE = [
  'Merge::Rewards::Coins',
  'Merge::Rewards::Gems',
  'Merge::Rewards::Energy',
  'Merge::Rewards::EventProgress',
  'Merge::Rewards::Xp',
];
const ITEM_REWARD_TYPE = 'Merge::Rewards::Item';
const EXPERT_REWARD_TYPE = 'Merge::Rewards::Expert';
const ENERGY_REFRESH_REWARD_TYPE = 'Merge::Rewards::EnergyRefresh';
const REFRESH_ALL_PRODUCERS_REWARD_TYPE = 'Merge::Rewards::RefreshAllProducers';
const GIFT_BAG_REWARD_TYPE = 'Merge::Rewards::GiftBag';

const humanizedNames = {
  Xp: 'XP',
  EnergyRefresh: 'Energy refresh',
  EventProgress: 'Event Progress',
  RefreshAllProducers: 'Refresh all producers',
};

const getHumanizedType = (type) => {
  const humanizedValue = type.split('::').slice(-1)[0];
  return humanizedNames[humanizedValue] || humanizedValue;
};

/* eslint-disable no-param-reassign */
const appendRewardWithBalance = ({ type, value }, itemOptions, memo) => {
  const humanizedType = getHumanizedType(type);
  const adjustedValue = Number(value);

  if (memo[humanizedType]) {
    memo[humanizedType].quantity += adjustedValue;
    memo[humanizedType].entriesCount += 1;
  } else {
    memo[humanizedType] = { quantity: adjustedValue, entriesCount: 1 };
  }

  return memo;
};

const appendConfigurableReward = ({ type, value, quantity }, itemOptions, expertOptions, memo) => {
  const isItemReward = type === ITEM_REWARD_TYPE;

  const collection = isItemReward ? itemOptions : expertOptions;
  const item = collection.find((option) => option.value === value);

  if (item) {
    const { label } = item;
    const count = isItemReward ? quantity : 0;

    if (memo[label]) {
      memo[label].quantity += count;
    } else {
      memo[label] = { quantity: count };
    }
  }

  return memo;
};

const appendSingleValueReward = ({ type }, memo) => {
  const humanizedType = getHumanizedType(type);

  if (memo[humanizedType]) {
    memo[humanizedType].quantity += 1;
  } else {
    memo[humanizedType] = { quantity: 1 };
  }

  return memo;
};

const appendGiftBagReward = ({ type, id, rewardsAttributes = [] }, itemOptions, memo) => {
  const label = `${getHumanizedType(type)} (${id})`;

  // eslint-disable-next-line no-use-before-define
  memo[label] = { giftBag: true, ...calculateRewards(rewardsAttributes, itemOptions) };

  return memo;
};
/* eslint-enable no-param-reassign */

const calculateRewards = (rewardAttributes, itemOptions, expertOptions) => rewardAttributes.reduce((memo, item) => {
  const { type } = item;

  if (REWARD_TYPES_WITH_BALANCE.includes(type)) {
    appendRewardWithBalance(item, itemOptions, memo);
  }

  if ([ITEM_REWARD_TYPE, EXPERT_REWARD_TYPE].includes(type)) {
    appendConfigurableReward(item, itemOptions, expertOptions, memo);
  }

  if ([ENERGY_REFRESH_REWARD_TYPE, REFRESH_ALL_PRODUCERS_REWARD_TYPE].includes(type)) {
    appendSingleValueReward(item, memo);
  }

  if (type === GIFT_BAG_REWARD_TYPE) {
    appendGiftBagReward(item, itemOptions, memo);
  }

  return memo;
}, {});

const humanizedRewards = (calculatedRewards) => Object.keys(calculatedRewards).map((rewardName) => {
  const { quantity, giftBag, ...giftBagRewards } = calculatedRewards[rewardName];

  if (giftBag) {
    return `Gift Bag [${humanizedRewards(giftBagRewards).join(', ')}]`;
  }

  return quantity > 1 ? `${rewardName} (${quantity})` : rewardName;
});

const RewardsSummary = React.memo(({ rewardAttributes, itemOptions, expertOptions }) => {
  const notDestroyedRewards = rewardAttributes.filter(({ _destroy }) => !_destroy);

  return humanizedRewards(calculateRewards(notDestroyedRewards, itemOptions, expertOptions)).join(', ');
}, (prev, next) => (
  isEqual(prev.rewardAttributes === next.rewardAttributes)
  && isEqual(prev.itemOptions === next.itemOptions)
  && isEqual(prev.expertOptions === next.expertOptions)
));

export default RewardsSummary;
