import React from 'react';

import { PageTitle } from '@pages/common';
import ArchivedSettingsEdit from '@components/archived-settings/Edit';
import ArchivedSettingsNew from '@components/archived-settings/New';
import ArchivedSettingsIndex from '@components/archived-settings/Index';
import { ArchivedSettingsRoutes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function archivedSettings() {
  return [
    <Route
      key={ArchivedSettingsRoutes.indexRawPath}
      path={ArchivedSettingsRoutes.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Archived Settings" />
          <ArchivedSettingsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={ArchivedSettingsRoutes.newRawPath}
      path={ArchivedSettingsRoutes.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Archive setting" />
          <ArchivedSettingsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={ArchivedSettingsRoutes.editRawPath}
      path={ArchivedSettingsRoutes.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Archived Setting" action="Edit" />
          <ArchivedSettingsEdit />
        </RouteContainer>
      )}
    />,
  ];
}
