import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { createRewardItem, newRewardItem } from '@requests/rewards/items';
import Form from './Form';

export default function New() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Reward item',
    actionName: 'creation',
    redirectPath: ({ id }) => `/admin/applications/${query.applicationId}/rewards/items/${id}`,
    setData,
  });

  const onSubmit = (values) => createRewardItem(values).then((response) => responseHandler(response, values));

  useEffect(() => {
    newRewardItem(query).then((response) => setData(response.data));
  }, []);

  return data && (
    <Form
      data={data}
      onSubmit={onSubmit}
      setData={setData}
    />
  );
}
