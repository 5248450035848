import React from 'react';
import { Button } from 'react-bootstrap';
import { isEmpty } from 'lodash';

export default function ResetFilterButton({ filters, setFilters }) {
  const clearFilters = () => setFilters({});

  return (
    !isEmpty(filters)
      ? (
        <Button
          variant="secondary"
          size="sm"
          onClick={clearFilters}
          className="mb-2"
        >
          <span aria-hidden="true" className="me-2">&times;</span>
          Clear Filters
        </Button>
      ) : ''
  );
}
