import React from 'react';
import { Button } from 'react-bootstrap';

import {
  useFormGroup,
  FormGroup,
  useCurrentApplication,
  useFormContext,
} from '@hooks';

import DefaultRewardItem from './RewardItem';
import SolitaireRewardItem from './solitaire/RewardItem';

export default function RewardItems({
  rewards, onAdd, rewardItemsConfiguration = [], readOnly = false,
}) {
  const { setFieldValue } = useFormContext();
  const { generateName } = useFormGroup();
  const { currentApplication } = useCurrentApplication();

  const RewardItem = currentApplication.type === 'Solitaire::Application' ? SolitaireRewardItem : DefaultRewardItem;

  const buildRewardsList = () => {
    if (!rewards) return '';

    return rewards.map((reward, index) => {
      const partialRewardName = `rewardsAttributes[${index}]`;

      const handleChange = (data) => {
        setFieldValue(generateName(partialRewardName), data);
      };

      return (
        <FormGroup key={reward._uuid} name={partialRewardName}>
          <RewardItem
            initialData={reward}
            onChange={handleChange}
            rewardItemsConfiguration={rewardItemsConfiguration}
            readOnly={readOnly}
          />
        </FormGroup>
      );
    });
  };

  return (
    <>
      {buildRewardsList()}
      <div className="text-end">
        <Button onClick={onAdd} size="sm" disabled={readOnly}>Add</Button>
      </div>
    </>
  );
}
