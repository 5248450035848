import { get, set } from 'lodash';

/* eslint-disable no-param-reassign, array-callback-return */
export default function entityVariantsReducer(state, action) {
  switch (action.actionType) {
    case 'durationChanged': {
      const { duration, variantIndex } = action;
      const subpath = variantIndex ? `variantsAttributes[${variantIndex}]` : 'currentVariantAttributes.';
      set(state, `${subpath}duration`, duration);
      if (!duration) break;

      const selectedDays = get(state, `${subpath}intermittentDaysPopups`);
      if (!selectedDays) break;
      set(state, `${subpath}intermittentDaysPopups`, selectedDays.filter((el) => el <= duration));
      break;
    }
    default: break;
  }
  return state;
}
