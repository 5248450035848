import React from 'react';

import { FormGroup, useFormContext, useFormGroup } from '@hooks';
import { ServerError } from '@controls/form';
import { AddIcon } from '@components/merge/shared/icons';

import Reward from './Reward';

export default function Rewards({
  values,
  items,
  experts,
  rewardsConfigurations,
  formGroupName = 'rewardsAttributes',
  handleCollectionErrors = true,
  maxRewards,
}) {
  const { dispatch } = useFormContext();
  const { generateName } = useFormGroup();
  const path = generateName(formGroupName);

  const findRewardConfiguration = (type) => (
    rewardsConfigurations.find(({ value: seletedValue }) => seletedValue === type)
  );

  const onRewardAdd = () => dispatch({ type: 'add_reward', path });
  const onRewardDelete = (index) => (
    dispatch({ type: 'remove_reward', path: generateName(`${formGroupName}[${index}]`) })
  );
  const onRewardTypeChange = (index, e) => {
    dispatch({
      type: 'change_reward_type',
      path: generateName(`${formGroupName}[${index}]`),
      defaults: findRewardConfiguration(e.value).defaults || {},
    });
  };

  return (
    <>
      <ServerError name={path} full handleCollectionErrors={handleCollectionErrors} />
      {values.map((reward, index) => (
        !reward._destroy && (
          <FormGroup key={reward._uuid} name={`${formGroupName}[${index}]`}>
            <Reward
              index={index}
              value={reward}
              onRewardDelete={onRewardDelete}
              onRewardTypeChange={onRewardTypeChange}
              items={items}
              experts={experts}
              path={path}
              rewardsConfigurations={rewardsConfigurations}
            />
          </FormGroup>
        )
      ))}
      {(!maxRewards || maxRewards > values.filter((r) => !r._destroy).length) && (
        <AddIcon onClick={onRewardAdd} />
      )}
    </>
  );
}
