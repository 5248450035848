import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { Match3dRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const [data, setData] = useState(null);
  const { query } = useRouter();
  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Star level set',
    actionName: 'creation',
    redirectPath: `/admin/applications/${query.applicationId}/personal_level_sets`,
    setData,
  });

  const onSubmit = (values) => (
    Routes.PersonalLevelSets.createRequest(values).then((response) => responseHandler(response, values))
  );

  useEffect(() => {
    Routes.PersonalLevelSets.newRequest(query).then((response) => setData(response.data));
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} setData={setData} />;
}
