import React, { useCallback } from 'react';

import { OverridableSettingsTabs as AdsConfigurationTabs } from '@components/overridable-settings';

import buildFieldsFromConfig from '@services/settings/buildFieldsFromConfig';
import fieldsFactory from './FieldsFactory';

const tabsConfig = [
  {
    eventKey: '#rewarded-videos',
    title: 'Rewarded Videos',
    fieldsNames: [
      'rewardedVideoPlacementBankId',
      'rewardedVideoLimit',
      'rewardedVideoLimitResetHours',
      'adsEnableInterstitialInsteadRewarded',
      'videoGooglePostbidIds',
      'videoConsecutiveAdsLimit',
      'videoPostbidCpmFloorMultiplier',
      'videoGooglePostbidRequestCountLimit',
      'rewardedVideoMaxAdUnitsConfigurationAttributes',
    ],
  },
  {
    eventKey: '#interstitials',
    title: 'Interstitials',
    fieldsNames: [
      'maxAdUnitsConfigurationAttributes',
      'interstitialsPlacementBankId',
      'consecutiveAdsLimit',
      'newcomersRestrictionsConfigurationAttributes',
      'frequencyRestrictionsConfigurationAttributes',
      'backToBackFrequencyRestrictionsConfigurationAttributes',
      'impressionRestrictionsConfigurationAttributes',
      'customAdUnits',
      'pauseDurationToResumeWithAd',
      'interstitialTimeLimit',
      'googlePostbidIds',
      'postbidCpmFloorMultiplier',
      'googlePostbidRequestCountLimit',
    ],
  },
  {
    eventKey: '#waterfall',
    title: 'Waterfall',
    fieldsNames: [
      'requestRefreshPeriodConfiguration',
    ],
  },
  {
    eventKey: '#banners',
    title: 'Banners',
    fieldsNames: [
      'portraitBannersConfiguration',
      'landscapeBannersConfiguration',
      'adsBannerCpmFloor',
    ],
  },
  {
    eventKey: '#ad_badges',
    title: 'AD Badges',
    fieldsNames: [
      'adBadgePlacementsBankId',
    ],
  },
];

export default function Tabs({
  data, applicationId, isBase, enabledFieldsNames, errors,
}) {
  const { _data: { configurations, options } } = data;

  const fieldsFromBackend = useCallback(buildFieldsFromConfig(configurations, options), []);
  const preparedFieldsFactory = { ...fieldsFactory({ applicationId }), ...fieldsFromBackend };

  return (
    <AdsConfigurationTabs
      fieldsFactory={preparedFieldsFactory}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      errors={errors}
      abTestable={isBase}
      localeNamespace="ads.configuration"
    />
  );
}
