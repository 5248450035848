import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { last } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { FormGroup, useFormContext } from '@hooks';
import { Field } from '@controls/form';
import Tooltip from '@controls/Tooltip';

import Rewards from '@components/merge/shared/Rewards';
import Th from '@components/merge/shared/Th';
import { pickRewardsConfigurations } from '@components/merge/services/RewardsConfigurations';
import { MergeRoutes } from '@pages/routes';

const rewardsConfigurations = pickRewardsConfigurations([
  'Item', 'Expert', 'Coins', 'Gems', 'Energy', 'EnergyRefresh', 'GiftBag', 'RefreshAllProducers',
]);

export default function Levels({ values, schemaId }) {
  const { query } = useRouter();
  const [items, setItems] = useState();
  const [experts, setExperts] = useState();
  const { dispatch } = useFormContext();

  useEffect(() => {
    MergeRoutes.WorldSchemas.editRequest({ id: schemaId, applicationId: query.applicationId }).then((response) => {
      setItems(response.data.items.map((item) => ({ value: item.id, label: item.internalId, line: item.line })));
      setExperts(response.data.experts.map((expert) => ({ value: expert.id, label: expert.internalId })));
    });
  }, [schemaId]);

  let totalXp = 0;
  const preparedLevels = values.reduce((memo, level, index) => {
    if (level._destroy) return memo;

    const preparedLevel = { ...level, index, totalXp };
    totalXp += preparedLevel.xp || 0;

    return [...memo, preparedLevel];
  }, []);

  return (items && experts ? (
    <>
      <Table>
        <thead>
          <tr>
            <Th width="150px">Level</Th>
            <Th width="150px">
              <Tooltip text="shows the total XP number required to get THAT level">
                XP Total
              </Tooltip>
            </Th>
            <Th width="150px">
              <Tooltip text="shows how many XP is required to get the NEXT level">
                XP Diff
              </Tooltip>
            </Th>
            <th>
              <Tooltip text="the rewards user will get when he reaches that level">
                Rewards
              </Tooltip>
            </th>
            <Th width="100px" />
          </tr>
        </thead>
        <tbody>
          {preparedLevels.map((level) => (
            <FormGroup key={level._uuid} name={`levelsAttributes[${level.index}]`}>
              <tr>
                <td><Field name="number" type="number" readOnly /></td>
                <td>{level.totalXp}</td>
                <td><Field name="xp" type="number" readOnly={level === last(preparedLevels)} /></td>
                <td>
                  {level !== preparedLevels[0] && (
                    <Rewards
                      values={level.rewardsAttributes}
                      items={items}
                      experts={experts}
                      rewardsConfigurations={rewardsConfigurations}
                    />
                  )}
                </td>
                <td>
                  {level !== preparedLevels[0] && (
                    <Button
                      onClick={() => dispatch({ type: 'remove_level', index: level.index })}
                      size="sm"
                      variant="danger"
                    >
                      Remove
                    </Button>
                  )}
                </td>
              </tr>
            </FormGroup>
          ))}
        </tbody>
      </Table>
      <Button
        onClick={() => dispatch({ type: 'add_level' })}
        variant="success"
      >
        Add
      </Button>
    </>
  ) : '');
}
