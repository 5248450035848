import React from 'react';

import { Dropdown } from 'react-bootstrap';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@tripledotstudios/react-core';

const Toggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <IconButton icon={faEllipsisV} variant="secondary" minimized />
  </a>
));

export default function DropdownToggle() {
  return <Dropdown.Toggle as={Toggle} />;
}
