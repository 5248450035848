import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { useFormContext, FormGroup } from '@hooks';
import { RemoveButton } from '@pages/common';
import { SelectField } from '@controls/form';
import LabelCol from './rewards/LabelCol';
import AddRewardButton from './rewards/AddRewardButton';

export default function Rewards({
  name,
  label,
  rewards,
  rewardTypeOptions,
  selectionTypeOptions,
  selectionTypes,
  missionIndex,
}) {
  const { dispatch } = useFormContext();

  const switchRewardSelectionType = (event, rewardIndex) => {
    if (event.value !== selectionTypes.RANDOM_ALLOCATION) return;

    dispatch({
      actionType: 'clearRewardTypes',
      name,
      missionIndex,
      rewardIndex,
    });
  };

  const addReward = () => dispatch({
    actionType: 'addMissionTemplateReward',
    name,
    missionIndex,
  });

  const removeReward = (rewardIndex) => dispatch({
    actionType: 'removeMissionTemplateReward',
    name,
    missionIndex,
    rewardIndex,
  });

  const getFirstVisibleIndex = () => {
    const index = rewards.findIndex(({ _destroy }) => !_destroy) || 0;

    return index === -1 ? 0 : index;
  };

  const firstVisibleIndex = getFirstVisibleIndex();

  return rewards && (
    <>
      {rewards.map((reward, rewardIndex) => (
        <Row className="mb-2 align-items-center" key={reward._uuid}>
          <LabelCol>
            {rewardIndex === firstVisibleIndex && label}
          </LabelCol>
          {reward._destroy !== true && (
            <Col xs="11">
              <FormGroup name={`${name}[${rewardIndex}]`}>
                <Row className="align-items-center">
                  <Col xs="4">
                    <SelectField
                      name="selectionType"
                      options={selectionTypeOptions}
                      onChange={(event) => switchRewardSelectionType(event, rewardIndex)}
                    />
                  </Col>
                  <Col xs="7">
                    {reward.selectionType === selectionTypes.ONE_OF && (
                      <SelectField
                        name="rewardTypeIds"
                        options={rewardTypeOptions}
                        isMulti
                      />
                    )}
                  </Col>
                  <Col xs="1">
                    <RemoveButton type="button" onClick={() => removeReward(rewardIndex)} />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          )}
        </Row>
      ))}
      <AddRewardButton
        label={label}
        rewards={rewards}
        onClick={addReward}
      />
    </>
  );
}
