import React from 'react';
import { useQuery } from '@hooks';
import { SolRoutes as Routes } from '@pages/routes';

import { formResponseHandler } from '@requests/responseHandlers';

import Form from './Form';

export default function New() {
  const { response, setResponse } = useQuery(Routes.WinSets.newRequest);

  const redirectIfSuccessHandler = formResponseHandler({
    entityName: 'Xth Win Set',
    actionName: 'creation',
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.WinSets.createRequest(values).then(redirectIfSuccessHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
