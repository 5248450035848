import React from 'react';
import styled from 'styled-components';
import { omit, isEmpty } from 'lodash';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { getRewardItems, deleteRewardItem } from '@requests/rewards/items';
import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InUseColumn,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { useConfirm, useQuery } from '@hooks';
import IconButton from '@controls/buttons';

import RewardContent from '../RewardContent';
import Filter from './Filter';

const PaddedPagination = styled(Pagination)`
  margin-top: 15px;
`;

const { rewardTypes } = APP_DATA;

export const itemsColumns = ({ onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Item name', accessor: 'name' },
  {
    Header: 'Item type',
    accessor: 'rewardType',
    Cell: ({ row }) => rewardTypes[row.original.rewardType].name,
  },
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Data',
    Cell: ({ row }) => <RewardContent model={row.original} />,
  },
  {
    Header: 'Actions',
    Cell: ({
      row: {
        original: {
          id, type, name, inUse,
        },
      },
    }) => {
      const isInUse = !isEmpty(omit(inUse, '_uuid'));
      return (
        <ButtonToolbar>
          <IconButton.Edit to={`${id}/edit`} readonly={isInUse} minimized />
          <IconButton.Delete
            onClick={onDelete(id, type, name)}
            disabled={isInUse}
            title={isInUse ? 'Disabled as the entity is used' : null}
            minimized
          />
        </ButtonToolbar>
      );
    },
  },
];

export default function Index() {
  const { response, refetch } = useQuery(getRewardItems, { includeInUse: true, include_content: true });
  const { query } = useRouter();
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Reward Item',
    actionName: 'removal',
    refetch,
  });

  const onDelete = (id, type, name) => () => {
    const formattedType = type === 'Rewards::Container' ? 'Container' : 'Item';
    confirm.showConfirmation({
      title: `${formattedType} '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => deleteRewardItem({ id, applicationId: query.applicationId })
        .then(responseHandler));
  };

  const columns = React.useMemo(() => itemsColumns({ onDelete }), []);

  return response && (
    <div>
      <PageHeader title="Game Items" filter={<Filter rewardTypes={rewardTypes} />}>
        <IconButton.New to="new" />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'rewardType', desc: false }}
      />
      <PaddedPagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
