import SystemItemSet from './System';
import BlackjackItemSet from './Blackjack';
import MergeItemSet from './Merge';
import MyPetCafeItemSet from './MyPetCafe';
import PuzzleTimeItemSet from './PuzzleTime';
import SolitaireItemSet from './Solitaire';
import SolitaireScapesItemSet from './SolitaireScapes';
import SpiderSolitaireItemSet from './SpiderSolitaire';
import SudokuItemSet from './Sudoku';
import TileItemSet from './Tile';
import WoodokuItemSet from './Woodoku';
import Match3dItemSet from './Match3d';
import LpBingoItemSet from './LpBingo';
import WoodokuSagaItemSet from './WoodokuSaga';
import BlockPartyItemSet from './BlockParty';
import SortagaItemSet from './Sortaga';
import ReactorItemSet from './Reactor';
import SolitaireScapesCollectionItemSet from './SolitaireScapesCollection';
import WordHop from './WordHop';
import SolitaireComItemSet from './SolitaireCom';
import HexagoItemSet from './Hexago';
import WooAspenItemSet from './Woo/Aspen';
import TaosItemSet from './Taos';
import SolCoroItemSet from './Sol/Coro';
import LorayItemSet from './Loray';
import AxumItemSet from './Axum';
import LuskItemSet from './Lusk';
import VossItemSet from './Voss';

const applicationsMapping = {
  'Blackjack::Application': BlackjackItemSet,
  'Merge::Application': MergeItemSet,
  'MyPetCafe::Application': MyPetCafeItemSet,
  'PuzzleTime::Application': PuzzleTimeItemSet,
  'Solitaire::Application': SolitaireItemSet,
  'SolitaireScapes::Application': SolitaireScapesItemSet,
  'SpiderSolitaire::Application': SpiderSolitaireItemSet,
  'Sudoku::Application': SudokuItemSet,
  'Tile::Application': TileItemSet,
  'Woodoku::Application': WoodokuItemSet,
  'Match3d::Application': Match3dItemSet,
  'LpBingo::Application': LpBingoItemSet,
  'WoodokuSaga::Application': WoodokuSagaItemSet,
  'BlockParty::Application': BlockPartyItemSet,
  'Sortaga::Application': SortagaItemSet,
  'Reactor::Application': ReactorItemSet,
  'SolitaireScapesCollection::Application': SolitaireScapesCollectionItemSet,
  'WordHop::Application': WordHop,
  'SolitaireCom::Application': SolitaireComItemSet,
  'Hexago::Application': HexagoItemSet,
  'Woo::Aspen::Application': WooAspenItemSet,
  'Taos::Application': TaosItemSet,
  'Sol::Coro::Application': SolCoroItemSet,
  'Loray::Application': LorayItemSet,
  'Axum::Application': AxumItemSet,
  'Lusk::Application': LuskItemSet,
  'Voss::Application': VossItemSet,
};

export default function Finder(currentApplication) {
  if (!currentApplication) return SystemItemSet;

  const itemSet = applicationsMapping[currentApplication.type];

  if (!itemSet) throw new Error('Not supported application type');

  return itemSet;
}
