import React, {
  useContext, createContext,
} from 'react';

const settingsContext = createContext();

export function useSettingsContext() {
  return useContext(settingsContext);
}

export function SettingsContext({ settings, children }) {
  return (
    <settingsContext.Provider value={settings}>
      {children}
    </settingsContext.Provider>
  );
}
