import React from 'react';
import { Table } from 'react-bootstrap';

import { useQuery } from '@hooks';
import { PageHeader, Pagination } from '@pages/common';

import { ApplicationRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

export default function Index() {
  const { response } = useQuery(ApplicationRoutes.indexRequest);
  return response ? (
    <div>
      <PageHeader title="Applications">
        <IconButton.New to={ApplicationRoutes.newRawPath} />
      </PageHeader>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {response.items.map(({ id, name }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{name}</td>
              <td>
                <IconButton.Edit to={ApplicationRoutes.editPath({ id })} minimized />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  ) : '';
}
