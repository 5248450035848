import React from 'react';

import { PageSection } from '@pages/common';
import { FormButtonsGroup, Label, Field } from '@controls/form';
import { Form, useQuery } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { AdminRoutes } from '@pages/routes';

export default function SystemSettingsForm() {
  const { response, setResponse } = useQuery(AdminRoutes.Settings.System.editRequest);

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'System Settings',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => AdminRoutes.Settings.System.updateRequest(values).then(responseHandler);

  return response && (
    <Form initialValues={response} onSubmit={onSubmit}>
      {() => (
        <>
          <PageSection title="System Settings" size="md" actions={<FormButtonsGroup hideCancel />} />

          <Label
            text="Available Experiments Data S3 Path"
            tooltipText="Leave empty to disable available experiments data export"
            fieldSize={8}
          >
            <Field name="availableExperimentsExportS3Path" />
          </Label>

          <Label
            text="AppsFlyer auth token"
            fieldSize={8}
          >
            <Field name="appsflyerAuthToken" placeholder={response.appsflyerAuthTokenPlaceholder} />
          </Label>

          <Label
            text="AppLovin MAX GDPR API token"
            fieldSize={8}
          >
            <Field name="appLovinMaxGdprApiToken" placeholder={response.appLovinMaxGdprApiTokenPlaceholder} />
          </Label>

          <PageSection title="Chartboost">
            <Label
              text="User ID"
              fieldSize={8}
            >
              <Field name="chartboostUserId" placeholder={response.chartboostUserIdPlaceholder} />
            </Label>

            <Label
              text="User Signature"
              fieldSize={8}
            >
              <Field name="chartboostUserSignature" placeholder={response.chartboostUserSignaturePlaceholder} />
            </Label>
          </PageSection>
        </>
      )}
    </Form>
  );
}
