import React, { useMemo, useState } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { useQuery, useConfirm } from '@hooks';

import {
  PageHeader,
  Pagination,
  RulesList,
  StatusBadge,
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  ReactTable,
  DuplicationModalWithPriority,
  PriorityColumn,
} from '@pages/common';

import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { VersionUpdateRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';

const statuses = APP_DATA.enums['VersionUpdates::AppUpdateStatusesEnum'];

const columnsFactory = ({
  applicationId, editPath, onDelete, setDuplicatedRecord,
}) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  PriorityColumn,
  { Header: 'Update type', accessor: 'type' },
  {
    Header: 'Rules',
    id: 'rules',
    style: { width: '30%' },
    Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
  },
  {
    Header: 'Status',
    Cell: ({ row: { original: { status, statusHumanize } } }) => (
      <StatusBadge options={statuses} value={status} label={statusHumanize} />
    ),
  },
  InLiveColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        <IconButton.Delete onClick={onDelete(id)} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const {
    AppUpdates: {
      deleteRequest,
      editPath,
      newPath,
      indexRequest,
    },
  } = VersionUpdateRoutes;

  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(indexRequest);
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'App update',
    actionName: 'delete',
    refetch,
  });

  const onDuplicate = (values) => (
    VersionUpdateRoutes.AppUpdates.duplicateRequest({ ...values, applicationId })
  );

  const onDelete = (id) => () => {
    confirm.showConfirmation({ title: 'App update will be deleted and not available for work. Continue?' })
      .then(() => deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({
    applicationId, editPath, onDelete, setDuplicatedRecord,
  }), []);

  return response && (
    <div>
      <PageHeader
        title="App Updates"
        filter={<Filter />}
        userGuideUrlKey="versionUpdatesGuideUrl"
      >
        <IconButton.New to={newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
      <DuplicationModalWithPriority
        record={duplicatedRecord}
        entityName="App Update"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={onDuplicate}
        entitiesByPriority={response.entitiesByPriority}
      />
    </div>
  );
}
