import React from 'react';
import { Link } from 'react-router-dom';

import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ShortcutLink({
  color, icon, title, link, externalLink, application,
}) {
  const reportClickToNewRelic = () => {
    if (window.newrelic) {
      window.newrelic.addPageAction('Shortcut Click', { shortcut: `${application.name} | ${title}` });
    }
  };

  return (
    <div className="mb-1">
      <Link
        to={link}
        target={externalLink && '_blank'}
        onClick={() => reportClickToNewRelic()}
      >
        <FontAwesomeIcon icon={icon} color={color} className="me-2" />
        <b>{title}</b>
        {externalLink
          && <FontAwesomeIcon icon={faExternalLinkSquareAlt} className="ms-2" />}
      </Link>
    </div>
  );
}
