import React from 'react';

import { FaIcon } from '@pages/common';

export default function MissionTypeOrderIcon({ order, iconMapping }) {
  const values = iconMapping[order];
  if (!values) return null;

  return <FaIcon icon={values.icon} color={values.color} />;
}
