import { pull } from 'lodash';

export default (state, action) => {
  if (action.actionType === 'add_supported_language') {
    const { lang } = action;

    state.supportedLanguages.push(lang);
  }

  if (action.actionType === 'remove_supported_language') {
    const { lang } = action;

    pull(state.supportedLanguages, lang);
    pull(state.languagesInPopup, lang);

    if (state.defaultLanguage === lang) {
      state.defaultLanguage = 'system';
    }
  }

  if (action.actionType === 'add_language_to_popup') {
    const { lang, position } = action;

    if (position === 'top') {
      state.languagesInPopup = [lang, ...state.languagesInPopup];
    } else if (position === 'bottom') {
      state.languagesInPopup.push(lang);
    }
  }

  if (action.actionType === 'reorder_languages') {
    const { langs } = action;

    state.languagesInPopup = langs;
  }

  return state;
};
