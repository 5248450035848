import {
  fetchCollection, newResource, editResource, createResource, updateResource, deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/blackjack/daily_challenge_configs`;

export function getDailyChallengeConfigs(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function newDailyChallengeConfig(options) {
  return newResource(baseUrl(options), options);
}

export function editDailyChallengeConfig(data, options) {
  return editResource(baseUrl(data), data, options);
}

export function createDailyChallengeConfig(values) {
  return createResource(baseUrl(values), values);
}

export function updateDailyChallengeConfig(values) {
  return updateResource(baseUrl(values), values);
}

export function deleteDailyChallengeConfig(values) {
  return deleteResource(baseUrl(values), values);
}
