import { addUuid } from '@services/recursivelyProcessObject';
import { streakRewardTypes, POSITION_WITH_REWARDS_MULTIPLIER } from './constants';

const formReducer = (state, action) => {
  switch (action.type) {
    case 'addEmptyEntity': {
      const { position } = action;
      state.magicPotStreaksAttributes[position].streakRewardsAttributes.push(addUuid({
        id: null,
        rewardType: streakRewardTypes.ROCKET_VERTICAL,
        amount: 1,
      }));
      break;
    }
    case 'removeEntity': {
      const { position, index } = action;

      state.magicPotStreaksAttributes[position].streakRewardsAttributes[index]._destroy = true;
      break;
    }

    case 'changeRewardType': {
      const { index, value, position } = action;
      const multiplierStreak = state.magicPotStreaksAttributes[POSITION_WITH_REWARDS_MULTIPLIER]
        .streakRewardsAttributes[index];
      if (position === POSITION_WITH_REWARDS_MULTIPLIER) {
        if (value === streakRewardTypes.REWARDS_MULTIPLIER) {
          Object.assign(multiplierStreak, { amount: null, multiplierCount: 2.0 });
        } else {
          Object.assign(multiplierStreak, { amount: 1, multiplierCount: null });
        }
      }
      break;
    }

    default: break;
  }

  return state;
};

export default formReducer;
