import React from 'react';
import classNames from 'classnames';
import { useTheme } from 'styled-components';

const BoardWrapper = ({
  children,
  maxWidth,
  outline,
  errors,
}) => (
  <div
    className={classNames(
      'd-flex align-items-center flex-wrap',
      {
        border: outline,
        'border-black': outline && !errors,
        'border-danger': outline && errors,
        ...(outline ? { 'w-50': true } : { 'w-100': true, 'h-100': true }),
      },
    )}
    style={{ maxWidth: `${maxWidth}px` }}
  >
    {children}
  </div>
);

const BoardRow = ({ columnsCount, children }) => (
  <div className="d-flex w-100" style={{ aspectRatio: `${columnsCount} / 1` }}>
    {children}
  </div>
);

const BoardCell = ({
  outline,
  selected,
  errors,
  children,
  onClick,
}) => {
  const { woodoku: { board: { cell: cellColors } } } = useTheme();

  const baseBackgroundColor = errors ? cellColors.invalid : cellColors.normal;
  let backgroundColor = selected ? cellColors.selected : baseBackgroundColor;

  if (!outline) backgroundColor = selected ? cellColors.selected : 'transparent';

  return (
    <div
      style={{
        aspectRatio: '1 / 1',
        flex: '1 1 0',
        backgroundColor,
        border: (outline || selected) && '.5px solid lightgrey',
        clear: 'both',
      }}
      onClick={onClick}
      aria-hidden="true"
    >
      {children}
    </div>
  );
};

const emptyBoard = (width, height) => Array(height).fill([]).reduce((memo) => [...memo, Array(width).fill('0')], []);

const coordinatesFromValue = (rawValue, width, height) => {
  if (!rawValue) return emptyBoard(width, height);

  const origin = rawValue.split('\n');

  return Array(height).fill([]).reduce((memo, _, row) => {
    if (!origin[row]) return [...memo, Array(width).fill('0')];

    return [...memo, Array(width).fill('0').map((value, column) => origin[row][column] || value)];
  }, []);
};

export default function FigureSetsShapeBoard({
  clickable = true,
  width,
  height,
  maxWidth = '100%',
  value,
  currentCoordinates,
  onUpdate,
  errors,
  outline = true,
}) {
  const coordinates = currentCoordinates || coordinatesFromValue(value, width, height);

  const updateCoordinates = (rowIndex, columnIndex) => {
    const newCoordinates = coordinates.map((prevRow, prevRowIndex) => {
      if (prevRowIndex !== rowIndex) return prevRow;

      return prevRow.map((prevColumn, prevColumnIndex) => {
        if (prevColumnIndex !== columnIndex) return prevColumn;

        return prevColumn === '0' ? '1' : '0';
      });
    });

    return onUpdate(newCoordinates);
  };

  return (
    <div className="d-flex justify-content-center align-self-center" style={{ flexBasis: '100%' }}>
      <BoardWrapper maxWidth={maxWidth || (width + height) * 18} errors={errors} outline={outline}>
        {coordinates.map((row, rowIndex) => (
          <BoardRow columnsCount={width}>
            {row.map((_, columnIndex) => (
              <BoardCell
                rowsCount={height}
                columnsCount={width}
                selected={coordinates[rowIndex][columnIndex] === '1'}
                onClick={() => clickable && updateCoordinates(rowIndex, columnIndex)}
                errors={errors}
                outline={outline}
              />
            ))}
          </BoardRow>
        ))}
      </BoardWrapper>
    </div>
  );
}
