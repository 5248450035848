import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import APP_DATA from '@services/appData';
import SettingsTable from '@pages/common/search-enabled-fields/SettingsTable';

const { enums: { GameSettingsStatuses: gameSettingsStatuses } } = APP_DATA;

export default function UsedSettingsModal({ fieldName, handleClose, usages }) {
  return (
    <Modal show={!isEmpty(usages)} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>There are entities which still use selected Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>List of entities:</b>
        <SettingsTable data={usages} entityStatuses={gameSettingsStatuses} fieldName={fieldName} />
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="secondary" onClick={handleClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}
