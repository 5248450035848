import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { at } from 'lodash';

import APP_DATA from '@services/appData';

import { Form, useCurrentApplication, useQuery } from '@hooks';
import { Label, Field, AssetsKeySelectField } from '@controls/form';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { prepareInboundData } from '@requests/common';

export default function MissionTypeForm({
  actionName, entityName, getForm, onHide, submitForm, record, refetch,
}) {
  if (!record) { return null; }

  const { currentApplication, applicationName } = useCurrentApplication();
  const [assetKeysStatuses, assetTypes] = at(
    APP_DATA.enums,
    ['AssetsKeys::Statuses', `${applicationName}::AssetTypes`],
  );
  const { response: data, setResponse } = useQuery((params) => getForm({ ...params, id: record }));

  const fullData = { ...data, applicationId: currentApplication.id };

  const responseHandler = collectionResponseHandlerFactory({
    actionName,
    refetch,
    entityName,
  });

  const onSubmit = async (values) => {
    const response = await submitForm(values);
    responseHandler(response);
    if (response.status < 300) {
      onHide();
    } else {
      setResponse((currentValues) => ({ ...currentValues, ...values, ...prepareInboundData(response.data) }));
    }
  };

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{entityName}</Modal.Title>
      </Modal.Header>
      {data && (
        <Form
          initialValues={fullData}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => (
            <>
              <Modal.Body>
                <Label text="Internal id*">
                  <Field name="internalId" />
                </Label>
                <Label text="Asset bundle*">
                  <AssetsKeySelectField
                    name="assetKeyId"
                    status={assetKeysStatuses.ACTIVE}
                    types={assetTypes.CONTACT}
                  />
                </Label>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit">Confirm</Button>
                <Button type="button" variant="secondary" onClick={onHide}>Cancel</Button>
              </Modal.Footer>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
}
