import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { WooAspenRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse } = useQuery(Routes.RewardSets.editRequest);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Reward Set',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.RewardSets.updateRequest({ ...values, applicationId }).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
