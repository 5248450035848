import { addUuid } from '@services/recursivelyProcessObject';
import removeEntity from '@services/removeEntity';
import { persistedCollection } from '@services/utils';

export default function formReducer(state, action) {
  switch (action.type) {
    case 'addChapter': {
      state.chaptersAttributes.push(addUuid({ position: persistedCollection(state.chaptersAttributes).length + 1 }));
      break;
    }
    case 'removeChapter': {
      const { uuid } = action;

      return removeEntity(state, 'chaptersAttributes', uuid, 'position');
    }
    default: break;
  }

  return state;
}
