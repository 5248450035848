import {
  sortBy, last, get, set,
} from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';
import { persistedCollection } from '@services/utils';
import { EMPTY_CONFIGS_REWARD_ATTRIBUTES } from '@root/pages/woo/aspen/play-on-pop-up/constants';

export function maxPosition(collection) {
  const lastElement = last(collection);

  // Set max position to -1 in case if there are no not-destroyed entities so that new added entity
  // will have position of 0
  return lastElement ? lastElement.position : -1;
}

export default function popUpsReducer(state, action) {
  const configsAttributesPath = action.path || 'configsAttributes';
  const configsAttributes = get(state, configsAttributesPath, []);

  const nonDestroyedConfigs = () => persistedCollection(configsAttributes);
  const sortedConfigs = () => sortBy(nonDestroyedConfigs(), 'position');

  const freshCollection = () => {
    let maxPos = 0;

    return sortBy(configsAttributes, 'position').map((range) => {
      if (!range._destroy) {
        range.position = maxPos;

        if (maxPos === 0) range.fromChapter = 1;

        maxPos += 1;
      }

      return range;
    });
  };

  switch (action.type) {
    case 'addPopUp': {
      const position = maxPosition(sortedConfigs()) + 1;
      const attributes = {
        position,
        _destroy: false,
        abortSequence: false,
        minRewardsToShow: null,
        configsRewardsAttributes: [addUuid(EMPTY_CONFIGS_REWARD_ATTRIBUTES)],
      };

      configsAttributes.push(addUuid(attributes));
      set(state, configsAttributesPath, configsAttributes);

      break;
    }
    case 'removePopUp': {
      const { _uuid } = action;

      const toDestroy = configsAttributes.find((config) => config._uuid === _uuid);
      toDestroy._destroy = true;

      const newCollection = freshCollection();
      const notDestroyed = persistedCollection(newCollection);

      notDestroyed.forEach((range, index) => {
        const prevNotDestroyed = notDestroyed[index - 1];

        if (prevNotDestroyed) {
          const prevChapterRange = newCollection.find((aff) => aff._uuid === prevNotDestroyed._uuid);

          if (prevChapterRange) range.fromChapter = Number(prevChapterRange.toChapter) + 1;
        }

        return range;
      });

      set(state, configsAttributesPath, newCollection);

      break;
    }
    case 'setConfigReward': {
      const { index, path, option: { rewardId, rewardType } } = action;
      set(state, `${path}[${index}]`, { ...get(state, `${path}[${index}]`), rewardId, rewardType });
      break;
    }
    case 'addConfigReward': {
      const { path } = action;
      get(state, path).push(addUuid(EMPTY_CONFIGS_REWARD_ATTRIBUTES));
      break;
    }
    case 'removeConfigReward': {
      const { path, index } = action;
      set(state, `${path}[${index}]._destroy`, true);
      break;
    }
    default: break;
  }

  return state;
}
