import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { CheckboxField, SelectField, Label } from '@controls/form';

import Rewards from '@components/merge/shared/Rewards';
import Targets from '@components/merge/shared/Targets';
import { PageSection } from '@pages/common';

export default function RandomOrder({
  value, items, postcards, rewardsConfigurations,
}) {
  const emptyOption = { label: 'None', value: null };

  return (
    <Row>
      <Col xs={1}>
        <PageSection title="Attributes" />
        <hr />
        <Label text="Repeatable" direction="vertical">
          <CheckboxField name="repeatable" />
        </Label>
      </Col>
      <Col xs={2}>
        <PageSection title="Postcards" />
        <hr />
        <Label text="From" direction="vertical">
          <SelectField options={postcards} name="fromSchemaPostcardId" />
        </Label>
        <Label text="Till" direction="vertical">
          <SelectField options={[emptyOption, ...postcards]} name="toSchemaPostcardId" />
        </Label>
      </Col>
      <Col xs={4}>
        <PageSection title="Required Items" />
        <hr />
        <Targets values={value.targetsAttributes} items={items} />
      </Col>
      <Col xs={1} />
      <Col xs={4}>
        <PageSection title="Rewards" />
        <hr />
        <Rewards
          values={value.rewardsAttributes}
          items={items}
          rewardsConfigurations={rewardsConfigurations}
        />
      </Col>
    </Row>
  );
}
