import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  white-space: pre-wrap;
`;

export default function MultilineContent({ value, wrapper }) {
  return (
    <StyledDiv>
      {wrapper ? wrapper({ value }) : value}
    </StyledDiv>
  );
}
