import React from 'react';

import { SelectField } from '@controls/form';
import EditableCell from './EditableCell';

export default function SelectCell({
  row, name, options, errors, isRequired = false, isEditable = true,
}) {
  const value = row[name];
  const error = errors[name];
  const text = (value === undefined || value === null) ? '' : options.find((option) => option.value === value).label;

  return (
    <EditableCell text={text} isEditable={isEditable} error={error}>
      {({ stopEditing, onKeyPress }) => (
        <SelectField
          name={name}
          options={options}
          onBlur={stopEditing}
          onKeyDown={onKeyPress}
          autoFocus
          includeEmpty={!isRequired}
        />
      )}
    </EditableCell>
  );
}
