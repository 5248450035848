import React from 'react';

import { editAppConfig, updateAppConfig } from '@requests/my-pet-cafe/appConfigs';
import CreateUpdateConfig from './CreateUpdateConfig';

export default function Edit() {
  return (
    <CreateUpdateConfig
      fetchFunction={editAppConfig}
      saveFunction={updateAppConfig}
      actionName="update"
    />
  );
}
