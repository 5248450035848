import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { LabelsFormGroup } from '@controls/form';
import { useCurrentApplication, Form } from '@hooks';
import { LabelRoutes } from '@pages/routes';
import { PageHeader } from '@pages/common';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';

import IconButton from '@controls/buttons';

import EntitiesTable from './search/EntitiesTable';

export default function SearchLabels() {
  const [data, setData] = useState();
  const [submitted, setSubmitted] = useState(false);
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { query: { labelId } } = useRouter();
  const initialId = parseInt(labelId, 10);

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Labels',
    actionName: 'search',
    showSuccess: false,
    setData,
  });

  const onSubmit = (values) => {
    LabelRoutes
      .searchRequest({ ...values, applicationId })
      .then(responseHandler);
  };

  if (initialId && !submitted) {
    onSubmit({ id: initialId });
    setSubmitted(true);
  }

  return (
    <>
      <PageHeader title="Labels Search" />
      <Form onSubmit={onSubmit} disableDirtyChecker initialValues={{ id: initialId }}>
        {({ values: { id } }) => (
          <>
            <Row>
              <Col xs="9">
                <LabelsFormGroup
                  label="Select Label"
                  tooltip="You can search by Label name"
                  name="id"
                  isMulti={false}
                  fieldSize={8}
                />
              </Col>
              <Col xs="3" className="ps-0">
                <IconButton.Search minimized type="submit" disabled={!id} block />
              </Col>
            </Row>
            {data && data.items.length > 0 && <EntitiesTable data={data.items} />}
          </>
        )}
      </Form>
    </>
  );
}
