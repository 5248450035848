import React from 'react';
import { Link } from 'react-router-dom';

export default function InUseLink({
  entityName, id, name, type, path, children, statusHumanize, as, newTab = false,
}) {
  const text = children
    || `${entityName} "${name}"${statusHumanize ? ` (${statusHumanize})` : ''} ${as ? ` as ${as}` : ''}`;
  return path ? (
    <Link to={path} key={`${type}_${id}`} target={newTab && '_blank'}>
      {text}
    </Link>
  ) : <span>{text}</span>;
}
