import React, { useState, useCallback } from 'react';
import { InputGroup } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import camelCase from 'lodash/camelCase';

import {
  FormButtonsGroup,
  FormTrails,
  SelectField,
  Label,
  Field,
  ServerError,
} from '@controls/form';
import { Form, useCurrentApplication, useI18n } from '@hooks';
import { PageHeader, SettingsFieldsSelect, buildSettingLabel } from '@pages/common';
import { ArchivedSettingsRoutes, GameSettingsRoutes } from '@pages/routes';
import { fieldsFactories } from '@components/game-settings';
import { fromClassNameToOptions } from '@services/enums';
import APP_DATA from '@services/appData';
import buildFieldsFromConfig from '@services/settings/buildFieldsFromConfig';
import { clearUuids } from '@services/recursivelyProcessObject';
import UsedSettingsModal from './UsedSettingsModal';
import settingToKeys from './settingToKeys';

const strategiesEnumClass = 'GameSettings::ArchivedSettingsStrategies';
const { enums } = APP_DATA;
const strategies = enums[strategiesEnumClass];
const strategiesOptions = fromClassNameToOptions(strategiesEnumClass);

export default function ArchivedSettingForm({ onSubmit, data }) {
  const { applicationId, frontendTypeName, currentApplication } = useCurrentApplication();
  const [usedSettings, setUsedSettings] = useState(null);
  const { translate } = useI18n();
  const { _data: { configurations, options } } = data;

  const submit = async (values) => {
    const usage = !values.id && values.key ? await GameSettingsRoutes.searchFieldRequest({
      fieldName: values.key, gameType: frontendTypeName, applicationId,
    }) : {};
    if (!isEmpty(omit(usage.data, '_uuid'))) {
      setUsedSettings(usage);
    } else {
      const fieldName = values?.key;
      const locale = translate.fallback(`gameSettings.${camelCase(values.key)}`);
      await onSubmit({
        ...omit(values, '_data'),
        applicationId,
        key: fieldName,
        name: locale?.label || locale?.clientKey || fieldName,
        affectedKeys: values.affectedKeys || settingToKeys(values.key, translate).join(' '),
      });
    }
  };
  const fieldsFromBackend = useCallback(buildFieldsFromConfig(configurations, options), []);
  const factory = {
    ...fieldsFactories[`${currentApplication.name}::GameSettings`]({ applicationId }),
    ...fieldsFromBackend,
  };

  return (
    <Form initialValues={data} onSubmit={submit}>
      {({ values, setFieldValue }) => {
        const { Labeled: SettingsField } = factory[camelCase(values.key)] || {};
        return (
          <>
            <PageHeader title={`${data.id ? 'Edit Archived' : 'Archive'} Setting`}>
              <FormButtonsGroup
                cancelButtonPath={ArchivedSettingsRoutes.indexPath({ applicationId })}
              />
            </PageHeader>
            <Label text="Setting" fieldSize={8}>
              {data.id
                ? buildSettingLabel({
                  field: camelCase(data?.key),
                  localeNamespace: 'gameSettings',
                  translate,
                }) : (
                  <SettingsFieldsSelect
                    fields={data.fields}
                    localeNamespace="gameSettings"
                    name="key"
                    selectedValue={values.key}
                    onChange={({ value }) => {
                      setFieldValue('key', value);
                      setFieldValue('data', values.defaults[camelCase(value)]);
                      setFieldValue('meta', omit(values.meta, 'errors.data'));
                    }}
                  />
                )}
              <ServerError name="key" />
            </Label>
            <Label text="Behaviour" fieldSize={8}>
              <SelectField name="strategy" options={strategiesOptions} />
            </Label>
            <FormTrails data={data} />
            {values.strategy === strategies.SEND_DEFAULT && (
              <>
                {SettingsField ? (
                  <SettingsField
                    name="data"
                    values={{ data: values.data || {} }}
                    localeNamespace="gameSettings"
                  />
                ) : values.key && (
                  <Label fieldSize={8} text="Hardcoded value (There is no field builder for some reason)">
                    {JSON.stringify(clearUuids(values.data), null, 2)}
                  </Label>
                )}
                <Label
                  fieldSize={8}
                  text="Client Version"
                  tooltipText="Clients of versions older than specified in this field will receive value,
                    configured here"
                >
                  <InputGroup>
                    <InputGroup.Text>Older than</InputGroup.Text>
                    <Field name="versionLessThan" inline={false} />
                  </InputGroup>
                  <ServerError name="versionLessThan" />
                </Label>
              </>
            )}
            {!data.id && usedSettings && (
              <UsedSettingsModal
                usages={usedSettings.data}
                fieldName={camelCase(values.key)}
                handleClose={() => setUsedSettings(null)}
              />
            )}
          </>
        );
      }}
    </Form>
  );
}

