import React from 'react';
import { PageSection as TDSPageSection } from '@tripledotstudios/react-core';

import IconTooltip from '@controls/tooltips';

export default function PageSection({
  userGuideUrlKey,
  children,
  ...rest
}) {
  return (
    <TDSPageSection
      leadingAccessories={<IconTooltip.Guide userGuideUrlKey={userGuideUrlKey} />}
      {...rest}
    >
      {children}
    </TDSPageSection>
  );
}
