import GoalsFields from './fields/Goals';
import TeleportingFields from './fields/Teleporting';

export default function registry(type) {
  switch (type) {
    case 'Tile::ModesConfigs::Goals':
      return GoalsFields;
    case 'Tile::ModesConfigs::Teleporting':
      return TeleportingFields;
    default:
      throw new Error('Not supported Game Mode');
  }
}
