import React from 'react';

import { addUuid } from '@services/recursivelyProcessObject';
import { useFormContext } from '@hooks';

import IconButton from '@controls/buttons';

export default function AddEmptyVariantButton({ disabled, handleTabChange }) {
  const { dispatch, values } = useFormContext();

  const addNewVariant = () => {
    const options = {
      name: 'New',
      weight: 0,
      skip: false,
    };
    dispatch({
      type: 'addVariant',
      variant: addUuid(options),
    });
    handleTabChange(`#${values.variantsAttributes.length}`);
  };

  return (
    <IconButton.New
      onClick={addNewVariant}
      className="mb-3"
      disabled={disabled}
    >
      Add new Variant
    </IconButton.New>
  );
}
