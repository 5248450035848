import React from 'react';

import { Row, Col, Card } from 'react-bootstrap';
import { FormGroup } from '@hooks';
import ServerError from '@controls/form/ServerError';
import { ColoredCard } from '@components/shared';

import OperationCol from './OperationCol';
import ChildRules from './ChildRules';
import ButtonsBlock from './ButtonsBlock';
import colors from '../colors';

export default function Section({
  index,
  nestingLevel,
  rulesAttributes,
  ruleSectionsAttributes,
  _destroy,
  hideControls,
  hideNamedGroupsRule,
  disabled,
  excludeTags,
  setShowCreateNamedRuleGroupModal,
  setNamedRuleGroupSection,
  createNewNamedRuleGroupEnabled,
}) {
  return !_destroy && (
    <Row className={nestingLevel !== 0 ? 'mb-3' : ''}>
      <Col>
        <ColoredCard color={colors[nestingLevel]}>
          <Card.Header>
            <OperationCol disabled={disabled} />
          </Card.Header>
          <Card.Body>
            <ChildSections
              nestingLevel={nestingLevel}
              ruleSectionsAttributes={ruleSectionsAttributes}
              disabled={disabled}
              hideControls={hideControls}
              hideNamedGroupsRule={hideNamedGroupsRule}
              excludeTags={excludeTags}
              createNewNamedRuleGroupEnabled={createNewNamedRuleGroupEnabled}
              setShowCreateNamedRuleGroupModal={setShowCreateNamedRuleGroupModal}
              setNamedRuleGroupSection={setNamedRuleGroupSection}
            />
            <ChildRules
              nestingLevel={nestingLevel}
              rulesAttributes={rulesAttributes}
              hideControls={hideControls}
              disabled={disabled}
              excludeTags={excludeTags}
            />
            {!hideControls && (
              <ButtonsBlock
                index={index}
                nestingLevel={nestingLevel}
                hideNamedGroupsRule={hideNamedGroupsRule}
                disabled={disabled}
                createNewNamedRuleGroupEnabled={createNewNamedRuleGroupEnabled}
                setShowCreateNamedRuleGroupModal={setShowCreateNamedRuleGroupModal}
                setNamedRuleGroupSection={setNamedRuleGroupSection}
              />
            )}
            <ServerError name="rulesAttributes" />
          </Card.Body>
        </ColoredCard>
      </Col>
    </Row>
  );
}

function ChildSections({
  nestingLevel,
  ruleSectionsAttributes,
  hideControls,
  hideNamedGroupsRule,
  excludeTags,
  setShowCreateNamedRuleGroupModal,
  setNamedRuleGroupSection,
  createNewNamedRuleGroupEnabled,
  disabled,
}) {
  return (
    ruleSectionsAttributes && ruleSectionsAttributes.map((section, index) => (
      <FormGroup name={`ruleSectionsAttributes.${index}`}>
        <Section
          key={section._uuid}
          index={index}
          nestingLevel={nestingLevel + 1}
          rulesAttributes={section.rulesAttributes}
          ruleSectionsAttributes={section.ruleSectionsAttributes}
          _destroy={section._destroy}
          hideControls={hideControls}
          hideNamedGroupsRule={hideNamedGroupsRule}
          excludeTags={excludeTags}
          disabled={disabled}
          setShowCreateNamedRuleGroupModal={setShowCreateNamedRuleGroupModal}
          setNamedRuleGroupSection={setNamedRuleGroupSection}
          createNewNamedRuleGroupEnabled={createNewNamedRuleGroupEnabled}
        />
      </FormGroup>
    ))
  );
}
