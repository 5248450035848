import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { editLobbyConfig, updateLobbyConfig } from '@requests/puzzle-time/lobbyConfigs';

import Form from '@pages/puzzle-time/GameConfigForm';

export default function Edit() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const indexPath = `/admin/applications/${query.applicationId}/puzzle_time/lobby_configs`;

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Lobby Config',
    actionName: 'update',
    redirectPath: indexPath,
    setData,
  });

  const onSubmit = (values) => updateLobbyConfig(values).then(redirectIfSuccessHandler);

  useEffect(() => {
    editLobbyConfig(query).then((response) => setData(response.data));
  }, []);

  return data ? (
    <Form
      data={data}
      onSubmit={onSubmit}
      actionName="Update"
      indexPath={indexPath}
      configName="Lobby Config"
    />
  )
    : '';
}
