import React from 'react';
import { get } from 'lodash';

import { useFormGroup, useFormContext } from '@hooks';

import DateTimeInput from './DateTimeInput';
import ServerError from './ServerError';

export default function DateTimeField({
  showTimeSelect = true,
  isClearable = false,
  dateFormat = ['MMMM d, yyyy - HH:mm', 'dd.MM.yyyy', 'dd/MM/yyyy', 'dd-MM-yyyy'],
  showTimeSelectOnly = false,
  showMonthYearPicker = false,
  name,
  className,
  disabled,
  onChange,
  testId,
}) {
  const { values, setFieldValue, applySharedInputProps } = useFormContext();
  const { generateName } = useFormGroup();

  const fullName = generateName(name);
  const value = get(values, fullName);

  return (
    <>
      <DateTimeInput
        dateFormat={dateFormat}
        name={fullName}
        className={className}
        onChange={(date) => {
          setFieldValue(fullName, date);
          if (onChange) { onChange(date); }
        }}
        showTimeSelect={showTimeSelect}
        value={value}
        isClearable={isClearable}
        showTimeSelectOnly={showTimeSelectOnly}
        showMonthYearPicker={showMonthYearPicker}
        testId={testId}
        {...applySharedInputProps({ disabled })}
      />
      <ServerError name={name} />
    </>
  );
}
