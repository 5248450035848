import React from 'react';

import { Form } from '@hooks';
import {
  Label, Field, FormButtonsGroup, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { MergeRoutes } from '@pages/routes';
import { useRouter } from '@tripledotstudios/react-core';
import EventItems from './Items';

export default function SchemasForm({ data, onSubmit }) {
  const { query } = useRouter();
  const { items } = data;

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
    >
      {() => (
        <>
          <PageHeader title="Edit Event Schema">
            <FormButtonsGroup
              cancelButtonPath={MergeRoutes.Events.Schemas.indexPath(query)}
            />
          </PageHeader>

          <Label text="Name"><Field type="text" name="name" /></Label>
          <Label text="Description"><Field type="text" name="description" /></Label>
          <Label text="Min version"><Field type="text" name="minVersion" /></Label>
          <Label text="Max version"><Field type="text" name="maxVersion" /></Label>

          <StaticFieldsFormGroup data={data} />

          {data.id && <EventItems values={items} />}
        </>
      )}
    </Form>
  );
}
