import React from 'react';
import { last } from 'lodash';

import VersionRow from '../VersionRow';
import Creation from './Creation';
import Destruction from './Destruction';
import Update from './Update';

const components = {
  create: Creation,
  update: Update,
  destroy: Destruction,
  duplicate: Creation,
};

const renderDiff = ({ showAssociation, versionTree }) => {
  const { version } = versionTree;
  const props = { showAssociation, versionTree };
  const Component = components[version.event];
  return <Component {...props} />;
};

export default function Index({
  applications, version, showAssociation, entity,
}) {
  return (
    <div>
      <VersionRow
        applications={applications}
        entity={entity}
        displayedTypeName={version.displayedTypeName || last(version.itemSubtype.split('::'))}
        version={version}
      >
        {({ versionTree }) => renderDiff({ showAssociation, versionTree })}
      </VersionRow>
    </div>
  );
}
