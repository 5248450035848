import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import editPath from '@services/editPath';
import IconButton from '@controls/buttons';
import { TIMESTAMP_COLUMNS } from '@pages/common';

export default function ColumnsFactory({ onDelete }) {
  return [
    { Header: 'Type', accessor: 'favorableType', Cell: ({ row }) => row.original.type },
    { Header: 'App User ID / IDFV', accessor: 'deviceIdfvOrItemId', disableSortBy: true },
    { Header: 'Name', accessor: 'name', disableSortBy: true },
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({
        row: {
          original: {
            id, applicationId, favorableType, deviceIdfvOrItemId, type, frontendIdentity,
          },
        },
      }) => {
        const editThePath = editPath({
          application: { id: applicationId },
          ...frontendIdentity,
          ...(favorableType === 'Accounts::TesterDevice' && { query: { column: 'idfv', value: deviceIdfvOrItemId } }),
        });

        return (
          <ButtonToolbar>
            <IconButton.Edit
              to={editThePath}
              readonly
              minimized
            >
              Go to Favourite
            </IconButton.Edit>
            <IconButton.Delete
              onClick={onDelete(id, type)}
              title="Delete from the Favoutites"
              minimized
            />
          </ButtonToolbar>
        );
      },
    },
  ];
}
