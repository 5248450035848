import React, { useMemo, useState } from 'react';
import { merge } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
  Filter,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import DuplicationModal from '@components/merge/shared/DuplicationModalWithWorldSchemaId';
import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';
import ColumnsFactory from './ColumnsFactory';

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(
    (params) => MergeRoutes.UnbubblingConfigurations.indexRequest(
      merge(params, { filter: { schemaTypeEq: 'Merge::WorldSchema' } }),
    ),
    { includeInUse: true },
  );
  const confirm = useConfirm();
  const { query } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Unbubbling Configuration',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Unbubbling Configuration '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => MergeRoutes.UnbubblingConfigurations.deleteRequest({ id, applicationId: query.applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({
    query, setDuplicatedRecord, onDelete,
  }), []);

  return response ? (
    <div>
      <PageHeader
        title="Unbubbling Configurations"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
      >
        <IconButton.New to={MergeRoutes.UnbubblingConfigurations.newPath({ applicationId: query.applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Unbubbling Configuration"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => (
          MergeRoutes.UnbubblingConfigurations.duplicateRequest({ ...values, applicationId: query.applicationId })
        )}
        title="Duplicate Unbubbling Configuration"
        applicationId={query.applicationId}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  ) : '';
}
