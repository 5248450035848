import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { partition, sortBy } from 'lodash';
import ReactSelect from 'react-select';
import { Spinner } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import { useQuery, useConfirm, useCurrentApplication } from '@hooks';
import { Label, SelectField, ServerError } from '@controls/form';
import { ReactTable, RulesList, PriorityColumn } from '@pages/common';
import { MyPetCafeRoutes } from '@pages/routes';

const columnsFactory = ({ routes, onOverrideRemove }) => [
  {
    Header: 'Name',
    Cell: ({ row }) => {
      const { id, name, applicationId } = row.original;

      return (
        <Link target="_blank" to={routes.editPath({ id, applicationId })}>
          {name}
        </Link>
      );
    },
  },
  {
    Header: 'Number of Events',
    Cell: ({ row }) => row.original.levelsCount,
  },
  {
    Header: 'Rules',
    Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
  },
  PriorityColumn,
  {
    Header: 'Actions',
    Cell: ({ row }) => {
      const { id, name } = row.original;

      return <Button variant="danger" size="sm" onClick={() => onOverrideRemove(id, name)}>Remove</Button>;
    },
  },
];

const buildBankSelectOption = (bank) => (
  { label: `${bank.name} [${bank.levelsCount} Event(s)]`, value: bank.id }
);

export default function EntityBanks({
  variantAttributes, disabled, onBankAdd, onBankRemove,
}) {
  const [selectedBankId, setSelectedBankId] = useState(null);
  const { enums } = APP_DATA;
  const { applicationId } = useCurrentApplication();

  const { response, isLoading } = useQuery({
    request: MyPetCafeRoutes.SoloMissions.EventBanks.indexRequest,
    additionalRequestParams: { withoutPagination: true },
    queryKey: ['soloMissionsBanks', applicationId, disabled],
    staleTime: 60_000,
  });
  const confirm = useConfirm();

  const bankStatuses = enums['MyPetCafe::Adventures::BankStatuses'];

  const onOverrideAdd = () => {
    onBankAdd(selectedBankId);
    return setSelectedBankId(null);
  };

  const onOverrideRemove = (bankId, name) => {
    confirm.showConfirmation({
      title: 'Are you sure?',
      body: `Solo Mission Events Bank '${name}' will be removed from the Config and won't be served to users.`,
    }).then(() => {
      onBankRemove(bankId);
    });
  };

  const columns = useMemo(() => columnsFactory({
    routes: MyPetCafeRoutes.SoloMissions.EventBanks,
    onOverrideRemove,
  }), []);

  if (isLoading) return <Spinner />;

  const notDestroyedBanks = variantAttributes.bankAffiliationsAttributes.filter(({ _destroy }) => !_destroy);
  const addedBankIds = notDestroyedBanks.map((bank) => bank.bankId);
  const [selectedBanks, notSelectedBanks] = partition(response.items, ({ id }) => addedBankIds.includes(id));

  const defaultBank = variantAttributes.defaultBankId
    ? response.items.find(({ id }) => id === variantAttributes.defaultBankId)
    : null;

  const bankOptions = notSelectedBanks
    .filter(({ id, status }) => (
      status === bankStatuses.ACTIVE && (defaultBank ? defaultBank.id !== id : true)
    ))
    .map((bank) => buildBankSelectOption(bank));

  return (
    <div className="mt-2 mb-2">
      <h3>Event Banks</h3>

      <Label text="Default Bank">
        <SelectField
          name="defaultBankId"
          options={defaultBank
            ? [buildBankSelectOption(defaultBank), ...bankOptions]
            : bankOptions}
          isDisabled={disabled}
        />
      </Label>
      {defaultBank && (
        <ReactTable
          data={[defaultBank]}
          columns={columns.slice(0, 3)}
        />
      )}
      {selectedBanks.length > 0 && (
        <Label text="Bank overrides" direction="vertical">
          <ReactTable
            data={sortBy(selectedBanks, ({ id }) => addedBankIds.indexOf(id))}
            columns={columns}
          />
        </Label>
      )}
      {defaultBank && (
        <Row>
          <Col xs={9}>
            <ReactSelect
              options={bankOptions}
              onChange={({ value }) => setSelectedBankId(value)}
              value={selectedBankId ? bankOptions.find(({ value }) => value === selectedBankId) : null}
              isDisabled={disabled}
            />
          </Col>
          <Col xs={3}>
            <Button
              className="w-100"
              onClick={onOverrideAdd}
              disabled={!selectedBankId || disabled}
            >
              Add Solo Mission Events Bank
            </Button>
          </Col>
        </Row>
      )}
      <ServerError name="bankAffiliationsAttributes" />
    </div>
  );
}
