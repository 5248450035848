import React, { useEffect, useState } from 'react';
import { Table, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { useRouter } from '@tripledotstudios/react-core';

import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FaIcon } from '@pages/common/FaButton';

import Cell from './Cell';

const TableContainer = styled.div`
  table {
    td {
      width: 14%;
      height: 6rem;
      border-color: #333;
    }
    tr:nth-child(3), tr:nth-child(8) {
      td:nth-child(n+3):nth-child(-n+5) {
        border-top: 4px solid green;
      }
    }
    tr:nth-child(n+3):nth-child(-n+7) {
      td:nth-child(3), td:nth-child(6) {
        border-left: 4px solid green;
      }
    }
  }
`;

export default function Cells({ values, schemaId, schemaRequest }) {
  const { query } = useRouter();
  const [items, setItems] = useState(null);

  useEffect(() => {
    schemaRequest({ id: schemaId, applicationId: query.applicationId }).then((response) => {
      setItems(response.data.items.map((item) => ({ value: item.id, label: item.internalId, line: item.line })));
    });
  }, [schemaId]);

  const cellRows = values.reduce((memo, cell, index) => {
    const chunk = Math.floor(index / 7);
    if (!memo[chunk]) memo.push([]);

    memo[chunk].push({ cell, index });
    return memo;
  }, []);

  return items && (
  <>
    <Alert variant="danger">
      <b>
        Note: Changes to this section can affect hardcoded tutorials.
        Make sure all changes are compatible and test before releasing.
      </b>
    </Alert>
    <TableContainer>
      <Table bordered>
        <tbody>
          {cellRows.map((row) => (
            <tr>
              {row.map(({ cell, index }) => (
                <Cell cell={cell} items={items} index={index} />
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
    <div className="mb-1 d-flex flex-column align-items-center">
      <div style={{ backgroundColor: '#d6d8db' }}>grey color for Locked cell</div>
      <div>
        <FaIcon icon={faLock} className="me-2" />
        for Locked item
      </div>
      <div className="fw-bold" style={{ color: 'green' }}>bold borders for the tutorial zone</div>
      <div>double click to edit the cell</div>
    </div>
  </>
  );
}
