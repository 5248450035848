import { useRouter } from '@tripledotstudios/react-core';

export default function useAvailabilityTypes() {
  const router = useRouter();
  const { query: { type } } = router;

  const isLive = type === 'live';
  const isTest = !isLive;

  return {
    type,
    isLive,
    isTest,
  };
}
