import { useCurrentApplication } from '@hooks';

const OPTIONS_PER_APPLICATION_TYPE = Object.freeze(
  {
    'Merge::Application': [
      { label: 'Producer Cooldown', value: 'Ads::RewardedVideos::Placements::Merge::ProducerCooldown' },
      { label: 'Chest Opening', value: 'Ads::RewardedVideos::Placements::Merge::ChestOpening' },
      { label: 'Bubbled Item', value: 'Ads::RewardedVideos::Placements::Merge::BubbledItem' },
      { label: 'Piggy Bank', value: 'Ads::RewardedVideos::Placements::Merge::PiggyBank' },
      { label: 'Energy', value: 'Ads::RewardedVideos::Placements::Merge::Energy' },
    ],
  },
);

export default function placementTypeOptions() {
  const { currentApplication: { type: applicationType } } = useCurrentApplication();
  return OPTIONS_PER_APPLICATION_TYPE[applicationType] || [];
}
