import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import IconButton from '@controls/buttons';

import MassOperations from './MassOperations';

const ReorderingButtons = ({ toggleReordering, onReorderingCancel }) => (
  <ButtonToolbar>
    <IconButton.Confirm onClick={toggleReordering} variant="success">
      Finish reordering
    </IconButton.Confirm>
    <IconButton.Cancel variant="danger" onClick={onReorderingCancel}>
      Cancel reordering
    </IconButton.Cancel>
  </ButtonToolbar>
);

export default function ListActions({
  reordering,
  massOperationsMode,
  readOnly,
  selectedItems,
  notDestroyedEntities,
  selectedRows,
  toggleReordering,
  onReorderingCancel,
  setSelectedRows,
  setBulkDuplicatedRecords,
  setMassOperationsMode,
  onBulkDelete,
  disabled,
  BulkCopyModal,
  isReorderable,
  withMassOperations = true,
  onBulkDuplicate,
}) {
  if (reordering) {
    return <ReorderingButtons toggleReordering={toggleReordering} onReorderingCancel={onReorderingCancel} />;
  }

  if (massOperationsMode) {
    return (
      <MassOperations
        notDestroyedEntities={notDestroyedEntities}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setBulkDuplicatedRecords={setBulkDuplicatedRecords}
        setMassOperationsMode={setMassOperationsMode}
        selectedItems={selectedItems}
        onBulkDelete={onBulkDelete}
        disabled={disabled}
        BulkCopyModal={BulkCopyModal}
        onBulkDuplicate={onBulkDuplicate}
      />
    );
  }

  return (
    <ButtonToolbar>
      {withMassOperations && (
        <IconButton.Menu onClick={() => setMassOperationsMode(true)}>
          Mass operations
        </IconButton.Menu>
      )}
      {isReorderable && (
        <IconButton.Sort onClick={toggleReordering} disabled={readOnly || disabled} variant="primary">
          Reorder
        </IconButton.Sort>
      )}
    </ButtonToolbar>
  );
}
