import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Spinner } from '@tripledotstudios/react-core';

import { PageHeader } from '@pages/common';
import { LorayRoutes } from '@pages/routes';

import { useCurrentApplication, useQuery } from '@hooks';
import IconButton from '@controls/buttons';

const JsonCode = styled.div`
  border: 1px solid black;
  padding: 10px;
  width: 100%;
  white-space: break-spaces;
`;

export default function Edit() {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { LevelLayouts: LevelLayoutRoutes, Banks: BankRoutes } = LorayRoutes;
  const { response, isLoading } = useQuery((data) => LevelLayoutRoutes.editRequest(data, { skipUuids: true }));

  if (isLoading) {
    return <Spinner />;
  }

  const {
    clientData, name, contentHash, position, bank, validForUse,
  } = response;

  const bankLink = BankRoutes.editPath({ id: bank.id, applicationId });

  return response && (
    <>
      <PageHeader title="View Level Layout">
        <IconButton.Cancel to={bankLink} />
      </PageHeader>

      <Table striped bordered hover>
        <tbody>
          <tr>
            <td>Bank</td>
            <td><Link to={bankLink}>{bank.name}</Link></td>
          </tr>
          <tr>
            <td>Name</td>
            <td>{name}</td>
          </tr>
          <tr>
            <td>Hash</td>
            <td>{contentHash}</td>
          </tr>
          <tr>
            <td>Valid for use</td>
            <td>{validForUse ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td>Position in the Bank</td>
            <td>{position}</td>
          </tr>
        </tbody>
      </Table>
      <JsonCode>{clientData}</JsonCode>
    </>
  );
}
