import React from 'react';

import { ItemsSelectField } from '@components/merge/shared/items/Select';
import EditableCell from './EditableCell';

export default function ItemsSelectCell({
  row, name, items, errors, isRequired = false, isEditable = true,
}) {
  const value = row[name];
  const error = errors[name];
  const text = (value === undefined || value === null) ? '' : items.find((option) => option.value === value).label;

  return (
    <EditableCell text={text} isEditable={isEditable} error={error}>
      {({ stopEditing, onKeyPress }) => (
        <ItemsSelectField
          autoFocus
          includeEmpty={!isRequired}
          name={name}
          items={items}
          onBlur={stopEditing}
          onKeyDown={onKeyPress}
        />
      )}
    </EditableCell>
  );
}
