import React from 'react';
import { PageTitle } from '@pages/common';

import ForceUpdatesIndex from '@pages/version-updates/force-updates/Index';
import ForceUpdatesNew from '@pages/version-updates/force-updates/New';
import ForceUpdatesEdit from '@pages/version-updates/force-updates/Edit';

import AppUpdatesIndex from '@pages/version-updates/app-updates/Index';
import AppUpdatesNew from '@pages/version-updates/app-updates/New';
import AppUpdatesEdit from '@pages/version-updates/app-updates/Edit';

import { VersionUpdateRoutes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function versionUpdates() {
  return [
    <Route
      key={VersionUpdateRoutes.ForceUpdates.indexRawPath}
      path={VersionUpdateRoutes.ForceUpdates.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Force Updates" />
          <ForceUpdatesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={VersionUpdateRoutes.ForceUpdates.newRawPath}
      path={VersionUpdateRoutes.ForceUpdates.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Force Update" action="New" />
          <ForceUpdatesNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={VersionUpdateRoutes.ForceUpdates.editRawPath}
      path={VersionUpdateRoutes.ForceUpdates.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Force Update" action="Edit" />
          <ForceUpdatesEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={VersionUpdateRoutes.AppUpdates.indexRawPath}
      path={VersionUpdateRoutes.AppUpdates.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="App Updates" />
          <AppUpdatesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={VersionUpdateRoutes.AppUpdates.newRawPath}
      path={VersionUpdateRoutes.AppUpdates.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="App Update" action="New" />
          <AppUpdatesNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={VersionUpdateRoutes.AppUpdates.editRawPath}
      path={VersionUpdateRoutes.AppUpdates.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="App Update" action="Edit" />
          <AppUpdatesEdit />
        </RouteContainer>
      )}
    />,
  ];
}
