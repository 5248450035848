import sudokuReducer from './sudokuReducer';
import tileReducer from './tileReducer';
import solitaireReducer from './solitaireReducer';
import woodokuReducer from './woodokuReducer';

export default {
  sudoku: sudokuReducer,
  tile: tileReducer,
  solitaire: solitaireReducer,
  woodoku: woodokuReducer,
};
