import React from 'react';
import { Card } from 'react-bootstrap';

import GeneralAdminSettingsForm from './Form';
import ApplicationsAdminSettingsTabs from './applications/Tabs';

export default function TabContent() {
  return (
    <>
      <Card className="mb-3">
        <Card.Body className="pb-0">
          <GeneralAdminSettingsForm />
        </Card.Body>
      </Card>

      <ApplicationsAdminSettingsTabs />
    </>
  );
}
