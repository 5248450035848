import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  TIMESTAMP_COLUMNS,
} from '@pages/common';
import IconButton from '@controls/buttons';

export default function ColumnsFactory({ setEditedRecord, onDelete }) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Internal Id', accessor: 'internalId' },
    {
      Header: 'Asset Key',
      accessor: 'assetKeyId',
      Cell: ({ row: { original: { assetKey } } }) => `${assetKey.uid} (${assetKey.statusHumanize})`,
    },
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({ row: { original } }) => (
        <ButtonToolbar>
          <IconButton.Edit onClick={() => setEditedRecord(original.id)} minimized />
          <IconButton.Delete onClick={onDelete(original)} minimized />
        </ButtonToolbar>
      ),
    },
  ];
}
