import React, { useEffect } from 'react';
import Sidebar from '@controls/navigation/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FlashMessages, useFlashMessages } from '@tripledotstudios/react-core';
import FlashAnnouncements from './FlashAnnouncements';

const ErrorComponent = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '30px',
      fontWeight: 500,
      paddingBottom: '15%',
    }}
  >
    <FontAwesomeIcon icon={faTriangleExclamation} size="xl" className="text-danger" style={{ marginRight: '0.1em' }} />
    Something went wrong
  </div>
);

export default function ErrorPage() {
  const { error } = useFlashMessages();

  useEffect(() => {
    error('An error has occurred');
  }, []);

  return (
    <div className="d-flex">
      <FlashMessages />
      <FlashAnnouncements />
      <Sidebar />
      <ErrorComponent />
    </div>
  );
}
