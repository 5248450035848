import React from 'react';
import { Alert } from '@tripledotstudios/react-core';

const TitledAlert = ({
  name, alertText, customAlertName = null,
}) => (
  alertText && (
    <Alert variant="info">
      <span>
        <b>
          {customAlertName || name}
        </b>
        {` ${alertText}`}
      </span>
    </Alert>
  )
);

export default TitledAlert;
