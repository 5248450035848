import { values } from 'lodash';

import APP_DATA from '@services/appData';

export function filterEnums(data, options = {}) {
  return data.filter(({ hidden }) => options.withoutFiltering || !hidden);
}

export function fromClassNameToOptions(enumKey, options = {}) {
  const { rawEnums: enums } = APP_DATA;
  const transformLabel = options.transformLabel || ((v) => v);

  const enumValues = values(enums[enumKey]);

  return filterEnums(enumValues, options).map(
    ({ translation, value }) => ({ label: transformLabel(translation), value }),
  );
}
export function fromClassNamesToOptions(enumKeys, options = {}) {
  return enumKeys.map((enumKey) => (fromClassNameToOptions(enumKey, options)));
}

export function localizedEnumValueString(enumKey, value, options = {}) {
  if (value === null || value === undefined) { return null; }
  const { rawEnums: enums } = APP_DATA;

  const enumValues = values(enums[enumKey]);
  const localizedEnum = filterEnums(enumValues, options).find((enumValue) => enumValue.value === value);

  return localizedEnum?.translation;
}
