import { memoize, merge } from 'lodash';
import {
  fieldBuilder,
  fieldArrayBuilder,
  reorderableFieldBuilder,
  selectBuilder,
  timePeriodFieldBuilder,
} from '@services/fields-factory';
import { LocalizationsRoutes } from '@pages/routes';
import { appEventsFields } from '@components/game-settings/Resource';
import wrapArray from '@services/wrapArray';
import { fromClassNameToOptions } from '@services/enums';
import selectOptionsWithQuickLink from '@root/services/selectOptionsWithQuickLink';

export const defaultPopupsOptions = [
  { label: 'IDFA', value: 'IDFA' },
  { label: 'Languages', value: 'Language_Menu' },
  { label: 'GDPR', value: 'GDPR' },
  { label: 'Push Notifications', value: 'Allow_Notifications' },
  { label: 'Rate Us', value: 'Rate_Us' },
  { label: 'Google CMP', value: 'Google_CMP' },
  { label: 'Push notifications retry', value: 'PushNotifications_Retry_OptIn' },
];

const staticFields = memoize(({
  options: {
    popupsOptions,
    popupsTriggersOptions,
  },
  localizationConfigOptions,
  adsMediationPlatformOptions,
}) => (
  {
    ...fieldBuilder({ name: 'adsBannerEnabled', type: 'checkbox' }),
    ...fieldBuilder({ name: 'adsInterstitialCooldownAfterInstall', type: 'number' }),
    ...fieldBuilder({ name: 'appsflyerClientAdRevenueTimeframe', type: 'number' }),
    ...fieldBuilder({ name: 'appsflyerClientAdRevenueThreshold', type: 'number' }),
    ...fieldBuilder({ name: 'adsMaxIosBannerId', type: 'text' }),
    ...fieldBuilder({ name: 'adsMaxIosInterstitialId', type: 'text' }),
    ...fieldBuilder({ name: 'adsMaxIosExpressInterstitialId', type: 'text' }),
    ...fieldBuilder({ name: 'adsMaxIosRewardedVideoId', type: 'text' }),
    ...fieldBuilder({ name: 'adsMaxAndroidBannerId', type: 'text' }),
    ...fieldBuilder({ name: 'adsMaxAndroidInterstitialId', type: 'text' }),
    ...fieldBuilder({ name: 'adsMaxAndroidExpressInterstitialId', type: 'text' }),
    ...fieldBuilder({ name: 'adsMaxAndroidRewardedVideoId', type: 'text' }),
    ...fieldBuilder({ name: 'adsAmazonIosSdkKey', type: 'text' }),
    ...fieldBuilder({ name: 'adsAmazonIosInterstitialId', type: 'text' }),
    ...fieldBuilder({ name: 'adsAmazonIosBannerId', type: 'text' }),
    ...fieldBuilder({ name: 'adsAmazonIosLeaderboardId', type: 'text' }),
    ...fieldBuilder({ name: 'adsAmazonAndroidSdkKey', type: 'text' }),
    ...fieldBuilder({ name: 'adsAmazonAndroidInterstitialId', type: 'text' }),
    ...fieldBuilder({ name: 'adsAmazonAndroidBannerId', type: 'text' }),
    ...fieldBuilder({ name: 'adsAmazonAndroidLeaderboardId', type: 'text' }),
    ...fieldBuilder({ name: 'iosIdfaRequestEnabled', type: 'checkbox' }),
    ...fieldBuilder({ name: 'iosIdfaExplainerPopupEnabled', type: 'checkbox' }),
    ...fieldBuilder({ name: 'iosIdfaFakeNativePopupEnabled', type: 'checkbox' }),
    ...fieldBuilder({ name: 'iosIdfaNativePopupEnabled', type: 'checkbox' }),
    ...fieldBuilder({ name: 'iosIdfaExplainerTitleTranslationKey', type: 'text' }),
    ...fieldBuilder({ name: 'iosIdfaExplainerBodyTranslationKey', type: 'text' }),
    ...fieldBuilder({ name: 'iosIdfaExplainerButtonTranslationKey', type: 'text' }),
    ...fieldBuilder({ name: 'iosIdfaExplainerPopupAcceptsGdpr', type: 'checkbox' }),
    ...fieldBuilder({ name: 'rewardedAdFeaturesFromLevel', type: 'number' }),
    ...fieldBuilder({ name: 'missionsEnabled', type: 'checkbox' }),
    ...fieldBuilder({ name: 'shopConfigFromLiveops', type: 'checkbox' }),
    ...fieldBuilder({ name: 'shopViewRevamp', type: 'checkbox' }),
    ...fieldBuilder({ name: 'shouldShowPushPreemptivePopup', type: 'checkbox' }),
    ...fieldBuilder({ name: 'autoloadingAssetsDelay', type: 'number' }),
    ...timePeriodFieldBuilder({ name: 'analyticsBatchingDelay', inputFields: ['seconds'] }),
    ...timePeriodFieldBuilder({ name: 'analyticsBatchingStartupDuration', inputFields: ['seconds'] }),
    ...timePeriodFieldBuilder({ name: 'analyticsBatchingDelayStartup', inputFields: ['seconds'] }),
    ...reorderableFieldBuilder({ name: 'popupsOrder', options: popupsOptions || defaultPopupsOptions }),
    ...fieldArrayBuilder(
      {
        name: 'popupsTriggers',
        tooltipText: '',
        isHorizontal: true,
        fieldsConfiguration: [
          {
            label: 'Type',
            name: 'type',
            as: 'select',
            componentData: popupsTriggersOptions || popupsOptions || defaultPopupsOptions,
          },
          { label: 'App Starts', name: 'appStarts', as: 'array' },
          { label: 'Game Starts', name: 'gameStarts', as: 'array' },
        ],
      },
    ),
    ...selectBuilder({ name: 'localizationsConfigId', ...localizationConfigOptions }),
    ...fieldBuilder({
      name: 'bakedInConfigsJson',
      fieldOptions: {
        abTestable: false,
        component: 'textarea',
        htmlFieldProps: {
          rows: 8,
        },
      },
    }),
    ...selectBuilder({ name: 'adsMediation', options: adsMediationPlatformOptions }),
  }
), (props) => props.applicationId);

const sharedFactory = ({ applicationId }, options) => {
  const adsMediationPlatformOptions = fromClassNameToOptions('GameSettingsAdsMediationPlatforms');

  const localizationConfigOptions = selectOptionsWithQuickLink(LocalizationsRoutes.Configs, applicationId);

  return ({
    ...staticFields({
      applicationId, options, localizationConfigOptions, adsMediationPlatformOptions,
    }),
    ...appEventsFields(applicationId),
  });
};

export default (specificFactories, specificFactoryOptions = {}) => (props) => {
  const fields = wrapArray(specificFactories).map((factory) => factory(props)).reduce(merge);
  return ({ ...sharedFactory(props, specificFactoryOptions), ...fields });
};
