import React, { useRef, useEffect } from 'react';
import { isEqual } from 'lodash';

import { useFormContext } from '@hooks';
import { Label } from '@controls/form';
import { persistedCollection } from '@services/utils';

const GlobalAttributes = React.memo(({ levelsAttributes, levelsPath = null, readOnly }) => {
  const { dispatch, sharedInputProps } = useFormContext();
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) { return; }
    const heterogenous = levelsAttributes.every(({ tutorialEnabled }, index, levelsAttributesArray) => (
      index === 0 || tutorialEnabled === levelsAttributesArray[index - 1].tutorialEnabled
    ));
    ref.current.indeterminate = !heterogenous;
  }, [ref.current, levelsAttributes]);

  const onChange = (attributeName, value) => dispatch({
    actionType: 'globalCheckboxAttributeChange',
    attributeName,
    levelsPath,
    value,
  });

  return (
    <Label
      text="Apply In-game mechanic tutorial: on/off to level bank"
      labelSize={5}
      fieldSize={7}
    >
      <input
        type="checkbox"
        ref={ref}
        id="globalInGameMechanicTutorial"
        checked={persistedCollection(levelsAttributes).every(({ tutorialEnabled }) => tutorialEnabled)}
        onChange={({ target: { checked } }) => onChange('tutorialEnabled', checked)}
        disabled={readOnly || sharedInputProps.disabled}
      />
    </Label>
  );
}, (prev, next) => isEqual(prev.levelsAttributes, next.levelsAttributes));

export default GlobalAttributes;
