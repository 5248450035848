import React from 'react';

import { MyPetCafeRoutes } from '@pages/routes';
import buildOnSubmit from '@services/journeys/banks/buildOnSubmit';
import APP_DATA from '@services/appData';
import {
  Form,
  useCurrentApplication,
  AbTestingContext,
  useConfirm,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import {
  Label,
  Field,
  SelectField,
  FormButtonsGroup,
  PriorityField,
  AvailabilityStateFormGroup,
  StaticFieldsFormGroup,
  LabelsFormGroup,
  DateTimeField,
  localizedLabelFactory,
} from '@controls/form';
import { DuplicateButton, PageHeader } from '@pages/common';
import RulesForm from '@pages/rules/Form';
import { combineReducers, rulesReducer } from '@reducers';
import IconButton from '@controls/buttons';

import reducer from './reducer';
import VariantForm from './VariantForm';

const statusesEnum = APP_DATA.enums['MyPetCafe::WinStreaks::ConfigStatuses'];
const LocalizedLabel = localizedLabelFactory('myPetCafe.winStreaks.configs.fields');

export default function WinStreaksConfigsForm({ data, title, onSubmit }) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const statuses = fromClassNameToOptions('MyPetCafe::WinStreaks::ConfigStatuses');
  const confirm = useConfirm();
  const { status } = data;

  const isArchived = status === statusesEnum.ARCHIVED;
  const isUpdate = Boolean(data.id);

  const onFormSubmit = buildOnSubmit({
    applicationId,
    data,
    entityName: 'Win Streak Config',
    statusesEnum,
    onSubmitFunction: onSubmit,
    confirm,
    routes: MyPetCafeRoutes.WinStreaks.Configs,
  });

  return (
    <AbTestingContext
      entityType="MyPetCafe::WinStreaks::Config"
      entityId={data.id}
    >
      <Form
        initialValues={data}
        onSubmit={onFormSubmit}
        reducer={combineReducers([rulesReducer, reducer])}
        sharedInputProps={{ disabled: isArchived }}
      >
        {({ values }) => (
          <>
            <PageHeader title={title}>
              <FormButtonsGroup
                cancelButtonPath={MyPetCafeRoutes.WinStreaks.Configs.indexPath({ applicationId })}
              >
                {isUpdate && (
                  <>
                    <IconButton.ActivityLog
                      applicationId={applicationId}
                      id={data.id}
                      entityType="MyPetCafe::WinStreaks::Config"
                    />
                    <FormButtonsGroup.Divider />
                  </>
                )}
                <DuplicateButton
                  routes={MyPetCafeRoutes.WinStreaks.Configs}
                  data={data}
                  entityName="Win Streak Config"
                  modalType="withPriority"
                />
              </FormButtonsGroup>
            </PageHeader>

            {isUpdate && (
              <div className="mt-2">
                <Label text="ID">
                  <Field name="id" disabled />
                </Label>
                <Label text="Variant ID">
                  <Field name="currentVariantAttributes.id" disabled />
                </Label>
              </div>
            )}

            <Label text="Name">
              <Field type="text" name="name" disabled={false} />
            </Label>
            <LabelsFormGroup disabled={false} />
            <Label text="Status">
              <SelectField name="status" options={statuses} />
            </Label>
            <AvailabilityStateFormGroup />
            <Label text="Priority">
              <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} />
            </Label>

            <LocalizedLabel name="startsForClientAt">
              <DateTimeField as="date" name="startsForClientAt" />
            </LocalizedLabel>
            <LocalizedLabel name="startsForPlayerAt">
              <DateTimeField as="date" name="startsForPlayerAt" />
            </LocalizedLabel>
            <LocalizedLabel name="endsAt">
              <DateTimeField as="date" name="endsAt" />
            </LocalizedLabel>

            <StaticFieldsFormGroup data={data} />

            <VariantForm defaultVariant values={values.currentVariantAttributes} isArchived={isArchived} />
            <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} disabled={isArchived} />
          </>
        )}
      </Form>
    </AbTestingContext>
  );
}
