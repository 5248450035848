import APP_DATA from '@services/appData';

import String from './String';
import Number from './Number';
import Boolean from './Boolean';

const populateOptions = (enumName) => (
  Object.entries(APP_DATA.enums[enumName]).map(([key, value]) => (
    { label: key, value }
  ))
);

const TYPE_REGISTRY = {
  'MyPetCafe::UnlockRequirements::Conditions::String': {
    label: 'String',
    component: String,
    options: populateOptions('MyPetCafe::UnlockRequirements::Conditions::Strings'),
  },
  'MyPetCafe::UnlockRequirements::Conditions::Number': {
    label: 'Number',
    component: Number,
    options: populateOptions('MyPetCafe::UnlockRequirements::Conditions::Numbers'),
  },
  'MyPetCafe::UnlockRequirements::Conditions::Boolean': {
    label: 'Boolean',
    component: Boolean,
    options: populateOptions('MyPetCafe::UnlockRequirements::Conditions::Booleans'),

  },
};

const typeOptions = Object.entries(TYPE_REGISTRY).map(([type, { label }]) => ({ label, value: type }));
const keyOptionsFor = (type) => TYPE_REGISTRY[type].options;

const fieldsFor = (type) => {
  const registryEntry = TYPE_REGISTRY[type];
  if (!registryEntry) throw new Error('Not supported unlock requirements condition');

  return registryEntry.component;
};

export {
  typeOptions,
  keyOptionsFor,
  fieldsFor,
};
