import React, { useState, useMemo } from 'react';
import {
  Row,
  Col,
  Button,
  ListGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import { difference, partition, remove } from 'lodash';

import Select from '@controls/Select';
import { useFormContext } from '@hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  SelectField,
  ReorderableList,
  ServerError,
  CheckboxField,
} from '@controls/form';

const LanguagesSelect = styled(Select)`
  width: 250px;
`;

const LanguagesDiffList = ({
  list,
  languageNames,
  actions: Actions,
  keyPrefix,
  itemVariant,
}) => (
  <ListGroup as="ul">
    {list.map((lang) => (
      <li className="d-flex align-items-center" key={`${keyPrefix}-${lang}`}>
        <ListGroup.Item variant={itemVariant} as="div" className="w-25">
          {languageNames[lang]}
        </ListGroup.Item>
        <Actions lang={lang} />
      </li>
    ))}
  </ListGroup>
);

const OrderLanguageDiffAction = ({ lang }) => {
  const { dispatch } = useFormContext();

  const addToPopup = (position) => {
    dispatch({ actionType: 'add_language_to_popup', lang, position });
  };

  return (
    <>
      <Button className="ms-3" onClick={() => addToPopup('top')}>
        Add to the top
      </Button>
      <Button className="ms-1" onClick={() => addToPopup('bottom')}>
        Add to the bottom
      </Button>
    </>
  );
};

const RestoreLanguageDiffAction = ({ lang }) => {
  const { dispatch } = useFormContext();

  const restoreLanguage = () => {
    dispatch({ actionType: 'add_supported_language', lang });
  };

  return (
    <Button className="ms-3" onClick={restoreLanguage}>
      Restore
    </Button>
  );
};

export default function Languages({ data, onReorder }) {
  const { dispatch } = useFormContext();
  const [supportedLanguage, setSupportedLanguage] = useState();

  const {
    languages, supportedLanguages, languagesInPopup,
  } = data;

  const languageNames = {
    system: 'System',
    current: 'Current',
    ...languages.reduce((result, { code, name }) => ({ ...result, [code]: name }), {}),
  };

  const languageOptions = [
    { label: 'System', value: 'system' },
    { label: 'Current', value: 'current' },
    ...languages.map(({ code, name }) => ({ label: name, value: code })),
  ];

  const [
    supportedLanguagesOptions, potentiallySupportedLanguagesOptions,
  ] = partition(languageOptions, ({ value }) => supportedLanguages.includes(value));
  remove(supportedLanguagesOptions, (l) => l.value === 'current');
  const languagesInPopupOptions = languagesInPopup.map((lang) => ({ label: languageNames[lang], value: lang }));

  const initiallySupportedLanguages = useMemo(() => [...supportedLanguages], []);
  const languagesToOrganize = difference(supportedLanguages, languagesInPopup);
  const removedLanguages = difference(initiallySupportedLanguages, supportedLanguages);

  const selectSupportedLanguage = ({ value }) => {
    setSupportedLanguage(value);
  };

  const addSupportedLanguage = () => {
    if (!supportedLanguage) return;

    dispatch({ actionType: 'add_supported_language', lang: supportedLanguage });
    setSupportedLanguage(null);
  };

  const removeSupportedLanguage = (lang) => {
    dispatch({ actionType: 'remove_supported_language', lang });
  };

  const reorderLanguagesInPopup = (langs) => {
    dispatch({ actionType: 'reorder_languages', langs });
    onReorder(langs);
  };

  return (
    <>
      <Row className="mt-5">
        <Col>
          <h5>Languages supported by the client</h5>

          <div className="text-secondary">
            <p className="mb-0">you can refer to</p>
            <p className="mb-0">&quot;System&quot; language as the language set on the device</p>
            <p>&quot;Current&quot; language as the current language of the application</p>
          </div>

          <ol className="list-group ms-3 mt-3">
            {supportedLanguages.map((lang) => (
              <li key={lang} className="ms-3 my-1">
                <div className="d-flex align-items-center">
                  <span>{languageNames[lang]}</span>
                  <Button variant="default" onClick={() => removeSupportedLanguage(lang)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </div>
              </li>
            ))}
          </ol>

          <div className="d-flex align-items-center mt-2 mb-5">
            <LanguagesSelect
              selectedValue={supportedLanguage}
              onChange={selectSupportedLanguage}
              options={potentiallySupportedLanguagesOptions}
              placeholder="Select language"
              className="d-inline-block"
            />

            <Button className="ms-2" onClick={addSupportedLanguage}>Add</Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex align-items-center mt-5">
            <span className="h5">Client default language</span>

            <div className="w-25 ms-5">
              <SelectField
                name="defaultLanguage"
                options={supportedLanguagesOptions}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <div className="h5 mt-5">Languages pop-up order</div>

          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="d-flex align-items-center" htmlFor="showLanguagesPopup">
            <CheckboxField name="showLanguagesPopup" id="showLanguagesPopup" />
            <span className="ms-2">Show &quot;Select language&quot; pop up with game launch</span>
          </label>

          <div className="mt-5 w-25">
            <div>Current State</div>

            <ReorderableList
              name="languages_in_popup"
              options={languagesInPopupOptions}
              onChange={reorderLanguagesInPopup}
              droppableId="languages_in_popup"
              variant="success"
            />
          </div>

          <div className="mt-5">
            <div>Languages to organize</div>

            <LanguagesDiffList
              list={languagesToOrganize}
              languageNames={languageNames}
              keyPrefix="organized"
              itemVariant="warning"
              actions={OrderLanguageDiffAction}
            />

            <ServerError name="languagesToOrganize" full />
          </div>

          <div className="mt-5">
            <div>Removed</div>

            <LanguagesDiffList
              list={removedLanguages}
              languageNames={languageNames}
              keyPrefix="removed"
              itemVariant="dark"
              actions={RestoreLanguageDiffAction}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
