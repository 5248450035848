import { generateChapterBasedRoutes } from '@pages/routes';
import { camelCase, upperFirst } from 'lodash';
import React, { useState } from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InLiveBadge,
  InLiveColumnHeader,
  InUseColumn,
  Filter,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { useCurrentApplication, useQuery, useConfirm } from '@hooks';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

import EditModal from './EditModal';
import NewModal from './NewModal';

export const uiAssetsColumns = ({ entityName, onDelete, onEdit }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: `${entityName} key`, accessor: 'key' },
  { Header: `${entityName} name`, accessor: 'name' },
  InUseColumn,
  {
    id: 'availability_header',
    Header: () => <InLiveColumnHeader />,
    Cell: ({ row }) => <InLiveBadge inLive={row.original.availabilityStateLive} />,
  },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, inUse } } }) => {
      const disabled = isInUse(inUse);

      return (
        <ButtonToolbar>
          <IconButton.Edit
            onClick={() => onEdit(id)}
            disabled={disabled}
            title={disabled ? 'Disabled as the entity is used' : null}
            minimized
          />

          <IconButton.Delete
            onClick={onDelete(id, name)}
            disabled={disabled}
            title={disabled ? 'Disabled as the entity is used' : null}
            minimized
          />
        </ButtonToolbar>
      );
    },
  },
];

export default function Index({ entityNameOverride = null, recordType }) {
  const entityName = entityNameOverride || 'UI asset';
  const { applicationKey: gameType, currentApplication } = useCurrentApplication();
  const Routes = generateChapterBasedRoutes(gameType)[upperFirst(camelCase(recordType))];
  const { response, refetch } = useQuery(Routes.indexRequest);
  const [editedRecord, setEditedRecord] = useState(null);
  const [newRecord, setNewRecord] = useState(false);
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName,
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `${entityName} '${name}' will be deleted and not available for work. Continue?`,
    }).then(() => Routes.deleteRequest({ id, gameType, applicationId: currentApplication.id })
      .then(responseHandler));
  };

  const columns = React.useMemo(() => uiAssetsColumns({ entityName, onDelete, onEdit: setEditedRecord }), []);

  return response ? (
    <div>
      <PageHeader title={entityName} filter={<Filter defaultComponents={['availabilityStateIn']} />}>
        <IconButton.New onClick={() => setNewRecord(true)} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <NewModal
        entityName={entityName}
        onHide={() => setNewRecord(false)}
        record={newRecord}
        recordType={recordType}
        refetch={refetch}
      />
      <EditModal
        entityName={entityName}
        onHide={() => setEditedRecord(null)}
        record={editedRecord}
        recordType={recordType}
        refetch={refetch}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  ) : '';
}
