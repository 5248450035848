import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { faGear } from '@fortawesome/free-solid-svg-icons';

import { useCurrentApplication, useQuery } from '@hooks';
import { ApplicationRoutes, HomeRoutes } from '@pages/routes';

import { PageHeader } from '@pages/common';
import IconButton from '@controls/buttons';

import Shortcuts from './home/Shortcuts';
import LatestChanges from './home/LatestChanges';
import ExpiredExperiments from './home/ExpiredExperiments';
import DeletedPlayerSegments from './home/DeletedPlayerSegments';

export default function Home() {
  const { response } = useQuery(HomeRoutes.indexRequest);

  const { currentApplication } = useCurrentApplication();
  const title = currentApplication ? `${currentApplication.configuredName} Home` : 'Home';

  return response && (
    <>
      <PageHeader title={title}>
        <IconButton
          icon={faGear}
          to={ApplicationRoutes.editPath({ id: currentApplication.id })}
          variant="secondary"
          outline
        >
          {`Configure ${currentApplication.configuredName}`}
        </IconButton>
      </PageHeader>

      <Row>
        <Col lg={12} xl={6} className="mb-3 pe-xl-2">
          <Shortcuts application={currentApplication} />
          <LatestChanges application={currentApplication} />
        </Col>

        <Col lg={12} xl={6} className="ps-xl-2">
          <ExpiredExperiments application={currentApplication} />
          <DeletedPlayerSegments application={currentApplication} />
        </Col>
      </Row>
    </>
  );
}
