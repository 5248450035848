import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { SelectField, Field } from '@controls/form';

const eventOptions = [
  { label: 'Game Complete', value: 0 },
  { label: 'Stack Complete', value: 1 },
  { label: 'Stock Pile Complete', value: 2 },
];

export default function HintField({ name, disabled, trigger }) {
  return (
    <Container className="pb-3">
      {trigger && (
        <Row>
          <Col xs={8} className="ps-0">Trigger timing (seconds)</Col>
          <Col className="pe-0">
            <Field
              type="number"
              name={`${name}.threshold`}
              disabled={disabled}
            />
          </Col>
        </Row>
      )}
      <Row className="mb-3 mt-1">
        <Col xs={8} className="ps-0">Stop animation when</Col>
      </Row>
      <Row>
        <Col xs={8} className="ps-0">
          <div className="fw-bold mb-1">Event</div>
          <SelectField
            name={`${name}.event`}
            options={eventOptions}
            isDisabled={disabled}
          />
        </Col>
        <Col className="pe-0">
          <div className="fw-bold mb-1">Quantity</div>
          <Field
            type="number"
            name={`${name}.quantity`}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Container>
  );
}
