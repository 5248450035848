import { IconTooltip } from '@tripledotstudios/react-core';

import QuickLinkTooltip from './QuickLinkTooltip';
import GuideTooltip from './GuideTooltip';
import ContactUsTooltip from './ContactUsTooltip';

IconTooltip.QuickLink = QuickLinkTooltip;
IconTooltip.Guide = GuideTooltip;
IconTooltip.ContactUs = ContactUsTooltip;

export default IconTooltip;
