import React, { useState, useEffect } from 'react';

import { useRouter } from '@tripledotstudios/react-core';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes } from '@pages/routes';
import Form from './Form';

export default function Edit() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Bundle Catalogs',
    actionName: 'update',
    redirectPath: MergeRoutes.BundleCatalogs.indexPath({ applicationId: query.applicationId }),
    setData,
  });

  const onSubmit = (values) => (
    MergeRoutes.BundleCatalogs
      .updateRequest({ ...values, applicationId: query.applicationId })
      .then(responseHandler)
  );

  useEffect(() => {
    MergeRoutes.BundleCatalogs.editRequest(query).then((response) => setData(response.data));
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} actionName="Update" />;
}
