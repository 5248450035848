import React from 'react';

import { Label, DateTimeField } from '@controls/form';

export default function StartEndFields({ isBase, fieldSize = 6 }) {
  return !isBase && (
    <>
      <Label text="Start At" fieldSize={fieldSize}>
        <DateTimeField as="date" name="startAt" isClearable />
      </Label>
      <Label text="End At" fieldSize={fieldSize}>
        <DateTimeField as="date" name="endAt" isClearable />
      </Label>
    </>
  );
}
