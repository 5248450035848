import React from 'react';
import { Col } from 'react-bootstrap';

import { fromClassNameToOptions } from '@services/enums';
import { Filter, TextFilter, SelectFilter } from '@pages/common';

export default function AppUpdatesFilter() {
  const typeOptions = fromClassNameToOptions('VersionUpdates::AppUpdateTypesEnum');

  return (
    <Filter defaultComponents={['availabilityStateIn']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="typeIn"
              label="Type"
              updateFilterField={updateFilterField}
              options={typeOptions}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
