import React from 'react';
import { Link } from 'react-router-dom';

import editPath from '@services/editPath';
import LabelRow from './LabelRow';

export default function BaseEntityRow({ application, baseEntity, fieldName }) {
  return baseEntity && (
    <>
      <LabelRow text="Base Config" />
      {baseEntity && (
        <tr>
          <td colSpan="5">
            <Link
              key={baseEntity.name}
              to={editPath({ ...baseEntity.frontendIdentity, application, query: { focus: fieldName } })}
              target="_blank"
            >
              {baseEntity.name}
            </Link>
          </td>
        </tr>
      )}
    </>
  );
}
