import React from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useI18n } from '@hooks';
import { MyPetCafeRoutes } from '@pages/routes';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function Edit() {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(MyPetCafeRoutes.Adventures.Entities.editRequest);
  const { translate } = useI18n();

  const entityName = translate.fallback('adventures.entities.name');

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName,
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => MyPetCafeRoutes.Adventures.Entities
    .updateRequest({ ...values, applicationId })
    .then(responseHandler);

  if (isLoading) return <Spinner />;

  return (
    <Form
      actionName="Update"
      data={response}
      onSubmit={onSubmit}
      title={`Edit ${entityName}`}
      entityName={entityName}
    />
  );
}
