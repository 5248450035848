import React from 'react';
import { get } from 'lodash';

import APP_DATA from '@services/appData';

import {
  useFormContext, FormGroup, useCurrentApplication, useQuery,
} from '@hooks';
import {
  Label, Field, SelectField, AssetsKeySelectField, DateTimeField,
} from '@controls/form';
import { PageSection } from '@pages/common';
import { LocalizationsRoutes } from '@pages/routes';

import LevelBanks from '@pages/journeys/level-banks/EventsBlock';
import SharedTranslationKeys from '@pages/journeys/shared/SharedTranslationKeys';

export const ConfigurationVariant = ({
  values, defaultVariant = false, variantIndex, isArchived,
}) => {
  const { dispatch } = useFormContext();
  const { applicationId } = useCurrentApplication();
  const { enums, modelsLocales: { solitaire: { journeys: { tooltips } } } } = APP_DATA;

  const onBankAdd = (id) => dispatch({ actionType: 'addLevelBankToJourney', id, variantIndex });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeLevelBankFromJourney', id, variantIndex,
  });

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  const assetTypesEnum = enums['Solitaire::AssetTypes'];

  const { response: localizationKeys } = useQuery({
    request: LocalizationsRoutes.Keys.indexRequest,
    queryKey: ['localizationKeys', applicationId],
    toSelectOptions: true,
  });

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Default Title">
        <Field name="defaultTitle" />
      </Label>
      <Label text="Title localisation key">
        <SelectField name="titleTranslationKeyId" options={localizationKeys} />
      </Label>
      <Label text="Asset: Main Bundle">
        <AssetsKeySelectField
          name="assetKeyId"
          types={assetTypesEnum.JOURNEY_MAIN}
        />
      </Label>
      <Label text="Asset: Rewards Bundle">
        <AssetsKeySelectField
          name="assetRewardsKeyId"
          types={assetTypesEnum.JOURNEY_REWARDS}
        />
      </Label>
      <Label text="Game Screen Theme" tooltipText={tooltips.gameScreenTheme}>
        <AssetsKeySelectField
          includeEmpty
          name="journeyGameScreenThemeId"
          types={assetTypesEnum.JOURNEY_CUSTOMISATION}
        />
      </Label>

      <PageSection title="Configuration">
        <Label text="Not Long Left Popup Appears From" tooltipText={tooltips.notLongLeftTime}>
          <DateTimeField as="date" name="notLongLeftTime" />
        </Label>

        <Label text="Show Timer On UI" tooltipText={tooltips.config.showTimer}>
          <Field type="checkbox" name="showTimer" />
        </Label>

        <SharedTranslationKeys localizationKeys={localizationKeys} />
      </PageSection>

      <LevelBanks
        disabled={isArchived}
        variantAttributes={get(values, variantAttributesKey, values)}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
        eventType="event"
      />
    </FormGroup>
  );
};

const SolitaireConfigurationForm = React.memo(({ values, defaultVariant = false, isArchived }) => (
  <>
    <Label
      text="Minimum supported version"
      tooltipText="Could be used to encourage players to update the client version"
    >
      <Field type="text" name="minimumSupportedVersion" />
    </Label>

    <ConfigurationVariant values={values} defaultVariant={defaultVariant} isArchived={isArchived} />
  </>
));

export default SolitaireConfigurationForm;
