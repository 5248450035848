import React, { useState } from 'react';
import styled from 'styled-components';

import { generateChapterBasedRoutes } from '@pages/routes';
import {
  PageHeader,
  Pagination,
  ReactTable,
  DuplicationModal,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { useQuery, useConfirm, useCurrentApplication } from '@hooks';
import IconButton from '@controls/buttons';
import uiConfigurationsColumns from './uiConfigurationsColumns';

const PaddedPagination = styled(Pagination)`
  margin-top: 15px;
`;

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { applicationName, currentApplication: { id: applicationId } } = useCurrentApplication();
  const Routes = generateChapterBasedRoutes(applicationName).UiConfigs;
  const { response, refetch } = useQuery(Routes.indexRequest, { includeInUse: true });
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'UI configuration',
    actionName: 'removal',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `UI Configuration '${name}' with all the content will be removed from the list. Continue?`,
    })
      .then(() => Routes.deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = React.useMemo(() => uiConfigurationsColumns({
    applicationId,
    onDelete,
    onDuplicate: setDuplicatedRecord,
    Routes,
  }), []);

  return response && (
    <div>
      <PageHeader title="UI Configurations">
        <IconButton.New to={Routes.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="UI configuration"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => Routes.duplicateRequest({ ...values, applicationId })}
        title="Duplicate UI configuration"
      />
      <PaddedPagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
