import React, { useState } from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { useFormContext } from '@hooks';
import IconButton from '@controls/buttons';
import { CancelButton } from '@controls/form/buttons';

export default function FormButtons({ cancelButtonPath }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { submitForm } = useFormContext();

  const onFormSubmit = () => {
    setIsSubmitting(true);

    return submitForm().then(() => setIsSubmitting(false));
  };

  return (
    <ButtonToolbar>
      <CancelButton to={cancelButtonPath} />
      <IconButton.Save
        disabled={isSubmitting}
        onClick={onFormSubmit}
        type="submit"
      />
    </ButtonToolbar>
  );
}
