import React from 'react';
import IndexPage from '@pages/puzzle-time/ConfigIndex';
import { useRouter } from '@tripledotstudios/react-core';
import {
  getHexagonPuzzleConfigs,
  deleteHexagonPuzzleConfig,
} from '@requests/puzzle-time/hexagonPuzzleConfigs';

export default function Index() {
  const { query } = useRouter();

  return (
    <IndexPage
      configName="Hexa Puzzle Config"
      path={`/admin/applications/${query.applicationId}/puzzle_time/hexagon_puzzle_configs`}
      getConfigFunction={getHexagonPuzzleConfigs}
      deleteConfigFunction={deleteHexagonPuzzleConfig}
    />
  );
}
