/* eslint-disable gs/plz-camel-case-for-action-names */
import { v4 as uuidv4 } from 'uuid';

const resolveState = (state, defaultVariant) => (defaultVariant ? state.currentVariantAttributes : state);

export default function rewardsReducer(state, action) {
  if (action.actionType === 'add_reward') {
    const { defaultVariant, levelIndex } = action;
    const variantState = resolveState(state, defaultVariant);

    variantState.levelsAttributes[levelIndex].rewardsAttributes.push({ _uuid: uuidv4() });
  }

  if (action.actionType === 'remove_reward') {
    const { levelIndex, rewardIndex, defaultVariant } = action;
    const variantState = resolveState(state, defaultVariant);

    variantState.levelsAttributes[levelIndex].rewardsAttributes[rewardIndex]._destroy = true;
  }

  if (action.actionType === 'add_loss_reward') {
    const { defaultVariant, levelIndex } = action;
    const variantState = resolveState(state, defaultVariant);

    variantState.levelsAttributes[levelIndex].configAttributes.rewardsAttributes.push({ _uuid: uuidv4() });
  }

  return state;
}
