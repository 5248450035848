import React, { useEffect, useState } from 'react';

import { useFormContext } from '@hooks';

import Table from './changed-table/Table';
import FormButtonsBlock from './FormButtonsBlock';

export default function ChangedTable({ setShowCancelModal }) {
  const [showOnlyKeysWithErrors, setShowOnlyKeysWithErrors] = useState(false);
  const { values: { keyConfigs }, resetForm } = useFormContext();

  useEffect(() => { if (keyConfigs.length < 1) resetForm(); }, [keyConfigs.length]);

  return keyConfigs.length > 0 && (
    <>
      <FormButtonsBlock
        setShowCancelModal={setShowCancelModal}
        showOnlyKeysWithErrors={showOnlyKeysWithErrors}
        setShowOnlyKeysWithErrors={setShowOnlyKeysWithErrors}
      />
      <Table showOnlyKeysWithErrors={showOnlyKeysWithErrors} setShowOnlyKeysWithErrors={setShowOnlyKeysWithErrors} />
    </>
  );
}
