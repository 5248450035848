import React from 'react';
import { toLower, sortBy } from 'lodash';

import { SelectField } from '@controls/form';
import Select from '@controls/Select';

const selectItems = (items = []) => items.reduce((memo, item) => {
  const option = { ...item };
  const persistedGroup = memo.find(({ label }) => label === item.line);

  if (persistedGroup) {
    persistedGroup.options.push(option);
  } else {
    memo.push({ label: item.line, options: [option] });
  }

  return sortBy(memo, 'label').map((groupOption) => (
    { ...groupOption, options: sortBy(groupOption.options, 'label') }
  ));
}, []);

const filterOption = ({ data, label }, input) => {
  const { line } = data;
  const loweredInput = toLower(input);

  return toLower(label).includes(loweredInput) || toLower(line).includes(loweredInput);
};

const ItemsSelectField = ({
  name,
  value,
  items,
  includeEmpty,
  ...props
}) => (
  <SelectField
    {...props}
    includeEmpty={includeEmpty}
    groupByFn={selectItems}
    name={name}
    options={items}
    defaultValue={value}
    filterOption={filterOption}
  />
);

const ItemsSelect = ({
  value,
  items,
  includeEmpty,
  ...props
}) => (
  <Select
    {...props}
    includeEmpty={includeEmpty}
    groupByFn={selectItems}
    options={items}
    selectedValue={value}
    filterOption={filterOption}
  />
);

export { ItemsSelectField, ItemsSelect };
