import React, { useState } from 'react';
import { Alert } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import { MissionsRoutes } from '@pages/routes';
import {
  PageHeader,
  Pagination,
  ReactTable,
  DuplicationModalWithPriority,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  useCurrentApplication, useQuery, useConfirm, useI18n,
} from '@hooks';
import IconButton from '@controls/buttons';

import ColumnsFactory from './ColumnsFactory';
import CurrentMissionsModal from './CurrentMissionsModal';
import Filter from './Filter';

const statuses = APP_DATA.enums['Missions::AllocationTemplateStatuses'];

export default function Index() {
  const [currentAllocationInstanceId, setCurrentAllocationInstanceId] = useState();
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const [showMissionsModal, setShowMissionsModal] = useState(null);
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  const { currentApplication, applicationId } = useCurrentApplication();
  const { indexRequest, deleteRequest } = MissionsRoutes.AllocationTemplates;
  const { response, refetch } = useQuery(indexRequest);

  const confirm = useConfirm();

  const buildResponseHandler = (actionName) => collectionResponseHandlerFactory({
    entityName: `${namespaceName} Allocation`,
    actionName,
    refetch,
  });

  const deleteResponseHandler = buildResponseHandler('deletion');
  const regenerationResponseHandler = buildResponseHandler('regeneration');

  const onShowCurrentAllocation = (allocationInstanceId) => {
    setShowMissionsModal(true);
    setCurrentAllocationInstanceId(allocationInstanceId);
  };

  const handleCloseCurrentAllocationModal = () => {
    setShowMissionsModal(false);
    setCurrentAllocationInstanceId(null);
  };

  const onRegenerateAllocation = (allocationInstanceId) => {
    confirm
      .showConfirmation({ title: 'Today’s allocations will be regenerated for all players' })
      .then(() => (
        MissionsRoutes.AllocationInstances.regenerateRequest(
          { id: allocationInstanceId, applicationId: currentApplication.id },
        )
      ))
      .then(regenerationResponseHandler);
  };

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `${namespaceName} Allocation '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => deleteRequest({ id, applicationId: currentApplication.id })
        .then(deleteResponseHandler));
  };

  if (!response) { return null; }

  const columns = ColumnsFactory({
    currentApplication,
    statuses,
    setDuplicatedRecord,
    onDelete,
    onShowCurrentAllocation,
    onRegenerateAllocation,
  });

  return (
    <div>
      <PageHeader
        title={`${namespaceName} Allocations`}
        filter={<Filter />}
        userGuideUrlKey="missionsGuideUrl"
      >
        <IconButton.New to={MissionsRoutes.AllocationTemplates.newPath({ applicationId: currentApplication.id })} />
      </PageHeader>

      <Alert variant="info">
        {`New ${namespaceName} will be sent to the client at ${response.missionsStartTime} UTC daily, \
          server starts generating allocations an hour before - at ${response.generationStartTime}`}
      </Alert>
      <ReactTable
        columns={columns}
        data={response.items}
        defaultSort={{ id: 'priority', desc: true }}
      />
      <DuplicationModalWithPriority
        record={duplicatedRecord}
        handleClose={() => setDuplicatedRecord(null)}
        entitiesByPriority={response.entitiesByPriority || {}}
        entityName="Allocation"
        onSubmit={(values) => (
          MissionsRoutes.AllocationTemplates.duplicateRequest({ ...values, applicationId })
        )}
      />
      <CurrentMissionsModal
        show={showMissionsModal}
        handleClose={handleCloseCurrentAllocationModal}
        allocationInstanceId={currentAllocationInstanceId}
      />
      <Pagination pageCount={response._meta.pages} />
    </div>
  );
}
