import React from 'react';
import { useQuery } from '@hooks';
import { useRouter } from '@tripledotstudios/react-core';

import Form from './Form';

export default function Resource({
  gameType, entityType, fieldsFactory, Tabs, isBase, onInitialLoad, onSubmit,
}) {
  const { query } = useRouter();
  const { response, setResponse } = useQuery({
    request: onInitialLoad,
    additionalRequestParams: { gameType },
  });

  return response && (
    <Form
      entityType={entityType}
      fieldsFactory={fieldsFactory}
      Tabs={Tabs}
      data={response}
      setData={setResponse}
      onSubmit={(values) => onSubmit({ gameType, ...query, ...values })}
      isBase={isBase}
      gameType={gameType}
    />
  );
}
