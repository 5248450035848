import React from 'react';

import Edit from '../Edit';
import Form from './Form';

export default function AppUpdatesEdit() {
  return (
    <Edit
      form={Form}
      routesName="AppUpdates"
      entityName="App updates"
    />
  );
}
