import React from 'react';
import { Alert, Spinner } from '@tripledotstudios/react-core';

import { PageSection } from '@pages/common';
import { Form, useQuery } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { AdminRoutes } from '@pages/routes';
import { FormButtonsGroup } from '@controls/form';

import { JOURNEY_NOTIFICATION_APPLICATIONS } from '@pages/applications/constants';

import JourneyKeyMetricsServingSettingsFields from './fields/JourneyKeyMetricsServingSettings';
import DailyChallengeServingSettingsFields from './fields/DailyChallengeServingSettings';
import JourneyNotificationTitledAlerts from './fields/JourneyNotificationAlert';
import JiraSettings from './fields/JiraSettings';
import BaseFields from './fields/Base';

export default function AdminApplicationSettingsForm({
  applicationId, applicationName, applicationType, applicationTypeName,
}) {
  const { isLoading, response, setResponse } = useQuery(
    AdminRoutes.Settings.Admin.Applications.editRequest, { applicationId },
  );
  const entityName = `${applicationName} Admin Settings`;

  const responseHandler = noRedirectHandlerFactory({
    entityName,
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    AdminRoutes.Settings.Admin.Applications.updateRequest({ ...values, applicationId }).then(responseHandler)
  );
  const journeyMetricsAppTypes = [
    'Solitaire::Application',
    'Woodoku::Application',
    'BlockParty::Application',
    'Sortaga::Application',
  ];

  const appTypesWithJiraSupport = [
    'Solitaire::Application',
    'Tile::Application',
    'MyPetCafe::Application',
    'Blackjack::Application',
    'Sudoku::Application',
    'Woodoku::Application',
  ];

  if (isLoading) return <Spinner />;

  return response ? (
    <Form initialValues={response} onSubmit={onSubmit}>
      {() => (
        <PageSection title={entityName} size="md" actions={<FormButtonsGroup hideCancel />}>
          <BaseFields />
          {journeyMetricsAppTypes.includes(applicationType)
            && <JourneyKeyMetricsServingSettingsFields />}
          {['Woodoku::Application', 'BlockParty::Application', 'Sortaga::Application'].includes(applicationType)
            && <DailyChallengeServingSettingsFields />}
          {JOURNEY_NOTIFICATION_APPLICATIONS.includes(applicationType)
            && <JourneyNotificationTitledAlerts applicationTypeName={applicationTypeName} />}
          {appTypesWithJiraSupport.includes(applicationType) && <JiraSettings />}
        </PageSection>
      )}
    </Form>
  ) : (
    <Alert variant="danger">Not available for editing</Alert>
  );
}
