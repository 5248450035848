import React from 'react';
import { Form } from 'react-bootstrap';

import { ServerError } from '@controls/form';

const DefaultRewardItemsConfigurations = (label = 'Bundle Asset') => ([
  {
    label,
    value: 'Payments::Rewards::BundleAssetReward',
    buildDefaults: () => ({ value: null }),
    component: ({ data, addData, readOnly }) => {
      const handleValueChange = (event) => {
        const { value } = event.target;

        addData({ value });
      };

      return (
        <tr>
          <td><b>Value</b></td>
          <td>
            <Form.Control
              type="text"
              value={data.value}
              onChange={handleValueChange}
              disabled={readOnly}
            />
            <ServerError name="value" />
          </td>
        </tr>
      );
    },
  },
]);

export default DefaultRewardItemsConfigurations;
