import React from 'react';
import { Row, Col } from 'react-bootstrap';

import APP_DATA from '@services/appData';
import {
  Field,
  SelectField,
  TagsField,
  Label,
} from '@controls/form';
import { useCurrentApplication, useFormContext } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import { PageSection } from '@pages/common';

import TierTargetTemplates from './target-template/TierTargetTemplates';
import Rules from './target-template/Rules';

const allocationTypes = APP_DATA.enums['Missions::TargetTemplateAllocationTypes'];
const allocationTypeOptions = fromClassNameToOptions('Missions::TargetTemplateAllocationTypes');

export default function TargetTemplate({
  index,
  tierTargetsAttributes,
  valuesType,
  rulesAttributes,
  allocationType,
  supportRules,
  localizationKeys,
  _destroy,
}) {
  const { dispatch } = useFormContext();
  const { currentApplication } = useCurrentApplication();

  const switchAllocationType = (event) => {
    if (event.value === allocationTypes.RANGE) return;

    dispatch({ actionType: 'clearValue', index });
  };

  const isLocalizationKeyRequired = currentApplication.type === 'Woodoku::Application';

  return (
    <>
      <PageSection title="Configuration" />
      <Row>
        <Col>
          <Label text="Parameters" direction="vertical">
            <TagsField
              name="parametersAttributes"
              propertyName="value"
              placeholder="Add a parameter..."
              disabled={_destroy}
            />
          </Label>
        </Col>
        <Col>
          <Label text="Weight" direction="vertical">
            <Field
              type="number"
              name="weight"
              disabled={_destroy}
            />
          </Label>
        </Col>
        <Col>
          <Label text="Allocation type" direction="vertical">
            <SelectField
              name="allocationType"
              options={allocationTypeOptions}
              onChange={(event) => switchAllocationType(event)}
              isDisabled={_destroy}
            />
          </Label>
        </Col>
        <Col>
          {/* TODO: remove the check when the field becomes required or after we switch to game-specific views */}
          <Label text={`Localisation key${isLocalizationKeyRequired ? '*' : ''}`} direction="vertical">
            <SelectField
              name="titleTranslationKeyId"
              options={localizationKeys}
              includeEmpty={!isLocalizationKeyRequired}
              disabled={_destroy}
            />
          </Label>
        </Col>
      </Row>
      <hr />
      <PageSection
        title="Target value"
        tooltip="Range would be either 'from Tier X to Tier Y' or 'from Tier X to exact value'"
      />
      <Row className="mb-3">
        <Col>
          <Row>
            <TierTargetTemplates
              tierTargets={tierTargetsAttributes}
              allocationType={allocationType}
              allocationTypes={allocationTypes}
              valuesType={valuesType}
              disabled={_destroy}
            />
          </Row>
        </Col>
      </Row>
      {supportRules && (
        <>
          <hr />
          <PageSection title="Rules" />
          <Row>
            <Col>
              <Rules
                path={`targetsAttributes.${index}`}
                rulesAttributes={rulesAttributes}
                disabled={_destroy}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
