import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { get } from 'lodash';

import { useFormContext, useFormGroup } from '@hooks';

import Field from './Field';
import SelectField from './SelectField';
import DateTimeField from './DateTimeField';
import FileField from './FileField';
import ArrayField from './ArrayField';
import Label from './Label';
import TimePeriodField from './TimePeriodField';

export default function LabeledField({
  type = null,
  as = null,
  disabled = false,
  componentData = null,
  component = null,
  children = null,
  onChange = null,
  className = '',
  componentFn = () => null,
  disabledFn = () => false,
  name,
  label,
  step,
  isMultiSelect,
  appendText,
  disableLabel,
  tooltipText,
  includeEmptyOption,
  hasPrepopulatedOption,
  clientKey,
  quickLinkFn,
  inputs,
}) {
  const { generateName } = useFormGroup();
  const { values } = useFormContext();
  // eslint-disable-next-line no-useless-escape
  const groupValues = get(values, generateName(name).replace(new RegExp(`\.?${name}`), ''));
  const asToComponentMap = {
    null: [Field, {
      name,
      type,
      as,
      component,
      onChange,
      appendText,
      step,
      disabled: disabled || disabledFn(groupValues),
      formControlClass: type !== 'checkbox',
    }],
    date: [DateTimeField, { name }],
    select: [SelectField, {
      name,
      isDisabled: disabled || disabledFn(groupValues),
      includeEmpty: includeEmptyOption,
      isMulti: isMultiSelect,
      quickLinkFn,
      onChange,
      options: componentData,
      hasPrepopulatedOption,
    }],
    file: [FileField, { name }],
    timePeriod: [TimePeriodField, { name, disabled: disabled || disabledFn(groupValues), inputs: inputs?.split(', ') }],
    array: [ArrayField, { name, disabled: disabled || disabledFn(groupValues), ...componentData }],
  };

  const [Component, componentProps] = as === 'component'
    ? componentFn({ disabled, name, ...componentData })
    : asToComponentMap[as];

  const inputLabel = <Label text={label} copyableText={clientKey} tooltipText={tooltipText} labelSize={12} />;

  const labelCol = disableLabel ? '' : <Col xs={4}>{inputLabel}</Col>;

  let fullClassName = className;
  if (!disableLabel) fullClassName += ' mb-3';

  return (
    <Col xs={12} className={fullClassName}>
      <Row>
        {labelCol}

        <Col xs={disableLabel ? 12 : 8}>
          <Component {...componentProps}>
            {children}
          </Component>
        </Col>
      </Row>
    </Col>
  );
}
