import React from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { DailyRewardsRoutes as Routes } from '@pages/routes';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function Edit() {
  const { query: { id, applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(Routes.Configs.editRequest, { id });

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Daily Rewards Config',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.Configs.updateRequest({ ...values, applicationId }).then(responseHandler);

  if (isLoading) return <Spinner />;

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
