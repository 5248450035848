import React from 'react';

import { useRouter } from '@tripledotstudios/react-core';
import useAvailabilityTypes from './shared/services/useAvailabilityTypes';
import Table from './diff-table/Table';
import ButtonGroup from './diff-table/DiffTableButtonGroup';

export default function DiffTable({
  setMigrationInProgress,
  setKeyIdsToSkip,
  keyIdsToSkip,
  keyConfigs = [],
  filteredKeyConfigs = [],
}) {
  const { isLive } = useAvailabilityTypes();
  const { query: { diff } } = useRouter();

  return isLive && diff && (
    <>
      <ButtonGroup
        setMigrationInProgress={setMigrationInProgress}
        keyConfigs={keyConfigs}
        keyIdsToSkip={keyIdsToSkip}
        setKeyIdsToSkip={setKeyIdsToSkip}
      />
      <Table
        keyConfigs={filteredKeyConfigs}
        setKeyIdsToSkip={setKeyIdsToSkip}
      />
    </>
  );
}

