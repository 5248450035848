import React from 'react';
import { Col } from 'react-bootstrap';

import { Filter, TextFilter } from '@pages/common';

export default function LeaderboardsFilter() {
  return (
    <Filter defaultComponents={['availabilityStateIn', 'createdByMe']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="unityLeaderboardIdContAny"
              label="Unity Leaderboard ID"
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
