import React from 'react';

import styled from 'styled-components';

import { Col, Row } from 'react-bootstrap';
import { FaButton, RemoveButton } from '@pages/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useFormContext } from '@hooks';

const MoveDownButton = styled(FaButton)`
  margin-top: 10px;
`;

const ShiftPositionControl = styled.div`
  display: flex;
  flex-flow: column;
`;

export default function CategoryPositionCol({ index, uuid, categoriesCount }) {
  const { dispatch, meta } = useFormContext();

  const moveUp = async () => dispatch({
    type: 'moveUp', itemsNames: ['categoriesAttributes'], index, meta,
  });
  const moveDown = async () => dispatch({
    type: 'moveDown', itemsNames: ['categoriesAttributes'], index, meta,
  });
  const removeCategory = async () => dispatch({ type: 'removeCategory', uuid });

  return (
    <Col xs="2" xl="1">
      <Row>
        <Col xs="5" className="text-right">
          <RemoveButton size="lg" onClick={removeCategory} title="Delete the category" />
        </Col>
        <Col xs="7" className="text-center">
          <ShiftPositionControl>
            <FaButton onClick={moveUp} disabled={index === 0}>
              <FontAwesomeIcon icon={faChevronUp} />
            </FaButton>
            <MoveDownButton onClick={moveDown} disabled={index === categoriesCount - 1}>
              <FontAwesomeIcon icon={faChevronDown} />
            </MoveDownButton>
          </ShiftPositionControl>
        </Col>
      </Row>
    </Col>
  );
}
