import React, { useState, useEffect } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import { PageHeader } from '@pages/common';
import {
  FormButtonsGroup,
  Field,
  Label,
  FileField,
  SelectField,
  StaticFieldsFormGroup,
} from '@controls/form';
import { fromClassNameToOptions } from '@services/enums';

import ErrorsCard from './ErrorsCard';

const safelyParseJson = (value) => {
  if (typeof value === 'object') {
    return value;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return '';
  }
};

const parseJsonContent = async (file, rawSavedJson) => {
  if (file) {
    const text = await file.text();

    return safelyParseJson(text);
  }

  return safelyParseJson(rawSavedJson);
};

const JsonConfigContent = ({ file, fileContent }) => {
  const [jsonContent, setJsonContent] = useState(null);

  useEffect(() => {
    parseJsonContent(file, fileContent).then((content) => setJsonContent(content));
  }, [file]);

  return jsonContent && (
    <JSONInput
      placeholder={jsonContent}
      onKeyPressUpdate={false}
      width="100%"
      confirmGood={false}
      viewOnly
      theme="light_mitsuketa_tribute"
    />
  );
};

export default function JsonConfigsForm({
  data,
  cancelButtonPath,
  onSubmit,
  configTypes,
  disableTypeSelect,
  humanizedGroupName,
}) {
  const { query } = useRouter();
  const { applicationId } = query;
  const allConfigTypes = fromClassNameToOptions('MyPetCafe::JsonConfigTypes');
  const filteredOptions = allConfigTypes.filter(({ value }) => configTypes.includes(value));

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit}>
      {({ values }) => {
        const {
          downloadUrl, meta, fileContent, file,
        } = values;

        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} ${humanizedGroupName} JSON Config`}>
              <FormButtonsGroup cancelButtonPath={cancelButtonPath} />
            </PageHeader>

            <Label text="Name" fieldSize={8}>
              <Field type="text" name="name" />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <Label text="Type" fieldSize={8}>
              <SelectField
                name="type"
                options={filteredOptions}
                isDisabled={disableTypeSelect}
              />
            </Label>
            <Label text="Config (JSON)" fieldSize={8}>
              <FileField downloadUrl={downloadUrl} name="file" accept="application/json" fullError />
            </Label>
            <ErrorsCard meta={meta} />
            <JsonConfigContent file={file} fileContent={fileContent} />
          </>
        );
      }}
    </Form>
  );
}
