const optionsToInternalId = (values, options) => (
  options.filter((o) => values.indexOf(o.value) > -1).map((o) => o.label.toString())
);

export default function transformPostcardAttributes(postcard) {
  if (!postcard.storyOrdersAttributes && !postcard.postcardChangesAttributes) return postcard;

  (postcard.storyOrdersAttributes || []).forEach((storyOrder) => {
    // eslint-disable-next-line no-param-reassign
    storyOrder.prerequisites = optionsToInternalId(storyOrder.prerequisites, storyOrder.prerequisiteOptions);
  });
  (postcard.postcardChangesAttributes || []).forEach((change) => {
    // eslint-disable-next-line no-param-reassign
    change.storyOrders = optionsToInternalId(change.storyOrders, postcard.storyOrdersOptions);
  });
  return postcard;
}
