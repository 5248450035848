import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { StreaksRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const [data, setData] = useState(null);
  const { query } = useRouter();
  const { applicationId } = query;

  const {
    indexPath, editRequest, updateRequest, archiveRequest,
  } = StreaksRoutes.Entities;

  const baseAttrs = {
    entityName: 'Streak',
    actionName: 'update',
    redirectPath: indexPath({ applicationId }),
    setData,
  };
  const editResponseHandler = redirectIfSuccessHandlerFactory(baseAttrs);
  const archiveResponseHandler = redirectIfSuccessHandlerFactory({ ...baseAttrs, actionName: 'archive' });
  const onSubmit = (values) => updateRequest({ ...values, applicationId }).then(editResponseHandler);
  const onArchive = () => archiveRequest({ applicationId, id: data.id }).then(archiveResponseHandler);

  useEffect(() => {
    editRequest(query).then((response) => setData(response.data));
  }, []);

  return data && <Form data={data} onSubmit={onSubmit} onArchive={onArchive} actionName="Update" />;
}
