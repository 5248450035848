import { max, last } from 'lodash';

import { persistedCollection } from '@services/utils';
import { addUuid } from '@services/recursivelyProcessObject';

export default function reducer(state, action) {
  const { type, uuid } = action;

  switch (type) {
    case 'addLevel': {
      const levels = persistedCollection(state.levelsAttributes);
      const maxNumber = max(levels.map(({ wonGamesCount }) => wonGamesCount)) || 0;
      const lastLevel = last(levels) || { xp: 0 };

      state.levelsAttributes.push(
        addUuid({ wonGamesCount: maxNumber + 1, xp: lastLevel.xp || 0 }),
      );

      break;
    }
    case 'removeLevel': {
      const levelToDelete = state.levelsAttributes.find((level) => !level._destroy && level._uuid === uuid);
      levelToDelete._destroy = true;

      break;
    }
    default: break;
  }

  return state;
}
