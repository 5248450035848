/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import { Viewer, Differ } from 'json-diff-kit';
import { differenceWith, isEqual, isEmpty } from 'lodash';

import { ExpandIcon } from '@components/merge/shared/icons';

export default function DiffViewer({
  itemNumber, leftItem, rightItem, itemName, allOpened,
}) {
  const [expanded, setExpanded] = useState(false);
  const onClick = () => setExpanded(!expanded);

  useEffect(() => {
    setExpanded(allOpened);
  }, [allOpened]);

  const differ = new Differ({
    detectCircular: true,
    maxDepth: Infinity,
    showModifications: true,
    arrayDiffMethod: 'normal',
    recursiveEqual: true,
  });

  const diff = differ.diff(leftItem, rightItem);
  const isDiffsEqual = isEmpty(differenceWith(diff[0], diff[1], isEqual));
  return (
    <div className="d-flex">
      <div className={`d-flex align-items-top ${isEmpty(leftItem)
        ? 'text-success' : isEmpty(rightItem) ? 'text-danger' : !isDiffsEqual ? 'text-warning' : ''}`}
      >
        {itemName}
        {itemNumber}
        <ExpandIcon value={expanded} onClick={onClick} />
      </div>

      {expanded && (
        <Viewer
          diff={diff}
          indent={4}
          lineNumbers={false}
          highlightInlineDiff={false}
          inlineDiffOptions={{
            mode: 'word',
            wordSeparator: ' ',
          }}
        />
      )}
    </div>
  );
}
