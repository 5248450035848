import React from 'react';

import APP_DATA from '@services/appData';

import { Col } from 'react-bootstrap';
import { Label } from '@controls/form';
import { fromClassNamesToOptions } from '@services/enums';
import {
  Filter,
  TextFilter,
  DateTimeFilter,
  StatusFilter,
} from '@pages/common';

const { enums: { AbExperimentStatuses } } = APP_DATA;

export default function ExperimentsFilter() {
  const [statusOptions] = fromClassNamesToOptions(
    ['AbExperimentStatuses'],
    { withoutFiltering: true },
  );
  const defaultStatuses = [
    AbExperimentStatuses.ACTIVE,
    AbExperimentStatuses.INACTIVE,
    AbExperimentStatuses.ALLOCATION_PAUSED,
  ];

  return (
    <Filter
      defaultComponents={['availabilityStateIn', 'labels', 'createdByMe', 'namedRuleGroup', 'id']}
    >
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <StatusFilter
              name="statusIn"
              label="Status"
              updateFilterField={updateFilterField}
              options={statusOptions}
              defaults={defaultStatuses}
            />
          </Col>
          <Col md={6}>
            <Label direction="vertical" text="Allocation Starts at">
              <DateTimeFilter
                name="allocationStartAt"
                updateFilterField={updateFilterField}
              />
            </Label>
          </Col>
          <Col md={6}>
            <Label direction="vertical" text="Allocation Ends at">
              <DateTimeFilter
                name="allocationEndAt"
                updateFilterField={updateFilterField}
              />
            </Label>
          </Col>
          <Col md={6}>
            <Label direction="vertical" text="Starts at">
              <DateTimeFilter
                name="startAt"
                updateFilterField={updateFilterField}
              />
            </Label>
          </Col>
        </>
      )}
    </Filter>
  );
}
