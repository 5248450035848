import React from 'react';
import { useTheme } from 'styled-components';
import { Sidebar } from '@tripledotstudios/react-core';
import { WoodokuRoutes as Routes, MachineLearningRoutes, FigureSetsRoutes } from '@pages/routes';

import {
  faTable,
  faBars,
  faShapes,
  faMedal,
  faCalendarPlus,
  faToggleOn,
  faCubesStacked,
  faBrain,
} from '@fortawesome/free-solid-svg-icons';

import {
  ApplicationItemSet,
  AssetsNavigationItem,
  AdsNavigationSection,
  FilesNavigationItem,
  JourneysNavigationSection,
  DailyChallengesNavigationSection,
  MissionsNavigationSection,
  RewardsNavigationItem,
} from './common';

export default function Woodoku({ applicationId, routingScope, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="woodoku"
      routingScope={routingScope}
      minimized={minimized}
    >
      <FilesNavigationItem minimized={minimized} applicationId={applicationId} />
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />

      <JourneysNavigationSection
        includeActivationEvents
        includePromoEvents
        includeEndOfJourneyEvents
        includeHardJourneys
        routingScope={routingScope}
        minimized={minimized}
        applicationId={applicationId}
      />

      <DailyChallengesNavigationSection routingScope={routingScope} minimized={minimized} />

      <Sidebar.SectionItem
        icon={faCalendarPlus}
        title="Bonus Levels"
        color={theme.cornflowerBlue}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/woodoku/bonus_levels/events`}
          title="Event"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/woodoku/bonus_levels/level_banks`}
          title="Level Banks"
        />
      </Sidebar.SectionItem>

      <Sidebar.SectionItem
        icon={faTable}
        title="Figure Sets"
        color={theme.yellow}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={FigureSetsRoutes.Sets.indexPath({ applicationId })}
          title="Sets"
        />
        <Sidebar.LinkItem
          to={FigureSetsRoutes.Shapes.indexPath({ applicationId })}
          title="Shapes"
        />
      </Sidebar.SectionItem>

      <Sidebar.SectionItem
        icon={faCubesStacked}
        title="Figures Generation"
        color={theme.conifer}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={Routes.PerfectFit.indexPath({ applicationId })}
          title="Perfect Fit"
        />
        <Sidebar.LinkItem
          to={Routes.ShapesBankSets.indexPath({ applicationId })}
          title="All shapes bank sets"
        />
      </Sidebar.SectionItem>

      <Sidebar.SectionItem
        icon={faBars}
        title="Dynamic Configs"
        color={theme.lightBlue}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/woodoku/dynamic_configs`}
          title="Dynamic Config"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/woodoku/figure_set_dynamic_configs`}
          title="Figure Set Dynamic Config"
        />
      </Sidebar.SectionItem>

      <Sidebar.LinkItem
        to={Routes.TriggerConditions.indexPath({ applicationId })}
        icon={faToggleOn}
        title="Trigger Conditions"
        color={theme.cerulean}
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to={`${routingScope}/woodoku/tile_types`}
        icon={faShapes}
        title="Tile Types"
        color={theme.lightGreen}
        minimized={minimized}
      />

      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />

      <Sidebar.SectionItem
        icon={faMedal}
        title="Daily Reward Configs"
        color={theme.orange}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/daily_rewards/banks`}
          title="Daily Reward Banks"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/daily_rewards/configs`}
          title="Daily Reward Configs"
        />
      </Sidebar.SectionItem>

      <Sidebar.LinkItem
        to={MachineLearningRoutes.Models.indexPath({ applicationId })}
        icon={faBrain}
        title="ML Models"
        color={theme.turquoise}
        minimized={minimized}
      />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />

      <MissionsNavigationSection applicationId={applicationId} minimized={minimized} applicationTypeName="woodoku" />
    </ApplicationItemSet>
  );
}
