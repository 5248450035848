import React, { useState, useMemo } from 'react';
import { ButtonToolbar, useRouter, Spinner } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader,
  Pagination,
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  DuplicationModal,
  InUseColumn,
  ReactTable,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { FigureSetsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';
import Filter from './Filter';

const columnsFactory = ({ applicationId, onDelete, setDuplicatedRecord }) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, inUse } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={FigureSetsRoutes.Sets.editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        <IconButton.Delete onClick={() => onDelete(id, name)} disabled={isInUse(inUse)} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function FigureSetSetsIndex() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch, isLoading } = useQuery(FigureSetsRoutes.Sets.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Figure Set',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => {
    confirm.showConfirmation({ title: `Figure Set '${name}' will be deleted and not available for work. Continue?` })
      .then(() => FigureSetsRoutes.Sets.deleteRequest({ id, applicationId }).then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({ applicationId, onDelete, setDuplicatedRecord }), []);

  return response && (
    <div>
      <PageHeader
        title="Figure Sets"
        filter={<Filter />}
      >
        <IconButton.New to={FigureSetsRoutes.Sets.newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? <Spinner /> : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
            defaultSort={{ id: 'createdAt', desc: true }}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
          <DuplicationModal
            record={duplicatedRecord}
            entityName="Figure Set"
            handleClose={() => setDuplicatedRecord(null)}
            title="Duplicate Figure Set"
            onSubmit={(values) => (
              FigureSetsRoutes.Sets.duplicateRequest({ ...values, applicationId })
            )}
          />
        </>
      )}
    </div>
  );
}
