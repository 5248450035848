import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { useI18n } from '@hooks';
import { MissionsRoutes } from '@pages/routes';

import Error from '@controls/form/Error';

import MissionTemplates from './MissionTemplates';
import TargetTemplates from './TargetTemplates';

export default function MissionsForm({ targetsAttributes, missionsAttributes }) {
  const { query: { applicationId } } = useRouter();
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  return targetsAttributes.length > 0 ? (
    <>
      <TargetTemplates targets={targetsAttributes} />
      <p />
      <MissionTemplates
        missions={missionsAttributes}
        targets={targetsAttributes}
      />
    </>
  ) : (
    <Error>
      {`No ${namespaceName} types`}
      &nbsp;
      <Link
        to={MissionsRoutes.MissionTypes.indexPath({ applicationId })}
        target="_blank"
      >
        <FontAwesomeIcon icon={faLink} />
      </Link>
      &nbsp;
      defined
    </Error>
  );
}
