import React, { useState } from 'react';

import {
  useQuery,
  useMassOperations,
  useCurrentApplication,
} from '@hooks';
import { useRouter } from '@tripledotstudios/react-core';

import {
  DuplicationModalWithPriority,
  PageHeader,
  PageSection,
  Pagination,
  ReactTable,
} from '@pages/common';

import { GameSettingsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';
import Filter from '@components/overridable-settings/Filter';
import { fromClassNameToOptions } from '@services/enums';

import Toolbar from './Toolbar';
import buildColumns from './buildColumns';

export default function List({ gameType }) {
  const { query: { applicationId } } = useRouter();
  const { currentApplication: { type } } = useCurrentApplication();
  const { response, refetch } = useQuery(GameSettingsRoutes.indexRequest, { gameType, applicationId });
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const {
    MassOperationsHeaderButtons,
    MassOperationsModal,
    massOperationsTableProps,
  } = useMassOperations();

  const getRowProps = (row) => {
    if (!row || !row.original.overrideAbExperiments) return { color: 'red' };

    return { className: 'table-primary' };
  };

  const columns = buildColumns({ gameType, refetch, setDuplicatedRecord });

  return response && (
    <div>
      <PageHeader
        title="Game Settings"
        filter={<Filter statusesEnum="GameSettingsStatuses" />}
        userGuideUrlKey="gameSettingsGuideUrl"
        menuButtons={(
          <>
            <MassOperationsHeaderButtons />

            <IconButton.Search
              to={GameSettingsRoutes.searchFieldPath({ gameType, applicationId })}
              variant="secondary"
              outline
            >
              Search
            </IconButton.Search>
          </>
        )}
      >
        <IconButton.New
          to={GameSettingsRoutes.newPath({ gameType, applicationId })}
          testId="header_new_button"
        />
      </PageHeader>

      <Toolbar
        applicationId={applicationId}
        gameType={gameType}
        supportFiles={response.supportFiles}
      />

      <PageSection title="Overrides" />
      <ReactTable
        tableProps={{
          bordered: true,
          hover: true,
          striped: true,
        }}
        columns={columns}
        data={response.items || []}
        additionalProps={{ getRowProps }}
        {...massOperationsTableProps}
      />
      <MassOperationsModal
        pageModelName="Game Settings"
        modelName={type.replace('Application', 'GameSettings')}
        statusOptions={fromClassNameToOptions('GameSettingsStatuses')}
        transactional
        includeAvailabilityState
        refetch={refetch}
      />
      <DuplicationModalWithPriority
        record={duplicatedRecord}
        entityName="Game Settings"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => GameSettingsRoutes.duplicateRequest({ ...values, applicationId, gameType })}
        entitiesByPriority={response.entitiesByPriority || {}}
      />
      <Pagination
        pageCount={response._meta?.pages}
      />
    </div>
  );
}
