import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  font-size: 1.5em;
  ${({ variant, theme }) => (variant && `color: ${theme.variants[variant]};`)}
`;

export default function CardHeader({ text, variant }) {
  return (
    <StyledSpan variant={variant}>
      {text}
    </StyledSpan>
  );
}
