import React from 'react';

import APP_DATA from '@services/appData';

import { Form, useConfirm, checkAvailabilityOnSubmit } from '@hooks';

import RulesForm from '@pages/rules/Form';
import {
  combineReducers,
  rulesReducer,
  missionTemplatesReducer,
  missionTemplateRewardsReducer,
  targetTemplatesReducer,
  clientRulesReducer,
} from '@reducers';

import moveItemsReducer from '@root/reducers/moveItemsReducer';
import GeneralFields from './form/GeneralFields';
import MissionsForm from './form/MissionsForm';

const reducer = combineReducers([
  moveItemsReducer,
  missionTemplatesReducer,
  missionTemplateRewardsReducer,
  targetTemplatesReducer,
  rulesReducer,
  clientRulesReducer,
]);

const availabilityStatesEnum = APP_DATA.enums.AvailabilityStates;

export default function AllocationTemplatesForm({ data, onSubmit }) {
  const confirm = useConfirm();

  const onSubmitWrapper = (values) => {
    const { targetsAttributes, ...rest } = values;

    // Patching values required to force form to send uuid to backend
    // It is necessary to resolve target ids from uuids
    const patchedTargetsAttributes = targetsAttributes.map((attributes) => ({ ...attributes, uuid: attributes._uuid }));
    const patchedValues = { ...rest, targetsAttributes: patchedTargetsAttributes };

    return checkAvailabilityOnSubmit({
      values: patchedValues,
      onSubmit,
      confirm,
      availabilityStatesEnum,
    });
  };

  return data && (
    <Form
      initialValues={data}
      onSubmit={onSubmitWrapper}
      reducer={reducer}
    >
      {({ values }) => (
        <>
          <GeneralFields values={values} />
          <MissionsForm
            targetsAttributes={values.targetsAttributes}
            missionsAttributes={values.missionsAttributes}
          />

          <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} />
        </>
      )}
    </Form>
  );
}
