import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { AdsRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { query: { applicationId } } = useRouter();
  const { indexPath, editRequest, updateRequest } = AdsRoutes.RewardedVideos.Placements;
  const { response, setResponse } = useQuery(editRequest, { applicationId });

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Rewarded Video Placement',
    actionName: 'update',
    redirectPath: indexPath({ applicationId }),
    setData: setResponse,
  });

  const onSubmit = (values) => (
    updateRequest({ ...values, applicationId }).then(responseHandler)
  );

  return response && (
    <Form data={response} onSubmit={onSubmit} />
  );
}
