import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { get, map } from 'lodash';
import { PageSection, Alert } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { FormGroup } from '@hooks';

import { Field, SelectField, ServerError } from '@controls/form';
import Tooltip from '@controls/Tooltip';
import IconButton from '@controls/buttons';

const { rewardTypes } = APP_DATA;

export default function ContainerContent({
  values,
  isInUse,
  dispatch,
  rewardItems,
}) {
  const { forbiddenChildrenIds } = values;
  const [containerItems, setContainerItems] = useState([]);

  useEffect(() => {
    const options = rewardItems
      .filter(({ id: respId, rewardType: itemRewardType }) => (
        !forbiddenChildrenIds.includes(respId) && !rewardTypes[itemRewardType].type_metadata.ignore_in_container))
      .map(({
        id: itemId, content, data, name, displayRewardType,
      }) => ({
        content,
        data,
        label: `${name} (${displayRewardType})`,
        value: itemId,
      }));

    setContainerItems(options);
  }, [rewardItems]);

  return (
    <PageSection title="Content">
      <Row className="mb-2">
        <Col sm={6} className="fw-bold">Item name (item_key)</Col>
        <Col sm={2} className="fw-bold">Quantity</Col>
        <Col className="fw-bold">
          <Tooltip text="Shows content if a container is selected">
            Included Items
          </Tooltip>
        </Col>
        <Col sm={1} />
      </Row>
      <ServerError as={Alert} name="contentSetsList" full />
      <ServerError as={Alert} name="contentSetsAttributes" full />
      {values.contentSetsAttributes.map((contentSet, index) => {
        const reward = containerItems
          .find(({ value }) => value === get(values, `contentSetsAttributes[${index}].childId`));
        return !contentSet._destroy && (
          <FormGroup name={`contentSetsAttributes[${index}]`} key={contentSet._uuid}>
            <Row>
              <Col sm={6}>
                <SelectField
                  name="childId"
                  isDisabled={isInUse}
                  options={containerItems}
                />
              </Col>

              <Col sm={2}>
                <Field name="quantity" type="number" disabled={isInUse} />
              </Col>

              <Col>
                {reward
                  && map(
                    reward.content, (set) => `${set.name}(${set.rewardType}): ${set.quantity}`,
                  ).join('\n')}
              </Col>

              <Col sm={1}>
                <IconButton.Delete
                  minimized
                  disabled={isInUse}
                  onClick={() => !isInUse && dispatch({ actionType: 'removeItem', index })}
                  className="mb-3"
                >
                  Delete the item
                </IconButton.Delete>
              </Col>
            </Row>
          </FormGroup>
        );
      })}

      <IconButton.New
        disabled={isInUse}
        onClick={() => !isInUse && dispatch({ actionType: 'addItem' })}
      >
        Add a new item
      </IconButton.New>
    </PageSection>
  );
}
