import React, { useMemo, useState } from 'react';

import { MissionsRoutes } from '@pages/routes';
import {
  PageHeader, Pagination, ReactTable,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  useCurrentApplication, useQuery, useConfirm, useI18n,
} from '@hooks';
import IconButton from '@controls/buttons';
import ColumnsFactory from './ColumnsFactory';

import EditModal from './EditModal';
import NewModal from './NewModal';
import Filter from './Filter';

export default function Index() {
  const { currentApplication } = useCurrentApplication();
  const { indexRequest, archiveRequest } = MissionsRoutes.MissionTypes;
  const { response, refetch } = useQuery(indexRequest, { includeInUse: true });
  const [editedRecordId, setEditedRecordId] = useState(null);
  const [newRecord, setNewRecord] = useState(false);
  const confirm = useConfirm();

  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  const responseHandler = collectionResponseHandlerFactory({
    entityName: `${namespaceName} Type`,
    actionName: 'archive',
    refetch,
  });

  const onArchive = (id, name) => {
    confirm.showConfirmation({
      title: `${namespaceName} Type '${name}' will be archived and not available for work. Continue?`,
    }).then(() => archiveRequest({ id, applicationId: currentApplication.id })
      .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({ setEditedRecordId, onArchive }), []);

  return response && (
    <div>
      <PageHeader
        title={`${namespaceName} Types`}
        filter={<Filter />}
        userGuideUrlKey="missionsGuideUrl"
      >
        <IconButton.New onClick={() => setNewRecord(true)} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <NewModal
        onHide={() => setNewRecord(false)}
        recordId={newRecord}
        refetch={refetch}
        entityName={`${namespaceName} Type`}
      />
      <EditModal
        onHide={() => setEditedRecordId(null)}
        recordId={editedRecordId}
        refetch={refetch}
        entityName={`${namespaceName} Type`}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
