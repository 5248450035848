import PropTypes from 'prop-types';
import styled from 'styled-components';
import { transparentize } from 'polished';

const BACKGROUNDS = [
  'danger',
  'warning',
  'primary',
  'secondary',
  'success',
];

const Badge = styled.span`
  border-radius: 0;
  border: none;
  text-align: center;
  padding: 3px 10px;
  white-space: nowrap;
  ${({ bg, theme }) => {
    const { color, backgroundColor } = theme.badges[bg];
    return `
      background: ${transparentize(0.7, backgroundColor || color)};
      color: ${color};
    `;
  }}
`;

Badge.propTypes = {
  bg: PropTypes.oneOf(BACKGROUNDS),
};

export default Badge;
