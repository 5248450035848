import React from 'react';

import TextareaAutosize from 'react-textarea-autosize';
import { Field } from 'formik';

import EditableCell from '../EditableCell';
import onFocus from '../../shared/services/editable-cell/onFocus';

export default function TextCell({
  name,
  path,
  value,
  oldValues,
  setFieldValue,
  disabled,
  isNewRecord,
  sticky,
  multiline,
}) {
  return (
    <EditableCell
      path={path}
      name={name}
      value={value}
      oldValues={oldValues}
      setFieldValue={setFieldValue}
      disabled={disabled}
      isNewRecord={isNewRecord}
      sticky={sticky}
    >
      {({
        fullName,
        currentValue,
        setCurrentValue,
        onKeyDown,
        onBlur,
      }) => (
        <Field
          name={fullName}
          value={currentValue}
          type="text"
          className="form-control"
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={(event) => setCurrentValue(event.target.value)}
          as={multiline ? TextareaAutosize : Field}
          autoFocus
        />
      )}
    </EditableCell>
  );
}
