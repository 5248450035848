/* eslint-disable react/destructuring-assignment */
import React, { memo, useContext } from 'react';
import { isEqual, omit } from 'lodash';

import { Accordion, useAccordionButton } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { SelectField } from '@controls/form';
import { generateChapterBasedRoutes } from '@pages/routes';
import Tooltip from '@controls/Tooltip';
import LevelQRCode from '@pages/woo/aspen/banks/LevelQRCode';

import { FormGroup, useCurrentApplication } from '@hooks';

const LevelsContainer = styled.div`
  display: flex;
`;

const FullWidthAccordion = styled(Accordion)`
  flex-grow: 1;
`;

const LevelsTable = styled.div`
  padding: 10px 0;
  flex-grow: 1;
`;

const Level = styled.div`
  border: 1px solid lightgray;
  display: flex;
  flex-flow: column;
  margin-bottom: 1px;
  padding: 5px;
`;

const LevelRow = styled.div`
  display: flex;
`;

const LevelsHeader = styled.div`
  font-weight: bold;
  display: flex;
  margin-bottom: 10px;
`;

const Position = styled.div`
  padding: 0 5px;
  width: 5%;
`;

const LevelName = styled.div`
  padding: 0 5px;
  width: 30%;
`;

const LevelHash = styled.div`
  padding: 0 5px;
  width: 30%;
`;

const QRCode = styled.div`
  padding: 0 5px;
  width: 30%;
`;

const Actions = styled.div`
  padding: 0 5px;
  width: 5%;
`;

const TextValueCell = styled.div`
  padding-top: 8px;
`;

const LevelHashValue = styled(TextValueCell)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AccordionButton = ({ eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;

  return (
    <FontAwesomeIcon
      size="lg"
      icon={faChevronDown}
      style={isCurrentEventKey && { transform: 'rotate(180deg)' }}
      onClick={decoratedOnClick}
    />
  );
};

const LevelQR = ({ levelId, routes, applicationId }) => (
  <div className="p-2" style={{ backgroundColor: '#FFF' }}>
    <LevelQRCode levelId={levelId} routes={routes} applicationId={applicationId} />
  </div>
);

const MemoizedLevel = memo(({
  position,
  levelLayoutsOptions,
  dispatch,
  chapterPath,
  applicationId,
  appType,
  levelLayoutId,
  levelLayout,
  levelsTotal,
}) => {
  const LevelLayoutRoutes = generateChapterBasedRoutes(appType).LevelLayouts;
  const calculatedPosition = levelsTotal + position;

  return (
    <Level>
      <LevelRow>
        <Position>
          <TextValueCell>
            {calculatedPosition}
          </TextValueCell>
        </Position>
        <LevelName>
          <SelectField
            name="levelLayoutId"
            options={levelLayoutsOptions}
            onChange={() => dispatch({ actionType: 'changeLevelLayout', chapterPath })}
          />
        </LevelName>
        <LevelHash>
          <LevelHashValue title={levelLayout.contentHash}>
            {levelLayout.contentHash}
          </LevelHashValue>
        </LevelHash>
        {levelLayout.id && (
          <QRCode>
            <Tooltip
              text={<LevelQR routes={LevelLayoutRoutes} levelId={levelLayout.id} applicationId={applicationId} />}
            >
              Scan to play
            </Tooltip>
          </QRCode>
        )}
        <Actions>
          <TextValueCell>
            {levelLayoutId && (
              <Link
                to={LevelLayoutRoutes.editPath({ applicationId, id: levelLayoutId })}
                target="_blank"
                title="See layout page in separate tab"
              >
                <FontAwesomeIcon size="lg" icon={faFileAlt} />
              </Link>
            )}
          </TextValueCell>
        </Actions>
      </LevelRow>
    </Level>
  );
}, (prev, next) => isEqual(omit(prev, ['dispatch']), omit(next, ['dispatch'])));

export default function Levels({
  chapter, chapterPath, dispatch, levelLayouts, banks, levelsTotal,
}) {
  const { typeName: appType, currentApplication } = useCurrentApplication();
  const levels = chapter.levelsAttributes || [];

  const levelLayoutsOptions = levelLayouts.map((layout) => {
    const bankName = banks.find(({ value }) => value === layout.bankId)?.label;

    return ({
      label: `${layout.name} (${layout.position}) - ${bankName}`,
      value: layout.id,
    });
  });

  return (
    <LevelsContainer>
      {levels.length > 0 && (
        <FullWidthAccordion defaultActiveKey={chapter.populated && '0'}>
          <AccordionButton eventKey="0" />
          <hr />
          <Accordion.Collapse eventKey="0">
            <LevelsTable>
              <LevelsHeader>
                <Position>
                  Level
                </Position>
                <LevelName>
                  Layout to use
                </LevelName>
                <LevelHash>
                  Layout hash
                </LevelHash>
              </LevelsHeader>
              {levels.map((level, levelIndex) => {
                const {
                  levelLayoutId, position, _destroy, _uuid,
                } = level;
                const levelLayout = levelLayouts.find(({ id }) => levelLayoutId === id) || {};
                const groupName = `levelsAttributes[${levelIndex}]`;

                return !_destroy && (
                  <FormGroup name={groupName} key={_uuid}>
                    <MemoizedLevel
                      {...{
                        applicationId: currentApplication.id,
                        appType,
                        chapterPath,
                        dispatch,
                        levelLayoutsOptions,
                        levelLayoutId,
                        levelLayout,
                        position,
                        levelsTotal,
                      }}
                    />
                  </FormGroup>
                );
              })}
            </LevelsTable>
          </Accordion.Collapse>
        </FullWidthAccordion>
      )}
    </LevelsContainer>
  );
}
