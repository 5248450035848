import React from 'react';
import IndexPage from '@pages/puzzle-time/ConfigIndex';
import { useRouter } from '@tripledotstudios/react-core';
import {
  getHexagonSquareConfigs,
  deleteHexagonSquareConfig,
} from '@requests/puzzle-time/hexagonSquareConfigs';

export default function Index() {
  const { query } = useRouter();

  return (
    <IndexPage
      configName="Square Puzzle Config"
      path={`/admin/applications/${query.applicationId}/puzzle_time/hexagon_square_configs`}
      getConfigFunction={getHexagonSquareConfigs}
      deleteConfigFunction={deleteHexagonSquareConfig}
    />
  );
}
