import React, { useState } from 'react';
import { Accordion, ListGroup } from 'react-bootstrap';
import { useRouter, Alert } from '@tripledotstudios/react-core';

import { useQuery, Form } from '@hooks';

import { getLevelLayouts, updateLevelLayout } from '@requests/my-pet-cafe/level-layouts';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';

import { MyPetCafeRoutes } from '@pages/routes';
import { FormButtonsGroup } from '@controls/form';
import { PageHeader, Pagination } from '@pages/common';

import IconButton from '@controls/buttons';
import NoResults from '@root/components/shared/NoResults';
import {
  LayoutRow,
  LayoutDataRow,
  TableHeader,
  SearchBar,
} from './bank';

export default function Index() {
  const [deletionUnlocked, setDeletionUnlocked] = useState(false);
  const [showOnlyModifiedLevels, setShowOnlyModifiedLevels] = useState(false);

  const { response, refetch } = useQuery(getLevelLayouts);
  const { query: { applicationId } } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Level layout',
    actionName: 'update',
    collectionUrl: `/admin/applications/${applicationId}/my_pet_cafe/level_layouts/home`,
  });

  const onSubmit = (values) => {
    const { meta, ...formValues } = values;

    const transformedValues = Object.entries(formValues).map(([levelLayoutId, levelHash]) => (
      { levelLayoutId, levelHash }
    ));

    return updateLevelLayout({ layouts: transformedValues, applicationId }).then(responseHandler);
  };

  const collectInitialValues = (data) => (
    Object.fromEntries(data.items.map(({ levelLayoutId, currentLayoutDataHash }) => (
      [levelLayoutId, currentLayoutDataHash]
    )))
  );

  const revertAll = (setFieldValue) => {
    if (!response) return;

    response.items.map(
      ({ levelLayoutId, currentLayoutDataHash }) => setFieldValue(levelLayoutId, currentLayoutDataHash),
    );
  };

  const showLayoutLevel = (values, levelLayoutId, currentLayoutDataHash) => {
    if (!showOnlyModifiedLevels) return true;

    return values[levelLayoutId] !== currentLayoutDataHash;
  };

  const itemsToDisplay = (values) => (
    response.items.filter(({ levelLayoutId, currentLayoutDataHash }) => (
      showLayoutLevel(values, levelLayoutId, currentLayoutDataHash)
    )));

  return response ? (
    <>
      <Form
        initialValues={collectInitialValues(response)}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, dirty, setFieldValue }) => (
          <>
            <PageHeader
              title="Level Layouts"
              menuButtons={(dirty && (
                <IconButton.Restore onClick={() => revertAll(setFieldValue)}>
                  Revert All
                </IconButton.Restore>
              ))}
            >
              <FormButtonsGroup
                disableSubmit={!dirty}
                cancelButtonPath={MyPetCafeRoutes.LevelLayouts.homePath({ applicationId })}
              />
            </PageHeader>

            <SearchBar
              showOnlyModifiedLevels={showOnlyModifiedLevels}
              setShowOnlyModifiedLevels={setShowOnlyModifiedLevels}
            />
            <Alert variant="info">
              <ul className="mb-0">
                <li>Selecting level hash will make the solitaire editor download that version of level.</li>
                <li>
                  Selecting level hash won&apos;t affect how user receive that version of levels in-game.
                  It will be determined by the hash in the level manifest.
                </li>
                <li>
                  When uploading levels to the GS, it will check whether it has same LevelLayoutID or level hash.
                  Otherwise it will create a new LevelLayoutID or new level hash.
                </li>
              </ul>
            </Alert>
            <TableHeader deletionUnlocked={deletionUnlocked} setDeletionUnlocked={setDeletionUnlocked} />
            {(() => {
              const items = itemsToDisplay(values);

              return items.length > 0 ? (
                items.map(({
                  levelLayoutId, currentLayoutDataHash, layoutData, updatedAt,
                }) => (
                  <Accordion defaultActiveKey={response.items[0].levelLayoutId === levelLayoutId && '0'}>
                    <Accordion.Item eventKey="0" className="mb-3">
                      <Accordion.Header eventKey="0">
                        <LayoutRow
                          levelLayoutId={levelLayoutId}
                          currentLayoutDataHash={currentLayoutDataHash}
                          values={values}
                          updatedAt={updatedAt}
                          deletionUnlocked={deletionUnlocked}
                          refetch={refetch}
                        />
                      </Accordion.Header>
                      <Accordion.Body eventKey="0">
                        <ListGroup>
                          {layoutData.map(({ levelHash, updatedAt: dataUpdatedAt }) => (
                            <LayoutDataRow
                              levelLayoutId={levelLayoutId}
                              levelHash={levelHash}
                              updatedAt={dataUpdatedAt}
                              deletionUnlocked={deletionUnlocked && layoutData.length > 1}
                              refetch={refetch}
                            />
                          ))}
                        </ListGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))) : <NoResults />;
            })()}
          </>
        )}
      </Form>
      <Pagination
        pageCount={response._meta.pages}
      />
    </>
  ) : '';
}
