import { isObjectLike, forOwn } from 'lodash';

function deepFreezeInPlace(object) {
  if (!isObjectLike(object)) { return; }

  Object.freeze(object);

  forOwn(object, (value) => {
    if (!isObjectLike(value) || Object.isFrozen(value)) { return; }

    deepFreezeInPlace(value);
  });
}

export default function deepFreeze(object) {
  deepFreezeInPlace(object);
  return object;
}
