import React from 'react';

import { PageTitle } from '@pages/common';
import { MissionsRoutes } from '@pages/routes';
import {
  AllocationTemplatesIndex,
  AllocationTemplatesNew,
  AllocationTemplatesEdit,

  MissionTypesIndex,

  RewardSetsIndex,
  RewardSetsNew,
  RewardSetsEdit,

  RewardTypesIndex,

  SettingsEdit,
} from '@components/missions';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function missions() {
  return [
    <Route
      key={MissionsRoutes.AllocationTemplates.indexRawPath}
      path={MissionsRoutes.AllocationTemplates.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Missions Allocations" />
          <AllocationTemplatesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MissionsRoutes.AllocationTemplates.newRawPath}
      path={MissionsRoutes.AllocationTemplates.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Mission Allocation" action="New" />
          <AllocationTemplatesNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MissionsRoutes.AllocationTemplates.editRawPath}
      path={MissionsRoutes.AllocationTemplates.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Mission Allocation" action="Edit" />
          <AllocationTemplatesEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MissionsRoutes.MissionTypes.indexRawPath}
      path={MissionsRoutes.MissionTypes.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Mission Types" />
          <MissionTypesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MissionsRoutes.Rewards.Sets.indexRawPath}
      path={MissionsRoutes.Rewards.Sets.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Reward Sets" />
          <RewardSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MissionsRoutes.Rewards.Sets.newRawPath}
      path={MissionsRoutes.Rewards.Sets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Reward Set" action="New" />
          <RewardSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MissionsRoutes.Rewards.Sets.editRawPath}
      path={MissionsRoutes.Rewards.Sets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Reward Set" action="Edit" />
          <RewardSetsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MissionsRoutes.Rewards.Types.indexRawPath}
      path={MissionsRoutes.Rewards.Types.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Reward Types" />
          <RewardTypesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MissionsRoutes.Settings.editRawPath}
      path={MissionsRoutes.Settings.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Missions Settings" />
          <SettingsEdit />
        </RouteContainer>
      )}
    />,
  ];
}
