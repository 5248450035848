import {
  maxBy, isEmpty, map, cloneDeep, isPlainObject, get,
} from 'lodash';

import { addUuid } from '@services/recursivelyProcessObject';
import { reorderList } from '@services/reorder';
import reorderMeta from '@services/reorderMeta';

const reorderFigureSets = (list, startIndex, endIndex) => {
  let index = 0;

  const figureSets = reorderList(list, startIndex, endIndex);
  const resultList = figureSets.map((element) => {
    const level = element;
    level.position = index;

    if (!level._destroy) index += 1;

    return level;
  });

  const uuids = map(list, '_uuid');
  const reorderedUuids = map(resultList, '_uuid');

  const [oldPositions, newPositions] = uuids.reduce((memo, uuid) => {
    memo[0].push(uuids.indexOf(uuid));
    memo[1].push(reorderedUuids.indexOf(uuid));

    return memo;
  }, [[], []]);

  return { result: resultList, changeSet: [oldPositions, newPositions] };
};

export default function reducer(state, action) {
  const { figureSetAffiliationsAttributes } = state;
  const { actionType } = action;

  if (actionType === 'addFigureSet') {
    const notDestroyedAffiliations = figureSetAffiliationsAttributes.filter(({ _destroy }) => !_destroy);

    const lastAffiliation = maxBy(notDestroyedAffiliations, 'position') || {};
    const position = isEmpty(lastAffiliation) ? 0 : lastAffiliation.position + 1;

    state.figureSetAffiliationsAttributes.push(addUuid({ position, _destroy: false }));
  }

  if (actionType === 'removeFigureSet') {
    const { uuid } = action;

    const list = cloneDeep(figureSetAffiliationsAttributes);

    list.forEach((affiliation) => {
      affiliation._destroy = affiliation._destroy || affiliation._uuid === uuid;

      return affiliation;
    });

    state.figureSetAffiliationsAttributes = reorderFigureSets(list).result;
  }

  if (actionType === 'reorderFigureSet') {
    const {
      sourceIndex,
      destinationIndex,
      meta,
      metaErrorsPath,
    } = action;

    const list = cloneDeep(figureSetAffiliationsAttributes);

    const { result, changeSet: [fromIndices, toIndices] } = reorderFigureSets(list, sourceIndex, destinationIndex);

    if (meta.errors && isPlainObject(get(meta, metaErrorsPath))) {
      meta.errors = { ...meta.errors, ...reorderMeta(meta, fromIndices, toIndices, metaErrorsPath).errors };
    }

    state.figureSetAffiliationsAttributes = result;
  }

  return state;
}
