import React from 'react';
import { Col } from 'react-bootstrap';

import { TextFilter } from '@pages/common';

export default function TileFilters({ updateFilterField }) {
  return (
    <>
      <Col xs={3}>
        <TextFilter
          name="levelsCountGteq"
          label="N Pictures from"
          isMulti={false}
          type="number"
          updateFilterField={updateFilterField}
        />
      </Col>
      <Col xs={3}>
        <TextFilter
          name="levelsCountLteq"
          label="N Pictures to"
          isMulti={false}
          type="number"
          updateFilterField={updateFilterField}
        />
      </Col>
    </>
  );
}
