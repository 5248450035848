import React from 'react';

import { Form, FormGroup, useCurrentApplication } from '@hooks';
import {
  FormButtonsGroup,
  Label,
  Field,
  StaticFieldsFormGroup,
  LabelsFormGroup,
  SelectField,
  PriorityField,
  AvailabilityStateFormGroup,
  localizedLabelFactory,
} from '@controls/form';
import { DuplicateButton, PageHeader, PageSection } from '@pages/common';
import { MachineLearningRoutes as Routes } from '@pages/routes';
import { fromClassNameToOptions } from '@services/enums';
import { rulesReducer } from '@reducers';

import RulesForm from '@pages/rules/Form';

import SolitaireConfigFields from './config/Solitaire';
import WoodokuConfigFields from './config/Woodoku';

const APP_SPECIFIC_CONFIG_FIELDS = {
  solitaire: SolitaireConfigFields,
  woodoku: WoodokuConfigFields,
};

export default function MachineLearningModelForm({ data, onSubmit }) {
  const { applicationId, frontendTypeName } = useCurrentApplication();
  const statusOptions = fromClassNameToOptions('MachineLearning::ModelStatuses');
  const { readOnly } = data;

  const LocalizedLabel = localizedLabelFactory(`${frontendTypeName}.machineLearning.model`);
  const ConfigFields = APP_SPECIFIC_CONFIG_FIELDS[frontendTypeName];

  return (
    <Form
      initialValues={{ ...data, applicationId }}
      onSubmit={onSubmit}
      reducer={rulesReducer}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} ML Model`}>
            <FormButtonsGroup
              cancelButtonPath={Routes.Models.indexPath({ applicationId })}
            >
              <DuplicateButton
                routes={Routes.Models}
                data={data}
                entityName="ML Model"
                modalType="withPriority"
              />
            </FormButtonsGroup>
          </PageHeader>

          <Label required text="Name">
            <Field name="name" />
          </Label>

          <Label text="Status" required>
            <SelectField name="status" options={statusOptions} />
          </Label>

          <Label text="Priority" required>
            <PriorityField
              name="priority"
              entitiesByPriority={data.entitiesByPriority}
              disabled={readOnly}
            />
          </Label>

          <AvailabilityStateFormGroup />

          <StaticFieldsFormGroup data={data} />

          <LabelsFormGroup />

          <PageSection title="Endpoint">
            <LocalizedLabel required name="endpointUrl">
              <Field name="endpointUrl" disabled={readOnly} />
            </LocalizedLabel>
          </PageSection>

          <FormGroup name="configAttributes">
            <ConfigFields readOnly={readOnly} />
          </FormGroup>

          <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} disabled={readOnly} />
        </>
      )}
    </Form>
  );
}
