import React from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import LabelCol from './LabelCol';

export default function AddRewardButton({ label, rewards, onClick }) {
  return (
    <Row className="mt-3">
      <LabelCol>
        {rewards.length === 0 && label}
      </LabelCol>
      <Col xs={11}>
        <Row className="align-items-center">
          <Col xs="4">
            <Button type="button" className="float-end mb-3 btn-sm btn-success" onClick={onClick}>
              Add another reward
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
