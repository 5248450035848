import React, { useState, useMemo } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import { useQuery, useConfirm } from '@hooks';

import {
  PageHeader,
  Pagination,
  ReactTable,
  InLiveBadge,
  InLiveColumnHeader,
  TIMESTAMP_COLUMNS,
  DuplicationModal,
  InUseColumn,
  Filter,
} from '@pages/common';

import { isInUse } from '@pages/common/InUse';
import { MyPetCafeRoutes as Routes } from '@pages/routes';

import IconButton from '@controls/buttons';

const columnsFactory = ({ applicationId, setDuplicatedRecord, onArchive }) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  {
    id: 'availability_header',
    Header: () => <InLiveColumnHeader />,
    Cell: ({ row }) => <InLiveBadge inLive={row.original.inLive} />,
  },
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, inUse } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={Routes.BetUpConfigurations.editPath({ applicationId, id })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        <IconButton.Delete onClick={() => onArchive(id, name)} disabled={isInUse(inUse)} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();
  const { response, refetch } = useQuery(
    Routes.BetUpConfigurations.indexRequest,
    { applicationId, includeInUse: true },
  );

  const removalResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Bet Up Configuration',
    actionName: 'archiving',
    refetch,
  });

  const onArchive = (id, name) => (
    confirm.showConfirmation({
      title: `Bet Up Configuration ${name} will be archived and not available for work. Continue?`,
    })
      .then(() => Routes.BetUpConfigurations.archiveRequest({ id, applicationId }).then(removalResponseHandler))
  );

  const columns = useMemo(() => columnsFactory({
    applicationId, setDuplicatedRecord, onArchive,
  }), []);

  return response && (
    <>
      <PageHeader
        title="Bet Up Configurations"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
      >
        <IconButton.New to={Routes.BetUpConfigurations.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        defaultSort={{ id: 'id', desc: true }}
        data={response ? response.items : []}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
      <DuplicationModal
        entityName="Bet Up configuration"
        title="Duplicate Bet Up configuration"
        record={duplicatedRecord}
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={({ id, name }) => (
          Routes.BetUpConfigurations.duplicateRequest({ id, name, applicationId })
        )}
      />
    </>
  );
}
