import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import { WoodokuSagaRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Figure Set',
    actionName: 'update',
    redirectPath: Routes.FigureSets.indexPath(query),
    setData,
  });

  const onSubmit = (values) => Routes.FigureSets.updateRequest(values).then(redirectIfSuccessHandler);

  useEffect(() => {
    Routes.FigureSets.editRequest(query).then((response) => setData(response.data));
  }, []);

  return data && (
    <Form
      data={data}
      onSubmit={onSubmit}
      actionName="Update"
    />
  );
}
