import React, { useCallback } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import requestWorldSchemaOptions from '@components/merge/services/requestSchemaOptions';
import requestEventSchemaOptions from '@components/merge/services/requestEventSchemaOptions';
import { combineReducers } from '@reducers';
import rewardsReducer from '@components/merge/reducers/rewardsReducer';

import { MergeRoutes } from '@pages/routes';
import formReducer from './formReducer';
import Levels from './form/Levels';

export default function LevelSetsForm({ data, onSubmit, actionName }) {
  const { query: { applicationId } } = useRouter();
  const fetchWorldSchemaOptions = useCallback(() => requestWorldSchemaOptions(applicationId), []);
  const fetchEventSchemaOptions = useCallback(() => requestEventSchemaOptions(applicationId), []);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([formReducer, rewardsReducer])}
    >
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Reward Track Configuration`}>
            <FormButtonsGroup
              cancelButtonPath={MergeRoutes.Events.LevelSets.indexPath({ applicationId })}
            />
          </PageHeader>

          <Label text="Name/Topic"><Field type="text" name="name" /></Label>
          <Label text="Description"><Field type="text" name="description" /></Label>

          <Label text="Event Schema">
            <SelectField
              name="eventSchemaId"
              options={fetchEventSchemaOptions}
              isDisabled={actionName !== 'Create'}
            />
          </Label>
          <Label text="Core Schema">
            <SelectField
              name="worldSchemaId"
              options={fetchWorldSchemaOptions}
              onChange={() => dispatch({ type: 'initialize_levels' })}
              isDisabled={actionName !== 'Create'}
            />
          </Label>

          <StaticFieldsFormGroup data={data} />

          {!!values.worldSchemaId && (
            <Levels
              levels={values.levelsAttributes}
              worldSchemaId={values.worldSchemaId}
            />
          )}
        </>
      )}
    </Form>
  );
}
