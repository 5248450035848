import React from 'react';
import { isEqual } from 'lodash';

import APP_DATA from '@services/appData';

import { Label, SelectField } from '@controls/form';

const SharedTranslationKeys = React.memo(({ localizationKeys }) => {
  const { modelsLocales: { shared: { journeys: { tooltips: sharedTooltips } } } } = APP_DATA;

  return (
    <>
      <Label
        text="Start Popup Heading translation key"
        tooltipText={sharedTooltips.surfacingHeadingTranslationKeyId}
      >
        <SelectField
          name="surfacingHeadingTranslationKeyId"
          options={localizationKeys}
          includeEmpty
        />
      </Label>
      <Label
        text="Start Popup Message translation key"
        tooltipText={sharedTooltips.surfacingMessageTranslationKeyId}
      >
        <SelectField
          name="surfacingMessageTranslationKeyId"
          options={localizationKeys}
          includeEmpty
        />
      </Label>
      <Label
        text="Not Long Left Popup Heading translation key"
        tooltipText={sharedTooltips.notLongLeftHeadingTranslationKeyId}
      >
        <SelectField
          name="notLongLeftHeadingTranslationKeyId"
          options={localizationKeys}
          includeEmpty
        />
      </Label>
      <Label
        text="Not Long Left Popup Message translation key"
        tooltipText={sharedTooltips.notLongLeftMessageTranslationKeyId}
      >
        <SelectField
          name="notLongLeftMessageTranslationKeyId"
          options={localizationKeys}
          includeEmpty
        />
      </Label>

      <Label
        text="End Popup Heading translation key"
        tooltipText={sharedTooltips.endHeadingTranslationKeyId}
      >
        <SelectField
          name="endHeadingTranslationKeyId"
          options={localizationKeys}
          includeEmpty
        />
      </Label>
      <Label
        text="End Popup Message translation key"
        tooltipText={sharedTooltips.endMessageTranslationKeyId}
      >
        <SelectField
          name="endMessageTranslationKeyId"
          options={localizationKeys}
          includeEmpty
        />
      </Label>
    </>
  );
}, (prev, next) => isEqual(prev.localizationKeys, next.localizationKeys));

export default SharedTranslationKeys;
