import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { PageSection } from '@pages/common';
import { useFormContext } from '@hooks';

import Entity from './Entity';

export default function EntitiesForm({ values, figureSetsOptions }) {
  const { dispatch } = useFormContext();

  const handleAddEntity = () => {
    dispatch({ actionType: 'addEntity' });
  };

  return (
    <div>
      <PageSection title="Dynamic Config Entities" />
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Difficulty</th>
            <th>Figure Set</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {values.dynamicConfigEntitiesAttributes.map((attributes, index) => (
            <Entity
              key={attributes.id}
              index={index}
              figureSetsOptions={figureSetsOptions}
              _destroy={attributes._destroy}
            />
          ))}
        </tbody>
      </Table>
      <div className="text-end">
        <Button onClick={handleAddEntity}>Add</Button>
      </div>
    </div>
  );
}
