import React, { useMemo, useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import {
  useQuery,
  useConfirm,
} from '@hooks';
import {
  DuplicationModal,
  PageHeader,
  Pagination,
  ReactTable,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { WoodokuRoutes as Routes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';
import ColumnsFactory from './ColumnsFactory';

export default function JsonConfigsIndex({ configTypes, group }) {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(
    Routes.TriggerConditions.indexRequest,
    { includeInUse: true, type: configTypes },
  );

  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Trigger Conditions',
    actionName: 'deletion',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Trigger Conditions '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => Routes.TriggerConditions.deleteRequest({ id, applicationId }))
      .then(responseHandler);
  };

  const columns = useMemo(() => ColumnsFactory({
    applicationId, group, onDelete, setDuplicatedRecord,
  }), []);

  return response && (
    <>
      <PageHeader
        title="Trigger Conditions"
        filter={<Filter />}
      >
        <IconButton.New to={Routes.TriggerConditions.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Trigger Conditions"
        handleClose={() => setDuplicatedRecord(null)}
        title="Duplicate Trigger Conditions"
        onSubmit={(values) => (
          Routes.TriggerConditions.duplicateRequest({ ...values, applicationId })
        )}
      />
    </>
  );
}
