import React from 'react';

import { Form, useCurrentApplication } from '@hooks';
import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { AdsRoutes } from '@pages/routes';

import APP_DATA from '@services/appData';

import rewardedVideoPlacementTypeOptions from './placementTypeOptions';

const labelPrefixes = {
  ProducerCooldown: 'Producer Skip',
  ChestOpening: 'Chest Opening',
  BubbledItem: 'Bubbled Items',
};

const renderConfigFields = (type) => {
  const subType = type.replace(/Ads::RewardedVideos::Placements::\w+::/, '');
  const labelPrefix = labelPrefixes[subType];
  const { modelsLocales: { ads: { rewardedVideos: { placements: { tooltips } } } } } = APP_DATA;

  switch (subType) {
    case 'PiggyBank':
      return (
        <Label text="Available Piggy Bank Quantity" tooltipText={tooltips.availableQuantity} required>
          <Field type="number" name="availableQuantity" />
        </Label>
      );
    case 'Energy':
      return (
        <>
          <Label text="Rewarded Energy Units" tooltipText={tooltips.rewardedUnits} required>
            <Field type="number" name="rewardedUnits" />
          </Label>
          <Label text="Energy Rewarded Video Limit" tooltipText={tooltips.videoLimit} required>
            <Field type="number" name="videoLimit" />
          </Label>
          <Label text="Energy Rewarded Video Limit Reset Time" tooltipText={tooltips.videoLimitResetHours} required>
            <Field name="videoLimitResetHours" type="number" appendText="hours" />
          </Label>
        </>
      );
    default:
      return (
        <>
          <Label text={`${labelPrefix} Rewarded Video Threshold`} tooltipText={tooltips.thresholdGems} required>
            <Field name="thresholdGems" type="number" appendText="gems" />
          </Label>
          <Label text={`${labelPrefix} Rewarded Video Threshold`} tooltipText={tooltips.thresholdCoins} required>
            <Field name="thresholdCoins" type="number" appendText="coins" />
          </Label>
          <Label text={`${labelPrefix} Probability`} tooltipText={tooltips.probability} required>
            <Field type="number" name="probability" />
          </Label>
          <Label text={`${labelPrefix} Rewarded Video Limit`} tooltipText={tooltips.videoLimit} required>
            <Field type="number" name="videoLimit" />
          </Label>
          <Label
            text={`${labelPrefix} Rewarded Video Limit Reset Time`}
            tooltipText={tooltips.videoLimitResetHours}
            required
          >
            <Field name="videoLimitResetHours" type="number" appendText="hours" />
          </Label>
        </>
      );
  }
};

export default function PlacementsForm({ data, onSubmit }) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const placementTypeOptions = rewardedVideoPlacementTypeOptions();

  return (
    <Form initialValues={data} onSubmit={onSubmit}>
      {({ values: { type } }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Rewarded Video Placement`}>
            <FormButtonsGroup cancelButtonPath={AdsRoutes.RewardedVideos.Placements.indexPath({ applicationId })} />
          </PageHeader>

          <Label text="Name" required>
            <Field type="text" name="name" />
          </Label>
          <Label text="Key" required>
            <Field type="text" name="key" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <>
            <Label text="Placement Type" required>
              <SelectField
                name="type"
                options={placementTypeOptions}
                isDisabled={data.id}
              />
            </Label>

            <hr />

            {type && renderConfigFields(type)}
          </>
        </>
      )}
    </Form>
  );
}
