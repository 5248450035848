/* eslint-disable react/forbid-elements */
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import APP_DATA from '@services/appData';

import { FormGroup, useConfirm } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import { Field, SelectField } from '@controls/form';
import { DndIcon, RemoveButton, PageSection } from '@pages/common';
import { ColumnTitle } from '@pages/metagame/chapter-based/chapter-sets/shared';

const COLUMN_PADDING_SIZE = 10;

const TriggerList = styled.div`
  margin-top: 15px;
`;

const AddNewTrigger = styled(Button)`
  margin-top: 10px;
`;

const Trigger = styled.div`
  align-items: flex-start;
  display: flex;
  padding: ${COLUMN_PADDING_SIZE}px 0 ${COLUMN_PADDING_SIZE}px ${COLUMN_PADDING_SIZE}px;
  margin: 0 -${COLUMN_PADDING_SIZE}px;
`;

const PositionColumn = styled.div`
  padding-left: ${COLUMN_PADDING_SIZE}px;
  width: 4%;
  font-weight: bold;
`;

const TriggerColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 30%;
`;

const BeforeColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 33%;
`;

const AfterColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 33%;
`;

const { modelsLocales: { merge: { worldSchemas: { postcardChange: tooltips } } }, enums } = APP_DATA;
const triggerTypes = enums['Merge::PostcardChangesTriggerTypes'];

export default function AdditionalPostcardChanges({
  dispatch, postcard, index,
}) {
  const storyOrderTriggers = [triggerTypes.ALL_ORDERS_COMPLETED, triggerTypes.SOME_ORDERS_COMPLETED];
  const tooltipTriggers = [triggerTypes.METAGAME_CHANGE];
  const triggerTypeOptions = fromClassNameToOptions('Merge::PostcardChangesTriggerTypes');
  const triggerNames = {
    [triggerTypes.ALL_ORDERS_COMPLETED]: 'Story Orders',
    [triggerTypes.SOME_ORDERS_COMPLETED]: 'Story Orders',
    [triggerTypes.METAGAME_CHANGE]: 'Metagame Change ID',
    [triggerTypes.CUTSCENE_END]: 'Cutscene ID',
  };
  const confirm = useConfirm();

  const onDragEnd = ({ source, destination }) => {
    dispatch({
      actionType: 'reorderPostcardChanges',
      path: `postcardsAttributes[${index}].postcardChangesAttributes`,
      sourceIndex: source.index,
      destinationIndex: destination.index,
    });
  };

  const addNewTrigger = () => dispatch({ actionType: 'addPostcardChange', postcardIndex: index });

  return (
    <>
      <p />
      <PageSection title="Additional Postcard Changes" />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-expert-orders">
          {(provided) => (
            <TriggerList ref={provided.innerRef} {...provided.droppableProps}>
              {postcard.postcardChangesAttributes.map((postcardChange, changeIndex) => {
                if (postcardChange._destroy) { return null; }
                const removeChange = () => {
                  confirm.showConfirmation({ title: 'Trigger and its content will be removed. Continue?' })
                    .then(() => dispatch({
                      actionType: 'removePostcardChange',
                      index: changeIndex,
                      postcardIndex: index,
                    }));
                };
                return (
                  <Draggable key={postcardChange._uuid} draggableId={postcardChange._uuid} index={changeIndex}>
                    {(draggableProvided) => (
                      <Trigger ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                        <DndIcon {...draggableProvided.dragHandleProps} />
                        <FormGroup name={`postcardChangesAttributes[${changeIndex}]`}>
                          <PositionColumn>{postcardChange.position}</PositionColumn>
                          <TriggerColumn>
                            <ColumnTitle>Trigger</ColumnTitle>
                            <SelectField
                              name="triggerType"
                              options={triggerTypeOptions}
                            />
                          </TriggerColumn>
                          <BeforeColumn>
                            <>
                              <ColumnTitle
                                tooltip={tooltipTriggers.indexOf(postcardChange.triggerType) > -1
                                  && tooltips.assetIdWarning}
                              >
                                {`After this: ${triggerNames[postcardChange.triggerType]}`}
                              </ColumnTitle>
                              {storyOrderTriggers.indexOf(postcardChange.triggerType) > -1 ? (
                                <SelectField
                                  name="storyOrders"
                                  isMulti
                                  options={postcard.storyOrdersOptions}
                                />
                              ) : (
                                <Field name="triggerInternalId" className="me-2" />
                              )}
                            </>
                          </BeforeColumn>
                          <AfterColumn>
                            <ColumnTitle tooltip={tooltips.assetIdWarning}>Do this: Metagame Change ID</ColumnTitle>
                            <Field name="resultInternalId" className="me-2" />
                          </AfterColumn>
                        </FormGroup>
                        <RemoveButton size="lg" onClick={removeChange} title="Delete the trigger" />
                      </Trigger>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </TriggerList>
          )}
        </Droppable>
      </DragDropContext>
      <AddNewTrigger onClick={addNewTrigger} variant="success">Add trigger</AddNewTrigger>
    </>
  );
}
