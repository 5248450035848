import React from 'react';
import { Card } from 'react-bootstrap';

import Form from './Form';

export default function TabContent() {
  return (
    <Card>
      <Card.Body className="pb-0">
        <Form />
      </Card.Body>
    </Card>
  );
}
