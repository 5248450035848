import React from 'react';
import { Card } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';
import { Form } from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup, ServerError, LabelsFormGroup,
} from '@controls/form';
import Tooltip from '@controls/Tooltip';
import { PageHeader, PageSection, DuplicateButton } from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';
import { rewardableReducer, combineReducers } from '@reducers';

import StreaksFormGroup from './streaks/StreaksFormGroup';
import reducer from './formReducer';

export default function AbilityImpactsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const disabled = isInUse(data.inUse);

  return (
    <Form
      initialValues={{ ...data, applicationId }}
      onSubmit={onSubmit}
      reducer={combineReducers([reducer, rewardableReducer])}
    >
      {({ values, dirty }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Magic Pot`}>
            <FormButtonsGroup
              cancelButtonPath={Routes.MagicPots.indexPath({ applicationId })}
            >
              <DuplicateButton
                entityName="Magic Pot"
                routes={Routes.MagicPots}
                data={data}
                disabled={dirty}
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label required text="Name">
            <Field name="name" />
          </Label>

          <StaticFieldsFormGroup data={data} />
          <LabelsFormGroup />
          {values.magicPotStreaksAttributes.map((streak, index) => (
            <Card className="mb-3" key={streak._uuid}>
              <Card.Body>
                <div className="d-flex mb-3">
                  <PageSection title={`Streak ${index + 1}`} />
                  <Tooltip text={`Streak ${index + 1} reward`} />
                </div>
                <StreaksFormGroup
                  streak={streak}
                  disabled={disabled}
                  position={index}
                />
              </Card.Body>
            </Card>
          ))}
          <ServerError name="magicPotStreaksAttributes.list" />
        </>
      )}
    </Form>
  );
}
