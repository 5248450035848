import React from 'react';
import { PageTitle } from '@pages/common';

import SettingsEdit from '@pages/settings/Edit';
import Profile from '@pages/admin-users/Profile';

import { AdminRoutes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function admin() {
  return [
    <Route
      key={AdminRoutes.settingsRawPath}
      path={AdminRoutes.settingsRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Settings" />
          <SettingsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdminRoutes.profileRawPath}
      path={AdminRoutes.profileRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Admin Profile" />
          <Profile />
        </RouteContainer>
      )}
    />,
  ];
}
