import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import { newProduct, createProduct } from '@requests/payments/products';

import Form from './Form';

export default function New() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const { applicationId } = query;

  useEffect(() => {
    newProduct(query).then((response) => setData(response.data));
  }, []);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Product',
    actionName: 'create',
    redirectPath: `/admin/applications/${applicationId}/products`,
    setData,
  });

  const onSubmit = (values) => createProduct({ ...values, applicationId }).then(responseHandler);

  return (
    data ? <Form data={data} onSubmit={onSubmit} /> : ''
  );
}
