import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import {
  Label, SelectField, TimePeriodField,
} from '@controls/form';
import { useFormContext, FormGroup } from '@hooks';

import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { pickRewardsConfigurations } from '@components/merge/services/RewardsConfigurations';
import Rewards from '@components/merge/shared/Rewards';
import { MergeRoutes } from '@pages/routes';
import BundleRules from './BundleRules';

const rewardsConfigurations = pickRewardsConfigurations([
  'Item', 'Coins', 'Gems', 'Energy', 'InventorySlot',
]);

export default function BundleProduct({ values, isNewRecord, productId }) {
  const [items, setItems] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [postcards, setPostcards] = useState([]);

  const { dispatch } = useFormContext();
  const { query } = useRouter();
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(query.applicationId), []);

  useEffect(() => {
    if (!values.schemaId) return;

    MergeRoutes.WorldSchemas.editRequest({
      id: values.schemaId,
      applicationId: query.applicationId,
    }).then((response) => {
      setItems(response.data.items.map((item) => ({ value: item.id, label: item.internalId, line: item.line })));
      setPostcards(response.data.postcards.map((item) => ({ value: item.id, label: item.internalId })));
    });
    MergeRoutes.Products.indexRequest({
      applicationId: query.applicationId, rewardType: 'bundle', withoutPagination: true,
    }).then((response) => {
      setBundles(
        response.data.items
          .filter((item) => item.id !== productId)
          .map((item) => ({ value: item.id, label: item.name })),
      );
    });
  }, [values.schemaId]);

  return (
    <>
      <Label text="World Schema">
        <SelectField
          name="schemaId"
          options={fetchSchemaOptions}
          onChange={() => dispatch({ type: 'initalize_bundle_product_value' })}
          isDisabled={!isNewRecord}
        />
      </Label>

      {values.schemaId && (
        <>
          <hr />
          <Label text="Duration">
            <TimePeriodField name="duration" type="number" inputs={['hours']} />
          </Label>
          <hr />
          <Row>
            <Col xs={4} className="pe-0">
              <b>Bundle Rewards</b>
            </Col>
            <Col xs={8}>
              <FormGroup name="rewardAttributes">
                <Rewards
                  values={values.rewardAttributes.rewardsAttributes}
                  items={items}
                  rewardsConfigurations={rewardsConfigurations}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <BundleRules values={values.rules} bundles={bundles} postcards={postcards} />
        </>
      )}
    </>
  );
}
