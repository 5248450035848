import React, { useState, useEffect } from 'react';

import { useCurrentApplication } from '@hooks';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { LabelRoutes } from '@pages/routes';
import ColoredBadge from '@pages/common/ColoredBadge';
import Label from './Label';
import SelectField from './SelectField';

const CustomLabel = ({ label, color }) => (
  <ColoredBadge pill={false} hexColor={color} text={label} style={{ padding: '3px 4px' }} />
);
const MultiValueLabel = ({ data }) => CustomLabel(data);

export default function LabelsFormGroup({
  name = 'labelIds', label = 'Labels', tooltip, fieldSize = 6, disabled, defaultApplicationId, direction = 'horizontal',
  isMulti = true, ...props
}) {
  const { currentApplication: { id: currentApplicationId } } = useCurrentApplication();
  const applicationId = defaultApplicationId || currentApplicationId;
  const [labelOptions, setLabelOptions] = useState([]);

  useEffect(() => {
    if (!applicationId) return;

    requestWithToSelectOptions(
      LabelRoutes.indexRequest,
      applicationId,
      { withoutPagination: true },
      {
        toSelectOptionsFn: (response) => (
          response.data.items.map(({ id: value, name: text, color }) => ({ value, color, label: text }))
        ),
      },
    ).then(setLabelOptions);
  }, [defaultApplicationId]);

  return (
    <Label text={label} fieldSize={fieldSize} tooltipText={tooltip} direction={direction}>
      <SelectField
        name={name}
        options={labelOptions}
        isDisabled={disabled}
        components={{ MultiValueLabel }}
        formatOptionLabel={CustomLabel}
        isMulti={isMulti}
        {...props}
      />
    </Label>
  );
}
