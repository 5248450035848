import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { useCurrentApplication, useQuery } from '@hooks';
import RawVersionToggle from '@pages/audit-logs/RawVersionToggle';
import editPath from '@services/editPath';
import { AuditRoutes } from '@pages/routes';
import { NON_ADMIN_AUTHORS } from './constants';

const ACTIONS = {
  create: 'created',
  update: 'updated',
  destroy: 'deleted',
  duplicate: 'duplicated', // makes sense for feature branches where there are no versions for most of the entities
};

const actionDescription = ({ version, application, fallbackApplication }) => {
  const source = version.duplicationSourceVersion;
  if (source) {
    return (
      <>
        {' was duplicated from '}
        <EntityName
          version={source}
          application={application}
          fallbackApplication={fallbackApplication}
        >
          <b>{source.displayedName || source.itemId}</b>
        </EntityName>
        {' by '}
      </>
    );
  }
  return ` was ${ACTIONS[version.event]} by `;
};

const CollapseButton = styled(FontAwesomeIcon)`
  margin-top: 5px;
  margin-left: 5px;
`;

const presentUser = (user) => {
  if (user.email) return (user.email);
  if (user.id === NON_ADMIN_AUTHORS.SYSTEM) return 'System';
  if (user.id === NON_ADMIN_AUTHORS.UNKNOWN) {
    return <span className="text-danger">Author is unknown! Notify server team!</span>;
  }

  return `User #${user.id}`;
};

const VersionDetails = ({ children, version }) => {
  const { response } = useQuery(
    (data) => AuditRoutes.Versions.showRequest({ ...data, id: version.id }, { skipUuids: true }),
  );
  if (!response) return null;

  const { versionTree } = response;
  return (
    <RawVersionToggle version={versionTree}>
      {children({ versionTree })}
    </RawVersionToggle>
  );
};

const NoApplicationSpan = ({ withNoApplicationRelation }) => (
  !withNoApplicationRelation && (<span className="text-danger">Application is not set! Notify server team!</span>)
);

const entityPath = ({ version, application, fallbackApplication }) => {
  const {
    applicationId, itemSubtype, withNoApplicationRelation, object, objectChanges, frontendIdentity,
  } = version;
  if (!applicationId && !withNoApplicationRelation) { return null; }
  if (itemSubtype === 'Merge::UnbubblingConfiguration') {
    const { schemaType } = object || (objectChanges && objectChanges[1]) || {};
    if (schemaType === 'Merge::Events::Schema') { return null; }
  }

  const routeApplication = application || fallbackApplication;

  return editPath({ application: routeApplication, ...frontendIdentity });
};

const EntityName = ({
  children, version, application, fallbackApplication,
}) => {
  const path = entityPath({ version, application, fallbackApplication });
  return (path && !version.itemRemoved ? <Link target="_blank" to={path}>{children}</Link> : children);
};

export default function VersionRow({
  applications, children, entity, version, displayedTypeName,
}) {
  const [open, setOpen] = useState(false);
  const { currentApplication } = useCurrentApplication();
  const {
    applicationId, id, createdAt, message,
  } = version;
  const collapseId = `collapse-${id}`;
  const application = applications.find(({ value }) => applicationId === value);
  const fallbackApplication = currentApplication || applications[0];
  const { withNoApplicationRelation } = version;
  const applicationLabel = application && application.label;
  let entityName = entity || displayedTypeName;
  if (applicationLabel) entityName = entityName.replace(`[${applicationLabel}] `, '');

  return (
    <div>
      <div>
        {`${createdAt}: `}
        <EntityName
          version={version}
          application={application}
          fallbackApplication={fallbackApplication}
        >
          <b>
            {applicationLabel || (
              <NoApplicationSpan withNoApplicationRelation={withNoApplicationRelation} />
            )}
            {`: ${entityName} "${version.displayedName || version.itemId}"`}
          </b>
        </EntityName>
        {actionDescription({ version, application, fallbackApplication })}
        {presentUser(version.adminUser)}
        {message && ` (${message})`}
        {children && (
          /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
          <span
            onClick={() => setOpen(!open)}
            aria-controls={collapseId}
            aria-expanded={open}
          >
            <CollapseButton icon={open ? faChevronUp : faChevronDown} size="sm" />
          </span>
        )}
      </div>
      {children && (
        <Collapse in={open}>
          <div id={collapseId} className="pt-2">
            {open && (
              <VersionDetails version={version}>
                {children}
              </VersionDetails>
            )}
          </div>
        </Collapse>
      )}
    </div>
  );
}
