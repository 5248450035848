import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useCurrentApplication, useQuery } from '@hooks';
import { NamedRuleGroupsRoutes } from '@pages/routes';
import { fromClassNameToOptions } from '@services/enums';
import APP_DATA from '@services/appData';
import FilterField from './FilterField';
import SelectFilter from './SelectFilter';

const { enums: { 'Filters::Inclusion': FiltersInclusion } } = APP_DATA;
const OPERATIONS = fromClassNameToOptions('Filters::Inclusion', { transformLabel: (label) => label.toUpperCase() });
const OPERATIONS_MAP = OPERATIONS.reduce((acc, operation) => {
  acc[operation.value] = operation;

  return acc;
}, {});

const FieldComponent = ({ name, fieldValue, updateFilterField }) => {
  const [operation, setOperation] = useState(() => fieldValue.operation || FiltersInclusion.IN);
  const { applicationId } = useCurrentApplication();
  const { response: namedRuleGroupsOptions } = useQuery({
    request: () => NamedRuleGroupsRoutes.indexRequest({ applicationId }),
    queryKey: ['namedRuleGroups', applicationId],
    toSelectOptions: true,
  });

  const updateFilterValue = (newFilter, setAsDefault = false) => {
    if (!newFilter.ids || newFilter.ids.length > 0) {
      const updateValue = { operation, ...fieldValue, ...newFilter };

      updateFilterField({ [name]: updateValue }, setAsDefault);
    } else {
      updateFilterField({ [name]: null }, setAsDefault);
    }
  };

  const updateOperation = (newFilter, setAsDefault = false) => {
    setOperation(newFilter.operation);

    if (fieldValue.ids?.length > 0) {
      updateFilterValue(newFilter, setAsDefault);
    }
  };

  return (
    <Row>
      <Col md={3} className="pe-0">
        <SelectFilter
          name="operation"
          isMulti={false}
          value={OPERATIONS_MAP[operation]}
          options={OPERATIONS}
          updateFilterField={updateOperation}
        />
      </Col>
      <Col md>
        <SelectFilter
          name="ids"
          stateFieldValue={fieldValue.ids}
          updateFilterField={updateFilterValue}
          options={namedRuleGroupsOptions}
        />
      </Col>
    </Row>
  );
};

const NamedRuleGroupFilter = ({ updateFilterField }) => (
  <Col md={6}>
    <FilterField
      name="byNamedRuleGroups"
      label="Named Rule Groups"
      canAdd={({ currentValue }) => currentValue}
      hideButton
      updateFilterField={updateFilterField}
      FieldComponent={FieldComponent}
    />
  </Col>
);

export default NamedRuleGroupFilter;
