import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRouter } from '@tripledotstudios/react-core';

import { AutoCollapse } from '@pages/common/PartialCollapse';

export default function UsedExperiments({ usedProperties = [] }) {
  const { query } = useRouter();

  return (
    usedProperties.length > 0
      ? (
        <Row className="mb-3">
          <Col xs={{ span: 4, offset: 8 }}>
            <>
              <div>
                Tested in
                <strong>{` ${usedProperties.length} `}</strong>
                experiments:
              </div>
              <AutoCollapse minHeight={110}>
                {usedProperties.map(
                  (property) => (
                    <div key={property.id}>
                      <Link
                        to={`/admin/applications/${query.applicationId}/ab_testing/experiments/${property.id}/edit`}
                      >
                        {`${property.name} (${property.statusHumanize})`}
                      </Link>
                    </div>
                  ),
                )}
              </AutoCollapse>
            </>
          </Col>
        </Row>
      ) : ''
  );
}
