import {
  last, cloneDeep, isPlainObject, sortBy, get, set, max,
} from 'lodash';
import reorderMeta from '@services/reorderMeta';
import { addUuid } from '@services/recursivelyProcessObject';
import removeNestedIds from '@services/removeNestedIds';
import reorderEntities from '@services/reorderEntities';
import removeEntity from '@services/removeEntity';

const CHALLENGES_PATH = 'challengesAttributes';

const reducer = (state, action) => {
  const challengesAttributes = get(state, CHALLENGES_PATH);
  const setChallenges = (value) => set(state, CHALLENGES_PATH, value);

  switch (action.actionType) {
    case 'removeEntity': {
      const { _uuid: uuidToDelete } = action;
      removeEntity(state, CHALLENGES_PATH, uuidToDelete, 'number');

      break;
    }

    case 'bulkRemoveEntities': {
      const { uuids } = action;
      const challenges = cloneDeep(challengesAttributes);

      challenges.forEach((challenge) => {
        if (uuids.includes(challenge._uuid)) challenge._destroy = true;
      });

      setChallenges(reorderEntities(challenges, 'number').result);

      break;
    }

    case 'restoreEntity': {
      const { _uuid: uuidToDelete } = action;
      const challenges = cloneDeep(challengesAttributes);
      delete challenges.find(({ _uuid }) => _uuid === uuidToDelete)._destroy;

      setChallenges(reorderEntities(challenges, 'number').result);

      break;
    }

    case 'moveEntity': {
      const {
        sourceIndex,
        destinationIndex,
        meta,
        metaErrorsPath,
      } = action;

      const challenges = cloneDeep(challengesAttributes);

      if (challengesAttributes.length <= 1) break;

      const { result, changeSet: [fromIndices, toIndices] } = reorderEntities(
        challenges,
        'number',
        sourceIndex,
        destinationIndex,
      );

      setChallenges(result);

      if (meta.errors && isPlainObject(get(meta, metaErrorsPath))) {
        meta.errors = { ...meta.errors, ...reorderMeta(meta, fromIndices, toIndices, metaErrorsPath).errors };
      }

      break;
    }

    case 'applyPositions': {
      const { uuidToPositionMapping } = action;

      challengesAttributes.forEach((challenge) => {
        challenge.number = uuidToPositionMapping[challenge._uuid];
      });

      setChallenges(sortBy(challengesAttributes, 'number'));

      break;
    }

    case 'duplicateEntity': {
      const { _uuid: originalUuid } = action;
      const {
        id, _uuid, ...originalChallenge
      } = challengesAttributes.find((challenge) => challenge._uuid === originalUuid);

      const lastChallenge = last(challengesAttributes.filter(({ _destroy }) => !_destroy));

      const duplicatedChallenge = removeNestedIds(cloneDeep(originalChallenge));
      challengesAttributes.push(addUuid({ ...duplicatedChallenge, _destroy: false, number: lastChallenge.number + 1 }));

      break;
    }

    case 'addEmptyEntity': {
      const { options = {}, position } = action;

      const lastChallenge = last(challengesAttributes.filter(({ _destroy }) => !_destroy));
      const challenge = addUuid({
        ...options,
        _destroy: false,
        id: null,
        number: lastChallenge ? lastChallenge.number + 1 : 1,
        levelManifestType: null,
        challengeType: null,
        challengeParameter: [],
        ranksAttributes: [addUuid({ _destroy: false, rewardAffiliationsAttributes: [], number: 1 })],
        creativeSettings: {
          assetKey: null,
        },
      });

      if (position && position === 'start') {
        state.challengesAttributes = reorderEntities([challenge, ...challengesAttributes]).result;
        break;
      }

      challengesAttributes.push(challenge);

      break;
    }

    case 'cleanChallenges': {
      setChallenges([]);

      break;
    }

    case 'addRank': {
      const { challengeUuid } = action;

      const challenge = challengesAttributes.find(({ _uuid }) => _uuid === challengeUuid);
      const maxRank = max(
        challenge.ranksAttributes.filter(({ _destroy }) => !_destroy).map(({ number }) => number),
      ) || 0;

      challenge.ranksAttributes.push(
        addUuid({ number: maxRank + 1, rewardAffiliationsAttributes: [], _destroy: false }),
      );

      break;
    }

    case 'removeRank': {
      const { uuid, challengeUuid } = action;

      const challenge = challengesAttributes.find(({ _uuid }) => _uuid === challengeUuid);
      challenge.ranksAttributes.find(({ _uuid }) => _uuid === uuid)._destroy = true;

      challenge.ranksAttributes = reorderEntities(challenge.ranksAttributes, 'number').result;

      break;
    }

    default: break;
  }

  return state;
};

export default reducer;
