import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { flatMap, flattenDeep, map } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { SelectField, Field } from '@controls/form';
import { MergeRoutes } from '@pages/routes';
import RewardsSummary from '@components/merge/shared/rewards/Summary';

import { presentLinesRequired, presentUnlockedExperts } from '@components/merge/services/postcardSummary';
import IconButton from '@controls/buttons';

const EMPTY_POSTCARD_OPTION = { label: 'Not configured', value: null };

export default function Postcard({
  index,
  postcardSetPostcard,
  postcardConfigurations,
  items,
  experts,
  handleViewPostcard,
  setPostcardConfiguration,
  postcardConfigurationsIndex,
}) {
  const { query: { applicationId } } = useRouter();

  const { postcardId } = postcardSetPostcard;
  const postcardConfiguration = postcardConfigurationsIndex[postcardSetPostcard.position];

  const expertsOptions = experts.map((expert) => ({ value: expert.id, label: expert.internalId }));
  const itemsOptions = items.map((item) => ({ value: item.id, label: item.internalId, line: item.line }));

  const postcardsOptions = postcardConfigurations
    .filter(({ schemaPostcardId }) => schemaPostcardId === postcardSetPostcard.schemaPostcardId)
    .map((config) => ({ value: config.id, label: config.name }));

  const renderPostcardLink = () => (
    postcardId ? (
      <Link
        to={MergeRoutes.PostcardConfigurations.editPath({ id: postcardId, applicationId })}
        target="_blank"
      >
        Link
      </Link>
    ) : ''
  );

  useEffect(() => {
    if (!postcardId) {
      setPostcardConfiguration(postcardSetPostcard.position, null);
    } else {
      MergeRoutes.PostcardConfigurations.editRequest({
        id: postcardId,
        applicationId,
      }).then((response) => {
        setPostcardConfiguration(postcardSetPostcard.position, response.data);
      });
    }
  }, [postcardId]);

  return (
    <tr>
      <td>
        {postcardSetPostcard.position}
      </td>
      <td>
        {postcardSetPostcard.internalId}
      </td>
      <td>
        <Row className="pe-3">
          <Col xs={10}>
            <SelectField
              name="postcardId"
              options={[EMPTY_POSTCARD_OPTION, ...postcardsOptions]}
              placeholder={EMPTY_POSTCARD_OPTION.label}
            />
          </Col>
          <Col xs={2} className="ps-0">
            {renderPostcardLink()}
          </Col>
        </Row>
      </td>
      <td>
        <ul className="list-unstyled">
          {presentUnlockedExperts(postcardConfiguration, experts).map((expert) => <li key={expert}>{expert}</li>)}
        </ul>
      </td>
      <td>
        <ul className="list-unstyled">
          {presentLinesRequired(postcardConfiguration, items).map((line) => <li key={line}>{line}</li>)}
        </ul>
      </td>
      <td>
        {postcardConfiguration && (
          <RewardsSummary
            rewardAttributes={flattenDeep(
              [
                postcardConfiguration.rewardsAttributes,
                map(flatMap(postcardConfiguration.storyOrdersAttributes, 'tasksAttributes'), 'rewardsAttributes'),
              ],
            )}
            itemOptions={itemsOptions}
            expertOptions={expertsOptions}
          />
        )}
      </td>
      <td><Field type="number" name="unlockLevel" readOnly={index === 0} /></td>
      <td>
        <IconButton.Edit onClick={() => handleViewPostcard(postcardSetPostcard.schemaPostcardId)} readonly minimized />
      </td>
    </tr>
  );
}
