import React, { useState } from 'react';
import { Spinner } from '@tripledotstudios/react-core';
import {
  faSpinner,
  faXmark,
  faCheck,
  faPlusMinus,
} from '@fortawesome/free-solid-svg-icons';

import { useQuery } from '@hooks';
import { AppUserRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import APP_DATA from '@services/appData';

import AnonymisationStatusModal from './AnonymisationStatusModal';

const ANONYMISATION_STATUS_BUTTONS_CONFIGURATIONS = {
  inProgress: {
    icon: faSpinner,
    label: 'In Progress',
    variant: 'warning',
  },
  completedSuccessfully: {
    icon: faCheck,
    label: 'Completed',
    variant: 'success',
  },
  completedWithErrors: {
    icon: faPlusMinus,
    label: 'Completed with errors',
    variant: 'warning',
  },
  failed: {
    icon: faXmark,
    label: 'Failed',
    variant: 'danger',
  },
};

export default function AnonymisationStatus({ user }) {
  const { enums } = APP_DATA;
  const notificationStatus = enums['Accounts::Anonymisation::Notification::Statuses'];

  const [showAnonymisationStatusModal, setShowAnonymisationStatusModal] = useState(false);

  const { response, isLoading } = useQuery(
    AppUserRoutes.anonymisationNotificationStatusesRequest, { id: user.id },
  );

  if (isLoading) return <Spinner width={10} height={10} />;

  const buildButtonConfiguration = (statuses) => {
    if (statuses.every((status) => status.status === notificationStatus.PROCESSED)) {
      return ANONYMISATION_STATUS_BUTTONS_CONFIGURATIONS.completedSuccessfully;
    }

    if (statuses.every((status) => status.status === notificationStatus.FAILED)) {
      return ANONYMISATION_STATUS_BUTTONS_CONFIGURATIONS.failed;
    }

    if (statuses.some((status) => (
      [notificationStatus.PROCESSING, notificationStatus.SCHEDULED].includes(status.status)
    ))) {
      return ANONYMISATION_STATUS_BUTTONS_CONFIGURATIONS.inProgress;
    }

    return ANONYMISATION_STATUS_BUTTONS_CONFIGURATIONS.completedWithErrors;
  };
  const buttonConfiguration = response && buildButtonConfiguration(response.anonymisationNotificationStatuses);

  return buttonConfiguration && (
    <>
      <IconButton
        icon={buttonConfiguration.icon}
        variant={buttonConfiguration.variant}
        onClick={() => setShowAnonymisationStatusModal(true)}
        size="sm"
        outline
      >
        {buttonConfiguration.label}
      </IconButton>

      <AnonymisationStatusModal
        statuses={response.anonymisationNotificationStatuses}
        show={showAnonymisationStatusModal}
        setShow={setShowAnonymisationStatusModal}
      />
    </>
  );
}
