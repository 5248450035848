import styled from 'styled-components';
import Field from './Field';

const Checkbox = styled(Field).attrs({ type: 'checkbox', formControlClass: false })`
  height: auto;
  display: inline-block;
  margin-right: 5px;
  margin-top: 0.75rem;
`;

export default Checkbox;
