import { wrapReducer } from '@reducers';

const reducer = (state, action) => {
  const { type } = action;

  if (type === 'clearDefaultLabels') {
    const defaultFiltersAttributes = { ...(state.defaultFiltersAttributes || {}), labelIds: [] };

    state.defaultFiltersAttributes = defaultFiltersAttributes;
    return state;
  }

  return state;
};

export default wrapReducer(reducer);
