import React from 'react';

import {
  GameSettingsTabs,
  localizationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  analyticsDefaultFieldNames,
  offlineGamesSettingsDefaultFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
  adRequestDelayFieldNames,
} from '@components/game-settings/Resource';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'disappearingBottomUiEnabled',
      'analyticsUseBinaryQueue',
      'uiForOldUsers',
      'gameScreenBackButton',
      'deckRatingsEnabled',
      'cardAttachMoveTime',
      'defaultCardBack',
      'defaultCardFront',
      'defaultTableBackground',
      ...offlineGamesSettingsDefaultFieldNames,
      ...assetsDefaultFieldNames,
      {
        eventKey: '#client_settings_menu',
        title: 'Client Settings Menu',
        fieldsNames: [
          'hideThreeWasteCardsEnabled',
          'hideEnableUndoEnabled',
          'hideEnableTimerEnabled',
          'hideLeftHandModeEnabled',
          'hideVegasScoringEnabled',
          'hideVegasCumulativeEnabled',
          'hideWinAnimationEnabled',
          'hideDealThreeEnabled',
          'hideMyStatsEnabled',
        ],
      },
    ],
  },
  {
    eventKey: '#onboarding',
    title: 'Onboarding',
    fieldsNames: [
      'tutorialType',
    ],
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      ...adRequestDelayFieldNames,
      'adsUseFairbidMediation',
      ...adPlacementIdsDefaultFieldNames,
      ...adConfigsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      'pushNotificationsSettingEnabled',
      'dailyChallengePushNotificationsConfiguration',
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: [
      ...popupsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#journey',
    title: 'Journey',
    fieldsNames: [
      'journeyConfiguration',
      'journeyClassicModeHints',
      'journeyClassicModeUndos',
      'journeyClassicModeShuffles',
      'journeyExpressModeHints',
      'journeyExpressModeUndos',
      'journeyExpressModeShuffles',
      'journeyTrialModeHints',
      'journeyTrialModeUndos',
      'journeyTrialModeShuffles',
      'journeyChallengeModeHints',
      'journeyChallengeModeUndos',
      'journeyChallengeModeShuffles',
      'journeyRiddleModeHints',
      'journeyRiddleModeUndos',
      'journeyRiddleModeShuffles',
      'journeyHelperAnalyticsEvents',
      'journeyGameSelectThemedButtonEnabled',
      'journeyStockpileCycleModeHints',
      'journeyStockpileCycleModeUndos',
      'journeyStockpileCycleModeShuffles',
      'journeyLobbyThemedButtonEnabled',
      'newJourneyAvailablePopupToggle',
      'journeyCustomisationOptionsEnabled',
      'challengeModeAnimationConfiguration',
      'newJourneyAvailableFirstTimeExperiencePopupEnabled',
      'replayJourneyLevelsEnabled',
    ],
  },
  {
    eventKey: '#debug',
    title: 'Debug',
    fieldsNames: [],
  },
  {
    eventKey: '#hints',
    title: 'Hints',
    fieldsNames: [
      'hintLockEnabled',
      'hintsConfiguration',
      'hintsAnimationConfiguration',
    ],
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: analyticsDefaultFieldNames,
  },
  {
    eventKey: '#sounds',
    title: 'Sounds',
    fieldsNames: [
      'foundationMoveSound',
      'invalidMoveSound',
    ],
  },
  {
    eventKey: '#daily_challenge',
    title: 'Daily Challenge',
    fieldsNames: ['dailyChallengeTrophiesSettings'],
  },
  {
    eventKey: '#power_ups',
    title: 'Power-ups',
    fieldsNames: [],
  },
  {
    eventKey: '#user_xp',
    title: 'User XP',
    fieldsNames: [],
  },
  {
    eventKey: '#puzzle_collection_events',
    title: 'Puzzle Collection Events',
    fieldsNames: [],
  },
  {
    eventKey: '#daily_rewards',
    title: 'Daily Reward',
    fieldsNames: [],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: popupsDefaultArchivedFieldNames,
  },
  {
    eventKey: '#boosters',
    title: 'Boosters',
    fieldsNames: [],
  },
  {
    eventKey: '#haptic_feedback',
    title: 'Haptic Feedback',
    fieldsNames: [],
  },
];

export default function Tabs({
  setContractType, fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      onChange={setContractType}
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
