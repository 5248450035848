/* eslint-disable gs/no-space-literal */
import React from 'react';
import { omit, capitalize } from 'lodash';

import { AutoCollapse } from '@pages/common/PartialCollapse';

const presentedProductContent = (content) => (
  Object.keys(omit(content, '_uuid')).map((key) => (
    content[key] ? (
      <div className="mb-1">
        <b>
          {`${capitalize(key)}: `}
        </b>
        {content[key]}
      </div>
    ) : ''
  ))
);

export default function ProductDetails({ product }) {
  return (product ? (
    <AutoCollapse minHeight={107}>
      <div className="mb-1">
        <b>Price: </b>
        <span className="me-2">{product.priceType}</span>
        {product.priceValue}
      </div>
      {product.storeProductName && (
        <div className="mb-1">
          <b>Store Product: </b>
          {product.storeProductName}
        </div>
      )}
      <div className="mb-1">
        {presentedProductContent(product.content)}
      </div>
    </AutoCollapse>
  ) : '');
}
