import { useRouter } from '@tripledotstudios/react-core';
import React from 'react';
import { useQuery } from '@hooks';

import { formResponseHandler } from '@requests/responseHandlers';

import { WoodokuRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const { response, setResponse } = useQuery(Routes.DynamicConfigs.newRequest);
  const { query: { applicationId } } = useRouter();
  const responseHandler = formResponseHandler({
    entityName: 'Dynamic Config',
    actionName: 'creation',
    setData: setResponse,
  });
  const onSubmit = (values) => Routes.DynamicConfigs.createRequest({ ...values, applicationId }).then(responseHandler);

  return response && <Form data={response} onSubmit={onSubmit} actionName="Create" />;
}
