import React from 'react';
import styled from 'styled-components';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DndContainer = styled.div`
  position: relative;
  top: 2em;
  left: -2em;
  display: inline-block;

  ${({ disabled }) => disabled && 'color: #BBB;'}
`;

const FaIcon = ({ iconProps }) => (
  <FontAwesomeIcon icon={faExchangeAlt} {...iconProps} />
);

export default function DndIcon({ iconProps, wrap = true, ...props }) {
  return (
    wrap ? <DndContainer {...props}><FaIcon iconProps={iconProps} /></DndContainer> : <FaIcon iconProps={iconProps} />
  );
}
