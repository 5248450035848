import React from 'react';

import APP_DATA from '@services/appData';

import Tooltip from '@controls/Tooltip';

const { modelsLocales: { shared: { tooltips: { availabilityState: availabilityStateTooltip } } } } = APP_DATA;

export default function InLiveColumnHeader({ placement }) {
  return (
    <Tooltip placement={placement} text={availabilityStateTooltip}>
      Availability
    </Tooltip>
  );
}
