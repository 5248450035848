import React from 'react';
import { PageTitle } from '@pages/common';

import PlayerLevelsSetsIndex from '@pages/sol/player-levels/Index';
import PlayerLevelsSetsNew from '@pages/sol/player-levels/New';
import PlayerLevelsSetsEdit from '@pages/sol/player-levels/Edit';

import WinSetsIndex from '@pages/sol/win-sets/Index';
import WinSetsNew from '@pages/sol/win-sets/New';
import WinSetsEdit from '@pages/sol/win-sets/Edit';

import FirstTryStreakConfigsIndex from '@root/pages/sol/first-try-streak-configs/Index';
import FirstTryStreakConfigsNew from '@root/pages/sol/first-try-streak-configs/New';
import FirstTryStreakConfigsEdit from '@root/pages/sol/first-try-streak-configs/Edit';

import CategorySetsIndex from '@pages/solitaire/category-sets/Index';
import CategorySetsEdit from '@pages/solitaire/category-sets/Edit';
import CategorySetsNew from '@pages/solitaire/category-sets/New';

import { SolRoutes as Routes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function sol() {
  return [
    <Route
      key={Routes.PlayerLevelSets.indexRawPath}
      path={Routes.PlayerLevelSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Player Level Sets" />
          <PlayerLevelsSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.PlayerLevelSets.newRawPath}
      path={Routes.PlayerLevelSets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="New Player Level Set" />
          <PlayerLevelsSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.PlayerLevelSets.editRawPath}
      path={Routes.PlayerLevelSets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Player Level Set" />
          <PlayerLevelsSetsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.WinSets.indexRawPath}
      path={Routes.WinSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Xth Win Sets" />
          <WinSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.WinSets.newRawPath}
      path={Routes.WinSets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="New Xth Win Set" />
          <WinSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.WinSets.editRawPath}
      path={Routes.WinSets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Xth Win Set" />
          <WinSetsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.FirstTryStreakConfigs.indexRawPath}
      path={Routes.FirstTryStreakConfigs.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="First Try Streak Configs" />
          <FirstTryStreakConfigsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.FirstTryStreakConfigs.newRawPath}
      path={Routes.FirstTryStreakConfigs.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="First Try Streak Configs" />
          <FirstTryStreakConfigsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.FirstTryStreakConfigs.editRawPath}
      path={Routes.FirstTryStreakConfigs.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="First Try Streak Configs" />
          <FirstTryStreakConfigsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.CategorySets.indexRawPath}
      path={Routes.CategorySets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Category Sets" />
          <CategorySetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.CategorySets.newRawPath}
      path={Routes.CategorySets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Category Set" action="New" />
          <CategorySetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.CategorySets.editRawPath}
      path={Routes.CategorySets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Category Set" action="Edit" />
          <CategorySetsEdit />
        </RouteContainer>
      )}
    />,
  ];
}
