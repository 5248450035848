import React, { useContext } from 'react';
import {
  Accordion, Row, Col, useAccordionButton,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isEqual } from 'lodash';
import AccordionContext from 'react-bootstrap/AccordionContext';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { SelectField, Label, ServerError } from '@controls/form';

import { FormGroup } from '@hooks';
import { Rewards } from '@pages/common';
import OverlayTooltip from '@controls/OverlayTooltip';

import APP_DATA from '@services/appData';

const SegmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const Segment = styled.div`
  ${({ order }) => `order: ${order};`}
  ${({ flexBasis }) => `flex-basis: ${flexBasis};`}

  margin: .5em;
  border: 1px solid gray;
  border-radius: .2em;
  flex-grow: 1;
  padding: 1em;
  position: relative;
`;

const FullWidthAccordion = styled(Accordion)`
  flex-grow: 1;
`;

const LevelLayoutLink = styled(Link)`
  position: absolute;
  top: .35em;
  right: .5em;
`;

const EnabledFaIcon = styled(FontAwesomeIcon)`
  pointer-events: initial;
`;

const AccordionButton = ({ eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <EnabledFaIcon
      size="lg"
      icon={faChevronDown}
      style={isCurrentEventKey && { transform: 'rotate(180deg)' }}
      onClick={decoratedOnClick}
    />
  );
};

const calculateSegmentSizeBasis = (position, segmentsSize) => {
  // In case if layout has less than 5 segments
  // we need to render them as a square (2 segments per row OR 1 full-width segment if the number is odd)
  if (segmentsSize < 5) return '48%';

  // When layout has 6 segments
  // we need to render it a bit differently - (3 segments on a row - 2 segment on a row - 1 segment on a row)
  // to do that we pass 48% as flex basis for one segment from the second line
  if (segmentsSize === 6 && position === 5) return '48%';

  // In that case we need to render 2 segments - 3 segments - 2 segmets per row
  // in order to reach that we pass 48% as flex basis for one element from first row and one element from the last one
  // all the remaining segments having 32% as basis
  if (segmentsSize === 7 && (position <= 1 || position >= 5)) return '48%';

  return '32%';
};

const PictureSegments = React.memo(({
  dispatch, levelLayouts, modeConfigs, picture, rewardItems, LevelLayoutRoutes, applicationId,
}) => {
  const tileTypes = APP_DATA.enums['Tile::TileTypes'];
  const tileTypesSupportingMode = [tileTypes.GOALS, tileTypes.TELEPORTING];

  const pictureSegments = picture.pictureSegmentsAttributes || [];
  const levelLayoutsOptions = levelLayouts.map(({ name, position, id }) => ({
    label: `${name} (${position})`, value: id,
  }));

  const selectedLayoutIds = pictureSegments.map(({ levelLayoutId }) => levelLayoutId);
  const selectedLevelLayouts = levelLayouts.filter(({ id }) => selectedLayoutIds.includes(id));

  const renderGlobalSchemaSelect = (type) => selectedLevelLayouts.some(({ tileType }) => tileType === type);
  const filterModesConfigsOptions = (type) => modeConfigs.filter(({ tileType }) => tileType === type);

  const notDestroyedPictureSegments = pictureSegments.filter(({ _destroy }) => !_destroy);

  return (
    <div>
      {pictureSegments.length > 0 && (
        <>
          <ServerError name="segmentList" />
          <FullWidthAccordion defaultActiveKey={picture.populated && '0'}>
            <AccordionButton eventKey="0" />
            <hr />
            <Accordion.Collapse eventKey="0">
              <>
                <Row>
                  {(renderGlobalSchemaSelect(tileTypes.GOALS)) && (
                    <Col xs={3}>
                      <Label text="Global Goals Schema" direction="vertical">
                        <SelectField
                          name="goalsModeConfigId"
                          options={filterModesConfigsOptions(tileTypes.GOALS)}
                          includeEmpty
                        />
                      </Label>
                    </Col>
                  )}

                  {(renderGlobalSchemaSelect(tileTypes.TELEPORTING)) && (
                    <Col xs={3}>
                      <Label text="Global Teleporting Schema" direction="vertical">
                        <SelectField
                          name="teleportingModeConfigId"
                          options={filterModesConfigsOptions(tileTypes.TELEPORTING)}
                          includeEmpty
                        />
                      </Label>
                    </Col>
                  )}
                </Row>

                <SegmentsContainer>
                  {pictureSegments.map((segment, segmentIndex) => {
                    const {
                      levelLayoutId,
                      position,
                      _destroy,
                      _uuid,
                    } = segment;

                    const formGroupName = `pictureSegmentsAttributes[${segmentIndex}]`;

                    const levelLayout = levelLayouts.find(({ id }) => levelLayoutId === id) || {};
                    const supportsModes = tileTypesSupportingMode.includes(levelLayout.tileType);
                    const modesConfigsOptions = filterModesConfigsOptions(levelLayout.tileType);

                    return !_destroy && (
                      <Segment
                        order={position}
                        flexBasis={calculateSegmentSizeBasis(position - 1, notDestroyedPictureSegments.length)}
                        key={_uuid}
                      >
                        <FormGroup name={formGroupName}>
                          <Label
                            text="Layout"
                            direction="vertical"
                          >
                            <SelectField
                              name="levelLayoutId"
                              options={levelLayoutsOptions}
                              onChange={() => dispatch({
                                actionType: 'changeLevelLayout',
                                uuid: picture._uuid,
                                segmentIndex,
                              })}
                            />
                          </Label>
                          {levelLayoutId && (
                            <LevelLayoutLink
                              to={LevelLayoutRoutes.editPath({ applicationId, id: levelLayoutId })}
                              target="_blank"
                            >
                              <OverlayTooltip text="See layout page in separate tab" placement="top">
                                <FontAwesomeIcon size="lg" icon={faExternalLinkSquareAlt} />
                              </OverlayTooltip>
                            </LevelLayoutLink>
                          )}
                          {levelLayoutId && (
                            <Row>
                              <Col>
                                <Label text="Layout hash" direction="vertical">
                                  {levelLayout.contentHash}
                                </Label>
                              </Col>
                              <Col>
                                <Label text="Layout type" direction="vertical">
                                  {levelLayout.tileTypeHumanize}
                                </Label>
                              </Col>
                            </Row>
                          )}
                          {(supportsModes) && (
                            <Label text="Mode config" direction="vertical">
                              <SelectField
                                name="modeConfigId"
                                options={modesConfigsOptions}
                                includeEmpty
                              />
                            </Label>
                          )}
                          <Label text="Rewards" direction="vertical">
                            <Rewards
                              dispatch={dispatch}
                              rewardable={segment}
                              rewardItems={rewardItems}
                            />
                          </Label>
                        </FormGroup>
                      </Segment>
                    );
                  })}
                </SegmentsContainer>
              </>
            </Accordion.Collapse>
          </FullWidthAccordion>
        </>
      )}
    </div>
  );
}, (prev, next) => (
  isEqual(prev.picture.pictureSegmentsAttributes, next.picture.pictureSegmentsAttributes)
    && isEqual(prev.modeConfigs, next.modeConfigs) && isEqual(prev.rewardItems, next.rewardItems)
    && isEqual(prev.levelLayouts, next.levelLayouts)
));

export default PictureSegments;
