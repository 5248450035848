import React from 'react';
import { Card } from 'react-bootstrap';

import { PageSection } from '@pages/common';

import { SelectField, Field, Label } from '@controls/form';

export default function ExpressLevelConfig({ level: { limitType }, limitTypeOptions, cardDenominationOptions }) {
  return (
    <Card body key="express">
      <PageSection title="Express" />

      <Label text="Limit type">
        <SelectField name="limitType" options={limitTypeOptions} />
      </Label>
      {limitType > 0 && (
        <Label text="Limit value">
          <Field name="limitValue" type="number" />
        </Label>
      )}
      <Label text="Max card">
        <SelectField name="maxCard" options={cardDenominationOptions} />
      </Label>
      <Label text="Reduce stacks by">
        <Field name="reduceStacksBy" type="number" />
      </Label>
    </Card>
  );
}
