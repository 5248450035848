import { isFunction, get } from 'lodash';
import { useGlobalLoading } from '@hooks';
import { useFlashMessages, useRouter } from '@tripledotstudios/react-core';

import { prepareInboundData } from '@requests/common';

function duplicateResponseHandlerFactory({
  buildRedirectPath = null, setData, entityName, openInNewTab,
}) {
  const router = useRouter();
  const { success, error } = useFlashMessages();
  const { withReload } = useGlobalLoading();

  return (response) => {
    switch (response.status) {
      case 201: {
        const redirectTo = buildRedirectPath ? buildRedirectPath(response.data.id)
          : `${router.pathname.replace(/\/$/, '').replace(/\/\d+\/edit(?:$|#\w*$)/, '')}/${response.data.id}/edit`;
        if (openInNewTab) {
          window.open(redirectTo, '_blank');
        } else withReload(() => router.push(redirectTo));
        success(`${entityName} duplication have been successfully finished`);
        break;
      }
      case 200:
      case 204: // Fallback for custom duplicate implementations
        withReload(() => router.push(router.pathname));
        success(`${entityName} duplication have been successfully finished`);
        break;
      case 403:
        error(response.data.reason);
        break;
      case 422:
        setData((previousState) => ({ ...previousState, ...prepareInboundData(response.data) }));
        error(`${entityName} duplication has failed`);
        break;
      default:
        error('Something went wrong', { details: response.data.message });
    }

    return response;
  };
}

function createUpdateResponseHandlerFactory({
  entityName,
  actionName,
  collectionUrl,
  setData,
}) {
  const router = useRouter();
  const { success, error } = useFlashMessages();
  const { withReload } = useGlobalLoading();

  return (response, originalData) => {
    const flashMessage = get(response.data, '_meta.errors.flash');

    switch (response.status) {
      case 200:
      case 201:
        withReload(() => router.push(collectionUrl));
        success(`${entityName} ${actionName} have been successfully finished`);
        break;
      case 204: {
        withReload(() => router.push(collectionUrl || router.fullpath));
        success(`${entityName} ${actionName} have been successfully finished`);
        break;
      }
      case 403:
        error(response.data.reason);
        break;
      case 422:
        setData((previousState) => ({ ...previousState, ...originalData, ...prepareInboundData(response.data) }));
        error(
          `${entityName} ${actionName} has failed`
          + `${flashMessage ? ` with message: "${flashMessage}"` : ''}`,
        );
        break;
      case 500:
        router.push(collectionUrl);
        error(`${entityName} ${actionName} has failed`);
        break;
      default:
        router.push(collectionUrl);
        error('Something went wrong', { details: response.data.message });
    }

    return response;
  };
}

function collectionResponseHandlerFactory({ entityName, actionName, refetch }) {
  const { success, error } = useFlashMessages();

  return (response) => {
    const flashMessage = get(response.data, '_meta.errors.flash');

    switch (response.status) {
      case 200:
      case 201:
      case 204:
        refetch();
        success(`${entityName} ${actionName} have been successfully finished`);
        break;
      case 403:
        refetch();
        error(response.data.reason);
        break;
      case 422:
        error(
          `${entityName} ${actionName} can not be performed`
          + `${flashMessage ? ` with message: "${flashMessage}"` : ''}`,
        );
        break;
      case 500:
        refetch();
        error(`${entityName} ${actionName} has failed`);
        break;
      default:
        refetch();
        error('Something went wrong');
    }

    return response;
  };
}

function redirectIfSuccessHandlerFactory({
  entityName,
  actionName,
  redirectPath,
  setData,
}) {
  const router = useRouter();
  const { success, error } = useFlashMessages();
  const { withReload } = useGlobalLoading();

  return (response, originalData = {}) => {
    const resolvePath = () => (isFunction(redirectPath) ? redirectPath(response.data) : redirectPath);
    const flashMessage = get(response.data, '_meta.errors.flash');

    switch (response.status) {
      case 200:
        router.push(resolvePath());
        success(`${entityName} ${actionName} have been successfully finished`);
        break;
      case 201: {
        // $1 supports postfixes like new/worlds
        const editSuffix = `${response.data.id}/edit$1`;
        const newSuffixRegExp = /new(\/.+)?$/;
        const editPath = router.location.pathname.replace(newSuffixRegExp, editSuffix);

        router.push(editPath);
        success(`${entityName} ${actionName} have been successfully finished`);
        break;
      }
      case 204: {
        withReload(() => router.push(router.pathname));
        success(`${entityName} ${actionName} have been successfully finished`);
        break;
      }
      case 403:
        error(response.data.reason);
        break;
      case 422:
        setData((previousState) => ({ ...previousState, ...originalData, ...prepareInboundData(response.data) }));
        error(`${entityName} ${actionName} has failed ${flashMessage ? ` with message: "${flashMessage}"` : ''}`);
        break;
      default:
        error('Something went wrong', { details: response.data.message });
    }

    return response;
  };
}

function noRedirectHandlerFactory({
  entityName,
  actionName,
  setData,
  showSuccess = true,
  setDataOnSuccess = false,
}) {
  const router = useRouter();
  const { withReload } = useGlobalLoading();
  const { success, error } = useFlashMessages();

  return (response) => {
    const flashMessage = get(response.data, '_meta.errors.flash');

    switch (response.status) {
      case 200:
        if (setDataOnSuccess) setData(response.data);
        if (showSuccess) success(`${entityName} ${actionName} have been successfully finished`);
        break;
      case 201:
        setData(response.data);
        if (showSuccess) success(`${entityName} ${actionName} have been successfully finished`);
        break;
      case 204: {
        withReload(() => router.push(router.fullpath));
        if (showSuccess) success(`${entityName} ${actionName} have been successfully finished`);
        break;
      }
      case 403:
        error(response.data.reason);
        break;
      case 422:
        setData((previousState) => ({ ...previousState, ...prepareInboundData(response.data) }));
        error(
          `${entityName} ${actionName} has failed`
          + `${flashMessage ? ` with message: "${flashMessage}"` : ''}`,
        );
        break;
      default:
        error('Something went wrong', { details: response.data.message });
    }

    return response;
  };
}

function searchResponseHandlerFactory({
  entityName,
  actionName,
  redirectPath,
  setCollection,
  setData,
}) {
  const router = useRouter();
  const { success, error } = useFlashMessages();
  return (response) => {
    const resolvePath = () => (isFunction(redirectPath) ? redirectPath(response.data) : redirectPath);
    switch (response.status) {
      case 200:
        if (response.data.items.length === 1) {
          router.push(resolvePath());
        } else if (response.data.items.length > 1) {
          setData(response.data.searchParams);
          setCollection(response.data);
        } else {
          setData(response.data.searchParams);
          setCollection(null);
          error(`${entityName} was not found`);
          break;
        }
        success(`${entityName} ${actionName} have been successfully finished`);
        break;
      case 422:
        setData((previousState) => ({ ...previousState, ...prepareInboundData(response.data) }));
        error(`${entityName} ${actionName} has failed`);
        break;
      default:
        error('Something went wrong', { details: response.data.message });
    }

    return response;
  };
}

function formResponseHandler({
  entityName,
  actionName,
  setData,
  refetch,
}) {
  const { push, location } = useRouter();
  const { success, error } = useFlashMessages();
  const { withReload } = useGlobalLoading();
  const reloadForm = refetch || withReload;

  return (response, originalData = {}) => {
    const flashMessage = get(response.data, '_meta.errors.flash');

    switch (response.status) {
      case 201: {
        // $1 supports postfixes like new/worlds
        const editSuffix = `${response.data.id}/edit$1`;
        const newSuffixRegExp = /new(\/.+)?$/;
        const editPath = location.pathname.replace(newSuffixRegExp, editSuffix);

        push(editPath);
        success(`${entityName} ${actionName} have been successfully finished`);
        break;
      }
      case 204: {
        reloadForm();
        success(`${entityName} ${actionName} have been successfully finished`);
        break;
      }
      case 403:
        error(response.data.reason);
        break;
      case 422:
        setData((previousState) => ({ ...previousState, ...originalData, ...prepareInboundData(response.data) }));
        error(
          `${entityName} ${actionName} has failed`
          + `${flashMessage ? ` with message: "${flashMessage}"` : ''}`,
        );
        break;
      default:
        error('Something went wrong', { details: response.data.message });
    }

    return response;
  };
}

export {
  createUpdateResponseHandlerFactory,
  collectionResponseHandlerFactory,
  redirectIfSuccessHandlerFactory,
  noRedirectHandlerFactory,
  searchResponseHandlerFactory,
  duplicateResponseHandlerFactory,
  formResponseHandler,
};
