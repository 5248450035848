import React from 'react';
import { useTheme } from 'styled-components';
import {
  faListOl,
  faTable,
  faCalendarDays,
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import {
  ApplicationItemSet,
  PaymentsNavigationSection,
  AdsNavigationSection,
  RewardsNavigationItem,
} from './common';

export default function Blackjack({ applicationId, routingScope, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="blackjack"
      routingScope={routingScope}
      minimized={minimized}
    >
      <Sidebar.LinkItem
        to={`${routingScope}/level_sets`}
        icon={faListOl}
        title="Level Sets"
        color={theme.purple}
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to={`${routingScope}/blackjack/table_configs`}
        icon={faTable}
        title="Table Configs"
        color={theme.yellow}
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to={`${routingScope}/blackjack/daily_challenge_configs`}
        icon={faCalendarDays}
        title="Daily Challenge Configs"
        color={theme.cornflowerBlue}
        minimized={minimized}
      />

      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />

      <PaymentsNavigationSection routingScope={routingScope} minimized={minimized} />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
