import React from 'react';
import { Modal } from 'react-bootstrap';
import { Spinner, Alert } from '@tripledotstudios/react-core';
import { isEmpty, omit } from 'lodash';

import { useQuery } from '@hooks';
import { PageSection } from '@pages/common';

import { AbTestingRoutes } from '@pages/routes';
import { InUseCollapse } from '@pages/common/InUse';

import IconButton from '@controls/buttons';

const isEmptyObject = (object) => isEmpty(omit(object, '_uuid'));

const Body = ({ recordsCount, data }) => {
  if (!recordsCount) return 'Not used in Rules';

  return (
    <>
      <PageSection title={`${recordsCount} record(s) use this experiment in Variant Rules:`} />

      {Object.entries(data).map(([variantName, inUseData]) => (
        <PageSection title={variantName}>
          {isEmptyObject(inUseData) ? (
            'No In Use data for this variant'
          ) : (
            <InUseCollapse inUse={inUseData} newTab />
          )}
        </PageSection>
      ))}
    </>
  );
};

export default function InUseInRulesModal({
  show,
  entity,
  onClose,
  onSave,
}) {
  if (!show) return '';

  const { response, isLoading } = useQuery(AbTestingRoutes.Experiments.inUseInRulesRequest, { id: entity.id });
  const allowsForceSave = typeof onSave === 'function';

  return (
    <Modal
      show={show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {allowsForceSave ? `Archive ${entity.name}` : `${entity.name} usage in Experiment variant Rules data`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {allowsForceSave && (
          <Alert variant="danger">
            {`You're about to archive an experiment. Keep in mind that this experiment is currently being used by
              ${response?.recordsCount} records in Variant Rules. If you continue, the archived experiment variant(s)
              will still be included in the rules of the following entities:`}
          </Alert>
        )}

        {isLoading ? (
          <Spinner name="Variants that are used in Variant Rules" />
        ) : (
          <Body data={omit(response.data, '_uuid')} recordsCount={response.recordsCount} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <IconButton.Cancel onClick={onClose}>{allowsForceSave ? 'Cancel' : 'Close'}</IconButton.Cancel>
        {allowsForceSave && (
          <IconButton.Save onClick={onSave}>Confirm</IconButton.Save>
        )}
      </Modal.Footer>
    </Modal>
  );
}
