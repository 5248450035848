import React from 'react';
import { PageTitle } from '@pages/common';

import {
  BanksEdit,
  BanksIndex,
  BanksNew,

  ChapterSetsIndex,
  ChapterSetsNew,
  ChapterSetsEdit,

  LevelLayoutsEdit,

  FigureSetsEdit,
  FigureSetsIndex,
  FigureSetsNew,

  AreaSetsIndex,
  AreaSetsNew,
  AreaSetsEdit,

  LevelRewardSetsIndex,
  LevelRewardSetsNew,
  LevelRewardSetsEdit,

  AbilityNamesIndex,
  AbilityNamesNew,
  AbilityNamesEdit,

  AbilityImpactsIndex,
  AbilityImpactsNew,
  AbilityImpactsEdit,

  AbilitySetsIndex,
  AbilitySetsNew,
  AbilitySetsEdit,

  MagicPotsIndex,
  MagicPotsNew,
  MagicPotsEdit,
} from '@pages/woodoku-saga';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function woodokuSaga() {
  return [
    //
    // Level Layout Banks
    //
    <Route
      key={Routes.Banks.indexRawPath}
      path={Routes.Banks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Level Layout Banks" />
          <BanksIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Banks.newRawPath}
      path={Routes.Banks.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout Bank" action="New" />
          <BanksNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Banks.editRawPath}
      path={Routes.Banks.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout Bank" action="Edit" />
          <BanksEdit />
        </RouteContainer>
      )}
    />,

    //
    // Chapter Sets
    //
    <Route
      key={Routes.ChapterSets.indexRawPath}
      path={Routes.ChapterSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Chapter Sets" />
          <ChapterSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.ChapterSets.newRawPath}
      path={Routes.ChapterSets.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Chapter Set" action="New" />
          <ChapterSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.ChapterSets.editRawPath}
      path={Routes.ChapterSets.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Chapter Set" action="Edit" />
          <ChapterSetsEdit />
        </RouteContainer>
      )}
    />,

    //
    // Level Layouts
    //
    <Route
      key={Routes.LevelLayouts.editRawPath}
      path={Routes.LevelLayouts.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout" action="Edit" />
          <LevelLayoutsEdit />
        </RouteContainer>
      )}
    />,

    //
    // Figure Sets
    //
    <Route
      key={Routes.FigureSets.indexRawPath}
      path={Routes.FigureSets.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Figure Sets" />
          <FigureSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.FigureSets.newRawPath}
      path={Routes.FigureSets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Figure Set" action="New" />
          <FigureSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.FigureSets.editRawPath}
      path={Routes.FigureSets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Figure Set" action="Edit" />
          <FigureSetsEdit />
        </RouteContainer>
      )}
    />,

    //
    // Area Sets
    //
    <Route
      key={Routes.AreaSets.indexRawPath}
      path={Routes.AreaSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Area Sets" />
          <AreaSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.AreaSets.newRawPath}
      path={Routes.AreaSets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Area Set" action="New" />
          <AreaSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.AreaSets.editRawPath}
      path={Routes.AreaSets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Area Set" action="Edit" />
          <AreaSetsEdit />
        </RouteContainer>
      )}
    />,

    //
    // Level Reward Sets
    //
    <Route
      key={Routes.LevelRewardSets.indexRawPath}
      path={Routes.LevelRewardSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Level Reward Sets" />
          <LevelRewardSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.LevelRewardSets.newRawPath}
      path={Routes.LevelRewardSets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Reward Set" action="New" />
          <LevelRewardSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.LevelRewardSets.editRawPath}
      path={Routes.LevelRewardSets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Reward Set" action="Edit" />
          <LevelRewardSetsEdit />
        </RouteContainer>
      )}
    />,

    //
    // Ability Names
    //
    <Route
      key={Routes.Abilities.Names.indexRawPath}
      path={Routes.Abilities.Names.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Ability Names" />
          <AbilityNamesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Abilities.Names.newRawPath}
      path={Routes.Abilities.Names.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Ability Name" action="New" />
          <AbilityNamesNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Abilities.Names.editRawPath}
      path={Routes.Abilities.Names.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Ability Name" action="Edit" />
          <AbilityNamesEdit />
        </RouteContainer>
      )}
    />,

    //
    // Ability Impact Areas
    //
    <Route
      key={Routes.Abilities.Impacts.indexRawPath}
      path={Routes.Abilities.Impacts.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Ability Impact Areas" />
          <AbilityImpactsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Abilities.Impacts.newRawPath}
      path={Routes.Abilities.Impacts.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Ability Impact Areas" action="New" />
          <AbilityImpactsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Abilities.Impacts.editRawPath}
      path={Routes.Abilities.Impacts.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Ability Impact Areas" action="Edit" />
          <AbilityImpactsEdit />
        </RouteContainer>
      )}
    />,

    //
    // Ability Sets
    //
    <Route
      key={Routes.Abilities.Sets.indexRawPath}
      path={Routes.Abilities.Sets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Ability Sets" />
          <AbilitySetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Abilities.Sets.newRawPath}
      path={Routes.Abilities.Sets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Ability Set" action="New" />
          <AbilitySetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Abilities.Sets.editRawPath}
      path={Routes.Abilities.Sets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Ability Set" action="Edit" />
          <AbilitySetsEdit />
        </RouteContainer>
      )}
    />,
    //
    // Magic Pots
    //
    <Route
      key={Routes.MagicPots.indexRawPath}
      path={Routes.MagicPots.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Magic Pots" />
          <MagicPotsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.MagicPots.newRawPath}
      path={Routes.MagicPots.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Magic Pot" action="New" />
          <MagicPotsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.MagicPots.editRawPath}
      path={Routes.MagicPots.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Magic Pot" action="Edit" />
          <MagicPotsEdit />
        </RouteContainer>
      )}
    />,
  ];
}
