import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form, FormGroup } from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { rulesReducer, combineReducers } from '@reducers';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';

import { Form as DistributionsForm, reducer as figureSetsReducer } from '../../distributions-form/Index';

const reducer = combineReducers([figureSetsReducer('woodokuSaga'), rulesReducer]);

export default function FigureSetForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const { readOnly } = data;

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit} reducer={reducer}>
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Figure set`}>
            <FormButtonsGroup
              cancelButtonPath={Routes.FigureSets.indexPath({ applicationId })}
            />
          </PageHeader>

          <FormGroup>
            <Label text="Name" required>
              <Field type="text" name="name" />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <DistributionsForm values={values} disabled={readOnly} gameType="woodokuSaga" />
          </FormGroup>
        </>
      )}
    </Form>
  );
}
