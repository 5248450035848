import React from 'react';

import { fromClassNameToOptions } from '@services/enums';
import SelectField from '@controls/form/SelectField';

export default function DeviceTypeRule({ disabled }) {
  const deviceTypeOptions = fromClassNameToOptions('Accounts::DeviceTypes');

  return (
    <SelectField
      name="deviceTypes"
      options={deviceTypeOptions}
      isMulti
      isDisabled={disabled}
    />
  );
}
