import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { FormGroup, useFormContext } from '@hooks';
import { Label, SelectField } from '@controls/form';
import IconButton from '@controls/buttons';

export default function AbilitiesFormGroup({
  values, allNameOptions, impactOptions, disabled,
}) {
  const { dispatch } = useFormContext();
  let position = 0;

  const nameOptions = (currentIndex) => {
    const usedNameIds = values.entitiesAttributes
      ? values.entitiesAttributes.map(({ nameId, _destroy }, index) => (index !== currentIndex && !_destroy && nameId))
      : [];
    return allNameOptions.filter(({ value }) => usedNameIds.indexOf(value) < 0);
  };

  return values.entitiesAttributes && (
    <>
      {values.entitiesAttributes.map(({ _destroy }, index) => {
        if (index === 0) position = 0;
        if (_destroy) return null;

        position += 1;
        return (
          <FormGroup name={`entitiesAttributes[${index}]`}>
            <Row className="mb-2">
              <Col xs="1">
                <IconButton.Delete
                  onClick={() => dispatch({ type: 'removeEntity', index })}
                  minimized
                  disabled={disabled}
                />
                <h4 className="d-inline ms-2">{position}</h4>
              </Col>
              <Col xs="11" className="p-0">
                <Card>
                  <Card.Body>
                    <Label required text="Ability Name" labelSize={2} fieldSize={6}>
                      <SelectField
                        name="nameId"
                        options={() => nameOptions(index)}
                        isDisabled={disabled}
                      />
                    </Label>
                    <Label required text="Impact Areas" labelSize={2} fieldSize={6}>
                      <SelectField
                        name="impactId"
                        options={impactOptions}
                        isDisabled={disabled}
                      />
                    </Label>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </FormGroup>
        );
      })}
      <div className="text-end mt-4">
        <IconButton.New onClick={() => dispatch({ type: 'addEmptyEntity' })} disabled={disabled}>
          Add
        </IconButton.New>
      </div>
    </>
  );
}
