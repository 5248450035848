import React from 'react';

import { PageTitle } from '@pages/common';
import { StreaksRoutes } from '@pages/routes';
import {
  EntitiesIndex,
  EntitiesNew,
  EntitiesEdit,
  EntitiesExperimentScoped,
} from '@components/streaks';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function streaks() {
  return [
    <Route
      key={StreaksRoutes.Entities.indexRawPath}
      path={StreaksRoutes.Entities.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Missions Allocations" />
          <EntitiesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={StreaksRoutes.Entities.newRawPath}
      path={StreaksRoutes.Entities.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Streak" action="New" />
          <EntitiesNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={StreaksRoutes.Entities.editRawPath}
      path={StreaksRoutes.Entities.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Streak" action="Edit" />
          <EntitiesEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={StreaksRoutes.Entities.experimentScopedRawPath}
      path={StreaksRoutes.Entities.experimentScopedRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Streak A/B Experiment Scoped" />
          <EntitiesExperimentScoped />
        </RouteContainer>
      )}
    />,
  ];
}
