import { fromClassNameToOptions } from '@root/services/enums';

const config = {
  pageModelName: 'Level Banks',
  modelName: 'Journeys::LevelBanks::Bank',
  statusOptions: fromClassNameToOptions('Journeys::LevelBanks::BankStatuses'),
};

const massOperationsConfig = {
  woodoku: config, solitaire: config, my_pet_cafe: { ...config, pageModelName: 'Milestone banks' },
};

export default massOperationsConfig;
