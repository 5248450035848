import { wrapReducer } from '@reducers';
import { addUuid } from '@services/recursivelyProcessObject';
import {
  omit, get, isUndefined, transform,
} from 'lodash';

const reducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case 'toggleShape': {
      const { id, value } = action;

      if (value) {
        state.enabledShapeIds = Array.from(new Set([...(state.enabledShapeIds || []), id])).sort();

        break;
      }

      state.enabledShapeIds = state.enabledShapeIds.filter((shapeId) => shapeId !== id).sort();

      break;
    }

    case 'addRow': {
      const { shapesConfig, index } = action;

      if (!isUndefined(index)) {
        const attributes = state.figureAffiliationsAttributes;
        const newAttributes = [
          ...attributes.slice(0, index),
          addUuid({ from: 0, set: addUuid(shapesConfig), _destroy: false }),
          ...attributes.slice(index),
        ];

        state.figureAffiliationsAttributes = newAttributes;
        break;
      }

      state.figureAffiliationsAttributes.push(addUuid({ from: 0, set: addUuid(shapesConfig), _destroy: false }));

      break;
    }

    case 'removeRow': {
      const { uuid } = action;

      state.figureAffiliationsAttributes.find(({ _uuid }) => _uuid === uuid)._destroy = true;

      break;
    }

    case 'updateRow': {
      const { row } = action;

      state.figureAffiliationsAttributes = state.figureAffiliationsAttributes.map((figureAffiliation) => {
        if (figureAffiliation._uuid !== row._uuid) return figureAffiliation;

        const set = transform(omit(row, ['from', '_errors']), (memo, currentValue, key) => {
          if (key.startsWith('_')) {
            memo[key] = currentValue;
            return;
          }

          memo[key] = Number(currentValue);
        }, {});

        return { ...figureAffiliation, from: row.from, set };
      });

      break;
    }

    case 'normalizeSets': {
      const { shapeIds } = action;

      state.figureAffiliationsAttributes = state.figureAffiliationsAttributes.map((figureAffiliation) => {
        const set = shapeIds.reduce((memo, shapeId) => (
          { ...memo, [shapeId]: get(figureAffiliation.set, shapeId, 0) }
        ), {});

        return { ...figureAffiliation, set };
      });

      break;
    }

    case 'upload': {
      const { data } = action;
      const [headers, ...csvData] = data;

      const filteredHeaders = headers.filter((header) => header !== 'Bank');

      state.enabledShapeIds = filteredHeaders.map((header) => (
        state.availableShapes.find(({ name }) => name === header).id
      ));

      state.figureAffiliationsAttributes.forEach((affiliation) => {
        affiliation._destroy = true;

        return affiliation;
      });

      const setConfigs = csvData.map((row) => (
        row.reduce((memo, cell, index) => {
          if (index === 0) return ({ ...memo, from: Number(cell) });

          const figureId = state.availableShapes.find(({ name }) => name === headers[index]).id;

          return ({ ...memo, [figureId]: Number(cell) });
        }, {})
      ));

      setConfigs.forEach((setConfig) => {
        const { from, ...set } = setConfig;

        state.figureAffiliationsAttributes.push(addUuid({ from, set, _destroy: false }));
      });

      break;
    }

    default: break;
  }

  return state;
};

export default wrapReducer(reducer);
