import React, { useEffect } from 'react';
import { Tabs } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

export default function ControlsTabs({
  onChange, children, focusedKey, ...rest
}) {
  const { location, push } = useRouter();
  const processSetActiveKey = (newActiveKey) => {
    if (location.hash !== newActiveKey) {
      push(`${location.pathname}${location.search}${newActiveKey}`);
    }
    if (onChange) onChange(newActiveKey.replace('#', ''));
  };

  useEffect(() => {
    if (focusedKey !== undefined) {
      processSetActiveKey(focusedKey);
    }
  }, [focusedKey]);

  return (
    <Tabs activeKey={location.hash === '' ? undefined : location.hash} onSelect={processSetActiveKey} {...rest}>
      {children}
    </Tabs>
  );
}
