import React from 'react';
import { useTheme } from 'styled-components';
import {
  faListOl,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { Match3dRoutes as Routes } from '@pages/routes';

import {
  ApplicationItemSet,
  PaymentsNavigationSection,
  AdsNavigationSection,
  RewardsNavigationItem,
} from './common';

export default function Match3d({ routingScope, applicationId, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="match3d"
      routingScope={routingScope}
      minimized={minimized}
    >
      <Sidebar.SectionItem
        icon={faListOl}
        title="Levels & Chapters"
        color={theme.purple}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={Routes.Banks.indexPath({ applicationId })}
          title="Layout Banks"
        />
        <Sidebar.LinkItem
          to={Routes.ChapterSets.indexPath({ applicationId })}
          title="Chapter Sets"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/match3d/terrains`}
          title="Terrains"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/match3d/figure_packs`}
          title="Figure Packs"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/match3d/ui_themes`}
          title="UI Themes"
        />
        <Sidebar.LinkItem
          to={Routes.UiConfigs.indexPath({ applicationId })}
          title="UI Configurations"
        />
      </Sidebar.SectionItem>

      <Sidebar.LinkItem
        to={`${routingScope}/match3d/personal_level_sets`}
        icon={faStar}
        title="Star Levels"
        color={theme.yellow}
        minimized={minimized}
      />

      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />

      <PaymentsNavigationSection routingScope={routingScope} minimized={minimized} />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
