import React from 'react';
import { Button, Modal, Container } from 'react-bootstrap';

import { Form, useCurrentApplication, useQuery } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import {
  Label,
  Field,
  CheckboxField,
  SelectField,
} from '@controls/form';

import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { prepareInboundData } from '@requests/common';
import TierParameters from './TierParameters';
import reducer from './reducer';

export default function MissionTypeForm({
  actionName, entityName, getForm, onHide, submitForm, recordId, refetch,
}) {
  if (!recordId) { return null; }

  const { currentApplication } = useCurrentApplication();
  const { response: data, setResponse } = useQuery((params) => getForm({ ...params, id: recordId }));

  const orderDirectionOptions = fromClassNameToOptions('Missions::MissionTypeOrderDirections');
  const valuesTypeOptions = fromClassNameToOptions('Missions::MissionTypeValuesTypes');

  const fullData = { ...data, applicationId: currentApplication.id };

  const responseHandler = collectionResponseHandlerFactory({
    actionName,
    refetch,
    entityName,
  });

  const onSubmit = async (values) => {
    const response = await submitForm(values);
    responseHandler(response);
    if (response.status < 300) {
      onHide();
    } else {
      setResponse((currentValues) => ({ ...currentValues, ...values, ...prepareInboundData(response.data) }));
    }
  };

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{entityName}</Modal.Title>
      </Modal.Header>
      {data && (
        <Form
          initialValues={fullData}
          onSubmit={onSubmit}
          reducer={reducer}
          enableReinitialize
        >
          {() => (
            <Container>
              <Modal.Body>
                <Label text="Name*" fieldSize={8}>
                  <Field name="name" />
                </Label>
                <Label text="Key*" fieldSize={8}>
                  <Field name="key" />
                </Label>
                <Label text="Target values type*" fieldSize={8}>
                  <SelectField
                    name="valuesType"
                    options={valuesTypeOptions}
                  />
                </Label>
                <Label text="Target values order*" fieldSize={8}>
                  <SelectField
                    name="order"
                    options={orderDirectionOptions}
                  />
                </Label>
                <Label text="Allow same target value for consecutive tiers" fieldSize={8}>
                  <CheckboxField name="allowSameValues" />
                </Label>
                <TierParameters />
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit">Confirm</Button>
                <Button type="button" variant="secondary" onClick={onHide}>Cancel</Button>
              </Modal.Footer>
            </Container>
          )}
        </Form>
      )}
    </Modal>
  );
}
