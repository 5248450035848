import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { formResponseHandler } from '@requests/responseHandlers';
import { WooAspenRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const { response, setResponse } = useQuery(Routes.PlayOnPopUps.newRequest);
  const { query: { applicationId } } = useRouter();

  const responseHandler = formResponseHandler({
    entityName: 'Play on Pop-Up',
    actionName: 'creation',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    Routes.PlayOnPopUps.createRequest({ ...values, applicationId }).then(responseHandler)
  );

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
