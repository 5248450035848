/* eslint-disable no-param-reassign, array-callback-return */
import {
  cloneDeep, compact, find, set, findIndex, findLastIndex,
} from 'lodash';
import { dndRecords } from '@services/reorder';
import { addUuid, clearValues } from '@services/recursivelyProcessObject';
import { persistedCollection } from '@services/utils';
import { RULES_LIST } from '@pages/rules/list';
import { v4 } from 'uuid';
import getDefaultVariant from './getDefaultVariant';
import getDefaultSubVariant from './getDefaultSubVariant';

const defaultRuleOperation = (ruleType) => {
  if (!ruleType) return null;

  const rule = find(RULES_LIST, (r) => r.type === ruleType);
  if (rule.operations) {
    return rule.operations[0];
  } if (rule.options) {
    return rule.options[0].value;
  }
  return null;
};

const updateDefaultSubVariant = (variant) => {
  variant.defaultSubVariant = getDefaultSubVariant(variant) || variant.defaultSubVariant;
};

const setDefaultSubVariant = (variant, variantRuleGroupUuid) => {
  if (!variant.defaultSubVariant) updateDefaultSubVariant(variant);
  variant.subVariantsAttributes.push({ ...addUuid(cloneDeep(variant.defaultSubVariant)), variantRuleGroupUuid });
};

const buildExperimentEntityPredicate = ({
  entityId,
  entityType,
  propertyName,
  type,
}) => {
  const isTargetedEntity = (experimentEntity) => (
    experimentEntity.entityId === entityId && experimentEntity.entityType === entityType
  );

  return type === 'property' ? (
    (experimentEntity) => isTargetedEntity(experimentEntity) && experimentEntity.propertyName === propertyName
  ) : (
    (experimentEntity) => isTargetedEntity(experimentEntity) && experimentEntity.entityVariantId
  );
};

const applyNewRuleGroup = (state, data) => {
  const { id, subVariants, ruleSectionsAttributes } = data;
  const variantRuleGroupUuid = v4();
  state.variantRuleGroupsAttributes.push(
    {
      id, priority: 1, ruleSectionsAttributes, draft: false, uuid: variantRuleGroupUuid,
    },
  );
  set(state, 'meta.errors.newVariantRule', null);

  if (state.id) {
    state.variantsAttributes.forEach((variant) => {
      if (variant.id) {
        const subVariant = subVariants[variant.id];

        const destroyedEntities = variant.defaultSubVariant.experimentEntitiesAttributes
          .filter((entity) => entity._destroy);
        variant.subVariantsAttributes.push({
          ...subVariant,
          experimentEntitiesAttributes: [
            ...subVariant.experimentEntitiesAttributes.map((entity) => {
              const matchEntity = buildExperimentEntityPredicate(entity);
              destroyedEntities.forEach((entityToRemove) => {
                if (matchEntity(entityToRemove)) { entity._destroy = true; }
              });
              return entity;
            }),
          ],
        });
      } else {
        const { defaultVariant } = getDefaultVariant(state);
        const subVariant = cloneDeep(subVariants[defaultVariant.id]);
        delete subVariant.id;
        const destroyedEntities = defaultVariant.defaultSubVariant.experimentEntitiesAttributes
          .filter((entity) => entity._destroy);
        variant.subVariantsAttributes.push({
          ...subVariant,
          experimentEntitiesAttributes: [
            ...subVariant.experimentEntitiesAttributes.map((entity) => {
              const matchEntity = buildExperimentEntityPredicate(entity);
              const entityClone = cloneDeep(entity);
              destroyedEntities.forEach((entityToRemove) => {
                if (matchEntity(entityToRemove)) { entityClone._destroy = true; }
              });
              delete entityClone.id;
              return entityClone;
            }),
          ],
        });
      }
    });
  } else {
    state.variantsAttributes.forEach((v) => setDefaultSubVariant(v, variantRuleGroupUuid));
  }
};

export default function Reducer(state, action) {
  switch (action.type) {
    case 'setDefaults': {
      state.variantsAttributes.forEach(updateDefaultSubVariant);
      break;
    }

    case 'removeEntity': {
      const shouldBeDeleted = buildExperimentEntityPredicate(action.entity);
      state.variantsAttributes.forEach((variant) => {
        compact([variant.defaultSubVariant, ...variant.subVariantsAttributes]).forEach((subVariant) => {
          subVariant.experimentEntitiesAttributes.forEach((experimentEntity) => {
            if (shouldBeDeleted(experimentEntity)) {
              experimentEntity._destroy = true;
            }
          });
        });
      });
      break;
    }

    case 'addVariant': {
      const { variant } = action;
      const variantClone = cloneDeep(variant);
      let { sourceVariant } = action;
      const { defaultVariant } = getDefaultVariant(state);
      sourceVariant = sourceVariant || defaultVariant;

      if (sourceVariant.defaultSubVariant) {
        variantClone.defaultSubVariant = addUuid(cloneDeep(sourceVariant.defaultSubVariant));
        delete variantClone.defaultSubVariant.id;
      } else {
        variantClone.defaultSubVariant = {};
      }
      if (sourceVariant.subVariantsAttributes) {
        variantClone.subVariantsAttributes = cloneDeep(sourceVariant.subVariantsAttributes);
        variantClone.subVariantsAttributes.forEach((subVariant) => {
          delete subVariant.id;
          subVariant.experimentEntitiesAttributes.forEach((ee) => {
            delete ee.id;
          });
        });
      } else {
        variantClone.subVariantsAttributes = [];
      }
      state.variantsAttributes.push(variantClone);
      break;
    }

    case 'showVariant': {
      const { variant } = action;
      state.variantsAttributes.push(variant);
      break;
    }

    case 'removeVariant': {
      const { index } = action;
      const variant = state.variantsAttributes[index];

      if (variant.id) {
        variant._destroy = true;
      } else {
        state.variantsAttributes.splice(index, 1);
      }

      if (variant.default) {
        const newDefaultVariant = state.variantsAttributes.find((v) => !v._destroy);
        if (newDefaultVariant) {
          newDefaultVariant.default = true;
        }
      }

      break;
    }

    case 'undoRemoveVariant': {
      const { index } = action;
      const variant = state.variantsAttributes[index];

      if (variant.id) {
        if (variant.default) {
          const { defaultVariantIndex, defaultVariant } = getDefaultVariant(state);
          if (defaultVariant) {
            if (defaultVariantIndex > index) {
              defaultVariant.default = false;
            } else {
              variant.default = false;
            }
          }
        }
        variant._destroy = false;
      }
      break;
    }

    case 'changeSeparateAllocationDate': {
      const {
        checkbox, checked, target, source,
      } = action;

      state[checkbox] = checked;
      state[target] = state[source];
      break;
    }

    case 'addVariantRuleGroup': {
      const { validationResult, ruleType } = action;
      if (validationResult.status < 300) {
        persistedCollection(state.variantRuleGroupsAttributes).forEach((ruleGroup) => {
          ruleGroup.priority += 1;
        });
        applyNewRuleGroup(state, validationResult.data);
        state.newVariantRule = { ...clearValues(state.newVariantRule), operation: defaultRuleOperation(ruleType) };
      } else if (validationResult.status === 422) {
        set(state,
          'meta.errors.newVariantRule',
          validationResult.data._meta.errors.ruleSectionsAttributes[0].rulesAttributes[0],
        );
      }
      break;
    }

    case 'moveDownVariantRuleGroup': {
      const { index } = action;
      const destinationIndex = findIndex(state.variantRuleGroupsAttributes, (group) => !group._destroy, index + 1);
      state.variantRuleGroupsAttributes[index].priority -= 1;
      state.variantRuleGroupsAttributes[destinationIndex].priority += 1;
      dndRecords(state, { path: 'variantRuleGroupsAttributes', sourceIndex: index, destinationIndex });
      state.variantsAttributes.forEach((variant) => {
        dndRecords(variant, { path: ['subVariantsAttributes'], sourceIndex: index, destinationIndex });
      });
      break;
    }

    case 'moveUpVariantRuleGroup': {
      const { index } = action;
      const destinationIndex = findLastIndex(state.variantRuleGroupsAttributes, (group) => !group._destroy, index - 1);
      state.variantRuleGroupsAttributes[index].priority += 1;
      state.variantRuleGroupsAttributes[destinationIndex].priority -= 1;
      dndRecords(state, { path: 'variantRuleGroupsAttributes', sourceIndex: index, destinationIndex });
      state.variantsAttributes.forEach((variant) => {
        dndRecords(variant, { path: ['subVariantsAttributes'], sourceIndex: index, destinationIndex });
      });
      break;
    }

    case 'removeVariantRuleGroup': {
      const { index } = action;
      state.variantRuleGroupsAttributes[index]._destroy = true;
      for (let i = 0; i < index; i += 1) {
        const rule = state.variantRuleGroupsAttributes[i];
        rule.priority -= 1;
      }
      state.variantsAttributes.forEach((variant) => {
        variant.subVariantsAttributes[index]._destroy = true;
        updateDefaultSubVariant(variant);
      });
      break;
    }

    case 'changeVariantRuleType': {
      persistedCollection(state.variantRuleGroupsAttributes).forEach((ruleGroup) => {
        ruleGroup._destroy = true;
      });
      state.variantsAttributes.forEach((variant) => {
        updateDefaultSubVariant(variant);
        variant.subVariantsAttributes.forEach((subVariant) => { subVariant._destroy = true; });
      });
      state.variantRuleType = state.newVariantRuleType;
      state.newVariantRule = { operation: defaultRuleOperation(state.newVariantRuleType) };
      break;
    }

    case 'removeVariantRuleType': {
      const { validationResult } = action;
      if (validationResult.status < 300) {
        persistedCollection(state.variantRuleGroupsAttributes).forEach((ruleGroup) => {
          ruleGroup._destroy = true;
        });
        state.variantsAttributes.forEach((variant) => {
          updateDefaultSubVariant(variant);
          variant.subVariantsAttributes.forEach((subVariant) => { subVariant._destroy = true; });
        });
        applyNewRuleGroup(state, validationResult.data);
        state.newVariantRuleType = null;
        state.variantRuleType = null;
        state.newVariantRule = {};
      }
      break;
    }

    default: break;
  }

  return state;
}
