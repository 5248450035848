import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Field, SelectField } from '@controls/form';

export const TrackableField = styled(Field)`
  ${({ dirty }) => (dirty && 'border-color: #ffc107;')}
`;

export const TrackableSelectField = ({ dirty, ...props }) => {
  const styles = {
    control: useCallback((provided) => (dirty ? {
      ...provided,
      borderColor: '#ffc107',
    } : provided), [dirty]),
  };
  return <SelectField {...props} styles={styles} />;
};
