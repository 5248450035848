import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';
import { useTheme } from 'styled-components';

export default function HomeNavigationItem({ routingScope, minimized }) {
  const theme = useTheme();

  return (
    <Sidebar.LinkItem
      to={`${routingScope}/home`}
      icon={faHome}
      title="Home"
      color={theme.colors.home}
      minimized={minimized}
    />
  );
}
