import React from 'react';
import { Modal } from 'react-bootstrap';

export default function PostcardGraphModal({ internalId, onHide }) {
  return (
    <Modal
      show
      onHide={onHide}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {internalId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The diagram is under maintenance.
        For more info contact GS team.
      </Modal.Body>
    </Modal>
  );
}
