import unbubblingConfigurationReducer from '@components/merge/unbubbling-configurations/formReducer';
import MyPetCafeConfigurationForm from './MyPetCafeConfigurationForm';
import SudokuConfigurationForm from './SudokuConfigurationForm';
import MergeConfigurationForm from './MergeConfigurationForm';
import mergeEventReducer from './merge/reducer';
import mergePreprocessSubmit from './merge/preprocessSubmit';
import TileConfigurationForm from './TileConfigurationForm';
import SolitaireConfigurationForm from './SolitaireConfigurationForm';
import WoodokuConfigurationForm from './WoodokuConfigurationForm';
import BlockPartyConfigurationForm from './BlockPartyConfigurationForm';
import SortagaConfigurationForm from './SortagaConfigurationForm';
import HexagoConfigurationForm from './HexagoConfigurationForm';

export default {
  my_pet_cafe: { ConfigurationForm: MyPetCafeConfigurationForm },
  sudoku: { ConfigurationForm: SudokuConfigurationForm },
  merge: {
    ConfigurationForm: MergeConfigurationForm,
    reducers: [mergeEventReducer, unbubblingConfigurationReducer],
    preprocessSubmit: mergePreprocessSubmit,
  },
  tile: { ConfigurationForm: TileConfigurationForm },
  solitaire: { ConfigurationForm: SolitaireConfigurationForm },
  woodoku: { ConfigurationForm: WoodokuConfigurationForm },
  block_party: { ConfigurationForm: BlockPartyConfigurationForm },
  sortaga: { ConfigurationForm: SortagaConfigurationForm },
  hexago: { ConfigurationForm: HexagoConfigurationForm },
};
