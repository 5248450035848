import React from 'react';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { IconTooltip } from '@tripledotstudios/react-core';
import { isEmpty } from 'lodash';

import APP_DATA from '@services/appData';

const { adminSettings } = APP_DATA;

export default function GuideTooltip({ userGuideUrlKey, href, ...rest }) {
  const link = adminSettings[userGuideUrlKey] || href;

  return isEmpty(link) ? '' : <IconTooltip icon={faBook} text="Click to open User Guide" href={link} {...rest} />;
}
