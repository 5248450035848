import React, { useState } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader,
  Pagination,
  RulesList,
  TIMESTAMP_COLUMNS,
  ReactTable,
  DuplicationModal,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { NamedRuleGroupsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

const columnsFactory = ({
  applicationId, editPath, onDelete, setDuplicatedRecord,
}) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Rules',
    id: 'rules',
    style: { width: '30%' },
    Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
  },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        <IconButton.Delete onClick={onDelete(id)} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const {
    deleteRequest, editPath, newPath, indexRequest, duplicateRequest,
  } = NamedRuleGroupsRoutes;

  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(indexRequest);
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Named rule group',
    actionName: 'delete',
    refetch,
  });

  const onDuplicate = (values) => (
    duplicateRequest({ ...values, applicationId })
  );

  const onDelete = (id) => () => {
    confirm.showConfirmation({ title: 'Named rule group will be deleted and not available for work. Continue?' })
      .then(() => deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  return response && (
    <div>
      <PageHeader
        title="Named Rule Groups"
      >
        <IconButton.New to={newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columnsFactory({
          applicationId, editPath, onDelete, setDuplicatedRecord,
        })}
        data={response ? response.items : []}
        defaultSort={{ id: 'priority', desc: true }}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Named rule Group"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={onDuplicate}
        title="Duplicate Named Rule Group"
      />
    </div>
  );
}
