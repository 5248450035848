import React from 'react';

import APP_DATA from '@services/appData';

import { DailyChallengesRoutes } from '@pages/routes';

import { fromClassNameToOptions } from '@services/enums';
import {
  Form,
  AbTestingContext,
  useConfirm,
  useCurrentApplication,
} from '@hooks';

import {
  Label,
  Field,
  SelectField,
  FormButtonsGroup,
  DateTimeField,
  PriorityField,
  AvailabilityStateFormGroup,
  StaticFieldsFormGroup,
  LabelsFormGroup,
} from '@controls/form';

import { DuplicateButton, PageHeader } from '@pages/common';
import RulesForm from '@pages/rules/Form';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import { combineReducers, rulesReducer } from '@reducers';

import IconButton from '@controls/buttons';

import CONFIG_FORMS from './config-forms';

import formReducer from './formReducer';

const { enums, modelsLocales: { woodoku: { dailyChallenges: { events: { tooltips } } } } } = APP_DATA;
const statusesEnum = enums['DailyChallenges::EventStatuses'];

export default function DailyChallengesForm({ data, onSubmit, entityName }) {
  const statuses = fromClassNameToOptions('DailyChallenges::EventStatuses');
  const { currentApplication: { id: applicationId, typeName: appTypeName } } = useCurrentApplication();
  const confirm = useConfirm();

  const { status, configType, configAttributes = {} } = data;

  const isArchived = status === statusesEnum.ARCHIVED;
  const isUpdate = Boolean(data.id);

  const archiveResponseHandler = redirectIfSuccessHandlerFactory({
    entityName,
    actionName: 'archive',
    setData: () => null,
  });

  const onFormSubmit = (values) => {
    const { id, name } = values;

    if (data.status !== statusesEnum.ARCHIVED && values.status === statusesEnum.ARCHIVED) {
      return confirm.showConfirmation({
        body: `${entityName} '${name}' will be archived and not available for work. Continue?`,
      }).then(() => DailyChallengesRoutes.Events.archiveRequest({ id, applicationId }).then(archiveResponseHandler));
    }

    return onSubmit(values);
  };

  const ConfigForm = CONFIG_FORMS[appTypeName];

  return (
    <AbTestingContext
      entityType={configType}
      entityId={configAttributes.id}
    >
      <Form
        initialValues={data}
        onSubmit={onFormSubmit}
        reducer={combineReducers([rulesReducer, formReducer])}
      >
        {({ values }) => (
          <>
            <PageHeader title={`${isUpdate ? 'Edit' : 'New'} ${entityName}`}>
              <FormButtonsGroup cancelButtonPath={DailyChallengesRoutes.Events.indexPath({ applicationId })}>
                {isUpdate && (
                  <>
                    <IconButton.ActivityLog
                      applicationId={applicationId}
                      id={data.id}
                      entityType="DailyChallenges::Event"
                    />
                    <FormButtonsGroup.Divider />
                  </>
                )}
                <DuplicateButton
                  routes={DailyChallengesRoutes.Events}
                  data={data}
                  entityName={entityName}
                  modalType="withPriority"
                />
              </FormButtonsGroup>
            </PageHeader>

            {isUpdate && (
              <div className="mt-2">
                <Label text="ID">
                  <Field name="id" disabled />
                </Label>
                <Label text="Variant ID">
                  <Field name="configAttributes.currentVariantAttributes.id" disabled />
                </Label>
              </div>
            )}

            <Label required text="Name">
              <Field type="text" name="name" />
            </Label>
            <Label required text="Status">
              <SelectField name="status" options={statuses} isDisabled={isArchived} />
            </Label>
            <AvailabilityStateFormGroup disabled={isArchived} />
            <Label required text="Priority">
              <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} disabled={isArchived} />
            </Label>

            <LabelsFormGroup />
            <StaticFieldsFormGroup data={data} />

            <Label required text="Start for client" tooltipText={tooltips.startForClient}>
              <DateTimeField as="date" name="startForClient" disabled={isArchived} />
            </Label>

            <Label required text="Month">
              <DateTimeField
                showMonthYearPicker
                name="eventMonth"
                dateFormat={['MMMM, yyyy']}
                showTimeSelect={false}
                disabled={isArchived}
              />
            </Label>

            <ConfigForm
              variantAttributes={values.configAttributes.currentVariantAttributes}
              disabled={isArchived}
            />

            <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} disabled={isArchived} />
          </>
        )}
      </Form>
    </AbTestingContext>
  );
}
