import React from 'react';

import { PageHeader } from '@pages/common';
import { Form } from '@hooks';
import {
  FormButtonsGroup,
  Field,
  Label,
  FieldArrayWithLabel,
} from '@controls/form';

export default function GameConfigsForm({
  data,
  onSubmit,
  fieldsConfiguration,
  indexPath,
  configName,
}) {
  return (
    <Form initialValues={data} onSubmit={onSubmit}>
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} ${configName}`}>
            <FormButtonsGroup cancelButtonPath={indexPath} />
          </PageHeader>

          <Label text="Name"><Field type="text" name="name" /></Label>
          {fieldsConfiguration.map((field) => (
            <Label text={field.label}>
              {(field.type === 'array') ? (
                <FieldArrayWithLabel
                  collectionName={field.name}
                  values={values.packPrices}
                  fieldsConfiguration={field.fieldsConfiguration}
                  isHorizontal
                />
              ) : (<Field type={field.type} name={field.name} />)}
            </Label>
          ))}
        </>
      )}
    </Form>
  );
}
