import React from 'react';
import {
  Row, Col, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import styled from 'styled-components';
import { get, isEqual } from 'lodash';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';

import APP_DATA from '@services/appData';
import { RewardsRoutes } from '@pages/routes';
import {
  useCurrentApplication,
  FormGroup,
  useFormGroup,
} from '@hooks';

import { Field, SelectField, ServerError } from '@controls/form';
import IconButton from '@controls/buttons';
import RewardContent from '@pages/rewards/RewardContent';
import { persistedCollection } from '@services/utils';

import FaButton, { FaIcon } from './FaButton';
import RemoveButton from './RemoveButton';

const ContentRow = styled(Row)`
  display: flex;
  margin-bottom: 5px;
  align-content: start;
`;

const ActionsCol = styled(Col)`
  display: flex;
  flex-flow: column;
  align-items: start;
  align-self: start;
`;

const { rewardTypes } = APP_DATA;

const Rewards = React.memo(({
  dispatch,
  rewardable,
  rewardItems,
  fixedQuantity,
  maxRewards,
  minRewards,
  hideQuantityUntilSelected = false,
  hideContent = false,
  fieldPath,
  readOnly,
  className,
}) => {
  const path = fieldPath || useFormGroup().generateName('').slice(0, -1);
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const rewardQuickLinkFn = (id) => RewardsRoutes.Items.editPath({ id, applicationId });
  const add = () => dispatch({
    actionType: 'add_reward',
    path,
  });
  const remove = (index) => dispatch({
    actionType: 'remove_reward',
    index,
    path,
  });

  const persistedRewardsCount = persistedCollection(rewardable.rewardAffiliationsAttributes).length;
  const isQuantityFieldDisabled = (rewardItem) => (
    fixedQuantity || (rewardItem && rewardTypes[rewardItem.rewardType].type_metadata.fixed_quantity)
  );

  return (
    <div className={className}>
      <ServerError name="rewardAffiliationsAttributes.list" />

      {(rewardable.rewardAffiliationsAttributes || []).map((affiliation, index) => {
        const rewardItem = rewardItems.find(({ value }) => value === affiliation.itemId);

        return !affiliation._destroy && (
          <FormGroup name={`rewardAffiliationsAttributes[${index}]`} key={affiliation._uuid}>
            <ContentRow>
              <Field name="id" type="hidden" disabled={readOnly} />
              <Col xs={6} className="pe-0 align-self-start">
                <SelectField
                  name="itemId"
                  options={rewardItems}
                  title={get(rewardItem, 'label')}
                  isDisabled={readOnly}
                  quickLinkFn={rewardQuickLinkFn}
                />
              </Col>
              {(!hideQuantityUntilSelected || (hideQuantityUntilSelected && rewardItem)) && (
                <Col xs={4} className="pe-0 align-self-start">
                  <Field
                    name="quantity"
                    type="number"
                    placeholder="Quantity"
                    disabled={readOnly || isQuantityFieldDisabled(rewardItem)}
                  />
                </Col>
              )}
              <ActionsCol xs={2}>
                {!hideContent && (
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="left"
                    overlay={(
                      <Tooltip id={`tooltip-affiliation-${index}-${affiliation.id}`}>
                        {rewardItem && <RewardContent model={rewardItem} wrap />}
                      </Tooltip>
                    )}
                  >
                    <span>
                      <FaButton disabled={readOnly || (!rewardItem || rewardItem.type !== 'Rewards::Container')}>
                        <FaIcon icon={faFolderOpen} />
                      </FaButton>
                    </span>
                  </OverlayTrigger>
                )}
                <RemoveButton
                  className={classNames({ 'pt-2': hideContent })}
                  onClick={() => remove(index)}
                  title="Delete the reward"
                  disabled={readOnly || persistedRewardsCount <= minRewards}
                />
              </ActionsCol>
            </ContentRow>
          </FormGroup>
        );
      })}
      {(!maxRewards || maxRewards > persistedRewardsCount) && (
        <IconButton.New onClick={add} disabled={readOnly}>
          Add Reward
        </IconButton.New>
      )}
    </div>
  );
}, (prev, next) => isEqual(prev.rewardItems, next.rewardItems) && isEqual(prev.rewardable, next.rewardable));

export default Rewards;
