import React, { useState, useEffect, memo } from 'react';

import ReactSelect, { components as defaultComponents } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useField } from 'formik';
import styled from 'styled-components';
import { snakeCase } from '@tripledotstudios/react-core';

import IconTooltip from '@controls/tooltips';

import {
  get,
  isEqual,
  isFunction,
  isNil,
  omit,
} from 'lodash';

import { useFormContext } from '@hooks/useFormContext';
import { useFormGroup } from '@hooks/useFormGroup';

import ServerError from './ServerError';
import EMPTY_OPTION from './emptyOption';
import ALL_OPTION from './allOption';

const omitProps = ['field', 'setValue', 'setTouched', 'onChange'];

const ControlWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

const StyledControl = styled(defaultComponents.Control)`
  width: 100%;
`;

const MemoizedSelect = memo(({
  className,
  styles,
  name,
  options,
  includeEmpty,
  includeAll,
  includeAllOption = ALL_OPTION,
  isClearable,
  isDisabled,
  isMulti,
  groupByFn,
  onReload,
  onChange,
  hasPrepopulatedOption,
  defaultValue,
  title,
  onBlur,
  autoFocus,
  onKeyDown,
  field,
  setValue,
  setTouched,
  placeholder,
  filterOption,
  testId,
  quickLinkFn,
  isCreatable,
  isLoading,
  isOptionSelected,
  sharedInputProps,
  components,
  formatOptionLabel,
}) => {
  const SelectComponent = isCreatable ? CreatableSelect : ReactSelect;
  const [resolvedOptions, setResolvedOptions] = useState();
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  let selected = isNil(field) || isNil(field?.value) ? defaultValue : field.value;
  if (isNil(selected) || !resolvedOptions) {
    selected = isMulti ? [] : undefined;
  } else if (isMulti) {
    selected = resolvedOptions.filter((option) => selected.includes(option.value));
  } else {
    [selected] = resolvedOptions.filter((option) => selected === option?.value);
  }

  if (onReload) onReload({ selected });

  useEffect(() => {
    (async () => {
      let newOptions = options;
      if (isFunction(options)) {
        setIsLoadingOptions(true);
        setResolvedOptions([]);
        newOptions = await options();
      }
      if ((isNil(field) || !field.value) && hasPrepopulatedOption) {
        setValue(newOptions[0].value);
      }
      if (
        !isMulti || !includeAll
        || (field.value instanceof Array && field.value.length === newOptions.length)
      ) {
        setIsLoadingOptions(false);
        return setResolvedOptions(newOptions);
      }

      setIsLoadingOptions(false);
      return setResolvedOptions([includeAllOption, ...newOptions]);
    })();
  }, isFunction(options) ? [options] : [JSON.stringify(options)]);

  const handleChange = async (event) => {
    if (onChange) await onChange(event);
    if (!event) return setValue('');
    if (!(event instanceof Array)) return setValue(event.value);

    let value = event.map((el) => el.value);
    if (!includeAll) return setValue(value);

    const optionsWithValues = resolvedOptions.filter(({ isAllOption }) => !isAllOption);
    if (value.includes(includeAllOption.value) || value.length === optionsWithValues.length) {
      value = optionsWithValues.map((el) => el.value);
      setResolvedOptions(optionsWithValues);
    } else if (!resolvedOptions.includes(includeAllOption)) {
      setResolvedOptions([includeAllOption, ...resolvedOptions]);
    }
    return setValue(value);
  };

  const handleCreate = (value) => {
    setResolvedOptions([...resolvedOptions, { value, label: value }]);
    return setValue([...selected.map((el) => el.value), value]);
  };

  const handleBlur = (event) => {
    if (onBlur) onBlur(event);

    setTouched(true);
  };

  const groupedResolvedOptions = isFunction(groupByFn) ? groupByFn(resolvedOptions) : resolvedOptions;

  // eslint-disable-next-line react/no-unstable-nested-components
  const Control = ({ children, ...props }) => (
    isMulti || !isFunction(quickLinkFn) ? (
      <defaultComponents.Control {...props}>
        {children}
      </defaultComponents.Control>
    ) : (
      <ControlWrapper>
        <StyledControl {...props}>
          {children}
        </StyledControl>
        {selected && selected.value && (
          <IconTooltip.QuickLink to={quickLinkFn(selected.value, selected)} />
        )}
      </ControlWrapper>
    )
  );

  return resolvedOptions ? (
    <div title={title} data-testid={testId || snakeCase(name)} className={className}>
      <SelectComponent
        styles={styles}
        value={selected === undefined ? null : selected}
        options={!isMulti && includeEmpty ? [EMPTY_OPTION, ...groupedResolvedOptions] : groupedResolvedOptions}
        isClearable={isClearable}
        isDisabled={isNil(isDisabled) ? sharedInputProps.disabled : isDisabled}
        isMulti={isMulti}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder || (includeEmpty && EMPTY_OPTION.label)}
        filterOption={filterOption}
        components={{ Control, ...components }}
        className="react-select"
        classNamePrefix="react-select"
        isLoading={isLoading || isLoadingOptions}
        isOptionSelected={isOptionSelected}
        onCreateOption={handleCreate}
        formatOptionLabel={formatOptionLabel}
      />
      <ServerError name={name} />
    </div>
  ) : '';
}, (prev, next) => (
  get(prev.field, 'name') === get(next.field, 'name') && get(prev.field, 'value') === get(next.field, 'value')
  && isEqual(omit(next, omitProps), omit(prev, omitProps))
));

export default function SelectFiled({ name, ...props }) {
  const { generateName } = useFormGroup();
  const { sharedInputProps } = useFormContext();
  const [field, , { setTouched, setValue }] = useField(generateName(name));

  return (
    <MemoizedSelect
      {...{
        ...props, field, setValue, setTouched, name, sharedInputProps,
      }}
    />
  );
}
