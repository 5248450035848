import React, { useState, useMemo } from 'react';
import { Spinner, ButtonToolbar, useRouter } from '@tripledotstudios/react-core';
import IconButton from '@controls/buttons';

import { DailyRewardsRoutes as Routes } from '@pages/routes';
import { useQuery, useConfirm } from '@hooks';

import {
  PageHeader,
  Pagination,
  InLiveColumn,
  InUseColumn,
  TIMESTAMP_COLUMNS,
  LabelsColumn,
  ReactTable,
  DuplicationModal,
} from '@pages/common';

import { isInUse } from '@pages/common/InUse';

import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import Filter from './Filter';

const columnsFactory = ({ applicationId, onDelete, setDuplicatedRecord }) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  InLiveColumn,
  InUseColumn,
  LabelsColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, inUse } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={Routes.Configs.editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        <IconButton.Delete onClick={() => onDelete(id)} disabled={isInUse(inUse)} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { isLoading, response, refetch } = useQuery(Routes.Configs.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Daily Rewards Config',
    actionName: 'delete',
    refetch,
  });

  const onDuplicate = (values) => Routes.Configs.duplicateRequest({ ...values, applicationId });

  const onDelete = (id) => {
    confirm.showConfirmation({ title: 'Daily Rewards Config will be deleted and not available for work. Continue?' })
      .then(() => Routes.Configs.deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({ applicationId, onDelete, setDuplicatedRecord }), []);

  return response && (
    <div>
      <PageHeader title="Daily Reward Configs" filter={<Filter />}>
        <IconButton.New to={Routes.Configs.newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
            defaultSort={{ id: 'id', desc: true }}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Daily Rewards Config"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={onDuplicate}
        title="Duplicate Daily Rewards Config"
      />
    </div>
  );
}
