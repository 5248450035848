import React from 'react';
import styled from 'styled-components';

import { DIRECTIONS } from './constants';
import TreePanel from './TreePanel';

const Panel = styled.div`
  width: 50%;
  padding: 0 10px;
`;

const LeftPanel = styled(Panel)`
  border-right: 1px solid gray;
`;

export default function VersionContent({
  associationName, disableHighlight, direction, splitView, versionTree,
}) {
  if (splitView) {
    const forwardProps = { versionTree, associationName, disableHighlight };
    return (
      <div className="d-flex">
        <LeftPanel>
          <TreePanel {...forwardProps} direction={DIRECTIONS.BEFORE} title="Before" />
        </LeftPanel>
        <Panel>
          <TreePanel {...forwardProps} direction={DIRECTIONS.AFTER} title="After" />
        </Panel>
      </div>
    );
  }
  return (
    <TreePanel {...{ versionTree, associationName }} disableHighlight direction={direction} />
  );
}
