import React from 'react';

import { Label, CheckboxField } from '@controls/form';
import AllowEditLayoutBanksInUse from './shared/AllowEditLayoutBanksInUse';

export default function WoodokuSagaTesterSettingsFields() {
  return (
    <>
      <Label text="Allow edit Figure Sets in use">
        <CheckboxField name="editInUseFigureSets" />
      </Label>
      <AllowEditLayoutBanksInUse />
    </>
  );
}
