import React from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { TileRoutes } from '@pages/routes';
import { useQuery } from '@hooks';
import Form from './Form';

export default function Edit() {
  const { query: { id, applicationId } } = useRouter();
  const { response, isLoading, refetch } = useQuery(TileRoutes.ChapterSetImports.editRequest, { applicationId, id });

  if (isLoading) return <Spinner />;
  return <Form disabled data={response} refetch={refetch} />;
}
