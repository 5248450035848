import React from 'react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@tripledotstudios/react-core';

import { AuditRoutes } from '@pages/routes';

export default function ActivityLogButton({
  applicationId, entityType, id, ...rest
}) {
  const path = AuditRoutes.ApplicationLogs.indexPath({ applicationId });
  const params = new URLSearchParams({
    'filter[applicationIdIn][]': applicationId,
    'filter[recordTypeIn][]': entityType,
    'filter[itemIdIn][]': id,
  }).toString();

  return (
    <IconButton
      to={[path, params].join('?')}
      target="_blank"
      rel="noopener noreferrer"
      variant="outline-primary"
      icon={faList}
      {...rest}
    >
      Activity Log
    </IconButton>
  );
}
