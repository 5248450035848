import React from 'react';
import { get } from 'lodash';

import { PageSection } from '@pages/common';
import { dirtyAttributes } from '@services/dirtyAttributes';
import { serializableAttributes } from '@services/difficultySchemas';

import BasedOnUsageFields from './BasedOnUsageFields';

const fieldsMapping = {
  FieldsComponent: BasedOnUsageFields,
  attibutesName: 'basedOnUsageConfigAttributes',
};

export default function Fields({ values, disabled }) {
  const { FieldsComponent, attibutesName } = fieldsMapping;

  const dirtyFields = dirtyAttributes(
    get(values.originalData, attibutesName),
    get(serializableAttributes(values), attibutesName),
  );

  const isDirty = (field) => dirtyFields.indexOf(field) > -1;

  return (
    <PageSection title="Schema Settings">
      {dirtyFields.length > 0 && (
        <span className="text-warning">
          Will not be saved without &quot;Generate the difficulty matrix&quot; operation
        </span>
      )}
      {FieldsComponent && <FieldsComponent isDirty={isDirty} disabled={disabled} />}
    </PageSection>
  );
}
