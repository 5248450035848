import React, { useMemo, useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  DuplicationModal,
  PageHeader,
  Pagination,
  ReactTable,
  Filter,
} from '@pages/common';
import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';
import ColumnsFactory from './ColumnsFactory';

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(MergeRoutes.Events.LevelSets.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Reward Track Configuration',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm
      .showConfirmation({
        title: `Reward Track Configuration '${name}' will be deleted and not available for work. Continue?`,
      })
      .then(() => MergeRoutes.Events.LevelSets.deleteRequest({ id, applicationId: query.applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({
    query, setDuplicatedRecord, onDelete,
  }), []);

  return response && (
    <div>
      <PageHeader
        title="Reward Track Configurations"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
      >
        <IconButton.New to={MergeRoutes.Events.LevelSets.newPath({ applicationId: query.applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Reward Track Configuration"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => (
          MergeRoutes.Events.LevelSets.duplicateRequest({ ...values, applicationId: query.applicationId })
        )}
        title="Duplicate Reward Track Configuration"
        applicationId={query.applicationId}
      />
      <Pagination
        pageCount={response ? response._meta.pages : 1}
      />
    </div>
  );
}
