import React from 'react';
import { Col } from 'react-bootstrap';

import { fromClassNameToOptions } from '@services/enums';
import {
  Filter,
  TextFilter,
  SelectFilter,
  StatusFilter,
} from '@pages/common';

export default function AssetKeysFilter({ assetTypeOptions }) {
  const assetStatusOptions = fromClassNameToOptions(
    'AssetsKeys::Statuses',
  );

  return (
    <Filter
      defaultComponents={['availabilityStateIn', 'createdByMe', 'labels', 'namedRuleGroup', 'id']}
    >
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="uidContAny"
              label="UID = "
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <StatusFilter
              name="statusIn"
              label="Status"
              options={assetStatusOptions}
              updateFilterField={updateFilterField}
            />
          </Col>
          {assetTypeOptions.length > 0 && (
            <Col md={6}>
              <SelectFilter
                name="filteringTypesValueIn"
                label="Type = "
                options={assetTypeOptions}
                includeEmpty="No type"
                updateFilterField={updateFilterField}
              />
            </Col>
          )}
        </>
      )}
    </Filter>
  );
}
