import React from 'react';

import styled from 'styled-components';

import { Col, Row } from 'react-bootstrap';
import { FaButton, PageSection } from '@pages/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useFormContext } from '@hooks';

const MoveDownButton = styled(FaButton)`
  margin-top: 10px;
`;

const ShiftPositionControl = styled.div`
  display: flex;
  flex-flow: column;
`;

export default function MissionPositionCol({ mission, missionsCount, missionIndex }) {
  const { dispatch, meta } = useFormContext();

  const moveUp = async (index) => dispatch({
    type: 'moveUp', itemsNames: ['missionsAttributes'], index, meta,
  });
  const moveDown = async (index) => dispatch({
    type: 'moveDown', itemsNames: ['missionsAttributes'], index, meta,
  });

  return (
    <Col xs="1">
      <Row>
        <Col xs="6" className="text-center">
          <ShiftPositionControl>
            <FaButton onClick={() => moveUp(missionIndex)} disabled={missionIndex === 0}>
              <FontAwesomeIcon icon={faChevronUp} />
            </FaButton>
            <MoveDownButton
              onClick={() => moveDown(missionIndex)}
              disabled={missionIndex === missionsCount - 1}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </MoveDownButton>
          </ShiftPositionControl>
        </Col>
        <Col xs="6" className="text-end">
          <PageSection title={mission.position} />
        </Col>
      </Row>
    </Col>
  );
}
