import React from 'react';
import { InLiveBadge, InLiveColumnHeader, Badge } from '@pages/common';

const AvailabilityStateBadge = {
  id: 'availability_header',
  Header: () => <InLiveColumnHeader />,
  Cell: ({ row: { original: { availabilityState } } }) => (
    availabilityState === null
      ? <Badge bg="warning">Pending sync</Badge>
      : <InLiveBadge inLive={availabilityState} />
  ),
};

export default AvailabilityStateBadge;
