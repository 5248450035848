import React from 'react';
import { useTheme } from 'styled-components';
import { Sidebar } from '@tripledotstudios/react-core';

import { faListOl, faFont, faTable } from '@fortawesome/free-solid-svg-icons';

import { WooAspenRoutes, FigureSetsRoutes } from '@pages/routes';

import {
  ApplicationItemSet,
  AssetsNavigationItem,
  AdsNavigationSection,
  RewardsNavigationItem,
} from '../common';

export default function Aspen({ applicationId, routingScope, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="woo/aspen"
      routingScope={routingScope}
      minimized={minimized}
    >
      <Sidebar.SectionItem
        icon={faListOl}
        title="Levels & Chapters"
        color={theme.yellow}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={WooAspenRoutes.Banks.indexPath({ applicationId })}
          title="Layout Banks"
        />
        <Sidebar.LinkItem
          to={WooAspenRoutes.ChapterSets.indexPath({ applicationId })}
          title="Chapter Sets"
        />
        <Sidebar.LinkItem
          to={WooAspenRoutes.RewardSets.indexPath({ applicationId })}
          title="Reward Schema Sets"
        />
        <Sidebar.LinkItem
          to={WooAspenRoutes.PlayOnPopUps.indexPath({ applicationId })}
          title="Play on pop-ups"
        />
        <Sidebar.LinkItem
          to={WooAspenRoutes.PurchaseConfigs.indexPath({ applicationId })}
          title="Purchase Configs"
        />
        <Sidebar.LinkItem
          to={WooAspenRoutes.Chapters.Pictures.indexPath({ applicationId })}
          title="Pictures"
        />
      </Sidebar.SectionItem>

      <Sidebar.SectionItem
        icon={faTable}
        title="Figure Sets"
        color={theme.orange}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={FigureSetsRoutes.Sets.indexPath({ applicationId })}
          title="Sets"
        />
        <Sidebar.LinkItem
          to={FigureSetsRoutes.Shapes.indexPath({ applicationId })}
          title="Shapes"
        />
      </Sidebar.SectionItem>

      <Sidebar.LinkItem
        to={WooAspenRoutes.LiveOpsTokens.indexPath({ applicationId })}
        icon={faFont}
        title="LiveOps Tokens"
        color={theme.cornflowerBlue}
        minimized={minimized}
      />

      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />
      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
