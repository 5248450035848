import React from 'react';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

const rgb = (hexString) => {
  if (hexString.length === 3) {
    // eslint-disable-next-line no-param-reassign
    hexString = hexString.split('').map((l) => l + l).join('');
  }
  const hex = Number(`0x${hexString}`);
  const b = hex % 0x100;
  const g = (hex % 0x10000) - b;
  const r = hex - g - b;

  return { r: r / 0x10000, g: g / 0x100, b };
};

const variantForHex = (hexString) => {
  const colors = rgb(hexString);
  if ((colors.r * 0.299) + (colors.g * 0.587) + (colors.b * 0.114) > 186) {
    return 'dark';
  }
  return 'light';
};

const StyledBadge = styled(Badge)`
  font-size: 1em;
  background-color: #${(props) => props.$hexColor} !important;
`;

export default function ColoredBadge({
  hexColor, text, pill = true, children, ...props
}) {
  return (
    <StyledBadge pill={pill} $hexColor={hexColor} text={variantForHex(hexColor)} {...props}>
      {text}
      {children}
    </StyledBadge>
  );
}
