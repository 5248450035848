import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { useFormContext, useFormGroup } from '@hooks';
import IconButton from '@controls/buttons';

import ServerError from './ServerError';

export default function FileField({
  name, accept, disabled, downloadUrl, outputFilename, fullError, multilineError, className,
}) {
  const { setFieldValue, applySharedInputProps } = useFormContext();

  const { generateName } = useFormGroup();
  const fullName = generateName(name);

  const onChange = (e) => {
    const image = e.target.files[0];
    setFieldValue(fullName, image);
  };

  return (
    <Row className={className}>
      <Col sm={downloadUrl ? 9 : 12}>
        <input
          type="file"
          className="form-control"
          name={fullName}
          onChange={onChange}
          accept={accept}
          {...applySharedInputProps({ disabled })}
        />
      </Col>
      <ServerError name={fullName} full={fullError} multiline={multilineError} />
      {downloadUrl && (
        <Col sm={3}>
          <IconButton.Download
            href={downloadUrl}
            download={outputFilename}
            className="d-block"
          />
        </Col>
      )}
    </Row>
  );
}
