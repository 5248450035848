import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-height: 800px;
`;

export default function TableContainer({ children }) {
  return (
    <Container className="table-responsive">
      {children}
    </Container>
  );
}
