import React from 'react';

import SelectField from '@controls/form/SelectField';

const cityOptions = [{ label: 'London', value: 'London' }];

export default function CityRule({ disabled }) {
  return (
    <SelectField
      name="cityNames"
      options={cityOptions}
      isMulti
      isDisabled={disabled}
      testId="city_rule_code"
    />
  );
}
