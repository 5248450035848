import React from 'react';
import { isNil } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { FigureSetsRoutes, DailyChallengesRoutes as Routes } from '@pages/routes';

import {
  useQuery,
  useFormContext,
  FormGroup,
} from '@hooks';

import { Label, SelectField } from '@controls/form';

import LevelBanks from '@pages/journeys/level-banks/EventsBlock';

export const Variant = ({ variantAttributes, variantIndex, disabled }) => {
  const { query } = useRouter();
  const { dispatch } = useFormContext();

  const queryResult = useQuery({
    request: Routes.LevelBanks.indexRequest,
    queryKey: ['dcLevelBanks', query.applicationId],
    staleTime: 60_000,
    additionalRequestParams: { withoutPagination: true },
  });

  const { response: figureSets, isLoading: figureSetsLoading } = useQuery({
    request: FigureSetsRoutes.Sets.indexRequest,
    queryKey: ['figureSets', query.applicationId],
    toSelectOptions: true,
  });

  const onBankAdd = (id) => dispatch({ actionType: 'addLevelBankToDailyChallenge', id, variantIndex });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeLevelBankFromDailyChallenge', id, variantIndex,
  });

  return (
    <>
      <Label required text="Figure Set">
        <SelectField
          name="figureSetId"
          options={figureSets}
          isLoading={figureSetsLoading}
          isDisabled={disabled}
        />
      </Label>

      <LevelBanks
        showLevelsCount={false}
        showRewardedLevels={false}
        routes={Routes}
        banksQuery={queryResult}
        variantAttributes={variantAttributes}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
        disabled={disabled}
      />
    </>
  );
};

export default function Woodoku({ variantAttributes, variantIndex, disabled }) {
  const formGroupName = isNil(variantIndex) ? 'currentVariantAttributes' : `variantsAttributes.${variantIndex}`;

  return (
    <FormGroup name="configAttributes">
      <FormGroup name={formGroupName}>
        <Variant variantAttributes={variantAttributes} variantIndex={variantIndex} disabled={disabled} />
      </FormGroup>
    </FormGroup>
  );
}
