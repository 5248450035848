import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { omit, isEmpty } from 'lodash';

import { Spinner, Alert } from '@tripledotstudios/react-core';

import { MassOperationsRoutes } from '@pages/routes';
import useQuery from '@hooks/useQuery';
import useCurrentApplication from '@hooks/useCurrentApplication';
import { Form } from '@hooks/useFormContext';
import AvailabilityStateFormGroup from '@controls/form/AvailabilityStateFormGroup';
import Label from '@controls/form/Label';
import SelectField from '@controls/form/SelectField';

import editPath from '@services/editPath';
import MassOperationIncompleteModalContent from './MassOperationIncompleteModalContent';

const ModalContent = ({
  statusOptions,
  selectedItems,
  pageModelName,
  modelName,
  currentApplication,
  includeAvailabilityState,
  setItems,
  isSubmitInProgress,
  meta = {},
}) => {
  const { response, isLoading } = useQuery(
    MassOperationsRoutes.indexRequest,
    { applicationId: currentApplication.id, ids: selectedItems, modelName },
  );

  if (isLoading || isSubmitInProgress) return <Spinner />;

  const { data: { items } } = response;
  const { errors = {} } = meta;

  setItems(items);

  const entityPath = (entity) => editPath({
    ...entity,
    application: currentApplication,
    id: entity.id,
    type: modelName,
  });

  return response && (
    <>
      <strong>
        {!isEmpty(errors) && (
          <Alert variant="warning">{`None of ${pageModelName} were updated`}</Alert>
        )}
        {`${pageModelName} to update:`}
      </strong>
      <div className="mb-2">
        {items.map((entity) => (
          <div>
            <Link to={entityPath(entity)} target="_blank">
              {`${modelName} "${entity.name}"`}
            </Link>
            {errors[entity.id] && (
              <p className="text-danger">
                Not valid for a given Mass Update
              </p>
            )}
          </div>
        ))}
      </div>
      {includeAvailabilityState && <AvailabilityStateFormGroup />}

      <Label text="Status">
        <SelectField options={statusOptions} name="status" />
      </Label>
    </>
  );
};

export default function IndexMassOperationsModal({
  pageModelName,
  show,
  setShow,
  selectedItems,
  modelName,
  statusOptions,
  includeAvailabilityState,
  transactional,
  onSuccess,
}) {
  const [items, setItems] = useState([]);
  const [meta, setMeta] = useState({});
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const [failedValidationItems, setFailedValidationItems] = useState([]);

  if (!show) return '';

  const { currentApplication } = useCurrentApplication();
  const { id: applicationId } = currentApplication;

  const closeModal = () => setShow(false);
  const onIncompleteSuccess = () => {
    closeModal();
    onSuccess();
  };

  const onSubmit = (values) => {
    setIsSubmitInProgress(true);
    const requestData = items.map((entity) => ({ ...entity, ...omit(values, 'meta'), applicationId }));

    MassOperationsRoutes.massUpdateRequest({
      applicationId,
      data: requestData,
      modelName,
      transactional,
    }).then(({ data: { _meta, message }, status }) => {
      setIsSubmitInProgress(false);
      if (status === 200) {
        setFailedValidationItems(items.filter((item) => _meta.failedRecordsIds.includes(item.id)));
      } else if (status === 204) {
        closeModal();
        onSuccess();
      } else {
        setMeta(_meta || message);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
    >
      {isEmpty(failedValidationItems) ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>{`Mass update ${pageModelName}`}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={onSubmit}>
            {({ dirty }) => (
              <>
                <Modal.Body>
                  <ModalContent
                    statusOptions={statusOptions}
                    selectedItems={selectedItems}
                    modelName={modelName}
                    pageModelName={pageModelName}
                    currentApplication={currentApplication}
                    setItems={setItems}
                    includeAvailabilityState={includeAvailabilityState}
                    meta={meta}
                    isSubmitInProgress={isSubmitInProgress}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeModal}>Cancel</Button>
                  <Button variant="primary" type="submit" disabled={!dirty}>Save</Button>
                </Modal.Footer>
              </>
            )}
          </Form>
        </>
      ) : (
        <MassOperationIncompleteModalContent
          items={failedValidationItems}
          pageModelName={pageModelName}
          modelName={modelName}
          currentApplication={currentApplication}
          onConfirm={onIncompleteSuccess}
        />
      )}
    </Modal>
  );
}
