import {
  fetchCollection, newResource, editResource, createResource, updateResource, deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/puzzle_time/new_game_popup_configs`;

export function getNewGamePopupConfigs(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function newNewGamePopupConfig(options) {
  return newResource(baseUrl(options), options);
}

export function editNewGamePopupConfig(data, options) {
  return editResource(baseUrl(data), data, options);
}

export function createNewGamePopupConfig(values) {
  return createResource(baseUrl(values), values);
}

export function updateNewGamePopupConfig(values) {
  return updateResource(baseUrl(values), values);
}

export function deleteNewGamePopupConfig(values) {
  return deleteResource(baseUrl(values), values);
}
