import React from 'react';

import EventSchemaCell from './EventSchemaCell';
import WorldSchemaCell from './WorldSchemaCell';

export default function SchemaCell({ row: { original } }) {
  const schema = original.worldSchema || original.eventSchema;
  if (!schema) return 'Schema is missing check your view code';

  return original.worldSchema ? <WorldSchemaCell schema={schema} /> : <EventSchemaCell schema={schema} />;
}
