import React, { useState } from 'react';

import { ErrorIcon } from '@pages/common';

import DisabledCell from './DisabledCell';

export default function EditableCell({
  text, isEditable, error, children, className,
}) {
  const [editMode, setEditMode] = useState(false);
  const startEditing = () => setEditMode(true);
  const stopEditing = () => setEditMode(false);

  if (!isEditable) return (<DisabledCell />);

  const onKeyPress = (event) => {
    if (event.key !== 'Enter') return;

    event.preventDefault();
    stopEditing();
  };

  return (
    editMode
      ? <td className={className}>{children({ stopEditing, onKeyPress })}</td>
      : (
        <td className={className} onDoubleClick={startEditing}>
          <div className="d-flex justify-content-between">
            <div>{text}</div>
            {error ? <div><ErrorIcon error={error.join(', ')} /></div> : ''}
          </div>
        </td>
      )
  );
}
