import React from 'react';
import { Modal } from 'react-bootstrap';
import { IconTooltip } from '@tripledotstudios/react-core';

import Legend from '@components/shared/items-comparison/Legend';
import { useI18n } from '@hooks';
import ItemsComparison from './ItemsComparison';

const ItemsModal = ({
  isOpened, handleHide, applicationId, frontendRoutes, rulesWarning,
  modelName, applicationName, localePath,
}) => {
  const { translate } = useI18n();
  return (
    <Modal
      show={isOpened}
      onHide={handleHide}
      size="xl"
    >
      <Modal.Header closeButton className="d-flex justify-content-between">
        <Modal.Title>
          {`Compare ${translate.fallback(`${localePath}.pluralName`)}`}
        </Modal.Title>
        <IconTooltip.Help text="This feature compares only parameters which are displayed below.
        If you see that some parameters are missed and you want to add it to comparison feature,
        feel free to contact GS team"
        />
        <Legend rulesWarning={rulesWarning} />
      </Modal.Header>
      <Modal.Body>
        <ItemsComparison
          applicationId={applicationId}
          frontendRoutes={frontendRoutes}
          localePath={localePath}
          modelName={modelName}
          applicationName={applicationName}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ItemsModal;
