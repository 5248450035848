import React, { useState, useMemo } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
  DuplicationModal,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { AdsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';

const columnsFactory = ({
  editPath, applicationId, onDelete, onDuplicate,
}) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, inUse } } }) => {
      const disabled = isInUse(inUse);

      return (
        <ButtonToolbar>
          <IconButton.Edit to={editPath({ id, applicationId })} minimized />
          <IconButton.Duplicate onClick={() => onDuplicate(id, name)} minimized />
          <IconButton.Delete onClick={() => onDelete(id, name)} disabled={disabled} minimized />
        </ButtonToolbar>
      );
    },
  },
];

export default function Index() {
  const {
    indexRequest, deleteRequest, newPath, editPath,
  } = AdsRoutes.Badges.PlacementBanks;
  const { response, refetch, isLoading } = useQuery(indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Ad Badge Placements Bank',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => {
    confirm.showConfirmation({
      title: `Ad Badge Placements Bank '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const onDuplicate = (id, name) => setDuplicatedRecord({ id, name });

  const columns = useMemo(() => columnsFactory({
    editPath, applicationId, onDelete, onDuplicate,
  }), []);

  return (
    <>
      <PageHeader title="Badge Placement Banks" filter={<Filter />}>
        <IconButton.New to={newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? <Spinner /> : (
        response && (
          <>
            <ReactTable
              columns={columns}
              data={response.items}
            />
            <DuplicationModal
              entityName="Badge Placements Bank"
              title="Duplicate Badge Placements Bank"
              record={duplicatedRecord}
              handleClose={() => setDuplicatedRecord(null)}
              onSubmit={({ id, name }) => (
                AdsRoutes.Badges.PlacementBanks.duplicateRequest({ id, name, applicationId })
              )}
            />
            <Pagination
              pageCount={response._meta.pages}
            />
          </>
        )
      )}
    </>
  );
}
