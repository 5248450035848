import React from 'react';
import { Alert, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import {
  Label, Field, FormButtonsGroup, StaticFieldsFormGroup, ServerError,
} from '@controls/form';
import { PageHeader, PageSection } from '@pages/common';
import { Form, FormGroup } from '@hooks';
import { MyPetCafeRoutes } from '@pages/routes';

import Section from './conditions/Section';
import reducer from './formReducer';

export default function UnlockRequirementsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const { modelsLocales: { myPetCafe: { unlockRequirements: { configuration: locale } } } } = APP_DATA;

  return (
    <Form initialValues={data} onSubmit={onSubmit} reducer={reducer}>
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Unlock Requirement`}>
            <FormButtonsGroup
              cancelButtonPath={MyPetCafeRoutes.UnlockRequirements.indexPath({ applicationId })}
            />
          </PageHeader>

          <Label text="Name" required>
            <Field type="text" name="name" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <PageSection
            title="Conditions"
            tooltip={locale.conditionsSection.tooltip}
          />
          <ServerError as={Alert} name="conditionsSectionAttributes" />
          <FormGroup name="conditionsSectionAttributes">
            <Section
              attributes={values.conditionsSectionAttributes}
              nestingLevel={0}
            />
          </FormGroup>
        </>
      )}
    </Form>
  );
}
