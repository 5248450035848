import React from 'react';
import { Col } from 'react-bootstrap';

import SelectFilter from './SelectFilter';

const options = [
  { label: 'In Use', value: true },
  { label: 'Not In Use', value: false },
];

export default function InUseSelectFilter({ updateFilterField }) {
  return (
    <Col md={6}>
      <SelectFilter
        name="used"
        label="In Use"
        updateFilterField={updateFilterField}
        options={options}
      />
    </Col>
  );
}
