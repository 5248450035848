import { useCallback } from 'react';
import { requestWithToSelectOptions } from './toSelectOptions';
import { buildQuickLinkFn } from './fields-factory';

const selectOptionsWithQuickLink = (route, applicationId, params = {}, options = {}) => ({
  options: useCallback(() => requestWithToSelectOptions(route.indexRequest, applicationId, params, options), []),
  quickLinkFn: buildQuickLinkFn(route, applicationId),
});

export default selectOptionsWithQuickLink;
