import React, { useMemo } from 'react';
import { ButtonToolbar, Alert, Spinner } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { useQuery, useConfirm } from '@hooks';
import { AbTestingRoutes } from '@pages/routes';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  ReactTable,
  InLiveBadge,
  InLiveColumnHeader,
  StatusBadge,
} from '@pages/common';
import IconButton from '@controls/buttons';

import HomeCard from './HomeCard';

const EXPIRED_EXPERIMENTS_AMOUNT = 5;

const { enums: { AbExperimentStatuses: statuses } } = APP_DATA;

const columnsFactory = ({ applicationId, onArchive }) => [
  { Header: 'Name', accessor: 'name' },
  { Header: 'Ends At', accessor: 'endAt' },
  {
    Header: 'Status',
    Cell: ({ row }) => {
      const { status, statusHumanize } = row.original;

      return (
        <StatusBadge
          options={statuses}
          value={status}
          label={statusHumanize}
        />
      );
    },
  },
  {
    id: 'availability_header',
    Header: () => <InLiveColumnHeader placement="left" />,
    Cell: ({ row: { original: { availabilityStateLive } } }) => <InLiveBadge inLive={availabilityStateLive} />,
  },
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={AbTestingRoutes.Experiments.editPath({ id, applicationId })} minimized />
        <IconButton.Archive onClick={() => onArchive(id, name)} minimized />
      </ButtonToolbar>
    ),
  },
];

const ExperimentsTable = ({ applicationId }) => {
  const confirm = useConfirm();
  const { isLoading, response, refetch } = useQuery(
    AbTestingRoutes.Experiments.indexRequest, {
      applicationId,
      sort: { id: 'end_at', desc: false },
      filter: {
        statusIn: [statuses.INACTIVE],
        allocationEndAtLteq: new Date(),
      },
      per: EXPIRED_EXPERIMENTS_AMOUNT,
    },
  );

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'A/B Experiment',
    actionName: 'archive',
    refetch,
  });

  const onArchive = (id, name) => {
    confirm.showConfirmation({
      title: `A/B Experiment '${name}' will be archived and not available for work. Continue?`,
    })
      .then(() => AbTestingRoutes.Experiments.archiveRequest({ id, applicationId }))
      .then(responseHandler);
  };

  const columns = useMemo(() => columnsFactory({ applicationId, onArchive }), [applicationId]);

  if (isLoading) return <Spinner />;

  return (
    response?.items?.length ? (
      <ReactTable
        columns={columns}
        data={response.items}
        disableSortBy
        className="mb-0"
      />
    ) : (
      <Alert variant="info" className="mb-0">
        <b>There are no expired experiments</b>
      </Alert>
    )
  );
};

export default function ExpiredExperiments({ application, className }) {
  return (
    <HomeCard
      title="Expired A/B Experiments"
      tooltip="Only 5 oldest inactive experiments are shown"
      className={className}
      key={application.id}
    >
      <ExperimentsTable applicationId={application.id} />
    </HomeCard>
  );
}
