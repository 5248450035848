import {
  camelCase, map, omit, reduce,
} from 'lodash';
import { v4 } from 'uuid';

import { DIRECTIONS, EVENTS } from './constants';

const ACTIONS = {
  [EVENTS.CREATE]: 'set',
  [EVENTS.UPDATE]: 'edited',
  [EVENTS.DESTROY]: 'removed',
};

const associationRows = ({
  action, context, changes, direction, disableHighlight, isUpdateRows = false, versionTree, showAssociation,
}) => {
  const associations = omit(versionTree.associations, '_uuid');
  const nestedAssociations = map(associations, ((subTree, associationName) => ({
    _uuid: v4(),
    action,
    attribute: associationName,
    context,
    mergeDiff: isUpdateRows,
    onClick: () => showAssociation({
      associationName,
      direction,
      disableHighlight,
      splitView: isUpdateRows,
      versionTree,
    }),
  })));
  const nestedAssociationNames = Object.keys(associations);
  return reduce(changes, (acc, diff, attribute) => {
    const association = versionTree.trackedAssociations[attribute];
    if (association && nestedAssociationNames.indexOf(camelCase(association.name)) === -1) {
      return [...acc, {
        _uuid: v4(),
        action,
        attribute: camelCase(association.name),
        context,
        mergeDiff: isUpdateRows,
        onClick: () => showAssociation({
          associationName: association.name,
          disableHighlight: true,
          direction: isUpdateRows ? DIRECTIONS.BEFORE : direction,
          splitView: isUpdateRows,
          versionTree,
        }),
      }];
    }
    return acc;
  }, nestedAssociations);
};

export const creationRows = ({
  versionTree, showAssociation, omitAttributes = ['_uuid'], context,
}) => {
  const { trackedAssociations, version } = versionTree;
  const changes = omit(version.objectChanges, omitAttributes);
  return reduce(changes, (acc, diff, attribute) => {
    const association = trackedAssociations[camelCase(attribute)];
    if (!association) {
      return [...acc, {
        _uuid: v4(),
        attribute,
        context,
        value: diff[1],
      }];
    }
    return acc;
  }, []).concat(associationRows({
    context, changes, versionTree, showAssociation, direction: DIRECTIONS.AFTER, disableHighlight: true,
  }));
};

export const destructionRows = ({
  versionTree, showAssociation, omitAttributes = ['_uuid'], context,
}) => {
  const { version, trackedAssociations } = versionTree;
  const changes = omit(version.objectChanges, omitAttributes);
  return reduce(changes, (acc, diff, attribute) => {
    const association = trackedAssociations[camelCase(attribute)];
    if (!association) {
      return [...acc, {
        _uuid: v4(),
        attribute,
        context,
        value: diff[0],
      }];
    }
    return acc;
  }, []).concat(associationRows({
    context, changes, versionTree, showAssociation, direction: DIRECTIONS.BEFORE, disableHighlight: true,
  }));
};

export const updateRows = ({
  versionTree, showAssociation, omitAttributes = ['_uuid'], context, event = EVENTS.UPDATE,
}) => {
  const action = ACTIONS[event];
  const { version, trackedAssociations } = versionTree;
  const changes = omit(version.objectChanges, omitAttributes);
  const associations = associationRows({
    context, changes, versionTree, showAssociation, isUpdateRows: true, action,
  });
  return reduce(changes, (acc, diff, attribute) => {
    const association = trackedAssociations[camelCase(attribute)];
    if (!association) {
      return [...acc, {
        action,
        _uuid: v4(),
        attribute,
        context,
        after: diff[1],
        before: diff[0],
      }];
    }
    return acc;
  }, []).concat(associations);
};
