import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { set } from 'lodash';

import APP_DATA from '@services/appData';

import { PageSection } from '@pages/common';
import {
  SelectField, Field, Label, ServerError,
} from '@controls/form';
import { useFormContext } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';

import SuitCheckbox from '@pages/journeys/level-banks/configuration-forms/solitaire/level-configs/SuitCheckbox';

const NUMBER_OF_CARDS_OPTIONS = [
  { label: 'One', value: 1 },
  { label: 'Two', value: 2 },
  { label: 'Three', value: 3 },
  { label: 'Four', value: 4 },
];

const SUITS = [
  { attribute: 'spadesSelected', label: 'Spades' },
  { attribute: 'heartsSelected', label: 'Hearts' },
  { attribute: 'diamondsSelected', label: 'Diamonds' },
  { attribute: 'clubsSelected', label: 'Clubs' },
];

export default function ChallengeLevelConfig({
  level, levelIndex, limitTypeOptions, cardDenominationOptions,
}) {
  const { enums } = APP_DATA;
  const { dispatch } = useFormContext();
  const targetActions = enums['Solitaire::Journeys::TargetActions'];
  const targetActionOptions = fromClassNameToOptions('Solitaire::Journeys::TargetActions');

  const suitsAttributes = useMemo(() => SUITS.map(({ attribute }) => attribute), []);

  const targetDenominationActions = [
    targetActions.REVEAL,
    targetActions.MOVE_TO_TABLEAU,
    targetActions.MOVE_TO_FOUNDATION,
  ];

  const onNumberOfCardsChange = ({ value }) => dispatch({
    actionType: 'handleNumberOfCardsChanged',
    index: levelIndex,
    value,
    suitsAttributes,
  });

  const onAnySuitChange = (value) => dispatch({
    actionType: 'anySuitChecked',
    index: levelIndex,
    suitsAttributes,
    value,
  });

  const onSuitChange = (value, suitName) => dispatch({
    actionType: 'suitChanged',
    index: levelIndex,
    suitsAttributes,
    value,
    suitName,
  });

  const onTargetActionChange = (event) => {
    set(level, 'actionStackCount', null);
    if (targetDenominationActions.includes(event.value)) return;

    set(level, 'targetDenomination', null);
    set(level, 'anySuitSelected', false);

    suitsAttributes.forEach((attribute) => set(level, attribute, false));
  };

  return (
    <PageSection title="Challenge">
      <Label text="Limit type">
        <SelectField name="limitType" options={limitTypeOptions} />
      </Label>
      {level.limitType > 0 && (
        <Label text="Limit value">
          <Field name="limitValue" type="number" />
        </Label>
      )}
      <Label text="Target action">
        <SelectField
          name="targetAction"
          options={targetActionOptions}
          onChange={onTargetActionChange}
        />
      </Label>
      {targetDenominationActions.includes(level.targetAction) && (
        <>
          <Label text="Target denomination">
            <SelectField name="targetDenomination" options={cardDenominationOptions} />
          </Label>
          <Label text="Number of cards">
            <SelectField
              name="actionStackCount"
              options={NUMBER_OF_CARDS_OPTIONS}
              onChange={onNumberOfCardsChange}
            />
          </Label>
          <Row className="mb-3 px-3">
            <SuitCheckbox
              label="Any suit"
              name="anySuitSelected"
              level={level}
              suitsAttributes={suitsAttributes}
              onChange={onAnySuitChange}
            />
            {SUITS.map(({ attribute, label }) => (
              <SuitCheckbox
                label={label}
                name={attribute}
                level={level}
                suitsAttributes={suitsAttributes}
                onChange={onSuitChange}
              />
            ))}
          </Row>
          <Row>
            <Col>
              <ServerError name="suits" />
            </Col>
          </Row>
        </>
      )}
      {level.targetAction === targetActions.BUILD_A_STACK && (
        <Label text="Stack count">
          <Field name="actionStackCount" type="number" />
        </Label>
      )}
    </PageSection>
  );
}
