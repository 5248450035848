import React from 'react';
import { PageTitle } from '@pages/common';

import {
  RewardItemsEdit, RewardItemsIndex, RewardItemsNew,
} from '@pages/rewards';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function rewards() {
  const scope = '/admin/applications/:applicationId/rewards';

  return [
    <Route
      key={`${scope}/items`}
      path={`${scope}/items`}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Game Items" />
          <RewardItemsIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/items/new`}
      path={`${scope}/items/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Game Item" action="New" />
          <RewardItemsNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/items/:id/edit`}
      path={`${scope}/items/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Game Item" action="Edit" />
          <RewardItemsEdit />
        </RouteContainer>
    )}
    />,
  ];
}
