import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Button, Row, Col } from 'react-bootstrap';
import { useFormContext, useI18n } from '@hooks';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { MissionsRoutes } from '@pages/routes';

import { ServerError } from '@controls/form';
import Select from '@controls/Select';

export default function NewMissionType() {
  const [missionTypeOptions, setMissionTypeOptions] = useState([]);
  const [selectedMissionType, setSelectedMissionType] = useState({});
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');
  const { dispatch, values: { tiersCount } } = useFormContext();
  const { query: { applicationId } } = useRouter();

  const { value } = selectedMissionType;

  const onAddMissionType = () => {
    if (!value) return;

    dispatch({
      actionType: 'addMissionType',
      missionType: selectedMissionType,
      tiersCount,
    });
  };

  const toSelectOptionsFn = (response) => (
    response.data.items.map(({
      id,
      name,
      order,
      valuesType,
      tierParameters,
    }) => (
      {
        value: id,
        label: name,
        valuesType,
        order,
        tierParameters,
      }
    ))
  );

  useEffect(() => {
    (async () => {
      const options = await requestWithToSelectOptions(
        MissionsRoutes.MissionTypes.indexRequest,
        applicationId,
        {},
        { toSelectOptionsFn },
      );

      setMissionTypeOptions(options);
    })();
  }, []);

  return (
    <>
      <Row>
        <Col xs={3}>
          <Select
            selectedValue={value}
            options={missionTypeOptions}
            onChange={setSelectedMissionType}
          />
        </Col>
        <Col xs={2}>
          <Button
            className="mt-1"
            variant="success"
            onClick={onAddMissionType}
          >
            {`Add ${namespaceName} type`}
          </Button>
        </Col>
        <p />
      </Row>
      <Row>
        <Col>
          <ServerError className="mt-2" name="missionTypes" />
        </Col>
      </Row>
    </>
  );
}
