import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { useQuery } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { FigureSetsRoutes, WoodokuRoutes as Routes } from '@pages/routes';

import ExperimentScopedTabs from '@pages/ab-testing/experiments/ExperimentScopedTabs';
import VariantForm from './VariantForm';

const bankStatuses = APP_DATA.enums['Woodoku::BonusLevels::LevelBankStatuses'];

export default function ExperimentScoped() {
  const { response, setResponse } = useQuery(Routes.BonusLevels.Events.experimentScopedRequest);
  const { query } = useRouter();
  const { applicationId } = query;
  const [figureSets, setFigureSets] = useState();
  const [levelBanks, setLevelBanks] = useState();

  useEffect(() => {
    FigureSetsRoutes.Sets.indexRequest({ ...query, withoutPagination: true }).then(({ data }) => setFigureSets(
      data.items.map(({ name, id }) => ({ label: name, value: id })),
    ));
    Routes.BonusLevels.LevelBanks.indexRequest({
      ...query,
      withoutPagination: true,
      filter: { statusIn: [bankStatuses.ACTIVE] },
    }).then(({ data }) => setLevelBanks(
      data.items.map(({ name, id }) => ({ label: name, value: id })),
    ));
  }, []);

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Bonus Levels Event variant',
    actionName: 'update',
    setData: setResponse,
  });

  const handleSubmit = (values) => Routes.BonusLevels.EventVariants.updateVariantsRequest({ applicationId, ...values })
    .then(responseHandler);

  return (
    <ExperimentScopedTabs
      response={response}
      entityName="Bonus Levels Event"
      handleSubmit={handleSubmit}
    >
      {({ variantAttributes, variantIndex }) => (
        <VariantForm
          variantAttributes={variantAttributes}
          variantIndex={variantIndex}
          figureSets={figureSets}
          levelBanks={levelBanks}
        />
      )}
    </ExperimentScopedTabs>
  );
}
