import React from 'react';
import { Col } from 'react-bootstrap';
import { sortBy, filter } from 'lodash';

import { useCurrentApplication } from '@hooks';
import { Filter, TextFilter, SelectFilter } from '@pages/common';

export default function RewardItemsFilter({ rewardTypes }) {
  const { applicationName } = useCurrentApplication();

  const gameRewards = sortBy(
    filter(rewardTypes, (_value, className) => className.includes(`::${applicationName}::`)),
    ['name'],
  );

  const rewardTypeOptions = Object.values(gameRewards).map(
    (type) => ({ label: type.name, value: type.class_name }),
  );

  return (
    <Filter defaultComponents={['createdByMe']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameIContAny"
              label="Item Name = "
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="rewardTypeIn"
              label="Item Type = "
              options={rewardTypeOptions}
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
