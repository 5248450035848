import React, { useState, useEffect } from 'react';
import { Tab } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';
import { validate as uuidValidate } from 'uuid';

import { useCurrentApplication } from '@hooks';
import { AppUserRoutes } from '@pages/routes';

import Tabs from '@controls/Tabs';
import GeneralInfoTab from '@pages/users/app-users/tabs/GeneralInfo';
import CloudsaveTab from '@pages/users/app-users/tabs/Cloudsave';
import EventsTracerTab from '@pages/users/app-users/tabs/EventsTracer';
import SocialNetworkAccounts from '@pages/users/app-users/tabs/SocialNetworkAccounts';
import Purchases from '@pages/users/app-users/tabs/Purchases';
import InitAnalysis from '@pages/users/app-users/tabs/InitAnalysis';
import Rewards from '@pages/users/app-users/tabs/Rewards';

const MANUAL_REWARDS_SUPPORTED_APPLICATIONS = [
  'Merge::Application',
  'MyPetCafe::Application',
  'Woodoku::Application',
  'Tile::Application',
  'Woo::Aspen::Application',
];

export default function Edit() {
  const router = useRouter();
  const { query } = router;
  const { currentApplication } = useCurrentApplication();
  const [data, setData] = useState(null);

  useEffect(() => {
    AppUserRoutes.editRequest(query).then((response) => {
      if (uuidValidate(query.id) && response.status === 200) {
        router.push(router.fullpath.replace(query.id, response.data.id));
      }
      setData(response.data);
    }).catch(() => { });
  }, []);

  // as it takes a while to update query string via router.push, when using public_id instead of id,
  // rendering the component is blocked with flag below
  const queryStringWithUserId = query.id === `${data?.id}`;

  return data && queryStringWithUserId && (
    <Tabs className="mb-3">
      <Tab eventKey="#general_info" title="General Info">
        <GeneralInfoTab data={data} setData={setData} />
      </Tab>
      <Tab eventKey="#cloudsave" title="Cloudsave Details">
        <CloudsaveTab />
      </Tab>
      <Tab eventKey="#events_tracer" title="Events Tracer">
        <EventsTracerTab id={data.id} />
      </Tab>
      <Tab eventKey="#social_network_accounts" title="Social Network Accounts">
        <SocialNetworkAccounts queryString={query} id={data.id} />
      </Tab>
      <Tab eventKey="#purchases" title="Purchases">
        <Purchases id={data.id} />
      </Tab>
      <Tab eventKey="#init_analysis" title="Init call analysis" disabled={!data.devices.length}>
        <InitAnalysis user={data} />
      </Tab>
      {MANUAL_REWARDS_SUPPORTED_APPLICATIONS.includes(currentApplication.type) ? (
        <Tab eventKey="#rewards" title="Rewards">
          <Rewards />
        </Tab>
      ) : ''}
    </Tabs>
  );
}
