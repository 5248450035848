import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import { FaIcon } from '@pages/common/FaButton';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const StyledButton = styled(Button)`
  position: sticky;
  float: right;
  bottom: 5.5em;
  margin-right: -3.4em;

  ${({ $visible }) => !$visible && 'display: none;'}
`;

const SCROLL_THRESHOLD = 300; // pixels

export default function ScrollToTopButton() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => setVisible(window.scrollY > SCROLL_THRESHOLD));
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <StyledButton size="lg" onClick={scrollToTop} $visible={visible}>
      <FaIcon color="white" icon={faArrowUp} />
    </StyledButton>
  );
}
