import React, { useState } from 'react';

import APP_DATA from '@services/appData';

import { useQuery, useCurrentApplication } from '@hooks';
import { AdsRoutes } from '@pages/routes';
import { PageHeader, SearchEnabledFields } from '@pages/common';

const { enums: { 'Ads::ConfigurationStatuses': configurationStatuses } } = APP_DATA;

export default function SearchConfigurationEnabledFields() {
  const { Configurations: { fieldNamesRequest, searchFieldRequest } } = AdsRoutes;

  const [data, setData] = useState();
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { response: fieldsData } = useQuery(fieldNamesRequest);

  const onSubmit = (values) => {
    searchFieldRequest({ ...values, applicationId })
      .then(({ data: rawData }) => setData(rawData));
  };

  return fieldsData && (
    <>
      <PageHeader title="Ads Configuration Fields Search" />
      <SearchEnabledFields
        fieldsData={fieldsData}
        data={data}
        onSubmit={onSubmit}
        localeNamespace="ads.configuration"
        entityStatuses={configurationStatuses}
      />
    </>
  );
}
