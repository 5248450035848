import React from 'react';

import { useQuery } from '@hooks';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';
import { formResponseHandler } from '@requests/responseHandlers';

import Form from './Form';

export default function New() {
  const { newRequest, createRequest } = Routes.MagicPots;
  const { response, setResponse } = useQuery(newRequest);

  const responseHandler = formResponseHandler({
    entityName: 'Magic Pot',
    actionName: 'creation',
    setData: setResponse,
  });

  const onSubmit = (values) => createRequest(values).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      actionName="Create"
    />
  );
}
