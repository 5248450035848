import React from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { MyPetCafeRoutes } from '@pages/routes';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function New() {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(MyPetCafeRoutes.Leaderboards.Configs.newRequest);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Leaderboard',
    actionName: 'create',
    setData: setResponse,
  });

  const onSubmit = (values) => MyPetCafeRoutes.Leaderboards.Configs
    .createRequest({ ...values, applicationId })
    .then(responseHandler);

  if (isLoading) return <Spinner />;

  return (
    <Form data={response} onSubmit={onSubmit} />
  );
}
