import React, { useState, useEffect } from 'react';
import { isEqual, capitalize, camelCase } from 'lodash';

import APP_DATA from '@services/appData';

import { FilesRoutes, JourneyRoutes } from '@pages/routes';
import { useFormContext, useCurrentApplication } from '@hooks';
import { fromClassNamesToOptions } from '@services/enums';
import {
  Label,
  Field,
  CheckboxField,
  SelectField,
} from '@controls/form';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { getLevelLayouts } from '@components/solitaire/level-layouts/requests/levelLayoutsRequests';

import IconButton from '@controls/buttons';

import RewardItems from '@pages/journeys/shared/RewardItems';
// move from shared?
import rewardItemsConfiguration from '@pages/journeys/shared/solitaire/RewardItemsConfigurations';
import LevelsListWrapper from '@components/journeys/banks/levels/LevelsListWrapper';

import LevelConfigurationForms from './solitaire/level-configs';

const { enums, enumLocales } = APP_DATA;
const fileTypesEnum = enums['Solitaire::FileTypesEnum'];

const AddLevelsBlock = React.memo(({ disabled, onEntityAdd }) => {
  const gameModes = enums['Solitaire::Journeys::GameModes'];
  const translatedModes = enumLocales['solitaire/journeys/gameModes'];

  const translateMode = (mode) => translatedModes[camelCase(mode)] || capitalize(mode);

  return (
    <div className="text-end">
      {Object.keys(gameModes).map((mode) => (
        <IconButton.New key={mode} disabled={disabled} className="ms-2" onClick={() => onEntityAdd(gameModes[mode])}>
          {`Add ${translateMode(mode)} level`}
        </IconButton.New>
      ))}
    </div>
  );
}, (prev, next) => prev.disabled === next.disabled);

const Solitaire = React.memo(({ values, isArchived, readOnly: isReadOnly }) => {
  const { dispatch } = useFormContext();
  const { currentApplication: { id: applicationId } } = useCurrentApplication();

  const [levelLayoutOptions, setLevelLayoutOptions] = useState([]);

  const getLevelLayoutOptions = async () => {
    setLevelLayoutOptions(await requestWithToSelectOptions(getLevelLayouts, applicationId));
  };

  useEffect(() => {
    getLevelLayoutOptions();
  }, []);

  const [
    limitTypeOptions,
    cardDenominationOptions,
    levelDifficultyOptions,
    levelBankDifficultyIndicatorOptions,
  ] = fromClassNamesToOptions([
    'Solitaire::Journeys::LimitTypes',
    'Solitaire::Journeys::CardDenominations',
    'Solitaire::Journeys::LevelDifficulties',
    'Solitaire::Journeys::LevelBankDifficultyIndicators',
  ]);

  const [winRateFileOptions, setWinRateFileOptions] = useState([]);

  useEffect(() => {
    requestWithToSelectOptions(
      FilesRoutes.indexRequest,
      applicationId,
      { filter: { fileTypeEq: fileTypesEnum.JOURNEY_LEVEL_WIN_RATE_STATS } },
    ).then(setWinRateFileOptions);
  }, []);

  const { configAttributes: { levelsAttributes } } = values;
  const { modelsLocales: { solitaire: { journeys: { tooltips } } } } = APP_DATA;

  const onLevelAdd = (mode) => dispatch({
    actionType: 'addEmptyEntity',
    options: { mode, rewardsAttributes: [] },
  });

  const onRewardAdd = (uuid) => dispatch({ actionType: 'addSolitaireReward', uuid });

  return (
    <>
      <Label
        text="Level difficulty indicator type"
        tooltipText="An icon that will appear for hard levels: a skull or fire (level_difficulty_type)"
        copyableText="level_difficulty_type"
      >
        <SelectField name="difficultyIndicator" options={levelBankDifficultyIndicatorOptions} />
      </Label>
      <Label
        text="Config for Level Win Rate Stats"
        tooltipText="Select .csv config file with parameters for Journey Level Win Rate Stats"
        copyableText="journey_level_win_rate_stats"
      >
        <SelectField name="winRateStatsConfigId" options={winRateFileOptions} />
      </Label>
      <LevelsListWrapper
        entityAttributes={levelsAttributes}
        onEntityAdd={onLevelAdd}
        attributesName="levelsAttributes"
        metaErrorsPath={['errors', 'configAttributes', 'levelsAttributes']}
        entityNameTranslationPath="journeys.levelBanks.levels.name"
        AddEntityBlock={AddLevelsBlock}
        bulkCopyRoutes={JourneyRoutes.LevelBanks}
        readOnly={isReadOnly}
        disabled={isArchived}
      >
        {({ readOnly, entity: level }) => {
          const {
            _uuid,
            index,
            mode,
            rewardsAttributes,
          } = level;
          const ConfigurationForm = LevelConfigurationForms[mode || 0];

          return (
            <>
              <Label text="Name (optional)">
                <Field name="name" type="text" />
              </Label>
              <Label text="Seed">
                <Field name="seed" type="number" />
              </Label>
              <Label text="Award">
                <RewardItems
                  levelIndex={index}
                  rewards={rewardsAttributes}
                  onAdd={() => onRewardAdd(_uuid)}
                  rewardItemsConfiguration={rewardItemsConfiguration}
                  readOnly={readOnly || isArchived}
                />
              </Label>
              <Label
                text="Deal 3 Cards"
                tooltipText={tooltips.levelBanks.levels.dealThreeCards}
              >
                <CheckboxField name="dealThreeCards" />
              </Label>
              <Label
                text="Difficulty Indicator"
                tooltipText={tooltips.levelBanks.levels.difficultyIndicator}
              >
                <SelectField name="difficultyIndicator" options={levelDifficultyOptions} includeEmpty />
              </Label>

              <div className="mb-2">
                <ConfigurationForm
                  level={level}
                  levelIndex={index}
                  limitTypeOptions={limitTypeOptions}
                  cardDenominationOptions={cardDenominationOptions}
                  levelLayoutOptions={levelLayoutOptions}
                />
              </div>
            </>
          );
        }}
      </LevelsListWrapper>
    </>
  );
}, (prev, next) => isEqual(prev.values, next.values));

export default Solitaire;
