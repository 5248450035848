import React from 'react';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const RedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.variants.dark};
  cursor: pointer;
  &:hover {
    color: rgb(200, 35, 51) !important;
  }
  &.disabled {
    opacity: 0.6;
  }
`;

export default function RemoveIcon({ className, onClick, disabled }) {
  return (
    <div style={{ paddingTop: '0.5rem' }} className={className || 'text-end'}>
      <RedIcon
        icon={faTrashAlt}
        className={disabled && 'disabled'}
        onClick={disabled ? () => {} : onClick}
      />
    </div>
  );
}
