import React from 'react';

import { Collapsible } from '@controls/form';

import CardHeader from '@components/shared/CardHeader';

import { TableHead, TableContainer } from '../shared';
import { Table } from '../shared/styled-components';
import { useLanguagesContext } from '../shared/services/useLanguages';
import Row from './Row';

export default function DiffTable({ keyConfigs, setKeyIdsToSkip }) {
  const { allLanguages } = useLanguagesContext();

  return (
    <Collapsible
      header={<CardHeader text="Translation keys diff" variant="warning" />}
      eventKey="keys-diff"
      className="mb-3"
      opened
    >
      {keyConfigs.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead languages={allLanguages} />
            <tbody>
              {keyConfigs.map((keyConfig) => (
                <Row
                  key={keyConfig._uuid}
                  data={keyConfig}
                  setKeyIdsToSkip={setKeyIdsToSkip}
                />
              ))}
            </tbody>
          </Table>
        </TableContainer>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <h3 className="text-muted mb-0">No difference between Live and Test</h3>
        </div>
      )}
    </Collapsible>
  );
}
