import React from 'react';

import { ExpanderColumn } from '@pages/common';
import { Badge } from 'react-bootstrap';
import Tooltip from '@controls/Tooltip';
import { useI18n } from '@hooks';
import ButtonGroup from './target-template/ButtonGroup';
import RulesList from './target-template/rules/RulesList';
import NameColumn from './target-template/NameColumn';

export default function TargetColumns({
  missionTypeOrders,
  allocationTypeOptions,
  rawEnums,
  supportRules,
}) {
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  const RulesColumn = supportRules ? {
    Header: 'Rules',
    id: 'rules',
    Cell: ({ row: { original: { rulesAttributes } } }) => (
      <RulesList
        rulesAttributes={rulesAttributes}
        rawEnums={rawEnums}
      />
    ),
  } : {};

  return ([
    ExpanderColumn,
    {
      Header: `${namespaceName} type`,
      Cell: ({ row: { original, index } }) => (
        <NameColumn
          index={index}
          rowData={original}
          missionTypeOrders={missionTypeOrders}
        />
      ),
    },
    { Header: 'Weight', accessor: 'weight' },
    {
      Header: 'Allocation Type',
      Cell: ({ row: { original: { allocationType } } }) => {
        const allocationTypeLabel = allocationTypeOptions.find(({ value }) => value === allocationType).label;

        return <span>{allocationTypeLabel}</span>;
      },
    },
    {
      Header: () => (
        <Tooltip text={`User may add parameters in order to differ ${namespaceName} types`}>
          Parameters
        </Tooltip>
      ),
      id: 'parameters',
      Cell: ({ row: { original: { parametersAttributes } } }) => (
        parametersAttributes.filter(({ _destroy }) => !_destroy).map(({ value }) => (
          <Badge bg="secondary" className="p-2 me-2">
            <span>{value}</span>
          </Badge>
        ))
      ),
    },
    RulesColumn,
    {
      Header: 'Actions',
      Cell: ({ row: { original: { _destroy }, index } }) => (
        <ButtonGroup index={index} _destroy={_destroy} />
      ),
    },
  ].filter((configuration) => configuration.Header));
}
