import classnames from 'classnames';
import React from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { camelCase, every, isNil } from 'lodash';

import APP_DATA from '@services/appData';
import { useCurrentApplication } from '@hooks';

import NoResults from '@root/components/shared/NoResults';
import Entities from './Entities';
import BaseEntityRow from './BaseEntityRow';
import ArchivedSettingRow from './ArchivedSettingRow';

const NameTh = styled.th`
  min-width: 300px;
`;

const { enums: { AbExperimentStatuses: abExperimentStatuses } } = APP_DATA;

export default function SettingsTable({ data, fieldName, entityStatuses }) {
  const {
    overrides, experiments, baseEntity, archivedSetting,
  } = data;
  const frontendFieldName = camelCase(fieldName);
  const { currentApplication } = useCurrentApplication();
  const noResults = every([overrides, experiments, baseEntity, archivedSetting], isNil);

  return noResults ? <NoResults /> : (
    <Table bordered striped>
      <thead>
        <tr>
          <NameTh>Name</NameTh>
          <th>Priority</th>
          <th>Status</th>
          <th>Availability</th>
          <th>Rules</th>
        </tr>
      </thead>
      <tbody>
        <Entities
          label="A/B Experiments"
          collection={experiments}
          entityStatuses={abExperimentStatuses}
          application={currentApplication}
        />
        <Entities
          label="Overrides"
          collection={overrides}
          entityStatuses={entityStatuses}
          fieldName={frontendFieldName}
          application={currentApplication}
          trProps={(override) => ({ className: classnames({ 'table-primary': override.overrideAbExperiments }) })}
        />
        <BaseEntityRow baseEntity={baseEntity} fieldName={frontendFieldName} application={currentApplication} />
        <ArchivedSettingRow setting={archivedSetting} application={currentApplication} />
      </tbody>
    </Table>
  );
}
