import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useField } from 'formik';
import { uniq } from 'lodash';
import { Alert } from '@tripledotstudios/react-core';

import { useFormGroup } from '@hooks';
import {
  localizedLabelFactory,
  Field,
  CheckboxField,
  ServerError,
} from '@controls/form';

const LocalizedLabel = localizedLabelFactory(
  'ads.configuration.frequencyRestrictionsConfigurationAttributes.conditions.custom',
);

const KeySelect = ({ rootValues, name, disabled }) => {
  const { generateName } = useFormGroup();
  const [field, , { setTouched, setValue }] = useField(generateName(name));
  const [options, setOptions] = useState([]);

  const buildOptionFrom = (key) => ({ label: key, value: key });

  const handleNewOption = (key) => {
    const newOption = buildOptionFrom(key);

    setOptions((prev) => (prev.includes(newOption) ? prev : [...prev, newOption]));
    setValue(newOption.value);
  };

  const fetchKeysFromSection = (section) => {
    const keys = (section.conditionsAttributes || []).reduce((memo, { key }) => {
      if (key) memo.push(key);

      return memo;
    }, []);

    section.conditionsSectionsAttributes.forEach((nestedSection) => {
      keys.push(...fetchKeysFromSection(nestedSection));
    });

    return keys;
  };

  const fetchKeysFromAttributes = (attributes) => {
    const keys = fetchKeysFromSection(attributes.conditionsSectionAttributes);

    (attributes.overridesAttributes || []).forEach((overrideAttributes) => {
      keys.push(...fetchKeysFromSection(overrideAttributes.conditionsSectionAttributes));
    });

    return keys;
  };

  useEffect(() => {
    const keys = uniq(fetchKeysFromAttributes(rootValues.frequencyRestrictionsAttributes || rootValues));
    const keysOptions = keys.map((key) => buildOptionFrom(key));

    setOptions(keysOptions);
  }, [rootValues]);

  return (
    <>
      <CreatableSelect
        options={options}
        onChange={(event) => setValue(event.value)}
        onBlur={() => setTouched(true)}
        onCreateOption={handleNewOption}
        placeholder="Type or select..."
        noOptionsMessage={() => 'No keys available for reuse. Start typing to create a new one'}
        value={buildOptionFrom(field.value)}
        isDisabled={disabled}
        className="react-select"
        classNamePrefix="react-select"
      />
      <ServerError name={name} />
    </>
  );
};

export default function Custom({ rootValues, disabled }) {
  return (
    <>
      <LocalizedLabel name="key" direction="vertical">
        <KeySelect name="key" rootValues={rootValues} disabled={disabled} />
      </LocalizedLabel>

      <LocalizedLabel name="minCountBetweenInterstitials" direction="vertical">
        <Field type="number" name="minCountBetweenInterstitials" disabled={disabled} />
      </LocalizedLabel>

      <LocalizedLabel name="resetTimerAfterRewardedVideo" direction="vertical">
        <CheckboxField name="resetTimerAfterRewardedVideo" disabled={disabled} />
      </LocalizedLabel>

      <LocalizedLabel name="resetTimerAfterInterstitial" direction="vertical">
        <CheckboxField name="resetTimerAfterInterstitial" disabled={disabled} />
      </LocalizedLabel>

      <Alert variant="info">
        <span>
          <b>Reset after Interstitial </b>
          is ignored by clients with platform version older than 5.4.1
        </span>
      </Alert>
    </>
  );
}
