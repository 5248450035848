import React from 'react';

import APP_DATA from '@services/appData';

import Index from '@components/journeys/banks/Index';
import { MyPetCafeRoutes } from '@pages/routes';
import { fromClassNameToOptions } from '@root/services/enums';
import { useI18n } from '@root/hooks';

const statuses = APP_DATA.enums['MyPetCafe::SoloMissions::EventBankStatuses'];

export default function SoloMissionsEventBanksIndex() {
  const { translate } = useI18n();
  const translationsPath = 'soloMissions.eventBanks';

  return (
    <Index
      statuses={statuses}
      routes={MyPetCafeRoutes.SoloMissions.EventBanks}
      translationsPath={translationsPath}
      massOperationsEnabled
      massOperationsProps={{
        pageModelName: translate.fallback(`${translationsPath}.pluralName`),
        modelName: 'MyPetCafe::SoloMissions::EventBank',
        statusOptions: fromClassNameToOptions('MyPetCafe::SoloMissions::EventBankStatuses'),
      }}
    />
  );
}
