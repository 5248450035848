import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { PaymentsRoutes } from '@pages/routes';
import { SettingsContext } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function Edit() {
  const [data, setData] = useState(null);
  const [settings, setSettings] = useState(null);
  const { query } = useRouter();
  const { editRequest, updateRequest } = PaymentsRoutes.Catalogs;

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Catalog',
    actionName: 'update',
    setData,
  });

  const onSubmit = (values) => updateRequest({ ...values, applicationId: query.applicationId }).then(responseHandler);

  useEffect(() => {
    editRequest(query).then((response) => {
      const { form, settings: formSettings } = response.data;

      setData(form);
      setSettings(formSettings);
    });
  }, []);

  return data && settings && (
    <SettingsContext settings={settings}>
      <Form actionName="Update" data={data} onSubmit={onSubmit} />
    </SettingsContext>
  );
}
