import { get } from 'lodash';

import APP_DATA from '@services/appData';
import { addUuid } from '@services/recursivelyProcessObject';

const buildNewOverride = (placementId) => (
  addUuid({
    id: null,
    enabled: true,
    placementId,
    _destroy: false,
  })
);

const blockTypes = APP_DATA.enums['Ads::Configuration::NewcomersRestrictions::BlockTypes'];

export default function newcomersRestrictionsReducer(state, action) {
  const { overridesPath } = action;
  const overridesAttributes = get(state, overridesPath);

  switch (action.type) {
    case 'addNewcomersRestrictionsOverride': {
      const { placementId } = action;

      overridesAttributes.push(buildNewOverride(placementId));

      break;
    }
    case 'removeNewcomersRestrictionsOverride': {
      const { placementIndex } = action;

      overridesAttributes[placementIndex]._destroy = true;
      break;
    }
    case 'newcomersRestrictionsChangeBlockType': {
      const { value, attributesPath } = action;
      const attributes = get(state, attributesPath);

      if (value === blockTypes.SECONDS) attributes.blockInterstitials = null;
      if (value === blockTypes.INTERSTITIALS) attributes.blockSeconds = null;

      break;
    }
    default: break;
  }

  return state;
}
