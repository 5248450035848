import React, { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { ButtonToolbar, Spinner } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import { useQuery, useConfirm } from '@hooks';

import {
  PageHeader,
  Pagination,
  ReactTable,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { ExternalDataImportRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

const { applications, enums } = APP_DATA;
const statuses = enums['ExternalData::Import::ArtifactStatuses'];

const ARTIFACT_STATUSES_MAPPING = {
  [statuses.SCHEDULED]: 'warning',
  [statuses.PROCESSING]: 'info',
  [statuses.FAILED]: 'danger',
  [statuses.PROCESSED]: 'success',
};

const StatusCell = ({
  status,
  statusHumanize,
  failureReason,
  processingTime,
}) => (
  <>
    <Badge bg={ARTIFACT_STATUSES_MAPPING[status]}>
      {statusHumanize}
    </Badge>

    {(status === statuses.FAILED) && (
      <p className="mb-0">
        <b>Failure Reason: </b>
        {failureReason}
      </p>
    )}

    {(status === statuses.PROCESSED) && (
      <p className="mb-0">
        <b>Processed for: </b>
        {processingTime}
      </p>
    )}
  </>
);

const columnsFactory = ({ onRetry }) => ([
  { Header: 'ID', accessor: 'id' },
  {
    Header: 'Application',
    Cell: ({ row: { original: { applicationId } } }) => (
      applications.find(({ id }) => (id === applicationId)).name
    ),
  },
  { Header: 'Type', accessor: 'humanizedType', disableSortBy: true },
  { Header: 'Source', accessor: 'source', disableSortBy: true },
  {
    Header: 'Status',
    accessor: 'statusHumanize',
    disableSortBy: true,
    Cell: ({ row: { original } }) => <StatusCell {...original} />,
  },
  { Header: 'Scheduled At', accessor: 'createdAt' },
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, status } } }) => (
      <ButtonToolbar>
        {status === statuses.FAILED && (
          <IconButton.Restore onClick={onRetry(id)} minimized>Retry</IconButton.Restore>
        )}
      </ButtonToolbar>
    ),
  },
]);

export default function Index() {
  const { indexRequest, retryRequest } = ExternalDataImportRoutes.Artifacts;
  const { response, refetch, isLoading } = useQuery(indexRequest);
  const confirm = useConfirm();

  const retryRequestHandler = collectionResponseHandlerFactory({
    entityName: 'Import',
    actionName: 'retry',
    refetch,
  });

  const onRetry = (id) => () => {
    confirm.showConfirmation({ title: 'Import will be retried. Continue?' })
      .then(() => retryRequest({ id })
        .then(retryRequestHandler));
  };

  const columns = useMemo(() => columnsFactory({ onRetry }), []);

  return (
    <>
      <PageHeader title="External Data Import Log" />

      {isLoading ? <Spinner /> : (
        response && (
          <>
            <ReactTable
              columns={columns}
              data={response.items}
            />
            <Pagination
              pageCount={response._meta.pages}
            />
          </>
        )
      )}
    </>
  );
}
