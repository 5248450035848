import React from 'react';
import { useQuery } from '@hooks';

import { formResponseHandler } from '@requests/responseHandlers';

import { MachineLearningRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function MachineLearningModelsNew() {
  const { newRequest, createRequest } = Routes.Models;
  const { response, setResponse } = useQuery(newRequest);

  const redirectIfSuccessHandler = formResponseHandler({
    entityName: 'ML Model',
    actionName: 'create',
    setData: setResponse,
  });

  const onSubmit = (values) => createRequest(values).then(redirectIfSuccessHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
