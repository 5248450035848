import { get, set, map } from 'lodash';
import { persistedCollection as getPersistedCollection } from '@services/utils';

import reorderMeta from '@services/reorderMeta';

function isMovable({ index, direction, collection }) {
  const isDownDirectionValid = direction === 'down' && index !== collection.length - 1;
  const isUpDirectionValid = direction === 'up' && index !== 0;

  return isDownDirectionValid || isUpDirectionValid;
}

function collectUUIDs({ state, attributesPath }) {
  return map(get(state, attributesPath), '_uuid');
}

function processMeta({
  state,
  meta,
  attributesPath,
  uuidsBeforeSwap,
}) {
  if (meta.errors) {
    const reorderedUuids = collectUUIDs({ state, attributesPath });

    const [fromIndices, toIndices] = uuidsBeforeSwap.reduce((memo, uuid) => {
      memo[0].push(uuidsBeforeSwap.indexOf(uuid));
      memo[1].push(reorderedUuids.indexOf(uuid));

      return memo;
    }, [[], []]);

    set(state, 'meta.errors', {
      ...meta.errors,
      ...reorderMeta(meta, fromIndices, toIndices, ['errors', ...attributesPath]).errors,
    });
  }
}

function swapElements({
  state,
  field,
  sourcePath,
  targetPath,
  shift,
}) {
  set(state, [...sourcePath, field], get(state, [...sourcePath, field]) + shift);
  set(state, [...targetPath, field], get(state, [...targetPath, field]) - shift);

  const buffer = get(state, sourcePath);
  set(state, sourcePath, get(state, targetPath));
  set(state, targetPath, buffer);
}

function findIndexByUUID({ collection, uuid }) {
  return collection.findIndex(({ _uuid }) => _uuid === uuid);
}

export default function move(direction, state, attributesPath, action, field = 'position', postprocessFn = () => { }) {
  const { index: sourceIndex, meta } = action;
  const collection = get(state, attributesPath);
  const persistedCollection = getPersistedCollection(collection);

  const uuidsBeforeSwap = collectUUIDs({ state, attributesPath });
  const sourcePath = [...attributesPath, sourceIndex];

  const { _uuid: sourceUUID } = get(state, sourcePath);
  const indexOnPersistedCollection = findIndexByUUID({ collection: persistedCollection, uuid: sourceUUID });

  if (!isMovable({ index: indexOnPersistedCollection, direction, collection: persistedCollection })) return;

  const shift = direction === 'down' ? 1 : -1;

  const { _uuid: targetUUID } = persistedCollection[indexOnPersistedCollection + shift];
  const targetIndex = findIndexByUUID({ collection, uuid: targetUUID });
  const targetPath = [...attributesPath, targetIndex];

  swapElements({
    state,
    field,
    sourcePath,
    targetPath,
    shift,
  });

  processMeta({
    state,
    meta,
    attributesPath,
    uuidsBeforeSwap,
  });

  postprocessFn(state, sourcePath, targetPath);
}
