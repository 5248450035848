import React from 'react';
import { matchPath } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import {
  TesterDeviceRoutes, DeviceRoutes, AppUserRoutes, FavouritesRoutes,
} from '@pages/routes';

export default function AppUsersNavigationSection({ applicationId, minimized = false }) {
  const theme = useTheme();

  return (
    <Sidebar.SectionItem icon={faUsers} title="App Users" color={theme.red} minimized={minimized}>
      <Sidebar.LinkItem
        to={DeviceRoutes.indexPath({ applicationId })}
        title="Search"
        isActive={(_, location) => (
          [
            DeviceRoutes.indexRawPath,
            AppUserRoutes.editRawPath,
          ].some((path) => matchPath({ path }, location.pathname))
        )}
      />
      <Sidebar.LinkItem to={TesterDeviceRoutes.indexPath({ applicationId })} title="Test Devices" />
      <Sidebar.LinkItem to={FavouritesRoutes.indexPath({ applicationId })} title="Favourites" />
    </Sidebar.SectionItem>
  );
}
