import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { Form, useCurrentApplication, useRewardOptions } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import { PageHeader, Rewards, Publish } from '@pages/common';
import {
  Label, Field, FormButtonsGroup, SelectField, ServerError, StaticFieldsFormGroup,
} from '@controls/form';

import rewardableReducer from '@reducers/rewardableReducer';

import { PaymentsRoutes } from '@pages/routes';
import { getProductImages } from '@requests/payments/productImages';

import { combineReducers } from '@reducers';

import formReducer from './formReducer';

const { enums, modelsLocales: { product: { tooltips } } } = APP_DATA;
const priceTypesEnum = enums['Payments::PriceTypes'];

export default function ProductForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const { routingScope } = useCurrentApplication();
  const [storeProducts, setStoreProducts] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [publishInfo, setPublishInfo] = useState(data.publishInfo);
  const rewardItems = useRewardOptions();
  const priceTypes = fromClassNameToOptions('Payments::PriceTypes');

  useEffect(() => {
    PaymentsRoutes.StoreProducts.indexRequest({ applicationId, withoutPagination: true })
      .then((response) => setStoreProducts(response.data.items));
    getProductImages({ applicationId, withoutPagination: true })
      .then((response) => setProductImages(response.data.items));
  }, []);

  const onPublish = () => {
    PaymentsRoutes.Products.publishRequest({ applicationId }).then(() => { setPublishInfo(null); });
  };

  const storeProductOptions = storeProducts.map((item) => ({ value: item.id, label: item.storeId }));
  const productImageOptions = productImages.map((item) => ({ value: item.id, label: item.name }));

  const customPriceTypes = priceTypes.map((type) => {
    const customType = { ...type };
    switch (type.value) {
      case 'paid':
        customType.label = 'USD';
        break;
      case 'ad':
      default:
        customType.label = 'Ad watching';
    }
    return customType;
  });

  return (
    data ? (
      <Form initialValues={data} onSubmit={onSubmit} reducer={combineReducers([formReducer, rewardableReducer])}>
        {({ values, dispatch }) => (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Product`}>
              <FormButtonsGroup cancelButtonPath={`${routingScope}/payments/products`} />
            </PageHeader>

            <Publish info={publishInfo} onPublish={onPublish} />
            <Label text="Name">
              <Field type="text" name="name" />
            </Label>
            <hr />
            <Label text="Description">
              <Field type="text" name="description" />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <hr />
            <Row className="px-3">
              <Col xs={4} className="pe-0">
                <b>Content</b>
              </Col>
              <Col xs={6}>
                <Rewards rewardItems={rewardItems} rewardable={values} dispatch={dispatch} />
                <ServerError name="rewardAffiliationsAttributes" />
              </Col>
            </Row>
            <hr />
            <Label text="Image">
              <SelectField name="productImageId" options={productImageOptions} />
            </Label>
            <hr />
            <Label text="Currency">
              <SelectField name="priceType" options={customPriceTypes} />
            </Label>
            <hr />
            {values.priceType === priceTypesEnum.PAID ? (
              <Label text="Store product" tooltipText={tooltips.storeProductId}>
                <SelectField name="storeProductId" options={storeProductOptions} />
              </Label>
            ) : ''}
          </>
        )}
      </Form>
    ) : ''
  );
}
