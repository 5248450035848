import { get } from 'lodash';
import { addUuid } from '@root/services/recursivelyProcessObject';

const buildNewOverride = (placementId) => (
  addUuid({
    id: null,
    placementId,
    _destroy: false,
  })
);

export default function basicRestrictionsReducer(state, action) {
  const { overridesPath } = action;
  const overridesAttributes = get(state, overridesPath);

  switch (action.type) {
    case 'addOverride': {
      const { placementId } = action;

      overridesAttributes.push(buildNewOverride(placementId));

      break;
    }
    case 'removeOverride': {
      const { placementIndex } = action;

      overridesAttributes[placementIndex]._destroy = true;
      break;
    }
    default: break;
  }

  return state;
}
