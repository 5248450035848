import React, { useState, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ButtonToolbar, IconButton } from '@tripledotstudios/react-core';
import { faListCheck, faTrash } from '@fortawesome/free-solid-svg-icons';

import APP_DATA from '@services/appData';
import { useQuery, useConfirm, useCurrentApplication } from '@hooks';
import { LookerAbDropdownItem } from '@controls/buttons/LookerAbButton';
import DropdownToggle from '@root/controls/buttons/dropdown/DropdownToggle';
import DropdownItem from '@root/controls/buttons/dropdown/DropdownItem';
import {
  PageHeader,
  Pagination,
  ReactTable,
  StatusAndAvailabilityColumn,
  RulesList,
  DuplicationModalWithPriority,
  UsageCountColumn,
  PriorityColumn,
  NameColumn,
} from '@pages/common';
import { AbTestingRoutes } from '@pages/routes';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import Filter from './Filter';
import InUseInRulesModal from './InUseInRulesModal';

const { enums: { AbExperimentStatuses: statuses } } = APP_DATA;

const columnsFactory = ({
  onDelete,
  applicationId,
  setDuplicatedRecord,
  setRecordWithDetails,
  onArchive,
}) => ([
  { Header: 'ID', accessor: 'id' },
  NameColumn({ routes: AbTestingRoutes.Experiments, hideIdInTooltip: true }),
  StatusAndAvailabilityColumn({ statuses }),
  PriorityColumn,
  { Header: 'Ends at', accessor: 'endAt' },
  { Header: 'Allocation Starts at', accessor: 'allocationStartAt' },
  { Header: 'Allocation Ends at', accessor: 'allocationEndAt' },
  {
    Header: 'Rules',
    Cell: ({ row: { original: { ruleSectionsAttributes } } }) => <RulesList rules={ruleSectionsAttributes} />,
  },
  UsageCountColumn({ entityTooltipText: 'experiment' }),
  {
    Header: 'Actions',
    Cell: ({
      row: {
        original: {
          id, name, participationsCount, status,
        },
      },
    }) => (
      <ButtonToolbar>
        <IconButton.Edit
          to={AbTestingRoutes.Experiments.editPath({ id, applicationId })}
          minimized
          testId={`edit_button_${name}`}
        />
        <IconButton.Duplicate
          onClick={() => setDuplicatedRecord({ id, name })}
          minimized
          testId={`duplicate_button_${name}`}
        />
        {(status !== statuses.ARCHIVED && status !== statuses.PERMANENTLY_ARCHIVED) && (
          <IconButton.Archive
            onClick={() => onArchive(id, name)}
            minimized
            testId={`archive_button_${name}`}
          />
        )}
        <ButtonToolbar.Divider />
        <Dropdown>
          <DropdownToggle />
          <Dropdown.Menu className="shadow mb-5 bg-body rounded">
            <DropdownItem
              variant="danger"
              icon={faTrash}
              disabled={participationsCount}
              onClick={() => onDelete(id, name)}
            >
              Delete
            </DropdownItem>
            <DropdownItem
              variant="secondary"
              icon={faListCheck}
              onClick={() => setRecordWithDetails({ id, name })}
            >
              Used as a Variant Rule
            </DropdownItem>
            <LookerAbDropdownItem id={id} type="lite" />
            <LookerAbDropdownItem id={id} type="default" />
            <LookerAbDropdownItem id={id} type="cohort" />
          </Dropdown.Menu>
        </Dropdown>
      </ButtonToolbar>
    ),
  },
]);

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const [recordWithDetails, setRecordWithDetails] = useState(null);
  const [recordForArchiving, setRecordForArchiving] = useState(null);
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const [showInUseModal, setShowInUseModal] = useState(false);

  const { response, refetch } = useQuery(AbTestingRoutes.Experiments.indexRequest, { applicationId });

  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'A/B experiment',
    actionName: 'delete',
    refetch,
  });

  const archiveResponseHandler = collectionResponseHandlerFactory({
    entityName: 'A/B Experiment',
    actionName: 'archive',
    refetch,
  });

  const onDelete = (id, name) => {
    confirm.showConfirmation({
      title: `A/B experiment '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => AbTestingRoutes.Experiments.deleteRequest({ applicationId, id })
        .then(responseHandler));
  };

  const modalOnSave = () => {
    AbTestingRoutes.Experiments.archiveRequest({ id: recordForArchiving.id, applicationId, force: true })
      .then(archiveResponseHandler);
    setShowInUseModal(false);
  };

  const modalOnClose = () => {
    setShowInUseModal(false);
  };

  const onArchive = (id, name) => {
    confirm.showConfirmation({
      title: `A/B Experiment '${name}' will be archived and not available for work. Continue?`,
    }).then(() => {
      setRecordForArchiving({ id, name });
      AbTestingRoutes.Experiments.archiveRequest({ id, applicationId })
        .then((data) => {
          if (data.data !== '' && data.data._meta?.errors?.flash?.some((error) => error
            .includes('which uses this experiment as Variant rule'))) {
            setShowInUseModal(true);
          } else {
            archiveResponseHandler(data);
          }
        });
    });
  };

  const columns = useMemo(() => columnsFactory({
    onDelete,
    applicationId,
    setDuplicatedRecord,
    setRecordWithDetails,
    setRecordForArchiving,
    onArchive,
  }), []);

  return response && (
    <div>
      <PageHeader
        title="A/B Experiments"
        userGuideUrlKey="abExperimentsGuideUrl"
        filter={<Filter />}
      >
        <IconButton.New
          to={AbTestingRoutes.Experiments.newPath({ applicationId })}
          testId="header_new_button"
        />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
        defaultSort={{ id: 'priority', desc: true }}
      />
      <DuplicationModalWithPriority
        entityName="A/B Experiment"
        record={duplicatedRecord}
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => (
          AbTestingRoutes.Experiments.duplicateRequest({ ...values, applicationId })
        )}
        entitiesByPriority={response.entitiesByPriority || {}}
      />
      <InUseInRulesModal
        entity={recordWithDetails}
        show={recordWithDetails}
        onClose={() => setRecordWithDetails(null)}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
      <InUseInRulesModal
        entity={recordForArchiving}
        show={showInUseModal}
        onClose={modalOnClose}
        onSave={modalOnSave}
      />
    </div>
  );
}
