/* eslint-disable gs/plz-camel-case-for-action-names */
import { addUuid } from '@services/recursivelyProcessObject';

export default function formReducer(state, action) {
  if (action.type === 'initialize_products') {
    state.catalogProductsAttributes = [];
  }

  if (action.type === 'add_product') {
    state.catalogProductsAttributes.push(addUuid({}));
  }

  if (action.type === 'remove_product') {
    const { index } = action;

    state.catalogProductsAttributes[index]._destroy = true;
  }

  if (action.type === 'update_repeatable') {
    const { index, value } = action;
    const repeatableValue = state.catalogProductsAttributes[index].repeatable;

    state.catalogProductsAttributes[index].default = value;
    state.catalogProductsAttributes[index].repeatable = repeatableValue || value;
  }

  return state;
}
