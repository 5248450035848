import React from 'react';

import requestSchemaOptions from '@components/merge/services/requestEventSchemaOptions';
import ItemConfigurationSetsEdit from '../../item-configuration-sets/Edit';

export default function Edit(props) {
  return (
    <ItemConfigurationSetsEdit
      requestSchemaOptions={requestSchemaOptions}
      {...props}
    />
  );
}
