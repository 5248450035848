import set from 'lodash/set';
import compact from 'lodash/compact';

export default (state, action) => {
  switch (action.type) {
    case 'upload': {
      const rows = action.data.filter((row) => row.length === 2);
      const seedMap = rows.reduce((acc, row) => {
        const seeds = acc[row[0]] || [];
        return { ...acc, [row[0]]: [...seeds, row[1]] };
      }, {});
      state.levelLayoutsAttributes.forEach((level) => {
        if (!Object.hasOwn(seedMap, level.contentHash)) return;

        set(level, 'configAttributes.seeds', compact(seedMap[level.contentHash]));
        set(level, 'configAttributes.seedsChanged', true);
      });
      break;
    }
    case 'clearSeeds': {
      state.levelLayoutsAttributes.forEach((level) => {
        set(level, 'configAttributes.seeds', []);
      });
      break;
    }
    default: break;
  }
  return state;
};
