import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { useQuery } from '@hooks';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function New() {
  const { newRequest, createRequest, indexPath } = Routes.FigureSets;
  const { applicationId } = useRouter().query;
  const { response, setResponse } = useQuery(newRequest, { applicationId });

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Figure Set',
    actionName: 'creation',
    redirectPath: indexPath({ applicationId }),
    setData: setResponse,
  });

  const onSubmit = (values) => createRequest(values).then(redirectIfSuccessHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      actionName="Create"
    />
  );
}
