import React from 'react';
import styled from 'styled-components';

const StyledTh = styled.th`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: ${({ theme }) => `${theme.bootstrap.background} !important`};
  z-index: 2;
  ${({ width }) => `width: ${width};`}
  ${({ stickyHorizontaly }) => stickyHorizontaly && `
    z-index: 3;
    left: 0;
  `}
  border-width: 1px 1px !important;
`;

export default function Th({ width, stickyHorizontaly, children }) {
  return (
    <StyledTh width={width} stickyHorizontaly={stickyHorizontaly}>
      {children}
    </StyledTh>
  );
}
