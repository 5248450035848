/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import {
  get, isEmpty, isEqual, uniq,
} from 'lodash';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { FormGroup } from '@hooks';
import { fromClassNamesToOptions } from '@services/enums';
import {
  CheckboxCell, NumberCell, ItemsListCell, SelectCell, MinutesCell, ItemsSelectCell,
} from './cells';
import {
  SelectFilter, MultiSelectFilter, ResetFilterButton, ItemsFilter, ItemSelectFilter,
} from './filters';

const booleanFilterOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const TableContainer = styled.div`
  height: 500px;

  table {
    border-spacing: 1px;

    thead {
      position: relative;
      z-index: 2;
    }

    td {
      min-width: 200px;
      width: 200px;
      vertical-align: middle;
      height: 54px;
      padding: .25rem .75rem;

      &.table-danger {
        background-color: #f5c6cb !important;
      }

      &.sticky {
        position: -webkit-sticky;
        position: sticky;
        background: ${({ theme }) => theme.bootstrap.background};
        left: 0;
        z-index: 1;
      }

      &.disable-text-select {
        user-select: none;
        -webkit-user-select: none;
      }
    }

    tr.sticky {
      th,td {
        position: -webkit-sticky;
        position: sticky;
        background: ${({ theme }) => theme.bootstrap.background};
        z-index: 2;

        &.sticky-left {
          left: 0;
          z-index: 3;
        }
      }
    }

    tr.sticky-header {
      height: 66px;

      th,td {
        top: 0;
      }
    }

    tr.sticky-filter {
      th,td {
        top: 65px;
      }
    }
  }
`;

const filterColumns = (columns) => columns.filter((c) => !c.skip);

const Row = React.memo(({ row, columns, rowErrors = {} }) => (
  <tr>
    {columns.map((columnGroup) => (
      filterColumns(columnGroup.columns).map((column) => (
        column.Cell(row, rowErrors)
      ))
    ))}
  </tr>
));

export default React.memo(({ values, errors, excludedColumns }) => {
  if (!values || values.length === 0) return '';

  const [filters, setFilters] = useState({});
  const items = values.map((item) => ({ value: item.schemaItemId, label: item.internalId, line: item.line }));
  const lines = uniq(values.map(({ line }) => line)).map((line) => ({ value: line, label: line }));
  const categories = uniq(values.map(({ category }) => category)).map((category) => (
    { value: category, label: category }
  ));
  const [currencies, freeCurrencies] = fromClassNamesToOptions(['Merge::Currencies', 'Merge::FreeCurrencies']);

  const columns = useMemo(
    () => [
      {
        Header: '',
        columns: [
          {
            Header: 'Internal ID',
            className: 'sticky-left',
            Cell: (row, rowErrors) => (
              <td className={`sticky ${isEmpty(rowErrors) ? '' : 'table-danger'}`}>
                {row.internalId}
              </td>
            ),
            FilterCell: (currentFilters) => (
              <ItemSelectFilter
                options={items.map((item) => ({ ...item, value: item.label }))}
                name="internalId"
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          {
            Header: 'Line',
            Cell: (row) => <td>{row.line}</td>,
            FilterCell: (currentFilters) => (
              <MultiSelectFilter
                name="line"
                options={lines}
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          {
            Header: 'Category',
            skip: excludedColumns.indexOf('category') > -1,
            Cell: (row) => <td>{row.category}</td>,
            FilterCell: (currentFilters) => (
              <MultiSelectFilter
                name="category"
                options={categories}
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          { Header: 'Tier', Cell: (row) => <td>{row.tier}</td> },
          {
            Header: 'Merges Into',
            Cell: (row) => <td>{row.mergesInto}</td>,
            FilterCell: (currentFilters) => (
              <ItemSelectFilter
                options={items.map((item) => ({ ...item, value: item.label }))}
                name="mergesInto"
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
        ],
      },
      {
        Header: '',
        columns: [
          {
            Header: 'Spawns upon merging',
            Cell: (row, rowErrors) => (
              <ItemsListCell
                row={row}
                name="mergeSpawnProbability"
                items={items}
                errors={rowErrors}
                isEditable={row.mergesInto}
                validateTotalProbability={false}
              />
            ),
            FilterCell: (currentFilters) => (
              <ItemSelectFilter
                options={items}
                name="mergeSpawnProbability"
                filters={currentFilters}
                setFilters={setFilters}
                operationType="item_in_array"
              />
            ),
          },
        ],
      },
      {
        Header: 'Unbubble',
        columns: [
          {
            Header: 'Unbubble currency',
            Cell: (row, rowErrors) => (
              <SelectCell
                row={row}
                name="breakBubbleCostCurrency"
                options={freeCurrencies}
                errors={rowErrors}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                options={freeCurrencies}
                name="breakBubbleCostCurrency"
                filters={currentFilters}
                setFilters={setFilters}
                includeNotSet
              />
            ),
          },
          {
            Header: 'Unbubble cost',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="breakBubbleCostAmount"
                errors={rowErrors}
              />
            ),
          },
        ],
      },
      {
        Header: 'Collectable',
        columns: [
          {
            Header: 'Collectable?',
            Cell: (row, rowErrors) => (
              <CheckboxCell
                row={row}
                name="collectable"
                errors={rowErrors}
                isEditable={!row.selling && !row.lock && !row.activeProducer && !row.passiveProducer}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                name="collectable"
                options={booleanFilterOptions}
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          {
            Header: 'Collectable Currency',
            accessor: 'collectableCurrency',
            Cell: (row, rowErrors) => (
              <SelectCell
                row={row}
                options={currencies}
                name="collectableCurrency"
                errors={rowErrors}
                isEditable={row.collectable}
              />
            ),
            FilterCell: (currentFilters) => (
              <MultiSelectFilter
                name="collectableCurrency"
                options={currencies}
                filters={currentFilters}
                setFilters={setFilters}
                includeNotSet
              />
            ),
          },
          {
            Header: 'Collectable: Balance value',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="collectableAmount"
                isEditable={row.collectable}
                errors={rowErrors}
              />
            ),
          },
        ],

      },

      {
        Header: 'Selling',
        columns: [
          {
            Header: 'Selling?',
            Cell: (row, rowErrors) => (
              <CheckboxCell
                row={row}
                name="selling"
                isEditable={!row.collectable}
                errors={rowErrors}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                name="selling"
                options={booleanFilterOptions}
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          {
            Header: 'Sale currency',
            Cell: (row, rowErrors) => (
              <SelectCell
                row={row}
                name="sellPriceCurrency"
                errors={rowErrors}
                options={freeCurrencies}
                isEditable={row.selling}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                options={freeCurrencies}
                name="sellPriceCurrency"
                filters={currentFilters}
                setFilters={setFilters}
                includeNotSet
              />
            ),
          },
          {
            Header: 'Sale value',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="sellPriceAmount"
                isEditable={row.selling}
                errors={rowErrors}
              />
            ),
          },
        ],

      },

      {
        Header: 'Purchasable',
        skip: excludedColumns.indexOf('purchasable') > -1,
        columns: [
          {
            Header: 'Purchasable?',
            Cell: (row, rowErrors) => (
              <CheckboxCell
                row={row}
                name="purchasable"
                errors={rowErrors}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                name="purchasable"
                options={booleanFilterOptions}
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          {
            Header: 'Purchase currency',
            Cell: (row, rowErrors) => (
              <SelectCell
                row={row}
                name="purchasePriceCurrency"
                errors={rowErrors}
                options={freeCurrencies}
                isEditable={row.purchasable}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                options={freeCurrencies}
                name="purchasePriceCurrency"
                filters={currentFilters}
                setFilters={setFilters}
                includeNotSet
              />
            ),
          },
          {
            Header: 'Purchase price',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="purchasePriceAmount"
                errors={rowErrors}
                isEditable={row.purchasable}
              />
            ),
          },
          {
            Header: 'Purchase limit',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="purchaseLimit"
                errors={rowErrors}
                isEditable={row.purchasable}
              />
            ),
          },
        ],
      },

      {
        Header: 'Active Producer',
        columns: [
          {
            Header: 'Active Producer',
            Cell: (row, rowErrors) => (
              <CheckboxCell
                row={row}
                name="activeProducer"
                isEditable={!row.collectable && !row.lock}
                errors={rowErrors}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                name="activeProducer"
                options={booleanFilterOptions}
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          {
            Header: 'Items produced',
            Cell: (row, rowErrors) => (
              <ItemsListCell
                row={row}
                name="activeProducerProduceList"
                items={items}
                errors={rowErrors}
                isEditable={row.activeProducer}
              />
            ),
            FilterCell: (currentFilters) => (
              <ItemSelectFilter
                options={items}
                name="activeProducerProduceList"
                filters={currentFilters}
                setFilters={setFilters}
                operationType="item_in_array"
              />
            ),
          },
          {
            Header: 'Active Cldwn (min)',
            Cell: (row, rowErrors) => (
              <MinutesCell
                row={row}
                name="activeProducerChargeCooldown"
                errors={rowErrors}
                isEditable={row.activeProducer}
              />
            ),
          },
          {
            Header: 'Active Cldwn Skip Currency',
            Cell: (row, rowErrors) => (
              <SelectCell
                row={row}
                name="activeProducerChargeSkipCostCurrency"
                errors={rowErrors}
                options={freeCurrencies}
                isEditable={row.activeProducer}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                options={freeCurrencies}
                name="activeProducerChargeSkipCostCurrency"
                filters={currentFilters}
                setFilters={setFilters}
                includeNotSet
              />
            ),
          },
          {
            Header: 'Active Cldwn Skip Cost',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="activeProducerChargeSkipCostAmount"
                errors={rowErrors}
                isEditable={row.activeProducer}
              />
            ),
          },
          {
            Header: 'Capacity',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="activeProducerCapacity"
                errors={rowErrors}
                isEditable={row.activeProducer}
              />
            ),
          },
          {
            Header: 'Max Spawns before self-destruct',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="activeProducerSpawnLimit"
                errors={rowErrors}
                isEditable={row.activeProducer}
              />
            ),
          },
          {
            Header: 'Self-transforming item',
            Cell: (row, rowErrors) => (
              <ItemsSelectCell
                row={row}
                name="activeProducerTransformInto"
                errors={rowErrors}
                isEditable={row.activeProducer}
                items={items}
              />
            ),
            FilterCell: (currentFilters) => (
              <ItemSelectFilter
                options={items}
                name="activeProducerTransformInto"
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          {
            Header: 'Energy Cost',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="activeProducerEnergyCost"
                errors={rowErrors}
                isEditable={row.activeProducer}
              />
            ),
          },
        ],
      },

      {
        Header: 'Passive Producer',
        columns: [
          {
            Header: 'Passive Producer',
            Cell: (row, rowErrors) => (
              <CheckboxCell
                row={row}
                name="passiveProducer"
                isEditable={!row.collectable && !row.lock}
                errors={rowErrors}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                name="passiveProducer"
                options={booleanFilterOptions}
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          {
            Header: 'Items produced',
            Cell: (row, rowErrors) => (
              <ItemsListCell
                row={row}
                name="passiveProducerProduceList"
                errors={rowErrors}
                items={items}
                isEditable={row.passiveProducer}
              />
            ),
            FilterCell: (currentFilters) => (
              <ItemSelectFilter
                options={items}
                name="passiveProducerProduceList"
                filters={currentFilters}
                setFilters={setFilters}
                operationType="item_in_array"
              />
            ),
          },
          {
            Header: 'Passive Spawns before cldwn',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="passiveProducerSpawnsBetweenCooldown"
                errors={rowErrors}
                isEditable={row.passiveProducer}
              />
            ),
          },
          {
            Header: 'Passive Cldwn (min)',
            Cell: (row, rowErrors) => (
              <MinutesCell
                row={row}
                name="passiveProducerChargeCooldown"
                errors={rowErrors}
                isEditable={row.passiveProducer}
              />
            ),
          },
          {
            Header: 'Passive Cldwn Skip Currency',
            Cell: (row, rowErrors) => (
              <SelectCell
                row={row}
                name="passiveProducerChargeSkipCostCurrency"
                errors={rowErrors}
                options={freeCurrencies}
                isEditable={row.passiveProducer}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                options={freeCurrencies}
                name="passiveProducerChargeSkipCostCurrency"
                filters={currentFilters}
                setFilters={setFilters}
                includeNotSet
              />
            ),
          },
          {
            Header: 'Passive Cldwn Skip Cost',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="passiveProducerChargeSkipCostAmount"
                errors={rowErrors}
                isEditable={row.passiveProducer}
              />
            ),
          },
          {
            Header: 'Max Spawns before self-destruct',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="passiveProducerSpawnLimit"
                errors={rowErrors}
                isEditable={row.passiveProducer}
              />
            ),
          },
          {
            Header: 'Max Held items?',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="passiveProducerCapacity"
                errors={rowErrors}
                isEditable={row.passiveProducer}
              />
            ),
          },
          {
            Header: 'Self-transforming item',
            Cell: (row, rowErrors) => (
              <ItemsSelectCell
                row={row}
                name="passiveProducerTransformInto"
                errors={rowErrors}
                isEditable={row.passiveProducer}
                items={items}
              />
            ),
            FilterCell: (currentFilters) => (
              <ItemSelectFilter
                options={items}
                name="passiveProducerTransformInto"
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
        ],
      },

      {
        Header: 'Chest',
        columns: [
          {
            Header: 'Opened Chest?',
            Cell: (row, rowErrors) => (
              <CheckboxCell
                row={row}
                name="openedChest"
                errors={rowErrors}
                isEditable={!row.collectable && !row.lock}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                name="openedChest"
                options={booleanFilterOptions}
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          {
            Header: 'Closed Chest?',
            Cell: (row, rowErrors) => (
              <CheckboxCell
                row={row}
                name="lock"
                errors={rowErrors}
                isEditable={!row.collectable && !row.openedChest && !row.activeProducer && !row.passiveProducer}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                name="lock"
                options={booleanFilterOptions}
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          {
            Header: 'Chest opening time (min)',
            Cell: (row, rowErrors) => (
              <MinutesCell
                row={row}
                name="lockTimeToCountdown"
                errors={rowErrors}
                isEditable={row.lock}
              />
            ),
          },
          {
            Header: 'Transform into after opening',
            Cell: (row, rowErrors) => (
              <ItemsSelectCell
                row={row}
                name="lockTransformsInto"
                errors={rowErrors}
                isEditable={row.lock}
                items={items}
              />
            ),
            FilterCell: (currentFilters) => (
              <ItemSelectFilter
                options={items}
                name="lockTransformsInto"
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
          {
            Header: 'Skip opening currency',
            Cell: (row, rowErrors) => (
              <SelectCell
                row={row}
                name="lockSkipCostCurrency"
                errors={rowErrors}
                isEditable={row.lock}
                options={freeCurrencies}
              />
            ),
            FilterCell: (currentFilters) => (
              <SelectFilter
                name="lockSkipCostCurrency"
                options={freeCurrencies}
                filters={currentFilters}
                setFilters={setFilters}
                includeNotSet
              />
            ),
          },
          {
            Header: 'Skip opening cost',
            Cell: (row, rowErrors) => (
              <NumberCell
                row={row}
                name="lockSkipCostAmount"
                errors={rowErrors}
                isEditable={row.lock}
              />
            ),
          },
        ],
      },

      {
        Header: 'Crate',
        columns: [
          {
            Header: 'Is Crate?',
            // eslint-disable-next-line react/no-unstable-nested-components
            Cell: (row, rowErrors) => (
              <CheckboxCell
                row={row}
                name="crate"
                errors={rowErrors}
                isEditable
              />
            ),
            // eslint-disable-next-line react/no-unstable-nested-components
            FilterCell: (currentFilters) => (
              <SelectFilter
                name="crate"
                options={booleanFilterOptions}
                filters={currentFilters}
                setFilters={setFilters}
              />
            ),
          },
        ],
      },
    ],
    [],
  );

  const filteredValues = ItemsFilter(values, filters);

  const visibleColumns = filterColumns(columns);
  return (
    <TableContainer className="table-responsive">
      <ResetFilterButton
        filters={filters}
        setFilters={setFilters}
      />
      <Table bordered>
        <thead>
          <tr>
            {visibleColumns.map((columnGroup) => (
              <th key={uuidv4()} colSpan={filterColumns(columnGroup.columns).length}>{columnGroup.Header}</th>
            ))}
          </tr>
          <tr className="sticky sticky-header">
            {visibleColumns.map((columnGroup) => (
              filterColumns(columnGroup.columns).map((column) => (
                <th key={uuidv4()} className={column.className || ''}>{column.Header}</th>
              ))
            ))}
          </tr>
          <tr className="sticky sticky-filter">
            {visibleColumns.map((columnGroup) => (
              filterColumns(columnGroup.columns).map((column) => (
                <td key={uuidv4()} className={column.className || ''}>
                  {column.FilterCell && column.FilterCell(filters)}
                </td>
              ))
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredValues.map((row) => {
            const path = `itemConfigurationsAttributes[${row.index}]`;
            const rowErrors = get(errors, path);

            return (
              <FormGroup name={path}>
                <Row key={row._uuid} row={row} columns={visibleColumns} rowErrors={rowErrors} />
              </FormGroup>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
}, (prev, next) => (
  isEqual(prev.values, next.values) && isEqual(prev.errors, next.errors)
));
