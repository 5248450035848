import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { get } from 'lodash';

import APP_DATA from '@services/appData';

import { FormGroup, useQuery, useFormContext } from '@hooks';
import { Label, SelectField, AssetsKeySelectField } from '@controls/form';

import { LocalizationsRoutes, JourneyRoutes } from '@pages/routes';
import LevelBanks from '@pages/journeys/level-banks/EventsBlock';

const { enums } = APP_DATA;

const assetTypesEnum = enums['BlockParty::AssetTypes'];
const bankStatuses = enums['Journeys::LevelBanks::BankStatuses'];

export default function BlockPartyConfigurationForm({
  values, defaultVariant = false, isArchived, variantIndex,
}) {
  const { query: { applicationId } } = useRouter();
  const { dispatch } = useFormContext();

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';
  const variantAttributes = get(values, variantAttributesKey, values);

  const notDestroyedLevelBanks = variantAttributes.levelBankAffiliationsAttributes.filter(({ _destroy }) => !_destroy);
  const addedBankIds = notDestroyedLevelBanks.map(({ levelBankId }) => levelBankId);

  const { response: localizationKeys } = useQuery({
    request: LocalizationsRoutes.Keys.indexRequest,
    queryKey: ['localizationKeys', applicationId],
    toSelectOptions: true,
  });

  const onBankAdd = (id) => dispatch({ actionType: 'addLevelBankToJourney', id, variantIndex });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeLevelBankFromJourney', id, variantIndex,
  });

  const queryResult = useQuery({
    request: JourneyRoutes.LevelBanks.indexRequest,
    additionalRequestParams: {
      withoutPagination: true,
      withoutLevels: true,
      filter: isArchived
        ? { idIn: [variantAttributes.defaultLevelBankId, ...addedBankIds] }
        : { statusIn: [bankStatuses.ACTIVE, bankStatuses.INACTIVE] },
    },
    queryKey: ['journeyLevelBanks', applicationId, isArchived],
    staleTime: 60_000,
  });

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Title Translation Key">
        <SelectField name="titleTranslationKeyId" options={localizationKeys} isDisabled={isArchived} />
      </Label>
      <Label text="Subtitle Translation Key">
        <SelectField name="subtitleTranslationKeyId" options={localizationKeys} isDisabled={isArchived} />
      </Label>

      <Label text="Asset: Main Bundle">
        <AssetsKeySelectField
          name="assetKeyId"
          types={assetTypesEnum.JOURNEY_MAIN}
        />
      </Label>
      <Label text="Asset: Rewards Bundle">
        <AssetsKeySelectField
          name="assetRewardsKeyId"
          types={assetTypesEnum.JOURNEY_REWARDS}
        />
      </Label>
      <Label text="Asset: Journey movies">
        <AssetsKeySelectField
          name="assetMoviesKeyId"
          types={assetTypesEnum.JOURNEY_MOVIES}
        />
      </Label>

      <LevelBanks
        disabled={isArchived}
        variantAttributes={variantAttributes}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
        eventType="event"
        showLevelsCount={false}
        showRewardedLevels={false}
        banksQuery={queryResult}
      />
    </FormGroup>
  );
}
