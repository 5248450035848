import React from 'react';

import { PageSection } from '@pages/common';
import { Label, Field } from '@controls/form';

export default function LinksToUserGuides() {
  return (
    <>
      <PageSection title="Links to User Guides" />

      <Label text="Game Settings" fieldSize={8}>
        <Field name="gameSettingsGuideUrl" />
      </Label>
      <Label text="A/B Experiments" fieldSize={8}>
        <Field name="abExperimentsGuideUrl" />
      </Label>
      <Label text="Missions" fieldSize={8}>
        <Field name="missionsGuideUrl" />
      </Label>
      <Label text="Localizations" fieldSize={8}>
        <Field name="localizationsGuideUrl" />
      </Label>
      <Label text="Rules" fieldSize={8}>
        <Field name="rulesGuideUrl" />
      </Label>
      <Label text="Ads Configurations" fieldSize={8}>
        <Field name="adsConfigurationsGuideUrl" />
      </Label>
      <Label text="Slack Notifications" fieldSize={8}>
        <Field name="slackNotificationsGuideUrl" />
      </Label>
      <Label text="Version Updates" fieldSize={8}>
        <Field name="versionUpdatesGuideUrl" />
      </Label>
      <Label text="Hawk Integration" fieldSize={8}>
        <Field name="liveopsIntegrationGuideUrl" />
      </Label>
    </>
  );
}
