import React, { useState, useMemo } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import {
  useQuery,
  useI18n,
  useConfirm,
  useMassOperations,
} from '@hooks';

import { JourneyRoutes as Routes } from '@pages/routes';

import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import {
  PageHeader,
  Pagination,
  Badge,
  ReactTable,
  TIMESTAMP_COLUMNS,
  RulesList,
  InLiveColumn,
  DuplicationModalWithPriority,
  LabelsColumn,
  PriorityColumn,
} from '@pages/common';

import IconButton from '@controls/buttons';
import { fromClassNameToOptions } from '@services/enums';

import Filter from './JourneysFilter';

const { enums } = APP_DATA;
const statuses = enums['Journeys::Statuses'];

const statusBadgeColor = (status) => {
  const colorMapping = {
    [statuses.ACTIVE]: 'success',
    [statuses.INACTIVE]: 'secondary',
    [statuses.ARCHIVED]: 'secondary',
  };

  return colorMapping[status];
};

const columnsFactory = ({ applicationId, setDuplicatedRecord, onArchive }) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Variant ID', accessor: 'defaultVariantId', disableSortBy: true },
  { Header: 'Client ID', accessor: 'clientId', disableSortBy: true },
  { Header: 'Name', accessor: 'name' },
  LabelsColumn,
  {
    Header: 'Status',
    Cell: ({ row: { original: { status, statusHumanize } } }) => (
      <Badge bg={statusBadgeColor(status)}>{statusHumanize}</Badge>
    ),
  },
  InLiveColumn,
  PriorityColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Duration',
    accessor: 'duration',
    style: { minWidth: '6em' },
  },
  {
    Header: 'Rules',
    Cell: ({ row: { original: { ruleSectionsAttributes } } }) => <RulesList rules={ruleSectionsAttributes} />,
  },
  {
    Header: 'Actions',
    Cell: ({
      row: {
        original: {
          id, name, status, eventType,
        },
      },
    }) => (
      <ButtonToolbar>
        <IconButton.Edit to={Routes.ActivationEvents.editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name, eventType })} minimized />
        {status !== statuses.ARCHIVED && <IconButton.Archive onClick={onArchive(id, name)} minimized />}
      </ButtonToolbar>
    ),
  },
];

const Index = () => {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { translate } = useI18n();
  const { query: { applicationId } } = useRouter();
  const { response, refetch, isLoading } = useQuery(Routes.ActivationEvents.indexRequest);
  const confirm = useConfirm();
  const {
    MassOperationsHeaderButtons,
    MassOperationsModal,
    massOperationsTableProps,
    selectedItems: massSelectedItems,
  } = useMassOperations();

  const modelName = translate.fallback('journeys.activationEvents.name');

  const archiveResponseHandler = collectionResponseHandlerFactory({
    entityName: modelName,
    actionName: 'archive',
    refetch,
  });

  const onArchive = (id, name) => () => {
    confirm.showConfirmation({
      title: `${modelName} '${name}' will be archived and not available for work. Continue?`,
    })
      .then(() => Routes.ActivationEvents.archiveRequest({ applicationId, id })
        .then(archiveResponseHandler));
  };

  const columns = useMemo(() => columnsFactory({ applicationId, setDuplicatedRecord, onArchive }), []);

  return response && (
    <>
      <PageHeader
        title={translate.fallback('journeys.activationEvents.pluralName')}
        filter={<Filter statuses={statuses} />}
        menuButtons={(
          <MassOperationsHeaderButtons>
            <IconButton.LookerJourney
              entityType="Journeys::ActivationEvent"
              entityIds={massSelectedItems}
              disabled={!massSelectedItems.length}
            />
          </MassOperationsHeaderButtons>
        )}
      >
        <IconButton.New to={Routes.ActivationEvents.newPath({ applicationId })} />
      </PageHeader>
      {isLoading ? <Spinner /> : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
            {...massOperationsTableProps}
          />
          <MassOperationsModal
            pageModelName={translate.fallback('journeys.activationEvents.pluralName')}
            modelName="Journeys::ActivationEvent"
            statusOptions={fromClassNameToOptions('Journeys::Statuses')}
            includeAvailabilityState
            transactional
          />
          <DuplicationModalWithPriority
            record={duplicatedRecord}
            entityName={modelName}
            handleClose={() => setDuplicatedRecord(null)}
            onSubmit={(values) => Routes.ActivationEvents.duplicateRequest({ ...values, applicationId })}
            entitiesByPriority={response.entitiesByPriority || {}}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </>
  );
};

export default Index;
