import React from 'react';
import {
  faChevronDown as expandIcon,
  faChevronUp as foldIcon,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ExpandIcon({ value, onClick }) {
  return value
    ? <FontAwesomeIcon onClick={onClick} icon={foldIcon} />
    : <FontAwesomeIcon onClick={onClick} icon={expandIcon} />;
}
