import { get, set, map } from 'lodash';
import reorderMeta from '@services/reorderMeta';
import move from '@services/reorder/move';

function reorderList(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const dndRecords = (state, action) => {
  const {
    path,
    sourceIndex,
    destinationIndex,
  } = action;
  const { meta } = state;
  const elements = get(state, path);
  const uuids = map(elements, '_uuid');
  const records = reorderList(elements, sourceIndex, destinationIndex);

  records.filter(({ _destroy }) => !_destroy).forEach((record, index) => {
    // eslint-disable-next-line no-param-reassign
    record.position = index + 1;

    return record;
  });

  if (meta) {
    const reorderedUuids = map(records, '_uuid');

    const [fromIndices, toIndices] = uuids.reduce((memo, uuid) => {
      memo[0].push(uuids.indexOf(uuid));
      memo[1].push(reorderedUuids.indexOf(uuid));

      return memo;
    }, [[], []]);

    set(meta.errors, path, reorderMeta(get(meta.errors, path), fromIndices, toIndices));
  }

  set(state, path, records);
};

function moveUp(state, attributesPath, action, field, postprocessFn) {
  move('up', state, attributesPath, action, field, postprocessFn);
}
function moveDown(state, attributesPath, action, field, postprocessFn) {
  move('down', state, attributesPath, action, field, postprocessFn);
}

export {
  dndRecords,
  reorderList,
  moveUp,
  moveDown,
};
