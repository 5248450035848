import React from 'react';
import { ListGroup } from 'react-bootstrap';
import {
  get, sortBy, partition, includes,
} from 'lodash';
import styled from 'styled-components';

import { useFormGroup, useFormContext } from '@hooks';

import ReorderableListFieldOption from './ReorderableListFieldOption';

const Hints = styled.div`
  font-size: 9px;
  font-color: inherit;
`;

const Hint = styled.div`
  font-size: inherit;
  font-color: inherit;
  margin-top: 1px;
`;

const ColoredText = styled(Hint)`
  font-color: ${({ color }) => color};
`;

const Emphasis = styled(Hint)`
  font-weight: bold;
`;

const ColoredBackground = styled(Hint)`
  background-color: ${({ color }) => color};
  float: left;
`;

export default function ReorderableListField({ name, disabled, options }) {
  const { values, setFieldValue } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const fieldValue = get(values, fullName) || [];

  const [persistedOptions, notPersistedOptions] = partition(options, (option) => includes(fieldValue, option.value));
  const data = [
    ...sortBy(persistedOptions, (option) => fieldValue.indexOf(option.value)),
    ...notPersistedOptions,
  ];

  const handleReposition = (lhsIndex, rhsIndex) => {
    const newData = data;

    newData.splice(rhsIndex, 0, newData.splice(lhsIndex, 1)[0]);
    setFieldValue(fullName, newData.map((option) => option.value));

    return newData;
  };

  const allOptions = data.map(({ label, value }, index) => (
    <ReorderableListFieldOption
      key={value}
      index={index}
      dndType={fullName}
      label={label}
      isPersisted={includes(fieldValue, value)}
      isDisabled={disabled}
      onReposition={handleReposition}
    />
  ));

  return (
    <>
      <ListGroup>{allOptions}</ListGroup>
      <Hints className="mt-2">
        <ColoredText color="gray">
          <Hint>
            <Emphasis>Usage cheatsheet:</Emphasis>
          </Hint>
          <Hint>
            <ColoredBackground color="#f5c6cb">Red (when enabled)</ColoredBackground>
            <Hint>&nbsp;- entry is currently not persisted in DB thus won&lsquo;t be in a payload</Hint>
          </Hint>
          <Hint>
            <ColoredBackground color="#c3e6cb">Green (when enabled)</ColoredBackground>
            <Hint>&nbsp;- entry is currently persisted in DB and, if saved, will be in a payload</Hint>
          </Hint>
          <Hint>
            <ColoredBackground color="#c6c8ca">Gray (when disabled)</ColoredBackground>
            <Hint>&nbsp;- it is impossible to reorder entries</Hint>
          </Hint>
          <Emphasis>Default order won&apos;t be saved - changes are required</Emphasis>
          <Emphasis>It is possible to prepare data for save via dragging and dropping an entry on itself</Emphasis>
        </ColoredText>
      </Hints>
    </>
  );
}
