import React, { useState, useMemo, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { IconButton, ButtonToolbar, useFlashMessages } from '@tripledotstudios/react-core';

import { useCurrentApplication, useQuery, useConfirm } from '@hooks';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { AssetsRoutes, TileRoutes } from '@pages/routes';
import APP_DATA from '@services/appData';

import BulkDuplicateModal from '@pages/metagame/chapter-based/chapter-sets/BulkDuplicateModal';
import Chapters from '../../metagame/chapter-based/chapter-sets/Chapters';
import ChapterFields from './ChapterFields';
import Levels from './Levels';
import BulkUpdateModal from './BulkUpdateModal';

const MassOperations = ({
  chapterSetId,
  dispatch,
  selectedItems,
  setMassOperationsMode,
  values,
  levelsData,
  rewardItems,
  chapterFieldsData: { assetsKeys, refetchAssetsKeys },
}) => {
  const confirm = useConfirm();
  const { success: flashSuccess, error: flashError } = useFlashMessages();
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const [bulkUpdateRecords, setBulkUpdateRecords] = useState([]);
  const [bulkCopyChaptersRecords, setBulkCopyChaptersRecords] = useState([]);
  const [bulkLiveBundles, setBulkLiveBundles] = useState([]);

  const massBundlesLiveDisabled = useMemo(() => {
    if (selectedItems.length === 0) return true;

    let hasTestBundles = false;
    const selectedBundles = [];
    selectedItems.forEach((chapter) => {
      if (!chapter.configAttributes.uiThemeAssetBundleKeyId) return;

      const bundle = assetsKeys.find((k) => k.id === chapter.configAttributes.uiThemeAssetBundleKeyId);
      if (!bundle.inLive) hasTestBundles = true;
      selectedBundles.push(bundle);
    });
    setBulkLiveBundles(selectedBundles);
    return !hasTestBundles;
  }, [selectedItems]);

  const handleBulkLiveBundles = () => (
    confirm.showConfirmation({
      title: 'The assets will become available for Live entities. Continue?',
      body: (
        <>
          <b>List of Bundles:</b>
          <div className="mt-1 mb-2">
            {(bulkLiveBundles || []).map((bundle) => (
              <div key={bundle.id} className="mb-1">
                {`${bundle.uid} (${bundle.inLive ? 'Live' : 'Test'})`}
              </div>
            ))}
          </div>
        </>
      ),
    }).then(() => (
      AssetsRoutes.Keys.massLiveRequest({
        applicationId,
        ids: bulkLiveBundles.map(({ id }) => id),
      }).then((response) => {
        switch (response.status) {
          case 200:
          case 204:
            refetchAssetsKeys();
            setMassOperationsMode(false);
            flashSuccess('Asset Bundles update has successfully finished');
            break;
          default:
            flashError('Something went wrong');
        }
      })
    ))
  );

  return (
    <>
      <ButtonToolbar className="d-inline-flex">
        <Button
          onClick={() => setBulkUpdateRecords(selectedItems)}
          disabled={selectedItems.length === 0}
        >
          Mass Update
        </Button>
        <IconButton.Duplicate
          variant="primary"
          onClick={() => setBulkCopyChaptersRecords(selectedItems)}
          disabled={selectedItems.length === 0}
        >
          Copy to Chapter Set
        </IconButton.Duplicate>
        <Button
          onClick={handleBulkLiveBundles}
          disabled={massBundlesLiveDisabled}
        >
          Set Bundles to Live
        </Button>
        <IconButton.Cancel onClick={() => setMassOperationsMode(false)} variant="secondary">
          Cancel
        </IconButton.Cancel>
      </ButtonToolbar>
      <div className="text-secondary mt-1">
        Select the chapters for mass update or copy to another Chapter set with checkboxes
      </div>
      <BulkUpdateModal
        chapters={bulkUpdateRecords}
        dispatch={dispatch}
        handleClose={() => {
          setBulkUpdateRecords([]);
          setMassOperationsMode(false);
        }}
        rewardItems={rewardItems}
        values={values}
        modesConfigs={levelsData.modesConfigs}
      />
      <BulkDuplicateModal
        chapterSetId={chapterSetId}
        chapters={bulkCopyChaptersRecords}
        dispatch={dispatch}
        handleClose={() => {
          setBulkCopyChaptersRecords([]);
          setMassOperationsMode(false);
        }}
      />
    </>
  );
};

const TileChapters = ({ chapterSetId }) => {
  const { currentApplication } = useCurrentApplication();
  const [modesConfigs, setModesConfigs] = useState([]);
  const { enums } = APP_DATA;
  const assetTypes = enums['Tile::AssetTypes'];

  const fetchModesConfigsOptions = async () => setModesConfigs(
    await requestWithToSelectOptions(
      TileRoutes.ModesConfigs.indexRequest,
      currentApplication.id,
      { withoutPagination: true },
      {
        toSelectOptionsFn: (response) => (
          response.data.items.map((item) => (
            { value: item.id, label: item.name, tileType: item.tileType }
          ))
        ),
      },
    ),
  );

  useEffect(() => { fetchModesConfigsOptions(); }, []);
  const { response: assetsKeys, refetch: refetchAssetsKeys } = useQuery(
    AssetsRoutes.Keys.indexRequest,
    {
      status: 0,
      applicationId: currentApplication.id,
      withoutPagination: true,
      includeCategories: true,
      filter: {
        withTypesOrIds: { types: [assetTypes.CHAPTER_SET], ids: null },
      },
    },
  );

  return (
    <Chapters
      chapterSetId={chapterSetId}
      chapterFieldsData={{ assetsKeys: assetsKeys ? assetsKeys.items : [], refetchAssetsKeys }}
      ChapterFields={ChapterFields}
      levelsData={{ modesConfigs }}
      Levels={Levels}
      MassOperations={MassOperations}
    />
  );
};

export default TileChapters;
