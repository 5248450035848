import React from 'react';
import { Route } from 'react-router-dom';

import RouteContainer from '@pages/RouteContainer';

import { DailyRewardsRoutes as Routes } from '@pages/routes';
import { PageTitle } from '@pages/common';

import { Banks, Configs } from '@pages/daily-rewards';

export default function dailyRewards() {
  return [
    //
    // Banks
    //
    <Route
      key={Routes.Banks.indexRawPath}
      path={Routes.Banks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Daily Reward Banks" />
          <Banks.Index />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Banks.newRawPath}
      path={Routes.Banks.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="New Daily Rewards Bank" />
          <Banks.New />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Banks.editRawPath}
      path={Routes.Banks.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Daily Rewards Bank" />
          <Banks.Edit />
        </RouteContainer>
      )}
    />,

    //
    //  Configs
    //
    <Route
      key={Routes.Configs.indexRawPath}
      path={Routes.Configs.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Daily Reward Configs" />
          <Configs.Index />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Configs.newRawPath}
      path={Routes.Configs.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="New Daily Rewards Config" />
          <Configs.New />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Configs.editRawPath}
      path={Routes.Configs.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Daily Rewards Config" />
          <Configs.Edit />
        </RouteContainer>
      )}
    />,
  ];
}
