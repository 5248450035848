import React from 'react';
import { useFormGroup } from '@hooks';

import { persistedCollection } from '@root/services/utils';
import { IconButton } from '@tripledotstudios/react-core';
import { ServerError } from '@root/controls/form';
import APP_DATA from '@root/services/appData';
import { filter, sortBy, reduce } from 'lodash';
import Reward from './Reward';
import { FORBIDDEN_REWARD_TYPES, WOO_ASPEN_REWARDS_PREFIX } from './constants';

export default function ConfigRewards({ entity, dispatch, liveOpsTokensOptions }) {
  const persistedRewardsCount = persistedCollection(entity.configsRewardsAttributes).length;
  const path = `${useFormGroup().generateName('')}configsRewardsAttributes`;
  const add = () => dispatch({ type: 'addConfigReward', path });
  const { rewardTypes } = APP_DATA;
  const gameRewards = sortBy(
    filter(rewardTypes, (_value, className) => (
      className.includes(WOO_ASPEN_REWARDS_PREFIX) && !FORBIDDEN_REWARD_TYPES.includes(className)
    )), ['name'],
  );
  const gameRewardsOptions = reduce(gameRewards, ((acc, gameReward) => {
    acc.push({
      label: gameReward.name, value: gameReward.class_name, rewardId: null, rewardType: gameReward.class_name,
    });
    return acc;
  }), []);

  return (
    <div>
      <ServerError name="configsRewardsAttributes.list" />

      {entity.configsRewardsAttributes.map((configsReward, index) => (
        <Reward
          path={path}
          dispatch={dispatch}
          reward={configsReward}
          rewardItems={[...gameRewardsOptions, ...liveOpsTokensOptions]}
          index={index}
          persistedRewardsCount={persistedRewardsCount}
        />
      ))}
      <IconButton.New onClick={add} disabled={entity.readOnly}>
        Add Reward
      </IconButton.New>
    </div>
  );
}
