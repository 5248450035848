import React from 'react';

import { MergeRoutes } from '@pages/routes';
import Form from './Form';

export default function EditModal({ ...props }) {
  const { editRequest, updateRequest } = MergeRoutes.Contacts;

  return (
    <Form actionName="update" entityName="Contact" getForm={editRequest} submitForm={updateRequest} {...props} />
  );
}
