import React from 'react';

import { useQuery, useCurrentApplication } from '@hooks';
import { generateChapterBasedRoutes } from '@pages/routes';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function Edit() {
  const { applicationName } = useCurrentApplication();
  const Routes = generateChapterBasedRoutes(applicationName).UiConfigs;
  const { response: data, setResponse } = useQuery(Routes.editRequest);

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Ui Configuration',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.updateRequest(values).then((response) => responseHandler(response, values));

  return data ? <Form data={data} onSubmit={onSubmit} setData={setResponse} /> : '';
}
