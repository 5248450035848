import React from 'react';
import classnames from 'classnames';
import { InputGroup } from 'react-bootstrap';
import { useCSVReader } from 'react-papaparse';
import { useFlashMessages } from '@tripledotstudios/react-core';
import { useConfirm } from '@hooks';
import { BooleanIcon } from '@pages/common';
import { Label, Field, ServerError } from '@controls/form';
import IconButton from '@controls/buttons';
import { CopyToClipboardAddon } from '@controls/CopyToClipboardButton';
import entityIdentity from '@components/shared/entityIdentity';
import formReducer from '@pages/metagame/chapter-based/banks/formReducer';
import { combineReducers } from '@reducers';
import { persistedCollection } from '@services/utils';
import { BanksEdit } from '../../metagame/chapter-based';
import tileReducer from './reducer';

const seedCountField = ({ configAttributes }) => (
  <span className={classnames({ 'text-success': configAttributes?.seedsChanged })}>
    {configAttributes?.seeds?.length || 0}
  </span>
);

const EntityHeader = ({ entity, entityName }) => {
  const {
    contentHash, name, tileTypeHumanize, validForUse, updatedAt, configAttributes, index,
  } = entity;
  return (
    <table className="table table-borderless mb-0">
      <tbody>
        <tr className="d-flex align-items-center col">
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {entityIdentity({ entity: { ...entity, name: null }, entityName })}
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-1">
            <div>
              {'Hash: '}
              {contentHash}
            </div>
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {'Layout Name: '}
            {name}
            <ServerError name={`levelLayoutsAttributes.${index}.name`} full />
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-1">
            {'Type: '}
            {tileTypeHumanize}
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {'Valid for use: '}
            <BooleanIcon value={validForUse} className="ms-1" />
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-2">
            {' Updated at: '}
            {updatedAt}
          </td>
          <td className="bg-transparent text-break p-0 pe-2 col-1">
            {'Seeds Amount: '}
            {seedCountField({ configAttributes })}
          </td>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td className="bg-transparent text-break p-0 pe-2 col-1" />
        </tr>
      </tbody>
    </table>
  );
};

const levelLayoutFields = ({ entity }) => (
  <div className="d-flex flex-row justify-content-around">
    <div className="flex-fill pe-2">
      <Label text="Layout Name">
        <Field name="name" />
      </Label>
      <Label text="Hash">
        <span className="d-flex align-items-center">
          <InputGroup>
            <Field name="contentHash" disabled />
            <CopyToClipboardAddon text={entity.contentHash} />
          </InputGroup>
        </span>
      </Label>
      <Label text="Seeds Amount">
        {seedCountField(entity)}
        <ServerError name="configAttributes.seeds" />
      </Label>
    </div>
  </div>
);

const validateFile = (data) => (data.length === 0 ? ['Rows are missing'] : []);

const ExtraListButtons = ({ disabled, dispatch, values }) => {
  const { CSVReader } = useCSVReader();
  const { error, flush, success } = useFlashMessages();
  const confirm = useConfirm();

  const handleUpload = ({ data, errors }) => {
    if (errors.length > 0) {
      error('Invalid file format');
    } else {
      const validationErrors = validateFile(data);
      if (validationErrors.length === 0) {
        dispatch({ type: 'upload', data });
        flush();
        success('The file is uploaded');
      } else {
        error(
          `File format issues:\n${validationErrors.join(';\n')}`,
          { timeout: 600 },
        );
      }
    }
  };
  return persistedCollection(values.levelLayoutsAttributes).length > 0 && (
    <>
      <div>
        <CSVReader onUploadAccepted={handleUpload} config={{ delimiter: ',' }}>
          {({ getRootProps }) => (
            <IconButton.Upload type="button" {...getRootProps()} disabled={disabled}>
              Upload Seeds
            </IconButton.Upload>
          )}
        </CSVReader>
      </div>
      <div>
        <IconButton.Delete
          onClick={() => confirm
            .showConfirmation({ title: 'If you press Confirm, all the seeds for all levels will be removed' })
            .then(() => dispatch({ type: 'clearSeeds' }))}
          disabled={disabled}
        >
          Clear seeds
        </IconButton.Delete>
      </div>
    </>
  );
};

const reducer = combineReducers([formReducer, tileReducer]);

export default function Edit(props) {
  return (
    <BanksEdit
      EntityHeader={EntityHeader}
      levelLayoutFields={levelLayoutFields}
      ExtraListButtons={ExtraListButtons}
      reducer={reducer}
      {...props}
    />
  );
}
