import { get } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';
import { dndRecords } from '@services/reorder';
import removeNestedIds from '@services/removeNestedIds';

const updatePositions = (list) => {
  let position = 1;

  return list.map((element) => {
    element.position = position;

    if (!element._destroy) position += 1;

    return element;
  });
};

export default function formReducer(state, action) {
  if (action.type === 'populateExperts') {
    state.expertsAttributes = action.expertsAttributes;
  }

  if (action.type === 'addRandomOrder') {
    const { path, uuid } = action;
    const randomOrders = get(state, path);

    randomOrders.push({
      _uuid: uuid,
      _destroy: false,
      id: null,
      repeatable: true,
      rewardsAttributes: [addUuid({})],
      targetsAttributes: [addUuid({ quantity: 1 })],
    });
    updatePositions(randomOrders);
  }

  if (action.type === 'duplicateRandomOrder') {
    const { path, index } = action;
    const randomOrders = get(state, path);
    const {
      id, _uuid, internalId, ...originalOrder
    } = randomOrders[index];
    const duplicatedOrder = removeNestedIds(originalOrder);

    randomOrders.push(duplicatedOrder);
    updatePositions(randomOrders);
  }

  if (action.type === 'removeRandomOrder') {
    const { path, index } = action;
    const randomOrders = get(state, path);

    randomOrders[index]._destroy = true;
    updatePositions(randomOrders);
  }

  if (action.type === 'reorderRandomOrder') {
    dndRecords(state, action);
  }
  return state;
}
