import React from 'react';
import { Field, PriorityField, Label } from '@controls/form';

import DuplicationModal from './DuplicationModal';

export default function DuplicationModalWithPriority({
  entityName, entitiesByPriority, handleClose, onSubmit, record, title, openInNewTab,
}) {
  return (
    <DuplicationModal
      record={record}
      entityName={entityName}
      onSubmit={onSubmit}
      title={title}
      handleClose={handleClose}
      openInNewTab={openInNewTab}
    >
      <Label text="Name"><Field type="text" name="name" /></Label>
      <Label text="Priority">
        <PriorityField name="priority" entitiesByPriority={entitiesByPriority} />
      </Label>
    </DuplicationModal>
  );
}
