import React from 'react';
import { get } from 'lodash';

import { Form } from '@hooks';
import { combineReducers } from '@reducers';
import rewardsReducer from '@components/merge/reducers/rewardsReducer';
import targetsReducer from '@components/merge/reducers/targetsReducer';
import formReducer from './formReducer';

import ConfigurationForm from './form/ConfigurationForm';

export default function OrdersConfigurationForm({ data, onSubmit, actionName }) {
  const errors = get(data, '_meta.errors', {});

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([formReducer, rewardsReducer, targetsReducer])}
    >
      {({ values }) => <ConfigurationForm values={values} errors={errors} actionName={actionName} />}
    </Form>
  );
}
