import React from 'react';
import { Table, Button } from 'react-bootstrap';

import { FormGroup, useFormContext } from '@hooks';
import { ServerError } from '@controls/form';
import { PageSection } from '@pages/common';
import Tooltip from '@controls/Tooltip';

import Level from './Level';

export default function VariantForm({
  formGroupName, defaultVariant = false, variantAttributes, variantIndex,
}) {
  const { dispatch } = useFormContext();
  const addLevel = (event) => {
    event.preventDefault();

    dispatch({ actionType: 'add_level', defaultVariant, variantId: variantAttributes.id });
  };

  return (
    <div>
      <PageSection title="Levels" />
      <FormGroup name={formGroupName}>
        <p><ServerError name="levelsAttributes.list" /></p>
        <Table>
          <thead>
            <tr>
              <th>
                <Tooltip text="The players level">
                  <span>Number</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip text="The amount of XP required to level up">
                  <span>XP</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip text="The reward for levelling up">
                  <span>Reward</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip text="Target balance for Mercy mechanic">
                  <span>Target Balance</span>
                </Tooltip>
              </th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {variantAttributes.levelsAttributes.map((attributes, index) => (
              <Level
                key={attributes._uuid}
                index={index}
                defaultVariant={defaultVariant}
                number={attributes.number}
                _destroy={attributes._destroy}
                uuid={attributes._uuid}
                variantIndex={variantIndex}
              />
            ))}
          </tbody>
        </Table>
        <div className="text-end">
          <Button onClick={(event) => addLevel(event)}>Add</Button>
        </div>
      </FormGroup>
    </div>
  );
}
