import React from 'react';

export default function GameServerLogo({ width, height, className }) {
  return (
    <img
      src="/images/game-server-logo.svg"
      width={width}
      height={height}
      className={className}
      alt="Game Server Logo"
    />
  );
}
