import React from 'react';

import { InUseCollapse } from '@pages/common/InUse';
import Label from './Label';

export default function InUseFormGroup({ inUse, labelSize = 4 }) {
  return inUse !== undefined && (
    <Label text="In Use" labelSize={labelSize}>
      <InUseCollapse inUse={inUse} />
    </Label>
  );
}
