import { buildQuickLinkFn } from '@services/fields-factory';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { keys } from 'lodash';

import { TileRoutes } from '@pages/routes';

import { Spinner } from '@tripledotstudios/react-core';
import { Label, SelectField } from '@controls/form';
import {
  Form, FormGroup, useCurrentApplication, useQuery,
} from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import IconButton from '@controls/buttons';

export default function ReplacementModal({
  isOpen, selectedRows, handleClose, searchResults, onSuccess,
}) {
  const [data, setData] = useState({});
  const [bankId, setBankId] = useState(null);
  const { applicationId } = useCurrentApplication();
  const [submitted, setSubmitted] = useState(false);
  const noRedirectResponseHandler = noRedirectHandlerFactory({
    entityName: 'Layouts',
    actionName: 'Replacement',
    setData,
  });

  const { response: bankOptions } = useQuery({
    request: TileRoutes.Banks.indexRequest,
    queryKey: ['bankOptions', applicationId, searchResults.tileType],
    toSelectOptions: true,
    additionalRequestParams: {
      withoutPagination: true,
      includeInUse: false,
      withoutVersions: true,
      filter: { keptLevelLayoutsConfigTileTypeEq: searchResults.tileType },
    },
  });
  const { response: layoutOptions } = useQuery({
    request: TileRoutes.LevelLayouts.indexRequest,
    queryKey: ['levelLayoutOptions', applicationId, bankId],
    toSelectOptions: true,
    processResponse: (response) => response.data.items.map(
      (record) => ({ value: record.id, label: `${record.name} (${record.contentHash})`, record }),
    ),
    additionalRequestParams: {
      withoutPagination: true,
      filter: {
        bankIdEq: bankId,
        configTileTypeEq: searchResults.tileType,
        contentHashNotEq: searchResults.contentHash,
      },
    },
  });

  const onClose = () => {
    setData({ _meta: {} });
    setBankId(null);
    setSubmitted(false);
    handleClose();
  };
  const onSubmitWithHandler = (value) => setSubmitted(
    true,
    TileRoutes.LayoutReplacements.createRequest({ ...value, applicationId, ids: keys(selectedRows) })
      .then(noRedirectResponseHandler)
      .then((response) => {
        if (response.status < 300) {
          onSuccess();
          onClose();
        }
      }).finally(() => setSubmitted(false)));

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select the Level Layout that should replace the selected Layout(s)</Modal.Title>
      </Modal.Header>
      <Form initialValues={data} onSubmit={onSubmitWithHandler} enableReinitialize>
        {({ values, setFieldValue }) => {
          const selectedLayout = layoutOptions?.find(({ record }) => values.levelLayoutId === record.id)?.record;
          return (
            <FormGroup>
              <Modal.Body>
                <Label text="Layout Bank" tooltipText="Select Layout bank where required Level Layout is placed">
                  <SelectField
                    name="bankId"
                    options={bankOptions}
                    onChange={({ value }) => {
                      setBankId(value);
                      setFieldValue('levelLayoutId', value);
                    }}
                    quickLinkFn={buildQuickLinkFn(TileRoutes.Banks, applicationId)}
                    includeEmpty
                  />
                </Label>
                <Label
                  text="Level Layout"
                  tooltipText="Select Level Layout. Level layout can be searched by Name or Hash"
                >
                  <SelectField name="levelLayoutId" options={layoutOptions} isDisabled={!bankId} includeEmpty />
                </Label>
                {selectedLayout && (
                  <>
                    <span className="pe-2">
                      <strong>Position: </strong>
                      {selectedLayout.position}
                    </span>
                    <span className="pe-2">
                      <strong>Hash: </strong>
                      {selectedLayout.contentHash}
                    </span>
                    <span className="pe-2">
                      <strong>Seeds Amount: </strong>
                      {selectedLayout.seedsCount}
                    </span>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <IconButton.Save type="submit" disabled={submitted} className="d-flex align-items-center">
                  {submitted ? (
                    <Spinner height="14px" width="14px" />
                  ) : 'Confirm'}
                </IconButton.Save>
                <IconButton.Cancel type="button" variant="secondary" onClick={onClose} disabled={submitted} />
              </Modal.Footer>
            </FormGroup>
          );
        }}
      </Form>
    </Modal>
  );
}
