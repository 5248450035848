import {
  last, cloneDeep, isPlainObject, sortBy, get, set,
} from 'lodash';
import reorderMeta from '@services/reorderMeta';
import { addUuid } from '@services/recursivelyProcessObject';
import removeNestedIds from '@services/removeNestedIds';
import reorderEntities from '@services/reorderEntities';
import removeEntity from '@services/removeEntity';

const DEFAULT_LEVELS_PATH = 'configAttributes.levelsAttributes';

/* eslint-disable no-param-reassign, array-callback-return */
export default function formReducer(state, action) {
  const { levelsPath } = action;
  const resolvedLevelsPath = levelsPath || DEFAULT_LEVELS_PATH;
  const levelsAttributes = get(state, resolvedLevelsPath);

  const setLevels = (value) => set(state, resolvedLevelsPath, value);

  if (action.actionType === 'removeEntity') {
    const { _uuid: uuidToDelete } = action;

    return removeEntity(state, resolvedLevelsPath, uuidToDelete, 'number');
  }

  if (action.actionType === 'bulkRemoveEntities') {
    const { uuids } = action;

    const levels = cloneDeep(levelsAttributes);

    levels.forEach((level) => {
      if (uuids.includes(level._uuid)) level._destroy = true;

      return level;
    });

    setLevels(reorderEntities(levels, 'number').result);

    return state;
  }

  if (action.actionType === 'restoreEntity') {
    const { _uuid: uuidToDelete } = action;

    const levels = cloneDeep(levelsAttributes);

    delete levels.find(({ _uuid }) => _uuid === uuidToDelete)._destroy;

    setLevels(reorderEntities(levels, 'number').result);

    return state;
  }

  if (action.actionType === 'moveEntity') {
    const {
      sourceIndex,
      destinationIndex,
      meta,
      metaErrorsPath,
    } = action;

    const levels = cloneDeep(levelsAttributes);

    if (levelsAttributes.length <= 1) return state;

    const { result, changeSet: [fromIndices, toIndices] } = reorderEntities(
      levels,
      'number',
      sourceIndex,
      destinationIndex,
    );

    setLevels(result);

    if (meta.errors && isPlainObject(get(meta, metaErrorsPath))) {
      meta.errors = { ...meta.errors, ...reorderMeta(meta, fromIndices, toIndices, metaErrorsPath).errors };
    }

    return state;
  }

  if (action.actionType === 'applyPositions') {
    const { uuidToPositionMapping } = action;

    levelsAttributes.forEach((level) => {
      level.number = uuidToPositionMapping[level._uuid];

      return level;
    });

    setLevels(sortBy(levelsAttributes, 'number'));

    return state;
  }

  if (action.actionType === 'duplicateEntity') {
    const { _uuid: originalUuid } = action;

    const { id, _uuid, ...originalLevel } = levelsAttributes.find((level) => level._uuid === originalUuid);
    const lastLevel = last(levelsAttributes.filter(({ _destroy }) => !_destroy));

    const duplicatedLevel = removeNestedIds(originalLevel);

    levelsAttributes.push(addUuid({ ...duplicatedLevel, _destroy: false, number: lastLevel.number + 1 }));
  }

  if (action.actionType === 'addEmptyEntity') {
    const { options = {}, position } = action;

    const lastLevel = last(levelsAttributes.filter(({ _destroy }) => !_destroy));
    const level = addUuid({
      ...options, _destroy: false, id: null, number: lastLevel ? lastLevel.number + 1 : 1,
    });

    if (position && position === 'start') {
      state.levelsAttributes = reorderEntities([level, ...levelsAttributes]).result;
      return state;
    }

    levelsAttributes.push(level);
  }

  if (action.actionType === 'cleanLevels') {
    setLevels([]);

    return state;
  }

  return state;
}
