import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { TileRoutes as Routes } from '@pages/routes';

import Form from './Form';

const TileRewardSetEdit = () => {
  const { query: { id, applicationId } } = useRouter();
  const { response, setResponse } = useQuery(Routes.RewardSets.editRequest, { id, applicationId });

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Reward Set',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    Routes.RewardSets.updateRequest({ ...values, applicationId }).then(responseHandler)
  );

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
};

export default TileRewardSetEdit;
