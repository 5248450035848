import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import {
  createHexagonPuzzleConfig,
  newHexagonPuzzleConfig,
} from '@requests/puzzle-time/hexagonPuzzleConfigs';

import fieldsConfiguration from './FieldsConfiguration';
import Form from '../GameConfigsForm';

export default function New() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const indexPath = `/admin/applications/${query.applicationId}/puzzle_time/hexagon_puzzle_configs`;

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Hexa Puzzle Config',
    actionName: 'creation',
    redirectPath: indexPath,
    setData,
  });

  const onSubmit = (values) => createHexagonPuzzleConfig(query, values).then(redirectIfSuccessHandler);

  useEffect(() => {
    newHexagonPuzzleConfig(query).then((response) => setData(response.data));
  }, []);

  return data ? (
    <Form
      data={data}
      onSubmit={onSubmit}
      fieldsConfiguration={fieldsConfiguration}
      actionName="Create"
      indexPath={indexPath}
      configName="Hexa Puzzle Configs"
    />
  )
    : '';
}
