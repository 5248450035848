import React, { useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import {
  filter, get, reduce, sortBy, isEmpty,
} from 'lodash';
import { faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { PageSection } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { Form, useCurrentApplication, useFormContext } from '@hooks';
import {
  Label, Field, FormButtonsGroup, SelectField, ServerError,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { rewardItemsReducer } from '@reducers';
import { getRewardItems } from '@requests/rewards/items';
import { isInUse as isInUseFunction } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

import StaticFieldsFormGroup from './form/StaticFieldsFormGroup';

import RewardFields from './RewardFields';
import ContainerContent from './ContainerContent';
import LiveopsTokenContent from './LiveopsTokenContent';

const { rewardTypes, rewardOperations } = APP_DATA;

const REWARD_TYPE_TO_CONTENT_MAPPING = {
  container: ContainerContent,
  liveops_token: LiveopsTokenContent,
};

const Fields = ({ values, setFieldValue }) => {
  const { dispatch, resetForm } = useFormContext();
  const [rewardItems, setRewardItems] = useState([]);
  const { routingScope, applicationName, currentApplication: { id: applicationId } } = useCurrentApplication();
  const {
    id, contentSetsAttributes, rewardType, operation, inUse,
  } = values;
  const rewardSchema = rewardType ? rewardTypes[rewardType] : null;
  const rewardOperation = operation ? rewardOperations[operation] : null;

  useEffect(() => {
    getRewardItems({
      applicationId,
      include_content: true,
      withoutPagination: true,
    }).then(({ data: { items } }) => setRewardItems(items));
  }, []);

  useEffect(
    () => {
      if (rewardSchema) {
        const attributeDefaults = reduce(
          rewardSchema.attributes,
          (acc, value, attribute) => {
            if (value.default) { return { ...acc, [attribute]: value.default }; }
            if (value.type === 'bool') { return { ...acc, [attribute]: false }; }
            return acc;
          },
          {},
        );

        resetForm({
          values: {
            ...values,
            type: rewardSchema.item_class_name,
            data: attributeDefaults,
          },
        });
      }
    },
    [rewardType],
  );

  const isInUse = isInUseFunction(inUse);
  const gameRewards = sortBy(
    filter(rewardTypes, (_value, className) => className.includes(`::${applicationName}::`)),
    ['name'],
  );
  const rewardTypeOptions = Object.values(gameRewards).map(
    (gameRewardItem) => ({ label: gameRewardItem.name, value: gameRewardItem.class_name }),
  );
  const rewardOperationsOptions = Object.values(rewardOperations).map(
    (operationItem) => ({ label: operationItem.name, value: operationItem.class_name }),
  );
  const action = () => {
    if (!id) return 'New';

    return isInUse ? 'View' : 'Edit';
  };
  const showGenerateButton = rewardSchema?.name === 'Chest';
  const onGenerateClick = () => {
    const gameItemName = contentSetsAttributes?.filter(({ _destroy }) => !_destroy)?.map((item) => {
      const itemData = rewardItems.find(({ id: rewardItemId }) => rewardItemId === item.childId);

      return `${item.quantity}${itemData?.name[0] ?? ''}`;
    })?.filter((n) => n)?.join(', ');

    setFieldValue('name', gameItemName);
  };

  const ContentComponent = REWARD_TYPE_TO_CONTENT_MAPPING[rewardSchema?.type];

  return (
    <>
      <PageHeader title={`${action()} Game Item`}>
        <FormButtonsGroup cancelButtonPath={`${routingScope}/rewards/items`} hideSubmit={isInUse} />
      </PageHeader>

      <fieldset>
        <Field name="applicationId" type="hidden" />
        <Label text="Name" required>
          <InputGroup>
            <Field name="name" disabled={isInUse} inline={false} />

            {showGenerateButton && (
              <IconButton
                icon={faMagicWandSparkles}
                variant="info"
                onClick={onGenerateClick}
                disabled={isInUse}
              >
                Generate Name
              </IconButton>
            )}
          </InputGroup>

          <ServerError name="name" full />
        </Label>

        <StaticFieldsFormGroup data={values} />

        <Label text="Type" required>
          {id
            ? rewardSchema.name
            : (
              <SelectField
                name="rewardType"
                options={rewardTypeOptions}
              />
            )}
        </Label>

        {(rewardSchema?.type && (!isEmpty(rewardSchema.attributes) || ContentComponent)) && (
          <PageSection title={`${rewardSchema.name} Configuration`}>
            {get(rewardSchema, 'type') === 'container' && (
              <>
                <Label text="Content type">
                  <SelectField
                    isDisabled={isInUse}
                    name="operation"
                    options={rewardOperationsOptions}
                  />
                </Label>
                {rewardOperation && (
                  <RewardFields disabled={isInUse} name="data.operationAttributes" rewardSchema={rewardOperation} />
                )}
              </>
            )}
            {rewardType && (
              <>
                <RewardFields disabled={isInUse} name="data" rewardSchema={rewardSchema} />
                {ContentComponent && (
                  <ContentComponent
                    dispatch={dispatch}
                    isInUse={isInUse}
                    values={values}
                    rewardItems={rewardItems}
                  />
                )}
              </>
            )}

          </PageSection>
        )}
      </fieldset>
    </>
  );
};

export default function RewardsItemsForm({ data, onSubmit }) {
  const { currentApplication, applicationName } = useCurrentApplication();
  const fullData = { ...data, applicationName, applicationId: currentApplication.id };

  return (
    <Form
      initialValues={fullData}
      enableReinitialize
      onSubmit={onSubmit}
      reducer={rewardItemsReducer}
    >
      {({ values, setFieldValue }) => <Fields data={data} setFieldValue={setFieldValue} values={values} />}
    </Form>
  );
}
