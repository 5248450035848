import React from 'react';

import APP_DATA from '@services/appData';

import { fromClassNameToOptions } from '@services/enums';

import Label from './Label';
import SelectField from './SelectField';

const { modelsLocales: { shared: { tooltips: { availabilityState: availabilityStateTooltip } } } } = APP_DATA;

export default function AvailabilityStateFormGroup({ disabled, fieldSize }) {
  const availabilityStateOptions = fromClassNameToOptions('AvailabilityStates');

  return (
    <Label text="Availability" tooltipText={availabilityStateTooltip} fieldSize={fieldSize} required>
      <SelectField
        name="availabilityState"
        options={availabilityStateOptions}
        isDisabled={disabled}
      />
    </Label>
  );
}
