import React, { useState } from 'react';
import { minBy } from 'lodash';
import { Button } from 'react-bootstrap';
import ReactJson from 'react-json-view';

import { Alert, ButtonToolbar, useRouter } from '@tripledotstudios/react-core';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { PageHeader } from '@pages/common';

import { Form } from '@hooks';
import { Label, SelectField } from '@controls/form';

import { External } from '@pages/routes';

export default function InitAnalysis({ user }) {
  const { query: { applicationId } } = useRouter();

  const deviceOptions = user.devices.map((device) => {
    const { id, appVersion } = device;
    return ({ value: id, label: `${id} (${appVersion})` });
  });
  const [initResult, setInitResult] = useState(null);
  const theOldestDeviceId = minBy(user.devices, 'createdAtTimestamp')?.id;
  const initHandler = noRedirectHandlerFactory({
    entityName: 'Init',
    actionName: 'call',
    showSuccess: false,
  });

  const analyze = (values) => {
    const device = user.devices.find(({ id }) => id === values.deviceId);

    External
      .initRequest({ token: device.token, is_introspection_enabled: true }, { skipUuids: true })
      .then((resp) => {
        setInitResult(resp.rawData);
        return resp;
      }).then(initHandler);
  };

  return (
    <Form initialValues={{ deviceId: theOldestDeviceId, applicationId }} onSubmit={analyze}>
      {() => (
        <>
          <PageHeader
            title="Init call analysis"
            className="pt-1 mt-3"
          >
            <ButtonToolbar>
              <Button type="submit">Analyse</Button>
            </ButtonToolbar>
          </PageHeader>
          <Alert variant="primary">
            {/* eslint-disable react/no-unescaped-entities */}
            Pressing "Analyse" button will make an init call with the following features:
            <ul>
              <li>This init call will use the user's current data (IDFV, country, app version, IP address, etc.)</li>
              <li>
                For the duration of the call, the user will be allocated to the experiments as if it were a normal
                init call, but all changes will not be saved.
              </li>
              <li>
                In the init response a new section called "rendered_configs" will be added. In this
                section you can see more or less the same structure as in the init response but instead of
                the settings values it contains information about the source of the corresponding values. If you
                can't find a setting then it's probably in the "unmapped" section.
              </li>
            </ul>
            {/* eslint-enable react/no-unescaped-entities */}
          </Alert>
          <Label text="Select Device" tooltipText="Select device for introspection">
            <SelectField
              name="deviceId"
              options={deviceOptions}
            />
          </Label>
          {initResult && (
            <ReactJson
              src={initResult}
              theme="harmonic"
              displayDataTypes={false}
            />
          )}
        </>
      )}
    </Form>
  );
}
