import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { Field } from '@controls/form';

const Header = ({ text }) => <Col><span className="fw-bold">{text}</span></Col>;
const ParameterRow = ({ text, valueName, maxDeviationName }) => (
  <Row className="mb-4">
    <Col>{text}</Col>
    <Col><Field name={valueName} type="number" /></Col>
    <Col><Field name={maxDeviationName} type="number" /></Col>
  </Row>
);

export default function SettingsTable() {
  return (
    <>
      <hr />
      <Row className="mb-4">
        <Header text="Parameter" />
        <Header text="Value" />
        <Header text="Max deviation (%)" />
      </Row>
      <ParameterRow
        text="Percentage of wins (%)"
        valueName="percentageOfWinsValue"
        maxDeviationName="percentageOfWinsMaxDeviation"
      />
      <ParameterRow
        text="Moves average"
        valueName="movesAverageValue"
        maxDeviationName="movesAverageMaxDeviation"
      />
      <ParameterRow
        text="Score average"
        valueName="scoreAverageValue"
        maxDeviationName="scoreAverageMaxDeviation"
      />
      <ParameterRow
        text="Time average (sec)"
        valueName="timeAverageValue"
        maxDeviationName="timeAverageMaxDeviation"
      />
    </>
  );
}
