import React from 'react';
import { Col } from 'react-bootstrap';

export default function Columns() {
  return (
    <Col xs={2}>
      <b>Pre-discount amount</b>
    </Col>
  );
}
