import React, { useEffect } from 'react';

import { Form, useCurrentApplication } from '@hooks';
import { difficultySchemasReducer } from '@reducers';
import { TileRoutes } from '@pages/routes';
import { serializableAttributes } from '@services/difficultySchemas';
import {
  Label,
  Field,
  FormButtonsGroup,
  StaticFieldsFormGroup,
} from '@controls/form';
import { DuplicateButton, PageHeader } from '@pages/common';
import { isInUse } from '@pages/common/InUse';

import Fields from './form/Fields';

const DifficultySchemaForm = ({ data, onSubmit, setData }) => {
  const { currentApplication, applicationName } = useCurrentApplication();
  const { inUse } = data;

  const disabled = isInUse(inUse);
  const fullData = { ...data, applicationName, applicationId: currentApplication.id };

  useEffect(() => {
    setData({ ...data, originalData: serializableAttributes(data) });
  }, [JSON.stringify(data)]);

  return (
    <Form
      initialValues={fullData}
      enableReinitialize
      onSubmit={onSubmit}
      reducer={difficultySchemasReducer}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${values.id ? 'View' : 'New'} DDA Schema`}>
            <FormButtonsGroup
              cancelButtonPath={TileRoutes.DifficultySchemas.indexPath({ applicationId: currentApplication.id })}
              hideSubmit={disabled}
            >
              <DuplicateButton
                routes={TileRoutes.DifficultySchemas}
                data={data}
                entityName="DDA Schema"
              />
            </FormButtonsGroup>
          </PageHeader>

          <Label text="Name" required>
            <Field name="name" disabled={disabled} />
          </Label>

          <StaticFieldsFormGroup data={values} />

          <Fields values={values} disabled={disabled} />
        </>
      )}
    </Form>
  );
};

export default DifficultySchemaForm;
