import React, { useState, useEffect } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { WooAspenRoutes as Routes } from '@pages/routes';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InLiveBadge,
  LabelsColumn,
  InLiveColumnHeader,
  InUseColumn,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { useCurrentApplication, useQuery, useConfirm } from '@hooks';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

import ModalForm from './ModalForm';
import Filter from './Filter';

export const columnsFactory = ({ onDelete, onEdit, onDuplicate }) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Key', accessor: 'key' },
  LabelsColumn,
  InUseColumn,
  {
    id: 'availability_header',
    Header: () => <InLiveColumnHeader />,
    Cell: ({ row }) => <InLiveBadge inLive={row.original.availabilityStateLive} />,
  },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({
      row: {
        original: {
          id, name, key, inUse, labels,
        },
      },
    }) => {
      const disabled = isInUse(inUse);

      return (
        <ButtonToolbar>
          <IconButton.Edit
            onClick={() => onEdit({
              id, name, key, disabled,
            })}
            minimized
          />
          <IconButton.Duplicate
            minimized
            onClick={() => onDuplicate({
              name,
              key,
              duplicatedFrom: id,
              labelIds: labels.map(({ id: labelId }) => labelId),
            })}
          />
          <IconButton.Delete
            onClick={onDelete(id, name)}
            disabled={disabled}
            title={disabled ? 'Disabled as the entity is used' : null}
            minimized
          />
        </ButtonToolbar>
      );
    },
  },
];

export default function Index() {
  const confirm = useConfirm();
  const { currentApplication } = useCurrentApplication();
  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.LiveOpsTokens.indexRequest, { includeInUse: true });
  const [record, setRecord] = useState(null);

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'LiveOps token',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `LiveOps token '${name}' will be deleted and not available for work. Continue?`,
    }).then(() => Routes.LiveOpsTokens.deleteRequest({ id, applicationId: currentApplication.id })
      .then(responseHandler));
  };

  const columns = React.useMemo(() => columnsFactory({
    onDelete, onEdit: setRecord, onDuplicate: setRecord,
  }), []);

  useEffect(() => {
    const focusedRecordId = query.focused_entity_id;

    if (!focusedRecordId || !response) return;

    const focusedRecord = response.items.find(({ id }) => id === Number(focusedRecordId));

    if (focusedRecord) setRecord({ ...focusedRecord, disabled: isInUse(focusedRecord.inUse) });
  }, [response?.items?.length, query.focused_entity_id]);

  return response && (
    <div>
      <PageHeader
        title="LiveOps Tokens"
        filter={<Filter />}
      >
        <IconButton.New onClick={() => setRecord({})} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <ModalForm
        record={record}
        onHide={() => setRecord(null)}
        refetch={refetch}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
