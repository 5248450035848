import React from 'react';

import { get } from 'lodash';
import { Td } from '../shared/styled-components';
import CellContent from '../shared/cells/CellContent';

export default function Cell({
  name,
  newValue,
  oldValues,
  valueWrapper,
  sticky = false,
}) {
  const oldValue = get(oldValues, name);

  return (
    <Td width="250px" sticky={sticky}>
      <CellContent
        newValue={newValue}
        oldValue={oldValue}
        valueWrapper={valueWrapper}
        isNewRecord={false}
      />
    </Td>
  );
}
