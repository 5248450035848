import React from 'react';

import { Th } from './styled-components';
import LanguageHeaders from './LanguageHeaders';

export default function TableHead({ languages, type = 'test' }) {
  return (
    <thead>
      <tr>
        {type === 'test' && <Th />}
        <Th stickyHorizontaly width="250px">Key ID</Th>
        <Th width="100px">Type</Th>
        <Th width="250px">In Use</Th>
        <Th width="250px">Description</Th>
        <LanguageHeaders languages={languages} />
      </tr>
    </thead>
  );
}
