import { IconButton } from '@tripledotstudios/react-core';

import MenuButton from './MenuButton';
import LookerAbButton from './LookerAbButton';
import LookerJourneyButton from './LookerJourneyButton';
import ActivityLogButton from './ActivityLogButton';
import UndoButton from './UndoButton';
import ListCheckButton from './ListCheckButton';
import ViewButton from './ViewButton';
import ApplyButton from './ApplyButton';

IconButton.Menu = MenuButton;
IconButton.LookerAb = LookerAbButton;
IconButton.LookerJourney = LookerJourneyButton;
IconButton.ActivityLog = ActivityLogButton;
IconButton.Undo = UndoButton;
IconButton.ListCheck = ListCheckButton;
IconButton.View = ViewButton;
IconButton.Apply = ApplyButton;

export default IconButton;
