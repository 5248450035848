import React from 'react';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import FaButton, { FaIcon } from './FaButton';

const ExpanderColumn = {
  Header: () => null,
  id: 'expander',
  style: { width: 50 },
  Cell: ({ row }) => (
    <FaButton {...row.getToggleRowExpandedProps()}>
      <FaIcon icon={row.isExpanded ? faChevronUp : faChevronDown} />
    </FaButton>
  ),
};

export default ExpanderColumn;
