import React, { memo, useState } from 'react';
import { FieldArray } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { useFormGroup, useFormContext } from '@hooks';
import isEqualProps from '@services/isEqualProps';
import Entity from '@controls/form/field-array-with-label/Entity';
import IconButton from '@controls/buttons';

import ServerError from './ServerError';

function FieldArrayWithLabel({
  collectionName,
  values,
  fieldsConfiguration,
  isHorizontal,
  disableLabel,
  reorderable,
  handleOnly,
  showPosition,
  disabled,
  stopAdding,
  tooltips,
  includeEmptyOption,
  localeNamespace,
  locale,
  tooltipBuilder,
}) {
  const { generateName } = useFormGroup();
  const { meta } = useFormContext();
  const [keys, setKeys] = useState((values && values.map(() => uuidv4())) || []);

  const getDefaultValues = () => {
    if (fieldsConfiguration.length === 1 && !fieldsConfiguration[0].name) return null;

    const fieldsWithDefaults = fieldsConfiguration.filter((config) => config.default != null);
    return fieldsWithDefaults.reduce((object, element) => ({
      ...object, [element.name]: element.default,
    }), {});
  };

  const fieldName = generateName(collectionName);

  return (
    <div className="mb-2">
      <FieldArray
        name={fieldName}
        render={(arrayHelpers) => (
          <div className="mt-3">
            {values && values.map((value, index) => (
              <Entity
                key={keys[index]}
                value={value}
                index={index}
                collectionName={collectionName}
                fieldsConfiguration={fieldsConfiguration}
                isHorizontal={isHorizontal}
                arrayHelpers={arrayHelpers}
                disableLabel={disableLabel}
                reorderable={reorderable}
                handleOnly={handleOnly}
                showPosition={showPosition}
                disabled={disabled}
                meta={meta}
                tooltips={tooltips}
                includeEmptyOption={includeEmptyOption}
                localeNamespace={localeNamespace}
                collectionLocale={locale}
                tooltipBuilder={tooltipBuilder}
                setKeys={setKeys}
              />
            ))}
            <div className="text-end mb-4">
              <IconButton.New
                onClick={() => {
                  setKeys((previous) => [...previous, uuidv4()]);
                  arrayHelpers.push(getDefaultValues());
                }}
                disabled={disabled || stopAdding}
              >
                Add
              </IconButton.New>
            </div>
          </div>
        )}
      />
      <ServerError full name={`${fieldName}.common` || fieldName} />
    </div>
  );
}

export default memo(FieldArrayWithLabel, isEqualProps(['values', 'disabled', 'fieldsConfiguration']));
