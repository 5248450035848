import React from 'react';
import { PageTitle } from '@pages/common';

import {
  FlexiblePopupsPopupsIndex,
  FlexiblePopupsPopupsNew,
  FlexiblePopupsPopupsEdit,

  FlexiblePopupsActionsIndex,
  FlexiblePopupsActionsNew,
  FlexiblePopupsActionsEdit,
} from '@pages/flexible-popups';

import { FlexiblePopupRoutes as Routes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function flexiblePopups() {
  return [
    <Route
      key={Routes.Popups.indexRawPath}
      path={Routes.Popups.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Flexible Popups" />
          <FlexiblePopupsPopupsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Popups.newRawPath}
      path={Routes.Popups.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Flexible Popup" action="New" />
          <FlexiblePopupsPopupsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Popups.editRawPath}
      path={Routes.Popups.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Flexible Popup" action="Edit" />
          <FlexiblePopupsPopupsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Actions.indexRawPath}
      path={Routes.Actions.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Flexible Popup Actions" />
          <FlexiblePopupsActionsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Actions.newRawPath}
      path={Routes.Actions.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Flexible Popup Action" action="New" />
          <FlexiblePopupsActionsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Actions.editRawPath}
      path={Routes.Actions.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Flexible Popup Action" action="Edit" />
          <FlexiblePopupsActionsEdit />
        </RouteContainer>
      )}
    />,
  ];
}
