import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Field } from '@controls/form';
import { RemoveIcon } from '@components/merge/shared/icons';
import { ItemsSelectField } from '@components/merge/shared/items/Select';

export default function Target({ onTargetDelete, items }) {
  return (
    <Row className="mb-2">
      <Col xs={7} className="pe-0">
        <Field name="id" type="hidden" className="me-1" />
        <ItemsSelectField name="schemaItemId" items={items} className="me-1" />
      </Col>
      <Col xs={4} className="pe-0">
        <Field name="quantity" type="number" className="me-1" />
      </Col>
      <Col xs={1}>
        <RemoveIcon onClick={onTargetDelete} />
      </Col>
    </Row>
  );
}
