import { getThemeName as reactCoreGetThemeName } from '@tripledotstudios/react-core';

const DEFAULT_THEME_NAME = 'classic';
const AVAILABLE_THEME_NAMES = [DEFAULT_THEME_NAME, 'dark'];

export function getThemeName() {
  return reactCoreGetThemeName({ defaultValue: DEFAULT_THEME_NAME, availableValues: AVAILABLE_THEME_NAMES });
}

export function themeNameIs(name) {
  return getThemeName() === name;
}
