import React, { memo } from 'react';
import styled from 'styled-components';
import { Alert } from '@tripledotstudios/react-core';

import getFieldLocale from '@services/getFieldLocale';

import areEnabledPropsEqual from './areEnabledPropsEqual';

const WithNewLines = styled.p`
  white-space: pre-line;
`;

const AlertSection = ({ baseName, variant, alertOptions }) => (
  ({
    localeNamespace, locale = {},
  }) => {
    const fieldLocale = getFieldLocale(localeNamespace, baseName);
    const container = fieldLocale || locale;
    if (!container) return '';

    const { title, text } = container;

    return (title || text) && (
      <Alert variant={variant} className="mb-0" {...alertOptions}>
        {title && (
          <b>{title}</b>
        )}
        {text && (
          <WithNewLines>{text}</WithNewLines>
        )}
      </Alert>
    );
  }
);

export default function WarningBuilder({
  name: baseName, fieldOptions = {}, variant = 'info', alertOptions = {},
}) {
  return {
    [baseName]: {
      Inline: memo(AlertSection({ baseName, variant, alertOptions }), areEnabledPropsEqual),
      Labeled: memo(AlertSection({ baseName, variant, alertOptions }), areEnabledPropsEqual),
      fieldOptions,
    },
  };
}
