import React, { useMemo, useState } from 'react';

import { MissionsRoutes } from '@pages/routes';
import { useQuery } from '@hooks';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
} from '@pages/common';
import IconButton from '@controls/buttons';

import NewGameItemModal from './NewGameItemModal';

export default function Index() {
  const [showModal, setShowModal] = useState(false);
  const { response, refetch } = useQuery(MissionsRoutes.Rewards.Types.indexRequest);

  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Type', accessor: 'rewardType' },
      ...TIMESTAMP_COLUMNS,
    ],
    [],
  );

  return response && (
    <>
      <PageHeader title="Reward Types" userGuideUrlKey="missionsGuideUrl">
        <IconButton.New onClick={() => setShowModal(true)} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
      />
      <NewGameItemModal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        refetch={refetch}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </>
  );
}
