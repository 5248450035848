import React from 'react';

import { MissionsRoutes } from '@pages/routes';
import CreateUpdateComponent from './CreateUpdateComponent';

export default function Edit() {
  return (
    <CreateUpdateComponent
      actionName="update"
      getFunction={MissionsRoutes.Rewards.Sets.editRequest}
      postFunction={MissionsRoutes.Rewards.Sets.updateRequest}
    />
  );
}
