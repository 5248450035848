import React, { useState, useMemo } from 'react';
import { Spinner, ButtonToolbar, useRouter } from '@tripledotstudios/react-core';
import IconButton from '@controls/buttons';

import { WoodokuRoutes as Routes } from '@pages/routes';
import { useQuery, useConfirm } from '@hooks';

import APP_DATA from '@services/appData';

import {
  PageHeader,
  Pagination,
  InLiveColumn,
  InUseColumn,
  TIMESTAMP_COLUMNS,
  LabelsColumn,
  ReactTable,
  DuplicationModal,
  Badge,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';

import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import Filter from './Filter';

const { enums } = APP_DATA;
const statuses = enums['Woodoku::BonusLevels::LevelBankStatuses'];

const statusBadgeColor = (status) => {
  const colorMapping = {
    [statuses.ACTIVE]: 'success',
    [statuses.ARCHIVED]: 'secondary',
  };

  return colorMapping[status];
};

const columnsFactory = ({ applicationId, onArchive, setDuplicatedRecord }) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Levels count', accessor: 'levelsCount' },
  {
    Header: 'Status',
    Cell: ({ row: { original: { status, statusHumanize } } }) => (
      <Badge bg={statusBadgeColor(status, statuses)}>{statusHumanize}</Badge>
    ),
  },
  InLiveColumn,
  InUseColumn,
  LabelsColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({
      row: {
        original: {
          id, name, inUse, status,
        },
      },
    }) => (
      <ButtonToolbar>
        <IconButton.Edit to={Routes.BonusLevels.LevelBanks.editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        {status !== statuses.ARCHIVED && (
          <IconButton.Archive onClick={() => onArchive(id, name)} minimized disabled={isInUse(inUse)} />
        )}
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { isLoading, response, refetch } = useQuery(Routes.BonusLevels.LevelBanks.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Bonus Levels Bank',
    actionName: 'archive',
    refetch,
  });

  const onDuplicate = (values) => Routes.BonusLevels.LevelBanks.duplicateRequest({ ...values, applicationId });

  const onArchive = (id) => {
    confirm.showConfirmation({ title: 'Bonus Levels Bank will be archived and not available for work. Continue?' })
      .then(() => Routes.BonusLevels.LevelBanks.archiveRequest({ applicationId, id })
        .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({ applicationId, onArchive, setDuplicatedRecord }), []);

  return response && (
    <div>
      <PageHeader title="Bonus Level Banks" filter={<Filter />}>
        <IconButton.New to={Routes.BonusLevels.LevelBanks.newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
            defaultSort={{ id: 'id', desc: true }}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Bonus Levels Bank"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={onDuplicate}
        title="Duplicate Bonus Levels Bank"
      />
    </div>
  );
}
