import React, { useMemo, useState } from 'react';
import { Col } from 'react-bootstrap';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { PaymentsRoutes } from '@pages/routes';
import { useQuery, useConfirm, useCurrentApplication } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import {
  PageHeader,
  ReactTable,
  Pagination,
  RulesList,
  InLiveBadge,
  InLiveColumnHeader,
  TIMESTAMP_COLUMNS,
  StatusBadge,
  Filter,
  SelectFilter,
  PriorityColumn,
  DuplicationModalWithPriority,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import IconButton from '@controls/buttons';

const statuses = APP_DATA.enums['Payments::CatalogStatuses'];

const ApplicationSpecificColumns = (applicationType) => {
  if (applicationType !== 'my_pet_cafe') { return []; }

  return [{ Header: 'Type', accessor: 'catalogTypeHumanize' }];
};

const CatalogFilters = ({ applicationType }) => {
  if (applicationType !== 'my_pet_cafe') {
    return <Filter defaultComponents={['availabilityStateIn']} />;
  }

  const catalogTypes = fromClassNameToOptions('MyPetCafe::CatalogTypes');

  return (
    <Filter defaultComponents={['availabilityStateIn']}>
      {({ updateFilterField }) => (
        <Col md={6}>
          <SelectFilter
            name="catalogTypeIn"
            label="Type"
            isMulti={false}
            options={catalogTypes}
            updateFilterField={updateFilterField}
          />
        </Col>
      )}
    </Filter>
  );
};

const columnsFactory = ({
  editPath, applicationId, setDuplicatedRecord, onDelete, applicationType,
}) => ([
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  PriorityColumn,
  ...ApplicationSpecificColumns(applicationType),
  {
    Header: 'Status',
    Cell: ({ row: { original: { status, statusHumanize } } }) => (
      <StatusBadge
        options={statuses}
        value={status}
        label={statusHumanize}
      />
    ),
  },
  {
    id: 'availability_header',
    Header: () => <InLiveColumnHeader />,
    Cell: ({ row }) => <InLiveBadge inLive={row.original.availabilityStateLive} />,
  },
  {
    Header: 'Rules',
    Cell: ({ row: { original: { ruleSectionsAttributes } } }) => <RulesList rules={ruleSectionsAttributes} />,
  },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={editPath({ applicationId, id })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        <IconButton.Delete onClick={onDelete(id, name)} minimized />
      </ButtonToolbar>
    ),
  },
]);

export default function Index() {
  const {
    indexRequest, deleteRequest, newPath, editPath,
  } = PaymentsRoutes.Catalogs;
  const { response, refetch } = useQuery(indexRequest);
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const confirm = useConfirm();
  const { currentApplication: { id: applicationId, typeName: applicationType } } = useCurrentApplication();

  const deleteResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Catalog',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `Catalog '${name}' will be deleted and not available for work. Continue?` })
      .then(() => deleteRequest({ applicationId, id })
        .then(deleteResponseHandler));
  };

  const columns = useMemo(() => columnsFactory({
    editPath, applicationId, setDuplicatedRecord, onDelete, applicationType,
  }), []);

  return response && (
    <div>
      <PageHeader title="Catalogs" filter={<CatalogFilters applicationType={applicationType} />}>
        <IconButton.New to={newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        defaultSort={{ id: 'createdAt', desc: true }}
        data={response ? response.items : []}
      />
      <DuplicationModalWithPriority
        entityName="Catalog"
        record={duplicatedRecord}
        handleClose={() => setDuplicatedRecord(null)}
        entitiesByPriority={response.entitiesByPriority || {}}
        onSubmit={(values) => (
          PaymentsRoutes.Catalogs.duplicateRequest({ ...values, applicationId })
        )}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
