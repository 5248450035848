import React from 'react';
import { IconButton } from '@tripledotstudios/react-core';

import { Label } from '@controls/form';

export default function ExpandedLevelLayout({
  entity, LevelLayoutRoutes, applicationId, levelLayoutFields, disabled,
}) {
  return (
    <>
      {levelLayoutFields({
        applicationId, LevelLayoutRoutes, entity, disabled,
      })}
      <Label text="View Level Layout">
        <IconButton.View
          to={entity.id && LevelLayoutRoutes.editPath({ applicationId, id: entity.id })}
          title="Go to layout view form"
          minimized
          disabled={!entity.id}
        />
      </Label>
    </>
  );
}
