import React from 'react';
import { camelCase, upperFirst } from 'lodash';

import { useCurrentApplication } from '@hooks';
import { generateChapterBasedRoutes } from '@pages/routes';
import Form from './Form';

export default function EditModal({ recordType, ...props }) {
  const { applicationName } = useCurrentApplication();
  const Routes = generateChapterBasedRoutes(applicationName)[upperFirst(camelCase(recordType))];

  return <Form actionName="update" getForm={Routes.editRequest} submitForm={Routes.updateRequest} {...props} />;
}
