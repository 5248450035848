import React, { useMemo } from 'react';

import { ButtonToolbar, Spinner } from '@tripledotstudios/react-core';

import { TesterDeviceRoutes } from '@pages/routes';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  BooleanIcon,
} from '@pages/common';

import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import { useQuery, useCurrentApplication, useConfirm } from '@hooks';

import IconButton from '@controls/buttons';

import Filter from './Filter';

const columnsFactory = ({ applicationId, onDelete }) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'IDFV', accessor: 'idfv' },
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Enabled as test device',
    Cell: ({ row: { original: { enabled } } }) => <BooleanIcon value={enabled} />,
  },
  {
    Header: 'Assign to Experiments',
    Cell: ({ row: { original: { assignToExperiments } } }) => <BooleanIcon value={assignToExperiments} />,
  },
  {
    Header: 'Send Overrides',
    Cell: ({ row: { original: { sendOverrides } } }) => <BooleanIcon value={sendOverrides} />,
  },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, idfv } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={TesterDeviceRoutes.editPath({ id, applicationId })} minimized />
        <IconButton.Delete onClick={() => onDelete({ id, name, idfv })} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const { response, refetch, isLoading } = useQuery(TesterDeviceRoutes.indexRequest);
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Tester Device',
    actionName: 'deletion',
    refetch,
  });

  const onDelete = ({ id, name, idfv }) => {
    const nameIdentity = name ? ` '${name}'` : null;
    const identity = ['Device', nameIdentity, `with IDFV of ${idfv}`].filter((text) => text).join(' ');

    confirm.showConfirmation({ title: `${identity} will be deleted and not available for work. Continue?` })
      .then(() => TesterDeviceRoutes.deleteRequest({ id, params: { applicationId } })
        .then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({ applicationId, onDelete }), []);

  return response && (
    <>
      <PageHeader title="Tester Devices" filter={<Filter />}>
        <IconButton.New to={TesterDeviceRoutes.newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
            defaultSort={{ id: 'id', desc: true }}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </>
  );
}
