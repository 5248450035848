import React, { useState, useMemo } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
  DuplicationModal,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { MyPetCafeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';

const columnsFactory = ({
  applicationId, editPath, setDuplicatedRecord, onDelete,
}) => ([
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, inUse } } }) => {
      const disabled = isInUse(inUse);

      return (
        <ButtonToolbar>
          <IconButton.Edit to={editPath({ id, applicationId })} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
          <IconButton.Delete onClick={onDelete(id, name)} disabled={disabled} minimized />
        </ButtonToolbar>
      );
    },
  },
]);

export default function Index() {
  const {
    indexRequest, deleteRequest, newPath, duplicateRequest, editPath,
  } = MyPetCafeRoutes.UnlockRequirements;
  const { response, refetch, isLoading } = useQuery(indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Unlock Requirement',
    actionName: 'deletion',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Unlock Requirement '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const onDuplicate = (values) => (
    duplicateRequest({ ...values, applicationId })
  );

  const columns = useMemo(() => columnsFactory({
    applicationId, editPath, setDuplicatedRecord, onDelete,
  }), []);

  return (
    <>
      <PageHeader
        title="Unlock Requirements"
        filter={<Filter />}
      >
        <IconButton.New to={newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? <Spinner /> : (
        response && (
          <>
            <ReactTable
              columns={columns}
              data={response.items}
            />
            <DuplicationModal
              record={duplicatedRecord}
              entityName="Unlock Requirement"
              handleClose={() => setDuplicatedRecord(null)}
              onSubmit={onDuplicate}
              title="Duplicate Unlock Requirement"
            />
            <Pagination
              pageCount={response._meta.pages}
            />
          </>
        )
      )}
    </>
  );
}
