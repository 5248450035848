import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function CreateUpdateSet({
  fetchFunction,
  saveFunction,
  actionName,
  isBase = false,
}) {
  const { query } = useRouter();
  const { response, setResponse } = useQuery(fetchFunction);

  const collectionUrl = `/admin/applications/${query.applicationId}/my_pet_cafe/level_manifest_sets`;

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Level manifest set',
    setData: setResponse,
    actionName,
    collectionUrl,
  });

  const onSubmit = (values) => saveFunction(values).then(responseHandler);

  return response ? (
    <Form data={response} cancelButtonPath={collectionUrl} onSubmit={onSubmit} isBase={isBase} />
  ) : '';
}
