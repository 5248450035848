import {
  get,
  post,
  fetchCollection,
  newResource,
  editResource,
  createResource,
  updateResource,
  deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/my_pet_cafe/app_configs`;

export function getAppConfigs(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function baseAppConfig(values) {
  return get(`${baseUrl(values)}/base`);
}

export function newAppConfig(options) {
  return newResource(baseUrl(options));
}

export function editAppConfig(data, options) {
  return editResource(baseUrl(data), data, options);
}

export function createAppConfig(values) {
  return createResource(baseUrl(values), values);
}

export function updateAppConfig(values) {
  return updateResource(baseUrl(values), values);
}

export function duplicateAppConfig(data) {
  const { meta, ...duplicateData } = data;
  const { id } = duplicateData;

  return post(`${baseUrl(data)}/${id}/duplicate`, { ...duplicateData });
}

export function deleteAppConfig(values) {
  return deleteResource(baseUrl(values), values);
}
