import React from 'react';
import { PageTitle } from '@pages/common';

import {
  TesterDevicesIndex,
  TesterDevicesNew,
  TesterDevicesEdit,
} from '@pages/tester-devices';
import { TesterDeviceRoutes } from '@pages/routes';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function testerDevices() {
  return [
    <Route
      key={TesterDeviceRoutes.indexRawPath}
      path={TesterDeviceRoutes.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Tester Devices" action="List" />
          <TesterDevicesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={TesterDeviceRoutes.newRawPath}
      path={TesterDeviceRoutes.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Tester Device" action="New" />
          <TesterDevicesNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={TesterDeviceRoutes.editRawPath}
      path={TesterDeviceRoutes.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Tester Device" action="Edit" />
          <TesterDevicesEdit />
        </RouteContainer>
      )}
    />,
  ];
}
