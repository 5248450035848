import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import {
  BooleanIcon,
  TIMESTAMP_COLUMNS,
  InUseColumn,
  StatusBadge,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

const missionTypeStatuses = APP_DATA.enums['Missions::MissionTypeStatuses'];

export default function ColumnsFactory({ setEditedRecordId, onArchive }) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Key', accessor: 'key' },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row: { original: { status, statusHumanize } } }) => (
        <StatusBadge
          options={missionTypeStatuses}
          value={status}
          label={statusHumanize}
        />
      ),
    },
    { Header: 'Target values order', accessor: 'orderHumanize', disableSortBy: true },
    { Header: 'Target values type', accessor: 'valuesTypeHumanize', disableSortBy: true },
    {
      Header: 'Allow same values',
      Cell: ({ row: { original: { allowSameValues } } }) => <BooleanIcon value={allowSameValues} />,
    },
    {
      Header: 'Tier parameters',
      Cell: ({ row: { original: { tierParameters } } }) => tierParameters.map(({ nameWithType }) => (
        <span key={nameWithType} className="d-flex">{nameWithType}</span>
      )),
    },
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({
        row: {
          original: {
            id, name, inUse, status,
          },
        },
      }) => {
        const disabled = isInUse(inUse);
        return (
          <ButtonToolbar>
            <IconButton.Edit
              onClick={() => setEditedRecordId(id)}
              title={disabled ? 'Disabled as the entity is used' : null}
              disabled={disabled}
              minimized
            />
            <IconButton.Archive
              onClick={() => onArchive(id, name)}
              title={disabled ? 'Disabled as the entity is used' : null}
              disabled={disabled || status === missionTypeStatuses.ARCHIVED}
              minimized
            />
          </ButtonToolbar>
        );
      },
    },
  ];
}
