import React from 'react';
import { Spinner } from '@tripledotstudios/react-core';

import { PageHeader, Pagination } from '@pages/common';
import { useQuery } from '@hooks';
import APP_DATA from '@services/appData';
import { AuditRoutes } from '@pages/routes';

import Versions from './Versions';

import Filter from './Filter';

export default function Index() {
  const { applications } = APP_DATA;
  const VersionsRoutes = AuditRoutes.Versions;
  const {
    response: versionsResponse, isLoading: isLoadingVersions,
  } = useQuery(VersionsRoutes.listRequest);
  const {
    response: paginationResponse,
  } = useQuery(VersionsRoutes.paginationRequest);

  if (!applications) { return null; }

  return (
    <div>
      <PageHeader
        title="Activity Log"
        filter={<Filter applications={applications} />}
      />
      {isLoadingVersions ? <Spinner /> : (
        <Versions
          versions={versionsResponse.items}
          applications={applications}
        />
      )}
      <Pagination
        pageCount={paginationResponse?._meta?.pages}
        className="mt-3"
      />
    </div>
  );
}
