import TimeBasedFields from './TimeBased';
import CustomFields from './Custom';

export default function registry(type) {
  switch (type) {
    case 'Ads::Configuration::Interstitials::FrequencyRestrictions::Conditions::TimeBased':
      return TimeBasedFields;
    case 'Ads::Configuration::Interstitials::FrequencyRestrictions::Conditions::Custom':
      return CustomFields;
    default:
      throw new Error('Not supported Frequency Restriction Condition type');
  }
}
