import React, { useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { Alert, Spinner } from '@tripledotstudios/react-core';

import { InUseColumn, ReactTable } from '@pages/common';
import { useCurrentApplication } from '@hooks';

const getRowProps = (row) => {
  if (!row || !row.original.deletedAt) return {};

  return { className: 'text-muted' };
};

const columnsFactory = () => ([
  { Header: 'Segment Id', accessor: 'externalId' },
  {
    Header: 'Segment Name',
    Cell: ({ row }) => {
      const { deletedAt, name } = row.original;

      return (
        <>
          {deletedAt && (
            <Badge pill className="px-3 py-1 me-1" bg="secondary">Deleted</Badge>
          )}
          {name}
        </>
      );
    },
  },
  { Header: 'Info', accessor: 'info' },
  InUseColumn,
  { Header: 'Sync At (UTC)', accessor: 'updatedAt' },
]);

export default function PlayerSegmentsTable({ response, isLoading }) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const columns = useMemo(() => columnsFactory({ applicationId }), [applicationId]);

  if (isLoading) return <Spinner />;

  return (response?.items?.length) ? (
    <ReactTable
      columns={columns}
      data={response.items}
      disableSortBy
      className="mb-0"
      additionalProps={{ getRowProps }}
    />
  ) : (
    <Alert variant="info" className="mb-0">
      <b>There are no segments</b>
    </Alert>
  );
}
