import {
  fetchCollection,
  newResource,
  editResource,
  createResource,
  updateResource,
  deleteResource,
  post,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/payments/products`;

export function getProducts(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function newProduct(options) {
  return newResource(baseUrl(options), options);
}

export function editProduct(data, options) {
  return editResource(baseUrl(data), data, options);
}

export function createProduct(values) {
  return createResource(baseUrl(values), values);
}

export function updateProduct(values) {
  return updateResource(baseUrl(values), values);
}

export function duplicateProduct(params, values = {}) {
  return post(`${baseUrl(params)}/${params.id}/duplicate`, values);
}

export function deleteProduct(values) {
  return deleteResource(baseUrl(values), values);
}
