import React from 'react';
import { PageTitle } from '@pages/common';

import {
  BanksEdit, BanksIndex, BanksNew, ChapterSetsIndex, ChapterSetsNew, ChapterSetsEdit, ChapterSetImportsNew,
  ChapterSetImportsEdit, DifficultySchemasNew, DifficultySchemasIndex, DifficultySchemasEdit, LevelLayoutsEdit,
  ModesConfigsIndex, ModesConfigsNew, ModesConfigsEdit, CategorySetsIndex, CategorySetsNew, CategorySetsEdit,
  JourneysExperimentScoped, DifficultySchemaSetsIndex, DifficultySchemaSetsNew, DifficultySchemaSetsEdit,
  RewardsForWinsSchemasIndex, RewardsForWinsSchemasNew, RewardsForWinsSchemasEdit,
  RewardSetsIndex, RewardSetsNew, RewardSetsEdit, LayoutsReplacement, BankBulkImportsEdit, AssetBundleSchemaSetsIndex,
  AssetBundleSchemaSetsNew, AssetBundleSchemaSetsEdit,
} from '@pages/tile';
import { TileRoutes } from '@pages/routes';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function tile() {
  const scope = '/admin/applications/:applicationId/tile';

  return [
    <Route
      key={TileRoutes.Banks.indexRawPath}
      path={TileRoutes.Banks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Level Layout Banks" />
          <BanksIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.Banks.newRawPath}
      path={TileRoutes.Banks.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout Bank" action="New" />
          <BanksNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.Banks.editRawPath}
      path={TileRoutes.Banks.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout Bank" action="Edit" />
          <BanksEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.LayoutReplacements.newRawPath}
      path={TileRoutes.LayoutReplacements.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layouts Replacement" action="New" />
          <LayoutsReplacement />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.ChapterSets.indexRawPath}
      path={TileRoutes.ChapterSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Chapter Sets" />
          <ChapterSetsIndex importPath={TileRoutes.ChapterSetImports.newPath} />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.ChapterSets.newRawPath}
      path={TileRoutes.ChapterSets.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Chapter Set" action="New" />
          <ChapterSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.ChapterSets.editRawPath}
      path={TileRoutes.ChapterSets.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Chapter Set" action="Edit" />
          <ChapterSetsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.ChapterSetImports.newRawPath}
      path={TileRoutes.ChapterSetImports.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Chapter Set" action="Create from CSV" />
          <ChapterSetImportsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.ChapterSetImports.editRawPath}
      path={TileRoutes.ChapterSetImports.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Chapter Set" action="Create from CSV" />
          <ChapterSetImportsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.BankBulkImports.editRawPath}
      path={TileRoutes.BankBulkImports.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Level Layout Bank" action="Bulk Upload" />
          <BankBulkImportsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={`${scope}/difficulty_schemas`}
      path={`${scope}/difficulty_schemas`}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="DDA Schemas" />
          <DifficultySchemasIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={`${scope}/difficulty_schemas/new`}
      path={`${scope}/difficulty_schemas/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="DDA Schema" action="New" />
          <DifficultySchemasNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={`${scope}/difficulty_schemas/:id/edit`}
      path={`${scope}/difficulty_schemas/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="DDA Schema" action="Edit" />
          <DifficultySchemasEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.ModesConfigs.indexRawPath}
      path={TileRoutes.ModesConfigs.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Modes Configs" />
          <ModesConfigsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.ModesConfigs.newRawPath}
      path={TileRoutes.ModesConfigs.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Mode Config" action="New" />
          <ModesConfigsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.ModesConfigs.editRawPath}
      path={TileRoutes.ModesConfigs.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Mode Config" action="Edit" />
          <ModesConfigsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.CategorySets.indexRawPath}
      path={TileRoutes.CategorySets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Category Sets" />
          <CategorySetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.CategorySets.newRawPath}
      path={TileRoutes.CategorySets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Category Set" action="New" />
          <CategorySetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.CategorySets.editRawPath}
      path={TileRoutes.CategorySets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Category Set" action="Edit" />
          <CategorySetsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.LevelLayouts.editRawPath}
      path={TileRoutes.LevelLayouts.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout" action="Edit" />
          <LevelLayoutsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.Journeys.EventConfigs.experimentScopedRawPath}
      path={TileRoutes.Journeys.EventConfigs.experimentScopedRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Journey variant" />
          <JourneysExperimentScoped />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.DifficultySchemaSets.indexRawPath}
      path={TileRoutes.DifficultySchemaSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Difficulty Schema Set" action="List" />
          <DifficultySchemaSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.DifficultySchemaSets.newRawPath}
      path={TileRoutes.DifficultySchemaSets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Difficulty Schema Set" action="New" />
          <DifficultySchemaSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.DifficultySchemaSets.editRawPath}
      path={TileRoutes.DifficultySchemaSets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Difficulty Schema Set" action="Edit" />
          <DifficultySchemaSetsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.RewardSets.indexRawPath}
      path={TileRoutes.RewardSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Reward Schema Set" action="List" />
          <RewardSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.RewardSets.newRawPath}
      path={TileRoutes.RewardSets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Reward Schema Set" action="New" />
          <RewardSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.RewardSets.editRawPath}
      path={TileRoutes.RewardSets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Reward Schema Set" action="Edit" />
          <RewardSetsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.RewardsForWinsSchemas.indexRawPath}
      path={TileRoutes.RewardsForWinsSchemas.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Coins for wins Schemas" />
          <RewardsForWinsSchemasIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.RewardsForWinsSchemas.newRawPath}
      path={TileRoutes.RewardsForWinsSchemas.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Coins for wins Schema" action="New" />
          <RewardsForWinsSchemasNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.RewardsForWinsSchemas.editRawPath}
      path={TileRoutes.RewardsForWinsSchemas.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Coins for wins Schema" action="Edit" />
          <RewardsForWinsSchemasEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.AssetBundleSchemaSets.indexRawPath}
      path={TileRoutes.AssetBundleSchemaSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Asset Bundle Schema Sets" />
          <AssetBundleSchemaSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.AssetBundleSchemaSets.editRawPath}
      path={TileRoutes.AssetBundleSchemaSets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Asset Bundle Schema Set" action="Edit" />
          <AssetBundleSchemaSetsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={TileRoutes.AssetBundleSchemaSets.newRawPath}
      path={TileRoutes.AssetBundleSchemaSets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Asset Bundle Schema Set" action="New" />
          <AssetBundleSchemaSetsNew />
        </RouteContainer>
      )}
    />,
  ];
}
