import React, { useMemo } from 'react';

import { TextCell } from './cells';

export default function Translations({
  keyPath,
  translations,
  oldValues,
  setFieldValue,
  disabled,
  isNewRecord,
}) {
  return (
    useMemo(() => (
      translations.map(({ value, _uuid }, translationIndex) => (
        <TextCell
          key={_uuid}
          name={`translationsAttributes.${translationIndex}.value`}
          path={keyPath}
          value={value}
          oldValues={oldValues}
          setFieldValue={setFieldValue}
          disabled={disabled}
          isNewRecord={isNewRecord}
          multiline
        />
      ))
    ),
    [
      keyPath,
      translations,
      setFieldValue,
      disabled,
    ])
  );
}
