import React from 'react';
import { useQuery } from '@hooks';

import { formResponseHandler } from '@requests/responseHandlers';

import { MachineLearningRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function MachineLearningModelsEdit() {
  const { editRequest, updateRequest } = Routes.Models;
  const { response, setResponse } = useQuery(editRequest);

  const redirectIfSuccessHandler = formResponseHandler({
    entityName: 'ML Model',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => updateRequest(values).then(redirectIfSuccessHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
