import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { PageHeader } from '@pages/common';
import { Form, useCurrentApplication } from '@hooks';
import {
  Label, Field, FormButtonsGroup, FieldArrayWithLabel,
} from '@controls/form';

export default function SingleScreenTutorialConfigsForm({ data, onSubmit }) {
  const fieldsConfiguration = [
    { label: 'Game', name: 'game', type: 'text' },
    { label: 'Enable', name: 'enable', type: 'checkbox' },
  ];

  const { query: { applicationId } } = useRouter();
  const { routingScope } = useCurrentApplication();

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit}>
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Single Screen Tutorial Config`}>
            <FormButtonsGroup
              cancelButtonPath={`${routingScope}/puzzle_time/single_screen_tutorial_configs`}
            />
          </PageHeader>

          <Field type="hidden" name="applicationId" />

          <Label text="Name"><Field type="text" name="name" /></Label>

          <FieldArrayWithLabel
            collectionName="cells"
            values={values.cells}
            fieldsConfiguration={fieldsConfiguration}
            isHorizontal
          />
        </>
      )}
    </Form>
  );
}
