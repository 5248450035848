import React from 'react';

import { editManifestSet, updateManifestSet } from '@requests/my-pet-cafe/manifestSets';
import CreateUpdateSet from './CreateUpdateSet';

export default function Edit() {
  return (
    <CreateUpdateSet
      fetchFunction={editManifestSet}
      saveFunction={updateManifestSet}
      actionName="update"
    />
  );
}
