import React from 'react';

import { FormButtonsGroup } from '@controls/form';
import { DuplicateButton, PageHeader } from '@pages/common';
import { MyPetCafeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

export default function Header({
  data,
  applicationId,
  title,
  entityName,
}) {
  const { id } = data;

  return (
    <PageHeader title={title}>
      <FormButtonsGroup
        cancelButtonPath={MyPetCafeRoutes.Adventures.EventBanks.indexPath({ applicationId })}
      >
        {id && (
          <>
            <IconButton.ActivityLog
              applicationId={applicationId}
              id={id}
              entityType="MyPetCafe::Adventures::Bank"
            />
            <FormButtonsGroup.Divider />
          </>
        )}
        <DuplicateButton
          routes={MyPetCafeRoutes.Adventures.EventBanks}
          data={data}
          entityName={entityName}
          modalType="withPriority"
        />
      </FormButtonsGroup>
    </PageHeader>
  );
}
