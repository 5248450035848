import { ServerError } from '@controls/form';
import React from 'react';
import { Button } from 'react-bootstrap';

import { pickRewardsConfigurations } from '@components/merge/services/RewardsConfigurations';
import { FormGroup, useConfirm } from '@hooks';
import isEqualProps from '@services/isEqualProps';
import { ReactTable, RemoveButton, REORDER_COLUMN } from '@pages/common';
import InternalIdLabel from '@components/merge/shared/InternalIdLabel';
import Rewards from '@components/merge/shared/Rewards';
import Targets from '@components/merge/shared/Targets';

const rewardsConfigurations = pickRewardsConfigurations([
  'Item', 'Coins', 'Gems', 'GiftBag', 'EventProgress',
]);

const columns = ({ confirm, items }) => [
  REORDER_COLUMN,
  {
    Header: 'Order',
    accessor: 'position',
    style: { width: 75 },
  },
  {
    Header: 'Internal Id',
    style: { width: 250 },
    Cell: ({ row: { original } }) => <InternalIdLabel name={original.internalId} />,
  },
  {
    Header: 'Required Items',
    Cell: ({ row: { original } }) => (
      <FormGroup name={`ordersAttributes[${original.index}]`}>
        <Targets values={original.targetsAttributes} items={items} />
      </FormGroup>
    ),
  },
  {
    Header: 'Rewards',
    Cell: ({ row: { original } }) => (
      <FormGroup name={`ordersAttributes[${original.index}]`}>
        <Rewards
          handleCollectionErrors={false}
          items={items}
          rewardsConfigurations={rewardsConfigurations}
          values={original.rewardsAttributes}
        />
      </FormGroup>
    ),
  },
  {
    Header: 'Actions',
    style: { width: 75 },
    Cell: ({ row: { original: { index, dispatch } } }) => {
      const removeOrder = () => {
        confirm.showConfirmation({ title: 'Order and its content will be removed. Continue?' })
          .then(() => dispatch({ type: 'removeOrder', index }));
      };
      return (
        <RemoveButton size="lg" onClick={removeOrder} title="Delete the order" />
      );
    },
  },
];

const Orders = React.memo(({
  values, items, dispatch,
}) => {
  const confirm = useConfirm();
  const orderColumns = React.useMemo(
    () => columns({
      confirm,
      items,
    }),
    [JSON.stringify(items)],
  );
  const orders = values.map((o, index) => ({ ...o, dispatch, index })).filter((o) => !o._destroy);
  const onDragEnd = ({ source, destination }) => {
    dispatch({
      type: 'reorderOrders',
      path: 'ordersAttributes',
      sourceIndex: source.index,
      destinationIndex: destination.index,
    });
  };
  return (
    <>
      <ServerError name="ordersAttributes.list" />
      <ReactTable
        columns={orderColumns}
        data={orders}
        disableSortBy
        droppableId="orders"
        isDragDisabled={false}
        onDragEnd={onDragEnd}
        tableProps={{ fixed: true }}
        wholeRowDraggable={false}
      />
      <Button onClick={() => dispatch({ type: 'createOrder' })}>Create Order</Button>
    </>
  );
}, isEqualProps(['values', 'items']));

export default Orders;
