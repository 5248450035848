import React from 'react';
import PropTypes from 'prop-types';
import {
  capitalize, get, isEmpty, map, omit,
} from 'lodash';
import styled from 'styled-components';

import APP_DATA from '@services/appData';

const ContentContainer = styled.div`
  text-align: left;
  ${({ wrap }) => (!wrap && 'white-space: pre')}
`;

const { rewardOperations, rewardTypes } = APP_DATA;

const renderAttributeValue = (attributeType, value) => {
  switch (attributeType) {
    case 'time_period': {
      return `${value} seconds`;
    }
    default: return value;
  }
};

export default function RewardContent({ model, wrap = false }) {
  const data = omit(model.data, '_uuid');
  const operationAttributes = omit((data || {}).operationAttributes, '_uuid');
  const operation = rewardOperations[model.operation];
  const rewardType = rewardTypes[model.rewardType];

  return (
    <ContentContainer wrap={wrap}>
      {map(omit(data, 'operationAttributes', 'fallbackRewardId', 'fallbackRewardQuantity'), (value, key) => {
        const attributeType = rewardType.attributes[key]?.type;

        return (
          <p className="mb-0" key={key}>
            <b>{`${capitalize(key)}: `}</b>
            {renderAttributeValue(attributeType, value)}
          </p>
        );
      })}
      {model.type === 'Rewards::Container' && (
        <>
          <div className="mb-2">
            <b>{`Operation: ${operation.name}`}</b>
            {map(operationAttributes, (value, key) => (
              <p className="mb-0" key={key}>
                <b>{`${get(operation.attributes, `[${key}].name`, capitalize(key))}: `}</b>
                {value}
              </p>
            ))}
          </div>
          {model.content && (
            <>
              <b>Content:</b>
              {isEmpty(model.content)
                ? ' none'
                : (
                  <ul className="mb-0">
                    {map(model.content, (set) => (
                      <li>{`${set.name} (${set.rewardType}): ${set.quantity}`}</li>
                    ))}
                  </ul>
                )}
            </>
          )}
        </>
      )}
    </ContentContainer>
  );
}

RewardContent.propTypes = {
  model: PropTypes.shape({}).isRequired,
};
