import React from 'react';

import AppUsersNavigationSection from './AppUsersNavigationSection';
import LocalizationsNavigationSection from './LocalizationsNavigationSection';
import VersionUpdatesNavigationSection from './VersionUpdatesNavigationSection';

import HomeNavigationItem from './HomeNavigationItem';
import GameSettingsNavigationItem from './GameSettingsNavigationItem';
import AbExperimentsNavigationItem from './AbExperimentsNavigationItem';
import AppEventsNavigationItem from './AppEventsNavigationItem';
import LabelsNavigationItem from './LabelsNavigationItem';
import PlayerSegmentsNavigationItem from './PlayerSegmentsNavigationItem';
import NamedRuleGroupsNavigationItem from './NamedRuleGroupsNavigationItem';

export default function ApplicationItemSet({
  applicationId,
  applicationTypeName,
  routingScope,
  minimized = false,
  children,
}) {
  return (
    <>
      <HomeNavigationItem
        routingScope={routingScope}
        minimized={minimized}
      />

      <GameSettingsNavigationItem
        applicationId={applicationId}
        applicationTypeName={applicationTypeName}
        minimized={minimized}
      />

      <AbExperimentsNavigationItem routingScope={routingScope} minimized={minimized} />

      <LabelsNavigationItem routingScope={routingScope} minimized={minimized} />

      <NamedRuleGroupsNavigationItem applicationId={applicationId} minimized={minimized} />

      <PlayerSegmentsNavigationItem applicationId={applicationId} minimized={minimized} />

      <AppUsersNavigationSection applicationId={applicationId} minimized={minimized} />

      {children}

      <LocalizationsNavigationSection applicationId={applicationId} minimized={minimized} />

      <VersionUpdatesNavigationSection applicationId={applicationId} minimized={minimized} />

      <AppEventsNavigationItem applicationId={applicationId} minimized={minimized} />
    </>
  );
}
