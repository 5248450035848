import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';

import { TextFilter, SelectFilter, Filter } from '@pages/common';
import { MergeRoutes } from '@pages/routes';
import { requestWithToSelectOptions } from '@services/toSelectOptions';

export default function WorldsFilter({ applicationId }) {
  const [worldSchemasOptions, setWorldSchemasOptions] = useState([]);

  useEffect(() => {
    requestWithToSelectOptions(MergeRoutes.WorldSchemas.indexRequest, applicationId).then(setWorldSchemasOptions);
  }, []);

  return (
    <Filter defaultComponents={['availabilityStateIn']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <SelectFilter
              name="schemaIdIn"
              label="World Schema = "
              options={worldSchemasOptions}
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
