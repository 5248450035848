import React, { useMemo, useEffect, useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
  DuplicationModal,
  Publish,
} from '@pages/common';

import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';
import ColumnsFactory from './ColumnsFactory';

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response } = useQuery(MergeRoutes.Products.indexRequest, { includeInUse: true });
  const [publishInfo, setPublishInfo] = useState(null);
  const { query } = useRouter();

  useEffect(() => {
    if (response) {
      setPublishInfo(response.publishInfo);
    }
  }, [response]);

  const onPublish = () => {
    MergeRoutes.Products.publishRequest(query).then(() => { setPublishInfo(null); });
  };

  const columns = useMemo(() => ColumnsFactory({ query, setDuplicatedRecord }), []);

  return response && (
    <div>
      <PageHeader title="Products" filter={<Filter response={response} />}>
        <IconButton.New to={MergeRoutes.Products.newPath({ applicationId: query.applicationId })} />
      </PageHeader>
      <Publish info={publishInfo} onPublish={onPublish} className="mb-2" />

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Product"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => MergeRoutes.Products.duplicateRequest({ ...values, applicationId: query.applicationId })}
        title="Duplicate Product"
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
