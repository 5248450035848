import React from 'react';

import { useCurrentApplication, useQuery } from '@hooks';
import { NamedRuleGroupsRoutes } from '@pages/routes';

import SelectField from '@controls/form/SelectField';

export default function NamedGroupRule({ disabled }) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { response } = useQuery(NamedRuleGroupsRoutes.indexRequest, { applicationId, withoutPagination: true });

  if (!response) return '';

  const options = response.items.map(({ id, name }) => (
    { value: id, label: `${name}` }
  ));

  return (
    <SelectField
      name="groupIds"
      options={options}
      isMulti
      isDisabled={disabled}
    />
  );
}
