import React from 'react';

import { Field, Label } from '@controls/form';

export default function StockpileCycleLevelConfig() {
  return (
    <Label text="Cycle Limit" tooltipText="Count of cycles that a user can make through the stockpile in game round.">
      <Field name="cycleLimit" type="number" />
    </Label>
  );
}
