import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@controls/buttons';
import { RulesList, PriorityColumn } from '@pages/common';

export default function columnsFactory({
  levelEntityName, onOverrideRemove, showLevelsCount, showRewardedLevels, routes, disabled,
}) {
  return [
    {
      Header: 'Name',
      Cell: ({ row }) => {
        const { id, name, applicationId } = row.original;

        return (
          <Link target="_blank" to={routes.LevelBanks.editPath({ id, applicationId })}>
            {name}
          </Link>
        );
      },
    },
    ...(showLevelsCount ? (
      [
        { Header: `Number of ${levelEntityName}s`, Cell: ({ row }) => row.original.levelsCount },
      ]
    ) : []),
    ...(showRewardedLevels ? (
      [
        {
          Header: `Rewarded ${levelEntityName} numbers`,
          Cell: ({ row }) => (row.original.rewardedLevelNumbers || []).join(', '),
        },
      ]
    ) : []),
    {
      Header: 'Rules',
      Cell: ({ row }) => <RulesList rules={row.original.ruleSectionsAttributes} />,
    },
    PriorityColumn,
    {
      Header: 'Actions',
      Cell: ({ row }) => {
        const { id, name } = row.original;

        return (
          <IconButton.Delete
            onClick={() => onOverrideRemove(id, name)}
            disabled={disabled}
          >
            Remove
          </IconButton.Delete>
        );
      },
    },
  ];
}
