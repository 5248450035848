import React from 'react';

import APP_DATA from '@services/appData';

import { Field } from '@controls/form';

const { enums } = APP_DATA;
const actionTypesEnum = enums['Solitaire::FlexiblePopups::ActionTypes'];

export default function FormFields({ actionType, LabelComponent }) {
  return (
    actionType === actionTypesEnum.OPEN_URL && (
      <LabelComponent name="url">
        <Field name="url" />
      </LabelComponent>
    )
  );
}
