import React from 'react';
import { useTheme } from 'styled-components';
import { darken } from 'polished';

export default function DisabledCell() {
  const theme = useTheme();

  return (
    <td style={{ backgroundColor: darken(0.05, theme.variants.light) }} />
  );
}
