import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function OverlayTooltip({
  text,
  delay,
  placement = 'right',
  children,
}) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip show>{text}</Tooltip>}
      delay={delay}
    >
      {children}
    </OverlayTrigger>
  );
}
