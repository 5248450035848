import React, { useMemo } from 'react';
import { ReactTable } from '@pages/common';

export default function Items({ values }) {
  const columns = useMemo(
    () => [
      { Header: 'Internal ID', accessor: 'internalId' },
      { Header: 'Line', accessor: 'line' },
      { Header: 'Section', accessor: 'category' },
      { Header: 'Tier', accessor: 'tier' },
      { Header: 'Merges Into', accessor: 'mergesInto' },
    ],
    [],
  );

  return (
    values ? (
      <ReactTable
        columns={columns}
        data={values}
        disableSortBy
      />
    ) : ''
  );
}
