import { useEffect } from 'react';
import { useWebSocketChannel } from '@hooks';
import { useFlashMessages } from '@tripledotstudios/react-core';

import { MESSAGE_TYPES } from '@hooks/useWebSocketChannel';

export default function FlashAnnouncements() {
  const { messages } = useWebSocketChannel({
    path: 'admin_users/announcements',
  });

  const { warning } = useFlashMessages();
  const message = messages[0];

  useEffect(() => {
    if (message && message.type === MESSAGE_TYPES.CHANNEL && typeof message.data === 'string') {
      warning(message.data);
    }
  }, [message]);

  return '';
}
