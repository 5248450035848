import React, { useState } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { fromClassNameToOptions } from '@services/enums';
import { Label } from '@controls/form';

import Select from '@controls/Select';

export default function TypeModal({ defaultValue, onSave, onHide }) {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const translationTypesOptions = fromClassNameToOptions('Localizations::TranslationTypes');

  const handleSave = () => {
    onSave({ value: selectedValue });
    onHide();
  };

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Translation key&apos;s type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Label
          text="Type"
          className="mb-0"
          labelSize={2}
          fieldSize={10}
        >
          <Select
            selectedValue={selectedValue}
            onChange={({ value }) => setSelectedValue(value)}
            options={translationTypesOptions}
            placeholder="Select translation's type"
            autoFocus
          />
        </Label>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
        <Button variant="primary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}
