import React from 'react';

import {
  ApplicationItemSet,
  AssetsNavigationItem,
  AdsNavigationSection,
} from './common';

export default function SolitaireScapesCollection({ applicationId, routingScope, minimized = false }) {
  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="solitaire_scapes_collection"
      routingScope={routingScope}
      minimized={minimized}
    >
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
