import React from 'react';
import { useFlashMessages } from '@tripledotstudios/react-core';

import { MyPetCafeRoutes } from '@pages/routes';

import APP_DATA from '@services/appData';

import {
  Form,
  useCurrentApplication,
  useConfirm,
} from '@hooks';

import { fromClassNameToOptions } from '@services/enums';

import {
  Label,
  Field,
  SelectField,
  PriorityField,
  StaticFieldsFormGroup,
} from '@controls/form';

import RulesForm from '@pages/rules/Form';
import { combineReducers, rulesReducer, rewardableReducer } from '@reducers';
import buildOnSubmit from '@services/journeys/banks/buildOnSubmit';
import formReducer from './form/reducer';

import Header from './form/Header';
import Events from './form/Events';

export default function AdventureBanksForm({
  data, title, onSubmit: onPageSubmit, entityName,
}) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { enums } = APP_DATA;
  const statusesEnum = enums['MyPetCafe::Adventures::BankStatuses'];
  const statuses = fromClassNameToOptions('MyPetCafe::Adventures::BankStatuses');
  const confirm = useConfirm();
  const { error } = useFlashMessages();

  const { status } = data;

  const isArchived = status === statusesEnum.ARCHIVED;

  const onSubmit = buildOnSubmit({
    applicationId,
    data,
    entityName,
    statusesEnum,
    onSubmitFunction: onPageSubmit,
    confirm,
    error,
    routes: MyPetCafeRoutes.Adventures.EventBanks,
  });

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([rulesReducer, rewardableReducer, formReducer])}
      sharedInputProps={{ disabled: isArchived }}
    >
      {({ values }) => (
        <>
          <Header
            data={data}
            applicationId={applicationId}
            title={title}
            entityName={entityName}
          />
          <Label text="Name">
            <Field type="text" name="name" disabled={false} />
          </Label>
          <Label text="Status">
            <SelectField name="status" options={statuses} />
          </Label>
          <Label text="Priority">
            <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} />
          </Label>

          <StaticFieldsFormGroup data={data} />
          <Events events={values.eventsAttributes} isArchived={isArchived} />
          <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} disabled={isArchived} />
        </>
      )}
    </Form>
  );
}
