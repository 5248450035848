import { get, fetchCollection } from './common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/devices`;

export function getDevices(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function userSearch(data) {
  return get(`${baseUrl(data)}/user_search`, { params: data });
}
