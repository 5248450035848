import React from 'react';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from 'styled-components';
import { Sidebar } from '@tripledotstudios/react-core';

import { PlayerSegmentRoutes } from '@pages/routes';

export default function PlayerSegmentsNavigationItem({ applicationId, minimized }) {
  const theme = useTheme();

  return (
    <Sidebar.LinkItem
      color={theme.queenBlue}
      icon={faChartPie}
      to={PlayerSegmentRoutes.indexPath({ applicationId })}
      title="Player Segments"
      minimized={minimized}
    />
  );
}
