import React from 'react';
import { PageTitle } from '@pages/common';

import {
  PlayerLevelsSetsIndexPage,
  PlayerLevelsSetsNewPage,
  PlayerLevelsSetsEditPage,
} from '@components/spider-solitaire/player-levels/sets';

import {
  DailyChallengesWorldStatsConfigsIndexPage,
  DailyChallengesWorldStatsConfigsNewPage,
  DailyChallengesWorldStatsConfigsEditPage,
} from '@components/spider-solitaire/daily-challenges/world-stats-configs';

import { SpiderSolitaireRoutes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function spiderSolitaire() {
  return [
    <Route
      key={SpiderSolitaireRoutes.PlayerLevelSets.indexRawPath}
      path={SpiderSolitaireRoutes.PlayerLevelSets.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Player Level Sets" />
          <PlayerLevelsSetsIndexPage />
        </RouteContainer>
      )}
    />,
    <Route
      key={SpiderSolitaireRoutes.PlayerLevelSets.newRawPath}
      path={SpiderSolitaireRoutes.PlayerLevelSets.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Player Level Set" action="New" />
          <PlayerLevelsSetsNewPage />
        </RouteContainer>
      )}
    />,
    <Route
      key={SpiderSolitaireRoutes.PlayerLevelSets.editRawPath}
      path={SpiderSolitaireRoutes.PlayerLevelSets.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Player Level Set" action="Edit" />
          <PlayerLevelsSetsEditPage />
        </RouteContainer>
      )}
    />,
    <Route
      key={SpiderSolitaireRoutes.WorldStatsConfig.indexRawPath}
      path={SpiderSolitaireRoutes.WorldStatsConfig.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="World Statistics" />
          <DailyChallengesWorldStatsConfigsIndexPage />
        </RouteContainer>
      )}
    />,
    <Route
      key={SpiderSolitaireRoutes.WorldStatsConfig.newRawPath}
      path={SpiderSolitaireRoutes.WorldStatsConfig.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="World Statistics Config" action="New" />
          <DailyChallengesWorldStatsConfigsNewPage />
        </RouteContainer>
      )}
    />,
    <Route
      key={SpiderSolitaireRoutes.WorldStatsConfig.editRawPath}
      path={SpiderSolitaireRoutes.WorldStatsConfig.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="World Statistics Config" action="Edit" />
          <DailyChallengesWorldStatsConfigsEditPage />
        </RouteContainer>
      )}
    />,
  ];
}
