import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { useFormContext } from '@hooks';
import { PageSection, Alert } from '@pages/common';
import { ServerError } from '@controls/form';

import FieldsStatusesController from './FieldsStatusesController';
import Fields from './Fields';

export default function TabContent({
  fieldsFactory, fieldsNames, abTestable, localeNamespace, focus,
}) {
  const { values } = useFormContext();

  return (
    <FieldsStatusesController fieldsNames={fieldsNames} enabledFields={values.enabledFieldsNames}>
      {({
        enabledFields, disabledFields, removeButtonBuilderFn, addButtonBuilderFn,
      }) => (
        <>
          <ServerError name="enabledFieldsNames" as={Alert} />

          <PageSection
            title="Enabled Fields"
            size="lg"
            icon={(<FontAwesomeIcon icon={faCircleCheck} color="green" size="lg" />)}
          />
          <Fields
            fieldsFactory={fieldsFactory}
            fields={enabledFields}
            values={values}
            button={removeButtonBuilderFn}
            abTestable={abTestable}
            localeNamespace={localeNamespace}
            focus={focus}
          />

          <PageSection
            title="Disabled Fields"
            size="lg"
            icon={(<FontAwesomeIcon icon={faCircleMinus} color="grey" size="lg" />)}
          />
          <Fields
            fieldsFactory={fieldsFactory}
            fields={disabledFields}
            values={values}
            button={addButtonBuilderFn}
            abTestable={abTestable}
            localeNamespace={localeNamespace}
            focus={focus}
            disabled
          />
        </>
      )}
    </FieldsStatusesController>
  );
}
