import React from 'react';
import { PageTitle } from '@pages/common';

import {
  LevelLayoutsIndex,
  LevelLayoutsEdit,
  LevelLayoutsNew,
} from '@components/solitaire/level-layouts/pages';

import JourneysExperimentScoped from '@pages/solitaire/journeys/ExperimentScoped';
import ActivationJourneysExperimentScoped from '@pages/solitaire/activation-journeys/ExperimentScoped';

import { SolitaireRoutes as Routes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function solitaire() {
  return [
    <Route
      key={Routes.LevelLayouts.indexRawPath}
      path={Routes.LevelLayouts.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Level Layouts" />
          <LevelLayoutsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.LevelLayouts.newRawPath}
      path={Routes.LevelLayouts.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout" action="New" />
          <LevelLayoutsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.LevelLayouts.editRawPath}
      path={Routes.LevelLayouts.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Layout" action="Edit" />
          <LevelLayoutsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.Journeys.EventConfigs.experimentScopedRawPath}
      path={Routes.Journeys.EventConfigs.experimentScopedRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Journey variant" />
          <JourneysExperimentScoped />
        </RouteContainer>
      )}
    />,
    <Route
      key={Routes.ActivationJourneys.EventConfigs.experimentScopedRawPath}
      path={Routes.ActivationJourneys.EventConfigs.experimentScopedRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Activation Journey variant" />
          <ActivationJourneysExperimentScoped />
        </RouteContainer>
      )}
    />,
  ];
}
