import React from 'react';

import {
  useCurrentApplication,
  useQuery,
  useFormContext,
  useFormGroup,
} from '@hooks';
import { PlayerSegmentRoutes } from '@pages/routes';

import SelectField from '@controls/form/SelectField';

const getItemLabel = ({ name, info, deletedAt }) => `${name} [${deletedAt ? 'deleted' : info}]`;

export default function PlayerSegmentRule({ disabled }) {
  const { setFieldValue } = useFormContext();
  const { generateName } = useFormGroup();

  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { response } = useQuery(PlayerSegmentRoutes.indexRequest, { applicationId, withoutPagination: true });

  if (!response) return '';

  const options = response.items.map((item) => ({ value: item.id, label: getItemLabel(item) }));

  const setPlayerSegmentNames = (playerSegments) => {
    const segmentIds = playerSegments.map((segment) => segment.value);
    const selectedSegments = response.items.filter(({ id }) => segmentIds.includes(id));
    const segmentNames = selectedSegments.map(({ name }) => name);

    setFieldValue(generateName('playerSegmentNames'), segmentNames);
  };

  return (
    <SelectField
      name="playerSegments"
      options={options}
      isMulti
      isDisabled={disabled}
      testId="player_segment_rule_code"
      onChange={setPlayerSegmentNames}
    />
  );
}
