import React from 'react';
import { IconButton } from '@tripledotstudios/react-core';
import {
  faChartLine,
  faChartSimple,
  faChartArea,
} from '@fortawesome/free-solid-svg-icons';

import useCurrentApplication from '@hooks/useCurrentApplication';
import APP_DATA from '@services/appData';
import cleanedUrl from '@root/services/cleanedUrl';
import DropdownItem from '@root/controls/buttons/dropdown/DropdownItem';

const BUTTON_CONFIGURATIONS = {
  default: {
    text: 'Analyse LAB',
    icon: faChartLine,
    urlAttributeName: 'lookerAbExperimentsUrl',
  },
  cohort: {
    text: 'Analyse LAB Cohort',
    icon: faChartSimple,
    urlAttributeName: 'lookerAbExperimentsCohortUrl',
  },
  lite: {
    text: 'Analyse LAB Lite',
    icon: faChartArea,
    urlAttributeName: 'lookerAbExperimentsLiteUrl',
  },
};

function getButtonConfiguration(id, type) {
  const configuration = BUTTON_CONFIGURATIONS[type];
  const { currentApplication: { id: applicationId, lookerAbName } } = useCurrentApplication();
  const { adminSettings } = APP_DATA;

  const rawUrl = adminSettings[configuration?.urlAttributeName];

  const params = new URLSearchParams({ 'Experiment ID': id, App: lookerAbName }).toString();

  return (
    {
      noLookerAbButton: !rawUrl || !applicationId || !id || !lookerAbName,
      hrefForLookerAbButton: [cleanedUrl(rawUrl), params].join('?'),
      ...configuration,
    }
  );
}

export default function LookerAbButton({ id, type = 'default', ...rest }) {
  const configuration = getButtonConfiguration(id, type);

  if (configuration?.noLookerAbButton) { return ''; }

  return (
    <IconButton
      icon={configuration.icon}
      variant="info"
      outline
      href={configuration.hrefForLookerAbButton}
      target="_blank"
      {...rest}
    >
      {configuration.text}
    </IconButton>
  );
}

export function LookerAbDropdownItem({ id, type }) {
  const configuration = getButtonConfiguration(id, type);

  if (configuration?.noLookerAbButton) { return ''; }

  return (
    <DropdownItem
      href={configuration.hrefForLookerAbButton}
      icon={configuration.icon}
      target="_blank"
      variant="info"
      className="mb-1 d-flex align-items-center"
    >
      {configuration.text}
    </DropdownItem>
  );
}
