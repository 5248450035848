import React from 'react';

import APP_DATA from '@services/appData';
import { WoodokuRoutes, FigureSetsRoutes } from '@pages/routes';
import {
  Form,
  useCurrentApplication,
  useQuery,
  useI18n,
  useConfirm,
} from '@hooks';
import {
  Label,
  Field,
  SelectField,
  FormButtonsGroup,
  StaticFieldsFormGroup,
  LabelsFormGroup,
} from '@controls/form';
import LevelBanks from '@pages/journeys/level-banks/EventsBlock';
import { DuplicateButton, PageHeader } from '@pages/common';
import IconButton from '@controls/buttons';
import { fromClassNameToOptions } from '@services/enums';
import buildOnSubmit from '@services/journeys/banks/buildOnSubmit';

import formReducer from './formReducer';

const { enums, modelsLocales: { woodoku: { journeys: { tooltips } } } } = APP_DATA;
const statusesEnum = enums['Woodoku::HardJourneyStatuses'];

const HardJourneysForm = ({
  data, title, onSubmit,
}) => {
  const confirm = useConfirm();
  const { translate } = useI18n();
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const entityName = translate.fallback('woodoku.hardJourney.name');
  const { status } = data;
  const isArchived = status === statusesEnum.ARCHIVED;
  const isUpdate = Boolean(data.id);
  const statuses = fromClassNameToOptions('Woodoku::HardJourneyStatuses');
  const { response: figureSets, isLoading: figureSetsLoading } = useQuery({
    request: FigureSetsRoutes.Sets.indexRequest,
    queryKey: ['figureSets', applicationId],
    toSelectOptions: true,
  });

  const onFormSubmit = buildOnSubmit({
    applicationId,
    data,
    entityName,
    statusesEnum,
    onSubmitFunction: onSubmit,
    confirm,
    routes: WoodokuRoutes.HardJourneys,
  });

  return (
    <Form
      initialValues={data}
      onSubmit={onFormSubmit}
      reducer={formReducer}
      sharedInputProps={{ disabled: isArchived }}
    >
      {({ values, dispatch }) => {
        const onBankAdd = (id) => dispatch({ actionType: 'addLevelBankToJourney', id });
        const onBankRemove = (id) => dispatch({ actionType: 'removeLevelBankFromJourney', id });

        return (
          <>
            <PageHeader title={title}>
              <FormButtonsGroup
                cancelButtonPath={WoodokuRoutes.HardJourneys.indexPath({ applicationId })}
              >
                {isUpdate && (
                  <>
                    <IconButton.ActivityLog
                      applicationId={applicationId}
                      id={data.id}
                      entityType="Woodoku::HardJourney"
                    />
                    <FormButtonsGroup.Divider />
                  </>
                )}
                <DuplicateButton
                  routes={WoodokuRoutes.HardJourneys}
                  data={data}
                  entityName={entityName}
                />
              </FormButtonsGroup>
            </PageHeader>
            {isUpdate && (
              <>
                <div className="mt-2">
                  <Label text="ID">
                    <Field name="id" disabled />
                  </Label>
                </div>
                <Label text="Client ID">
                  <Field name="clientId" disabled />
                </Label>
              </>
            )}
            <Label text="Name">
              <Field type="text" name="name" disabled={false} />
            </Label>

            <Label required text="Status">
              <SelectField name="status" options={statuses} />
            </Label>

            <LabelsFormGroup disabled={false} />
            <StaticFieldsFormGroup data={data} />

            <Label text="Figure Set">
              <SelectField
                name="figureSetId"
                options={figureSets}
                isLoading={figureSetsLoading}
              />
            </Label>

            <Label
              text="Ads off for Journey level X and Below"
              tooltipText={tooltips.config.adsOffJourneyLevelX}
            >
              <Field type="number" name="adsOffJourneyLevelX" />
            </Label>

            <LevelBanks
              disabled={isArchived}
              validateLevels
              variantAttributes={values}
              onBankAdd={onBankAdd}
              onBankRemove={onBankRemove}
              translationPath="woodoku.hardJourney.name"
            />
          </>
        );
      }}
    </Form>
  );
};

export default HardJourneysForm;
