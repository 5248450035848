import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import {
  FormGroup,
  Form,
  useCurrentApplication,
} from '@hooks';

import { RewardsRoutes, AppUserRoutes } from '@pages/routes';
import { SelectField, Field, Label } from '@controls/form';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { PageSection } from '@pages/common';
import { gachaRewardItemTypes } from '@pages/my-pet-cafe/constants';

import IconButton from '@controls/buttons';

const APP_SPECIFIC_FILTERS = {
  tile: { filter: { rewardTypeNotEq: 'Rewards::Types::Tile::Chest' } },
  my_pet_cafe: { filter: { rewardTypeNotIn: gachaRewardItemTypes } },
};

export default function GameItemBasedReward() {
  const [rewardItems, setRewardItems] = useState([]);
  const [data, setData] = useState({ rewardAttributes: {} });
  const { query: { id: appUserId, applicationId } } = useRouter();
  const { currentApplication: { typeName: applicationType } } = useCurrentApplication();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'User Rewards',
    actionName: 'create',
    setData,
  });

  useEffect(() => {
    requestWithToSelectOptions(
      RewardsRoutes.Items.indexRequest,
      applicationId,
      APP_SPECIFIC_FILTERS[applicationType],
    ).then(setRewardItems);
  }, []);

  const onSubmit = (values) => AppUserRoutes.UserRewards
    .createRequest({ ...values, appUserId, applicationId })
    .then(responseHandler);

  return (
    <PageSection title="Manual Reward">
      <Form
        initialValues={data}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <FormGroup name="rewardAttributes">
            <Row>
              <Col className="pe-0">
                <Label text="Game Item" direction="vertical">
                  <SelectField name="rewardItemId" options={rewardItems} className="me-1" />
                </Label>
              </Col>
              <Col>
                <Label text="Quantity" direction="vertical">
                  <Field name="quantity" className="me-1" />
                </Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <IconButton.Save type="submit" disabled={isSubmitting}>Submit</IconButton.Save>
              </Col>
            </Row>
          </FormGroup>
        )}
      </Form>
    </PageSection>
  );
}
