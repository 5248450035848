import React from 'react';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { useRouter } from '@tripledotstudios/react-core';

import { useConfirm, useFormContext } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { deleteLevelLayout } from '@requests/my-pet-cafe/level-layouts';

import IconButton from '@controls/buttons';

export default function LayoutRow({
  levelLayoutId,
  currentLayoutDataHash,
  values,
  updatedAt,
  deletionUnlocked,
  refetch,
}) {
  const { setFieldValue } = useFormContext();
  const confirm = useConfirm();
  const isLayoutDataChanged = values[levelLayoutId] !== currentLayoutDataHash;
  const { query: { applicationId } } = useRouter();

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Layout level',
    actionName: 'update',
    setData: () => null,
  });

  const handleRevertClick = (event) => {
    event.stopPropagation();

    setFieldValue(levelLayoutId, currentLayoutDataHash);
  };

  const onRemove = (event) => {
    event.stopPropagation();

    confirm
      .showConfirmation({ title: 'Confirm to remove selected level ID', headerColor: 'lightpink' })
      .then(() => deleteLevelLayout({ applicationId, id: levelLayoutId })
        .then(responseHandler)
        .then(refetch));
  };
  return (
    <Row className={classNames('align-items-center text-center w-100', isLayoutDataChanged && 'text-warning')}>
      <Col xs={2}>
        {levelLayoutId}
      </Col>
      <Col xs={4}>
        {currentLayoutDataHash}
      </Col>
      <Col xs={2}>
        {updatedAt}
      </Col>
      <Col xs={2}>
        {isLayoutDataChanged ? <IconButton.Restore onClick={handleRevertClick}>Revert</IconButton.Restore> : ''}
      </Col>
      <Col xs={2}>
        <IconButton.Delete onClick={onRemove} disabled={!deletionUnlocked}>
          Delete Level
        </IconButton.Delete>
      </Col>
    </Row>
  );
}
