import React, { useMemo } from 'react';

import { TextCell } from './cells';

export default function Translations({ translations, filteredTranslations, pushChangedRow }) {
  const filteredTranslationUuids = filteredTranslations.map(({ _uuid }) => _uuid);

  return (
    useMemo(() => (
      translations.map(({ value, _uuid }, translationIndex) => (
        (filteredTranslationUuids.length === 0 || filteredTranslationUuids.includes(_uuid)) && (
          <TextCell
            key={_uuid}
            name={`translationsAttributes.${translationIndex}.value`}
            defaultValue={value}
            pushChangedRow={pushChangedRow}
            multiline
          />
        )
      ))
    ),
    [translations, pushChangedRow])
  );
}
