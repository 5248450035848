import {
  get, post, fetchCollection, newResource, editResource, createResourceMultipart, updateResourceMultipart,
  deleteResource,
} from '@requests/common';

const baseUrl = ({ applicationId }) => `/api/admin/applications/${applicationId}/my_pet_cafe/manifest_sets`;

export function getManifestSets(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function baseManifestSet(values) {
  return get(`${baseUrl(values)}/base`);
}

export function newManifestSet(options) {
  return newResource(baseUrl(options));
}

export function editManifestSet(data, options) {
  return editResource(baseUrl(data), data, options);
}

export function createManifestSet(values) {
  return createResourceMultipart(baseUrl(values), values);
}

export function updateManifestSet(values) {
  return updateResourceMultipart(baseUrl(values), values);
}

export function duplicateManifestSet(data) {
  const { meta, ...duplicateData } = data;
  const { id } = duplicateData;

  return post(`${baseUrl(data)}/${id}/duplicate`, { ...duplicateData });
}

export function deleteManifestSet(values) {
  return deleteResource(baseUrl(values), values);
}
