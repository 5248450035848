import React, { useState, useCallback, useEffect } from 'react';
import { get, at } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { Form, useCurrentApplication } from '@hooks';
import {
  Label,
  Field,
  FormButtonsGroup,
  SelectField,
  AssetsKeySelectField,
  StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import { MergeRoutes as Routes } from '@pages/routes';
import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { combineReducers } from '@reducers';
import rewardsReducer from '@components/merge/reducers/rewardsReducer';
import targetsReducer from '@components/merge/reducers/targetsReducer';

import formReducer from './formReducer';
import RandomOrders from './form/RandomOrders';

export default function ExpertConfigurationsForm({ data, onSubmit, actionName }) {
  const [schemaExperts, setSchemaExperts] = useState([]);
  const [items, setItems] = useState([]);
  const [postcards, setPostcards] = useState([]);
  const { applicationName } = useCurrentApplication();
  const [assetKeysStatuses, assetTypes] = at(
    APP_DATA.enums,
    ['AssetsKeys::Statuses', `${applicationName}::AssetTypes`],
  );

  const { query } = useRouter();
  const { applicationId } = query;
  const { schemaId } = data;

  const errors = get(data, '_meta.errors', {});

  const populateDependencies = (targetSchemaId) => {
    Routes.ExpertSets.buildExpertSetExpertsRequest({ applicationId, schemaId: targetSchemaId })
      .then(({ data: { expertSetExpertsAttributes } }) => {
        const options = expertSetExpertsAttributes.map(({ internalId, schemaExpertId }) => ({
          value: schemaExpertId,
          label: internalId,
        }));

        setSchemaExperts(options);
      });
    Routes.WorldSchemas.editRequest({ id: targetSchemaId, applicationId }).then((response) => {
      setItems(response.data.items.map((item) => ({ value: item.id, label: item.internalId, line: item.line })));
      setPostcards(response.data.postcards.map((item) => ({ value: item.id, label: item.internalId })));
    });
  };

  useEffect(() => {
    if (schemaId) {
      populateDependencies(schemaId);
    }
  }, [schemaId]);

  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(applicationId), []);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([formReducer, rewardsReducer, targetsReducer])}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Expert Configuration`}>
            <FormButtonsGroup cancelButtonPath={Routes.ExpertConfigurations.indexPath({ applicationId })} />
          </PageHeader>

          <Label text="Name">
            <Field type="text" name="name" />
          </Label>
          <Label text="Description">
            <Field type="text" name="description" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <Label text="Game Schema">
            <SelectField
              name="schemaId"
              options={fetchSchemaOptions}
              isDisabled={actionName !== 'Create'}
              onChange={({ value }) => populateDependencies(value)}
            />
          </Label>
          {schemaExperts.length > 0 && (
            <Label text="Expert">
              <SelectField
                name="schemaExpertId"
                options={schemaExperts}
                isDisabled={actionName !== 'Create'}
              />
            </Label>
          )}
          <Label text="Asset: Character Bundle">
            <AssetsKeySelectField
              name="characterAssetKeyId"
              status={assetKeysStatuses.ACTIVE}
              types={assetTypes.EXPERT}
              includeEmpty
            />
          </Label>
          {postcards.length > 0 && items.length > 0 && (
            <RandomOrders
              values={values.randomOrdersAttributes}
              postcards={postcards}
              items={items}
              errors={errors.randomOrdersAttributes}
            />
          )}
        </>
      )}
    </Form>
  );
}
