import APP_DATA from '@services/appData';

import ClassicLevelConfig from './ClassicLevelConfig';
import ExpressLevelConfig from './ExpressLevelConfig';
import TrialLevelConfig from './Trial';
import ChallengeLevelConfig from './ChallengeLevelConfig';
import RiddleLevelConfig from './RiddleLevelConfig';
import StockpileCycleLevelConfig from './StockpileCycleLevelConfig';

const gameModes = APP_DATA.enums['Solitaire::Journeys::GameModes'];

export default {
  [gameModes.CLASSIC]: ClassicLevelConfig,
  [gameModes.EXPRESS]: ExpressLevelConfig,
  [gameModes.TRIAL]: TrialLevelConfig,
  [gameModes.CHALLENGE]: ChallengeLevelConfig,
  [gameModes.RIDDLE]: RiddleLevelConfig,
  [gameModes.STOCKPILE_CYCLE]: StockpileCycleLevelConfig,
};
