import React from 'react';

import {
  ApplicationItemSet,
  AssetsNavigationItem,
  AdsNavigationSection,
  RewardsNavigationItem,
} from './common';

export default function Axum({ applicationId, routingScope, minimized = false }) {
  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="axum"
      routingScope={routingScope}
      minimized={minimized}
    >
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />

      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
