import React from 'react';
import { at } from 'lodash';
import { PageTitle } from '@pages/common';
import APP_DATA from '@services/appData';

import {
  AppConfigIndex,
  AppConfigBase,
  AppConfigNew,
  AppConfigEdit,

  LevelManifestSetsIndex,
  LevelManifestSetsBase,
  LevelManifestSetsNew,
  LevelManifestSetsEdit,

  LevelManifestSetTypesIndex,
  LevelManifestSetTypesNew,
  LevelManifestSetTypesEdit,

  LevelLayoutHome,
  LevelLayoutIndex,

  BetUpConfigurationsIndex,
  BetUpConfigurationsNew,
  BetUpConfigurationsEdit,

  JourneysExperimentScoped,

  AdventuresEventBanksIndex,
  AdventuresEventBanksNew,
  AdventuresEventBanksEdit,

  AdventuresEntitiesIndex,
  AdventuresEntitiesNew,
  AdventuresEntitiesEdit,
  AdventuresEntitiesExperimentScoped,

  UnlockRequirementsIndex,
  UnlockRequirementsNew,
  UnlockRequirementsEdit,

  LeaderboardsIndex,
  LeaderboardsNew,
  LeaderboardsEdit,

  SoloMissionsEventBanksIndex,
  SoloMissionsEventBanksNew,
  SoloMissionsEventBanksEdit,

  SoloMissionsConfigsIndex,
  SoloMissionsConfigsNew,
  SoloMissionsConfigsEdit,
  SoloMissionsConfigsExperimentScoped,

  WinStreaksEventBanksIndex,
  WinStreaksEventBanksNew,
  WinStreaksEventBanksEdit,

  WinStreaksConfigsIndex,
  WinStreaksConfigsNew,
  WinStreaksConfigsEdit,
  WinStreaksConfigsExperimentScoped,
} from '@pages/my-pet-cafe';

import { MyPetCafeRoutes } from '@pages/routes';

import buildJsonConfigsRoutes from '@pages/routes/my-pet-cafe/jsonConfigs';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

const jsonConfigTypes = APP_DATA.enums['MyPetCafe::JsonConfigTypes'];

export default function myPetCafe() {
  return [
    <Route
      key={MyPetCafeRoutes.AppConfigs.indexRawPath}
      path={MyPetCafeRoutes.AppConfigs.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Remote DB's" />
          <AppConfigIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.AppConfigs.baseRawPath}
      path={MyPetCafeRoutes.AppConfigs.baseRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Base Remote DB" />
          <AppConfigBase />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.AppConfigs.newRawPath}
      path={MyPetCafeRoutes.AppConfigs.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Remote DB" action="New" />
          <AppConfigNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.AppConfigs.editRawPath}
      path={MyPetCafeRoutes.AppConfigs.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Remote DB" action="Edit" />
          <AppConfigEdit />
        </RouteContainer>
      )}
    />,

    <Route
      key={MyPetCafeRoutes.LevelManifestSets.indexRawPath}
      path={MyPetCafeRoutes.LevelManifestSets.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Level Manifest Sets" />
          <LevelManifestSetsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.LevelManifestSets.baseRawPath}
      path={MyPetCafeRoutes.LevelManifestSets.baseRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Base Level Manifest Set" />
          <LevelManifestSetsBase />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.LevelManifestSets.newRawPath}
      path={MyPetCafeRoutes.LevelManifestSets.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Level Manifest Set" action="New" />
          <LevelManifestSetsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.LevelManifestSets.editRawPath}
      path={MyPetCafeRoutes.LevelManifestSets.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Level Manifest Set" action="Edit" />
          <LevelManifestSetsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.LevelManifestSetTypes.indexRawPath}
      path={MyPetCafeRoutes.LevelManifestSetTypes.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Level Manifest Sets Types" />
          <LevelManifestSetTypesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.LevelManifestSetTypes.newRawPath}
      path={MyPetCafeRoutes.LevelManifestSetTypes.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Manifest Set" action="New" />
          <LevelManifestSetTypesNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.LevelManifestSetTypes.editRawPath}
      path={MyPetCafeRoutes.LevelManifestSetTypes.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Level Manifest Set" action="Edit" />
          <LevelManifestSetTypesEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.LevelLayouts.homeRawPath}
      path={MyPetCafeRoutes.LevelLayouts.homeRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Level Layout Home" />
          <LevelLayoutHome />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.LevelLayouts.indexRawPath}
      path={MyPetCafeRoutes.LevelLayouts.indexRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Level Layouts Index" />
          <LevelLayoutIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.BetUpConfigurations.indexRawPath}
      path={MyPetCafeRoutes.BetUpConfigurations.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Bet Up Configurations Index" />
          <BetUpConfigurationsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.BetUpConfigurations.newRawPath}
      path={MyPetCafeRoutes.BetUpConfigurations.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="New Bet Up Configuration" />
          <BetUpConfigurationsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.BetUpConfigurations.editRawPath}
      path={MyPetCafeRoutes.BetUpConfigurations.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Bet Up Configuration" />
          <BetUpConfigurationsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.Journeys.EventConfigs.experimentScopedRawPath}
      path={MyPetCafeRoutes.Journeys.EventConfigs.experimentScopedRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Edit Season variant" />
          <JourneysExperimentScoped />
        </RouteContainer>
      )}
    />,
    ...buildJsonConfigsRoutes({ configTypes: [jsonConfigTypes.ANTI_CHURN_REWARDS], group: 'game_settings' }),
    ...buildJsonConfigsRoutes({
      configTypes: at(
        jsonConfigTypes,
        [
          'REMOTE_DB_CAFE_INCOME', 'REMOTE_DB_STAR_CHEST_REWARD', 'REMOTE_DB_STAR_CHEST_ORDER',
          'REMOTE_DB_BOOSTER', 'REMOTE_DB_BOOSTERS_UNLOCK', 'REMOTE_DB_SOLITAIRE_TUTORIAL', 'REMOTE_DB_CAFE_TUTORIAL',
          'REMOTE_DB_SOLITAIRE_SCORE', 'REMOTE_DB_CHAPTER_REQUIREMENTS', 'REMOTE_DB_REDEEMABLE_OFFER',
          'REMOTE_DB_REDEEMABLE_REWARD', 'REMOTE_DB_REDEEMABLE_REWARD_INVENTORY_GROUP', 'DECO_PROGRESS', 'WORLD_MAP',
          'REMOTE_DB_PET_DATA', 'REMOTE_DB_PET_SLOT', 'REMOTE_DB_PET_SPACE',
          'REMOTE_DB_PET_ANIMATION', 'REMOTE_DB_PET_SORTING_ORDER', 'REMOTE_DB_PET_MENU_SECTION',
          'REMOTE_DB_LEADERBOARD_REWARD_ICON',
        ],
      ),
      group: 'remote_db',
    }),
    ...buildJsonConfigsRoutes({
      configTypes: [jsonConfigTypes.CHAPTER_CONFIG_CHAPTER_REWARDS], group: 'chapter_configs',
    }),
    <Route
      key={MyPetCafeRoutes.Adventures.EventBanks.indexRawPath}
      path={MyPetCafeRoutes.Adventures.EventBanks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle translationPath="adventures.banks.pluralName" />
          <AdventuresEventBanksIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.Adventures.EventBanks.newRawPath}
      path={MyPetCafeRoutes.Adventures.EventBanks.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="New" translationPath="adventures.banks.name" />
          <AdventuresEventBanksNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.Adventures.EventBanks.editRawPath}
      path={MyPetCafeRoutes.Adventures.EventBanks.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="Edit" translationPath="adventures.banks.name" />
          <AdventuresEventBanksEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.Adventures.Entities.indexRawPath}
      path={MyPetCafeRoutes.Adventures.Entities.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle translationPath="adventures.entities.pluralName" />
          <AdventuresEntitiesIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.Adventures.Entities.newRawPath}
      path={MyPetCafeRoutes.Adventures.Entities.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="New" translationPath="adventures.entities.name" />
          <AdventuresEntitiesNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.Adventures.Entities.editRawPath}
      path={MyPetCafeRoutes.Adventures.Entities.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle action="Edit" translationPath="adventures.entities.name" />
          <AdventuresEntitiesEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.Adventures.Entities.experimentScopedRawPath}
      path={MyPetCafeRoutes.Adventures.Entities.experimentScopedRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Adventure Variant" />
          <AdventuresEntitiesExperimentScoped />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.UnlockRequirements.indexRawPath}
      path={MyPetCafeRoutes.UnlockRequirements.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Unlock Requirements" />
          <UnlockRequirementsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.UnlockRequirements.newRawPath}
      path={MyPetCafeRoutes.UnlockRequirements.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Unlock Requirement" action="New" />
          <UnlockRequirementsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.UnlockRequirements.editRawPath}
      path={MyPetCafeRoutes.UnlockRequirements.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Unlock Requirement" action="Edit" />
          <UnlockRequirementsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.Leaderboards.Configs.indexRawPath}
      path={MyPetCafeRoutes.Leaderboards.Configs.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Leaderboards" />
          <LeaderboardsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.Leaderboards.Configs.newRawPath}
      path={MyPetCafeRoutes.Leaderboards.Configs.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Leaderboard" action="New" />
          <LeaderboardsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.Leaderboards.Configs.editRawPath}
      path={MyPetCafeRoutes.Leaderboards.Configs.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Leaderboard" action="Edit" />
          <LeaderboardsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.SoloMissions.EventBanks.indexRawPath}
      path={MyPetCafeRoutes.SoloMissions.EventBanks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Solo Missions Event Banks" />
          <SoloMissionsEventBanksIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.SoloMissions.EventBanks.newRawPath}
      path={MyPetCafeRoutes.SoloMissions.EventBanks.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Solo Mission Event Bank" action="New" />
          <SoloMissionsEventBanksNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.SoloMissions.EventBanks.editRawPath}
      path={MyPetCafeRoutes.SoloMissions.EventBanks.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Solo Mission Event Bank" action="Edit" />
          <SoloMissionsEventBanksEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.SoloMissions.Configs.indexRawPath}
      path={MyPetCafeRoutes.SoloMissions.Configs.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Solo Missions Events" />
          <SoloMissionsConfigsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.SoloMissions.Configs.newRawPath}
      path={MyPetCafeRoutes.SoloMissions.Configs.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Solo Mission Event Bank" action="New" />
          <SoloMissionsConfigsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.SoloMissions.Configs.editRawPath}
      path={MyPetCafeRoutes.SoloMissions.Configs.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Solo Mission Event Bank" action="Edit" />
          <SoloMissionsConfigsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.SoloMissions.Configs.experimentScopedRawPath}
      path={MyPetCafeRoutes.SoloMissions.Configs.experimentScopedRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Solo Mission Config Variant" />
          <SoloMissionsConfigsExperimentScoped />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.WinStreaks.EventBanks.indexRawPath}
      path={MyPetCafeRoutes.WinStreaks.EventBanks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Win Streaks Event Banks" />
          <WinStreaksEventBanksIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.WinStreaks.EventBanks.newRawPath}
      path={MyPetCafeRoutes.WinStreaks.EventBanks.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Win Streak Event Bank" action="New" />
          <WinStreaksEventBanksNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.WinStreaks.EventBanks.editRawPath}
      path={MyPetCafeRoutes.WinStreaks.EventBanks.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Win Streak Event Bank" action="Edit" />
          <WinStreaksEventBanksEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.WinStreaks.Configs.indexRawPath}
      path={MyPetCafeRoutes.WinStreaks.Configs.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Win Streaks Events" />
          <WinStreaksConfigsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.WinStreaks.Configs.newRawPath}
      path={MyPetCafeRoutes.WinStreaks.Configs.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Win Streak Event Bank" action="New" />
          <WinStreaksConfigsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.WinStreaks.Configs.editRawPath}
      path={MyPetCafeRoutes.WinStreaks.Configs.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Win Streak Event Bank" action="Edit" />
          <WinStreaksConfigsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={MyPetCafeRoutes.WinStreaks.Configs.experimentScopedRawPath}
      path={MyPetCafeRoutes.WinStreaks.Configs.experimentScopedRawPath}
      element={(
        <RouteContainer>
          <PageTitle text="Win Streak Config Variant" />
          <WinStreaksConfigsExperimentScoped />
        </RouteContainer>
      )}
    />,
  ];
}
