import React from 'react';
import styled from 'styled-components';

const RangeCell = styled.div`
  white-space: pre-wrap;
  width: 135px;
`;

const ActivityRangeColumn = {
  Header: 'Active at',
  id: 'activeAt',
  disableSortBy: true,
  style: { width: '20%' },
  Cell: ({ row: { original } }) => {
    const { activeFrom, activeTo } = original;
    if (!activeFrom && !activeTo) return null;

    return (
      <RangeCell>
        <strong>From:</strong>
        {` ${activeFrom || '-'}`}
        <p />
        <strong>To:</strong>
        {` ${activeTo || '-'}`}
      </RangeCell>
    );
  },
};

export default ActivityRangeColumn;
