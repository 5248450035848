import React from 'react';

import APP_DATA from '@services/appData';

import { Field, DateTimeField } from '@controls/form';

const { enums } = APP_DATA;
const actionTypesEnum = enums['MyPetCafe::FlexiblePopups::ActionTypes'];

export default function FormFields({ actionType, LabelComponent }) {
  if (actionType === actionTypesEnum.SURVEY) {
    return (
      <>
        <LabelComponent name="url">
          <Field name="url" />
        </LabelComponent>
        <LabelComponent name="startDate">
          <DateTimeField as="date" name="startDate" />
        </LabelComponent>
        <LabelComponent name="endDate">
          <DateTimeField as="date" name="endDate" />
        </LabelComponent>
        <LabelComponent name="expiredHours">
          <Field name="expiredHours" type="number" appendText="Hours" />
        </LabelComponent>
        <LabelComponent name="surveyId">
          <Field name="surveyId" />
        </LabelComponent>
      </>
    );
  }

  if (actionType === actionTypesEnum.OPEN_URL) {
    return (
      <LabelComponent name="url">
        <Field name="url" />
      </LabelComponent>
    );
  }

  return '';
}
