import React from 'react';
import { Button } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';

import { Level as DndType } from '@types/dragNDrop';
import { FormGroup, useFormContext } from '@hooks';
import { Field } from '@controls/form';

export default function Level({
  defaultVariant = false, _destroy = false, index, number, uuid, variantIndex,
}) {
  const { dispatch, meta } = useFormContext();

  const drop = useDrop({
    accept: DndType,
    drop: (item) => {
      const oldIndex = item.index;
      const newIndex = index;

      dispatch({
        actionType: 'move_level',
        metaErrorsPath: ['errors', 'currentVariantAttributes', 'levelsAttributes'],
        oldIndex,
        newIndex,
        defaultVariant,
        meta,
        variantIndex,
      });
    },
  })[1];

  const drag = useDrag({
    type: DndType,
    item: { index },
  })[1];

  const onDelete = (event) => {
    event.preventDefault();

    dispatch({
      actionType: 'remove_level', defaultVariant, deleteUuid: uuid, variantIndex,
    });
  };

  return (
    !_destroy && (
      <tr ref={(node) => drag(drop(node))}>
        <FormGroup name={`levelsAttributes[${index}]`}>
          <td className="text-center align-middle text-secondary w-25">
            {number}
          </td>
          <td className="w-25">
            <Field type="number" name="xp" />
          </td>
          <td className="w-25">
            <Field type="number" name="reward" />
          </td>
          <td className="w-25">
            <Field type="number" name="targetBalance" />
          </td>
          <td className="w-50">
            <Button
              variant="danger"
              onClick={(e) => onDelete(e)}
              size="sm"
              className="mt-0 mb-0 pull-end"
            >
              Delete
            </Button>
          </td>
        </FormGroup>
      </tr>
    )
  );
}

export const defaultLevel = {
  id: null,
  number: 0,
  xp: 0,
  reward: 0,
  target_balance: 50000,
  _destroy: false,
};
