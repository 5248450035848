import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import {
  PageHeader,
  Pagination,
  ReactTable,
  DuplicationModal,
  TIMESTAMP_COLUMNS,
  InUseColumn,
} from '@pages/common';
import { Match3dRoutes as Routes } from '@pages/routes';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { isInUse } from '@pages/common/InUse';
import { useQuery, useConfirm } from '@hooks';
import IconButton from '@controls/buttons';

const PaddedPagination = styled(Pagination)`
  margin-top: 15px;
`;

export const levelSetsColumns = ({ onDelete, onDuplicate }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row }) => {
      const { id, inUse, name } = row.original;
      const isUsed = isInUse(inUse);

      return (
        <ButtonToolbar>
          <IconButton.Edit to={`${id}/edit`} minimized />
          <IconButton.Duplicate onClick={() => onDuplicate({ id, name })} minimized />
          <IconButton.Delete
            onClick={onDelete(id, name)}
            disabled={isUsed}
            title={isUsed ? 'Disabled as the entity is used' : null}
            minimized
          />
        </ButtonToolbar>
      );
    },
  },
];

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(Routes.PersonalLevelSets.indexRequest, { includeInUse: true });
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Star level set',
    actionName: 'removal',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Star level set '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => Routes.PersonalLevelSets.deleteRequest({ applicationId, id })
        .then(responseHandler));
  };

  const onDuplicate = (values) => (
    Routes.PersonalLevelSets.duplicateRequest({ ...values, applicationId })
  );

  const columns = React.useMemo(() => levelSetsColumns({
    onDelete,
    onDuplicate: setDuplicatedRecord,
  }), []);

  return response && (
    <div>
      <PageHeader title="Star Level Sets">
        <IconButton.New to="new" />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Star level set"
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={onDuplicate}
        title="Duplicate Star level set"
      />
      <PaddedPagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
