import { pull } from 'lodash';

export default function Reducer(state, action) {
  const { type, fieldName } = action;

  if (type === 'disableField') {
    pull(state.enabledFieldsNames, fieldName);
    return state;
  }

  if (type === 'enableField') {
    state.enabledFieldsNames.push(fieldName);
    return state;
  }

  return state;
}
