import React from 'react';
import { useFlashMessages, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { useQuery } from '@hooks';

import { MyPetCafeRoutes as Routes } from '@pages/routes';

import Form from './Form';

const statusesEnum = APP_DATA.enums['MyPetCafe::BetUpConfigurationStatuses'];
const isArchived = (status) => status === statusesEnum.ARCHIVED;

export default function Edit() {
  const { query: { id, applicationId } } = useRouter();
  const { error } = useFlashMessages();
  const { response, setResponse } = useQuery(Routes.BetUpConfigurations.editRequest, { id, applicationId });

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Bet Up Configuration',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => {
    if (isArchived(values.status)) {
      return new Promise((resolve) => {
        error('Bet Up configuration is archived');
        resolve();
      });
    }

    return Routes.BetUpConfigurations.updateRequest({ ...values, applicationId, id }).then(responseHandler);
  };

  return response && <Form record={response} onSubmit={onSubmit} />;
}
