import React from 'react';

import {
  GameSettingsTabs,
  localizationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  analyticsDefaultFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
} from '@components/game-settings/Resource';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'chapterSetId',
      'personalLevelSetId',
      ...assetsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#physics',
    title: 'Physics',
    fieldsNames: [
      'dragLerp',
      'dragHeight',
      'inertiaForce',
      'outOfLidForce',
      'pullDuration',
      'rejectionPower',
      'gravityForce',
    ],
  },
  {
    eventKey: '#gameplay',
    title: 'Gameplay',
    fieldsNames: [
      'hintPowerUpLevelUnlocked',
      'hintPowerUpStartingBalance',
      'hintPowerUpPriceForCoins',
      'freezePowerUpLevelUnlocked',
      'freezePowerUpStartingBalance',
      'freezePowerUpPriceForCoins',
      'maxLives',
      'livesRefreshRateMinutes',
      'priceForFiveLives',
      'gameOverExtraTimePrice',
      'podiumPrefill',
      'interLevelFlow',
      'shopshelfUnlock',
    ],
  },
  {
    eventKey: '#onboarding',
    title: 'Onboarding',
    fieldsNames: [
      'onboardingSkipMap',
    ],
  },
  {
    eventKey: '#shop',
    title: 'Shop',
    fieldsNames: [
      'freeCoins',
      'freeCoinsRefreshRateMinutes',
    ],
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: popupsDefaultFieldNames,
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      ...adPlacementIdsDefaultFieldNames,
      ...adConfigsDefaultFieldNames,
      'rewardedAdFeaturesFromLevel',
    ],
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#feature_toggles',
    title: 'Feature toggles',
    fieldsNames: [
      'accelerometer',
      'tapFunction',
      'shopshelfAvailable',
    ],
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: analyticsDefaultFieldNames,
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: popupsDefaultArchivedFieldNames,
  },
];

export default function Tabs({
  setContractType, fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      onChange={setContractType}
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
