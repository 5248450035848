import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { get, isEmpty } from 'lodash';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { FormGroup, useFormContext, useFormGroup } from '@hooks';
import { DndIcon, PageSection } from '@pages/common';
import useExpanded from '@components/merge/hooks/useExpanded';
import Th from '@components/merge/shared/Th';
import { ExpandIcon } from '@components/merge/shared/icons';
import InternalIdLabel from '@components/merge/shared/InternalIdLabel';
import { pickRewardsConfigurations } from '@components/merge/services/RewardsConfigurations';
import RewardsSummary from '@components/merge/shared/rewards/Summary';
import IconButton from '@controls/buttons';

import RandomOrder from './RandomOrder';

const Td = styled.td`
  ${({ withErrors }) => withErrors && 'color: #FF0000'}
`;

const DndIconStyled = styled(DndIcon)`
  left: 0;
  top: 0;
`;

const rewardsConfigurations = pickRewardsConfigurations([
  'Item', 'Xp', 'Coins', 'Gems', 'Energy', 'EnergyRefresh', 'GiftBag', 'RefreshAllProducers',
]);

const RandomOrderSummary = ({ value, items, rewardsConfigurations: rewards }) => {
  const presentItem = (item) => {
    const itemName = items.find((i) => i.value === item.schemaItemId).label;
    return `${itemName} (${item.quantity})`;
  };

  return (
    <>
      <div className="mb-1">
        <b>Items: </b>
        {value.targetsAttributes
          .filter((item) => (!item._destroy && item.schemaItemId && item.quantity))
          .map((item) => presentItem(item))
          .join(', ')}
      </div>
      <div className="mb-1">
        <b>Rewards: </b>
        <RewardsSummary
          rewardAttributes={value.rewardsAttributes}
          itemOptions={items}
          rewardConfigurations={rewards}
        />
      </div>
      <div className="mb-1">
        <b>Repeatable: </b>
        {value.repeatable ? 'yes' : 'no'}
      </div>
    </>
  );
};

const RandomOrderPostcardsInfo = ({ value, postcards }) => {
  const fetchPostcardName = (postcardId) => (
    postcardId && postcards.find((postcard) => postcard.value === postcardId).label
  );

  return (
    <>
      <div>
        <b>From: </b>
        {fetchPostcardName(value.fromSchemaPostcardId)}
      </div>
      <div>
        <b>To: </b>
        {fetchPostcardName(value.toSchemaPostcardId)}
      </div>
    </>
  );
};

const AddRandomOrderButton = ({ onOrderAdd }) => (
  <Button onClick={onOrderAdd} variant="success" className="mb-2">Add random order</Button>
);

export default function RandomOrders({
  values, items, postcards, errors,
}) {
  const { isExpanded, toggleExpanded, expand } = useExpanded();

  const { dispatch } = useFormContext();
  const { generateName } = useFormGroup();
  const path = generateName('randomOrdersAttributes');

  const onOrderAdd = () => {
    const uuid = uuidv4();

    dispatch({ type: 'addRandomOrder', path, uuid }).then(() => expand(uuid));
  };
  const onOrderDuplicate = (index) => dispatch({ type: 'duplicateRandomOrder', path, index });
  const onOrderDelete = (index) => dispatch({ type: 'removeRandomOrder', path, index });
  const onDragEnd = ({ source, destination }) => {
    dispatch({
      path,
      type: 'reorderRandomOrder',
      sourceIndex: source.index,
      destinationIndex: destination.index,
    });
  };

  return (
    <>
      <PageSection title="Random orders" />
      <hr />
      <AddRandomOrderButton onOrderAdd={onOrderAdd} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-expert-orders">
          {(provided) => (
            <Table ref={provided.innerRef} {...provided.droppableProps}>
              <thead>
                <tr>
                  <Th width="50px" />
                  <Th width="50px">Position</Th>
                  <Th width="50px" />
                  <Th width="20%">Internal ID</Th>
                  <Th width="30%">Postcards</Th>
                  <Th width="30%">Summary</Th>
                  <Th />
                </tr>
              </thead>
              <tbody>
                {values && values.length > 0 && values.map((randomOrder, index) => {
                  if (randomOrder._destroy) return null;

                  const expanded = isExpanded(randomOrder._uuid) || get(errors, `[${index}]`);
                  const onClick = () => toggleExpanded(randomOrder._uuid);

                  return (
                    <Draggable key={randomOrder._uuid} draggableId={randomOrder._uuid} index={index}>
                      {(draggableProvided) => (
                        <FormGroup name={`randomOrdersAttributes[${index}]`} key={randomOrder._uuid}>
                          <tr ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                            <Td className="ps-2">
                              <DndIconStyled {...draggableProvided.dragHandleProps} />
                            </Td>
                            <Td withErrors={!isEmpty(get(errors, index))}>
                              {randomOrder.position}
                            </Td>
                            <td>
                              <ExpandIcon value={expanded} onClick={onClick} />
                            </td>
                            <td>
                              <InternalIdLabel
                                onClick={onClick}
                                name={randomOrder.internalId}
                              />
                            </td>
                            <td>
                              <RandomOrderPostcardsInfo value={randomOrder} postcards={postcards} />
                            </td>
                            <td>
                              <RandomOrderSummary
                                value={randomOrder}
                                items={items}
                                rewardsConfigurations={rewardsConfigurations}
                              />
                            </td>
                            <td>
                              <IconButton.Duplicate minimized onClick={() => onOrderDuplicate(index)} />
                              <IconButton.Delete minimized onClick={() => onOrderDelete(index)} />
                            </td>
                          </tr>
                          <tr className={`collapse ${expanded ? 'show' : ''}`}>
                            <td colSpan="1" />
                            <td colSpan="6">
                              <RandomOrder
                                value={randomOrder}
                                items={items}
                                postcards={postcards}
                                rewardsConfigurations={rewardsConfigurations}
                              />
                            </td>
                          </tr>
                        </FormGroup>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
      <AddRandomOrderButton onOrderAdd={onOrderAdd} />
    </>
  );
}
