import React from 'react';
import { useTheme } from 'styled-components';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { AssetsRoutes } from '@pages/routes';

export default function AssetsNavigationItem({ applicationId, minimized }) {
  const theme = useTheme();

  return (
    <Sidebar.LinkItem
      to={AssetsRoutes.Keys.indexPath({ applicationId })}
      icon={faImage}
      title="Asset Bundles"
      color={theme.turquoise}
      minimized={minimized}
    />
  );
}
