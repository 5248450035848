import React from 'react';
import { Label } from '@controls/form';
import Tooltip from '@controls/Tooltip';

const label = 'Usage';

const presentTooltip = (entityTooltipText = 'override') => `System counts number of times ${entityTooltipText} was used`
  + ' starting from 00-00am UTC during 24 hours. Every day the counter is refreshed';

const presentUsageCount = (usageCount) => usageCount.toLocaleString('en');

export const UsageCountLabel = ({ data, entityTooltipText, ...props }) => (
  <Label text={label} tooltipText={presentTooltip(entityTooltipText)} {...props}>
    {presentUsageCount(data.usageCount)}
  </Label>
);

const UsageCountColumn = ({ entityTooltipText } = {}) => ({
  Header: () => (
    <Tooltip text={presentTooltip(entityTooltipText)}>{label}</Tooltip>
  ),
  Cell: ({ row: { original: { usageCount } } }) => presentUsageCount(usageCount),
  accessor: 'usageCount',
  disableSortBy: true,
  style: { width: '10%' },
});

export default UsageCountColumn;
