import React, { memo } from 'react';
import { useCurrentApplication } from '@hooks';
import { AbTestingRoutes } from '@pages/routes';
import { components } from 'react-select';
import { Link } from 'react-router-dom';
import isEqualProps from '@services/isEqualProps';

function LabelWithLink({ children, ...props }) {
  const { currentApplication: { id } } = useCurrentApplication();

  return (
    <components.MultiValueLabel {...props}>
      <Link to={AbTestingRoutes.Experiments.editPath({ applicationId: id, id: props.data.value })} target="_blank">
        {children}
      </Link>
    </components.MultiValueLabel>
  );
}

export default memo(LabelWithLink, isEqualProps(['data.value']));
