export default function formReducer(state, action) {
  if (action.type === 'changeSchemaId') {
    state.itemConfigurationSetId = null;
    state.postcardSetId = null;
    state.expertSetId = null;
    state.levelSetId = null;
  }

  return state;
}
