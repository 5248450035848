import React from 'react';

import SingleLevelRules from './rules-list/SingleLevelRules';
import MultiLevelRules from './rules-list/MultiLevelRules';

export default function RulesList({ rules, nestedForm = false }) {
  const rootSection = rules[0];
  const isSingleLevelSection = rootSection.ruleSectionsAttributes.length === 0;

  return isSingleLevelSection ? (
    <SingleLevelRules
      sectionOperation={rootSection.operation}
      rules={rootSection.rulesAttributes}
      emptyRulesMark="-"
    />
  ) : (
    <MultiLevelRules rules={rules} nestedForm={nestedForm} />
  );
}
