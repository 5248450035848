import { memoize } from 'lodash';

import { fieldBuilder, selectBuilder, fieldArrayBuilder } from '@services/fields-factory';

import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';

const adsBannerPositionOptions = [
  { label: 'Top', value: 'top' },
  { label: 'Bottom', value: 'bottom' },
];

const fieldsFactory = memoize(() => (
  {
    ...fieldBuilder({ name: 'adsInterstitialFrequencyCap', type: 'number' }),
    ...fieldBuilder({ name: 'backToLobbyTimer', type: 'number' }),
    ...fieldBuilder({ name: 'mistakeLimitEnabledByDefault', type: 'checkbox' }),
    ...fieldBuilder({ name: 'mistakeLimitCount', type: 'number' }),
    ...fieldBuilder({ name: 'mistakeLimitCount6', type: 'number' }),
    ...fieldBuilder({ name: 'mistakeLimitCount4', type: 'number' }),
    ...fieldBuilder({ name: 'mistakeLimitEnabledForDc', type: 'checkbox' }),
    ...fieldBuilder({ name: 'freeHints', type: 'checkbox' }),
    ...fieldBuilder({ name: 'startingHintCount', type: 'number' }),
    ...fieldBuilder({ name: 'adsInterstitialRequestTimeout', type: 'number' }),
    ...fieldBuilder({ name: 'adsInterstitialRetryTimeout', type: 'number' }),
    ...fieldBuilder({ name: 'adsRewardedVideoRequestTimeout', type: 'number' }),
    ...fieldBuilder({ name: 'adsRewardedVideoRetryTimeout', type: 'number' }),
    ...fieldBuilder({ name: 'analyticsUseBinaryQueue', type: 'checkbox' }),
    ...fieldBuilder({ name: 'boardPrefillEasy', type: 'number' }),
    ...fieldBuilder({ name: 'boardPrefillMedium', type: 'number' }),
    ...fieldBuilder({ name: 'boardPrefillHard', type: 'number' }),
    ...fieldBuilder({ name: 'boardPrefillExpert', type: 'number' }),
    ...fieldBuilder({ name: 'journeyPopupTranslationKey', type: 'text' }),
    ...fieldBuilder({ name: 'adShowDelayOnPause', type: 'integer' }),
    ...selectBuilder({ name: 'adsBannerPosition', options: adsBannerPositionOptions }),
    ...fieldArrayBuilder(
      {
        name: 'rateUsPopupLevels',
        fieldsConfiguration: [
          { type: 'number', label: 'Value' },
        ],
      },
    ),
    ...fieldArrayBuilder(
      {
        name: 'adShowBeforeDailyGame',
        fieldsConfiguration: [
          { type: 'number', label: 'Value' },
        ],
      },
    ),
    ...fieldArrayBuilder(
      {
        name: 'adShowAfterDailyGame',
        fieldsConfiguration: [
          { type: 'number', label: 'Value' },
        ],
      },
    ),
    ...fieldArrayBuilder(
      {
        name: 'newGamesToShowDcPopup',
        fieldsConfiguration: [
          { type: 'number', label: 'Value' },
        ],
      },
    ),
    ...fieldArrayBuilder(
      {
        name: 'newGamesToShowJourneyEventPopup',
        fieldsConfiguration: [
          { type: 'number', label: 'Value' },
        ],
      },
    ),
    ...fieldArrayBuilder(
      {
        name: 'journeyPopupTriggers',
        fieldsConfiguration: [
          { type: 'number', label: 'Value' },
        ],
      },
    ),
  }
));

export default combineWithSharedFieldsFactory(fieldsFactory);
