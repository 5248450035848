import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { FormGroup, useQuery } from '@hooks';
import {
  Label,
  Field,
  SelectField,
  AssetsKeySelectField,
} from '@controls/form';
import { PageSection } from '@pages/common';

import { LocalizationsRoutes, FilesRoutes } from '@pages/routes';

const SortagaConfigurationForm = ({ defaultVariant = false }) => {
  const {
    enums,
    modelsLocales: { sortaga: { journeys: { activationEvents: { tooltips } } } },
  } = APP_DATA;

  const { query } = useRouter();

  const { applicationId } = query;

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  const assetTypesEnum = enums['Sortaga::AssetTypes'];
  const fileTypesEnum = enums['Sortaga::FileTypesEnum'];

  const { response: localizationKeys } = useQuery({
    request: LocalizationsRoutes.Keys.indexRequest,
    queryKey: ['localizationKeys', applicationId],
    toSelectOptions: true,
  });
  const { response: levelFileOptions } = useQuery({
    request: FilesRoutes.indexRequest,
    additionalRequestParams: { withoutPagination: true, filter: { fileTypeEq: fileTypesEnum.JOURNEY_LEVELS } },
    queryKey: ['journeyLevelFiles', applicationId],
    toSelectOptions: true,
  });

  return (
    <>
      <Label text="Sequence number" tooltipText={tooltips.cohortDayActivationRule}>
        <Field type="number" name="sequenceNumber" />
      </Label>
      <FormGroup name={variantAttributesKey}>
        <Label text="Activation Journey Cohort Day Limit" tooltipText={tooltips.cohortDayActivationRule}>
          <Field type="number" name="cohortDayActivationRule" appendText="Days" />
        </Label>
        <Label text="Title Translation Key">
          <SelectField name="titleTranslationKeyId" options={localizationKeys} />
        </Label>
        <Label text="Subtitle Translation Key">
          <SelectField name="subtitleTranslationKeyId" options={localizationKeys} />
        </Label>

        <PageSection title="Asset Bundles:" />
        <Label text="Asset: Main Bundle">
          <AssetsKeySelectField
            name="assetKeyId"
            types={assetTypesEnum.JOURNEY_MAIN}
          />
        </Label>
        <Label text="Asset: Rewards Bundle">
          <AssetsKeySelectField
            name="assetRewardsKeyId"
            types={assetTypesEnum.JOURNEY_REWARDS}
          />
        </Label>

        <Label
          text="Journey levels"
          tooltipText={tooltips.journeyLevelsUrl}
          copyableText="config.journey_levels_url"
        >
          <SelectField
            name="levelsFileId"
            options={levelFileOptions}
            quickLinkFn={(id) => FilesRoutes.editPath({ applicationId, id })}
          />
        </Label>
      </FormGroup>
    </>
  );
};

export default SortagaConfigurationForm;
