import { last, mapValues } from 'lodash';

import { EVENTS } from './constants';

export default function versionObject(version) {
  return (
    (version.event === EVENTS.CREATE || version.event === EVENTS.DUPLICATE)
      ? mapValues(version.objectChanges, last)
      : version.object
  );
}
