import React from 'react';
import { get } from 'lodash';
import { Alert } from '@tripledotstudios/react-core';

import { useFormContext, useFormGroup } from '@hooks';
import { localizedLabelFactory, Field, CheckboxField } from '@controls/form';

const LocalizedLabel = localizedLabelFactory(
  'ads.configuration.frequencyRestrictionsConfigurationAttributes.conditions.timeBased',
);

export default function TimeBased({ disabled }) {
  const { values } = useFormContext();
  const { generateName } = useFormGroup();

  const minTimeAdjustmentEnabled = get(values, generateName('minTimeAdjustment'));

  return (
    <>
      <LocalizedLabel name="minTimeBetweenInterstitials" direction="vertical">
        <Field type="number" name="minTimeBetweenInterstitials" appendText="seconds" disabled={disabled} />
      </LocalizedLabel>

      <LocalizedLabel name="minTimeAdjustment" direction="vertical">
        <CheckboxField name="minTimeAdjustment" disabled={disabled} />
      </LocalizedLabel>

      <LocalizedLabel name="secondsPerImpression" direction="vertical">
        <Field
          type="number"
          name="secondsPerImpression"
          disabled={disabled || !minTimeAdjustmentEnabled}
          appendText="seconds"
        />
      </LocalizedLabel>

      <LocalizedLabel name="secondsMaxValue" direction="vertical">
        <Field
          type="number"
          name="secondsMaxValue"
          disabled={disabled || !minTimeAdjustmentEnabled}
          appendText="seconds"
        />
      </LocalizedLabel>

      <LocalizedLabel name="resetTimerAfterRewardedVideo" direction="vertical">
        <CheckboxField name="resetTimerAfterRewardedVideo" disabled={disabled} />
      </LocalizedLabel>

      <LocalizedLabel name="resetTimerAfterInterstitial" direction="vertical">
        <CheckboxField name="resetTimerAfterInterstitial" disabled={disabled} />
      </LocalizedLabel>

      <Alert variant="info">
        <span>
          <b>Reset after Interstitial </b>
          is ignored by clients with platform version older than 5.4.1
        </span>
      </Alert>

      <LocalizedLabel name="timerCountsInBackground" direction="vertical">
        <CheckboxField name="timerCountsInBackground" disabled={disabled} />
      </LocalizedLabel>
    </>
  );
}
