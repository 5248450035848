import React from 'react';
import { Col } from 'react-bootstrap';

import { fromClassNameToOptions } from '@services/enums';
import {
  Filter,
  TextFilter,
  StatusFilter,
} from '@pages/common';

export default function MissionKeysFilter() {
  const missionStatusOptions = fromClassNameToOptions(
    'Missions::AllocationTemplateStatuses',
  );

  return (
    <Filter defaultComponents={['availabilityStateIn', 'createdByMe', 'id']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <StatusFilter
              label="Status"
              options={missionStatusOptions}
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
