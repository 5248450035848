import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import {
  get, isPlainObject, isEqual,
} from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';
import classNames from 'classnames';

import { DndIcon, PageSection } from '@pages/common';
import { Collapsible, ServerError } from '@controls/form';
import IconButton from '@controls/buttons';
import {
  useFormContext, useFormGroup, useSelectedRows, FormGroup, useConfirm, useI18n,
} from '@hooks';
import entityIdentity from '@components/shared/entityIdentity';

import ListActions from './ListActions';

const SlimDndIcon = styled(DndIcon)`
  display: block;
  top: 0.8rem;
  height: 0;
`;

const CheckboxWrapper = styled.div`
  display: block;
  margin-right: 5px;
  position: relative;
  left: -2em;
  top: 0.8rem;
  height: 0;
`;

const EntityContent = styled.div`
  ${({ readOnly }) => readOnly && `
    pointer-events: none;
    opacity: 0.6;
  `}
`;

const DefaultAddEntityBlock = React.memo(({ onEntityAdd, disabled, entityName }) => (
  <div className="pb-4">
    <IconButton.New className="float-end" onClick={onEntityAdd} disabled={disabled}>
      {`Add ${entityName}`}
    </IconButton.New>
  </div>
), (prev, next) => prev.disabled === next.disabled && prev.entityName === next.entityName);

const ListWrapper = React.memo(({
  AddEntityBlock,
  BulkCopyModal,
  EntityHeader,
  attributesName,
  children,
  disableAdding,
  disabled,
  entityAttributes,
  entityNameTranslationPath,
  extraEntityIdentityFn,
  levelsPath,
  metaErrorsPath,
  onEntityAdd,
  readOnly,
  bulkCopyRoutes,
  hideListActions,
  isReorderable = true,
  ExtraListButtons,
  disableDelete,
  withReorderIconPadding = false,
  withMassOperations = true,
  onBulkDuplicate,
  bankType,
}) => {
  const entities = entityAttributes || [];
  const notDestroyedEntities = entities.filter(({ _destroy }) => !_destroy);
  const [uuidToPositionMapping, setUuidToPositionMapping] = useState(null);
  const [expandedEntities, setExpandedEntities] = useState([]);
  const [massOperationsMode, setMassOperationsMode] = useState(false);
  const [bulkDuplicatedRecords, setBulkDuplicatedRecords] = useState(null);
  const [reordering, setReordering] = useState(false);
  const {
    selectedItems,
    selectedRows,
    selectableItems,
    setSelectedRows,
  } = useSelectedRows(entities, ({ _destroy }) => !_destroy);

  const { dispatch, meta } = useFormContext();
  const { generateName } = useFormGroup();
  const confirm = useConfirm();
  const { translate } = useI18n();
  const { query: { id: bankId } } = useRouter();
  const entitiesErrors = get(meta, `errors.${generateName(attributesName)}`, {});
  const handleCloseModal = () => {
    setBulkDuplicatedRecords(null);
    setMassOperationsMode(false);
  };

  const entityName = translate.fallback(entityNameTranslationPath);

  const toggleReordering = () => {
    if (reordering) {
      setUuidToPositionMapping(null);
    } else {
      const mapping = entityAttributes.reduce((memo, { _uuid, position, number }) => {
        // eslint-disable-next-line no-param-reassign
        memo[_uuid] = position || number;

        return memo;
      }, {});

      setUuidToPositionMapping(mapping);
    }

    return setReordering(!reordering);
  };

  const onReorderingCancel = () => {
    dispatch({ actionType: 'applyPositions', uuidToPositionMapping, levelsPath });

    toggleReordering();
  };

  const onEntityToggle = (uuid, entityUuid) => {
    if (reordering) return null;

    const activeEntities = uuid
      ? [...expandedEntities, uuid]
      : expandedEntities.filter((_uuid) => _uuid !== entityUuid);

    return setExpandedEntities(activeEntities);
  };

  const onEntitiesReorder = ({ source, destination }) => {
    dispatch({
      actionType: 'moveEntity',
      sourceIndex: source.index,
      destinationIndex: destination.index,
      levelsPath,
      metaErrorsPath,
      meta,
    });
  };

  const onEntityDelete = (entity) => {
    const { _uuid } = entity;

    return confirm.showConfirmation({
      body: `${entityIdentity({ entityName, entity })} will be deleted and not available for work. Continue?`,
    })
      .then(() => {
        dispatch({ actionType: 'removeEntity', _uuid, levelsPath });
        onEntityToggle(null, _uuid);
      });
  };

  const onBulkDelete = (entitiesToRemove) => (
    confirm.showConfirmation({
      body: 'Selected entity(s) will be deleted and not available for work. Continue?',
    })
      .then(() => {
        dispatch({ actionType: 'bulkRemoveEntities', uuids: entitiesToRemove.map(({ _uuid }) => _uuid), levelsPath });

        return setMassOperationsMode(false);
      })
  );

  return (
    <>
      <PageSection title={`${entityName}s`} />

      <FormGroup name={attributesName}>
        <ButtonToolbar>
          {notDestroyedEntities.length > 1 && !hideListActions && (
            <ListActions
              reordering={reordering}
              toggleReordering={toggleReordering}
              massOperationsMode={massOperationsMode}
              onReorderingCancel={onReorderingCancel}
              notDestroyedEntities={notDestroyedEntities}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setBulkDuplicatedRecords={setBulkDuplicatedRecords}
              setMassOperationsMode={setMassOperationsMode}
              selectedItems={selectedItems}
              readOnly={readOnly}
              onBulkDelete={onBulkDelete}
              disabled={disabled || disableDelete}
              BulkCopyModal={BulkCopyModal}
              isReorderable={isReorderable}
              withMassOperations={withMassOperations}
              onBulkDuplicate={onBulkDuplicate}
            />
          )}
          {ExtraListButtons && (
            <>
              <ButtonToolbar.Divider />
              {ExtraListButtons}
            </>
          )}
        </ButtonToolbar>
        <ServerError name="list" />
        <DragDropContext onDragEnd={onEntitiesReorder}>
          <Droppable droppableId="entity-banks-droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {selectableItems.map((entity, index) => {
                  const { id, _destroy, _uuid } = entity;
                  const isExpanded = !reordering && expandedEntities.includes(_uuid);
                  const blockReordering = !reordering || _destroy || disabled;
                  const extraEntityIdentityInTitle = extraEntityIdentityFn && extraEntityIdentityFn(entity);

                  const rightSideText = [
                    extraEntityIdentityInTitle,
                    entity.id && `ID: ${entity.id}`,
                  ].filter((text) => text).join(' ');

                  return (
                    <Draggable key={_uuid} draggableId={_uuid} index={index} isDragDisabled={blockReordering}>
                      {(draggableProvided) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          className={classNames('mt-3', { 'ps-4': withReorderIconPadding && reordering })}
                        >
                          {reordering && (
                            <SlimDndIcon
                              {...draggableProvided.dragHandleProps}
                              disabled={blockReordering}
                            />
                          )}
                          {massOperationsMode && (
                            <CheckboxWrapper>
                              <input
                                type="checkbox"
                                disabled={_destroy || !id}
                                checked={entity.selectedRows[entity.id] || false}
                                onClick={(e) => entity.toggleRow(e, index)}
                              />
                            </CheckboxWrapper>
                          )}
                          <Collapsible
                            header={EntityHeader ? (
                              <EntityHeader entityName={entityName} entity={entity} />
                            ) : entityIdentity({ entityName, entity })}
                            eventKey={_uuid}
                            activeKey={isExpanded ? _uuid : ''}
                            rightSideText={rightSideText}
                            withErrors={isPlainObject(entitiesErrors) && entitiesErrors[index]}
                            onSelect={(clickedUuid) => onEntityToggle(clickedUuid, _uuid)}
                            readOnly={_destroy}
                            strikethroughText={_destroy}
                          >
                            <FormGroup name={index}>
                              <EntityContent readOnly={_destroy || disabled}>
                                {isExpanded && children({ index, entity, readOnly: (readOnly || _destroy) })}
                              </EntityContent>
                              <Row>
                                <Col>
                                  <ButtonToolbar className="justify-content-end">
                                    <IconButton.Duplicate
                                      disabled={readOnly || _destroy || disabled}
                                      onClick={() => dispatch({ actionType: 'duplicateEntity', _uuid, levelsPath })}
                                    />
                                    {_destroy ? (
                                      <IconButton.Restore
                                        disabled={readOnly || disableAdding}
                                        onClick={() => dispatch({ actionType: 'restoreEntity', _uuid, levelsPath })}
                                      />
                                    ) : (
                                      <IconButton.Delete
                                        disabled={disabled || readOnly}
                                        onClick={() => onEntityDelete(entity)}
                                      />
                                    )}
                                  </ButtonToolbar>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Collapsible>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="mt-4">
          {AddEntityBlock ? (
            <AddEntityBlock disabled={readOnly || disableAdding || reordering || disabled} onEntityAdd={onEntityAdd} />
          ) : (
            <DefaultAddEntityBlock
              onEntityAdd={onEntityAdd}
              disabled={readOnly || disableAdding || reordering || disabled}
              entityName={entityName}
            />
          )}
        </div>
      </FormGroup>
      {BulkCopyModal && createPortal(
        <BulkCopyModal
          entityName={entityName}
          bankId={bankId}
          handleClose={handleCloseModal}
          entities={bulkDuplicatedRecords}
          bulkCopyRoutes={bulkCopyRoutes}
          bankType={bankType}
        />,
        document.body,
      )}
    </>
  );
}, (prev, next) => (
  prev.readOnly === next.readOnly && prev.disableAdding === next.disableAdding
  && isEqual(prev.entityAttributes, next.entityAttributes) && isEqual(prev.children, next.children)
  && isEqual(prev.AddEntityBlock, next.AddEntityBlock)
));

export default ListWrapper;
