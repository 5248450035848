import React from 'react';
import { JourneyRoutes } from '@pages/routes';
import SharedNew from '../shared/New';

import Form from './Form';

const Edit = () => (
  <SharedNew localePath="journeys.activationEvents" Form={Form} routes={JourneyRoutes.ActivationEvents} />
);

export default Edit;
