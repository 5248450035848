/* eslint-disable gs/no-space-literal */
import React, { useState } from 'react';
import {
  Modal, Row, Col, Button,
} from 'react-bootstrap';
import { get } from 'lodash';

import { FormGroup, useFormContext, useFormGroup } from '@hooks';
import { Field, CheckboxField, ServerError } from '@controls/form';
import { ItemsSelectField } from '@components/merge/shared/items/Select';
import { AddIcon, RemoveIcon } from '@components/merge/shared/icons';

const calculateRewards = (rewards, items) => {
  if (!rewards || !rewards.filter(({ _destroy }) => !_destroy).length) {
    return {};
  }

  return rewards.reduce((memo, item) => {
    const correspondingItem = items.find(({ value }) => Number(item.value) === value);
    const itemName = correspondingItem ? correspondingItem.label : '';

    // eslint-disable-next-line no-param-reassign
    memo[itemName] = memo[itemName] ? memo[itemName] += item.quantity : item.quantity;

    return memo;
  }, {});
};

export default function GiftBag({ value, items }) {
  const [showModal, setShowModal] = useState(false);
  const { dispatch, meta } = useFormContext();
  const { generateName } = useFormGroup();
  const path = generateName('rewardsAttributes');

  const errors = meta && meta.errors && get(meta.errors, path);
  const totalQuantity = value.rewardsAttributes
    ? value.rewardsAttributes.filter(({ _destroy }) => !_destroy).reduce((memo, { quantity }) => (
      memo + (quantity || 0)
    ), 0) : 0;

  const onGiftBagItemAdd = () => dispatch({ type: 'add_gift_bag_item', path });
  const onGiftBagItemDelete = (index) => (
    dispatch({ type: 'remove_reward', path: generateName(`rewardsAttributes[${index}]`) })
  );
  const onHide = () => setShowModal(false);

  return (
    <>
      <Button variant={errors ? 'outline-danger' : 'light'} onClick={() => setShowModal(true)}>
        <span className="me-2">Gift Bag Items</span>
        (
        <span className="me-2">{totalQuantity}</span>
        )
        {Object.entries(calculateRewards(value.rewardsAttributes, items))
          .map(([item, count]) => `${item} (${count})`).join(', ')}
      </Button>
      <Modal
        show={showModal}
        onHide={onHide}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Gift Bag Items
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ServerError name="rewardsAttributes.base" className="mb-1" />
          <Row className="mb-2">
            <Col xs={5}><b>Item</b></Col>
            <Col xs={3}><b>Locked</b></Col>
            <Col xs={3}><b>Quantity</b></Col>
          </Row>
          {value.rewardsAttributes && value.rewardsAttributes.map((reward, index) => (
            !reward._destroy ? (
              <FormGroup key={reward._uuid} name={`rewardsAttributes[${index}]`}>
                <Row className="mb-2">
                  <Col xs={5} className="pe-0">
                    <ItemsSelectField name="value" items={items} className="me-1" />
                  </Col>
                  <Col xs={3} className="pe-0">
                    <CheckboxField name="locked" />
                  </Col>
                  <Col xs={3} className="pe-0">
                    <Field name="quantity" type="number" className="me-1" />
                  </Col>
                  <Col xs={1}>
                    <RemoveIcon onClick={() => onGiftBagItemDelete(index)} />
                  </Col>
                </Row>
              </FormGroup>
            ) : ''
          ))}
          <AddIcon onClick={onGiftBagItemAdd} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
