import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { FormGroup } from '@hooks';
import { MissionsRoutes } from '@pages/routes';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { PageSection } from '@pages/common';

import Tier from './tiers/Tier';

const adjustRewardOptions = ({ data: { items } }) => (
  items.map(({ id, rewardType, name }) => ({ value: id, label: name, rewardTypeName: rewardType }))
);

export default function Tiers({ data }) {
  const [missionTypeOptions, setMissionTypeOptions] = useState([]);
  const [rewardTypeOptions, setRewardTypeOptions] = useState([]);

  const { query: { applicationId } } = useRouter();

  useEffect(() => {
    (async () => {
      setMissionTypeOptions(await requestWithToSelectOptions(MissionsRoutes.MissionTypes.indexRequest, applicationId));
    })();

    requestWithToSelectOptions(
      MissionsRoutes.Rewards.Types.indexRequest,
      applicationId,
      {},
      { toSelectOptionsFn: adjustRewardOptions },
    ).then((options) => setRewardTypeOptions(options));
  }, []);

  return rewardTypeOptions.length > 0 && (
    <>
      <PageSection title="Tiers" />
      <hr />
      {data.map((tier, index) => (
        <FormGroup key={tier._uuid} name={`tiersAttributes.${index}`}>
          <Tier
            data={tier}
            tierIndex={index}
            missionTypeOptions={missionTypeOptions}
            rewardTypeOptions={rewardTypeOptions}
          />
        </FormGroup>
      ))}
    </>
  );
}
