import React from 'react';
import styled from 'styled-components';

const LegendWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.gray};
  display: flex;
  padding: 5px;
  margin-left: 20px;
  flex-direction: column;
`;

const SmallText = styled.div`
  font-size: 10px;
`;

const BoldText = styled(SmallText)`
  font-weight: 600;
`;

const VerySmallText = styled.div`
  font-size: 8px;
`;
export default function Legend({ rulesWarning }) {
  return (
    <LegendWrapper>
      <div className="d-flex">
        <BoldText className="d-flex me-1">Legend: </BoldText>
        <span className="d-flex">
          <SmallText className="text-danger">Exists only in the left entity</SmallText>
          <SmallText className="text-warning px-2">Difference in values</SmallText>
          <SmallText className="text-success">Exists only in the right entity</SmallText>
        </span>
      </div>
      <div className="d-flex mt-1 justify-content-center">
        {rulesWarning && (
          <VerySmallText>
            Warning: The comparison is NOT always correctly applicable to Rules
          </VerySmallText>
        )}
      </div>
    </LegendWrapper>
  );
}
