import React, { useMemo } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import {
  PageHeader, Pagination, ReactTable, TIMESTAMP_COLUMNS, InLiveColumn, InUseColumn, Filter,
} from '@pages/common';
import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

const columnsFactory = ({ query }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Description', accessor: 'description' },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  { Header: 'Min version', accessor: 'minVersion' },
  { Header: 'Max version', accessor: 'maxVersion' },
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id } } }) => (
      <IconButton.Edit to={MergeRoutes.Events.Schemas.editPath({ id, applicationId: query.applicationId })} minimized />
    ),
  },
];

export default function Index() {
  const { response } = useQuery(MergeRoutes.Events.Schemas.indexRequest, { includeInUse: true });
  const { query } = useRouter();

  const columns = useMemo(() => columnsFactory({ query }), []);

  return response && (
    <div>
      <PageHeader
        title="Event Schemas"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
      />

      <ReactTable
        columns={columns}
        data={response.items}
      />

      <Pagination
        pageCount={response ? response._meta.pages : 1}
      />
    </div>
  );
}
