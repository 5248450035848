import React from 'react';

import BundleTable from '@components/assets/keys/BundleTable';
import { PageHeader, DuplicateButton } from '@pages/common';
import {
  Form,
  useCurrentApplication,
} from '@hooks';
import { fromClassNamesToOptions } from '@services/enums';
import {
  DateTimeField,
  Field,
  FormButtonsGroup,
  Label,
  SelectField,
  StaticFieldsFormGroup,
  AvailabilityStateFormGroup,
  LabelsFormGroup,
} from '@controls/form';
import { combineReducers, rulesReducer } from '@reducers';
import { isInUse } from '@pages/common/InUse';

import { AssetsRoutes } from '@pages/routes';
import formReducer from './formReducer';
import { ASSET_TYPE_HINT, ASSET_TYPE_LABEL } from './constants';

export default function AssetKeysForm({ action, data, onSubmit }) {
  const { currentApplication, applicationName } = useCurrentApplication();
  const [assetTypeOptions, statusOptions] = fromClassNamesToOptions([
    `${applicationName}::AssetTypes`,
    'AssetsKeys::Statuses',
  ]);

  return (
    <Form
      initialValues={{ applicationId: currentApplication.id, ...data }}
      onSubmit={(values) => onSubmit(values)}
      reducer={combineReducers([formReducer, rulesReducer])}
    >
      {({ dispatch, values, dirty }) => (
        <>
          <PageHeader title={`${action} Asset Key`}>
            <FormButtonsGroup cancelButtonPath={AssetsRoutes.Keys.indexPath({ applicationId: currentApplication.id })}>
              <DuplicateButton
                entityName="Asset Key"
                routes={AssetsRoutes.Keys}
                data={data}
                disabled={dirty}
                name="uid"
              />
            </FormButtonsGroup>
          </PageHeader>

          <Label text="Asset key UID">
            <Field name="uid" disabled={isInUse(values.inUse)} />
          </Label>
          <Label text="Status">
            <SelectField name="status" options={statusOptions} />
          </Label>

          <AvailabilityStateFormGroup />

          <LabelsFormGroup />

          <StaticFieldsFormGroup data={data} />

          {assetTypeOptions.length > 0 && (
            <Label text={ASSET_TYPE_LABEL} tooltipText={ASSET_TYPE_HINT}>
              <SelectField
                name="filteringTypesValues"
                isMulti
                placeholder="Not specified"
                options={assetTypeOptions}
              />
            </Label>
          )}
          <Label text="Active from (UTC)">
            <DateTimeField as="date" name="activeFrom" isClearable />
          </Label>
          <Label text="Active to (UTC)">
            <DateTimeField as="date" name="activeTo" isClearable />
          </Label>
          <BundleTable data={values} dispatch={dispatch} />
        </>
      )}
    </Form>
  );
}
