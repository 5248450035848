import React from 'react';
import { Tabs } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

export default function UncontrolledTabs({ as, defaultActiveKey, children }) {
  const router = useRouter();
  const activeKey = router.location.hash ? router.location.hash : defaultActiveKey;
  const onSelect = (e) => {
    const path = router.location.search + e;
    router.push(path);
  };

  const Component = as || Tabs;

  return (
    <Component activeKey={activeKey} onSelect={onSelect}>
      {children}
    </Component>
  );
}
