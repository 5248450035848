import { get, set } from 'lodash';

import APP_DATA from '@services/appData';
import { addUuid } from '@services/recursivelyProcessObject';

const DEFAULT_CONDITION_TYPE = 'Ads::Configuration::Interstitials::FrequencyRestrictions::Conditions::TimeBased';
const Operations = APP_DATA.enums['Ads::Configuration::FrequencyRestrictions::ConditionsSectionOperations'];

const buildNewOverride = (placementId) => (
  addUuid({
    id: null,
    placementId,
    conditionsSectionAttributes: {
      operation: Operations.AND,
      conditionsSectionsAttributes: [],
    },
    _destroy: false,
  })
);

const buildNewCondition = () => (
  addUuid({
    id: null,
    type: DEFAULT_CONDITION_TYPE,
    minTimeAdjustment: false,
    resetTimerAfterRewardedVideo: false,
    resetTimerAfterInterstitial: true,
    timerCountsInBackground: false,
    _destroy: false,
  })
);

const buildNewSection = () => addUuid(
  {
    id: null,
    operation: Operations.AND,
    conditionsAttributes: [
      buildNewCondition(),
    ],
    conditionsSectionsAttributes: [],
    _destroy: false,
  },
);

const recursivelyRemoveSections = (section) => {
  if (!section) return;

  section._destroy = true;
  const childSections = section.conditionsSectionsAttributes;

  if (childSections) childSections.forEach((childSection) => recursivelyRemoveSections(childSection));
};

export default function frequencyRestrictionsReducer(state, action) {
  switch (action.type) {
    case 'addFrequencyRestrictionsOverride': {
      const { overridesPath, placementId } = action;

      const overridesAttributes = get(state, overridesPath);
      overridesAttributes.push(buildNewOverride(placementId));

      break;
    }
    case 'removeFrequencyRestrictionsOverride': {
      const { overridesPath, overrideIndex } = action;

      const overridesAttributes = get(state, overridesPath);
      overridesAttributes[overrideIndex]._destroy = true;

      break;
    }
    case 'addFrequencyRestrictionsConditionsSection': {
      const { conditionsSectionsPath } = action;
      const conditionsSectionsAttributes = get(state, conditionsSectionsPath);

      conditionsSectionsAttributes.push(buildNewSection());

      break;
    }
    case 'removeFrequencyRestrictionsConditionsSection': {
      const { conditionsSectionPath } = action;

      recursivelyRemoveSections(get(state, conditionsSectionPath));

      break;
    }
    case 'addFrequencyRestrictionsCondition': {
      const { conditionsPath } = action;
      const conditionsAttributes = get(state, conditionsPath);

      conditionsAttributes.push(buildNewCondition());

      break;
    }
    case 'removeFrequencyRestrictionsCondition': {
      const { conditionDestroyPath } = action;

      set(state, conditionDestroyPath, true);

      break;
    }
    default: break;
  }

  return state;
}
