import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { newTableConfig, createTableConfig } from '@requests/blackjack/tableConfigs';

import Form from './Form';

export default function New() {
  const [data, setData] = useState();
  const { query } = useRouter();

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Table Config',
    actionName: 'creation',
    redirectPath: `/admin/applications/${query.applicationId}/table_configs`,
    setData,
  });

  const onSubmit = (values) => createTableConfig(values).then(redirectIfSuccessHandler);

  useEffect(() => {
    newTableConfig(query).then((response) => setData(response.data));
  }, []);

  return data ? (
    <Form
      data={data}
      onSubmit={onSubmit}
    />
  )
    : '';
}
