import {
  sortBy, last, get, set,
} from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';
import { persistedCollection } from '@services/utils';

export function maxPosition(collection) {
  const lastElement = last(collection);

  // Set max position to -1 in case if there are no not-destroyed entities so that new added entity
  // will have position of 0
  return lastElement ? lastElement.position : -1;
}

export default function chapterRangeListReducer(state, action) {
  const rangesAttributesPath = action.path || 'chapterRangesAttributes';
  const rangesAttributes = get(state, rangesAttributesPath, []);

  const nonDestroyedChapterRanges = () => persistedCollection(rangesAttributes);
  const sortedChapterRanges = () => sortBy(nonDestroyedChapterRanges(), 'position');

  const freshCollection = () => {
    let maxPos = 0;

    return sortBy(rangesAttributes, 'position').map((range) => {
      if (!range._destroy) {
        range.position = maxPos;

        if (maxPos === 0) range.fromChapter = 1;

        maxPos += 1;
      }

      return range;
    });
  };

  switch (action.type) {
    case 'addChapterRange': {
      const { additionalAttributes } = action;

      const position = maxPosition(sortedChapterRanges()) + 1;
      const attributes = { position, _destroy: false, ...additionalAttributes };
      if (position === 0) attributes.fromChapter = 1;

      rangesAttributes.push(addUuid(attributes));

      set(state, rangesAttributesPath, rangesAttributes);

      break;
    }
    case 'removeChapterRange': {
      const { _uuid } = action;

      const toDestroy = rangesAttributes.find((range) => range._uuid === _uuid);
      toDestroy._destroy = true;

      const newCollection = freshCollection();
      const notDestroyed = persistedCollection(newCollection);
      const lastUuid = last(notDestroyed)._uuid;
      const lastChapterRange = newCollection.find((range) => range._uuid === lastUuid);

      lastChapterRange.toChapter = '';

      notDestroyed.forEach((range, index) => {
        const prevNotDestroyed = notDestroyed[index - 1];

        if (prevNotDestroyed) {
          const prevChapterRange = newCollection.find((aff) => aff._uuid === prevNotDestroyed._uuid);

          if (prevChapterRange) range.fromChapter = Number(prevChapterRange.toChapter) + 1;
        }

        return range;
      });

      set(state, rangesAttributesPath, newCollection);

      break;
    }
    case 'toChapterChange': {
      const { _uuid, value } = action;

      const newCollection = freshCollection();
      const current = newCollection.find((range) => range._uuid === _uuid);
      const next = newCollection.find((range) => (
        !range._destroy && range.position === current.position + 1
      ));

      if (!value.length) {
        current.toChapter = '';
        if (next) next.fromChapter = '';

        break;
      }

      const valueToSet = Number(value);
      current.toChapter = valueToSet;

      if (next) next.fromChapter = valueToSet + 1;

      set(state, rangesAttributesPath, newCollection);

      break;
    }
    default: break;
  }

  return state;
}
