import React from 'react';

import APP_DATA from '@services/appData';

import { localizedLabelFactory, Field, CheckboxField } from '@controls/form';

const { enums } = APP_DATA;

const LocalizedLabel = localizedLabelFactory('ads.configuration.newcomersRestrictionsConfigurationAttributes');
const blockTypes = enums['Ads::Configuration::NewcomersRestrictions::BlockTypes'];

const BlockConfigurationFields = ({ selectedBlockType, disabled }) => {
  switch (selectedBlockType) {
    case blockTypes.SECONDS:
      return (
        <>
          <LocalizedLabel name="blockSeconds" labelSize={5} fieldSize={7}>
            <Field type="number" name="blockSeconds" disabled={disabled} />
          </LocalizedLabel>

          <LocalizedLabel name="timerCountsInBackground" labelSize={5} fieldSize={7}>
            <CheckboxField name="timerCountsInBackground" disabled={disabled} />
          </LocalizedLabel>
        </>
      );
    case blockTypes.INTERSTITIALS:
      return (
        <LocalizedLabel name="blockInterstitials" labelSize={5} fieldSize={7}>
          <Field type="number" name="blockInterstitials" disabled={disabled} />
        </LocalizedLabel>
      );
    default:
      return ('');
  }
};

export default BlockConfigurationFields;
