import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { WoodokuRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Tile Type',
    actionName: 'create',
    redirectPath: WoodokuRoutes.TileTypes.indexPath({ applicationId: query.applicationId }),
    setData,
  });

  const onSubmit = (values) => (
    WoodokuRoutes.TileTypes
      .createRequest({ ...values, applicationId: query.applicationId })
      .then(responseHandler)
  );

  useEffect(() => {
    WoodokuRoutes.TileTypes
      .newRequest(query).then((response) => setData(response.data));
  }, []);

  return data && (
    <Form data={data} onSubmit={onSubmit} actionName="Create" headerTitle="New Tile Type" />
  );
}
