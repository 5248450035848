import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useI18n } from '@hooks';
import { formResponseHandler } from '@requests/responseHandlers';
import { MissionsRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  const responseHandler = formResponseHandler({
    entityName: `${namespaceName} Allocation`,
    actionName: 'create',
    redirectPath: MissionsRoutes.AllocationTemplates.indexPath({ applicationId: query.applicationId }),
    setData,
  });

  const onSubmit = (values) => (
    MissionsRoutes.AllocationTemplates
      .createRequest({ ...values, applicationId: query.applicationId })
      .then(responseHandler)
  );

  useEffect(() => {
    MissionsRoutes.AllocationTemplates.newRequest(query).then((response) => setData(response.data));
  }, []);

  return data && (
    <Form
      data={data}
      onSubmit={onSubmit}
      actionName="Create"
    />
  );
}
