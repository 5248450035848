import { get } from 'lodash';

export default function formReducer(state, action) {
  if (action.actionType === 'changeLevelLayout') {
    const { chapterPath, levelIndex } = action;
    const chapter = get(state, chapterPath);
    const level = chapter.levelsAttributes[levelIndex];

    if (level) level.modeConfigId = null;
  }

  return state;
}
