import React, { useState, useEffect, useRef } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { get, toNumber, toInteger } from 'lodash';

import { useFormGroup, useFormContext } from '@hooks';
import ServerError from './ServerError';

const TimeInputGroup = ({ text, children }) => (
  <InputGroup className="mb-3">
    {children}
    <InputGroup.Text>{text}</InputGroup.Text>
  </InputGroup>
);

// supported inputs ['days', 'hours', 'minutes', 'seconds']
export default function TimePeriodField({
  name, disabled, onKeyPress, onBlur, autoFocus, inputs = ['hours', 'minutes'],
}) {
  const { values, setFieldValue, applySharedInputProps } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const firstInputRef = useRef();

  const [secondsValue, setSecondsValue] = useState(get(values, fullName));

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [
    showDays, showHours, showMinutes, showSeconds,
  ] = ['days', 'hours', 'minutes', 'seconds'].map((input) => inputs.includes(input));

  const applyChanges = () => {
    const totalSeconds = (toInteger(days) * 24 * 60 * 60)
      + (toInteger(hours) * 60 * 60)
      + (toInteger(minutes) * 60)
      + toNumber(seconds || 0);

    setFieldValue(fullName, totalSeconds);
    setSecondsValue(totalSeconds);
  };

  useEffect(() => {
    let initialValue = secondsValue;

    const extractTime = (divider) => {
      const time = toInteger(initialValue / divider);
      initialValue -= (time * divider);

      return time;
    };

    setDays(showDays ? extractTime(60 * 60 * 24) : 0);
    setHours(showHours ? extractTime(60 * 60) : 0);
    setMinutes(showMinutes ? extractTime(60) : 0);
    setSeconds(initialValue);
  }, [secondsValue]);

  useEffect(() => {
    if (firstInputRef.current) { firstInputRef.current.focus(); }
  }, []);

  const handleChange = (fn) => ({ target: { value: newValue } }) => fn(newValue);

  const inputProps = (inputName) => ({
    type: 'number',
    step: inputName === 'seconds' ? '0.1' : '1',
    onBlur: () => {
      if (onBlur) { onBlur(); }
      applyChanges();
    },
    ...applySharedInputProps({ disabled }),
    onKeyPress: (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        applyChanges();
      }
      if (onKeyPress) { onKeyPress(event); }
    },
    ...autoFocus && inputs[0] === inputName ? { ref: firstInputRef } : {},
  });

  return (
    <>
      {showDays && (
        <TimeInputGroup text="days">
          <FormControl
            value={days}
            onChange={handleChange(setDays)}
            {...inputProps('days')}
          />
        </TimeInputGroup>
      )}
      {showHours && (
        <TimeInputGroup text="hours">
          <FormControl
            value={hours}
            onChange={handleChange(setHours)}
            {...inputProps('hours')}
          />
        </TimeInputGroup>
      )}
      {showMinutes && (
        <TimeInputGroup text="minutes">
          <FormControl
            value={minutes}
            onChange={handleChange(setMinutes)}
            {...inputProps('minutes')}
          />
        </TimeInputGroup>
      )}
      {showSeconds && (
        <TimeInputGroup text="seconds">
          <FormControl
            value={seconds}
            onChange={handleChange(setSeconds)}
            {...inputProps('seconds')}
          />
        </TimeInputGroup>
      )}
      <ServerError name={name} className="mt-n3" />
    </>
  );
}
