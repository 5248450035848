import { React, useMemo, useCallback } from 'react';

import { Alert } from '@tripledotstudios/react-core';
import APP_DATA from '@services/appData';
import {
  localizedLabelFactory,
  SelectField,
  Field,
  CheckboxField,
} from '@controls/form';
import { fromClassNamesToOptions } from '@services/enums';
import { useCurrentApplication, useFormGroup, useFormContext } from '@hooks';
import { TitledAlert } from '@components/shared';

import { PageSection } from '@pages/common';
import { Container } from 'react-bootstrap';
import { omit } from 'lodash';

const biddingFloorTypes = APP_DATA.enums['Ads::Configuration::BiddingFloors::BiddingFloorTypes'];
const [biddingFloorTypeOptions, formulaTypeOptions, nonQualifyingBehaviorOptions] = fromClassNamesToOptions(
  [
    'Ads::Configuration::BiddingFloors::BiddingFloorTypes',
    'Ads::Configuration::BiddingFloors::FormulaTypes',
    'Ads::Configuration::BiddingFloors::NonQualifyingBehaviors',
  ],
);

export default function Fields({
  name,
  values,
  disabled,
  clientKey,
  alertText,
  labelKey,
  rewardedVideo,
}) {
  const LocalizedLabel = useMemo(() => localizedLabelFactory(labelKey), [labelKey]);
  const { currentApplication } = useCurrentApplication();
  const { generateName } = useFormGroup();
  const { meta: { errors }, setFieldValue } = useFormContext();
  const requestsConsidered = values?.successfulRequestsOnly ? 'successfulOnly' : 'all';
  const successfulRequestsOnlyField = generateName('successfulRequestsOnly');
  const qualifyingRequestsCountField = generateName('qualifyingRequestsCount');
  const calculationRequestsCountField = generateName('calculationRequestsCount');
  const callbackDependencies = [name, setFieldValue, generateName];

  const onBiddingFloorTypeChanged = useCallback((e) => {
    if (e.value === biddingFloorTypes.STATIC) {
      setFieldValue(successfulRequestsOnlyField, null);
      setFieldValue(qualifyingRequestsCountField, null);
      setFieldValue(calculationRequestsCountField, null);
      setFieldValue(generateName('formulaMultiplier'), null);
      setFieldValue(generateName('nonQualifyingBehavior'), null);
    }
  }, callbackDependencies);

  const onSuccessfulRequestsOnlyChange = useCallback((e) => {
    setFieldValue(qualifyingRequestsCountField, null);
    setFieldValue(calculationRequestsCountField, null);
    setFieldValue('meta.errors', omit(errors, [qualifyingRequestsCountField, calculationRequestsCountField]));
    setFieldValue(successfulRequestsOnlyField, e.target.checked);
  }, callbackDependencies);
  const rewardedVideoBiddingFloorTypeOptions = biddingFloorTypeOptions.slice(0, 1);

  return (
    <>
      <LocalizedLabel name="biddingFloorType" labelSize={5} fieldSize={7}>
        <SelectField
          name="biddingFloorType"
          options={rewardedVideo ? rewardedVideoBiddingFloorTypeOptions : biddingFloorTypeOptions}
          isDisabled={disabled}
          onChange={onBiddingFloorTypeChanged}
          hasPrepopulatedOption
        />
      </LocalizedLabel>
      <LocalizedLabel name="staticFloorValue" labelSize={5} fieldSize={7}>
        <Field name="staticFloorValue" disabled={disabled} appendText="$" type="number" step="0.01" />
      </LocalizedLabel>
      {values && values.biddingFloorType === biddingFloorTypes.FORMULA && (
        <Container className="bg-light mb-3 p-3">
          <PageSection title="Formula Parameters" className="mt-2" />
          <LocalizedLabel name="formulaType" labelSize={5} fieldSize={7}>
            <SelectField
              name="formulaType"
              options={formulaTypeOptions}
              isDisabled={disabled}
              hasPrepopulatedOption
            />
          </LocalizedLabel>
          <LocalizedLabel name="successfulRequestsOnly" labelSize={5} fieldSize={7}>
            <CheckboxField
              name="successfulRequestsOnly"
              disabled={disabled}
              onChange={onSuccessfulRequestsOnlyChange}
            />
          </LocalizedLabel>
          <LocalizedLabel name={`qualifyingRequestsCount.${requestsConsidered}`} labelSize={5} fieldSize={7}>
            <Field name="qualifyingRequestsCount" disabled={disabled} type="number" />
          </LocalizedLabel>
          {(!currentApplication.adsSupport.enableAdsStatsCollection) && (
            <Alert variant="info">
              Formula calculations are disabled for this app.
              Please reach out to the Game Server Team to enable them.
            </Alert>
          )}
          <LocalizedLabel name={`calculationRequestsCount.${requestsConsidered}`} labelSize={5} fieldSize={7}>
            <Field name="calculationRequestsCount" disabled={disabled} type="number" />
          </LocalizedLabel>
          <LocalizedLabel name="formulaMultiplier" labelSize={5} fieldSize={7}>
            <Field name="formulaMultiplier" disabled={disabled} type="number" step="0.01" />
          </LocalizedLabel>
          <LocalizedLabel name="nonQualifyingBehavior" labelSize={5} fieldSize={7}>
            <SelectField
              name="nonQualifyingBehavior"
              options={nonQualifyingBehaviorOptions}
              isDisabled={disabled}
              hasPrepopulatedOption
            />
          </LocalizedLabel>
        </Container>
      )}
      <TitledAlert name={clientKey} alertText={alertText} />
    </>
  );
}
