const FieldsConfiguration = [
  {
    label: 'Pack Prices',
    name: 'packPrices',
    type: 'array',
    fieldsConfiguration: [
      { label: 'Name', name: 'key', type: 'text' },
      { label: 'Value', name: 'value', type: 'number' },
    ],
  },
];

export default FieldsConfiguration;
