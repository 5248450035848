import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { SelectField } from '@controls/form';
import { useFormContext } from '@hooks';

export default function TierSelect() {
  const { values: { tiersCount } } = useFormContext();

  const tierArray = Array(tiersCount).fill().map((_, i) => i + 1);
  const tierOptions = tierArray.map((tier) => ({ label: tier, value: tier }));

  return (
    <Row className="mb-2 align-items-center">
      <Col xs="1" className="text-end pe-0 ps-0">Tier</Col>
      <Col xs="11">
        <Row>
          <Col xs="4">
            <SelectField name="tier" options={tierOptions} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
