import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { FormGroup, useFormContext } from '@hooks';
import { SelectField, Field, ServerError } from '@controls/form';
import { fromClassNameToOptions } from '@services/enums';

import { PageSection } from '@pages/common';

import IconButton from '@controls/buttons';
import { persistedCollection } from '@services/utils';

import { tileTypedLevelGoalTypes } from '../constants';
import { filteredGoalTypeOptions, collectTileGoalOptions } from '../utils';

export default function Goals({
  index, levelGoalsAttributes, tileTypeOptions, onConfigItemRemove, levelsPath, readOnly,
}) {
  const goalTypeOptions = fromClassNameToOptions('Woodoku::Journeys::LevelBanks::LevelGoalTypes');
  const { dispatch } = useFormContext();

  const levelGoals = persistedCollection(levelGoalsAttributes);
  const atLeastOneCollectGoalAdded = levelGoals.some(({ goalType }) => goalType === tileTypedLevelGoalTypes.collect);

  const onGoalAdd = () => dispatch({ actionType: 'addLevelGoal', index, levelsPath });

  return (
    <PageSection title="Goals">
      {levelGoals.length > 0 && (
        <div>
          <Row className="mb-2">
            <Col>
              <strong>Goal type</strong>
            </Col>
            {atLeastOneCollectGoalAdded && (
              <Col>
                <strong>Tile type</strong>
              </Col>
            )}
            <Col>
              <strong>Target value</strong>
            </Col>
            <Col xs={1} />
          </Row>

          {levelGoalsAttributes.map(({
            goalType, tileId, _destroy, _uuid,
          }, goalIndex) => (
            !_destroy && (
              <FormGroup key={_uuid} name={`levelGoalsAttributes[${goalIndex}]`}>
                <Row className="mb-2">
                  <Col>
                    <SelectField
                      options={filteredGoalTypeOptions(goalTypeOptions, levelGoals, goalType)}
                      name="goalType"
                      isDisabled={readOnly}
                    />
                  </Col>
                  {atLeastOneCollectGoalAdded && (
                    <Col>
                      {goalType === tileTypedLevelGoalTypes.collect && (
                        <SelectField
                          options={collectTileGoalOptions(tileTypeOptions, levelGoals, tileId)}
                          name="tileId"
                          isDisabled={readOnly}
                        />
                      )}
                    </Col>
                  )}
                  <Col>
                    <Field type="number" name="targetScore" disabled={readOnly} />
                  </Col>
                  <Col xs={1}>
                    <IconButton.Delete
                      minimized
                      onClick={() => onConfigItemRemove('levelGoalsAttributes', _uuid)}
                      disabled={readOnly}
                    />
                  </Col>
                </Row>
              </FormGroup>
            )
          ))}
        </div>
      )}
      <ServerError name="levelGoalsAttributes.common" />
      <IconButton.New
        minimized
        onClick={onGoalAdd}
        disabled={readOnly}
      />
    </PageSection>
  );
}
