import React from 'react';
import { Row, Col } from 'react-bootstrap';

import APP_DATA from '@services/appData';
import {
  useCurrentApplication,
  useFormContext,
} from '@hooks';

import { SelectField } from '@controls/form';
import IconButton from '@controls/buttons';

import typesForSelectByTags from '@services/rules/typesForSelectByTags';
import getAllOperation from '@services/rules/getAllOperations';
import getOperationsForSelect from '@services/rules/getOperationsForSelect';

import { RULES_LIST, DEFAULT_TAG } from './list';

const { rawEnums } = APP_DATA;

export default function BaseRule({
  index,
  rule,
  path,
  disabled,
}) {
  const { applicationKey } = useCurrentApplication();
  const { dispatch } = useFormContext();

  const { id, type, _destroy } = rule;
  const RawRuleOperations = rawEnums['Missions::RuleOperations'];
  const allOperations = getAllOperation(RawRuleOperations);
  const typeToComponentMap = Object.fromEntries(RULES_LIST.map((config) => [config.type, config]));

  const ruleConfig = typeToComponentMap[type] || {};
  const { operations, component: Component } = ruleConfig;

  const onTypeChange = ({ value }) => {
    if (id === null) return;

    dispatch({
      actionType: 'changeClientRuleType',
      path,
      index,
      type: value,
    });
  };

  const onDeleteRule = () => dispatch({ actionType: 'removeClientRule', ruleIndex: index, path });

  return !_destroy && (
    <Row className="mb-3">
      <Col>
        <SelectField
          name="type"
          className="w-100"
          options={typesForSelectByTags(RULES_LIST, [DEFAULT_TAG, applicationKey])}
          onChange={onTypeChange}
          isDisabled={disabled}
        />
      </Col>
      <Col>
        {operations && operations.length > 0 && (
          <SelectField
            name="operation"
            className="w-100"
            options={getOperationsForSelect(allOperations, operations)}
            isDisabled={disabled}
            includeEmpty
          />
        )}
      </Col>
      <Col>
        {Component ? <Component disabled={disabled} /> : null }
      </Col>
      <Col xs={1}>
        <IconButton.Delete
          minimized
          onClick={onDeleteRule}
        >
          Delete
        </IconButton.Delete>
      </Col>
    </Row>
  );
}
