import React, { useState, useMemo } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import {
  useQuery,
  useI18n,
  useConfirm,
} from '@hooks';

import { MyPetCafeRoutes } from '@pages/routes';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import {
  PageHeader,
  Pagination,
  Badge,
  ReactTable,
  TIMESTAMP_COLUMNS,
  RulesList,
  InLiveColumn,
  DuplicationModalWithPriority,
  LabelsColumn,
  PriorityColumn,
} from '@pages/common';

import APP_DATA from '@services/appData';

import IconButton from '@controls/buttons';
import Filter from './Filter';

const statusBadgeColor = (status, statuses) => {
  const colorMapping = {
    [statuses.ACTIVE]: 'success',
    [statuses.INACTIVE]: 'secondary',
    [statuses.ARCHIVED]: 'secondary',
  };

  return colorMapping[status];
};

const columnsFactory = ({
  applicationId, setDuplicatedRecord, onArchive, statuses,
}) => (
  [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Variant ID', accessor: 'defaultVariantId', disableSortBy: true },
    { Header: 'Name', accessor: 'name' },
    LabelsColumn,
    {
      Header: 'Status',
      Cell: ({ row: { original: { status, statusHumanize } } }) => (
        <Badge bg={statusBadgeColor(status, statuses)}>{statusHumanize}</Badge>
      ),
    },
    InLiveColumn,
    PriorityColumn,
    { Header: 'Number of days', accessor: 'numberOfDays' },
    { Header: 'Starting cohory day', accessor: 'startingCohortDay' },
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Rules',
      Cell: ({ row: { original: { ruleSectionsAttributes } } }) => <RulesList rules={ruleSectionsAttributes} />,
    },
    {
      Header: 'Actions',
      Cell: ({
        row: {
          original: { id, name, status },
        },
      }) => (
        <ButtonToolbar>
          <IconButton.Edit to={MyPetCafeRoutes.Adventures.Entities.editPath({ id, applicationId })} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
          {status !== statuses.ARCHIVED && <IconButton.Archive onClick={onArchive(id, name)} minimized />}
        </ButtonToolbar>
      ),
    },
  ]
);

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { enums } = APP_DATA;
  const { translate } = useI18n();
  const statuses = enums['MyPetCafe::Adventures::EntityStatuses'];
  const { query: { applicationId } } = useRouter();
  const { response, refetch, isLoading } = useQuery(MyPetCafeRoutes.Adventures.Entities.indexRequest);
  const confirm = useConfirm();

  const modelName = translate.fallback('adventures.entities.name');

  const archiveResponseHandler = collectionResponseHandlerFactory({
    entityName: modelName,
    actionName: 'archive',
    refetch,
  });

  const onArchive = (id, name) => () => {
    confirm.showConfirmation({
      title: `${modelName} '${name}' will be archived and not available for work. Continue?`,
    })
      .then(() => MyPetCafeRoutes.Adventures.Entities.archiveRequest({ applicationId, id })
        .then(archiveResponseHandler));
  };

  const columns = useMemo(() => columnsFactory({
    applicationId, setDuplicatedRecord, onArchive, statuses,
  }), []);

  return (
    <>
      <PageHeader
        title={translate.fallback('adventures.entities.pluralName')}
        filter={<Filter statuses={statuses} />}
      >
        <IconButton.New to={MyPetCafeRoutes.Adventures.Entities.newPath({ applicationId })} />
      </PageHeader>
      {isLoading ? <Spinner /> : response && (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
          />
          <DuplicationModalWithPriority
            record={duplicatedRecord}
            entityName={modelName}
            handleClose={() => setDuplicatedRecord(null)}
            onSubmit={(values) => MyPetCafeRoutes.Adventures.Entities.duplicateRequest({ ...values, applicationId })}
            entitiesByPriority={response.entitiesByPriority || {}}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </>
  );
}
