import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import {
  editHexagonSquareConfig,
  updateHexagonSquareConfig,
} from '@requests/puzzle-time/hexagonSquareConfigs';

import fieldsConfiguration from './FieldsConfiguration';
import Form from '../GameConfigsForm';

export default function Edit() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const indexPath = `/admin/applications/${query.applicationId}/puzzle_time/hexagon_square_configs`;

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Square Puzzle Config',
    actionName: 'update',
    redirectPath: indexPath,
    setData,
  });

  const onSubmit = (values) => updateHexagonSquareConfig(query, values).then(redirectIfSuccessHandler);

  useEffect(() => {
    editHexagonSquareConfig(query).then((response) => setData(response.data));
  }, []);

  return data ? (
    <Form
      data={data}
      onSubmit={onSubmit}
      fieldsConfiguration={fieldsConfiguration}
      actionName="Update"
      indexPath={indexPath}
      configName="Square Puzzle Config"
    />
  )
    : '';
}
