import React from 'react';
import { Card } from 'react-bootstrap';
import { TdsLogo } from '@tripledotstudios/react-core';

export default function MaintenanceMode() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Card className="text-center">
        <Card.Body className="p-5">
          <TdsLogo width="100" height="100" className="mb-3" maintenance />
          <h3>Maintenance mode</h3>
          <span className="text-muted">
            Sorry, Game Server admin interface is unavailable due to scheduled maintenance.
            <p />
            Please come back later. Our client APIs are running as normal.
          </span>
        </Card.Body>
      </Card>
    </div>
  );
}
