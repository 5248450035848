import React from 'react';

import { localizedLabelFactory, SelectField, CheckboxField } from '@controls/form';
import BlockConfigurationFields from './BlockConfigurationFields';

const LocalizedLabel = localizedLabelFactory('ads.configuration.newcomersRestrictionsConfigurationAttributes');

export default function ConfigurationFields({
  values, disabled, blockTypeOptions, dispatch, attributesPath, children,
}) {
  const overridableFieldsDisalbed = disabled || !values.enabled;

  return (
    <>
      <LocalizedLabel name="enabled" labelSize={5} fieldSize={7}>
        <CheckboxField name="enabled" disabled={disabled} />
      </LocalizedLabel>

      {children}

      <LocalizedLabel name="blockType" labelSize={5} fieldSize={7}>
        <SelectField
          key={attributesPath}
          name="blockType"
          options={blockTypeOptions}
          onChange={({ value }) => dispatch({ type: 'newcomersRestrictionsChangeBlockType', value, attributesPath })}
          hasPrepopulatedOption
          isDisabled={overridableFieldsDisalbed}
        />
      </LocalizedLabel>
      <BlockConfigurationFields
        selectedBlockType={values.blockType}
        disabled={overridableFieldsDisalbed}
      />
    </>
  );
}
