import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { compact } from 'lodash';

import { fromClassNameToOptions } from '@services/enums';

import {
  useCurrentApplication, Form, FormGroup, useRewardOptions,
} from '@hooks';
import { combineReducers, rewardableReducer } from '@reducers';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup, ServerError,
} from '@controls/form';
import {
  DuplicateButton,
  PageHeader,
  PageSection,
  ReactTable,
  Rewards,
} from '@pages/common';
import { SolRoutes as Routes } from '@pages/routes';
import Tooltip from '@controls/Tooltip';
import playerLevelSetReducer from './reducer';
import MassOperations from './MassOperations';
import FeatureToUnlock from './custom-fields/FeatureToUnlock';

const reducer = combineReducers([playerLevelSetReducer, rewardableReducer]);
const featuresToUnlockOptions = {
  solitaire_scapes: fromClassNameToOptions('SolitaireScapes::FeaturesToUnlockEnum'),
  solitaire: fromClassNameToOptions('Solitaire::FeaturesToUnlockEnum'),
};

const columns = (rewardItems, featureOptions) => compact([
  {
    Header: () => (
      <Tooltip text="The player's level">
        Number
      </Tooltip>
    ),
    accessor: 'number',
    className: 'w-10',
    Cell: ({ row: { original: { path, dispatch } } }) => (
      <FormGroup name={path}>
        <Field name="number" type="number" onKeyUp={() => dispatch({ type: 'recalculateTotalXp' })} />
      </FormGroup>
    ),
  },
  {
    Header: () => (
      <Tooltip text="Total amount of XP required to unlock a level">
        Total XP
      </Tooltip>
    ),
    accessor: 'totalXp',
    className: 'w-10',
  },
  {
    Header: () => (
      <Tooltip text="XP diff with the previous level">
        XP diff
      </Tooltip>
    ),
    accessor: 'xpToUnlock',
    className: 'w-10',
    Cell: ({ row: { original: { path, dispatch } } }) => (
      <FormGroup name={path}>
        <Field name="xpToUnlock" type="number" onKeyUp={() => dispatch({ type: 'recalculateTotalXp' })} />
      </FormGroup>
    ),
  },
  {
    Header: () => 'Rewards',
    className: 'w-30',
    id: 'rewards',
    Cell: ({ row: { original } }) => (
      <FormGroup name={original.path}>
        <Rewards
          hideQuantityUntilSelected
          hideContent
          dispatch={original.dispatch}
          rewardable={original}
          rewardItems={rewardItems}
        />
      </FormGroup>
    ),
  },
  featureOptions && {
    Header: () => (
      <Tooltip text="Selected features will be unlocked when user reaches the relevant level">
        Feature To Unlock
      </Tooltip>
    ),
    className: 'w-30',
    id: 'featureToUnlock',
    Cell: ({ row: { original } }) => (
      <FeatureToUnlock
        record={original}
        options={featureOptions}
      />
    ),
  },
  {
    Header: '',
    className: 'w-10',
    id: 'actions',
    Cell: ({ row }) => {
      const { dispatch, _uuid } = row.original;
      return (
        <Button
          className="mt-0 mb-0"
          variant="danger"
          icon={faTrashAlt}
          onClick={() => dispatch({ type: 'removeLevel', uuid: _uuid })}
          title="Delete the level"
          size="sm"
        >
          Delete
        </Button>
      );
    },
  },
]);

const prepareLevels = (levels, dispatch) => levels.reduce((acc, level, index) => {
  if (level._destroy) { return acc; }

  return [
    ...acc,
    {
      ...level,
      path: `levelsAttributes[${index}]`,
      dispatch,
      index,
    },
  ];
}, []);

export default function LevelsForm({ data, onSubmit }) {
  const { applicationId, typeName } = useCurrentApplication();
  const featureOptions = featuresToUnlockOptions[typeName];

  const rewardItems = useRewardOptions();
  const memoizedColumns = useMemo(
    () => columns(rewardItems, featureOptions),
    [rewardItems.length],
  );

  return (
    <Form
      initialValues={{ ...data, applicationId }}
      onSubmit={onSubmit}
      reducer={reducer}
      onLoad={({ dispatch }) => dispatch({ type: 'recalculateTotalXp' })}
    >
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Player Level Set`}>
            <FormButtonsGroup cancelButtonPath={Routes.PlayerLevelSets.indexPath({ applicationId })}>
              <DuplicateButton
                routes={Routes.PlayerLevelSets}
                data={data}
                entityName="Player Level Set"
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label required text="Name">
            <Field name="name" />
          </Label>
          <StaticFieldsFormGroup data={data} />

          <PageSection title="Levels" />
          <MassOperations values={values} dispatch={dispatch} />
          <p><ServerError name="levelsAttributes.list" /></p>
          <ReactTable data={prepareLevels(values.levelsAttributes, dispatch)} columns={memoizedColumns} />
          <div className="text-end">
            <Button onClick={() => dispatch({ type: 'addLevel' })}>Add</Button>
          </div>
        </>
      )}
    </Form>
  );
}
