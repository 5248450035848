import React, { useMemo } from 'react';
import { get, remove } from 'lodash';
import { v4 } from 'uuid';

import { ReactTable } from '@pages/common';

import { updateRows } from '../diffRows';
import { DIRECTIONS } from '../constants';
import DiffValue from '../DiffValue';
import versionObject from '../versionObject';

const columnBuilder = [
  { Header: 'Attribute/Property', accessor: 'attribute' },
  { Header: 'Action', accessor: 'action' },
  {
    Header: 'Context',
    accessor: 'context',
  },
  {
    Header: 'State Before',
    accessor: 'before',
    disableSortBy: true,
    colSpan: ({ row }) => (row.original.mergeDiff ? 2 : null),
    Cell: ({ row }) => (
      <DiffValue
        centered={row.original.mergeDiff}
        value={row.original.before}
        onClick={row.original.onClick}
      />
    ),
  },
  {
    Header: 'State After',
    accessor: 'after',
    disableSortBy: true,
    skip: ({ row }) => row.original.mergeDiff,
    Cell: ({ row }) => <DiffValue value={row.original.after} onClick={row.original.onClick} />,
  },
];

const AbExperimentUpdate = ({ showAssociation, versionTree }) => {
  let changesRows = updateRows({ versionTree, showAssociation });
  remove(changesRows, (row) => row.attribute === 'variants');
  if (versionTree.associations.variants) {
    versionTree.associations.variants.forEach((variant) => {
      const context = `${versionObject(variant.version).name} (id: ${variant.version.itemId})`;
      changesRows = changesRows.concat(updateRows({
        context, versionTree: variant, showAssociation, event: variant.version.event,
      }));
    });
  }
  const newStatus = get(versionTree.version.objectChanges, 'status[1]');
  if (newStatus === 'Active' || newStatus === 'Allocation Paused') {
    changesRows.push({
      _uuid: v4(),
      attribute: 'Full entity state at the moment of status change',
      mergeDiff: true,
      onClick: () => showAssociation({
        associationName: 'self',
        direction: DIRECTIONS.AFTER,
        splitView: false,
        versionTree,
      }),
    });
  }
  const columns = useMemo(() => columnBuilder, []);

  return (
    <ReactTable
      columns={columns}
      data={changesRows}
      disableSortBy
    />
  );
};

export default AbExperimentUpdate;
