import React from 'react';
import styled from 'styled-components';

const LegendWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.gray};
  display: flex;
  padding: 5px;
  margin-left: 20px;
`;

export default function Legend() {
  return (
    <LegendWrapper>
      <div className="d-flex me-1">Legend: </div>
      <span className="d-flex">
        <div className="text-success">Created</div>
        <div className="text-warning px-2">Modified</div>
        <div className="text-danger">Destroyed</div>
      </span>
    </LegendWrapper>
  );
}
