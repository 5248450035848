import React from 'react';

import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { useConfirm } from '@hooks';

export default function TableHeader({ deletionUnlocked, setDeletionUnlocked }) {
  const confirm = useConfirm();
  const textValueForMessage = deletionUnlocked ? 'lock' : 'unlock';
  const icon = deletionUnlocked ? faUnlock : faLock;

  const onUnlock = () => {
    confirm
      .showConfirmation({ title: `Confirm to ${textValueForMessage} deletion function` })
      .then(() => setDeletionUnlocked(!deletionUnlocked));
  };

  return (
    <Row className="mb-2 align-items-center text-center">
      <Col xs={4}>LEVEL LAYOUT ID</Col>
      <Col xs={2}>HASH</Col>
      <Col xs={4}>UPLOAD DATE</Col>
      <Col xs={2}>
        <Button variant="default" onClick={onUnlock}>
          <FontAwesomeIcon icon={icon} size="lg" />
        </Button>
      </Col>
    </Row>
  );
}
