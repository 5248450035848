import React, { useState, useEffect } from 'react';
import { Alert, useRouter } from '@tripledotstudios/react-core';
import { Row, Col } from 'react-bootstrap';

import { maxBy } from 'lodash';
import { persistedCollection } from '@services/utils';

import {
  FormButtonsGroup,
  StaticFieldsFormGroup,
  Label,
  Field,
  SelectField,
  ServerError,
} from '@controls/form';

import {
  Form,
  FormGroup,
} from '@hooks';
import { requestWithToSelectOptions } from '@services/toSelectOptions';

import { DuplicateButton, PageHeader, PageSection } from '@pages/common';

import { TileRoutes as Routes } from '@pages/routes';

import IconButton from '@controls/buttons';

import { chapterRangeListReducer, wrapReducer } from '@reducers';

const reducer = wrapReducer(chapterRangeListReducer);

const TileDifficultySchemaSetForm = ({ data, onSubmit }) => {
  const { query: { applicationId } } = useRouter();
  const [difficultySchemaOptions, setDifficultySchemaOptions] = useState([]);
  const reducerPath = 'difficultySchemaAffiliationsAttributes';

  useEffect(() => {
    requestWithToSelectOptions(Routes.DifficultySchemas.indexRequest, applicationId).then(setDifficultySchemaOptions);
  }, []);

  return (
    <Form initialValues={data} onSubmit={onSubmit} reducer={reducer}>
      {({ values: { difficultySchemaAffiliationsAttributes }, dispatch }) => {
        const notDestroyedAffiliations = persistedCollection(difficultySchemaAffiliationsAttributes);
        const maxPosition = (maxBy(notDestroyedAffiliations, 'position') || {}).position || 0;

        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Difficulty Schema Set`}>
              <FormButtonsGroup cancelButtonPath={Routes.DifficultySchemaSets.indexPath({ applicationId })}>
                <DuplicateButton
                  routes={Routes.DifficultySchemaSets}
                  data={data}
                  entityName="Difficulty Schema Set"
                />
              </FormButtonsGroup>
            </PageHeader>

            <Label required text="Name">
              <Field type="text" name="name" />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <PageSection title="DDA Schemas" />
            <FormGroup name="difficultySchemaAffiliationsAttributes">
              <ServerError name="list" as={Alert} />

              {React.Children.toArray(difficultySchemaAffiliationsAttributes.map((affiliationAttributes, index) => {
                if (affiliationAttributes._destroy) return '';

                const {
                  _uuid,
                  position,
                } = affiliationAttributes;

                const isLastAffiliation = position === maxPosition;
                const isFirstAffiliation = position === 0;

                return (
                  <FormGroup name={index}>
                    <Row>
                      <Col xs={1}>
                        <Label text={<>&nbsp;</>} direction="vertical">
                          <p>{affiliationAttributes.position + 1}</p>
                        </Label>
                      </Col>
                      <Col xs={5}>
                        <Label
                          text="Difficulty Schema"
                          direction="vertical"
                          tooltipText={`
                          Select DDA Schema(s) which will be used for Chapter set selected in Game setting and
                          specify range of chapters for which this DDA Schema should be applicable
                        `}
                        >
                          <SelectField
                            name="difficultySchemaId"
                            options={difficultySchemaOptions}
                            quickLinkFn={(id) => Routes.DifficultySchemas.editPath({ applicationId, id })}
                          />
                        </Label>
                      </Col>
                      <Col xs={2}>
                        <Label
                          text="From"
                          direction="vertical"
                          tooltipText={isFirstAffiliation && (
                            'First schema is always configured to be available from Chapter 1'
                          )}
                        >
                          <Field
                            disabled
                            name="fromChapter"
                            type="number"
                          />
                        </Label>
                      </Col>
                      <Col xs={2}>
                        <Label
                          text="To"
                          direction="vertical"
                          tooltipText={isLastAffiliation && (
                            'The last schema is always applicable for all Chapters from "From" until the last'
                          )}
                        >
                          <Field
                            name="toChapter"
                            type="number"
                            onChange={({ target: { value } }) => {
                              dispatch({
                                type: 'toChapterChange', path: reducerPath, value, _uuid,
                              });
                            }}
                            placeholder={isLastAffiliation && 'All next'}
                            disabled={isLastAffiliation}
                          />
                        </Label>
                      </Col>
                      <Col xs={2}>
                        <Label text={<>&nbsp;</>} direction="vertical">
                          <IconButton.Remove
                            minimized
                            disabled={notDestroyedAffiliations.length <= 1}
                            onClick={() => dispatch({ type: 'removeChapterRange', path: reducerPath, _uuid })}
                          />
                        </Label>
                      </Col>
                    </Row>
                  </FormGroup>
                );
              }))}
              <IconButton.New
                onClick={() => dispatch({ type: 'addChapterRange', path: reducerPath })}
                className="float-end"
              >
                Add Difficulty Schema
              </IconButton.New>
            </FormGroup>
          </>
        );
      }}
    </Form>
  );
};

export default TileDifficultySchemaSetForm;
