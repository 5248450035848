import React from 'react';
import { matchPath } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  faDatabase,
  faListOl,
  faCode,
  faCircleUp,
  faWindowMaximize,
  faMountainSun,
  faTrophy,
  faMedal,
  faFireFlameCurved,
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { MyPetCafeRoutes } from '@pages/routes';

import {
  ApplicationItemSet,
  AssetsNavigationItem,
  MissionsNavigationSection,
  PaymentsNavigationSection,
  JourneysNavigationSection,
  RewardsNavigationItem,
  AdsNavigationSection,
} from './common';

export default function MyPetCafe({ routingScope, applicationId, minimized = false }) {
  const theme = useTheme();
  const jsonConfigBaseIndexPath = MyPetCafeRoutes.JsonConfigs.indexPath({ applicationId });

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="my_pet_cafe"
      routingScope={routingScope}
      minimized={minimized}
    >
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />

      <Sidebar.LinkItem
        to={`${routingScope}/my_pet_cafe/app_configs`}
        icon={faDatabase}
        title="Remote DB"
        color={theme.purple}
        minimized={minimized}
      />

      <JourneysNavigationSection
        routingScope={routingScope}
        minimized={minimized}
        sectionName="Seasons"
        eventsName="Seasons"
        levelBanksName="Milestone Banks"
      />

      <Sidebar.SectionItem
        icon={faMountainSun}
        title="Adventures"
        color={theme.green}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={MyPetCafeRoutes.Adventures.Entities.indexPath({ applicationId })}
          title="Adventures"
        />
        <Sidebar.LinkItem
          to={MyPetCafeRoutes.Adventures.EventBanks.indexPath({ applicationId })}
          title="Event banks"
        />
      </Sidebar.SectionItem>

      <Sidebar.SectionItem
        icon={faMedal}
        title="Solo Missions"
        color={theme.newYorkPink}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={MyPetCafeRoutes.SoloMissions.Configs.indexPath({ applicationId })}
          title="Configs"
        />
        <Sidebar.LinkItem
          to={MyPetCafeRoutes.SoloMissions.EventBanks.indexPath({ applicationId })}
          title="Solo Event Banks"
        />
      </Sidebar.SectionItem>

      <Sidebar.SectionItem
        icon={faFireFlameCurved}
        title="Win Streaks"
        color={theme.orange}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={MyPetCafeRoutes.WinStreaks.Configs.indexPath({ applicationId })}
          title="Win Streak Configs"
        />
        <Sidebar.LinkItem
          to={MyPetCafeRoutes.WinStreaks.EventBanks.indexPath({ applicationId })}
          title="Win Streak Banks"
        />
      </Sidebar.SectionItem>

      <Sidebar.SectionItem
        icon={faListOl}
        title="Levels"
        color={theme.cornflowerBlue}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/my_pet_cafe/level_manifest_sets`}
          title="Level Manifests"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/my_pet_cafe/manifest_set_types`}
          title="Level Manifest Types"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/my_pet_cafe/level_layouts/home`}
          title="Level Banks"
          isActive={(_, location) => (
            [
              `${routingScope}/my_pet_cafe/level_layouts`,
            ].some((path) => matchPath({ path }, location.pathname))
          )}
        />
      </Sidebar.SectionItem>

      <Sidebar.SectionItem
        icon={faCode}
        title="JSON Configs"
        color={theme.tulipTree}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${jsonConfigBaseIndexPath}/game_settings`}
          title="Game Settings"
          isActive={(_, location) => (
            [
              `${jsonConfigBaseIndexPath}/new/game_settings`,
              `${jsonConfigBaseIndexPath}/:id/edit/game_settings`,
            ].some((path) => matchPath({ path }, location.pathname))
          )}
        />
        <Sidebar.LinkItem
          to={`${jsonConfigBaseIndexPath}/remote_db`}
          title="Remote DB"
          isActive={(_, location) => (
            [
              `${jsonConfigBaseIndexPath}/new/remote_db`,
              `${jsonConfigBaseIndexPath}/:id/edit/remote_db`,
            ].some((path) => matchPath({ path }, location.pathname))
          )}
        />
        <Sidebar.LinkItem
          to={`${jsonConfigBaseIndexPath}/chapter_configs`}
          title="Chapter Configs"
          isActive={(_, location) => (
            [
              `${jsonConfigBaseIndexPath}/new/chapter_configs`,
              `${jsonConfigBaseIndexPath}/:id/edit/chapter_configs`,
            ].some((path) => matchPath({ path }, location.pathname))
          )}
        />
      </Sidebar.SectionItem>

      <Sidebar.LinkItem
        to={`${routingScope}/my_pet_cafe/bet_up_configurations`}
        icon={faCircleUp}
        title="Bet Up"
        color={theme.conifer}
        minimized={minimized}
      />

      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />

      <MissionsNavigationSection applicationId={applicationId} minimized={minimized} />

      <Sidebar.SectionItem
        icon={faTrophy}
        title="Leaderboards"
        color={theme.orange}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/my_pet_cafe/leaderboards`}
          title="Configs"
        />
      </Sidebar.SectionItem>

      <PaymentsNavigationSection routingScope={routingScope} minimized={minimized} />

      <Sidebar.SectionItem
        icon={faWindowMaximize}
        title="Flexible Popups"
        color={theme.orange}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/flexible_popups/popups`}
          title="Flexible Popups"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/flexible_popups/actions`}
          title="Actions"
        />
      </Sidebar.SectionItem>
      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
