import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Row, Col, Button } from 'react-bootstrap';

import styled from 'styled-components';

import { FormGroup } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import useRewardOptions from '@hooks/useRewardOptions';
import { Field, SelectField } from '@controls/form';
import { DndIcon } from '@pages/common';
import ProductDetails from '@pages/payments/products/Details';

import GameSpecificFields from './GameSpecificFields';

const RemoveSlotButton = styled(Button)`
  height: fit-content;
`;

export default function List({
  values, products, dispatch, variantId, defaultVariant,
}) {
  const productLabels = fromClassNameToOptions('Payments::ProductLabels');
  const { slotsAttributes } = values;
  const productOptions = products.map((item) => ({ value: item.id, label: item.name }));
  const rewardItems = useRewardOptions();

  return slotsAttributes.length ? (
    slotsAttributes.map((slot, index) => (
      !slot._destroy ? (
        <Draggable key={slot._uuid} draggableId={slot._uuid} index={index}>
          {(draggableProvided) => (
            <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
              <FormGroup name={`slotsAttributes.${index}`}>
                <DndIcon {...draggableProvided.dragHandleProps} />
                <Row className="mb-2">
                  <Col xs={1}>
                    <Field name="id" type="hidden" />
                    <Field name="catalogVariantId" type="hidden" />
                    <Field name="position" type="number" readOnly />
                  </Col>
                  <Col xs={3}>
                    <SelectField
                      name="productId"
                      options={productOptions}
                    />
                  </Col>
                  <Col xs={2}>
                    <ProductDetails
                      product={products.find(({ id }) => id === slot.productId)}
                      rewardItems={rewardItems}
                    />
                  </Col>
                  <GameSpecificFields />
                  <Col xs={3}>
                    <SelectField
                      name="label"
                      options={productLabels}
                    />
                  </Col>
                  <Col xs={1}>
                    <RemoveSlotButton
                      variant="danger"
                      onClick={() => dispatch({
                        type: 'remove_slot', position: slot.position, variantId, defaultVariant,
                      })}
                    >
                      Remove
                    </RemoveSlotButton>
                  </Col>
                </Row>
              </FormGroup>
            </div>
          )}
        </Draggable>
      ) : ''
    ))
  ) : '';
}
