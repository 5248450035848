import React, { useState, useCallback } from 'react';
import { Col, Card } from 'react-bootstrap';
import { camelCase } from 'lodash';

import {
  PageHeader, UsageCountLabel, SettingsFieldsSelect, DuplicateButton,
} from '@pages/common';
import RulesForm from '@pages/rules/Form';

import {
  AbTestingContext, Form, useCurrentApplication, useQuery,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import {
  FormButtonsGroup,
  Field,
  Label,
  PriorityField,
  SelectField,
  StaticFieldsFormGroup,
  AvailabilityStateFormGroup,
  LabelsFormGroup,
} from '@controls/form';

import { formResponseHandler } from '@requests/responseHandlers';
import { rulesReducer, combineReducers } from '@reducers';
import { reducer as gameSettingsReducer } from '@components/overridable-settings';

import StartEndFields from '@components/overridable-settings/StartEndFields';

import buildFieldsFromConfig from '@services/settings/buildFieldsFromConfig';
import { GameSettingsRoutes } from '@pages/routes';
import ActivityLogButton from '@root/controls/buttons/ActivityLogButton';

const reducer = combineReducers([gameSettingsReducer, rulesReducer]);

const routeGameType = (gameType) => {
  if (gameType === 'woodoku_saga') return 'discovery';

  return gameType;
};

export default function GameSettingsForm({
  entityType, fieldsFactory, Tabs, data, setData, gameType, onSubmit, isBase,
}) {
  const [focusedField, setFocusedField] = useState('');
  const [contractType, setContractType] = useState('general');

  const { frontendTypeName, currentApplication: { id: applicationId } } = useCurrentApplication();

  const statuses = fromClassNameToOptions('GameSettingsStatuses');
  const responseHandler = formResponseHandler({
    entityName: 'Game Settings',
    actionName: data.id ? 'update' : 'create',
    setData,
  });

  const { _data: { configurations, options } } = data;

  const fieldsFromBackend = useCallback(buildFieldsFromConfig(configurations, options), []);
  const preparedFieldsFactory = { ...fieldsFactory({ applicationId }), ...fieldsFromBackend };

  const { response: fieldsData } = useQuery(
    GameSettingsRoutes.fieldNamesRequest, { gameType: routeGameType(frontendTypeName) },
  );

  const handleSubmit = (values) => onSubmit({ ...values, contractType }).then(responseHandler);

  const isUpdate = Boolean(data.id);
  const action = isUpdate ? 'Edit' : 'New';

  const resource = isBase ? 'Basic Game Settings' : 'Game Settings Override';
  const onFocusedFieldChange = ({ value }) => setFocusedField(value);

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={handleSubmit} reducer={reducer}>
      {({ values, dirty }) => (
        <>
          <PageHeader title={`${action} ${resource}`} userGuideUrlKey="gameSettingsGuideUrl">
            <FormButtonsGroup cancelButtonPath={GameSettingsRoutes.indexPath({ applicationId, gameType })}>
              {isUpdate && (
                <ActivityLogButton
                  className="me-2"
                  applicationId={applicationId}
                  id={data.id}
                  entityType="GameSettings"
                />
              )}
              {!isBase && (
                <DuplicateButton
                  entityName="Game Settings"
                  onSubmit={(value) => GameSettingsRoutes.duplicateRequest({ ...value, applicationId, gameType })}
                  data={data}
                  disabled={dirty}
                  modalType="withPriority"
                />
              )}
            </FormButtonsGroup>
          </PageHeader>

          <AbTestingContext entityType={entityType} entityId={data.id}>
            <Col xs={11}>
              <Label text="Name" fieldSize={8}>
                <Field name="name" />
              </Label>
              <Label text="Status" fieldSize={8}>
                <SelectField name="status" options={statuses} isDisabled={isBase} />
              </Label>
              <Label text="Priority" fieldSize={8}>
                <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} disabled={isBase} />
              </Label>
              <AvailabilityStateFormGroup disabled={isBase} fieldSize={8} />

              <StaticFieldsFormGroup data={data} />

              <UsageCountLabel fieldSize={8} data={data} />

              <LabelsFormGroup fieldSize={8} />
              <StartEndFields isBase={isBase} fieldSize={8} />
              {!isBase && (
                <Label
                  text="Override A/B Experiments"
                  tooltipText="Select the toggle if you want to override A/B experiment and other Overrides
                  with this Override"
                  fieldSize={8}
                >
                  <Field type="checkbox" name="overrideAbExperiments" />
                </Label>
              )}
              <Label text="Notes" fieldSize={8}>
                <Field name="notes" as="textarea" />
              </Label>
            </Col>

            <Card className="mb-3">
              <Card.Body>
                {fieldsData && (
                  <SettingsFieldsSelect
                    archivedFields={fieldsData.archivedFields}
                    fields={fieldsData.fields}
                    localeNamespace="gameSettings"
                    name="focusedField"
                    selectedValue={focusedField}
                    onChange={onFocusedFieldChange}
                    placeholder="Find Setting"
                    includeArchived={false}
                  />
                )}
              </Card.Body>
            </Card>

            <Tabs
              setContractType={setContractType}
              fieldsFactory={preparedFieldsFactory}
              isBase={isBase}
              errors={(data._meta && data._meta.errors) || {}}
              enabledFieldsNames={values.enabledFieldsNames}
              focusedField={camelCase(focusedField)}
            />

            {!isBase && <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} />}
          </AbTestingContext>
        </>
      )}
    </Form>
  );
}
