import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { FormGroup } from '@hooks';
import {
  Label, Field, SelectField, LabelsFormGroup,
} from '@controls/form';
import { PageSection } from '@pages/common';
import { fromClassNamesToOptions } from '@services/enums';
import { OPERATIONS } from '@pages/common/filtering/LabelsSelectFilter';

export default function DefaultFilters({ defaultApplicationId }) {
  const [availabilityStateOptions, statusOptions] = fromClassNamesToOptions([
    'AvailabilityStates',
    'DefaultFilterStatuses',
  ]);

  return (
    <FormGroup name="defaultFiltersAttributes">
      <PageSection title="Default Filters">
        <Row>
          <Col md="6">
            <Label text="Availability State" direction="vertical">
              <SelectField name="availabilityStates" isMulti options={availabilityStateOptions} />
            </Label>
          </Col>
          <Col md="6">
            <Label text="Status" direction="vertical">
              <SelectField name="statuses" isMulti options={statusOptions} />
            </Label>
          </Col>
          <Col md="6" className="ps-0 pt-3">
            <Label text="Created by me">
              <Field name="createdByMe" type="checkbox" className="mt-2" />
            </Label>
          </Col>
          <Col md="6">
            <Row>
              <Col md="3">
                <Label text="Labels" direction="vertical" tooltipText="Applicable only for default app">
                  <SelectField
                    options={OPERATIONS}
                    name="labelOperation"
                    isDisabled={!defaultApplicationId}
                  />
                </Label>
              </Col>
              <Col md>
                <LabelsFormGroup
                  label={null}
                  direction="vertical"
                  defaultApplicationId={defaultApplicationId}
                  disabled={!defaultApplicationId}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </PageSection>
    </FormGroup>
  );
}
