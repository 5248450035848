/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { ExpandIcon } from '@components/merge/shared/icons';
import styled from 'styled-components';

const ItalicText = styled.div`
  display: flex;
  font-style: italic;
`;

export default function CollapsibleDescription({ text, maxCharacters = 100 }) {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const shouldCollapse = !expanded && text && text.length > maxCharacters;
  const displayedText = shouldCollapse ? text.substring(0, maxCharacters) : text;

  return (
    <>
      <ItalicText>{displayedText}</ItalicText>
      {text && text.length > maxCharacters && (
        <ExpandIcon value={expanded} onClick={toggleExpanded} />
      )}
    </>
  );
}
