import React from 'react';
import { PageTitle } from '@pages/common';
import IndexPage from '@pages/named-rule-groups/Index';
import NewPage from '@pages/named-rule-groups/New';
import EditPage from '@pages/named-rule-groups/Edit';
import { NamedRuleGroupsRoutes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function namedRuleGroups() {
  return [
    <Route
      key={NamedRuleGroupsRoutes.indexRawPath}
      path={NamedRuleGroupsRoutes.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Named Rule Groups" />
          <IndexPage />
        </RouteContainer>
      )}
    />,
    <Route
      key={NamedRuleGroupsRoutes.newRawPath}
      path={NamedRuleGroupsRoutes.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Named Rule Groups" action="New" />
          <NewPage />
        </RouteContainer>
      )}
    />,
    <Route
      key={NamedRuleGroupsRoutes.editRawPath}
      path={NamedRuleGroupsRoutes.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Named Rule Groups" action="Edit" />
          <EditPage />
        </RouteContainer>
      )}
    />,
  ];
}
