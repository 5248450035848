import React from 'react';
import { useTheme } from 'styled-components';
import { faRetweet } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';
import { VersionUpdateRoutes } from '@pages/routes';

export default function VersionUpdatesNavigationSection({ applicationId, minimized = false }) {
  const theme = useTheme();

  return (
    <Sidebar.SectionItem icon={faRetweet} title="Version Updates" color={theme.green} minimized={minimized}>
      <Sidebar.LinkItem
        to={VersionUpdateRoutes.ForceUpdates.indexPath({ applicationId })}
        title="Force Updates"
      />
      <Sidebar.LinkItem
        to={VersionUpdateRoutes.AppUpdates.indexPath({ applicationId })}
        title="App Updates"
      />
    </Sidebar.SectionItem>
  );
}
