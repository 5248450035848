import React from 'react';
import { Form } from 'react-bootstrap';

import { ServerError } from '@controls/form';

import defaultRewardItemsConfiguration from '@pages/journeys/shared/DefaultRewardItemsConfigurations';

const valueComponent = ({ data, addData, readOnly }) => {
  const handleValueChange = (event) => {
    const { value } = event.target;

    addData({ value });
  };

  return (
    <tr>
      <td><b>Amount</b></td>
      <td>
        <Form.Control
          type="number"
          value={data.value}
          onChange={handleValueChange}
          disabled={readOnly}
        />
        <ServerError name="value" />
      </td>
    </tr>
  );
};

const rewardItemsConfiguration = [
  ...defaultRewardItemsConfiguration('Medallion'),
  {
    label: 'Coins',
    value: 'Woodoku::Rewards::CoinsReward',
    buildDefaults: () => ({ value: null }),
    component: (data) => valueComponent(data),
  },
  {
    label: 'Single tile',
    value: 'Woodoku::Rewards::SingleTileReward',
    buildDefaults: () => ({ value: null }),
    component: (data) => valueComponent(data),
  },
  {
    label: 'Cannon',
    value: 'Woodoku::Rewards::CannonReward',
    buildDefaults: () => ({ value: null }),
    component: (data) => valueComponent(data),
  },
  {
    label: 'Arrow',
    value: 'Woodoku::Rewards::ArrowReward',
    buildDefaults: () => ({ value: null }),
    component: (data) => valueComponent(data),
  },
];

export default rewardItemsConfiguration;
