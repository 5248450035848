import React, { useState } from 'react';
import {
  Modal, Button, Form, Table,
} from 'react-bootstrap';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { startCase, uniqBy } from 'lodash';

import { useFormGroup, useFormContext } from '@hooks';
import Select from '@controls/Select';
import Error from '@controls/form/Error';

import Th from '@components/merge/shared/Th';
import { RemoveIcon } from '@components/merge/shared/icons';
import { ItemsSelect } from '@components/merge/shared/items/Select';

const totalProbability = (items) => items.reduce((sum, r) => (parseFloat(r.probability) || 0) + sum, 0);
const itemsSchema = Yup.object().shape({
  items: Yup.array()
    .of(
      Yup.object().shape({
        schemaItemId: Yup.number().typeError('must be a number').required('is required'),
        probability: Yup.number().typeError('must be a number').required('is required')
          .positive('should be greater than 0')
          .max(100, 'probability must be less than 100'),
      }),
    )
    .test('schemaItemIds', 'Items must be unique', (values) => uniqBy(values, 'schemaItemId').length === values.length),
});

const probabilitySchema = Yup.object().shape({
  items: Yup.array()
    .test('totalProbability', 'Total probability must be less than or equal to 100', (values) => (
      totalProbability(values) <= 100
    )),
});

export default function ItemsListModal({
  fieldName, values, abilities, items, stopEditing, validateTotalProbability = true,
}) {
  const [data, setData] = useState(values);
  const [errors, setErrors] = useState([]);
  const { setFieldValue } = useFormContext();
  const { generateName } = useFormGroup();

  let formSchema = itemsSchema;
  if (validateTotalProbability) {
    formSchema = formSchema.concat(probabilitySchema);
  }

  const validate = (callback) => {
    formSchema
      .validate({ items: data }, { abortEarly: false })
      .then(({ items: result }) => callback(result))
      .catch((error) => {
        setErrors(error.inner.reduce((memo, err) => ({ ...memo, [err.path]: err.message }), {}));
      });
  };
  const handleChange = (rowIndex, columnId, value) => {
    setData((previousState) => previousState.map((item, index) => (
      (index === rowIndex) ? { ...previousState[rowIndex], [columnId]: value } : item
    )));
  };
  const addRow = () => setData((prev) => [...prev, { _uuid: uuidv4(), probability: 1 }]);
  const removeRow = (uuid) => setData((previousState) => (previousState.filter((item) => item._uuid !== uuid)));
  const onCancel = () => { setData(values); stopEditing(); };
  const onConfirm = () => {
    validate((result) => {
      setFieldValue(generateName(fieldName), result);
      stopEditing();
    });
  };
  const total = totalProbability(data);

  return (
    <Modal
      show
      onHide={stopEditing}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{startCase(fieldName)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errors.items && errors.items.length > 0 && (
          <Error>
            <div className="mb-3">
              {errors.items}
            </div>
          </Error>
        )}

        <Button variant="success" className="mb-2" onClick={addRow}>Add</Button>

        <Table>
          <thead>
            <tr>
              <Th width="30%">Item</Th>
              <Th width="30%">Probability</Th>
              <Th width="30%">Additional</Th>
              <Th />
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item._uuid}>
                <td>
                  <ItemsSelect
                    value={item.schemaItemId}
                    items={items}
                    onChange={(e) => handleChange(index, 'schemaItemId', e.value)}
                  />
                  <Error>{errors[`items[${index}].schemaItemId`]}</Error>
                </td>
                <td>
                  <Form.Control
                    type="number"
                    step="0.1"
                    value={item.probability}
                    onChange={({ target }) => handleChange(index, 'probability', target.value)}
                  />
                  <Error>{errors[`items[${index}].probability`]}</Error>
                </td>
                <td>
                  <Select
                    selectedValue={item.additional}
                    options={abilities}
                    onChange={(e) => handleChange(index, 'additional', e.value)}
                    hasPrepopulatedOption
                  />
                </td>
                <td>
                  <RemoveIcon onClick={() => removeRow(item._uuid)} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <Th />
            <td>
              <b>{total.toFixed(2)}</b>
            </td>
            <Th />
            <Th />
          </tfoot>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
        <Button variant="primary" onClick={onConfirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}
