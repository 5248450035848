import React from 'react';

import { Form } from '@hooks';
import { useRouter } from '@tripledotstudios/react-core';
import { FormButtonsGroup, AvailabilityStateFormGroup, StaticFieldsFormGroup } from '@controls/form';
import { PageHeader } from '@pages/common';
import RulesForm from '@pages/rules/Form';
import { rulesReducer } from '@reducers';
import { VersionUpdateRoutes } from '@pages/routes';

export default function ForceUpdatesForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={rulesReducer}
    >
      {({ values: { ruleSectionsAttributes } }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Force Update`} userGuideUrlKey="versionUpdatesGuideUrl">
            <FormButtonsGroup
              cancelButtonPath={VersionUpdateRoutes.ForceUpdates.indexPath({ applicationId })}
            />
          </PageHeader>
          <AvailabilityStateFormGroup />
          <StaticFieldsFormGroup data={data} />
          <RulesForm ruleSectionsAttributes={ruleSectionsAttributes} />
        </>
      )}
    </Form>
  );
}
