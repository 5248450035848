import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

const PointerListGroupItem = styled(ListGroup.Item)`
  &:hover {
    cursor: pointer;
}`;

export default function ReorderableListFieldOption({
  dndType, isPersisted, isDisabled, value, label, index, onReposition,
}) {
  const drag = useDrag({
    type: dndType,
    item: { index },
    canDrag: !isDisabled,
  })[1];

  const drop = useDrop({
    accept: dndType,
    drop: (item) => {
      const oldIndex = item.index;
      const newIndex = index;

      onReposition(oldIndex, newIndex);
    },
  })[1];

  // eslint-disable-next-line no-nested-ternary
  const variant = isDisabled ? 'dark' : (isPersisted ? 'success' : 'danger');

  return (
    <PointerListGroupItem key={value} ref={(node) => drag(drop(node))} variant={variant}>
      {label}
    </PointerListGroupItem>
  );
}
