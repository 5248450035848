import React from 'react';
import styled from 'styled-components';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import IconButton from '@controls/buttons';
import { MassSelectCheckbox } from '@hooks/useSelectedRows';

const MassSelectContainer = styled.div`
  display: inline-block;
  margin-left: -28px;
  width: 125px;
`;

const SelectionHint = styled.div`
  margin-left: -28px;
`;

export default function MassOperations({
  notDestroyedEntities,
  selectedRows,
  setSelectedRows,
  setBulkDuplicatedRecords,
  setMassOperationsMode,
  onBulkDelete,
  onBulkDuplicate,
  selectedItems,
  disabled,
  BulkCopyModal,
}) {
  return (
    <div className="d-flex flex-column">
      <ButtonToolbar>
        <MassSelectContainer>
          <MassSelectCheckbox
            items={notDestroyedEntities}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </MassSelectContainer>
        {BulkCopyModal && (
          <IconButton.Copy
            onClick={() => setBulkDuplicatedRecords(selectedItems)}
            disabled={selectedItems.length === 0}
          >
            Copy to bank
          </IconButton.Copy>
        )}
        {onBulkDuplicate && (
          <IconButton.Copy
            onClick={() => onBulkDuplicate(selectedItems)}
            disabled={selectedItems.length === 0}
          >
            Duplicate to current bank
          </IconButton.Copy>
        )}
        <IconButton.Delete
          onClick={() => onBulkDelete(selectedItems)}
          disabled={selectedItems.length === 0 || disabled}
          variant="danger"
        >
          Mass Delete
        </IconButton.Delete>
        <IconButton.Cancel
          onClick={() => {
            setSelectedRows({});
            setMassOperationsMode(false);
          }}
          variant="secondary"
        >
          Cancel
        </IconButton.Cancel>
      </ButtonToolbar>
      <SelectionHint className="text-secondary mt-1">
        {`Select entities to ${BulkCopyModal ? 'copy them to another bank or bulk delete' : 'bulk delete them'}`}
      </SelectionHint>
    </div>
  );
}
