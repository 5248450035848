import React from 'react';
import { isEmpty } from 'lodash';
import { Card, Nav, Tab } from 'react-bootstrap';
import { Alert } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import Form from './Form';
import WoodokuFields from './fields/Woodoku';
import WoodokuSagaFields from './fields/WoodokuSaga';
import TileFields from './fields/Tile';
import SolitaireFields from './fields/Solitaire';
import WooAspenFields from './fields/woo/Aspen';
import TaosFields from './fields/Taos';
import HexagoFields from './fields/Hexago';

const SETTINGS = {
  woodoku: WoodokuFields,
  woodoku_saga: WoodokuSagaFields,
  tile: TileFields,
  taos: TaosFields,
  hexago: HexagoFields,
  solitaire: SolitaireFields,
  'woo/aspen': WooAspenFields,
};

const TesterSettingsTabs = ({ editableSettings }) => (
  <Tab.Container defaultActiveKey={editableSettings[0].id}>
    <Nav variant="tabs" className="mb-3">
      {editableSettings.map(({ id, name }) => (
        <Nav.Item>
          <Nav.Link eventKey={id}>
            {name}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>

    <Tab.Content>
      {editableSettings.map(({
        Fields, id, name,
      }) => (
        <Tab.Pane eventKey={id}>
          <Card>
            <Card.Body className="pb-0">
              <Form
                Fields={Fields}
                applicationId={id}
                applicationName={name}
              />
            </Card.Body>
          </Card>
        </Tab.Pane>
      ))}
    </Tab.Content>
  </Tab.Container>
);

const NoApplicationsMessage = () => (
  <Alert variant="warning">
    There are no applications available for configuration
  </Alert>
);

export default function TesterSettingsTabContent() {
  const { applications } = APP_DATA;

  const editableSettings = applications && applications.reduce((memo, application) => {
    const Fields = SETTINGS[application.typeName];
    if (!Fields) { return memo; }

    return [
      ...memo,
      {
        id: application.value,
        name: application.label,
        typeName: application.typeName,
        Fields,
      },
    ];
  }, []);

  return applications && (
    isEmpty(editableSettings) ? <NoApplicationsMessage /> : <TesterSettingsTabs editableSettings={editableSettings} />
  );
}
