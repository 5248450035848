import React, { useState } from 'react';

import { Button, Modal } from 'react-bootstrap';
import { filter } from 'lodash';
import { Label, SelectField } from '@controls/form';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { Form, FormGroup, useCurrentApplication } from '@hooks';
import entityIdentity from '@components/shared/entityIdentity';
import { DailyRewardsRoutes as Routes } from '@pages/routes';

export default function BulkCopyModal({ bankId, handleClose, entities }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentApplication: { id: applicationId } } = useCurrentApplication();

  const [data, setData] = useState({});

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Daily Rewards Bank',
    actionName: 'copying',
    setData,
  });

  const onSubmit = (values) => {
    setIsSubmitting(true);

    const { bankId: targetBankId } = values;

    return Routes.Banks.bulkDuplicateDaysRequest({
      bankId: targetBankId,
      ids: entities.map(({ id }) => id),
      applicationId,
      id: Number(bankId),
    }).then((response) => {
      setIsSubmitting(false);

      return response;
    }).then(responseHandler);
  };

  const bankOptions = async () => {
    const options = await requestWithToSelectOptions(
      Routes.Banks.indexRequest,
      applicationId,
      { withoutVersions: true },
      { unused: true },
    );

    return filter(options, ({ value }) => value !== Number(bankId));
  };
  const onHide = () => {
    setData({});
    handleClose();
  };

  return (
    <Modal show={!!entities} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Copy Daily Rewards Bank(s)</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit} initialValues={data}>
        {() => (
          <FormGroup rootGroup>
            <Modal.Body>
              <Label text="Daily Rewards Bank(s) to copy" labelSize={5} fieldSize={7}>
                <div className="mt-1 mb-2">
                  {(entities || []).map(({ id, name, number }) => (
                    <div key={id} className="mb-1">
                      {entityIdentity({ entity: { number, name }, entityName: 'Daily Rewards Bank' })}
                    </div>
                  ))}
                </div>
              </Label>
              <Label
                text="Daily Rewards Bank"
                tooltipText="Only unused banks are available"
                fieldSize={8}
                labelSize={4}
              >
                <SelectField name="bankId" options={bankOptions} />
              </Label>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" disabled={isSubmitting}>Confirm</Button>
              <Button type="button" variant="secondary" onClick={onHide}>Cancel</Button>
            </Modal.Footer>
          </FormGroup>
        )}
      </Form>
    </Modal>
  );
}
