import React from 'react';

import APP_DATA from '@services/appData';

import { Form } from '@hooks';
import { useRouter } from '@tripledotstudios/react-core';
import {
  FormButtonsGroup, Label, Field, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { TileRoutes } from '@pages/routes';
import { DuplicateButton, PageHeader } from '@pages/common';

import { Button, Row, Col } from 'react-bootstrap';
import formReducer from './formReducer';
import gameModeOptions, {
  generateGoalsName, generateTeleportingName, TILE_TELEPORTING, TILE_GOALS,
} from './gameModeHelper';
import findModeFields from './registry';

const renderModeConfigFields = (type, tooltips) => {
  if (!type) return null;

  const Fields = findModeFields(type);
  return <Fields tooltips={tooltips} />;
};

const { modelsLocales: { tile: { modesConfigs: { tooltips } } } } = APP_DATA;

const Fields = ({
  values, dispatch, data, setFieldValue,
}) => {
  const { query: { applicationId } } = useRouter();
  const {
    tileTypesPercents, type, tapCount, mode, tilesPercent,
  } = values;

  const onGenerateClick = () => {
    switch (type) {
      case TILE_GOALS:
        setFieldValue('name', generateGoalsName(tilesPercent, tileTypesPercents));
        break;
      case TILE_TELEPORTING:
        setFieldValue('name', generateTeleportingName(tapCount, mode));
        break;
      default:
    }
  };
  return (
    <>
      <PageHeader title={`${data.id ? 'Edit' : 'New'} Mode Config`}>
        <FormButtonsGroup cancelButtonPath={TileRoutes.ModesConfigs.indexPath({ applicationId })}>
          <DuplicateButton
            routes={TileRoutes.ModesConfigs}
            data={data}
            entityName="Mode Config"
          />
        </FormButtonsGroup>
      </PageHeader>

      <Field type="hidden" name="id" />
      <Label text="Name">
        <Row>
          <Col md={9}>
            <Field type="text" name="name" />
          </Col>
          <Col>
            <Button type="button" variant="secondary" onClick={onGenerateClick}>Generate Name</Button>
          </Col>
        </Row>
      </Label>

      <StaticFieldsFormGroup data={data} />

      <Label text="Configuration Mode">
        <SelectField
          name="type"
          onChange={(e) => dispatch({ type: 'change_mode_type', modeType: e.value, defaults: data.defaults })}
          options={gameModeOptions}
          isDisabled={data.id}
        />
      </Label>

      {type && renderModeConfigFields(type, tooltips)}
    </>
  );
};

export default function ModesConfigsForm({ data, onSubmit }) {
  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={formReducer}
    >
      {({ values, setFieldValue, dispatch }) => (
        <Fields setFieldValue={setFieldValue} dispatch={dispatch} data={data} values={values} />
      )}
    </Form>
  );
}
