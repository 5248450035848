import React, { useCallback } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { PageHeader } from '@pages/common';
import { Form, FormGroup } from '@hooks';
import {
  FormButtonsGroup,
  Label,
  Field,
  SelectField,
  MultipleFilesField,
  StaticFieldsFormGroup,
} from '@controls/form';
import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { isInUse } from '@pages/common/InUse';
import { MergeRoutes } from '@pages/routes';

export default function CutscenesConfigurationsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(applicationId), []);

  return (
    <Form initialValues={data} onSubmit={onSubmit}>
      {({ values }) => {
        const { attachmentsAttributes, inUse } = values;
        const disabled = isInUse(inUse);

        const action = () => {
          if (!data.id) return 'New';

          return disabled ? 'View' : 'Edit';
        };

        return (
          <>
            <PageHeader title={`${action()} Cutscenes Configuration`}>
              <FormButtonsGroup
                cancelButtonPath={MergeRoutes.CutscenesConfigurations.indexPath({ applicationId })}
              />
            </PageHeader>

            <Label text="Name">
              <Field type="text" name="name" disabled={disabled} />
            </Label>
            <Label text="Description">
              <Field type="text" name="description" disabled={disabled} />
            </Label>
            <Label text="World Schema">
              <SelectField
                name="schemaId"
                options={fetchSchemaOptions}
                isDisabled={data.id}
              />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <FormGroup name="attachmentsAttributes">
              <MultipleFilesField
                name="file"
                label="Upload JSON files"
                accept="application/json"
                attributes={attachmentsAttributes}
                disabled={disabled}
              />
            </FormGroup>
          </>
        );
      }}
    </Form>
  );
}
