import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { AbTestingContext } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Worlds',
    actionName: 'update',
    setData,
  });

  const onSubmit = (values) => (
    MergeRoutes.Worlds.updateRequest({ ...values, applicationId: query.applicationId }).then(responseHandler)
  );

  useEffect(() => {
    MergeRoutes.Worlds.editRequest(query).then((response) => setData(response.data));
  }, []);

  return data && (
    <AbTestingContext entityType="Merge::World" entityId={query.id}>
      <Form
        data={data}
        onSubmit={onSubmit}
        actionName="Update"
      />
    </AbTestingContext>
  );
}
