import React, { useCallback } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { MergeRoutes } from '@pages/routes';

import formReducer from './formReducer';
import TransformsInto from './form/TransformsInto';

export default function UnbubblingConfigurationsForm({ data, onSubmit, actionName }) {
  const { query } = useRouter();
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(query.applicationId), []);

  return (
    <Form initialValues={data} onSubmit={onSubmit} reducer={formReducer}>
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Unbubbling Configuration`}>
            <FormButtonsGroup
              cancelButtonPath={MergeRoutes.UnbubblingConfigurations.indexPath({ applicationId: query.applicationId })}
            />
          </PageHeader>

          <Label text="Name">
            <Field type="text" name="name" />
          </Label>
          <Label text="Description">
            <Field type="text" name="description" />
          </Label>
          <Label text="World Schema">
            <SelectField
              name="schemaId"
              options={fetchSchemaOptions}
              onChange={() => dispatch({ type: 'changeSchemaId' })}
              isDisabled={actionName !== 'Create'}
            />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <hr />

          {values.schemaId && <TransformsInto values={values} schemaRequest={MergeRoutes.WorldSchemas.editRequest} />}
        </>
      )}
    </Form>
  );
}
