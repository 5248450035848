import React, { useRef } from 'react';

import Selecto from 'react-selecto';

import { useOutsideClickListener } from '@hooks';

import BOARD_GRIDS from './board-grids';

const CELL_CLASS = 'board-cell';
const SELECTED_CLASS = 'selected';

const BoardGrid = ({
  values,
  tileTypeOptions,
  onChange,
  readOnly,
  levelIndex,
  onMassSelect,
  gridType,
  occupiedCells,
  massSelectedCellIds,
  errors = {},
}) => {
  const GridComponent = BOARD_GRIDS[gridType];

  const wrapperRef = useRef(null);
  const wrapperId = `board-grid-wrapper-${levelIndex}`;

  useOutsideClickListener(wrapperRef, (event) => {
    const actionsDiv = document.querySelector(`.board-cell-actions[data-level-index='${levelIndex}']`);

    if ((actionsDiv && actionsDiv.contains(event.target)) || document.getElementsByClassName('modal show').length > 0) {
      return null;
    }

    Array.from(wrapperRef.current.getElementsByClassName([CELL_CLASS, SELECTED_CLASS].join(' '))).forEach((node) => (
      node.classList.remove(SELECTED_CLASS)
    ));

    return onMassSelect([]);
  });

  return (
    <div id={wrapperId} ref={wrapperRef} style={{ padding: '1.25em' }}>
      <Selecto
        container={document.getElementById(wrapperId)}
        selectableTargets={[`#${wrapperId} .${CELL_CLASS}`]}
        hitRate={0}
        toggleContinueSelect="shift"
        onSelect={({ added, removed, selected }) => {
          added.forEach(({ classList }) => classList.add(SELECTED_CLASS));
          selected.forEach(({ classList }) => classList.add(SELECTED_CLASS));
          removed.forEach(({ classList }) => classList.remove(SELECTED_CLASS));

          return onMassSelect(selected);
        }}
      />
      <GridComponent
        values={values}
        tileTypeOptions={tileTypeOptions}
        levelIndex={levelIndex}
        onChange={onChange}
        errors={errors}
        readOnly={readOnly}
        occupiedCells={occupiedCells}
        massSelectedCellIds={massSelectedCellIds}
      />
    </div>
  );
};

export default BoardGrid;
