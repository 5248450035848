import React from 'react';

export default function LabelRow({ text }) {
  return (
    <tr>
      <td colSpan="5">
        <b>{text}</b>
      </td>
    </tr>
  );
}
