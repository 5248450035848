import React from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { DailyChallengesRoutes as Routes } from '@pages/routes';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function New() {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse, isLoading } = useQuery(Routes.Events.newRequest);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Daily Challenge Event',
    actionName: 'create',
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.Events.createRequest({ ...values, applicationId }).then(responseHandler);

  if (isLoading) return <Spinner />;

  return response && (
    <Form
      actionName="Create"
      data={response}
      onSubmit={onSubmit}
      entityName="Daily Challenge Event"
    />
  );
}
