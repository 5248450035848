import React from 'react';
import { PageTitle } from '@pages/common';

import ApplicationIndex from '@pages/applications/Index';
import ApplictionEdit from '@pages/applications/Edit';
import ApplicationNew from '@pages/applications/New';
import ApplicationHome from '@pages/applications/Home';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function applications() {
  const scope = '/admin/applications';

  return [
    <Route
      key={scope}
      path={scope}
      element={(
        <RouteContainer>
          <PageTitle text="Applications" />
          <ApplicationIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={`${scope}/new`}
      path={`${scope}/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Application" action="New" />
          <ApplicationNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={`${scope}/:id`}
      path={`${scope}/:applicationId/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Application" action="Edit" />
          <ApplictionEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={`${scope}/:id/home`}
      path={`${scope}/:applicationId/home`}
      fullWidth
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Home" />
          <ApplicationHome />
        </RouteContainer>
      )}
    />,
  ];
}
