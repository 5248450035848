import React from 'react';

import { Badge } from 'react-bootstrap';
import { fromClassNameToOptions } from '@services/enums';

export default function OperationBadge({ operation, className }) {
  const sectionOperations = fromClassNameToOptions('Rules::Sections::Operations');
  const operationLabel = sectionOperations.find(({ value }) => value === operation).label;

  return (
    <Badge bg="secondary" className={className}>
      {operationLabel}
    </Badge>
  );
}
