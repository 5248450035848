import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import styled from 'styled-components';
import { endOfMinute, parseISO } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { format, toZonedTime } from 'date-fns-tz';
import { v4 as uuidv4 } from 'uuid';
import { snakeCase } from '@tripledotstudios/react-core';

const StyledDatePicker = styled.div`
div.react-datepicker__tab-loop {
  div.react-datepicker-popper {
    div {
      div.react-datepicker {
        display: flex;
      }
    }
  }
}`;

registerLocale('en-gb', enGB);

export default function DateTimeInput({
  className = '',
  showTimeSelect = true,
  isClearable = false,
  dateFormat = ['MMMM d, yyyy - HH:mm', 'dd.MM.yyyy', 'dd/MM/yyyy', 'dd-MM-yyyy'],
  showTimeSelectOnly = false,
  showMonthYearPicker = false,
  disabled,
  name,
  onChange,
  value,
  withPortal,
  placeholderText,
  isEndOfRange,
  testId,
}) {
  const uuid = uuidv4();
  const selectedValue = (dateString) => {
    if (!dateString) return null;

    return showTimeSelect ? toZonedTime(parseISO(dateString), 'UTC') : new Date(dateString);
  };

  const convertDate = (date) => {
    if (!date) return null;

    if (showTimeSelect) {
      const timeAdjustedDate = isEndOfRange ? endOfMinute(new Date(date)) : date;
      const formattedDate = `${format(timeAdjustedDate, 'yyyy-MM-dd HH:mm:ss').split(' ').join('T')}Z`;
      const utcDate = parseISO(formattedDate);
      return utcDate.toISOString();
    }

    return date.toDateString();
  };

  return (
    <StyledDatePicker data-testid={testId || snakeCase(name)}>
      <DatePicker
        id={uuid}
        name={name}
        selected={selectedValue(value)}
        className={`form-control ${className}`}
        autoComplete="off"
        dateFormat={dateFormat}
        timeFormat="HH:mm"
        locale="en-gb"
        showTimeSelect={showTimeSelect}
        onChange={(date) => onChange(convertDate(date))}
        disabled={disabled}
        isClearable={isClearable}
        withPortal={withPortal}
        placeholderText={placeholderText}
        showTimeSelectOnly={showTimeSelectOnly}
        showMonthYearPicker={showMonthYearPicker}
      />
    </StyledDatePicker>
  );
}
