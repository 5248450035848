import React from 'react';

import { Card } from 'react-bootstrap';

import { FormGroup, useFormContext, useFormGroup } from '@hooks';
import IconButton from '@controls/buttons';
import { ServerError } from '@controls/form';

import { Alert } from '@pages/common';

import ConfigurationItemFields from './ConfigurationItemFields';

export default function ConfigurationFields({ values, disabled, rewardedVideo = false }) {
  const { dispatch } = useFormContext();
  const { generateName } = useFormGroup();
  const adUnitsConfigurationItemsPath = generateName('itemsAttributes');

  const handleAddEntity = () => {
    dispatch({ actionType: 'addAdUnitsConfigurationItem', adUnitsConfigurationItemsPath });
  };

  const handleDelete = (_e, deleteAt) => {
    dispatch({ actionType: 'removeAdUnitsConfigurationItem', deleteAt, adUnitsConfigurationItemsPath });
  };

  let position = 0;

  return (
    <div>
      <Alert variant="info">
        Available from tripledot.ads.package
        {rewardedVideo ? ' v3.0.0' : ' v2.1.0'}
      </Alert>
      <ServerError name="itemsAttributes.list" />
      {values.itemsAttributes.map((attributes, index) => {
        if (index === 0) position = 0;
        if (attributes._destroy) return null;

        position += 1;
        return (
          <FormGroup name={`itemsAttributes[${index}]`}>
            <Card>
              <Card.Header as="h4">
                Ad unit #
                {position}
              </Card.Header>
              <Card.Body>
                <ConfigurationItemFields
                  key={attributes.id}
                  values={attributes}
                  disabled={disabled}
                  rewardedVideo={rewardedVideo}
                />
                <div className="text-end">
                  <IconButton.Remove
                    onClick={(e) => handleDelete(e, index)}
                    disabled={disabled}
                  />
                </div>
              </Card.Body>
            </Card>
          </FormGroup>
        );
      })}
      <div className="mt-3 text-end">
        <IconButton.New onClick={handleAddEntity} disabled={disabled} title="Add" />
      </div>
    </div>
  );
}
