import React from 'react';
import { isEmpty } from 'lodash';

import APP_DATA from '@services/appData';

const contentDetails = (content, rewardItems) => {
  if (!content || !rewardItems.length) {
    return '';
  }

  const rewardQuantities = content.reduce((memo, { itemId, quantity }) => {
    const correspondingRewardItem = rewardItems.find(({ value }) => value === itemId);

    if (correspondingRewardItem) {
      // eslint-disable-next-line no-param-reassign
      memo[correspondingRewardItem.label] = (memo[correspondingRewardItem.label] || 0) + quantity;
    }

    return memo;
  }, {});

  const humanizedContent = Object.keys(rewardQuantities).map((rewardLabel) => (
    `${rewardLabel}: ${rewardQuantities[rewardLabel]}`
  )).join(', ');

  return (
    <div className="mb-1">
      <b>Content: </b>
      {humanizedContent}
    </div>
  );
};

const priceTypesEnum = APP_DATA.enums['Payments::PriceTypes'];

const resolveProductCurrency = (product) => {
  if (isEmpty(product)) {
    return '';
  }

  return (
    <>
      <b>Currency: </b>
      {product.priceType === priceTypesEnum.PAID ? 'USD' : 'Ad watching'}
    </>
  );
};

export default function Details({ product = {}, rewardItems = [] }) {
  return (
    product ? (
      <>
        <div className="mb-1">
          {resolveProductCurrency(product)}
        </div>
        {product.storeProduct && (
          <div>
            <div className="mb-1">
              <b>Store Product: </b>
              {product.storeProduct.storeId}
            </div>
          </div>
        )}
        {contentDetails(product.content, rewardItems)}
      </>
    ) : ''
  );
}
