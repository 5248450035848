import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Alert } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { AdminUsersRoutes } from '@pages/routes';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { PageHeader } from '@pages/common';
import CopyToClipboardButton from '@controls/CopyToClipboardButton';
import IconButton from '@controls/buttons';
import APP_DATA from '@services/appData';

import reducer from './reducer';
import DefaultFilters from './DefaultFilters';

export default function AdminUsersForm({ data, onSubmit, actionName }) {
  const [generatedInternalToken, setGeneratedInternalToken] = useState('');

  const { applications } = APP_DATA;

  const { id, internalTokenGeneratedAt, uid } = data;

  const generateInternalTokenCallback = () => {
    AdminUsersRoutes.generateInternalTokenRequest({ id })
      .then(({ data: { internalToken: newInternalToken } }) => setGeneratedInternalToken(newInternalToken));
  };

  return (
    <Form initialValues={data} onSubmit={onSubmit} reducer={reducer}>
      {({ dispatch, values: { defaultApplicationId } }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} User`}>
            <FormButtonsGroup cancelButtonPath="/admin/admin_users" />
          </PageHeader>

          <Label text="Email">
            <Field type="text" name="email" disabled={actionName === 'Update'} />
          </Label>

          <Label
            text="Personal Token"
            tooltipText="This token is used for internal API calls"
          >
            {generatedInternalToken ? (
              <>
                <Row>
                  <Col xs={11}>
                    <Alert variant="secondary" className="mb-0">
                      {generatedInternalToken}
                    </Alert>
                  </Col>
                  <Col xs={1} className="align-self-center ps-0">
                    <CopyToClipboardButton text={generatedInternalToken} />
                  </Col>
                </Row>
                <Alert variant="warning" className="mt-3 mb-0">
                  Please save generated token somewhere safe. After page reload it will not be available
                </Alert>
              </>
            ) : (
              <Row>
                <Col xs={6}>
                  <IconButton
                    icon={faSync}
                    onClick={generateInternalTokenCallback}
                    disabled={!id}
                  >
                    Generate New Token
                  </IconButton>
                </Col>
                <Col xs={6} className="align-self-center">
                  {internalTokenGeneratedAt && (
                    <>
                      Last Generated At:
                      <p />
                      {internalTokenGeneratedAt}
                    </>
                  )}
                </Col>
              </Row>
            )}
          </Label>
          <Label text="Default app">
            <SelectField
              name="defaultApplicationId"
              options={applications}
              includeEmpty
              onChange={() => dispatch({ type: 'clearDefaultLabels' })}
            />
          </Label>

          {uid && (
            <Label text="Uid">
              {uid}
            </Label>
          )}

          <StaticFieldsFormGroup data={data} />

          {actionName === 'Create' && (
            <Alert variant="danger">
              <b>
                The user created manually will not have the access to the system without access to login set
                on the Google side
              </b>
            </Alert>
          )}

          <DefaultFilters defaultApplicationId={defaultApplicationId} />
        </>
      )}
    </Form>
  );
}
