import React from 'react';

import APP_DATA from '@services/appData';

import Badge from './Badge';

const { rawEnums: { AvailabilityStates: availabilityStatesEnum } } = APP_DATA;

export default function InLiveBadge({ inLive }) {
  return (
    inLive
      ? <Badge bg="success">{availabilityStatesEnum.LIVE.translation}</Badge>
      : <Badge bg="secondary">{availabilityStatesEnum.TEST.translation}</Badge>
  );
}
