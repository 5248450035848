import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { TesterDeviceRoutes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { query: { id, applicationId } } = useRouter();
  const { response, setResponse } = useQuery(TesterDeviceRoutes.editRequest, { id, applicationId });

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Tester Device',
    actionName: 'update',
    redirectPath: '/admin/tester_devices',
    setData: setResponse,
  });

  const onSubmit = (values) => TesterDeviceRoutes.updateRequest({ ...values, applicationId }).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}

