import React, { useCallback } from 'react';
import { omit } from 'lodash';
import camelcase from 'camelcase';

import { fieldsFactories as gameSettingsFactories } from '@components/game-settings';
import { useFormContext } from '@hooks';

import buildFieldsFromConfig from '@services/settings/buildFieldsFromConfig';
import appConfigsFieldsFactory from '@components/my-pet-cafe/app-configs/FieldsFactory';
import levelManifestSetsFactory from '@components/my-pet-cafe/level-manifest-sets/FieldsFactory';
import mergeWorldsFactory from '@components/merge/worlds/FieldsFactory';
import adsConfigurationsFactory from '@pages/ads/configurations/form/FieldsFactory';

import PropertyModal from './PropertyModal';

const myPetCafeFactories = {
  'MyPetCafe::AppConfig': appConfigsFieldsFactory,
  'MyPetCafe::ManifestSet': levelManifestSetsFactory,
};

const mergeFactories = {
  'Merge::World': mergeWorldsFactory,
};

const factories = {
  'Ads::Configuration': adsConfigurationsFactory,
  ...gameSettingsFactories,
  ...myPetCafeFactories,
  ...mergeFactories,
};

const localeNamespaces = {
  'Ads::Configuration': 'ads.configuration',
};

const buildOptions = (data, disabled, entityType) => {
  const options = {
    name: 'data',
    values: { data },
    disabled,
  };

  const localeNamespace = entityType.endsWith('::GameSettings') ? 'gameSettings' : localeNamespaces[entityType];
  if (localeNamespaces) options.localeNamespace = localeNamespace;

  return options;
};

const maybeModal = (component, propertyName, variantIndex, index, options = {}) => (
  options.preferModalInExperiments ? (
    <PropertyModal
      propertyName={propertyName}
      variantIndex={variantIndex}
      index={index}
    >
      {component}
    </PropertyModal>
  ) : component
);

export default function FieldsFactory({
  entityType, propertyName, data, applicationId, disabled, entityContext, variantIndex, index,
}) {
  const { values } = useFormContext();

  const entityData = values._data.find((entity) => entity.entityType === entityType)?.data;
  const { configurations, options: selectOptions } = entityData || { configurations: [], options: {} };

  const fieldsFromFrontend = factories[entityType](omit({ applicationId, ...entityContext }, '_uuid'));
  const fieldsFromBackend = useCallback(buildFieldsFromConfig(configurations, selectOptions), []);
  const fieldFactories = { ...fieldsFromFrontend, ...fieldsFromBackend };

  const camelizedPropertyName = camelcase(propertyName);
  const factory = fieldFactories[camelizedPropertyName];

  if (!factory) return JSON.stringify(data, null, 2);

  const { Inline, options } = factory;

  return maybeModal(
    <Inline {...buildOptions(data, disabled, entityType)} />,
    propertyName,
    variantIndex,
    index,
    options,
  );
}
