import { pick, reduce, camelCase } from 'lodash';
import APP_DATA from '@services/appData';

const serializableChapterAttributes = [
  '_uuid',
  'chapterBankAffiliationsAttributes',
  'levelsNumber',
  'orderingType',
];

// eslint-disable-next-line import/prefer-default-export
const serializableChapter = (chapter) => pick(chapter, serializableChapterAttributes);
const persistedLevelsAttributes = (applicationName, levelsAttributes) => {
  const currentGameData = APP_DATA.gameSpecificData[camelCase(applicationName)];
  if (!currentGameData) return [];
  const { chapterSets: { persistedLevelAttributes = [] } = {} } = currentGameData;

  return reduce(levelsAttributes, (persisted, current) => {
    if (!current._destroy) {
      return [
        ...persisted,
        reduce(persistedLevelAttributes, (obj, levelAttribute) => ({ [levelAttribute]: current[levelAttribute] }), {}),
      ];
    }
    return persisted;
  }, []);
};

export { serializableChapter, persistedLevelsAttributes };
