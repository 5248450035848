import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, InputGroup } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

export default function ColorInput({
  name, setValue, value, disabled = false,
}) {
  const uuid = uuidv4();

  return (
    <InputGroup>
      <FormControl
        type="color"
        value={`#${value}`}
        onChange={(e) => setValue(e.target.value.substring(1))}
        disabled={disabled}
        className="col-1"
      />

      <FormControl
        value={value}
        onChange={(e) => setValue(e.target.value)}
        id={uuid}
        name={name}
        disabled={disabled}
      />
    </InputGroup>
  );
}

ColorInput.propTypes = {
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
