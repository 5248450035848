import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

export default function Loading() {
  const router = useRouter();

  const onTimeout = () => {
    if (router.query && router.query.redirect_path) {
      router.push(router.query.redirect_path);
    }
  };

  useEffect(() => {
    setTimeout(onTimeout, 250);
  }, []);

  return (
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
}
