import React from 'react';

import { UIKit } from '@tripledotstudios/react-core';
import { useQuery } from '@hooks';
import { UIKitRoutes } from '@pages/routes';

export default function UIKitPage() {
  const { response } = useQuery(UIKitRoutes.indexRequest);

  return response && <UIKit />;
}
