import React, { useMemo, useState } from 'react';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

import { FormGroup, useFormContext } from '@hooks';

import { PageSection } from '@pages/common';

import IconButton from '@controls/buttons';
import BoardGrid from '@pages/journeys/level-banks/configuration-forms/woodoku/level-partials/BoardGrid';
import CellModal from '@pages/journeys/level-banks/configuration-forms/woodoku/level-partials/CellModal';

import { ButtonToolbar } from '@tripledotstudios/react-core';
import { isEqual } from 'lodash';
import {
  hasAsymmetricalLargeTiles,
  boardSize,
  disableBaseTileInModal,
  hasFilledTiles,
  validateCellRef,
  largeTilesParentCells,
  largeTiles,
} from '../utils';
import { BASE_BOARD_GRID_COLUMN_SIZE, ROTATABLE_BOARD_GRID_TYPES } from '../constants';

export default function BoardStart({
  index, boardCellsAttributes, tileTypeOptions, errors, levelsPath, readOnly, boardGridType,
}) {
  const [massSelectedTileIds, setMassSelectedTileIds] = useState([]);
  const [massSelectedCellIds, setMassSelectedCellIds] = useState([]);
  const [disableOverlay, setDisableOverlay] = useState(false);
  const [massBoardCellEdit, setMassBoardCellEdit] = useState(false);

  const { dispatch } = useFormContext();
  const massSelectedMode = massSelectedCellIds.length >= 1;

  const rotateBoard = () => dispatch({
    actionType: 'rotateBoard',
    index,
    levelsPath,
    tileTypeOptions,
  });

  const clearCells = () => dispatch({
    actionType: 'clearCells',
    index,
    cellIndices: massSelectedTileIds,
    levelsPath,
  });

  const onCellsChange = (cellIndices, cellConfig) => (
    cellConfig && validateCellRef(boardCellsAttributes, cellIndices, cellConfig) && dispatch({
      actionType: 'updateCells',
      index,
      cellIndices,
      cellConfig,
      levelsPath,
      tileTypeOptions,
    })
  );

  const onMassSelect = (selectedItems) => {
    setDisableOverlay(false);
    const items = selectedItems.map(({ dataset }) => Number(dataset.cellIndex));
    setMassSelectedCellIds(items);
    const cellsWithLargeTiles = largeTiles(boardCellsAttributes);
    const selectedTilesIds = items.map((value) => (
      largeTilesParentCells(largeTiles(boardCellsAttributes), value)[0]?.position || value
    ));
    if (!isEqual(items, selectedTilesIds) || cellsWithLargeTiles.some((cell) => items.includes(cell.position))) {
      setDisableOverlay(true);
    }
    return (setMassSelectedTileIds(selectedTilesIds));
  };

  const nonRotatable = !ROTATABLE_BOARD_GRID_TYPES.includes(boardGridType);
  const occupiedCells = boardCellsAttributes.filter((cell) => !!cell.baseTileId || !!cell.overlayTileId);
  const boardWidth = useMemo(() => boardSize(boardGridType) / BASE_BOARD_GRID_COLUMN_SIZE, [boardGridType]);
  const rotateBoardDisabled = readOnly || nonRotatable || !hasFilledTiles(boardCellsAttributes)
      || hasAsymmetricalLargeTiles(boardCellsAttributes, tileTypeOptions, index);

  return (
    <PageSection
      title={(
        <>
          Board Start
          <span style={{ 'font-size': '10px' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;(Click to select cell.
            Press &quot;Shift&quot; + click to select multiple cells. Click + drag to select area. Double click
            to edit cell)
          </span>
        </>
      )}
      actions={(
        <ButtonToolbar className="board-cell-actions" data-level-index={index}>
          <IconButton.Copy
            disabled={readOnly || !massSelectedMode}
            onClick={() => setMassBoardCellEdit(true)}
          >
            Override selected
          </IconButton.Copy>
          <IconButton
            icon={faArrowsRotate}
            onClick={rotateBoard}
            disabled={rotateBoardDisabled}
          >
            Rotate 90˚
          </IconButton>
          <IconButton.Cancel
            variant="danger"
            onClick={clearCells}
            disabled={readOnly || !hasFilledTiles(boardCellsAttributes)}
          >
            {massSelectedTileIds.length >= 1 ? 'Clear selected' : 'Clear all'}
          </IconButton.Cancel>
        </ButtonToolbar>
      )}
    >
      <FormGroup name="boardCellsAttributes" className="ml-4">
        <BoardGrid
          values={boardCellsAttributes}
          onChange={(cellIndex, cellConfig) => onCellsChange(cellIndex, cellConfig)}
          tileTypeOptions={tileTypeOptions}
          readOnly={readOnly}
          levelIndex={index}
          onMassSelect={onMassSelect}
          errors={errors.boardCellsAttributes}
          gridType={boardGridType}
          occupiedCells={occupiedCells}
          massSelectedCellIds={massSelectedCellIds}
        />
      </FormGroup>

      {massBoardCellEdit && (
        <CellModal
          show
          disableBase={disableBaseTileInModal(boardCellsAttributes, massSelectedTileIds)}
          title={(
            massSelectedTileIds.length === 1 ? `Edit Grid Cell #${massSelectedTileIds[0] + 1}` : 'Bulk Cells Edit'
          )}
          cells={boardCellsAttributes.filter(({ position }) => massSelectedTileIds.includes(position))}
          indexes={massSelectedTileIds}
          onEditStop={() => setMassBoardCellEdit(false)}
          onSubmit={(_, cellConfig) => onCellsChange(massSelectedTileIds, cellConfig)}
          options={tileTypeOptions}
          massMode={massSelectedTileIds.length >= 2}
          disableOverlay={disableOverlay}
          boardWidth={boardWidth}
          occupiedCells={occupiedCells}
        />
      )}
    </PageSection>
  );
}
