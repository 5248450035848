import appEventsFieldNames from './appEventsFieldNames';

const analyticsDefaultFieldNames = [
  'analyticsBatchingDelay',
  'analyticsBatchingStartupDuration',
  'analyticsBatchingDelayStartup',
  ...appEventsFieldNames,
];

export default analyticsDefaultFieldNames;
