import React from 'react';
import { Helmet } from 'react-helmet';

import { useCurrentApplication, useI18n } from '@hooks';
import { shortCurrentEnvironment } from '@services/currentEnvironment';

export default function PageTitle({
  text, entity, action, translationPath,
}) {
  const { shortApplicationName } = useCurrentApplication();
  const { translate } = useI18n();

  const entityName = translationPath ? translate.fallback(translationPath) : entity;

  const buildTitle = () => {
    const lhs = `[${shortCurrentEnvironment()}] ${shortApplicationName || 'Tripledot Game Server'}`;
    const rhs = text || [action, entityName].join(' ');

    return `${lhs}: ${rhs}`;
  };

  return (
    <Helmet>
      <title>{buildTitle()}</title>
    </Helmet>
  );
}
