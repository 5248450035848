export default function emptyStoryOrders(container) {
  return container.storyOrdersAttributes.filter((order) => (
    order.tasksAttributes.length === 0
    || order.tasksAttributes.filter((task) => (
      !task._destroy && (
        task.targetsAttributes.filter(({ _destroy }) => !_destroy).length === 0
        || task.rewardsAttributes.filter(({ _destroy }) => !_destroy).length === 0
      )
    )).length > 0
  ));
}
