import { memoize } from 'lodash';

import { fieldBuilder } from '@services/fields-factory';

import combineWithSharedFieldsFactory from '@components/game-settings/combineWithSharedFieldsFactory';

import { adRequestDelayFields } from '@components/game-settings/Resource/Shared';

const fieldsFactory = memoize(() => ({
  ...fieldBuilder({ name: 'everythingJson', type: 'text', fieldOptions: { component: 'textarea' } }),
}));

export const popupsOptions = [
  { label: 'IDFA', value: 'IDFA' },
  { label: 'GDPR', value: 'GDPR' },
  { label: 'Push Notifications', value: 'Allow_Notifications' },
  { label: 'Google CMP', value: 'Google_CMP' },
  { label: 'Push notifications retry', value: 'PushNotifications_Retry_OptIn' },
];

export default combineWithSharedFieldsFactory([fieldsFactory, adRequestDelayFields], { popupsOptions });
