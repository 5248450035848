import React from 'react';
import SchemaCell from '@components/merge/shared/SchemaCell';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

export default function ColumnsFactory({
  applicationId, setDuplicatedRecord, onDelete, routePostfix, schemaTypeName,
}) {
  return [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Description', accessor: 'description' },
    {
      Header: schemaTypeName,
      Cell: SchemaCell,
    },
    InLiveColumn,
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({
        row: {
          original: {
            id, name, inUse, schemaId,
          },
        },
      }) => {
        const disabled = isInUse(inUse);

        return (
          <ButtonToolbar>
            <IconButton.Edit
              to={MergeRoutes.ItemConfigurationSets.editPath({ id, applicationId, routePostfix })}
              minimized
            />
            <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name, newSchemaId: schemaId })} minimized />
            <IconButton.Delete onClick={onDelete(id, name)} disabled={disabled} minimized />
          </ButtonToolbar>
        );
      },
    },
  ];
}
