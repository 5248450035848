import { last, cloneDeep } from 'lodash';

import { addUuid } from '@services/recursivelyProcessObject';
import removeEntity from '@services/removeEntity';
import removeNestedIds from '@services/removeNestedIds';
import reorderEntities from '@services/reorderEntities';

export default function reducer(state, action) {
  switch (action.actionType) {
    case 'addEmptyEntity': {
      state.banksAttributes.push(addUuid({ id: null }));
      break;
    }
    case 'duplicateEntity': {
      const { _uuid: originalUuid } = action;

      const { id, _uuid, ...originalBank } = state.banksAttributes.find((bank) => bank._uuid === originalUuid);
      const lastBank = last(state.banksAttributes.filter(({ _destroy }) => !_destroy));

      const duplicatedBank = removeNestedIds(originalBank);

      state.banksAttributes.push(addUuid({
        ...duplicatedBank, _destroy: false, position: lastBank.position + 1,
      }));
      break;
    }
    case 'bulkRemoveEntities': {
      const { banksAttributes } = state;
      const { uuids } = action;

      const banks = cloneDeep(banksAttributes);

      banks.forEach((bank) => {
        if (uuids.includes(bank._uuid)) bank._destroy = true;

        return bank;
      });

      state.banksAttributes = reorderEntities(banks, 'position').result;

      break;
    }
    case 'restoreEntity': {
      const { banksAttributes } = state;
      const { _uuid: uuidToDelete } = action;

      const banks = cloneDeep(banksAttributes);

      delete banks.find(({ _uuid }) => _uuid === uuidToDelete)._destroy;

      state.banksAttributes = reorderEntities(banks, 'position').result;

      break;
    }
    case 'removeEntity': {
      const { _uuid: uuidToDelete } = action;

      return removeEntity(state, 'banksAttributes', uuidToDelete, 'position');
    }
    default: break;
  }

  return state;
}
