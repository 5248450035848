import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { snakeCase } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';
import underscore from 'to-snake-case';

import APP_DATA from '@services/appData';
import { UsedExperiments } from '@controls/form';
import parseEntityUsageData from '@services/ab-testing/parseEntityUsageData';
import ExperimentsDropdown from '@controls/form/ExperimentsDropdown';
import { AbTestingRoutes } from '@pages/routes';

const { enums: { AbExperimentStatuses } } = APP_DATA;

export default function AbTestingSelect(
  {
    applicationId,
    entityId,
    entityType,
    propertyName,
  },
) {
  const [entityUsage, setEntityUsage] = useState({ all: [], used: [] });
  const { push: routePush } = useRouter();

  const handleAdditionOfPropertyToExperiment = (id) => {
    AbTestingRoutes.Experiments.addPropertyToExperimentRequest({
      id,
      applicationId,
      entity: { entityType, entityId, propertyName: snakeCase(propertyName) },
    }).then(() => {
      routePush(AbTestingRoutes.Experiments.editPath({ id, applicationId }));
    });
  };

  useEffect(() => {
    AbTestingRoutes.Experiments.entityUsageRequest({ applicationId, entity: { entityType, entityId } })
      .then((response) => setEntityUsage(response.data));
  }, []);

  const propertyEntityUsage = parseEntityUsageData(entityUsage, AbExperimentStatuses, propertyName);
  const usedProperties = propertyEntityUsage.usedExperiments;

  return (
    <Row>
      <ExperimentsDropdown
        confirmationText={(experimentName) => (
          `The entity will be added to a/b experiment "${experimentName}". Continue?`
        )}
        handleAdd={handleAdditionOfPropertyToExperiment}
        propertyEntityUsage={propertyEntityUsage}
        entityType={entityType}
        entityId={entityId}
        propertyName={underscore(propertyName)}
        disabled={false}
      />
      <Col xs={12}>
        <UsedExperiments usedProperties={usedProperties} />
      </Col>
    </Row>
  );
}
