import { get, set, compact } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';

const transformsIntoPathPath = (action) => compact([...(action.path || []), 'transformsInto']);

export default function formReducer(state, action) {
  if (action.type === 'changeSchemaId') {
    set(state, transformsIntoPathPath(action), []);
  }

  if (action.type === 'addItem') {
    get(state, transformsIntoPathPath(action)).push(addUuid({}));
  }

  if (action.type === 'removeItem') {
    const { index } = action;

    get(state, transformsIntoPathPath(action)).splice(index, 1);
  }

  return state;
}
