import React, { useMemo } from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { PageHeader, Pagination, ReactTable } from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { AdsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';
import ColumnsFactory from './ColumnsFactory';

export default function Index() {
  const {
    indexRequest, deleteRequest, newPath, editPath,
  } = AdsRoutes.Badges.Placements;
  const { response, refetch, isLoading } = useQuery(indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Ad Badge Placement',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => {
    confirm.showConfirmation({
      title: `Ad Badge Placement '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({
    editPath, applicationId, onDelete,
  }), []);

  return (
    <>
      <PageHeader title="Badge Placements" filter={<Filter />}>
        <IconButton.New to={newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? <Spinner /> : (
        response && (
          <>
            <ReactTable
              columns={columns}
              data={response.items}
            />
            <Pagination
              pageCount={response._meta.pages}
            />
          </>
        )
      )}
    </>
  );
}
