import React from 'react';

import {
  GameSettingsTabs,
  adsControlDefaultFieldNames,
  localizationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  analyticsDefaultFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  assetsDefaultFieldNames,
} from '@components/game-settings/Resource';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'backToLobbyTimer',
      'mistakeLimitEnabledByDefault',
      'mistakeLimitCount',
      'mistakeLimitCount6',
      'mistakeLimitCount4',
      'mistakeLimitEnabledForDc',
      'freeHints',
      'startingHintCount',
      'adsBannerPosition',
      'adsInterstitialRequestTimeout',
      'adsInterstitialRetryTimeout',
      'adsRewardedVideoRequestTimeout',
      'adsRewardedVideoRetryTimeout',
      'adShowDelayOnPause',
      'analyticsUseBinaryQueue',
      'boardPrefillEasy',
      'boardPrefillMedium',
      'boardPrefillHard',
      'boardPrefillExpert',
      'journeyPopupTranslationKey',
      'rateUsPopupLevels',
      'adShowBeforeDailyGame',
      'adShowAfterDailyGame',
      'newGamesToShowDcPopup',
      'newGamesToShowJourneyEventPopup',
      'journeyPopupTriggers',
      ...assetsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      ...adPlacementIdsDefaultFieldNames,
      ...adConfigsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: popupsDefaultFieldNames,
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#ads_control',
    title: 'Ads Control',
    fieldsNames: [
      ...adsControlDefaultFieldNames,
      'adsInterstitialFrequencyCap',
    ],
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: analyticsDefaultFieldNames,
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: popupsDefaultArchivedFieldNames,
  },
];

export default function Tabs({
  setContractType, fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      onChange={setContractType}
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
