import { addUuid } from '@services/recursivelyProcessObject';

export default function reducer(state, action) {
  const { type, uuid } = action;

  switch (type) {
    case 'addLevel': {
      state.levelsAttributes.push(
        addUuid({ flag: 1, winStreakCount: 1, xpIncreasePercent: 1 }),
      );

      break;
    }
    case 'removeLevel': {
      const levelToDelete = state.levelsAttributes.find((level) => !level._destroy && level._uuid === uuid);
      levelToDelete._destroy = true;

      break;
    }
    default: break;
  }

  return state;
}
