import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import { SpiderSolitaireRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const [data, setData] = useState(null);
  const { query } = useRouter();
  const { applicationId } = query;

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Player Levels Set',
    actionName: 'create',
    redirectPath: SpiderSolitaireRoutes.PlayerLevelSets.indexPath({ applicationId: query.applicationId }),
    setData,
  });

  useEffect(() => {
    SpiderSolitaireRoutes.PlayerLevelSets.newRequest(query).then((response) => setData(response.data));
  }, []);

  const handleSubmit = (values) => (
    SpiderSolitaireRoutes.PlayerLevelSets.createRequest({ ...values, applicationId }).then(responseHandler)
  );

  return data && <Form initialValues={{ ...data, rewardAffiliationsAttributes: [] }} onSubmit={handleSubmit} />;
}
