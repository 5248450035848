import qs from 'qs';
import underscore from 'to-snake-case';
import pluralize from 'pluralize';

function prepareEntityType(entityType) {
  const entityTypeSegments = entityType.split('::');

  entityTypeSegments[entityTypeSegments.length - 1] = pluralize(entityTypeSegments[entityTypeSegments.length - 1]);

  return entityTypeSegments.map((e) => underscore(e)).join('/');
}

export default function urlBuilder({
  entityId, experimentId, variantId, entityType, routingScope, subVariantIds, variantRuleType,
}) {
  const entityTypeSnake = prepareEntityType(entityType);

  const query = qs.stringify(
    { sub_variant_ids: subVariantIds, variantRuleType },
    { arrayFormat: 'brackets', encode: true },
  );
  return `${routingScope}/${entityTypeSnake}/${entityId}/experiment_scoped/${experimentId}?${query}#${variantId}`;
}
