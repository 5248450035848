import React from 'react';

import { Label, Field } from '@controls/form';

export default function DailyChallengeServingSettingsFields() {
  return (
    <Label
      text="Serve daily challenges after end for"
      tooltipText="Select number of month during which client should still receive DC after it finished"
    >
      <Field type="number" name="serveDcAfterEndFor" appendText="Months" />
    </Label>
  );
}
