import React from 'react';

import MultilineContent from '@controls/MultilineContent';
import CellWithDiff from './CellWithDiff';

export default function CellContent({
  newValue,
  oldValue,
  valueWrapper,
  isNewRecord,
}) {
  return (
    isNewRecord ? (
      <MultilineContent value={newValue} wrapper={valueWrapper} />
    ) : (
      <CellWithDiff
        newValue={newValue}
        oldValue={oldValue}
        valueWrapper={valueWrapper}
      />
    )
  );
}
