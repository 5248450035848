import React, { useEffect, useState } from 'react';
import { Alert, useRouter } from '@tripledotstudios/react-core';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { persistedCollection } from '@services/utils';

import {
  FormButtonsGroup,
  StaticFieldsFormGroup,
  Label,
  Field,
  ServerError,
  LabelsFormGroup,
  localizedLabelFactory,
} from '@controls/form';

import {
  Form,
  FormGroup,
  useI18n,
} from '@hooks';

import {
  PageHeader, PageSection, FaButton, DuplicateButton,
} from '@pages/common';

import { WooAspenRoutes as Routes } from '@pages/routes';
import IconButton from '@controls/buttons';
import { combineReducers, popUpsReducer } from '@reducers';

import { requestWithToSelectOptions } from '@root/services/toSelectOptions';
import moveItemsReducer from '@root/reducers/moveItemsReducer';
import ConfigRewards from './ConfigRewards';
import { REWARDABLE_LIVE_OPS_TOKEN_CLASS } from './constants';

const localeNamespace = 'woo.aspen.playOnPopUp';
const LocalizedLabel = localizedLabelFactory(localeNamespace);

export default function WooAspenPlayOnPopUpForm({ data, onSubmit }) {
  const [liveOpsTokensOptions, setLiveOpsTokensOptions] = useState([]);
  const { query: { applicationId } } = useRouter();
  const { translate } = useI18n();
  const locale = translate.fallback(localeNamespace);
  useEffect(() => {
    requestWithToSelectOptions(
      Routes.LiveOpsTokens.indexRequest,
      applicationId,
      { includeInUse: false },
      {
        toSelectOptionsFn: ({ data: { items } }) => (items.map(({ id, name }) => ({
          value: `${REWARDABLE_LIVE_OPS_TOKEN_CLASS}:${id}`,
          label: name,
          rewardId: id,
          rewardType: REWARDABLE_LIVE_OPS_TOKEN_CLASS,
        }
        ))),
      }).then(setLiveOpsTokensOptions);
  }, []);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([popUpsReducer, moveItemsReducer])}
    >
      {({ values: { configsAttributes }, dispatch, meta }) => {
        const notDestroyedConfigs = persistedCollection(configsAttributes);
        const configsCount = configsAttributes.length;

        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} ${locale.name}`}>
              <FormButtonsGroup cancelButtonPath={Routes.PlayOnPopUps.indexPath({ applicationId })}>
                <DuplicateButton
                  routes={Routes.PlayOnPopUps}
                  data={data}
                  entityName={locale.name}
                />
              </FormButtonsGroup>
            </PageHeader>

            <Label required text="Name">
              <Field type="text" name="name" />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <LabelsFormGroup disabled={false} />

            <PageSection title="Pop-ups order" />
            <FormGroup name="configsAttributes">
              <ServerError name="list" as={Alert} />

              {React.Children.toArray(configsAttributes.map((configAttributes, index) => {
                if (configAttributes._destroy) return '';

                const { _uuid, position } = configAttributes;

                return (
                  <div className="d-flex flex-row">
                    <div className="d-flex flex-column flex-grow-0">
                      <FaButton
                        onClick={() => dispatch({
                          type: 'moveUp', index, meta, itemsNames: ['configsAttributes'],
                        })}
                        disabled={index === 0}
                      >
                        <FontAwesomeIcon icon={faChevronUp} />
                      </FaButton>
                      <FaButton
                        className="mt-1"
                        onClick={() => dispatch({
                          type: 'moveDown', index, meta, itemsNames: ['configsAttributes'],
                        })}
                        disabled={index === configsCount - 1}
                      >
                        <FontAwesomeIcon icon={faChevronDown} />
                      </FaButton>
                    </div>
                    <div className="flex-grow-1">
                      <PageSection
                        size="lg"
                        title={`Pop-up ${position + 1}`}
                        actions={(
                          <IconButton.Remove
                            disabled={notDestroyedConfigs.length <= 1}
                            onClick={() => dispatch({ type: 'removePopUp', _uuid })}
                          >
                            {`Remove Pop-up ${position + 1}`}
                          </IconButton.Remove>
                        )}
                      >
                        <FormGroup name={index}>
                          <Row>
                            <LocalizedLabel
                              name="configs.abortSequence"
                              direction="horizontal"
                            >
                              <Field
                                name="abortSequence"
                                type="checkbox"
                              />
                            </LocalizedLabel>
                          </Row>
                          <Row>
                            <LocalizedLabel
                              name="configs.minRewardsToShow"
                              direction="horizontal"
                            >
                              <Field
                                name="minRewardsToShow"
                                type="number"
                              />
                            </LocalizedLabel>
                          </Row>
                          <PageSection
                            title={locale.configs.rewards.label}
                            size="md"
                            tooltip={locale.configs.rewards.tooltip}
                          />
                          <ConfigRewards
                            entity={configAttributes}
                            dispatch={dispatch}
                            liveOpsTokensOptions={liveOpsTokensOptions}
                          />
                        </FormGroup>
                      </PageSection>
                    </div>
                  </div>
                );
              }))}
            </FormGroup>
            <IconButton.New
              onClick={() => { dispatch({ type: 'addPopUp' }); }}
            >
              Add Pop-up
            </IconButton.New>
          </>
        );
      }}
    </Form>
  );
}
