import React from 'react';

import APP_DATA from '@root/services/appData';
import { Col } from 'react-bootstrap';
import { FormGroup } from '@hooks';
import FieldWithText from './FieldWithText';
import TierParameters from './TierParameters';

export default function TierTargetTemplates({
  tierTargets,
  allocationType,
  allocationTypes,
  valuesType,
  disabled,
}) {
  const step = valuesType === APP_DATA.enums['Missions::MissionTypeValuesTypes'].INTEGER ? 1 : 0.01;

  return (
    <>
      {tierTargets && tierTargets.map((template, index) => (
        <FormGroup name={`tierTargetsAttributes[${index}]`} key={template._uuid}>
          <Col>
            <span className="fw-bold p-2">{`Tier ${index + 1}`}</span>
            <FieldWithText
              name="from"
              text={allocationType === allocationTypes.EXACT_VALUE ? 'Value' : 'From'}
              disabled={disabled}
              valuesType={valuesType}
              step={step}
            />
            {allocationType === allocationTypes.EXACT_RANGE && (
              <FieldWithText
                name="to"
                text="To"
                disabled={disabled}
                valuesType={valuesType}
                step={step}
              />
            )}
            <TierParameters tierParametersAttributes={template.tierParametersAttributes} disabled={disabled} />
          </Col>
        </FormGroup>
      ))}
      {tierTargets && allocationType === allocationTypes.RANGE && (
        <Col>
          <FieldWithText className="mt-4" name="value" text="To" disabled={disabled} step={step} />
        </Col>
      )}
    </>
  );
}
