import {
  last, cloneDeep, isPlainObject, get, sortBy,
} from 'lodash';

import { addUuid } from '@services/recursivelyProcessObject';
import removeEntity from '@services/removeEntity';
import reorderEntities from '@services/reorderEntities';
import reorderMeta from '@services/reorderMeta';
import removeNestedIds from '@services/removeNestedIds';

export default function reducer(state, action) {
  switch (action.actionType) {
    case 'addEmptyEntity': {
      const { daysAttributes } = state;

      const lastEntity = last(daysAttributes.filter(({ _destroy }) => !_destroy));
      const newDay = addUuid({
        id: null,
        number: lastEntity ? lastEntity.number + 1 : 1,
        rewardAffiliationsAttributes: [],
        _destroy: false,
      });

      daysAttributes.push(newDay);

      break;
    }
    case 'moveEntity': {
      const { daysAttributes } = state;
      const {
        sourceIndex,
        destinationIndex,
        meta,
        metaErrorsPath,
      } = action;

      const days = cloneDeep(daysAttributes);

      if (daysAttributes.length <= 1) return state;

      const { result, changeSet: [fromIndices, toIndices] } = reorderEntities(
        days, 'number', sourceIndex, destinationIndex,
      );

      state.daysAttributes = result;

      if (meta.errors && isPlainObject(get(meta, metaErrorsPath))) {
        meta.errors = { ...meta.errors, ...reorderMeta(meta, fromIndices, toIndices, metaErrorsPath).errors };
      }

      break;
    }

    case 'applyPositions': {
      const { daysAttributes } = state;
      const { uuidToPositionMapping } = action;

      daysAttributes.forEach((day) => {
        day.number = uuidToPositionMapping[day._uuid];

        return day;
      });

      state.daysAttributes = sortBy(daysAttributes, 'number');

      break;
    }
    case 'duplicateEntity': {
      const { _uuid: originalUuid } = action;

      const { id, _uuid, ...sourceDay } = state.daysAttributes.find((day) => day._uuid === originalUuid);
      const lastDay = last(state.daysAttributes.filter(({ _destroy }) => !_destroy));

      state.daysAttributes.push(addUuid({
        ...removeNestedIds(sourceDay), _destroy: false, number: lastDay.number + 1,
      }));
      break;
    }
    case 'bulkRemoveEntities': {
      const { daysAttributes } = state;
      const { uuids } = action;

      const days = cloneDeep(daysAttributes);

      days.forEach((day) => {
        if (uuids.includes(day._uuid)) day._destroy = true;
      });

      state.daysAttributes = reorderEntities(days, 'number').result;

      break;
    }
    case 'restoreEntity': {
      const { daysAttributes } = state;
      const { _uuid: uuidToDelete } = action;

      const days = cloneDeep(daysAttributes);

      delete days.find(({ _uuid }) => _uuid === uuidToDelete)._destroy;

      state.daysAttributes = reorderEntities(days, 'number').result;

      break;
    }
    case 'removeEntity': {
      const { _uuid: uuidToDelete } = action;

      return removeEntity(state, 'daysAttributes', uuidToDelete, 'number');
    }
    case 'bulkDuplicate': {
      const { daysAttributes } = state;
      const { entities } = action;

      const days = cloneDeep(daysAttributes);

      entities.forEach(({ _uuid: uuid }) => {
        const { id, _uuid, ...sourceDay } = days.find((day) => day._uuid === uuid);
        const lastDay = last(days.filter(({ _destroy }) => !_destroy));

        days.push(addUuid({
          ...removeNestedIds(sourceDay), _destroy: false, number: lastDay.number + 1,
        }));
      });

      state.daysAttributes = reorderEntities(days, 'number').result;

      break;
    }
    default: break;
  }

  return state;
}
