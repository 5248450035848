import React from 'react';

import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { useFormContext, FormGroup } from '@hooks';
import IconButton from '@controls/buttons';

import BaseRule from './rules/BaseRule';

export default function Rules({ path = '', rulesAttributes, disabled }) {
  const { dispatch } = useFormContext();

  const onAddRule = () => dispatch({ actionType: 'addClientRule', path });

  return (
    <>
      {rulesAttributes && rulesAttributes.map((rule, index) => (
        <FormGroup name={`rulesAttributes[${index}]`}>
          <BaseRule
            key={rule._uuid}
            index={index}
            rule={rule}
            path={path}
            disabled={disabled}
          />
        </FormGroup>
      ))}
      <IconButton
        variant="success"
        icon={faFlag}
        onClick={onAddRule}
      >
        Add Rule
      </IconButton>
    </>
  );
}
