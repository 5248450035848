import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useCurrentApplication } from '@hooks';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import { FilesRoutes } from '@pages/routes';

import Form from './Form';

export default function Resource({
  fetchRequest,
  saveRequest,
  actionName,
}) {
  const { applicationKey: gameType } = useCurrentApplication();
  const { query: { applicationId } } = useRouter();
  const { response, setResponse } = useQuery(fetchRequest);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'File',
    actionName,
    redirectPath: FilesRoutes.indexPath({ applicationId, gameType }),
    setData: setResponse,
  });

  const onSubmit = (values) => saveRequest({ ...values, applicationId }).then(responseHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
    />
  );
}
