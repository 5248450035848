import React from 'react';

import {
  FormButtonsGroup,
  StaticFieldsFormGroup,
  Field,
  SelectField,
  FileField,
  localizedLabelFactory,
  Label,
} from '@controls/form';
import { Form, useCurrentApplication } from '@hooks';
import { useRouter } from '@tripledotstudios/react-core';
import { PageHeader } from '@pages/common';
import { FilesRoutes } from '@pages/routes';
import { CopyToClipboardAddon } from '@controls/CopyToClipboardButton.jsx';
import { isInUse } from '@pages/common/InUse';
import { InputGroup } from 'react-bootstrap';

const LocalizedLabel = localizedLabelFactory('woodoku.gameSettingsFiles');

export default function FilesForm({ data, onSubmit }) {
  const { inUse } = data;

  const { query: { applicationId } } = useRouter();
  const { applicationKey: gameType } = useCurrentApplication();
  const disabled = isInUse(inUse);

  return (
    <Form initialValues={data} onSubmit={onSubmit}>
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} File`}>
            <FormButtonsGroup
              cancelButtonPath={FilesRoutes.indexPath({ applicationId, gameType })}
            />
          </PageHeader>

          <LocalizedLabel name="name">
            <Field type="text" name="name" disabled={disabled} />
          </LocalizedLabel>

          <LocalizedLabel name="fileType">
            <SelectField
              name="fileType"
              options={values.typeOptions}
              isDisabled={data.id}
            />
          </LocalizedLabel>

          <LocalizedLabel name="file">
            <FileField
              name="file"
              downloadUrl={values.downloadUrl}
              accept="text/csv, .json"
              disabled={disabled}
              outputFilename={values.filename}
              fullError
            />
          </LocalizedLabel>

          <StaticFieldsFormGroup data={data} />

          {data.id && (
            <Label text="CDN Link">
              <InputGroup>
                <Field name="fileUrl" disabled />
                <CopyToClipboardAddon text={data.fileUrl} />
              </InputGroup>
            </Label>
          )}
        </>
      )}
    </Form>
  );
}
