import React, { useState } from 'react';
import {
  isEmpty,
  isEqual,
  map,
  omit,
} from 'lodash';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import APP_DATA from '@services/appData';

import VersionContent from './VersionContent';
import AbExperimentVersion from './ab-experiments/Index';
import OverridableSettingsVersion from './overridable-settings/Index';
import GeneralVersion from './general/Index';
import Legend from './Legend';

const journeyTypes = APP_DATA.enums['Journeys::EventTypes'];

const versionComponent = (applications, version, showAssociation) => {
  const props = {
    applications,
    showAssociation,
    version,
    key: version.id,
  };
  if (version.itemType.endsWith('::GameSettings')) {
    return <OverridableSettingsVersion entity="Game Settings" {...props} />;
  }

  switch (version.itemType) {
    case 'Ads::Configuration':
      return <OverridableSettingsVersion entity="Ads Configuration" {...props} />;
    case 'AbTesting::Experiment':
      return <AbExperimentVersion {...props} />;
    case 'Journeys::Event':
      if (version.object && version.object.eventType === journeyTypes.ACTIVATION_EVENT) {
        return <GeneralVersion {...props} entity="Activation Journey" />;
      }

      return <GeneralVersion {...props} />;
    default: return <GeneralVersion {...props} />;
  }
};

const transformVersion = (version) => {
  const rules = version.rules
    && map(map(version.rules, 'rules'), (rulesList) => map(rulesList, (r) => omit(r, '_uuid')));
  const { objectChanges } = version;

  if (rules && !isEqual(rules[0], rules[1])) {
    objectChanges.rules = rules;
  }
  return { ...version, objectChanges };
};

const RowsWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: 1.5em;
  }
`;

export default function Versions({ versions, applications }) {
  const [associationModalData, setAssociationModalData] = useState({});

  return (
    <>
      <RowsWrapper>
        {versions.map((version) => versionComponent(
          applications,
          transformVersion(version),
          setAssociationModalData,
        ))}
      </RowsWrapper>

      <Modal
        show={!isEmpty(associationModalData)}
        onHide={() => setAssociationModalData({})}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Diff</Modal.Title>
          {!associationModalData.disableHighlight && <Legend />}
        </Modal.Header>
        <Modal.Body>
          {!isEmpty(associationModalData) && <VersionContent {...associationModalData} />}
        </Modal.Body>
      </Modal>
    </>
  );
}
