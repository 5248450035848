import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { MergeRoutes } from '@pages/routes';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function Edit() {
  const { query: { applicationId } } = useRouter();
  const { response, setResponse } = useQuery(MergeRoutes.CutscenesConfigurations.editRequest);

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Cutscenes Configuration',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    MergeRoutes.CutscenesConfigurations
      .updateRequest({ ...values, applicationId })
      .then(responseHandler)
  );

  return response && <Form data={response} onSubmit={onSubmit} />;
}
