import React from 'react';
import { Spinner } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';
import {
  FormGroup, useFormContext, useFormGroup, useQuery, useConfirm,
} from '@hooks';
import { SelectWithAction } from '@controls/form';
import { PageSection } from '@pages/common';
import { AdsRoutes } from '@pages/routes';

import IconButton from '@controls/buttons';
import ConfigurationFields from './ConfigurationFields';
import { indexedPlacements, placementsToAddOptions } from '../utils';

export default function Fields({ values, disabled }) {
  const { dispatch } = useFormContext();
  const { generateName } = useFormGroup();
  const confirm = useConfirm();
  const overridesPath = generateName('overridesAttributes');

  const { response: placementsResponse, isLoading: placementsAreLoading } = useQuery(
    AdsRoutes.Interstitials.Placements.indexRequest, { withoutPagination: true },
  );

  const addOverride = (selectedPlacementId) => {
    dispatch({ type: 'addOverride', overridesPath, placementId: selectedPlacementId });
  };

  const removeOverride = (placementIndex) => {
    confirm.showConfirmation({
      title: 'Impression Restrictions Override will be removed. Continue?',
    }).then(() => dispatch({ type: 'removeOverride', overridesPath, placementIndex }));
  };

  const placements = indexedPlacements(placementsResponse);
  const {
    modelsLocales: { ads: { configuration: { impressionRestrictionsConfigurationAttributes: locale } } },
  } = APP_DATA;

  return placementsAreLoading ? <Spinner /> : (
    <>
      <ConfigurationFields
        disabled={disabled}
        dispatch={dispatch}
        values={values}
      />

      <PageSection title={locale.overridesAttributes.label} tooltip={locale.overridesAttributes.tooltip} />

      {values.overridesAttributes.map((overrideAttributes, index) => (
        !overrideAttributes._destroy && (
          <PageSection
            title={placements[overrideAttributes.placementId].name}
            actions={<IconButton.Remove size="sm" onClick={() => removeOverride(index)} disabled={disabled} />}
            key={overrideAttributes._uuid}
          >
            <FormGroup name={`overridesAttributes[${index}]`}>
              <ConfigurationFields
                disabled={disabled}
                dispatch={dispatch}
                values={overrideAttributes}
              />
            </FormGroup>
          </PageSection>
        )
      ))}

      <SelectWithAction
        selectOptions={placementsToAddOptions(values, placementsResponse)}
        buttonOnClick={addOverride}
        buttonTitle="Add Override"
        cassName="mb-3"
        disabled={disabled}
      />
    </>
  );
}
