import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { get } from 'lodash';
import { Select, useFlashMessages } from '@tripledotstudios/react-core';

import { useCurrentApplication, useQuery } from '@hooks';
import { generateChapterBasedRoutes } from '@root/pages/routes';

export default function BulkDuplicateModal({ chapterSetId, chapters, handleClose }) {
  const { success: flashSuccess, error: flashError } = useFlashMessages();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedChapterSet, setSelectedChapterSet] = useState(null);
  const show = chapters?.length > 0;
  const { typeName: appType, applicationId } = useCurrentApplication();
  const ChapterSetRoutes = generateChapterBasedRoutes(appType).ChapterSets;

  const { response: options, refetch, isLoading } = useQuery(
    ChapterSetRoutes.copyToChapterSetOptionsRequest,
    {},
    (response) => response?.data?.reduce((acc, item) => {
      if (item.value !== chapterSetId) {
        acc.push(item);
      }

      return acc;
    }, []),
  );

  useEffect(() => {
    if (show) {
      refetch();
    }
  }, [show]);

  const onSubmit = () => {
    setIsSubmitting(true);

    ChapterSetRoutes.bulkDuplicateChaptersRequest({
      applicationId,
      id: selectedChapterSet.value,
      ids: chapters.map((chapter) => chapter.id),
    }).then((response) => {
      const errors = get(response, 'data._meta.errors.flash');
      if (errors) {
        errors.forEach(flashError);
      } else {
        flashSuccess('Chapters were successfully copied');
      }

      handleClose();
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  const disabled = isLoading || isSubmitting;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Copy Chapter(s) to Chapter Set</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>Chapters to Copy:</b>
        <div className="mt-1 mb-2">
          {(chapters || []).map((chapter) => (
            <div key={chapter.id} className="mb-1">
              {chapter.name || `Chapter #${chapter.position}`}
            </div>
          ))}
        </div>
        <Select
          label="Select Chapter Set"
          isDisabled={disabled}
          options={options || []}
          value={selectedChapterSet}
          onChange={setSelectedChapterSet}
        />
        <span className="small ps-1 text-secondary">
          Dropdown includes only Chapter sets with Availability &apos;Test&apos;
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" onClick={onSubmit} disabled={disabled}>Confirm</Button>
        <Button type="button" variant="secondary" disabled={disabled} onClick={handleClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}
