import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader } from '@pages/common';
import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { MergeRoutes } from '@pages/routes';

import formReducer from './formReducer';

export default function InventoryConfigurationsForm({ data, onSubmit, actionName }) {
  const [postcardsOptions, setPostcardsOptions] = useState([]);

  const { query } = useRouter();
  const freeCurrencies = fromClassNameToOptions('Merge::FreeCurrencies');
  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(query.applicationId), []);

  const fetchPostcardsOptions = async (schemaId) => {
    if (!schemaId) return setPostcardsOptions([]);

    const response = await MergeRoutes.WorldSchemas.editRequest({ id: schemaId, applicationId: query.applicationId });
    const options = response.data.postcards.flatMap((postcard) => (
      postcard.storyOrders.map((order) => (
        {
          value: order.id,
          label: order.internalId,
        }
      ))
    ));

    return setPostcardsOptions(options);
  };

  useEffect(() => {
    (async () => fetchPostcardsOptions(data.schemaId))();
  }, []);

  return (
    <Form initialValues={data} onSubmit={onSubmit} reducer={formReducer}>
      {({ dispatch }) => {
        const onSchemaChange = ({ value }) => {
          dispatch({ type: 'changeSchemaId' });
          fetchPostcardsOptions(value);
        };

        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Inventory Configuration`}>
              <FormButtonsGroup
                cancelButtonPath={MergeRoutes.InventoryConfigurations.indexPath({ applicationId: query.applicationId })}
              />
            </PageHeader>

            <Label text="Name">
              <Field type="text" name="name" />
            </Label>
            <Label text="Description">
              <Field type="text" name="description" />
            </Label>
            <Label text="World Schema">
              <SelectField
                name="schemaId"
                onChange={(e) => onSchemaChange(e)}
                options={fetchSchemaOptions}
                isDisabled={actionName !== 'Create'}
              />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <hr />

            <Label text="Unlocking Story Order Id">
              <SelectField name="unlockingSchemaStoryOrderId" options={postcardsOptions} />
            </Label>

            <Label text="Number of Free Slot">
              <Field type="number" name="numberOfFreeSlots" />
            </Label>

            <Label text="Midpoint">
              <Field type="number" name="midpoint" />
            </Label>

            <Label text="Max Cost">
              <Field type="number" name="maxCost" />
            </Label>

            <Label text="Steepness">
              <Field type="number" name="steepness" />
            </Label>

            <Label text="Currency">
              <SelectField options={freeCurrencies} name="currency" />
            </Label>
          </>
        );
      }}
    </Form>
  );
}
