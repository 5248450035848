import { moveUp, moveDown } from '@services/reorder';

export default function moveItemsReducer(state, action) {
  switch (action.type) {
    case 'moveDown': {
      const { itemsNames } = action;
      moveDown(state, itemsNames, action);

      break;
    }
    case 'moveUp': {
      const { itemsNames } = action;
      moveUp(state, itemsNames, action);

      break;
    }
    default: break;
  }

  return state;
}
