import React from 'react';
import styled, { useTheme } from 'styled-components';
import { TdsLogo } from '@tripledotstudios/react-core';

import { components } from 'react-select';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';

import { ApplicationIcon } from '@components/shared';
import Select from '@controls/Select';

const OptionWrapper = styled.div`
  position: relative;
`;

const ExternalLinkIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 12px;
  right: 15px;
`;

const ApplicationLabel = styled.div`
  display: inline-flex;
  width: 140px;
`;

const Option = ({ children, ...props }) => (
  (props.data.value && props.data.frontendTypeName) ? (
    <OptionWrapper data-testid={`application_select_${props.data.frontendTypeName}_${props.data.value}`}>
      <components.Option {...props}>
        <ApplicationIcon
          imageCdnUrl={props.data.imageCdnUrl}
          className="app-icon me-2"
        />
        <ApplicationLabel>{children}</ApplicationLabel>
      </components.Option>
      <Link to={`/admin/applications/${props.value}/home`} target="_blank">
        <ExternalLinkIcon icon={faExternalLinkSquareAlt} className="text-dark" />
      </Link>
    </OptionWrapper>
  ) : (
    <components.Option {...props}>
      {children}
    </components.Option>
  )
);

const Control = ({ children, ...props }) => {
  const value = props.getValue()[0];

  return (
    <components.Control {...props}>
      {value && value.frontendTypeName && (
        <ApplicationIcon
          imageCdnUrl={value.imageCdnUrl}
          className="app-icon ms-2"
        />
      )}
      {children}
    </components.Control>
  );
};

const MinimizedControl = ({ children, ...props }) => {
  const value = props.getValue()[0];

  return (
    <components.Control {...props}>
      {value && (value.frontendTypeName ? (
        <ApplicationIcon
          imageCdnUrl={value.imageCdnUrl}
          className="app-icon ms-2"
          width="32"
          height="32"
        />
      ) : (
        <value.Icon width="32" height="32" className="ms-2" />
      ))}
      {children}
    </components.Control>
  );
};

export default function ApplicationSelect({
  applications, currentApplicationId, handleAppChange, minimized = false,
}) {
  const options = [
    { value: '', label: 'System', Icon: TdsLogo },
    ...applications,
  ];

  const minimizedProps = { IndicatorSeparator: () => null, IndicatorsContainer: () => null };
  const theme = useTheme();

  return (
    <Select
      options={options}
      selectedValue={currentApplicationId || ''}
      onChange={handleAppChange}
      components={{
        Option,
        Control: minimized ? MinimizedControl : Control,
        ...(minimized ? minimizedProps : {}),
      }}
      controlShouldRenderValue={!minimized}
      placeholder={null}
      isSearchable={!minimized}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      styles={{
        menuPortal: (provided) => ({ ...provided, minWidth: 200, zIndex: 1030 }),
        valueContainer: (provided) => (minimized ? ({ ...provided, padding: 0 }) : provided),
        indicatorsContainer: (provided) => (minimized ? ({ ...provided, width: '100%', marginLeft: '10%' }) : provided),
        control: (provided) => (
          minimized ? {
            ...provided,
            border: 'none',
            cursor: 'pointer',
            backgroundColor: theme.variants.light,
          } : provided
        ),
      }}
      testId="application-select"
    />
  );
}
