import React, { useMemo } from 'react';

import { get, set, camelCase } from 'lodash';
import { InUseCollapse, isInUse } from '@pages/common/InUse';
import { useConfirm, useFormContext } from '@hooks';
import IconButton from '@controls/buttons';

import { useLanguagesContext } from '../shared/services/useLanguages';
import { Tr, Td } from '../shared/styled-components';
import { TextCell, FormatCell, NameCell } from './cells';
import Translations from './Translations';
import useAvailabilityTypes from '../shared/services/useAvailabilityTypes';

export default function Row({ rowData }) {
  const {
    name,
    format,
    inUse,
    description,
    translationsAttributes,
  } = rowData;

  const confirm = useConfirm();
  const isDisabled = isInUse(inUse);

  const { filteredLanguages } = useLanguagesContext();
  const { dispatch } = useFormContext();
  const { isTest } = useAvailabilityTypes();

  const languageIds = filteredLanguages.map(({ id }) => id);
  const filteredTranslations = translationsAttributes.filter(({ languageId }) => languageIds.includes(languageId));

  const pushChangedRow = ({ fieldName, value }) => {
    set(rowData, `oldValues.${camelCase(fieldName)}`, get(rowData, fieldName));
    set(rowData, fieldName, value);
    set(rowData, 'markedAsChanged', true);

    dispatch({ type: 'pushChangedRow', rowData });
  };

  const onRemove = () => confirm
    .showConfirmation({ title: `Key '${name}' could be used in live game client. Continue?` })
    .then(() => pushChangedRow({ fieldName: '_destroy', value: true }));

  return (
    useMemo(() => (
      <Tr>
        {isTest && (
          <Td width="56px">
            <IconButton.Delete
              disabled={isDisabled}
              onClick={() => !isDisabled && onRemove()}
              minimized
            />
          </Td>
        )}
        <NameCell
          defaultValue={name}
          rowData={rowData}
          isDisabled={isDisabled}
        />
        <FormatCell
          name="format"
          defaultValue={format}
          pushChangedRow={pushChangedRow}
        />
        <Td><InUseCollapse inUse={inUse} /></Td>
        <TextCell
          name="description"
          defaultValue={description}
          pushChangedRow={pushChangedRow}
          multiline
        />
        <Translations
          translations={translationsAttributes}
          filteredTranslations={filteredTranslations}
          pushChangedRow={pushChangedRow}
        />
      </Tr>
    ),
    [
      name,
      format,
      description,
      inUse,
      translationsAttributes,
      filteredLanguages,
    ])
  );
}
