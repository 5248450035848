import React, { useMemo } from 'react';

import { ReactTable } from '@pages/common';
import { updateRows } from '../diffRows';
import DiffValue from '../DiffValue';

const columnBuilder = [
  { Header: 'Attribute/Property', accessor: 'attribute' },
  { Header: 'Action', accessor: 'action' },
  {
    Header: 'Context',
    accessor: 'context',
  },
  {
    Header: 'State Before',
    accessor: 'before',
    disableSortBy: true,
    colSpan: ({ row }) => (row.original.mergeDiff ? 2 : null),
    Cell: ({ row }) => <DiffValue value={row.original.before} onClick={row.original.onClick} />,
  },
  {
    Header: 'State After',
    accessor: 'after',
    disableSortBy: true,
    skip: ({ row }) => row.original.mergeDiff,
    Cell: ({ row }) => <DiffValue value={row.original.after} onClick={row.original.onClick} />,
  },
];

export default function Update({ showAssociation, versionTree }) {
  const changesRows = updateRows({ versionTree, showAssociation });
  const columns = useMemo(() => columnBuilder, []);

  return (
    <ReactTable
      columns={columns}
      data={changesRows}
      disableSortBy
    />
  );
}
