import React, { useState } from 'react';

import MultilineContent from '@controls/MultilineContent';
import { Td } from '../shared/styled-components';
import useAvailabilityTypes from '../shared/services/useAvailabilityTypes';

export default function EditableCell({
  name,
  defaultValue,
  pushChangedRow,
  sticky,
  valueWrapper,
  children,
}) {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const { isTest } = useAvailabilityTypes();

  const enableEditMode = () => setEditModeEnabled(true);
  const disableEditMode = () => setEditModeEnabled(false);
  const isChanged = () => currentValue !== defaultValue;

  const onChange = (event) => setCurrentValue(event.target.value);
  const onKeyDown = (event) => {
    switch (event.key) {
      case 'Escape': {
        disableEditMode();
        setCurrentValue(defaultValue);

        break;
      }
      case 'Enter':
        if (event.shiftKey) break;
        if (isChanged()) pushChangedRow({ fieldName: name, value: currentValue });

        event.preventDefault();
        disableEditMode();

        break;
      default:
    }
  };

  const onBlur = (event) => {
    if (isChanged()) pushChangedRow({ fieldName: name, value: currentValue });

    event.preventDefault();
    disableEditMode();
  };

  return (
    <Td
      width="250px"
      onDoubleClick={() => isTest && enableEditMode()}
      sticky={sticky}
    >
      {editModeEnabled && isTest
        ? children({
          value: currentValue,
          onChange,
          onKeyDown,
          onBlur,
          disableEditMode,
        })
        : <MultilineContent value={currentValue} wrapper={valueWrapper} />}
    </Td>
  );
}
