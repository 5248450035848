import React, { useState, useMemo } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  PageHeader,
  Pagination,
  InLiveColumn,
  TIMESTAMP_COLUMNS,
  ReactTable,
  DuplicationModal,
  InUseColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';
import { SolRoutes as Routes } from '@pages/routes';

import Filter from './Filter';

const columnsFactory = ({ applicationId, setDuplicatedRecord, onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, inUse, name } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={Routes.PlayerLevelSets.editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        <IconButton.Delete onClick={() => onDelete(id, name)} disabled={isInUse(inUse)} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch, isLoading } = useQuery(Routes.PlayerLevelSets.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query, query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Player Level Set',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => {
    confirm.showConfirmation({
      title: `Player Level Set '${name}' will be deleted and not available for work. Continue?`,
    }).then(() => Routes.PlayerLevelSets.deleteRequest({ applicationId, id }).then(responseHandler));
  };

  const columns = useMemo(() => columnsFactory({ applicationId, setDuplicatedRecord, onDelete }), []);

  return response && (
    <div>
      <PageHeader title="Player Level Sets" filter={<Filter />}>
        <IconButton.New to={Routes.PlayerLevelSets.newPath({ applicationId })} />
      </PageHeader>

      {isLoading ? <Spinner /> : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
            defaultSort={{ id: 'createdAt', desc: true }}
          />
          <DuplicationModal
            record={duplicatedRecord}
            entityName="Player Level Set"
            handleClose={() => setDuplicatedRecord(null)}
            title="Duplicate Player Level Set"
            onSubmit={(values) => (
              Routes.PlayerLevelSets.duplicateRequest({ ...values, applicationId: query.applicationId })
            )}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </div>
  );
}
