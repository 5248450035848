import React from 'react';
import styled from 'styled-components';

import { TimePeriodField } from '@controls/form';
import EditableCell from './EditableCell';

const FieldContainer = styled.div`
  .input-group {
    margin-bottom: 0!important;
  }
`;

export default function MinutesCell({
  row, name, errors, isEditable = true,
}) {
  const value = row[name] / 60;
  const error = errors[name];

  return (
    <EditableCell text={value} isEditable={isEditable} error={error}>
      {({ stopEditing, onKeyPress }) => (
        <FieldContainer>
          <TimePeriodField
            name={name}
            inputs={['minutes']}
            onKeyPress={onKeyPress}
            onBlur={stopEditing}
            autoFocus
          />
        </FieldContainer>
      )}
    </EditableCell>
  );
}
