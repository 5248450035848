import React from 'react';

import { useFormContext, useConfirm } from '@hooks';
import IconButton from '@controls/buttons';

export default function ButtonGroup({ index, _destroy }) {
  const { dispatch } = useFormContext();
  const confirm = useConfirm();

  const removeTargetTemplate = () => {
    confirm
      .showConfirmation({ title: 'This target template will be removed. Continue?' })
      .then(() => dispatch({ actionType: 'removeTargetTemplate', index }));
  };

  const restoreTargetTemplate = () => dispatch({ actionType: 'restoreTargetTemplate', index });

  return _destroy ? (
    <IconButton.Restore
      onClick={restoreTargetTemplate}
      minimized
    />
  ) : (
    <IconButton.Delete
      onClick={removeTargetTemplate}
      minimized
    />
  );
}
