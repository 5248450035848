import styled from 'styled-components';

const Error = styled.p`
  color: red;
  margin-bottom: 0;
  font-weight: normal;
  ${({ multiline }) => multiline && 'white-space: pre-wrap'};
`;

export default Error;
