import React from 'react';
import styled from 'styled-components';
import { FormGroup } from '@hooks';
import { Field, SelectField } from '@controls/form';
import { Rewards } from '@pages/common';

import { ChapterRow, ColumnTitle, LevelsConfigFields } from '../../metagame/chapter-based/chapter-sets/shared';

const COLUMN_PADDING_SIZE = 10;

const StaticValue = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
`;

const IdColumn = styled.div`
  padding-right: ${COLUMN_PADDING_SIZE}px;
  width: 5%;
`;

const NameColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 15%;
`;

const TranslationColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 15%;
`;

const FigurePackColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 15%;
`;

const UiAssetColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 15%;
`;

const UiConfigColumn = styled.div`
  padding: 0 ${COLUMN_PADDING_SIZE}px;
  width: 15%;
`;

const RewardColumn = styled.div`
  padding-left: ${COLUMN_PADDING_SIZE}px;
  width: 20%;
`;

export default function ChapterFields({
  banks,
  chapter,
  dispatch,
  figurePacks,
  isDirty,
  levelsTotal,
  populateChapter,
  rewardItems,
  uiAssets,
  uiConfigs,
  localizationKeys,
}) {
  return (
    <>
      <ChapterRow>
        <IdColumn>
          <ColumnTitle>Id</ColumnTitle>
          <StaticValue>{chapter.id}</StaticValue>
        </IdColumn>
        <NameColumn>
          <ColumnTitle>Chapter name</ColumnTitle>
          <Field name="name" fast />
        </NameColumn>
        <TranslationColumn>
          <ColumnTitle>Name: localization key</ColumnTitle>
          <FormGroup name="configAttributes">
            <SelectField name="translationKeyId" options={localizationKeys} />
          </FormGroup>
        </TranslationColumn>
        <UiAssetColumn>
          <ColumnTitle>UI theme</ColumnTitle>
          <SelectField
            name="uiAssetId"
            options={uiAssets}
          />
        </UiAssetColumn>
        <UiConfigColumn>
          <ColumnTitle>UI configuration (optional)</ColumnTitle>
          <SelectField
            includeEmpty
            name="uiConfigId"
            options={uiConfigs}
          />
        </UiConfigColumn>
        <FigurePackColumn>
          <ColumnTitle>Figure Pack</ColumnTitle>
          <FormGroup name="configAttributes">
            <Field type="hidden" name="id" fast />
            <SelectField
              name="figurePackId"
              options={figurePacks}
            />
          </FormGroup>
        </FigurePackColumn>
        <RewardColumn>
          <ColumnTitle>Reward for the chapter</ColumnTitle>
          <Rewards
            dispatch={dispatch}
            rewardable={chapter}
            rewardItems={rewardItems}
          />
        </RewardColumn>
      </ChapterRow>
      <LevelsConfigFields
        banks={banks}
        chapter={chapter}
        isDirty={isDirty}
        levelsTotal={levelsTotal}
        populateChapter={populateChapter}
        dispatch={dispatch}
      />
    </>
  );
}
