import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { PaymentsRoutes } from '@pages/routes';

import Form from './Form';

export default function New() {
  const [data, setData] = useState(null);
  const { query } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Store Product',
    actionName: 'creation',
    collectionUrl: PaymentsRoutes.StoreProducts.indexPath({ applicationId: query.applicationId }),
    setData,
  });

  const onSubmit = (values) => PaymentsRoutes.StoreProducts.createRequest(values).then(responseHandler);

  useEffect(() => {
    PaymentsRoutes.StoreProducts.newRequest(query).then((response) => (
      setData(response.data)
    ));
  }, []);

  return data && <Form actionName="Create" data={data} onSubmit={onSubmit} />;
}
