import { getData } from 'country-list';

const itself = (e) => e;

const optionsMap = (options = {}) => {
  const nameFn = options.nameFn || itself;
  const codeFn = options.codeFn || itself;

  return getData().map((el) => (
    {
      label: nameFn(el.name),
      value: codeFn(el.code),
    }
  ));
};

const countryCodesOptions = optionsMap();
const languageCodesOptions = optionsMap({ codeFn: (code) => code.toLowerCase() });

export { countryCodesOptions, languageCodesOptions };
