import React from 'react';

import { MyPetCafeRoutes } from '@pages/routes';
import New from '@components/journeys/banks/New';
import Form from './Form';

export default function AdventureBanksNew() {
  return (
    <New
      form={Form}
      nameTranslationPath="adventures.banks.name"
      routes={MyPetCafeRoutes.Adventures.EventBanks}
    />
  );
}
