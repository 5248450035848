import React from 'react';

import { Form, useCurrentApplication } from '@hooks';
import {
  Field, FormButtonsGroup, Label, StaticFieldsFormGroup,
} from '@controls/form';
import { PageHeader, DuplicateButton } from '@pages/common';
import RulesForm from '@pages/rules/Form';
import { rulesReducer } from '@reducers';
import { NamedRuleGroupsRoutes } from '@pages/routes';

export default function RuleGroupsForm({ data, onSubmit }) {
  const { currentApplication } = useCurrentApplication();
  const applicationId = currentApplication.id;

  const isUpdate = Boolean(data.id);

  return (
    <Form
      initialValues={{ applicationId, ...data }}
      onSubmit={onSubmit}
      reducer={rulesReducer}
    >
      {({ values, dirty }) => (
        <>
          <PageHeader title={`${isUpdate ? 'Edit' : 'New'} Named Rule Group`}>
            <FormButtonsGroup cancelButtonPath={NamedRuleGroupsRoutes.indexPath({ applicationId })}>
              <DuplicateButton
                entityName="Named rule Group"
                routes={NamedRuleGroupsRoutes}
                data={data}
                disabled={dirty}
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label text="Name">
            <Field name="name" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <RulesForm
            ruleSectionsAttributes={values.ruleSectionsAttributes}
            createNewNamedRuleGroupEnabled={false}
            hideNamedGroupsRule
          />
        </>
      )}
    </Form>
  );
}
