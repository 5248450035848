import React from 'react';

import { useCurrentApplication } from '@hooks';

import { Fields as BlackjackFields } from './blackjack';

export default function GameSpecificFields() {
  const { applicationName } = useCurrentApplication();

  switch (applicationName) {
    case 'Blackjack': return <BlackjackFields />;
    default: return '';
  }
}
