import React from 'react';

import {
  GameSettingsTabs,
  localizationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  analyticsDefaultFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  zendeskDefaultFieldNames,
  assetsDefaultFieldNames,
} from '@components/game-settings/Resource';

import { adRequestDelayFieldNames } from '@components/game-settings/Resource/Shared';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'chapterSetId',
      'ddaSchemaSetId',
      'rewardSetId',
      'categorySetId',
      'minLevelsDownloaded',
      'themesToDownloadAhead',
      'flowAfterSplashScreen',
      'enableRewardProgressWinPopup',
      'rewardFlow',
      'ftueAfterLevelOneFlow',
      'enableFtTutorial',
      'increaseTilesSize',
      'enableGameSaving',
      'polaroidsJournalEnabled',
      ...zendeskDefaultFieldNames,
      ...assetsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      ...adPlacementIdsDefaultFieldNames,
      ...adConfigsDefaultFieldNames,
      ...adRequestDelayFieldNames,
    ],
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: [...popupsDefaultFieldNames, 'privacyPolicyPopup'],
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#cta',
    title: 'Power Up CTA',
    fieldsNames: [
      'ctaCooldown',
      'ctaMagicMatch',
      'ctaShuffle',
    ],
  },
  {
    eventKey: '#power_ups',
    title: 'Power Ups',
    fieldsNames: [
      'powerUpUndoUnlockLevel',
      'powerUpUndoUnlockCount',
      'powerUpShuffleUnlockLevel',
      'powerUpShuffleUnlockCount',
      'powerUpMagicMatchUnlockLevel',
      'powerUpMagicMatchUnlockCount',
      'powerUpTutorialEnabled',
    ],
  },
  {
    eventKey: '#scrapbooks',
    title: 'Scrapbooks',
    fieldsNames: [
      'dailyChallengesEnabled',
      'dailyChallengesFreeSegments',
      'dailyChallengesRewardedSegments',
      'dailyChallengesUnlockLevel',
      'dailyChallengesPowerUpsDisabled',
      'dailyChallengesEverydayReminderPopupEnabled',
      'newDailyChallengeReminderPopupEnabled',
      'dailyChallengesPushNotificationAfterDcResetConfiguration',
      'dailyChallengesPushNotificationBeforeDcResetConfiguration',
    ],
  },
  {
    eventKey: '#coins',
    title: 'Coins',
    fieldsNames: [
      'coinsEnable',
      'coinsInitialInventory',
      'coinsPowerupUndoCoins',
      'coinsPowerupMagicmatchCoins',
      'coinsPowerupShuffleCoins',
      'coinsItemReviveCoins',
    ],
  },
  {
    eventKey: '#timer',
    title: 'Timer',
    fieldsNames: [],
  },
  {
    eventKey: '#coins_for_wins',
    title: 'Coins for wins',
    fieldsNames: [],
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: analyticsDefaultFieldNames,
  },
  {
    eventKey: '#liveOpsEvents',
    title: 'LiveOps events',
    fieldsNames: [],
  },
  {
    eventKey: '#splash_screen',
    title: 'Splash Screen',
    fieldsNames: [
      'splashScreenDurationMin',
      'splashScreenDurationMax',
    ],
  },
  {
    eventKey: '#haptic_feedback',
    title: 'Haptic Feedback',
    fieldsNames: [
      'hapticTileTap',
      'hapticTileTapMatch',
      'hapticPowerupUndo',
      'hapticPowerupShuffle',
      'hapticPowerupMagicmatch',
    ],
  },
  {
    eventKey: '#notifications',
    title: 'Notifications',
    fieldsNames: ['oneSignalTags'],
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#shop',
    title: 'Shop',
    fieldsNames: [
      'shopEnabled',
    ],
  },
  {
    eventKey: '#revive_schema',
    title: 'Revive Schema',
    fieldsNames: ['multipleRevives'],
  },
  {
    eventKey: '#daily_rewards',
    title: 'Daily Reward',
    fieldsNames: [],
  },
  {
    eventKey: '#special_tiles',
    title: 'Special Tiles',
    fieldsNames: [],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: [
      ...popupsDefaultArchivedFieldNames,
      'navFlowAfterGameWin',
      'inactiveButtonsSettings',
      'enableLevelStartAnimation',
      'enableMapShowLockedChapters',
      'enableMapStickySnapping',
      'randomLevelsEnabled',
      'randomLevelsExcludeFromStart',
      'randomLevelsExcludeFromEnd',
      'enableChapterUnlockAnimation',
      'dailyChallengesUnlockFlow',
      'newDailyChallengesLocalizationKey',
      'newDailyChallengeFlow',
      'enableBannersMetaScreens',
    ],
  },
  {
    eventKey: '#sounds',
    title: 'Sounds',
    fieldsNames: [],
  },
];

export default function Tabs({
  setContractType, fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      onChange={setContractType}
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
