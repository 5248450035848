import React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { Form } from '@hooks';
import RulesForm from '@pages/rules/Form';

export default function RulesListModal({
  show,
  rules,
  nestedForm,
  onClose,
}) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      dialogClassName="mw-75"
    >
      <Modal.Body>
        <Form initialValues={{ ruleSectionsAttributes: rules }}>
          {({ values }) => (
            <RulesForm
              ruleSectionsAttributes={values.ruleSectionsAttributes}
              nestedForm={nestedForm}
              hideControls
              disabled
            />
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
