import React from 'react';
import { Col } from 'react-bootstrap';

import APP_DATA from '@services/appData';

import { fromClassNameToOptions } from '@services/enums';
import {
  Filter,
  TextFilter,
  SelectFilter,
  StatusFilter,
} from '@pages/common';

const missionTypeStatuses = APP_DATA.enums['Missions::MissionTypeStatuses'];

const defaultStatuses = [
  missionTypeStatuses.ACTIVE,
];

export default function MissionTypesFilter() {
  const orderDirectionsOptions = fromClassNameToOptions('Missions::MissionTypeOrderDirections');
  const statusOptions = fromClassNameToOptions('Missions::MissionTypeStatuses', { withoutFiltering: true });
  const valueTypeOptions = fromClassNameToOptions('Missions::MissionTypeValuesTypes', { withoutFiltering: true });

  return (
    <Filter>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <TextFilter
              name="keyContAny"
              label="Key"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <StatusFilter
              name="statusIn"
              label="Status"
              options={statusOptions}
              updateFilterField={updateFilterField}
              defaults={defaultStatuses}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="orderIn"
              label="Target values order"
              isMulti={false}
              updateFilterField={updateFilterField}
              options={orderDirectionsOptions}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="valuesTypeIn"
              label="Target values type"
              isMulti={false}
              updateFilterField={updateFilterField}
              options={valueTypeOptions}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
