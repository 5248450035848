import React, { useState } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';

import { FlexiblePopupRoutes as Routes } from '@pages/routes';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
  InLiveColumn,
  InUseColumn,
  DuplicationModal,
} from '@pages/common';
import IconButton from '@controls/buttons';
import { isInUse } from '@pages/common/InUse';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import Filter from './Filter';

const columnsFactory = ({
  applicationId, onDelete, onDuplicate,
}) => ([
  { Header: 'ID', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Type', accessor: 'actionType' },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, inUse } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={Routes.Actions.editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate
          onClick={() => onDuplicate(id, name)}
          minimized
        />
        <IconButton.Delete
          onClick={onDelete(id, name)}
          disabled={isInUse(inUse)}
          minimized
        />
      </ButtonToolbar>
    ),
  },
]);

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { query: { applicationId } } = useRouter();
  const { response, refetch, isLoading } = useQuery(Routes.Actions.indexRequest, { includeInUse: true });
  const confirm = useConfirm();

  const deleteResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Flexible Popup Action',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Flexible Popup Action '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => Routes.Actions.deleteRequest({ id, applicationId })
        .then(deleteResponseHandler));
  };

  const onDuplicate = (id, name) => setDuplicatedRecord({ id, name });

  const columns = React.useMemo(() => columnsFactory({ applicationId, onDelete, onDuplicate }), []);

  return response && (
    <>
      <PageHeader
        title="Flexible Popup Actions"
        filter={<Filter defaultComponents={['availabilityStateIn']} />}
      >
        <IconButton.New to={Routes.Actions.newPath({ applicationId })} />
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <ReactTable
          columns={columns}
          data={response.items}
        />
      )}
      <DuplicationModal
        entityName="Flexible Popup"
        title="Duplicate Flexible Popup"
        record={duplicatedRecord}
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={({ id, name }) => (
          Routes.Actions.duplicateRequest({ id, name, applicationId })
        )}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </>
  );
}
