import React from 'react';
import { Card } from 'react-bootstrap';

import { PageSection } from '@pages/common';

import { SelectField, Field, Label } from '@controls/form';

export default function Trial({ level: { limitType }, limitTypeOptions }) {
  return (
    <Card body key="trial">
      <PageSection title="Trial" />

      <Label text="Limit type">
        <SelectField name="limitType" options={limitTypeOptions} />
      </Label>
      {limitType > 0 && (
        <Label text="Limit value">
          <Field name="limitValue" type="number" />
        </Label>
      )}
    </Card>
  );
}
