import React from 'react';

import APP_DATA from '@services/appData';

import { JourneyRoutes as Routes } from '@pages/routes';

import {
  Form,
  FormGroup,
  useCurrentApplication,
  AbTestingContext,
  useConfirm,
} from '@hooks';

import { fromClassNameToOptions } from '@services/enums';

import {
  Label,
  Field,
  SelectField,
  FormButtonsGroup,
  PriorityField,
  AvailabilityStateFormGroup,
  StaticFieldsFormGroup,
  TimePeriodField,
  LabelsFormGroup,
} from '@controls/form';

import { DuplicateButton, PageHeader } from '@pages/common';
import RulesForm from '@pages/rules/Form';
import buildOnSubmit from '@services/journeys/banks/buildOnSubmit';
import { combineReducers, rulesReducer } from '@reducers';

import IconButton from '@controls/buttons';

import formReducer from './formReducer';
import APP_SPECIFIC_CONFIGS from './configuration-forms';

const { enums, modelsLocales: { journeys: { events: { tooltips } } } } = APP_DATA;
const statusesEnum = enums['Journeys::Statuses'];

const JourneysActivationEventsForm = ({
  actionName, data, title, onSubmit, entityName,
}) => {
  const { applicationKey, currentApplication: { id: applicationId } } = useCurrentApplication();
  const statuses = fromClassNameToOptions('Journeys::Statuses');
  const confirm = useConfirm();

  const { status, configType } = data;

  const isArchived = status === statusesEnum.ARCHIVED;
  const isUpdate = Boolean(data.id);

  const { ConfigurationForm, reducers = [], preprocessSubmit } = APP_SPECIFIC_CONFIGS[applicationKey];

  const onFormSubmit = buildOnSubmit({
    applicationId,
    data,
    entityName,
    statusesEnum,
    onSubmitFunction: (values) => onSubmit(preprocessSubmit ? preprocessSubmit(values) : values),
    confirm,
    routes: Routes.ActivationEvents,
  });

  return (
    <AbTestingContext
      entityType={configType}
      entityId={data.configAttributes.id}
    >
      <Form
        initialValues={data}
        onSubmit={onFormSubmit}
        reducer={combineReducers([rulesReducer, formReducer, ...reducers])}
        sharedInputProps={{ disabled: isArchived }}
      >
        {({ values }) => (
          <>
            <PageHeader title={title}>
              <FormButtonsGroup
                cancelButtonPath={Routes.ActivationEvents.indexPath({ applicationId })}
              >
                {isUpdate && (
                  <>
                    <IconButton.LookerJourney entityType="Journeys::ActivationEvent" entityIds={[values.id]} />
                    <IconButton.ActivityLog
                      applicationId={applicationId}
                      id={data.id}
                      entityType="Journeys::Event"
                    />
                    <FormButtonsGroup.Divider />
                  </>
                )}
                <DuplicateButton
                  routes={Routes.ActivationEvents}
                  data={data}
                  entityName={entityName}
                  modalType="withPriority"
                />
              </FormButtonsGroup>
            </PageHeader>

            {isUpdate && (
              <div className="mt-2">
                <Label text="ID">
                  <Field name="id" disabled />
                </Label>
                <Label text="Variant ID">
                  <Field name="configAttributes.currentVariantAttributes.id" disabled />
                </Label>
              </div>
            )}

            <Label text="Name">
              <Field type="text" name="name" disabled={false} />
            </Label>
            <Label text="Status">
              <SelectField name="status" options={statuses} />
            </Label>
            <AvailabilityStateFormGroup />
            <Label text="Priority">
              <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} />
            </Label>

            <LabelsFormGroup disabled={false} />

            <StaticFieldsFormGroup data={data} />

            <Label text="Duration" tooltipText={tooltips.duration}>
              <TimePeriodField name="duration" />
            </Label>

            <FormGroup name="configAttributes" className="mb-2">
              <ConfigurationForm
                isArchived={isArchived}
                actionName={actionName}
                defaultVariant
                values={values.configAttributes}
              />
            </FormGroup>

            <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} disabled={isArchived} />
          </>
        )}
      </Form>
    </AbTestingContext>
  );
};

export default JourneysActivationEventsForm;
