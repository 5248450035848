import React from 'react';

import { PageTitle } from '@pages/common';

import {
  ConfigurationsIndex,
  ConfigurationsNew,
  ConfigurationsEdit,
  ConfigurationsBase,
  SearchConfigurationEnabledFields,

  AdUnitCampaignsIndex,

  InterstitialsPlacementsIndex,
  InterstitialsPlacementsNew,
  InterstitialsPlacementsEdit,

  InterstitialsPlacementBanksIndex,
  InterstitialsPlacementBanksNew,
  InterstitialsPlacementBanksEdit,

  RewardedVideosPlacementsIndex,
  RewardedVideosPlacementsNew,
  RewardedVideosPlacementsEdit,

  RewardedVideosPlacementBanksIndex,
  RewardedVideosPlacementBanksNew,
  RewardedVideosPlacementBanksEdit,

  BadgesPlacementsIndex,
  BadgesPlacementsNew,
  BadgesPlacementsEdit,

  BadgesPlacementBanksIndex,
  BadgesPlacementBanksNew,
  BadgesPlacementBanksEdit,
} from '@pages/ads';

import { AdsRoutes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function ads() {
  return [
    <Route
      key={AdsRoutes.Configurations.indexRawPath}
      path={AdsRoutes.Configurations.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Ads Configurations" />
          <ConfigurationsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Configurations.baseRawPath}
      path={AdsRoutes.Configurations.baseRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Ads Configuration" action="Edit" />
          <ConfigurationsBase />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Configurations.newRawPath}
      path={AdsRoutes.Configurations.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Ads Configuration" action="New" />
          <ConfigurationsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Configurations.editRawPath}
      path={AdsRoutes.Configurations.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Ads Configuration" action="Edit" />
          <ConfigurationsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Configurations.searchFieldRawPath}
      path={AdsRoutes.Configurations.searchFieldRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Ads Configuration Fields" action="Search" />
          <SearchConfigurationEnabledFields />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.AdUnitCampaigns.indexRawPath}
      path={AdsRoutes.AdUnitCampaigns.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Ad Unit Campaigns" />
          <AdUnitCampaignsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Interstitials.Placements.indexRawPath}
      path={AdsRoutes.Interstitials.Placements.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Interstitial Placements" />
          <InterstitialsPlacementsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Interstitials.Placements.newRawPath}
      path={AdsRoutes.Interstitials.Placements.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Interstitial Placement" action="New" />
          <InterstitialsPlacementsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Interstitials.Placements.editRawPath}
      path={AdsRoutes.Interstitials.Placements.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Interstitial Placement" action="Edit" />
          <InterstitialsPlacementsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Interstitials.PlacementBanks.indexRawPath}
      path={AdsRoutes.Interstitials.PlacementBanks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Interstitial Placement Banks" />
          <InterstitialsPlacementBanksIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Interstitials.PlacementBanks.newRawPath}
      path={AdsRoutes.Interstitials.PlacementBanks.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Interstitial Placement Bank" action="New" />
          <InterstitialsPlacementBanksNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Interstitials.PlacementBanks.editRawPath}
      path={AdsRoutes.Interstitials.PlacementBanks.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Interstitial Placement Bank" action="Edit" />
          <InterstitialsPlacementBanksEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.RewardedVideos.Placements.indexRawPath}
      path={AdsRoutes.RewardedVideos.Placements.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Rewarded Video Placements" />
          <RewardedVideosPlacementsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.RewardedVideos.Placements.newRawPath}
      path={AdsRoutes.RewardedVideos.Placements.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Rewarded Video Placement" action="New" />
          <RewardedVideosPlacementsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.RewardedVideos.Placements.editRawPath}
      path={AdsRoutes.RewardedVideos.Placements.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Rewarded Video Placement" action="Edit" />
          <RewardedVideosPlacementsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.RewardedVideos.PlacementBanks.indexRawPath}
      path={AdsRoutes.RewardedVideos.PlacementBanks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Rewarded Video Placement Banks" />
          <RewardedVideosPlacementBanksIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.RewardedVideos.PlacementBanks.newRawPath}
      path={AdsRoutes.RewardedVideos.PlacementBanks.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Rewarded Video Placement Bank" action="New" />
          <RewardedVideosPlacementBanksNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.RewardedVideos.PlacementBanks.editRawPath}
      path={AdsRoutes.RewardedVideos.PlacementBanks.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Rewarded Video Placement Bank" action="Edit" />
          <RewardedVideosPlacementBanksEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Badges.Placements.indexRawPath}
      path={AdsRoutes.Badges.Placements.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Badge Placements" />
          <BadgesPlacementsIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Badges.Placements.newRawPath}
      path={AdsRoutes.Badges.Placements.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Badge Placement" action="New" />
          <BadgesPlacementsNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Badges.Placements.editRawPath}
      path={AdsRoutes.Badges.Placements.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Rewarded Video Placement" action="Edit" />
          <BadgesPlacementsEdit />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Badges.PlacementBanks.indexRawPath}
      path={AdsRoutes.Badges.PlacementBanks.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Rewarded Video Placement Banks" />
          <BadgesPlacementBanksIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Badges.PlacementBanks.newRawPath}
      path={AdsRoutes.Badges.PlacementBanks.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Rewarded Video Placement Bank" action="New" />
          <BadgesPlacementBanksNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={AdsRoutes.Badges.PlacementBanks.editRawPath}
      path={AdsRoutes.Badges.PlacementBanks.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Rewarded Video Placement Bank" action="Edit" />
          <BadgesPlacementBanksEdit />
        </RouteContainer>
      )}
    />,
  ];
}
