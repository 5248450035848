import React from 'react';
import { get } from 'lodash';

import { useFormContext, FormGroup } from '@hooks';
import { Label, Field } from '@controls/form';

import LevelBanks from '@pages/journeys/level-banks/EventsBlock';

const MyPetCafeConfigurationForm = ({
  values, defaultVariant = false, variantIndex, isArchived,
}) => {
  const { dispatch } = useFormContext();

  const onBankAdd = (id) => dispatch({ actionType: 'addLevelBankToJourney', id, variantIndex });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeLevelBankFromJourney', id, variantIndex,
  });

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  return (
    <FormGroup name={variantAttributesKey}>
      <Label text="Default Title">
        <Field name="defaultTitle" />
      </Label>
      <Label text="Assets Key">
        <Field name="assetKey" />
      </Label>

      <LevelBanks
        disabled={isArchived}
        variantAttributes={get(values, variantAttributesKey, values)}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
        eventType="event"
      />
    </FormGroup>
  );
};

export default MyPetCafeConfigurationForm;
