import React, { useState } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { uniq, isPlainObject } from 'lodash';

import { Col, Row } from 'react-bootstrap';
import FilterBadge from './FilterBadge';
import FilterField from './FilterField';

const emptyValue = 'null';
const emptyLabel = 'Not set';

export default function SelectFilter({
  includeEmpty,
  label,
  name,
  operations,
  options,
  updateFilterField,
  stateFieldValue,
  className,
  isMulti = true,
  ...rest
}) {
  const emptyOption = { value: emptyValue, label: typeof includeEmpty === 'string' ? includeEmpty : emptyLabel };
  const extendedOptions = includeEmpty ? [emptyOption, ...options] : options;
  const [operation, setOperation] = useState(operations ? operations[0].value : null);
  const fetchValue = (value) => {
    if (!operations) return { operation: name, value };
    if (!isPlainObject(value)) return { operation: null, value };

    const valueEntry = Object.entries(value)[0];
    return { operation: valueEntry[0], value: valueEntry[1] };
  };
  const queryName = operations ? `__merge__${name}` : name;
  return (
    <FilterField
      canAdd={({ currentValue }) => currentValue}
      label={label}
      hideButton
      name={queryName}
      updateFilterField={updateFilterField}
      stateFieldValue={stateFieldValue}
      isMulti={isMulti}
      // eslint-disable-next-line react/no-unstable-nested-components
      FieldComponent={({ fieldValue }) => {
        const { value: queryValue, operation: queryOperation } = fetchValue(fieldValue);
        return (
          <Row>
            {operations && (
              <Col md={3} className="pe-0">
                <ReactSelect
                  options={operations}
                  value={operations.find((o) => o.value === queryOperation)
                    || operations.find((o) => o.value === operation)}
                  onChange={({ value }) => {
                    setOperation(value);
                    updateFilterField({ [queryName]: { [value]: queryValue } });
                  }}
                  classNamePrefix="react-select"
                  className={classNames('react-select', className)}
                />
              </Col>
            )}
            <Col md>
              <ReactSelect
                onChange={({ value }) => {
                  const newValue = isMulti ? uniq([...queryValue, value]) : value;
                  updateFilterField({ [queryName]: operations ? { [operation]: newValue } : newValue });
                }}
                // query string parser always returns strings
                options={extendedOptions.filter((option) => queryValue.indexOf(option.value.toString()) === -1)}
                className={classNames('react-select', className)}
                classNamePrefix="react-select"
                {...rest}
              />
            </Col>
          </Row>
        );
      }}
      BadgesComponent={({ fieldValue, removeValue }) => extendedOptions.length > 0
        && extendedOptions
          .filter((option) => fetchValue(fieldValue).value.indexOf(option.value.toString()) > -1)
          .map((option) => (
            <FilterBadge
              key={option.value}
              onClick={() => removeValue(option.value.toString())}
              value={option.label}
              color={option.color}
            />
          ))}
    />
  );
}
