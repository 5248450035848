import React from 'react';
import { useTheme } from 'styled-components';
import { faFlask } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

export default function AbExperimentsNavigationItem({ routingScope, minimized = false }) {
  const theme = useTheme();

  return (
    <Sidebar.LinkItem
      to={`${routingScope}/ab_testing/experiments`}
      icon={faFlask}
      title="A/B Experiments"
      color={theme.blue}
      minimized={minimized}
    />
  );
}
