import React from 'react';
import { PageTitle } from '@pages/common';

import {
  gameConfigsList,
  List,
  New,
  Base,
  Edit,
  SearchEnabledSettings,
} from '@components/game-settings';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function gameSettings() {
  const routes = [];

  gameConfigsList.forEach((gameConfig) => {
    const scope = `/admin/applications/:applicationId/${gameConfig.gameType}/game_settings`;

    routes.push(
      <Route
        key={scope}
        path={scope}
        element={(
          <RouteContainer fullWidth>
            <PageTitle text="Game Settings" />
            <List {...gameConfig} />
          </RouteContainer>
      )}
      />,
      <Route
        key={`${scope}/new`}
        path={`${scope}/new`}
        element={(
          <RouteContainer>
            <PageTitle entity="Game Settings" action="New" />
            <New {...gameConfig} />
          </RouteContainer>
      )}
      />,
      <Route
        key={`${scope}/base`}
        path={`${scope}/base`}
        element={(
          <RouteContainer>
            <PageTitle entity="Base Game Settings" action="Edit" />
            <Base {...gameConfig} />
          </RouteContainer>
      )}
      />,
      <Route
        key={`${scope}/:id/edit`}
        path={`${scope}/:id/edit`}
        element={(
          <RouteContainer>
            <PageTitle entity="Override" action="Edit" />
            <Edit {...gameConfig} />
          </RouteContainer>
      )}
      />,
      <Route
        key={`${scope}/search_field`}
        path={`${scope}/search_field`}
        element={(
          <RouteContainer>
            <PageTitle entity="Game Settings" action="Search" />
            <SearchEnabledSettings />
          </RouteContainer>
      )}
      />,
    );
  });

  return routes;
}
