import React, { useMemo } from 'react';
import { remove } from 'lodash';

import { ReactTable } from '@pages/common';
import DiffValue from '../DiffValue';
import { creationRows } from '../diffRows';

const columnBuilder = [
  { Header: 'Attribute/Property', accessor: 'attribute' },
  { Header: 'Action', id: 'action', Cell: 'set' },
  { Header: 'Context', accessor: 'context' },
  {
    Header: 'Values',
    accessor: 'value',
    disableSortBy: true,
    Cell: ({ row }) => <DiffValue value={row.original.value} onClick={row.original.onClick} />,
  },
];

export default function Creation({ showAssociation, versionTree }) {
  let changesRows = creationRows({
    versionTree, showAssociation, omitAttributes: ['_uuid', 'abExperimentId'],
  });
  remove(changesRows, (row) => row.attribute === 'variants');
  versionTree.associations.variants.forEach((variant) => {
    const context = `${variant.version.objectChanges.name[1]} (id: ${variant.version.itemId})`;
    changesRows = changesRows.concat(creationRows({
      context, versionTree: variant, showAssociation,
    }));
  });
  const columns = useMemo(() => columnBuilder, []);

  return (
    <ReactTable
      columns={columns}
      data={changesRows}
      disableSortBy
    />
  );
}
