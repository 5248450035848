import React, { useState } from 'react';
import { isNull, isEqual } from 'lodash';
import styled from 'styled-components';

import Tooltip from '@controls/Tooltip';

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  height: auto;
  display: inline-block;
  margin-right: 5px;
`;

export const itemId = ({ id, _uuid }) => id || _uuid;

export const MassSelectCheckbox = ({
  items, selectedRows, setSelectedRows, noTooltip,
}) => {
  const allItems = items.reduce((memo, item) => ({ ...memo, [itemId(item)]: true }), {});

  const isChecked = isEqual(selectedRows, allItems);
  const valueToPopulate = isChecked ? {} : allItems;
  const labelText = isChecked ? 'Deselect all' : 'Select all';
  const Component = (
    <>
      <Checkbox
        checked={isChecked}
        onClick={() => setSelectedRows(valueToPopulate)}
        onChange={() => { }}
      />
      <span>{labelText}</span>
    </>
  );
  return noTooltip ? Component : (
    <Tooltip text={'Press "Shift" to select a block'}>
      {Component}
    </Tooltip>
  );
};

export const CHECKBOX_COLUMN = {
  id: 'selected',
  style: { width: 140 },
  Header: ({ rows }) => {
    if (rows.length === 0) return null;

    const [{ original: { selectedRows, setSelectedRows } }] = rows;

    return (
      <MassSelectCheckbox
        items={rows.map((r) => r.original)}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    );
  },
  Cell: ({ row }) => {
    const { selectedRows, toggleRow } = row.original;
    return (
      <Checkbox
        checked={selectedRows[itemId(row.original)] || false}
        onClick={(e) => toggleRow(e, row.index)}
        onChange={() => { }}
      />
    );
  },
};

export default function useSelectedRows(items, filterFn = () => true) {
  const [selectedRows, setSelectedRows] = useState({});
  const [rangeStart, setRangeStart] = useState(null);
  const toggleRow = (e, index) => {
    const id = itemId(items[index]);
    if (e.shiftKey) {
      if (!isNull(rangeStart)) {
        const ids = items.slice(
          Math.min(rangeStart, index),
          Math.max(rangeStart, index) + 1,
        ).map(itemId);
        setSelectedRows({ ...selectedRows, ...ids.reduce((acc, rowId) => ({ ...acc, [rowId]: true }), {}) });
        setRangeStart(null);
      } else {
        setSelectedRows({ ...selectedRows, [id]: !selectedRows[id] });
        setRangeStart(index);
      }
    } else {
      setSelectedRows({ ...selectedRows, [id]: !selectedRows[id] });
      setRangeStart(null);
    }
  };
  const selectableItems = items.reduce((acc, row, index) => [
    ...acc,
    {
      ...row,
      toggleRow,
      selectedRows,
      setSelectedRows,
      index,
    },
  ], []);

  const selectedItems = items
    .filter(filterFn)
    .reduce((acc, item) => (selectedRows[itemId(item)] ? [...acc, item] : acc), []);

  return {
    selectedRows, setSelectedRows, toggleRow, selectableItems, selectedItems,
  };
}
