import React from 'react';
import { Col } from 'react-bootstrap';
import { isEqual } from 'lodash';

import { FieldWithExperiment } from '@controls/form';

import FieldsFactory from '../FieldsFactory';

const Tab = React.memo(({ schemaId, applicationId, fieldsNames }) => {
  const fieldsFactory = FieldsFactory({ applicationId, schemaId });

  return (
    fieldsNames.map((fieldName) => {
      const { Labeled } = fieldsFactory[fieldName];
      return (
        <FieldWithExperiment name={fieldName} key={fieldName}>
          <Col xs={12}>
            <Labeled />
          </Col>
        </FieldWithExperiment>
      );
    })
  );
}, (prev, next) => (
  prev.schemaId === next.schemaId && prev.applicationId === next.applicationId
  && isEqual(prev.fieldsNames) === isEqual(next.fieldsNames)
));

export default Tab;
