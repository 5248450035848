import React from 'react';
import { PageTitle } from '@pages/common';

import ExperimentIndex from '@pages/ab-testing/experiments/Index';
import ExperimentNew from '@pages/ab-testing/experiments/New';
import ExperimentEdit from '@pages/ab-testing/experiments/Edit';
import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function experiments() {
  const scope = '/admin/applications/:applicationId/ab_testing/experiments';

  return [
    <Route
      key={scope}
      path={scope}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="A/B Experiments" />
          <ExperimentIndex />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/new`}
      path={`${scope}/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="A/B Experiment" action="New" />
          <ExperimentNew />
        </RouteContainer>
    )}
    />,
    <Route
      key={`${scope}/:id/edit`}
      path={`${scope}/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="A/B Experiment" action="Edit" />
          <ExperimentEdit />
        </RouteContainer>
    )}
    />,
  ];
}
