import React, { useState, useEffect } from 'react';
import { useFlashMessages, PageSection } from '@tripledotstudios/react-core';
import { cloneDeep, isEqual, omit } from 'lodash';

import { DailyChallengesRoutes, AdminUsersRoutes } from '@pages/routes';
import { DuplicateButton, PageHeader } from '@pages/common';
import IconButton from '@controls/buttons';

import { clearUuids } from '@services/recursivelyProcessObject';

import { GlobalAttributes } from '@pages/journeys/level-banks/configuration-forms/woodoku/index';

import APP_DATA from '@services/appData';

import {
  Form, useCurrentApplication, useConfirm, FormGroup,
} from '@hooks';

import {
  Label,
  Field,
  SelectField,
  PriorityField,
  StaticFieldsFormGroup,
  FormButtonsGroup,
  LabelsFormGroup,
} from '@controls/form';

import { fromClassNameToOptions } from '@services/enums';

import RulesForm from '@pages/rules/Form';
import buildOnSubmit from '@services/journeys/banks/buildOnSubmit';

import { combineReducers, rulesReducer } from '@reducers';
import formReducer from '@pages/journeys/level-banks/formReducer';
import woodokuBankReducer from '@pages/journeys/level-banks/app-reducers/woodokuReducer';

import Levels from './form/Levels';

const { enums } = APP_DATA;
const statusesEnum = enums['DailyChallenges::LevelBankStatuses'];
const statuses = fromClassNameToOptions('DailyChallenges::LevelBankStatuses');
const tilesGenerationOptions = fromClassNameToOptions('Woodoku::DailyChallenges::TilesGenerationTypes', {
  transformLabel: (label) => {
    switch (label) {
      case 'Default':
        return 'Default (spawn acc to tile_spawn_chance)';
      case 'Exclude Reached Goal':
        return 'Stop spawn when collected';
      default:
        return label;
    }
  },
});

export default function DailyChallengeLevelBanksForm({
  data, title, onSubmit: onPageSubmit, entityName,
}) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const [adminUsers, setAdminUsers] = useState([]);
  const confirm = useConfirm();
  const { error } = useFlashMessages();

  const { status, readOnly, isStatusEditingDenied = null } = data;

  const isArchived = status === statusesEnum.ARCHIVED;

  useEffect(() => {
    AdminUsersRoutes.indexRequest({
      applicationId,
      withoutPagination: true,
      withTokens: true,
    }).then((usersResponse) => {
      setAdminUsers(usersResponse.data.items.map(({ id, email }) => ({ value: id, label: email })));
    });
  }, []);

  const markDirtyLevels = (values) => {
    const originalLevels = clearUuids(data.levelsAttributes).reduce(
      (acc, level) => ({ ...acc, [level.id]: omit(level, 'number') }),
      {},
    );
    const levelsAttributes = clearUuids(cloneDeep(values.levelsAttributes)).map((level) => {
      if (!level.id || !isEqual(originalLevels[level.id], omit(level, 'number'))) {
        return { ...level, dirty: true };
      }
      return level;
    });
    return {
      ...values,
      levelsAttributes,
      applicationId,
    };
  };

  const onSubmit = buildOnSubmit({
    applicationId,
    data,
    entityName,
    statusesEnum,
    onSubmitFunction: onPageSubmit,
    confirm,
    error,
    routes: DailyChallengesRoutes.LevelBanks,
  });

  return (
    <Form
      initialValues={data}
      onSubmit={(values) => onSubmit(markDirtyLevels(values))}
      reducer={combineReducers([rulesReducer, formReducer, woodokuBankReducer])}
      sharedInputProps={{ disabled: readOnly || isArchived }}
    >
      {({ values: { id, levelsAttributes, ruleSectionsAttributes } }) => (
        <>
          <PageHeader title={title}>
            <FormButtonsGroup cancelButtonPath={DailyChallengesRoutes.LevelBanks.indexPath({ applicationId })}>
              {id && (
                <>
                  <IconButton.ActivityLog
                    applicationId={applicationId}
                    id={id}
                    entityType="DailyChallenges::LevelBanks::Bank"
                  />
                  <FormButtonsGroup.Divider />
                </>
              )}
              <DuplicateButton
                routes={DailyChallengesRoutes.LevelBanks}
                data={data}
                entityName={entityName}
                modalType="withPriority"
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label text="Name">
            <Field type="text" name="name" disabled={readOnly} />
          </Label>
          <Label text="Status">
            <SelectField name="status" options={statuses} isDisabled={isStatusEditingDenied || readOnly} />
          </Label>
          <Label text="Priority">
            <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} />
          </Label>

          <LabelsFormGroup disabled={readOnly} />

          <StaticFieldsFormGroup data={data} />
          <GlobalAttributes levelsAttributes={levelsAttributes} levelsPath="levelsAttributes" readOnly={readOnly} />

          <FormGroup name="configAttributes" className="mb-4">
            <Label
              text="Users"
              tooltipText="Select users who should see this level bank in Level Editor"
            >
              <SelectField
                isMulti
                name="adminUserIds"
                options={adminUsers}
              />
            </Label>
            <PageSection title="Settings">
              <Label
                text="Spawn generation type"
                tooltipText="When “Stop spawn when collected” is selected → the system stops
            spawning special tile in FB when tile is collected"
                labelSize={5}
                fieldSize={7}
              >
                <SelectField
                  options={tilesGenerationOptions}
                  name="tilesGenerationType"
                />
              </Label>
            </PageSection>
          </FormGroup>

          <Levels levelsAttributes={levelsAttributes} readOnly={readOnly || isArchived} />
          <RulesForm ruleSectionsAttributes={ruleSectionsAttributes} disabled={isArchived} />
        </>
      )}
    </Form>
  );
}
