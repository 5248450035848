import React from 'react';
import styled from 'styled-components';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FaButton from '@pages/common/FaButton';

const RedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.variants.dark};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: rgb(200, 35, 51) !important;
  }
`;

export default function RemoveButton({
  className, size, color, onClick, ...rest
}) {
  return (
    <FaButton className={className} onClick={onClick} {...rest}>
      <RedIcon
        color={color}
        icon={faTrashAlt}
        size={size}
        {...rest}
      />
    </FaButton>
  );
}
