import React from 'react';
import styled from 'styled-components';
import { Accordion, Card } from 'react-bootstrap';

const CardWithOverflow = styled(Card)`
  overflow: visible !important;

  ${({ $readOnly }) => $readOnly && 'border: 1px solid #FF292970;'}
`;

const CardHeader = styled(Card.Header)`
  cursor: pointer;

  ${({ $readOnly }) => $readOnly && 'opacity: 0.6;'}
  ${({ $strikethroughText }) => $strikethroughText && 'text-decoration: line-through;'}
`;

const RightSideHeader = styled.span`
  position: absolute;
  right: 50px;

  ${({ top }) => top && `top: ${top}px;`}
`;

export default function Collapsible({
  onSelect = () => null,
  withErrors = false,
  className = '',
  opened = false,
  header,
  eventKey,
  activeKey,
  children,
  rightSideText,
  readOnly = false,
  rightSideTextProps = {},
  strikethroughText = false,
}) {
  return (
    <Accordion
      activeKey={activeKey}
      onSelect={onSelect}
      className={className}
      defaultActiveKey={opened ? eventKey : ''}
    >
      <Accordion.Item
        eventKey={eventKey}
        as={CardWithOverflow}
        $readOnly={readOnly}
      >
        <Accordion.Button
          as={CardHeader}
          $readOnly={readOnly}
          className={withErrors ? 'text-danger' : ''}
          $strikethroughText={strikethroughText}
        >
          {header}
          {rightSideText && (
            <RightSideHeader {...rightSideTextProps}>
              {rightSideText}
            </RightSideHeader>
          )}
        </Accordion.Button>
        <Accordion.Collapse eventKey={eventKey}>
          <CardWithOverflow.Body>
            {children}
          </CardWithOverflow.Body>
        </Accordion.Collapse>
      </Accordion.Item>
    </Accordion>
  );
}
