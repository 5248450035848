import React from 'react';

import APP_DATA from '@services/appData';

import {
  Form,
  FormGroup,
  useCurrentApplication,
  AbTestingContext,
  useConfirm,
  useI18n,
} from '@hooks';

import { fromClassNameToOptions } from '@services/enums';

import {
  Label,
  Field,
  SelectField,
  FormButtonsGroup,
  DateTimeField,
  PriorityField,
  AvailabilityStateFormGroup,
  StaticFieldsFormGroup,
  LabelsFormGroup,
} from '@controls/form';

import { DuplicateButton, PageHeader } from '@pages/common';
import RulesForm from '@pages/rules/Form';
import buildOnSubmit from '@services/journeys/banks/buildOnSubmit';
import { combineReducers, rulesReducer } from '@reducers';

import IconButton from '@controls/buttons';

import formReducer from './formReducer';

const statusesEnum = APP_DATA.enums['Journeys::Statuses'];

const DatesBasedForm = ({
  actionName, data, title, onSubmit, entityName, routes, localePath, appSpecificConfigs, massOperationsModel,
}) => {
  const { applicationKey, currentApplication: { id: applicationId } } = useCurrentApplication();
  const statuses = fromClassNameToOptions('Journeys::Statuses');
  const confirm = useConfirm();
  const { translate } = useI18n();

  const { status, configType } = data;

  const isArchived = status === statusesEnum.ARCHIVED;
  const isUpdate = Boolean(data.id);

  const { ConfigurationForm, reducers = [], preprocessSubmit } = appSpecificConfigs[applicationKey];

  const onFormSubmit = buildOnSubmit({
    applicationId,
    data,
    entityName,
    statusesEnum,
    onSubmitFunction: (values) => onSubmit(preprocessSubmit ? preprocessSubmit(values) : values),
    confirm,
    routes,
  });

  return (
    <AbTestingContext
      entityType={configType}
      entityId={data.configAttributes.id}
    >
      <Form
        initialValues={data}
        onSubmit={onFormSubmit}
        reducer={combineReducers([rulesReducer, formReducer, ...reducers])}
        sharedInputProps={{ disabled: isArchived }}
      >
        {({ values }) => (
          <>
            <PageHeader title={title}>
              <FormButtonsGroup
                cancelButtonPath={routes.indexPath({ applicationId })}
              >
                {isUpdate && (
                  <>
                    <IconButton.LookerJourney entityType={massOperationsModel} entityIds={[values.id]} />
                    <IconButton.ActivityLog
                      applicationId={applicationId}
                      id={data.id}
                      entityType="Journeys::Event"
                    />
                    <FormButtonsGroup.Divider />
                  </>
                )}
                <DuplicateButton
                  routes={routes}
                  data={data}
                  entityName={entityName}
                  modalType="withPriority"
                />
              </FormButtonsGroup>
            </PageHeader>

            {isUpdate && (
              <div className="mt-2">
                <Label text="ID">
                  <Field name="id" disabled />
                </Label>
                <Label text="Variant ID">
                  <Field name="configAttributes.currentVariantAttributes.id" disabled />
                </Label>
                <Label text="Client ID">
                  <Field name="clientId" disabled />
                </Label>
              </div>
            )}

            <Label text="Name">
              <Field type="text" name="name" disabled={false} />
            </Label>
            <Label text="Status">
              <SelectField name="status" options={statuses} />
            </Label>
            <AvailabilityStateFormGroup />
            <Label text="Priority">
              <PriorityField name="priority" entitiesByPriority={data.entitiesByPriority} />
            </Label>

            <LabelsFormGroup disabled={false} />
            <StaticFieldsFormGroup data={data} />

            <Label
              text="Start for client"
              tooltipText={translate.fallback(`${localePath}.tooltips.startForClient`)}
            >
              <DateTimeField as="date" name="startForClient" />
            </Label>
            <Label text="Start for player" tooltipText={translate.fallback(`${localePath}.tooltips.startAt`)}>
              <DateTimeField as="date" name="startAt" />
            </Label>
            <Label text="End for player/client" tooltipText={translate.fallback((`${localePath}.tooltips.endAt`))}>
              <DateTimeField as="date" name="endAt" />
            </Label>

            <FormGroup name="configAttributes">
              <ConfigurationForm
                isArchived={isArchived}
                actionName={actionName}
                defaultVariant
                values={values.configAttributes}
              />
            </FormGroup>

            <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} disabled={isArchived} />
          </>
        )}
      </Form>
    </AbTestingContext>
  );
};

export default DatesBasedForm;
