import React, { useState, useEffect } from 'react';
import {
  Tab,
  Tabs,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  capitalize, round, get, sum, omit, find, flatMap,
} from 'lodash';
import styled from 'styled-components';
import { Alert } from '@tripledotstudios/react-core';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import APP_DATA from '@services/appData';
import { fromClassNamesToOptions } from '@services/enums';
import { AbTestingRoutes } from '@pages/routes';
import cleanedUrl from '@root/services/cleanedUrl';
import {
  Form, FormGroup, useCurrentApplication, useI18n,
} from '@hooks';
import {
  FormButtonsGroup,
  Field,
  Label,
  DateTimeField,
  SelectField,
  ServerError,
  PriorityField,
  AvailabilityStateFormGroup,
  LabelsFormGroup,
  CheckboxField,
} from '@controls/form';
import {
  rulesReducer,
  newcomersRestrictionsReducer,
  frequencyRestrictionsReducer,
  basicRestrictionsReducer,
  adUnitsConfigurationReducer,
  combineReducers,
} from '@reducers';
import { RULES_LIST } from '@pages/rules/list';
import { persistedMap } from '@services/utils';
import RulesForm from '@pages/rules/Form';
import Tooltip from '@controls/Tooltip';
import IconButton from '@controls/buttons';
import {
  PageHeader, PageSection, UsageCountLabel, DuplicateButton,
} from '@pages/common';

import ExperimentEntitiesList from './form/ExperimentEntitiesList';
import Variant from './form/Variant';
import AddEmptyVariantButton from './form/AddEmptyVariantButton';
import VariantRuleGroups from './VariantRuleGroups';
import experimentsReducer from './reducer';
import InUseInRulesModal from './InUseInRulesModal';

const DateWithAddonWrapper = styled.div`
  width: calc((100% - 10px) * 0.75);
`;

const CheckboxLabel = styled.div`
  margin-left: 5px;
  margin-top: 8px;
`;

const reducer = combineReducers(
  [
    experimentsReducer,
    rulesReducer,
    newcomersRestrictionsReducer,
    frequencyRestrictionsReducer,
    basicRestrictionsReducer,
    adUnitsConfigurationReducer,
  ],
);

const AllocationField = ({
  isArchived, values, direction, dispatch,
}) => {
  const allocationField = `allocation${capitalize(direction)}At`;
  const checkboxField = `separateAllocation${capitalize(direction)}`;

  const onCheckboxClick = (e) => {
    dispatch({
      type: 'changeSeparateAllocationDate',
      checked: e.target.checked,
      checkbox: checkboxField,
      source: `${direction}At`,
      target: allocationField,
    });
  };

  return (
    <div className="d-flex">
      <DateWithAddonWrapper>
        <DateTimeField
          name={allocationField}
          disabled={isArchived || !values[checkboxField]}
        />
      </DateWithAddonWrapper>
      <CheckboxLabel>
        <CheckboxField
          name={checkboxField}
          onChange={onCheckboxClick}
          disabled={isArchived}
        />
        <span>Use different date</span>
      </CheckboxLabel>
    </div>
  );
};

const ButtonsGroup = ({
  routingScope, applicationId, values, dirty,
}) => (
  <FormButtonsGroup cancelButtonPath={`${routingScope}/ab_testing/experiments`}>
    {values.id && (
      <>
        <IconButton.LookerAb id={values.id} />
        <IconButton.LookerAb type="cohort" id={values.id} />
        <IconButton.LookerAb type="lite" id={values.id} />
        <IconButton.ActivityLog
          applicationId={applicationId}
          id={values.id}
          entityType="AbTesting::Experiment"
        />
        <FormButtonsGroup.Divider />
        <DuplicateButton
          data={values}
          routes={AbTestingRoutes.Experiments}
          disabled={dirty}
          entityName="A/B Experiment"
          modalType="withPriority"
        />
      </>
    )}
  </FormButtonsGroup>
);

const { enums: { AbExperimentStatuses: statusesEnum } } = APP_DATA;

export default function ExperimentsForm({ action, data, onSubmit }) {
  const { currentApplication: { lookerAbName, adminSettings } } = useCurrentApplication();
  const params = new URLSearchParams({ 'App Name': lookerAbName }).toString();
  const { adminSettings: adminSettingsFromAppData } = APP_DATA;
  const labSizingToolLink = [cleanedUrl(adminSettingsFromAppData.lookerLabSizingToolUrl), params].join('?');

  const [showInUseModal, setShowInUseModal] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState('#0');

  const { translate } = useI18n();

  const [
    audiencesOptions, statusesOptions,
  ] = fromClassNamesToOptions([
    'AbExperimentAudiences',
    'AbExperimentStatuses',
    'AvailabilityStates',
  ]);

  const variantHasErrors = (index) => get(data, `_meta.errors.variantsAttributes.${index}`, false);

  const buildVariantTabTitle = (index, variants, currentVariant) => {
    if (currentVariant._destroy) {
      const className = variantHasErrors(index) ? 'text-danger' : 'text-muted';
      return (
        <>
          <FontAwesomeIcon icon={faTrash} className={`${className} pe-1`} />
          <span className={className}>{currentVariant.name}</span>
        </>
      );
    }
    const weightSum = variants.reduce((acc, value) => acc + (value._destroy ? 0 : value.weight), 0);
    const probability = round((currentVariant.weight / weightSum) * 100, 2);

    return `${currentVariant.name} (${Number.isNaN(probability) ? 0 : probability}%)`;
  };

  const { currentApplication, routingScope } = useCurrentApplication();

  const handleTabChange = (key) => setActiveTabIndex(key);

  useEffect(() => {
    if (data._meta?.errors?.status?.some((error) => error.includes('which uses this experiment as Variant rule'))) {
      if (Object.keys(data._meta?.errors).length === 2) {
        setShowInUseModal(true);
      } else {
        // eslint-disable-next-line no-param-reassign
        delete (data._meta.errors.status);
      }
      setStatusError(true);
    }
  }, [data._meta]);

  return (
    <Form
      initialValues={{ ...data, applicationId: currentApplication.id, originalExperimentId: data.id }}
      onSubmit={onSubmit}
      reducer={reducer}
      onLoad={({ dispatch }) => dispatch({ type: 'setDefaults', silent: true })}
    >
      {({
        values, initialValues, dispatch, setFieldValue, submitForm, dirty,
      }) => {
        const variantRule = find(RULES_LIST, (rule) => rule.type === values.variantRuleType) || { component: null };
        const checkIsArchived = (status) => (
          status === statusesEnum.ARCHIVED || status === statusesEnum.PERMANENTLY_ARCHIVED
        );
        const subVariantIds = flatMap(
          values.variantsAttributes,
          (v) => (persistedMap(v.subVariantsAttributes, (sv) => sv.id)),
        );

        const isArchived = checkIsArchived(values.status);
        const wasArchived = checkIsArchived(initialValues.status);

        const { daysOfGracePeriod, gracePeriodLeft } = initialValues;

        const isPermanentlyArchived = initialValues.status === statusesEnum.PERMANENTLY_ARCHIVED;

        let statusExplainer = '';
        if (isPermanentlyArchived) {
          statusExplainer = <span>Experiment is permanently archived</span>;
        } else if (isArchived && wasArchived && !statusError) {
          statusExplainer = (
            <Tooltip
              text={'After grace period related participations will be deleted and'
                + 'A/B experiment will not be available to work'}
              placement="right"
            >
              <small>{`grace period left: ${gracePeriodLeft}`}</small>
            </Tooltip>
          );
        } else if (isArchived) {
          statusExplainer = (
            <small>
              {`${daysOfGracePeriod} days grace period will start after save of experiment`}
            </small>
          );
        }
        const totalParticipants = sum(Object.values(omit(values.participationsCountPerVariant, '_uuid')));
        const modalOnSave = () => {
          setFieldValue('force', true);
          submitForm();
          setShowInUseModal(false);
        };

        const modalOnClose = () => {
          setFieldValue('force', false);
          setShowInUseModal(false);
        };

        return (
          <>
            <PageHeader title={`${action} A/B Experiment`} userGuideUrlKey="abExperimentsGuideUrl">
              <ButtonsGroup
                applicationId={currentApplication.id}
                routingScope={routingScope}
                values={values}
                dirty={dirty}
              />
            </PageHeader>

            {action === 'New' && adminSettings.enableRedirectionToLabSizingTool && (
              <Alert variant="info">
                {'Have you determined the sample size you need for your experiment? '}
                <Link to={labSizingToolLink} target="_blank">
                  Go to LAB Sizing Tool
                </Link>
              </Alert>
            )}

            <Field type="hidden" name="applicationId" />

            <FormGroup>
              <ServerError name="id" className="mb-4" />

              <Label text="Name" required>
                <Field type="text" name="name" disabled={false} />
              </Label>

              <Label text="Status" required>
                {!isPermanentlyArchived && <SelectField name="status" options={statusesOptions} />}
                {statusExplainer}
              </Label>

              <Label text="Audience Types" tooltipText={translate.fallback('abTesting.tooltips.audienceType')} required>
                <SelectField
                  name="audienceType"
                  options={audiencesOptions}
                  isDisabled={isArchived}
                />
              </Label>

              <Label text="Priority" required>
                <PriorityField entitiesByPriority={data.entitiesByPriority} name="priority" disabled={isArchived} />
              </Label>

              <Label text="Use Local time" tooltipText={translate.fallback('abTesting.tooltips.useLocalTime')}>
                <CheckboxField name="useLocalTime" disabled={isArchived} />
              </Label>

              <Label text="Start At" tooltipText={translate.fallback('abTesting.tooltips.startAt')} required>
                <DateTimeField
                  as="date"
                  name="startAt"
                  disabled={isArchived}
                  onChange={(date) => {
                    if (!values.separateAllocationStart) { setFieldValue('allocationStartAt', date); }
                  }}
                />
              </Label>

              <Label
                text="Allocation start"
                fieldSize={8}
                tooltipText={translate.fallback('abTesting.tooltips.allocationStartAt')}
              >
                <AllocationField direction="start" values={values} isArchived={isArchived} dispatch={dispatch} />
              </Label>

              <Label text="End At" tooltipText={translate.fallback('abTesting.tooltips.endAt')}>
                <DateTimeField
                  as="date"
                  name="endAt"
                  disabled={isArchived}
                  onChange={(date) => {
                    if (!values.separateAllocationEnd) { setFieldValue('allocationEndAt', date); }
                  }}
                />
              </Label>

              <Label
                text="Allocation end"
                fieldSize={8}
                tooltipText={translate.fallback('abTesting.tooltips.allocationEndAt')}
              >
                <AllocationField direction="end" values={values} isArchived={isArchived} dispatch={dispatch} />
              </Label>

              <Label
                text="Maximum audience size"
                tooltipText={translate.fallback('abTesting.tooltips.maxParticipants')}
              >
                <Field name="maxParticipants" disabled={isArchived} type="number" />
              </Label>

              <AvailabilityStateFormGroup disabled={isArchived} />

              <LabelsFormGroup disabled={false} />

              <Label
                text="Include in LAB calculations"
                tooltipText="Uncheck this checkbox if this experiment does not need to be analysed on LAB"
              >
                <CheckboxField
                  name="includedInLab"
                  disabled={isArchived || values.labExclusionGracePeriodEnded}
                  htmlFieldProps={{
                    title: values.labExclusionGracePeriodEnded
                      && translate.fallback('abTesting.tooltips.labExclusionExpired'),
                  }}
                />
              </Label>

              <Label text="Total Participants">
                {isPermanentlyArchived && totalParticipants === 0 ? 'N/A' : totalParticipants.toLocaleString()}
              </Label>

              <UsageCountLabel fieldSize={8} data={data} entityTooltipText="experiment" />

              <Label text="Notes">
                <Field name="notes" as="textarea" />
              </Label>
            </FormGroup>

            <ExperimentEntitiesList disabled={isArchived} />

            {values.variantsAttributes
              && values.variantsAttributes.length > 0
              && <PageSection title="Variants" />}
            <VariantRuleGroups dispatch={dispatch} values={values} isArchived={isArchived} variantRule={variantRule} />
            <AddEmptyVariantButton disabled={isArchived} handleTabChange={handleTabChange} />
            <Tabs
              activeKey={activeTabIndex}
              onSelect={handleTabChange}
            >
              {values.variantsAttributes
                && values.variantsAttributes.length > 0
                && values.variantsAttributes.map((attributes, index) => (
                  <Tab
                    key={attributes._uuid}
                    eventKey={`#${index}`}
                    title={buildVariantTabTitle(index, values.variantsAttributes, attributes)}
                    className="mt-3"
                    tabClassName={variantHasErrors(index) ? 'text-danger' : ''}
                  >
                    {/* eslint-disable react/no-array-index-key */}
                    <Variant
                      key={index}
                      index={index}
                      isPermanentlyArchived={isPermanentlyArchived}
                      experimentId={values.id}
                      disabled={isArchived}
                      participationsCount={values.participationsCountPerVariant[attributes.id] || 0}
                      variantRule={variantRule}
                      subVariantIds={subVariantIds}
                      variantRuleType={values.variantRuleType}
                      handleTabChange={handleTabChange}
                      {...attributes}
                    />
                    {/* eslint-enable react/no-array-index-key */}
                  </Tab>
                ))}
            </Tabs>

            <RulesForm ruleSectionsAttributes={values.ruleSectionsAttributes} disabled={isArchived} />

            {data.id && (
              <InUseInRulesModal
                entity={data}
                show={showInUseModal}
                onClose={modalOnClose}
                onSave={modalOnSave}
              />
            )}
          </>
        );
      }}
    </Form>
  );
}
