import React, { useState, useEffect } from 'react';
import { Spinner, useRouter } from '@tripledotstudios/react-core';

import { DuplicateButton, PageHeader } from '@pages/common';
import { DailyRewardsRoutes as Routes } from '@pages/routes';
import IconButton from '@controls/buttons';

import {
  FormButtonsGroup,
  StaticFieldsFormGroup,
  Label,
  Field,
  LabelsFormGroup,
  SelectField,
  localizedLabelFactory,
} from '@controls/form';
import { requestWithToSelectOptions } from '@services/toSelectOptions';

import { Form } from '@hooks';

const LocalizedLabel = localizedLabelFactory('dailyRewards.config.form');

const banksToSelectOptions = ({ data: { items } }) => (
  items.map(({ id, name, daysCount }) => ({ value: id, label: `${name} (${daysCount} day(s))` }))
);

export default function DailyRewardsConfigsForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();
  const [bankOptions, setBankOptions] = useState();

  useEffect(() => {
    requestWithToSelectOptions(
      Routes.Banks.indexRequest,
      applicationId,
      { includeInUse: false },
      { toSelectOptionsFn: banksToSelectOptions },
    ).then(setBankOptions);
  }, []);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Daily Rewards Config`}>
            <FormButtonsGroup cancelButtonPath={Routes.Configs.indexPath({ applicationId })}>
              {data.id && (
                <>
                  <IconButton.ActivityLog
                    applicationId={applicationId}
                    id={data.id}
                    entityType="DailyRewards::Config"
                  />
                  <FormButtonsGroup.Divider />
                </>
              )}
              <DuplicateButton
                routes={Routes.Configs}
                data={data}
                entityName="Daily Rewards Config"
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label text="Name">
            <Field type="text" name="name" />
          </Label>

          <LabelsFormGroup />

          <StaticFieldsFormGroup data={data} />

          {bankOptions ? (
            <>
              <LocalizedLabel name="rewardsBankNonRepeatableId">
                <SelectField
                  includeEmpty
                  name="rewardsBankNonRepeatableId"
                  options={bankOptions}
                  quickLinkFn={(id) => Routes.Banks.editPath({ applicationId, id })}
                />
              </LocalizedLabel>
              <LocalizedLabel name="rewardsBankNonRepeatableBackupBankEnabled">
                <Field name="rewardsBankNonRepeatableBackupBankEnabled" type="checkbox" />
              </LocalizedLabel>
              <LocalizedLabel name="rewardsBankNonRepeatableBackupId">
                <SelectField
                  includeEmpty
                  name="rewardsBankNonRepeatableBackupId"
                  options={bankOptions}
                  quickLinkFn={(id) => Routes.Banks.editPath({ applicationId, id })}
                  isDisabled={!values.rewardsBankNonRepeatableBackupBankEnabled}
                />
              </LocalizedLabel>

              <LocalizedLabel name="rewardsBankRepeatableId">
                <SelectField
                  includeEmpty
                  name="rewardsBankRepeatableId"
                  options={bankOptions}
                  quickLinkFn={(id) => Routes.Banks.editPath({ applicationId, id })}
                />
              </LocalizedLabel>
              <LocalizedLabel name="rewardsBankRepeatableBackupEnabled">
                <Field name="rewardsBankRepeatableBackupEnabled" type="checkbox" />
              </LocalizedLabel>
              <LocalizedLabel name="rewardsBankRepeatableBackupId">
                <SelectField
                  includeEmpty
                  name="rewardsBankRepeatableBackupId"
                  options={bankOptions}
                  quickLinkFn={(id) => Routes.Banks.editPath({ applicationId, id })}
                  isDisabled={!values.rewardsBankRepeatableBackupEnabled}
                />
              </LocalizedLabel>
            </>

          ) : (<Spinner />)}
        </>
      )}
    </Form>
  );
}
