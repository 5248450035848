import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { compact, last, sortBy } from 'lodash';
import { ButtonToolbar, IconButton } from '@tripledotstudios/react-core';

import { Form, useWebSocketChannel } from '@hooks';
import { MESSAGE_TYPES } from '@hooks/useWebSocketChannel';
import { PageHeader } from '@pages/common';
import {
  Field, FileField, Label,
} from '@controls/form';
import { CancelButton } from '@controls/form/buttons';
import { InputGroup } from 'react-bootstrap';
import { CopyToClipboardAddon } from '@controls/CopyToClipboardButton';

const LogArea = styled.div`
  padding: 10px 20px;
  white-space: break-spaces;
  border-radius: 5px;
`;

export default function AsyncFileProcessingForm({
  onSubmit, data, disabled, refetch, title, websocketsPath, cancelPath, accept, FormFields, Result,
}) {
  const [status, setStatus] = useState(null);

  const { messages } = useWebSocketChannel({ path: websocketsPath });
  const orderedMessages = sortBy(messages.filter((m) => m.type === MESSAGE_TYPES.CHANNEL), 'data.payload.number');
  const messagesLog = compact(orderedMessages.map((m) => m.data?.payload?.message)).join('\n');
  useEffect(() => {
    const newMessageData = last(orderedMessages)?.data?.payload || {};
    switch (newMessageData.type) {
      case 'completed':
        refetch();
        break;
      case 'status_change':
        setStatus(newMessageData.status);
        break;
      default: break;
    }
  }, [orderedMessages.length]);

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      sharedInputProps={{ disabled }}
    >
      {({ values }) => (
        <>
          <PageHeader title={title}>
            <ButtonToolbar>
              {!disabled && <IconButton.Upload type="submit" />}
              <CancelButton to={cancelPath} text="Back" />
            </ButtonToolbar>
          </PageHeader>
          {FormFields && <FormFields values={values} />}
          <Label text="File">
            {values.id ? (
              <InputGroup>
                <Field name="fileUrl" disabled />
                <CopyToClipboardAddon text={data.fileUrl} />
              </InputGroup>
            ) : <FileField name="file" accept={accept} />}
          </Label>
          {values.id && (
            <>
              <Label text="Status">{status || values.statusHumanize}</Label>
              {Result && <Result values={values} />}
              <Label text="Log" direction="vertical">
                <LogArea className="bg-light">
                  {values.log || messagesLog}
                </LogArea>
              </Label>
            </>
          )}
        </>
      )}
    </Form>
  );
}
