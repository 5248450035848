import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FaIcon } from '@pages/common/FaButton';

export default function AddIcon({ onClick }) {
  return (
    <div style={{ paddingTop: '0.5rem' }}>
      <FaIcon
        color="green"
        icon={faPlus}
        onClick={onClick}
      />
    </div>
  );
}
