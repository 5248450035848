export default function reducer(state, action) {
  const { type } = action;

  switch (type) {
    case 'updateBoardValue': {
      const { value } = action;

      state.coordinates = value;
      break;
    }
    case 'resetBoard': {
      if (state.figureConfig) state.figureConfig = '';
      state.coordinates = null;

      break;
    }

    default: break;
  }

  return state;
}
