import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useCurrentApplication } from '@hooks';
import { generateChapterBasedRoutes } from '@pages/routes';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function New() {
  const { applicationName, currentApplication: { id: applicationId } } = useCurrentApplication();
  const Routes = generateChapterBasedRoutes(applicationName).UiConfigs;
  const [data, setData] = useState();
  const { query } = useRouter();
  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Ui configuration',
    actionName: 'creation',
    redirectPath: Routes.indexPath({ applicationId }),
    setData,
  });

  const onSubmit = (values) => Routes.createRequest(values).then((response) => responseHandler(response, values));

  useEffect(() => {
    Routes.newRequest(query).then((response) => setData(response.data));
  }, []);

  return data ? <Form data={data} onSubmit={onSubmit} setData={setData} /> : '';
}
