import React from 'react';
import { useTheme } from 'styled-components';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { AdsRoutes } from '@pages/routes';

export default function AdsNavigationSection({ applicationId, minimized = false }) {
  const theme = useTheme();

  return (
    <Sidebar.SectionItem
      icon={faNewspaper}
      title="Ads"
      color={theme.lightPurple}
      minimized={minimized}
    >
      <Sidebar.LinkItem
        to={AdsRoutes.Configurations.indexPath({ applicationId })}
        title="Configurations"
      />

      <Sidebar.LinkItem
        to={AdsRoutes.AdUnitCampaigns.indexPath({ applicationId })}
        title="Ad Unit Campaigns"
      />

      <Sidebar.LinkItem
        to={AdsRoutes.Interstitials.Placements.indexPath({ applicationId })}
        title="Interstitial Placements"
      />
      <Sidebar.LinkItem
        to={AdsRoutes.Interstitials.PlacementBanks.indexPath({ applicationId })}
        title="Interstitial Placement Banks"
      />

      <Sidebar.LinkItem
        to={AdsRoutes.RewardedVideos.Placements.indexPath({ applicationId })}
        title="Rewarded Video Placements"
      />
      <Sidebar.LinkItem
        to={AdsRoutes.RewardedVideos.PlacementBanks.indexPath({ applicationId })}
        title="Rewarded Video Placement Banks"
      />
      <Sidebar.LinkItem
        to={AdsRoutes.Badges.Placements.indexPath({ applicationId })}
        title="Badge Placements"
      />
      <Sidebar.LinkItem
        to={AdsRoutes.Badges.PlacementBanks.indexPath({ applicationId })}
        title="Badge Placement Banks"
      />
    </Sidebar.SectionItem>
  );
}
