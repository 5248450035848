import React from 'react';

import { useFormContext, FormGroup } from '@hooks';
import { Field, localizedLabelFactory } from '@controls/form';

import EventBanks from './EventBanks';

const LocalizedLabel = localizedLabelFactory('myPetCafe.winStreaks.configs.fields');

export default function VariantForm({
  values, defaultVariant, variantIndex, isArchived,
}) {
  const { dispatch } = useFormContext();
  const onBankAdd = (id) => dispatch({
    actionType: 'addBankToWinStreakConfig', id, variantIndex,
  });
  const onBankRemove = (id) => dispatch({
    actionType: 'removeBankFromWinStreakConfig', id, variantIndex,
  });

  const variantAttributesKey = defaultVariant ? 'currentVariantAttributes' : '';

  return (
    <FormGroup name={variantAttributesKey}>
      <LocalizedLabel name="bypassStart">
        <Field name="bypassStart" type="checkbox" />
      </LocalizedLabel>

      <EventBanks
        variantAttributes={values}
        disabled={isArchived}
        onBankAdd={onBankAdd}
        onBankRemove={onBankRemove}
      />
    </FormGroup>
  );
}
