import React from 'react';
import IndexPage from '@pages/puzzle-time/ConfigIndex';
import { useRouter } from '@tripledotstudios/react-core';
import { getAdsConfigs, deleteAdsConfig } from '@requests/puzzle-time/adsConfigs';

export default function Index() {
  const { query } = useRouter();

  return (
    <IndexPage
      configName="Ads Config"
      path={`/admin/applications/${query.applicationId}/puzzle_time/ads_configs`}
      getConfigFunction={getAdsConfigs}
      deleteConfigFunction={deleteAdsConfig}
    />
  );
}
