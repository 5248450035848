import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';

import { useFlashMessages } from '@tripledotstudios/react-core';

const CopyButton = styled(CopyToClipboard)`
  cursor: pointer;
  margin-left: 10px;
`;

export default function CopyToClipboardButton({
  text, onCopy = () => { }, title = 'Click to copy to the clipboard', children,
}) {
  const { success } = useFlashMessages();

  const onCopyWithFlash = () => {
    onCopy();
    success('Copied to clipboard');
  };

  return (
    <CopyButton text={text} onCopy={onCopyWithFlash} title={title}>
      {children || <FontAwesomeIcon size="lg" icon={faClipboard} />}
    </CopyButton>
  );
}

export function CopyToClipboardAddon({ ...props }) {
  return (
    <CopyToClipboardButton {...props}>
      <Button type="button">
        <FontAwesomeIcon size="lg" icon={faClipboard} className="pe-2" />
        Copy
      </Button>
    </CopyToClipboardButton>
  );
}
