import React from 'react';

import { newAppConfig, createAppConfig } from '@requests/my-pet-cafe/appConfigs';
import CreateUpdateConfig from './CreateUpdateConfig';

export default function New() {
  return (
    <CreateUpdateConfig
      fetchFunction={newAppConfig}
      saveFunction={createAppConfig}
      actionName="creation"
    />
  );
}
