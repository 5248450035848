import React from 'react';

import { PageSection } from '@pages/common';
import {
  FormButtonsGroup, Label, Field, MultipleValuesField, CheckboxField,
} from '@controls/form';
import { Form, useQuery } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { AdminRoutes } from '@pages/routes';
import LinksToUserGuides from './LinksToUserGuides';

const AdminSettingsForm = () => {
  const { response, setResponse } = useQuery(AdminRoutes.Settings.Admin.editRequest);

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Admin Settings',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => AdminRoutes.Settings.Admin.updateRequest(values).then(responseHandler);

  return response && (
    <Form initialValues={response} onSubmit={onSubmit}>
      {() => (
        <>
          <PageSection title="Admin Settings" size="md" actions={<FormButtonsGroup hideCancel />} />

          <Label text="Analyse Experiment URL (LAB)" fieldSize={8}>
            <Field name="lookerAbExperimentsUrl" />
          </Label>
          <Label text="Analyse Experiment URL (LAB Cohort)" fieldSize={8}>
            <Field name="lookerAbExperimentsCohortUrl" />
          </Label>
          <Label text="Analyse Experiment URL (LAB Lite)" fieldSize={8}>
            <Field name="lookerAbExperimentsLiteUrl" />
          </Label>
          <Label
            tooltipText="This feature is available only if it is enabled in game specific settings below"
            text="Link to LAB Sizing Tool"
            fieldSize={8}
          >
            <Field name="lookerLabSizingToolUrl" />
          </Label>
          <Label
            tooltipText="This feature is available only if it is enabled in game specific settings below"
            text="Link Journey Key Metrics"
            fieldSize={8}
          >
            <Field name="lookerJourneyKeyMetricsUrl" />
          </Label>

          <Label text="Contact Us Recepients" fieldSize={8}>
            <MultipleValuesField
              name="contactUsRecepientsEmails"
              InputComponent={Field}
              handleCollectionErrors={false}
            />
          </Label>
          <Label
            tooltipText={`
              Allow to add tab "JSON Baked-in Config" into Game settings and add there JSON which will be
              used as part of response in addition to other Game settings
            `}
            text="Enable JSON Baked-in Config in Game Settings"
            fieldSize={8}
          >
            <CheckboxField name="enableBakedInConfigs" />
          </Label>

          <LinksToUserGuides />
        </>
      )}
    </Form>
  );
};

export default AdminSettingsForm;
