import BanksIndex from './banks/Index';
import BanksNew from './banks/New';
import BanksEdit from './banks/Edit';

import ConfigsIndex from './configs/Index';
import ConfigsNew from './configs/New';
import ConfigsEdit from './configs/Edit';

export const Banks = { Index: BanksIndex, New: BanksNew, Edit: BanksEdit };
export const Configs = { Index: ConfigsIndex, New: ConfigsNew, Edit: ConfigsEdit };
