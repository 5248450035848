import React from 'react';
import styled from 'styled-components';

const PriorityCell = styled.div`
  width: 70px;
`;

const PriorityColumn = {
  Header: 'Priority',
  accessor: 'priority',
  Cell: ({ row }) => (
    <PriorityCell>
      {row?.original?.priority}
    </PriorityCell>
  ),
};

export default PriorityColumn;
