import React from 'react';

import { SpiderSolitaireRoutes } from '@pages/routes';

import CreateUpdateComponent from './CreateUpdateComponent';

export default function New() {
  return (
    <CreateUpdateComponent
      getFunction={SpiderSolitaireRoutes.WorldStatsConfig.newRequest}
      postFunction={SpiderSolitaireRoutes.WorldStatsConfig.createRequest}
      actionName="creation"
    />
  );
}
