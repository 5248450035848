import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useFormContext } from '@hooks';
import {
  Field,
  SelectField,
  localizedLabelFactory,
  TagsField,
} from '@controls/form';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { MissionsRoutes, MyPetCafeRoutes } from '@pages/routes';
import { PageSection } from '@pages/common';

import LevelsListWrapper from '@components/journeys/banks/levels/LevelsListWrapper';
import Milestones from './Milestones';

const LocalizedLabel = localizedLabelFactory('myPetCafe.adventures.banks.events.fields');

export default function Events({ events, isArchived }) {
  const [missionTypeOptions, setMissionTypeOptions] = useState([]);
  const { dispatch } = useFormContext();
  const { query: { applicationId } } = useRouter();

  const onEntityAdd = () => dispatch({ actionType: 'addEmptyEntity' });

  useEffect(() => {
    (async () => {
      const missionTypes = await requestWithToSelectOptions(MissionsRoutes.MissionTypes.indexRequest, applicationId);

      setMissionTypeOptions(missionTypes);
    })();
  }, []);

  return (
    <LevelsListWrapper
      entityAttributes={events}
      onEntityAdd={onEntityAdd}
      attributesName="eventsAttributes"
      metaErrorsPath={['errors', 'eventsAttributes']}
      entityNameTranslationPath="adventures.banks.events.name"
      bulkCopyRoutes={MyPetCafeRoutes.Adventures.EventBanks}
      disabled={isArchived}
    >
      {({ index, entity, readOnly }) => (
        <>
          <LocalizedLabel name="name">
            <Field type="text" name="name" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="missionTypeId">
            <SelectField
              name="missionTypeId"
              options={missionTypeOptions}
              isDisabled={readOnly}
            />
          </LocalizedLabel>
          <LocalizedLabel name="parameters">
            <TagsField
              name="parametersAttributes"
              propertyName="value"
              placeholder="Add a parameter..."
              disabled={readOnly}
              inline
            />
          </LocalizedLabel>
          <LocalizedLabel name="startDay">
            <Field type="number" name="startDay" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="duration">
            <Field type="number" name="duration" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="assetKey">
            <Field type="text" name="assetKey" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="prefabKey">
            <Field type="text" name="prefabKey" disabled={readOnly} />
          </LocalizedLabel>

          <PageSection title="Event Start Popup" />
          <LocalizedLabel name="startPopupDescription">
            <Field type="text" name="startPopupDescription" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="startPopupHint">
            <Field type="text" name="startPopupHint" disabled={readOnly} />
          </LocalizedLabel>
          <PageSection title="Main Event Popup" />
          <LocalizedLabel name="mainPopupDescription">
            <Field type="text" name="mainPopupDescription" disabled={readOnly} />
          </LocalizedLabel>

          <PageSection title="Help Popup for event" />
          <LocalizedLabel name="helpPopupDescription">
            <Field type="text" name="helpPopupDescription" disabled={readOnly} />
          </LocalizedLabel>
          <LocalizedLabel name="helpPopupHint">
            <Field type="text" name="helpPopupHint" disabled={readOnly} />
          </LocalizedLabel>
          <Milestones
            eventIndex={index}
            milestones={entity.milestonesAttributes}
            readOnly={readOnly || isArchived}
          />
        </>
      )}
    </LevelsListWrapper>
  );
}
