import {
  fetchCollection, newResource, editResource, createResource, updateResource, deleteResource,
} from '@requests/common';

const baseUrl = ({
  applicationId,
}) => `/api/admin/applications/${applicationId}/puzzle_time/single_screen_tutorial_configs`;

export function getSingleScreenTutorialConfigs(data, options) {
  return fetchCollection(baseUrl(data), data, options);
}

export function newSingleScreenTutorialConfig(options) {
  return newResource(baseUrl(options), options);
}

export function editSingleScreenTutorialConfig(data, options) {
  return editResource(baseUrl(data), data, options);
}

export function createSingleScreenTutorialConfig(values) {
  return createResource(baseUrl(values), values);
}

export function updateSingleScreenTutorialConfig(values) {
  return updateResource(baseUrl(values), values);
}

export function deleteSingleScreenTutorialConfig(values) {
  return deleteResource(baseUrl(values), values);
}
