import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import { MyPetCafeRoutes } from '@pages/routes';
import ExperimentScopedTabs from '@pages/ab-testing/experiments/ExperimentScopedTabs';
import VariantForm from './VariantForm';
import reducer from './reducer';

export default function ExperimentScoped() {
  const { response, setResponse } = useQuery(MyPetCafeRoutes.SoloMissions.Configs.experimentScopedRequest);
  const { query: { applicationId } } = useRouter();

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Solo Mission Config variant',
    actionName: 'update',
    setData: setResponse,
  });

  const handleSubmit = (values) => MyPetCafeRoutes.SoloMissions.ConfigVariants
    .updateVariantsRequest({ applicationId, ...values }).then(responseHandler);

  return (
    <ExperimentScopedTabs
      response={response}
      entityName="Solo Mission Config"
      handleSubmit={handleSubmit}
      reducer={reducer}
    >
      {({ variantAttributes, variantIndex }) => (
        <VariantForm values={variantAttributes} variantIndex={variantIndex} />
      )}
    </ExperimentScopedTabs>
  );
}
