import React, { useState } from 'react';
import { get } from 'lodash';
import { Modal } from 'react-bootstrap';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import { useFormContext } from '@hooks';

import IconButton from '@controls/buttons';

export default function PropertyModal({
  propertyName,
  variantIndex,
  index,
  children,
}) {
  const [showModal, setShowModal] = useState(false);
  const { meta } = useFormContext();

  const fieldHasErrors = get(
    meta, `errors.variantsAttributes.${variantIndex}.experimentEntitiesAttributes.${index}`, false,
  );

  const onHide = () => setShowModal(false);

  return (
    <>
      <IconButton
        icon={faUpRightFromSquare}
        variant={fieldHasErrors ? 'danger' : 'primary'}
        size="sm"
        onClick={() => setShowModal(true)}
      >
        Edit
      </IconButton>

      <Modal
        show={showModal}
        size="lg"
        onHide={onHide}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Edit property "${propertyName}"`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <IconButton.Confirm onClick={onHide} />
        </Modal.Footer>
      </Modal>
    </>
  );
}
