import { sortBy, get, set } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';
import { persistedCollection } from '@services/utils';
import { moveUp, moveDown } from '@services/reorder';

import { maxPosition } from '@reducers/chapterRangeListReducer';

const formReducer = (state, action) => {
  const nonDestroyedMidchapterConfigs = (range) => persistedCollection(range.midchapterConfigsAttributes);
  const sortedMidchapterConfigs = (range) => sortBy(nonDestroyedMidchapterConfigs(range), 'position');

  const freshMidchapterCollection = (range) => {
    let maxPos = 0;

    return sortBy(range.midchapterConfigsAttributes, 'position').map((config) => {
      if (!config._destroy) {
        config.position = maxPos;
        maxPos += 1;
      }

      return config;
    });
  };

  const swapRangeValues = (processedState, sourcePath, targetPath) => {
    const source = get(processedState, sourcePath);
    const target = get(processedState, targetPath);
    const { toChapter, fromChapter } = source;
    set(processedState, sourcePath, { ...source, toChapter: target.toChapter, fromChapter: target.fromChapter });
    set(processedState, targetPath, { ...target, toChapter, fromChapter });
  };

  switch (action.type) {
    case 'addMidchapterConfig': {
      const { chapterRangeUuid } = action;
      const chapterRange = state.chapterRangesAttributes.find((range) => range._uuid === chapterRangeUuid);

      const position = maxPosition(sortedMidchapterConfigs(chapterRange)) + 1;

      chapterRange.midchapterConfigsAttributes.push(
        addUuid({ position, _destroy: false, rewardAffiliationsAttributes: [] }),
      );

      break;
    }
    case 'removeMidchapterConfig': {
      const { chapterRangeUuid, _uuid } = action;
      const chapterRange = state.chapterRangesAttributes.find((range) => range._uuid === chapterRangeUuid);
      const toDestroy = chapterRange.midchapterConfigsAttributes.find((config) => config._uuid === _uuid);
      toDestroy._destroy = true;

      chapterRange.midchapterConfigsAttributes = freshMidchapterCollection(chapterRange);

      break;
    }
    case 'moveDown': {
      moveDown(state, ['chapterRangesAttributes'], action, 'position', swapRangeValues);

      return state;
    }
    case 'moveUp': {
      moveUp(state, ['chapterRangesAttributes'], action, 'position', swapRangeValues);

      return state;
    }
    default: break;
  }

  return state;
};

export default formReducer;
