import React from 'react';

import {
  GameSettingsTabs,
  localizationsDefaultFieldNames,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  appEventsFieldNames,
  assetsDefaultFieldNames,
  adRequestDelayFieldNames,
  adPlacementIdsDefaultFieldNames,
  adConfigsDefaultFieldNames,
} from '@components/game-settings/Resource';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'areaSetId',
      'chapterSetId',
      'figureSetId',
      'abilitiesSetId',
      ...assetsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [
      ...adRequestDelayFieldNames,
      ...adPlacementIdsDefaultFieldNames,
      ...adConfigsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: [
      ...popupsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#inventory',
    title: 'Inventory',
    fieldsNames: [],
  },
  {
    eventKey: '#power_ups',
    title: 'Power Ups',
    fieldsNames: [],
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#level_awards',
    title: 'Level Awards',
    fieldsNames: [
      'levelRewardsSetId',
    ],
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: appEventsFieldNames,
  },
  {
    eventKey: '#soft_tutorial',
    title: 'Soft Tutorial',
    fieldsNames: [],
  },
  {
    eventKey: '#magic_pots',
    title: 'Magic Pots',
    fieldsNames: [],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: popupsDefaultArchivedFieldNames,
  },
  {
    eventKey: '#shapes',
    title: 'Shapes',
    fieldsNames: [],
  },
];

export default function Tabs({
  fieldsFactory, isBase, enabledFieldsNames, errors, focusedField,
}) {
  return (
    <GameSettingsTabs
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
