import React, { useEffect } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import { Spinner } from '@tripledotstudios/react-core';

export default function ProgressModal({ fetchTranslationsInitialValues }) {
  useEffect(() => {
    const interval = setInterval(() => {
      fetchTranslationsInitialValues();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Row>
        <Col>
          <h2 className="text-muted">Data migration in progress</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Spinner />
        </Col>
      </Row>
    </Container>
  );
}
