import React, { useState, useRef } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { useField } from 'formik';

import { useFormGroup } from '@hooks';
import Field from './Field';

const AvailabilityTooltip = ({
  targetRef, show, entitiesByPriority, value,
}) => {
  const isAvailable = (priority) => {
    const entity = entitiesByPriority[priority];

    if (priority <= 0) { return ''; }

    return entity ? `${priority}: "${entity.name}"` : `${priority}: available priority`;
  };

  const findAvailablePriority = (startPriority, direction) => {
    let priority = Math.max(startPriority, 0) + direction;
    while (entitiesByPriority[priority]) {
      priority += direction;
    }
    return priority;
  };

  const previousAvailablePriority = (priority) => {
    const previousPriority = findAvailablePriority(priority, -1);
    return previousPriority > 0 ? `available lower priority: ${previousPriority}` : '';
  };

  const nextAvailablePriority = (priority) => {
    const nextPriority = findAvailablePriority(priority, 1);
    return `available higher priority: ${nextPriority}`;
  };

  return (
    <Overlay target={targetRef.current} show={show} placement="bottom">
      {(props) => (
        <Tooltip {...props}>
          <p>{isAvailable(value)}</p>
          <p>{nextAvailablePriority(value)}</p>
          <p>{previousAvailablePriority(value)}</p>
        </Tooltip>
      )}
    </Overlay>
  );
};

const PriorityField = ({ name, entitiesByPriority, disabled }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const [{ value }] = useField(fullName);

  return (
    <>
      <Field
        type="number"
        name={name}
        innerRef={target}
        onFocus={() => setShow(true)}
        onBlur={() => setShow(false)}
        disabled={disabled}
      />
      <AvailabilityTooltip
        targetRef={target}
        show={show && Number.isInteger(value)}
        value={value}
        entitiesByPriority={entitiesByPriority}
      />
    </>
  );
};

export default PriorityField;
