import React, { useEffect, useState } from 'react';
import { AdminUsersRoutes, generateChapterBasedRoutes } from '@pages/routes';
import { Form, useQuery, useCurrentApplication } from '@hooks';
import { createUpdateResponseHandlerFactory } from '@requests/responseHandlers';
import {
  FormButtonsGroup, Label, Field, SelectField, StaticFieldsFormGroup, LabelsFormGroup,
} from '@controls/form';
import { PageHeader, DownloadButton, DuplicateButton } from '@pages/common';
import ListWrapper from '@components/shared/ListWrapper';

import BulkCopyModal from './BulkCopyModal';
import ExpandedLevelLayout from './ExpandedLevelLayout';
import formReducer from './formReducer';

const NoAddEntityBlock = () => <div />;

export default function Edit({
  EntityHeader, levelLayoutFields, ExtraListButtons, reducer = formReducer,
}) {
  const { typeName, applicationId } = useCurrentApplication();

  const [adminUsers, setAdminUsers] = useState([]);

  useEffect(() => {
    AdminUsersRoutes.indexRequest({
      applicationId,
      withoutPagination: true,
      withTokens: true,
    }).then((usersResponse) => {
      setAdminUsers(usersResponse.data.items.map(({ id, email }) => ({ value: id, label: email })));
    });
  }, []);

  const { Banks: BankRoutes, LevelLayouts: LevelLayoutRoutes } = generateChapterBasedRoutes(typeName);
  const { response: data, setResponse } = useQuery(BankRoutes.editRequest);

  const responseHandler = createUpdateResponseHandlerFactory({
    entityName: 'Level layouts bank',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => BankRoutes.updateRequest(values).then((response) => responseHandler(response, values));

  return (data && (
    <Form
      initialValues={{ ...data, applicationId }}
      onSubmit={onSubmit}
      reducer={reducer}
    >
      {({ values, dispatch, dirty }) => (
        <>
          <PageHeader title="View Layout Bank">
            <FormButtonsGroup cancelButtonPath={BankRoutes.indexPath({ applicationId })}>
              <DuplicateButton
                entityName="Layout Bank"
                routes={BankRoutes}
                data={data}
                disabled={dirty}
              />
              <DownloadButton
                className="me-2"
                disabled={dirty}
                request={(_, options) => BankRoutes.downloadRequest({ applicationId, id: data.id }, options)}
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label required text="Name">
            <Field type="text" name="name" />
          </Label>
          <Label text="Description">
            <Field type="text" as="textarea" name="description" disabled={data.readOnly} />
          </Label>
          <Label text="Users">
            <SelectField
              isMulti
              name="adminUserIds"
              options={adminUsers}
            />
          </Label>
          <StaticFieldsFormGroup data={data} />
          <LabelsFormGroup disabled={false} />

          <ListWrapper
            entityAttributes={values.levelLayoutsAttributes}
            attributesName="levelLayoutsAttributes"
            metaErrorsPath={['errors', 'levelLayoutsAttributes']}
            entityNameTranslationPath="bank.layout.name"
            bulkCopyRoutes={BankRoutes}
            EntityHeader={EntityHeader}
            BulkCopyModal={BulkCopyModal}
            readOnly={data.readOnly}
            disableDelete={data.readOnly}
            values={values}
            AddEntityBlock={NoAddEntityBlock}
            ExtraListButtons={ExtraListButtons && (
              <ExtraListButtons values={values} disabled={data.readOnly} dispatch={dispatch} />
            )}
          >
            {({ entity }) => (
              <ExpandedLevelLayout
                levelLayoutFields={levelLayoutFields}
                entity={entity}
                LevelLayoutRoutes={LevelLayoutRoutes}
                applicationId={applicationId}
                disabled={data.readOnly}
              />
            )}
          </ListWrapper>
        </>
      )}
    </Form>
  ));
}
