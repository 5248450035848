import React from 'react';

import { Field } from '@controls/form';

export default function Number() {
  return (
    <Field name="value" type="number" />
  );
}

