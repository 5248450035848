import React, { useState, useEffect, useCallback } from 'react';
import {
  Row, Col, Button,
} from 'react-bootstrap';
import { flattenDeep, map, sumBy } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import {
  Label, Field, FormButtonsGroup, SelectField, StaticFieldsFormGroup,
} from '@controls/form';
import {
  useFormContext,
} from '@hooks';

import Rewards from '@components/merge/shared/Rewards';
import { MergeRoutes } from '@pages/routes';
import { pickRewardsConfigurations } from '@components/merge/services/RewardsConfigurations';
import requestSchemaOptions from '@components/merge/services/requestSchemaOptions';
import { PageHeader } from '@pages/common';
import { presentLinesRequired, presentUnlockedExperts } from '@components/merge/services/postcardSummary';
import RewardsSummary from '@components/merge/shared/rewards/Summary';
import PostcardGraphModal from '@components/merge/shared/PostcardGraphModal';

import StoryOrders from './StoryOrders';

const rewardsConfigurations = pickRewardsConfigurations([
  'Item', 'Xp', 'Coins', 'Gems', 'Energy', 'EnergyRefresh', 'Expert', 'GiftBag', 'RefreshAllProducers',
]);

export default function PostcardForm({ values, errors, actionName }) {
  const { query } = useRouter();
  const [items, setItems] = useState();
  const [experts, setExperts] = useState();
  const [postcards, setPostcards] = useState();
  const [viewPostcard, setViewPostcard] = useState();
  const { dispatch } = useFormContext();

  const { applicationId } = query;

  useEffect(() => {
    if (!values.schemaId) return;

    MergeRoutes.WorldSchemas.editRequest({
      id: values.schemaId,
      applicationId,
    }).then((response) => {
      setPostcards(response.data.postcards);
      setItems(response.data.items);
      setExperts(response.data.experts);
    });
  }, [values.schemaId]);

  const itemsOptions = items && items.map((item) => ({ value: item.id, label: item.internalId, line: item.line }));
  const expertsOptions = experts && experts.map((expert) => ({ value: expert.id, label: expert.internalId }));

  const fetchSchemaOptions = useCallback(() => requestSchemaOptions(query.applicationId), []);
  const populateStoryOrders = (schemaPostcardId) => {
    MergeRoutes.PostcardConfigurations.buildStoryOrdersRequest({
      applicationId,
      schemaPostcardId,
    }).then((response) => dispatch({
      type: 'populateStoryOrders',
      storyOrdersAttributes: response.data.storyOrdersAttributes,
    }));
  };
  const handleViewPostcard = (schemaPostcardId) => {
    MergeRoutes.WorldSchemas.editRequest({ id: values.schemaId, applicationId }).then((response) => {
      setViewPostcard(response.data.postcards.find((schemaPostcard) => schemaPostcard.id === schemaPostcardId));
    });
  };

  return (
    <>
      <PageHeader title={`${values.id ? 'Edit' : 'New'} Postcard Configuration`}>
        <FormButtonsGroup
          cancelButtonPath={MergeRoutes.PostcardConfigurations.indexPath({ applicationId })}
        />
      </PageHeader>

      <Label text="Name">
        <Field type="text" name="name" />
      </Label>
      <Label text="Description">
        <Field type="text" name="description" />
      </Label>

      <Label text="World Schema">
        <SelectField
          name="schemaId"
          options={fetchSchemaOptions}
          onChange={() => dispatch({ type: 'changeSchemaId' })}
          isDisabled={actionName !== 'Create'}
        />
      </Label>
      {postcards && (
        <Label text="Postcard">
          <Row>
            <Col xs={10}>
              <SelectField
                name="schemaPostcardId"
                options={postcards.map((postcard) => ({ value: postcard.id, label: postcard.internalId }))}
                onChange={(e) => populateStoryOrders(e.value)}
                isDisabled={actionName !== 'Create'}
              />
            </Col>
            <Col xs={2}>
              {values.schemaPostcardId && (
                <Button onClick={() => handleViewPostcard(values.schemaPostcardId)}>
                  View
                </Button>
              )}
            </Col>
          </Row>
        </Label>
      )}

      <StaticFieldsFormGroup data={values} />

      {items && experts && (
        <>
          <Row className="mb-2">
            <Col xs={4}><b>Rewards for postcard completion</b></Col>
            <Col xs={3}><b>Rewards Summary</b></Col>
            <Col xs={2}><b>Expert unlocked</b></Col>
            <Col xs={2}><b>Lines required</b></Col>
            <Col xs={1}><b>Tasks</b></Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Rewards
                values={values.rewardsAttributes}
                items={itemsOptions}
                experts={expertsOptions}
                rewardsConfigurations={rewardsConfigurations}
              />
            </Col>
            <Col xs={3}>
              <RewardsSummary
                rewardAttributes={flattenDeep(
                  [
                    values.rewardsAttributes,
                    map(values.storyOrdersAttributes.flatMap((t) => t.tasksAttributes), 'rewardsAttributes'),
                  ],
                )}
                itemOptions={itemsOptions}
                expertOptions={expertsOptions}
              />
            </Col>
            <Col xs={2}>
              <ul className="list-unstyled">
                {presentUnlockedExperts(values, experts).map((expert) => <li key={expert}>{expert}</li>)}
              </ul>
            </Col>
            <Col xs={2}>
              <ul className="list-unstyled">
                {presentLinesRequired(values, items).map((line) => <li key={line}>{line}</li>)}
              </ul>
            </Col>
            <Col xs={1}>
              {sumBy(values.storyOrdersAttributes, (o) => o.tasksAttributes.filter((t) => !t._destroy).length)}
            </Col>
          </Row>

          <StoryOrders
            values={values.storyOrdersAttributes}
            errors={errors.storyOrdersAttributes}
            items={itemsOptions}
            experts={expertsOptions}
            dispatch={dispatch}
          />
        </>
      )}

      {viewPostcard && (
        <PostcardGraphModal
          internalId={viewPostcard.internalId}
          onHide={() => setViewPostcard(null)}
        />
      )}
    </>
  );
}
