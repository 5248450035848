import React from 'react';
import { Col } from 'react-bootstrap';

import { Filter, TextFilter } from '@pages/common';

export default function TileRewardSetsFilter() {
  return (
    <Filter defaultComponents={['availabilityStateIn', 'id', 'createdByMe', 'labels']}>
      {({ updateFilterField }) => (
        <Col md={6}>
          <TextFilter
            name="nameContAny"
            label="Name"
            updateFilterField={updateFilterField}
          />
        </Col>
      )}
    </Filter>
  );
}
