import React, { useState, useEffect, useMemo } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import useRewardOptions from '@hooks/useRewardOptions';

import {
  PageHeader,
  ReactTable,
  DuplicationModal,
  Pagination,
  Publish,
  TIMESTAMP_COLUMNS,
  InUseColumn,
  Filter,
} from '@pages/common';

import { PaymentsRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import ProductDetails from './Details';

const columnsFactory = ({
  rewardItems, setDuplicatedRecord,
}) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Description', accessor: 'description' },
  {
    Header: 'Product details',
    Cell: ({ row: { original } }) => <ProductDetails product={original} rewardItems={rewardItems} />,
  },
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={`${id}/edit`} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const { response } = useQuery(PaymentsRoutes.Products.indexRequest, { includeInUse: true });
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const [publishInfo, setPublishInfo] = useState(null);
  const { query } = useRouter();
  const { applicationId } = query;
  const rewardItems = useRewardOptions();

  useEffect(() => {
    if (response) {
      setPublishInfo(response.publishInfo);
    }
  }, [response]);

  const onPublish = () => {
    PaymentsRoutes.Products.publishRequest({ applicationId }).then(() => { setPublishInfo(null); });
  };

  const columns = useMemo(() => columnsFactory({
    rewardItems, setDuplicatedRecord,
  }), [rewardItems]);

  return (
    response ? (
      <>
        <PageHeader title="Products" filter={<Filter defaultComponents={['availabilityStateIn']} />}>
          <IconButton.New to={PaymentsRoutes.Products.newPath(query)} />
        </PageHeader>
        <Publish info={publishInfo} onPublish={onPublish} className="mb-2" />

        <DuplicationModal
          record={duplicatedRecord}
          entityName="Product"
          handleClose={() => setDuplicatedRecord(null)}
          onSubmit={({ id, name }) => PaymentsRoutes.Products.duplicateRequest({ id, name, applicationId })}
          title="Duplicate Product"
        />
        <ReactTable
          columns={columns}
          defaultSort={{ id: 'createdAt', desc: true }}
          data={response ? response.items : []}
        />
        <Pagination
          pageCount={response._meta.pages}
        />
      </>
    ) : ''
  );
}
