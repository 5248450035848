/* eslint-disable gs/plz-camel-case-for-action-names */
import { addUuid } from '@services/recursivelyProcessObject';

export default function formReducer(state, action) {
  if (action.type === 'change_product_type') {
    state.priceType = null;
  }

  if (action.type === 'change_price_type') {
    state.slotsAttributes = Array(6).fill({}).map((_, index) => (addUuid({ position: index + 1 })));
  }

  return state;
}
