import React from 'react';

import { useFlashMessages, useRouter } from '@tripledotstudios/react-core';
import { useCurrentApplication } from '@hooks';
import { TileRoutes } from '@pages/routes';
import { UploadButton } from '@pages/common';

function Import({ refetch }) {
  const { success, error } = useFlashMessages();
  const { applicationId } = useCurrentApplication();
  const router = useRouter();

  const uploadFile = (file) => {
    if (['application/zip', 'text/csv'].includes(file.type)) {
      return TileRoutes.BankBulkImports.createRequest({ applicationId, file }).then((result) => {
        if (result.status >= 300) return error('Something went wrong', { details: result.data.message });

        return router.push(TileRoutes.BankBulkImports.editPath({ applicationId, id: result.data.id }));
      });
    }

    return TileRoutes.Banks.uploadRequest({ applicationId, file })
      .then((result) => {
        switch (result.status) {
          case 422:
            error(
              `The following validation errors happened during upload: ${JSON.stringify(result.rawData._meta.errors)}`,
              { timeout: 120 },
            );
            break;
          case 500:
            error('Something went wrong', { details: result.data.message });
            break;
          default:
            success('The bank was created successfully');
            refetch();
        }
      });
  };
  return <UploadButton accept="application/JSON, application/zip, text/csv" request={uploadFile} />;
}

export default Import;
