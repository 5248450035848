import {
  at, get, isNil, uniqBy, isEmpty, groupBy, sumBy, compact, flatten, values, reduce, memoize,
} from 'lodash';

import APP_DATA from '@root/services/appData';
import { BASE_BOARD_GRID_COLUMN_SIZE, LARGE_TILE_FAMILY_ID, tileTypedLevelGoalTypes } from './constants';

const buildSelectOptions = (originalCollection, selectedValue, filterFunction) => {
  const filteredOptions = originalCollection.filter(({ value }) => filterFunction(value));
  if (isNil(selectedValue)) return filteredOptions;

  return uniqBy([originalCollection.find(({ value }) => value === selectedValue), ...filteredOptions], 'value');
};

export const isLargeTileFamily = (familyId) => (familyId === LARGE_TILE_FAMILY_ID);

const asymmetricalLargeTilesIds = memoize((_index, tileOptions) => (
  tileOptions.filter(({ familyId, width, height }) => isLargeTileFamily(familyId) && width !== height)
    .map(({ value }) => value)
));

export const largeTileEntity = (tileOptions, baseTileId) => (
  tileOptions.find((options) => options.value === baseTileId) || {}
);

export const disableBaseTileInModal = (boardCellsAttributes, massSelectedCellIds) => massSelectedCellIds.some((id) => {
  const cellsWithParams = boardCellsAttributes.filter(({ parameters }) => parameters);

  return cellsWithParams.some(({ baseTileId, overlayTileId, parameters }) => {
    const referenceKeys = [
      baseTileId,
      overlayTileId,
    ].filter((tileId) => tileId).map((tileId) => `${tileId}.referenceId`);

    const referenceIds = at(parameters, referenceKeys).filter((cellId) => cellId).map((cellId) => Number(cellId) - 1);

    return referenceIds.includes(id);
  });
});

export const hasFilledTiles = (boardCells) => (
  boardCells.filter(({ baseTileId, overlayTileId }) => baseTileId || overlayTileId).length > 0
);

export const hasAsymmetricalLargeTiles = (boardCells, tileOptions, index) => (
  boardCells.some(({ baseTileId }) => asymmetricalLargeTilesIds(index, tileOptions).includes(baseTileId))
);

export const tileTypeProbabilityOptions = (tileTypeOptions, addedTileProbabilityIds, selected) => (
  buildSelectOptions(tileTypeOptions, selected, (value) => !addedTileProbabilityIds.includes(value))
);

export const filteredGoalTypeOptions = (goalTypeOptions, levelGoals, selected) => (
  buildSelectOptions(goalTypeOptions, selected, (value) => {
    if (value !== tileTypedLevelGoalTypes.score) {
      return true;
    }

    return !levelGoals.map(({ goalType }) => goalType).includes(value);
  })
);

export const collectTileGoalOptions = (tileTypeOptions, levelGoals, selected) => (
  buildSelectOptions(tileTypeOptions, selected, (value) => (
    !levelGoals
      .filter(({ goalType }) => goalType === tileTypedLevelGoalTypes.collect)
      .map(({ tileId }) => tileId).includes(value)
  ))
);

export const validateCellRef = (boardCellsAttributes, cellIndices, cellConfig) => {
  const { baseTileId, parameters } = cellConfig;

  if (cellIndices.length >= 2 || isEmpty(parameters)) return true;

  const cellPosition = cellIndices[0];

  const referenceId = Number(get(parameters, `${baseTileId}.referenceId`) || 0);
  const refPosition = referenceId - 1;
  const refBoardCell = boardCellsAttributes.find(({ position }) => position === refPosition) || {};
  const refHasReferences = boardCellsAttributes.some((boardCell) => {
    const { position, parameters: boardCellParams, baseTileId: boardCellBaseTileId } = boardCell;

    if (position === cellPosition) return false;

    return Number(get(boardCellParams || {}, `${boardCellBaseTileId}.referenceId`)) === referenceId;
  });

  if (!isNil(refBoardCell.baseTileId) || refPosition === cellPosition || refHasReferences) return false;

  return true;
};

export const presentLevelGoals = (goals, goalTypes, tileTypes) => {
  if (!goals.length || !tileTypes.length) return '';

  const collectGoals = goals.filter(({ goalType, tileId, targetScore }) => (
    goalType === goalTypes.COLLECT && tileId && targetScore !== ''
  ));
  const groupedCollectGoals = groupBy(collectGoals, 'tileId');
  const tileTypesMapping = groupBy(tileTypes, 'id');

  const humanizedCollectGoals = Object.entries(groupedCollectGoals).map(([tileId, tileGoals]) => (
    `${tileTypesMapping[tileId][0].typeId}:${sumBy(tileGoals, 'targetScore')}`
  ));

  const humanizedScoreGoals = goals.filter(({ goalType, targetScore }) => (
    goalType === goalTypes.SCORE && targetScore !== ''
  ));

  const collectGoalsText = humanizedCollectGoals.length > 0 && `C ${humanizedCollectGoals.join(', ')}`;
  const scoreGoalsText = humanizedScoreGoals.length > 0 && `TS ${sumBy(humanizedScoreGoals, 'targetScore')}`;

  return `{ ${compact([collectGoalsText, scoreGoalsText]).join('; ')} }`;
};

export const boardSize = (boardType) => (
  Object.values(APP_DATA.rawEnums['Woodoku::BoardGridTypes']).find(({ value }) => value === boardType).boardSize
);

export const isLargeTile = (tileOptions, baseTileId) => {
  if (!baseTileId) return false;
  return (isLargeTileFamily(largeTileEntity(tileOptions, baseTileId)?.familyId));
};

export const fetchLargeTileCoordinates = (parameters, baseTileId) => get(parameters, `${baseTileId}.coordinates`);

export const largeTiles = (allTiles) => (allTiles.filter(({ baseTileId, parameters = {} }) => (
  Boolean(fetchLargeTileCoordinates(parameters, baseTileId))
)));

export const largeTilesParentCells = (tiles, cellPosition) => tiles.filter(({ baseTileId, parameters = {} }) => (
  fetchLargeTileCoordinates(parameters, baseTileId).includes(cellPosition)
));

export const largeTileCoordinates = (cellIndex, cellConfig, currentBoardWidth = 9) => {
  const coordinates = [];
  let currentIndex = cellIndex;
  for (let columnIndex = 1; columnIndex <= cellConfig.height; columnIndex += 1) {
    for (let rowIndex = columnIndex === 1 ? 1 : 0; rowIndex < cellConfig.width; rowIndex += 1) {
      coordinates.push(currentIndex + rowIndex);
    }
    currentIndex += currentBoardWidth;
  }
  return coordinates;
};

export const buildCoordinatesSet = (indexes, newTileOptions, boardWidth) => (
  reduce(indexes,
    (coordinatesSet, index) => (
      { ...coordinatesSet, [index]: largeTileCoordinates(index, newTileOptions, boardWidth) }
    ),
    {})
);

export const tileOutsideBoard = (index, tileOption, currentBoardWidth, setErrorFn) => {
  const maxBoardIndex = (currentBoardWidth * BASE_BOARD_GRID_COLUMN_SIZE) - 1;
  const tileMaxXIndex = (index + tileOption.width) - 1;
  const tileMaxYIndex = (index + ((tileOption.height - 1) * currentBoardWidth));
  const tileRow = Math.ceil((index + 1) / currentBoardWidth);
  const tileRowMaxXIndex = (tileRow * currentBoardWidth) - 1;

  const outsideBoard = tileMaxXIndex > tileRowMaxXIndex || tileMaxYIndex > maxBoardIndex;
  if (outsideBoard) {
    setErrorFn('Tile is placed outside the board');
  }
  return outsideBoard;
};
export const tileCollidesWithOther = (index, coordinates, occupiedCells, setErrorFn) => {
  const otherTiles = occupiedCells.filter((cell) => cell.position !== index);
  const otherTilesPositions = otherTiles.map((occupiedCell) => occupiedCell.position);
  const largeTilesCoordinates = compact(otherTiles.flatMap((cell) => cell.parameters[cell.baseTileId]?.coordinates));
  const { [index]: _, ...otherCurrentCoordinates } = coordinates;

  const collidesWithOther = [
    ...otherTilesPositions, ...largeTilesCoordinates, ...flatten(values(otherCurrentCoordinates)),
  ].some((position) => coordinates[index].includes(position));

  if (collidesWithOther) {
    setErrorFn(`Large tile can't be placed over other tile, please remove existing tile(s) from required cells in 
    order to place it`);
  }

  return collidesWithOther;
};
