import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';
import { AbTestingRoutes } from '@pages/routes';
import APP_DATA from '@services/appData';

import Form from './Form';

export default function Edit() {
  const { editRequest, updateRequest } = AbTestingRoutes.Experiments;
  const { id, applicationId } = useRouter().query;
  const { response, setResponse } = useQuery(editRequest, { id, applicationId });
  const { enums: { AbExperimentStatuses: statusesEnum } } = APP_DATA;
  const entityName = 'A/B experiment';

  const responseHandler = noRedirectHandlerFactory({
    entityName,
    actionName: 'update',
    setData: setResponse,
  });
  const confirm = useConfirm();

  const onSubmit = (values) => {
    if (values.status === statusesEnum.ARCHIVED) {
      return confirm.showConfirmation({
        body: `${entityName} '${values.name}' will be archived and not available for work. Continue?`,
      }).then(() => updateRequest({ id, applicationId, ...values }).then(responseHandler));
    }

    return updateRequest({ id, applicationId, ...values }).then(responseHandler);
  };

  return response && (
    <Form action="Edit" data={response} onSubmit={onSubmit} />
  );
}
