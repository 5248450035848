import React, { useMemo, useState } from 'react';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import {
  PageHeader,
  Pagination,
  ReactTable,
  DuplicationModalWithPriority,
  Filter,
} from '@pages/common';

import { useQuery, useConfirm } from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import IconButton from '@controls/buttons';
import columnsFactory from './columnsFactory';

export default function Index({
  legend,
  entityName,
  getConfigsFunction,
  duplicateConfigFunction,
  deleteConfigFunction,
}) {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(getConfigsFunction);
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName,
    actionName: 'deletion',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `${entityName} '${name}' will be deleted and not available for work. Continue?` })
      .then(() => { deleteConfigFunction({ id, applicationId }).then(responseHandler); });
  };

  const columns = useMemo(() => columnsFactory({
    onDelete, setDuplicatedRecord,
  }), []);

  return response && (
    <div>
      <PageHeader title={legend} filter={<Filter defaultComponents={['availabilityStateIn']} />}>
        <ButtonToolbar>
          <IconButton to="base" icon={faSuitcase}>Base Config</IconButton>
          <ButtonToolbar.Divider />
          <IconButton.New to="new" />
        </ButtonToolbar>
      </PageHeader>

      <ReactTable
        columns={columns}
        defaultSort={{ id: 'priority', desc: true }}
        data={response ? response.items : []}
      />
      <DuplicationModalWithPriority
        record={duplicatedRecord}
        entityName={entityName}
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => duplicateConfigFunction({ ...values, applicationId })}
        entitiesByPriority={response.entitiesByPriority || {}}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
