import React from 'react';

import { PageSection } from '@pages/common';
import {
  Label,
  Field,
  SelectField,
  TagsField,
  ServerError,
} from '@controls/form';
import { FormGroup } from '@hooks';
import IconButton from '@controls/buttons';

import Rank from './Rank';

const LeaderboardChallenge = ({
  entity,
  levelManifestTypes,
  missionTypes,
  dispatch,
  rewardOptions,
}) => (
  <>
    <Label text="Level Manifest type">
      <SelectField name="levelManifestType" options={levelManifestTypes} />
    </Label>
    <Label text="Max Level">
      <Field name="maxSolitaireLevel" type="number" />
    </Label>
    <Label text="Challenge Type">
      <SelectField name="challengeType" options={missionTypes} />
    </Label>
    <Label text="Challenge Parameter">
      <TagsField
        inline
        name="challengeParameter"
        propertyName="value"
        placeholder="Add a parameter..."
      />
    </Label>

    <PageSection title="Creatives Settings">
      <Label text="Title Key">
        <Field name="creativeSettings.titleKey" />
      </Label>
      <Label text="Announcement description key">
        <Field name="creativeSettings.announcementDescriptionKey" />
      </Label>
      <Label text="Announcement hint key">
        <Field name="creativeSettings.announcementHintKey" />
      </Label>
      <Label text="Loadout title key">
        <Field name="creativeSettings.loadoutTitleKey" />
      </Label>
      <Label text="Asset key">
        <Field name="creativeSettings.assetKey" />
      </Label>
    </PageSection>

    <PageSection title="Rewards" />
    <ServerError name="ranksAttributes.list" />
    <FormGroup name="ranksAttributes">
      {entity.ranksAttributes.map((rank, index) => {
        if (rank._destroy) return null;

        return (
          <FormGroup name={index}>
            <Rank
              entity={rank}
              challenge={entity}
              index={index}
              rewardOptions={rewardOptions}
              dispatch={dispatch}
            />
          </FormGroup>
        );
      })}
      <IconButton.New onClick={() => dispatch({ actionType: 'addRank', challengeUuid: entity._uuid })}>
        New Rank
      </IconButton.New>
    </FormGroup>
  </>
);

export default LeaderboardChallenge;
