import { map } from 'lodash';

const DEFAULT_WRAP_ELEMENT_FN = (e) => e;

const humanizedEnumValue = (operation, operationsEnum) => Object
  .values(operationsEnum)
  .find(({ value }) => operation === value)
  .translation
  .toLowerCase();

const presentCollection = (collection, options = {}) => {
  const presentedCollection = collection || [];
  const wrapElementFn = options.wrapElementFn || DEFAULT_WRAP_ELEMENT_FN;

  return map(presentedCollection, wrapElementFn).join(' OR ');
};

const presentSentencesCollection = (collection) => (
  presentCollection(collection, { wrapElementFn: (e) => `"${e}"` })
);

const presentDates = (dates, operation, enums) => {
  if (!dates || dates.length === 0) return '';

  const operations = enums.RuleOperations;
  if ([operations.NOT_IN, operations.IN].map((o) => o.translation.toLowerCase()).indexOf(operation) > -1) {
    return (dates || []).map((d) => d.value).join(', ');
  }
  return dates[0].value;
};

const buildVariant = (operation, props, enums) => {
  const collection = (props.buildVariants || []).map((t) => humanizedEnumValue(t, enums['Accounts::BuildVariants']));
  return (
    `Build variant ${operation} ${presentCollection(collection)}`
  );
};

const city = (operation, props) => `Cities ${operation} ${presentCollection(props.cityNames)}`;
const country = (operation, props) => `Countries ${operation} ${presentCollection(props.countryCodes)}`;
const campaignName = (operation, props) => `Campaign names ${operation} ${presentCollection(props.campaignNames)}`;
const cohortDay = (operation, props) => `Cohort day ${operation} ${props.number}`;
const daysSinceLastPayment = (operation, props) => `Days since last payments ${operation} ${props.number}`;
const daysSinceLastVisit = (operation, props) => `Days since last visit ${operation} ${props.number}`;
const daysSinceVersionUpdate = (operation, props) => `Days since version update ${operation} ${props.number}`;
const debugIds = (operation, props) => `Debug ids ${operation} ${presentCollection(props.debugIds)}`;
const deviceModel = (operation, props) => `Device model ${operation} ${props.model}`;
const deviceType = (operation, props, enums) => {
  const deviceTypes = (props.deviceTypes || []).map((t) => humanizedEnumValue(t, enums['Accounts::DeviceTypes']));
  return (
    `Device type ${operation} ${presentCollection(deviceTypes)}`
  );
};

const experimentVariant = (operation, props) => (
  `A/B experiments ${presentCollection(props.experimentIds)} `
  + `variants ${operation} ${presentCollection(props.variantIds)}`
);
const gameVersion = (operation, props) => `Current game version ${operation} ${presentCollection(props.gameVersions)}`;
const idfa = (operation, props) => `IDFA ${operation} ${presentCollection(props.idfa)}`;
const idfv = (operation, props) => `IDFV ${operation} ${presentCollection(props.idfv)}`;
const installDateLocalTimezone = (operation, props, enums) => (
  `Install date (Local Timezone) ${operation} ${presentDates(props.installDatesLocalTimezone, operation, enums)}`
);
const installDate = (operation, props, enums) => (
  `Install date (UTC) ${operation} ${presentDates(props.installDates, operation, enums)}`
);
const installVersion = (operation, props) => (
  `Install game version ${operation} ${presentCollection(props.gameVersions)}`
);
const language = (operation, props) => `Languages ${operation} ${presentCollection(props.languageCodes)}`;
const lastPaymentAmount = (operation, props) => `Last payment amount ${operation} ${props.amount}`;
const latUser = (operation, _props, enums) => (
  `LAT ${operation === enums.RuleOperations.NOT_IN.translation.toLowerCase() ? 'disabled' : 'enabled'}`
);
const mediaSource = (operation, props) => `Media sources ${operation} ${presentCollection(props.mediaSources)}`;
const osVersion = (operation, props) => `OS version ${operation} ${presentCollection(props.osVersions)}`;
const platform = (operation, props, enums) => {
  const platforms = (props.platforms || []).map(
    (p) => humanizedEnumValue(p, enums['Accounts::Platforms']).toUpperCase(),
  );
  return (
    `Platforms ${operation} ${presentCollection(platforms)}`
  );
};
const playerBucket = (operation, props) => (
  `Player ids ${operation} [${props.lowerBound}..${props.upperBound}] (modulo 100)`
);
const playerSegment = (operation, props) => (
  `Player Segments ${operation} ${presentCollection(props.playerSegmentNames)}`
);
const previousGameVersion = (operation, props) => (
  `Previous game version ${operation} ${presentCollection(props.gameVersions)}`
);
const sessionsCount = (operation, props) => `Sessions count ${operation} ${props.number}`;
const ramCapacity = (operation, props) => `Device RAM capacity (MB) ${operation} ${props.number}`;
const totalPaymentAmount = (operation, props) => `Total payments amount ${operation} ${props.amount}`;
const userIds = (operation, props) => `User ids ${operation} ${presentCollection(props.userIds)}`;
const versionUpdateDate = (operation, props, enums) => (
  `Version update date (UTC) ${operation} ${presentDates(props.updateDates, operation, enums)}`
);
const namedGroupRule = (operation, props) => (
  `Named rule groups ${operation} ${presentSentencesCollection(props.groupNames)}`
);
const daysSinceStartAt = (operation, props) => `Days since "Start at" ${operation} ${props.number}`;

const RulesPresenters = {
  'Rules::BuildVariantRule': buildVariant,
  'Rules::CampaignNameRule': campaignName,
  'Rules::CityRule': city,
  'Rules::CohortDayRule': cohortDay,
  'Rules::CountryRule': country,
  'Rules::DaysSinceLastPaymentRule': daysSinceLastPayment,
  'Rules::DaysSincePreviousVisitRule': daysSinceLastVisit,
  'Rules::DaysSinceVersionUpdateRule': daysSinceVersionUpdate,
  'Rules::DebugIdsRule': debugIds,
  'Rules::DeviceModelRule': deviceModel,
  'Rules::DeviceTypeRule': deviceType,
  'Rules::ExperimentVariantRule': experimentVariant,
  'Rules::GameVersionRule': gameVersion,
  'Rules::IdfaRule': idfa,
  'Rules::IdfvRule': idfv,
  'Rules::InstallDateLocalTimezoneRule': installDateLocalTimezone,
  'Rules::InstallDateRule': installDate,
  'Rules::InstallVersionRule': installVersion,
  'Rules::LanguageRule': language,
  'Rules::LastPaymentAmountRule': lastPaymentAmount,
  'Rules::LatUserRule': latUser,
  'Rules::MediaSourceRule': mediaSource,
  'Rules::NamedGroupRule': namedGroupRule,
  'Rules::OsVersionRule': osVersion,
  'Rules::PlatformRule': platform,
  'Rules::PlayerBucketRule': playerBucket,
  'Rules::PlayerSegmentRule': playerSegment,
  'Rules::PreviousGameVersionRule': previousGameVersion,
  'Rules::RamCapacityRule': ramCapacity,
  'Rules::SessionsCountRule': sessionsCount,
  'Rules::TotalPaymentAmountRule': totalPaymentAmount,
  'Rules::UserIdsRule': userIds,
  'Rules::VersionUpdateDateRule': versionUpdateDate,
  'Rules::DaysSinceStartAtRule': daysSinceStartAt,
};

export default function rulesPresenter({
  type,
  operation,
  enums,
  props,
}) {
  const operationsEnum = enums.RuleOperations;
  const presenter = RulesPresenters[type];
  const humanizedOperation = operation === null ? null : humanizedEnumValue(operation, operationsEnum);
  if (presenter) return presenter(humanizedOperation, props, enums);

  return null;
}
