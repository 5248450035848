import React, {
  useState, useEffect, useCallback,
} from 'react';
import { Table, Button } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import {
  Field,
  CheckboxField,
  SelectField,
  ServerError,
} from '@controls/form';
import {
  FormGroup, useFormContext,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import Th from '@components/merge/shared/Th';
import { MergeRoutes } from '@pages/routes';
import ProductDetails from '@components/merge/shared/ProductDetails';

export default function Products({ schemaId, values }) {
  const { query } = useRouter();
  const [products, setProducts] = useState([]);
  const productLabels = fromClassNameToOptions('Merge::ProductLabels');
  const { dispatch } = useFormContext();
  const productLabelsOptions = useCallback(() => productLabels, []);
  const productOptions = products.map((item) => ({ value: item.id, label: item.name }));
  const onDeleteProduct = useCallback((index) => dispatch({ type: 'remove_product', index }), []);
  const onChangeDefault = useCallback(
    (event, index) => dispatch({ type: 'update_repeatable', value: event.target.checked, index }),
    [],
  );

  useEffect(() => {
    MergeRoutes.Products.indexRequest({
      applicationId: query.applicationId,
      rewardType: 'bundle',
      withoutPagination: true,
      schemaId,
    }).then((response) => {
      setProducts(response.data.items);
    });
  }, [schemaId]);

  const productsById = products.reduce((memo, product) => ({ ...memo, [product.id]: product }), {});

  return (
    <>
      <ServerError name="catalogProductsAttributes.list" className="mb-2" />
      <Table>
        <thead>
          <tr>
            <Th width="10%">Priority</Th>
            <Th width="10%">Default</Th>
            <Th width="10%">Repeatable</Th>
            <Th width="20%">Product</Th>
            <Th width="20%">Label</Th>
            <Th width="30%">Content</Th>
            <Th />
          </tr>
        </thead>
        <tbody>
          {values.map((product, index) => (
            !product._destroy ? (
              <FormGroup key={product._uuid} name={`catalogProductsAttributes[${index}]`}>
                <tr>
                  <td><Field name="priority" type="number" /></td>
                  <td>
                    <CheckboxField name="default" onChange={(event) => (onChangeDefault(event, index))} />
                  </td>
                  <td><CheckboxField name="repeatable" disabled={product.default} /></td>
                  <td>
                    <SelectField
                      name="productId"
                      options={productOptions}
                    />
                  </td>
                  <td>
                    <SelectField
                      name="label"
                      options={productLabelsOptions}
                      hasPrepopulatedOption
                    />
                  </td>
                  <td>
                    <ProductDetails product={productsById[product.productId]} />
                  </td>
                  <td>
                    <Button
                      onClick={() => onDeleteProduct(index)}
                      size="sm"
                      variant="danger"
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              </FormGroup>
            ) : ''
          ))}
        </tbody>
      </Table>
      <Button
        onClick={() => dispatch({ type: 'add_product' })}
        variant="success"
      >
        Add
      </Button>
    </>
  );
}
