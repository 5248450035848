import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import IconButton from '@controls/buttons';
import NewTranslationKeyModal from './NewTranslationKeyModal';

export default function NewTranslationKey() {
  const [showModal, setShowModal] = useState(false);
  const onModalHide = () => {
    setShowModal(false);
  };

  return (
    <div className="float-end">
      <Row>
        <Col>
          <IconButton.New onClick={() => setShowModal(true)}>Add Key</IconButton.New>
        </Col>
      </Row>
      {showModal && <NewTranslationKeyModal onHide={onModalHide} />}
    </div>
  );
}
