import React from 'react';
import { Col } from 'react-bootstrap';

import { fromClassNameToOptions } from '@services/enums';
import { Filter, TextFilter, SelectFilter } from '@pages/common';

export default function JsonConfigsFilter({ configTypes }) {
  const availableConfigTypes = fromClassNameToOptions('MyPetCafe::JsonConfigTypes');

  return (
    <Filter defaultComponents={['availabilityStateIn']}>
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name contains"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <SelectFilter
              name="typeIn"
              label="Type in"
              options={availableConfigTypes.filter(({ value }) => configTypes.includes(value))}
              updateFilterField={updateFilterField}
            />
          </Col>
        </>
      )}
    </Filter>
  );
}
