import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import {
  TIMESTAMP_COLUMNS,
  InUseColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';

export default function ColumnsFactory({
  editPath, applicationId, onDelete,
}) {
  return [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Key', accessor: 'key' },
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({ row: { original: { id, name, inUse } } }) => {
        const disabled = isInUse(inUse);

        return (
          <ButtonToolbar>
            <IconButton.Edit to={editPath({ id, applicationId })} minimized />
            <IconButton.Delete onClick={onDelete(id, name)} disabled={disabled} minimized />
          </ButtonToolbar>
        );
      },
    },
  ];
}
