import React, { useMemo } from 'react';
import { Image } from 'react-bootstrap';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';

import {
  PageHeader,
  Pagination,
  ReactTable,
  TIMESTAMP_COLUMNS,
} from '@pages/common';
import { getProductImages, deleteProductImage } from '@requests/payments/productImages';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import IconButton from '@controls/buttons';

const columnsFactory = ({ onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Preview',
    id: 'preview',
    Cell: ({ row: { original } }) => <Image src={original.imageCdnUrl} rounded className="w-25" />,
  },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={`${id}/edit`} minimized />
        <IconButton.Delete onClick={onDelete(id, name)} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const { response, refetch } = useQuery(getProductImages);
  const { query: { applicationId } } = useRouter();
  const confirm = useConfirm();

  const deleteResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Product Image',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `Product Image '${name}' will be deleted and not available for work. Continue?` })
      .then(() => deleteProductImage({ applicationId, id })
        .then(deleteResponseHandler));
  };

  const columns = useMemo(() => columnsFactory({ onDelete }), []);

  return response && (
    <div>
      <PageHeader title="Product Images">
        <IconButton.New to="new" />
      </PageHeader>

      <ReactTable
        columns={columns}
        defaultSort={{ id: 'createdAt', desc: true }}
        data={response.items}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
