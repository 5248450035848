import React, { useMemo } from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';
import qs from 'qs';
import { camelCase, reduce, snakeCase } from 'lodash';
import {
  useTable,
  useSortBy,
  useGroupBy,
  useExpanded,
} from 'react-table';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortUp,
  faSortDown,
  faPlusSquare as expandIcon,
  faMinusSquare as foldIcon,
} from '@fortawesome/free-solid-svg-icons';

import useDidMountEffect from '@hooks/useDidMountEffect';
import updateQuery from '@services/updateQuery';
import DndIcon from './DndIcon';
import TrailFields from './TrailFields';

const widthClasses = () => {
  let styles = '';
  for (let i = 0; i <= 20; i += 1) {
    styles += `
      .w-${i * 5} {
        width: ${i * 5}%;
      }
    `;
  }
  return css`${styles}`;
};

const StyledTable = styled(Table)`
  // Builds w-10 w-25 w-65 classes
  ${widthClasses()}

  th {
    vertical-align: top !important;
  }

  th[title~='SortBy'] {
    cursor: pointer;
  }

  ${({ fixed }) => fixed && 'table-layout: fixed;'}
`;

const SortIcon = styled.span`
  margin-right: 5px;
`;

const NarrowCell = styled.div`
  word-break: break-word;
`;

const StaticDndIcon = styled(DndIcon)`
  position: static;
`;

const UserCell = ({ prefix, row }) => (
  <NarrowCell>
    <TrailFields prefix={prefix} record={row.original} />
  </NarrowCell>
);

const selectColumnBuilder = ({ onSelect, selectedItems }) => ({
  Header: '',
  Cell: ({ row: { original } }) => (
    <input
      type="checkbox"
      checked={selectedItems.includes(original.id)}
      onChange={({ target }) => onSelect(original, target.checked)}
    />
  ),
  style: { width: 30 },
  id: 'select-column',
});

export const REORDER_COLUMN = {
  Header: '',
  Cell: ({ row }) => (
    <StaticDndIcon
      {...(row.draggableProvided.dragHandleProps || {})}
    />
  ),
  style: { width: 60 },
  id: 'reorder',
};

export const TIMESTAMP_COLUMNS = [
  {
    Header: 'Updated at (UTC)',
    accessor: 'updatedAt',
    style: { width: '15%' },
    Cell: ({ row }) => <UserCell prefix="updated" row={row} />,
  },
];

export default function ReactTable({
  additionalProps = {},
  defaultSort = {},
  disableSortBy = false,
  droppableId = 'disabled',
  initialGroup = [],
  initialHiddenColumns = [],
  isDragDisabled = true,
  onDragEnd = () => { },
  tableProps = {
    bordered: true,
    hover: true,
    striped: true,
  },
  wholeRowDraggable = true,
  className,
  columns,
  data,
  renderRowSubComponent,
  selectable = false,
  selectedItems = [],
  onSelect = () => null,
}) {
  const router = useRouter();
  const sortQuery = qs.parse(router.query, { ignoreQueryPrefix: true }).sort;
  const initialSort = [sortQuery ? { id: camelCase(sortQuery.id), desc: sortQuery.desc === 'true' } : defaultSort];
  const selectColumn = useMemo(() => (
    selectable ? [selectColumnBuilder({ onSelect, selectedItems })] : []
  ), [selectable, selectedItems.length]);

  const tableColumns = useMemo(
    () => [...selectColumn, ...columns],
    [selectColumn.length, selectedItems.length, columns],
  );

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: { sortBy }, visibleColumns,
  } = useTable(
    {
      columns: tableColumns,
      data,
      disableSortBy,
      initialState: {
        hiddenColumns: initialHiddenColumns,
        sortBy: initialSort,
        groupBy: initialGroup,
      },
      autoResetExpanded: false,
      autoResetSortBy: false,
      disableMultiSort: true,
      disableSortRemove: true,
      manualSortBy: true,
      ...additionalProps,
    },
    useGroupBy,
    useSortBy,
    useExpanded,
  );
  useDidMountEffect(() => {
    if (!sortBy.length || sortBy.id === '' || disableSortBy) { return; }
    const { id, desc } = sortBy[0];
    updateQuery(router, { sort: { id: snakeCase(id), desc } });
  }, [sortBy]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <StyledTable
            {...tableProps}
            ref={provided.innerRef}
            {...getTableProps()}
            {...provided.droppableProps}
            className={className || 'table align-middle'}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} style={column.style}>
                      {column.isSorted
                        ? (
                          <SortIcon>
                            <FontAwesomeIcon icon={column.isSortedDesc ? faSortDown : faSortUp} />
                          </SortIcon>
                        )
                        : ''}
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((basicRow) => {
                const { _uuid, id, name } = basicRow.original;
                const key = _uuid || id;
                const draggableId = `${droppableId}-${key}`;
                const testId = name || id;

                return (
                  <Draggable
                    key={key}
                    index={basicRow.index}
                    draggableId={`${droppableId}-${key}`}
                    isDragDisabled={isDragDisabled}
                  >
                    {(draggableProvided) => {
                      const row = {
                        ...basicRow,
                        draggableProvided: !wholeRowDraggable ? draggableProvided : {},
                        draggableId,
                      };
                      prepareRow(row);

                      return (
                        <>
                          <tr
                            data-testid={`row_${testId}`}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...(wholeRowDraggable ? draggableProvided.dragHandleProps : {})}
                            {...row.getRowProps()}
                            {...(additionalProps.getRowProps && additionalProps.getRowProps(row))}
                          >
                            {reduce(row.cells, (rowCells, cell) => {
                              if (cell.column.skip && cell.column.skip(cell)) { return rowCells; }

                              return [
                                ...rowCells,
                                <td
                                  className={cell.column.className}
                                  {...cell.getCellProps()}
                                  style={cell.column.style}
                                  colSpan={cell.column.colSpan && cell.column.colSpan(cell)}
                                >
                                  {/* eslint-disable no-nested-ternary */}
                                  {cell.isGrouped ? (
                                    <>
                                      <span {...row.getToggleRowExpandedProps()} className="me-2">
                                        {row.isExpanded
                                          ? <FontAwesomeIcon icon={foldIcon} size="lg" />
                                          : <FontAwesomeIcon icon={expandIcon} size="lg" />}
                                      </span>
                                      {cell.render('Cell')}
                                      (
                                      <span className="ms-2">{row.subRows.length}</span>
                                      )
                                    </>
                                  ) : cell.isAggregated ? (
                                    cell.render('Aggregated')
                                  ) : cell.isPlaceholder ? null : (
                                    cell.render('Cell')
                                  )}
                                  {/* eslint-enable no-nested-ternary */}
                                </td>,
                              ];
                            }, [])}
                          </tr>
                          {row.isExpanded && (
                            <tr>
                              <td colSpan={visibleColumns.length}>
                                {renderRowSubComponent({ row })}
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </tbody>
          </StyledTable>
        )}
      </Droppable>
    </DragDropContext>
  );
}
