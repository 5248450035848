import React, { useState, useEffect } from 'react';

import { useCurrentApplication } from '@hooks';
import { generateChapterBasedRoutes, LocalizationsRoutes } from '@pages/routes';
import { requestWithToSelectOptions } from '@services/toSelectOptions';

import Chapters from '../../metagame/chapter-based/chapter-sets/Chapters';
import ChapterFields from './ChapterFields';

export default function Match3dChapters() {
  const { currentApplication: { id: applicationId }, applicationName } = useCurrentApplication();
  const [figurePacks, setFigurePacks] = useState([]);
  const [uiConfigs, setUiConfigs] = useState([]);
  const [localizationKeys, setLocalizationKeys] = useState([]);

  const { UiConfigs, FigurePacks } = generateChapterBasedRoutes(applicationName);

  const fetchFigurePacksOptions = async () => {
    const response = await FigurePacks.indexRequest({ applicationId, withoutPagination: true });
    const options = response.data.items.map((uiAsset) => ({
      label: `${uiAsset.name} (${uiAsset.key})`,
      value: uiAsset.id,
    }));
    setFigurePacks(options);
  };

  const fetchUiConfigsOptions = () => requestWithToSelectOptions(UiConfigs.indexRequest, applicationId, [])
    .then(setUiConfigs);

  const fetchLocalizationKeysOptions = () => (
    requestWithToSelectOptions(LocalizationsRoutes.Keys.indexRequest, applicationId, [])
      .then(setLocalizationKeys)
  );

  useEffect(() => {
    (async () => Promise.all([
      fetchFigurePacksOptions(),
      fetchUiConfigsOptions(),
      fetchLocalizationKeysOptions(),
    ]))();
  }, []);

  return (
    <Chapters
      chapterFieldsData={{ figurePacks, uiConfigs, localizationKeys }}
      ChapterFields={ChapterFields}
      enableUiAssets
    />
  );
}
