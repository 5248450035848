import React from 'react';

import { generateChapterBasedRoutes } from '@pages/routes';
import { PageHeader } from '@pages/common';
import { Form } from '@hooks';
import {
  FormButtonsGroup, Label, Field, LabelsFormGroup,
} from '@controls/form';

export default function BankForm({
  data, application, applicationName, applicationType, onSubmit,
}) {
  const BankRoutes = generateChapterBasedRoutes(applicationType).Banks;
  const fullData = { ...data, applicationName, applicationId: application.id };

  return (
    <Form
      initialValues={fullData}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <>
          <PageHeader title="New Layout Bank">
            <FormButtonsGroup
              disableSubmit={isSubmitting}
              cancelButtonPath={BankRoutes.indexPath({ applicationId: application.id })}
            />
          </PageHeader>

          <Label required text="Name">
            <Field name="name" />
          </Label>
          <Label text="Description">
            <Field name="description" as="textarea" />
          </Label>

          <LabelsFormGroup disabled={false} />
        </>
      )}
    </Form>
  );
}
