import React, { useState, useMemo } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { useQuery, useConfirm } from '@hooks';

import { DailyChallengesRoutes as Routes } from '@pages/routes';

import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import {
  PageHeader,
  Pagination,
  Badge,
  ReactTable,
  TIMESTAMP_COLUMNS,
  RulesList,
  InLiveColumn,
  DuplicationModalWithPriority,
  LabelsColumn,
  PriorityColumn,
} from '@pages/common';

import IconButton from '@controls/buttons';

import Filter from './Filter';

const { enums } = APP_DATA;
const statuses = enums['DailyChallenges::EventStatuses'];

const statusBadgeColor = (status) => {
  const colorMapping = {
    [statuses.ACTIVE]: 'success',
    [statuses.INACTIVE]: 'secondary',
    [statuses.ARCHIVED]: 'secondary',
  };

  return colorMapping[status];
};

const columnsFactory = ({ applicationId, setDuplicatedRecord, onArchive }) => [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Variant ID', accessor: 'defaultVariantId' },
  { Header: 'Name', accessor: 'name' },
  PriorityColumn,
  LabelsColumn,
  {
    Header: 'Status',
    Cell: ({ row: { original: { status, statusHumanize } } }) => (
      <Badge bg={statusBadgeColor(status, statuses)}>{statusHumanize}</Badge>
    ),
  },
  InLiveColumn,
  {
    Header: 'Start for client',
    accessor: 'startForClient',
    style: { minWidth: '6em' },
  },
  { Header: 'Month', accessor: 'humanizedEventMonth' },
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Rules',
    Cell: ({ row: { original: { ruleSectionsAttributes } } }) => <RulesList rules={ruleSectionsAttributes} />,
  },
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, name, status } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={Routes.Events.editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        {status !== statuses.ARCHIVED && <IconButton.Archive onClick={() => onArchive(id, name)} minimized />}
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { query: { applicationId } } = useRouter();
  const { response, refetch, isLoading } = useQuery(Routes.Events.indexRequest);
  const confirm = useConfirm();

  const archiveResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Daily Challenge Event',
    actionName: 'archive',
    refetch,
  });

  const onArchive = (id, name) => {
    confirm.showConfirmation({
      title: `Daily Challenge Event '${name}' will be archived and not available for work. Continue?`,
    })
      .then(() => Routes.Events.archiveRequest({ applicationId, id })
        .then(archiveResponseHandler));
  };

  const columns = useMemo(() => columnsFactory({
    applicationId, setDuplicatedRecord, onArchive,
  }), []);

  return response && (
    <>
      <PageHeader
        title="Daily Challenge Events"
        filter={<Filter statuses={statuses} />}
      >
        <IconButton.New to={Routes.Events.newPath({ applicationId })} />
      </PageHeader>
      {isLoading ? <Spinner /> : (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
          />
          <DuplicationModalWithPriority
            record={duplicatedRecord}
            entityName="Daily Challenge Event"
            handleClose={() => setDuplicatedRecord(null)}
            onSubmit={(values) => Routes.Events.duplicateRequest({ ...values, applicationId })}
            entitiesByPriority={response.entitiesByPriority || {}}
          />
          <Pagination
            pageCount={response._meta.pages}
          />
        </>
      )}
    </>
  );
}
