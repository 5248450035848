import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  isEqual, isEmpty, merge, omit, uniq,
} from 'lodash';
import { Spinner } from '@tripledotstudios/react-core';

import {
  useFormContext, useRewardOptions, useCurrentApplication,
} from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import { Rewards, ErrorIcon } from '@pages/common';

import {
  Label, Field, SelectField,
} from '@controls/form';
import { TileRoutes, JourneyRoutes, generateChapterBasedRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import { serializableLevel, serializableLevelAttributes } from '@pages/journeys/level-banks/app-utils/tile';
import { persistedCollection } from '@services/utils';

import { requestWithToSelectOptions } from '@services/toSelectOptions';

import LevelsListWrapper from '@components/journeys/banks/levels/LevelsListWrapper';
import { buildQuickLink } from '@root/services/fields-factory/quickLinksUtils';
import PictureSegments from './tile/PictureSegments';

const NEW_LEVEL_OPTIONS = {
  pictureSegmentsNumber: 2,
  assetKey: '',
  _appliedValues: {},
  populated: false,
  rewardAffiliationsAttributes: [],
  pictureSegmentsAttributes: [],
};

const rotateMeta = (levelNumber, values, resetForm) => {
  let newMeta = values.meta;
  const metaPath = `errors.configAttributes.levelsAttributes.${levelNumber - 1}`;

  if (newMeta) {
    const omitPaths = [...serializableLevelAttributes, '_uuid'].map((field) => `${metaPath}.${field}`);
    newMeta = omit(newMeta, omitPaths);

    if (isEmpty(newMeta)) {
      newMeta = omit(newMeta, metaPath);
    }
  }

  return resetForm({ values: { ...values, meta: newMeta || {} } });
};

const onApply = (values, level, applicationId, resetForm, dispatch) => (
  TileRoutes.Journeys.LevelBanks.Levels.newRequest({
    applicationId,
    ...serializableLevel(level),
    number: level.number,
  }).then(({ data, status }) => {
    if (status < 400) {
      const { pictureSegmentsAttributes } = data;
      rotateMeta(level.number, values, resetForm);

      dispatch({ actionType: 'applyLevelValues', uuid: level._uuid, pictureSegmentsAttributes });
    }

    if (status === 422) {
      resetForm({ values: { ...values, meta: isEmpty(values.meta) ? data._meta : merge(values.meta, data._meta) } });
    }
  })
);

const fetchBankOptions = (bankRoutes, applicationId, applicationName, setData) => (
  bankRoutes.indexRequest({
    applicationId,
    withoutPagination: true,
    validForUse: true,
    includeInUse: false,
  }).then(({ data: { items } }) => (
    items.map(({ id, name, levelLayoutsCount }) => ({ label: `${name} (${levelLayoutsCount})`, value: id }))),
  ).then(setData)
);

const Tile = React.memo(({ values, isArchived, readOnly: isReadOnly }) => {
  const [bankOptions, setBankOptions] = useState([]);
  const [levelLayouts, setLevelLayouts] = useState([]);
  const [modeConfigs, setModeConfigs] = useState([]);
  const { dispatch, resetForm } = useFormContext();
  const { currentApplication: { id: applicationId }, applicationName } = useCurrentApplication();
  const { LevelLayouts: LevelLayoutRoutes } = generateChapterBasedRoutes(applicationName);
  const BankRoutes = generateChapterBasedRoutes(applicationName).Banks;
  const orderingTypes = fromClassNameToOptions('Metagame::ChapterBased::ChapterOrderingTypes');

  const rewardItems = useRewardOptions();

  const { configAttributes: { levelsAttributes } } = values;
  const selectedBankIds = uniq(levelsAttributes.map(({ bankId }) => bankId).filter((id) => id));

  const onLevelAdd = () => dispatch({ actionType: 'addEmptyEntity', options: NEW_LEVEL_OPTIONS });
  const onReset = (level) => {
    rotateMeta(level.number, values, resetForm);

    return dispatch({ actionType: 'resetLevelValues', uuid: level._uuid });
  };

  useEffect(() => {
    fetchBankOptions(BankRoutes, applicationId, applicationName, setBankOptions);
    requestWithToSelectOptions(
      TileRoutes.ModesConfigs.indexRequest,
      applicationId,
      { withoutPagination: true, includeInUse: false },
      {
        toSelectOptionsFn: ({ data: { items } }) => (
          items.map(({ id, name, tileType }) => (
            { value: id, label: name, tileType }
          ))
        ),
      },
    ).then(setModeConfigs);
  }, []);

  useEffect(() => {
    const layoutsLoadedForBankIds = new Set(levelLayouts.map(({ bankId }) => bankId));

    if (layoutsLoadedForBankIds.size <= 0 || !isEqual(layoutsLoadedForBankIds, selectedBankIds)) {
      LevelLayoutRoutes.indexRequest({
        applicationId,
        filter: { bankIdIn: Array.from(selectedBankIds) },
        withoutPagination: true,
        includeInUse: false,
      }).then(({ data: { items } }) => setLevelLayouts(items));
    }
  }, [JSON.stringify(selectedBankIds)]);

  const bankQuickLinkFn = buildQuickLink(BankRoutes, applicationId);

  if (!(bankOptions && bankOptions.length)) return <Spinner />;

  return (
    <LevelsListWrapper
      entityAttributes={levelsAttributes}
      onEntityAdd={onLevelAdd}
      attributesName="levelsAttributes"
      metaErrorsPath={['errors', 'configAttributes', 'levelsAttributes']}
      entityNameTranslationPath="journeys.levelBanks.levels.name"
      disableAdding={persistedCollection(levelsAttributes).length >= 5}
      bulkCopyRoutes={JourneyRoutes.LevelBanks}
      readOnly={isReadOnly}
      disabled={isArchived}
    >
      {({ readOnly, entity: level }) => {
        const { pictureSegmentsNumber, bankId, _appliedValues } = level;

        const isDirty = !isEqual(_appliedValues, serializableLevel(level));

        return (
          <>
            <Row>
              <Col xs={6}>
                <Label text="Asset Key" direction="vertical">
                  <Field name="assetKey" disabled={readOnly} />
                </Label>
              </Col>
              <Col xs={6}>
                <Label text="Reward for the picture" direction="vertical">
                  <Rewards
                    dispatch={dispatch}
                    rewardable={level}
                    rewardItems={rewardItems}
                    maxRewards={1}
                    readOnly={readOnly}
                  />
                </Label>
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Label text="Number of segments" direction="vertical">
                  <Field name="pictureSegmentsNumber" min={1} max={7} type="number" disabled={readOnly} />
                  {pictureSegmentsNumber !== undefined && (
                    <small>
                      {`From 1 to ${pictureSegmentsNumber}`}
                    </small>
                  )}
                </Label>
              </Col>
              <Col xs={4}>
                <Label text="Layout Bank" direction="vertical">
                  <SelectField
                    name="bankId"
                    options={bankOptions}
                    isDisabled={readOnly}
                    quickLinkFn={bankQuickLinkFn}
                  />
                </Label>
              </Col>
              <Col xs={3}>
                <Label text="How to apply to the level" direction="vertical">
                  <SelectField name="orderingType" options={orderingTypes} isDisabled={readOnly} />
                </Label>
              </Col>
              <Col xs={3}>
                <Label text={<>&nbsp;</>} direction="vertical">
                  {level.pictureSegmentsAttributes.length <= 0 && (
                    <>
                      {!isDirty && (
                        <IconButton.Confirm
                          disabled={readOnly}
                          onClick={() => onApply(values, level, applicationId, resetForm, dispatch)}
                        >
                          Apply to levels
                        </IconButton.Confirm>
                      )}
                      <ErrorIcon error='Press "Apply" to create level configurations' float="right" />
                    </>
                  )}
                  {isDirty && (
                    <>
                      <IconButton.Confirm
                        disabled={readOnly}
                        onClick={() => onApply(values, level, applicationId, resetForm, dispatch)}
                      >
                        Apply to levels
                      </IconButton.Confirm>
                      <span className="text-warning float-end">
                        will not be saved without &quot;Apply to levels&quot; operation
                      </span>
                      {!isEmpty(_appliedValues) && (
                        <IconButton.Restore
                          className="ms-2"
                          onClick={() => onReset(level)}
                          disabled={readOnly}
                        >
                          Reset
                        </IconButton.Restore>
                      )}
                    </>
                  )}
                </Label>
              </Col>
            </Row>
            <PictureSegments
              dispatch={dispatch}
              levelLayouts={levelLayouts.filter((layout) => layout.bankId === bankId)}
              picture={level}
              rewardItems={rewardItems}
              modeConfigs={modeConfigs}
              LevelLayoutRoutes={LevelLayoutRoutes}
              applicationId={applicationId}
            />
          </>
        );
      }}
    </LevelsListWrapper>
  );
}, (prev, next) => isEqual(prev.values, next.values));

export default Tile;
