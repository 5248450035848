/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import {
  debounce, sortBy, at, compact, isEmpty, flatten,
} from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import APP_DATA from '@services/appData';

import { AssetsRoutes } from '@pages/routes';
import { Field, SelectField } from '@controls/form';
import { FormGroup, useCurrentApplication } from '@hooks';

import Tooltip from '@controls/Tooltip';
import useExpanded from '@components/merge/hooks/useExpanded';
import Th from '@components/merge/shared/Th';

import PostcardGraph from './postcards/PostcardGraph';
import EditablePostcardRow from './postcards/EditablePostcardRow';
import StaticPostcardRow from './postcards/StaticPostcardRow';

const { enums } = APP_DATA;
const [assetKeysStatuses, assetTypes] = at(enums, ['AssetsKeys::Statuses', 'Merge::AssetTypes']);

export default function Postcards({ dispatch, handleChange, values }) {
  const [assetKeys, setAssetKeys] = useState({});
  const [viewPostcard, setViewPostcard] = useState();
  const { isExpanded, toggleExpanded } = useExpanded();
  const { currentApplication } = useCurrentApplication();
  const { postcardsAttributes } = values;

  const onChange = debounce(({ value, postcardIndex, storyOrderIndex }) => dispatch({
    actionType: 'editStoryOrderInternalId',
    value,
    postcardIndex,
    storyOrderIndex,
  }),
  300);

  const staticColumns = useMemo(
    () => [
      { Header: 'Position', accessor: 'position' },
      {
        Header: 'Internal ID',
        accessor: 'internalId',
      },
      {
        Header: 'Prerequisites',
        Cell: ({ row: { original: { prerequisites } } }) => prerequisites.join(', '),
      },
    ],
    [],
  );

  const editableColumns = useMemo(
    () => [
      { Header: 'Position', accessor: 'position', style: { width: '10%' } },
      {
        Header: 'Internal ID',
        accessor: 'internalId',
        style: { width: '30%' },
        Cell: ({
          postcardIndex,
          row: { original: { index } },
        }) => (
          <FormGroup name={`storyOrdersAttributes[${index}]`}>
            <Field
              name="internalId"
              onChange={(e) => {
                e.persist();
                handleChange(e);
                onChange({
                  value: e.target.value,
                  postcardIndex,
                  storyOrderIndex: index,
                });
              }}
            />
          </FormGroup>
        ),
      },
      {
        Header: 'Prerequisites',
        accessor: 'prerequisites',
        style: { width: '30%' },
        Cell: ({ row: { original: { index, prerequisiteOptions } } }) => index > 0 && (
          <FormGroup name={`storyOrdersAttributes[${index}]`}>
            <SelectField
              isMulti
              name="prerequisites"
              options={prerequisiteOptions}
            />
          </FormGroup>
        ),
      },
      {
        Header: 'Actions',
        id: 'actions',
        style: { width: '25%' },
        Cell: ({ postcardIndex, row: { original: { index } } }) => (
          <FontAwesomeIcon
            icon={faTrashAlt}
            onClick={() => dispatch({
              actionType: 'removeStoryOrder',
              postcardIndex,
              storyOrderIndex: index,
            })}
          />
        ),
      },
    ],
    [],
  );

  const filterAssetKeys = (keys, selectedKeyIds, type) => {
    const ids = selectedKeyIds[type];

    return ({
      [type]: keys.filter(
        ({ id, filteringTypesValues }) => (
          ids.includes(id) || isEmpty(filteringTypesValues) || filteringTypesValues.includes(type)
        ),
      ),
    });
  };

  const fetchSelectedAssetKeys = (attributeName) => (
    compact(postcardsAttributes.map((postcardAttributes) => postcardAttributes[attributeName]))
  );

  const fetchKeys = async () => {
    const selectedKeyIdsByType = {
      [assetTypes.POSTCARD]: fetchSelectedAssetKeys('mainAssetKeyId'),
      [assetTypes.POSTCARD_SCENE]: fetchSelectedAssetKeys('sceneAssetKeyId'),
    };
    const selectedKeyIds = flatten(Object.values(selectedKeyIdsByType));

    const params = {
      applicationId: currentApplication.id,
      per: 200,
      status: assetKeysStatuses.ACTIVE,
      withoutPagination: true,
      filter: {
        withTypesOrIds: {
          types: [assetTypes.POSTCARD, assetTypes.POSTCARD_SCENE],
          ids: isEmpty(selectedKeyIds) ? null : selectedKeyIds,
        },
      },
    };

    const response = await AssetsRoutes.Keys.indexRequest(params);
    const keys = response.data.items;

    const groupedAssetKeys = {
      ...filterAssetKeys(keys, selectedKeyIdsByType, assetTypes.POSTCARD),
      ...filterAssetKeys(keys, selectedKeyIdsByType, assetTypes.POSTCARD_SCENE),
    };

    setAssetKeys(groupedAssetKeys);
  };

  useEffect(() => {
    (async () => fetchKeys())();
  }, []);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <Th width="50px" />
            <th>Position</th>
            <th>Internal ID</th>
            <th>
              <Tooltip text="There is at least one postcard configuration. The postcard structure can't be edited.">
                Configured
              </Tooltip>
            </th>
            <Th width="200px">Actions</Th>
          </tr>
        </thead>
        <tbody>
          {sortBy(postcardsAttributes, ['position']).map((postcard, index) => {
            const sharedProps = {
              dispatch,
              index,
              postcard,
              toggleExpanded,
              setViewPostcard,
              assetKeys,
              assetTypes,
            };
            if (postcard.isEditable) {
              if (postcard._destroy) { return null; }
              return (
                <EditablePostcardRow
                  key={postcard._uuid}
                  isExpanded={isExpanded(postcard._uuid)}
                  orderColumns={editableColumns}
                  {...sharedProps}
                />
              );
            }
            return (
              <StaticPostcardRow
                key={postcard._uuid}
                isExpanded={isExpanded(postcard._uuid)}
                orderColumns={staticColumns}
                {...sharedProps}
              />
            );
          })}
        </tbody>
      </Table>
      <Button onClick={() => dispatch({ actionType: 'addPostcard' })}>Add Postcard</Button>
      {viewPostcard && (
        <PostcardGraph
          postcard={viewPostcard}
          onHide={() => setViewPostcard(null)}
        />
      )}
    </>
  );
}
