import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Label, SelectField } from '@controls/form';
import { FormGroup, useCurrentApplication } from '@hooks';
import { MissionsRoutes } from '@pages/routes';
import { requestWithToSelectOptions } from '@services/toSelectOptions';

const APPLICATIONS_SUPPORTED_SEASON_REWARD_SETS = ['my_pet_cafe'];

export default function RewardSets() {
  const [rewardsSetOptions, setRewardsSetOptions] = useState([]);
  const { applicationKey } = useCurrentApplication();
  const { query: { applicationId } } = useRouter();

  const supportSeasonRewardSets = APPLICATIONS_SUPPORTED_SEASON_REWARD_SETS.includes(applicationKey);

  const rewardsSetQuickLinkFn = (id) => MissionsRoutes.Rewards.Sets.editPath({ id, applicationId });

  useEffect(() => {
    requestWithToSelectOptions(MissionsRoutes.Rewards.Sets.indexRequest, applicationId)
      .then(setRewardsSetOptions);
  }, []);

  return (
    <>
      <Label text="Reward set">
        <SelectField
          name="rewardSetId"
          options={rewardsSetOptions}
          quickLinkFn={rewardsSetQuickLinkFn}
        />
      </Label>

      {supportSeasonRewardSets && (
        <Label text="Season Reward set">
          <FormGroup name="mpcConfigAttributes">
            <SelectField
              name="rewardSetId"
              options={rewardsSetOptions}
              quickLinkFn={rewardsSetQuickLinkFn}
              includeEmpty
            />
          </FormGroup>
        </Label>
      )}
    </>
  );
}
