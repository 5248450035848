import React, { useEffect } from 'react';

import { useFormGroup, useFormContext } from '@hooks';
import { BooleanIcon } from '@pages/common';
import EditableCell from './EditableCell';

const AutoToggleField = ({ name, value, stopEditing }) => {
  const { generateName } = useFormGroup();
  const { setFieldValue } = useFormContext();

  useEffect(() => {
    setFieldValue(generateName(name), !value);
    stopEditing();
  }, []);

  return '';
};

export default function CheckboxCell({
  row, name, errors, isEditable = true,
}) {
  const value = row[name];
  const error = errors[name];

  return (
    <EditableCell
      text={<BooleanIcon value={value} />}
      isEditable={isEditable}
      error={error}
      className="disable-text-select"
    >
      {({ stopEditing }) => (
        <AutoToggleField name={name} value={value} stopEditing={stopEditing} />
      )}
    </EditableCell>
  );
}
