/* eslint-disable react/no-unknown-property */
import React from 'react';

import { Field } from '@controls/form';
import { useFormContext } from '@hooks';

export default function RewardConfig({
  data,
  tierIndex,
  missionConfigIndex,
  rewardConfigIndex,
}) {
  const { dispatch } = useFormContext();
  const { rewardName, rewardTypeName, rewardAmount } = data;

  const onBaseValueChanged = (value) => {
    dispatch({
      actionType: 'recalculateSingleRewardAmount',
      value,
      tierIndex,
      missionConfigIndex,
      rewardConfigIndex,
    });
  };

  return (
    <tr>
      <td xs={3}>{`${rewardName} (${rewardTypeName})`}</td>
      <td xs={3}><Field type="number" name="weight" /></td>
      <td xs={3}>
        <Field
          type="number"
          name="baseValue"
          onChange={(event) => onBaseValueChanged(event.target.value)}
        />
      </td>
      <td className="text-center"><span>{rewardAmount}</span></td>
    </tr>
  );
}
