import React from 'react';

import APP_DATA from '@services/appData';

import { AssetsKeySelectField, ImageFileField, Label } from '@controls/form';

const { modelsLocales: { merge: { postcards: tooltips } } } = APP_DATA;

export default function SharedPostcardFields({ assetKeys, assetTypes }) {
  return (
    <>
      <Label text="Asset: Main Bundle" tooltipText={tooltips.mainAssetKeyId}>
        <AssetsKeySelectField
          name="mainAssetKeyId"
          options={assetKeys[assetTypes.POSTCARD] || []}
        />
      </Label>
      <Label text="Asset: Scene">
        <AssetsKeySelectField
          name="sceneAssetKeyId"
          options={assetKeys[assetTypes.POSTCARD_SCENE] || []}
        />
      </Label>
      <Label text="Polaroid image">
        <ImageFileField name="polaroidImage" />
      </Label>
      <Label text="Logo image">
        <ImageFileField name="logoImage" />
      </Label>
    </>
  );
}
