import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { MergeRoutes } from '@pages/routes';
import schemaLabel from './worldSchemaLabel';

export default function requestEventSchemaOptions(applicationId) {
  return requestWithToSelectOptions(
    MergeRoutes.Events.Schemas.indexRequest,
    applicationId,
    {},
    {
      toSelectOptionsFn: (response) => (
        response.data.items.map((item) => (
          { value: item.id, label: schemaLabel(item) }
        ))
      ),
    },
  );
}
