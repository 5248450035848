import React, { useState } from 'react';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import {
  PageHeader,
  Pagination,
  InLiveColumn,
  TIMESTAMP_COLUMNS,
  ReactTable,
  DuplicationModal,
  InUseColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';
import { WoodokuSagaRoutes as Routes } from '@pages/routes';

import Filter from './Filter';

const columnsFactory = ({ applicationId, setDuplicatedRecord, onDelete }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  {
    Header: 'Actions',
    Cell: ({ row: { original: { id, inUse, name } } }) => (
      <ButtonToolbar>
        <IconButton.Edit to={Routes.FigureSets.editPath({ id, applicationId })} minimized />
        <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
        <IconButton.Delete onClick={onDelete(id, name)} disabled={isInUse(inUse)} minimized />
      </ButtonToolbar>
    ),
  },
];

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(Routes.FigureSets.indexRequest, { includeInUse: true });
  const confirm = useConfirm();
  const { query, query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Figure Set',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({ title: `Figure Set '${name}' will be deleted and not available for work. Continue?` })
      .then(() => Routes.FigureSets.deleteRequest({ applicationId, id }).then(responseHandler));
  };

  return response && (
    <div>
      <PageHeader title="Figure Sets" filter={<Filter />}>
        <IconButton.New to={Routes.FigureSets.newPath({ applicationId })} />
      </PageHeader>

      <ReactTable
        columns={columnsFactory({ applicationId, setDuplicatedRecord, onDelete })}
        data={response ? response.items : []}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <DuplicationModal
        record={duplicatedRecord}
        entityName="Figure Set"
        handleClose={() => setDuplicatedRecord(null)}
        title="Duplicate Figure Set"
        onSubmit={(values) => (
          Routes.FigureSets.duplicateRequest({ ...values, applicationId: query.applicationId })
        )}
      />

      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
