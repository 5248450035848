import { get } from 'lodash';
import React from 'react';

import { useFormGroup, useFormContext } from '@hooks';
import ColorInput from './ColorInput';
import ServerError from './ServerError';

export default function ColorField({ name, placement = 'top', disabled = null }) {
  const { values, setFieldValue, applySharedInputProps } = useFormContext();
  const { generateName } = useFormGroup();

  const fullName = generateName(name);
  const value = get(values, fullName);

  return (
    <>
      <ColorInput
        name={name}
        value={value}
        placement={placement}
        setValue={(newValue) => setFieldValue(name, newValue)}
        {...applySharedInputProps({ disabled })}
      />
      <ServerError name={name} />
    </>
  );
}
