import React, { useMemo, useState } from 'react';

import { LabelRoutes } from '@pages/routes';
import {
  PageHeader, Pagination, ReactTable,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { useCurrentApplication, useQuery, useConfirm } from '@hooks';
import IconButton from '@controls/buttons';

import EditModal from './Edit';
import NewModal from './NewModal';
import ColumnsFactory from './ColumnsFactory';
import Filter from './Filter';

export default function Index() {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const { indexRequest, deleteRequest } = LabelRoutes;
  const { response, refetch } = useQuery(indexRequest, { includeInUse: true });
  const [editedRecordId, setEditedRecordId] = useState(null);
  const [newRecord, setNewRecord] = useState(false);
  const confirm = useConfirm();

  const responseHandler = collectionResponseHandlerFactory({
    entityName: 'Label',
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => {
    confirm.showConfirmation({ title: `Label '${name}' will be deleted and not available for work. Continue?` })
      .then(() => deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({ setEditedRecordId, onDelete }), []);

  return response && (
    <div>
      <PageHeader
        title="Labels"
        filter={<Filter />}
        menuButtons={(
          <IconButton.Search
            to={LabelRoutes.searchPath({ applicationId })}
            variant="secondary"
            outline
            className="ms-2"
          >
            Search
          </IconButton.Search>
        )}
      >
        <IconButton.New onClick={() => setNewRecord(true)} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response.items}
        defaultSort={{ id: 'createdAt', desc: true }}
      />
      <NewModal
        onHide={() => setNewRecord(false)}
        recordId={newRecord}
        refetch={refetch}
        entityName="Label"
      />
      <EditModal
        onHide={() => setEditedRecordId(null)}
        recordId={editedRecordId}
        refetch={refetch}
        entityName="Label"
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
