import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { SolRoutes } from '@pages/routes';
import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import Form from './Form';

export default function Edit() {
  const { query: { id, applicationId } } = useRouter();
  const { response, setResponse } = useQuery(SolRoutes.CategorySets.editRequest, { id });

  const responseHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Category Set',
    actionName: 'update',
    setData: setResponse,
  });

  const onSubmit = (values) => (
    SolRoutes.CategorySets
      .updateRequest({ ...values, applicationId })
      .then(responseHandler)
  );

  return response && <Form data={response} onSubmit={onSubmit} actionName="Update" />;
}
