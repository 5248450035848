import React from 'react';
import { Col } from 'react-bootstrap';

import { fromClassNameToOptions } from '@services/enums';
import {
  Filter,
  TextFilter,
  StatusFilter,
  DateTimeFilter,
} from '@pages/common';

import { Label } from '@controls/form';

export default function SoloMissionsConfigsFilter({ refetch, statuses }) {
  const statusOptions = fromClassNameToOptions('MyPetCafe::SoloMissions::ConfigStatuses');

  const defaultStatuses = [
    statuses.ACTIVE,
    statuses.INACTIVE,
  ];

  return (
    <Filter
      refetch={refetch}
      defaultComponents={['availabilityStateIn', 'labels', 'createdByMe', 'namedRuleGroup', 'id']}
    >
      {({ updateFilterField }) => (
        <>
          <Col md={6}>
            <TextFilter
              name="nameContAny"
              label="Name"
              updateFilterField={updateFilterField}
            />
          </Col>
          <Col md={6}>
            <StatusFilter
              name="statusIn"
              label="Status"
              updateFilterField={updateFilterField}
              options={statusOptions}
              defaults={defaultStatuses}
            />
          </Col>
          <Col md={6} className="align-items-center">
            <Label labelSize={1} fieldSize={11} direction="vertical" text="Starts for client">
              <DateTimeFilter
                name="startsForClientAt"
                updateFilterField={updateFilterField}
              />
            </Label>
          </Col>
          <Col md={6}>
            <Label direction="vertical" text="Starts for player">
              <DateTimeFilter
                name="startsAt"
                updateFilterField={updateFilterField}
              />
            </Label>
          </Col>
        </>
      )}
    </Filter>
  );
}
