import React from 'react';
import { faFlag, faDiagramProject } from '@fortawesome/free-solid-svg-icons';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { FormGroup, useFormGroup, useFormContext } from '@hooks';
import { Alert } from '@pages/common';
import { variants, NestedConditions } from '@components/nested-conditions';
import { fromClassNameToOptions } from '@services/enums';
import { ServerError } from '@controls/form';
import IconButton from '@controls/buttons';

import Condition from './Condition';

const ROOT_SECTION_NESTING_LEVEL = 0;
const MAX_NESTING_LEVEL = 2;

export default function Section({ attributes, nestingLevel }) {
  const { conditionsSectionsAttributes, conditionsAttributes } = attributes;

  const operationsOptions = fromClassNameToOptions(
    'MyPetCafe::UnlockRequirements::Conditions::SectionOperations',
  );

  const { generateName } = useFormGroup();
  const { dispatch } = useFormContext();

  const conditionsSectionsPath = generateName('conditionsSectionsAttributes');
  const conditionsPath = generateName('conditionsAttributes');

  const onConditionsSectionAdd = () => (
    dispatch({ type: 'addUnlockRequirementsConditionsSection', conditionsSectionsPath })
  );

  const onConditionSectionRemove = () => {
    dispatch({ type: 'removeUnlockRequirementsConditionsSection', conditionsSectionPath: generateName() });
  };

  const onConditionAdd = () => (
    dispatch({ type: 'addUnlockRequirementsCondition', conditionsPath })
  );

  return (
    <NestedConditions.Section variant={variants[nestingLevel]} operations={operationsOptions}>
      <ServerError name="conditionsAttributes" as={Alert} />

      {conditionsSectionsAttributes && conditionsSectionsAttributes.map((conditionsSectionAttributes, index) => (
        !conditionsSectionAttributes._destroy && (
          <FormGroup key={conditionsSectionAttributes._uuid} name={`conditionsSectionsAttributes.${index}`}>
            <Section
              attributes={conditionsSectionAttributes}
              nestingLevel={nestingLevel + 1}
            />
          </FormGroup>
        )
      ))}

      {conditionsAttributes && conditionsAttributes.map((conditionAttributes, index) => (
        !conditionAttributes._destroy && (
          <FormGroup key={conditionAttributes._uuid} name={`conditionsAttributes.${index}`}>
            <Condition
              attributes={conditionAttributes}
              nestingLevel={nestingLevel + 1}
            />
          </FormGroup>
        )
      ))}

      <ButtonToolbar>
        <IconButton icon={faFlag} variant="success" onClick={onConditionAdd}>
          Add Condition
        </IconButton>

        {nestingLevel < MAX_NESTING_LEVEL && (
          <IconButton icon={faDiagramProject} onClick={onConditionsSectionAdd}>
            Add Section
          </IconButton>
        )}

        {nestingLevel !== ROOT_SECTION_NESTING_LEVEL && (
          <>
            <ButtonToolbar.Divider />

            <IconButton.Remove
              onClick={onConditionSectionRemove}
              variant={variants[nestingLevel - 1]}
              opacity={0.7}
              minimized
            >
              Remove Section
            </IconButton.Remove>
          </>
        )}
      </ButtonToolbar>
    </NestedConditions.Section>
  );
}
