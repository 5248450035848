import React from 'react';

import { JourneyRoutes as Routes } from '@pages/routes';
import Edit from '@components/journeys/banks/Edit';
import Form from './Form';

export default function JourneysEventsEdit() {
  return (
    <Edit
      form={Form}
      nameTranslationPath="journeys.levelBanks.name"
      routes={Routes.LevelBanks}
    />
  );
}
