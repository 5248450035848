import React from 'react';
import { useTheme } from 'styled-components';
import { faDice } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { RewardsRoutes } from '@pages/routes';

export default function RewardsNavigationItem({ applicationId, minimized }) {
  const theme = useTheme();

  return (
    <Sidebar.LinkItem
      to={RewardsRoutes.Items.indexPath({ applicationId })}
      icon={faDice}
      title="Game Items"
      color={theme.lightBlue}
      minimized={minimized}
    />
  );
}
