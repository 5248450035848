import { cloneDeep } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';

/* eslint-disable no-param-reassign, array-callback-return */
export default function rewardItemsReducer(state, action) {
  switch (action.actionType) {
    case 'removeItem': {
      const newState = cloneDeep(state);
      const { index, path = 'contentSetsAttributes' } = action;

      newState[path][index]._destroy = true;
      return newState;
    }
    case 'addItem': {
      const newState = cloneDeep(state);
      const { path = 'contentSetsAttributes' } = action;

      newState[path].push(addUuid({ _destroy: false, id: null, quantity: 1 }));
      return newState;
    }
    default: return state;
  }
}
