import React, {
  useState, useEffect, useCallback,
} from 'react';
import { isEqual, get } from 'lodash';

import APP_DATA from '@services/appData';

import { useFormContext } from '@hooks';
import { PageSection, Spinner, useRouter } from '@tripledotstudios/react-core';

import { Label, Field, SelectField } from '@controls/form';

import {
  FigureSetsRoutes, WoodokuRoutes, JourneyRoutes, FilesRoutes, AdminUsersRoutes,
} from '@pages/routes';
import { persistedCollection } from '@services/utils';
import { requestWithToSelectOptions } from '@services/toSelectOptions';

import LevelsListWrapper from '@components/journeys/banks/levels/LevelsListWrapper';

import { fromClassNameToOptions } from '@services/enums';
import { TileTypedLevel, GlobalAttributes } from './woodoku/index';
import { defaultTileTypedLevelOptions } from './woodoku/constants';
import { presentLevelGoals } from './woodoku/utils';

const fileTypesEnum = APP_DATA.enums['Woodoku::FileTypesEnum'];
const goalTypes = APP_DATA.enums['Woodoku::Journeys::LevelBanks::LevelGoalTypes'];
const tilesGenerationOptions = fromClassNameToOptions('Woodoku::Journeys::LevelBanks::TilesGenerationTypes', {
  transformLabel: (label) => {
    switch (label) {
      case 'Default':
        return 'Default (spawn acc to tile_spawn_chance)';
      case 'Exclude Reached Goal':
        return 'Stop spawn when collected';
      default:
        return label;
    }
  },
});

const Woodoku = React.memo(({ values, isArchived, readOnly: isReadOnly }) => {
  const { query } = useRouter();
  const [figureSets, setFigureSets] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [figureSetDynamicConfigs, setFigureSetDynamicConfigs] = useState([]);
  const [tileTypes, setTileTypes] = useState([]);
  const [shapesBankSetOptions, setShapesBankSetOptions] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { dispatch } = useFormContext();

  const { applicationId } = query;

  const { configAttributes: { levelsAttributes = [], socialConfigEnabled = false } } = values;

  const socialConfigFileOptions = useCallback(
    () => requestWithToSelectOptions(
      FilesRoutes.indexRequest,
      query.applicationId,
      { filter: { fileTypeEq: fileTypesEnum.JOURNEY_SOCIAL_VALIDATION } },
    ),
    [],
  );

  useEffect(() => {
    const promises = [
      FigureSetsRoutes.Sets.indexRequest({ ...query, withoutPagination: true }).then(({ data }) => setFigureSets(
        data.items.map((figureSet) => ({ label: figureSet.name, value: figureSet.id })),
      )),
      WoodokuRoutes.FigureSetDynamicConfigs.indexRequest({ ...query, withoutPagination: true }).then(({ data }) => (
        setFigureSetDynamicConfigs(
          data.items.map(({ name, id: dcId, figureSetIds }) => ({ label: name, value: dcId, figureSetIds })),
        )
      )),
      WoodokuRoutes.TileTypes.indexRequest({ ...query, withoutPagination: true }).then(({ data }) => (
        setTileTypes(data.items)
      )),
      AdminUsersRoutes.indexRequest({
        applicationId,
        withoutPagination: true,
        withTokens: true,
      }).then((usersResponse) => {
        setAdminUsers(usersResponse.data.items.map(({ id, email }) => ({ value: id, label: email })));
      }),
      requestWithToSelectOptions(WoodokuRoutes.ShapesBankSets.indexRequest, query.applicationId)
        .then((options) => (setShapesBankSetOptions(options))),
    ];

    Promise.all(promises).then(() => setDataLoaded(true));
  }, []);

  const onEntityAdd = () => dispatch({
    actionType: 'addEmptyEntity',
    options: {
      ...defaultTileTypedLevelOptions,
      rewardsAttributes: [],
    },
  });

  return (
    <>
      <Label
        text="Users"
        tooltipText="Select users who should see this level bank in Level Editor"
      >
        <SelectField
          isMulti
          name="adminUserIds"
          options={adminUsers}
        />
      </Label>
      <PageSection title="Settings" disabled={isReadOnly || isArchived}>
        <GlobalAttributes levelsAttributes={levelsAttributes} readOnly={isReadOnly} />
        <Label
          text="Special Level on Journey Map"
          tooltipText="Hard Level indicator on Journey Map screen"
          labelSize={5}
          fieldSize={7}
        >
          <Field type="checkbox" name="specialLevelEnabledMap" />
        </Label>
        <Label
          text="Special Level on Game Start and Game End popup"
          tooltipText="Hard Level indicator on Game Start and Game End Pop-up"
          labelSize={5}
          fieldSize={7}
        >
          <Field type="checkbox" name="specialLevelEnabledGamePopups" />
        </Label>
        <Label
          text="Special Level on Game Screen"
          tooltipText="Game Board is marked with a “Hard Level” indicator on Game screen"
          labelSize={5}
          fieldSize={7}
        >
          <Field type="checkbox" name="specialLevelEnabledGameScreen" />
        </Label>
        <Label
          text="Spawn generation type"
          tooltipText="When “Stop spawn when collected” is selected → the system stops
            spawning special tile in FB when tile is collected"
          labelSize={5}
          fieldSize={7}
        >
          <SelectField
            options={tilesGenerationOptions}
            name="tilesGenerationType"
          />
        </Label>
      </PageSection>

      <PageSection title="Social Validation" disabled={!socialConfigEnabled || isReadOnly || isArchived}>
        <Label
          text="Enabled"
          labelSize={5}
          fieldSize={7}
        >
          <Field type="checkbox" name="socialConfigEnabled" />
        </Label>
        <Label
          text="Journey level win"
          tooltipText="Displaying social validation on Journey win pop-ups with and without rewards"
          labelSize={5}
          fieldSize={7}
        >
          <SelectField
            name="playingConfigFileId"
            isDisabled={!socialConfigEnabled || isReadOnly || isArchived}
            options={socialConfigFileOptions}
          />
        </Label>
        <Label
          text="Journey level lose"
          tooltipText="Displaying social validation on Journey lose pop-ups: in game and default flow type"
          labelSize={5}
          fieldSize={7}
        >
          <SelectField
            name="retryingConfigFileId"
            isDisabled={!socialConfigEnabled || isReadOnly || isArchived}
            options={socialConfigFileOptions}
          />
        </Label>
        <Label
          text="Probability"
          labelSize={5}
          fieldSize={7}
        >
          <Field
            type="number"
            step={0.01}
            name="endGamePopupShowInfoRate"
            disabled={!socialConfigEnabled || isReadOnly || isArchived}
          />
        </Label>
      </PageSection>

      {dataLoaded ? (
        <LevelsListWrapper
          entityAttributes={levelsAttributes}
          onEntityAdd={onEntityAdd}
          attributesName="levelsAttributes"
          metaErrorsPath={['errors', 'configAttributes', 'levelsAttributes']}
          entityNameTranslationPath="journeys.levelBanks.levels.name"
          extraEntityIdentityFn={({ levelGoalsAttributes }) => (
            presentLevelGoals(persistedCollection(levelGoalsAttributes), goalTypes, tileTypes)
          )}
          bulkCopyRoutes={JourneyRoutes.LevelBanks}
          readOnly={isReadOnly}
          disabled={isArchived || isReadOnly}
        >
          {(props) => (
            <TileTypedLevel
              figureSets={figureSets}
              figureSetDynamicConfigs={figureSetDynamicConfigs}
              tileTypes={tileTypes}
              shapesBankSetOptions={shapesBankSetOptions}
              errors={get(values, `meta.errors.configAttributes.levelsAttributes.${props.index}`, {})}
              disabled={isArchived}
              {...props}
            />
          )}
        </LevelsListWrapper>
      ) : <Spinner name="levels data" />}
    </>
  );
}, (prev, next) => isEqual(prev.values, next.values));

export default Woodoku;
