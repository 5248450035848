import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { PageHeader } from '@pages/common';
import { Form } from '@hooks';
import {
  Label, Field, FormButtonsGroup, FieldArrayWithLabel,
} from '@controls/form';

export default function GameConfigForm({
  data, indexPath, onSubmit, configName,
}) {
  const tags = [
    { label: 'Coming soon', value: 0 },
    { label: 'New', value: 1 },
    { label: 'Popular puzzle', value: 2 },
    { label: 'For you', value: 3 },
  ];

  const sizes = [
    { label: 'Small', value: 0 },
    { label: 'Medium', value: 1 },
    { label: 'Large', value: 2 },
  ];

  const fieldsConfiguration = [
    {
      label: 'Game', name: 'game', type: 'text',
    },
    {
      label: 'Size', name: 'size', as: 'select', componentData: sizes,
    },
    {
      label: 'Tags', name: 'tags', as: 'select', isMultiSelect: true, componentData: tags,
    },
  ];

  const { query: { applicationId } } = useRouter();

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit}>
      {({ values }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} ${configName}`}>
            <FormButtonsGroup cancelButtonPath={indexPath} />
          </PageHeader>

          <Field type="hidden" name="applicationId" />

          <Label text="Name"><Field type="text" name="name" /></Label>
          <FieldArrayWithLabel
            collectionName="cells"
            values={values.cells}
            fieldsConfiguration={fieldsConfiguration}
            isHorizontal
            reorderable
          />
        </>
      )}
    </Form>
  );
}
