import React from 'react';
import styled from 'styled-components';

import { combineReducers, rewardableReducer } from '@reducers';
import IconButton from '@controls/buttons';
import {
  Form, FormGroup, useCurrentApplication, useRewardOptions,
} from '@hooks';
import {
  FormButtonsGroup, Label, Field, StaticFieldsFormGroup, ServerError,
} from '@controls/form';
import { TileRoutes } from '@pages/routes';
import { DuplicateButton, PageHeader, Rewards } from '@pages/common';
import Tooltip from '@controls/Tooltip';
import APP_DATA from '@services/appData';
import { persistedCollection } from '@services/utils';
import formReducer from './formReducer';

const Row = styled.div`
  display: flex;
`;

const RewardCol = styled.div`
  width: 45%;
`;

const MultiplierCol = styled.div`
  width: 15%;
  padding-right: 1rem;
`;

const ChaptersCol = styled.div`
  width: 30%;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
`;

const FieldWrapper = styled.div`
  align-self: start;
  width: 47%;
`;

const ActionsCol = styled.div`
  width: 10%;
`;

const reducer = combineReducers([formReducer, rewardableReducer]);
export default function RewardsForWinsSchemasForm({ data, onSubmit }) {
  const { currentApplication: { id: applicationId } } = useCurrentApplication();
  const rewardItems = useRewardOptions();
  const { modelsLocales: { tile: { rewardsForWinsSchemas } } } = APP_DATA;

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={reducer}
    >
      {({ values, dispatch }) => {
        const onToFieldChange = (value, index) => {
          dispatch({
            type: 'changeToField',
            value,
            index,
          });
        };

        const onRemove = (index) => {
          dispatch({ type: 'removeRow', index });
        };

        const lastVisibleRow = persistedCollection(values.rowsAttributes).slice(-1)[0];
        return (
          <>
            <PageHeader title={`${data.id ? 'Edit' : 'New'} Coins for wins schema`}>
              <FormButtonsGroup cancelButtonPath={TileRoutes.RewardsForWinsSchemas.indexPath({ applicationId })}>
                <DuplicateButton
                  routes={TileRoutes.RewardsForWinsSchemas}
                  data={data}
                  entityName="Coins for wins schema"
                />
              </FormButtonsGroup>
            </PageHeader>

            <Label text="Name">
              <Field type="text" name="name" />
            </Label>

            <StaticFieldsFormGroup data={data} />

            <Row className="mb-4">
              <RewardCol className="fw-bold">
                <Tooltip text={rewardsForWinsSchemas.reward.tooltip}>
                  Reward after win
                </Tooltip>
              </RewardCol>
              <MultiplierCol className="fw-bold">
                <Tooltip text={rewardsForWinsSchemas.multiplier.tooltip}>
                  Multiplier
                </Tooltip>
              </MultiplierCol>
              <ChaptersCol className="fw-bold justify-content-center">
                <div className="flex-column w-100">
                  Chapters
                  <div className="justify-content-between d-flex mt-2">
                    <FieldWrapper>
                      <Tooltip text={rewardsForWinsSchemas.from.tooltip}>
                        From
                      </Tooltip>
                    </FieldWrapper>
                    <FieldWrapper>
                      <Tooltip text={rewardsForWinsSchemas.to.tooltip}>
                        To
                      </Tooltip>
                    </FieldWrapper>
                  </div>
                </div>
              </ChaptersCol>
            </Row>
            <ServerError name="rowsAttributes.list" />

            {values.rowsAttributes.map((row, index) => (!row._destroy && (
              <Row className="mb-4" key={row._uuid}>
                <FormGroup name={`rowsAttributes.${index}`}>
                  <RewardCol>
                    <Rewards
                      rewardable={row}
                      dispatch={dispatch}
                      rewardItems={rewardItems}
                      maxRewards={1}
                      minRewards={1}
                    />
                  </RewardCol>
                  <MultiplierCol>
                    <Field type="number" name="multiplier" />
                  </MultiplierCol>
                  <ChaptersCol>
                    <FieldWrapper>
                      <Field type="number" name="from" disabled />
                    </FieldWrapper>
                    <FieldWrapper>
                      {(row._uuid === lastVisibleRow._uuid) ? (
                        'All Next'
                      ) : (
                        <Field
                          type="number"
                          name="to"
                          onChange={({ target: { value } }) => onToFieldChange(value, index)}
                        />
                      )}
                    </FieldWrapper>
                  </ChaptersCol>
                </FormGroup>
                <ActionsCol>
                  <IconButton.Delete
                    minimized
                    onClick={() => onRemove(index)}
                    className="float-end"
                  />
                </ActionsCol>
              </Row>
            )))}

            <IconButton.New onClick={() => dispatch({ type: 'addRow' })}>Add</IconButton.New>
          </>
        );
      }}
    </Form>
  );
}
