import { useCallback } from 'react';
import { memoize } from 'lodash';

import { AppEventsRoutes } from '@pages/routes';
import { selectBuilder } from '@services/fields-factory';
import { buildConverterFromIndexResponse, requestWithToSelectOptions } from '@services/toSelectOptions';

const staticFields = memoize((appEvents) => selectBuilder({
  name: 'appEventIds',
  options: appEvents,
  selectOptions: { isMulti: true },
}));

const appEventsFields = (applicationId) => {
  const appEvents = useCallback(
    () => requestWithToSelectOptions(
      AppEventsRoutes.indexRequest,
      applicationId,
      {},
      { toSelectOptionsFn: buildConverterFromIndexResponse({ labelAttribute: 'key' }) },
    ),
    [],
  );
  return staticFields(appEvents);
};

export default appEventsFields;
