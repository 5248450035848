import React, { memo } from 'react';
import { get } from 'lodash';

import { PageSection } from '@pages/common';
import { FieldArrayWithLabel } from '@controls/form';
import getFieldLocale from '@services/getFieldLocale';
import { TitledAlert } from '@components/shared';

import localizeField from './localizeField';
import areEnabledPropsEqual from './areEnabledPropsEqual';

export default function FieldArrayBuilder({
  name: baseName, labelText, fieldsConfiguration, fieldOptions = {}, tooltips = {}, forceFieldLabels = false,
  reorderable = false, handleOnly = false, showPosition = false, options = {}, alertText, customAlertName,
}) {
  return {
    [baseName]: {
      Inline: memo(({
        name: overrideName, values: formValues, disabled, localeNamespace, locale, tooltipBuilder,
      }) => {
        const name = overrideName || baseName;
        const values = get(formValues, name);
        const resolvedLocale = locale || getFieldLocale(localeNamespace, baseName);

        const fieldArrayOptions = {
          collectionName: name,
          values,
          fieldsConfiguration,
          disabled,
          locale: resolvedLocale,
          reorderable,
          handleOnly,
          showPosition,
          tooltips,
          tooltipBuilder,
        };

        return <FieldArrayWithLabel {...fieldArrayOptions} />;
      }, areEnabledPropsEqual),
      Labeled: memo(({
        name: overrideName, values: formValues, disabled, localeNamespace, locale, tooltipBuilder,
      }) => {
        const name = overrideName || baseName;
        const values = get(formValues, name);
        const disableLabel = fieldsConfiguration.length <= 1 && !forceFieldLabels;
        const resolvedLocale = locale || getFieldLocale(localeNamespace, baseName);

        const { label, tooltip, clientKey } = localizeField(
          baseName, labelText, tooltips, localeNamespace, locale, tooltipBuilder,
        );
        const fieldArrayOptions = {
          collectionName: name,
          values,
          fieldsConfiguration,
          tooltipText: tooltip,
          disableLabel,
          disabled,
          reorderable,
          handleOnly,
          locale: resolvedLocale,
          localeNamespace,
          showPosition,
          tooltips,
          tooltipBuilder,
        };

        const pageSectionProps = {
          title: label,
        };

        if (tooltip) pageSectionProps.tooltip = tooltip;
        if (clientKey) pageSectionProps.copyableText = clientKey;

        return (
          <>
            <PageSection {...pageSectionProps} className="mt-2" />
            <FieldArrayWithLabel {...fieldArrayOptions} />
            <TitledAlert name={name} alertText={alertText} customAlertName={customAlertName} />
          </>
        );
      }, areEnabledPropsEqual),
      options,
      fieldOptions,
    },
  };
}
