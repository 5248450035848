import reorderMeta from '@services/reorderMeta';
import {
  last, cloneDeep, isPlainObject, get, sortBy,
} from 'lodash';

import { addUuid } from '@services/recursivelyProcessObject';
import removeEntity from '@services/removeEntity';
import removeNestedIds from '@services/removeNestedIds';
import { dndRecords } from '@services/reorder';
import reorderEntities from '@services/reorderEntities';
import { persistedCollection } from '@services/utils';

export default function reducer(state, action) {
  switch (action.actionType) {
    case 'reorderAreas': {
      dndRecords(state, action);
      break;
    }
    case 'addEmptyEntity': {
      const areas = persistedCollection(state.areasAttributes);
      state.areasAttributes.push(addUuid({
        id: null,
        actionsAttributes: [addUuid({ position: 1 })],
        rewardAffiliationsAttributes: [],
        position: areas.length > 0 ? Math.max(...areas.map((p) => p.position)) + 1 : 1,
      }));
      break;
    }
    case 'duplicateEntity': {
      const { _uuid: originalUuid } = action;

      const { id, _uuid, ...originalArea } = state.areasAttributes.find((area) => area._uuid === originalUuid);
      const lastArea = last(state.areasAttributes.filter(({ _destroy }) => !_destroy));

      const duplicatedArea = removeNestedIds(originalArea);

      state.areasAttributes.push(addUuid({
        ...duplicatedArea, _destroy: false, position: lastArea.position + 1,
      }));
      break;
    }
    case 'bulkRemoveEntities': {
      const { areasAttributes } = state;
      const { uuids } = action;

      const areas = cloneDeep(areasAttributes);

      areas.forEach((area) => {
        if (uuids.includes(area._uuid)) area._destroy = true;

        return area;
      });

      state.areasAttributes = reorderEntities(areas, 'position').result;

      break;
    }
    case 'restoreEntity': {
      const { areasAttributes } = state;
      const { _uuid: uuidToDelete } = action;

      const areas = cloneDeep(areasAttributes);

      delete areas.find(({ _uuid }) => _uuid === uuidToDelete)._destroy;

      state.areasAttributes = reorderEntities(areas, 'position').result;

      break;
    }
    case 'removeEntity': {
      const { _uuid: uuidToDelete } = action;

      return removeEntity(state, 'areasAttributes', uuidToDelete, 'position');
    }
    case 'moveEntity': {
      const { areasAttributes } = state;
      const {
        sourceIndex,
        destinationIndex,
        meta,
        metaErrorsPath,
      } = action;

      const areas = cloneDeep(areasAttributes);

      if (areasAttributes.length <= 1) return state;

      const { result, changeSet: [fromIndices, toIndices] } = reorderEntities(
        areas, 'position', sourceIndex, destinationIndex,
      );

      state.areasAttributes = result;

      if (meta.errors && isPlainObject(get(meta, metaErrorsPath))) {
        meta.errors = { ...meta.errors, ...reorderMeta(meta, fromIndices, toIndices, metaErrorsPath).errors };
      }
      break;
    }
    case 'applyPositions': {
      const { areasAttributes } = state;
      const { uuidToPositionMapping } = action;

      areasAttributes.forEach((area) => {
        area.position = uuidToPositionMapping[area._uuid];

        return area;
      });

      state.areasAttributes = sortBy(areasAttributes, 'position');

      break;
    }
    case 'addAction': {
      const area = state.areasAttributes[action.areaIndex];
      const actions = persistedCollection(area.actionsAttributes);
      area.actionsAttributes.push(addUuid({
        id: null,
        position: actions.length > 0 ? Math.max(...actions.map((p) => p.position)) + 1 : 1,
      }));
      break;
    }
    case 'removeAction': {
      const { actionIndex } = action;
      const area = state.areasAttributes[action.areaIndex];
      const targetAction = area.actionsAttributes[actionIndex];

      targetAction._destroy = true;
      area.actionsAttributes.slice(actionIndex + 1).forEach((currentAction) => {
        if (!currentAction._destroy) currentAction.position -= 1;
      });
      break;
    }
    default: break;
  }

  return state;
}
