import {
  cloneDeep, get, last, set, omit, merge, pickBy, isNil,
} from 'lodash';

import { prepareInboundData } from '@requests/common';
import { itemId } from '@hooks/useSelectedRows';
import { addUuid, process } from '@services/recursivelyProcessObject';
import { moveUp, moveDown } from '@services/reorder';
import { serializableChapter, persistedLevelsAttributes } from '@services/chapterSets';

const GENERATED_CHAPTER_NAME_REGEXP = /^Chapter \d+$/;

const isValidMassUpdateValue = (value, key) => key !== '_uuid' && !isNil(value) && value !== '';

/* eslint-disable no-param-reassign, array-callback-return */
export default function chapterSetsReducer(state, action, appData) {
  const orderingTypes = appData.enums['Metagame::ChapterBased::ChapterOrderingTypes'];

  const updateGeneratedChapterNames = () => (
    state.chaptersAttributes.forEach((chapter) => {
      if (chapter._destroy) return chapter;

      if (GENERATED_CHAPTER_NAME_REGEXP.test(chapter.name)) {
        chapter.name = `Chapter ${chapter.position}`;
      }

      return chapter;
    })
  );

  switch (action.actionType) {
    case 'removeChapter': {
      const { index } = action;

      state.chaptersAttributes[index]._destroy = true;
      for (let i = index + 1; i < state.chaptersAttributes.length; i += 1) {
        state.chaptersAttributes[i].position -= 1;
      }
      const validChapter = state.validChapters[state.chaptersAttributes[index]._uuid];
      if (validChapter) { validChapter._destroy = true; }

      updateGeneratedChapterNames();

      break;
    }
    case 'addChapter': {
      const lastChapter = last(state.chaptersAttributes.filter((c) => !c._destroy));
      const position = lastChapter ? lastChapter.position + 1 : 1;

      state.chaptersAttributes.push(addUuid({
        _destroy: false,
        id: null,
        levelsNumber: 1,
        orderingType: orderingTypes.BY_ORDER_IN_BANK,
        name: `Chapter ${position}`,
        position,
        configAttributes: {
          iconsOffset: 0,
        },
        rewardAffiliationsAttributes: [],
        chapterBankAffiliationsAttributes: [addUuid({ _destroy: false, applyAll: true })],
      }));

      break;
    }
    case 'populateChapter': {
      const { response } = action;

      set(state, 'meta.errors.chaptersAttributes', get(state, 'meta.errors.chaptersAttributes', {}));

      if (response.status === 422) {
        state.chaptersAttributes.forEach(({ _uuid }, index) => {
          const errors = prepareInboundData(response.rawData._meta.errors[_uuid], { skipUuids: false });

          if (errors) set(state, `meta.errors.chaptersAttributes[${index}]`, errors);
        });

        break;
      }

      state.chaptersAttributes.forEach((chapter, index) => {
        if (chapter._destroy) return;

        delete state.meta.errors.chaptersAttributes[index];
        const newChapter = prepareInboundData(
          response.rawData.chapters_attributes[chapter._uuid],
          { skipUuids: false },
        );

        if (newChapter) {
          merge(newChapter, omit(chapter, ['levelsAttributes', '_uuid']));

          newChapter.populated = true;

          const levelsAttributes = [
            ...newChapter.levelsAttributes,
            ...(chapter.levelsAttributes || []).map((level) => ({ ...level, _destroy: true })),
          ];
          merge(levelsAttributes, persistedLevelsAttributes(state.applicationName, chapter.levelsAttributes));
          newChapter.levelsAttributes = levelsAttributes;

          state.chaptersAttributes[index] = newChapter;
          state.validChapters[newChapter._uuid] = cloneDeep(serializableChapter(newChapter));

          delete state.validChapters[chapter._uuid];
        }
      });

      break;
    }
    case 'moveDown': {
      moveDown(state, ['chaptersAttributes'], action);
      updateGeneratedChapterNames();

      break;
    }
    case 'moveUp': {
      moveUp(state, ['chaptersAttributes'], action);
      updateGeneratedChapterNames();

      break;
    }
    case 'resetChapter': {
      const { index } = action;
      const targetChapter = state.chaptersAttributes[index];
      state.chaptersAttributes[index] = { ...targetChapter, ...state.validChapters[targetChapter._uuid] };
      break;
    }
    case 'changeLevelLayout': {
      const { chapterPath } = action;
      const chapter = get(state, chapterPath);
      set(state, chapterPath, { ...chapter, orderingType: orderingTypes.MANUALLY });
      state.validChapters[chapter._uuid] = {
        ...state.validChapters[chapter._uuid],
        orderingType: orderingTypes.MANUALLY,
      };
      break;
    }
    case 'massUpdate': {
      const { chapters } = action;
      const chapterIds = chapters.map(itemId);

      const massUpdateValues = process(state.massUpdate, (obj) => pickBy(obj, isValidMassUpdateValue));

      const { rewardAffiliationsAttributes, ...plainAttributes } = massUpdateValues;
      const rewards = rewardAffiliationsAttributes.filter((reward) => !reward._destroy);

      state.chaptersAttributes.filter((chapter) => chapterIds.indexOf(itemId(chapter)) > -1).forEach((chapter) => {
        merge(chapter, plainAttributes);
        if (rewards.length > 0) {
          chapter.rewardAffiliationsAttributes.forEach((reward) => {
            reward._destroy = true;
          });
          chapter.rewardAffiliationsAttributes = [
            ...chapter.rewardAffiliationsAttributes,
            ...rewards,
          ];
        }
      });
      state.massUpdate = { rewardAffiliationsAttributes: [] };
      break;
    }
    case 'addChapterBankAffiliation': {
      const { chapterUuid } = action;

      const chapter = state.chaptersAttributes.find(({ _uuid }) => _uuid === chapterUuid);
      chapter.chapterBankAffiliationsAttributes.push(addUuid({ applyAll: true }));

      break;
    }

    case 'removeChapterBankAffiliation': {
      const { chapterUuid, _uuid } = action;

      const targetChapter = state.chaptersAttributes.find((chapter) => chapter._uuid === chapterUuid);
      targetChapter.chapterBankAffiliationsAttributes.find(({ _uuid: uuid }) => uuid === _uuid)._destroy = true;

      break;
    }
    default: break;
  }

  return state;
}
