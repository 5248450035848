import { isNil, get } from 'lodash';
import { addUuid } from '@services/recursivelyProcessObject';

const resolveState = (state, variantIndex) => (
  isNil(variantIndex)
    ? get(state, 'currentVariantAttributes', {})
    : state.variantsAttributes[variantIndex]
);

/* eslint-disable no-param-reassign, array-callback-return */
export default function formReducer(state, action) {
  const { actionType, variantIndex } = action;
  const variant = resolveState(state, variantIndex);

  if (actionType === 'addBankToWinStreakConfig') {
    const { id } = action;

    variant.bankAffiliationsAttributes.push(addUuid({
      bankId: id,
      variantId: variant.id,
      _destroy: false,
    }));

    return state;
  }

  if (actionType === 'removeBankFromWinStreakConfig') {
    const { id } = action;

    variant.bankAffiliationsAttributes.forEach((affiliation) => {
      if (affiliation.bankId === id) affiliation._destroy = true;
    });
  }

  return state;
}
