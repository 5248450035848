import { addUuid } from '@services/recursivelyProcessObject';
import { dndRecords } from '@services/reorder';

export default function formReducer(state, action) {
  switch (action.type) {
    case 'changeSchemaId': {
      state.schemaPostcardId = null;
      state.rewardsAttributes = [];
      state.storyOrdersAttributes = [];
      break;
    }
    case 'populateStoryOrders': {
      state.storyOrdersAttributes = action.storyOrdersAttributes;
      break;
    }
    case 'reorderTasks': {
      dndRecords(state, action);
      break;
    }
    case 'addTask': {
      const { storyOrderIndex } = action;
      const storyOrder = state.storyOrdersAttributes[storyOrderIndex];
      const tasks = storyOrder.tasksAttributes.filter((p) => !p._destroy);
      storyOrder.tasksAttributes.push(addUuid({
        id: null,
        internalId: `${storyOrder.internalId}_task_<next_id>`,
        targetsAttributes: [addUuid({ _destroy: false, id: null, quantity: 1 })],
        rewardsAttributes: [],
        position: tasks.length > 0 ? Math.max(...tasks.map((p) => p.position)) + 1 : 1,
      }));
      break;
    }
    case 'removeTask': {
      const { index, storyOrderIndex } = action;
      const storyOrder = state.storyOrdersAttributes[storyOrderIndex];
      const task = storyOrder.tasksAttributes[index];

      task._destroy = true;
      for (let i = index + 1; i < storyOrder.tasksAttributes.length; i += 1) {
        const currentTask = storyOrder.tasksAttributes[i];
        if (!currentTask._destroy) {
          currentTask.position -= 1;
        }
      }
      break;
    }
    default: break;
  }

  return state;
}
