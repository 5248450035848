import React from 'react';
import { useTheme } from 'styled-components';
import {
  faTable,
  faWindowMaximize,
  faListOl,
  faMedal,
  faBrain,
  faLayerGroup,
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';
import { SolRoutes, MachineLearningRoutes } from '@pages/routes';

import {
  ApplicationItemSet,
  AssetsNavigationItem,
  AdsNavigationSection,
  JourneysNavigationSection,
  FilesNavigationItem,
  RewardsNavigationItem,
} from './common';

export default function Solitaire({ applicationId, routingScope, minimized = false }) {
  const theme = useTheme();

  return (
    <ApplicationItemSet
      applicationId={applicationId}
      applicationTypeName="solitaire"
      routingScope={routingScope}
      minimized={minimized}
    >
      <FilesNavigationItem minimized={minimized} applicationId={applicationId} />
      <AssetsNavigationItem minimized={minimized} applicationId={applicationId} />

      <JourneysNavigationSection
        includeActivationEvents
        routingScope={routingScope}
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to={`${routingScope}/solitaire/level_layouts`}
        icon={faTable}
        title="Level Layouts"
        color={theme.yellow}
        minimized={minimized}
      />

      <Sidebar.SectionItem
        icon={faWindowMaximize}
        title="Flexible Popups"
        color={theme.green}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/flexible_popups/popups`}
          title="Flexible Popups"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/flexible_popups/actions`}
          title="Actions"
        />
      </Sidebar.SectionItem>

      <Sidebar.SectionItem
        icon={faListOl}
        title="Mastery Level"
        color={theme.purple}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={SolRoutes.PlayerLevelSets.indexPath({ applicationId })}
          title="Player Levels Sets"
        />
        <Sidebar.LinkItem
          to={SolRoutes.WinSets.indexPath({ applicationId })}
          title="Xth Win Sets"
        />
        <Sidebar.LinkItem
          to={SolRoutes.FirstTryStreakConfigs.indexPath({ applicationId })}
          title="First Try Streak Configs"
        />
      </Sidebar.SectionItem>

      <RewardsNavigationItem minimized={minimized} applicationId={applicationId} />

      <Sidebar.SectionItem
        icon={faMedal}
        title="Daily Reward Configs"
        color={theme.orange}
        minimized={minimized}
      >
        <Sidebar.LinkItem
          to={`${routingScope}/daily_rewards/banks`}
          title="Daily Reward Banks"
        />
        <Sidebar.LinkItem
          to={`${routingScope}/daily_rewards/configs`}
          title="Daily Reward Configs"
        />
      </Sidebar.SectionItem>

      <Sidebar.LinkItem
        to={SolRoutes.CategorySets.indexPath({ applicationId })}
        icon={faLayerGroup}
        title="Category Sets"
        color={theme.conifer}
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to={MachineLearningRoutes.Models.indexPath({ applicationId })}
        icon={faBrain}
        title="ML Models"
        color={theme.turquoise}
        minimized={minimized}
      />

      <AdsNavigationSection routingScope={routingScope} applicationId={applicationId} minimized={minimized} />
    </ApplicationItemSet>
  );
}
