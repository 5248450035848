import React, { useEffect, useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { MergeRoutes } from '@pages/routes';
import { requestWithToSelectOptions } from '@services/toSelectOptions';
import { Label, SelectField } from '@controls/form';

export default function ConfigurationTab({ schemaId }) {
  const [itemConfigurationSetOptions, setItemConfigurationSetOptions] = useState([]);
  const [orderConfigurationOptions, setOrderConfigurationOptions] = useState([]);
  const [levelSetOptions, setLevelSetOptions] = useState([]);
  const [startingGridOptions, setStartingGridOptions] = useState([]);
  const { query: { applicationId } } = useRouter();

  useEffect(() => {
    (async () => {
      if (!schemaId) return;

      Promise.all([
        requestWithToSelectOptions(
          MergeRoutes.ItemConfigurationSets.indexRequest,
          applicationId,
          { filter: { schemaTypeEq: 'Merge::Events::Schema', schemaIdEq: schemaId } },
        ).then(setItemConfigurationSetOptions),
        requestWithToSelectOptions(
          MergeRoutes.StartingGrids.indexRequest,
          applicationId,
          { filter: { schemaTypeEq: 'Merge::Events::Schema', schemaIdEq: schemaId } },
        ).then(setStartingGridOptions),
        requestWithToSelectOptions(
          MergeRoutes.Events.LevelSets.indexRequest,
          applicationId,
          { filter: { eventSchemaIdEq: schemaId } },
        ).then(setLevelSetOptions),
        requestWithToSelectOptions(
          MergeRoutes.Events.OrdersConfigurations.indexRequest,
          applicationId,
          { filter: { schemaIdEq: schemaId } },
        ).then(setOrderConfigurationOptions),
      ]);
    })();
  }, [schemaId]);

  return (
    <>
      <Label text="Item Configuration Set">
        <SelectField
          name="itemConfigurationSetId"
          options={itemConfigurationSetOptions}
        />
      </Label>
      <Label text="Order Configuration">
        <SelectField
          name="ordersConfigurationId"
          options={orderConfigurationOptions}
        />
      </Label>
      <Label text="Reward Track Configuration">
        <SelectField
          name="levelSetId"
          options={levelSetOptions}
        />
      </Label>
      <Label text="Starting Grid">
        <SelectField
          name="startingGridId"
          options={startingGridOptions}
        />
      </Label>
    </>
  );
}
