import React from 'react';
import styled from 'styled-components';

import { Col } from 'react-bootstrap';
import { fromClassNameToOptions } from '@services/enums';
import SelectField from '@controls/form/SelectField';

const Container = styled(Col)`
  max-width: 90px;
  padding-right: 0;
  padding-left: 0;
`;

export default function OperationCol({ disabled }) {
  const operationOptions = fromClassNameToOptions('Rules::Sections::Operations');

  return (
    <Container>
      <SelectField
        name="operation"
        options={operationOptions}
        isDisabled={disabled}
        hasPrepopulatedOption
      />
    </Container>
  );
}
