import React, { useMemo, useState } from 'react';
import { merge } from 'lodash';
import { useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';
import {
  PageHeader,
  Pagination,
  ReactTable,
} from '@pages/common';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';
import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

import Filter from './Filter';
import ColumnsFactory from './ColumnsFactory';

export default function Index({
  DuplicationModal, entityName, requestSchemaOptions, routePostfix, schemaType, schemaTypeName,
}) {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { response, refetch } = useQuery(
    (params) => MergeRoutes.ItemConfigurationSets.indexRequest(merge(params, { filter: { schemaTypeEq: schemaType } })),
    { includeInUse: true },
  );
  const confirm = useConfirm();
  const { query: { applicationId } } = useRouter();

  const responseHandler = collectionResponseHandlerFactory({
    entityName,
    actionName: 'delete',
    refetch,
  });

  const onDelete = (id, name) => () => {
    confirm.showConfirmation({
      title: `Item Configuration Set '${name}' will be deleted and not available for work. Continue?`,
    })
      .then(() => MergeRoutes.ItemConfigurationSets.deleteRequest({ id, applicationId })
        .then(responseHandler));
  };

  const columns = useMemo(() => ColumnsFactory({
    applicationId, setDuplicatedRecord, onDelete, routePostfix, schemaTypeName,
  }), []);

  return response && (
    <div>
      <PageHeader
        title={`${entityName}s`}
        filter={(
          <Filter
            applicationId={applicationId}
            requestSchemaOptions={requestSchemaOptions}
            schemaTypeName={schemaTypeName}
          />
        )}
      >
        <IconButton.New to={MergeRoutes.ItemConfigurationSets.newPath({ applicationId, routePostfix })} />
      </PageHeader>

      <ReactTable
        columns={columns}
        data={response ? response.items : []}
      />
      <DuplicationModal
        buildRedirectPath={(id) => MergeRoutes.ItemConfigurationSets.editPath({ id, applicationId, routePostfix })}
        entityName="Item Configuration set"
        record={duplicatedRecord}
        handleClose={() => setDuplicatedRecord(null)}
        onSubmit={(values) => (
          MergeRoutes.ItemConfigurationSets.duplicateRequest({ ...values, applicationId })
        )}
        title="Duplicate Item Configuration set"
        applicationId={applicationId}
      />
      <Pagination
        pageCount={response._meta.pages}
      />
    </div>
  );
}
