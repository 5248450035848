import React from 'react';

import {
  Field,
  localizedLabelFactory,
} from '@controls/form';

import { PageSection } from '@pages/common';

const LocalizedLabel = localizedLabelFactory('solitaire.machineLearning.model.config');

export default function Solitare({ readOnly }) {
  return (
    <PageSection title="Model request parameters">
      <LocalizedLabel name="seedsAmount">
        <Field name="seedsAmount" type="number" disabled={readOnly} />
      </LocalizedLabel>

      <LocalizedLabel name="targetWinRate">
        <Field name="targetWinRate" type="number" step="0.1" disabled={readOnly} />
      </LocalizedLabel>
    </PageSection>
  );
}
