import React from 'react';

import { DuplicationModal } from '@pages/common';
import StartingGridsIndex from '../../starting-grids/Index';

export default function List(props) {
  return (
    <StartingGridsIndex
      DuplicationModal={DuplicationModal}
      {...props}
    />
  );
}
