import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import {
  Form,
  useRewardOptions,
  useCurrentApplication,
  useQuery,
} from '@hooks';
import { MissionsRoutes } from '@pages/routes';
import { Label, SelectField } from '@controls/form';
import { noRedirectHandlerFactory } from '@requests/responseHandlers';

export default function NewGameItemModal({
  showModal,
  handleClose,
  refetch,
}) {
  if (!showModal) return null;

  const { currentApplication } = useCurrentApplication();
  const applicationId = currentApplication.id;
  const { response, setResponse } = useQuery(MissionsRoutes.Rewards.Types.newRequest);

  const [rewardTypes, setRewardTypes] = useState([]);

  useEffect(() => {
    MissionsRoutes.Rewards.Types.indexRequest({ applicationId }).then((r) => setRewardTypes(r.data.items));
  }, []);

  const rewardItems = useRewardOptions();
  const usedRewardsIds = rewardTypes.map((type) => type.rewardItemId);
  const filteredRewardItems = rewardItems.filter(({ value }) => !usedRewardsIds.includes(value));

  const responseHandler = noRedirectHandlerFactory({
    entityName: 'Reward type',
    actionName: 'creation',
    setData: setResponse,
  });

  const onClose = () => {
    setResponse({});
    handleClose();
  };

  const onSubmit = (values) => MissionsRoutes.Rewards.Types
    .createRequest({ ...values, applicationId })
    .then(responseHandler)
    .then((result) => {
      if (result.status === 201) {
        onClose();
        refetch();
      }
    });

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>New reward type</Modal.Title>
      </Modal.Header>
      {response && (
        <Form
          initialValues={response}
          onSubmit={onSubmit}
        >
          {() => (
            <>
              <Modal.Body>
                <Label text="Select Game item">
                  <SelectField
                    name="rewardItemId"
                    options={filteredRewardItems}
                  />
                </Label>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit">Confirm</Button>
                <Button type="button" variant="secondary" onClick={onClose}>Cancel</Button>
              </Modal.Footer>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
}
