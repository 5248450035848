import React from 'react';
import { Table, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRouter } from '@tripledotstudios/react-core';

import { FormGroup } from '@hooks';
import { SelectField } from '@controls/form';
import { AbTestingRoutes } from '@pages/routes';

export default function ExperimentsTable({ values }) {
  const { query: { applicationId } } = useRouter();

  const renderExperimentGroupTab = (experiments, label, isActive) => (
    <Tab
      eventKey={`#experiments_${label}`}
      title={label}
      className="mt-4"
    >
      <Table bordered striped>
        <thead>
          <tr>
            <th>ID</th>
            <th>Experiment</th>
            <th>Variant</th>
          </tr>
        </thead>
        <tbody>
          {experiments.map((participation, index) => (
            participation.active === isActive ? (
              <tr>
                <FormGroup name={`experimentParticipationsAttributes.${index}`}>
                  <td>{participation.id}</td>
                  <td>
                    <Link
                      to={AbTestingRoutes.Experiments.editPath({ id: participation.abExperimentId, applicationId })}
                    >
                      {participation.experimentName}
                    </Link>
                  </td>
                  <td><SelectField name="abVariantId" options={participation.variants} /></td>
                </FormGroup>
              </tr>
            ) : ''
          ))}
        </tbody>
      </Table>
    </Tab>
  );

  return (
    <Tabs>
      {renderExperimentGroupTab(values.experimentParticipationsAttributes, 'Active', true)}
      {renderExperimentGroupTab(values.experimentParticipationsAttributes, 'Inactive', false)}
    </Tabs>
  );
}
