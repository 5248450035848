import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { Form } from '@hooks';
import { PageHeader } from '@pages/common';
import {
  FormButtonsGroup,
  Field,
  Label,
  FileField,
  StaticFieldsFormGroup,
} from '@controls/form';

import ErrorsCard from './ErrorsCard';

export default function XpLevelConfigsForm({
  data,
  onSubmit,
}) {
  const { query } = useRouter();
  const { applicationId } = query;

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit}>
      {({ values: { downloadUrl, meta, inLive } }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} JSON Config`}>
            <FormButtonsGroup />
          </PageHeader>

          <Label text="Name" fieldSize={8}>
            <Field type="text" name="name" disabled={inLive} />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <Label text="Config (CSV)" fieldSize={8}>
            <FileField downloadUrl={downloadUrl} name="file" accept="text/csv" fullError disabled={inLive} />
            <ErrorsCard meta={meta} />
          </Label>
        </>
      )}
    </Form>
  );
}
