import React, { useState, useMemo } from 'react';
import { ButtonToolbar, Spinner, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useConfirm } from '@hooks';

import { MyPetCafeRoutes } from '@pages/routes';
import { collectionResponseHandlerFactory } from '@requests/responseHandlers';

import {
  PageHeader,
  Pagination,
  Badge,
  ReactTable,
  TIMESTAMP_COLUMNS,
  RulesList,
  InLiveColumn,
  DuplicationModalWithPriority,
  LabelsColumn,
  PriorityColumn,
} from '@pages/common';

import APP_DATA from '@services/appData';

import IconButton from '@controls/buttons';

import Filter from './Filter';

const statusBadgeColor = (status, statuses) => {
  const colorMapping = {
    [statuses.ACTIVE]: 'success',
    [statuses.INACTIVE]: 'secondary',
    [statuses.ARCHIVED]: 'secondary',
  };

  return colorMapping[status];
};

const columnsFactory = ({
  applicationId, setDuplicatedRecord, onArchive, statuses,
}) => (
  [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Variant ID', accessor: 'defaultVariantId', disableSortBy: true },
    { Header: 'Name', accessor: 'name' },
    LabelsColumn,
    {
      Header: 'Status',
      Cell: ({ row: { original: { status, statusHumanize } } }) => (
        <Badge bg={statusBadgeColor(status, statuses)}>{statusHumanize}</Badge>
      ),
    },
    InLiveColumn,
    PriorityColumn,
    {
      Header: 'Start for client',
      accessor: 'startsForClientAt',
    },
    {
      Header: 'Start for player',
      accessor: 'startsForPlayerAt',
    },
    {
      Header: 'End for player/client',
      accessor: 'endsAt',
    },
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Rules',
      Cell: ({ row: { original: { ruleSectionsAttributes } } }) => <RulesList rules={ruleSectionsAttributes} />,
    },
    {
      Header: 'Actions',
      Cell: ({
        row: {
          original: { id, name, status },
        },
      }) => (
        <ButtonToolbar>
          <IconButton.Edit to={MyPetCafeRoutes.SoloMissions.Configs.editPath({ id, applicationId })} minimized />
          <IconButton.Duplicate onClick={() => setDuplicatedRecord({ id, name })} minimized />
          {status !== statuses.ARCHIVED && <IconButton.Archive onClick={onArchive(id, name)} minimized />}
        </ButtonToolbar>
      ),
    },
  ]
);

const statuses = APP_DATA.enums['MyPetCafe::SoloMissions::ConfigStatuses'];

export default function Index() {
  const [duplicatedRecord, setDuplicatedRecord] = useState(null);
  const { query: { applicationId } } = useRouter();
  const { response, refetch, isLoading } = useQuery(MyPetCafeRoutes.SoloMissions.Configs.indexRequest);
  const confirm = useConfirm();

  const archiveResponseHandler = collectionResponseHandlerFactory({
    entityName: 'Solo Mission Config',
    actionName: 'archive',
    refetch,
  });

  const onArchive = (id, name) => () => {
    confirm.showConfirmation({
      title: `Solo Mission Config '${name}' will be archived and not available for work. Continue?`,
    })
      .then(() => MyPetCafeRoutes.SoloMissions.Configs.archiveRequest({ applicationId, id })
        .then(archiveResponseHandler));
  };

  const columns = useMemo(() => columnsFactory({
    applicationId, setDuplicatedRecord, onArchive, statuses,
  }), []);

  return (
    <>
      <PageHeader
        title="Solo Mission Configs"
        filter={<Filter refetch={refetch} statuses={statuses} />}
      >
        <IconButton.New to={MyPetCafeRoutes.SoloMissions.Configs.newPath({ applicationId })} />
      </PageHeader>
      {isLoading ? <Spinner /> : response && (
        <>
          <ReactTable
            columns={columns}
            data={response.items}
            refetch={refetch}
          />
          <DuplicationModalWithPriority
            record={duplicatedRecord}
            entityName="Solo Mission Config"
            handleClose={() => setDuplicatedRecord(null)}
            onSubmit={(values) => MyPetCafeRoutes.SoloMissions.Configs.duplicateRequest({ ...values, applicationId })}
            entitiesByPriority={response.entitiesByPriority || {}}
          />
          <Pagination
            pageCount={response._meta.pages}
            refetch={refetch}
          />
        </>
      )}
    </>
  );
}
