import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { get } from 'lodash';

import APP_DATA from '@services/appData';
import { fromClassNameToOptions } from '@services/enums';

import { useCurrentApplication } from '@hooks';
import SelectFilter from './SelectFilter';

const {
  enums: { AvailabilityStates },
} = APP_DATA;

const userAvailabilityStates = get(APP_DATA, 'user.defaultFilters.availabilityStates');

export function availabilitySelectFilterDefaultValue(adminSettings) {
  if (userAvailabilityStates) return userAvailabilityStates;

  const defaultAvailabilityStates = [];

  if (adminSettings && adminSettings.defaultLiveAvailabilityFilter) {
    defaultAvailabilityStates.push(String(AvailabilityStates.LIVE));
  }

  return defaultAvailabilityStates;
}

export function AvailabilitySelectFilter({ updateFilterField }) {
  const { currentApplication: { adminSettings } } = useCurrentApplication();

  const availabilityStateOptions = fromClassNameToOptions(
    'AvailabilityStates',
  );

  useEffect(() => {
    const defaultValue = availabilitySelectFilterDefaultValue(adminSettings);
    const availabilityStateIn = defaultValue.length ? defaultValue : null;

    if (availabilityStateIn) updateFilterField({ availabilityStateIn }, true);
  }, []);

  return (
    <Col md={6}>
      <SelectFilter
        name="availabilityStateIn"
        label="Availability state"
        updateFilterField={updateFilterField}
        options={availabilityStateOptions}
      />
    </Col>
  );
}
