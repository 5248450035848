import React from 'react';
import { Alert } from 'react-bootstrap';
import { get } from 'lodash';

import { Form, useConfirm } from '@hooks';
import { combineReducers } from '@reducers';
import rewardsReducer from '@components/merge/reducers/rewardsReducer';
import targetsReducer from '@components/merge/reducers/targetsReducer';
import formReducer from './formReducer';

import PostcardForm from './form/PostcardForm';
import emptyStoryOrders from '../services/emptyStoryOrders';

const EmptyStoryOrdersWarning = ({ storyOrders }) => (
  <Alert variant="warning">
    <b>Some story orders&apos;s tasks are left without specified target/reward and could ruin the client:</b>
    <ul className="list-unstyled">
      {storyOrders.map((storyOrder) => (
        <li key={storyOrder._uuid}>{storyOrder.internalId}</li>
      ))}
    </ul>
    <b>Continue?</b>
  </Alert>
);

export default function PostcardConfigurationsForm({ data, onSubmit, actionName }) {
  const confirm = useConfirm();

  const errors = get(data, '_meta.errors', {});
  const submitWithConfirmation = (values) => {
    const emptyOrders = emptyStoryOrders(values);

    return emptyOrders && emptyOrders.length > 0
      ? (
        confirm.showConfirmation({
          title: 'Review changes before saving',
          body: <EmptyStoryOrdersWarning storyOrders={emptyOrders} />,
          confirmationText: 'Confirm',
          cancellationText: 'Cancel',
        })
          .then(() => onSubmit(values))
          .catch(() => { })
      ) : onSubmit(values);
  };

  return (
    <Form
      initialValues={data}
      onSubmit={submitWithConfirmation}
      reducer={combineReducers([formReducer, rewardsReducer, targetsReducer])}
    >
      {({ values }) => <PostcardForm values={values} errors={errors} actionName={actionName} />}
    </Form>
  );
}
