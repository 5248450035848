import React, { useState, useEffect } from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';
import {
  editHexagonPuzzleConfig,
  updateHexagonPuzzleConfig,
} from '@requests/puzzle-time/hexagonPuzzleConfigs';

import fieldsConfiguration from './FieldsConfiguration';
import Form from '../GameConfigsForm';

export default function Edit() {
  const [data, setData] = useState();
  const { query } = useRouter();
  const indexPath = `/admin/applications/${query.applicationId}/puzzle_time/hexagon_puzzle_configs`;

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Hexa Puzzle Configs',
    actionName: 'update',
    redirectPath: indexPath,
    setData,
  });

  const onSubmit = (values) => updateHexagonPuzzleConfig(query, values).then(redirectIfSuccessHandler);

  useEffect(() => {
    editHexagonPuzzleConfig(query).then((response) => setData(response.data));
  }, []);

  return data ? (
    <Form
      data={data}
      onSubmit={onSubmit}
      fieldsConfiguration={fieldsConfiguration}
      actionName="Update"
      indexPath={indexPath}
      configName="Hexa Puzzle Configs"
    />
  ) : '';
}
