import React from 'react';
import { ButtonToolbar } from '@tripledotstudios/react-core';

import { MergeRoutes } from '@pages/routes';

import {
  InLiveBadge,
  InLiveColumnHeader,
  TIMESTAMP_COLUMNS,
  InUseColumn,
} from '@pages/common';
import { isInUse } from '@pages/common/InUse';
import IconButton from '@controls/buttons';
import WorldSchemaCell from '@components/merge/shared/WorldSchemaCell';

export default function ColumnsFactory({
  applicationId, onDelete,
}) {
  return [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    {
      Header: 'World Schema',
      Cell: ({ row: { original: { worldSchema } } }) => <WorldSchemaCell schema={worldSchema} />,
    },
    {
      id: 'availability_header',
      Header: () => <InLiveColumnHeader />,
      Cell: ({ row }) => <InLiveBadge inLive={row.original.inLive} />,
    },
    InUseColumn,
    ...TIMESTAMP_COLUMNS,
    {
      Header: 'Actions',
      Cell: ({
        row: {
          original: {
            id, name, inUse,
          },
        },
      }) => {
        const disabled = isInUse(inUse);

        return (
          <ButtonToolbar>
            <IconButton.Edit
              to={MergeRoutes.CutscenesConfigurations.editPath({ id, applicationId })}
              readonly={disabled}
              minimized
            />
            <IconButton.Delete
              onClick={() => onDelete(id, name)}
              disabled={disabled}
              title={disabled ? 'Disabled as the entity is used' : null}
              minimized
            />
          </ButtonToolbar>
        );
      },
    },
  ];
}
