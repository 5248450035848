import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { get, omit, upperCase } from 'lodash';

import APP_DATA from '@services/appData';
import { useFormContext, useFormGroup, useQuery } from '@hooks';
import { SelectField } from '@controls/form';
import { AdsRoutes } from '@pages/routes';

const CampaignPlatformKeys = ({ platformKeys }) => {
  const { rawEnums } = APP_DATA;
  const platformsEnum = rawEnums['Accounts::Platforms'];

  return (
    <ListGroup className="mt-3">
      {Object.entries(omit(platformKeys, '_uuid')).map(([key, value]) => (
        <ListGroup.Item className="py-2" key={key}>
          <b>{`${platformsEnum[upperCase(key)].translation}: `}</b>
          {value || '-'}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default function CampaignFields({ name: groupName, disabled }) {
  const { values } = useFormContext();
  const { generateName } = useFormGroup();

  const { response: adUnitCampaignsResponse, isLoading: adUnitCampaignsLoading } = useQuery(
    AdsRoutes.AdUnitCampaigns.indexRequest, { withoutPagination: true },
  );

  const adUnitCampaignsOptions = (adUnitCampaignsResponse && adUnitCampaignsResponse.items.map(({ id, name }) => (
    { label: name, value: id }
  ))) || [];

  const selectedCampaignId = get(values, generateName(groupName));
  const selectedCampaign = adUnitCampaignsResponse && adUnitCampaignsResponse.items.find(({ id }) => (
    selectedCampaignId === id
  ));

  return (
    <>
      <SelectField
        isDisabled={disabled}
        name={groupName}
        options={adUnitCampaignsOptions}
        isLoading={adUnitCampaignsLoading}
      />
      {selectedCampaign && (
        <CampaignPlatformKeys platformKeys={selectedCampaign.keys} />
      )}
    </>
  );
}
