import React from 'react';
import { Col } from 'react-bootstrap';

import { Filter, TextFilter } from '@pages/common';

export default function LiveOpsTokensFilter() {
  return (
    <Filter defaultComponents={['name', 'availabilityStateIn', 'id', 'createdByMe', 'labels']}>
      {({ updateFilterField }) => (
        <Col md={6}>
          <TextFilter
            name="keyContAny"
            label="Key = "
            updateFilterField={updateFilterField}
          />
        </Col>
      )}
    </Filter>
  );
}
