import React from 'react';
import { PageTitle } from '@pages/common';

import StoreProductIndex from '@pages/payments/store-products/Index';
import StoreProductNew from '@pages/payments/store-products/New';
import StoreProductEdit from '@pages/payments/store-products/Edit';

import CatalogIndex from '@pages/payments/catalogs/Index';
import CatalogNew from '@pages/payments/catalogs/New';
import CatalogEdit from '@pages/payments/catalogs/Edit';

import ProductIndex from '@pages/payments/products/Index';
import ProductNew from '@pages/payments/products/New';
import ProductEdit from '@pages/payments/products/Edit';

import ProductImageIndex from '@pages/payments/product-images/Index';
import ProductImageNew from '@pages/payments/product-images/New';
import ProductImageEdit from '@pages/payments/product-images/Edit';

import ExperimentScoped from '@pages/payments/catalogs/ExperimentScoped';

import { PaymentsRoutes } from '@pages/routes';

import { Route } from 'react-router-dom';
import RouteContainer from '@pages/RouteContainer';

export default function payments() {
  const scope = '/admin/applications/:applicationId/payments';

  return [
    <Route
      key={PaymentsRoutes.StoreProducts.indexRawPath}
      path={PaymentsRoutes.StoreProducts.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Payment Store Products" />
          <StoreProductIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={PaymentsRoutes.StoreProducts.newRawPath}
      path={PaymentsRoutes.StoreProducts.newRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Payment Store Product" action="New" />
          <StoreProductNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={PaymentsRoutes.StoreProducts.editRawPath}
      path={PaymentsRoutes.StoreProducts.editRawPath}
      element={(
        <RouteContainer>
          <PageTitle entity="Payment Store Product" action="Edit" />
          <StoreProductEdit />
        </RouteContainer>
      )}
    />,

    <Route
      key={`${scope}/catalogs`}
      path={`${scope}/catalogs`}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Payment Catalogs" />
          <CatalogIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={`${scope}/catalogs/new`}
      path={`${scope}/catalogs/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Payment Catalog" action="New" />
          <CatalogNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={`${scope}/catalogs/:id/edit`}
      path={`${scope}/catalogs/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Payment Catalog" action="Edit" />
          <CatalogEdit />
        </RouteContainer>
      )}
    />,

    <Route
      key={PaymentsRoutes.Products.indexRawPath}
      path={PaymentsRoutes.Products.indexRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Payment Products" />
          <ProductIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={PaymentsRoutes.Products.newRawPath}
      path={PaymentsRoutes.Products.newRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Payment Product" action="New" />
          <ProductNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={PaymentsRoutes.Products.editRawPath}
      path={PaymentsRoutes.Products.editRawPath}
      element={(
        <RouteContainer fullWidth>
          <PageTitle entity="Payment Product" action="Edit" />
          <ProductEdit />
        </RouteContainer>
      )}
    />,

    <Route
      key={`${scope}/product_images`}
      path={`${scope}/product_images`}
      element={(
        <RouteContainer fullWidth>
          <PageTitle text="Payment Product Images" />
          <ProductImageIndex />
        </RouteContainer>
      )}
    />,
    <Route
      key={`${scope}/product_images/new`}
      path={`${scope}/product_images/new`}
      element={(
        <RouteContainer>
          <PageTitle entity="Payment Product Image" action="New" />
          <ProductImageNew />
        </RouteContainer>
      )}
    />,
    <Route
      key={`${scope}/product_images/:id/edit`}
      path={`${scope}/product_images/:id/edit`}
      element={(
        <RouteContainer>
          <PageTitle entity="Payment Product Image" action="Edit" />
          <ProductImageEdit />
        </RouteContainer>
      )}
    />,

    <Route
      key={`${scope}/catalogs/:id/experiment_scoped/:experimentId`}
      path={`${scope}/catalogs/:id/experiment_scoped/:experimentId`}
      element={(
        <RouteContainer>
          <PageTitle entity="Payments Catalog A/B Experiment Variant" action="Edit" />
          <ExperimentScoped />
        </RouteContainer>
      )}
    />,
  ];
}
