import React from 'react';
import { useCurrentApplication, useQuery } from '@hooks';

import { redirectIfSuccessHandlerFactory } from '@requests/responseHandlers';

import { ArchivedSettingsRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  const { response, setResponse } = useQuery(Routes.newRequest);
  const { applicationId } = useCurrentApplication();

  const redirectIfSuccessHandler = redirectIfSuccessHandlerFactory({
    entityName: 'Setting',
    actionName: 'Archiving',
    redirectPath: Routes.indexPath({ applicationId }),
    setData: setResponse,
  });

  const onSubmit = (values) => Routes.createRequest(values).then(redirectIfSuccessHandler);

  return response && (
    <Form
      data={response}
      onSubmit={onSubmit}
      actionName="Edit"
    />
  );
}
