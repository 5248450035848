import React from 'react';
import { WoodokuRoutes as Routes } from '@pages/routes';
import { Col, Row } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import IconButton from '@controls/buttons';
import { Form, FormGroup, useI18n } from '@hooks';
import { DuplicateButton, PageHeader, PageSection } from '@pages/common';
import {
  FormButtonsGroup,
  Field,
  Label,
  StaticFieldsFormGroup,
  ServerError,
} from '@controls/form';
import reducer from './reducer';

export default function PerfectFitFrom({
  data,
  onSubmit,
}) {
  const { translate } = useI18n();
  const { query } = useRouter();
  const { applicationId } = query;

  return (
    <Form initialValues={{ ...data, applicationId }} onSubmit={onSubmit} reducer={reducer}>
      {({ values, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Perfect Fit`}>
            <FormButtonsGroup cancelButtonPath={Routes.PerfectFit.indexPath({ applicationId })}>
              <DuplicateButton
                routes={Routes.PerfectFit}
                data={data}
                entityName="Perfect Fit"
              />
            </FormButtonsGroup>
          </PageHeader>

          <Label text="Name" fieldSize={8}>
            <Field name="name" />
          </Label>

          <Label
            text="Score threshold"
            tooltipText={translate.fallback('perfectFit.set.tooltips.scoreThreshold')}
            fieldSize={8}
          >
            <Field name="scoreThreshold" type="number" step="0.1" />
          </Label>

          <Label
            text="Tile edge Multiplier"
            tooltipText={translate.fallback('perfectFit.set.tooltips.tileEdgeMultiplier')}
            fieldSize={8}
          >
            <Field name="tileEdgeMultiplier" type="number" step="0.1" />
          </Label>

          <Label
            text="Board edge Multiplier"
            tooltipText={translate.fallback('perfectFit.set.tooltips.boardEdgeMultiplier')}
            fieldSize={8}
          >
            <Field name="boardEdgeMultiplier" type="number" step="0.1" />
          </Label>

          <StaticFieldsFormGroup data={data} />

          <PageSection title="Banks Probabilities" />

          <ServerError name="rowsAttributes.list" />
          {values.rowsAttributes.map((row, index) => (
            !row._destroy && (
              <FormGroup key={row._uuid} name={`rowsAttributes.${index}`}>
                <Row className="mb-2">
                  <Col xs={4}>
                    <Label required text="Bank Number" labelSize={4} fieldSize={6}>
                      <Field type="number" name="figureBankId" />
                    </Label>
                  </Col>
                  <Col xs={4}>
                    <Label required text="Probability" labelSize={4} fieldSize={6}>
                      <Field type="number" name="probability" step="0.01" />
                    </Label>
                  </Col>

                  <Col xs={2}>
                    <IconButton.Delete
                      minimized
                      onClick={() => dispatch({
                        actionType: 'removeRow',
                        index,
                      })}
                    />
                  </Col>
                </Row>
              </FormGroup>
            )
          ))}
          <IconButton.New
            className="mt-3"
            onClick={() => dispatch({ actionType: 'addRow' })}
          >
            Add
          </IconButton.New>
        </>
      )}
    </Form>
  );
}
