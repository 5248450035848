import React from 'react';
import { Field as FormikField, FastField } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { InputGroup } from 'react-bootstrap';
import { snakeCase } from '@tripledotstudios/react-core';

import { useFormGroup } from '@hooks/useFormGroup';
import { useFormContext } from '@hooks/useFormContext';

import ServerError from './ServerError';

export default function Field({
  type = null,
  as = null,
  disabled = null,
  component = null,
  children = null,
  onKeyUp = null,
  onChange = null,
  inline = true,
  fast = false,
  className = '',
  formControlClass = true,
  name,
  onKeyDown,
  step,
  min,
  max,
  onBlur,
  onDoubleClick,
  onKeyPress,
  onFocus,
  autoFocus,
  readOnly,
  innerRef,
  placeholder,
  id,
  autoComplete,
  prependText,
  appendText,
  htmlFieldProps = {},
  testId,
  wrapperClassName,
}) {
  const uuid = uuidv4();
  const { handleChange, applySharedInputProps } = useFormContext();
  const { generateName } = useFormGroup();
  const FieldComponent = fast ? FastField : FormikField;

  const fullName = generateName(name);

  const props = {
    name: fullName,
    step: type === 'number' ? step || 'any' : undefined,
    min: type === 'number' ? min || 'any' : undefined,
    max: type === 'number' ? max || 'any' : undefined,
    id: id || uuid,
    onChange: onChange || handleChange,
    type,
    as,
    component,
    onKeyDown,
    onKeyUp,
    onKeyPress,
    onBlur,
    onDoubleClick,
    onFocus,
    autoFocus,
    readOnly,
    innerRef,
    autoComplete,
    placeholder,
    ...applySharedInputProps({ disabled }),
    ...htmlFieldProps,
  };
  const renderError = () => (
    inline && <ServerError name={fullName} full />
  );
  const resolvedTestId = testId || snakeCase(fullName);

  if (appendText || prependText) {
    return (
      <div className={wrapperClassName}>
        <InputGroup className={className}>
          {prependText && <InputGroup.Text>{prependText}</InputGroup.Text>}
          <FieldComponent
            className={(formControlClass && type !== 'checkbox') && 'form-control'}
            data-testid={resolvedTestId}
            {...props}
          >
            {children}
          </FieldComponent>
          {appendText && <InputGroup.Text>{appendText}</InputGroup.Text>}
        </InputGroup>
        {renderError()}
      </div>
    );
  }

  return (
    <>
      <FieldComponent
        className={(formControlClass && type !== 'checkbox') ? `form-control ${className}` : className}
        data-testid={resolvedTestId}
        {...props}
      >
        {children}
      </FieldComponent>
      {renderError()}
    </>
  );
}
