import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

export default function LevelQRCode({ levelId, routes, applicationId }) {
  const path = [
    window.location.origin,
    '/api',
    routes.levelEditorPath({ applicationId, levelId }),
  ].join('');

  return <QRCodeSVG value={path} />;
}
