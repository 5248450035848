import React from 'react';

import { SpiderSolitaireRoutes } from '@pages/routes';

import CreateUpdateComponent from './CreateUpdateComponent';

export default function Edit() {
  return (
    <CreateUpdateComponent
      getFunction={SpiderSolitaireRoutes.WorldStatsConfig.editRequest}
      postFunction={SpiderSolitaireRoutes.WorldStatsConfig.updateRequest}
      actionName="update"
    />
  );
}
