import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import APP_DATA from '@services/appData';

import { useFormContext, FormGroup, useI18n } from '@hooks';
import { fromClassNameToOptions } from '@services/enums';
import { PageSection } from '@pages/common';
import { ServerError } from '@controls/form';

import ButtonGroup from './mission-templates/ButtonGroup';
import MissionPositionCol from './mission-templates/MissionPositionCol';
import TierSelect from './mission-templates/TierSelect';
import TypeSelect from './mission-templates/TypeSelect';
import RewardsConfiguration from './mission-templates/RewardsConfiguration';

const selectionTypes = APP_DATA.enums['Missions::MissionTemplateSelectionTypes'];

export default function MissionTemplates({ missions, targets }) {
  const { dispatch } = useFormContext();
  const { translate } = useI18n();
  const namespaceName = translate.fallback('missions.namespaceName');

  const selectionTypeOptions = fromClassNameToOptions('Missions::MissionTemplateSelectionTypes');

  const addMission = () => dispatch({ actionType: 'addMissionTemplate' });

  return missions && (
    <div className="mb-4">
      <PageSection title={`${namespaceName}s`} />

      <ServerError name="missionsAttributes.list" className="mb-4" />
      {missions.map((mission, missionIndex) => (
        mission._destroy !== true && (
          <FormGroup
            key={mission._uuid}
            name={`missionsAttributes[${missionIndex}]`}
          >
            <Row className="mb-4">
              <MissionPositionCol
                mission={mission}
                missionsCount={missions.length}
                missionIndex={missionIndex}
              />
              <Col xs="9">
                <TierSelect />
                <TypeSelect
                  missionIndex={missionIndex}
                  mission={mission}
                  targets={targets}
                  selectionTypes={selectionTypes}
                  selectionTypeOptions={selectionTypeOptions}
                />
                <RewardsConfiguration
                  missionIndex={missionIndex}
                  mission={mission}
                  selectionTypes={selectionTypes}
                  selectionTypeOptions={selectionTypeOptions}
                />
              </Col>
              <ButtonGroup missionIndex={missionIndex} />
            </Row>
          </FormGroup>
        )
      ))}

      <Button type="button" variant="success" onClick={addMission}>
        {`Add new ${namespaceName}`}
      </Button>
    </div>
  );
}
