import React from 'react';

import {
  GameSettingsTabs,
  localizationsDefaultFieldNames,
  popupsDefaultFieldNames,
  popupsDefaultArchivedFieldNames,
  analyticsDefaultFieldNames,
  bakedInConfigsTab,
  pushNotificationsDefaultFieldNames,
  adConfigsDefaultFieldNames,
  assetsDefaultFieldNames,
  adPlacementIdsDefaultFieldNames,
} from '@components/game-settings/Resource';
import { adRequestDelayFieldNames } from '@components/game-settings/Resource/Shared';

const tabsConfig = [
  {
    eventKey: '#general',
    title: 'General',
    fieldsNames: [
      'appsflyerEventWhitelist',
      'missionsEnabled',
      ...assetsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#addressables',
    title: 'Addressables',
    fieldsNames: [
      'overrideAddressablesVersion',
    ],
  },
  {
    eventKey: '#income_timing_balancing',
    title: 'Income timing balancing',
    fieldsNames: [
      'baseIncomeMultiplier',
      'baseIncomeInterval',
      'maxBonusAccumulation',
      'bonusInterval',
      'maxBonusMultiplier',
    ],
  },
  {
    eventKey: '#download_level_timing',
    title: 'Download level timing',
    fieldsNames: [
      'startLevelDownload',
      'levelDownloadOffset',
    ],
  },
  {
    eventKey: '#booster_purchase_amount',
    title: 'Booster Purchase Amount',
    fieldsNames: [
      'bulkPurchaseAmount',
    ],
  },
  {
    eventKey: '#start_chest_setting',
    title: 'Star chest setting',
    fieldsNames: [
      'uncountedRewardLevel',
    ],
  },
  {
    eventKey: '#end_game_button',
    title: 'End game button',
    fieldsNames: [
      'unlockAtRemainingCard',
    ],
  },
  {
    eventKey: '#language_setting',
    title: 'Language setting',
    fieldsNames: [
      'localizationVersion',
      'localizationUrl',
    ],
  },
  {
    eventKey: '#pity_system',
    title: 'Pity system',
    fieldsNames: [
      'enablePitySystem',
    ],
  },
  {
    eventKey: '#play_on_config',
    title: 'Play on config',
    fieldsNames: [
      'playOnMatchingCount',
      'playOnMatchingCountIncrement',
      'playOnMatchingCountMax',
      'playOnMatchingChance',
      'playOnMatchingChanceIncrement',
      'playOnMatchingChanceMax',
    ],
  },
  {
    eventKey: '#general_giftbox_setting',
    title: 'General gift box setting',
    fieldsNames: [
      'giftboxAutoCollect',
    ],
  },
  {
    eventKey: '#quest_auto_popup',
    title: 'Quest auto popup',
    fieldsNames: [
      'enableAutoPopupQuestMenu',
      'autoPopupQuestAfterQuestId',
      'autoPopupQuestUntilQuestId',
      'maxDifferenceTurnOffPopUp',
      'isPopupEnabledAgainIfFulfilled',
    ],
  },
  {
    eventKey: '#facebook_settings',
    title: 'Facebook settings',
    fieldsNames: [
      'showFacebookSettingsAfterPlayCount',
    ],
  },
  {
    eventKey: '#saga_map',
    title: 'Saga map',
    fieldsNames: [
      'sagaMapAchievementRequirement',
    ],
  },
  {
    eventKey: '#world_map',
    title: 'World map',
    fieldsNames: [
      'worldMapChapterRequirement',
      'worldMapLevelRequirement',
      'worldMapShowWidgetLevelRequirement',
      'worldMapWatchedCutsceneRequirement',
      'automaticChapterDownloadLevelRequirement',
      'maxChapterSettings',
      'worldMapUnlockRequirementId',
    ],
  },
  {
    eventKey: '#ad_configs',
    title: 'Ad Configs',
    fieldsNames: [...adRequestDelayFieldNames, ...adPlacementIdsDefaultFieldNames, ...adConfigsDefaultFieldNames],
  },
  {
    eventKey: '#localizations',
    title: 'Localisations',
    fieldsNames: localizationsDefaultFieldNames,
  },
  {
    eventKey: '#popups',
    title: 'Popups',
    fieldsNames: [
      ...popupsDefaultFieldNames,
      'gdprEnabled',
      'gdprVersion',
      'rateUsCoinReward',
      'rateUsLevelTrigger',
      'rateUsStarThreshold',
      'flexiblePopupsSettings',
    ],
  },
  {
    eventKey: '#fmv',
    title: 'FMV',
    fieldsNames: [
      'fmvEnabled',
      'fmvIsSkippable',
      'fmvLevelRequirement',
    ],
  },
  {
    eventKey: '#daily_bonus',
    title: 'Daily Bonus',
    fieldsNames: [
      'dailyBonusEnabled',
      'dailyBonusMinLevelToUnlock',
      'dailyBonusRounding',
      'dailyBonusMultiplier',
      'dailyBonusUnlockWhenLowOnCoins',
    ],
  },
  {
    eventKey: '#menu_flow',
    title: 'Menu Flow',
    fieldsNames: [
      'menuFlowPostWinFlow',
      'menuFlowAutoEnter',
    ],
  },
  {
    eventKey: '#instand_quest',
    title: 'Instant Quest',
    fieldsNames: [
      'instantQuestSkipEnabled',
      'instantQuestConsecutiveSkipRequirement',
    ],
  },
  {
    eventKey: '#anti_churn',
    title: 'Anti-churn',
    fieldsNames: [
      'ftueTargetLevel',
      'ftueTargetLevelOffset',
      'ftueMinimumLevel',
      'ftueEntryCostRequirementThresholdMultiplier',
      'ftueNonWinAttemptCooldown',
      'ftueMaximumTriggerCount',
      'powerupRounding',
      'ftueMaximumPowerup',
      'coinRounding',
      'ftueMaximumCoin',
      'ftueRewardSet',
      'antiChurnRewardConfigId',
    ],
  },
  {
    eventKey: '#particle',
    title: 'Particle',
    fieldsNames: [
      'tapParticleName',
    ],
  },
  {
    eventKey: '#analytics',
    title: 'Analytics',
    fieldsNames: analyticsDefaultFieldNames,
  },
  {
    eventKey: '#daily_missions',
    title: 'Daily Missions',
    fieldsNames: [
      'enableDailyMissions',
      'dailyMissionsLevelTrigger',
      'enableDailyMissionNotification',
      'dailyMissionNotificationResetTime',
      'dailyMissionCutsceneTrigger',
      'dailyMissionShowWidgetLevelRequirement',
      'dailyMissionUnlockRequirementId',
    ],
  },
  {
    eventKey: '#reset_time',
    title: 'Feature reset time',
    fieldsNames: [
      'gameServerResetTime',
    ],
  },
  {
    eventKey: '#bet_up',
    title: 'Bet Up',
    fieldsNames: [
      'betUpEnabled',
      'betUpConfigurationId',
      'toggleSaveBetupModeAfterTutorial',
      'toggleResetBetupModeAfterPlay',
    ],
  },
  {
    eventKey: '#inventory',
    title: 'Inventory',
    fieldsNames: [
      'inventoryWidgetEnabled',
      'inventoryLevelRequirement',
      'inventoryCutsceneRequirement',
      'customDecorsEnabled',
      'customDecorsLevelRequirement',
      'customDecorsCutsceneRequirement',
      'showUnreleasedDeco',
      'inventoryUnlockRequirementId',
      'customDecoUnlockRequirementId',
    ],
  },
  {
    eventKey: '#season_pass',
    title: 'Season Pass',
    fieldsNames: [
      'seasonsPassEnabled',
      'seasonsPassLevelRequirement',
      'seasonsPassCutsceneRequirement',
      'seasonsPassUnlockRequirementId',
    ],
  },
  {
    eventKey: '#daily_adventure',
    title: 'Daily Adventure',
    fieldsNames: [
      'dailyAdventureEnabled',
      'dailyAdventureLevelRequirement',
      'dailyAdventureCutsceneRequirement',
    ],
  },
  {
    eventKey: '#quest_progress_bar',
    title: 'Quest progress bar',
    fieldsNames: [
      'isQuestProgressBarEnabled',
      'questProgressBarLevelRequirement',
      'questProgressBarWatchedCutsceneRequirement',
      'questProgressBarAppearanceDurationInSeconds',
      'isQuestProgressBarLimitEnabled',
      'questProgressBarAppearanceNumberPerDay',
    ],
  },
  {
    eventKey: '#deco_progress',
    title: 'Deco progress',
    fieldsNames: [
      'isDecoProgressEnabled',
      'decoProgressLevelRequirement',
      'decoProgressWatchedCutsceneRequirement',
      'decoProgressUnlockRequirementId',
    ],
  },
  {
    eventKey: '#star_point_helper',
    title: 'Start point helper',
    fieldsNames: [
      'isStarPointHelperEnabled',
      'isPlayonCountHelperEnabled',
      'isPreloadedCardcutHelperEnabled',
      'isPreloadedWhirlwindHelperEnabled',
      'isPreloadedWildcardHelperEnabled',
      'isUndoCountHelperEnabled',
      'isWildcardCountHelperEnabled',
      'min2StarPointHelperThreshold',
      'min3StarPointHelperThreshold',
      'minPlayonCountThreshold',
      'minUndoCountThreshold',
      'minWildcardCountThreshold',
    ],
  },
  {
    eventKey: '#quest_suggestion',
    title: 'Quest suggestion',
    fieldsNames: [
      'questSuggestionEnabled',
      'questSuggestionStart',
      'questSuggestionUntil',
      'questSuggestionDefaultShowDelay',
    ],
  },
  {
    eventKey: '#pet_reward',
    title: 'Pet reward',
    fieldsNames: [
      'isPetRewardEnabled',
      'petRewardUnlockRequirementId',
    ],
  },
  {
    eventKey: '#push_notifications',
    title: 'Push Notifications',
    fieldsNames: [
      ...pushNotificationsDefaultFieldNames,
    ],
  },
  {
    eventKey: '#playon_override_config',
    title: 'Playon Override Config',
    fieldsNames: [],
  },
  {
    eventKey: '#leaderboards',
    title: 'Parallel Leaderboard',
    fieldsNames: [],
  },
  {
    eventKey: '#solo_missions',
    title: 'Solo Mission',
    fieldsNames: [],
  },
  {
    eventKey: '#starter_pack',
    title: 'Starter Pack',
    fieldsNames: [],
  },
  {
    eventKey: '#special_offer',
    title: 'Special Offer',
    fieldsNames: [],
  },
  {
    eventKey: '#win_streak',
    title: 'Win Streak',
    fieldsNames: [],
  },
  {
    eventKey: '#play_button_limiter',
    title: 'Play button limiter',
    fieldsNames: [],
  },
  {
    eventKey: '#gacha',
    title: 'Gacha',
    fieldsNames: [],
  },
  {
    eventKey: '#initial_data',
    title: 'Initial Data',
    fieldsNames: [],
  },
  bakedInConfigsTab,
  {
    eventKey: '#archived',
    title: 'Archived',
    fieldsNames: popupsDefaultArchivedFieldNames,
  },
  {
    eventKey: '#dda',
    title: 'DDA',
    fieldsNames: [],
  },
];

export default function Tabs({
  setContractType, fieldsFactory, isBase, errors, enabledFieldsNames, focusedField,
}) {
  return (
    <GameSettingsTabs
      onChange={setContractType}
      abTestable={isBase}
      fieldsFactory={fieldsFactory}
      errors={errors}
      enabledFieldsNames={enabledFieldsNames}
      tabsConfig={tabsConfig}
      localeNamespace="gameSettings"
      focusedField={focusedField}
    />
  );
}
