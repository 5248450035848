import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { PageHeader, DuplicateButton, PageSection } from '@pages/common';
import { Form } from '@hooks';
import { combineReducers } from '@reducers';
import { TileRoutes as Routes } from '@pages/routes';

import {
  FormButtonsGroup,
  StaticFieldsFormGroup,
  LabelsFormGroup,
  Label,
  Field,
} from '@controls/form';

import reducer from './reducer';
import Chapters from './Chapters';

export default function AssetBundlesSchemaSetForm({ data, onSubmit }) {
  const { query: { applicationId } } = useRouter();

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      reducer={combineReducers([reducer])}
    >
      {({ values, dirty, dispatch }) => (
        <>
          <PageHeader title={`${data.id ? 'Edit' : 'New'} Asset Bundles Schema Set`}>
            <FormButtonsGroup cancelButtonPath={Routes.AssetBundleSchemaSets.indexPath({ applicationId })}>
              <DuplicateButton
                entityName="Asset Bundles Schema Set"
                routes={Routes.AssetBundleSchemaSets}
                data={data}
                disabled={dirty}
                modalType="withPriority"
              />
            </FormButtonsGroup>
          </PageHeader>
          <Label text="Name">
            <Field type="text" name="name" />
          </Label>
          <LabelsFormGroup />
          <StaticFieldsFormGroup data={data} />

          <PageSection title="Chapters" />

          <Chapters values={values} dispatch={dispatch} />
        </>
      )}
    </Form>
  );
}
