import { addUuid } from '@services/recursivelyProcessObject';

export default function sudokuReducer(state, action) {
  const { actionType } = action;
  const { configAttributes: { levelsAttributes } } = state;

  if (actionType === 'addSudokuReward') {
    const { uuid } = action;

    const targetLevel = levelsAttributes.find(({ _uuid }) => _uuid === uuid);

    targetLevel.rewardsAttributes = [...(targetLevel.rewardsAttributes || []), addUuid({ _destroy: false })];
  }

  return state;
}
