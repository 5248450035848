import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import APP_DATA from '@services/appData';

import { Label, SelectField } from '@controls/form';
import { FilesRoutes } from '@pages/routes';
import { useQuery } from '@hooks';

const fileTypesEnum = APP_DATA.enums['BlockParty::FileTypesEnum'];

export default function BlockParty({ readOnly }) {
  const { query: { applicationId } } = useRouter();

  const { response: levelFileOptions } = useQuery({
    request: FilesRoutes.indexRequest,
    additionalRequestParams: { withoutPagination: true, filter: { fileTypeEq: fileTypesEnum.JOURNEY_LEVELS } },
    queryKey: ['journeyLevelFiles', applicationId],
    toSelectOptions: true,
  });

  return (
    <Label
      text="Journey levels"
      tooltipText="Select file with levels configuration for this Event (config.journey_levels_url)"
      copyableText="config.journey_levels_url"
    >
      <SelectField
        name="levelsFileId"
        options={levelFileOptions}
        quickLinkFn={(id) => FilesRoutes.editPath({ applicationId, id })}
        isDisabled={readOnly}
      />
    </Label>
  );
}
