import React from 'react';

import {
  TIMESTAMP_COLUMNS, InLiveColumn, InUseColumn,
} from '@pages/common';
import { MergeRoutes } from '@pages/routes';
import IconButton from '@controls/buttons';

const ColumnsFactory = ({ query }) => [
  { Header: 'Id', accessor: 'id' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Description', accessor: 'description' },
  InLiveColumn,
  InUseColumn,
  ...TIMESTAMP_COLUMNS,
  { Header: 'Min version', accessor: 'minVersion' },
  { Header: 'Max version', accessor: 'maxVersion' },
  {
    Header: 'Actions',
    Cell: ({ row }) => {
      const { id } = row.original;
      return (
        <IconButton.Edit to={MergeRoutes.WorldSchemas.editPath({ id, applicationId: query.applicationId })} minimized />
      );
    },
  },
];

export default ColumnsFactory;
