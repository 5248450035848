import React from 'react';
import { faAppStoreIos } from '@fortawesome/free-brands-svg-icons';
import {
  faHome,
  faSlidersH,
  faUserGroup,
  faCloudArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { ExternalDataImportRoutes, AdminRoutes } from '@pages/routes';

export default function Default({ minimized = false }) {
  return (
    <>
      <Sidebar.LinkItem
        to="/admin"
        icon={faHome}
        title="Home"
        minimized={minimized}
        matchPathProps={{ end: true }}
      />

      <Sidebar.LinkItem
        to={AdminRoutes.settingsPath()}
        icon={faSlidersH}
        title="Settings"
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to="/admin/admin_users"
        icon={faUserGroup}
        title="Users"
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to="/admin/applications"
        icon={faAppStoreIos}
        title="Applications"
        minimized={minimized}
      />

      <Sidebar.LinkItem
        to={ExternalDataImportRoutes.Artifacts.indexPath()}
        icon={faCloudArrowDown}
        title="External Data Import Log"
        minimized={minimized}
      />
    </>
  );
}
